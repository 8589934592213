import {
    call, fork, put, take, takeEvery, all
} from 'redux-saga/effects';
import history from '../../utils/history';
import { AUTH_LOGIN_REQUEST, AUTH_LOGOUT_REQUEST } from 'redux/constants/authConstants';
import pipelines from 'services';
import { authLoginRequestFailed, authLoginRequestSuccess } from 'redux/actions/authActions';
import { dashboardReset, dashboardSetWorkerContextOnly } from 'penta-redux/actions/dashboardActions';
import { authModels } from 'penta-models';

function* loginSaga({type, body}: { type: string, body: authModels.loginCredentials }) {
    try {
        const { data } = yield call(pipelines.authPipeline.authLogin, body);
        localStorage.setItem('user', JSON.stringify(data.data.onlyOne));
        localStorage.setItem('token', data.data.onlyOne.usrtoken);
        localStorage.setItem('tables', JSON.stringify(data.data.tables));
        const currentCompany = localStorage.getItem('currentCompany');
        const currentDivision = localStorage.getItem('currentDivision');
        const currentBussOfic = localStorage.getItem('currentBussOfic');
        const currentFiscyear = localStorage.getItem('currentFiscyear');
        if(currentCompany && currentDivision && currentBussOfic && currentFiscyear) {
            const { data } = yield call(pipelines.authPipeline.authLoginPermissions, currentBussOfic);
            const tables = JSON.parse(localStorage.getItem('tables'));
            tables.menu = data.data.tables.menu;
            localStorage.setItem('token', data.data.onlyOne.usrtoken);
            localStorage.setItem('tables', JSON.stringify(tables));
            yield put(dashboardSetWorkerContextOnly(currentCompany, currentDivision, currentBussOfic, currentFiscyear));
        }
        yield put(authLoginRequestSuccess());
        yield history.push('/papp');
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(authLoginRequestFailed(`Error: ${err.code}`, err.message));
    }
}

function* logoutSaga() {
    try {
        localStorage.removeItem('token');
        yield put(dashboardReset());
        yield history.push('/login');
    } catch(error) {
        console.log(error);
    }
}

function* authRootSaga() {
    yield all([
      takeEvery(AUTH_LOGIN_REQUEST, loginSaga),
      takeEvery(AUTH_LOGOUT_REQUEST, logoutSaga)
    ]);
}
  
const authSagas = [
    fork(authRootSaga),
];
  
export default authSagas;