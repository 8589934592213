import * as types from 'penta-redux/constants/humanResourcesConstants';
import { baseModels } from 'penta-models';

//#region COMMISSIS

export const commissisInitUpdateFormRequest = (commyear: string, commmont: number, callback: void) => ({
    type: types.COMMISSIS_INIT_UPDATE_FORM_REQUEST,
    commyear,
    commmont,
    callback
});

export const commissisInitUpdateFormRequestSuccess = (tables: any, onlyOne: any) => ({
    type: types.COMMISSIS_INIT_UPDATE_FORM_REQUEST_SUCCESS,
    tables,
    onlyOne
});

export const commissisInitUpdateFormRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.COMMISSIS_INIT_UPDATE_FORM_REQUEST_FAILED,
    titleError,
    titleContent
});

export const commissisGetAllRecordsRequest = (body: baseModels.pentaRequestQuery) => ({
    type: types.COMMISSIS_GET_ALL_RECORDS_REQUEST,
    body
});

export const commissisGetAllRecordsRequestSuccess = (list: Array<any>, total: number) => ({
    type: types.COMMISSIS_GET_ALL_RECORDS_REQUEST_SUCCESS,
    list,
    total
});

export const commissisGetAllRecordsRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.COMMISSIS_GET_ALL_RECORDS_REQUEST_FAILED,
    titleError,
    titleContent
});

export const commissisInsertRequest = (body: any, callback?: void) => ({
    type: types.COMMISSIS_INSERT_REQUEST,
    body,
    callback
});

export const commissisInsertRequestSuccess = () => ({
    type: types.COMMISSIS_INSERT_REQUEST_SUCCESS
});

export const commissisInsertRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.COMMISSIS_INSERT_REQUEST_FAILED,
    titleError,
    titleContent
});

export const commissisUpdateRequest = (body: any) => ({
    type: types.COMMISSIS_UPDATE_REQUEST,
    body
});

export const commissisUpdateRequestSuccess = () => ({
    type: types.COMMISSIS_UPDATE_REQUEST_SUCCESS
});

export const commissisUpdateRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.COMMISSIS_UPDATE_REQUEST_FAILED,
    titleError,
    titleContent
});

export const commissisDeleteRequest = (commyears: Array<string>, commmonts: Array<string>) => ({
    type: types.COMMISSIS_DELETE_REQUEST,
    commyears,
    commmonts
});

export const commissisDeleteRequestSuccess = () => ({
    type: types.COMMISSIS_DELETE_REQUEST_SUCCESS
});

export const commissisDeleteRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.COMMISSIS_DELETE_REQUEST_FAILED,
    titleError,
    titleContent
});

/*Components */
export const commissisListSelectItem = (key: number) => ({
    type: types.COMMISSIS_LIST_SELECT_ITEM,
    key
});

export const commissisListSelectAllItem = (checked: boolean) => ({
    type: types.COMMISSIS_LIST_SELECT_ALL_ITEM,
    checked
});
export const commissisActionFailed = (titleError: string, titleContent: string) => ({
    type: types.COMMISSIS_ACTION_FAILED,
    titleError,
    titleContent
});

//#endregion

//#region WEEKCALES

export const weekcalesInitUpdateFormRequest = (yearnumb: string, weeknumb: number, callback: void) => ({
    type: types.WEEKCALES_INIT_UPDATE_FORM_REQUEST,
    yearnumb,
    weeknumb,
    callback
});

export const weekcalesInitUpdateFormRequestSuccess = (tables: any, onlyOne: any) => ({
    type: types.WEEKCALES_INIT_UPDATE_FORM_REQUEST_SUCCESS,
    tables,
    onlyOne
});

export const weekcalesInitUpdateFormRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.WEEKCALES_INIT_UPDATE_FORM_REQUEST_FAILED,
    titleError,
    titleContent
});

export const weekcalesGetAllRecordsRequest = (body: baseModels.pentaRequestQuery) => ({
    type: types.WEEKCALES_GET_ALL_RECORDS_REQUEST,
    body
});

export const weekcalesGetAllRecordsRequestSuccess = (list: Array<any>, total: number) => ({
    type: types.WEEKCALES_GET_ALL_RECORDS_REQUEST_SUCCESS,
    list,
    total
});

export const weekcalesGetAllRecordsRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.WEEKCALES_GET_ALL_RECORDS_REQUEST_FAILED,
    titleError,
    titleContent
});

export const weekcalesInsertRequest = (body: any, callback?: void) => ({
    type: types.WEEKCALES_INSERT_REQUEST,
    body,
    callback
});

export const weekcalesInsertRequestSuccess = () => ({
    type: types.WEEKCALES_INSERT_REQUEST_SUCCESS
});

export const weekcalesInsertRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.WEEKCALES_INSERT_REQUEST_SUCCESS,
    titleError,
    titleContent
});

export const weekcalesUpdateRequest = (body: any) => ({
    type: types.WEEKCALES_UPDATE_REQUEST,
    body
});

export const weekcalesUpdateRequestSuccess = () => ({
    type: types.WEEKCALES_UPDATE_REQUEST_SUCCESS
});

export const weekcalesUpdateRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.WEEKCALES_UPDATE_REQUEST_FAILED,
    titleError,
    titleContent
});

export const weekcalesDeleteRequest = (yearnumbs: Array<string>, weeknumbs: Array<string>) => ({
    type: types.WEEKCALES_DELETE_REQUEST,
    yearnumbs,
    weeknumbs
});

export const weekcalesDeleteRequestSuccess = () => ({
    type: types.WEEKCALES_DELETE_REQUEST_SUCCESS
});

export const weekcalesDeleteRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.WEEKCALES_DELETE_REQUEST_FAILED,
    titleError,
    titleContent
});

/*Components */
export const weekcalesListSelectItem = (key: number) => ({
    type: types.WEEKCALES_LIST_SELECT_ITEM,
    key
});

export const weekcalesListSelectAllItem = (checked: boolean) => ({
    type: types.WEEKCALES_LIST_SELECT_ALL_ITEM,
    checked
});
export const weekcalesActionFailed = (titleError: string, titleContent: string) => ({
    type: types.WEEKCALES_ACTION_FAILED,
    titleError,
    titleContent
});

//#endregion

//#region EMPLOYES

export const employesInitFormRequest = () => ({
    type: types.EMPLOYES_INIT_FORM_REQUEST
});

export const employesInitFormRequestSuccess = (tables: any) => ({
    type: types.EMPLOYES_INIT_FORM_REQUEST_SUCCESS,
    tables
});

export const employesInitFormRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.EMPLOYES_INIT_FORM_REQUEST_FAILED,
    titleError,
    titleContent
});

export const employesInitUpdateFormRequest = (emplcode: string, callback: void) => ({
    type: types.EMPLOYES_INIT_UPDATE_FORM_REQUEST,
    emplcode,
    callback
});

export const employesInitUpdateFormRequestSuccess = (tables: any, onlyOne: any) => ({
    type: types.EMPLOYES_INIT_UPDATE_FORM_REQUEST_SUCCESS,
    tables,
    onlyOne
});

export const employesInitUpdateFormRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.EMPLOYES_INIT_UPDATE_FORM_REQUEST_FAILED,
    titleError,
    titleContent
});

export const employesGetAllRecordsRequest = (body: baseModels.pentaRequestQuery) => ({
    type: types.EMPLOYES_GET_ALL_RECORDS_REQUEST,
    body
});

export const employesGetAllRecordsRequestSuccess = (list: Array<any>, total: number) => ({
    type: types.EMPLOYES_GET_ALL_RECORDS_REQUEST_SUCCESS,
    list,
    total
});

export const employesGetAllRecordsRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.EMPLOYES_GET_ALL_RECORDS_REQUEST_FAILED,
    titleError,
    titleContent
});

export const employesInsertRequest = (body: any, callback?: void) => ({
    type: types.EMPLOYES_INSERT_REQUEST,
    body,
    callback
});

export const employesInsertRequestSuccess = () => ({
    type: types.EMPLOYES_INSERT_REQUEST_SUCCESS
});

export const employesInsertRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.EMPLOYES_INSERT_REQUEST_FAILED,
    titleError,
    titleContent
});

export const employesUpdateRequest = (body: any) => ({
    type: types.EMPLOYES_UPDATE_REQUEST,
    body
});

export const employesUpdateRequestSuccess = () => ({
    type: types.EMPLOYES_UPDATE_REQUEST_SUCCESS
});

export const employesUpdateRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.EMPLOYES_UPDATE_REQUEST_FAILED,
    titleError,
    titleContent
});

export const employesDeleteRequest = (emplcodes: Array<string>) => ({
    type: types.EMPLOYES_DELETE_REQUEST,
    emplcodes
});

export const employesDeleteRequestSuccess = () => ({
    type: types.EMPLOYES_DELETE_REQUEST_SUCCESS
});

export const employesDeleteRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.EMPLOYES_DELETE_REQUEST_FAILED,
    titleError,
    titleContent
});

/*Components */
export const employesListSelectItem = (key: number) => ({
    type: types.EMPLOYES_LIST_SELECT_ITEM,
    key
});

export const employesListSelectAllItem = (checked: boolean) => ({
    type: types.EMPLOYES_LIST_SELECT_ALL_ITEM,
    checked
});
export const employesActionFailed = (titleError: string, titleContent: string) => ({
    type: types.EMPLOYES_ACTION_FAILED,
    titleError,
    titleContent
});

//#endregion

//#region CONCEPTS

export const conceptsInitFormRequest = (fiscyear: number) => ({
    type: types.CONCEPTS_INIT_FORM_REQUEST,
    fiscyear
});

export const conceptsInitFormRequestSuccess = (tables: any) => ({
    type: types.CONCEPTS_INIT_FORM_REQUEST_SUCCESS,
    tables
});

export const conceptsInitFormRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.CONCEPTS_INIT_FORM_REQUEST_FAILED,
    titleError,
    titleContent
});

export const conceptsInitUpdateFormRequest = (conccode: string, typesala: string, payrtype: string, callback: void) => ({
    type: types.CONCEPTS_INIT_UPDATE_FORM_REQUEST,
    conccode,
    typesala,
    payrtype,
    callback
});

export const conceptsInitUpdateFormRequestSuccess = (tables: any, onlyOne: any) => ({
    type: types.CONCEPTS_INIT_UPDATE_FORM_REQUEST_SUCCESS,
    tables,
    onlyOne
});

export const conceptsInitUpdateFormRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.CONCEPTS_INIT_UPDATE_FORM_REQUEST_FAILED,
    titleError,
    titleContent
});

export const conceptsGetAllRecordsRequest = (body: baseModels.pentaRequestQuery) => ({
    type: types.CONCEPTS_GET_ALL_RECORDS_REQUEST,
    body
});

export const conceptsGetAllRecordsRequestSuccess = (list: Array<any>, total: number) => ({
    type: types.CONCEPTS_GET_ALL_RECORDS_REQUEST_SUCCESS,
    list,
    total
});

export const conceptsGetAllRecordsRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.CONCEPTS_GET_ALL_RECORDS_REQUEST_FAILED,
    titleError,
    titleContent
});

export const conceptsInsertRequest = (body: any, callback?: void) => ({
    type: types.CONCEPTS_INSERT_REQUEST,
    body,
    callback
});

export const conceptsInsertRequestSuccess = () => ({
    type: types.CONCEPTS_INSERT_REQUEST_SUCCESS
});

export const conceptsInsertRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.CONCEPTS_INSERT_REQUEST_FAILED,
    titleError,
    titleContent
});

export const conceptsUpdateRequest = (body: any) => ({
    type: types.CONCEPTS_UPDATE_REQUEST,
    body
});

export const conceptsUpdateRequestSuccess = () => ({
    type: types.CONCEPTS_UPDATE_REQUEST_SUCCESS
});

export const conceptsUpdateRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.CONCEPTS_UPDATE_REQUEST_FAILED,
    titleError,
    titleContent
});

export const conceptsDeleteRequest = (conccodes: Array<string>, typesalas: Array<string>, payrtypes: Array<string>) => ({
    type: types.CONCEPTS_DELETE_REQUEST,
    conccodes,
    typesalas,
    payrtypes
});

export const conceptsDeleteRequestSuccess = () => ({
    type: types.CONCEPTS_DELETE_REQUEST_SUCCESS
});

export const conceptsDeleteRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.CONCEPTS_DELETE_REQUEST_FAILED,
    titleError,
    titleContent
});

/*Components */
export const conceptsListSelectItem = (key: number) => ({
    type: types.CONCEPTS_LIST_SELECT_ITEM,
    key
});

export const conceptsListSelectAllItem = (checked: boolean) => ({
    type: types.CONCEPTS_LIST_SELECT_ALL_ITEM,
    checked
});
export const conceptsActionFailed = (titleError: string, titleContent: string) => ({
    type: types.CONCEPTS_ACTION_FAILED,
    titleError,
    titleContent
});

//#endregion

//#region PAYROLL

export const payrollInitFormRequest = (payryear: string, callback: void) => ({
    type: types.PAYROLL_INIT_FORM_REQUEST,
    payryear,
    callback
});

export const payrollInitFormRequestSuccess = (tables: any) => ({
    type: types.PAYROLL_INIT_FORM_REQUEST_SUCCESS,
    tables
});

export const payrollInitFormRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.PAYROLL_INIT_FORM_REQUEST_FAILED,
    titleError,
    titleContent
});

export const payrollInitUpdateFormRequest = (emplcode: string, payryear: string, payrweek: string, typesala: string, callback: void) => ({
    type: types.PAYROLL_INIT_UPDATE_FORM_REQUEST,
    emplcode,
    payryear,
    payrweek,
    typesala,
    callback
});

export const payrollInitUpdateFormRequestSuccess = (tables: any, onlyOne: any) => ({
    type: types.PAYROLL_INIT_UPDATE_FORM_REQUEST_SUCCESS,
    tables,
    onlyOne
});

export const payrollInitUpdateFormRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.PAYROLL_INIT_UPDATE_FORM_REQUEST_FAILED,
    titleError,
    titleContent
});

export const payrollGetAllRecordsRequest = (body: baseModels.pentaRequestQuery) => ({
    type: types.PAYROLL_GET_ALL_RECORDS_REQUEST,
    body
});

export const payrollGetAllRecordsRequestSuccess = (list: Array<any>, total: number) => ({
    type: types.PAYROLL_GET_ALL_RECORDS_REQUEST_SUCCESS,
    list,
    total
});

export const payrollGetAllRecordsRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.PAYROLL_GET_ALL_RECORDS_REQUEST_FAILED,
    titleError,
    titleContent
});

export const payrollInsertRequest = (body: any, callback?: void) => ({
    type: types.PAYROLL_INSERT_REQUEST,
    body,
    callback
});

export const payrollInsertRequestSuccess = () => ({
    type: types.PAYROLL_INSERT_REQUEST_SUCCESS
});

export const payrollInsertRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.PAYROLL_INSERT_REQUEST_FAILED,
    titleError,
    titleContent
});

export const payrollUpdateRequest = (body: any, callback?: void) => ({
    type: types.PAYROLL_UPDATE_REQUEST,
    body,
    callback
});

export const payrollUpdateRequestSuccess = () => ({
    type: types.PAYROLL_UPDATE_REQUEST_SUCCESS
});

export const payrollUpdateRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.PAYROLL_UPDATE_REQUEST_FAILED,
    titleError,
    titleContent
});

export const payrollDeleteRequest = (emplcodes: Array<string>, payryears: Array<string>, payrweeks: Array<string>, typesalas: Array<string>) => ({
    type: types.PAYROLL_DELETE_REQUEST,
    emplcodes,
    payryears,
    payrweeks,
    typesalas
});

export const payrollDeleteRequestSuccess = () => ({
    type: types.PAYROLL_DELETE_REQUEST_SUCCESS
});

export const payrollDeleteRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.PAYROLL_DELETE_REQUEST_FAILED,
    titleError,
    titleContent
});

/*Components */
export const payrollListSelectItem = (key: number) => ({
    type: types.PAYROLL_LIST_SELECT_ITEM,
    key
});

export const payrollListSelectAllItem = (checked: boolean) => ({
    type: types.PAYROLL_LIST_SELECT_ALL_ITEM,
    checked
});
export const payrollActionFailed = (titleError: string, titleContent: string) => ({
    type: types.PAYROLL_ACTION_FAILED,
    titleError,
    titleContent
});

//#endregion

//#region REPORTS

export const employePayrollRequest = (year: string, month: string, typesala: string, callback: void) => ({
    type: types.EMPLOYE_PAYROLL_REQUEST,
    year,
    month,
    typesala,
    callback
});

export const employePayrollRequestSuccess = (onlyOne: any) => ({
    type: types.EMPLOYE_PAYROLL_REQUEST_SUCCESS,
    onlyOne
});

export const employePayrollRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.EMPLOYE_PAYROLL_REQUEST_FAILED,
    titleError,
    titleContent
});

export const laborerPayrollRequest = (year: string, month: string, typesala: string, callback: void) => ({
    type: types.LABORER_PAYROLL_REQUEST,
    year,
    month,
    typesala,
    callback
});

export const laborerPayrollRequestSuccess = (onlyOne: any) => ({
    type: types.LABORER_PAYROLL_REQUEST_SUCCESS,
    onlyOne
});

export const laborerPayrollRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.LABORER_PAYROLL_REQUEST_FAILED,
    titleError,
    titleContent
});

export const laborerPayrollAccumulatedRequest = (year: string, month: string, endMonth: string, all: boolean, callback: void) => ({
    type: types.LABORER_PAYROLL_ACCUMULATED_REQUEST,
    year,
    month,
    endMonth,
    all,
    callback
});

export const laborerPayrollAccumulatedRequestSuccess = (onlyOne: any) => ({
    type: types.LABORER_PAYROLL_ACCUMULATED_REQUEST_SUCCESS,
    onlyOne
});

export const laborerPayrollAccumulatedRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.LABORER_PAYROLL_ACCUMULATED_REQUEST_FAILED,
    titleError,
    titleContent
});

export const employePayrollAccumulatedRequest = (year: string, month: string, endMonth: string, all: boolean, callback: void) => ({
    type: types.EMPLOYE_PAYROLL_ACCUMULATED_REQUEST,
    year,
    month,
    endMonth,
    all,
    callback
});

export const employePayrollAccumulatedRequestSuccess = (onlyOne: any) => ({
    type: types.EMPLOYE_PAYROLL_ACCUMULATED_REQUEST_SUCCESS,
    onlyOne
});

export const employePayrollAccumulatedRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.EMPLOYE_PAYROLL_ACCUMULATED_REQUEST_FAILED,
    titleError,
    titleContent
});

export const employeVoucherRequest = (emplcode: string, year: string, month: string, week: string, typesala: string, payrtype: string, callback: void) => ({
    type: types.EMPLOYE_VOUCHER_REQUEST,
    emplcode,
    year,
    month,
    week,
    typesala,
    payrtype,
    callback
});

export const employeVoucherRequestSuccess = (onlyOne: any) => ({
    type: types.EMPLOYE_VOUCHER_REQUEST_SUCCESS,
    onlyOne
});

export const employeVoucherRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.EMPLOYE_VOUCHER_REQUEST_FAILED,
    titleError,
    titleContent
});

export const employeVoucherAllRequest = (year: string, month: string, week: string, typesala: string, payrtype: string, callback: void) => ({
    type: types.EMPLOYE_VOUCHER_ALL_REQUEST,
    year,
    month,
    week,
    typesala,
    payrtype,
    callback
});

export const employeVoucherAllRequestSuccess = (onlyOne: any) => ({
    type: types.EMPLOYE_VOUCHER_ALL_REQUEST_SUCCESS,
    onlyOne
});

export const employeVoucherAllRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.EMPLOYE_VOUCHER_ALL_REQUEST_FAILED,
    titleError,
    titleContent
});

export const afpListRequest = (officode: string, payryear: string, payrmont: string, payrtype: string, callback: void) => ({
    type: types.AFP_LIST_REQUEST,
    officode,
    payryear,
    payrmont,
    payrtype,
    callback
});

export const afpListRequestSuccess = (onlyOne: any) => ({
    type: types.AFP_LIST_REQUEST_SUCCESS,
    onlyOne
});

export const afpListRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.AFP_LIST_REQUEST_FAILED,
    titleError,
    titleContent
});

export const pdtStaffRequest = (year: string, month: string, callback: void) => ({
    type: types.PDT_STAFF_REQUEST,
    year,
    month,
    callback
});

export const pdtStaffRequestSuccess = (onlyOne: any) => ({
    type: types.PDT_STAFF_REQUEST_SUCCESS,
    onlyOne
});

export const pdtStaffRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.PDT_STAFF_REQUEST_FAILED,
    titleError,
    titleContent
});

export const ctsDepositsRequest = (officode: string, callback: void) => ({
    type: types.CTS_DEPOSITS_REQUEST,
    officode,
    callback
});

export const ctsDepositsRequestSuccess = (onlyOne: any) => ({
    type: types.CTS_DEPOSITS_REQUEST_SUCCESS,
    onlyOne
});

export const ctsDepositsRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.CTS_DEPOSITS_REQUEST_FAILED,
    titleError,
    titleContent
});

//#endregion