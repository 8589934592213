import { CASH_BANKS_DELETE_REQUEST, CASH_BANKS_GET_ALL_RECORDS_REQUEST, CASH_BANKS_INIT_FORM_REQUEST, CASH_BANKS_INIT_UPDATE_FORM_REQUEST, CASH_BANKS_INSERT_REQUEST, CASH_BANKS_MOVEMENTS_DELETE_REQUEST, CASH_BANKS_MOVEMENTS_GET_ALL_RECORDS_REQUEST, CASH_BANKS_MOVEMENTS_INIT_FORM_REQUEST, CASH_BANKS_MOVEMENTS_INIT_UPDATE_FORM_REQUEST, CASH_BANKS_MOVEMENTS_INSERT_REQUEST, CASH_BANKS_MOVEMENTS_MASSIVE_UPDATE_REQUEST, CASH_BANKS_MOVEMENTS_UPDATE_REQUEST, CASH_BANKS_UPDATE_REQUEST, CHECKS_BALANCE_REQUEST, CHECKS_DELETE_REQUEST, CHECKS_GET_ALL_RECORDS_REQUEST, CHECKS_INIT_FORM_REQUEST, CHECKS_INIT_UPDATE_FORM_REQUEST, CHECKS_INSERT_REQUEST, CHECKS_ISSUED_SUMMARY_REQUEST, CHECKS_UPDATE_REQUEST } from "penta-redux/constants/financeConstants";

import {
    call, fork, put, take, takeEvery, all
} from 'redux-saga/effects';
import history from '../../utils/history';
import pipelines from 'services';
import {

} from "models/financeModels";
import { 
    cashBanksDeleteRequestFailed,
    cashBanksGetAllRecordsRequestFailed,
    cashBanksGetAllRecordsRequestSuccess,
    cashBanksInitFormRequestFailed,
    cashBanksInitFormRequestSuccess, 
    cashBanksInitUpdateFormRequestFailed, 
    cashBanksInitUpdateFormRequestSuccess,
    cashBanksInsertRequestFailed,
    cashBanksInsertRequestSuccess,
    cashBanksMovementsDeleteRequestFailed,
    cashBanksMovementsGetAllRecordsRequestFailed,
    cashBanksMovementsGetAllRecordsRequestSuccess,
    cashBanksMovementsInitFormRequestFailed,
    cashBanksMovementsInitFormRequestSuccess,
    cashBanksMovementsInitUpdateFormRequestFailed,
    cashBanksMovementsInitUpdateFormRequestSuccess,
    cashBanksMovementsInsertRequestFailed,
    cashBanksMovementsInsertRequestSuccess,
    cashBanksMovementsMassiveUpdateRequestFailed,
    cashBanksMovementsUpdateRequestFailed,
    cashBanksUpdateRequestFailed,
    cashBanksUpdateRequestSuccess,
    checksBalanceRequestFailed,
    checksBalanceRequestSuccess,
    checksDeleteRequestFailed,
    checksGetAllRecordsRequestFailed,
    checksGetAllRecordsRequestSuccess,
    checksInitFormRequestFailed,
    checksInitFormRequestSuccess,
    checksInitUpdateFormRequestFailed,
    checksInitUpdateFormRequestSuccess,
    checksInsertRequestFailed,
    checksInsertRequestSuccess,
    checksIssuedSummaryRequestFailed,
    checksIssuedSummaryRequestSuccess,
    checksUpdateRequestFailed
} from "penta-redux/actions/financeActions";

//#region CASH BANKS

function* cashBanksInitFormSaga(action: any) {
    try {
        const data = (yield call(pipelines.financePipeline.cashBanksInitForm, action.fiscyear)).data;
        yield put(cashBanksInitFormRequestSuccess(data.data.tables));
        yield call(action.callback);
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(cashBanksInitFormRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* cashBanksInitUpdateFormSaga(action: any) {
    try {
        const data = (yield call(pipelines.financePipeline.cashBanksInitUpdateForm, action.idcsbank)).data;
        yield put(cashBanksInitUpdateFormRequestSuccess(data.data.tables, data.data.onlyOne));
        yield call(action.callback, data.data.onlyOne);
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(cashBanksInitUpdateFormRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* cashBanksGetAllRecordsSaga(action: any) {
    try {
        const data = (yield call(pipelines.financePipeline.cashBanksGetAllRecords, action.body)).data;
        yield put(cashBanksGetAllRecordsRequestSuccess(data.data.list, data.data.total));
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(cashBanksGetAllRecordsRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* cashBanksInsertSaga(action: any) {
    try {
        yield call(pipelines.financePipeline.cashBanksInsert, action.body);
        if (action.callback !== null) {
            yield call(action.callback);
            yield put(cashBanksInsertRequestSuccess());
        }
        else
            yield history.push('/papp/finance/caja-bancos');
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(cashBanksInsertRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* cashBanksUpdateSaga(action: any) {
    try {
        const data = (yield call(pipelines.financePipeline.cashBanksUpdate, action.body.idcsbank, action.body)).data;
        if (action.callback !== null) {
            yield history.push('/papp/finance/caja-bancos/agregar');
        }
        else
            yield history.push('/papp/finance/caja-bancos');
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(cashBanksUpdateRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* cashBanksDeleteSaga(action: any) {
    try {
        const data = (yield call(pipelines.financePipeline.cashBanksDelete, action.idcsbanks)).data;
        yield put({ type: CASH_BANKS_GET_ALL_RECORDS_REQUEST, body: {} });
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(cashBanksDeleteRequestFailed(`Error: ${data.code}`, data.message));
    }
}

//#endregion

//#region CASH BANKS MOVEMENTS

function* cashBanksMovementsInitFormSaga(action: any) {
    try {
        const data = (yield call(pipelines.financePipeline.cashBanksMovementsInitForm, action.fiscyear)).data;
        yield put(cashBanksMovementsInitFormRequestSuccess(data.data.tables));
        yield call(action.callback);
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(cashBanksMovementsInitFormRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* cashBanksMovementsInitUpdateFormSaga(action: any) {
    try {
        const data = (yield call(pipelines.financePipeline.cashBanksMovementsInitUpdateForm, action.yearexer, action.montexer, action.weekexer, action.pagenumb, action.idcsbank)).data;
        yield put(cashBanksMovementsInitUpdateFormRequestSuccess(data.data.tables, data.data.onlyOne));
        yield call(action.callback, data.data.onlyOne);
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(cashBanksMovementsInitUpdateFormRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* cashBanksMovementsGetAllRecordsSaga(action: any) {
    try {
        const data = (yield call(pipelines.financePipeline.cashBanksMovementsGetAllRecords, action.body)).data;
        yield put(cashBanksMovementsGetAllRecordsRequestSuccess(data.data.list, data.data.total));
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(cashBanksMovementsGetAllRecordsRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* cashBanksMovementsInsertSaga(action: any) {
    try {
        yield call(pipelines.financePipeline.cashBanksMovementsInsert, action.body);
        if (action.callback !== null) {
            yield call(action.callback);
            yield put(cashBanksMovementsInsertRequestSuccess());
        }
        else
            yield history.push('/papp/finance/movimientos-caja-bancos');
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(cashBanksMovementsInsertRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* cashBanksMovementsUpdateSaga(action: any) {
    try {
        const data = (yield call(pipelines.financePipeline.cashBanksMovementsUpdate, action.yearexer, action.montexer, action.weekexer, action.pagenumb, action.idcsbank, action.body)).data;
        if (action.callback !== null) {
            yield history.push('/papp/finance/movimientos-caja-bancos/agregar');
        }
        else
            yield history.push('/papp/finance/movimientos-caja-bancos');
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(cashBanksMovementsUpdateRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* cashBanksMovementsDeleteSaga(action: any) {
    try {
        const data = (yield call(pipelines.financePipeline.cashBanksMovementsDelete, action.yearexers, action.montexers, action.weekexers, action.pagenumbs, action.idcsbanks)).data;
        yield put({ type: CASH_BANKS_MOVEMENTS_GET_ALL_RECORDS_REQUEST, body: {} });
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(cashBanksMovementsDeleteRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* cashBanksMovementsMassiveUpdateSaga(action: any) {
    try {
        for (const iterator of action.body) {
            try {
                yield call(pipelines.financePipeline.cashBanksMovementsInsert, iterator);
            } catch { }
            yield call((async (ms) => { return new Promise(resolve => setTimeout(resolve, ms)) }), 1000)
        }
        // yield history.push('/papp/shopping/compras');
        yield put({ type: CASH_BANKS_MOVEMENTS_GET_ALL_RECORDS_REQUEST, body: {} });
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(cashBanksMovementsMassiveUpdateRequestFailed(`Error: ${err.code}`, err.message));
    }
}

//#endregion

//#region CHECKS

function* checksInitFormSaga(action: any) {
    try {
        const data = (yield call(pipelines.financePipeline.checksInitForm, action.fiscyear)).data;
        yield put(checksInitFormRequestSuccess(data.data.tables));
        yield call(action.callback);
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(checksInitFormRequestFailed(`Error: ${err.code}`, err.message));
    }
}

function* checksInitUpdateFormSaga(action: any) {
    try {
        const data = (yield call(pipelines.financePipeline.checksInitUpdateForm, action.correlat)).data;
        yield put(checksInitUpdateFormRequestSuccess(data.data.tables, data.data.onlyOne));
        yield call(action.callback, data.data.onlyOne);
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(checksInitUpdateFormRequestFailed(`Error: ${err.code}`, err.message));
    }
}

function* checksGetAllRecordsSaga(action: any) {
    try {
        const data = (yield call(pipelines.financePipeline.checksGetAllRecords, action.body)).data;
        yield put(checksGetAllRecordsRequestSuccess(data.data.list, data.data.total));
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(checksGetAllRecordsRequestFailed(`Error: ${err.code}`, err.message));
    }
}

function* checksInsertSaga(action: any) {
    try {
        yield call(pipelines.financePipeline.checksInsert, action.body);
        if (action.callback !== null) {
            yield call(action.callback);
            yield put(checksInsertRequestSuccess());
        }
        else
            yield history.push('/papp/finance/cheques');
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(checksInsertRequestFailed(`Error: ${err.code}`, err.message));
    }
}

function* checksUpdateSaga(action: any) {
    try {
        const data = (yield call(pipelines.financePipeline.checksUpdate, action.body.correlat, action.body)).data;
        yield history.push('/papp/finance/cheques');
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(checksUpdateRequestFailed(`Error: ${err.code}`, err.message));
    }
}

function* checksDeleteSaga(action: any) {
    try {
        const data = (yield call(pipelines.financePipeline.checksDelete, action.correlats)).data;
        yield put({ type: CHECKS_GET_ALL_RECORDS_REQUEST, body: {} });
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(checksDeleteRequestFailed(`Error: ${err.code}`, err.message));
    }
}

//#endregion

//#region 

function* checksIssuedSummarySaga(action: any) {
    try {
        const data = (yield call(pipelines.financePipeline.checksIssuedSummary, action.fiscyear, action.month, action.initdate, action.endidate)).data;
        yield put(checksIssuedSummaryRequestSuccess(data.data.onlyOne));
        yield call(action.callback);
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(checksIssuedSummaryRequestFailed(`Error: ${err.code}`, err.message));
    }
}

function* checksBalanceSaga(action: any) {
    try {
        const data = (yield call(pipelines.financePipeline.checksBalance, action.fiscyear, action.month, action.currency)).data;
        yield put(checksBalanceRequestSuccess(data.data.onlyOne));
        yield call(action.callback);
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(checksBalanceRequestFailed(`Error: ${err.code}`, err.message));
    }
}

//#endregion

function* financeRootSaga() {
    yield all([
        takeEvery(CASH_BANKS_INIT_FORM_REQUEST, cashBanksInitFormSaga),
        takeEvery(CASH_BANKS_INIT_UPDATE_FORM_REQUEST, cashBanksInitUpdateFormSaga),
        takeEvery(CASH_BANKS_GET_ALL_RECORDS_REQUEST, cashBanksGetAllRecordsSaga),
        takeEvery(CASH_BANKS_INSERT_REQUEST, cashBanksInsertSaga),
        takeEvery(CASH_BANKS_UPDATE_REQUEST, cashBanksUpdateSaga),
        takeEvery(CASH_BANKS_DELETE_REQUEST, cashBanksDeleteSaga),
        takeEvery(CASH_BANKS_MOVEMENTS_INIT_FORM_REQUEST, cashBanksMovementsInitFormSaga),
        takeEvery(CASH_BANKS_MOVEMENTS_INIT_UPDATE_FORM_REQUEST, cashBanksMovementsInitUpdateFormSaga),
        takeEvery(CASH_BANKS_MOVEMENTS_GET_ALL_RECORDS_REQUEST, cashBanksMovementsGetAllRecordsSaga),
        takeEvery(CASH_BANKS_MOVEMENTS_INSERT_REQUEST, cashBanksMovementsInsertSaga),
        takeEvery(CASH_BANKS_MOVEMENTS_UPDATE_REQUEST, cashBanksMovementsUpdateSaga),
        takeEvery(CASH_BANKS_MOVEMENTS_DELETE_REQUEST, cashBanksMovementsDeleteSaga),
        takeEvery(CASH_BANKS_MOVEMENTS_MASSIVE_UPDATE_REQUEST, cashBanksMovementsMassiveUpdateSaga),
        takeEvery(CHECKS_INIT_FORM_REQUEST, checksInitFormSaga),
        takeEvery(CHECKS_INIT_UPDATE_FORM_REQUEST, checksInitUpdateFormSaga),
        takeEvery(CHECKS_GET_ALL_RECORDS_REQUEST, checksGetAllRecordsSaga),
        takeEvery(CHECKS_INSERT_REQUEST, checksInsertSaga),
        takeEvery(CHECKS_UPDATE_REQUEST, checksUpdateSaga),
        takeEvery(CHECKS_DELETE_REQUEST, checksDeleteSaga),
        takeEvery(CHECKS_ISSUED_SUMMARY_REQUEST, checksIssuedSummarySaga),
        takeEvery(CHECKS_BALANCE_REQUEST, checksBalanceSaga)
    ]);
}
  
const financeSagas = [
    fork(financeRootSaga),
];
  
export default financeSagas;