export const MASTER_ACCOUNT_GET_ALL_RECORDS_REQUEST = 'MASTER_ACCOUNT_GET_ALL_RECORDS_REQUEST';
export const MASTER_ACCOUNT_GET_ALL_RECORDS_REQUEST_SUCCESS = 'MASTER_ACCOUNT_GET_ALL_RECORDS_REQUEST_SUCCESS';
export const MASTER_ACCOUNT_GET_ALL_RECORDS_REQUEST_FAILED = 'MASTER_ACCOUNT_GET_ALL_RECORDS_REQUEST_FAILED';
export const MASTER_ACCOUNT_UPDATE_REQUEST = 'MASTER_ACCOUNT_UPDATE_REQUEST';
export const MASTER_ACCOUNT_UPDATE_REQUEST_SUCCESS = 'MASTER_ACCOUNT_UPDATE_REQUEST_SUCCESS';
export const MASTER_ACCOUNT_UPDATE_REQUEST_FAILED = 'MASTER_ACCOUNT_UPDATE_REQUEST_FAILED';

export const PLAN_ACCOUNT_INIT_FORM_REQUEST = 'PLAN_ACCOUNT_INIT_FORM_REQUEST';
export const PLAN_ACCOUNT_INIT_FORM_REQUEST_SUCCESS = 'PLAN_ACCOUNT_INIT_FORM_REQUEST_SUCCESS';
export const PLAN_ACCOUNT_INIT_FORM_REQUEST_FAILED = 'PLAN_ACCOUNT_INIT_FORM_REQUEST_FAILED';
export const PLAN_ACCOUNT_INIT_UPDATE_FORM_REQUEST = 'PLAN_ACCOUNT_INIT_UPDATE_FORM_REQUEST';
export const PLAN_ACCOUNT_INIT_UPDATE_FORM_REQUEST_SUCCESS = 'PLAN_ACCOUNT_INIT_UPDATE_FORM_REQUEST_SUCCESS';
export const PLAN_ACCOUNT_INIT_UPDATE_FORM_REQUEST_FAILED = 'PLAN_ACCOUNT_INIT_UPDATE_FORM_REQUEST_FAILED';
export const PLAN_ACCOUNT_GET_ALL_RECORDS_REQUEST = 'PLAN_ACCOUNT_GET_ALL_RECORDS_REQUEST';
export const PLAN_ACCOUNT_GET_ALL_RECORDS_REQUEST_SUCCESS = 'PLAN_ACCOUNT_GET_ALL_RECORDS_REQUEST_SUCCESS';
export const PLAN_ACCOUNT_GET_ALL_RECORDS_REQUEST_FAILED = 'PLAN_ACCOUNT_GET_ALL_RECORDS_REQUEST_FAILED';
export const PLAN_ACCOUNT_INSERT_REQUEST = 'PLAN_ACCOUNT_INSERT_REQUEST';
export const PLAN_ACCOUNT_INSERT_REQUEST_SUCCESS = 'PLAN_ACCOUNT_INSERT_REQUEST_SUCCESS';
export const PLAN_ACCOUNT_INSERT_REQUEST_FAILED = 'PLAN_ACCOUNT_INSERT_REQUEST_FAILED';
export const PLAN_ACCOUNT_UPDATE_REQUEST = 'PLAN_ACCOUNT_UPDATE_REQUEST';
export const PLAN_ACCOUNT_UPDATE_REQUEST_SUCCESS = 'PLAN_ACCOUNT_UPDATE_REQUEST_SUCCESS';
export const PLAN_ACCOUNT_UPDATE_REQUEST_FAILED = 'PLAN_ACCOUNT_UPDATE_REQUEST_FAILED';
export const PLAN_ACCOUNT_DELETE_REQUEST = 'PLAN_ACCOUNT_DELETE_REQUEST';
export const PLAN_ACCOUNT_DELETE_REQUEST_SUCCESS = 'PLAN_ACCOUNT_DELETE_REQUEST_SUCCESS';
export const PLAN_ACCOUNT_DELETE_REQUEST_FAILED = 'PLAN_ACCOUNT_DELETE_REQUEST_FAILED';

/*Components */
export const PLAN_ACCOUNT_LIST_SELECT_ITEM = 'PLAN_ACCOUNT_LIST_SELECT_ITEM';
export const PLAN_ACCOUNT_LIST_SELECT_ALL_ITEM = 'PLAN_ACCOUNT_LIST_SELECT_ALL_ITEM';
export const PLAN_ACCOUNT_ACTION_FAILED = 'PLAN_ACCOUNT_ACTION_FAILED';

//#region VOUCHERS

export const VOUCHERS_INIT_FORM_REQUEST = 'VOUCHERS_INIT_FORM_REQUEST';
export const VOUCHERS_INIT_FORM_REQUEST_SUCCESS = 'VOUCHERS_INIT_FORM_REQUEST_SUCCESS';
export const VOUCHERS_INIT_FORM_REQUEST_FAILED = 'VOUCHERS_INIT_FORM_REQUEST_FAILED';
export const VOUCHERS_INIT_UPDATE_FORM_REQUEST = 'VOUCHERS_INIT_UPDATE_FORM_REQUEST';
export const VOUCHERS_INIT_UPDATE_FORM_REQUEST_SUCCESS = 'VOUCHERS_INIT_UPDATE_FORM_REQUEST_SUCCESS';
export const VOUCHERS_INIT_UPDATE_FORM_REQUEST_FAILED = 'VOUCHERS_INIT_UPDATE_FORM_REQUEST_FAILED';
export const VOUCHERS_GET_ALL_RECORDS_REQUEST = 'VOUCHERS_GET_ALL_RECORDS_REQUEST';
export const VOUCHERS_GET_ALL_RECORDS_REQUEST_SUCCESS = 'VOUCHERS_GET_ALL_RECORDS_REQUEST_SUCCESS';
export const VOUCHERS_GET_ALL_RECORDS_REQUEST_FAILED = 'VOUCHERS_GET_ALL_RECORDS_REQUEST_FAILED';
export const VOUCHERS_INSERT_REQUEST = 'VOUCHERS_INSERT_REQUEST';
export const VOUCHERS_INSERT_REQUEST_SUCCESS = 'VOUCHERS_INSERT_REQUEST_SUCCESS';
export const VOUCHERS_INSERT_REQUEST_FAILED = 'VOUCHERS_INSERT_REQUEST_FAILED';
export const VOUCHERS_UPDATE_REQUEST = 'VOUCHERS_UPDATE_REQUEST';
export const VOUCHERS_UPDATE_REQUEST_SUCCESS = 'VOUCHERS_UPDATE_REQUEST_SUCCESS';
export const VOUCHERS_UPDATE_REQUEST_FAILED = 'VOUCHERS_UPDATE_REQUEST_FAILED';
export const VOUCHERS_DELETE_REQUEST = 'VOUCHERS_DELETE_REQUEST';
export const VOUCHERS_DELETE_REQUEST_SUCCESS = 'VOUCHERS_DELETE_REQUEST_SUCCESS';
export const VOUCHERS_DELETE_REQUEST_FAILED = 'VOUCHERS_DELETE_REQUEST_FAILED';

/*Components */
export const VOUCHERS_LIST_SELECT_ITEM = 'VOUCHERS_LIST_SELECT_ITEM';
export const VOUCHERS_LIST_SELECT_ALL_ITEM = 'VOUCHERS_LIST_SELECT_ALL_ITEM';
export const VOUCHERS_ACTION_FAILED = 'VOUCHERS_ACTION_FAILED';

//#endregion

//#region REPORTS

export const GENERAL_BALANCES_ACCOUNT_REQUEST = 'GENERAL_BALANCES_ACCOUNT_REQUEST';
export const GENERAL_BALANCES_ACCOUNT_REQUEST_SUCCESS = 'GENERAL_BALANCES_ACCOUNT_REQUEST_SUCCESS';
export const GENERAL_BALANCES_ACCOUNT_REQUEST_FAILED = 'GENERAL_BALANCES_ACCOUNT_REQUEST_FAILED';

export const DIARY_BOOK_REQUEST = 'DIARY_BOOK_REQUEST';
export const DIARY_BOOK_REQUEST_SUCCESS = 'DIARY_BOOK_REQUEST_SUCCESS';
export const DIARY_BOOK_REQUEST_FAILED = 'DIARY_BOOK_REQUEST_FAILED';

export const PURCHASES_REGISTER_REQUEST = 'PURCHASES_REGISTER_REQUEST';
export const PURCHASES_REGISTER_REQUEST_SUCCESS = 'PURCHASES_REGISTER_REQUEST_SUCCESS';
export const PURCHASES_REGISTER_REQUEST_FAILED = 'PURCHASES_REGISTER_REQUEST_FAILED';

// export const GENERATE_EMPTY_BALANCES_REQUEST = 'GENERATE_EMPTY_BALANCES_REQUEST';
// export const GENERATE_EMPTY_BALANCES_REQUEST_SUCCESS = 'GENERATE_EMPTY_BALANCES_REQUEST_SUCCESS';
// export const GENERATE_EMPTY_BALANCES_REQUEST_FAILED = 'GENERATE_EMPTY_BALANCES_REQUEST_FAILED';

export const WORKSHEET_REQUEST = 'WORKSHEET_REQUEST';
export const WORKSHEET_REQUEST_SUCCESS = 'WORKSHEET_REQUEST_SUCCESS';
export const WORKSHEET_REQUEST_FAILED = 'WORKSHEET_REQUEST_FAILED';

export const MAJOR_GENERAL_REQUEST = 'MAJOR_GENERAL_REQUEST';
export const MAJOR_GENERAL_REQUEST_SUCCESS = 'MAJOR_GENERAL_REQUEST_SUCCESS';
export const MAJOR_GENERAL_REQUEST_FAILED = 'MAJOR_GENERAL_REQUEST_FAILED';

export const CASH_BOOK_BANKS_REQUEST = 'CASH_BOOK_BANKS_REQUEST';
export const CASH_BOOK_BANKS_REQUEST_SUCCESS = 'CASH_BOOK_BANKS_REQUEST_SUCCESS';
export const CASH_BOOK_BANKS_REQUEST_FAILED = 'CASH_BOOK_BANKS_REQUEST_FAILED';

export const EXPENSE_ANALYSIS_NINE_REQUEST = 'EXPENSE_ANALYSIS_NINE_REQUEST';
export const EXPENSE_ANALYSIS_NINE_REQUEST_SUCCESS = 'EXPENSE_ANALYSIS_NINE_REQUEST_SUCCESS';
export const EXPENSE_ANALYSIS_NINE_REQUEST_FAILED = 'EXPENSE_ANALYSIS_NINE_REQUEST_FAILED';

export const EXPENSE_ANALYSIS_SIX_REQUEST = 'EXPENSE_ANALYSIS_SIX_REQUEST';
export const EXPENSE_ANALYSIS_SIX_REQUEST_SUCCESS = 'EXPENSE_ANALYSIS_SIX_REQUEST_SUCCESS';
export const EXPENSE_ANALYSIS_SIX_REQUEST_FAILED = 'EXPENSE_ANALYSIS_SIX_REQUEST_FAILED';

export const INVENTORY_BOOK_BALANCE_REQUEST = 'INVENTORY_BOOK_BALANCE_REQUEST';
export const INVENTORY_BOOK_BALANCE_REQUEST_SUCCESS = 'INVENTORY_BOOK_BALANCE_REQUEST_SUCCESS';
export const INVENTORY_BOOK_BALANCE_REQUEST_FAILED = 'INVENTORY_BOOK_BALANCE_REQUEST_FAILED';

export const GENERAL_BALANCES_REQUEST = 'GENERAL_BALANCES_REQUEST';
export const GENERAL_BALANCES_REQUEST_SUCCESS = 'GENERAL_BALANCES_REQUEST_SUCCESS';
export const GENERAL_BALANCES_REQUEST_FAILED = 'GENERAL_BALANCES_REQUEST_FAILED';

export const PROFIT_LOSS_STATEMENT_REQUEST = 'PROFIT_LOSS_STATEMENT_REQUEST';
export const PROFIT_LOSS_STATEMENT_REQUEST_SUCCESS = 'PROFIT_LOSS_STATEMENT_REQUEST_SUCCESS';
export const PROFIT_LOSS_STATEMENT_REQUEST_FAILED = 'PROFIT_LOSS_STATEMENT_REQUEST_FAILED';

export const SALES_REGISTER_REQUEST = 'SALES_REGISTER_REQUEST';
export const SALES_REGISTER_REQUEST_SUCCESS = 'SALES_REGISTER_REQUEST_SUCCESS';
export const SALES_REGISTER_REQUEST_FAILED = 'SALES_REGISTER_REQUEST_FAILED';

export const DAOT_REQUEST = 'DAOT_REQUEST';
export const DAOT_REQUEST_SUCCESS = 'DAOT_REQUEST_SUCCESS';
export const DAOT_REQUEST_FAILED = 'DAOT_REQUEST_FAILED';

export const PURCHASE_DETAIL_SUPPLIER_REQUEST = 'PURCHASE_DETAIL_SUPPLIER_REQUEST';
export const PURCHASE_DETAIL_SUPPLIER_REQUEST_SUCCESS = 'PURCHASE_DETAIL_SUPPLIER_REQUEST_SUCCESS';
export const PURCHASE_DETAIL_SUPPLIER_REQUEST_FAILED = 'PURCHASE_DETAIL_SUPPLIER_REQUEST_FAILED';

//#endregion

export const MAJORIZATION_REQUEST = 'MAJORIZATION_REQUEST';
export const MAJORIZATION_REQUEST_SUCCESS = 'MAJORIZATION_REQUEST_SUCCESS';
export const MAJORIZATION_REQUEST_FAILED = 'MAJORIZATION_REQUEST_FAILED';