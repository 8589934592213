import React, { useRef } from 'react';
import {createUseStyles} from 'react-jss';
import Separator from './separator';
import Tooltip from './tooltip';

const AddFile = require('penta-assets/images/add-file.svg').ReactComponent;
const EditFile = require('penta-assets/images/edit-file.svg').ReactComponent;
const DeleteFile = require('penta-assets/images/delete-file.svg').ReactComponent;
const AnulateFile = require('penta-assets/images/anulate-file.svg').ReactComponent;
const CopyFile = require('penta-assets/images/copy-file.svg').ReactComponent;
const Upload = require('penta-assets/images/upload.svg').ReactComponent;
const Download = require('penta-assets/images/download.svg').ReactComponent;
const Camera = require('penta-assets/images/camera.svg').ReactComponent;
const Printer = require('penta-assets/images/printer.svg').ReactComponent;
const Email = require('penta-assets/images/email.svg').ReactComponent;
const Diagram = require('penta-assets/images/diagram.svg').ReactComponent;
const View = require('penta-assets/images/view.svg').ReactComponent;

const useStyles =  createUseStyles({
    container: {
        position: 'relative',
        border: '0.063px solid #DFDFDF',
        height: '2.188rem',
        padding: '0rem 0.5rem',
        borderRadius: '0.5rem',
        display: 'flex',
        alignItems: 'center',
        '& > div': {
            alignItems: 'center',
            display: 'flex'
        },
        '& svg': {
            cursor: 'pointer',
            width: '25px',
            height: 'auto',
            margin: '0rem 0.5rem'
        },
        '& label': {
            display: 'flex'
        }
    },
    disabled: {
        filter: 'grayscale(100%)'
    }
});

interface ListMenu {
    addItem?: () => void
    editItem?: () => void
    deleteItem?: () => void
    anulateItem?: () => void
    copyItem?: () => void
    uploadItems?: (e, fx) => void
    downloadItems?: () => void
    captureFromCamera?: () => void
    printItem?: () => void
    sendItem?: () => void
    showDiagramProcess?: () => void
    viewItem?: () => void
    // onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    // value: string | number | readonly string[]
    uploadFile?: any
}

const ListMenu = (props: ListMenu) => {
    const classes = useStyles();
    const fileRef = useRef<any>();
    
    const cleanFileInput = () => {
        if(fileRef.current)
            fileRef.current.value = '';
    }

    return (
        <div className={classes.container}>
            <Tooltip position="bottom" text="Agregar">
                <AddFile onClick={props.addItem} className={props.addItem ? '' : classes.disabled}/>
            </Tooltip>
            <Tooltip position="bottom" text="Editar">
                <EditFile onClick={props.editItem} className={props.editItem ? '' : classes.disabled}/>
            </Tooltip>
            <Tooltip position="bottom" text="Eliminar">
                <DeleteFile onClick={props.deleteItem}/>
            </Tooltip>
            <AnulateFile onClick={props.anulateItem} className={classes.disabled}/>
            <Separator marginLeft='0.5rem' marginRight='0.5rem'>|</Separator>
            <Tooltip position="bottom" text="Copiar">
                <CopyFile onClick={props.copyItem}/>
            </Tooltip>
            <Tooltip position="bottom" text="Cargar">
                <React.Fragment>
                    <input
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        style={{display: 'none'}}
                        id="upload-input"
                        type="file"
                        value={props.uploadFile}
                        onChange={(e) => { props.uploadItems(e, cleanFileInput); }}
                        disabled={!props.uploadItems}
                        ref={fileRef}
                    />
                    <label htmlFor="upload-input">
                        <Upload  className={props.uploadItems ? '' : classes.disabled}/>
                    </label>
                </React.Fragment>
            </Tooltip>
            <Download onClick={props.downloadItems} className={classes.disabled}/>
            <Camera onClick={props.captureFromCamera} className={classes.disabled}/>
            <Separator marginLeft='0.5rem' marginRight='0.5rem'>|</Separator>
            <Printer onClick={props.printItem} className={classes.disabled}/>
            <Email onClick={props.sendItem} className={classes.disabled}/>
            <Diagram onClick={props.showDiagramProcess} className={classes.disabled}/>
            <View onClick={props.viewItem} className={classes.disabled}/>
        </div>
    )
}

export default ListMenu;