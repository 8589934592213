import {
    call, fork, put, take, takeEvery, all
  } from 'redux-saga/effects';
import { dashboardSetWorkerContextSuccess } from 'redux/actions/dashboardActions';
// import history from '../../utils/history';
// import { AUTH_LOGIN_REQUEST } from 'redux/constants/authConstants';
import pipelines from 'services';
import { DASHBOARD_SET_WORKER_CONTEXT } from 'redux/constants/dashboardConstants';
import { authLoginRequestSuccess } from 'redux/actions/authActions';

function* setWorkerContextSaga(action: any) {
    try {
        const data = (yield call(pipelines.authPipeline.authLoginPermissions, action.bussofic)).data;
        localStorage.setItem('currentCompany', action.company);
        localStorage.setItem('currentDivision', action.division);
        localStorage.setItem('currentBussOfic', action.bussofic);
        localStorage.setItem('currentFiscyear', action.fiscyear);
        yield put(dashboardSetWorkerContextSuccess())
        const tables = JSON.parse(localStorage.getItem('tables'));
        tables.menu = data.data.tables.menu;
        localStorage.setItem('user', JSON.stringify(data.data.onlyOne));
        localStorage.setItem('token', data.data.onlyOne.usrtoken);
        localStorage.setItem('tables', JSON.stringify(tables));
        yield put(authLoginRequestSuccess());
        action.callback();
    } catch (error) {
        console.log(error);
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        // yield put(authLoginRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* authRootSaga() {
    yield all([
      takeEvery(DASHBOARD_SET_WORKER_CONTEXT, setWorkerContextSaga)
    ]);
}
  
const authSagas = [
    fork(authRootSaga),
];
  
export default authSagas;