import React, { useEffect } from 'react';
import ListView from 'penta-components/base/listView';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { familiesGetAllRecordsRequest, familiesListSelectItem, familiesListSelectAllItem, familiesActionFailed, familiesDeleteRequest } from 'penta-redux/actions/wharehouseProductsActions';
import history from '../../../utils/history';
import Button from 'penta-components/controls/button';
import ListMenu from 'penta-components/base/listMenu';
import Separator from 'penta-components/base/separator';
import Search from 'penta-components/controls/search';
import { createUseStyles } from 'react-jss';
import Modal from 'penta-components/base/modal';

const header = [
    { title: 'Codigo', key: 'famicode' },
    { title: 'Nombre', key: 'faminame' },
    { title: 'Categoria', key: 'ctgrcode' },
    { title: 'Cta. contable compras', key: 'purcacca' },
    { title: 'Cta. contable ventas', key: 'saleacca' },
]

const useStyles =  createUseStyles({
    container: {
        overflow: 'auto', 
        height: '100%', 
        display: 'flex', 
        flexDirection: 'column'
    },
    titleContainer: {
        flex: 1, 
        display: 'flex', 
        alignItems: 'baseline'
    },
    title: {
        fontFamily: '"Open Sans", sans-serif', 
        fontSize: '1.563rem', 
        fontWeight: 'bold', 
        color: '#0060A6', 
        marginRight: '3rem'
    },
    tabActive: {},
    tabs: {
        margin: '0 0.4rem', 
        padding: '0.4rem 0.5rem', 
        color: '#b9b9b9', 
        fontFamily: '"Open Sans", sans-serif', 
        fontWeight: 'bold', 
        fontSize: '0.875rem',
        cursor: 'pointer',
        '&$tabActive': {
            color: '#3B3B3B',
            borderBottom: '0.2rem solid #3B3B3B', 
        }
    },
    listContainer: {
        flex: 1,
        '& > div:first-child': {
            display: 'flex'
        }
    }
});

const ListFamilies = (props: any) => {
    const classes = useStyles();
    const amountJump = 20;
    const [showModal, setShowModal] = React.useState(false);
    const [typeModal, setTypeModal] = React.useState('');
    //===============================================================
    const [searchValue, setSearchValue] = React.useState('');
    const [take, setTake] = React.useState(amountJump);
    //===============================================================
    const [typing, setTyping] = React.useState(null);
    //===============================================================

    const agregar = () => {
        history.push('/papp/products/familias/agregar');
    };

    const editar = () => {
        const select = props.wharehouseProductsReducers.list.find((x: any) => x.select);
        if (select !== undefined)
            history.push('/papp/products/familias/editar/' + select.famicode + '/2024');
        else
            props.familiesActionFailed('Error: 0x0', 'No se ha selecionado un elemento para modificar');
    }

    const eliminar = () => {
        const select = props.wharehouseProductsReducers.list.filter((x: any) => x.select);
        const selectCorrelat = select.map((x: any) => { return x.famicode });
        if(select.length !== 0) {
            props.familiesDeleteRequest(selectCorrelat);
            handleCloseModal();
        }
        else
            props.familiesActionFailed('Error: 0x0', 'No se ha selecionado un elemento para eliminar');
    }

    const handleShowModal = (typeModal: string) => {
        setShowModal(true);
        setTypeModal(typeModal);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setTypeModal('');
    };

    const onChangeSearchValue = (e) => {
        const text = e.target.value;
        setSearchValue(text);
        if(typing) clearTimeout(typing)
        setTyping(
            setTimeout(() => {
                onSearch(text);
            }, 500)
        )
    }

    const onSearch = (typing?: string) => {
        const body = {
            fields: { 
                famicode: `${typing ?? searchValue}`,
                faminame: `${typing ?? searchValue}`
            },
            skip: 0,
            take: amountJump
        } as any

        props.familiesGetAllRecordsRequest(body);
        setTake(amountJump);
    }

    const callMoreItems = () => {
        const body = {
            fields: { 
                famicode: `${searchValue}`,
                faminame: `${searchValue}`
            },
            skip: 0,
            take: take + amountJump
        } as any

        props.familiesGetAllRecordsRequest(body);
        setTake(take + amountJump);
    }

    useEffect(() => {
        const { familiesGetAllRecordsRequest } = props;
        const body = {
            fields: { 
                famicode: `${searchValue}`,
                faminame: `${searchValue}`
            },
            skip: 0,
            take
        } as any

        familiesGetAllRecordsRequest(body);
    }, []);

    return (
        <div className={classes.container}>
            <div className={classes.titleContainer}>
                <div className={classes.title}>Familias</div>
                <div>
                    <span className={`${classes.tabs} ${classes.tabActive}`}>Lista</span>
                    <span className={classes.tabs}>Chart</span>
                    <span className={classes.tabs}>Historial</span>
                </div>
            </div>
            <div className={classes.listContainer}>
                <div>
                    <Search styleContainer={{flex: 1}} onChange={onChangeSearchValue} value={searchValue}/>
                    {/* <Select label="Año" labelPosition="left" style={{marginLeft: '1rem'}} data={
                        [{id: '2021', name: '2021'}, {id: '2020', name: '2020'}, {id: '2019', name: '2019'}]
                    } value={fiscyear} onChange={(value) => setFiscyear(value.id)}/> */}
                    {/* <Select label="Mes" labelPosition="left" style={{marginLeft: '1rem'}} data={months} value={acpemont} onChange={(value) => setAcpemont(value.id)}/>
                    <Select label="Moneda" labelPosition="left" style={{marginLeft: '1rem'}} data={
                        [{id: '00', name: '--Todos--'}, {id: '00000014', name: 'Soles'}, {id: '00000015', name: 'Dolares'}]
                    } value={state} onChange={(value) => setCurrency(value.id)}/> */}
                    &nbsp;&nbsp;
                    <Button type='primary' full={false} containerStyle={{height: 'auto'}} onClick={() => onSearch()}>Filtrar</Button>
                </div>
                <Separator marginBottom='1rem'/>
                <ListMenu
                    addItem={agregar}
                    editItem={editar}
                    deleteItem={() => handleShowModal('D')}
                />
                <Separator marginBottom='0.5rem'/>
                <ListView
                    header={header} body={props.wharehouseProductsReducers.list}
                    selectAction={props.familiesListSelectItem} 
                    selectAllAction={props.familiesListSelectAllItem}
                    total={props.wharehouseProductsReducers.total}
                    callMoreItems={callMoreItems}
                />
            </div>
            {
                showModal && typeModal === 'D' &&
                <Modal 
                    title="Penta ERP"
                    onClose={() => setShowModal(false)}
                    footer={
                        <React.Fragment>
                            <Button type='bad' full={false} onClick={handleCloseModal}>Cancelar</Button>
                            &nbsp;&nbsp;
                            <Button full={false} onClick={eliminar}>Aceptar</Button>
                        </React.Fragment>
                    }
                >
                    <React.Fragment>
                        <p>¿Desea eliminar los siguientes elemetos?</p>
                        {
                            props.wharehouseProductsReducers.list.filter((x: any) => x.select).map(item =>
                                <ul key={`${item.famicode}-${item.faminame}`} style={{margin: '1rem 2rem'}}>
                                    <li>{item.famicode}-{item.faminame}</li>
                                </ul>
                            )
                        }
                    </React.Fragment>
                </Modal>
            }
        </div>
    );
}

const wharehouseProductsReducers = 'wharehouseProductsReducers';
const dashboardReducers = 'dashboardReducers';

const mapStateToProps = (state: any) => ({
    wharehouseProductsReducers: state.get(wharehouseProductsReducers),
    dashboardReducers: state.get(dashboardReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        familiesGetAllRecordsRequest,
        familiesListSelectItem,
        familiesListSelectAllItem,
        familiesActionFailed,
        familiesDeleteRequest
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ListFamilies);