import { 
    AFP_LIST_REQUEST,
    AFP_LIST_REQUEST_FAILED,
    AFP_LIST_REQUEST_SUCCESS,
    COMMISSIS_ACTION_FAILED,
    COMMISSIS_DELETE_REQUEST,
    COMMISSIS_DELETE_REQUEST_FAILED,
    COMMISSIS_DELETE_REQUEST_SUCCESS,
    COMMISSIS_GET_ALL_RECORDS_REQUEST, 
    COMMISSIS_GET_ALL_RECORDS_REQUEST_FAILED, 
    COMMISSIS_GET_ALL_RECORDS_REQUEST_SUCCESS, 
    COMMISSIS_INIT_UPDATE_FORM_REQUEST, 
    COMMISSIS_INIT_UPDATE_FORM_REQUEST_FAILED, 
    COMMISSIS_INIT_UPDATE_FORM_REQUEST_SUCCESS, 
    COMMISSIS_INSERT_REQUEST, 
    COMMISSIS_INSERT_REQUEST_FAILED, 
    COMMISSIS_INSERT_REQUEST_SUCCESS, 
    COMMISSIS_LIST_SELECT_ALL_ITEM, 
    COMMISSIS_LIST_SELECT_ITEM, 
    COMMISSIS_UPDATE_REQUEST, 
    COMMISSIS_UPDATE_REQUEST_FAILED, 
    COMMISSIS_UPDATE_REQUEST_SUCCESS, 
    CONCEPTS_ACTION_FAILED, 
    CONCEPTS_DELETE_REQUEST, 
    CONCEPTS_DELETE_REQUEST_FAILED, 
    CONCEPTS_DELETE_REQUEST_SUCCESS, 
    CONCEPTS_GET_ALL_RECORDS_REQUEST, 
    CONCEPTS_GET_ALL_RECORDS_REQUEST_FAILED, 
    CONCEPTS_GET_ALL_RECORDS_REQUEST_SUCCESS, 
    CONCEPTS_INIT_FORM_REQUEST, 
    CONCEPTS_INIT_FORM_REQUEST_FAILED, 
    CONCEPTS_INIT_FORM_REQUEST_SUCCESS, 
    CONCEPTS_INIT_UPDATE_FORM_REQUEST, 
    CONCEPTS_INIT_UPDATE_FORM_REQUEST_FAILED, 
    CONCEPTS_INIT_UPDATE_FORM_REQUEST_SUCCESS, 
    CONCEPTS_INSERT_REQUEST, 
    CONCEPTS_INSERT_REQUEST_FAILED, 
    CONCEPTS_INSERT_REQUEST_SUCCESS, 
    CONCEPTS_LIST_SELECT_ALL_ITEM, 
    CONCEPTS_LIST_SELECT_ITEM, 
    CONCEPTS_UPDATE_REQUEST, 
    CONCEPTS_UPDATE_REQUEST_FAILED, 
    CONCEPTS_UPDATE_REQUEST_SUCCESS, 
    CTS_DEPOSITS_REQUEST, 
    CTS_DEPOSITS_REQUEST_FAILED, 
    CTS_DEPOSITS_REQUEST_SUCCESS, 
    EMPLOYES_ACTION_FAILED, 
    EMPLOYES_DELETE_REQUEST, 
    EMPLOYES_DELETE_REQUEST_FAILED, 
    EMPLOYES_DELETE_REQUEST_SUCCESS, 
    EMPLOYES_GET_ALL_RECORDS_REQUEST, 
    EMPLOYES_GET_ALL_RECORDS_REQUEST_FAILED, 
    EMPLOYES_GET_ALL_RECORDS_REQUEST_SUCCESS, 
    EMPLOYES_INIT_FORM_REQUEST, 
    EMPLOYES_INIT_FORM_REQUEST_FAILED, 
    EMPLOYES_INIT_FORM_REQUEST_SUCCESS, 
    EMPLOYES_INIT_UPDATE_FORM_REQUEST, 
    EMPLOYES_INIT_UPDATE_FORM_REQUEST_FAILED, 
    EMPLOYES_INIT_UPDATE_FORM_REQUEST_SUCCESS, 
    EMPLOYES_INSERT_REQUEST, 
    EMPLOYES_INSERT_REQUEST_FAILED, 
    EMPLOYES_INSERT_REQUEST_SUCCESS, 
    EMPLOYES_LIST_SELECT_ALL_ITEM, 
    EMPLOYES_LIST_SELECT_ITEM, 
    EMPLOYES_UPDATE_REQUEST, 
    EMPLOYES_UPDATE_REQUEST_FAILED, 
    EMPLOYES_UPDATE_REQUEST_SUCCESS, 
    EMPLOYE_PAYROLL_ACCUMULATED_REQUEST, 
    EMPLOYE_PAYROLL_ACCUMULATED_REQUEST_FAILED, 
    EMPLOYE_PAYROLL_ACCUMULATED_REQUEST_SUCCESS, 
    EMPLOYE_PAYROLL_REQUEST, 
    EMPLOYE_PAYROLL_REQUEST_FAILED, 
    EMPLOYE_PAYROLL_REQUEST_SUCCESS, 
    EMPLOYE_VOUCHER_ALL_REQUEST, 
    EMPLOYE_VOUCHER_ALL_REQUEST_FAILED, 
    EMPLOYE_VOUCHER_ALL_REQUEST_SUCCESS, 
    EMPLOYE_VOUCHER_REQUEST, 
    EMPLOYE_VOUCHER_REQUEST_FAILED, 
    EMPLOYE_VOUCHER_REQUEST_SUCCESS, 
    LABORER_PAYROLL_ACCUMULATED_REQUEST, 
    LABORER_PAYROLL_ACCUMULATED_REQUEST_FAILED, 
    LABORER_PAYROLL_ACCUMULATED_REQUEST_SUCCESS, 
    LABORER_PAYROLL_REQUEST, 
    LABORER_PAYROLL_REQUEST_FAILED, 
    LABORER_PAYROLL_REQUEST_SUCCESS, 
    PAYROLL_ACTION_FAILED, 
    PAYROLL_DELETE_REQUEST, 
    PAYROLL_DELETE_REQUEST_FAILED, 
    PAYROLL_DELETE_REQUEST_SUCCESS, 
    PAYROLL_GET_ALL_RECORDS_REQUEST, 
    PAYROLL_GET_ALL_RECORDS_REQUEST_FAILED, 
    PAYROLL_GET_ALL_RECORDS_REQUEST_SUCCESS, 
    PAYROLL_INIT_FORM_REQUEST, 
    PAYROLL_INIT_FORM_REQUEST_FAILED, 
    PAYROLL_INIT_FORM_REQUEST_SUCCESS, 
    PAYROLL_INIT_UPDATE_FORM_REQUEST, 
    PAYROLL_INIT_UPDATE_FORM_REQUEST_FAILED, 
    PAYROLL_INIT_UPDATE_FORM_REQUEST_SUCCESS, 
    PAYROLL_INSERT_REQUEST, 
    PAYROLL_INSERT_REQUEST_FAILED, 
    PAYROLL_INSERT_REQUEST_SUCCESS, 
    PAYROLL_LIST_SELECT_ALL_ITEM, 
    PAYROLL_LIST_SELECT_ITEM, 
    PAYROLL_UPDATE_REQUEST, 
    PAYROLL_UPDATE_REQUEST_FAILED, 
    PAYROLL_UPDATE_REQUEST_SUCCESS, 
    PDT_STAFF_REQUEST, 
    PDT_STAFF_REQUEST_FAILED, 
    PDT_STAFF_REQUEST_SUCCESS, 
    WEEKCALES_ACTION_FAILED, 
    WEEKCALES_DELETE_REQUEST, 
    WEEKCALES_DELETE_REQUEST_FAILED, 
    WEEKCALES_DELETE_REQUEST_SUCCESS, 
    WEEKCALES_GET_ALL_RECORDS_REQUEST, 
    WEEKCALES_GET_ALL_RECORDS_REQUEST_FAILED, 
    WEEKCALES_GET_ALL_RECORDS_REQUEST_SUCCESS, 
    WEEKCALES_INIT_UPDATE_FORM_REQUEST, 
    WEEKCALES_INIT_UPDATE_FORM_REQUEST_FAILED, 
    WEEKCALES_INIT_UPDATE_FORM_REQUEST_SUCCESS, 
    WEEKCALES_INSERT_REQUEST,
    WEEKCALES_INSERT_REQUEST_FAILED,
    WEEKCALES_INSERT_REQUEST_SUCCESS,
    WEEKCALES_LIST_SELECT_ALL_ITEM,
    WEEKCALES_LIST_SELECT_ITEM,
    WEEKCALES_UPDATE_REQUEST,
    WEEKCALES_UPDATE_REQUEST_FAILED,
    WEEKCALES_UPDATE_REQUEST_SUCCESS
} from '../constants/humanResourcesConstants';

const initialState: any = {
    tables: { 
        planAccount: [], 
        departamentos: [],
        provincias: [], 
        distritos: [], 
        prl_definiti: [], 
        grl_definiti: [], 
        prl_commissis: { commissi: "[]" }, 
        acc_planacco: [], 
        prl_concepts: [],
        prl_commissi: [],
        prl_employee: [],
        prl_weekcale: []
    },
    onlyOne: null,
    list: [],
    total: 0,
    loading: false,
    failed: false,
    failedTitle: null,
    failedContent: null
};

export default function humanResourcesReducer(state = initialState, action: any = {}) {
    switch(action.type) {
        case EMPLOYES_INIT_FORM_REQUEST:
        case CONCEPTS_INIT_FORM_REQUEST:
        case PAYROLL_INIT_FORM_REQUEST:
            return {
                ...state,
                loading: true
            };
        case EMPLOYES_INIT_FORM_REQUEST_SUCCESS:
        case CONCEPTS_INIT_FORM_REQUEST_SUCCESS:
        case PAYROLL_INIT_FORM_REQUEST_SUCCESS:
            return {
                ...state,
                tables: { ...state.tables, ...action.tables },
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case EMPLOYES_INIT_FORM_REQUEST_FAILED:
        case CONCEPTS_INIT_FORM_REQUEST_FAILED:
        case PAYROLL_INIT_FORM_REQUEST_FAILED:
            return {
                ...initialState,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case COMMISSIS_INIT_UPDATE_FORM_REQUEST:
        case WEEKCALES_INIT_UPDATE_FORM_REQUEST:
        case EMPLOYES_INIT_UPDATE_FORM_REQUEST:
        case CONCEPTS_INIT_UPDATE_FORM_REQUEST:
        case PAYROLL_INIT_UPDATE_FORM_REQUEST:
            return {
                ...state,
                loading: true
            };
        case COMMISSIS_INIT_UPDATE_FORM_REQUEST_SUCCESS:
        case WEEKCALES_INIT_UPDATE_FORM_REQUEST_SUCCESS:
        case EMPLOYES_INIT_UPDATE_FORM_REQUEST_SUCCESS:
        case CONCEPTS_INIT_UPDATE_FORM_REQUEST_SUCCESS:
        case PAYROLL_INIT_UPDATE_FORM_REQUEST_SUCCESS:
            return {
                ...state,
                tables: { ...state.tables, ...action.tables },
                onlyOne: action.onlyOne,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case COMMISSIS_INIT_UPDATE_FORM_REQUEST_FAILED:
        case WEEKCALES_INIT_UPDATE_FORM_REQUEST_FAILED:
        case EMPLOYES_INIT_UPDATE_FORM_REQUEST_FAILED:
        case CONCEPTS_INIT_UPDATE_FORM_REQUEST_FAILED:
        case PAYROLL_INIT_UPDATE_FORM_REQUEST_FAILED:
            return {
                ...initialState,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case COMMISSIS_GET_ALL_RECORDS_REQUEST:
        case WEEKCALES_GET_ALL_RECORDS_REQUEST:
        case EMPLOYES_GET_ALL_RECORDS_REQUEST:
        case CONCEPTS_GET_ALL_RECORDS_REQUEST:
        case PAYROLL_GET_ALL_RECORDS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case COMMISSIS_GET_ALL_RECORDS_REQUEST_SUCCESS:
        case WEEKCALES_GET_ALL_RECORDS_REQUEST_SUCCESS:
        case EMPLOYES_GET_ALL_RECORDS_REQUEST_SUCCESS:
        case CONCEPTS_GET_ALL_RECORDS_REQUEST_SUCCESS:
        case PAYROLL_GET_ALL_RECORDS_REQUEST_SUCCESS:
            return {
                ...state,
                list: action.list,
                total: action.total,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case COMMISSIS_GET_ALL_RECORDS_REQUEST_FAILED:
        case WEEKCALES_GET_ALL_RECORDS_REQUEST_FAILED:
        case EMPLOYES_GET_ALL_RECORDS_REQUEST_FAILED:
        case CONCEPTS_GET_ALL_RECORDS_REQUEST_FAILED:
        case PAYROLL_GET_ALL_RECORDS_REQUEST_FAILED:
            return {
                ...initialState,
                list: [],
                total: 0,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case COMMISSIS_INSERT_REQUEST:
        case WEEKCALES_INSERT_REQUEST:
        case EMPLOYES_INSERT_REQUEST:
        case CONCEPTS_INSERT_REQUEST:
        case PAYROLL_INSERT_REQUEST:
            return {
                ...state,
                loading: true
            };
        case COMMISSIS_INSERT_REQUEST_SUCCESS:
        case WEEKCALES_INSERT_REQUEST_SUCCESS:
        case EMPLOYES_INSERT_REQUEST_SUCCESS:
        case CONCEPTS_INSERT_REQUEST_SUCCESS:
        case PAYROLL_INSERT_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case COMMISSIS_INSERT_REQUEST_FAILED:
        case WEEKCALES_INSERT_REQUEST_FAILED:
        case EMPLOYES_INSERT_REQUEST_FAILED:
        case CONCEPTS_INSERT_REQUEST_FAILED:
        case PAYROLL_INSERT_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case COMMISSIS_UPDATE_REQUEST:
        case WEEKCALES_UPDATE_REQUEST:
        case EMPLOYES_UPDATE_REQUEST:
        case CONCEPTS_UPDATE_REQUEST:
        case PAYROLL_UPDATE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case COMMISSIS_UPDATE_REQUEST_SUCCESS:
        case WEEKCALES_UPDATE_REQUEST_SUCCESS:
        case EMPLOYES_UPDATE_REQUEST_SUCCESS:
        case CONCEPTS_UPDATE_REQUEST_SUCCESS:
        case PAYROLL_UPDATE_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case COMMISSIS_UPDATE_REQUEST_FAILED:
        case WEEKCALES_UPDATE_REQUEST_FAILED:
        case EMPLOYES_UPDATE_REQUEST_FAILED:
        case CONCEPTS_UPDATE_REQUEST_FAILED:
        case PAYROLL_UPDATE_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case COMMISSIS_DELETE_REQUEST:
        case WEEKCALES_DELETE_REQUEST:
        case EMPLOYES_DELETE_REQUEST:
        case CONCEPTS_DELETE_REQUEST:
        case PAYROLL_DELETE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case COMMISSIS_DELETE_REQUEST_SUCCESS:
        case WEEKCALES_DELETE_REQUEST_SUCCESS:
        case EMPLOYES_DELETE_REQUEST_SUCCESS:
        case CONCEPTS_DELETE_REQUEST_SUCCESS:
        case PAYROLL_DELETE_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case COMMISSIS_DELETE_REQUEST_FAILED:
        case WEEKCALES_DELETE_REQUEST_FAILED:
        case EMPLOYES_DELETE_REQUEST_FAILED:
        case CONCEPTS_DELETE_REQUEST_FAILED:
        case PAYROLL_DELETE_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case COMMISSIS_LIST_SELECT_ITEM:
        case WEEKCALES_LIST_SELECT_ITEM:
        case EMPLOYES_LIST_SELECT_ITEM:
        case CONCEPTS_LIST_SELECT_ITEM:
        case PAYROLL_LIST_SELECT_ITEM:
            const newList = state.list;
            newList[action.key].select = !newList[action.key].select;
            return {
                ...state,
                list: newList
            };
        case COMMISSIS_LIST_SELECT_ALL_ITEM:
        case WEEKCALES_LIST_SELECT_ALL_ITEM:
        case EMPLOYES_LIST_SELECT_ALL_ITEM:
        case CONCEPTS_LIST_SELECT_ALL_ITEM:
        case PAYROLL_LIST_SELECT_ALL_ITEM:
            const newListAll = state.list;
            for (const item of newListAll) {
                item.select = action.checked
            }
            return {
                ...state,
                list: newListAll
            };
        case COMMISSIS_ACTION_FAILED:
        case WEEKCALES_ACTION_FAILED:
        case EMPLOYES_ACTION_FAILED:
        case CONCEPTS_ACTION_FAILED:
        case PAYROLL_ACTION_FAILED:
            return {
                ...state,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case EMPLOYE_PAYROLL_REQUEST:
        case LABORER_PAYROLL_REQUEST:
        case LABORER_PAYROLL_ACCUMULATED_REQUEST:
        case EMPLOYE_PAYROLL_ACCUMULATED_REQUEST:
        case EMPLOYE_VOUCHER_REQUEST:
        case EMPLOYE_VOUCHER_ALL_REQUEST:
        case AFP_LIST_REQUEST:
        case PDT_STAFF_REQUEST:
        case CTS_DEPOSITS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case EMPLOYE_PAYROLL_REQUEST_SUCCESS:
        case LABORER_PAYROLL_REQUEST_SUCCESS:
        case LABORER_PAYROLL_ACCUMULATED_REQUEST_SUCCESS:
        case EMPLOYE_PAYROLL_ACCUMULATED_REQUEST_SUCCESS:
        case EMPLOYE_VOUCHER_REQUEST_SUCCESS:
        case EMPLOYE_VOUCHER_ALL_REQUEST_SUCCESS:
        case AFP_LIST_REQUEST_SUCCESS:
        case PDT_STAFF_REQUEST_SUCCESS:
        case CTS_DEPOSITS_REQUEST_SUCCESS:
            return {
                ...state,
                onlyOne: action.onlyOne,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case EMPLOYE_PAYROLL_REQUEST_FAILED:
        case LABORER_PAYROLL_REQUEST_FAILED:
        case LABORER_PAYROLL_ACCUMULATED_REQUEST_FAILED:
        case EMPLOYE_PAYROLL_ACCUMULATED_REQUEST_FAILED:
        case EMPLOYE_VOUCHER_REQUEST_FAILED:
        case EMPLOYE_VOUCHER_ALL_REQUEST_FAILED:
        case AFP_LIST_REQUEST_FAILED:
        case PDT_STAFF_REQUEST_FAILED:
        case CTS_DEPOSITS_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        default:
            return state;
    }
}