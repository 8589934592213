import React from 'react';
import { Backdrop, Button, ButtonGroup, Checkbox, CircularProgress, createStyles, FormControl, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Select, TextField, Theme, Tooltip, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { expenseAnalysisNineRequest } from 'penta-redux/actions/accountantActions';
import history from '../../../../utils/history';
import './../reports.scss'
import FormFooter from 'penta-components/base/formFooter';
import { MessageBox } from 'penta-components/base/messageBox';
import { categoryEntity } from 'models/wharehouseProductsModels';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PdfView from 'penta-components/base/pdfView';
// import * as pdfMake from "pdfmake/build/pdfmake";
// import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
import * as XLSX from 'sheetjs-style';
import ScreenLoader from 'penta-components/base/screenLoader';

let pdfMake = require("pdfmake/build/pdfmake");
let saveAs = require('file-saver').saveAs;

const meses = [
    { key: '01', value: 'Enero' },
    { key: '02', value: 'Febrero' },
    { key: '03', value: 'Marzo' },
    { key: '04', value: 'Abril' },
    { key: '05', value: 'Mayo' },
    { key: '06', value: 'Junio' },
    { key: '07', value: 'Julio' },
    { key: '08', value: 'Agosto' },
    { key: '09', value: 'Septiembre' },
    { key: '10', value: 'Octubre' },
    { key: '11', value: 'Noviembre' },
    { key: '12', value: 'Diciembre' }
]

const useStyles = (theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        formControl: {
            margin: 0,
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    });

interface FormcategorysState {
    title: string
    year: string
    month: string,
    base64Pdf: string
}

class ReportExpenseAnalysisNine extends React.Component<any, FormcategorysState> {

    constructor(props: any) {
        super(props);
        this.state = {
            title: 'Reporte de Analisis de Gastos Clase 9',
            year: moment().format('yyyy').toString(),
            month: moment().format('MM').toString(),
            base64Pdf: ''
        }
    }

    onChangeRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            ...value
        }))
    }

    onGeneratePdf = () => {
        const data = this.props.accountantReducers.onlyOne;
        const { year } = this.state;
        const { dashboardReducers } = this.props;
        const tables = JSON.parse(localStorage.getItem('tables'));
        const company = tables.companys.find((x: any) => x.taxident === dashboardReducers.currentCompany);
        const division = company.lst_grl_division.find((x: any) => x.divicode === dashboardReducers.currentDivision);

        const pdfObject: any = {
            pageSize: 'A4',
            pageOrientation: 'landscape',
            pageMargins: [ 20, 80, 20, 20 ],
            header: [
                {
                    text: ' ', lineHeight: 2
                },
                {
                    margin: [20, 0, 20, 0],
                    text: 'SERVICENTRO LAS AMARICAS',
                    style: {
                        bold: true,
                        fontSize: 9
                    }
                },
                {
                    margin: [20, 5, 20, 0],
                    text: 'ANALIS DE GASTOS ' + year,
                    style: {
                        bold: true,
                        fontSize: 9,
                        alignment: 'center'
                    }
                }
            ],
            content: [
                {
                    table: {
                        headerRows: 1,
                        widths: [],
                        body: []
                    }
                },
            ],
            styles: {
                headerTitle: {
                    bold: true,
                    fontSize: 7,
                    lineHeight: 1.2
                },
                table: {
                    layout: 'headerLineOnly',
                    fontSize: 6,
                    alignment: 'center'
                },
                subTable: {
                    layout: 'headerLineOnly',
                    fontSize: 6,
                    alignment: 'left'
                },
                tableDetailLeft: {
                    layout: 'headerLineOnly',
                    fontSize: 5.5,
                    alignment: 'left'
                },
                tableDetailRight: {
                    layout: 'headerLineOnly',
                    fontSize: 5.5,
                    alignment: 'right'
                },
                tableDetailRightBold: {
                    layout: 'headerLineOnly',
                    fontSize: 5.5,
                    alignment: 'right',
                    bold: true
                }
            },
            defaultStyle: {
                columnGap: 20,
            },
        }

        const unicodopRead: any = [];

        
        pdfObject.content[0].table.widths = [ 30, 120, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40 ];

        const tableBody: any = [
            [ 
                { text: '', style: 'table', colSpan: 1, border: [false, false, false, true] },
                { text: '', style: 'table', colSpan: 1, border: [false, false, false, true] },
                { text: 'ENERO', style: 'table', colSpan: 1, border: [false, false, false, true] },
                { text: 'FEBRERO', style: 'table', colSpan: 1, border: [false, false, false, true] },
                { text: 'MARZO', style: 'table', colSpan: 1, border: [false, false, false, true] },
                { text: 'ABRIL', style: 'table', colSpan: 1, border: [false, false, false, true] },
                { text: 'MAYO', style: 'table', colSpan: 1, border: [false, false, false, true] },
                { text: 'JUNIO', style: 'table', colSpan: 1, border: [false, false, false, true] },
                { text: 'JULIO', style: 'table', colSpan: 1, border: [false, false, false, true] },
                { text: 'AGOSTO', style: 'table', colSpan: 1, border: [false, false, false, true] },
                { text: 'SEPTIEMBRE', style: 'table', colSpan: 1, border: [false, false, false, true] },
                { text: 'OCTUBRE', style: 'table', colSpan: 1, border: [false, false, false, true] },
                { text: 'NOVIEMBRE', style: 'table', colSpan: 1, border: [false, false, false, true] },
                { text: 'DICIEMBRE', style: 'table', colSpan: 1, border: [false, false, false, true] },
                { text: 'TOTAL', style: 'table', colSpan: 1, border: [false, false, false, true] },
            ],
        ]

        for (const iterator of data) {
            if (!unicodopRead.includes(iterator.parenacc)) {

                const rows = data.filter((x: any) => x.parenacc === iterator.parenacc);
            
                const push = [
                    { text: iterator.parenacc, style: 'tableDetailLeft', border: [false, true, false, true] },
                    { text: iterator.parenden, style: 'tableDetailLeft', border: [false, true, false, true] }
                ];

                for(let i = 1; i <= 12; i++) {
                    const row = rows.find((x: any) => x.balacode === `${year}${i.toString().padStart(2, '0')}`) || { partotal: '0' };
                    push.push({ text: +row.partotal > 0 ? String.formatNumber.new(row.partotal) : '', style: 'tableDetailRight', border: [false, true, false, true] });
                }

                push.push({ text: +iterator.fintotal > 0 ? String.formatNumber.new(iterator.fintotal) : '', style: 'tableDetailRight', border: [false, true, false, true] });

                tableBody.push(push);

                const accRead = [];

                for (const row of rows) {
                    if(!accRead.includes(row.accaccou)) {
                        const accaccous = rows.filter((x: any) => x.accaccou === row.accaccou);
                        const _push = [
                            { text: row.accaccou, style: 'tableDetailLeft', border: [false, false, false, false] },
                            { text: row.denomina, style: 'tableDetailLeft', border: [false, false, false, false] }
                        ];
    
                        for(let i = 1; i <= 12; i++) {
                            const _row = accaccous.find((x: any) => x.balacode === `${year}${i.toString().padStart(2, '0')}`) || { totalbal: '0' };
                            _push.push({ text: +_row.totalbal > 0 ? String.formatNumber.new(_row.totalbal) : '', style: 'tableDetailRight', border: [false, false, false, false] });
                        }
    
                        _push.push({ text: +row.gentotal > 0 ? String.formatNumber.new(row.gentotal) : '', style: 'tableDetailRight', border: [false, false, false, false] });
    
                        tableBody.push(_push);
                        accRead.push(row.accaccou);
                    }
                }

                unicodopRead.push(iterator.parenacc);
            }
        }

        pdfObject.content[0].table.body = tableBody;

        pdfMake.fonts = {
            Roboto: {
                normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
                bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
                italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
                bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
              },
        }
        const pdfDocGenerator = pdfMake.createPdf(pdfObject);
        pdfDocGenerator.getDataUrl((data: any) => {
            // console.log(data);
            this.setState({
                base64Pdf: data
            })
        });
    }

    onGenerateExcel = () => {
        try {
            const { year } = this.state;
            const data = this.props.accountantReducers.onlyOne;

            const tableBody: any = [
                { 
                    A: 'SERVICENTRO LAS AMARICAS',
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null,
                    G: null,
                    H: null,
                    I: null,
                    J: null,
                    K: null,
                    L: null,
                    M: null,
                    N: null,
                    O: null
                },
                { 
                    A: `ANALIS DE GASTOS ${year}`,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null,
                    G: null,
                    H: null,
                    I: null,
                    J: null,
                    K: null,
                    L: null,
                    M: null,
                    N: null,
                    O: null
                },
                { 
                    A: null,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null,
                    G: null,
                    H: null,
                    I: null,
                    J: null,
                    K: null,
                    L: null,
                    M: null,
                    N: null,
                    O: null
                },
                { 
                    A: '',
                    B: '',
                    C: 'ENERO',
                    D: 'FEBRERO',
                    E: 'MARZO',
                    F: 'ABRIL',
                    G: 'MAYO',
                    H: 'JUNIO',
                    I: 'JULIO',
                    J: 'AGOSTO',
                    K: 'SEPTIEMBRE',
                    L: 'OCTUBRE',
                    M: 'NOVIEMBRE',
                    N: 'DICIEMBRE',
                    O: 'TOTAL'
                }
            ]

            const header = [
                'A',
                'B',
                'C',
                'D',
                'E',
                'F',
                'G',
                'H',
                'I',
                'J',
                'K',
                'L',
                'M',
                'N',
                'O'
            ];

            const unicodopRead: any = [];
            const cutRows: Array<number> = [];

            for (const iterator of data) {
                if (!unicodopRead.includes(iterator.parenacc)) {
    
                    const rows = data.filter((x: any) => x.parenacc === iterator.parenacc);
                
                    const push = {
                        A: iterator.parenacc,
                        B: iterator.parenden
                    }
    
                    for(let i = 1; i <= 12; i++) {
                        const row = rows.find((x: any) => x.balacode === `${year}${i.toString().padStart(2, '0')}`) || { partotal: '0' };
                        push[header[i + 1]] = +row.partotal > 0 ? String.formatNumber.new(row.partotal) : '';
                    }
    
                    push[header[header.length - 1]] = +iterator.fintotal > 0 ? String.formatNumber.new(iterator.fintotal) : '';
    
                    tableBody.push(push);
                    cutRows.push(tableBody.length);
    
                    const accRead = [];
    
                    for (const row of rows) {
                        if(!accRead.includes(row.accaccou)) {
                            const accaccous = rows.filter((x: any) => x.accaccou === row.accaccou);
                            const _push = {
                                A: row.accaccou,
                                B: row.denomina
                            };
        
                            for(let i = 1; i <= 12; i++) {
                                const _row = accaccous.find((x: any) => x.balacode === `${year}${i.toString().padStart(2, '0')}`) || { totalbal: '0' };
                                _push[header[i + 1]] = +_row.totalbal > 0 ? String.formatNumber.new(_row.totalbal) : '';
                            }
        
                            _push[header[header.length - 1]] = +row.gentotal > 0 ? String.formatNumber.new(row.gentotal) : '';
        
                            tableBody.push(_push);
                            accRead.push(row.accaccou);
                        }
                    }
    
                    unicodopRead.push(iterator.parenacc);
                }
            }

            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(tableBody, {header, skipHeader: true});
            ws["!cols"] = [
                {wpx:50},{wpx:200},{wpx:50},{wpx:50},{wpx:50},
                {wpx:50},{wpx:50},{wpx:50},{wpx:50},{wpx:50},
                {wpx:50},{wpx:50},{wpx:50},{wpx:50},{wpx:50},
                {wpx:50},{wpx:50}
            ];
            ws['!rows'] = [
                {hpx: 16},{hpx: 18},{hpx: 16},{hpx: 16}
            ];

            const keys = Object.keys(ws);
            const rowsCount = tableBody.length;

            for (const iterator of keys) {
                if(!isNaN(parseInt(ws[iterator].v)) && !iterator.match(/^(A)[0-9]+$/g)) {
                    ws[iterator].v = ws[iterator].v.replace(/,/g, '');
                    ws[iterator].t = 'n';
                    ws[iterator].z = '#,##0.00';
                }
                if(iterator !== '!ref' && iterator !== '!cols' && iterator !== '!rows') {
                    ws[iterator].s = {
                        font: {
                            name: 'Tahoma',
                            sz: 8
                        }
                    };
                    const row = +(iterator.match(/\d+/)[0]);
                    if(cutRows.includes(row)) {
                        ws[iterator].s.border = {
                            ...ws[iterator].s.border,
                            bottom: { style: 'thin', color: 'black'},
                            top: { style: 'thin', color: 'black'}
                        }
                    }
                }
            }

            for (let index = 5; index <= rowsCount; index++) {
                ws['!rows'].push({hpx: 12})
            }

            ws['A1'].s = {font:{name: 'Tahoma', sz: 8, bold: true}}
            ws['A2'].s = {font:{name: 'Tahoma', sz: 15, bold: true}, alignment: { wrapText: true, vertical: 'center', horizontal: 'center'}}
            ws['A3'].s = {font:{name: 'Tahoma', sz: 8, bold: true}}
            ws['A4'].s = {font:{name: 'Tahoma', sz: 8, bold: true}}

            for (const iterator of header) {
                const key1 = iterator + "4";
                ws[key1].s = {
                    ...ws[key1].s, 
                    font: {
                        ...ws[key1].s.font,
                        bold: true
                    }
                }
            };

            ws["!merges"] = [
                { s: { r: 1, c: 0 }, e: { r: 1, c: 14 } },
            ];

            XLSX.utils.book_append_sheet(wb, ws, "Analisis de Gastos");

            var wbout = XLSX.write(wb, {bookType:'xlsx', type: 'binary', cellStyles: true});

            const s2ab = function(s) {
                var buf = new ArrayBuffer(s.length);
                var view = new Uint8Array(buf);
                for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
                return buf;
            }

            saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), `analisis-gastos-${year}.xlsx`);

        } catch(err) {
            console.log(err);
        }
    }

    render() {

        const { title, year, month, base64Pdf } = this.state;
        const { loading, list, tables, failed, failedTitle, failedContent } = this.props.accountantReducers;
        const { classes } = this.props;

        return (
            <div style={{overflow: 'auto', height: '100%'}}>
                <Paper className='form__container'>
                    <Typography variant='h5'>{ title }</Typography>
                    <br/>
                    { failed && <MessageBox title={failedTitle} content={failedContent}/> }
                    <div style={{ display: 'flex' }}>
                        <form noValidate autoComplete="off" style={{ flex: 1, paddingRight: '15px', paddingTop: '2em' }}>
                            <Grid container spacing={2} >
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_año">Año</InputLabel>
                                            <Select
                                                labelId="lbl_año"
                                                id="year"
                                                className='select'
                                                label="Año"
                                                fullWidth
                                                value={ year }
                                                onChange={(e: any) => this.onChangeRoot({'year': e.target.value})}
                                            >
                                                <MenuItem value='2030'>2030</MenuItem>
                                                <MenuItem value='2029'>2029</MenuItem>
                                                <MenuItem value='2028'>2028</MenuItem>
                                                <MenuItem value='2027'>2027</MenuItem>
                                                <MenuItem value='2026'>2026</MenuItem>
                                                <MenuItem value='2025'>2025</MenuItem>
                                                <MenuItem value='2026'>2026</MenuItem>
                                                <MenuItem value='2025'>2025</MenuItem>
                                                <MenuItem value='2024'>2024</MenuItem>
                                                <MenuItem value='2023'>2023</MenuItem>
                                                <MenuItem value='2022'>2022</MenuItem>
                                                <MenuItem value='2021'>2021</MenuItem>
                                                <MenuItem value='2020'>2020</MenuItem>
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_mes">Mes</InputLabel>
                                            <Select
                                                labelId="lbl_mes"
                                                id="month"
                                                className='select'
                                                label="Mes"
                                                fullWidth
                                                value={ month }
                                                onChange={(e: any) => this.onChangeRoot({'month': e.target.value})}
                                            >
                                                {
                                                    meses.map((x: any) => 
                                                        <MenuItem value={x.key} key={x.key}>{x.value}</MenuItem>
                                                    )
                                                }
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} style={{ paddingTop: '50px' }}>
                                    <Button variant="contained" fullWidth color="primary" onClick={() => { 
                                        const balacode = [];
                                        for(let i = 1; i <= +month; i++) {
                                            balacode.push(`${year}${i.toString().padStart(2, '0')}`)
                                        }
                                        this.props.expenseAnalysisNineRequest(year, balacode, this.onGeneratePdf) 
                                    }}>
                                        Generar Reporte PDF
                                    </Button>
                                </Grid>
                                <Grid item xs={12} style={{ paddingTop: '10px' }}>
                                    <Button variant="contained" fullWidth color="inherit" onClick={() => { 
                                        const balacode = [];
                                        for(let i = 1; i <= +month; i++) {
                                            balacode.push(`${year}${i.toString().padStart(2, '0')}`)
                                        }
                                        this.props.expenseAnalysisNineRequest(year, balacode, this.onGenerateExcel) 
                                    }}>
                                        Generar Reporte EXCEL
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                        <PdfView data={base64Pdf} style={{ flex: 2 }}/>
                    </div>
                    <br/>
                    {/* <FormFooter onCancel={ this.onCancel } onSubmit={ this.onSubmit }/> */}
                </Paper>
                <ScreenLoader enabled={loading}/>
            </div>
        );
    }
}

const accountantReducers = 'accountantReducers';
const dashboardReducers = 'dashboardReducers';

const mapStateToProps = (state: any) => ({
    accountantReducers: state.get(accountantReducers),
    dashboardReducers: state.get(dashboardReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        expenseAnalysisNineRequest
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(ReportExpenseAnalysisNine));