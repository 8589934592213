const serverUrl = process.env.PENTA_MASTER_SERVER_URL || `https://api.${window.location.hostname}/api/`;

export const CUSTOMERS_INIT_FORM = serverUrl + 'sales/customer/init-form/';
export const CUSTOMERS_INIT_UPDATE_FORM = serverUrl + 'sales/customer/init-update-form/';
export const CUSTOMERS_GET_ALL_RECORDS = serverUrl + 'sales/customer/get-all-records';
export const CUSTOMERS_INSERT = serverUrl + 'sales/customer/insert';
export const CUSTOMERS_UPDATE = serverUrl + 'sales/customer/update/';
export const CUSTOMERS_DELETE = serverUrl + 'sales/customer/delete?';

export const SALES_INIT_FORM = serverUrl + 'sales/sales/init-form';
export const SALES_INIT_UPDATE_FORM = serverUrl + 'sales/sales/init-update-form/';
export const SALES_UPLOAD_CODES = serverUrl + 'sales/sales/upload-codes?';
export const SALES_GET_ALL_RECORDS = serverUrl + 'sales/sales/get-all-records';
export const SALES_INSERT = serverUrl + 'sales/sales/insert';
export const SALES_INSERT_BATCH = serverUrl + 'sales/sales/insert-batch';
export const SALES_UPDATE = serverUrl + 'sales/sales/update/';
export const SALES_DELETE = serverUrl + 'sales/sales/delete?';
export const SALES_DELETE_UPLOAD_CODE = serverUrl + 'sales/sales/delete-by-upload-code?';
export const SALES_ANULATE = serverUrl + 'sales/sales/anulate?';

export const SALES_DETAILED_DIARY_BOOK = serverUrl + 'sales/reports/sales-detailed-diarybook/';
export const KARDEX = serverUrl + 'sales/reports/kardex/';
export const INVOICES_CHARGE = serverUrl + 'sales/reports/invoices-to-charge/';