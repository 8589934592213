import React from 'react';
import { AppBar, Backdrop, Box, Button, ButtonGroup, Checkbox, CircularProgress, createStyles, FormControl, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Select, Tab, Tabs, TextField, Theme, Tooltip, Typography, FormControlLabel, withStyles, TextareaAutosize, Snackbar } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { cashBanksMovementsInitFormRequest, cashBanksMovementsInitUpdateFormRequest, cashBanksMovementsInsertRequest, cashBanksMovementsUpdateRequest } from 'penta-redux/actions/financeActions';
import { currencyExchangeRateRequest } from 'penta-redux/actions/generalActions';
import history from '../../../utils/history';
import './cashBanksMovements.scss'
import FormFooter from 'penta-components/base/formFooter';
import { MessageBox } from 'penta-components/base/messageBox';
import { cashBankMovementEntity } from 'models/financeModels';
import moment from 'moment';
import { Alert, Autocomplete } from '@material-ui/lab';
import EditableCell from 'penta-components/base/editableCell';
import ScreenLoader from 'penta-components/base/screenLoader';

const meses = [
    { key: '01', value: 'Enero' },
    { key: '02', value: 'Febrero' },
    { key: '03', value: 'Marzo' },
    { key: '04', value: 'Abril' },
    { key: '05', value: 'Mayo' },
    { key: '06', value: 'Junio' },
    { key: '07', value: 'Julio' },
    { key: '08', value: 'Agosto' },
    { key: '09', value: 'Septiembre' },
    { key: '10', value: 'Octubre' },
    { key: '11', value: 'Noviembre' },
    { key: '12', value: 'Diciembre' }
]

const header = [
    "", 
    "Tipo Comprobante", 
    "Glosa", 
    "Fecha Operación", 
    "Tipo de Cambio", 
    "RUC/DNI Otros", 
    "Denominación",
    "Nro. Documento",
    "Moneda Doc",
    "Nro. Comp.", 
    "Tipo Doc Cancelación", 
    "Doc. Sustentatorio", 
    "Cuenta Contable",
    "Cto.Costo",
    "Cargo Soles",
    "Abono Soles",
    "Cargo Dolares",
    "Abono Dolares",
    "Importe Dif Cambio",
    "Cuenta",
];

const currentFiscyear = localStorage.getItem('currentFiscyear');

const useStyles = (theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        formControl: {
            margin: 0,
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    });

const defaultCashBankMovementEntity: cashBankMovementEntity = {
    yearexer: currentFiscyear,
    montexer: moment().format('MM'),
    weekexer: '1',
    pagenumb: 1,
    officode: '',
    idcsbank: 0,
    stardate: moment().format('YYYY-MM-DD'),
    endidate: moment().format('YYYY-MM-DD'),
    optotals: {
        '00000014': { debitamo: 0.00, assetamo: 0.00 },
        '00000015': { debitamo: 0.00, assetamo: 0.00 },
    },
    csb_cashbank: {
        idcsbank: 0,
        typecsbk: '',
        acconumb: null,
        denomina: '',
        currency: null,
        accaccou: null,
        fiscyear: null,
        accdenom: null,
        profiacc: null,
        prodenom: null,
        lostacco: null,
        losdenom: null,
        csb_balances: []
    },
    csb_csbkmdet: []
}

interface FormCashBanksMovementsState {
    cashBankMovementEntity: cashBankMovementEntity
    title: string
    dateoper: any
    curreval: any
    itemnumb: number
    snackbar: boolean
}

class FormCashBanksMovements extends React.Component<any, FormCashBanksMovementsState> {

    constructor(props: any) {
        super(props);
        defaultCashBankMovementEntity.csb_csbkmdet = [];
        this.state = {
            cashBankMovementEntity: defaultCashBankMovementEntity,
            title: 'Agregar Movimientos',
            dateoper: moment().format('YYYY-MM-DD'),
            curreval: 3.22,
            itemnumb: 0,
            snackbar: false
        }
    }

    onChangeRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            ...value
        }))
    }

    onChangeCashBankMovementRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            cashBankMovementEntity: {
                ...state.cashBankMovementEntity,
                ...value
            }
        }));
    }

    onCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        console.log('cancel')
        history.push('/papp/finance/movimientos-caja-bancos');
    }

    onContinueCallback = () => {
        const newCashBankEntity = defaultCashBankMovementEntity;
        newCashBankEntity.csb_csbkmdet = [];
        this.setState({
            cashBankMovementEntity: newCashBankEntity,
            itemnumb: 0,
            snackbar: true
        });
    }

    onSubmitContinue = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const { params } = this.props.match;
        if (params.yearexer !== undefined) {
            this.props.cashBanksMovementsUpdateRequest(this.state.cashBankMovementEntity, null);
        } else {
            this.props.cashBanksMovementsInsertRequest(this.state.cashBankMovementEntity, this.onContinueCallback);
        }
    }

    onSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const { params } = this.props.match;
        if (params.yearexer !== undefined) {
            this.props.cashBanksMovementsUpdateRequest(this.state.cashBankMovementEntity, null);
        } else {
            this.props.cashBanksMovementsInsertRequest(this.state.cashBankMovementEntity, null);
        }
    }

    callbackInitUpdate = (cashBankMovementEntity: cashBankMovementEntity) => {
        const csb_cashbank = this.props.financeReducers.tables.csb_cashbank.find((x: any) => x.idcsbank === cashBankMovementEntity.idcsbank);
        cashBankMovementEntity.stardate = moment(cashBankMovementEntity.stardate).format('YYYY-MM-DD');
        cashBankMovementEntity.endidate = moment(cashBankMovementEntity.endidate).format('YYYY-MM-DD');
        cashBankMovementEntity.optotals = JSON.parse(cashBankMovementEntity.optotals);
        cashBankMovementEntity.csb_cashbank = {
            idcsbank: csb_cashbank.idcsbank, 
            typecsbk: '',
            acconumb: csb_cashbank.acconumb, 
            denomina: csb_cashbank.denomina, 
            currency: csb_cashbank.currency, 
            accaccou: csb_cashbank.accaccou, 
            fiscyear: csb_cashbank.fiscyear, 
            accdenom: csb_cashbank.denomina,
            profiacc: null,
            prodenom: null,
            lostacco: null,
            losdenom: null,
            csb_balances: []
        }
        for (let iterator of cashBankMovementEntity.csb_csbkmdet) {
            (iterator as any).transactions = this.props.financeReducers.tables.transactions.find((x: any) => (x.serialnu + '-' + x.documnum) === iterator.documnum && x.typetran === iterator.typetran);
            iterator.balances = JSON.parse(iterator.balances);
            (iterator as any).dateoper = moment(iterator.dateoper).format('YYYY-MM-DD');
        }

        this.setState({
            cashBankMovementEntity,
            dateoper: cashBankMovementEntity.csb_csbkmdet[cashBankMovementEntity.csb_csbkmdet.length - 1].dateoper,
            curreval: cashBankMovementEntity.csb_csbkmdet[cashBankMovementEntity.csb_csbkmdet.length - 1].curreval,
            itemnumb: cashBankMovementEntity.csb_csbkmdet[cashBankMovementEntity.csb_csbkmdet.length - 1].itemnumb
        });
    }

    callbackInit = () => {
        this.props.currencyExchangeRateRequest(moment(this.state.dateoper).format('YYYY-MM-DD'), () => {
            const { tables } = this.props.generalReducers;
            if(tables.exchangeRate !== null) {
                this.setState((state: any) => ({
                    ...state,
                    curreval: tables.exchangeRate.venta
                }))
            }
        });
    }

    calculateGrid = (csb_csbkmdet, props, value, curreval, currency, totalamo, _precalc = true, _origin = '') => {
        const { csb_cashbank } = this.props.financeReducers.tables;
        let balances;
        const companyCurrency = '00000014';
        const _typevouc = props.field === 'typevouc' ? value : csb_csbkmdet[props.index]['typevouc'];
        let _curreval = props.field === 'curreval' ? +value : +csb_csbkmdet[props.index]['curreval'];
        const _currency = this.state.cashBankMovementEntity.csb_cashbank.currency;
        let _exchrate = 0.00;
        if(companyCurrency === _currency) {
            if(currency === _currency) {
                const _totalamo = totalamo;
                const __altttamo = totalamo / _curreval;
                const str_totalamo = _precalc ? String.formatNumber.new(_totalamo) : _origin;
                const str_altttamo = String.formatNumber.new(__altttamo);
                balances = {
                    '00000014': { debitamo: _typevouc === 'TCSVO002' ? '0.00' : str_totalamo, assetamo: _typevouc === 'TCSVO002' ? str_totalamo : '0.00' },
                    '00000015': { debitamo: _typevouc === 'TCSVO002' ? '0.00' : str_altttamo, assetamo: _typevouc === 'TCSVO002' ? str_altttamo : '0.00' },
                }
            } else {
                const __altttamo = _precalc ? (Math.round((totalamo * curreval)*100)/100) : totalamo;

                // const totalFactura = _precalc ? totalamo : +csb_csbkmdet[props.index]['transactions'].balanpay.replace(/,/g, '')
                // const newCurrency = Math.round(((Math.round((totalFactura * _curreval) / totalFactura*100)/100))*1000000)/1000000;

                let _totalamo = __altttamo / _curreval;
                const str_totalamo = String.formatNumber.new(_totalamo);
                const str_altttamo = _precalc ? String.formatNumber.new(__altttamo) : _origin;
                balances = {
                    '00000014': { debitamo: _typevouc === 'TCSVO002' ? '0.00' : str_altttamo, assetamo: _typevouc === 'TCSVO002' ? str_altttamo : '0.00' },
                    '00000015': { debitamo: _typevouc === 'TCSVO002' ? '0.00' : str_totalamo, assetamo: _typevouc === 'TCSVO002' ? str_totalamo : '0.00' },
                }
                _totalamo = Math.round((_totalamo)*100)/100
                const a = Math.round(__altttamo*100)/100;
                const b =  Math.round((_precalc ? totalamo*_curreval : Math.round(Math.round(_totalamo*_curreval*100)/100 / curreval *100)/100 * _curreval) *100)/100;
                _exchrate = Math.round((a - b)*100)/100;
                // _exchrate = Math.round(((Math.round(_totalamo*100)/100) - (Math.round((_precalc ? totalamo : (totalamo / curreval))*100)/100))*100)/100;
            }
        } else {
            if(currency === _currency) {
                const _totalamo = totalamo;
                const __altttamo = totalamo * _curreval;
                const str_totalamo = _precalc ? String.formatNumber.new(_totalamo) : _origin;
                const str_altttamo = String.formatNumber.new(__altttamo);
                balances = {
                    '00000014': { debitamo: _typevouc === 'TCSVO002' ? '0.00' : str_altttamo, assetamo: _typevouc === 'TCSVO002' ? str_altttamo : '0.00' },
                    '00000015': { debitamo: _typevouc === 'TCSVO002' ? '0.00' : str_totalamo, assetamo: _typevouc === 'TCSVO002' ? str_totalamo : '0.00' },
                }
                const a = Math.round(_totalamo*curreval*100)/100;
                const b =  Math.round(__altttamo*100)/100;
                _exchrate = Math.round((a - b)*100)/100;
            } else {
                const __altttamo = _precalc ? (Math.round((totalamo / _curreval)*100)/100) : totalamo;

                const totalFactura = _precalc ? totalamo : +csb_csbkmdet[props.index]['transactions'].balanpay.replace(/,/g, '')
                const newCurrency = Math.round((totalFactura / (Math.round((totalFactura / _curreval)*100)/100))*1000000)/1000000;

                const _totalamo = __altttamo * newCurrency;
                const str_totalamo = String.formatNumber.new(_totalamo);
                const str_altttamo = _precalc ? String.formatNumber.new(__altttamo) : _origin;
                balances = {
                    '00000014': { debitamo: _typevouc === 'TCSVO002' ? '0.00' : str_totalamo, assetamo: _typevouc === 'TCSVO002' ? str_totalamo : '0.00' },
                    '00000015': { debitamo: _typevouc === 'TCSVO002' ? '0.00' : str_altttamo, assetamo: _typevouc === 'TCSVO002' ? str_altttamo : '0.00' },
                }
                totalamo = Math.round((totalamo)*100)/100
                const a = Math.round(_totalamo*100)/100;
                const b =  Math.round((_precalc ? Math.round(totalamo/curreval*100)/100 * _curreval : (Math.round(_totalamo/curreval*100)/100 * _curreval))*100)/100;
                _exchrate = Math.round((a - b)*100)/100;
                // _exchrate = Math.round(((Math.round((_precalc ? totalamo : (totalamo * curreval) - (Math.round(_totalamo*100)/100))*100)/100))*100)/100;
            }
        }

        if(_exchrate !== 0.00 && (csb_csbkmdet[props.index]['documnum'] || '') !== '' && (csb_csbkmdet[props.index]['docnumbe'] || '') !== '') {
            const { idcsbank } = this.state.cashBankMovementEntity;
            csb_csbkmdet[props.index]['exchrate'] = Math.abs(_exchrate);
            csb_csbkmdet[props.index]['diffsign'] = _exchrate > 0 ? '+' : '-';
            csb_csbkmdet[props.index]['defiacco'] = _exchrate > 0 ?  
            csb_cashbank.find((x: any) => x.idcsbank === idcsbank).profiacc
            :
            csb_cashbank.find((x: any) => x.idcsbank === idcsbank).lostacco;
        } else {
            csb_csbkmdet[props.index]['exchrate'] = 0.00;
            csb_csbkmdet[props.index]['diffsign'] = null;
            csb_csbkmdet[props.index]['defiacco'] = null;
        }
        csb_csbkmdet[props.index]['balances'] = balances;
    }

    onEditCell = (props: any, value: any) => {
        const csb_csbkmdet: any = this.state.cashBankMovementEntity.csb_csbkmdet;

        let curreval, currency, totalamo;
        if(props.field === 'transactions') {
            curreval = +value.curreval;
            currency = value.currency;
            totalamo = +value.balanpay.replace(/,/g, '');
            this.calculateGrid(csb_csbkmdet, props, value, curreval, currency, totalamo);
        } else if(props.field === 'typevouc') {
            const _typevouc = value;
            const balances = csb_csbkmdet[props.index]['balances'];
            const str_totalamo = String.formatNumber.new(+balances['00000014'].debitamo.replace(/,/g, '') + +balances['00000014'].assetamo.replace(/,/g, ''));
            const str_altttamo = String.formatNumber.new(+balances['00000015'].debitamo.replace(/,/g, '') + +balances['00000015'].assetamo.replace(/,/g, ''));
            csb_csbkmdet[props.index]['balances'] = {
                '00000014': { debitamo: _typevouc === 'TCSVO002' ? '0.00' : str_totalamo, assetamo: _typevouc === 'TCSVO002' ? str_totalamo : '0.00' },
                '00000015': { debitamo: _typevouc === 'TCSVO002' ? '0.00' : str_altttamo, assetamo: _typevouc === 'TCSVO002' ? str_altttamo : '0.00' },
            }
        } else if(props.field === 'balances') {
            if((csb_csbkmdet[props.index]['documnum'] || '') !== '' && (csb_csbkmdet[props.index]['docnumbe'] || '') !== '') {
                curreval = +csb_csbkmdet[props.index]['transactions'].curreval;
            } else {
                curreval = 1;
            }
            currency = csb_csbkmdet[props.index]['currency'];
            this.calculateGrid(csb_csbkmdet, props, value, curreval, currency, +value.replace(/,/g, ''), false, value);
        } else if(props.field === 'curreval') {
            curreval = 1;
            currency = csb_csbkmdet[props.index]['currency'];
            const balances = csb_csbkmdet[props.index]['balances'];
            let _value = +balances[currency].debitamo.replace(/,/g, '') + +balances[currency].assetamo.replace(/,/g, '');
            this.calculateGrid(csb_csbkmdet, props, value, curreval, currency, _value, false, _value.toString());
        }

        if(props.field !== 'balances')
            csb_csbkmdet[props.index][props.field] = value;

        const optotals = {
            '00000014': { debitamo: 0.00, assetamo: 0.00 },
            '00000015': { debitamo: 0.00, assetamo: 0.00 },
        };
        for (const iterator of csb_csbkmdet) {
            const { balances } = iterator;
            optotals['00000014'].debitamo += +balances['00000014'].debitamo.replace(/,/g, '');
            optotals['00000014'].assetamo += +balances['00000014'].assetamo.replace(/,/g, '');
            optotals['00000015'].debitamo += +balances['00000015'].debitamo.replace(/,/g, '');
            optotals['00000015'].assetamo += +balances['00000015'].assetamo.replace(/,/g, '');
        }

        this.setState((state: FormCashBanksMovementsState) => ({
            ...state,
            dateoper: props.field === 'dateoper' ? value : state.dateoper,
            curreval: props.field === 'curreval' ? value : state.curreval,
            cashBankMovementEntity: {
                ...state.cashBankMovementEntity,
                optotals,
                csb_csbkmdet,
            }
        }))

        if(props.field === 'dateoper') {
            const callback = (i) => {
                const { tables } = this.props.generalReducers;
                if(tables.exchangeRate !== null) {
                    this.onEditCell(
                        {index: i, field: 'curreval' }, 
                        tables.exchangeRate.venta
                    )
                }
            };
            this.props.currencyExchangeRateRequest(moment(csb_csbkmdet[props.index]['dateoper']).format('YYYY-MM-DD'), () => callback(props.index));
        }
    }

    onAddDetail = () => {
        const { csb_csbkmdet, idcsbank } = this.state.cashBankMovementEntity;
        const { currency } = this.state.cashBankMovementEntity.csb_cashbank;
        const { grl_definiti } = this.props.financeReducers.tables;
        const itemnumb = this.state.itemnumb + 1;
        if(idcsbank === 0) { 
            alert('No se ha seleccionado ninguna caja / banco')
            return;
        }
        const newItem: any = {
            itemnumb: itemnumb,
            typevouc: grl_definiti.filter((x: any) => x.defitype === 'TCSVO')[0].deficode,
            glosarys: '',
            dateoper: this.state.dateoper,
            curreval: this.state.curreval,
            docnumbe: '',
            denomina: '',
            documnum: '',
            typetran: null,
            currency: currency,
            voucnumb: '',
            tycadocu: grl_definiti.filter((x: any) => x.defitype === 'TCSCA')[0].deficode,
            suppdocu: '',
            accaccou: null,
            fiscyear: moment().format('YYYY'),
            costcent: null,
            balances: {
                '00000014': { debitamo: '0.00', assetamo: '0.00' },
                '00000015': { debitamo: '0.00', assetamo: '0.00' },
            },
            exchrate: 0.00,
            defiacco: null,
            diffsign: null,
            iddetrac: '',
            detrnumb: '',
            transactions: {
                curreval: '0.00',
                totalamo: '0.00',
                currency: '00000014'
            }
        };
        (csb_csbkmdet as any).push(newItem);
        this.setState((state: any) => ({
            ...state,
            itemnumb,
            cashBankMovementEntity: {
                ...state.cashBankMovementEntity,
                grl_definiti,
            }
        }))
    }

    onDeleteDetail = () => {
        // const { definition } = this.props.shoppingsReducers.tables;
        let { csb_csbkmdet } = this.state.cashBankMovementEntity;        
        csb_csbkmdet = csb_csbkmdet.filter((x: any) => x.select === false || x.select === undefined);

        const optotals = {
            '00000014': { debitamo: 0.00, assetamo: 0.00 },
            '00000015': { debitamo: 0.00, assetamo: 0.00 },
        };
        for (const iterator of csb_csbkmdet) {
            const { balances } = iterator;
            optotals['00000014'].debitamo += +balances['00000014'].debitamo.replace(/,/g, '');
            optotals['00000014'].assetamo += +balances['00000014'].assetamo.replace(/,/g, '');
            optotals['00000015'].debitamo += +balances['00000015'].debitamo.replace(/,/g, '');
            optotals['00000015'].assetamo += +balances['00000015'].assetamo.replace(/,/g, '');
        }

        this.setState((state: FormCashBanksMovementsState) => ({
            ...state,
            cashBankMovementEntity: {
                ...state.cashBankMovementEntity,
                optotals,
                csb_csbkmdet,
            }
        }))
        
    }

    renderDetail = () => {
        const { grl_definiti, entities, acc_planacco, transactions } = this.props.financeReducers.tables;
        const { csb_csbkmdet } = this.state.cashBankMovementEntity;
        const { currency } = this.state.cashBankMovementEntity.csb_cashbank;

        return csb_csbkmdet.map((item: any, i: any) => (
            <tr key={ i } >
                <td style={{minWidth:'50px'}}>
                    <input type="checkbox"
                    checked={ item.select } 
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'select' }, 
                            e.target.checked
                        )}
                    />
                </td>
                <td style={{minWidth:'80px'}}>
                    <select style={{width:'100%'}} value={ item.typevouc } 
                        onChange={ (e: any) => { 
                            this.onEditCell({index: i, field: 'typevouc'}, e.target.value)
                        }}
                    >
                        { grl_definiti.filter((x: any) => x.defitype === 'TCSVO').map((item:any) => <option value={item.deficode} key={item.deficode}>{item.definame}</option>) }
                    </select>
                </td>
                <td style={{minWidth:'150px'}}>
                    <input type="text"
                    value={ item.glosarys } 
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'glosarys' }, 
                            e.target.value
                        )}
                    style={{ textAlign: 'left' }}
                    />
                </td>
                <td style={{minWidth:'120px'}}>
                    <input type="date"
                    value={ item.dateoper } 
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'dateoper' }, 
                            e.target.value
                        )}
                    />
                </td>
                <td style={{width:'50px'}}>
                    <input type="text"
                    value={ item.curreval } 
                    onFocus={(e) => e.target.select() }
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'curreval' }, 
                            e.target.value
                        )}
                    style={{ textAlign: 'right' }}
                    />
                </td>
                <td style={{minWidth:'130px'}}>
                    <Autocomplete
                        id="docnumbe"
                        freeSolo
                        options={entities}
                        getOptionLabel={ (x: any) => x.docnumbe }
                        value={ entities.find((x: any) => x.docnumbe === item.docnumbe) || null }
                        onChange={(e: any, value) => {
                            this.onEditCell({index: i, field: 'docnumbe'}, value.docnumbe);
                            this.onEditCell({index: i, field: 'denomina'}, value.suppname + " " + value.lastname + " " + value.mlstname + value.businame);
                        }}
                        renderInput={(params) => (
                            <TextField {...params} 
                            onBlur={(e) => { 
                                const a = entities.find((x: any) => x.docnumbe === e.target.value);
                                if(a) {
                                    this.onEditCell({index: i, field: 'docnumbe'}, a.docnumbe);
                                    this.onEditCell({index: i, field: 'denomina'}, a.suppname + " " + a.lastname + " " + a.mlstname + a.businame);
                                    this.onEditCell({index: i, field: 'documnum'}, '');
                                }
                            }}
                            style={ { marginTop: 0 } } margin="normal" variant="outlined" />
                        )}
                    />
                </td>
                <td className={ item.docnumbe || '' !== '' ? "disabled" : '' } style={{minWidth:'150px'}}>
                    <input type="text"
                    value={ item.denomina } 
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'denomina' }, 
                            e.target.value
                        )}
                    style={{ textAlign: 'left' }}
                    disabled={ item.docnumbe || '' !== '' }
                    />
                </td>
                <td style={{minWidth:'150px'}}>
                    {
                        item.docnumbe !== '' ?
                        <Autocomplete
                            id="documnum"
                            freeSolo
                            options={transactions.filter((x: any) => x.docsuppl === item.docnumbe && +x.balanpay.replace(/,/g, '') > 0 && csb_csbkmdet.find((y) => y.documnum === x.serialnu + '-' + x.documnum) === undefined)}
                            getOptionLabel={ (x: any) => (x.serialnu + '-' + x.documnum) }
                            value={ transactions.find((x: any) => x.docsuppl === item.docnumbe && (x.serialnu + '-' + x.documnum) === item.documnum) || null }
                            onChange={(e: any, value) => {
                                this.onEditCell({index: i, field: 'documnum'}, (value.serialnu + '-' + value.documnum));
                                this.onEditCell({index: i, field: 'typetran'}, value.typetran);
                                this.onEditCell({index: i, field: 'currency'}, value.currency);
                                this.onEditCell({index: i, field: 'accaccou'}, value.accaccou);
                                this.onEditCell({index: i, field: 'fiscyear'}, value.fiscyear);
                                this.onEditCell({index: i, field: 'transactions'}, value);
                            }}
                            renderInput={(params) => (
                                <TextField {...params} 
                                onBlur={(e) => { 
                                    const a = transactions.find((x: any) => x.docsuppl === item.docnumbe && (x.serialnu + '-' + x.documnum) === e.target.value);
                                    if(a) {
                                        this.onEditCell({index: i, field: 'documnum'}, (a.serialnu + '-' + a.documnum));
                                        this.onEditCell({index: i, field: 'typetran'}, a.typetran);
                                        this.onEditCell({index: i, field: 'currency'}, a.currency);
                                        this.onEditCell({index: i, field: 'accaccou'}, a.accaccou);
                                        this.onEditCell({index: i, field: 'fiscyear'}, a.fiscyear);
                                        this.onEditCell({index: i, field: 'transactions'}, a);
                                    }
                                }}
                                style={ { marginTop: 0 } } margin="normal" variant="outlined" />
                            )}
                        />
                        :
                        <input type="text"
                        value={ item.documnum } 
                        onChange={ (e: any) => 
                            this.onEditCell(
                                {index: i, field: 'documnum' }, 
                                e.target.value
                            )}
                        style={{ textAlign: 'left' }}
                    />
                    }
                </td>
                <td className={ item.documnum || '' !== '' ? "disabled" : '' } style={{minWidth:'70px'}}>
                    <select style={{width:'100%'}} value={ item.currency } 
                        onChange={ (e: any) => { 
                            this.onEditCell({index: i, field: 'currency'}, e.target.value)
                        }}
                        disabled={ item.documnum || '' !== '' }
                    >
                        { grl_definiti.filter((x: any) => x.defitype === 'CURCY').map((item:any) => <option value={item.deficode} key={item.deficode}>{item.definame}</option>) }
                    </select>
                </td>
                <td style={{minWidth:'50px'}}>
                    <input type="text"
                    value={ item.voucnumb } 
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'voucnumb' }, 
                            e.target.value
                        )}
                    style={{ textAlign: 'left' }}
                    />
                </td>
                <td style={{minWidth:'80px'}}>
                    <select style={{width:'100%'}} value={ item.tycadocu } 
                        onChange={ (e: any) => { 
                            this.onEditCell({index: i, field: 'tycadocu'}, e.target.value)
                        }}
                    >
                        { grl_definiti.filter((x: any) => x.defitype === 'TCSCA').map((item:any) => <option value={item.deficode} key={item.deficode}>{item.definame}</option>) }
                    </select>
                </td>
                <td style={{minWidth:'50px'}}>
                    <input type="text"
                    value={ item.suppdocu } 
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'suppdocu' }, 
                            e.target.value
                        )}
                    style={{ textAlign: 'left' }}
                    />
                </td>
                {/* <td className={ item.documnum || '' !== '' ? "disabled" : '' } style={{minWidth:'130px'}}> */}
                <td style={{minWidth:'130px'}}>
                    <Autocomplete
                        id="accaccou"
                        freeSolo
                        options={acc_planacco}
                        getOptionLabel={ (x: any) => x.accaccou }
                        value={ acc_planacco.find((x: any) => x.accaccou === item.accaccou) || null }
                        onChange={(e: any, value) => {
                            this.onEditCell({index: i, field: 'accaccou'}, value.accaccou);
                            this.onEditCell({index: i, field: 'fiscyear'}, value.fiscyear);
                        }}
                        renderInput={(params) => (
                            <TextField {...params} 
                            onBlur={(e) => { 
                                const a = acc_planacco.find((x: any) => x.accaccou === e.target.value);
                                if(a) {
                                    this.onEditCell({index: i, field: 'accaccou'}, a.accaccou);
                                    this.onEditCell({index: i, field: 'fiscyear'}, a.fiscyear);
                                }
                            }}
                            style={ { marginTop: 0 } } margin="normal" variant="outlined" />
                        )}
                        // disabled={ item.documnum || '' !== '' }
                    />
                </td>
                <td style={{minWidth:'70px'}}>
                    <input type="text"
                    value={ item.costcent } 
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'costcent' }, 
                            e.target.value
                        )}
                    style={{ textAlign: 'left' }}
                    />
                </td>
                <td className={ item.typevouc !== 'TCSVO001' ? "disabled" :  currency !== '00000014' ? 'disabled' : '' } style={{minWidth:'70px'}}>
                    <input type="text"
                    value={ item.balances['00000014'].debitamo } 
                    onFocus={(e) => e.target.select() }
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'balances' },
                            e.target.value 
                        )}
                    style={{ textAlign: 'right' }}
                    disabled={ item.typevouc !== 'TCSVO001' ? true : currency !== '00000014' ? true : false }
                    />
                </td>
                <td className={ item.typevouc !== 'TCSVO002' ? "disabled" : currency !== '00000014' ? 'disabled' : '' } style={{minWidth:'70px'}}>
                    <input type="text"
                    value={ item.balances['00000014'].assetamo } 
                    onFocus={(e) => e.target.select() }
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'balances' }, 
                            e.target.value 
                        )}
                    style={{ textAlign: 'right' }}
                    disabled={ item.typevouc !== 'TCSVO002' ? true : currency !== '00000014' ? true : false  }
                    />
                </td>
                <td className={ item.typevouc !== 'TCSVO001' ? "disabled" : currency !== '00000015' ? 'disabled' : '' } style={{minWidth:'70px'}}>
                    <input type="text"
                    value={ item.balances['00000015'].debitamo } 
                    onFocus={(e) => e.target.select() }
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'balances' }, 
                            e.target.value 
                        )}
                    style={{ textAlign: 'right' }}
                    disabled={ item.typevouc !== 'TCSVO001' ? true : currency !== '00000015' ? true : false  }
                    />
                </td>
                <td className={ item.typevouc !== 'TCSVO002' ? "disabled" : currency !== '00000015' ? 'disabled' : '' } style={{minWidth:'70px'}}>
                    <input type="text"
                    value={ item.balances['00000015'].assetamo } 
                    onFocus={(e) => e.target.select() }
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'balances' }, 
                            e.target.value
                        )}
                    style={{ textAlign: 'right' }}
                    disabled={ item.typevouc !== 'TCSVO002' ? true : currency !== '00000015' ? true : false }
                    />
                </td>
                <td className="disabled" style={{minWidth:'70px'}}>
                    <input type="text"
                    value={ item.exchrate } 
                    style={{ textAlign: 'right' }}
                    disabled={ true }
                    />
                </td>
                <td className="disabled" style={{minWidth:'70px'}}>
                    <input type="text"
                    value={ item.defiacco || '' } 
                    style={{ textAlign: 'right' }}
                    disabled={ true }
                    />
                </td>
            </tr>
        ))
    }

    renderGrilla = () => {
        return (
            <div className="form">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className="form__field">
                            <div className="grid__actions">
                                <button className="add" onClick={ (e) => { e.preventDefault(); this.onAddDetail() } }><i className="fas fa-plus"/></button>
                                <button className="remove" onClick={ (e) => { e.preventDefault(); this.onDeleteDetail() } }><i className="fas fa-times"/></button>
                                <span style={ { marginLeft: '15px' } }>[ENTER]: Agregar nuevo item</span>
                                <span style={ { marginLeft: '15px' } }>[TAB]: Navegar entre las celdas</span>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <EditableCell
                            header={ header }
                            data={ this.renderDetail() }
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }

    componentDidMount() {
        const { params } = this.props.match;
        if (params.yearexer !== undefined) {
            this.setState({title: 'Editar Caja y Bancos'});
            this.props.cashBanksMovementsInitUpdateFormRequest(params.yearexer, params.montexer, params.weekexer, params.pagenumb, params.idcsbank, this.callbackInitUpdate);
        } else {
            this.props.cashBanksMovementsInitFormRequest(this.props.dashboardReducers.currentFiscyear, this.callbackInit);
        }
    }

    render() {

        const { cashBankMovementEntity, title, snackbar } = this.state;
        const { loading, list, tables, failed, failedTitle, failedContent } = this.props.financeReducers;
        const { classes } = this.props;

        return (
            <div style={{overflow: 'auto', height: '100%'}}>
                <Paper className='form__container'>
                    <br/>
                    <Typography variant='h5'>{ title }</Typography>
                    <br/>
                    { failed && <MessageBox title={failedTitle} content={failedContent}/> }
                    <form noValidate autoComplete="off">
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <Autocomplete
                                    id="idcsbank"
                                    freeSolo
                                    options={tables.csb_cashbank}
                                    getOptionLabel={ (x: any) => x.denomina }
                                    value={ tables.csb_cashbank.find((x: any) => x.idcsbank === cashBankMovementEntity.idcsbank) || null }
                                    onChange={(e: any, value) => this.onChangeCashBankMovementRoot({
                                        'idcsbank': value.idcsbank,
                                        'csb_cashbank': {
                                            'idcsbank': value.idcsbank, 
                                            'acconumb': value.acconumb, 
                                            'denomina': value.denomina, 
                                            'currency': value.currency, 
                                            'accaccou': value.accaccou, 
                                            'fiscyear': value.fiscyear, 
                                            'accdenom': value.denomina
                                        },
                                        'optotals': {
                                            '00000014': { debitamo: 0.00, assetamo: 0.00 },
                                            '00000015': { debitamo: 0.00, assetamo: 0.00 },
                                        },
                                        'csb_csbkmdet': []
                                    })}
                                    renderInput={(params) => (
                                        <TextField {...params} 
                                        onBlur={(e) => { 
                                            const a = tables.csb_cashbank.find((x: any) => x.idcsbank === e.target.value);
                                            if(a) {
                                                this.onChangeCashBankMovementRoot({
                                                    'idcsbank': a.idcsbank,
                                                    'csb_cashbank': {
                                                        'idcsbank': a.idcsbank, 
                                                        'acconumb': a.acconumb, 
                                                        'denomina': a.denomina, 
                                                        'currency': a.currency, 
                                                        'accaccou': a.accaccou, 
                                                        'fiscyear': a.fiscyear, 
                                                        'accdenom': a.denomina
                                                    },
                                                    'optotals': {
                                                        '00000014': { debitamo: 0.00, assetamo: 0.00 },
                                                        '00000015': { debitamo: 0.00, assetamo: 0.00 },
                                                    },
                                                    'csb_csbkmdet': []
                                                })
                                            }
                                        }}
                                        style={ { marginTop: 0 } } label="Caja / Banco" margin="normal" variant="outlined" />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <TextField fullWidth id="acconumb" label="Numero de cuenta" variant="outlined" value={ cashBankMovementEntity.csb_cashbank.acconumb || '' }/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                    <InputLabel id="lbl_moneda">Moneda</InputLabel>
                                    <Select
                                        labelId="lbl_moneda"
                                        id="currency"
                                        className='select'
                                        label="Moneda"
                                        fullWidth
                                        value={cashBankMovementEntity.csb_cashbank.currency || ''}
                                    >
                                        {
                                            tables.grl_definiti.filter((x: any) => x.defitype === 'CURCY' ).map((item: any, key: any) => 
                                                <MenuItem value={item.deficode} key={`moneda_${key}`}>{item.definame}</MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <TextField fullWidth id="accaccou" label="Cuenta Contable" variant="outlined" value={ cashBankMovementEntity.csb_cashbank.accaccou || '' }/>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth id="accdenom" variant="outlined" value={ cashBankMovementEntity.csb_cashbank.accdenom || '' }/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <TextField fullWidth id="yearexer" label="Ejercicio - Año" variant="outlined" value={ cashBankMovementEntity.yearexer || '' } onChange={(e: any) => this.onChangeCashBankMovementRoot({'yearexer': e.target.value})}/>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                        <InputLabel id="montexer">Ejercicio - Mes</InputLabel>
                                        <Select
                                            labelId="lbl_mes"
                                            id="month"
                                            className='select'
                                            label="Mes"
                                            fullWidth
                                            value={ cashBankMovementEntity.montexer || '' }
                                            onChange={(e: any) => this.onChangeCashBankMovementRoot({'montexer': e.target.value})}
                                        >
                                            {
                                                meses.map((x: any) => 
                                                    <MenuItem value={x.key} key={x.key}>{x.value}</MenuItem>
                                                )
                                            }
                                        </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={1}>
                                <TextField fullWidth id="weekexer" label="Nro Semana" variant="outlined" value={ cashBankMovementEntity.weekexer || '' } onChange={(e: any) => this.onChangeCashBankMovementRoot({'weekexer': e.target.value})}/>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField fullWidth type="date" id="stardate" variant="outlined" value={ cashBankMovementEntity.stardate } onChange={(e: any) => this.onChangeCashBankMovementRoot({'stardate': e.target.value})}/>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField fullWidth type="date" id="endidate" variant="outlined" value={ cashBankMovementEntity.endidate } onChange={(e: any) => this.onChangeCashBankMovementRoot({'endidate': e.target.value})}/>
                            </Grid>
                            <Grid item xs={1}>
                                <TextField fullWidth id="pagenumb" label="Nro Pagina" variant="outlined" value={ cashBankMovementEntity.pagenumb || '' } onChange={(e: any) => this.onChangeCashBankMovementRoot({'pagenumb': e.target.value})}/>
                            </Grid>
                        </Grid>
                        <div style={{ height: 50 }}/>
                        <Grid container spacing={2} >
                            { this.renderGrilla()  }
                        </Grid>
                        <div style={{ height: 50 }}/>
                        <Grid container spacing={2} justify="flex-end">
                            <Grid item xs={1}>
                                <TextField fullWidth id="00000014debitamo" label="T. Cargo Soles" variant="outlined" value={ String.formatNumber.new(cashBankMovementEntity.optotals['00000014'].debitamo) }/>
                            </Grid>
                            <Grid item xs={1}>
                                <TextField fullWidth id="00000014assetamo" label="T. Abono Soles" variant="outlined" value={ String.formatNumber.new(cashBankMovementEntity.optotals['00000014'].assetamo) }/>
                            </Grid>
                            <Grid item xs={1}>
                                <TextField fullWidth id="00000015debitamo" label="T. Cargo Dolar" variant="outlined" value={ String.formatNumber.new(cashBankMovementEntity.optotals['00000015'].debitamo) }/>
                            </Grid>
                            <Grid item xs={1}>
                                <TextField fullWidth id="00000015assetamo" label="T. Abono Dolar" variant="outlined" value={ String.formatNumber.new(cashBankMovementEntity.optotals['00000015'].assetamo) }/>
                            </Grid>
                        </Grid>
                    </form>
                    <br/>
                    <FormFooter 
                        onCancel={ this.onCancel } 
                        onSubmitContinue={ this.onSubmitContinue } 
                        onSubmit={ this.onSubmit }/>
                </Paper>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={snackbar}
                    onClose={() => this.setState({snackbar: false})}
                    autoHideDuration={6000}
                >
                    <Alert onClose={() => this.setState({snackbar: false})} severity="success">
                            Datos Guardados Correctamente
                    </Alert>
                </Snackbar>
                <ScreenLoader enabled={loading}/>
            </div>
        );
    }
}

const financeReducers = 'financeReducers';
const dashboardReducers = 'dashboardReducers';
const generalReducers = 'generalReducers';

const mapStateToProps = (state: any) => ({
    financeReducers: state.get(financeReducers),
    dashboardReducers: state.get(dashboardReducers),
    generalReducers: state.get(generalReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        cashBanksMovementsInitFormRequest,
        cashBanksMovementsInitUpdateFormRequest,
        cashBanksMovementsInsertRequest,
        cashBanksMovementsUpdateRequest,
        currencyExchangeRateRequest
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(FormCashBanksMovements));