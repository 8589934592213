import React from 'react';
import { Backdrop, Button, ButtonGroup, Checkbox, CircularProgress, createStyles, FormControl, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Select, TextField, Theme, Tooltip, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { inventoryBookBalanceRequest } from 'penta-redux/actions/accountantActions';
import history from '../../../../utils/history';
import './../reports.scss'
import FormFooter from 'penta-components/base/formFooter';
import { MessageBox } from 'penta-components/base/messageBox';
import { categoryEntity } from 'models/wharehouseProductsModels';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PdfView from 'penta-components/base/pdfView';
// import * as pdfMake from "pdfmake/build/pdfmake";
// import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
import * as XLSX from 'sheetjs-style';
import ScreenLoader from 'penta-components/base/screenLoader';

let pdfMake = require("pdfmake/build/pdfmake");
let saveAs = require('file-saver').saveAs;

const meses = [
    { key: '01', value: 'Enero' },
    { key: '02', value: 'Febrero' },
    { key: '03', value: 'Marzo' },
    { key: '04', value: 'Abril' },
    { key: '05', value: 'Mayo' },
    { key: '06', value: 'Junio' },
    { key: '07', value: 'Julio' },
    { key: '08', value: 'Agosto' },
    { key: '09', value: 'Septiembre' },
    { key: '10', value: 'Octubre' },
    { key: '11', value: 'Noviembre' },
    { key: '12', value: 'Diciembre' }
]

const useStyles = (theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        formControl: {
            margin: 0,
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    });

interface FormState {
    title: string,
    year: string,
    month: string,
    level: number
    base64Pdf: string
}

class ReportInventoryBookBalance extends React.Component<any, FormState> {

    constructor(props: any) {
        super(props);
        this.state = {
            title: 'Reporte de Libro de Inventarios y Balances',
            year: moment().format('yyyy').toString(),
            month: moment().format('MM').toString(),
            level: 1,
            base64Pdf: ''
        }
    }

    onChangeRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            ...value
        }))
    }

    onGeneratePdf = () => {
        const data = this.props.accountantReducers.onlyOne;
        const { month, year, level } = this.state;
        const { dashboardReducers } = this.props;
        const mes = meses.find((x: any) => x.key === month).value;
        const company = (JSON.parse(localStorage.getItem('tables')).companys.find((x: any) => x.taxident === dashboardReducers.currentCompany).bussname as string).toUpperCase()

        const pdfObject: any = {
            pageSize: 'A4',
            pageOrientation: 'landscape',
            pageMargins: [ 20, 100, 20, 20 ],
            header: [
                {
                    text: ' ', lineHeight: 2
                },
                {
                    margin: [20, 5, 20, 0],
                    text: 'LIBRO DE INVENTARIOS Y BALANCES\nBALANCE DE COMPROBACION',
                    style: {
                        bold: true,
                        fontSize: 11,
                        alignment: 'center'
                    }
                },
                {
                    margin: [20, 0, 20, 0],
                    text: `EJERCICIO : ${mes.toUpperCase()} ${year}`,
                    style: {
                        bold: true,
                        fontSize: 9
                    }
                },
                {
                    margin: [20, 0, 20, 0],
                    text: 'R.U.C. : ' + dashboardReducers.currentCompany,
                    style: {
                        bold: true,
                        fontSize: 9
                    }
                },
                {
                    margin: [20, 0, 0, 0],
                    text: 'EMPRESA : ' + company,
                    style: {
                        bold: true,
                        fontSize: 9
                    }
                }
            ],
            content: [
                {
                    table: {
                        headerRows: 2,
                        widths: [ 30, 150, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50 ],
                        body: []
                    }
                },
            ],
            styles: {
                headerTitle: {
                    bold: true,
                    fontSize: 7,
                    lineHeight: 1.2
                },
                table: {
                    layout: 'headerLineOnly',
                    fontSize: 6,
                    alignment: 'center'
                },
                subTable: {
                    layout: 'headerLineOnly',
                    fontSize: 6,
                    alignment: 'left'
                },
                tableDetailLeft: {
                    layout: 'headerLineOnly',
                    fontSize: 6,
                    alignment: 'left'
                },
                tableDetailRight: {
                    layout: 'headerLineOnly',
                    fontSize: 6,
                    alignment: 'right'
                },
                tableDetailRightBold: {
                    layout: 'headerLineOnly',
                    fontSize: 6,
                    alignment: 'right',
                    bold: true
                }
            },
            defaultStyle: {
                columnGap: 20,
            },
        }

        const tableBody: any = [
            [  
                { text: 'CUENTA', style: 'table', colSpan: 2, border: [true, true, false, true] },{},
                { text: 'SALDOS INICIALES', style: 'table', colSpan: 2, border: [true, true, false, true] },{},
                { text: 'MOVIMIENTOS', style: 'table', colSpan: 2, border: [true, true, false, true] },{},
                { text: 'SALDOS FINALES', style: 'table', colSpan: 2, border: [true, true, false, true] },{},
                { text: 'SALDOS FINALES DEL BALANCE GENERAL', style: 'table', colSpan: 2, border: [true, true, false, true] },{},
                { text: 'SALDOS FINALES DEL ESTADO DE PERDIDAS Y GANANCIA POR FUNCION', style: 'table', colSpan: 2, border: [true, true, true, true] },{},
            ],
            [ 
                { text: '\nCODIGO', style: 'subTable', border: [true, false, false, true] },
                { text: '\nDENOMINACION', style: 'subTable', border: [false, false, false, true] },
                
                { text: '\nDEUDOR', style: 'table', border: [true, false, false, true] },
                { text: '\nACREEDOR', style: 'table', border: [false, false, false, true] },
                
                { text: '\nDEBE', style: 'table', border: [true, false, false, true] },
                { text: '\nHABER', style: 'table', border: [false, false, false, true] },
                
                { text: '\nDEUDOR', style: 'table', border: [true, false, false, true] },
                { text: '\nACREEDOR', style: 'table', border: [false, false, false, true] },
                
                { text: '\nACTIVO', style: 'table', border: [true, false, false, true] },
                { text: 'PASIVO Y PATRAMONIO', style: 'table', border: [false, false, false, true] },
                
                { text: '\nPERDIDAS', style: 'table', border: [true, false, false, true] },
                { text: '\nGANANCIAS', style: 'table', border: [false, false, true, true] },
            ],
        ];

        
        for (const row of data) {

            const diff = (+row.inidbamo + +row.debitamo) - (+row.iniasamo + +row.assetamo);

            tableBody.push(
                [ 
                    { text: row.accaccou, style: 'tableDetailLeft', border: [false, false, false, false] },
                    { text: row.denomina.substr(0, 30), style: 'tableDetailLeft', border: [false, false, false, false] },
                    
                    { text: +row.inidbamo > 0 ? String.formatNumber.new(row.inidbamo) : '', style: 'tableDetailRight', border: [false, false, false, false] },
                    { text: +row.iniasamo > 0 ? String.formatNumber.new(row.iniasamo) : '', style: 'tableDetailRight', border: [false, false, false, false] },
                    
                    { text: +row.debitamo > 0 ? String.formatNumber.new(row.debitamo) : '', style: 'tableDetailRight', border: [false, false, false, false] },
                    { text: +row.assetamo > 0 ? String.formatNumber.new(row.assetamo) : '', style: 'tableDetailRight', border: [false, false, false, false] },
                    
                    { text: diff > 0 ? String.formatNumber.new(Math.abs(diff)) : '', style: 'tableDetailRight', border: [false, false, false, false] },
                    { text: diff < 0 ? String.formatNumber.new(Math.abs(diff)) : '', style: 'tableDetailRight', border: [false, false, false, false] },
                    
                    { text: row.codactyp == 1 ? (diff > 0 ? String.formatNumber.new(Math.abs(diff)) : '') : '', style: 'tableDetailRight', border: [false, false, false, false] },
                    { text: row.codactyp == 1 ? (diff < 0 ? String.formatNumber.new(Math.abs(diff)) : '') : '', style: 'tableDetailRight', border: [false, false, false, false] },
                    
                    { text: row.codactyp == 3 ? (diff > 0 ? String.formatNumber.new(Math.abs(diff)) : '') : '', style: 'tableDetailRight', border: [false, false, false, false] },
                    { text: row.codactyp == 3 ? (diff < 0 ? String.formatNumber.new(Math.abs(diff)) : '') : '', style: 'tableDetailRight', border: [false, false, false, false] },
                ]
            )
        }

        pdfObject.content[0].table.body = tableBody;

        pdfMake.fonts = {
            Roboto: {
                normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
                bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
                italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
                bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
              },
        }

        const pdfDocGenerator = pdfMake.createPdf(pdfObject);
        pdfDocGenerator.getDataUrl((data: any) => {
            // console.log(data);
            this.setState({
                base64Pdf: data
            })
        });

    }

    onGenerateExcel = () => {
        try {
            const data = this.props.accountantReducers.onlyOne;
            const { month, year, level } = this.state;
            const { dashboardReducers } = this.props;
            const mes = meses.find((x: any) => x.key === month).value;
            const company = (JSON.parse(localStorage.getItem('tables')).companys.find((x: any) => x.taxident === dashboardReducers.currentCompany).bussname as string).toUpperCase();

            const tableBody: any = [
                {
                    A: 'LIBRO DE INVENTARIOS Y BALANCES\nBALANCE DE COMPROBACION',
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null,
                    G: null,
                    H: null,
                    I: null,
                    J: null,
                    K: null,
                    L: null
                },
                {
                    A: `EJERCICIO: ${mes.toUpperCase()} ${year}`,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null,
                    G: null,
                    H: null,
                    I: null,
                    J: null,
                    K: null,
                    L: null
                },
                {
                    A: `R.U.C.: ${dashboardReducers.currentCompany}`,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null,
                    G: null,
                    H: null,
                    I: null,
                    J: null,
                    K: null,
                    L: null
                },
                {
                    A: `EMPRESA: ${company}`,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null,
                    G: null,
                    H: null,
                    I: null,
                    J: null,
                    K: null,
                    L: null
                },
                {
                    A: null,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null,
                    G: null,
                    H: null,
                    I: null,
                    J: null,
                    K: null,
                    L: null
                },
                {  
                    A: 'CUENTA',
                    B: '',
                    C: 'SALDOS INICIALES',
                    D: '',
                    E: 'MOVIMIENTOS',
                    F: '',
                    G: 'SALDOS FINALES',
                    H: '',
                    I: 'SALDOS FINALES DEL BALANCE GENERAL',
                    J: '',
                    K: 'SALDOS FINALES DEL ESTADO DE PERDIDAS Y GANANCIA POR FUNCION',
                    L: ''
                },
                { 
                    A: 'CODIGO',
                    B: 'DENOMINACION',
                    C: 'DEUDOR',
                    D: 'ACREEDOR',
                    E: 'DEBE',
                    F: 'HABER',
                    G: 'DEUDOR',
                    H: 'ACREEDOR',
                    I: 'ACTIVO',
                    J: 'PASIVO Y PATRAMONIO',
                    K: 'PERDIDAS',
                    L: 'GANANCIAS'
                },
            ];

            for (const row of data) {

                const diff = (+row.inidbamo + +row.debitamo) - (+row.iniasamo + +row.assetamo);
    
                tableBody.push(
                    { 
                        A: row.accaccou,
                        B: row.denomina.substr(0, 30),
                        C: +row.inidbamo > 0 ? String.formatNumber.new(row.inidbamo) : '',
                        D: +row.iniasamo > 0 ? String.formatNumber.new(row.iniasamo) : '',
                        E: +row.debitamo > 0 ? String.formatNumber.new(row.debitamo) : '',
                        F: +row.assetamo > 0 ? String.formatNumber.new(row.assetamo) : '',
                        G: diff > 0 ? String.formatNumber.new(Math.abs(diff)) : '',
                        H: diff < 0 ? String.formatNumber.new(Math.abs(diff)) : '',
                        I: row.codactyp == 1 ? (diff > 0 ? String.formatNumber.new(Math.abs(diff)) : '') : '',
                        J: row.codactyp == 1 ? (diff < 0 ? String.formatNumber.new(Math.abs(diff)) : '') : '',
                        K: row.codactyp == 3 ? (diff > 0 ? String.formatNumber.new(Math.abs(diff)) : '') : '',
                        L: row.codactyp == 3 ? (diff < 0 ? String.formatNumber.new(Math.abs(diff)) : '') : ''
                    }
                )
            }

            const header = [
                'A',
                'B',
                'C',
                'D',
                'E',
                'F',
                'G',
                'H',
                'I',
                'J',
                'K',
                'L'
            ];

            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(tableBody, {header, skipHeader: true});
            ws["!cols"] = [
                {wpx:60},{wpx:180},{wpx:80},{wpx:80},{wpx:80},{wpx:80},{wpx:80},{wpx:80},{wpx:80},{wpx:80},{wpx:80},{wpx:80}
            ];
            ws['!rows'] = [
                {hpx: 40},{hpx: 16},{hpx: 16},{hpx: 16},{hpx: 16},{hpx: 20},{hpx: 20}
            ];

            const keys = Object.keys(ws);
            const rowsCount = tableBody.length;

            for (const iterator of keys) {
                if(!isNaN(parseInt(ws[iterator].v)) && !iterator.match(/^(A|B)[0-9]+$/g)) {
                    ws[iterator].v = ws[iterator].v.replace(/,/g, '');
                    ws[iterator].t = 'n';
                    ws[iterator].z = '#,##0.00';
                }
                if(iterator !== '!ref' && iterator !== '!cols' && iterator !== '!rows') {
                    ws[iterator].s = {
                        font: {
                            name: 'Tahoma',
                            sz: 8
                        }
                    }
                }
            }

            for (let index = 5; index <= rowsCount; index++) {
                ws['!rows'].push({hpx: 12})
            }

            ws['A1'].s = {font:{name: 'Tahoma', sz: 15, bold: true}, alignment: { wrapText: true, vertical: 'center', horizontal: 'center'}}
            ws['A2'].s = {font:{name: 'Tahoma', sz: 8, bold: true}}
            ws['A3'].s = {font:{name: 'Tahoma', sz: 8, bold: true}}
            ws['A4'].s = {font:{name: 'Tahoma', sz: 8, bold: true}}

            for (const iterator of header) {
                const key1 = iterator + "6";
                const key2 = iterator + "7";
                ws[key1].s = {
                    ...ws[key1].s, 
                    alignment: { wrapText: true, vertical: 'center', horizontal: 'center'},
                    border: { 
                        top: { style: 'thin', color: 'black'},
                        bottom: { style: 'thin', color: 'black'},
                        left: { style: 'thin', color: 'black'},
                        right: { style: 'thin', color: 'black'}
                    } 
                }
                ws[key2].s = {
                    ...ws[key2].s, 
                    alignment: { wrapText: true, vertical: 'center', horizontal: 'center'},
                    border: { 
                        top: { style: 'thin', color: 'black'},
                        bottom: { style: 'thin', color: 'black'},
                        left: { style: 'thin', color: 'black'},
                        right: { style: 'thin', color: 'black'}
                    } 
                }
            };

            ws["!merges"] = [
                { s: { r: 0, c: 0 }, e: { r: 0, c: 11 } },
                { s: { r: 5, c: 0 }, e: { r: 5, c: 1 } },
                { s: { r: 5, c: 2 }, e: { r: 5, c: 3 } },
                { s: { r: 5, c: 4 }, e: { r: 5, c: 5 } },
                { s: { r: 5, c: 6 }, e: { r: 5, c: 7 } },
                { s: { r: 5, c: 8 }, e: { r: 5, c: 9 } },
                { s: { r: 5, c: 10 }, e: { r: 5, c: 11 } },
            ];

            XLSX.utils.book_append_sheet(wb, ws, "Libro Inventario Balances");

            var wbout = XLSX.write(wb, {bookType:'xlsx', type: 'binary', cellStyles: true});

            const s2ab = function(s) {
                var buf = new ArrayBuffer(s.length);
                var view = new Uint8Array(buf);
                for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
                return buf;
            }

            saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), `libro-inventario-balances-${year}-${month}.xlsx`);
    
        } catch(err) {
            console.log(err);
        }
    }

    render() {

        const { title, year, month, level, base64Pdf } = this.state;
        const { loading, list, tables, failed, failedTitle, failedContent } = this.props.accountantReducers;
        const { classes } = this.props;

        return (
            <div style={{overflow: 'auto', height: '100%'}}>
                <Paper className='form__container'>
                    <Typography variant='h5'>{ title }</Typography>
                    <br/>
                    { failed && <MessageBox title={failedTitle} content={failedContent}/> }
                    <div style={{ display: 'flex' }}>
                        <form noValidate autoComplete="off" style={{ flex: 1, paddingRight: '15px', paddingTop: '2em' }}>
                            <Grid container spacing={2} >
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_año">Año</InputLabel>
                                            <Select
                                                labelId="lbl_año"
                                                id="year"
                                                className='select'
                                                label="Año"
                                                fullWidth
                                                value={ year }
                                                onChange={(e: any) => this.onChangeRoot({'year': e.target.value})}
                                            >
                                                <MenuItem value='2030'>2030</MenuItem>
                                                <MenuItem value='2029'>2029</MenuItem>
                                                <MenuItem value='2028'>2028</MenuItem>
                                                <MenuItem value='2027'>2027</MenuItem>
                                                <MenuItem value='2026'>2026</MenuItem>
                                                <MenuItem value='2025'>2025</MenuItem>
                                                <MenuItem value='2026'>2026</MenuItem>
                                                <MenuItem value='2025'>2025</MenuItem>
                                                <MenuItem value='2024'>2024</MenuItem>
                                                <MenuItem value='2023'>2023</MenuItem>
                                                <MenuItem value='2022'>2022</MenuItem>
                                                <MenuItem value='2021'>2021</MenuItem>
                                                <MenuItem value='2020'>2020</MenuItem>
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_mesi">Mes Inicio</InputLabel>
                                            <Select
                                                labelId="lbl_mes"
                                                id="month"
                                                className='select'
                                                label="Mes Inicio"
                                                fullWidth
                                                value={ month }
                                                onChange={(e: any) => this.onChangeRoot({'month': e.target.value})}
                                            >
                                                {
                                                    meses.map((x: any) => 
                                                        <MenuItem value={x.key} key={x.key}>{x.value}</MenuItem>
                                                    )
                                                }
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} style={{ paddingTop: '50px' }}>
                                    <Button variant="contained" fullWidth color="primary" onClick={() => { 
                                        this.props.inventoryBookBalanceRequest(year, month, this.onGeneratePdf) 
                                    }}>
                                        Generar Reporte PDF
                                    </Button>
                                </Grid>
                                <Grid item xs={12} style={{ paddingTop: '10px' }}>
                                    <Button variant="contained" fullWidth color="inherit" onClick={() => { 
                                        this.props.inventoryBookBalanceRequest(year, month, this.onGenerateExcel) 
                                    }}>
                                        Generar Reporte EXCEL
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                        <PdfView data={base64Pdf} style={{ flex: 2 }}/>
                    </div>
                    <br/>
                    {/* <FormFooter onCancel={ this.onCancel } onSubmit={ this.onSubmit }/> */}
                </Paper>
                <ScreenLoader enabled={loading}/>
            </div>
        );
    }
}

const accountantReducers = 'accountantReducers';
const dashboardReducers = 'dashboardReducers';

const mapStateToProps = (state: any) => ({
    accountantReducers: state.get(accountantReducers),
    dashboardReducers: state.get(dashboardReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        inventoryBookBalanceRequest
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(ReportInventoryBookBalance));