import * as types from 'penta-redux/constants/salesConstants';
import { baseModels } from 'penta-models';

//#region CUSTOMERS

export const customersInitFormRequest = (fiscyear: string) => ({
    type: types.CUSTOMERS_INIT_FORM_REQUEST,
    fiscyear
});

export const customersInitFormRequestSuccess = (tables: any) => ({
    type: types.CUSTOMERS_INIT_FORM_REQUEST_SUCCESS,
    tables
});

export const customersInitFormRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.CUSTOMERS_INIT_FORM_REQUEST_FAILED,
    titleError,
    titleContent
});

export const customersInitUpdateFormRequest = (docnumbe: string, callback: void) => ({
    type: types.CUSTOMERS_INIT_UPDATE_FORM_REQUEST,
    docnumbe,
    callback
});

export const customersInitUpdateFormRequestSuccess = (tables: any, onlyOne: any) => ({
    type: types.CUSTOMERS_INIT_UPDATE_FORM_REQUEST_SUCCESS,
    tables,
    onlyOne
});

export const customersInitUpdateFormRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.CUSTOMERS_INIT_UPDATE_FORM_REQUEST_FAILED,
    titleError,
    titleContent
});

export const customersGetAllRecordsRequest = (body: baseModels.pentaRequestQuery) => ({
    type: types.CUSTOMERS_GET_ALL_RECORDS_REQUEST,
    body
});

export const customersGetAllRecordsRequestSuccess = (list: Array<any>, total: number) => ({
    type: types.CUSTOMERS_GET_ALL_RECORDS_REQUEST_SUCCESS,
    list,
    total
});

export const customersGetAllRecordsRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.CUSTOMERS_GET_ALL_RECORDS_REQUEST_FAILED,
    titleError,
    titleContent
});

export const customersInsertRequest = (body: any, callback?: void) => ({
    type: types.CUSTOMERS_INSERT_REQUEST,
    body,
    callback
});

export const customersInsertRequestSuccess = () => ({
    type: types.CUSTOMERS_INSERT_REQUEST_SUCCESS
});

export const customersInsertRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.CUSTOMERS_INSERT_REQUEST_SUCCESS,
    titleError,
    titleContent
});

export const customersUpdateRequest = (body: any) => ({
    type: types.CUSTOMERS_UPDATE_REQUEST,
    body
});

export const customersUpdateRequestSuccess = () => ({
    type: types.CUSTOMERS_UPDATE_REQUEST_SUCCESS
});

export const customersUpdateRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.CUSTOMERS_UPDATE_REQUEST_FAILED,
    titleError,
    titleContent
});

export const customersDeleteRequest = (docnumbes: Array<string>) => ({
    type: types.CUSTOMERS_DELETE_REQUEST,
    docnumbes
});

export const customersDeleteRequestSuccess = () => ({
    type: types.CUSTOMERS_DELETE_REQUEST_SUCCESS
});

export const customersDeleteRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.CUSTOMERS_DELETE_REQUEST_FAILED,
    titleError,
    titleContent
});

/*Components */
export const customersListSelectItem = (key: number) => ({
    type: types.CUSTOMERS_LIST_SELECT_ITEM,
    key
});

export const customersListSelectAllItem = (checked: boolean) => ({
    type: types.CUSTOMERS_LIST_SELECT_ALL_ITEM,
    checked
});
export const customersActionFailed = (titleError: string, titleContent: string) => ({
    type: types.CUSTOMERS_ACTION_FAILED,
    titleError,
    titleContent
});

//#endregion

//#region SALES

export const salesInitFormRequest = (callback: any) => ({
    type: types.SALES_INIT_FORM_REQUEST,
    callback
});

export const salesInitFormRequestSuccess = (tables: any) => ({
    type: types.SALES_INIT_FORM_REQUEST_SUCCESS,
    tables
});

export const salesInitFormRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.SALES_INIT_FORM_REQUEST_FAILED,
    titleError,
    titleContent
});

export const salesInitUpdateFormRequest = (serialnu: string, documnum: string, docnumbe: string, callback: void) => ({
    type: types.SALES_INIT_UPDATE_FORM_REQUEST,
    serialnu,
    documnum,
    docnumbe,
    callback
});

export const salesInitUpdateFormRequestSuccess = (tables: any, onlyOne: any) => ({
    type: types.SALES_INIT_UPDATE_FORM_REQUEST_SUCCESS,
    tables,
    onlyOne
});

export const salesInitUpdateFormRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.SALES_INIT_UPDATE_FORM_REQUEST_FAILED,
    titleError,
    titleContent
});

export const salesGetAllRecordsRequest = (body: baseModels.pentaRequestQuery) => ({
    type: types.SALES_GET_ALL_RECORDS_REQUEST,
    body
});

export const salesGetAllRecordsRequestSuccess = (list: Array<any>, total: number) => ({
    type: types.SALES_GET_ALL_RECORDS_REQUEST_SUCCESS,
    list,
    total
});

export const salesGetAllRecordsRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.SALES_GET_ALL_RECORDS_REQUEST_FAILED,
    titleError,
    titleContent
});

export const salesInsertRequest = (body: any, callback?: void) => ({
    type: types.SALES_INSERT_REQUEST,
    body,
    callback
});

export const salesInsertRequestSuccess = () => ({
    type: types.SALES_INSERT_REQUEST_SUCCESS
});

export const salesInsertRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.SALES_INSERT_REQUEST_FAILED,
    titleError,
    titleContent
});

export const salesUpdateRequest = (body: any) => ({
    type: types.SALES_UPDATE_REQUEST,
    body
});

export const salesUpdateRequestSuccess = () => ({
    type: types.SALES_UPDATE_REQUEST_SUCCESS
});

export const salesUpdateRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.SALES_UPDATE_REQUEST_FAILED,
    titleError,
    titleContent
});

export const salesDeleteRequest = (serialnus: Array<string>, documnums: Array<string>, docnumbes: Array<string>, filters: Object) => ({
    type: types.SALES_DELETE_REQUEST,
    serialnus,
    documnums,
    docnumbes,
    filters
});

export const salesDeleteRequestSuccess = () => ({
    type: types.SALES_DELETE_REQUEST_SUCCESS
});

export const salesDeleteRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.SALES_DELETE_REQUEST_FAILED,
    titleError,
    titleContent
});

export const salesAnulateRequest = (serialnus: Array<string>, documnums: Array<string>, docnumbes: Array<string>) => ({
    type: types.SALES_DELETE_REQUEST,
    serialnus,
    documnums,
    docnumbes
});

export const salesAnulateRequestSuccess = () => ({
    type: types.SALES_DELETE_REQUEST_SUCCESS
});

export const salesAnulateRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.SALES_DELETE_REQUEST_FAILED,
    titleError,
    titleContent
});

export const salesMassiveUpdateRequest = (body: any) => ({
    type: types.SALES_MASSIVE_UPDATE_REQUEST,
    body
});

export const salesMassiveUpdateRequestSuccess = () => ({
    type: types.SALES_MASSIVE_UPDATE_REQUEST_SUCCESS
});

export const salesMassiveUpdateRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.SALES_MASSIVE_UPDATE_REQUEST_FAILED,
    titleError,
    titleContent
});

/*Components */
export const salesListSelectItem = (key: number) => ({
    type: types.SALES_LIST_SELECT_ITEM,
    key
});

export const salesListSelectAllItem = (checked: boolean) => ({
    type: types.SALES_LIST_SELECT_ALL_ITEM,
    checked
});
export const salesActionFailed = (titleError: string, titleContent: string) => ({
    type: types.SALES_ACTION_FAILED,
    titleError,
    titleContent
});

//#endregion

//#region REPORTS

export const salesDetailedDiaryBookRequest = (year: string, month: string, callback: void) => ({
    type: types.SALES_DETAILED_DIARY_BOOK_REQUEST,
    year,
    month,
    callback
});

export const salesDetailedDiaryBookRequestSuccess = (onlyOne: any) => ({
    type: types.SALES_DETAILED_DIARY_BOOK_REQUEST_SUCCESS,
    onlyOne
});

export const salesDetailedDiaryBookRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.SALES_DETAILED_DIARY_BOOK_REQUEST_FAILED,
    titleError,
    titleContent
});

export const kardexRequest = (skucodee: string, year: string, month: string, callback: void) => ({
    type: types.KARDEX_REQUEST,
    skucodee,
    year,
    month,
    callback
});

export const kardexRequestSuccess = (onlyOne: any) => ({
    type: types.KARDEX_REQUEST_SUCCESS,
    onlyOne
});

export const kardexRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.KARDEX_REQUEST_FAILED,
    titleError,
    titleContent
});

export const invoicesChargeRequest = (initdate: string, endidate: string, callback: void) => ({
    type: types.INVOICES_CHARGE_REQUEST,
    initdate,
    endidate,
    callback
});

export const invoicesChargeRequestSuccess = (onlyOne: any) => ({
    type: types.INVOICES_CHARGE_REQUEST_SUCCESS,
    onlyOne
});

export const invoicesChargeRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.INVOICES_CHARGE_REQUEST_FAILED,
    titleError,
    titleContent
});

//#endregion