import React from 'react';
import { Backdrop, Button, ButtonGroup, Checkbox, CircularProgress, createStyles, FormControl, FormControlLabel, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Select, Switch, TextField, Theme, Tooltip, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { salesRegisterRequest } from 'penta-redux/actions/accountantActions';
import history from '../../../../utils/history';
import './../reports.scss'
import FormFooter from 'penta-components/base/formFooter';
import { MessageBox } from 'penta-components/base/messageBox';
import { categoryEntity } from 'models/wharehouseProductsModels';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PdfView from 'penta-components/base/pdfView';
// import * as pdfMake from "pdfmake/build/pdfmake";
// import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import * as XLSX from 'sheetjs-style';
import moment from 'moment';
import ScreenLoader from 'penta-components/base/screenLoader';

let pdfMake = require("pdfmake/build/pdfmake");
let saveAs = require('file-saver').saveAs;

const meses = [
    { key: '01', value: 'Enero' },
    { key: '02', value: 'Febrero' },
    { key: '03', value: 'Marzo' },
    { key: '04', value: 'Abril' },
    { key: '05', value: 'Mayo' },
    { key: '06', value: 'Junio' },
    { key: '07', value: 'Julio' },
    { key: '08', value: 'Agosto' },
    { key: '09', value: 'Septiembre' },
    { key: '10', value: 'Octubre' },
    { key: '11', value: 'Noviembre' },
    { key: '12', value: 'Diciembre' }
]

const useStyles = (theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        formControl: {
            margin: 0,
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    });

interface FormcategorysState {
    title: string,
    year: string,
    month: string,
    officode: string,
    divicode: string
    todo: boolean
    base64Pdf: string
}

class ReportSalesRegister extends React.Component<any, FormcategorysState> {

    constructor(props: any) {
        super(props);
        this.state = {
            title: 'Reporte de Registro de Ventas',
            year: moment().format('yyyy').toString(),
            month: moment().format('MM').toString(),
            officode: '',
            divicode: '',
            base64Pdf: '',
            todo: false
        }
    }

    onChangeRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            ...value
        }))
    }

    onGeneratePdf = () => {
        const data = this.props.accountantReducers.onlyOne;
        const { month, year } = this.state;
        const { dashboardReducers } = this.props;
        const mes = meses.find((x: any) => x.key === month).value;
        const tables = JSON.parse(localStorage.getItem('tables'));
        const company = tables.companys.find((x: any) => x.taxident === dashboardReducers.currentCompany);
        const division = company.lst_grl_division.find((x: any) => x.divicode === dashboardReducers.currentDivision);
        const oficina = division.lst_grl_bussofic.find((x: any) => x.officode === dashboardReducers.currentBussOfic);
        const date = new Date(parseInt(year), parseInt(month) - 1, 1);
        const stardate = moment(date).format('DD-MM-YYYY');
        const endidate = moment(date).daysInMonth() + moment(date).format('-MM-') + moment(date).format('YYYY');

        const pdfObject: any = {
            pageSize: {
                width: 1400,
                height: 700  
            },
            pageMargins: [ 20, 90, 20, 20 ],
            header: [
                {
                    margin: [20, 20, 20, 0],
                    text: 'REGISTRO DE VENTAS',
                    style: {
                        bold: true,
                        fontSize: 9,
                        alignment: 'left', 
                        lineHeight: 3
                    }
                },
                {
                    margin: [20, 0, 20, 0],
                    columns: [
                        {
                            stack: [
                                {
                                    columns: [
                                        {
                                            width: 50,
                                            text: 'PERIODO:',
                                            style: 'headerTitle'
                                        },
                                        {
                                            width: 500,
                                            text: `DEL ${stardate} AL ${endidate}`,
                                            style: 'headerTitle'
                                        }
                                    ]
                                },
                                {
                                    columns: [
                                        {
                                            width: 50,
                                            text: 'RUC:',
                                            style: 'headerTitle'
                                        },
                                        {
                                            width: 500,
                                            text: dashboardReducers.currentCompany,
                                            style: 'headerTitle'
                                        }
                                    ]
                                },
                                {
                                    columns: [
                                        {
                                            width: 50,
                                            text: 'EMPRESA:',
                                            style: 'headerTitle'
                                        },
                                        {
                                            width: 500,
                                            text: company.bussname.toUpperCase(),
                                            style: 'headerTitle'
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ],
            content: [
                {
                    table: {
                        headerRows: 2,
                        widths: [ 50, 50, 40, 15, 25, 25, 15, 40, 120, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 30, 20, 30, 40, 50 ],
                        body: []
                    }
                },
            ],
            styles: {
                headerTitle: {
                    bold: true,
                    fontSize: 7,
                    lineHeight: 1.2
                },
                table: {
                    layout: 'headerLineOnly',
                    fontSize: 6,
                    alignment: 'center'
                },
                subTable: {
                    layout: 'headerLineOnly',
                    fontSize: 6,
                    alignment: 'left'
                },
                tableDetailLeft: {
                    layout: 'headerLineOnly',
                    fontSize: 5.5,
                    alignment: 'left'
                },
                tableDetailRight: {
                    layout: 'headerLineOnly',
                    fontSize: 5.5,
                    alignment: 'right'
                },
                tableDetailRightBold: {
                    layout: 'headerLineOnly',
                    fontSize: 5.5,
                    alignment: 'right',
                    bold: true
                }
            },
            defaultStyle: {
                columnGap: 20,
            },
        }

        const tableBody: any = [
            [ 
                { text: '\n\nNÚMERO\nCORRELATIVO', style: 'table', rowSpan: 2, border: [true, true, true, true] },
                { text: '\n\nFECHA DE\nEMISIÓN DEL\nCOMPROBANTE', style: 'table', rowSpan: 2, border: [true, true, true, true] },
                { text: '\n\nFECHA\nDE\nVENC.', style: 'table', rowSpan: 2, border: [true, true, true, true] },
                { text: 'COMPROBANTE\nDE PAGO', style: 'table', colSpan: 3, border: [true, true, true, true] },{},{},
                { text: 'INFORMACION DEL\CLIENTE', style: 'table', colSpan: 3, border: [true, true, true, true] },{},{},
                { text: '\n\nVALOR FACTURADO DE LA EXPORTACIÓN', style: 'table', rowSpan: 2, border: [true, true, true, true] },
                { text: '\n\nBASE IMPONIBLE DE LA OPERACIÓN GRAVADA', style: 'table', rowSpan: 2, border: [true, true, true, true] },
                { text: 'IMPORTE TOTAL DE LA OPERACIÓN EXONERADA O INAFECTA', style: 'table', colSpan: 2, border: [true, true, true, true] },{},
                { text: '\n\n\n\nISC', style: 'table', rowSpan: 2, border: [true, true, true, true] },
                { text: '\n\n\nIGV Y/O IPM', style: 'table', rowSpan: 2, border: [true, true, true, true] },
                { text: 'OTROS TRIBUTOS Y CARGOS QUE NO FORMAN PARTE DE LA BASE IMPONIBLE', style: 'table', rowSpan: 2, border: [true, true, true, true] },
                { text: 'IMPORTE TOTAL DEL COMPROBANTE DE PAGO', style: 'table', rowSpan: 2, border: [true, true, true, true] },
                { text: '\n\n\nTIPO\nDE\nCAMBIO', style: 'table', rowSpan: 2, border: [true, true, true, true] },
                { text: 'REFERENCIA DEL COMPROBANTE DE\nPAGO\nO DOCUMENTO ORIGINAL QUE SE\nMODIFICA', style: 'table', colSpan: 4, border: [true, true, true, true] },{},{},{},
                { text: '\n\n\nCONDICION\nDE\nPAGO', style: 'table', rowSpan: 2, border: [true, true, true, true] }
            ],
            [ 
                { text: '', style: 'table', border: [true, false, true, true] },
                { text: '', style: 'table', border: [false, false, true, true] },
                { text: '', style: 'table', border: [false, false, true, true] },
                
                { text: 'TIPO', style: 'table', border: [false, false, true, true] },
                { text: 'NRO DE SERIE', style: 'table', border: [false, false, true, true] },
                { text: 'NÚMERO', style: 'table', border: [false, false, true, true] },
                
                { text: 'TIPO', style: 'table', border: [false, false, true, true] },
                { text: 'NÚMERO', style: 'table', border: [false, false, true, true] },
                { text: 'APELLIDOS Y NOMBRES, RAZÓN  SOCIAL', style: 'table', border: [false, false, true, true] },
                
                { text: '', style: 'table', border: [false, false, true, true] },
                { text: '', style: 'table', border: [false, false, true, true] },
                
                { text: 'EXONERADA', style: 'table', border: [false, false, true, true] },
                { text: 'INAFECTO', style: 'table', border: [false, false, true, true] },
                
                { text: '', style: 'table', border: [false, false, true, true] },
                
                { text: '', style: 'table', border: [false, false, true, true] },
                
                { text: '', style: 'table', border: [false, false, true, true] },
                
                { text: '', style: 'table', border: [false, false, true, true] },
                
                { text: '', style: 'table', border: [false, false, true, true] },
                
                { text: 'FECHA', style: 'table', border: [false, false, true, true] },
                { text: 'TIPO', style: 'table', border: [false, false, true, true] },
                { text: 'NRO DE SERIE', style: 'table', border: [false, false, true, true] },
                { text: 'NÚMERO', style: 'table', border: [false, false, true, true] },
                { text: '', style: 'table', border: [false, false, true, true] },
            ]
        ]

        let topgrava = 0.00;
        let totaltax = 0.00;
        let totnogra = 0.00;
        let topexone = 0.00;
        let totalamo = 0.00;

        for (const row of data) {
            tableBody.push(
                [ 
                    { text: row.unicodop, style: 'tableDetailLeft', border: [true, false, true, false] },
                    { text: moment(row.emitdate).format('DD/MM/YYYY'), style: 'tableDetailLeft', border: [true, false, true, false] },
                    { text: '', style: 'table', border: [true, false, true, false] },
                    { text: row.tppurcha, style: 'table', border: [true, false, true, false] },
                    { text: row.serialnu, style: 'table', border: [true, false, true, false] },
                    { text: row.documnum, style: 'table', border: [true, false, true, false] },
                    { text: row.typedocu, style: 'tableDetailLeft', border: [true, false, true, false] },
                    { text: row.docnumbe, style: 'table', border: [true, false, true, false] },
                    { text: row.businame.substr(0, 40), style: 'tableDetailLeft', border: [true, false, true, false] },
                    { text: '', style: 'tableDetailLeft', border: [true, false, true, false] },
                    { text: row.topgrava !== 0 ? String.formatNumber.new(row.topgrava) : '', style: 'tableDetailRight', border: [true, false, true, false] },
                    { text: row.topexone > 0 ? String.formatNumber.new(row.topexone) : '', style: 'tableDetailRight', border: [true, false, true, false] },
                    { text: row.topinafe > 0 ? String.formatNumber.new(row.topinafe) : '', style: 'tableDetailRight', border: [true, false, true, false] },
                    { text: '', style: 'tableDetailRight', border: [true, false, true, false] },
                    { text: row.totaltax !== 0 ? String.formatNumber.new(row.totaltax) : '', style: 'tableDetailRight', border: [true, false, true, false] },
                    { text: '', style: 'tableDetailRight', border: [true, false, true, false] },
                    { text: row.totalamo !== 0 ? String.formatNumber.new(row.totalamo) : '', style: 'tableDetailRight', border: [true, false, true, false] },
                    { text: row.curreval !== 0 ? String.formatNumber.new(row.curreval) : '', style: 'tableDetailRight', border: [true, false, true, false] },
                    { text: row.emitdref ? moment(row.emitdref).format('DD/MM/YYYY') : '', style: 'tableDetailRight', border: [true, false, true, false] },
                    { text: row.tppurref, style: 'tableDetailRight', border: [true, false, true, false] },
                    { text: row.seriaref, style: 'tableDetailRight', border: [true, false, true, false] },
                    { text: row.documref, style: 'tableDetailRight', border: [true, false, true, false] },
                    { text: row.paycondi === '00000009' ? 'CONTADO' : 'CREDITO', style: 'tableDetailRight', border: [true, false, true, false] }
                ]
            )

            topgrava += parseFloat(row.topgrava);
            totaltax += parseFloat(row.totaltax);
            totnogra += parseFloat(row.totnogra);
            topexone += parseFloat(row.topexone);
            totalamo += parseFloat(row.totalamo);
        }

        tableBody.push(
            [ 
                { text: '', style: 'tableDetailLeft', border: [false, true, false, false] },
                { text: '', style: 'tableDetailLeft', border: [false, true, false, false] },
                { text: '', style: 'table', border: [false, true, false, false] },
                { text: '', style: 'table', border: [false, true, false, false] },
                { text: '', style: 'table', border: [false, true, false, false] },
                { text: '', style: 'table', border: [false, true, false, false] },
                { text: '', style: 'tableDetailLeft', border: [false, true, false, false] },
                { text: '', style: 'tableDetailLeft', border: [false, true, false, false] },
                { text: 'TOTALES', style: 'tableDetailLeft', border: [true, true, true, true] },
                { text: '0.00', style: 'tableDetailRight', border: [true, true, true, true] },
                { text: String.formatNumber.new(topgrava), style: 'tableDetailRight', border: [true, true, true, true] },
                { text: String.formatNumber.new(topexone), style: 'tableDetailRight', border: [true, true, true, true] },
                { text: '0.00', style: 'tableDetailRight', border: [true, true, true, true] },
                { text: '0.00', style: 'tableDetailRight', border: [true, true, true, true] },
                { text: String.formatNumber.new(totaltax), style: 'tableDetailRight', border: [true, true, true, true] },
                { text: '0.00', style: 'tableDetailRight', border: [true, true, true, true] },
                { text: String.formatNumber.new(totalamo), style: 'tableDetailRight', border: [true, true, true, true] },
                { text: '', style: 'table', border: [false, true, false, false] },
                { text: '', style: 'table', border: [false, true, false, false] },
                { text: '', style: 'table', border: [false, true, false, false] },
                { text: '', style: 'table', border: [false, true, false, false] },
                { text: '', style: 'table', border: [false, true, false, false] },
                { text: '', style: 'table', border: [false, true, false, false] },
            ]
        )

        pdfObject.content[0].table.body = tableBody;

        pdfMake.fonts = {
            Roboto: {
                normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
                bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
                italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
                bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
              },
        }
        const pdfDocGenerator = pdfMake.createPdf(pdfObject);
        pdfDocGenerator.getBlob((data: any) => {
            var url = URL.createObjectURL(data);
            this.setState({
                base64Pdf: url
            })
        });
    }

    onGenerateExcel = () => {
        try {
            const data = this.props.accountantReducers.onlyOne;
            const { month, year } = this.state;
            const { dashboardReducers } = this.props;
            const mes = meses.find((x: any) => x.key === month).value;
            const tables = JSON.parse(localStorage.getItem('tables'));
            const company = tables.companys.find((x: any) => x.taxident === dashboardReducers.currentCompany);
            const division = company.lst_grl_division.find((x: any) => x.divicode === dashboardReducers.currentDivision);
            const oficina = division.lst_grl_bussofic.find((x: any) => x.officode === dashboardReducers.currentBussOfic);
            const date = new Date(parseInt(year), parseInt(month) - 1, 1);
            const stardate = moment(date).format('DD-MM-YYYY');
            const endidate = moment(date).daysInMonth() + moment(date).format('-MM-') + moment(date).format('YYYY');

            const tableBody: any = [
                { 
                    A: 'REGISTRO DE VENTAS',
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null,
                    G: null,
                    H: null,
                    I: null,
                    J: null,
                    K: null,
                    L: null,
                    M: null,
                    N: null,
                    O: null,
                    P: null,
                    Q: null,
                    R: null,
                    S: null,
                    T: null,
                    U: null,
                    V: null,
                    W: null
                },
                { 
                    A: `PERIODO: DEL ${stardate} AL ${endidate}`,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null,
                    G: null,
                    H: null,
                    I: null,
                    J: null,
                    K: null,
                    L: null,
                    M: null,
                    N: null,
                    O: null,
                    P: null,
                    Q: null,
                    R: null,
                    S: null,
                    T: null,
                    U: null,
                    V: null,
                    W: null
                },
                { 
                    A: `RUC: ${dashboardReducers.currentCompany}`,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null,
                    G: null,
                    H: null,
                    I: null,
                    J: null,
                    K: null,
                    L: null,
                    M: null,
                    N: null,
                    O: null,
                    P: null,
                    Q: null,
                    R: null,
                    S: null,
                    T: null,
                    U: null,
                    V: null,
                    W: null
                },
                { 
                    A: `EMPRESA: ${company.bussname.toUpperCase()}`,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null,
                    G: null,
                    H: null,
                    I: null,
                    J: null,
                    K: null,
                    L: null,
                    M: null,
                    N: null,
                    O: null,
                    P: null,
                    Q: null,
                    R: null,
                    S: null,
                    T: null,
                    U: null,
                    V: null,
                    W: null
                },
                { 
                    A: null,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null,
                    G: null,
                    H: null,
                    I: null,
                    J: null,
                    K: null,
                    L: null,
                    M: null,
                    N: null,
                    O: null,
                    P: null,
                    Q: null,
                    R: null,
                    S: null,
                    T: null,
                    U: null,
                    V: null,
                    W: null
                },
                { 
                    A: 'NÚMERO CORRELATIVO',
                    B: 'FECHA DE EMISIÓN DEL COMPROBANT DE COMPROBANTE',
                    C: 'FECHA DE VENC.',
                    D: 'COMPROBANTE DE PAGO',
                    E: '',
                    F: '',
                    G: 'INFORMACION DEL CLIENTE',
                    H: '',
                    I: '',
                    J: 'VALOR FACTURADO DE LA EXPORTACIÓN',
                    K: 'BASE IMPONIBLE DE LA OPERACIÓN GRAVADA',
                    L: 'IMPORTE TOTAL DE LA OPERACIÓN EXONERADA O INAFECTA',
                    M: '',
                    N: 'ISC',
                    O: 'IGV Y/O IPM',
                    P: 'OTROS TRIBUTOS Y CARGOS QUE NO FORMAN PARTE DE LA BASE IMPONIBLE',
                    Q: 'IMPORTE TOTAL DEL COMPROBANTE DE PAGO',
                    R: 'TIPO DE CAMBIO',
                    S: 'REFERENCIA DEL COMPROBANTE DE PAGO O DOCUMENTO ORIGINAL QUE SE MODIFICA',
                    T: '',
                    U: '',
                    V: '',
                    W: 'CONDICION DE PAGO'
                },
                { 
                    A: '',
                    B: '',
                    C: '',
                    D: 'TIPO',
                    E: 'NRO DE SERIE',
                    F: 'NÚMERO',
                    G: 'TIPO',
                    H: 'NÚMERO',
                    I: 'APELLIDOS Y NOMBRES, RAZÓN  SOCIAL',
                    J: '',
                    K: '',
                    L: 'EXONERADA',
                    M: 'INAFECTO',
                    N: '',
                    O: '',
                    P: '',
                    Q: '',
                    R: '',
                    S: 'FECHA',
                    T: 'TIPO',
                    U: 'NRO DE SERIE',
                    V: 'NÚMERO',
                    W: ''
                }
            ]

            let topgrava = 0.00;
            let totaltax = 0.00;
            let totnogra = 0.00;
            let topexone = 0.00;
            let totalamo = 0.00;

            for (const row of data) {
                tableBody.push(
                    {
                        A: row.unicodop,
                        B: moment(row.emitdate).format('DD/MM/YYYY'),
                        C: '',
                        D: row.tppurcha,
                        E: row.serialnu,
                        F: row.documnum,
                        G: row.typedocu,
                        H: row.docnumbe,
                        I: row.businame.substr(0, 40),
                        J: '',
                        K: row.topgrava !== 0 ? String.formatNumber.new(row.topgrava) : '',
                        L: row.topexone > 0 ? String.formatNumber.new(row.topexone) : '',
                        M: row.topinafe > 0 ? String.formatNumber.new(row.topinafe) : '',
                        N: '',
                        O: row.totaltax !== 0 ? String.formatNumber.new(row.totaltax) : '',
                        P: '',
                        Q: row.totalamo !== 0 ? String.formatNumber.new(row.totalamo) : '',
                        R: row.curreval !== 0 && row.currency !== '00000014' ? String.formatNumber.new(row.curreval) : '',
                        S: row.emitdref ? moment(row.emitdref).format('DD/MM/YYYY') : '',
                        T: row.tppurref,
                        U: row.seriaref,
                        V: row.documref,
                        W: row.paycondi === '00000009' ? 'CONTADO' : 'CREDITO'
                    }
                )

                topgrava += parseFloat(row.topgrava);
                totaltax += parseFloat(row.totaltax);
                totnogra += parseFloat(row.totnogra);
                topexone += parseFloat(row.topexone);
                totalamo += parseFloat(row.totalamo);
            }

            tableBody.push(
                {
                    A: '',
                    B: '',
                    C: '',
                    D: '',
                    E: '',
                    F: '',
                    G: '',
                    H: '',
                    I: 'TOTALES',
                    J: '0.00',
                    K: String.formatNumber.new(topgrava),
                    L: String.formatNumber.new(topexone),
                    M: '0.00',
                    N: '0.00',
                    O: String.formatNumber.new(totaltax),
                    P: '0.00',
                    Q: String.formatNumber.new(totalamo),
                    R: '',
                    S: '',
                    T: '',
                    U: '',
                    V: '',
                    W: ''
                }
            )

            const header = [
                "A" ,
                "B" ,
                "C" ,
                "D" ,
                "E" ,
                "F" ,
                "G" ,
                "H" ,
                "I" ,
                "J" ,
                "K" ,
                "L" ,
                "M" ,
                "N" ,
                "O" ,
                "P" ,
                "Q" ,
                "R" ,
                "S" ,
                "T" ,
                "U" ,
                "V" ,
                "W" 
            ];

            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(tableBody, {header, skipHeader: true});
            ws["!cols"] = [
                {wpx:70},{wpx:70},{wpx:60},{wpx:35},{wpx:40},{wpx:40},{wpx:35},{wpx:70},{wpx:150},{wpx:70},{wpx:70},{wpx:70},{wpx:70},{wpx:70},{wpx:70},
                {wpx:70},{wpx:60},{wpx:60},{wpx:50},{wpx:70},{wpx:60},{wpx:50},{wpx:50}
            ];
            ws['!rows'] = [
                {hpx: 18},{hpx: 16},{hpx: 16},{hpx: 16},{hpx: 16},{hpx: 50},{hpx: 30}
            ];

            const keys = Object.keys(ws);
            const rowsCount = tableBody.length;
            
            for (const iterator of keys) {
                if(!isNaN(parseInt(ws[iterator].v)) && iterator.match(/^(K|L|M|N|O|P|Q|R|)[0-9]+$/g)) {
                    ws[iterator].v = ws[iterator].v.replace(/,/g, '');
                    ws[iterator].t = 'n';
                    ws[iterator].z = '#,##0.00';
                }
                if(iterator !== '!ref' && iterator !== '!cols' && iterator !== '!rows') {
                    ws[iterator].s = {
                        font: {
                            name: 'Tahoma',
                            sz: 8
                        }
                    }
                    const row = +(iterator.match(/\d+/)[0]);
                    if(row >= 8 && row !== rowsCount) {
                        ws[iterator].s.border = {
                            left: { style: 'thin', color: 'black'},
                            right: { style: 'thin', color: 'black'}
                        }
                        if(iterator.match(/^(D|E|F)[0-9]+$/g)) {
                            ws[iterator].s.alignment = { wrapText: true, vertical: 'center', horizontal: 'center'}
                        }
                    }
                    if(row === rowsCount) {
                        ws[iterator].s.border = {
                            ...ws[iterator].s.border,
                            top: { style: 'thin', color: 'black'}
                        }
                        if(iterator.match(/^(J|K|L|M|N|O|P|Q|R|S|T)[0-9]+$/g)) {
                            ws[iterator].s.border = {
                                ...ws[iterator].s.border,
                                bottom: { style: 'thin', color: 'black'},
                                top: { style: 'thin', color: 'black'},
                                left: { style: 'thin', color: 'black'},
                                right: { style: 'thin', color: 'black'}
                            }
                        }
                    }
                }
            }

            for (let index = 8; index <= rowsCount; index++) {
                ws['!rows'].push({hpx: 12})
            }

            ws['A1'].s = {font:{name: 'Tahoma', sz: 15, bold: true}}
            ws['A2'].s = {font:{name: 'Tahoma', sz: 8, bold: true}}
            ws['A3'].s = {font:{name: 'Tahoma', sz: 8, bold: true}}
            ws['A4'].s = {font:{name: 'Tahoma', sz: 8, bold: true}}

            for (const iterator of header) {
                const key1 = iterator + "6";
                const key2 = iterator + "7";

                ws[key1].s = {
                    ...ws[key1].s, 
                    alignment: { wrapText: true, vertical: 'center', horizontal: 'center'},
                    border: { 
                        top: { style: 'thin', color: 'black'},
                        bottom: { style: 'thin', color: 'black'},
                        left: { style: 'thin', color: 'black'},
                        right: { style: 'thin', color: 'black'}
                    } 
                }
                ws[key2].s = {
                    ...ws[key2].s, 
                    alignment: { wrapText: true, vertical: 'center', horizontal: 'center'},
                    border: { 
                        top: { style: 'thin', color: 'black'},
                        bottom: { style: 'thin', color: 'black'},
                        left: { style: 'thin', color: 'black'},
                        right: { style: 'thin', color: 'black'}
                    } 
                }
            };

            ws["!merges"] = [
                { s: { r: 5, c: 0 }, e: { r: 6, c: 0 } },
                { s: { r: 5, c: 1 }, e: { r: 6, c: 1 } },
                { s: { r: 5, c: 2 }, e: { r: 6, c: 2 } },
                { s: { r: 5, c: 3 }, e: { r: 5, c: 5 } },
                { s: { r: 5, c: 6 }, e: { r: 5, c: 8 } },
                { s: { r: 5, c: 9 }, e: { r: 6, c: 9 } },
                { s: { r: 5, c: 10 }, e: { r: 6, c: 10 } },
                { s: { r: 5, c: 11 }, e: { r: 5, c: 12 } },
                { s: { r: 5, c: 13 }, e: { r: 6, c: 13 } },
                { s: { r: 5, c: 14 }, e: { r: 6, c: 14 } },
                { s: { r: 5, c: 15 }, e: { r: 6, c: 15 } },
                { s: { r: 5, c: 16 }, e: { r: 6, c: 16 } },
                { s: { r: 5, c: 17 }, e: { r: 6, c: 17 } },
                { s: { r: 5, c: 18 }, e: { r: 5, c: 21 } },
                { s: { r: 5, c: 22 }, e: { r: 6, c: 22 } },
            ];

            XLSX.utils.book_append_sheet(wb, ws, "Sales");

            var wbout = XLSX.write(wb, {bookType:'xlsx', type: 'binary', cellStyles: true});

            const s2ab = function(s) {
                var buf = new ArrayBuffer(s.length);
                var view = new Uint8Array(buf);
                for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
                return buf;
            }

            saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), `registro-ventas-${year}-${month}.xlsx`);

        } catch(err) {
            console.log(err);
        }
    }

    componentDidMount() {
        const { dashboardReducers } = this.props;
        this.setState({
            officode: dashboardReducers.currentDivision,
            divicode: dashboardReducers.currentBussOfic,
        })
    }

    render() {

        const { title, year, month, divicode, officode, base64Pdf, todo } = this.state;
        const { loading, list, tables, failed, failedTitle, failedContent } = this.props.accountantReducers;
        const { classes, dashboardReducers } = this.props;
        const company = JSON.parse(localStorage.getItem('tables')).companys.find((x: any) => x.taxident === dashboardReducers.currentCompany);

        return (
            <div style={{overflow: 'auto', height: '100%'}}>
                <Paper className='form__container'>
                    <Typography variant='h5'>{ title }</Typography>
                    <br/>
                    { failed && <MessageBox title={failedTitle} content={failedContent}/> }
                    <div style={{ display: 'flex' }}>
                        <form noValidate autoComplete="off" style={{ flex: 1, paddingRight: '15px', paddingTop: '2em' }}>
                            <Grid container spacing={2} >
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_año">Año</InputLabel>
                                            <Select
                                                labelId="lbl_año"
                                                id="year"
                                                className='select'
                                                label="Año"
                                                fullWidth
                                                value={ year }
                                                onChange={(e: any) => this.onChangeRoot({'year': e.target.value})}
                                            >
                                                <MenuItem value='2030'>2030</MenuItem>
                                                <MenuItem value='2029'>2029</MenuItem>
                                                <MenuItem value='2028'>2028</MenuItem>
                                                <MenuItem value='2027'>2027</MenuItem>
                                                <MenuItem value='2026'>2026</MenuItem>
                                                <MenuItem value='2025'>2025</MenuItem>
                                                <MenuItem value='2026'>2026</MenuItem>
                                                <MenuItem value='2025'>2025</MenuItem>
                                                <MenuItem value='2024'>2024</MenuItem>
                                                <MenuItem value='2023'>2023</MenuItem>
                                                <MenuItem value='2022'>2022</MenuItem>
                                                <MenuItem value='2021'>2021</MenuItem>
                                                <MenuItem value='2020'>2020</MenuItem>
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_mes">Mes</InputLabel>
                                            <Select
                                                labelId="lbl_mes"
                                                id="month"
                                                className='select'
                                                label="Mes"
                                                fullWidth
                                                value={ month }
                                                onChange={(e: any) => this.onChangeRoot({'month': e.target.value})}
                                            >
                                                {
                                                    meses.map((x: any) => 
                                                        <MenuItem value={x.key} key={x.key}>{x.value}</MenuItem>
                                                    )
                                                }
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth disabled={company === '' ? true : false}>
                                        <InputLabel id="lbl_division">Division</InputLabel>
                                        <Select
                                            labelId="lbl_division"
                                            id="division"
                                            value={divicode}
                                            onChange={(e: React.ChangeEvent<{ value: unknown }>) => { this.onChangeRoot({'divicode': e.target.value}) }}
                                            label="Division"
                                            fullWidth
                                        >
                                            {
                                                company.lst_grl_division.map((x: any) => 
                                                    <MenuItem value={x.divicode} key={x.divicode}>{ x.diviname }</MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth disabled={divicode === '' ? true : false}>
                                        <InputLabel id="lbl_oficina">Oficina</InputLabel>
                                        <Select
                                            labelId="lbl_oficina"
                                            id="oficina"
                                            value={officode}
                                            onChange={(e: React.ChangeEvent<{ value: unknown }>) => { this.onChangeRoot({'officode': e.target.value}) }}
                                            label="Oficina"
                                            fullWidth
                                        >
                                            {
                                                company.lst_grl_division.find((x) => x.divicode === divicode)?.lst_grl_bussofic.map((x: any) => 
                                                    <MenuItem value={x.officode} key={x.officode}>{ x.offiname }</MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={todo}
                                                onChange={(e: any) => this.onChangeRoot({'todo': e.target.checked})}
                                                name="todo"
                                                color="primary"
                                            />
                                        }
                                        label="Acumulado"
                                    />
                                </Grid>
                                <Grid item xs={12} style={{ paddingTop: '50px' }}>
                                    <Button variant="contained" fullWidth color="primary" onClick={() => {
                                        this.props.salesRegisterRequest(officode, year, month, todo, this.onGeneratePdf) 
                                    }}>
                                        Generar Reporte PDF
                                    </Button>
                                </Grid>
                                <Grid item xs={12} style={{ paddingTop: '10px' }}>
                                    <Button variant="contained" fullWidth color="inherit" onClick={() => 
                                        this.props.salesRegisterRequest(officode, year, month, todo, this.onGenerateExcel) }>
                                        Generar Reporte EXCEL
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                        <PdfView data={base64Pdf} style={{ flex: 2 }}/>
                    </div>
                    <br/>
                    {/* <FormFooter onCancel={ this.onCancel } onSubmit={ this.onSubmit }/> */}
                </Paper>
                <ScreenLoader enabled={loading}/>
            </div>
        );
    }
}

const accountantReducers = 'accountantReducers';
const dashboardReducers = 'dashboardReducers';

const mapStateToProps = (state: any) => ({
    accountantReducers: state.get(accountantReducers),
    dashboardReducers: state.get(dashboardReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        salesRegisterRequest
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(ReportSalesRegister));