import {
    call, fork, put, take, takeEvery, all
} from 'redux-saga/effects';
import history from '../../utils/history';
import pipelines from 'services';
import { 
    creditNoteDeleteRequestFailed,
    creditNoteGetAllRecordsRequestFailed,
    creditNoteGetAllRecordsRequestSuccess,
    creditNoteInitFormRequestFailed,
    creditNoteInitFormRequestSuccess,
    creditNoteInitUpdateFormRequestFailed,
    creditNoteInitUpdateFormRequestSuccess,
    creditNoteInsertRequestFailed,
    creditNoteInsertRequestSuccess,
    creditNoteUpdateRequestFailed,
    debitNoteDeleteRequestFailed,
    debitNoteGetAllRecordsRequestFailed,
    debitNoteGetAllRecordsRequestSuccess,
    debitNoteInitFormRequestFailed,
    debitNoteInitFormRequestSuccess,
    debitNoteInitUpdateFormRequestFailed,
    debitNoteInitUpdateFormRequestSuccess,
    debitNoteInsertRequestFailed,
    debitNoteInsertRequestSuccess,
    debitNoteUpdateRequestFailed,
    invoicesPayRequestFailed,
    invoicesPayRequestSuccess,
    purchasesDeleteRequestFailed,
    purchasesGetAllRecordsRequestFailed,
    purchasesGetAllRecordsRequestSuccess,
    purchasesInitFormRequestFailed,
    purchasesInitFormRequestSuccess,
    purchasesInitUpdateFormRequestFailed,
    purchasesInitUpdateFormRequestSuccess,
    purchasesInsertRequestFailed,
    purchasesInsertRequestSuccess,
    purchasesMassiveUpdateRequestFailed,
    purchasesUpdateRequestFailed,
    suppliersDeleteRequestFailed,
    suppliersGetAllRecordsRequestFailed,
    suppliersGetAllRecordsRequestSuccess,
    suppliersInitFormRequestFailed,
    suppliersInitFormRequestSuccess, 
    suppliersInitUpdateFormRequestFailed, 
    suppliersInitUpdateFormRequestSuccess,
    suppliersInsertRequestFailed,
    suppliersInsertRequestSuccess,
    suppliersUpdateRequestFailed
} from 'penta-redux/actions/shoppingsActions';
import { purchaseEntity, supplierEntity } from 'models/shoppingModels';
import { 
    CREDIT_NOTE_DELETE_REQUEST,
    CREDIT_NOTE_GET_ALL_RECORDS_REQUEST,
    CREDIT_NOTE_INIT_FORM_REQUEST,
    CREDIT_NOTE_INIT_UPDATE_FORM_REQUEST,
    CREDIT_NOTE_INSERT_REQUEST,
    CREDIT_NOTE_UPDATE_REQUEST,
    DEBIT_NOTE_DELETE_REQUEST,
    DEBIT_NOTE_GET_ALL_RECORDS_REQUEST,
    DEBIT_NOTE_INIT_FORM_REQUEST,
    DEBIT_NOTE_INIT_UPDATE_FORM_REQUEST,
    DEBIT_NOTE_INSERT_REQUEST,
    DEBIT_NOTE_UPDATE_REQUEST,
    INVOICES_PAY_REQUEST,
    PURCHASES_ANULATE_REQUEST,
    PURCHASES_DELETE_REQUEST,
    PURCHASES_GET_ALL_RECORDS_REQUEST,
    PURCHASES_INIT_FORM_REQUEST,
    PURCHASES_INIT_UPDATE_FORM_REQUEST,
    PURCHASES_INSERT_REQUEST,
    PURCHASES_MASSIVE_UPDATE_REQUEST,
    PURCHASES_UPDATE_REQUEST,
    SUPPLIERS_DELETE_REQUEST,
    SUPPLIERS_GET_ALL_RECORDS_REQUEST, 
    SUPPLIERS_INIT_FORM_REQUEST,
    SUPPLIERS_INIT_UPDATE_FORM_REQUEST,
    SUPPLIERS_INSERT_REQUEST,
    SUPPLIERS_UPDATE_REQUEST
} from 'penta-redux/constants/shoppingsConstants';
import { locationsGetProvincesSuccess } from 'penta-redux/actions/generalActions';

//#region SUPPLIERS

function* suppliersInitFormSaga(action: any) {
    try {
        const data = (yield call(pipelines.shoppingsPipeline.suppliersInitForm, action.fiscyear)).data;
        yield put(suppliersInitFormRequestSuccess(data.data.tables));
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(suppliersInitFormRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* suppliersInitUpdateFormSaga(action: any) {
    try {
        const data = (yield call(pipelines.shoppingsPipeline.suppliersInitUpdateForm, action.docnumbe)).data;
        yield put(suppliersInitUpdateFormRequestSuccess(data.data.tables, data.data.onlyOne));
        yield put(locationsGetProvincesSuccess(data.data.tables));
        yield put(locationsGetProvincesSuccess(data.data.tables));
        yield call(action.callback, data.data.onlyOne, data.data.tables.provincias[0].ploccode, data.data.tables.distritos[0].ploccode);
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(suppliersInitUpdateFormRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* suppliersGetAllRecordsSaga(action: any) {
    try {
        const data = (yield call(pipelines.shoppingsPipeline.suppliersGetAllRecords, action.body)).data;
        yield put(suppliersGetAllRecordsRequestSuccess(data.data.list, data.data.total));
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(suppliersGetAllRecordsRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* suppliersInsertSaga(action: any) {
    try {
        yield call(pipelines.shoppingsPipeline.suppliersInsert, action.body);
        if (action.callback !== null) {
            yield call(action.callback);
            yield put(suppliersInsertRequestSuccess());
        }
        else
            yield history.push('/papp/shopping/proveedores');
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(suppliersInsertRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* suppliersUpdateSaga(action: any) {
    try {
        const data = (yield call(pipelines.shoppingsPipeline.suppliersUpdate, (action.body as supplierEntity).docnumbe, action.body)).data;
        yield history.push('/papp/shopping/proveedores');
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(suppliersUpdateRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* suppliersDeleteSaga(action: any) {
    try {
        const data = (yield call(pipelines.shoppingsPipeline.suppliersDelete, action.docnumbes)).data;
        yield put({ type: SUPPLIERS_GET_ALL_RECORDS_REQUEST, body: {} });
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(suppliersDeleteRequestFailed(`Error: ${data.code}`, data.message));
    }
}

//#endregion

//#region PURCHASES

function* purchasesInitFormSaga(action: any) {
    try {
        const data = (yield call(pipelines.shoppingsPipeline.purchasesInitForm)).data;
        yield put(purchasesInitFormRequestSuccess(data.data.tables));
        yield call(action.callback, data.data.tables);
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(purchasesInitFormRequestFailed(`Error: ${err.code || '0x0'}`, err.message || err));
    }
}

function* purchasesInitUpdateFormSaga(action: any) {
    try {
        const data = (yield call(pipelines.shoppingsPipeline.purchasesInitUpdateForm, action.serialnu, action.documnum, action.docsuppl)).data;
        yield put(purchasesInitUpdateFormRequestSuccess(data.data.tables, data.data.onlyOne));
        yield call(action.callback, data.data.onlyOne);
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(purchasesInitUpdateFormRequestFailed(`Error: ${err.code || '0x0'}`, err.message || err));
    }
}

function* purchasesGetAllRecordsSaga(action: any) {
    try {
        const data = (yield call(pipelines.shoppingsPipeline.purchasesGetAllRecords, action.body)).data;
        yield put(purchasesGetAllRecordsRequestSuccess(data.data.list, data.data.total));
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(purchasesGetAllRecordsRequestFailed(`Error: ${err.code || '0x0'}`, err.message || err));
    }
}

function* purchasesInsertSaga(action: any) {
    try {
        yield call(pipelines.shoppingsPipeline.purchasesInsert, action.body);
        if (action.callback !== null) {
            yield call(action.callback);
            yield put(purchasesInsertRequestSuccess());
        }
        else
            yield history.push('/papp/shopping/compras');
    } catch (error) {
        console.log(error.response);
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(purchasesInsertRequestFailed(`Error: ${err.code || '0x0'}`, err.message || err));
    }
}

function* purchasesUpdateSaga(action: any) {
    try {
        const data = (yield call(pipelines.shoppingsPipeline.purchasesUpdate, (action.body as purchaseEntity).serialnu, (action.body as purchaseEntity).documnum, (action.body as purchaseEntity).docsuppl, action.body)).data;
        yield history.push('/papp/shopping/compras');
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(purchasesUpdateRequestFailed(`Error: ${err.code || '0x0'}`, err.message || err));
    }
}

function* purchasesDeleteSaga(action: any) {
    try {
        const data = (yield call(pipelines.shoppingsPipeline.purchasesDelete, action.serialnus, action.documnums, action.docsuppls)).data;
        yield put({ type: PURCHASES_GET_ALL_RECORDS_REQUEST, body: action.filters });
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(purchasesDeleteRequestFailed(`Error: ${err.code || '0x0'}`, err.message || err));
    }
}

function* purchasesAnulateSaga(action: any) {
    try {
        const data = (yield call(pipelines.shoppingsPipeline.purchasesAnulate, action.serialnus, action.documnums, action.docsuppls)).data;
        yield put({ type: PURCHASES_GET_ALL_RECORDS_REQUEST, body: {} });
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(purchasesDeleteRequestFailed(`Error: ${err.code || '0x0'}`, err.message || err));
    }
}

function* purchasesMassiveUpdateSaga(action: any) {
    try {
        for (const iterator of action.body) {
            try {
                yield call(pipelines.shoppingsPipeline.purchasesInsert, iterator);
            } catch { }
            yield call((async (ms) => { return new Promise(resolve => setTimeout(resolve, ms)) }), 1000)
        }
        // yield history.push('/papp/shopping/compras');
        yield put({ type: PURCHASES_GET_ALL_RECORDS_REQUEST, body: {} });
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(purchasesMassiveUpdateRequestFailed(`Error: ${err.code || '0x0'}`, err.message || err));
    }
}

//#endregion

//#region CREDIT NOTE

function* creditNoteInitFormSaga(action: any) {
    try {
        const data = (yield call(pipelines.shoppingsPipeline.creditNoteInitForm)).data;
        yield put(creditNoteInitFormRequestSuccess(data.data.tables));
        yield call(action.callback);
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(creditNoteInitFormRequestFailed(`Error: ${err.code}`, err.message));
    }
}

function* creditNoteInitUpdateFormSaga(action: any) {
    try {
        const data = (yield call(pipelines.shoppingsPipeline.creditNoteInitUpdateForm, action.serialnu, action.documnum, action.docsuppl)).data;
        yield put(creditNoteInitUpdateFormRequestSuccess(data.data.tables, data.data.onlyOne));
        yield call(action.callback, data.data.onlyOne);
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(creditNoteInitUpdateFormRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* creditNoteGetAllRecordsSaga(action: any) {
    try {
        const data = (yield call(pipelines.shoppingsPipeline.creditNoteGetAllRecords, action.body)).data;
        yield put(creditNoteGetAllRecordsRequestSuccess(data.data.list, data.data.total));
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(creditNoteGetAllRecordsRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* creditNoteInsertSaga(action: any) {
    try {
        yield call(pipelines.shoppingsPipeline.creditNoteInsert, action.body);
        if (action.callback !== null) {
            yield call(action.callback);
            yield put(creditNoteInsertRequestSuccess());
        }
        else
            yield history.push('/papp/shopping/notas-credito');
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(creditNoteInsertRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* creditNoteUpdateSaga(action: any) {
    try {
        const data = (yield call(pipelines.shoppingsPipeline.creditNoteUpdate, action.body.serialnu, action.body.documnum, action.body.docsuppl, action.body)).data;
        yield history.push('/papp/shopping/notas-credito');
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(creditNoteUpdateRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* creditNoteDeleteSaga(action: any) {
    try {
        const data = (yield call(pipelines.shoppingsPipeline.creditNoteDelete, action.serialnus, action.documnums, action.docsuppls)).data;
        yield put({ type: CREDIT_NOTE_GET_ALL_RECORDS_REQUEST, body: {} });
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(creditNoteDeleteRequestFailed(`Error: ${data.code}`, data.message));
    }
}

//#endregion

//#region DEBIT NOTE

function* debitNoteInitFormSaga(action: any) {
    try {
        const data = (yield call(pipelines.shoppingsPipeline.debitNoteInitForm)).data;
        yield put(debitNoteInitFormRequestSuccess(data.data.tables));
        yield call(action.callback);
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(debitNoteInitFormRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* debitNoteInitUpdateFormSaga(action: any) {
    try {
        const data = (yield call(pipelines.shoppingsPipeline.debitNoteInitUpdateForm, action.serialnu, action.documnum, action.docsuppl)).data;
        yield put(debitNoteInitUpdateFormRequestSuccess(data.data.tables, data.data.onlyOne));
        yield call(action.callback, data.data.onlyOne);
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(debitNoteInitUpdateFormRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* debitNoteGetAllRecordsSaga(action: any) {
    try {
        const data = (yield call(pipelines.shoppingsPipeline.debitNoteGetAllRecords, action.body)).data;
        yield put(debitNoteGetAllRecordsRequestSuccess(data.data.list, data.data.total));
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(debitNoteGetAllRecordsRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* debitNoteInsertSaga(action: any) {
    try {
        yield call(pipelines.shoppingsPipeline.debitNoteInsert, action.body);
        if (action.callback !== null) {
            yield call(action.callback);
            yield put(debitNoteInsertRequestSuccess());
        }
        else
            yield history.push('/papp/shopping/notas-debito');
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(debitNoteInsertRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* debitNoteUpdateSaga(action: any) {
    try {
        const data = (yield call(pipelines.shoppingsPipeline.debitNoteUpdate, action.body.serialnu, action.body.documnum, action.body.docsuppl, action.body)).data;
        yield history.push('/papp/shopping/notas-debito');
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(debitNoteUpdateRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* debitNoteDeleteSaga(action: any) {
    try {
        const data = (yield call(pipelines.shoppingsPipeline.debitNoteDelete, action.serialnus, action.documnums, action.docsuppls)).data;
        yield put({ type: DEBIT_NOTE_GET_ALL_RECORDS_REQUEST, body: {} });
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(debitNoteDeleteRequestFailed(`Error: ${data.code}`, data.message));
    }
}

//#endregion

//#region REPORTS

function* invoicesPaySaga(action: any) {
    try {
        const data = (yield call(pipelines.shoppingsPipeline.invoicesPay, action.initdate, action.endidate)).data;
        yield put(invoicesPayRequestSuccess(data.data.onlyOne));
        yield call(action.callback);
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(invoicesPayRequestFailed(`Error: ${err.code}`, err.message));
    }
}

//#endregion

function* shoppingsRootSaga() {
    yield all([
        takeEvery(SUPPLIERS_INIT_FORM_REQUEST, suppliersInitFormSaga),
        takeEvery(SUPPLIERS_INIT_UPDATE_FORM_REQUEST, suppliersInitUpdateFormSaga),
        takeEvery(SUPPLIERS_GET_ALL_RECORDS_REQUEST, suppliersGetAllRecordsSaga),
        takeEvery(SUPPLIERS_INSERT_REQUEST, suppliersInsertSaga),
        takeEvery(SUPPLIERS_UPDATE_REQUEST, suppliersUpdateSaga),
        takeEvery(SUPPLIERS_DELETE_REQUEST, suppliersDeleteSaga),
        takeEvery(PURCHASES_INIT_FORM_REQUEST, purchasesInitFormSaga),
        takeEvery(PURCHASES_INIT_UPDATE_FORM_REQUEST, purchasesInitUpdateFormSaga),
        takeEvery(PURCHASES_GET_ALL_RECORDS_REQUEST, purchasesGetAllRecordsSaga),
        takeEvery(PURCHASES_INSERT_REQUEST, purchasesInsertSaga),
        takeEvery(PURCHASES_UPDATE_REQUEST, purchasesUpdateSaga),
        takeEvery(PURCHASES_DELETE_REQUEST, purchasesDeleteSaga),
        takeEvery(PURCHASES_ANULATE_REQUEST, purchasesAnulateSaga),
        takeEvery(PURCHASES_MASSIVE_UPDATE_REQUEST, purchasesMassiveUpdateSaga),
        takeEvery(CREDIT_NOTE_INIT_FORM_REQUEST, creditNoteInitFormSaga),
        takeEvery(CREDIT_NOTE_INIT_UPDATE_FORM_REQUEST, creditNoteInitUpdateFormSaga),
        takeEvery(CREDIT_NOTE_GET_ALL_RECORDS_REQUEST, creditNoteGetAllRecordsSaga),
        takeEvery(CREDIT_NOTE_INSERT_REQUEST, creditNoteInsertSaga),
        takeEvery(CREDIT_NOTE_UPDATE_REQUEST, creditNoteUpdateSaga),
        takeEvery(CREDIT_NOTE_DELETE_REQUEST, creditNoteDeleteSaga),
        takeEvery(DEBIT_NOTE_INIT_FORM_REQUEST, debitNoteInitFormSaga),
        takeEvery(DEBIT_NOTE_INIT_UPDATE_FORM_REQUEST, debitNoteInitUpdateFormSaga),
        takeEvery(DEBIT_NOTE_GET_ALL_RECORDS_REQUEST, debitNoteGetAllRecordsSaga),
        takeEvery(DEBIT_NOTE_INSERT_REQUEST, debitNoteInsertSaga),
        takeEvery(DEBIT_NOTE_UPDATE_REQUEST, debitNoteUpdateSaga),
        takeEvery(DEBIT_NOTE_DELETE_REQUEST, debitNoteDeleteSaga),
        takeEvery(INVOICES_PAY_REQUEST, invoicesPaySaga)
    ]);
}
  
const shoppingsRootSagas = [
    fork(shoppingsRootSaga),
];
  
export default shoppingsRootSagas;