import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { authLogoutRequest } from 'redux/actions/authActions';
import {createUseStyles} from 'react-jss';

const useStyles = createUseStyles({
    container: {
        'position': 'absolute',
        'top': 0,
        'backgroundColor': '#00000029',
        'height': '100vh',
        'width': '100vw',
        'left': 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 100
    },
    modalContainer: {
        minWidth: '30rem',
        width: '40rem',
        backgroundColor: 'white',
        boxShadow: '0rem 0.188rem 0.375rem #00000029'
    },
    modalHeader: {
        display: 'flex',
        padding: '1.2rem',
        fontFamily: '"Open Sans", sans-serif',
        color: '#888888',
        fontSize: '0.875rem',
        fontWeight: 'bold',
        borderBottom: '0.063rem solid #DFDFDF',
        '& div:nth-child(1)': {
            flex: 1
        },
        '& div:last-child': {
            cursor: 'pointer'
        }
    },
    modalBody: {
        padding: '1.4rem 1.2rem',
        fontFamily: '"Work Sans", sans-serif',
        color: '#3B3B3B',
        fontSize: '0.75rem',
        fontWeight: '400',
        borderBottom: '0.063rem solid #DFDFDF',
    },
    modalFooter: {
        padding: '0.5rem',
        display: 'flex',
        justifyContent: 'flex-end'
    }
});

interface Modal {
    onClose: () => void
    children: React.ReactNode
    footer: React.ReactNode
    title?: string
    // authLogoutRequest: typeof authLogoutRequest
}

const Modal = (props: Modal) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={classes.modalContainer}>
                <div className={classes.modalHeader}>
                    <div>{props.title}</div>
                    <div onClick={props.onClose}>x</div>
                </div>
                <div className={classes.modalBody}>
                    {props.children}
                </div>
                <div className={classes.modalFooter}>
                    {props.footer}
                </div>
            </div>
        </div>
    )
}

Modal.defaultProps = {
    title: 'Penta ERP'
}

export default Modal;