import * as types from 'penta-redux/constants/wharehouseProductsConstants';
import { baseModels } from 'penta-models';

/** CATEGORIAS */
export const categorysInitFormRequest = (fiscyear: string) => ({
    type: types.CATEGORYS_INIT_FORM_REQUEST,
    fiscyear
});

export const categorysInitFormRequestSuccess = (tables: any) => ({
    type: types.CATEGORYS_INIT_FORM_REQUEST_SUCCESS,
    tables
});

export const categorysInitFormRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.CATEGORYS_INIT_FORM_REQUEST_FAILED,
    titleError,
    titleContent
});

export const categorysInitUpdateFormRequest = (ctgrcode: string, fiscyear: number, callback: void) => ({
    type: types.CATEGORYS_INIT_UPDATE_FORM_REQUEST,
    ctgrcode,
    fiscyear,
    callback
});

export const categorysInitUpdateFormRequestSuccess = (tables: any, onlyOne: any) => ({
    type: types.CATEGORYS_INIT_UPDATE_FORM_REQUEST_SUCCESS,
    tables,
    onlyOne
});

export const categorysInitUpdateFormRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.CATEGORYS_INIT_UPDATE_FORM_REQUEST_FAILED,
    titleError,
    titleContent
});

export const categorysGetAllRecordsRequest = (body: baseModels.pentaRequestQuery) => ({
    type: types.CATEGORYS_GET_ALL_RECORDS_REQUEST,
    body
});

export const categorysGetAllRecordsRequestSuccess = (list: Array<any>, total: number) => ({
    type: types.CATEGORYS_GET_ALL_RECORDS_REQUEST_SUCCESS,
    total,
    list
});

export const categorysGetAllRecordsRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.CATEGORYS_GET_ALL_RECORDS_REQUEST_FAILED,
    titleError,
    titleContent
});

export const categorysInsertRequest = (body: any, callback?: void) => ({
    type: types.CATEGORYS_INSERT_REQUEST,
    body,
    callback
});

export const categorysInsertRequestSuccess = () => ({
    type: types.CATEGORYS_INSERT_REQUEST_SUCCESS
});

export const categorysInsertRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.CATEGORYS_INSERT_REQUEST_FAILED,
    titleError,
    titleContent
});

export const categorysUpdateRequest = (body: any) => ({
    type: types.CATEGORYS_UPDATE_REQUEST,
    body
});

export const categorysUpdateRequestSuccess = () => ({
    type: types.CATEGORYS_UPDATE_REQUEST_SUCCESS
});

export const categorysUpdateRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.CATEGORYS_UPDATE_REQUEST_FAILED,
    titleError,
    titleContent
});

export const categorysDeleteRequest = (ctgrcodes: Array<string>) => ({
    type: types.CATEGORYS_DELETE_REQUEST,
    ctgrcodes
});

export const categorysDeleteRequestSuccess = () => ({
    type: types.CATEGORYS_DELETE_REQUEST_SUCCESS
});

export const categorysDeleteRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.CATEGORYS_DELETE_REQUEST_FAILED,
    titleError,
    titleContent
});

/*Components */
export const categorysListSelectItem = (key: number) => ({
    type: types.CATEGORYS_LIST_SELECT_ITEM,
    key
});

export const categorysListSelectAllItem = (checked: boolean) => ({
    type: types.CATEGORYS_LIST_SELECT_ALL_ITEM,
    checked
});
export const categorysActionFailed = (titleError: string, titleContent: string) => ({
    type: types.CATEGORYS_ACTION_FAILED,
    titleError,
    titleContent
});


/** FAMILIAS */
export const familiesInitFormRequest = () => ({
    type: types.FAMILIES_INIT_FORM_REQUEST
});

export const familiesInitFormRequestSuccess = (tables: any) => ({
    type: types.FAMILIES_INIT_FORM_REQUEST_SUCCESS,
    tables
});

export const familiesInitFormRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.FAMILIES_INIT_FORM_REQUEST_FAILED,
    titleError,
    titleContent
});

export const familiesInitUpdateFormRequest = (famicode: string, callback: void) => ({
    type: types.FAMILIES_INIT_UPDATE_FORM_REQUEST,
    famicode,
    callback
});

export const familiesInitUpdateFormRequestSuccess = (tables: any, onlyOne: any) => ({
    type: types.FAMILIES_INIT_UPDATE_FORM_REQUEST_SUCCESS,
    tables,
    onlyOne
});

export const familiesInitUpdateFormRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.FAMILIES_INIT_UPDATE_FORM_REQUEST_FAILED,
    titleError,
    titleContent
});

export const familiesGetAllRecordsRequest = (body: baseModels.pentaRequestQuery) => ({
    type: types.FAMILIES_GET_ALL_RECORDS_REQUEST,
    body
});

export const familiesGetAllRecordsRequestSuccess = (list: Array<any>, total: number) => ({
    type: types.FAMILIES_GET_ALL_RECORDS_REQUEST_SUCCESS,
    total,
    list
});

export const familiesGetAllRecordsRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.FAMILIES_GET_ALL_RECORDS_REQUEST_FAILED,
    titleError,
    titleContent
});

export const familiesInsertRequest = (body: any, callback?: void) => ({
    type: types.FAMILIES_INSERT_REQUEST,
    body,
    callback
});

export const familiesInsertRequestSuccess = () => ({
    type: types.FAMILIES_INSERT_REQUEST_SUCCESS
});

export const familiesInsertRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.FAMILIES_INSERT_REQUEST_FAILED,
    titleError,
    titleContent
});

export const familiesUpdateRequest = (body: any) => ({
    type: types.FAMILIES_UPDATE_REQUEST,
    body
});

export const familiesUpdateRequestSuccess = () => ({
    type: types.FAMILIES_UPDATE_REQUEST_SUCCESS
});

export const familiesUpdateRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.FAMILIES_UPDATE_REQUEST_FAILED,
    titleError,
    titleContent
});

export const familiesDeleteRequest = (famicodes: Array<string>) => ({
    type: types.FAMILIES_DELETE_REQUEST,
    famicodes
});

export const familiesDeleteRequestSuccess = () => ({
    type: types.FAMILIES_DELETE_REQUEST_SUCCESS
});

export const familiesDeleteRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.FAMILIES_DELETE_REQUEST_FAILED,
    titleError,
    titleContent
});

/*Components */
export const familiesListSelectItem = (key: number) => ({
    type: types.FAMILIES_LIST_SELECT_ITEM,
    key
});

export const familiesListSelectAllItem = (checked: boolean) => ({
    type: types.FAMILIES_LIST_SELECT_ALL_ITEM,
    checked
});
export const familiesActionFailed = (titleError: string, titleContent: string) => ({
    type: types.FAMILIES_ACTION_FAILED,
    titleError,
    titleContent
});


/** UNITS */
export const unitsInitUpdateFormRequest = (meascode: string, callback: void) => ({
    type: types.UNITS_INIT_UPDATE_FORM_REQUEST,
    meascode,
    callback
});

export const unitsInitUpdateFormRequestSuccess = (tables: any, onlyOne: any) => ({
    type: types.UNITS_INIT_UPDATE_FORM_REQUEST_SUCCESS,
    tables,
    onlyOne
});

export const unitsInitUpdateFormRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.UNITS_INIT_UPDATE_FORM_REQUEST_FAILED,
    titleError,
    titleContent
});

export const unitsGetAllRecordsRequest = (body: baseModels.pentaRequestQuery) => ({
    type: types.UNITS_GET_ALL_RECORDS_REQUEST,
    body
});

export const unitsGetAllRecordsRequestSuccess = (list: Array<any>, total: number) => ({
    type: types.UNITS_GET_ALL_RECORDS_REQUEST_SUCCESS,
    total,
    list
});

export const unitsGetAllRecordsRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.UNITS_GET_ALL_RECORDS_REQUEST_FAILED,
    titleError,
    titleContent
});

export const unitsInsertRequest = (body: any, callback?: void) => ({
    type: types.UNITS_INSERT_REQUEST,
    body,
    callback
});

export const unitsInsertRequestSuccess = () => ({
    type: types.UNITS_INSERT_REQUEST_SUCCESS
});

export const unitsInsertRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.UNITS_INSERT_REQUEST_FAILED,
    titleError,
    titleContent
});

export const unitsUpdateRequest = (body: any) => ({
    type: types.UNITS_UPDATE_REQUEST,
    body
});

export const unitsUpdateRequestSuccess = () => ({
    type: types.UNITS_UPDATE_REQUEST_SUCCESS
});

export const unitsUpdateRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.UNITS_UPDATE_REQUEST_FAILED,
    titleError,
    titleContent
});

export const unitsDeleteRequest = (meascodes: Array<string>) => ({
    type: types.UNITS_DELETE_REQUEST,
    meascodes
});

export const unitsDeleteRequestSuccess = () => ({
    type: types.UNITS_DELETE_REQUEST_SUCCESS
});

export const unitsDeleteRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.UNITS_DELETE_REQUEST_FAILED,
    titleError,
    titleContent
});

/*Components */
export const unitsListSelectItem = (key: number) => ({
    type: types.UNITS_LIST_SELECT_ITEM,
    key
});

export const unitsListSelectAllItem = (checked: boolean) => ({
    type: types.UNITS_LIST_SELECT_ALL_ITEM,
    checked
});
export const unitsActionFailed = (titleError: string, titleContent: string) => ({
    type: types.UNITS_ACTION_FAILED,
    titleError,
    titleContent
});


/** WHAREHOUSES */
export const warehousesInitUpdateFormRequest = (wharcode: string, callback: void) => ({
    type: types.WAREHOUSES_INIT_UPDATE_FORM_REQUEST,
    wharcode,
    callback
});

export const warehousesInitUpdateFormRequestSuccess = (tables: any, onlyOne: any) => ({
    type: types.WAREHOUSES_INIT_UPDATE_FORM_REQUEST_SUCCESS,
    tables,
    onlyOne
});

export const warehousesInitUpdateFormRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.WAREHOUSES_INIT_UPDATE_FORM_REQUEST_FAILED,
    titleError,
    titleContent
});

export const warehousesGetAllRecordsRequest = (body: baseModels.pentaRequestQuery) => ({
    type: types.WAREHOUSES_GET_ALL_RECORDS_REQUEST,
    body
});

export const warehousesGetAllRecordsRequestSuccess = (list: Array<any>, total: number) => ({
    type: types.WAREHOUSES_GET_ALL_RECORDS_REQUEST_SUCCESS,
    total,
    list
});

export const warehousesGetAllRecordsRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.WAREHOUSES_GET_ALL_RECORDS_REQUEST_FAILED,
    titleError,
    titleContent
});

export const warehousesInsertRequest = (body: any, callback?: void) => ({
    type: types.WAREHOUSES_INSERT_REQUEST,
    body,
    callback
});

export const warehousesInsertRequestSuccess = () => ({
    type: types.WAREHOUSES_INSERT_REQUEST_SUCCESS
});

export const warehousesInsertRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.WAREHOUSES_INSERT_REQUEST_FAILED,
    titleError,
    titleContent
});

export const warehousesUpdateRequest = (body: any) => ({
    type: types.WAREHOUSES_UPDATE_REQUEST,
    body
});

export const warehousesUpdateRequestSuccess = () => ({
    type: types.WAREHOUSES_UPDATE_REQUEST_SUCCESS
});

export const warehousesUpdateRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.WAREHOUSES_UPDATE_REQUEST_FAILED,
    titleError,
    titleContent
});

export const warehousesDeleteRequest = (wharcodes: Array<string>) => ({
    type: types.WAREHOUSES_DELETE_REQUEST,
    wharcodes
});

export const warehousesDeleteRequestSuccess = () => ({
    type: types.WAREHOUSES_DELETE_REQUEST_SUCCESS
});

export const warehousesDeleteRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.WAREHOUSES_GET_ALL_RECORDS_REQUEST_FAILED,
    titleError,
    titleContent
});

/*Components */
export const warehousesListSelectItem = (key: number) => ({
    type: types.WAREHOUSES_LIST_SELECT_ITEM,
    key
});

export const warehousesListSelectAllItem = (checked: boolean) => ({
    type: types.WAREHOUSES_LIST_SELECT_ALL_ITEM,
    checked
});
export const warehousesActionFailed = (titleError: string, titleContent: string) => ({
    type: types.WAREHOUSES_ACTION_FAILED,
    titleError,
    titleContent
});


/** PRODUCTS */
export const productsInitFormRequest = (fiscyear: string, callback: any) => ({
    type: types.PRODUCTS_INIT_FORM_REQUEST,
    fiscyear,
    callback
});

export const productsInitFormRequestSuccess = (tables: any) => ({
    type: types.PRODUCTS_INIT_FORM_REQUEST_SUCCESS,
    tables
});

export const productsInitFormRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.PRODUCTS_INIT_FORM_REQUEST_FAILED,
    titleError,
    titleContent
});

export const productsInitUpdateFormRequest = (skucodee: string, callback: void) => ({
    type: types.PRODUCTS_INIT_UPDATE_FORM_REQUEST,
    skucodee,
    callback
});

export const productsInitUpdateFormRequestSuccess = (tables: any, onlyOne: any) => ({
    type: types.PRODUCTS_INIT_UPDATE_FORM_REQUEST_SUCCESS,
    tables,
    onlyOne
});

export const productsInitUpdateFormRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.PRODUCTS_INIT_UPDATE_FORM_REQUEST_FAILED,
    titleError,
    titleContent
});

export const productsGetAllRecordsRequest = (body: baseModels.pentaRequestQuery) => ({
    type: types.PRODUCTS_GET_ALL_RECORDS_REQUEST,
    body
});

export const productsGetAllRecordsRequestSuccess = (list: Array<any>, total: number) => ({
    type: types.PRODUCTS_GET_ALL_RECORDS_REQUEST_SUCCESS,
    total,
    list
});

export const productsGetAllRecordsRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.PRODUCTS_GET_ALL_RECORDS_REQUEST_FAILED,
    titleError,
    titleContent
});

export const productsInsertRequest = (body: any, callback?: void) => ({
    type: types.PRODUCTS_INSERT_REQUEST,
    body,
    callback
});

export const productsInsertRequestSuccess = () => ({
    type: types.PRODUCTS_INSERT_REQUEST_SUCCESS
});

export const productsInsertRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.PRODUCTS_INSERT_REQUEST_FAILED,
    titleError,
    titleContent
});

export const productsUpdateRequest = (body: any) => ({
    type: types.PRODUCTS_UPDATE_REQUEST,
    body
});

export const productsUpdateRequestSuccess = () => ({
    type: types.PRODUCTS_UPDATE_REQUEST_SUCCESS
});

export const productsUpdateRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.PRODUCTS_UPDATE_REQUEST_FAILED,
    titleError,
    titleContent
});

export const productsDeleteRequest = (skucodees: Array<string>) => ({
    type: types.PRODUCTS_DELETE_REQUEST,
    skucodees
});

export const productsDeleteRequestSuccess = () => ({
    type: types.PRODUCTS_DELETE_REQUEST_SUCCESS
});

export const productsDeleteRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.PRODUCTS_DELETE_REQUEST_FAILED,
    titleError,
    titleContent
});

/*Components */
export const productsListSelectItem = (key: number) => ({
    type: types.PRODUCTS_LIST_SELECT_ITEM,
    key
});

export const productsListSelectAllItem = (checked: boolean) => ({
    type: types.PRODUCTS_LIST_SELECT_ALL_ITEM,
    checked
});
export const productsActionFailed = (titleError: string, titleContent: string) => ({
    type: types.PRODUCTS_ACTION_FAILED,
    titleError,
    titleContent
});