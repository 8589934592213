import { authModels } from 'penta-models';
import { AUTH_LOGIN, AUTH_LOGIN_PERMISSIONS } from '../constants/authConstants'
import request from './basePipeline';

export const authLogin = (payload: authModels.loginCredentials) => {
    return request.postWithoutToken(AUTH_LOGIN, payload);
}

export const authLoginPermissions = (officode: string) => {
    return request.getWithToken(AUTH_LOGIN_PERMISSIONS + `?officecode=${officode}`);
}