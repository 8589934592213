import React from 'react';
import { Button, createStyles, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Paper, Select, Switch, Theme, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ctsDepositsRequest } from 'penta-redux/actions/humanResourcesActions';
import './../reports.scss'
import { MessageBox } from 'penta-components/base/messageBox';
// import * as pdfMake from "pdfmake/build/pdfmake";
// import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
import * as XLSX from 'sheetjs-style';
import ScreenLoader from 'penta-components/base/screenLoader';

let saveAs = require('file-saver').saveAs;

const meses = [
    { key: '01', value: 'Enero' },
    { key: '02', value: 'Febrero' },
    { key: '03', value: 'Marzo' },
    { key: '04', value: 'Abril' },
    { key: '05', value: 'Mayo' },
    { key: '06', value: 'Junio' },
    { key: '07', value: 'Julio' },
    { key: '08', value: 'Agosto' },
    { key: '09', value: 'Septiembre' },
    { key: '10', value: 'Octubre' },
    { key: '11', value: 'Noviembre' },
    { key: '12', value: 'Diciembre' }
]

const useStyles = (theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        formControl: {
            margin: 0,
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    });

interface FormState {
    title: string
    year: string
    tramo: string
    officode: string
    divicode: string
    base64Pdf: string
    allFlag: boolean
}

class ReportCtsDeposits extends React.Component<any, FormState> {

    constructor(props: any) {
        super(props);
        this.state = {
            title: 'Reporte de depositos CTS',
            year: moment().format('yyyy').toString(),
            tramo: "1",
            officode: '',
            divicode: '',
            base64Pdf: '',
            allFlag: false
        }
    }

    onChangeRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            ...value
        }))
    }

    onGenerateExcel = () => {
        try {
            const _data = this.props.humanResourcesReducers.onlyOne;
            const { tramo, year } = this.state;
            const month = tramo === '1' ? '04' : '11';
            const { dashboardReducers } = this.props;
            const mes = meses.find((x: any) => x.key === month).value;
            const tables = JSON.parse(localStorage.getItem('tables'));
            const company = tables.companys.find((x: any) => x.taxident === dashboardReducers.currentCompany);
            const division = company.lst_grl_division.find((x: any) => x.divicode === dashboardReducers.currentDivision);
            const oficina = division.lst_grl_bussofic.find((x: any) => x.officode === dashboardReducers.currentBussOfic);
            const data = _data.filter((x: any) => x.payryear === year && x.payrmont === month);

            const tableBody: any = [
                {
                    A:  company.bussname.toUpperCase(),
                    B:  null,
                    C:  null,
                    D:  null,
                    E:  null,
                    F:  null,
                    G:  null,
                    H:  null,
                    I:  null,
                    J:  null,
                    K:  null,
                    L:  null,
                    M:  null,
                    N:  null,
                    O:  null,
                    P:  null,
                    Q:  null,
                    R:  null,
                    S:  null
                },
                {
                    A:  '',
                    B:  '',
                    C:  '',
                    D:  '',
                    E:  '',
                    F:  '',
                    G:  '',
                    H:  '',
                    I:  '',
                    J:  '',
                    K:  '',
                    L:  '',
                    M:  '',
                    N:  '',
                    O:  '',
                    P:  '',
                    Q:  '',
                    R:  '',
                    S:  ''
                },
                {
                    A:  `DEPOSITOS CTS ${tramo==='2' ? `SEGUNDO SEMESTRE ${`Mayo de ${year}` + ' a ' + `Octubre de ${year}`}` : `PRIMER SEMESTRE ${`Noviembre de ${+year - 1}` + ' a ' + `Abril de ${year}`}`}`.toUpperCase(),
                    B:  null,
                    C:  null,
                    D:  null,
                    E:  null,
                    F:  null,
                    G:  null,
                    H:  null,
                    I:  null,
                    J:  null,
                    K:  null,
                    L:  null,
                    M:  null,
                    N:  null,
                    O:  null,
                    P:  null,
                    Q:  null,
                    R:  null,
                    S:  null
                },
                {
                    A:  '',
                    B:  '',
                    C:  '',
                    D:  '',
                    E:  '',
                    F:  '',
                    G:  '',
                    H:  '',
                    I:  '',
                    J:  '',
                    K:  '',
                    L:  '',
                    M:  '',
                    N:  '',
                    O:  '',
                    P:  '',
                    Q:  '',
                    R:  '',
                    S:  ''
                },
            ]

            tableBody.push(
                {
                    A: '',
                    B: '',
                    C: '',
                    D: '',
                    E: 'MESES COMPLETO',
                    F: 'DIAS',
                    G: 'REMUNERACION MENSUAL INDEMNIZABLE',
                    H: '',
                    I: '',
                    J: '',
                    K: '',
                    L: '',
                    M: 'COMPENSACION',
                    N: '',
                    O: 'COMP. POR TIEMPO SERVICIO',
                    P: '',
                    Q: '',
                    R: 'INTERES',
                    S: ''
                },
            );
            tableBody.push(    
                { 
                    A: 'CODIGO',
                    B: 'APELLIDOS Y NOMBRES',
                    C: 'OCUPACION',
                    D: 'FECHA INGRESO',
                    E: '',
                    F: '',
                    G: 'REMUNER. BASICA',
                    H: 'ASIGNAC. FAMILIAR',
                    I: 'HORAS EXTRAS',
                    J: 'GRATIFIC.',
                    K: 'OTROS',
                    L: 'TOTAL',
                    M: 'DOCEAVO',
                    N: 'TREINTAVO',
                    O: 'MESES',
                    P: 'DIAS',
                    Q: 'TOTAL',
                    R: 'ANUAL 3.50%',
                    S: 'TOTAL'
                }
            );

            let gc102 = 0.00;
            let gc103 = 0.00;
            let gc201 = 0.00;
            let gc202 = 0.00;
            let gc211 = 0.00;
            let gc203 = 0.00;
            let gc213 = 0.00;
            let gc214 = 0.00;
            let gc215 = 0.00;
            let gc216 = 0.00;
            let gc217 = 0.00;
            let gc501 = 0.00;

            for (const row of data) {

                const concepts = JSON.parse(row.concepts);

                const c102 = concepts.find((x: any) => x.conccode === 'C102')?.outcomee || '0.00';
                const c103 = concepts.find((x: any) => x.conccode === 'C103')?.outcomee || '0.00';
                const c201 = concepts.find((x: any) => x.conccode === 'C201')?.outcomee || '0.00';
                const c202 = concepts.find((x: any) => x.conccode === 'C202')?.outcomee || '0.00';
                const c211 = concepts.find((x: any) => x.conccode === 'C211')?.outcomee || '0.00';
                const c203 = concepts.find((x: any) => x.conccode === 'C203')?.outcomee || '0.00';
                const c213 = concepts.find((x: any) => x.conccode === 'C213')?.outcomee || '0.00';
                const c214 = concepts.find((x: any) => x.conccode === 'C214')?.outcomee || '0.00';
                const c215 = concepts.find((x: any) => x.conccode === 'C215')?.outcomee || '0.00';
                const c216 = concepts.find((x: any) => x.conccode === 'C216')?.outcomee || '0.00';
                const c217 = concepts.find((x: any) => x.conccode === 'C217')?.outcomee || '0.00';
                const c501 = concepts.find((x: any) => x.conccode === 'C501')?.outcomee || '0.00';

                tableBody.push(
                    { 
                        A:  row.emplcode,
                        B:  row.nameeess.substr(0, 30),
                        C:  row.posscode.substr(0, 25),
                        D:  moment(row.dateadmi).format('YYYY-MM-DD'),
                        E:  String.formatNumber.new(c102),
                        F:  String.formatNumber.new(c103),
                        G:  String.formatNumber.new(c201),
                        H:  String.formatNumber.new(c202),
                        I:  String.formatNumber.new(c211),
                        J:  String.formatNumber.new(c203),
                        K:  String.formatNumber.new('0.00'),
                        L:  String.formatNumber.new(c213),
                        M:  String.formatNumber.new(c214),
                        N:  String.formatNumber.new(c215),
                        O:  String.formatNumber.new(c216),
                        P:  String.formatNumber.new(c217),
                        Q:  String.formatNumber.new(c501),
                        R:  String.formatNumber.new('0.00'),
                        S:  String.formatNumber.new(c501)
                    }
                )

                gc102 += parseFloat(c102);
                gc103 += parseFloat(c103);
                gc201 += parseFloat(c201);
                gc202 += parseFloat(c202);
                gc211 += parseFloat(c211);
                gc203 += parseFloat(c203);
                gc213 += parseFloat(c213);
                gc214 += parseFloat(c214);
                gc215 += parseFloat(c215);
                gc216 += parseFloat(c216);
                gc217 += parseFloat(c217);
                gc501 += parseFloat(c501);
            }

            tableBody.push(
                { 
                    A:  'TOTAL PLANILLA',
                    B:  '',
                    C:  '',
                    D:  '',
                    E:  String.formatNumber.new(gc102),
                    F:  String.formatNumber.new(gc103),
                    G:  String.formatNumber.new(gc201),
                    H:  String.formatNumber.new(gc202),
                    I:  String.formatNumber.new(gc211),
                    J:  String.formatNumber.new(gc203),
                    K:  String.formatNumber.new('0.00'),
                    L:  String.formatNumber.new(gc213),
                    M:  String.formatNumber.new(gc214),
                    N:  String.formatNumber.new(gc215),
                    O:  String.formatNumber.new(gc216),
                    P:  String.formatNumber.new(gc217),
                    Q:  String.formatNumber.new(gc501),
                    R:  String.formatNumber.new('0.00'),
                    S:  String.formatNumber.new(gc501)
                }
            )

            const header = [
                'A',
                'B',
                'C',
                'D',
                'E',
                'F',
                'G',
                'H',
                'I',
                'J',
                'K',
                'L',
                'M',
                'N',
                'O',
                'P',
                'Q',
                'R',
                'S'
            ]

            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(tableBody, {header, skipHeader: true});
            ws["!cols"] = [
                {wpx:50},{wpx:150},{wpx:120},{wpx:70},{wpx:50},{wpx:50},{wpx:50},{wpx:50},{wpx:50},{wpx:50},
                {wpx:50},{wpx:50},{wpx:50},{wpx:50},{wpx:50},{wpx:50},{wpx:50},{wpx:50},{wpx:50}
            ];

            const keys = Object.keys(ws);
            const rowsCount = tableBody.length;

            for (const iterator of keys) {
                if(ws[iterator].v?.match(/^[0-9,.]+$/g) && !iterator.match(/^(A|B|C|D)[0-9]+$/g)) {
                    ws[iterator].v = ws[iterator].v.replace(/,/g, '');
                    ws[iterator].t = 'n';
                    ws[iterator].z = '#,##0.00';
                }
                if(iterator !== '!ref' && iterator !== '!cols') {
                    ws[iterator].s = {
                        font: {
                            name: 'Calibri',
                            sz: 10
                        }
                    }
                    const row = +(iterator.match(/\d+/)[0]);
                    if(iterator.match(/^(D|E|F|G|H|I|J|K|L|M|N|O|P|Q|R|S)[0-9]+$/g) && + row >= 5) {
                        ws[iterator].s.border = {
                            right: { style: 'thin', color: 'black'},
                            bottom: { style: 'thin', color: 'black'}
                        }
                    }
                    if(row === rowsCount) {
                        ws[iterator].s.border = {
                            ...ws[iterator].s.border,
                            bottom: { style: 'thin', color: 'black'},
                            top: { style: 'thin', color: 'black'}
                        }
                    }
                }
            }

            ws['A1'].s = {font:{name: 'Arial', sz: 12, bold: true}}
            ws['A2'].s = {font:{name: 'Arial', sz: 10}}
            ws['A3'].s = {font:{name: 'Arial', sz: 10, bold: true}}
            
            for (const iterator of header) {
                const key1 = iterator + "5";
                const key2 = iterator + "6";

                ws[key1].s = {
                    ...ws[key1].s, 
                    alignment: { wrapText: true, vertical: 'center', horizontal: 'center'},
                    border: { 
                        ...ws[key1].s.border, 
                        top: { style: 'thin', color: 'black'},
                        bottom: null,
                    } 
                }
                ws[key2].s = {
                    ...ws[key2].s, 
                    alignment: { wrapText: true, vertical: 'center', horizontal: 'center'},
                    border: { 
                        ...ws[key1].s.border, 
                        top: null,
                        bottom: { style: 'thin', color: 'black'},
                    } 
                }
            };

            ws["!merges"] = [
                { s: { r: 4, c: 0 }, e: { r: 4, c: 3 } },
                { s: { r: 4, c: 4 }, e: { r: 5, c: 4 } },
                { s: { r: 4, c: 5 }, e: { r: 5, c: 5 } },
                { s: { r: 4, c: 6 }, e: { r: 4, c: 10 } },
                { s: { r: 4, c: 11 }, e: { r: 5, c: 11 } },
                { s: { r: 4, c: 12 }, e: { r: 4, c: 13 } },
                { s: { r: 4, c: 14 }, e: { r: 4, c: 16 } },
            ];

            XLSX.utils.book_append_sheet(wb, ws, "Depositos CTS");

            var wbout = XLSX.write(wb, {bookType:'xlsx', type: 'binary', cellStyles: true});

            const s2ab = function(s) {
                var buf = new ArrayBuffer(s.length);
                var view = new Uint8Array(buf);
                for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
                return buf;
            }

            saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), `depositos-cts-${year}-tramo-${tramo}.xlsx`);
        } catch(err) {
            console.log(err);
        }
    }

    render() {

        const { title, year, tramo, divicode, officode, allFlag } = this.state;
        const { loading, list, tables, failed, failedTitle, failedContent } = this.props.humanResourcesReducers;
        const { classes, dashboardReducers } = this.props;

        const company = JSON.parse(localStorage.getItem('tables')).companys.find((x: any) => x.taxident === dashboardReducers.currentCompany);

        return (
            <div style={{overflow: 'auto', height: '100%'}}>
                <Paper className='form__container'>
                    <Typography variant='h5'>{ title }</Typography>
                    <br/>
                    { failed && <MessageBox title={failedTitle} content={failedContent}/> }
                    <div style={{ display: 'flex' }}>
                        <form noValidate autoComplete="off" style={{ flex: 1, paddingRight: '15px', paddingTop: '2em' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                        <InputLabel id="lbl_año">Año</InputLabel>
                                        <Select
                                            labelId="lbl_año"
                                            id="year"
                                            className='select'
                                            label="Año"
                                            fullWidth
                                            value={ year }
                                            onChange={(e: any) => this.onChangeRoot({'year': e.target.value})}
                                        >
                                            <MenuItem value='2030'>2030</MenuItem>
                                            <MenuItem value='2029'>2029</MenuItem>
                                            <MenuItem value='2028'>2028</MenuItem>
                                            <MenuItem value='2027'>2027</MenuItem>
                                            <MenuItem value='2026'>2026</MenuItem>
                                            <MenuItem value='2025'>2025</MenuItem>
                                            <MenuItem value='2026'>2026</MenuItem>
                                            <MenuItem value='2025'>2025</MenuItem>
                                            <MenuItem value='2024'>2024</MenuItem>
                                            <MenuItem value='2023'>2023</MenuItem>
                                            <MenuItem value='2022'>2022</MenuItem>
                                            <MenuItem value='2021'>2021</MenuItem>
                                            <MenuItem value='2020'>2020</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_tramo">Tramo</InputLabel>
                                            <Select
                                                labelId="lbl_tramo"
                                                id="tramo"
                                                className='select'
                                                label="Trome"
                                                fullWidth
                                                value={ tramo }
                                                onChange={(e: any) => this.onChangeRoot({'tramo': e.target.value})}
                                            >
                                                <MenuItem value='1'>{`Noviembre de ${+year - 1}` + ' a ' + `Abril de ${year}`}</MenuItem>
                                                <MenuItem value='2'>{`Mayo de ${year}` + ' a ' + `Octubre de ${year}`}</MenuItem>
                                            </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                    <Switch
                                        checked={allFlag}
                                        onChange={(e: any) => this.onChangeRoot({'allFlag': e.target.checked})}
                                        name="allFlag"
                                        color="primary"
                                    />
                                    }
                                    label="Todo el personal"
                                />
                            </Grid>
                            {
                                !allFlag &&
                                <>
                                    <Grid container spacing={2}>
                                        <Grid item xs={4}>
                                            <FormControl variant="outlined" className={classes.formControl} fullWidth disabled={company === '' ? true : false}>
                                                <InputLabel id="lbl_division">Division</InputLabel>
                                                <Select
                                                    labelId="lbl_division"
                                                    id="division"
                                                    value={divicode}
                                                    onChange={(e: React.ChangeEvent<{ value: unknown }>) => { this.onChangeRoot({'divicode': e.target.value}) }}
                                                    label="Division"
                                                    fullWidth
                                                >
                                                    {
                                                        company.lst_grl_division.map((x: any) => 
                                                            <MenuItem value={x.divicode} key={x.divicode}>{ x.diviname }</MenuItem>
                                                        )
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={4}>
                                            <FormControl variant="outlined" className={classes.formControl} fullWidth disabled={divicode === '' ? true : false}>
                                                <InputLabel id="lbl_oficina">Oficina</InputLabel>
                                                <Select
                                                    labelId="lbl_oficina"
                                                    id="oficina"
                                                    value={officode}
                                                    onChange={(e: React.ChangeEvent<{ value: unknown }>) => { this.onChangeRoot({'officode': e.target.value}) }}
                                                    label="Oficina"
                                                    fullWidth
                                                >
                                                    {
                                                        company.lst_grl_division.find((x) => x.divicode === divicode)?.lst_grl_bussofic.map((x: any) => 
                                                            <MenuItem value={x.officode} key={x.officode}>{ x.offiname }</MenuItem>
                                                        )
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </>
                            }
                            <Grid container spacing={2}>
                                <Grid item xs={4} style={{ paddingTop: '10px' }}>
                                    <Button variant="contained" fullWidth color="inherit" onClick={() => { this.props.ctsDepositsRequest(allFlag ? 'ALL' : officode, this.onGenerateExcel) }}>
                                        Generar Reporte EXCEL
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                    <br/>
                    {/* <FormFooter onCancel={ this.onCancel } onSubmit={ this.onSubmit }/> */}
                </Paper>
                <ScreenLoader enabled={loading}/>
            </div>
        );
    }
}

const humanResourcesReducers = 'humanResourcesReducers';
const dashboardReducers = 'dashboardReducers';

const mapStateToProps = (state: any) => ({
    humanResourcesReducers: state.get(humanResourcesReducers),
    dashboardReducers: state.get(dashboardReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        ctsDepositsRequest
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(ReportCtsDeposits));