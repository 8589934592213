import React from 'react';
import { Backdrop, Button, ButtonGroup, Checkbox, CircularProgress, createStyles, FormControl, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Select, Snackbar, TextField, Theme, Tooltip, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { warehousesInitUpdateFormRequest, warehousesInsertRequest, warehousesUpdateRequest } from 'penta-redux/actions/wharehouseProductsActions';
import history from '../../../utils/history';
import './wharehouses.scss'
import FormFooter from 'penta-components/base/formFooter';
import { MessageBox } from 'penta-components/base/messageBox';
import { wharehouseEntity } from 'models/wharehouseProductsModels';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Alert } from '@material-ui/lab';
import ScreenLoader from 'penta-components/base/screenLoader';

const useStyles = (theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        formControl: {
            margin: 0,
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    });

const defaultWharehouseEntity: wharehouseEntity = {
    wharcode: '',
    wharname: ''
}

interface FormFamiliesState {
    wharehouseEntity: wharehouseEntity
    title: string
    snackbar: boolean
}

class FormFamilies extends React.Component<any, FormFamiliesState> {

    constructor(props: any) {
        super(props);
        this.state = {
            wharehouseEntity: defaultWharehouseEntity,
            title: 'Agregar Almacenes',
            snackbar: false
        }
    }

    onChangeRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            ...value
        }))
    }

    onChangewharehouseRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            wharehouseEntity: {
                ...state.wharehouseEntity,
                ...value
            }
        }))
    }

    onCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        console.log('cancel')
        history.push('/papp/products/almacenes');
    }

    onContinueCallback = () => {
        const newWharehouseEntity = defaultWharehouseEntity;
        this.setState({
            wharehouseEntity: newWharehouseEntity,
            snackbar: true
        });
    }

    onSubmitContinue = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const { params } = this.props.match;
        if (params.wharcode !== undefined) {
            this.props.warehousesUpdateRequest(this.state.wharehouseEntity);
        } else {
            this.props.warehousesInsertRequest(this.state.wharehouseEntity, this.onContinueCallback);
        }
    }

    onSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const { params } = this.props.match;
        if (params.wharcode !== undefined) {
            this.props.warehousesUpdateRequest(this.state.wharehouseEntity);
        } else {
            this.props.warehousesInsertRequest(this.state.wharehouseEntity, null);
        }
    }

    callbackInitUpdate = (wharehouseEntity: wharehouseEntity) => {
        this.setState({
            wharehouseEntity
        });
    }

    componentDidMount() {
        const { params } = this.props.match;
        if (params.wharcode !== undefined) {
            this.setState({title: 'Editar Almacenes'});
            this.props.warehousesInitUpdateFormRequest(params.wharcode, this.callbackInitUpdate);
        }
    }

    render() {

        const { wharehouseEntity, title, snackbar } = this.state;
        const { loading, list, tables, failed, failedTitle, failedContent } = this.props.wharehouseProductsReducers;
        const { classes } = this.props;

        return (
            <div style={{overflow: 'auto', height: '100%'}}>
                <Paper className='form__container'>
                    <br/>
                    <Typography variant='h5'>{ title }</Typography>
                    <br/>
                    { failed && <MessageBox title={failedTitle} content={failedContent}/> }
                    <form noValidate autoComplete="off">
                        <Grid container spacing={2} >
                            <Grid item xs={4}>
                                <TextField fullWidth id="wharcode" label="Codigo" variant="outlined" value={ wharehouseEntity.wharcode } onChange={(e: any) => this.onChangewharehouseRoot({'wharcode': e.target.value})}/>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth id="wharname" label="Nombre" variant="outlined" value={ wharehouseEntity.wharname } onChange={(e: any) => this.onChangewharehouseRoot({'wharname': e.target.value})}/>
                            </Grid>
                        </Grid>
                    </form>
                    <br/>
                    <FormFooter 
                        onCancel={ this.onCancel } 
                        onSubmitContinue={ this.onSubmitContinue } 
                        onSubmit={ this.onSubmit }/>
                </Paper>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={snackbar}
                    onClose={() => this.setState({snackbar: false})}
                    autoHideDuration={6000}
                >
                    <Alert onClose={() => this.setState({snackbar: false})} severity="success">
                            Datos Guardados Correctamente
                    </Alert>
                </Snackbar>
                <ScreenLoader enabled={loading}/>
            </div>
        );
    }
}

const wharehouseProductsReducers = 'wharehouseProductsReducers';

const mapStateToProps = (state: any) => ({
    wharehouseProductsReducers: state.get(wharehouseProductsReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        warehousesInitUpdateFormRequest,
        warehousesInsertRequest,
        warehousesUpdateRequest
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(FormFamilies));