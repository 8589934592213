const serverUrl = process.env.PENTA_MASTER_SERVER_URL || `https://api.${window.location.hostname}/api/`;

export const SUPPLIERS_INIT_FORM = serverUrl + 'shopping/suppliers/init-form/';
export const SUPPLIERS_INIT_UPDATE_FORM = serverUrl + 'shopping/suppliers/init-update-form/';
export const SUPPLIERS_GET_ALL_RECORDS = serverUrl + 'shopping/suppliers/get-all-records';
export const SUPPLIERS_INSERT = serverUrl + 'shopping/suppliers/insert';
export const SUPPLIERS_UPDATE = serverUrl + 'shopping/suppliers/update/';
export const SUPPLIERS_DELETE = serverUrl + 'shopping/suppliers/delete?';

export const PURCHASES_INIT_FORM = serverUrl + 'shopping/purchases/init-form';
export const PURCHASES_INIT_UPDATE_FORM = serverUrl + 'shopping/purchases/init-update-form/';
export const PURCHASES_GET_ALL_RECORDS = serverUrl + 'shopping/purchases/get-all-records';
export const PURCHASES_INSERT = serverUrl + 'shopping/purchases/insert';
export const PURCHASES_UPDATE = serverUrl + 'shopping/purchases/update/';
export const PURCHASES_DELETE = serverUrl + 'shopping/purchases/delete?';
export const PURCHASES_ANULATE = serverUrl + 'shopping/purchases/anulate?';

export const CREDIT_NOTE_INIT_FORM = serverUrl + 'shopping/credit-note/init-form';
export const CREDIT_NOTE_INIT_UPDATE_FORM = serverUrl + 'shopping/credit-note/init-update-form/';
export const CREDIT_NOTE_GET_ALL_RECORDS = serverUrl + 'shopping/credit-note/get-all-records';
export const CREDIT_NOTE_INSERT = serverUrl + 'shopping/credit-note/insert';
export const CREDIT_NOTE_UPDATE = serverUrl + 'shopping/credit-note/update/';
export const CREDIT_NOTE_DELETE = serverUrl + 'shopping/credit-note/delete?';

export const DEBIT_NOTE_INIT_FORM = serverUrl + 'shopping/debit-note/init-form';
export const DEBIT_NOTE_INIT_UPDATE_FORM = serverUrl + 'shopping/debit-note/init-update-form/';
export const DEBIT_NOTE_GET_ALL_RECORDS = serverUrl + 'shopping/debit-note/get-all-records';
export const DEBIT_NOTE_INSERT = serverUrl + 'shopping/debit-note/insert';
export const DEBIT_NOTE_UPDATE = serverUrl + 'shopping/debit-note/update/';
export const DEBIT_NOTE_DELETE = serverUrl + 'shopping/debit-note/delete?';

export const INVOICES_PAY = serverUrl + 'shopping/reports/invoices-to-pay/';