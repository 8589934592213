import React from 'react';
import { AppBar, Backdrop, Box, Button, ButtonGroup, Checkbox, CircularProgress, createStyles, FormControl, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Select, Tab, Tabs, TextField, Theme, Tooltip, Typography, FormControlLabel, withStyles, TextareaAutosize, Snackbar } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { checksInitFormRequest, checksInitUpdateFormRequest, checksInsertRequest, checksUpdateRequest } from 'penta-redux/actions/financeActions';
import { currencyExchangeRateRequest } from 'penta-redux/actions/generalActions';
import history from '../../../utils/history';
import './checks.scss'
import FormFooter from 'penta-components/base/formFooter';
import { MessageBox } from 'penta-components/base/messageBox';
import { csb_checksmo } from 'models/financeModels';
import moment from 'moment';
import { Alert, Autocomplete } from '@material-ui/lab';
import EditableCell from 'penta-components/base/editableCell';
import ScreenLoader from 'penta-components/base/screenLoader';

const meses = [
    { key: '00', value: 'Apertura' },
    { key: '01', value: 'Enero' },
    { key: '02', value: 'Febrero' },
    { key: '03', value: 'Marzo' },
    { key: '04', value: 'Abril' },
    { key: '05', value: 'Mayo' },
    { key: '06', value: 'Junio' },
    { key: '07', value: 'Julio' },
    { key: '08', value: 'Agosto' },
    { key: '09', value: 'Septiembre' },
    { key: '10', value: 'Octubre' },
    { key: '11', value: 'Noviembre' },
    { key: '12', value: 'Diciembre' },
    { key: '13', value: 'Cierre' },
]

const header = [
    "", 
    "Tipo Comprobante",
    "Fecha",
    "Tipo de Cambio", 
    "Glosa", 
    "Cheque",
    "Cuenta",
    "Denominación",
    "Debe S/.",
    "Haber S/.",
    "Debe $",
    "Haber $"
];

const currentFiscyear = localStorage.getItem('currentFiscyear');

const useStyles = (theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        formControl: {
            margin: 0,
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    });

const defaultChecksEntity: csb_checksmo = {
    correlat: 0,
    yearexer: currentFiscyear,
    montexer: moment().format('MM'),
    currency: '00000014',
    optotals: {
        '00000014': { debitamo: 0.00, assetamo: 0.00 },
        '00000015': { debitamo: 0.00, assetamo: 0.00 },
    },
    csb_chemvdet: []
}

interface FormChecksState {
    checksEntity: csb_checksmo
    title: string
    dateoper: any
    curreval: any
    itemnumb: number
    snackbar: boolean
}

class FormChecks extends React.Component<any, FormChecksState> {

    constructor(props: any) {
        super(props);
        defaultChecksEntity.csb_chemvdet = [];
        this.state = {
            checksEntity: defaultChecksEntity,
            title: 'Agregar Cheques',
            dateoper: moment().format('YYYY-MM-DD'),
            curreval: 3.22,
            itemnumb: 0,
            snackbar: false
        }
    }

    onChangeRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            ...value
        }))
    }

    onChangeChecksRoot = (value: any) => {
        const key = Object.keys(value)[0];
        if(key === 'currency') {
            const { csb_chemvdet } = this.state.checksEntity;
            this.setState((state) => ({
                ...state,
                checksEntity: {
                    ...state.checksEntity,
                    ...value
                }
            }))
            setTimeout(() => {
                for(let i = 0; i < csb_chemvdet.length; i++) {
                    this.onEditCell({index: i, field: 'select' }, (csb_chemvdet[i] as any).select)
                }
            }, 0);
            
        } 
        else
            this.setState((state) => ({
                ...state,
                checksEntity: {
                    ...state.checksEntity,
                    ...value
                }
            }));
    }

    onCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        console.log('cancel')
        history.push('/papp/finance/cheques');
    }

    onContinueCallback = () => {
        const newChecksEntity = defaultChecksEntity;
        newChecksEntity.csb_chemvdet = [];
        this.setState({
            checksEntity: newChecksEntity,
            itemnumb: 0,
            snackbar: true
        });
    }

    onSubmitContinue = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const { params } = this.props.match;
        if (params.correlat !== undefined) {
            this.props.checksUpdateRequest(this.state.checksEntity, null);
        } else {
            this.props.checksInsertRequest(this.state.checksEntity, this.onContinueCallback);
        }
    }

    onSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const { params } = this.props.match;
        if (params.correlat !== undefined) {
            this.props.checksUpdateRequest(this.state.checksEntity, null);
        } else {
            this.props.checksInsertRequest(this.state.checksEntity, null);
        }
    }

    callbackInitUpdate = (checksEntity: csb_checksmo) => {
        checksEntity.optotals = JSON.parse(checksEntity.optotals);
        for (let iterator of checksEntity.csb_chemvdet) {
            iterator.dateoper = moment(iterator.dateoper).format('YYYY-MM-DD');
            iterator.balances = JSON.parse(iterator.balances);
        }

        this.setState({
            checksEntity,
            dateoper: checksEntity.csb_chemvdet[checksEntity.csb_chemvdet.length - 1].dateoper,
            curreval: checksEntity.csb_chemvdet[checksEntity.csb_chemvdet.length - 1].curreval,
            itemnumb: checksEntity.csb_chemvdet[checksEntity.csb_chemvdet.length - 1].itemnumb
        });
    }

    callbackInit = () => {
        this.props.currencyExchangeRateRequest(moment().format('YYYY-MM-DD'), () => {
            const { tables } = this.props.generalReducers;
            if(tables.exchangeRate !== null) {
                this.setState((state: any) => ({
                    ...state,
                    curreval: tables.exchangeRate.venta
                }))
            }
        });
    }

    calculateGrid = (csb_chemvdet, props, value, curreval, currency, totalamo, _precalc = true, _origin = '') => {
        const { csb_cashbank } = this.props.financeReducers.tables;
        let balances;
        const companyCurrency = '00000014';
        const _typevouc = props.field === 'typevouc' ? value : csb_chemvdet[props.index]['typevouc'];
        let _curreval = props.field === 'curreval' ? +value : +csb_chemvdet[props.index]['curreval'];
        // console.log(_curreval);
        const _currency = this.state.checksEntity.currency;
        let _exchrate = 0.00;
        if(companyCurrency === _currency) {
            if(currency === _currency) {
                const _totalamo = totalamo;
                const __altttamo = totalamo / _curreval;
                const str_totalamo = _precalc ? String.formatNumber.new(_totalamo) : _origin;
                const str_altttamo = String.formatNumber.new(__altttamo);
                balances = {
                    '00000014': { debitamo: _typevouc === 'TCSVO002' ? '0.00' : str_totalamo, assetamo: _typevouc === 'TCSVO002' ? str_totalamo : '0.00' },
                    '00000015': { debitamo: _typevouc === 'TCSVO002' ? '0.00' : str_altttamo, assetamo: _typevouc === 'TCSVO002' ? str_altttamo : '0.00' },
                }
            } else {
                const __altttamo = _precalc ? (Math.round((totalamo * curreval)*100)/100) : totalamo;

                // const totalFactura = _precalc ? totalamo : +csb_chemvdet[props.index]['pbl_purchase'].balanpay.replace(/,/g, '')
                // const newCurrency = Math.round(((Math.round((totalFactura * _curreval) / totalFactura*100)/100))*1000000)/1000000;

                let _totalamo = __altttamo / _curreval;
                const str_totalamo = String.formatNumber.new(_totalamo);
                const str_altttamo = _precalc ? String.formatNumber.new(__altttamo) : _origin;
                balances = {
                    '00000014': { debitamo: _typevouc === 'TCSVO002' ? '0.00' : str_altttamo, assetamo: _typevouc === 'TCSVO002' ? str_altttamo : '0.00' },
                    '00000015': { debitamo: _typevouc === 'TCSVO002' ? '0.00' : str_totalamo, assetamo: _typevouc === 'TCSVO002' ? str_totalamo : '0.00' },
                }
                _totalamo = Math.round((_totalamo)*100)/100
                const a = Math.round(__altttamo*100)/100;
                const b =  Math.round((_precalc ? totalamo*_curreval : Math.round(Math.round(_totalamo*_curreval*100)/100 / curreval *100)/100 * _curreval) *100)/100;
                _exchrate = Math.round((a - b)*100)/100;
                // _exchrate = Math.round(((Math.round(_totalamo*100)/100) - (Math.round((_precalc ? totalamo : (totalamo / curreval))*100)/100))*100)/100;
            }
        } else {
            if(currency === _currency) {
                const _totalamo = totalamo;
                const __altttamo = totalamo * _curreval;
                const str_totalamo = _precalc ? String.formatNumber.new(_totalamo) : _origin;
                const str_altttamo = String.formatNumber.new(__altttamo);
                balances = {
                    '00000014': { debitamo: _typevouc === 'TCSVO002' ? '0.00' : str_altttamo, assetamo: _typevouc === 'TCSVO002' ? str_altttamo : '0.00' },
                    '00000015': { debitamo: _typevouc === 'TCSVO002' ? '0.00' : str_totalamo, assetamo: _typevouc === 'TCSVO002' ? str_totalamo : '0.00' },
                }
                const a = Math.round(_totalamo*curreval*100)/100;
                const b =  Math.round(__altttamo*100)/100;
                _exchrate = Math.round((a - b)*100)/100;
            } else {
                const __altttamo = _precalc ? (Math.round((totalamo / _curreval)*100)/100) : totalamo;

                const totalFactura = _precalc ? totalamo : +csb_chemvdet[props.index]['pbl_purchase'].balanpay.replace(/,/g, '')
                const newCurrency = Math.round((totalFactura / (Math.round((totalFactura / _curreval)*100)/100))*1000000)/1000000;

                const _totalamo = __altttamo * newCurrency;
                const str_totalamo = String.formatNumber.new(_totalamo);
                const str_altttamo = _precalc ? String.formatNumber.new(__altttamo) : _origin;
                balances = {
                    '00000014': { debitamo: _typevouc === 'TCSVO002' ? '0.00' : str_totalamo, assetamo: _typevouc === 'TCSVO002' ? str_totalamo : '0.00' },
                    '00000015': { debitamo: _typevouc === 'TCSVO002' ? '0.00' : str_altttamo, assetamo: _typevouc === 'TCSVO002' ? str_altttamo : '0.00' },
                }
                totalamo = Math.round((totalamo)*100)/100
                const a = Math.round(_totalamo*100)/100;
                const b =  Math.round((_precalc ? Math.round(totalamo/curreval*100)/100 * _curreval : (Math.round(_totalamo/curreval*100)/100 * _curreval))*100)/100;
                _exchrate = Math.round((a - b)*100)/100;
                // _exchrate = Math.round(((Math.round((_precalc ? totalamo : (totalamo * curreval) - (Math.round(_totalamo*100)/100))*100)/100))*100)/100;
            }
        }

        csb_chemvdet[props.index]['balances'] = balances;
    }

    onEditCell = (props: any, value: any) => {
        const csb_chemvdet: any = this.state.checksEntity.csb_chemvdet;
        const { checksEntity } = this.state;

        let curreval, currency, totalamo;
        if(props.field === 'balances') {
            curreval = 1;
            currency = checksEntity.currency;
            this.calculateGrid(csb_chemvdet, props, value, curreval, currency, +value.replace(/,/g, ''), false, value);
        } else if(props.field === 'curreval') {
            curreval = 1;
            currency = checksEntity.currency;
            const balances = csb_chemvdet[props.index]['balances'];
            let _value = +balances[currency].debitamo.replace(/,/g, '') + +balances[currency].assetamo.replace(/,/g, '');
            this.calculateGrid(csb_chemvdet, props, value, curreval, currency, _value, false, _value.toString());
        }

        if(props.field !== 'balances')
            csb_chemvdet[props.index][props.field] = value;

        const optotals = {
            '00000014': { debitamo: 0.00, assetamo: 0.00 },
            '00000015': { debitamo: 0.00, assetamo: 0.00 },
        };
        for (const iterator of csb_chemvdet) {
            const { balances } = iterator;
            optotals['00000014'].debitamo += +balances['00000014'].debitamo.replace(/,/g, '');
            optotals['00000014'].assetamo += +balances['00000014'].assetamo.replace(/,/g, '');
            optotals['00000015'].debitamo += +balances['00000015'].debitamo.replace(/,/g, '');
            optotals['00000015'].assetamo += +balances['00000015'].assetamo.replace(/,/g, '');
        }

        this.setState((state: FormChecksState) => ({
            ...state,
            dateoper: props.field === 'dateoper' ? value : state.dateoper,
            curreval: props.field === 'curreval' ? value : state.curreval,
            checksEntity: {
                ...state.checksEntity,
                optotals,
                csb_chemvdet,
            }
        }))

        if(props.field === 'dateoper') {
            const callback = (i) => {
                const { tables } = this.props.generalReducers;
                console.log(tables.exchangeRate);
                if(tables.exchangeRate !== null) {
                    this.onEditCell(
                        {index: i, field: 'curreval' }, 
                        tables.exchangeRate.venta
                    )
                }
            };
            this.props.currencyExchangeRateRequest(moment(csb_chemvdet[props.index]['dateoper']).format('YYYY-MM-DD'), () => callback(props.index));
        }
    }

    onAddDetail = () => {
        const { csb_chemvdet, currency } = this.state.checksEntity;
        const { grl_definiti } = this.props.financeReducers.tables;
        const itemnumb = this.state.itemnumb + 1;

        const newItem: any = {
            itemnumb: itemnumb,
            typevouc: grl_definiti.filter((x: any) => x.defitype === 'TCSVO')[0].deficode,
            dateoper: this.state.dateoper,
            curreval: this.state.curreval,
            glosarys: '',
            checknum: '',
            accaccou: null,
            fiscyear: 0,
            denomina: '',
            balances: {
                '00000014': { debitamo: '0.00', assetamo: '0.00' },
                '00000015': { debitamo: '0.00', assetamo: '0.00' },
            }
        };
        (csb_chemvdet as any).push(newItem);
        this.setState((state: any) => ({
            ...state,
            itemnumb,
            checksEntity: {
                ...state.checksEntity,
                csb_chemvdet,
            }
        }))
    }

    onDeleteDetail = () => {
        let { csb_chemvdet } = this.state.checksEntity;        
        csb_chemvdet = csb_chemvdet.filter((x: any) => x.select === false || x.select === undefined);

        const optotals = {
            '00000014': { debitamo: 0.00, assetamo: 0.00 },
            '00000015': { debitamo: 0.00, assetamo: 0.00 },
        };
        for (const iterator of csb_chemvdet) {
            const { balances } = iterator;
            optotals['00000014'].debitamo += +balances['00000014'].debitamo.replace(/,/g, '');
            optotals['00000014'].assetamo += +balances['00000014'].assetamo.replace(/,/g, '');
            optotals['00000015'].debitamo += +balances['00000015'].debitamo.replace(/,/g, '');
            optotals['00000015'].assetamo += +balances['00000015'].assetamo.replace(/,/g, '');
        }

        this.setState((state: FormChecksState) => ({
            ...state,
            checksEntity: {
                ...state.checksEntity,
                optotals,
                csb_chemvdet,
            }
        }))
    }

    renderDetail = () => {
        const { grl_definiti, pbl_supplier, acc_planacco, pbl_purchase } = this.props.financeReducers.tables;
        const { csb_chemvdet, currency } = this.state.checksEntity;

        return csb_chemvdet.map((item: any, i: any) => (
            <tr key={ i } >
                <td style={{minWidth:'50px'}}>
                    <input type="checkbox"
                    checked={ item.select } 
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'select' }, 
                            e.target.checked
                        )}
                    />
                </td>
                <td style={{minWidth:'80px'}}>
                    <select style={{width:'100%'}} value={ item.typevouc } 
                        onChange={ (e: any) => { 
                            this.onEditCell({index: i, field: 'typevouc'}, e.target.value)
                        }}
                    >
                        { grl_definiti.filter((x: any) => x.defitype === 'TCSVO').map((item:any) => <option value={item.deficode} key={item.deficode}>{item.definame}</option>) }
                    </select>
                </td>
                <td style={{minWidth:'120px'}}>
                    <input type="date"
                    value={ item.dateoper } 
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'dateoper' }, 
                            e.target.value
                        )}
                    />
                </td>
                <td style={{width:'50px'}}>
                    <input type="text"
                    value={ item.curreval } 
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'curreval' }, 
                            e.target.value
                        )}
                    style={{ textAlign: 'right' }}
                    />
                </td>
                <td style={{minWidth:'150px'}}>
                    <input type="text"
                    value={ item.glosarys } 
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'glosarys' }, 
                            e.target.value
                        )}
                    style={{ textAlign: 'left' }}
                    />
                </td>
                <td style={{minWidth:'150px'}}>
                    <input type="text"
                    value={ item.checknum } 
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'checknum' }, 
                            e.target.value
                        )}
                    style={{ textAlign: 'left' }}
                    />
                </td>
                <td className={ item.documnum || '' !== '' ? "disabled" : '' } style={{minWidth:'130px'}}>
                    <Autocomplete
                        id="accaccou"
                        freeSolo
                        options={acc_planacco}
                        getOptionLabel={ (x: any) => x.accaccou }
                        value={ acc_planacco.find((x: any) => x.accaccou === item.accaccou) || null }
                        onChange={(e: any, value) => {
                            console.log(value);
                            this.onEditCell({index: i, field: 'accaccou'}, value.accaccou);
                            this.onEditCell({index: i, field: 'fiscyear'}, value.fiscyear);
                            this.onEditCell({index: i, field: 'denomina'}, value.denomina);
                            this.onEditCell({index: i, field: 'chargacc'}, value.chargacc);
                            this.onEditCell({index: i, field: 'credtacc'}, value.credtacc);
                        }}
                        renderInput={(params) => (
                            <TextField {...params} 
                            style={ { marginTop: 0 } } margin="normal" variant="outlined" />
                        )}
                        disabled={ item.documnum || '' !== '' }
                    />
                </td>
                <td className="disabled" style={{minWidth:'70px'}}>
                    <input type="text"
                    value={ item.denomina } 
                    style={{ textAlign: 'left' }}
                    disabled={ true }
                    />
                </td>
                <td className={ item.typevouc !== 'TCSVO001' ? "disabled" :  currency !== '00000014' ? 'disabled' : '' } style={{minWidth:'70px'}}>
                    <input type="text"
                    value={ item.balances['00000014'].debitamo } 
                    onFocus={(e) => e.target.select() }
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'balances' },
                            e.target.value 
                        )}
                    style={{ textAlign: 'right' }}
                    disabled={ item.typevouc !== 'TCSVO001' ? true : currency !== '00000014' ? true : false }
                    />
                </td>
                <td className={ item.typevouc !== 'TCSVO002' ? "disabled" : currency !== '00000014' ? 'disabled' : '' } style={{minWidth:'70px'}}>
                    <input type="text"
                    value={ item.balances['00000014'].assetamo }
                    onFocus={(e) => e.target.select() }
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'balances' }, 
                            e.target.value 
                        )}
                    style={{ textAlign: 'right' }}
                    disabled={ item.typevouc !== 'TCSVO002' ? true : currency !== '00000014' ? true : false  }
                    />
                </td>
                <td className={ item.typevouc !== 'TCSVO001' ? "disabled" : currency !== '00000015' ? 'disabled' : '' } style={{minWidth:'70px'}}>
                    <input type="text"
                    value={ item.balances['00000015'].debitamo }
                    onFocus={(e) => e.target.select() }
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'balances' }, 
                            e.target.value 
                        )}
                    style={{ textAlign: 'right' }}
                    disabled={ item.typevouc !== 'TCSVO001' ? true : currency !== '00000015' ? true : false  }
                    />
                </td>
                <td className={ item.typevouc !== 'TCSVO002' ? "disabled" : currency !== '00000015' ? 'disabled' : '' } style={{minWidth:'70px'}}>
                    <input type="text"
                    value={ item.balances['00000015'].assetamo }
                    onFocus={(e) => e.target.select() }
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'balances' }, 
                            e.target.value
                        )}
                    style={{ textAlign: 'right' }}
                    disabled={ item.typevouc !== 'TCSVO002' ? true : currency !== '00000015' ? true : false }
                    />
                </td>
            </tr>
        ))
    }

    renderGrilla = () => {
        return (
            <div className="form">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className="form__field">
                            <div className="grid__actions">
                                <button className="add" onClick={ (e) => { e.preventDefault(); this.onAddDetail() } }><i className="fas fa-plus"/></button>
                                <button className="remove" onClick={ (e) => { e.preventDefault(); this.onDeleteDetail() } }><i className="fas fa-times"/></button>
                                <span style={ { marginLeft: '15px' } }>[ENTER]: Agregar nuevo item</span>
                                <span style={ { marginLeft: '15px' } }>[TAB]: Navegar entre las celdas</span>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <EditableCell
                            header={ header }
                            data={ this.renderDetail() }
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }

    componentDidMount() {
        const { params } = this.props.match;
        if (params.correlat !== undefined) {
            this.setState({title: 'Editar Cheques'});
            this.props.checksInitUpdateFormRequest(params.correlat, this.callbackInitUpdate);
        } else {
            this.props.checksInitFormRequest(this.props.dashboardReducers.currentFiscyear, this.callbackInit);
        }
    }

    render() {

        const { checksEntity, title, snackbar } = this.state;
        const { loading, list, tables, failed, failedTitle, failedContent } = this.props.financeReducers;
        const { classes } = this.props;

        return (
            <div style={{overflow: 'auto', height: '100%'}}>
                <Paper className='form__container'>
                    <br/>
                    <Typography variant='h5'>{ title }</Typography>
                    <br/>
                    { failed && <MessageBox title={failedTitle} content={failedContent}/> }
                    <form noValidate autoComplete="off">
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <TextField fullWidth id="yearexer" label="Ejercicio - Año" variant="outlined" value={ checksEntity.yearexer || '' } onChange={(e: any) => this.onChangeChecksRoot({'yearexer': e.target.value})}/>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                        <InputLabel id="montexer">Ejercicio - Mes</InputLabel>
                                        <Select
                                            labelId="lbl_mes"
                                            id="month"
                                            className='select'
                                            label="Mes"
                                            fullWidth
                                            value={ checksEntity.montexer || '' }
                                            onChange={(e: any) => this.onChangeChecksRoot({'montexer': e.target.value})}
                                        >
                                            {
                                                meses.map((x: any) => 
                                                    <MenuItem value={x.key} key={x.key}>{x.value}</MenuItem>
                                                )
                                            }
                                        </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                    <InputLabel id="lbl_moneda">Moneda</InputLabel>
                                    <Select
                                        labelId="lbl_moneda"
                                        id="currency"
                                        className='select'
                                        label="Tipo de moneda"
                                        fullWidth
                                        value={checksEntity.currency}
                                        onChange={(e: any) => this.onChangeChecksRoot({'currency': e.target.value, 'csb_chemvdet': []})}
                                    >
                                        {
                                            tables.grl_definiti.filter((x: any) => x.defitype === 'CURCY').map((item: any, key: any) => 
                                                <MenuItem value={item.deficode} key={`moneda_${key}`}>{item.definame}</MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div style={{ height: 50 }}/>
                        <Grid container spacing={2} >
                            { this.renderGrilla()  }
                        </Grid>
                        <div style={{ height: 50 }}/>
                        <Grid container spacing={2} justify="flex-end">
                            <Grid item xs={1}>
                                <TextField fullWidth id="00000014debitamo" label="T. Debe Soles" variant="outlined" value={ String.formatNumber.new(checksEntity.optotals['00000014'].debitamo) }/>
                            </Grid>
                            <Grid item xs={1}>
                                <TextField fullWidth id="00000014assetamo" label="T. Haber Soles" variant="outlined" value={ String.formatNumber.new(checksEntity.optotals['00000014'].assetamo) }/>
                            </Grid>
                            <Grid item xs={1}>
                                <TextField fullWidth id="00000015debitamo" label="T. Debe Dolar" variant="outlined" value={ String.formatNumber.new(checksEntity.optotals['00000015'].debitamo) }/>
                            </Grid>
                            <Grid item xs={1}>
                                <TextField fullWidth id="00000015assetamo" label="T. Haber Dolar" variant="outlined" value={ String.formatNumber.new(checksEntity.optotals['00000015'].assetamo) }/>
                            </Grid>
                        </Grid>
                    </form>
                    <br/>
                    <FormFooter 
                        onCancel={ this.onCancel } 
                        onSubmitContinue={ this.onSubmitContinue } 
                        onSubmit={ this.onSubmit }/>
                </Paper>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={snackbar}
                    onClose={() => this.setState({snackbar: false})}
                    autoHideDuration={6000}
                >
                    <Alert onClose={() => this.setState({snackbar: false})} severity="success">
                            Datos Guardados Correctamente
                    </Alert>
                </Snackbar>
                <ScreenLoader enabled={loading}/>
            </div>
        );
    }
}

const financeReducers = 'financeReducers';
const dashboardReducers = 'dashboardReducers';
const generalReducers = 'generalReducers';

const mapStateToProps = (state: any) => ({
    financeReducers: state.get(financeReducers),
    dashboardReducers: state.get(dashboardReducers),
    generalReducers: state.get(generalReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        checksInitFormRequest,
        checksInitUpdateFormRequest,
        checksInsertRequest,
        checksUpdateRequest,
        currencyExchangeRateRequest
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(FormChecks));