import { baseModels } from 'penta-models';
import { 
    CASH_BANKS_DELETE, 
    CASH_BANKS_GET_ALL_RECORDS, 
    CASH_BANKS_INIT_FORM, 
    CASH_BANKS_INIT_UPDATE_FORM, 
    CASH_BANKS_INSERT, 
    CASH_BANKS_MOVEMENTS_DELETE, 
    CASH_BANKS_MOVEMENTS_GET_ALL_RECORDS, 
    CASH_BANKS_MOVEMENTS_INIT_FORM, 
    CASH_BANKS_MOVEMENTS_INIT_UPDATE_FORM, 
    CASH_BANKS_MOVEMENTS_INSERT, 
    CASH_BANKS_MOVEMENTS_UPDATE, 
    CASH_BANKS_UPDATE,
    CHECKS_BALANCE,
    CHECKS_DELETE,
    CHECKS_GET_ALL_RECORDS,
    CHECKS_INIT_FORM,
    CHECKS_INIT_UPDATE_FORM,
    CHECKS_INSERT,
    CHECKS_ISSUED_SUMMARY,
    CHECKS_UPDATE
} from 'services/constants/financeConstants';
import request from './basePipeline';

//#region CASH BANKS

export const cashBanksInitForm = (fiscyear: number) => {
    return request.getWithToken(CASH_BANKS_INIT_FORM + fiscyear);
}

export const cashBanksInitUpdateForm = (idcsbank: number) => {
    return request.getWithToken(CASH_BANKS_INIT_UPDATE_FORM + idcsbank)
}

export const cashBanksGetAllRecords = (body: baseModels.pentaRequestQuery) => {
    return request.postWithToken(CASH_BANKS_GET_ALL_RECORDS, body);
}

export const cashBanksInsert = (body: any) => {
    return request.postWithToken(CASH_BANKS_INSERT, body)
}

export const cashBanksUpdate = (idcsbank: number, body: any) => {
    return request.putWithToken(CASH_BANKS_UPDATE + idcsbank, body)
}

export const cashBanksDelete = (idcsbanks: Array<number>) => {
    const urlSearchParams = new URLSearchParams();
    for(const idcsbank of idcsbanks) {
        urlSearchParams.append("idcsbank", idcsbank.toString());
    }
    return request.deleteWithToken(CASH_BANKS_DELETE + urlSearchParams.toString());
}

//#endregion

//#region CASH BANKS MOVEMENTS

export const cashBanksMovementsInitForm = (fiscyear: number) => {
    return request.getWithToken(CASH_BANKS_MOVEMENTS_INIT_FORM + fiscyear);
}

export const cashBanksMovementsInitUpdateForm = (yearexer: string, montexer: string, weekexer: string, pagenumb: number, idcsbank: number) => {
    return request.getWithToken(CASH_BANKS_MOVEMENTS_INIT_UPDATE_FORM + yearexer + '/' + montexer + '/' + weekexer + '/' + pagenumb + '/' + idcsbank)
}

export const cashBanksMovementsGetAllRecords = (body: baseModels.pentaRequestQuery) => {
    return request.postWithToken(CASH_BANKS_MOVEMENTS_GET_ALL_RECORDS, body);
}

export const cashBanksMovementsInsert = (body: any) => {
    return request.postWithToken(CASH_BANKS_MOVEMENTS_INSERT, body)
}

export const cashBanksMovementsUpdate = (yearexer: string, montexer: string, weekexer: string, pagenumb: number, idcsbank: number, body: any) => {
    return request.putWithToken(CASH_BANKS_MOVEMENTS_UPDATE + yearexer + '/' + montexer + '/' + weekexer + '/' + pagenumb + '/' + idcsbank, body)
}

export const cashBanksMovementsDelete = (yearexers: Array<string>, montexers: Array<string>, weekexers: Array<string>, pagenumbs: Array<number>, idcsbanks: Array<number>) => {
    const urlSearchParams = new URLSearchParams();
    for(const yearexer of yearexers) {
        urlSearchParams.append("yearexer", yearexer.toString());
    }
    for(const montexer of montexers) {
        urlSearchParams.append("montexer", montexer.toString());
    }
    for(const weekexer of weekexers) {
        urlSearchParams.append("weekexer", weekexer.toString());
    }
    for(const pagenumb of pagenumbs) {
        urlSearchParams.append("pagenumb", pagenumb.toString());
    }
    for(const idcsbank of idcsbanks) {
        urlSearchParams.append("idcsbank", idcsbank.toString());
    }
    return request.deleteWithToken(CASH_BANKS_MOVEMENTS_DELETE + urlSearchParams.toString());
}

//#endregion

//#region CHEKS

export const checksInitForm = (fiscyear: number) => {
    return request.getWithToken(CHECKS_INIT_FORM + fiscyear);
}

export const checksInitUpdateForm = (correlat: number) => {
    return request.getWithToken(CHECKS_INIT_UPDATE_FORM + correlat);
}

export const checksGetAllRecords = (body: baseModels.pentaRequestQuery) => {
    return request.postWithToken(CHECKS_GET_ALL_RECORDS, body);
}

export const checksInsert = (body: any) => {
    return request.postWithToken(CHECKS_INSERT, body)
}

export const checksUpdate = (correlat: number, body: any) => {
    return request.putWithToken(CHECKS_UPDATE + correlat, body)
}

export const checksDelete = (correlats: Array<string>) => {
    const urlSearchParams = new URLSearchParams();
    for(const correlat of correlats) {
        urlSearchParams.append("correlat", correlat);
    }
    return request.deleteWithToken(CHECKS_DELETE + urlSearchParams.toString());
}

//#endregion

//#region 

export const checksBalance = (fiscyear: string, month: string, currency: string) => {
    return request.getWithToken(CHECKS_BALANCE + fiscyear + '/' + month + '/' + currency);
}

export const checksIssuedSummary = (fiscyear: string, month: string, initdate: string, endidate: string) => {
    return request.getWithToken(CHECKS_ISSUED_SUMMARY + fiscyear + '/' + month + '/' + initdate + '/' + endidate);
}

//#endregion