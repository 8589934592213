import React from 'react';
import { Backdrop, Button, ButtonGroup, Checkbox, CircularProgress, createStyles, FormControl, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Select, TextField, Theme, Tooltip, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { pdtStaffRequest } from 'penta-redux/actions/humanResourcesActions';
import history from '../../../../utils/history';
import './../reports.scss'
import FormFooter from 'penta-components/base/formFooter';
import { MessageBox } from 'penta-components/base/messageBox';
import { categoryEntity } from 'models/wharehouseProductsModels';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PdfView from 'penta-components/base/pdfView';
// import * as pdfMake from "pdfmake/build/pdfmake";
// import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
import * as XLSX from 'sheetjs-style';
import ScreenLoader from 'penta-components/base/screenLoader';

let pdfMake = require("pdfmake/build/pdfmake");
let saveAs = require('file-saver').saveAs;

const meses = [
    { key: '01', value: 'Enero' },
    { key: '02', value: 'Febrero' },
    { key: '03', value: 'Marzo' },
    { key: '04', value: 'Abril' },
    { key: '05', value: 'Mayo' },
    { key: '06', value: 'Junio' },
    { key: '07', value: 'Julio' },
    { key: '08', value: 'Agosto' },
    { key: '09', value: 'Septiembre' },
    { key: '10', value: 'Octubre' },
    { key: '11', value: 'Noviembre' },
    { key: '12', value: 'Diciembre' }
]

const useStyles = (theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        formControl: {
            margin: 0,
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    });

interface FormState {
    title: string
    year: string
    month: string
    base64Pdf: string
}

class ReportPdtStaff extends React.Component<any, FormState> {

    constructor(props: any) {
        super(props);
        this.state = {
            title: 'Reporte PDT Empleados y Obreros',
            year: moment().format('yyyy').toString(),
            month: moment().format('MM').toString(),
            base64Pdf: ''
        }
    }

    onChangeRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            ...value
        }))
    }

    onGeneratePdf = () => {
        const data = this.props.humanResourcesReducers.onlyOne;
        const { month, year } = this.state;
        const { dashboardReducers } = this.props;
        const mes = meses.find((x: any) => x.key === month).value;
        const company = (JSON.parse(localStorage.getItem('tables')).companys.find((x: any) => x.taxident === dashboardReducers.currentCompany));
        const division = company.lst_grl_division.find((x: any) => x.divicode === dashboardReducers.currentDivision);
        const oficina = division.lst_grl_bussofic.find((x: any) => x.officode === dashboardReducers.currentBussOfic);
        const stardate = new Date(parseInt(year), parseInt(month) - 1, 1);
        const endidate = new Date(parseInt(year), parseInt(month) - 1, moment(stardate).daysInMonth());

        const pdfObject: any = {
            pageSize: 'A4',
            pageOrientation: 'portrait',
            pageMargins: [ 20, 80, 20, 20 ],
            header: [
                {
                    text: ' ', lineHeight: 2
                },
                {
                    margin: [20, 0, 0, 0],
                    text: 'SERVICENTRO LAS AMERICAS S.A.C.',
                    style: {
                        bold: true,
                        fontSize: 9
                    }
                },
                {
                    margin: [20, 5, 0, 0],
                    text: `PDT EMPLEADOS Y OBREROS ${mes.toUpperCase()} ${year}`,
                    style: {
                        fontSize: 9,
                        alignment: 'center'
                    }
                }
            ],
            content: [
                {
                    table: {
                        headerRows: 0,
                        widths: [ 30, 100, 50, 50, 45, 50, 50, 50, 50 ],
                        body: []
                    }
                },
            ],
            styles: {
                headerTitle: {
                    bold: true,
                    fontSize: 7,
                    lineHeight: 1.2
                },
                table: {
                    layout: 'headerLineOnly',
                    fontSize: 6,
                    alignment: 'center'
                },
                subTable: {
                    layout: 'headerLineOnly',
                    fontSize: 6,
                    alignment: 'left'
                },
                tableDetailLeft: {
                    layout: 'headerLineOnly',
                    fontSize: 6,
                    alignment: 'left'
                },
                tableDetailRight: {
                    layout: 'headerLineOnly',
                    fontSize: 6,
                    alignment: 'right'
                },
                tableDetailRightBold: {
                    layout: 'headerLineOnly',
                    fontSize: 6,
                    alignment: 'right',
                    bold: true
                }
            },
            defaultStyle: {
                columnGap: 20,
            },
        }

        const tableBody: any = [
            [ 
                { text: 'CODIGO', style: 'tableDetailLeft', colSpan: 1, border: [true, true, true, true] },
                { text: 'APELLIDOS Y NOMBRES', style: 'tableDetailLeft', colSpan: 1, border: [true, true, true, true] },
                { text: 'DIAS', style: 'table', colSpan: 1, border: [true, true, true, true] },
                { text: 'T.BRUTO', style: 'table', colSpan: 1, border: [true, true, true, true] },
                { text: 'S.N.P', style: 'table', colSpan: 1, border: [true, true, true, true] },
                { text: 'ESSALUD', style: 'table', colSpan: 1, border: [true, true, true, true] },
                { text: 'ESSALUD VIDA', style: 'table', colSpan: 1, border: [true, true, true, true] },
                { text: 'RETEN 5TA.', style: 'table', colSpan: 1, border: [true, true, true, true] },
                { text: 'TOTAL', style: 'table', colSpan: 1, border: [true, true, true, true] },
            ],
        ];

        const laborers = [];
        let gc1 = 0, gc2 = 0, gc3 = 0, gc4 = 0, gc5 = 0, gc6 = 0, gc7 = 0;
        
        for (const row of data) {
            if(!laborers.includes(row.emplcode)) {
                const laborer = data.filter((x) => x.emplcode === row.emplcode);
                const c1 = laborer.find((x) => x.conccode === (x.payrtype === '102' ? 'C107' : 'C103'))?.outcomee || 0;
                const c2 = laborer.find((x) => x.conccode === (x.payrtype === '102' ? 'C501' : 'C501'))?.outcomee || 0;
                const c3 = laborer.find((x) => x.conccode === (x.payrtype === '102' ? 'C306' : 'C307'))?.outcomee || 0;
                const c4 = laborer.find((x) => x.conccode === (x.payrtype === '102' ? 'C401' : 'C401'))?.outcomee || 0;
                const c5 = laborer.find((x) => x.conccode === (x.payrtype === '102' ? 'C301' : 'C308'))?.outcomee || 0;
                const c6 = laborer.find((x) => x.conccode === (x.payrtype === '102' ? 'C303' : 'C305'))?.outcomee || 0;
                gc1 += row.payrtype === '102' ? Math.round(c1 / 8) : c1; gc2 += c2; gc3 += c3; gc4 += c4; gc5 += c5; gc6 += c6; gc7 += (c3 + c4 + c5 + c6);
                tableBody.push(
                    [ 
                        { text: row.emplcode.toUpperCase(), style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                        { text: row.nameeess.substr(0, 25).toUpperCase(), style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                        { text: String.formatNumber.new(row.payrtype === '102' ? Math.round(c1 / 8) : c1), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                        { text: String.formatNumber.new(c2), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                        { text: String.formatNumber.new(c3), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                        { text: String.formatNumber.new(c4), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                        { text: String.formatNumber.new(c5), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                        { text: String.formatNumber.new(c6), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                        { text: String.formatNumber.new(c3 + c4 + c5 + c6), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                    ]
                )
                laborers.push(row.emplcode);
            }
        }

        tableBody.push(
            [ 
                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                { text: 'TOTALES', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                { text: String.formatNumber.new(gc1), style: 'tableDetailRight', colSpan: 1, border: [false, true, false, true] },
                { text: String.formatNumber.new(gc2), style: 'tableDetailRight', colSpan: 1, border: [false, true, false, true] },
                { text: String.formatNumber.new(gc3), style: 'tableDetailRight', colSpan: 1, border: [false, true, false, true] },
                { text: String.formatNumber.new(gc4), style: 'tableDetailRight', colSpan: 1, border: [false, true, false, true] },
                { text: String.formatNumber.new(gc5), style: 'tableDetailRight', colSpan: 1, border: [false, true, false, true] },
                { text: String.formatNumber.new(gc6), style: 'tableDetailRight', colSpan: 1, border: [false, true, false, true] },
                { text: String.formatNumber.new(gc7), style: 'tableDetailRight', colSpan: 1, border: [false, true, false, true] },
            ],
        )

        pdfObject.content[0].table.body = tableBody;

        pdfMake.fonts = {
            Roboto: {
                normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
                bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
                italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
                bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
            },
        }

        const pdfDocGenerator = pdfMake.createPdf(pdfObject);
        pdfDocGenerator.getDataUrl((data: any) => {
            // console.log(data);
            this.setState({
                base64Pdf: data
            })
        });

    }

    onGenerateExcel = () => {
        try {
            const data = this.props.humanResourcesReducers.onlyOne;
            const { month, year } = this.state;
            const { dashboardReducers } = this.props;
            const mes = meses.find((x: any) => x.key === month).value;
            const company = (JSON.parse(localStorage.getItem('tables')).companys.find((x: any) => x.taxident === dashboardReducers.currentCompany).bussname as string).toUpperCase()

            const tableBody: any = [
                {
                    A: company,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null,
                    G: null,
                    H: null,
                    I: null
                },
                {
                    A: `PDT EMPLEADOS Y OBREROS ${mes.toUpperCase()} ${year}`,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null,
                    G: null,
                    H: null,
                    I: null
                },
                {
                    A: null,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null,
                    G: null,
                    H: null,
                    I: null
                },
                { 
                    A: 'CODIGO',
                    B: 'APELLIDOS Y NOMBRES',
                    C: 'DIAS',
                    D: 'T.BRUTO',
                    E: 'S.N.P',
                    F: 'ESSALUD',
                    G: 'ESSALUD VIDA',
                    H: 'RETEN 5TA.',
                    I: 'TOTAL'
                },
            ];

            const laborers = [];
            let gc1 = 0, gc2 = 0, gc3 = 0, gc4 = 0, gc5 = 0, gc6 = 0, gc7 = 0;

            for (const row of data) {
                if(!laborers.includes(row.emplcode)) {
                    const laborer = data.filter((x) => x.emplcode === row.emplcode);
                    const c1 = laborer.find((x) => x.conccode === (x.payrtype === '102' ? 'C107' : 'C103'))?.outcomee || 0;
                    const c2 = laborer.find((x) => x.conccode === (x.payrtype === '102' ? 'C501' : 'C501'))?.outcomee || 0;
                    const c3 = laborer.find((x) => x.conccode === (x.payrtype === '102' ? 'C306' : 'C307'))?.outcomee || 0;
                    const c4 = laborer.find((x) => x.conccode === (x.payrtype === '102' ? 'C401' : 'C401'))?.outcomee || 0;
                    const c5 = laborer.find((x) => x.conccode === (x.payrtype === '102' ? 'C301' : 'C308'))?.outcomee || 0;
                    const c6 = laborer.find((x) => x.conccode === (x.payrtype === '102' ? 'C303' : 'C305'))?.outcomee || 0;
                    gc1 += row.payrtype === '102' ? Math.round(c1 / 8) : c1; gc2 += c2; gc3 += c3; gc4 += c4; gc5 += c5; gc6 += c6; gc7 += (c3 + c4 + c5 + c6);
                    tableBody.push(
                        { 
                            A: row.emplcode.toUpperCase(),
                            B: row.nameeess.substr(0, 25).toUpperCase(),
                            C: String.formatNumber.new(row.payrtype === '102' ? Math.round(c1 / 8) : c1),
                            D: String.formatNumber.new(c2),
                            E: String.formatNumber.new(c3),
                            F: String.formatNumber.new(c4),
                            G: String.formatNumber.new(c5),
                            H: String.formatNumber.new(c6),
                            I: String.formatNumber.new(c3 + c4 + c5 + c6)
                        }
                    );
                    laborers.push(row.emplcode);
                }
            }
    
            tableBody.push(
                { 
                    A: '',
                    B: 'TOTALES',
                    C: String.formatNumber.new(gc1),
                    D: String.formatNumber.new(gc2),
                    E: String.formatNumber.new(gc3),
                    F: String.formatNumber.new(gc4),
                    G: String.formatNumber.new(gc5),
                    H: String.formatNumber.new(gc6),
                    I: String.formatNumber.new(gc7)
                }
            );


            const header = [
                'A',
                'B',
                'C',
                'D',
                'E',
                'F',
                'G',
                'H',
                'I'
            ];

            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(tableBody, {header, skipHeader: true});
            ws["!cols"] = [
                {wpx:50},{wpx:150},{wpx:50},{wpx:60},{wpx:60},{wpx:60},{wpx:60},{wpx:60},{wpx:60}
            ];
            ws['!rows'] = [
                {hpx: 16},{hpx: 18},{hpx: 16},{hpx: 16}
            ];

            const keys = Object.keys(ws);
            const rowsCount = tableBody.length;

            for (const iterator of keys) {
                if(!isNaN(parseInt(ws[iterator].v)) && !iterator.match(/^(A|B|C)[0-9]+$/g)) {
                    ws[iterator].v = ws[iterator].v.replace(/,/g, '');
                    ws[iterator].t = 'n';
                    ws[iterator].z = '#,##0.00';
                }
                if(!isNaN(parseInt(ws[iterator].v)) && iterator.match(/^(C)[0-9]+$/g)) {
                    ws[iterator].t = 'n';
                }
                if(iterator !== '!ref' && iterator !== '!cols' && iterator !== '!rows') {
                    ws[iterator].s = {
                        font: {
                            name: 'Tahoma',
                            sz: 8
                        }
                    }
                    const row = +(iterator.match(/\d+/)[0]);
                    if(row === rowsCount && !iterator.match(/^(A|B)[0-9]+$/g)) {
                        ws[iterator].s.border = {
                            ...ws[iterator].s.border,
                            bottom: { style: 'thin', color: 'black'},
                            top: { style: 'thin', color: 'black'}
                        }
                    }
                }
            }

            for (let index = 1; index <= rowsCount; index++) {
                ws['!rows'].push({hpx: 12})
            }

            ws['A1'].s = {font:{name: 'Tahoma', sz: 8, bold: true}}
            ws['A2'].s = {font:{name: 'Tahoma', sz: 15, bold: true}, alignment: { wrapText: true, vertical: 'center', horizontal: 'center'}}
            ws['A3'].s = {font:{name: 'Tahoma', sz: 8, bold: true}}

            for (const iterator of header) {
                const key1 = iterator + "4";
                ws[key1].s = {
                    ...ws[key1].s, 
                    alignment: { wrapText: true, vertical: 'center', horizontal: 'center'},
                    border: { 
                        top: { style: 'thin', color: 'black'},
                        bottom: { style: 'thin', color: 'black'},
                        left: { style: 'thin', color: 'black'},
                        right: { style: 'thin', color: 'black'}
                    } 
                }
            };

            ws["!merges"] = [
                { s: { r: 1, c: 0 }, e: { r: 1, c: 8 } },
            ];

            XLSX.utils.book_append_sheet(wb, ws, "PDT Staff");

            var wbout = XLSX.write(wb, {bookType:'xlsx', type: 'binary', cellStyles: true});

            const s2ab = function(s) {
                var buf = new ArrayBuffer(s.length);
                var view = new Uint8Array(buf);
                for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
                return buf;
            }

            saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), `pdt-staff-${year}-${month}.xlsx`);

        } catch(err) {
            console.log(err);
        }
    }

    render() {

        const { title, year, month, base64Pdf } = this.state;
        const { loading, list, tables, failed, failedTitle, failedContent } = this.props.humanResourcesReducers;
        const { classes, dashboardReducers } = this.props;

        return (
            <div style={{overflow: 'auto', height: '100%'}}>
                <Paper className='form__container'>
                    <Typography variant='h5'>{ title }</Typography>
                    <br/>
                    { failed && <MessageBox title={failedTitle} content={failedContent}/> }
                    <div style={{ display: 'flex' }}>
                        <form noValidate autoComplete="off" style={{ flex: 1, paddingRight: '15px', paddingTop: '2em' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_año">Año</InputLabel>
                                            <Select
                                                labelId="lbl_año"
                                                id="year"
                                                className='select'
                                                label="Año"
                                                fullWidth
                                                value={ year }
                                                onChange={(e: any) => this.onChangeRoot({'year': e.target.value})}
                                            >
                                                <MenuItem value='2030'>2030</MenuItem>
                                                <MenuItem value='2029'>2029</MenuItem>
                                                <MenuItem value='2028'>2028</MenuItem>
                                                <MenuItem value='2027'>2027</MenuItem>
                                                <MenuItem value='2026'>2026</MenuItem>
                                                <MenuItem value='2025'>2025</MenuItem>
                                                <MenuItem value='2026'>2026</MenuItem>
                                                <MenuItem value='2025'>2025</MenuItem>
                                                <MenuItem value='2024'>2024</MenuItem>
                                                <MenuItem value='2023'>2023</MenuItem>
                                                <MenuItem value='2022'>2022</MenuItem>
                                                <MenuItem value='2021'>2021</MenuItem>
                                                <MenuItem value='2020'>2020</MenuItem>
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_mesi">Mes</InputLabel>
                                            <Select
                                                labelId="lbl_mes"
                                                id="month"
                                                className='select'
                                                label="Mes Inicio"
                                                fullWidth
                                                value={ month }
                                                onChange={(e: any) => this.onChangeRoot({'month': e.target.value})}
                                            >
                                                {
                                                    meses.map((x: any) => 
                                                        <MenuItem value={x.key} key={x.key}>{x.value}</MenuItem>
                                                    )
                                                }
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} style={{ paddingTop: '50px' }}>
                                    <Button variant="contained" fullWidth color="primary" onClick={() => { 
                                        this.props.pdtStaffRequest(year, month, this.onGeneratePdf) 
                                    }}>
                                        Generar Reporte PDF
                                    </Button>
                                </Grid>
                                <Grid item xs={12} style={{ paddingTop: '10px' }}>
                                    <Button variant="contained" fullWidth color="inherit" onClick={() => { 
                                        this.props.pdtStaffRequest(year, month, this.onGenerateExcel) 
                                    }}>
                                        Generar Reporte EXCEL
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                        <PdfView data={base64Pdf} style={{ flex: 2 }}/>
                    </div>
                    <br/>
                    {/* <FormFooter onCancel={ this.onCancel } onSubmit={ this.onSubmit }/> */}
                </Paper>
                <ScreenLoader enabled={loading}/>
            </div>
        );
    }
}

const humanResourcesReducers = 'humanResourcesReducers';
const dashboardReducers = 'dashboardReducers';

const mapStateToProps = (state: any) => ({
    humanResourcesReducers: state.get(humanResourcesReducers),
    dashboardReducers: state.get(dashboardReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        pdtStaffRequest
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(ReportPdtStaff));