import { baseModels } from 'penta-models';
import { CATEGORYS_DELETE, CATEGORYS_GET_ALL_RECORDS, CATEGORYS_INIT_FORM, CATEGORYS_INIT_UPDATE_FORM, CATEGORYS_INSERT, CATEGORYS_UPDATE, FAMILIES_DELETE, FAMILIES_GET_ALL_RECORDS, FAMILIES_INIT_FORM, FAMILIES_INIT_UPDATE_FORM, FAMILIES_INSERT, FAMILIES_UPDATE, PRODUCTS_DELETE, PRODUCTS_GET_ALL_RECORDS, PRODUCTS_INIT_FORM, PRODUCTS_INIT_UPDATE_FORM, PRODUCTS_INSERT, PRODUCTS_UPDATE, UNITS_DELETE, UNITS_GET_ALL_RECORDS, UNITS_INIT_UPDATE_FORM, UNITS_INSERT, UNITS_UPDATE, WAREHOUSES_DELETE, WAREHOUSES_GET_ALL_RECORDS, WAREHOUSES_INIT_UPDATE_FORM, WAREHOUSES_INSERT, WAREHOUSES_UPDATE } from 'services/constants/wharehouseProductsConstants';
import request from './basePipeline';

export const categorysInitForm = (fiscyear: string) => {
    return request.getWithToken(CATEGORYS_INIT_FORM + fiscyear);
}

export const categorysInitUpdateForm = (ctgrcode: string, fiscyear: string) => {
    return request.getWithToken(CATEGORYS_INIT_UPDATE_FORM + ctgrcode + "/" + fiscyear)
}

export const categorysGetAllRecords = (body: baseModels.pentaRequestQuery) => {
    return request.postWithToken(CATEGORYS_GET_ALL_RECORDS, body);
}

export const categorysInsert = (body: any) => {
    return request.postWithToken(CATEGORYS_INSERT, body)
}

export const categorysUpdate = (ctgrcode: string, body: any) => {
    return request.putWithToken(CATEGORYS_UPDATE + ctgrcode, body)
}

export const categorysDelete = (ctgrcodes: Array<string>) => {
    const urlSearchParams = new URLSearchParams();
    for(const ctgrcode of ctgrcodes) {
        urlSearchParams.append("ctgrcode", ctgrcode);
    }
    return request.deleteWithToken(CATEGORYS_DELETE + urlSearchParams.toString());
}

export const familiesInitForm = () => {
    return request.getWithToken(FAMILIES_INIT_FORM);
}

export const familiesInitUpdateForm = (famicode: string) => {
    return request.getWithToken(FAMILIES_INIT_UPDATE_FORM + famicode)
}

export const familiesGetAllRecords = (body: baseModels.pentaRequestQuery) => {
    return request.postWithToken(FAMILIES_GET_ALL_RECORDS, body);
}

export const familiesInsert = (body: any) => {
    return request.postWithToken(FAMILIES_INSERT, body)
}

export const familiesUpdate = (famicode: string, body: any) => {
    return request.putWithToken(FAMILIES_UPDATE + famicode, body)
}

export const familiesDelete = (famicodes: Array<string>) => {
    const urlSearchParams = new URLSearchParams();
    for(const famicode of famicodes) {
        urlSearchParams.append("famicode", famicode);
    }
    return request.deleteWithToken(FAMILIES_DELETE + urlSearchParams.toString());
}

export const unitsInitUpdateForm = (meascode: string) => {
    return request.getWithToken(UNITS_INIT_UPDATE_FORM + meascode)
}

export const unitsGetAllRecords = (body: baseModels.pentaRequestQuery) => {
    return request.postWithToken(UNITS_GET_ALL_RECORDS, body);
}

export const unitsInsert = (body: any) => {
    return request.postWithToken(UNITS_INSERT, body)
}

export const unitsUpdate = (meascode: string, body: any) => {
    return request.putWithToken(UNITS_UPDATE + meascode, body)
}

export const unitsDelete = (meascodes: Array<string>) => {
    const urlSearchParams = new URLSearchParams();
    for(const meascode of meascodes) {
        urlSearchParams.append("meascode", meascode);
    }
    return request.deleteWithToken(UNITS_DELETE + urlSearchParams.toString());
}

export const warehousesInitUpdateForm = (wharcode: string) => {
    return request.getWithToken(WAREHOUSES_INIT_UPDATE_FORM + wharcode)
}

export const warehousesGetAllRecords = (body: baseModels.pentaRequestQuery) => {
    return request.postWithToken(WAREHOUSES_GET_ALL_RECORDS, body);
}

export const warehousesInsert = (body: any) => {
    return request.postWithToken(WAREHOUSES_INSERT, body)
}

export const warehousesUpdate = (wharcode: string, body: any) => {
    return request.putWithToken(WAREHOUSES_UPDATE + wharcode, body)
}

export const warehousesDelete = (wharcodes: Array<string>) => {
    const urlSearchParams = new URLSearchParams();
    for(const wharcode of wharcodes) {
        urlSearchParams.append("wharcode", wharcode);
    }
    return request.deleteWithToken(WAREHOUSES_DELETE + urlSearchParams.toString());
}

export const productsInitForm = (fiscyear: string) => {
    return request.getWithToken(PRODUCTS_INIT_FORM + fiscyear);
}

export const productsInitUpdateForm = (skucodee: string) => {
    return request.getWithToken(PRODUCTS_INIT_UPDATE_FORM + skucodee)
}

export const productsGetAllRecords = (body: baseModels.pentaRequestQuery) => {
    return request.postWithToken(PRODUCTS_GET_ALL_RECORDS, body);
}

export const productsInsert = (body: any) => {
    return request.postWithToken(PRODUCTS_INSERT, body)
}

export const productsUpdate = (skucodee: string, body: any) => {
    return request.putWithToken(PRODUCTS_UPDATE + skucodee, body)
}

export const productsDelete = (skucodees: Array<string>) => {
    const urlSearchParams = new URLSearchParams();
    for(const skucodee of skucodees) {
        urlSearchParams.append("skucodee", skucodee);
    }
    return request.deleteWithToken(PRODUCTS_DELETE + urlSearchParams.toString());
}