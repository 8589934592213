import { DASHBOARD_RESET, DASHBOARD_SELECT_MENU, DASHBOARD_SET_WORKER_CONTEXT, DASHBOARD_SET_WORKER_CONTEXT_ONLY, DASHBOARD_SET_WORKER_CONTEXT_SUCCESS } from '../constants/dashboardConstants';

const initialState: any = {
    loading: false,
    failed: false,
    failedTitle: null,
    failedContent: null,
    currentCompany: localStorage.getItem('currentCompany') || '',
    currentDivision: localStorage.getItem('currentDivision') || '',
    currentBussOfic: localStorage.getItem('currentBussOfic') || '',
    currentFiscyear: localStorage.getItem('currentFiscyear') || '',
    selectMenu: localStorage.getItem('selectMenu') || ''
};

export default function dashboardReducer(state = initialState, action: any = {}) {
    switch(action.type) {
        case DASHBOARD_SET_WORKER_CONTEXT:
            return {
                ...state,
                loading: true
            };
        case DASHBOARD_SET_WORKER_CONTEXT_ONLY:
            return {
                ...state,
                currentCompany: localStorage.getItem('currentCompany'),
                currentDivision: localStorage.getItem('currentDivision'),
                currentBussOfic: localStorage.getItem('currentBussOfic'),
                currentFiscyear: localStorage.getItem('currentFiscyear')
            }
        case DASHBOARD_SET_WORKER_CONTEXT_SUCCESS:
            return {
                ...state,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null,
                currentCompany: localStorage.getItem('currentCompany'),
                currentDivision: localStorage.getItem('currentDivision'),
                currentBussOfic: localStorage.getItem('currentBussOfic'),
                currentFiscyear: localStorage.getItem('currentFiscyear')
            }
        case DASHBOARD_SELECT_MENU:
            return {
                ...state,
                selectMenu: action.moducode
            }
        case DASHBOARD_RESET:
            return {
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null,
                // currentCompany: '',
                // currentDivision: '',
                // currentBussOfic: '',
                // currentFiscyear: '',
                selectMenu: ''
            };
        default:
            return state;
    }
}