import React from 'react';
import { Backdrop, Button, ButtonGroup, Checkbox, CircularProgress, createStyles, FormControl, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Select, Snackbar, TextField, Theme, Tooltip, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { familiesInitFormRequest, familiesInitUpdateFormRequest, familiesInsertRequest, familiesUpdateRequest } from 'penta-redux/actions/wharehouseProductsActions';
import history from '../../../utils/history';
import './families.scss'
import FormFooter from 'penta-components/base/formFooter';
import { MessageBox } from 'penta-components/base/messageBox';
import { familyEntity } from 'models/wharehouseProductsModels';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Alert } from '@material-ui/lab';
import ScreenLoader from 'penta-components/base/screenLoader';

const useStyles = (theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        formControl: {
            margin: 0,
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    });

const defaultfamiliesEntity: familyEntity = {
    famicode: '',
    faminame: '',
    ctgrcode: '',
    purcacca: '',
    saleacca: ''
}

interface FormFamiliesState {
    familiesEntity: familyEntity
    title: string
    snackbar: boolean
}

class FormFamilies extends React.Component<any, FormFamiliesState> {

    constructor(props: any) {
        super(props);
        this.state = {
            familiesEntity: defaultfamiliesEntity,
            title: 'Agregar Familias',
            snackbar: false
        }
    }

    onChangeRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            ...value
        }))
    }

    onChangefamiliesRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            familiesEntity: {
                ...state.familiesEntity,
                ...value
            }
        }))
    }

    onCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        console.log('cancel')
        history.push('/papp/products/familias');
    }

    onContinueCallback = () => {
        const newfamiliesEntity = defaultfamiliesEntity;
        this.setState({
            familiesEntity: newfamiliesEntity,
            snackbar: true
        });
    }

    onSubmitContinue = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const { params } = this.props.match;
        if (params.famicode !== undefined) {
            this.props.familiesUpdateRequest(this.state.familiesEntity);
        } else {
            this.props.familiesInsertRequest(this.state.familiesEntity, this.onContinueCallback);
        }
    }

    onSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const { params } = this.props.match;
        if (params.famicode !== undefined) {
            this.props.familiesUpdateRequest(this.state.familiesEntity);
        } else {
            this.props.familiesInsertRequest(this.state.familiesEntity, null);
        }
    }

    callbackInitUpdate = (familiesEntity: familyEntity) => {
        this.setState({
            familiesEntity
        });
    }

    componentDidMount() {
        const { params } = this.props.match;
        if (params.famicode !== undefined) {
            this.setState({title: 'Editar Familias'});
            this.props.familiesInitUpdateFormRequest(params.famicode, this.callbackInitUpdate);
        }
        else {
            this.props.familiesInitFormRequest();
        }
    }

    render() {

        const { familiesEntity, title, snackbar } = this.state;
        const { loading, list, tables, failed, failedTitle, failedContent } = this.props.wharehouseProductsReducers;
        const { classes } = this.props;

        return (
            <div style={{overflow: 'auto', height: '100%'}}>
                <Paper className='form__container'>
                    <br/>
                    <Typography variant='h5'>{ title }</Typography>
                    <br/>
                    { failed && <MessageBox title={failedTitle} content={failedContent}/> }
                    <form noValidate autoComplete="off">
                        <Grid container spacing={2} >
                            <Grid item xs={4}>
                                <TextField fullWidth id="famicode" label="Codigo" variant="outlined" value={ familiesEntity.famicode } onChange={(e: any) => this.onChangefamiliesRoot({'famicode': e.target.value})}/>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth id="faminame" label="Nombre" variant="outlined" value={ familiesEntity.faminame } onChange={(e: any) => this.onChangefamiliesRoot({'faminame': e.target.value})}/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} >
                            <Grid item xs={4}>
                                <Autocomplete
                                    id="ctgrcode"
                                    freeSolo
                                    options={tables.categorys}
                                    getOptionLabel={ (x: any) => x.ctgrcode }
                                    value={ tables.categorys.find((x: any) => x.ctgrcode === familiesEntity.ctgrcode) || null }
                                    onChange={(e: any, value) => this.onChangefamiliesRoot({'ctgrcode': value.ctgrcode})}
                                    renderInput={(params) => (
                                        <TextField {...params} 
                                        style={ { marginTop: 0 } } label="Categoria" margin="normal" variant="outlined" />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Autocomplete
                                    id="purcacca"
                                    freeSolo
                                    options={tables.planAccount}
                                    getOptionLabel={ (x: any) => x.accaccou }
                                    value={ tables.planAccount.find((x: any) => x.accaccou === familiesEntity.purcacca) || {accaccou: '', fiscyear: ''} }
                                    onChange={(e: any, value) => {
                                        this.onChangefamiliesRoot({'purcacca': value?.accaccou})
                                        this.onChangefamiliesRoot({'fiscyear': value?.fiscyear})
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} 
                                        style={ { marginTop: 0 } } label="Cta Contable compras" margin="normal" variant="outlined" />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Autocomplete
                                    id="saleacca"
                                    freeSolo
                                    options={tables.planAccount}
                                    getOptionLabel={ (x: any) => x.accaccou }
                                    value={ tables.planAccount.find((x: any) => x.accaccou === familiesEntity.saleacca) || {accaccou: '', fiscyear: ''} }
                                    onChange={(e: any, value) => {
                                        this.onChangefamiliesRoot({'saleacca': value?.accaccou})
                                        this.onChangefamiliesRoot({'fiscyear': value?.fiscyear})
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} 
                                        style={ { marginTop: 0 } } label="Cta Contable ventas" margin="normal" variant="outlined" />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </form>
                    <br/>
                    <FormFooter 
                        onCancel={ this.onCancel } 
                        onSubmitContinue={ this.onSubmitContinue } 
                        onSubmit={ this.onSubmit }/>
                </Paper>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={snackbar}
                    onClose={() => this.setState({snackbar: false})}
                    autoHideDuration={6000}
                >
                    <Alert onClose={() => this.setState({snackbar: false})} severity="success">
                            Datos Guardados Correctamente
                    </Alert>
                </Snackbar>
                <ScreenLoader enabled={loading}/>
            </div>
        );
    }
}

const wharehouseProductsReducers = 'wharehouseProductsReducers';

const mapStateToProps = (state: any) => ({
    wharehouseProductsReducers: state.get(wharehouseProductsReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        familiesInitFormRequest,
        familiesInitUpdateFormRequest,
        familiesInsertRequest,
        familiesUpdateRequest
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(FormFamilies));