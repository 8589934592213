import React from 'react';
import {createUseStyles} from 'react-jss';

const useStyles =  createUseStyles({
    container: {
        'position': 'relative',
        fontFamily: '"Work Sans", sans-serif',
        color: '#3B3B3B'
    },
});

interface Separator {
    marginRight?: string
    marginBottom?: string
    marginLeft?: string
    marginTop?: string
    children?: React.ReactNode
}

const Separator = (props: Separator) => {
    const classes = useStyles();
    return (
        <div 
            className={classes.container} 
            style={{
                marginRight: props.marginRight,
                marginBottom: props.marginBottom,
                marginLeft: props.marginLeft,
                marginTop: props.marginTop
            }}
        >
            {props.children}
        </div>
    )
}

Separator.defaultProps = {
    marginRight: '0rem',
    marginBottom: '0rem',
    marginLeft: '0rem',
    marginTop: '0rem'
}

export default Separator;