import React from 'react';
import Loadable from 'react-loadable';

export const LoginPage = Loadable({
    loader: () => import('./auth/login'),
    loading: () => { return <div>loading...</div> }
});

export const DashboardPage = Loadable({
    loader: () => import('./dashboard/dashboard'),
    loading: () => { return <div>loading...</div> }
});

export const ListUsersPage = Loadable({
    loader: () => import('./users/listUsers'),
    loading: () => { return <div>loading...</div> }
});

export const FormUsersPage = Loadable({
    loader: () => import('./users/formUsers'),
    loading: () => { return <div>loading...</div> }
});

export const FormMasterAccountPage = Loadable({
    loader: () => import('./masterAccount/formMasterAccount'),
    loading: () => { return <div>loading...</div> }
});

export const FormMajorization = Loadable({
    loader: () => import('./majorization/formMajorization'),
    loading: () => { return <div>loading...</div> }
});

export const ListPlanAccount = Loadable({
    loader: () => import('./planAccount/listPlanAccount'),
    loading: () => { return <div>loading...</div> }
})

export const FormPlanAccount = Loadable({
    loader: () => import('./planAccount/formPlanAccount'),
    loading: () => { return <div>loading...</div> }
})

export const ReportGeneralBalancesAccount = Loadable({
    loader: () => import('./reports/balanceGeneralCuentas/reportGeneralBalancesAccount'),
    loading: () => { return <div>loading...</div> }
})

export const ReportSalesDetailedDiaryBook = Loadable({
    loader: () => import('./reports/libroDiarioVentasDetallado/reportSalesDetailedDiaryBook'),
    loading: () => { return <div>loading...</div> }
})

export const ReportDiaryBook = Loadable({
    loader: () => import('./reports/libroDiario/reportDiaryBook'),
    loading: () => { return <div>loading...</div> }
})

export const ReportPurchasesRegister = Loadable({
    loader: () => import('./reports/registroCompras/reportPurchasesRegister'),
    loading: () => { return <div>loading...</div> }
})

export const ReportWorksheet = Loadable({
    loader: () => import('./reports/hojaTrabajo/reportWorksheet'),
    loading: () => { return <div>loading...</div> }
})

export const ReportMajorGeneral = Loadable({
    loader: () => import('./reports/mayorGeneral/reportMajorGeneral'),
    loading: () => { return <div>loading...</div> }
})

export const ReportCashBookBanks = Loadable({
    loader: () => import('./reports/libroCajaBancos/reportCashBookBanks'),
    loading: () => { return <div>loading...</div> }
})

export const ReportExpenseAnalysisNine = Loadable({
    loader: () => import('./reports/analisisGastosNueve/reportExpenseAnalysisNine'),
    loading: () => { return <div>loading...</div> }
})

export const ReportExpenseAnalysisSix = Loadable({
    loader: () => import('./reports/analisisGastosSeis/reportExpenseAnalysisSix'),
    loading: () => { return <div>loading...</div> }
})

export const ReportInventoryBookBalance = Loadable({
    loader: () => import('./reports/libroInventariosBalances/reportInventoryBookBalance'),
    loading: () => { return <div>loading...</div> }
})

export const ReportGeneralBalances = Loadable({
    loader: () => import('./reports/balancesGeneral/reportGeneralBalances'),
    loading: () => { return <div>loading...</div> }
})

export const ReportBalanceFinancialPosition = Loadable({
    loader: () => import('./reports/balanceSituacionFinanciera/reportBalanceFinancialPosition'),
    loading: () => { return <div>loading...</div> }
})

export const ReportProfitLossStatement = Loadable({
    loader: () => import('./reports/estadoGananciasPerdidas/reportProfitLossStatement'),
    loading: () => { return <div>loading...</div> }
})

export const ReportSalesRegister = Loadable({
    loader: () => import('./reports/registroVentas/reportSalesRegister'),
    loading: () => { return <div>loading...</div> }
})

export const ReportDaot = Loadable({
    loader: () => import('./reports/daot/reportDaot'),
    loading: () => { return <div>loading...</div> }
})

export const ReportPurchaseDetailSupplier = Loadable({
    loader: () => import('./reports/comprasDetalleProveedor/reportPurchaseDetailSupplier'),
    loading: () => { return <div>loading...</div> }
})

export const ReportKardex = Loadable({
    loader: () => import('./reports/kardex/reportKardex'),
    loading: () => { return <div>loading...</div> }
})

export const ReportInvoicesToCharge = Loadable({
    loader: () => import('./reports/facturasCobrar/reportInvoicesToCharge'),
    loading: () => { return <div>loading...</div> }
})

export const ReportInvoicesToPay = Loadable({
    loader: () => import('./reports/facturasPagar/reportInvoicesToPay'),
    loading: () => { return <div>loading...</div> }
})

export const ListCategorys = Loadable({
    loader: () => import('./categorys/listCategorys'),
    loading: () => { return <div>loading...</div> }
})

export const FormCategorys = Loadable({
    loader: () => import('./categorys/formCategorys'),
    loading: () => { return <div>loading...</div> }
})

export const ListFamilies = Loadable({
    loader: () => import('./families/listFamilies'),
    loading: () => { return <div>loading...</div> }
})

export const FormFamilies = Loadable({
    loader: () => import('./families/formFamilies'),
    loading: () => { return <div>loading...</div> }
})

export const ListUnits = Loadable({
    loader: () => import('./units/listUnits'),
    loading: () => { return <div>loading...</div> }
})

export const FormUnits = Loadable({
    loader: () => import('./units/formUnits'),
    loading: () => { return <div>loading...</div> }
})

export const ListWharehouse = Loadable({
    loader: () => import('./wharehouse/listWharehouse'),
    loading: () => { return <div>loading...</div> }
})

export const FormWharehouse = Loadable({
    loader: () => import('./wharehouse/formWharehouse'),
    loading: () => { return <div>loading...</div> }
})

export const ListProducts = Loadable({
    loader: () => import('./products/listProducts'),
    loading: () => { return <div>loading...</div> }
})

export const FormProducts = Loadable({
    loader: () => import('./products/formProducts'),
    loading: () => { return <div>loading...</div> }
})

export const ListSales = Loadable({
    loader: () => import('./sales/listSales'),
    loading: () => { return <div>loading...</div> }
})

export const ListCustomers = Loadable({
    loader: () => import('./customers/listCustomers'),
    loading: () => { return <div>loading...</div> }
})

export const FormCustomers = Loadable({
    loader: () => import('./customers/formCustomers'),
    loading: () => { return <div>loading...</div> }
})

export const ListSuppliers = Loadable({
    loader: () => import('./suppliers/listSuppliers'),
    loading: () => { return <div>loading...</div> }
})

export const FormSuppliers = Loadable({
    loader: () => import('./suppliers/formSuppliers'),
    loading: () => { return <div>loading...</div> }
})

export const ListPurchases = Loadable({
    loader: () => import('./purchases/listPurchases'),
    loading: () => { return <div>loading...</div> }
})

export const FormPurchases = Loadable({
    loader: () => import('./purchases/formPurchases'),
    loading: () => { return <div>loading...</div> }
})

export const listCreditNote= Loadable({
    loader: () => import('./creditNote/listCreditNote'),
    loading: () => { return <div>loading...</div> }
})

export const FormCreditNote = Loadable({
    loader: () => import('./creditNote/formCreditNote'),
    loading: () => { return <div>loading...</div> }
})

export const listDebitNote= Loadable({
    loader: () => import('./debitNote/listDebitNote'),
    loading: () => { return <div>loading...</div> }
})

export const FormDebitNote = Loadable({
    loader: () => import('./debitNote/formDebitNote'),
    loading: () => { return <div>loading...</div> }
})

export const ListCommissions = Loadable({
    loader: () => import('./commissions/listCommissions'),
    loading: () => { return <div>loading...</div> }
})

export const FormCommissions = Loadable({
    loader: () => import('./commissions/formCommissions'),
    loading: () => { return <div>loading...</div> }
})

export const ListWeekends = Loadable({
    loader: () => import('./weekends/listWeekends'),
    loading: () => { return <div>loading...</div> }
})

export const FormWeekends = Loadable({
    loader: () => import('./weekends/formWeekends'),
    loading: () => { return <div>loading...</div> }
})

export const ListEmployes = Loadable({
    loader: () => import('./employes/listEmployes'),
    loading: () => { return <div>loading...</div> }
})

export const FormEmployes = Loadable({
    loader: () => import('./employes/formEmployes'),
    loading: () => { return <div>loading...</div> }
})

export const ListConcepts = Loadable({
    loader: () => import('./concepts/listConcepts'),
    loading: () => { return <div>loading...</div> }
})

export const FormConcepts = Loadable({
    loader: () => import('./concepts/formConcepts'),
    loading: () => { return <div>loading...</div> }
})

export const ListPayroll = Loadable({
    loader: () => import('./payroll/listPayroll'),
    loading: () => { return <div>loading...</div> }
})

export const FormPayroll = Loadable({
    loader: () => import('./payroll/formPayroll'),
    loading: () => { return <div>loading...</div> }
})

export const ReportEmployePayroll = Loadable({
    loader: () => import('./reports/planillaEmpleados/reportEmployePayroll'),
    loading: () => { return <div>loading...</div> }
})

export const ReportLaborerPayroll = Loadable({
    loader: () => import('./reports/planillaObreros/reportLaborerPayroll'),
    loading: () => { return <div>loading...</div> }
})

export const ReportLaborerPayrollAccumulated = Loadable({
    loader: () => import('./reports/planillaObrerosAcumulado/reportLaborerPayrollAcumulated'),
    loading: () => { return <div>loading...</div> }
})

export const ReportEmployePayrollAccumulated = Loadable({
    loader: () => import('./reports/planillaEmpleadosAcumulado/reportEmployePayrollAcumulated'),
    loading: () => { return <div>loading...</div> }
})

export const ReportEmployeVoucher = Loadable({
    loader: () => import('./reports/boletaPagoEmpleado/reportEmployeVoucher'),
    loading: () => { return <div>loading...</div> }
})

export const ReportAfpList = Loadable({
    loader: () => import('./reports/listaAfp/reportAfpList'),
    loading: () => { return <div>loading...</div> }
})

export const ReportPdtStaff = Loadable({
    loader: () => import('./reports/pdtPersonal/reportPdtStaff'),
    loading: () => { return <div>loading...</div> }
})

export const ReportFifthCategoryCalculation = Loadable({
    loader: () => import('./reports/calculoQuintaCategoria/reportFifthCategoryCalculation'),
    loading: () => { return <div>loading...</div> }
})

export const ReportCtsDeposits = Loadable({
    loader: () => import('./reports/depositosCts/reportCtsDeposits'),
    loading: () => { return <div>loading...</div> }
})

export const ListCashBanks = Loadable({
    loader: () => import('./cashBanks/listCashBanks'),
    loading: () => { return <div>loading...</div> }
})

export const FormCashBanks = Loadable({
    loader: () => import('./cashBanks/formCashBanks'),
    loading: () => { return <div>loading...</div> }
})

export const ListCashBanksMovements = Loadable({
    loader: () => import('./cashBanksMovements/listCashBanksMovements'),
    loading: () => { return <div>loading...</div> }
})

export const FormCashBanksMovements = Loadable({
    loader: () => import('./cashBanksMovements/formCashBanksMovements'),
    loading: () => { return <div>loading...</div> }
})

export const ListVouchers = Loadable({
    loader: () => import('./vouchers/listVouchers'),
    loading: () => { return <div>loading...</div> }
})

export const FormVouchers = Loadable({
    loader: () => import('./vouchers/formVouchers'),
    loading: () => { return <div>loading...</div> }
})

export const ListChecks = Loadable({
    loader: () => import('./checks/listChecks'),
    loading: () => { return <div>loading...</div> }
})

export const FormChecks = Loadable({
    loader: () => import('./checks/formChecks'),
    loading: () => { return <div>loading...</div> }
})

export const ReportChecksIssuedSummary = Loadable({
    loader: () => import('./reports/resumenChequesEmitidos/reportChecksIssuedSummary'),
    loading: () => { return <div>loading...</div> }
})

export const ReportChecksBalance = Loadable({
    loader: () => import('./reports/balanceCheques/reportChecksBalance'),
    loading: () => { return <div>loading...</div> }
})

export const ReportPlePurchase = Loadable({
    loader: () => import('./reports/pleCompras/reportPlePurchase'),
    loading: () => { return <div>loading...</div> }
})

export const ReportPleSale = Loadable({
    loader: () => import('./reports/pleVentas/reportPleSale'),
    loading: () => { return <div>loading...</div> }
})

export const ListMasterTables = Loadable({
    loader: () => import('./masterTables/listMasterTables'),
    loading: () => { return <div>loading...</div> }
})