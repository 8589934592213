import React, { Component, CSSProperties, StatelessComponent } from 'react';
// import './grid.css'
interface PdfView {
    data: string
    style: CSSProperties
}

const PdfView : StatelessComponent<PdfView> = ({ data, style }: PdfView) => {
    return (
        <div style={ { paddingTop: '2em', display: 'flex', justifyContent: 'center', ...style } }>
            <iframe src={ data } width='100%' height='100%' style={{ minHeight: '800px' }}>
            
            </iframe>
        </div>
    )
}

export default PdfView;