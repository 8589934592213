//#region CASH BANKS
export const CASH_BANKS_INIT_FORM_REQUEST = 'CASH_BANKS_INIT_FORM_REQUEST';
export const CASH_BANKS_INIT_FORM_REQUEST_SUCCESS = 'CASH_BANKS_INIT_FORM_REQUEST_SUCCESS';
export const CASH_BANKS_INIT_FORM_REQUEST_FAILED = 'CASH_BANKS_INIT_FORM_REQUEST_FAILED';
export const CASH_BANKS_INIT_UPDATE_FORM_REQUEST = 'CASH_BANKS_INIT_UPDATE_FORM_REQUEST';
export const CASH_BANKS_INIT_UPDATE_FORM_REQUEST_SUCCESS = 'CASH_BANKS_INIT_UPDATE_FORM_REQUEST_SUCCESS';
export const CASH_BANKS_INIT_UPDATE_FORM_REQUEST_FAILED = 'CASH_BANKS_INIT_UPDATE_FORM_REQUEST_FAILED';
export const CASH_BANKS_GET_ALL_RECORDS_REQUEST = 'CASH_BANKS_GET_ALL_RECORDS_REQUEST'; 
export const CASH_BANKS_GET_ALL_RECORDS_REQUEST_SUCCESS = 'CASH_BANKS_GET_ALL_RECORDS_REQUEST_SUCCESS'; 
export const CASH_BANKS_GET_ALL_RECORDS_REQUEST_FAILED = 'CASH_BANKS_GET_ALL_RECORDS_REQUEST_FAILED';
export const CASH_BANKS_INSERT_REQUEST = 'CASH_BANKS_INSERT_REQUEST';
export const CASH_BANKS_INSERT_REQUEST_SUCCESS = 'CASH_BANKS_INSERT_REQUEST_SUCCESS';
export const CASH_BANKS_INSERT_REQUEST_FAILED = 'CASH_BANKS_INSERT_REQUEST_FAILED';
export const CASH_BANKS_UPDATE_REQUEST = 'CASH_BANKS_UPDATE_REQUEST';
export const CASH_BANKS_UPDATE_REQUEST_SUCCESS = 'CASH_BANKS_UPDATE_REQUEST_SUCCESS';
export const CASH_BANKS_UPDATE_REQUEST_FAILED = 'CASH_BANKS_UPDATE_REQUEST_FAILED';
export const CASH_BANKS_DELETE_REQUEST = 'CASH_BANKS_DELETE_REQUEST';
export const CASH_BANKS_DELETE_REQUEST_SUCCESS = 'CASH_BANKS_DELETE_REQUEST_SUCCESS';
export const CASH_BANKS_DELETE_REQUEST_FAILED = 'CASH_BANKS_DELETE_REQUEST_FAILED';
/*Components */
export const CASH_BANKS_LIST_SELECT_ITEM = 'CASH_BANKS_LIST_SELECT_ITEM';
export const CASH_BANKS_LIST_SELECT_ALL_ITEM = 'CASH_BANKS_LIST_SELECT_ALL_ITEM';
export const CASH_BANKS_ACTION_FAILED = 'CASH_BANKS_ACTION_FAILED';
//#endregion

//#region CASH BANKS MOVEMENTS
export const CASH_BANKS_MOVEMENTS_INIT_FORM_REQUEST = 'CASH_BANKS_MOVEMENTS_INIT_FORM_REQUEST';
export const CASH_BANKS_MOVEMENTS_INIT_FORM_REQUEST_SUCCESS = 'CASH_BANKS_MOVEMENTS_INIT_FORM_REQUEST_SUCCESS';
export const CASH_BANKS_MOVEMENTS_INIT_FORM_REQUEST_FAILED = 'CASH_BANKS_MOVEMENTS_INIT_FORM_REQUEST_FAILED';
export const CASH_BANKS_MOVEMENTS_INIT_UPDATE_FORM_REQUEST = 'CASH_BANKS_MOVEMENTS_INIT_UPDATE_FORM_REQUEST';
export const CASH_BANKS_MOVEMENTS_INIT_UPDATE_FORM_REQUEST_SUCCESS = 'CASH_BANKS_MOVEMENTS_INIT_UPDATE_FORM_REQUEST_SUCCESS';
export const CASH_BANKS_MOVEMENTS_INIT_UPDATE_FORM_REQUEST_FAILED = 'CASH_BANKS_MOVEMENTS_INIT_UPDATE_FORM_REQUEST_FAILED';
export const CASH_BANKS_MOVEMENTS_GET_ALL_RECORDS_REQUEST = 'CASH_BANKS_MOVEMENTS_GET_ALL_RECORDS_REQUEST'; 
export const CASH_BANKS_MOVEMENTS_GET_ALL_RECORDS_REQUEST_SUCCESS = 'CASH_BANKS_MOVEMENTS_GET_ALL_RECORDS_REQUEST_SUCCESS'; 
export const CASH_BANKS_MOVEMENTS_GET_ALL_RECORDS_REQUEST_FAILED = 'CASH_BANKS_MOVEMENTS_GET_ALL_RECORDS_REQUEST_FAILED';
export const CASH_BANKS_MOVEMENTS_INSERT_REQUEST = 'CASH_BANKS_MOVEMENTS_INSERT_REQUEST';
export const CASH_BANKS_MOVEMENTS_INSERT_REQUEST_SUCCESS = 'CASH_BANKS_MOVEMENTS_INSERT_REQUEST_SUCCESS';
export const CASH_BANKS_MOVEMENTS_INSERT_REQUEST_FAILED = 'CASH_BANKS_MOVEMENTS_INSERT_REQUEST_FAILED';
export const CASH_BANKS_MOVEMENTS_UPDATE_REQUEST = 'CASH_BANKS_MOVEMENTS_UPDATE_REQUEST';
export const CASH_BANKS_MOVEMENTS_UPDATE_REQUEST_SUCCESS = 'CASH_BANKS_MOVEMENTS_UPDATE_REQUEST_SUCCESS';
export const CASH_BANKS_MOVEMENTS_UPDATE_REQUEST_FAILED = 'CASH_BANKS_MOVEMENTS_UPDATE_REQUEST_FAILED';
export const CASH_BANKS_MOVEMENTS_DELETE_REQUEST = 'CASH_BANKS_MOVEMENTS_DELETE_REQUEST';
export const CASH_BANKS_MOVEMENTS_DELETE_REQUEST_SUCCESS = 'CASH_BANKS_MOVEMENTS_DELETE_REQUEST_SUCCESS';
export const CASH_BANKS_MOVEMENTS_DELETE_REQUEST_FAILED = 'CASH_BANKS_MOVEMENTS_DELETE_REQUEST_FAILED';
export const CASH_BANKS_MOVEMENTS_MASSIVE_UPDATE_REQUEST = 'CASH_BANKS_MOVEMENTS_MASSIVE_UPDATE_REQUEST';
export const CASH_BANKS_MOVEMENTS_MASSIVE_UPDATE_REQUEST_SUCCESS = 'CASH_BANKS_MOVEMENTS_MASSIVE_UPDATE_REQUEST_SUCCESS';
export const CASH_BANKS_MOVEMENTS_MASSIVE_UPDATE_REQUEST_FAILED = 'CASH_BANKS_MOVEMENTS_MASSIVE_UPDATE_REQUEST_FAILED';
/*Components */
export const CASH_BANKS_MOVEMENTS_LIST_SELECT_ITEM = 'CASH_BANKS_MOVEMENTS_LIST_SELECT_ITEM';
export const CASH_BANKS_MOVEMENTS_LIST_SELECT_ALL_ITEM = 'CASH_BANKS_MOVEMENTS_LIST_SELECT_ALL_ITEM';
export const CASH_BANKS_MOVEMENTS_ACTION_FAILED = 'CASH_BANKS_MOVEMENTS_ACTION_FAILED';
//#endregion

//#region VOUCHERS

export const CHECKS_INIT_FORM_REQUEST = 'CHECKS_INIT_FORM_REQUEST';
export const CHECKS_INIT_FORM_REQUEST_SUCCESS = 'CHECKS_INIT_FORM_REQUEST_SUCCESS';
export const CHECKS_INIT_FORM_REQUEST_FAILED = 'CHECKS_INIT_FORM_REQUEST_FAILED';
export const CHECKS_INIT_UPDATE_FORM_REQUEST = 'CHECKS_INIT_UPDATE_FORM_REQUEST';
export const CHECKS_INIT_UPDATE_FORM_REQUEST_SUCCESS = 'CHECKS_INIT_UPDATE_FORM_REQUEST_SUCCESS';
export const CHECKS_INIT_UPDATE_FORM_REQUEST_FAILED = 'CHECKS_INIT_UPDATE_FORM_REQUEST_FAILED';
export const CHECKS_GET_ALL_RECORDS_REQUEST = 'CHECKS_GET_ALL_RECORDS_REQUEST';
export const CHECKS_GET_ALL_RECORDS_REQUEST_SUCCESS = 'CHECKS_GET_ALL_RECORDS_REQUEST_SUCCESS';
export const CHECKS_GET_ALL_RECORDS_REQUEST_FAILED = 'CHECKS_GET_ALL_RECORDS_REQUEST_FAILED';
export const CHECKS_INSERT_REQUEST = 'CHECKS_INSERT_REQUEST';
export const CHECKS_INSERT_REQUEST_SUCCESS = 'CHECKS_INSERT_REQUEST_SUCCESS';
export const CHECKS_INSERT_REQUEST_FAILED = 'CHECKS_INSERT_REQUEST_FAILED';
export const CHECKS_UPDATE_REQUEST = 'CHECKS_UPDATE_REQUEST';
export const CHECKS_UPDATE_REQUEST_SUCCESS = 'CHECKS_UPDATE_REQUEST_SUCCESS';
export const CHECKS_UPDATE_REQUEST_FAILED = 'CHECKS_UPDATE_REQUEST_FAILED';
export const CHECKS_DELETE_REQUEST = 'CHECKS_DELETE_REQUEST';
export const CHECKS_DELETE_REQUEST_SUCCESS = 'CHECKS_DELETE_REQUEST_SUCCESS';
export const CHECKS_DELETE_REQUEST_FAILED = 'CHECKS_DELETE_REQUEST_FAILED';

/*Components */
export const CHECKS_LIST_SELECT_ITEM = 'CHECKS_LIST_SELECT_ITEM';
export const CHECKS_LIST_SELECT_ALL_ITEM = 'CHECKS_LIST_SELECT_ALL_ITEM';
export const CHECKS_ACTION_FAILED = 'CHECKS_ACTION_FAILED';

//#endregion

//#region 

export const CHECKS_ISSUED_SUMMARY_REQUEST = 'CHECKS_ISSUED_SUMMARY_REQUEST';
export const CHECKS_ISSUED_SUMMARY_REQUEST_SUCCESS = 'CHECKS_ISSUED_SUMMARY_REQUEST_SUCCESS';
export const CHECKS_ISSUED_SUMMARY_REQUEST_FAILED = 'CHECKS_ISSUED_SUMMARY_REQUEST_FAILED';

export const CHECKS_BALANCE_REQUEST = 'CHECKS_BALANCE_REQUEST';
export const CHECKS_BALANCE_REQUEST_SUCCESS = 'CHECKS_BALANCE_REQUEST_SUCCESS';
export const CHECKS_BALANCE_REQUEST_FAILED = 'CHECKS_BALANCE_REQUEST_FAILED';

//#endregion