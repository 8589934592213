import { baseModels } from 'penta-models';
import { USERS_DELETE, USERS_GET_ALL_RECORDS, USERS_INIT_FORM, USERS_INIT_UPDATE_FORM, USERS_REGISTER, USERS_UPDATE } from 'services/constants/usersConstants';
import request from './basePipeline';

export const usersInitForm = () => {
    return request.getWithToken(USERS_INIT_FORM);
}

export const usersInitUpdateForm = (usersname: string) => {
    return request.getWithToken(USERS_INIT_UPDATE_FORM + usersname);
}

export const usersGetAllRecords = (body: baseModels.pentaRequestQuery) => {
    return request.postWithToken(USERS_GET_ALL_RECORDS, body);
}

export const usersRegister = (body: any) => {
    return request.postWithToken(USERS_REGISTER, body);
}

export const usersUpdate = (usersname: String, body: any) => {
    return request.putWithToken(USERS_UPDATE + usersname, body);
}

export const usersDelete = (usernames: Array<string>) => {
    const urlSearchParams = new URLSearchParams();
    for (const username of usernames) {
        urlSearchParams.append("username", username);
    }
    return request.deleteWithToken(USERS_DELETE + urlSearchParams.toString());
}