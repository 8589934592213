import React from 'react';
import { AppBar, Backdrop, Box, Button, ButtonGroup, Checkbox, CircularProgress, createStyles, FormControl, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Select, Tab, Tabs, TextField, Theme, Tooltip, Typography, FormControlLabel, withStyles, TextareaAutosize, Snackbar } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { conceptsInitFormRequest, conceptsInitUpdateFormRequest, conceptsInsertRequest, conceptsUpdateRequest } from 'penta-redux/actions/humanResourcesActions';
import history from '../../../utils/history';
import './concepts.scss'
import FormFooter from 'penta-components/base/formFooter';
import { MessageBox } from 'penta-components/base/messageBox';
import { conceptsEntity } from 'models/humanResourcesModels';
import moment from 'moment';
import { Alert, Autocomplete } from '@material-ui/lab';
import ScreenLoader from 'penta-components/base/screenLoader';

const useStyles = (theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        formControl: {
            margin: 0,
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    });

const defaultConceptsEntity: conceptsEntity = {
    conccode: '',
    officode: '',
    typesala: '001',
    payrtype: '101',
    descript: '',
    accaccou: null,
    fiscyear: null,
    scriptss: '',
    receiver: '',
    outcomee: '',
    conctype: '050',
    operator: '+'
}

interface FormConceptsState {
    conceptsEntity: conceptsEntity
    title: string
    snackbar: boolean
}

class FormConcepts extends React.Component<any, FormConceptsState> {

    constructor(props: any) {
        super(props);
        this.state = {
            conceptsEntity: defaultConceptsEntity,
            title: 'Agregar Conceptos',
            snackbar: false
        }
    }

    onChangeRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            ...value
        }))
    }

    onChangeConceptsRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            conceptsEntity: {
                ...state.conceptsEntity,
                ...value
            }
        }))
    }

    onCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        console.log('cancel')
        history.push('/papp/hhrr/conceptos');
    }

    onContinueCallback = () => {
        const newConceptsEntity = defaultConceptsEntity;
        this.setState({
            conceptsEntity: newConceptsEntity,
            snackbar: true
        });
    }

    onSubmitContinue = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const { params } = this.props.match;
        if (params.conccode !== undefined) {
            this.props.conceptsUpdateRequest(this.state.conceptsEntity, null);
        } else {
            this.props.conceptsInsertRequest(this.state.conceptsEntity, this.onContinueCallback);
        }
    }

    onSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const { params } = this.props.match;
        if (params.conccode !== undefined) {
            this.props.conceptsUpdateRequest(this.state.conceptsEntity, null);
        } else {
            this.props.conceptsInsertRequest(this.state.conceptsEntity, null);
        }
    }

    callbackInitUpdate = (conceptsEntity: conceptsEntity) => {
        this.setState({
            conceptsEntity
        });
    }

    componentDidMount() {
        const { params } = this.props.match;
        if (params.conccode !== undefined) {
            this.setState({title: 'Editar Conceptos'});
            this.props.conceptsInitUpdateFormRequest(params.conccode, params.typesala, params.payrtype, this.callbackInitUpdate);
        } else {
            this.props.conceptsInitFormRequest(this.props.dashboardReducers.currentFiscyear);
        }
    }

    render() {

        const { conceptsEntity, title, snackbar } = this.state;
        const { loading, list, tables, failed, failedTitle, failedContent } = this.props.humanResourcesReducers;
        const { classes } = this.props;

        return (
            <div style={{overflow: 'auto', height: '100%'}}>
                <Paper className='form__container'>
                    <br/>
                    <Typography variant='h5'>{ title }</Typography>
                    <br/>
                    { failed && <MessageBox title={failedTitle} content={failedContent}/> }
                    <form noValidate autoComplete="off">
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <TextField fullWidth id="conccode" label="Codigo" variant="outlined" value={ conceptsEntity.conccode } onChange={(e: any) => this.onChangeConceptsRoot({'conccode': e.target.value})}/>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth id="descript" label="Descripcion" variant="outlined" value={ conceptsEntity.descript } onChange={(e: any) => this.onChangeConceptsRoot({'descript': e.target.value})}/>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                    <InputLabel id="lbl_planilla">Planilla</InputLabel>
                                    <Select
                                        labelId="lbl_planilla"
                                        id="payrtype"
                                        className='select'
                                        label="Planilla"
                                        fullWidth
                                        value={conceptsEntity.payrtype || ''}
                                        onChange={(e: any) => this.onChangeConceptsRoot({'payrtype': e.target.value})}
                                    >
                                        {
                                            tables.prl_definiti.filter((x: any) => x.defitype === 'PRT' ).map((item: any, key: any) => 
                                                <MenuItem value={item.deficode} key={`planilla_${key}`}>{item.definame}</MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                    <InputLabel id="lbl_tipo">Tipo</InputLabel>
                                    <Select
                                        labelId="lbl_tipo"
                                        id="typesala"
                                        className='select'
                                        label="Tipo"
                                        fullWidth
                                        value={conceptsEntity.typesala || ''}
                                        onChange={(e: any) => this.onChangeConceptsRoot({'typesala': e.target.value})}
                                    >
                                        {
                                            tables.prl_definiti.filter((x: any) => x.defitype === 'STY' ).map((item: any, key: any) => 
                                                <MenuItem value={item.deficode} key={`tipo_${key}`}>{item.definame}</MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <Autocomplete
                                    id="accaccou"
                                    freeSolo
                                    options={tables.acc_planacco}
                                    getOptionLabel={ (x: any) => x.accaccou }
                                    value={ tables.acc_planacco.find((x: any) => x.accaccou === conceptsEntity.accaccou) || null }
                                    onChange={(e: any, value) => this.onChangeConceptsRoot({'accaccou': value.accaccou, 'fiscyear': value.fiscyear})}
                                    renderInput={(params) => (
                                        <TextField {...params} 
                                        style={ { marginTop: 0 } } label="Cuenta Contable" margin="normal" variant="outlined" />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth id="receiver" label="Receptor" variant="outlined" value={ conceptsEntity.receiver } onChange={(e: any) => this.onChangeConceptsRoot({'receiver': e.target.value})}/>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth id="outcomee" label="Resultado" variant="outlined" value={ conceptsEntity.outcomee } onChange={(e: any) => this.onChangeConceptsRoot({'outcomee': e.target.value})}/>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                    <InputLabel id="lbl_operacion">Operacion</InputLabel>
                                    <Select
                                        labelId="lbl_operacion"
                                        id="operator"
                                        className='select'
                                        label="Operacion"
                                        fullWidth
                                        value={conceptsEntity.operator || ''}
                                        onChange={(e: any) => this.onChangeConceptsRoot({'operator': e.target.value})}
                                    >
                                        <MenuItem value={'+'}>Debe</MenuItem>
                                        <MenuItem value={'-'}>Haber</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                    <InputLabel id="lbl_tipovariable">Tipo de variable</InputLabel>
                                    <Select
                                        labelId="lbl_tipo"
                                        id="conctype"
                                        className='select'
                                        label="Tipo de variable"
                                        fullWidth
                                        value={conceptsEntity.conctype || ''}
                                        onChange={(e: any) => this.onChangeConceptsRoot({'conctype': e.target.value})}
                                    >
                                        {
                                            tables.prl_definiti.filter((x: any) => x.defitype === 'CTY' ).map((item: any, key: any) => 
                                                <MenuItem value={item.deficode} key={`tipovariable_${key}`}>{item.definame}</MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <br/>
                        <Typography variant='subtitle2'>Formula</Typography>
                        <br/>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextareaAutosize 
                                    value={ conceptsEntity.scriptss } 
                                    onChange={(e: any) => this.onChangeConceptsRoot({'scriptss': e.target.value})}
                                    aria-label="Formula"
                                    rowsMin={5}
                                    style={ { width: '100%' } }
                                    placeholder="Ingresar formula"
                                />
                            </Grid>
                        </Grid>
                    </form>
                    <br/>
                    <FormFooter 
                        onCancel={ this.onCancel } 
                        onSubmitContinue={ this.onSubmitContinue } 
                        onSubmit={ this.onSubmit }/>
                </Paper>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={snackbar}
                    onClose={() => this.setState({snackbar: false})}
                    autoHideDuration={6000}
                >
                    <Alert onClose={() => this.setState({snackbar: false})} severity="success">
                            Datos Guardados Correctamente
                    </Alert>
                </Snackbar>
                <ScreenLoader enabled={loading}/>
            </div>
        );
    }
}

const humanResourcesReducers = 'humanResourcesReducers';
const dashboardReducers = 'dashboardReducers';

const mapStateToProps = (state: any) => ({
    humanResourcesReducers: state.get(humanResourcesReducers),
    dashboardReducers: state.get(dashboardReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        conceptsInitFormRequest,
        conceptsInitUpdateFormRequest,
        conceptsInsertRequest,
        conceptsUpdateRequest
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(FormConcepts));