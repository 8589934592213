import React, { CSSProperties } from 'react';
import {createUseStyles} from 'react-jss';
import SearchIcon from 'penta-assets/images/search.svg';

const useStyles =  createUseStyles({
    container: {
        'position': 'relative'
    },
    input: (props: any) => ({
        'border': '0.1rem solid #dfdfdf',
        'color': '#3B3B3B',
        'fontSize': '0.75rem',
        'fontFamily': '"Work Sans", sans-serif',
        'height': '2.188rem',
        'width': '100%',
        'padding': '0.6rem 2.813rem',
        '&:hover': {
            'borderColor': '#3B3B3B'
        },
        '&:focus': {
            'borderColor': '#0060A6'
        },
        // 'width': '22.375rem',
    }),
    iconContainer: {
        'position': 'absolute',
        'top': '0.5rem',
        'left': '0.85rem'
    },
    icon: {
        'width': 'auto',
        'height': '1.25rem'
    }
});

interface Search {
    styleInput?: CSSProperties
    styleContainer?: CSSProperties
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void
    value?: string | number | readonly string[]
}

const Search = (props: Search) => {
    const classes = useStyles();
    return (
        <div className={classes.container} style={props.styleContainer}>
            <input className={classes.input} style={props.styleInput} placeholder="buscar" onChange={props.onChange} onKeyUp={props.onKeyUp} value={props.value}/>
            <div className={classes.iconContainer}>
                <img src={SearchIcon} className={classes.icon} />
            </div>
        </div>
    )
}

Search.defaultProps = {
    styleInput: {}
}

export default Search;