import { Button } from '@material-ui/core';
import React from 'react';
import './base.scss';

interface IFormFooter {
    activeStep: number
    lastStep: number
    onBack: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
    onNext: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
    onCancel: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
    onSubmitContinue: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
    onSubmit: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const FormFooterSteps = (props: IFormFooter) => {

    const { activeStep, lastStep, onBack, onNext, onCancel, onSubmitContinue, onSubmit } = props;

    return (
        <div className="form__footer__steps">
            <div className="left">
                <Button variant="contained" color="default" onClick={ onBack }>
                    Atras
                </Button>
                &nbsp;&nbsp;
                <Button variant="contained" color="default" onClick={ onNext }>
                    Siguiente
                </Button>
            </div>
            {
                activeStep === lastStep &&
                <div className="rigth">
                    <Button variant="contained" color="secondary" onClick={ onCancel }>
                        Cancelar
                    </Button>
                    &nbsp;&nbsp;
                    <Button variant="outlined" color="primary" onClick={ onSubmitContinue }>
                        Guardar y Continuar
                    </Button>
                    &nbsp;&nbsp;
                    <Button variant="contained" color="primary" onClick={ onSubmit }>
                        Guardar
                    </Button>
                </div>
            }
        </div>
    )
};

export default FormFooterSteps;