import React from 'react';
import {createUseStyles} from 'react-jss';
import DownArrowIcon from 'penta-assets/images/arrow-down-sign-to-navigate.svg';
import { CSSProperties } from 'react';
import { useEffect } from 'react';

const error = {
    'border': '0.1rem solid #dc1010',
    'hover': { 
        'borderColor': '#dc1010'
    },
    'focus': {
        'borderColor': '#dc1010'
    }
}

const normal = {
    'border': '0.1rem solid #dfdfdf',
    'hover': {
        'borderColor': '#3B3B3B'
    },
    'focus': {
        'borderColor': '#0060A6'
    }
}

const useStyles =  createUseStyles({
    top: {},
    left: {},
    container: {
        'display': 'flex',
        '&$top': {
            'flexDirection': 'column'
        },
        '&$left': {
            'flexDirection': 'row',
            'alignItems': 'center'
        }
    },
    label: {
        'color': '#3B3B3B',
        'fontSize': '0.75rem',
        'fontFamily': '"Work Sans", sans-serif',
        'fontWeight': 600,
    },
    selectHeader: (props: any) => ({
        'fontFamily': '"Work Sans", sans-serif',
        'display': 'flex',
        'flex': 1,
        'alignItems': 'center',
        'backgroundColor': 'white',
        'fontSize': '0.75rem',
        'height': '2.188rem',
        'cursor': 'pointer',
        'color': '#3B3B3B',
        'margin': props.margin,
        'border': props.border,
        '&:hover': props.hover,
        '&:focus': props.focus,
        '& span': {
            'flex': 1,
            'margin': '0rem 0.938rem'
        },
        '&$top': {
            'marginTop': '1rem'
        },
        '&$left': {
            // 'marginLeft': '1rem'
        }
    }),
    selectList: {
        'zIndex': 10,
        'position': 'absolute',
        'width': '100%',
        'backgroundColor': 'white',
        'fontSize': '0.75rem',
        'border': '0.063rem solid rgb(223, 223, 223)',
        'borderTop': 'none',
        'color': '#3B3B3B',
        'fontFamily': '"Work Sans", sans-serif',
        'boxShadow': '0rem 0.188rem 0.375rem #00000029',
        height: '20rem',
        overflow: 'auto',
        '&$left': {
            // 'marginLeft': '1rem'
        },
        '& ul': {
            '& li': {
                listStyleType: 'none',
                cursor: 'pointer',
                padding: '1rem',
                maxHeight: '15rem',
                overflowY: 'auto',
                '&:hover': {
                    backgroundColor: '#e8e8e8'
                }
            }
        }
    },
    arrowContainer: {
        'display': 'flex',
        'alignItems': 'center',
        'margin': '0rem 0.938rem'
    },
    downArrowIcon: {
        'width': '1.25rem',
        'height': '0.713rem'
    },
    labelError: {
        'paddingTop': '0.5rem',
        'paddingBottom': '2rem',
        'fontSize': '0.65rem',
        'color': '#dc1010',
        'fontFamily': '"Work Sans", sans-serif',
    }
});

interface Select {
    onChange?: (value: any) => void
    // value: string | number | readonly string[]
    // type?: 'text' | 'password'
    labelPosition?: 'top' | 'left'
    labelLeftWidth?: string
    style?: CSSProperties
    label: string
    error?: boolean
    data: Array<{id: string, name: string}>
    value: any
}

const Select = (props: Select) => {

    let propsStyle = {...(props.error ? error : normal)};
    const classes = useStyles(propsStyle);
    const defaultValue = {id: null, name: ''};
    const [showList, setShowList] = React.useState(false);
    const [selectData, setSelectData] = React.useState(defaultValue);

    const onSelect = (e) => {
        const value = {
            id: e.target.getAttribute("data-id"),
            name: e.target.getAttribute("data-name"),
        };
        setSelectData(value);
        setShowList(false);
        if(value.id !== props.value) props.onChange(value);
    }

    useEffect(() => {
        const value = props.data.find(x => x.id === props.value) || defaultValue;
        setSelectData(value);
    }, [props.value])

    const renderControl = () => (
        <div style={{flex: 1, position: 'relative'}} tabIndex={0} onBlur={() => setShowList(false)}>
            <div className={`${classes.selectHeader} ${classes[props.labelPosition]}`} onClick={() => setShowList(!showList)} >
                <span>{ selectData.id ? selectData.name : 'Seleccionar . . .' }</span>
                <div className={classes.arrowContainer}>
                    <img src={DownArrowIcon} className={classes.downArrowIcon} />
                </div>
            </div>
            {
                showList &&
                <div className={`${classes.selectList} ${classes[props.labelPosition]}`}>
                    <ul>
                        {
                            props.data.map((item) => 
                                <li key={item.id} data-id={item.id} data-name={item.name} onClick={onSelect}>{item.name}</li>
                            )
                        }
                    </ul>
                </div>
            }
        </div>
    )

    return (
        <div style={props.style} className={`${classes.container} ${classes[props.labelPosition]}`}>
            {
                props.labelPosition === 'top' &&
                <React.Fragment>
                    <label className={classes.label}>{props.label}</label>
                    {renderControl()}
                </React.Fragment>
            }
            {
                props.labelPosition === 'left' &&
                <div style={{display: 'grid', gridTemplateColumns: `${props.labelLeftWidth} calc(100% - ${props.labelLeftWidth})`, alignItems: 'center', width: '100%'}}>
                    <label className={classes.label}>{props.label}</label>
                    {renderControl()}
                </div>
            }
            {
                props.error && <span className={classes.labelError}>{props.label} no puede estar vacio</span>
            }
        </div>
    )
}

Select.defaultProps = {
    labelPosition: 'left',
    labelLeftWidth: '5rem',
    type: 'text',
    error: false,
    style: {},
    data: [],
    value: null
}

export default Select;