import { CASH_BOOK_BANKS_REQUEST, DAOT_REQUEST, DIARY_BOOK_REQUEST, EXPENSE_ANALYSIS_NINE_REQUEST, EXPENSE_ANALYSIS_SIX_REQUEST, GENERAL_BALANCES_ACCOUNT_REQUEST, GENERAL_BALANCES_REQUEST, INVENTORY_BOOK_BALANCE_REQUEST, MAJORIZATION_REQUEST, MAJOR_GENERAL_REQUEST, MASTER_ACCOUNT_GET_ALL_RECORDS_REQUEST, MASTER_ACCOUNT_UPDATE_REQUEST, PLAN_ACCOUNT_DELETE_REQUEST, PLAN_ACCOUNT_GET_ALL_RECORDS_REQUEST, PLAN_ACCOUNT_INIT_FORM_REQUEST, PLAN_ACCOUNT_INIT_UPDATE_FORM_REQUEST, PLAN_ACCOUNT_INSERT_REQUEST, PLAN_ACCOUNT_UPDATE_REQUEST, PROFIT_LOSS_STATEMENT_REQUEST, PURCHASES_REGISTER_REQUEST, PURCHASE_DETAIL_SUPPLIER_REQUEST, SALES_REGISTER_REQUEST, VOUCHERS_DELETE_REQUEST, VOUCHERS_GET_ALL_RECORDS_REQUEST, VOUCHERS_INIT_FORM_REQUEST, VOUCHERS_INIT_UPDATE_FORM_REQUEST, VOUCHERS_INSERT_REQUEST, VOUCHERS_UPDATE_REQUEST, WORKSHEET_REQUEST } from "penta-redux/constants/accountantConstants";

import {
    call, fork, put, take, takeEvery, all
} from 'redux-saga/effects';
import history from '../../utils/history';
import pipelines from 'services';
import { cashBookBanksRequestFailed, cashBookBanksRequestSuccess, daotRequestFailed, daotRequestSuccess, diaryBookRequestFailed, diaryBookRequestSuccess, expenseAnalysisNineRequestFailed, expenseAnalysisNineRequestSuccess, expenseAnalysisSixRequestFailed, expenseAnalysisSixRequestSuccess, generalBalancesAccountRequestFailed, generalBalancesAccountRequestSuccess, generalBalancesRequestFailed, generalBalancesRequestSuccess, inventoryBookBalanceRequestFailed, inventoryBookBalanceRequestSuccess, majorGeneralRequestFailed, majorGeneralRequestSuccess, majorizationRequestFailed, majorizationRequestSuccess, masterAccountGetAllRecordsRequestFailed, masterAccountGetAllRecordsRequestSuccess, masterAccountUpdateRequestFailed, masterAccountUpdateRequestSuccess, planAccountDeleteRequestFailed, planAccountGetAllRecordsRequestSuccess, planAccountInitFormRequestFailed, planAccountInitFormRequestSuccess, planAccountInitUpdateFormRequestFailed, planAccountInitUpdateFormRequestSuccess, planAccountInsertRequestFailed, planAccountInsertRequestSuccess, planAccountUpdateRequestFailed, profitLossStatementRequestFailed, profitLossStatementRequestSuccess, purchaseDetailSupplierRequestFailed, purchaseDetailSupplierRequestSuccess, purchasesRegisterRequestFailed, purchasesRegisterRequestSuccess, salesRegisterRequestFailed, salesRegisterRequestSuccess, vouchersDeleteRequestFailed, vouchersGetAllRecordsRequestFailed, vouchersGetAllRecordsRequestSuccess, vouchersInitFormRequestFailed, vouchersInitFormRequestSuccess, vouchersInitUpdateFormRequestFailed, vouchersInitUpdateFormRequestSuccess, vouchersInsertRequestFailed, vouchersInsertRequestSuccess, vouchersUpdateRequestFailed, worksheetRequestFailed, worksheetRequestSuccess } from "penta-redux/actions/accountantActions";
import { masterAccountEntity, planAccountEntity } from "models/accountantModels";

function* masterAccountGetAllRecordsSaga(action: any) {
    try {
        const data = (yield call(pipelines.accountantPipeline.masterAccountGetAllRecords, action.body)).data;
        yield put(masterAccountGetAllRecordsRequestSuccess(data.data.list));
        yield call(action.callback, data.data.list[0]);
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(masterAccountGetAllRecordsRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* masterAccountUpdateSaga(action: any) {
    try {
        const data = (yield call(pipelines.accountantPipeline.masterAccountUpdate, (action.body as masterAccountEntity).fiscyear, action.body)).data;
        yield put(masterAccountUpdateRequestSuccess());
        yield history.push('/papp/accounting/maestro-contable');
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(masterAccountUpdateRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* planAccountInitFormSaga(action: any) {
    try {
        const data = (yield call(pipelines.accountantPipeline.planAccountInitForm, action.fiscyear)).data;
        yield put(planAccountInitFormRequestSuccess(data.data.tables));
        yield call(action.callback);
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(planAccountInitFormRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* planAccountInitUpdateFormSaga(action: any) {
    try {
        const data = (yield call(pipelines.accountantPipeline.planAccountInitUpdateForm, action.accaccou, action.fiscyear)).data;
        yield put(planAccountInitUpdateFormRequestSuccess(data.data.tables, data.data.onlyOne));
        yield call(action.callback, data.data.onlyOne);
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(planAccountInitUpdateFormRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* planAccountGetAllRecordsSaga(action: any) {
    try {
        const data = (yield call(pipelines.accountantPipeline.planAccountGetAllRecords, action.body)).data;
        yield put(planAccountGetAllRecordsRequestSuccess(data.data.list, data.data.total));
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(planAccountInitFormRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* planAccountInsertSaga(action: any) {
    try {
        yield call(pipelines.accountantPipeline.planAccountInsert, action.body);
        if (action.callback !== null) {
            yield call(action.callback);
            yield put(planAccountInsertRequestSuccess());
        }
        else
            yield history.push('/papp/accounting/plan-cuentas');
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(planAccountInsertRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* planAccountUpdateSaga(action: any) {
    try {
        yield call(pipelines.accountantPipeline.planAccountUpdate, (action.body as planAccountEntity).accaccou,  (action.body as planAccountEntity).fiscyear, action.body);
        yield history.push('/papp/accounting/plan-cuentas');
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(planAccountUpdateRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* planAccountDeleteSaga(action: any) {
    try {
        const data = (yield call(pipelines.accountantPipeline.planAccountDelete, action.accaccous, action.fiscyears)).data;
        yield put({ type: PLAN_ACCOUNT_GET_ALL_RECORDS_REQUEST, body: {} });
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(planAccountDeleteRequestFailed(`Error: ${data.code}`, data.message));
    }
}

//#region VOUCHERS

function* vouchersInitFormSaga(action: any) {
    try {
        const data = (yield call(pipelines.accountantPipeline.vouchersInitForm, action.fiscyear)).data;
        yield put(vouchersInitFormRequestSuccess(data.data.tables));
        yield call(action.callback);
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(vouchersInitFormRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* vouchersInitUpdateFormSaga(action: any) {
    try {
        const data = (yield call(pipelines.accountantPipeline.vouchersInitUpdateForm, action.correlat)).data;
        yield put(vouchersInitUpdateFormRequestSuccess(data.data.tables, data.data.onlyOne));
        yield call(action.callback, data.data.onlyOne);
    } catch (error) {
        console.log(error);
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(vouchersInitUpdateFormRequestFailed(`Error: ${err.code}`, err.message));
    }
}

function* vouchersGetAllRecordsSaga(action: any) {
    try {
        const data = (yield call(pipelines.accountantPipeline.vouchersGetAllRecords, action.body)).data;
        yield put(vouchersGetAllRecordsRequestSuccess(data.data.list, data.data.total));
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(vouchersGetAllRecordsRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* vouchersInsertSaga(action: any) {
    try {
        yield call(pipelines.accountantPipeline.vouchersInsert, action.body);
        if (action.callback !== null) {
            yield call(action.callback);
            yield put(vouchersInsertRequestSuccess());
        }
        else
            yield history.push('/papp/accounting/comprobantes');
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(vouchersInsertRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* vouchersUpdateSaga(action: any) {
    try {
        const data = (yield call(pipelines.accountantPipeline.vouchersUpdate, action.body.correlat, action.body)).data;
        yield history.push('/papp/accounting/comprobantes');
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(vouchersUpdateRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* vouchersDeleteSaga(action: any) {
    try {
        const data = (yield call(pipelines.accountantPipeline.vouchersDelete, action.correlats)).data;
        yield put({ type: VOUCHERS_GET_ALL_RECORDS_REQUEST, body: {} });
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(vouchersDeleteRequestFailed(`Error: ${data.code}`, data.message));
    }
}

//#endregion

//#region REPORTS

function* generalBalancesAccountSaga(action: any) {
    try {
        yield call(pipelines.accountantPipeline.generateEmptyBalances, action.year, action.month)
        const data = (yield call(pipelines.accountantPipeline.generalBalancesAccount, action.year, action.month)).data;
        yield put(generalBalancesAccountRequestSuccess(data.data.onlyOne));
        yield call(action.callback);
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(generalBalancesAccountRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* diaryBookSaga(action: any) {
    try {
        const data = (yield call(pipelines.accountantPipeline.diaryBook, action.year, action.month)).data;
        yield put(diaryBookRequestSuccess(data.data.onlyOne));
        yield call(action.callback);
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(diaryBookRequestFailed(`Error: ${err.code}`, err.message));
    }
}

function* purchasesRegisterSaga(action: any) {
    try {
        const data = (yield call(pipelines.accountantPipeline.purchasesRegister, action.officode, action.year, action.month, action.all)).data;
        yield put(purchasesRegisterRequestSuccess(data.data.onlyOne));
        yield call(action.callback);
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(purchasesRegisterRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* worksheetSaga(action: any) {
    try {
        const data = (yield call(pipelines.accountantPipeline.worksheet, action.fiscyear, action.balacode, action.level)).data;
        yield put(worksheetRequestSuccess(data.data.onlyOne));
        yield call(action.callback);
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(worksheetRequestFailed(`Error: ${err.code}`, err.message));
    }
}

function* majorGeneralSaga(action: any) {
    try {
        const data = (yield call(pipelines.accountantPipeline.majorGeneral, action.fiscyear, action.balacode)).data;
        yield put(majorGeneralRequestSuccess(data.data.onlyOne));
        yield call(action.callback);
    } catch (error) {
        console.log(error);
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(majorGeneralRequestFailed(`Error: ${err.code}`, err.message));
    }
}

function* cashBookBanksSaga(action: any) {
    try {
        const data = (yield call(pipelines.accountantPipeline.cashBookBanks, action.year, action.month, action.typecsbk)).data;
        yield put(cashBookBanksRequestSuccess(data.data.onlyOne));
        yield call(action.callback);
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(cashBookBanksRequestFailed(`Error: ${err.code}`, err.message));
    }
}

function* expenseAnalysisNineSaga(action: any) {
    try {
        const data = (yield call(pipelines.accountantPipeline.expenseAnalysisNine, action.year, action.balacode)).data;
        yield put(expenseAnalysisNineRequestSuccess(data.data.onlyOne));
        yield call(action.callback);
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(expenseAnalysisNineRequestFailed(`Error: ${err.code}`, err.message));
    }
}

function* expenseAnalysisSixSaga(action: any) {
    try {
        const data = (yield call(pipelines.accountantPipeline.expenseAnalysisSix, action.year, action.balacode)).data;
        yield put(expenseAnalysisSixRequestSuccess(data.data.onlyOne));
        yield call(action.callback);
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(expenseAnalysisSixRequestFailed(`Error: ${err.code}`, err.message));
    }
}

function* inventoryBookBalanceSaga(action: any) {
    try {
        const data = (yield call(pipelines.accountantPipeline.inventoryBookBalance, action.year, action.month)).data;
        yield put(inventoryBookBalanceRequestSuccess(data.data.onlyOne));
        yield call(action.callback);
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(inventoryBookBalanceRequestFailed(`Error: ${err.code}`, err.message));
    }
}

function* generalBalancesSaga(action: any) {
    try {
        const data = (yield call(pipelines.accountantPipeline.generalBalances, action.fiscyear, action.balacode)).data;
        yield put(generalBalancesRequestSuccess(data.data.onlyOne));
        yield call(action.callback);
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(generalBalancesRequestFailed(`Error: ${err.code}`, err.message));
    }
}

function* profitLossStatementSaga(action: any) {
    try {
        const data = (yield call(pipelines.accountantPipeline.profitLossStatement, action.fiscyear, action.balacode)).data;
        yield put(profitLossStatementRequestSuccess(data.data.onlyOne));
        yield call(action.callback);
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(profitLossStatementRequestFailed(`Error: ${err.code}`, err.message));
    }
}

function* salesRegisterSaga(action: any) {
    try {
        const data = (yield call(pipelines.accountantPipeline.salesRegister, action.officode, action.year, action.month, action.all)).data;
        yield put(salesRegisterRequestSuccess(data.data.onlyOne));
        yield call(action.callback);
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(salesRegisterRequestFailed(`Error: ${err.code}`, err.message));
    }
}

function* daotSaga(action: any) {
    try {
        const data = (yield call(pipelines.accountantPipeline.daot, action.officode, action.year, action.initmonth, action.endmonth)).data;
        yield put(daotRequestSuccess(data.data.onlyOne));
        yield call(action.callback);
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(daotRequestFailed(`Error: ${err.code}`, err.message));
    }
}

function* purchaseDetailSupplierSaga(action: any) {
    try {
        const data = (yield call(pipelines.accountantPipeline.purchaseDetailSupplier, action.officode, action.year, action.initmonth, action.endmonth, action.ruc, action.product)).data;
        yield put(purchaseDetailSupplierRequestSuccess(data.data.onlyOne));
        yield call(action.callback);
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(purchaseDetailSupplierRequestFailed(`Error: ${err.code}`, err.message));
    }
}

//#endregion

function* majorizationSaga(action: any) {
    try {
        const data = (yield call(pipelines.accountantPipeline.majorization, action.year, action.month)).data;
        yield put(majorizationRequestSuccess({}));
        yield call(action.callback);
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(majorizationRequestFailed(`Error: ${err.code}`, err.message));
    }
}

function* accountantRootSaga() {
    yield all([
        takeEvery(MASTER_ACCOUNT_GET_ALL_RECORDS_REQUEST, masterAccountGetAllRecordsSaga),
        takeEvery(MASTER_ACCOUNT_UPDATE_REQUEST, masterAccountUpdateSaga),
        takeEvery(PLAN_ACCOUNT_INIT_FORM_REQUEST, planAccountInitFormSaga),
        takeEvery(PLAN_ACCOUNT_INIT_UPDATE_FORM_REQUEST, planAccountInitUpdateFormSaga),
        takeEvery(PLAN_ACCOUNT_GET_ALL_RECORDS_REQUEST, planAccountGetAllRecordsSaga),
        takeEvery(PLAN_ACCOUNT_INSERT_REQUEST, planAccountInsertSaga),
        takeEvery(PLAN_ACCOUNT_UPDATE_REQUEST, planAccountUpdateSaga),
        takeEvery(PLAN_ACCOUNT_DELETE_REQUEST, planAccountDeleteSaga),
        takeEvery(VOUCHERS_INIT_FORM_REQUEST, vouchersInitFormSaga),
        takeEvery(VOUCHERS_INIT_UPDATE_FORM_REQUEST, vouchersInitUpdateFormSaga),
        takeEvery(VOUCHERS_GET_ALL_RECORDS_REQUEST, vouchersGetAllRecordsSaga),
        takeEvery(VOUCHERS_INSERT_REQUEST, vouchersInsertSaga),
        takeEvery(VOUCHERS_UPDATE_REQUEST, vouchersUpdateSaga),
        takeEvery(VOUCHERS_DELETE_REQUEST, vouchersDeleteSaga),
        takeEvery(GENERAL_BALANCES_ACCOUNT_REQUEST, generalBalancesAccountSaga),
        takeEvery(DIARY_BOOK_REQUEST, diaryBookSaga),
        takeEvery(PURCHASES_REGISTER_REQUEST, purchasesRegisterSaga),
        takeEvery(WORKSHEET_REQUEST, worksheetSaga),
        takeEvery(MAJOR_GENERAL_REQUEST, majorGeneralSaga),
        takeEvery(CASH_BOOK_BANKS_REQUEST, cashBookBanksSaga),
        takeEvery(EXPENSE_ANALYSIS_NINE_REQUEST, expenseAnalysisNineSaga),
        takeEvery(EXPENSE_ANALYSIS_SIX_REQUEST, expenseAnalysisSixSaga),
        takeEvery(INVENTORY_BOOK_BALANCE_REQUEST, inventoryBookBalanceSaga),
        takeEvery(GENERAL_BALANCES_REQUEST, generalBalancesSaga),
        takeEvery(PROFIT_LOSS_STATEMENT_REQUEST, profitLossStatementSaga),
        takeEvery(MAJORIZATION_REQUEST, majorizationSaga),
        takeEvery(SALES_REGISTER_REQUEST, salesRegisterSaga),
        takeEvery(DAOT_REQUEST, daotSaga),
        takeEvery(PURCHASE_DETAIL_SUPPLIER_REQUEST, purchaseDetailSupplierSaga),
    ]);
}
  
const accountantSagas = [
    fork(accountantRootSaga),
];
  
export default accountantSagas;