import React from 'react';
import {createUseStyles} from 'react-jss';
import SpainIcon from 'penta-assets/images/spain.svg';
import DownArrowIcon from 'penta-assets/images/arrow-down-sign-to-navigate.svg';

const useStyles =  createUseStyles({
    container: {
        'position': 'relative'
    },
    selectHeader: {
        'fontFamily': '"Work Sans", sans-serif',
        'display': 'flex',
        'alignItems': 'center',
        'backgroundColor': '#F5F5F5',
        'fontSize': '0.75rem',
        'height': '2.188rem'
    },
    input: (props: any) => ({
        'border': '#dc1010',
        'color': '#3B3B3B',
        'fontSize': '0.75rem',
        'fontFamily': '"Work Sans", sans-serif',
        'height': '1.875rem',
        'borderRadius': '1.563rem',
        'width': '22.375rem',
        'padding': '0.6rem 2.813rem'
        // '&:hover': props.hover,
        // '&:focus': props.focus
    }),
    iconContainer: {
        'position': 'absolute',
        'top': '0.3rem',
        'left': '0.85rem'
    },
    countryDiv: {
        'display': 'flex',
        'alignItems': 'center',
        'margin': '0rem 0.938rem'
    },
    countryIcon: {
        'width': '1.25rem',
        'height': '1.25rem'
    },
    span: {
        'width': '6.25rem',
        'maxWidth': '6.25rem'
    },
    downArrowIcon: {
        'width': '1.25rem',
        'height': '0.713rem'
    }
});

interface CountryPicker {
    // onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    // value: string | number | readonly string[]
}

const CountryPicker = (props: CountryPicker) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <div className={classes.selectHeader}>
                <div className={classes.countryDiv}>
                    <img src={SpainIcon} className={classes.countryIcon} />
                </div>
                <span>Español</span>
                <div className={classes.countryDiv}>
                    <img src={DownArrowIcon} className={classes.downArrowIcon} />
                </div>
            </div>
            {/* <div className>
                <ul>
                    <li>Español</li>
                    <li>Ingles</li>
                </ul>
            </div> */}
            {/* <input className={classes.input} placeholder="search"/>
            <div className={classes.iconContainer}>
                <img src={SearchIcon} className={classes.icon} />
            </div> */}
        </div>
    )
}

export default CountryPicker;