export const CUSTOMERS_INIT_FORM_REQUEST = 'CUSTOMERS_INIT_FORM_REQUEST';
export const CUSTOMERS_INIT_FORM_REQUEST_SUCCESS = 'CUSTOMERS_INIT_FORM_REQUEST_SUCCESS';
export const CUSTOMERS_INIT_FORM_REQUEST_FAILED = 'CUSTOMERS_INIT_FORM_REQUEST_FAILED';
export const CUSTOMERS_INIT_UPDATE_FORM_REQUEST = 'CUSTOMERS_INIT_UPDATE_FORM_REQUEST';
export const CUSTOMERS_INIT_UPDATE_FORM_REQUEST_SUCCESS = 'CUSTOMERS_INIT_UPDATE_FORM_REQUEST_SUCCESS';
export const CUSTOMERS_INIT_UPDATE_FORM_REQUEST_FAILED = 'CUSTOMERS_INIT_UPDATE_FORM_REQUEST_FAILED';
export const CUSTOMERS_GET_ALL_RECORDS_REQUEST = 'CUSTOMERS_GET_ALL_RECORDS_REQUEST'; 
export const CUSTOMERS_GET_ALL_RECORDS_REQUEST_SUCCESS = 'CUSTOMERS_GET_ALL_RECORDS_REQUEST_SUCCESS'; 
export const CUSTOMERS_GET_ALL_RECORDS_REQUEST_FAILED = 'CUSTOMERS_GET_ALL_RECORDS_REQUEST_FAILED';
export const CUSTOMERS_INSERT_REQUEST = 'CUSTOMERS_INSERT_REQUEST';
export const CUSTOMERS_INSERT_REQUEST_SUCCESS = 'CUSTOMERS_INSERT_REQUEST_SUCCESS';
export const CUSTOMERS_INSERT_REQUEST_FAILED = 'CUSTOMERS_INSERT_REQUEST_FAILED';
export const CUSTOMERS_UPDATE_REQUEST = 'CUSTOMERS_UPDATE_REQUEST';
export const CUSTOMERS_UPDATE_REQUEST_SUCCESS = 'CUSTOMERS_UPDATE_REQUEST_SUCCESS';
export const CUSTOMERS_UPDATE_REQUEST_FAILED = 'CUSTOMERS_UPDATE_REQUEST_FAILED';
export const CUSTOMERS_DELETE_REQUEST = 'CUSTOMERS_DELETE_REQUEST';
export const CUSTOMERS_DELETE_REQUEST_SUCCESS = 'CUSTOMERS_DELETE_REQUEST_SUCCESS';
export const CUSTOMERS_DELETE_REQUEST_FAILED = 'CUSTOMERS_DELETE_REQUEST_FAILED';
/*Components */
export const CUSTOMERS_LIST_SELECT_ITEM = 'CUSTOMERS_LIST_SELECT_ITEM';
export const CUSTOMERS_LIST_SELECT_ALL_ITEM = 'CUSTOMERS_LIST_SELECT_ALL_ITEM';
export const CUSTOMERS_ACTION_FAILED = 'CUSTOMERS_ACTION_FAILED';

export const SALES_INIT_FORM_REQUEST = 'SALES_INIT_FORM_REQUEST';
export const SALES_INIT_FORM_REQUEST_SUCCESS = 'SALES_INIT_FORM_REQUEST_SUCCESS';
export const SALES_INIT_FORM_REQUEST_FAILED = 'SALES_INIT_FORM_REQUEST_FAILED';
export const SALES_INIT_UPDATE_FORM_REQUEST = 'SALES_INIT_UPDATE_FORM_REQUEST';
export const SALES_INIT_UPDATE_FORM_REQUEST_SUCCESS = 'SALES_INIT_UPDATE_FORM_REQUEST_SUCCESS';
export const SALES_INIT_UPDATE_FORM_REQUEST_FAILED = 'SALES_INIT_UPDATE_FORM_REQUEST_FAILED';
export const SALES_GET_ALL_RECORDS_REQUEST = 'SALES_GET_ALL_RECORDS_REQUEST'; 
export const SALES_GET_ALL_RECORDS_REQUEST_SUCCESS = 'SALES_GET_ALL_RECORDS_REQUEST_SUCCESS'; 
export const SALES_GET_ALL_RECORDS_REQUEST_FAILED = 'SALES_GET_ALL_RECORDS_REQUEST_FAILED';
export const SALES_INSERT_REQUEST = 'SALES_INSERT_REQUEST';
export const SALES_INSERT_REQUEST_SUCCESS = 'SALES_INSERT_REQUEST_SUCCESS';
export const SALES_INSERT_REQUEST_FAILED = 'SALES_INSERT_REQUEST_FAILED';
export const SALES_UPDATE_REQUEST = 'SALES_UPDATE_REQUEST';
export const SALES_UPDATE_REQUEST_SUCCESS = 'SALES_UPDATE_REQUEST_SUCCESS';
export const SALES_UPDATE_REQUEST_FAILED = 'SALES_UPDATE_REQUEST_FAILED';
export const SALES_DELETE_REQUEST = 'SALES_DELETE_REQUEST';
export const SALES_DELETE_REQUEST_SUCCESS = 'SALES_DELETE_REQUEST_SUCCESS';
export const SALES_DELETE_REQUEST_FAILED = 'SALES_DELETE_REQUEST_FAILED';
export const SALES_ANULATE_REQUEST = 'SALES_ANULATE_REQUEST';
export const SALES_ANULATE_REQUEST_SUCCESS = 'SALES_ANULATE_REQUEST_SUCCESS';
export const SALES_ANULATE_REQUEST_FAILED = 'SALES_ANULATE_REQUEST_FAILED';
export const SALES_MASSIVE_UPDATE_REQUEST = 'SALES_MASSIVE_UPDATE_REQUEST';
export const SALES_MASSIVE_UPDATE_REQUEST_SUCCESS = 'SALES_MASSIVE_UPDATE_REQUEST_SUCCESS';
export const SALES_MASSIVE_UPDATE_REQUEST_FAILED = 'SALES_MASSIVE_UPDATE_REQUEST_FAILED';
/*Components */
export const SALES_LIST_SELECT_ITEM = 'SALES_LIST_SELECT_ITEM';
export const SALES_LIST_SELECT_ALL_ITEM = 'SALES_LIST_SELECT_ALL_ITEM';
export const SALES_ACTION_FAILED = 'SALES_ACTION_FAILED';

//#region REPORTS

export const SALES_DETAILED_DIARY_BOOK_REQUEST = 'SALES_DETAILED_DIARY_BOOK_REQUEST';
export const SALES_DETAILED_DIARY_BOOK_REQUEST_SUCCESS = 'SALES_DETAILED_DIARY_BOOK_REQUEST_SUCCESS';
export const SALES_DETAILED_DIARY_BOOK_REQUEST_FAILED = 'SALES_DETAILED_DIARY_BOOK_REQUEST_FAILED';

export const KARDEX_REQUEST = 'KARDEX_REQUEST';
export const KARDEX_REQUEST_SUCCESS = 'KARDEX_REQUEST_SUCCESS';
export const KARDEX_REQUEST_FAILED = 'KARDEX_REQUEST_FAILED';

export const INVOICES_CHARGE_REQUEST = 'INVOICES_CHARGE_REQUEST';
export const INVOICES_CHARGE_REQUEST_SUCCESS = 'INVOICES_CHARGE_REQUEST_SUCCESS';
export const INVOICES_CHARGE_REQUEST_FAILED = 'INVOICES_CHARGE_REQUEST_FAILED';

//#endregion