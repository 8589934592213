import React from 'react';
import {createUseStyles} from 'react-jss';

const useStyles =  createUseStyles({
    container: {
        width: 'calc(100% - 6.875rem)',
        backgroundColor: '#F8F8F8',
        borderRadius: '3.3rem',
        boxShadow: '1px 3px 6px 0px rgb(0 0 0 / 16%)',
        padding: '2.5rem'
    },
});

interface Main {
    children: React.ReactNode
}

const Main = (props: Main) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            {props.children}
        </div>
    )
}

export default Main;