import * as React from "react";
import { Redirect, Route } from "react-router";
import { JwtVerify } from "utils/jwtVerify";

export const PublicRoute = ({ component: Component, ...rest }: any) => (
    <Route
      {...rest}
      render={props =>
        JwtVerify() ? (
            <Redirect
                to={{
                  pathname: "/papp",
                  state: { from: props.location }
                }}
            />
        ) : (
            <Component {...props} />
        )
      }
    />
);