import * as types from 'penta-redux/constants/shoppingsConstants';
import { baseModels } from 'penta-models';

//#region SUPPLIERS

export const suppliersInitFormRequest = (fiscyear: string) => ({
    type: types.SUPPLIERS_INIT_FORM_REQUEST,
    fiscyear
});

export const suppliersInitFormRequestSuccess = (tables: any) => ({
    type: types.SUPPLIERS_INIT_FORM_REQUEST_SUCCESS,
    tables
});

export const suppliersInitFormRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.SUPPLIERS_INIT_FORM_REQUEST_FAILED,
    titleError,
    titleContent
});

export const suppliersInitUpdateFormRequest = (docnumbe: string, callback: void) => ({
    type: types.SUPPLIERS_INIT_UPDATE_FORM_REQUEST,
    docnumbe,
    callback
});

export const suppliersInitUpdateFormRequestSuccess = (tables: any, onlyOne: any) => ({
    type: types.SUPPLIERS_INIT_UPDATE_FORM_REQUEST_SUCCESS,
    tables,
    onlyOne
});

export const suppliersInitUpdateFormRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.SUPPLIERS_INIT_UPDATE_FORM_REQUEST_FAILED,
    titleError,
    titleContent
});

export const suppliersGetAllRecordsRequest = (body: baseModels.pentaRequestQuery) => ({
    type: types.SUPPLIERS_GET_ALL_RECORDS_REQUEST,
    body
});

export const suppliersGetAllRecordsRequestSuccess = (list: Array<any>, total: number) => ({
    type: types.SUPPLIERS_GET_ALL_RECORDS_REQUEST_SUCCESS,
    list,
    total
});

export const suppliersGetAllRecordsRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.SUPPLIERS_GET_ALL_RECORDS_REQUEST_FAILED,
    titleError,
    titleContent
});

export const suppliersInsertRequest = (body: any, callback?: void) => ({
    type: types.SUPPLIERS_INSERT_REQUEST,
    body,
    callback
});

export const suppliersInsertRequestSuccess = () => ({
    type: types.SUPPLIERS_INSERT_REQUEST_SUCCESS
});

export const suppliersInsertRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.SUPPLIERS_INSERT_REQUEST_SUCCESS,
    titleError,
    titleContent
});

export const suppliersUpdateRequest = (body: any) => ({
    type: types.SUPPLIERS_UPDATE_REQUEST,
    body
});

export const suppliersUpdateRequestSuccess = () => ({
    type: types.SUPPLIERS_UPDATE_REQUEST_SUCCESS
});

export const suppliersUpdateRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.SUPPLIERS_UPDATE_REQUEST_FAILED,
    titleError,
    titleContent
});

export const suppliersDeleteRequest = (docnumbes: Array<string>) => ({
    type: types.SUPPLIERS_DELETE_REQUEST,
    docnumbes
});

export const suppliersDeleteRequestSuccess = () => ({
    type: types.SUPPLIERS_DELETE_REQUEST_SUCCESS
});

export const suppliersDeleteRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.SUPPLIERS_DELETE_REQUEST_FAILED,
    titleError,
    titleContent
});

/*Components */
export const suppliersListSelectItem = (key: number) => ({
    type: types.SUPPLIERS_LIST_SELECT_ITEM,
    key
});

export const suppliersListSelectAllItem = (checked: boolean) => ({
    type: types.SUPPLIERS_LIST_SELECT_ALL_ITEM,
    checked
});
export const suppliersActionFailed = (titleError: string, titleContent: string) => ({
    type: types.SUPPLIERS_ACTION_FAILED,
    titleError,
    titleContent
});

//#endregion

//#region PURCHASES

export const purchasesInitFormRequest = (callback: any) => ({
    type: types.PURCHASES_INIT_FORM_REQUEST,
    callback
});

export const purchasesInitFormRequestSuccess = (tables: any) => ({
    type: types.PURCHASES_INIT_FORM_REQUEST_SUCCESS,
    tables
});

export const purchasesInitFormRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.PURCHASES_INIT_FORM_REQUEST_FAILED,
    titleError,
    titleContent
});

export const purchasesInitUpdateFormRequest = (serialnu: string, documnum: string, docsuppl: string, callback: void) => ({
    type: types.PURCHASES_INIT_UPDATE_FORM_REQUEST,
    serialnu,
    documnum,
    docsuppl,
    callback
});

export const purchasesInitUpdateFormRequestSuccess = (tables: any, onlyOne: any) => ({
    type: types.PURCHASES_INIT_UPDATE_FORM_REQUEST_SUCCESS,
    tables,
    onlyOne
});

export const purchasesInitUpdateFormRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.PURCHASES_INIT_UPDATE_FORM_REQUEST_FAILED,
    titleError,
    titleContent
});

export const purchasesGetAllRecordsRequest = (body: baseModels.pentaRequestQuery) => ({
    type: types.PURCHASES_GET_ALL_RECORDS_REQUEST,
    body
});

export const purchasesGetAllRecordsRequestSuccess = (list: Array<any>, total: number) => ({
    type: types.PURCHASES_GET_ALL_RECORDS_REQUEST_SUCCESS,
    list,
    total
});

export const purchasesGetAllRecordsRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.PURCHASES_GET_ALL_RECORDS_REQUEST_FAILED,
    titleError,
    titleContent
});

export const purchasesInsertRequest = (body: any, callback?: void) => ({
    type: types.PURCHASES_INSERT_REQUEST,
    body,
    callback
});

export const purchasesInsertRequestSuccess = () => ({
    type: types.PURCHASES_INSERT_REQUEST_SUCCESS
});

export const purchasesInsertRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.PURCHASES_INSERT_REQUEST_FAILED,
    titleError,
    titleContent
});

export const purchasesUpdateRequest = (body: any) => ({
    type: types.PURCHASES_UPDATE_REQUEST,
    body
});

export const purchasesUpdateRequestSuccess = () => ({
    type: types.PURCHASES_UPDATE_REQUEST_SUCCESS
});

export const purchasesUpdateRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.PURCHASES_UPDATE_REQUEST_FAILED,
    titleError,
    titleContent
});

export const purchasesDeleteRequest = (serialnus: Array<string>, documnums: Array<string>, docsuppls: Array<string>, filters: Object) => ({
    type: types.PURCHASES_DELETE_REQUEST,
    serialnus,
    documnums,
    docsuppls,
    filters
});

export const purchasesDeleteRequestSuccess = () => ({
    type: types.PURCHASES_DELETE_REQUEST_SUCCESS
});

export const purchasesDeleteRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.PURCHASES_DELETE_REQUEST_FAILED,
    titleError,
    titleContent
});

export const purchasesAnulateRequest = (serialnus: Array<string>, documnums: Array<string>, docsuppls: Array<string>) => ({
    type: types.PURCHASES_DELETE_REQUEST,
    serialnus,
    documnums,
    docsuppls
});

export const purchasesAnulateRequestSuccess = () => ({
    type: types.PURCHASES_DELETE_REQUEST_SUCCESS
});

export const purchasesAnulateRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.PURCHASES_DELETE_REQUEST_FAILED,
    titleError,
    titleContent
});

export const purchasesMassiveUpdateRequest = (body: any) => ({
    type: types.PURCHASES_MASSIVE_UPDATE_REQUEST,
    body
});

export const purchasesMassiveUpdateRequestSuccess = () => ({
    type: types.PURCHASES_MASSIVE_UPDATE_REQUEST_SUCCESS
});

export const purchasesMassiveUpdateRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.PURCHASES_MASSIVE_UPDATE_REQUEST_FAILED,
    titleError,
    titleContent
});

/*Components */
export const purchasesListSelectItem = (key: number) => ({
    type: types.PURCHASES_LIST_SELECT_ITEM,
    key
});

export const purchasesListSelectAllItem = (checked: boolean) => ({
    type: types.PURCHASES_LIST_SELECT_ALL_ITEM,
    checked
});
export const purchasesActionFailed = (titleError: string, titleContent: string) => ({
    type: types.PURCHASES_ACTION_FAILED,
    titleError,
    titleContent
});

//#endregion

//#region CREDIT NOTE

export const creditNoteInitFormRequest = (callback: void) => ({
    type: types.CREDIT_NOTE_INIT_FORM_REQUEST,
    callback
});

export const creditNoteInitFormRequestSuccess = (tables: any) => ({
    type: types.CREDIT_NOTE_INIT_FORM_REQUEST_SUCCESS,
    tables
});

export const creditNoteInitFormRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.CREDIT_NOTE_INIT_FORM_REQUEST_FAILED,
    titleError,
    titleContent
});

export const creditNoteInitUpdateFormRequest = (serialnu: string, documnum: string, docsuppl: string, callback: void) => ({
    type: types.CREDIT_NOTE_INIT_UPDATE_FORM_REQUEST,
    serialnu,
    documnum,
    docsuppl,
    callback
});

export const creditNoteInitUpdateFormRequestSuccess = (tables: any, onlyOne: any) => ({
    type: types.CREDIT_NOTE_INIT_UPDATE_FORM_REQUEST_SUCCESS,
    tables,
    onlyOne
});

export const creditNoteInitUpdateFormRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.CREDIT_NOTE_INIT_UPDATE_FORM_REQUEST_FAILED,
    titleError,
    titleContent
});

export const creditNoteGetAllRecordsRequest = (body: baseModels.pentaRequestQuery) => ({
    type: types.CREDIT_NOTE_GET_ALL_RECORDS_REQUEST,
    body
});

export const creditNoteGetAllRecordsRequestSuccess = (list: Array<any>, total: number) => ({
    type: types.CREDIT_NOTE_GET_ALL_RECORDS_REQUEST_SUCCESS,
    list,
    total
});

export const creditNoteGetAllRecordsRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.CREDIT_NOTE_GET_ALL_RECORDS_REQUEST_FAILED,
    titleError,
    titleContent
});

export const creditNoteInsertRequest = (body: any, callback?: void) => ({
    type: types.CREDIT_NOTE_INSERT_REQUEST,
    body,
    callback
});

export const creditNoteInsertRequestSuccess = () => ({
    type: types.CREDIT_NOTE_INSERT_REQUEST_SUCCESS
});

export const creditNoteInsertRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.CREDIT_NOTE_INSERT_REQUEST_FAILED,
    titleError,
    titleContent
});

export const creditNoteUpdateRequest = (body: any) => ({
    type: types.CREDIT_NOTE_UPDATE_REQUEST,
    body
});

export const creditNoteUpdateRequestSuccess = () => ({
    type: types.CREDIT_NOTE_UPDATE_REQUEST_SUCCESS
});

export const creditNoteUpdateRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.CREDIT_NOTE_UPDATE_REQUEST_FAILED,
    titleError,
    titleContent
});

export const creditNoteDeleteRequest = (serialnus: Array<string>, documnums: Array<string>, docsuppls: Array<string>) => ({
    type: types.CREDIT_NOTE_DELETE_REQUEST,
    serialnus,
    documnums,
    docsuppls
});

export const creditNoteDeleteRequestSuccess = () => ({
    type: types.CREDIT_NOTE_DELETE_REQUEST_SUCCESS
});

export const creditNoteDeleteRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.CREDIT_NOTE_DELETE_REQUEST_FAILED,
    titleError,
    titleContent
});

export const creditNoteAnulateRequest = (serialnus: Array<string>, documnums: Array<string>, docsuppls: Array<string>) => ({
    type: types.CREDIT_NOTE_DELETE_REQUEST,
    serialnus,
    documnums,
    docsuppls
});

export const creditNoteAnulateRequestSuccess = () => ({
    type: types.CREDIT_NOTE_DELETE_REQUEST_SUCCESS
});

export const creditNoteAnulateRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.CREDIT_NOTE_DELETE_REQUEST_FAILED,
    titleError,
    titleContent
});

/*Components */
export const creditNoteListSelectItem = (key: number) => ({
    type: types.CREDIT_NOTE_LIST_SELECT_ITEM,
    key
});

export const creditNoteListSelectAllItem = (checked: boolean) => ({
    type: types.CREDIT_NOTE_LIST_SELECT_ALL_ITEM,
    checked
});
export const creditNoteActionFailed = (titleError: string, titleContent: string) => ({
    type: types.CREDIT_NOTE_ACTION_FAILED,
    titleError,
    titleContent
});

//#endregion

//#region DEBIT NOTE

export const debitNoteInitFormRequest = (callback: void) => ({
    type: types.DEBIT_NOTE_INIT_FORM_REQUEST,
    callback
});

export const debitNoteInitFormRequestSuccess = (tables: any) => ({
    type: types.DEBIT_NOTE_INIT_FORM_REQUEST_SUCCESS,
    tables
});

export const debitNoteInitFormRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.DEBIT_NOTE_INIT_FORM_REQUEST_FAILED,
    titleError,
    titleContent
});

export const debitNoteInitUpdateFormRequest = (serialnu: string, documnum: string, docsuppl: string, callback: void) => ({
    type: types.DEBIT_NOTE_INIT_UPDATE_FORM_REQUEST,
    serialnu,
    documnum,
    docsuppl,
    callback
});

export const debitNoteInitUpdateFormRequestSuccess = (tables: any, onlyOne: any) => ({
    type: types.DEBIT_NOTE_INIT_UPDATE_FORM_REQUEST_SUCCESS,
    tables,
    onlyOne
});

export const debitNoteInitUpdateFormRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.DEBIT_NOTE_INIT_UPDATE_FORM_REQUEST_FAILED,
    titleError,
    titleContent
});

export const debitNoteGetAllRecordsRequest = (body: baseModels.pentaRequestQuery) => ({
    type: types.DEBIT_NOTE_GET_ALL_RECORDS_REQUEST,
    body
});

export const debitNoteGetAllRecordsRequestSuccess = (list: Array<any>, total: number) => ({
    type: types.DEBIT_NOTE_GET_ALL_RECORDS_REQUEST_SUCCESS,
    list,
    total
});

export const debitNoteGetAllRecordsRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.DEBIT_NOTE_GET_ALL_RECORDS_REQUEST_FAILED,
    titleError,
    titleContent
});

export const debitNoteInsertRequest = (body: any, callback?: void) => ({
    type: types.DEBIT_NOTE_INSERT_REQUEST,
    body,
    callback
});

export const debitNoteInsertRequestSuccess = () => ({
    type: types.DEBIT_NOTE_INSERT_REQUEST_SUCCESS
});

export const debitNoteInsertRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.DEBIT_NOTE_INSERT_REQUEST_FAILED,
    titleError,
    titleContent
});

export const debitNoteUpdateRequest = (body: any) => ({
    type: types.DEBIT_NOTE_UPDATE_REQUEST,
    body
});

export const debitNoteUpdateRequestSuccess = () => ({
    type: types.DEBIT_NOTE_UPDATE_REQUEST_SUCCESS
});

export const debitNoteUpdateRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.DEBIT_NOTE_UPDATE_REQUEST_FAILED,
    titleError,
    titleContent
});

export const debitNoteDeleteRequest = (serialnus: Array<string>, documnums: Array<string>, docsuppls: Array<string>) => ({
    type: types.DEBIT_NOTE_DELETE_REQUEST,
    serialnus,
    documnums,
    docsuppls
});

export const debitNoteDeleteRequestSuccess = () => ({
    type: types.DEBIT_NOTE_DELETE_REQUEST_SUCCESS
});

export const debitNoteDeleteRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.DEBIT_NOTE_DELETE_REQUEST_FAILED,
    titleError,
    titleContent
});

export const debitNoteAnulateRequest = (serialnus: Array<string>, documnums: Array<string>, docsuppls: Array<string>) => ({
    type: types.DEBIT_NOTE_DELETE_REQUEST,
    serialnus,
    documnums,
    docsuppls
});

export const debitNoteAnulateRequestSuccess = () => ({
    type: types.DEBIT_NOTE_DELETE_REQUEST_SUCCESS
});

export const debitNoteAnulateRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.DEBIT_NOTE_DELETE_REQUEST_FAILED,
    titleError,
    titleContent
});

/*Components */
export const debitNoteListSelectItem = (key: number) => ({
    type: types.DEBIT_NOTE_LIST_SELECT_ITEM,
    key
});

export const debitNoteListSelectAllItem = (checked: boolean) => ({
    type: types.DEBIT_NOTE_LIST_SELECT_ALL_ITEM,
    checked
});
export const debitNoteActionFailed = (titleError: string, titleContent: string) => ({
    type: types.DEBIT_NOTE_ACTION_FAILED,
    titleError,
    titleContent
});

//#endregion

//#region REPORTS

export const invoicesPayRequest = (initdate: string, endidate: string, callback: void) => ({
    type: types.INVOICES_PAY_REQUEST,
    initdate,
    endidate,
    callback
});

export const invoicesPayRequestSuccess = (onlyOne: any) => ({
    type: types.INVOICES_PAY_REQUEST_SUCCESS,
    onlyOne
});

export const invoicesPayRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.INVOICES_PAY_REQUEST_FAILED,
    titleError,
    titleContent
});

//#endregion