import { CURRENCY_EXCHANGE_RATE_REQUEST, CURRENCY_EXCHANGE_RATE_REQUEST_FAILED, CURRENCY_EXCHANGE_RATE_REQUEST_SUCCESS, LOCATIONS_GET_DEPARTMENTS_REQUEST, LOCATIONS_GET_DEPARTMENTS_REQUEST_FAILED, LOCATIONS_GET_DEPARTMENTS_REQUEST_SUCCESS, LOCATIONS_GET_DISTRICT_REQUEST, LOCATIONS_GET_DISTRICT_REQUEST_FAILED, LOCATIONS_GET_DISTRICT_REQUEST_SUCCESS, LOCATIONS_GET_PROVINCES_REQUEST, LOCATIONS_GET_PROVINCES_REQUEST_FAILED, LOCATIONS_GET_PROVINCES_REQUEST_SUCCESS, MASTER_TABLES_REQUEST, MASTER_TABLES_REQUEST_FAILED, MASTER_TABLES_REQUEST_SUCCESS } from '../constants/generalConstants';

const initialState: any = {
    tables: { departamentos: [], provincias: [], distritos: [], exchangeRate: [] },
    loading: false,
    failed: false,
    failedTitle: null,
    failedContent: null
};

export default function generalReducer(state = initialState, action: any = {}) {
    switch(action.type) {
        case LOCATIONS_GET_DEPARTMENTS_REQUEST:
        case LOCATIONS_GET_PROVINCES_REQUEST:
        case LOCATIONS_GET_DISTRICT_REQUEST:
        case CURRENCY_EXCHANGE_RATE_REQUEST:
        case MASTER_TABLES_REQUEST:
            return {
                ...state,
                loading: true
            };
        case MASTER_TABLES_REQUEST_SUCCESS:
            return {
                ...state,
                onlyOne: action.onlyOne,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case MASTER_TABLES_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case LOCATIONS_GET_DEPARTMENTS_REQUEST_SUCCESS:
        case LOCATIONS_GET_PROVINCES_REQUEST_SUCCESS:
        case LOCATIONS_GET_DISTRICT_REQUEST_SUCCESS:
            return {
                ...state,
                tables: { ...state.tables, ...{ ...state.tables, ...action.tables } },
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case CURRENCY_EXCHANGE_RATE_REQUEST_SUCCESS:
            return {
                ...state,
                tables: { ...state.tables, ...action.tables },
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case LOCATIONS_GET_DEPARTMENTS_REQUEST_FAILED:
        case LOCATIONS_GET_PROVINCES_REQUEST_FAILED:
        case LOCATIONS_GET_DISTRICT_REQUEST_FAILED:
        case CURRENCY_EXCHANGE_RATE_REQUEST_FAILED:
            return {
                ...state,
                tables: initialState.tables,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        default:
            return state;
    }
}