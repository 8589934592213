import React from 'react';
import {createUseStyles} from 'react-jss';

const useStyles = createUseStyles({
    container: {
        position: 'relative',
        padding: '15px',
        '&:after': {
            position: 'absolute',
            transform: 'translateY(-50%)',
            top: '50%',
            left: 0,
            content: '""',
            display: 'block',
            width: '100%',
            height: '24px',
            backgroundImage: 'linear-gradient( 100deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0) 80% )',
            backgroundSize: '200px 24px',
            backgroundPosition: '-100px 0',
            backgroundRepeat: 'no-repeat',
            animation: '$loading 1s infinite',
        }
    },
    bar: {
        backgroundColor: '#E7E7E7',
        height: '14px',
        borderRadius: '7px',
        width: '80%'
    },
    '@keyframes loading': {
        '40%': {
            backgroundPosition: '100% 0'
        },
        '100%': {
            backgroundPosition: '100% 0'
        }
    }
});

interface Skeleton {

}

const Row = (props: Skeleton) => {
    const classes = useStyles();
    return (
        <td className={classes.container}>
            <div className={classes.bar}/>
        </td>
    )
}

const Skeleton = {
    Row
}

export default Skeleton;