import React, { useEffect } from 'react';
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
    ul: {
        'listStyle': 'none'
    },
    li: {
        'display': 'inline',
        'fontSize': '0.75rem',
        'fontFamily': '"Work Sans", sans-serif',
        'color': '#888888',
        '& + &:before': {
            'padding': '0.313rem',
            'content': '"/"'
        }
    }
});

const Breadcrumb = () => {
    const classes = useStyles();
    const [paths, setPaths] = React.useState(['papp']);

    useEffect(() => {
        const _paths = window.location.pathname.split('/');
        setPaths([..._paths.filter(x => x !== '')]);
    }, [window.location.pathname,])

    return (
        <ul className={classes.ul}>
            {
                paths.map((item) => 
                    <li key={item} className={classes.li}>{item}</li>
                )
            }
        </ul>
    )
}

export default Breadcrumb;