import React from 'react';
import { AppBar, Backdrop, Box, Button, ButtonGroup, Checkbox, CircularProgress, createStyles, FormControl, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Select, Tab, Tabs, TextField, Theme, Tooltip, Typography, FormControlLabel, withStyles, Stepper, Step, StepLabel, Snackbar, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { payrollInitFormRequest, payrollInitUpdateFormRequest, payrollInsertRequest, payrollUpdateRequest } from 'penta-redux/actions/humanResourcesActions';
import history from '../../../utils/history';
import './payroll.scss'
import FormFooter from 'penta-components/base/formFooter';
import { MessageBox } from 'penta-components/base/messageBox';
import { conceptsEntity, employeeEntity, emplpayrEntity } from 'models/humanResourcesModels';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormFooterSteps from 'penta-components/base/formFooterSteps';
import { Alert } from '@material-ui/lab';
import ScreenLoader from 'penta-components/base/screenLoader';

const moment = require('moment');
const steps = ['Definicion de archivos de planillas', 'Tareos'];
const currentFiscyear = localStorage.getItem('currentFiscyear');

const useStyles = (theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        formControl: {
            margin: 0,
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    });

const defaultEmplpayrEntity: emplpayrEntity = {
    emplcode: '',
    officode: '',
    payryear: currentFiscyear,
    payrweek: '' + moment().week(),
    payrmont: moment().format('MM'),
    typesala: '',
    payrtype: '',
    stardate: moment().format('YYYY-MM-01'),
    endidate: moment().format('YYYY-MM-') + moment().daysInMonth(),
    nameeess: '',
    basalary: 0.00,
    commcode: null,
    typecomm: null,
    regitype: '',
    asignmen: '',
    concepts: [],
    totincom: 0.00,
    totdesco: 0.00,
    netrecei: 0.00
}

interface FormPayrollState {
    emplpayrEntity: emplpayrEntity
    title: string
    activeStep: number
    conccode: string
    concdesc: string
    concvalu: string
    snackbar: boolean
}

class FormPayroll extends React.Component<any, FormPayrollState> {

    constructor(props: any) {
        super(props);
        defaultEmplpayrEntity.concepts = [];
        this.state = {
            emplpayrEntity: defaultEmplpayrEntity,
            title: 'Agregar Tareo',
            activeStep: 0,
            conccode: '',
            concdesc: '',
            concvalu: '',
            snackbar: false
        }
    }

    onChangeRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            ...value
        }))
        const key = Object.keys(value)[0];
        if(key === 'activeStep') {
            if(value.activeStep === 1) {
                const { typesala, payrtype } = this.state.emplpayrEntity;
                if (typesala === '' || payrtype === '')
                this.setState({activeStep: 0});
                else {
                    const { emplpayrEntity } = this.state;
                    const { tables } = this.props.humanResourcesReducers;
                    const concepts = this.onCalculate(
                        emplpayrEntity, 
                        [...tables.prl_concepts.filter((x: any) => x.typesala === emplpayrEntity.typesala && x.payrtype === emplpayrEntity.payrtype).map((x: any) => { return {...x, outcomee: '0', isChange: false } })], 
                        []);
                    this.setState((state) => ({
                        ...state,
                        emplpayrEntity: {
                            ...state.emplpayrEntity,
                            concepts
                        }
                    }))
                }
            }
        }
        if(key === 'conccode') {
            const { emplpayrEntity } = this.state;
            let concepts = this.props.humanResourcesReducers.tables.prl_concepts.find((x: any) => (x.conccode + '').toUpperCase() === (value.conccode + '').toUpperCase() && x.typesala === emplpayrEntity.typesala && x.payrtype === emplpayrEntity.payrtype);
            concepts = concepts !== undefined ? concepts.descript : '';
            this.setState((state) => ({
                ...state,
                concdesc: concepts
            }))
        }
    }

    onChangeEmplpayrRoot = (value: any) => {
        const { tables } = this.props.humanResourcesReducers;
        this.setState((state) => ({
            ...state,
            emplpayrEntity: {
                ...state.emplpayrEntity,
                ...value
            }
        }))
        const key = Object.keys(value)[0];
        if (key === 'payrweek') {
            const weekcale = tables.prl_weekcale.find((x: any) => parseInt(x.weeknumb) == parseInt(value.payrweek) && parseInt(x.yearnumb) == parseInt(this.state.emplpayrEntity.payryear)) || { };
            this.setState((state) => ({
                ...state,
                emplpayrEntity: {
                    ...state.emplpayrEntity,
                   payrmont: weekcale.montnumb,
                   stardate: moment(weekcale.stardate).format('YYYY-MM-DD'),
                   endidate: moment(weekcale.endddate).format('YYYY-MM-DD')
                }
            }));
        }
        else if (key === 'payrmont') {
            const weekcale = tables.prl_weekcale.filter((x: any) => parseInt(x.montnumb) == parseInt(value.payrmont) && parseInt(x.yearnumb) == parseInt(this.state.emplpayrEntity.payryear)) || [];
            if(weekcale.length === 0) return;
            const dateString = `${this.state.emplpayrEntity.payryear}-${value.payrmont}-01`;
            this.setState((state) => ({
                ...state,
                emplpayrEntity: {
                    ...state.emplpayrEntity,
                   payrweek: weekcale[0].weeknumb,
                   stardate: moment(dateString).format('YYYY-MM-DD'),
                   endidate: moment(dateString).format('YYYY-MM-') + moment(dateString).daysInMonth()
                }
            }));
        }
    }

    onLoadEmploye = (value: any) => {
        const { emplpayrEntity } = this.state;
        const { tables } = this.props.humanResourcesReducers;
        // emplpayrEntity.concepts.find((x: any) => x.conccode === 'C201').outcomee = value.basalary;
        // value.concepts = emplpayrEntity.concepts;
        emplpayrEntity.emplcode = value.emplcode;
        emplpayrEntity.basalary = value.basalary;
        emplpayrEntity.regitype = value.regitype;
        emplpayrEntity.commcode = value.commcode;
        emplpayrEntity.typecomm = value.typecomm;
        emplpayrEntity.asignmen = value.asignmen;
        (emplpayrEntity as any).renumber2 = value.renumber2;
        (emplpayrEntity as any).renumber3 = value.renumber3;
        (emplpayrEntity as any).renumber4 = value.renumber4;
        (emplpayrEntity as any).renumber5 = value.renumber5;
        value.concepts = this.onCalculate(emplpayrEntity, 
            [...tables.prl_concepts.filter((x: any) => x.typesala === emplpayrEntity.typesala && x.payrtype === emplpayrEntity.payrtype).map((x: any) => { return {...x, outcomee: '0', isChange: false } })], []);//Object.keys(value))
        this.setState((state) => ({
            ...state,
            emplpayrEntity: {
                ...state.emplpayrEntity,
                ...value
            }
        }))
    }

    keyPressConcept = (e: any) => {
        if(e.key === 'Enter') {
            const { emplpayrEntity, concvalu, conccode } = this.state;
            try {
                for (const iterator of emplpayrEntity.concepts) {
                    if((conccode + '').toUpperCase() === 'C132' && this.state.emplpayrEntity.typesala === '004') {
                        if(concvalu !== '21' && concvalu !== '22') continue;
                    }
                    if((iterator.conccode + '').toUpperCase() === (conccode + '').toUpperCase()) {
                        iterator.outcomee = concvalu;
                        iterator.isChange = true;
                    }
                }
                // emplpayrEntity.concepts.find((x: any) => (x.conccode + '').toUpperCase() === (conccode + '').toUpperCase())
                emplpayrEntity.concepts = this.onCalculate(emplpayrEntity, emplpayrEntity.concepts, [conccode.toUpperCase()]);
                this.setState((state) => ({
                    ...state,
                    emplpayrEntity: {
                        ...state.emplpayrEntity,
                        concepts: emplpayrEntity.concepts
                    }
                }))
            } catch {}
        }
    }

    onCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        console.log('cancel')
        history.push('/papp/hhrr/planillas');
    }

    onContinueCallback = () => {
        const { emplpayrEntity } = this.state;
        const { tables } = this.props.humanResourcesReducers;
        const newEmplpayrEntity = defaultEmplpayrEntity;
        newEmplpayrEntity.typesala = this.state.emplpayrEntity.typesala;
        newEmplpayrEntity.payrtype = this.state.emplpayrEntity.payrtype;
        newEmplpayrEntity.payryear = this.state.emplpayrEntity.payryear;
        newEmplpayrEntity.payrweek = this.state.emplpayrEntity.payrweek;
        newEmplpayrEntity.payrmont = this.state.emplpayrEntity.payrmont;
        newEmplpayrEntity.stardate = this.state.emplpayrEntity.stardate;
        newEmplpayrEntity.endidate = this.state.emplpayrEntity.endidate;
        newEmplpayrEntity.concepts = this.onCalculate(
            newEmplpayrEntity, 
            [...tables.prl_concepts.filter((x: any) => x.typesala === newEmplpayrEntity.typesala && x.payrtype === newEmplpayrEntity.payrtype).map((x: any) => { return {...x, outcomee: '0', isChange: false } })], 
            []);
        this.setState({
            emplpayrEntity: newEmplpayrEntity
        });
    }

    onSubmitContinue = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const { params } = this.props.match;
        if (params.emplcode !== undefined) {
            this.props.payrollUpdateRequest(this.state.emplpayrEntity, null);
        } else {
            this.props.payrollInsertRequest(this.state.emplpayrEntity, this.onContinueCallback);
        }
    }

    onSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const { params } = this.props.match;
        if (params.emplcode !== undefined) {
            this.props.payrollUpdateRequest(this.state.emplpayrEntity, null);
        } else {
            this.props.payrollInsertRequest(this.state.emplpayrEntity, null);
        }
    }

    callbackInit = () => {
        // const { emplpayrEntity } = this.state;
        // const { tables } = this.props.humanResourcesReducers;
        // const concepts = this.onCalculate(emplpayrEntity, tables.prl_concepts.filter((x: any) => x.typesala === emplpayrEntity.typesala && x. === emplpayrEntity.payrtype)), null);
        // this.setState((state) => ({
        //     ...state,
        //     emplpayrEntity: {
        //         ...state.emplpayrEntity,
        //         concepts
        //     }
        // }))
        return;
    }

    callbackInitUpdate = (emplpayrEntity: emplpayrEntity) => {
        // payrollEntity.prl_emplinfo.datebirt = moment(payrollEntity.prl_emplinfo.datebirt).format('YYYY-MM-DD')
        // payrollEntity.prl_emplojob.dateadmi = moment(payrollEntity.prl_emplojob.dateadmi).format('YYYY-MM-DD')
        // payrollEntity.prl_emplojob.termdate = moment(payrollEntity.prl_emplojob.termdate).format('YYYY-MM-DD')
        // payrollEntity.prl_emplrenu.renumber = JSON.parse(payrollEntity.prl_emplrenu.renumber);
        const { tables } = this.props.humanResourcesReducers;
        const employee = tables.prl_employee.find((x: any) => x.emplcode === emplpayrEntity.emplcode);
        if(employee) {
            (emplpayrEntity as any).renumber2 = JSON.parse(employee.prl_emplrenu.renumber).renumber2;
            (emplpayrEntity as any).renumber3 = JSON.parse(employee.prl_emplrenu.renumber).renumber3;
            (emplpayrEntity as any).renumber4 = JSON.parse(employee.prl_emplrenu.renumber).renumber4;
            (emplpayrEntity as any).renumber5 = JSON.parse(employee.prl_emplrenu.renumber).renumber5;
        }
        emplpayrEntity.stardate = moment(emplpayrEntity.stardate).format('YYYY-MM-DD');
        emplpayrEntity.endidate = moment(emplpayrEntity.endidate).format('YYYY-MM-DD');
        emplpayrEntity.concepts = JSON.parse(emplpayrEntity.concepts).map((x: any) => { return {...x, isChange: x.isChange ? x.isChange : false } });
        this.setState({
            emplpayrEntity
        });
    }

    onCalculate = (emplpayrEntity: emplpayrEntity, concepts: Array<conceptsEntity>, currentKeys: Array<string>) => {
        const { tables } = this.props.humanResourcesReducers;
        const conctype50 = concepts.filter((x: any) => x.conctype === '050');
        const conctype51 = concepts.filter((x: any) => x.conctype === '051');
        const conctype52 = concepts.filter((x: any) => x.conctype === '052');
        const conctype53 = concepts.filter((x: any) => x.conctype === '053');
        const conctype54 = concepts.filter((x: any) => x.conctype === '054');
        const conctype55 = concepts.filter((x: any) => x.conctype === '055');
        for(const iterator of conctype50) {
            if (currentKeys.includes(iterator.conccode)) continue;
            if(iterator.scriptss !== null && iterator.scriptss !== '' && (iterator as any).isChange === false) {
                eval(iterator.scriptss);
                iterator.outcomee = (Math.round(parseFloat(iterator.outcomee) * 100) / 100).toString();
            } else {
                iterator.outcomee = iterator.outcomee === '' ? '0' : iterator.outcomee;
            }
        }
        for(const iterator of conctype51) {
            if (currentKeys.includes(iterator.conccode)) continue;
            if(iterator.scriptss !== null && iterator.scriptss !== '' && (iterator as any).isChange === false) {
                eval(iterator.scriptss);
                iterator.outcomee = (Math.round(parseFloat(iterator.outcomee) * 100) / 100).toString();
            } else {
                iterator.outcomee = iterator.outcomee === '' ? '0' : iterator.outcomee;
            }
        }
        for(const iterator of conctype54) {
            if (currentKeys.includes(iterator.conccode)) continue;
            if(iterator.scriptss !== null && iterator.scriptss !== '' && (iterator as any).isChange === false) {
                eval(iterator.scriptss);
                iterator.outcomee = (Math.round(parseFloat(iterator.outcomee) * 100) / 100).toString();
            } else {
                iterator.outcomee = iterator.outcomee === '' ? '0' : iterator.outcomee;
            }
        }
        // debugger;
        for(const iterator of conctype52) {
            if (currentKeys.includes(iterator.conccode)) continue;
            if(iterator.scriptss !== null && iterator.scriptss !== '' && (iterator as any).isChange === false) {
                eval(iterator.scriptss);
                iterator.outcomee = (Math.round(parseFloat(iterator.outcomee) * 100) / 100).toString();
            } else {
                iterator.outcomee = iterator.outcomee === '' ? '0' : iterator.outcomee;
            }
        }
        for(const iterator of conctype54) {
            if (currentKeys.includes(iterator.conccode)) continue;
            if(iterator.scriptss !== null && iterator.scriptss !== '' && (iterator as any).isChange === false) {
                eval(iterator.scriptss);
                iterator.outcomee = (Math.round(parseFloat(iterator.outcomee) * 100) / 100).toString();
            } else {
                iterator.outcomee = iterator.outcomee === '' ? '0' : iterator.outcomee;
            }
        }
        for(const iterator of conctype53) {
            if (currentKeys.includes(iterator.conccode)) continue;
            if(iterator.scriptss !== null && iterator.scriptss !== '' && (iterator as any).isChange === false) {
                eval(iterator.scriptss);
                iterator.outcomee = (Math.round(parseFloat(iterator.outcomee) * 100) / 100).toString();
            } else {
                iterator.outcomee = iterator.outcomee === '' ? '0' : iterator.outcomee;
            }
        }
        for(const iterator of conctype54) {
            if (currentKeys.includes(iterator.conccode)) continue;
            if(iterator.scriptss !== null && iterator.scriptss !== '' && (iterator as any).isChange === false) {
                eval(iterator.scriptss);
                iterator.outcomee = (Math.round(parseFloat(iterator.outcomee) * 100) / 100).toString();
            } else {
                iterator.outcomee = iterator.outcomee === '' ? '0' : iterator.outcomee;
            }
        }
        for(const iterator of conctype55) {
            if (currentKeys.includes(iterator.conccode)) continue;
            if(iterator.scriptss !== null && iterator.scriptss !== '' && (iterator as any).isChange === false) {
                eval(iterator.scriptss);
                iterator.outcomee = (Math.round(parseFloat(iterator.outcomee) * 100) / 100).toString();
            } else {
                iterator.outcomee = iterator.outcomee === '' ? '0' : iterator.outcomee;
            }
        }
        return concepts;
    }

    componentDidMount() {
        const { params } = this.props.match;
        if (params.emplcode !== undefined) {
            this.setState({title: 'Editar Empleados', activeStep: 1});
            this.props.payrollInitUpdateFormRequest(params.emplcode, params.payryear, params.payrweek, params.typesala, this.callbackInitUpdate);
        } else {
            this.props.payrollInitFormRequest(this.state.emplpayrEntity.payryear, this.callbackInit);
        }
    }

    render() {

        const { emplpayrEntity, title, snackbar, activeStep, conccode, concdesc, concvalu } = this.state;
        const { loading, list, tables, failed, failedTitle, failedContent } = this.props.humanResourcesReducers;
        const { classes } = this.props;

        return (
            <div style={{overflow: 'auto', height: '100%'}}>
                <Paper className='form__container'>
                    <br/>
                    <Typography variant='h5'>{ title }</Typography>
                    <br/>
                    { failed && <MessageBox title={failedTitle} content={failedContent}/> }
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    {
                        activeStep === 0 &&
                        <form noValidate autoComplete="off">
                            <Grid container justify="center" spacing={2}>
                                <Grid item xs={3}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                        <InputLabel id="lbl_tipo">Tipo</InputLabel>
                                        <Select
                                            labelId="lbl_tipo"
                                            id="typesala"
                                            className='select'
                                            label="Tipo"
                                            fullWidth
                                            value={emplpayrEntity.typesala}
                                            onChange={(e: any) => this.onChangeEmplpayrRoot({'typesala': e.target.value})}
                                        >
                                            {
                                                tables.prl_definiti.filter((x: any) => x.defitype === 'STY').map((item: any, key: any) => 
                                                    <MenuItem value={item.deficode} key={`tipo_${key}`}>{item.definame}</MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                        <InputLabel id="lbl_frecpago">Planilla</InputLabel>
                                        <Select
                                            labelId="lbl_frecpago"
                                            id="payrtype"
                                            className='select'
                                            label="Frecuencia de Pago"
                                            fullWidth
                                            value={emplpayrEntity.payrtype}
                                            onChange={(e: any) => this.onChangeEmplpayrRoot({'payrtype': e.target.value})}
                                        >
                                            {
                                                tables.prl_definiti.filter((x: any) => x.defitype === 'PRT').map((item: any, key: any) => 
                                                    <MenuItem value={item.deficode} key={`frecpago_${key}`}>{item.definame}</MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}></Grid>
                            </Grid>
                            <Grid container justify="center" spacing={2}>
                                <Grid item xs={3}>
                                    <TextField fullWidth id="payryear" label="Año" variant="outlined" value={ emplpayrEntity.payryear } onChange={(e: any) => this.onChangeEmplpayrRoot({'payryear': e.target.value})}/>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth id="payrweek" label="Semana" variant="outlined" value={ emplpayrEntity.payrweek } onBlur={(e: any) => this.onChangeEmplpayrRoot({'payrweek': e.target.value.toString().padStart(2, '0')})} onChange={(e: any) => this.onChangeEmplpayrRoot({'payrweek': e.target.value})} disabled={ emplpayrEntity.payrtype === '102' ? false : true }/>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth id="payrmont" label="Mes" variant="outlined" value={ emplpayrEntity.payrmont } onBlur={(e: any) => this.onChangeEmplpayrRoot({'payrmont': e.target.value.toString().padStart(2, '0')})} onChange={(e: any) => this.onChangeEmplpayrRoot({'payrmont': e.target.value})} disabled={ emplpayrEntity.payrtype === '101' ? false : true }/>
                                </Grid>
                            </Grid>
                            <Grid container justify="center" spacing={2}>
                                <Grid item xs={3}>
                                    <TextField fullWidth type="date" id="stardate"  label="Inicio" variant="outlined" value={ emplpayrEntity.stardate } onChange={(e: any) => this.onChangeEmplpayrRoot({'stardate': e.target.value})} disabled/>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth type="date" id="endidate"  label="Final" variant="outlined" value={ emplpayrEntity.endidate } onChange={(e: any) => this.onChangeEmplpayrRoot({'endidate': e.target.value})} disabled/>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth label="Dias" variant="outlined" value={ moment(emplpayrEntity.endidate).diff(moment(emplpayrEntity.stardate), 'days') + 1 } disabled/>
                                </Grid>
                            </Grid>
                        </form>
                    }
                    {
                        activeStep === 1 &&
                        <form noValidate autoComplete="off">
                            <div className="seccion__title">DEFINICION DE ARCHIVOS DE PLANILLAS</div>
                            <Grid container spacing={2}>
                                <Grid item xs={2}>
                                    <TextField fullWidth id="typesala" label="Tipo de planilla" variant="outlined" value={ tables.prl_definiti.find((x: any) => x.deficode === emplpayrEntity.typesala)?.definame } />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField fullWidth id="payrtype" label="Tipo de trabajador" variant="outlined" value={ tables.prl_definiti.find((x: any) => x.deficode === emplpayrEntity.payrtype)?.definame } />
                                </Grid>
                                <Grid item xs={1}>
                                    <TextField fullWidth id="payryear" label="Ejercicio" variant="outlined" value={ emplpayrEntity.payryear } />
                                </Grid>
                                <Grid item xs={1}>
                                    <TextField fullWidth id="payrweek" label="Semana" variant="outlined" value={ emplpayrEntity.payrweek } />
                                </Grid>
                                <Grid item xs={1}>
                                    <TextField fullWidth id="payrmont" label="Mes" variant="outlined" value={ emplpayrEntity.payrmont } />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField fullWidth type="date" id="stardate"  label="Inicio" variant="outlined" value={ emplpayrEntity.stardate } />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField fullWidth type="date" id="endidate"  label="Final" variant="outlined" value={ emplpayrEntity.endidate } />
                                </Grid>
                            </Grid>
                            {/* <div style={{ height: 50 }}/> */}
                            <div className="seccion__title">DATOS DEL TRABAJADOR</div>
                            <Grid container spacing={2}>
                                <Grid item xs={2}>
                                    <Autocomplete
                                        id="emplcode"
                                        freeSolo
                                        options={tables.prl_employee}
                                        getOptionLabel={ (x: any) => x.emplcode }
                                        value={ tables.prl_employee.find((x: any) => x.emplcode === emplpayrEntity.emplcode) || null }
                                        onChange={(e: any, value) => { 
                                            // corregir asignacion familiar
                                            this.onLoadEmploye(
                                            {   
                                                'emplcode': value.emplcode, 
                                                'nameeess': value.lastname + " " + value.mlstname + " " + value.nameeess,
                                                'basalary': JSON.parse(value.prl_emplrenu.renumber).renumber1,
                                                'renumber2': JSON.parse(value.prl_emplrenu.renumber).renumber2,
                                                'renumber3': JSON.parse(value.prl_emplrenu.renumber).renumber3,
                                                'renumber4': JSON.parse(value.prl_emplrenu.renumber).renumber4,
                                                'renumber5': JSON.parse(value.prl_emplrenu.renumber).renumber5,
                                                'commcode': value.prl_emplojob.commcode,
                                                'typecomm': value.prl_emplojob.typecomm,
                                                'regitype': value.prl_emplojob.regitype,
                                                'asignmen': JSON.parse(value.prl_emplrela.relative).find((x: any) => moment().diff(moment(x.nacimiento), 'years', true) < 18 ) !== undefined ? '520' : '521'
                                            })}
                                        }
                                        renderInput={(params) => (
                                            <TextField {...params} 
                                            style={ { marginTop: 0 } } label="Trabajador" margin="normal" variant="outlined" />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Autocomplete
                                        id="emplname"
                                        freeSolo
                                        options={tables.prl_employee}
                                        getOptionLabel={ (x: any) => x.lastname + " " + x.mlstname + " " + x.nameeess }
                                        value={ tables.prl_employee.find((x: any) => x.emplcode === emplpayrEntity.emplcode) || null }
                                        onChange={(e: any, value) => this.onLoadEmploye(
                                        {   
                                            'emplcode': value.emplcode, 
                                            'nameeess': value.lastname + " " + value.mlstname + " " + value.nameeess,
                                            'basalary': JSON.parse(value.prl_emplrenu.renumber).renumber1,
                                            'renumber2': JSON.parse(value.prl_emplrenu.renumber).renumber2,
                                            'renumber3': JSON.parse(value.prl_emplrenu.renumber).renumber3,
                                            'renumber4': JSON.parse(value.prl_emplrenu.renumber).renumber4,
                                            'renumber5': JSON.parse(value.prl_emplrenu.renumber).renumber5,
                                            'commcode': value.prl_emplojob.commcode,
                                            'typecomm': value.prl_emplojob.typecomm,
                                            'regitype': value.prl_emplojob.regitype,
                                            'asignmen': JSON.parse(value.prl_emplrela.relative).find((x: any) => moment().year() - moment(x.nacimiento).year() <= 18 ) !== undefined ? '520' : '521'
                                        })}
                                        renderInput={(params) => (
                                            <TextField {...params} 
                                            style={ { marginTop: 0 } } label="Trabajador" margin="normal" variant="outlined" />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={2}>
                                    <TextField fullWidth id="basalary" label="Sueldo basico" variant="outlined" value={ emplpayrEntity.basalary }/>
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                        <InputLabel id="lbl_comision">AFP / ONP</InputLabel>
                                        <Select
                                            labelId="lbl_comision"
                                            id="commcode"
                                            className='select'
                                            label="AFP / ONP"
                                            fullWidth
                                            value={emplpayrEntity.commcode || ''}
                                        >
                                            {
                                                JSON.parse((tables.prl_commissi
                                                .find((x: any) => x.commyear === emplpayrEntity.payryear && x.commmont === emplpayrEntity.payrmont) || { commissi: '[]' })
                                                .commissi).map((item: any, key: any) => 
                                                    <MenuItem value={item.codigoafp} key={`comision_${key}`}>{item.nombreafp}</MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                        <InputLabel id="lbl_asignmen">Carga Asig. Familiar</InputLabel>
                                        <Select
                                            labelId="lbl_asignmen"
                                            id="asignmen"
                                            className='select'
                                            label="Carga Asig. Familiar"
                                            fullWidth
                                            value={emplpayrEntity.asignmen || ''}
                                        >
                                            {
                                                tables.prl_definiti.filter((x: any) => x.defitype === 'FCH').map((item: any, key: any) => 
                                                    <MenuItem value={item.deficode} key={`asignmen_${key}`}>{item.definame}</MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            {
                                emplpayrEntity.typesala === '009' &&
                                <>
                                    <Grid container spacing={2}>
                                        <Grid item xs={2}>
                                            <TextField fullWidth type="date" id="dateadmi"  label="Fecha de ingreso" variant="outlined" value={ moment(tables.prl_employee.find((x) => x.emplcode === emplpayrEntity.emplcode)?.prl_emplojob?.dateadmi).format('YYYY-MM-DD') || moment().format('YYYY-MM-01') } />
                                        </Grid>
                                    </Grid>
                                    <div className="seccion__title">SEMESTRES CTS</div>
                                    <Grid container spacing={2}>
                                        <Grid item xs={2}>
                                            <TextField fullWidth type="text" id="tramo2"  label="Semestre 1 CTS" variant="outlined" value={ `Noviembre de ${+emplpayrEntity.payryear - 1}` + ' a ' + `Abril de ${emplpayrEntity.payryear}` } />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField fullWidth type="text" id="tramo1"  label="Semestre 2 CTS" variant="outlined" value={ `Mayo de ${emplpayrEntity.payryear}` + ' a ' + `Octubre de ${emplpayrEntity.payryear}`  } />
                                        </Grid>
                                    </Grid>
                                </>
                            }
                            {/* <div style={{ height: 50 }}/> */}
                            <div className="seccion__title">TAREOS Y CALCULOS DE PLANILLA</div>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <TextField fullWidth id="conccode" label="Concepto" variant="outlined" value={ conccode } onChange={ (e: any) => this.onChangeRoot({conccode: e.target.value}) }/>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth id="concdesc" label="Descripcion" variant="outlined" value={ concdesc }/>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth id="concvalu" label="Valor" variant="outlined" value={ concvalu } onChange={ (e: any) => this.onChangeRoot({concvalu: e.target.value}) } onKeyPress={this.keyPressConcept}/>
                                </Grid>
                            </Grid>
                            <div style={{ height: 50 }}/>
                            {/* <Typography variant='subtitle2'>Conceptos</Typography> */}
                            <br/>
                            <Grid container spacing={2} className="conceptos">
                                <Grid item xs={!(emplpayrEntity.payrtype === '102' && emplpayrEntity.typesala === '001') ? 2 : 4}>
                                    <Grid item xs={12} style={{textAlign: 'center'}}>
                                        <Typography variant="button">VARIABLES</Typography>
                                    </Grid>
                                    <br/>
                                    {
                                        !(emplpayrEntity.payrtype === '102' && emplpayrEntity.typesala === '001') &&
                                        emplpayrEntity.concepts.filter((x: any) => x.conctype === '050').map((item: any, key: any) => 
                                            <React.Fragment key={key}>
                                                <Grid item xs={12}>
                                                    <TextField fullWidth label={item.conccode + ' ' + item.descript} variant="outlined" value={ String.formatNumber.new(+item.outcomee) } />
                                                </Grid>
                                                <br/>
                                            </React.Fragment>
                                        )
                                        ||
                                        <TableContainer component={Paper}>
                                            <Table aria-label="spanning table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="center">J</TableCell>
                                                        <TableCell align="center">V</TableCell>
                                                        <TableCell align="center">S</TableCell>
                                                        <TableCell align="center">L</TableCell>
                                                        <TableCell align="center">M</TableCell>
                                                        <TableCell align="center">M</TableCell>
                                                        <TableCell align="center">TOT</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell align="center">
                                                            <TextField fullWidth label='C101' variant="outlined" value={ String.formatNumber.new(+emplpayrEntity.concepts.find((x: any) => x.conctype === '050' && x.conccode === 'C101')?.outcomee) } />
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <TextField fullWidth label='C102' variant="outlined" value={ String.formatNumber.new(+emplpayrEntity.concepts.find((x: any) => x.conctype === '050' && x.conccode === 'C102')?.outcomee) } />
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <TextField fullWidth label='C103' variant="outlined" value={ String.formatNumber.new(+emplpayrEntity.concepts.find((x: any) => x.conctype === '050' && x.conccode === 'C103')?.outcomee) } />
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <TextField fullWidth label='C104' variant="outlined" value={ String.formatNumber.new(+emplpayrEntity.concepts.find((x: any) => x.conctype === '050' && x.conccode === 'C104')?.outcomee) } />
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <TextField fullWidth label='C105' variant="outlined" value={ String.formatNumber.new(+emplpayrEntity.concepts.find((x: any) => x.conctype === '050' && x.conccode === 'C105')?.outcomee) } />
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <TextField fullWidth label='C106' variant="outlined" value={ String.formatNumber.new(+emplpayrEntity.concepts.find((x: any) => x.conctype === '050' && x.conccode === 'C106')?.outcomee) } />
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <TextField fullWidth label='C107' variant="outlined" value={ String.formatNumber.new(+emplpayrEntity.concepts.find((x: any) => x.conctype === '050' && x.conccode === 'C107')?.outcomee) } />
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="center">
                                                            <TextField fullWidth label='C108' variant="outlined" value={ String.formatNumber.new(+emplpayrEntity.concepts.find((x: any) => x.conctype === '050' && x.conccode === 'C108')?.outcomee) } />
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <TextField fullWidth label='C109' variant="outlined" value={ String.formatNumber.new(+emplpayrEntity.concepts.find((x: any) => x.conctype === '050' && x.conccode === 'C109')?.outcomee) } />
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <TextField fullWidth label='C110' variant="outlined" value={ String.formatNumber.new(+emplpayrEntity.concepts.find((x: any) => x.conctype === '050' && x.conccode === 'C110')?.outcomee) } />
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <TextField fullWidth label='C111' variant="outlined" value={ String.formatNumber.new(+emplpayrEntity.concepts.find((x: any) => x.conctype === '050' && x.conccode === 'C111')?.outcomee) } />
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <TextField fullWidth label='C112' variant="outlined" value={ String.formatNumber.new(+emplpayrEntity.concepts.find((x: any) => x.conctype === '050' && x.conccode === 'C112')?.outcomee) } />
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <TextField fullWidth label='C113' variant="outlined" value={ String.formatNumber.new(+emplpayrEntity.concepts.find((x: any) => x.conctype === '050' && x.conccode === 'C113')?.outcomee) } />
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <TextField fullWidth label='C114' variant="outlined" value={ String.formatNumber.new(+emplpayrEntity.concepts.find((x: any) => x.conctype === '050' && x.conccode === 'C114')?.outcomee) } />
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="center">
                                                            <TextField fullWidth label='C115' variant="outlined" value={ String.formatNumber.new(+emplpayrEntity.concepts.find((x: any) => x.conctype === '050' && x.conccode === 'C115')?.outcomee) } />
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <TextField fullWidth label='C116' variant="outlined" value={ String.formatNumber.new(+emplpayrEntity.concepts.find((x: any) => x.conctype === '050' && x.conccode === 'C116')?.outcomee) } />
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <TextField fullWidth label='C117' variant="outlined" value={ String.formatNumber.new(+emplpayrEntity.concepts.find((x: any) => x.conctype === '050' && x.conccode === 'C117')?.outcomee) } />
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <TextField fullWidth label='C118' variant="outlined" value={ String.formatNumber.new(+emplpayrEntity.concepts.find((x: any) => x.conctype === '050' && x.conccode === 'C118')?.outcomee) } />
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <TextField fullWidth label='C119' variant="outlined" value={ String.formatNumber.new(+emplpayrEntity.concepts.find((x: any) => x.conctype === '050' && x.conccode === 'C119')?.outcomee) } />
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <TextField fullWidth label='C120' variant="outlined" value={ String.formatNumber.new(+emplpayrEntity.concepts.find((x: any) => x.conctype === '050' && x.conccode === 'C120')?.outcomee) } />
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <TextField fullWidth label='C121' variant="outlined" value={ String.formatNumber.new(+emplpayrEntity.concepts.find((x: any) => x.conctype === '050' && x.conccode === 'C121')?.outcomee) } />
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="center" colSpan={2}>
                                                            <TextField fullWidth label='C122 Dom' variant="outlined" value={ String.formatNumber.new(+emplpayrEntity.concepts.find((x: any) => x.conctype === '050' && x.conccode === 'C122')?.outcomee) } />
                                                        </TableCell>
                                                        <TableCell align="center" colSpan={2}>
                                                            <TextField fullWidth label='C123 Fer' variant="outlined" value={ String.formatNumber.new(+emplpayrEntity.concepts.find((x: any) => x.conctype === '050' && x.conccode === 'C123')?.outcomee) } />
                                                        </TableCell>
                                                        <TableCell align="center" colSpan={2}>
                                                            <TextField fullWidth label='C124 Hs.Nct' variant="outlined" value={ String.formatNumber.new(+emplpayrEntity.concepts.find((x: any) => x.conctype === '050' && x.conccode === 'C124')?.outcomee) } />
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    }
                                </Grid>
                                <Grid item xs={2}>
                                    <Grid item xs={12} style={{textAlign: 'center'}}>
                                        <Typography variant="button">INGRESOS</Typography>
                                    </Grid>
                                    <br/>
                                    {
                                        emplpayrEntity.concepts.filter((x: any) => x.conctype === '051').map((item: any, key: any) => 
                                            <React.Fragment key={key}>
                                                <Grid item xs={12}>
                                                    <TextField fullWidth label={item.conccode + ' ' + item.descript} variant="outlined" value={ String.formatNumber.new(+item.outcomee) } />
                                                </Grid>
                                                <br/>
                                            </React.Fragment>
                                        )
                                    }
                                </Grid>
                                <Grid item xs={2} >
                                    <Grid item xs={12} style={{textAlign: 'center'}}>
                                        <Typography variant="button">DESCUENTOS</Typography>
                                    </Grid>
                                    <br/>
                                    {
                                        emplpayrEntity.concepts.filter((x: any) => x.conctype === '052').map((item: any, key: any) => 
                                            <React.Fragment key={key}>
                                                <Grid item xs={12}>
                                                    <TextField fullWidth label={item.conccode + ' ' + item.descript} variant="outlined" value={ String.formatNumber.new(+item.outcomee) } />
                                                </Grid>
                                                <br/>
                                            </React.Fragment>
                                        )
                                    }
                                </Grid>
                                <Grid item xs={2}>
                                    <Grid item xs={12} style={{textAlign: 'center'}}>
                                        <Typography variant="button">APORTACIONES</Typography>
                                    </Grid>
                                    <br/>
                                    {
                                        emplpayrEntity.concepts.filter((x: any) => x.conctype === '053').map((item: any, key: any) => 
                                            <React.Fragment key={key}>
                                                <Grid item xs={12}>
                                                    <TextField fullWidth label={item.conccode + ' ' + item.descript} variant="outlined" value={ String.formatNumber.new(+item.outcomee) } />
                                                </Grid>
                                                <br/>
                                            </React.Fragment>
                                        )
                                    }
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <Grid item xs={12} style={{textAlign: 'center'}}>
                                        <Typography variant="button">TOTALES</Typography>
                                    </Grid>
                                    <br/>
                                    {
                                        emplpayrEntity.concepts.filter((x: any) => x.conctype === '054').map((item: any, key: any) => 
                                            <React.Fragment key={key}>
                                                <Grid item xs={12}>
                                                    <TextField fullWidth label={item.conccode + ' ' + item.descript} variant="outlined" value={ String.formatNumber.new(+item.outcomee) } />
                                                </Grid>
                                                <br/>
                                            </React.Fragment>
                                        )
                                    }
                                </Grid>
                            </Grid>
                        </form>
                    }
                    <br/>
                    <FormFooterSteps 
                        activeStep={ activeStep }
                        lastStep={ 1 }
                        onBack={ () => this.onChangeRoot({activeStep: 0}) } 
                        onNext={ () => this.onChangeRoot({activeStep: 1}) } 
                        onCancel={ this.onCancel } 
                        onSubmitContinue={ this.onSubmitContinue } 
                        onSubmit={ this.onSubmit }
                    />
                </Paper>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={snackbar}
                    onClose={() => this.setState({snackbar: false})}
                    autoHideDuration={6000}
                >
                    <Alert onClose={() => this.setState({snackbar: false})} severity="success">
                            Datos Guardados Correctamente
                    </Alert>
                </Snackbar>
                <ScreenLoader enabled={loading}/>
            </div>
        );
    }
}

const humanResourcesReducers = 'humanResourcesReducers';
const generalReducers = 'generalReducers';
const dashboardReducers = 'dashboardReducers';

const mapStateToProps = (state: any) => ({
    humanResourcesReducers: state.get(humanResourcesReducers),
    generalReducers: state.get(generalReducers),
    dashboardReducers: state.get(dashboardReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        payrollInitFormRequest,
        payrollInitUpdateFormRequest,
        payrollInsertRequest,
        payrollUpdateRequest
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(FormPayroll));