import {
    call, fork, put, take, takeEvery, all
  } from 'redux-saga/effects';
import pipelines from 'services';
import { 
    currencyExchangeRateRequestFailed,
    currencyExchangeRateRequestSuccess,
    locationsGetDepartmentsFailed, 
    locationsGetDepartmentsSuccess, 
    locationsGetDistrictsFailed, 
    locationsGetDistrictsSuccess, 
    locationsGetProvincesFailed, 
    locationsGetProvincesSuccess, 
    masterTablesRequestFailed, 
    masterTablesRequestSuccess
} from 'penta-redux/actions/generalActions';
import { 
    CURRENCY_EXCHANGE_RATE_REQUEST,
    LOCATIONS_GET_DEPARTMENTS_REQUEST, 
    LOCATIONS_GET_DISTRICT_REQUEST, 
    LOCATIONS_GET_PROVINCES_REQUEST, 
    MASTER_TABLES_REQUEST
} from 'penta-redux/constants/generalConstants';

function* locationGetDepartmentsSaga(action: any) {
    try {
        const data = (yield call(pipelines.generalPipeline.locationGetDepartments)).data;
        yield put(locationsGetDepartmentsSuccess(data.data.tables));
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(locationsGetDepartmentsFailed(`Error: ${data.code}`, data.message));
    }
}

function* locationGetProvincesSaga(action: any) {
    try {
        const data = (yield call(pipelines.generalPipeline.locationGetProvinces, action.locacode)).data;
        yield put(locationsGetProvincesSuccess(data.data.tables));
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(locationsGetProvincesFailed(`Error: ${data.code}`, data.message));
    }
}

function* locationGetDistrictsSaga(action: any) {
    try {
        const data = (yield call(pipelines.generalPipeline.locationGetDistricts, action.locacode)).data;
        yield put(locationsGetDistrictsSuccess(data.data.tables));
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(locationsGetDistrictsFailed(`Error: ${data.code}`, data.message));
    }
}

function* currencyExchangeRateSaga(action: any) {
    try {
        const data = (yield call(pipelines.generalPipeline.currencyExchangeRate, action.datetime)).data;
        yield put(currencyExchangeRateRequestSuccess(data.data.tables));
        yield call(action.callback);
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(currencyExchangeRateRequestFailed(`Error: ${data.code}`, data.message));
        yield call(action.callback);
    }
}

function* masterTablesSaga(action: any) {
    try {
        const data = (yield call(pipelines.generalPipeline.masterTables, action.option)).data;
        yield put(masterTablesRequestSuccess(data.data.onlyOne));
        yield call(action.callback);
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(masterTablesRequestFailed(`Error: ${err.code}`, err.message));
    }
}

function* authRootSaga() {
    yield all([
      takeEvery(LOCATIONS_GET_DEPARTMENTS_REQUEST, locationGetDepartmentsSaga),
      takeEvery(LOCATIONS_GET_PROVINCES_REQUEST, locationGetProvincesSaga),
      takeEvery(LOCATIONS_GET_DISTRICT_REQUEST, locationGetDistrictsSaga),
      takeEvery(CURRENCY_EXCHANGE_RATE_REQUEST, currencyExchangeRateSaga),
      takeEvery(MASTER_TABLES_REQUEST, masterTablesSaga),
    ]);
}
  
const authSagas = [
    fork(authRootSaga),
];
  
export default authSagas;