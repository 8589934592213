import { authInitialState } from 'models/statesModels';
import { AUTH_LOGIN_REQUEST, AUTH_LOGIN_REQUEST_FAILED, AUTH_LOGIN_REQUEST_SUCCESS, AUTH_LOGOUT_REQUEST } from '../constants/authConstants';

const initialState: authInitialState = {
    loggedIn: false,
    token: localStorage.getItem('token') || '',
    user: JSON.parse(localStorage.getItem('user')) || null,
    tables: JSON.parse(localStorage.getItem('tables')) || null,
    loading: false,
    failed: false,
    failedTitle: null,
    failedContent: null,
    currentCompany: '',
    currentDivision: '',
    currentBussOfic: ''
};

export default function authReducer(state = initialState, action: any = {}) {
    switch(action.type) {
        case AUTH_LOGIN_REQUEST:
            return {
                ...state,
                loading: true
            };
        case AUTH_LOGIN_REQUEST_SUCCESS:
            return {
                ...state,
                loggedIn: true,
                user: JSON.parse(localStorage.getItem('user')),
                token: localStorage.getItem('token'),
                tables: JSON.parse(localStorage.getItem('tables')),
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case AUTH_LOGIN_REQUEST_FAILED:
            return {
                loggedIn: false,
                token: '',
                user: null,
                tables: null,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case AUTH_LOGOUT_REQUEST:
            return initialState
        default:
            return state;
    }
}