import React, { MouseEvent, ReactInstance, useState } from "react";
import ReactDOM from "react-dom";
import { TweenMax } from "gsap";
import { createUseStyles } from "react-jss";
// import './base.scss';

const useStyles = createUseStyles({
    fullContainer: {},
    container: {
        'position': 'relative',
        'display': 'inline-block',
        'verticalAlign': 'middle',
        'cursor': 'pointer',
        'width': '7.813rem',
        'height':'1.875rem',
        '&$fullContainer': {
            'width': '100%'
        }
    },
    primary: {},
    secundary: {},
    bad: {},
    button: {
        'outline': 'none',
        'boxShadow': '0rem 0.125rem 0.25rem -0.063rem rgb(0 0 0 / 20%), 0rem 0.25rem 0.313rem 0rem rgb(0 0 0 / 14%), 0rem 0.063rem 0.625rem 0rem rgb(0 0 0 / 12%)',
        'textTransform': 'uppercase',
        'cursor': 'pointer',
        'fontFamily': '"Work Sans", sans-serif',
        'fontWeight': 700,
        'lineHeight': 1.75,
        'borderRadius': '0.25rem',
        'width': '100%',
        'height':'100%',
        // 'letterSpacing': '0.02857em',
        // 'padding': '0.625rem 0.938rem',
        'fontSize': '0.75rem',
        '&$primary': {
            'color': 'white',
            'border': '0.063rem solid #0060A6',
            'backgroundColor': '#0060A6',
            '&:hover': {
                'backgroundColor': '#0055a6',
                'border': '0.063rem solid #0055a6'
            },
        },
        '&$secundary': {
            'color': '#0060A6',
            'border': '0.063rem solid #0060A6',
            'backgroundColor': 'white',
            '&:hover': {
                'backgroundColor': '#f3f3f3',
                'border': '0.063rem solid #0055a6'
            },
        },
        '&$bad': {
            'color': 'white',
            'border': '0.063rem solid #E26464',
            'backgroundColor': '#E26464',
            '&:hover': {
                'backgroundColor': '#de5151',
                'border': '0.063rem solid #de5151'
            },
        }
    },
    svg: {
        'height': '100%',
        'pointerEvents': 'none',
        'position': 'absolute',
        'top': 0,
        'left': 0,
        'width': '100%',
        'z-index': 0,
        'fill': 'rgba(255,255,255,.3)'
    }
})

interface Button {
    type?: 'primary' | 'secundary' | 'bad'
    full?: boolean
    // size: 'medium' | 'small' | 'giant'
    className?: string
    containerStyle?: React.CSSProperties
    children?: React.ReactNode,
    onClick?: (event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => void
}

const Button = (props: Button) => {
    const classes = useStyles();
    const ripple = React.useRef(null);
    const [width, setWidth] = useState<number>(0);
    const [height, setHeight] = useState<number>(0);

    const handleClick = (event) => {
        props.onClick(event);
        const elem       = ripple.current,
            x            = event.nativeEvent.offsetX,
            y            = event.nativeEvent.offsetY,
            w            = event.target.offsetWidth,
            h            = event.target.offsetHeight,
            offsetX      = Math.abs( (w / 2) - x ),
            offsetY      = Math.abs( (h / 2) - y ),
            deltaX       = (w / 2) + offsetX,
            deltaY       = (h / 2) + offsetY,
            scaleRatio   = Math.sqrt(Math.pow(deltaX, 2) + Math.pow(deltaY, 2))

        setWidth(w);
        setHeight(h);

        TweenMax.fromTo(elem, 0.5, {
            x: x,
            y: y,
            transformOrigin: '50% 50%',
            scale: 0,
            opacity: 1
        }, {
            scale: scaleRatio,
            opacity: 0
        })
    }

    // const className = 'form ' + this.props.size + ' ' + this.props.type + ' ' + (this.props.className || '');
    return (
        <div className={`${classes.container} ${props.full ? classes.fullContainer : ''}`} style={props.containerStyle} onClick={handleClick}>
            <button className={`${classes.button} ${classes[props.type]}`}>{props.children || ''}</button>
            <svg viewBox={`0 0 ${width} ${height}`} className={classes.svg}>
                <circle ref={ripple} cx="1" cy="1" r="1"/>
            </svg>
        </div>
    );
}

Button.defaultProps = {
    type: 'primary',
    full: true,
    containerStyle: {}
}

export default Button;