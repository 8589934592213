import React from 'react';
import { Backdrop, Button, ButtonGroup, Checkbox, CircularProgress, createStyles, FormControl, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Select, TextField, Theme, Tooltip, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { cashBookBanksRequest } from 'penta-redux/actions/accountantActions';
import history from '../../../../utils/history';
import './../reports.scss'
import FormFooter from 'penta-components/base/formFooter';
import { MessageBox } from 'penta-components/base/messageBox';
import { categoryEntity } from 'models/wharehouseProductsModels';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PdfView from 'penta-components/base/pdfView';
// import * as pdfMake from "pdfmake/build/pdfmake";
// import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
import * as XLSX from 'sheetjs-style';
import ScreenLoader from 'penta-components/base/screenLoader';

let pdfMake = require("pdfmake/build/pdfmake");
let saveAs = require('file-saver').saveAs;

const meses = [
    { key: '01', value: 'Enero' },
    { key: '02', value: 'Febrero' },
    { key: '03', value: 'Marzo' },
    { key: '04', value: 'Abril' },
    { key: '05', value: 'Mayo' },
    { key: '06', value: 'Junio' },
    { key: '07', value: 'Julio' },
    { key: '08', value: 'Agosto' },
    { key: '09', value: 'Septiembre' },
    { key: '10', value: 'Octubre' },
    { key: '11', value: 'Noviembre' },
    { key: '12', value: 'Diciembre' }
]

const useStyles = (theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        formControl: {
            margin: 0,
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    });

interface FormcategorysState {
    title: string
    year: string
    month: string,
    typecsbk: string
    base64Pdf: string
}

class ReportCashBookBanks extends React.Component<any, FormcategorysState> {

    constructor(props: any) {
        super(props);
        this.state = {
            title: 'Reporte de Caja Banco',
            year: moment().format('yyyy').toString(),
            month: moment().format('MM').toString(),
            typecsbk: 'TCSBK001',
            base64Pdf: ''
        }
    }

    onChangeRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            ...value
        }))
    }

    onGeneratePdf = () => {
        const { CashBookBanks, GeneralBalancesAccount } = this.props.accountantReducers.onlyOne;
        const { month, year, typecsbk } = this.state;
        const { dashboardReducers } = this.props;
        const mes = meses.find((x: any) => x.key === month).value;
        const tables = JSON.parse(localStorage.getItem('tables'));
        const company = tables.companys.find((x: any) => x.taxident === dashboardReducers.currentCompany);
        const division = company.lst_grl_division.find((x: any) => x.divicode === dashboardReducers.currentDivision);
        const oficina = division.lst_grl_bussofic.find((x: any) => x.officode === dashboardReducers.currentBussOfic);
        const date = new Date(parseInt(year), parseInt(month) - 1, 1);
        const stardate = moment(date).format('DD-MM-YYYY');
        const endidate = moment(date).daysInMonth() + moment(date).format('-MM-') + moment(date).format('YYYY');

        const pdfObject: any = {
            pageSize: 'A4',
            pageMargins: [ 20, 90, 20, 20 ],
            header: [
                {
                    margin: [20, 20, 20, 0],
                    text: 'LIBRO CAJA',
                    style: {
                        bold: true,
                        fontSize: 9,
                        alignment: 'center', 
                        lineHeight: 3
                    }
                },
                {
                    margin: [20, 0, 20, 0],
                    columns: [
                        {
                            stack: [
                                {
                                    columns: [
                                        {
                                            width: 50,
                                            text: 'PERIODO:',
                                            style: 'headerTitle'
                                        },
                                        {
                                            width: 500,
                                            text: `DEL ${stardate} AL ${endidate}`,
                                            style: 'headerTitle'
                                        }
                                    ]
                                },
                                {
                                    columns: [
                                        {
                                            width: 50,
                                            text: 'RUC:',
                                            style: 'headerTitle'
                                        },
                                        {
                                            width: 500,
                                            text: dashboardReducers.currentCompany,
                                            style: 'headerTitle'
                                        }
                                    ]
                                },
                                {
                                    columns: [
                                        {
                                            width: 50,
                                            text: 'EMPRESA:',
                                            style: 'headerTitle'
                                        },
                                        {
                                            width: 500,
                                            text: company.bussname.toUpperCase(),
                                            style: 'headerTitle'
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ],
            content: [
                {
                    table: {
                        headerRows: 2,
                        widths: [],
                        body: []
                    }
                },
            ],
            styles: {
                headerTitle: {
                    bold: true,
                    fontSize: 7,
                    lineHeight: 1.2
                },
                table: {
                    layout: 'headerLineOnly',
                    fontSize: 6,
                    alignment: 'center'
                },
                subTable: {
                    layout: 'headerLineOnly',
                    fontSize: 6,
                    alignment: 'left'
                },
                tableDetailLeft: {
                    layout: 'headerLineOnly',
                    fontSize: 5.5,
                    alignment: 'left'
                },
                tableDetailRight: {
                    layout: 'headerLineOnly',
                    fontSize: 5.5,
                    alignment: 'right'
                },
                tableDetailRightBold: {
                    layout: 'headerLineOnly',
                    fontSize: 5.5,
                    alignment: 'right',
                    bold: true
                }
            },
            defaultStyle: {
                columnGap: 20,
            },
        }

        const unicodopRead: any = [];

        if(typecsbk === 'TCSBK001') {
            pdfObject.content[0].table.widths = [ 40, 35, 180, 30, 130, 40, 40 ];

            const tableBody: any = [
                [ 
                    { text: 'NUM CORREL. DEL ASIENTO O COD UNICO DE LA OPERAC.', style: 'table', rowSpan: 2, border: [true, true, false, true] },
                    { text: '\n\nFECHA', style: 'table', rowSpan: 2, border: [true, true, false, true] },
                    { text: '\n\nGLOSA O DESCRIPCION DE LA OPERAC.', style: 'table', rowSpan: 2, border: [true, true, false, true] },
                    { text: 'CUENTA CONTABLE ASOCIADA A LA OPERACION', style: 'table', colSpan: 2, border: [true, true, true, true] },{},
                    { text: 'MOVIMIENTO', style: 'table', colSpan: 2, border: [true, true, true, true] },{},
                ],
                [ 
                    { text: '', style: 'table', border: [true, false, true, true] },
                    { text: '', style: 'table', border: [false, false, true, true] },
                    { text: '', style: 'table', border: [false, false, true, true] },
                    { text: '\nCODIGO', style: 'table', border: [true, false, true, true] },
                    { text: '\nDENOMINACION', style: 'table', border: [false, false, true, true] },
                    { text: '\nDEBE.', style: 'table', border: [false, false, true, true] },
                    { text: '\nHABER', style: 'table', border: [false, false, true, true] },
                ]
            ]

            for (const iterator of CashBookBanks) {
                if (!unicodopRead.includes(iterator.idcsbank)) {
                    const rows = CashBookBanks.filter((x: any) => x.idcsbank === iterator.idcsbank);
                    const saleinit = GeneralBalancesAccount.find(x => x.accaccou === iterator.bkaccacc)?.totalacc || '0.00'
                    let chargamo =  +saleinit >= 0 ? +saleinit : 0.00;
                    let crediamo = +saleinit < 0 ? +saleinit : 0.00;

                    tableBody.push(
                        [ 
                            { text: iterator.csbkname, style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                            { text: rows.length > 0 ? '01/' + moment(rows[0].dateoper).format('MM/YYYY') : '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                            { text: 'Saldo inicial', style: 'tableDetailLeft', colSpan: 3, border: [false, false, false, false] },{},{},
                            { text: +saleinit >= 0 ? String.formatNumber.new(saleinit) : '', style: 'tableDetailRight', border: [false, false, false, false] },
                            { text: +saleinit <  0 ? String.formatNumber.new(saleinit * -1) : '', style: 'tableDetailRight', border: [false, false, false, false] },
                        ],
                    )

                    for (const row of rows) {
                        tableBody.push(
                            [ 
                                { text: row.unicodop, style: 'tableDetailLeft', border: [false, false, false, false] },
                                { text: moment(row.dateoper).format('DD/MM/YYYY'), style: 'tableDetailLeft', border: [false, false, false, false] },
                                { text: row.operglos.substr(0, 50), style: 'tableDetailLeft', border: [false, false, false, false] },
                                { text: row.accaccou, style: 'tableDetailLeft', border: [false, false, false, false] },
                                { text: row.denomina.substr(0, 30), style: 'tableDetailLeft', border: [false, false, false, false] },
                                { text: +row.chargamo > 0 ? String.formatNumber.new(row.chargamo) : '', style: 'tableDetailRight', border: [false, false, false, false] },
                                { text: +row.crediamo > 0 ? String.formatNumber.new(row.crediamo) : '', style: 'tableDetailRight', border: [false, false, false, false] },
                            ]
                        )
                        chargamo += +row.chargamo;
                        crediamo += +row.crediamo;
                    }

                    const diff = chargamo - crediamo;
                    chargamo += diff < 0 ? Math.abs(diff) : 0;
                    crediamo += diff > 0 ? Math.abs(diff) : 0;

                    tableBody.push(
                        [ 
                            { text: '', border: [false, false, false, false] },
                            { text: '', border: [false, false, false, false] },
                            { text: '', border: [false, false, false, false] },
                            { text: '', border: [false, false, false, false] },
                            { text: 'SALDO FINAL', style: 'tableDetailLeft', border: [false, false, false, false] },
                            { text: diff < 0 ? String.formatNumber.new(Math.abs(diff)) : '', style: 'tableDetailRightBold', border: [false, false, false, false] },
                            { text: diff > 0 ? String.formatNumber.new(Math.abs(diff)) : '', style: 'tableDetailRightBold', border: [false, false, false, false] },
                        ]
                    )

                    tableBody.push(
                        [ 
                            { text: '', border: [false, false, false, false] },
                            { text: '', border: [false, false, false, false] },
                            { text: '', border: [false, false, false, false] },
                            { text: '', border: [false, false, false, false] },
                            { text: 'TOTALES', style: 'tableDetailLeft', border: [false, false, false, false] },
                            { text: String.formatNumber.new(chargamo), style: 'tableDetailRightBold', border: [false, false, false, false] },
                            { text: String.formatNumber.new(crediamo), style: 'tableDetailRightBold', border: [false, false, false, false] },
                        ]
                    )

                    unicodopRead.push(iterator.idcsbank);
                }
            }

            pdfObject.content[0].table.body = tableBody;
        } else {

            pdfObject.pageOrientation = 'landscape';
            pdfObject.header[0].text = 'LIBRO BANCO';
            pdfObject.content[0].table.widths = [ 40, 35, 30, 110, 150, 110, 30, 130, 40, 40 ];

            const tableBody: any = [
                [ 
                    { text: 'NUM CORREL. DEL ASIENTO O COD UNICO DE LA OPERAC.', style: 'table', rowSpan: 2, border: [true, true, false, true] },
                    { text: '\n\nFECHA', style: 'table', rowSpan: 2, border: [true, true, false, true] },
                    { text: 'OPERACIONES BANCARIAS', style: 'table', colSpan: 4, border: [true, true, false, true] },{},{},{},
                    { text: 'CUENTA CONTABLE ASOCIADA A LA OPERACION', style: 'table', colSpan: 2, border: [true, true, true, true] },{},
                    { text: 'MOVIMIENTO', style: 'table', colSpan: 2, border: [true, true, true, true] },{},
                ],
                [ 
                    { text: '', style: 'table', border: [true, false, true, true] },
                    { text: '', style: 'table', border: [false, false, true, true] },
                    { text: 'MEDIO DE PAGO', style: 'table', border: [true, false, true, true] },
                    { text: '\nDESCRIPCION DE LA OPERACION', style: 'table', border: [false, false, true, true] },
                    { text: 'APELLIDOS Y NOMBRES, DENOMINACION, RAZON SOCIAL', style: 'table', border: [false, false, true, true] },
                    { text: 'NÚMERO DE TRANSACCIÓN BANCARIA, DE DOCUMENTO SUSTENTATORIO O DE CONTROL INTERNO DE LA OPERACIÓN', style: 'table', border: [false, false, true, true] },
                    
                    { text: '\nCODIGO', style: 'table', border: [true, false, true, true] },
                    { text: '\nDENOMINACION', style: 'table', border: [false, false, true, true] },
                    
                    { text: '\nDEBE.', style: 'table', border: [false, false, true, true] },
                    { text: '\nHABER', style: 'table', border: [false, false, true, true] },
                ],
            ]

            for (const iterator of CashBookBanks) {
                if (!unicodopRead.includes(iterator.idcsbank)) {
                    const rows = CashBookBanks.filter((x: any) => x.idcsbank === iterator.idcsbank);
                    const saleinit = GeneralBalancesAccount.find(x => x.accaccou === iterator.bkaccacc)?.totalacc || '0.00'
                    let chargamo =  +saleinit >= 0 ? +saleinit : 0.00;
                    let crediamo = +saleinit < 0 ? +saleinit : 0.00;

                    tableBody.push(
                        [ 
                            { text: iterator.csbkname, style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                            { text: rows.length > 0 ? '01/' + moment(rows[0].dateoper).format('MM/YYYY') : '', style: 'tableDetailLeft', colSpan: 3, border: [false, false, false, false] },{},{},
                            { text: 'Saldo inicial', style: 'tableDetailLeft', colSpan: 4, border: [false, false, false, false] },{},{},{},
                            { text: +saleinit >= 0 ? String.formatNumber.new(saleinit) : '', style: 'tableDetailRight', border: [false, false, false, false] },
                            { text: +saleinit <  0 ? String.formatNumber.new(saleinit * -1) : '', style: 'tableDetailRight', border: [false, false, false, false] },
                        ],
                    )

                    for (const row of rows) {
                        tableBody.push(
                            [ 
                                { text: row.unicodop, style: 'tableDetailLeft', border: [false, false, false, false] },
                                { text: moment(row.dateoper).format('DD/MM/YYYY'), style: 'tableDetailLeft', border: [false, false, false, false] },
                                { text: '', style: 'tableDetailLeft', border: [false, false, false, false] },
                                { text: row.operglos.substr(0, 50), style: 'tableDetailLeft', border: [false, false, false, false] },
                                { text: row.bussinam.substr(0, 50), style: 'tableDetailLeft', border: [false, false, false, false] },
                                { text: row.sustnumb.substr(0, 50), style: 'tableDetailLeft', border: [false, false, false, false] },
                                { text: row.accaccou, style: 'tableDetailLeft', border: [false, false, false, false] },
                                { text: row.denomina.substr(0, 30), style: 'tableDetailLeft', border: [false, false, false, false] },
                                { text: +row.chargamo > 0 ? String.formatNumber.new(row.chargamo) : '', style: 'tableDetailRight', border: [false, false, false, false] },
                                { text: +row.crediamo > 0 ? String.formatNumber.new(row.crediamo) : '', style: 'tableDetailRight', border: [false, false, false, false] },
                            ]
                        )
                        chargamo += +row.chargamo;
                        crediamo += +row.crediamo;
                    }

                    const diff = chargamo - crediamo;
                    chargamo += diff < 0 ? Math.abs(diff) : 0;
                    crediamo += diff > 0 ? Math.abs(diff) : 0;

                    tableBody.push(
                        [ 
                            { text: '', border: [false, false, false, false] },
                            { text: '', border: [false, false, false, false] },
                            { text: '', border: [false, false, false, false] },
                            { text: '', border: [false, false, false, false] },
                            { text: '', border: [false, false, false, false] },
                            { text: '', border: [false, false, false, false] },
                            { text: '', border: [false, false, false, false] },
                            { text: 'SALDO FINAL', style: 'tableDetailLeft', border: [false, false, false, false] },
                            { text: diff < 0 ? String.formatNumber.new(Math.abs(diff)) : '', style: 'tableDetailRightBold', border: [false, false, false, false] },
                            { text: diff > 0 ? String.formatNumber.new(Math.abs(diff)) : '', style: 'tableDetailRightBold', border: [false, false, false, false] },
                        ]
                    )

                    tableBody.push(
                        [ 
                            { text: '', border: [false, false, false, false] },
                            { text: '', border: [false, false, false, false] },
                            { text: '', border: [false, false, false, false] },
                            { text: '', border: [false, false, false, false] },
                            { text: '', border: [false, false, false, false] },
                            { text: '', border: [false, false, false, false] },
                            { text: '', border: [false, false, false, false] },
                            { text: 'TOTALES', style: 'tableDetailLeft', border: [false, false, false, false] },
                            { text: String.formatNumber.new(chargamo), style: 'tableDetailRightBold', border: [false, false, false, false] },
                            { text: String.formatNumber.new(crediamo), style: 'tableDetailRightBold', border: [false, false, false, false] },
                        ]
                    )

                    unicodopRead.push(iterator.idcsbank);
                }
            }

            pdfObject.content[0].table.body = tableBody;
        }

        pdfMake.fonts = {
            Roboto: {
                normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
                bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
                italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
                bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
              },
        }
        const pdfDocGenerator = pdfMake.createPdf(pdfObject);
        pdfDocGenerator.getDataUrl((data: any) => {
            // console.log(data);
            this.setState({
                base64Pdf: data
            })
        });

    }

    onGenerateExcel = () => {
        try {
            const { CashBookBanks, GeneralBalancesAccount } = this.props.accountantReducers.onlyOne;
            const { month, year, typecsbk } = this.state;
            const { dashboardReducers } = this.props;
            const mes = meses.find((x: any) => x.key === month).value;
            const tables = JSON.parse(localStorage.getItem('tables'));
            const company = tables.companys.find((x: any) => x.taxident === dashboardReducers.currentCompany);
            const division = company.lst_grl_division.find((x: any) => x.divicode === dashboardReducers.currentDivision);
            const oficina = division.lst_grl_bussofic.find((x: any) => x.officode === dashboardReducers.currentBussOfic);
            const date = new Date(parseInt(year), parseInt(month) - 1, 1);
            const stardate = moment(date).format('DD-MM-YYYY');
            const endidate = moment(date).daysInMonth() + moment(date).format('-MM-') + moment(date).format('YYYY');

            const unicodopRead: any = [];

            if(typecsbk === 'TCSBK001') {

                const tableBody: any = [];

                let rowIndex = 1;
                const headerIndex: any = [];

                for (const iterator of CashBookBanks) {
                    if (!unicodopRead.includes(iterator.idcsbank)) {
                        const rows = CashBookBanks.filter((x: any) => x.idcsbank === iterator.idcsbank);
                        const saleinit = GeneralBalancesAccount.find(x => x.accaccou === iterator.bkaccacc)?.totalacc || '0.00';
                        let chargamo = +saleinit >= 0 ? +saleinit : 0.00;
                        let crediamo = +saleinit < 0 ? +saleinit * -1 : 0.00;

                        headerIndex.push(rowIndex);

                        tableBody.push(
                            { 
                                A: null,
                                B: null,
                                C: 'LIBRO CAJA',
                                D: null,
                                E: null,
                                F: null,
                                G: null
                            }
                        );

                        tableBody.push(
                            { 
                                A: null,
                                B: null,
                                C: null,
                                D: null,
                                E: null,
                                F: null,
                                G: null
                            }
                        );

                        tableBody.push(
                            { 
                                A: `PERIODO DEL ${stardate} AL ${endidate}`,
                                B: null,
                                C: null,
                                D: null,
                                E: null,
                                F: null,
                                G: null
                            }
                        );

                        tableBody.push(
                            { 
                                A: `RUC: ${dashboardReducers.currentCompany}`,
                                B: null,
                                C: null,
                                D: null,
                                E: null,
                                F: null,
                                G: null
                            },
                        );

                        tableBody.push(
                            { 
                                A: `EMPRESA: ${company.bussname.toUpperCase()}`,
                                B: null,
                                C: null,
                                D: null,
                                E: null,
                                F: null,
                                G: null
                            },
                        );

                        tableBody.push(
                            { 
                                A: `CAJA: ${iterator.csbkname}`,
                                B: null,
                                C: null,
                                D: null,
                                E: null,
                                F: null,
                                G: null
                            },
                        );

                        tableBody.push(
                            { 
                                A: 'NUM CORREL. DEL ASIENTO O COD UNICO DE LA OPERAC.',
                                B: 'FECHA',
                                C: 'GLOSA O DESCRIPCION DE LA OPERAC.',
                                D: 'CUENTA CONTABLE ASOCIADA A LA OPERACION',
                                E: '',
                                F: 'MOVIMIENTO',
                                G: ''
                            },
                        );

                        tableBody.push(
                            { 
                                A: '',
                                B: '',
                                C: '',
                                D: 'CODIGO',
                                E: 'DENOMINACION',
                                F: 'DEBE.',
                                G: 'HABER'
                            }
                        );

                        tableBody.push(
                            {
                                A: '',
                                B: '',
                                C: 'Saldo inicial',
                                D: null,
                                E: null,
                                F: +saleinit >= 0 ? String.formatNumber.new(saleinit) : '',
                                G: +saleinit <  0 ? String.formatNumber.new(saleinit * -1) : ''
                            },
                        )

                        rowIndex += 9;

                        for (const row of rows) {
                            tableBody.push(
                                { 
                                    A: row.unicodop,
                                    B: moment(row.dateoper).format('DD/MM/YYYY'),
                                    C: row.operglos.substr(0, 50),
                                    D: row.accaccou,
                                    E: row.denomina.substr(0, 30),
                                    F: +row.chargamo > 0 ? String.formatNumber.new(row.chargamo) : '',
                                    G: +row.crediamo > 0 ? String.formatNumber.new(row.crediamo) : ''
                                }
                            )
                            chargamo += +row.chargamo;
                            crediamo += +row.crediamo;
                            rowIndex++;
                        }

                        const diff = chargamo - crediamo;
                        chargamo += diff < 0 ? Math.abs(diff) : 0;
                        crediamo += diff > 0 ? Math.abs(diff) : 0;

                        tableBody.push(
                            { 
                                A: '',
                                B: '',
                                C: '',
                                D: '',
                                E: 'SALDO FINAL',
                                F: diff < 0 ? String.formatNumber.new(Math.abs(diff)) : '',
                                G: diff > 0 ? String.formatNumber.new(Math.abs(diff)) : ''
                            }
                        )

                        tableBody.push(
                            { 
                                A: '',
                                B: '',
                                C: '',
                                D: '',
                                E: 'TOTALES',
                                F: String.formatNumber.new(chargamo),
                                G: String.formatNumber.new(crediamo)
                            }
                        )

                        unicodopRead.push(iterator.idcsbank);
                        rowIndex += 2;
                    }
                }

                const header = [
                    'A',
                    'B',
                    'C',
                    'D',
                    'E',
                    'F',
                    'G'
                ]
                
                const wb = XLSX.utils.book_new();
                const ws = XLSX.utils.json_to_sheet(tableBody, {header, skipHeader: true});
                ws["!cols"] = [
                    {wpx:70},{wpx:65},{wpx:210},{wpx:60},{wpx:160},{wpx:70},{wpx:70}
                ];
                ws['!rows'] = [];

                const keys = Object.keys(ws);
                const rowsCount = tableBody.length;
                
                for (const iterator of keys) {
                    if(ws[iterator].v?.match(/^[0-9,.]+$/g) && !iterator.match(/^(A|B|C|D|E)[0-9]+$/g)) {
                        ws[iterator].v = ws[iterator].v.replace(/,/g, '');
                        ws[iterator].t = 'n';
                        ws[iterator].z = '#,##0.00';
                    }
                    if(iterator !== '!ref' && iterator !== '!cols' && iterator !== '!rows') {
                        ws[iterator].s = {
                            font: {
                                name: 'Tahoma',
                                sz: 8
                            }
                        }
                    }
                }

                for (let index = 1; index <= rowsCount; index++) {
                    ws['!rows'].push({hpx: 12})
                }

                const merges: any = [];
                for (const index of headerIndex) {
                    ws[`C${index}`].s = {font:{name: 'Tahoma', sz: 12, bold: true}}
                    for (const iterator of header) {
                        const key1 = iterator + (index+6).toString();
                        const key2 = iterator + (index+7).toString();

                        ws[key1].s = {
                            ...ws[key1].s, 
                            alignment: { wrapText: true, vertical: 'center', horizontal: 'center'},
                            border: { 
                                top: { style: 'thin', color: 'black'},
                                bottom: { style: 'thin', color: 'black'},
                                left: { style: 'thin', color: 'black'},
                                right: { style: 'thin', color: 'black'}
                            } 
                        }
                        ws[key2].s = {
                            ...ws[key2].s, 
                            alignment: { wrapText: true, vertical: 'center', horizontal: 'center'},
                            border: { 
                                top: { style: 'thin', color: 'black'},
                                bottom: { style: 'thin', color: 'black'},
                                left: { style: 'thin', color: 'black'},
                                right: { style: 'thin', color: 'black'}
                            } 
                        }
                    };

                    const ha = index + 5;
                    const hb = index + 6;
                    ws['!rows'][index - 1].hpx = 16;
                    ws['!rows'][ha].hpx = 16;
                    ws['!rows'][hb].hpx = 16;
                    merges.push({ s: { r: ha, c: 0 }, e: { r: hb, c: 0 } });
                    merges.push({ s: { r: ha, c: 1 }, e: { r: hb, c: 1 } });
                    merges.push({ s: { r: ha, c: 2 }, e: { r: hb, c: 2 } });
                    merges.push({ s: { r: ha, c: 3 }, e: { r: ha, c: 4 } });
                    merges.push({ s: { r: ha, c: 5 }, e: { r: ha, c: 6 } });
                }

                ws["!merges"] = merges;

                XLSX.utils.book_append_sheet(wb, ws, "Libro Caja");

                var wbout = XLSX.write(wb, {bookType:'xlsx', type: 'binary', cellStyles: true});

                const s2ab = function(s) {
                    var buf = new ArrayBuffer(s.length);
                    var view = new Uint8Array(buf);
                    for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
                    return buf;
                }

                saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), `libro-caja-${year}-${month}.xlsx`);
            } else {

                const tableBody: any = []

                let rowIndex = 1;
                const headerIndex: any = [];

                for (const iterator of CashBookBanks) {
                    if (!unicodopRead.includes(iterator.idcsbank)) {
                        const rows = CashBookBanks.filter((x: any) => x.idcsbank === iterator.idcsbank);
                        const saleinit = GeneralBalancesAccount.find(x => x.accaccou === iterator.bkaccacc)?.totalacc || '0.00'
                        let chargamo = +saleinit >= 0 ? +saleinit : 0.00;
                        let crediamo = +saleinit < 0 ? +saleinit * -1 : 0.00;

                        headerIndex.push(rowIndex);

                        tableBody.push(
                            { 
                                A: null,
                                B: null,
                                C: null,
                                D: null,
                                E: 'LIBRO BANCO',
                                F: null,
                                G: null,
                                H: null,
                                I: null,
                                J: null
                            }
                        );

                        tableBody.push(
                            { 
                                A: null,
                                B: null,
                                C: null,
                                D: null,
                                E: null,
                                F: null,
                                G: null,
                                H: null,
                                I: null,
                                J: null
                            }
                        );

                        tableBody.push(
                            { 
                                A: `PERIODO DEL ${stardate} AL ${endidate}`,
                                B: null,
                                C: null,
                                D: null,
                                E: null,
                                F: null,
                                G: null,
                                H: null,
                                I: null,
                                J: null
                            }
                        );

                        tableBody.push(
                            { 
                                A: `RUC: ${dashboardReducers.currentCompany}`,
                                B: null,
                                C: null,
                                D: null,
                                E: null,
                                F: null,
                                G: null,
                                H: null,
                                I: null,
                                J: null
                            },
                        );

                        tableBody.push(
                            { 
                                A: `EMPRESA: ${company.bussname.toUpperCase()}`,
                                B: null,
                                C: null,
                                D: null,
                                E: null,
                                F: null,
                                G: null,
                                H: null,
                                I: null,
                                J: null
                            }
                        );

                        tableBody.push(
                            { 
                                A: `ENTIDAD FINANC: ${iterator.csbkname}`,
                                B: null,
                                C: null,
                                D: null,
                                E: null,
                                F: null,
                                G: null,
                                H: null,
                                I: null,
                                J: null
                            }
                        );

                        tableBody.push(
                            { 
                                A: `CTA. CORRIENTE: ${iterator.bkaccnum}`,
                                B: null,
                                C: null,
                                D: null,
                                E: null,
                                F: null,
                                G: null,
                                H: null,
                                I: null,
                                J: null
                            }
                        );

                        tableBody.push(
                            { 
                                A: 'NUM CORREL. DEL ASIENTO O COD UNICO DE LA OPERAC.',
                                B: 'FECHA',
                                C: 'OPERACIONES BANCARIAS',
                                D: '',
                                E: '',
                                F: '',
                                G: 'CUENTA CONTABLE ASOCIADA A LA OPERACION',
                                H: '',
                                I: 'MOVIMIENTO',
                                J: ''
                            }
                        );

                        tableBody.push(
                            { 
                                A: '',
                                B: '',
                                C: 'MEDIO DE PAGO',
                                D: 'DESCRIPCION DE LA OPERACION',
                                E: 'APELLIDOS Y NOMBRES, DENOMINACION, RAZON SOCIAL',
                                F: 'NÚMERO DE TRANSACCIÓN BANCARIA, DE DOCUMENTO SUSTENTATORIO O DE CONTROL INTERNO DE LA OPERACIÓN',
                                G: 'CODIGO',
                                H: 'DENOMINACION',
                                I: 'DEBE.',
                                J: 'HABER'
                            }
                        );

                        tableBody.push(
                            { 
                                A: '',
                                B: '',
                                C: null,
                                D: null,
                                E: 'Saldo inicial',
                                F: null,
                                G: null,
                                H: null,
                                I: +saleinit >= 0 ? String.formatNumber.new(saleinit) : '',
                                J: +saleinit <  0 ? String.formatNumber.new(saleinit * -1) : ''
                            },
                        );

                        rowIndex += 10;

                        for (const row of rows) {
                            tableBody.push(
                                { 
                                    A: row.unicodop,
                                    B: moment(row.dateoper).format('DD/MM/YYYY'),
                                    C: '',
                                    D: row.operglos.substr(0, 50),
                                    E: row.bussinam.trim().substr(0, 50),
                                    F: row.sustnumb.substr(0, 50),
                                    G: row.accaccou,
                                    H: row.denomina.substr(0, 30),
                                    I: +row.chargamo > 0 ? String.formatNumber.new(row.chargamo) : '',
                                    J: +row.crediamo > 0 ? String.formatNumber.new(row.crediamo) : ''
                                }
                            )
                            chargamo += +row.chargamo;
                            crediamo += +row.crediamo;
                            rowIndex++;
                        }

                        const diff = chargamo - crediamo;
                        chargamo += diff < 0 ? Math.abs(diff) : 0;
                        crediamo += diff > 0 ? Math.abs(diff) : 0;

                        tableBody.push(
                            { 
                                A: '',
                                B: '',
                                C: '',
                                D: '',
                                E: '',
                                F: '',
                                G: '',
                                H: 'SALDO FINAL',
                                I: diff < 0 ? String.formatNumber.new(Math.abs(diff)) : '',
                                J: diff > 0 ? String.formatNumber.new(Math.abs(diff)) : ''
                            }
                        )

                        tableBody.push(
                            { 
                                A: '',
                                B: '',
                                C: '',
                                D: '',
                                E: '',
                                F: '',
                                G: '',
                                H: 'TOTALES',
                                I: String.formatNumber.new(chargamo),
                                J: String.formatNumber.new(crediamo)
                            }
                        )

                        unicodopRead.push(iterator.idcsbank);
                        rowIndex += 2;
                    }
                }

                const header = [
                    'A',
                    'B',
                    'C',
                    'D',
                    'E',
                    'F',
                    'G',
                    'H',
                    'I',
                    'J'
                ]
                
                const wb = XLSX.utils.book_new();
                const ws = XLSX.utils.json_to_sheet(tableBody, {header, skipHeader: true});
                ws["!cols"] = [
                    {wpx:70},{wpx:65},{wpx:60},{wpx:140},{wpx:180},{wpx:140},{wpx:60},{wpx:150},{wpx:70},{wpx:70}
                ];
                ws['!rows'] = [];

                const keys = Object.keys(ws);
                const rowsCount = tableBody.length;

                for (const iterator of keys) {
                    if(ws[iterator].v?.match(/^[0-9,.]+$/g) && !iterator.match(/^(A|B|C|D|E|F|G)[0-9]+$/g)) {
                        ws[iterator].v = ws[iterator].v.replace(/,/g, '');
                        ws[iterator].t = 'n';
                        ws[iterator].z = '#,##0.00';
                    }
                    if(iterator !== '!ref' && iterator !== '!cols' && iterator !== '!rows') {
                        ws[iterator].s = {
                            font: {
                                name: 'Tahoma',
                                sz: 8
                            }
                        }
                    }
                }

                for (let index = 1; index <= rowsCount; index++) {
                    ws['!rows'].push({hpx: 12})
                }

                const merges: any = [];
                for (const index of headerIndex) {
                    ws[`E${index}`].s = {font:{name: 'Tahoma', sz: 12, bold: true}}
                    for (const iterator of header) {
                        const key1 = iterator + (index+7).toString();
                        const key2 = iterator + (index+8).toString();

                        ws[key1].s = {
                            ...ws[key1].s, 
                            alignment: { wrapText: true, vertical: 'center', horizontal: 'center'},
                            border: { 
                                top: { style: 'thin', color: 'black'},
                                bottom: { style: 'thin', color: 'black'},
                                left: { style: 'thin', color: 'black'},
                                right: { style: 'thin', color: 'black'}
                            } 
                        }
                        ws[key2].s = {
                            ...ws[key2].s, 
                            alignment: { wrapText: true, vertical: 'center', horizontal: 'center'},
                            border: { 
                                top: { style: 'thin', color: 'black'},
                                bottom: { style: 'thin', color: 'black'},
                                left: { style: 'thin', color: 'black'},
                                right: { style: 'thin', color: 'black'}
                            } 
                        }
                    };

                    const ha = index + 6;
                    const hb = index + 7;
                    ws['!rows'][index - 1].hpx = 16;
                    ws['!rows'][ha].hpx = 16;
                    ws['!rows'][hb].hpx = 30;
                    merges.push({ s: { r: ha, c: 0 }, e: { r: hb, c: 0 }});
                    merges.push({ s: { r: ha, c: 1 }, e: { r: hb, c: 1 }});
                    merges.push({ s: { r: ha, c: 2 }, e: { r: ha, c: 5 }});
                    merges.push({ s: { r: ha, c: 6 }, e: { r: ha, c: 7 }});
                    merges.push({ s: { r: ha, c: 8 }, e: { r: ha, c: 9 }});
                }
                ws["!merges"] = merges;

                XLSX.utils.book_append_sheet(wb, ws, "Libro Banco");

                var wbout = XLSX.write(wb, {bookType:'xlsx', type: 'binary', cellStyles: true});

                const s2ab = function(s) {
                    var buf = new ArrayBuffer(s.length);
                    var view = new Uint8Array(buf);
                    for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
                    return buf;
                }

                saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), `libro-banco-${year}-${month}.xlsx`);
            }

        } catch(err) {
            console.log(err);
        }
    }

    render() {

        const { title, year, month, base64Pdf, typecsbk } = this.state;
        const { loading, list, tables, failed, failedTitle, failedContent } = this.props.accountantReducers;
        const { classes } = this.props;

        return (
            <div style={{overflow: 'auto', height: '100%'}}>
                <Paper className='form__container'>
                    <Typography variant='h5'>{ title }</Typography>
                    <br/>
                    { failed && <MessageBox title={failedTitle} content={failedContent}/> }
                    <div style={{ display: 'flex' }}>
                        <form noValidate autoComplete="off" style={{ flex: 1, paddingRight: '15px', paddingTop: '2em' }}>
                            <Grid container spacing={2} >
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_año">Año</InputLabel>
                                            <Select
                                                labelId="lbl_año"
                                                id="year"
                                                className='select'
                                                label="Año"
                                                fullWidth
                                                value={ year }
                                                onChange={(e: any) => this.onChangeRoot({'year': e.target.value})}
                                            >
                                                <MenuItem value='2030'>2030</MenuItem>
                                                <MenuItem value='2029'>2029</MenuItem>
                                                <MenuItem value='2028'>2028</MenuItem>
                                                <MenuItem value='2027'>2027</MenuItem>
                                                <MenuItem value='2026'>2026</MenuItem>
                                                <MenuItem value='2025'>2025</MenuItem>
                                                <MenuItem value='2026'>2026</MenuItem>
                                                <MenuItem value='2025'>2025</MenuItem>
                                                <MenuItem value='2024'>2024</MenuItem>
                                                <MenuItem value='2023'>2023</MenuItem>
                                                <MenuItem value='2022'>2022</MenuItem>
                                                <MenuItem value='2021'>2021</MenuItem>
                                                <MenuItem value='2020'>2020</MenuItem>
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_mes">Mes</InputLabel>
                                            <Select
                                                labelId="lbl_mes"
                                                id="month"
                                                className='select'
                                                label="Mes"
                                                fullWidth
                                                value={ month }
                                                onChange={(e: any) => this.onChangeRoot({'month': e.target.value})}
                                            >
                                                {
                                                    meses.map((x: any) => 
                                                        <MenuItem value={x.key} key={x.key}>{x.value}</MenuItem>
                                                    )
                                                }
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_libro">Libro</InputLabel>
                                            <Select
                                                labelId="lbl_libro"
                                                id="year"
                                                className='select'
                                                label="Libro"
                                                fullWidth
                                                value={ typecsbk }
                                                onChange={(e: any) => this.onChangeRoot({'typecsbk': e.target.value})}
                                            >
                                                <MenuItem value='TCSBK001'>Libro Caja</MenuItem>
                                                <MenuItem value='TCSBK002'>Libro Banco</MenuItem>
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} style={{ paddingTop: '50px' }}>
                                    <Button variant="contained" fullWidth color="primary" onClick={() => { this.props.cashBookBanksRequest(year, month, typecsbk, this.onGeneratePdf) }}>
                                        Generar Reporte
                                    </Button>
                                </Grid>
                                <Grid item xs={12} style={{ paddingTop: '10px' }}>
                                    <Button variant="contained" fullWidth color="inherit" onClick={() => { this.props.cashBookBanksRequest(year, month, typecsbk, this.onGenerateExcel) }}>
                                        Generar Reporte EXCEL
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                        <PdfView data={base64Pdf} style={{ flex: 2 }}/>
                    </div>
                    <br/>
                    {/* <FormFooter onCancel={ this.onCancel } onSubmit={ this.onSubmit }/> */}
                </Paper>
                <ScreenLoader enabled={loading}/>
            </div>
        );
    }
}

const accountantReducers = 'accountantReducers';
const dashboardReducers = 'dashboardReducers';

const mapStateToProps = (state: any) => ({
    accountantReducers: state.get(accountantReducers),
    dashboardReducers: state.get(dashboardReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        cashBookBanksRequest
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(ReportCashBookBanks));