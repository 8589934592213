import { baseModels } from 'penta-models';
import { CASH_BOOK_BANKS, DAOT, DIARY_BOOK, EXPENSE_ANALYSIS_NINE, EXPENSE_ANALYSIS_SIX, GENERAL_BALANCES, GENERAL_BALANCES_ACCOUNT, GENERATE_EMPTY_BALANCES, INVENTORY_BOOK_BALANCE, MAJORIZATION, MAJOR_GENERAL, MASTER_ACCOUNT_GET_ALL_RECORDS, MASTER_ACCOUNT_UPDATE, PLAN_ACCOUNT_DELETE, PLAN_ACCOUNT_GET_ALL_RECORDS, PLAN_ACCOUNT_INIT_FORM, PLAN_ACCOUNT_INIT_UPDATE_FORM, PLAN_ACCOUNT_INSERT, PLAN_ACCOUNT_UPDATE, PROFIT_LOSS_STATEMENT, PURCHASES_REGISTER, PURCHASE_DETAIL_SUPPLIER, SALES_REGISTER, VOUCHERS_DELETE, VOUCHERS_GET_ALL_RECORDS, VOUCHERS_INIT_FORM, VOUCHERS_INIT_UPDATE_FORM, VOUCHERS_INSERT, VOUCHERS_UPDATE, WORKSHEET } from 'services/constants/accountantConstants';
import request from './basePipeline';

export const masterAccountGetAllRecords = (body: baseModels.pentaRequestQuery) => {
    return request.postWithToken(MASTER_ACCOUNT_GET_ALL_RECORDS, body);
}

export const masterAccountUpdate = (fiscyear: number, body: any) => {
    return request.putWithToken(MASTER_ACCOUNT_UPDATE + fiscyear, body);
}

export const planAccountInitForm = (fiscyear: string) => {
    return request.getWithToken(PLAN_ACCOUNT_INIT_FORM + fiscyear);
}

export const planAccountInitUpdateForm = (accaccou: string, fiscyear: string) => {
    return request.getWithToken(PLAN_ACCOUNT_INIT_UPDATE_FORM + accaccou + '/' + fiscyear);
}

export const planAccountGetAllRecords = (body: baseModels.pentaRequestQuery) => {
    return request.postWithToken(PLAN_ACCOUNT_GET_ALL_RECORDS, body);
}

export const planAccountInsert = (body: any) => {
    return request.postWithToken(PLAN_ACCOUNT_INSERT, body)
}

export const planAccountUpdate = (accaccou: string, fiscyear: number, body: any) => {
    return request.putWithToken(PLAN_ACCOUNT_UPDATE + accaccou + '/' + fiscyear, body)
}

export const planAccountDelete = (accaccous: Array<string>, fiscyears: Array<string>) => {
    const urlSearchParams = new URLSearchParams();
    for(const accaccou of accaccous) {
        urlSearchParams.append("accaccou", accaccou);
    }
    for(const fiscyear of fiscyears) {
        urlSearchParams.append("fiscyear", fiscyear);
    }
    return request.deleteWithToken(PLAN_ACCOUNT_DELETE + urlSearchParams.toString());
}

//#region VOUCHERS

export const vouchersInitForm = (fiscyear: number) => {
    return request.getWithToken(VOUCHERS_INIT_FORM + fiscyear);
}

export const vouchersInitUpdateForm = (correlat: number) => {
    return request.getWithToken(VOUCHERS_INIT_UPDATE_FORM + correlat);
}

export const vouchersGetAllRecords = (body: baseModels.pentaRequestQuery) => {
    return request.postWithToken(VOUCHERS_GET_ALL_RECORDS, body);
}

export const vouchersInsert = (body: any) => {
    return request.postWithToken(VOUCHERS_INSERT, body)
}

export const vouchersUpdate = (correlat: number, body: any) => {
    return request.putWithToken(VOUCHERS_UPDATE + correlat, body)
}

export const vouchersDelete = (correlats: Array<string>) => {
    const urlSearchParams = new URLSearchParams();
    for(const correlat of correlats) {
        urlSearchParams.append("correlat", correlat);
    }
    return request.deleteWithToken(VOUCHERS_DELETE + urlSearchParams.toString());
}

//#endregion

//#region REPORTS

export const generalBalancesAccount = (fiscyear: string, month: string) => {
    return request.getWithToken(GENERAL_BALANCES_ACCOUNT + fiscyear + '/' + month);
}

export const generateEmptyBalances = (fiscyear: string, month: string) => {
    return request.getWithToken(GENERATE_EMPTY_BALANCES + fiscyear + '/' + month);
}

export const diaryBook = (year: string, month: string) => {
    return request.getWithToken(DIARY_BOOK + year + '/' + month);
}

export const purchasesRegister = (officode: string, year: string, month: string, all: boolean) => {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append("all", all.toString());
    return request.getWithToken(PURCHASES_REGISTER + officode + '/' + year + '/' + month + '?' + urlSearchParams.toString());
}

export const worksheet = (fiscyear: string, balacodes: Array<string>, level: number) => {
    const urlSearchParams = new URLSearchParams();
    for(const balacode of balacodes) {
        urlSearchParams.append("balacode", balacode);
    }
    urlSearchParams.append("level", level.toString());
    return request.getWithToken(WORKSHEET + fiscyear + '?' + urlSearchParams.toString());
}

export const majorGeneral = (fiscyear: string, balacodes: Array<string>) => {
    const urlSearchParams = new URLSearchParams();
    for(const balacode of balacodes) {
        urlSearchParams.append("balacode", balacode);
    }
    return request.getWithToken(MAJOR_GENERAL + fiscyear + '?' + urlSearchParams.toString());
}

export const cashBookBanks = (year: string, month: string, typecsbk: string) => {
    return request.getWithToken(CASH_BOOK_BANKS + year + '/' + month + '/' + typecsbk);
}

export const expenseAnalysisNine = (year: string, balacodes: Array<string>) => {
    const urlSearchParams = new URLSearchParams();
    for(const balacode of balacodes) {
        urlSearchParams.append("balacode", balacode);
    }
    return request.getWithToken(EXPENSE_ANALYSIS_NINE + year + '?' + urlSearchParams.toString());
}

export const expenseAnalysisSix = (year: string, balacodes: Array<string>) => {
    const urlSearchParams = new URLSearchParams();
    for(const balacode of balacodes) {
        urlSearchParams.append("balacode", balacode);
    }
    return request.getWithToken(EXPENSE_ANALYSIS_SIX + year + '?' + urlSearchParams.toString());
}

export const inventoryBookBalance = (fiscyear: string, month) => {
    return request.getWithToken(INVENTORY_BOOK_BALANCE + fiscyear + '/' + month);
}

export const generalBalances = (fiscyear: string, balacodes: Array<string>) => {
    const urlSearchParams = new URLSearchParams();
    for(const balacode of balacodes) {
        urlSearchParams.append("balacode", balacode);
    }
    return request.getWithToken(GENERAL_BALANCES + fiscyear + '?' + urlSearchParams.toString());
}

export const profitLossStatement = (fiscyear: string, balacodes: Array<string>) => {
    const urlSearchParams = new URLSearchParams();
    for(const balacode of balacodes) {
        urlSearchParams.append("balacode", balacode);
    }
    return request.getWithToken(PROFIT_LOSS_STATEMENT + fiscyear + '?' + urlSearchParams.toString());
}

export const salesRegister = (officode: string, year: string, month: string, all: boolean) => {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append("all", all.toString());
    return request.getWithToken(SALES_REGISTER + officode + '/' + year + '/' + month + '?' + urlSearchParams.toString());
}

export const daot = (officode: string, year: string, initmonth: string, endmonth: string) => {
    return request.getWithToken(DAOT + officode + '/' + year + '/' + initmonth + '/' + endmonth);
}

export const purchaseDetailSupplier = (officode: string, year: string, initmonth: string, endmonth: string, ruc: string, product: string) => {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append("ruc", ruc);
    urlSearchParams.append("product", product);
    return request.getWithToken(PURCHASE_DETAIL_SUPPLIER + officode + '/' + year + '/' + initmonth + '/' + endmonth + '?' + urlSearchParams.toString());
}

//#endregion

export const majorization = (year: string, month: string) => {
    return request.getWithToken(MAJORIZATION + year + '/' + month);
}