import React, { useEffect } from 'react';
import { Backdrop, CircularProgress, createStyles, Paper, TextField, Theme, Tooltip, withStyles } from '@material-ui/core';
import ListView from 'penta-components/base/listView';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { payrollGetAllRecordsRequest, payrollListSelectItem, payrollListSelectAllItem, payrollActionFailed, payrollDeleteRequest } from 'penta-redux/actions/humanResourcesActions';
import history from '../../../utils/history';
import './payroll.scss'
import filePNG from './../../../../icons/fluent/file.png';
import fileEditPNG from './../../../../icons/fluent/file-edit.png';
import fileDeletePNG from './../../../../icons/fluent/delete.png';
import fileCopyPNG from './../../../../icons/fluent/copy.png';
import fileDownloadPNG from './../../../../icons/fluent/download.png';
import fileUploadPNG from './../../../../icons/fluent/upload.png';
import fileAnulatePNG from './../../../../icons/fluent/anulate.png';
import { MessageBox } from 'penta-components/base/messageBox';
import ActionDialog from 'penta-components/base/dialog';
import { Input } from 'penta-components/base/input';
import Button from 'penta-components/controls/button';
import * as XLSX from 'xlsx';
import { purchaseEntity } from 'models/shoppingModels';
import ListMenu from 'penta-components/base/listMenu';
import Separator from 'penta-components/base/separator';
import Search from 'penta-components/controls/search';
import Select from 'penta-components/controls/select';
import { createUseStyles } from 'react-jss';
import Modal from 'penta-components/base/modal';
import Checkbox from 'penta-components/controls/checkbox';
import TextInput from 'penta-components/controls/input';

const moment = require('moment');
const months = [
    { id: '00', name: '--Todos--' },
    { id: '01', name: 'Enero' },
    { id: '02', name: 'Febrero' },
    { id: '03', name: 'Marzo' },
    { id: '04', name: 'Abril' },
    { id: '05', name: 'Mayo' },
    { id: '06', name: 'Junio' },
    { id: '07', name: 'Julio' },
    { id: '08', name: 'Agosto' },
    { id: '09', name: 'Septiembre' },
    { id: '10', name: 'Octubre' },
    { id: '11', name: 'Noviembre' },
    { id: '12', name: 'Diciembre' }
];

const header = [
    { title: 'Codigo', key: 'emplcode' },
    { title: 'Apellidos y Nombres', key: 'nameeess' },
    { title: 'Tipo', key: '_typesala', 
        value: (call, item) => { 
            try {
                const val = call(item, 'typesala').split('|')[1]; 
                return val; 
            } 
            catch { } 
        }
    },
    { title: 'Planilla', key: 'payrtype' },
    { title: 'Año', key: 'payryear' },
    { title: 'Mes', key: 'payrmont' },
    { title: 'Semana', key: 'payrweek' },
    { title: 'Total Ingresos', key: 'totincom' },
    { title: 'Total Descuentos', key: 'totdesco' },
    { title: 'Neto Recibido', key: 'netrecei' }
]

const useStyles =  createUseStyles({
    container: {
        overflow: 'auto', 
        height: '100%', 
        display: 'flex', 
        flexDirection: 'column'
    },
    titleContainer: {
        flex: 1, 
        display: 'flex', 
        alignItems: 'baseline'
    },
    title: {
        fontFamily: '"Open Sans", sans-serif', 
        fontSize: '1.563rem', 
        fontWeight: 'bold', 
        color: '#0060A6', 
        marginRight: '3rem'
    },
    tabActive: {},
    tabs: {
        margin: '0 0.4rem', 
        padding: '0.4rem 0.5rem', 
        color: '#b9b9b9', 
        fontFamily: '"Open Sans", sans-serif', 
        fontWeight: 'bold', 
        fontSize: '0.875rem',
        cursor: 'pointer',
        '&$tabActive': {
            color: '#3B3B3B',
            borderBottom: '0.2rem solid #3B3B3B', 
        }
    },
    listContainer: {
        flex: 1,
        '& > div:first-child': {
            display: 'flex'
        }
    }
});

const generateWeeks = () => {
    const weeks = moment(new Date()).weeksInYear();
    const options = [];
    options.push({id: '00', name: '--Todos--'});
    let i = 1;
    for(i; i <= weeks; i++) {
        options.push({id: i.toString().padStart(2, '0'), name: i.toString().padStart(2, '0')});
    }
    return options;
}

const ListPayroll = (props: any) => {
    const classes = useStyles();
    const amountJump = 20;
    const [showModal, setShowModal] = React.useState(false);
    const [typeModal, setTypeModal] = React.useState('');
    //===============================================================
    const [searchValue, setSearchValue] = React.useState('');
    const [payryear, setPayryear] = React.useState(localStorage.getItem('currentFiscyear'));
    const [payrmont, setPayrmont] = React.useState('00');
    const [payrweek, setPayrweek] = React.useState('00');
    const [typesala, setTypesala] = React.useState('000');
    const [payrtype, setPayrtype] = React.useState('000');
    const [take, setTake] = React.useState(amountJump);
    //===============================================================
    const [typing, setTyping] = React.useState(null);
    //===============================================================

    const agregar = () => {
        history.push('/papp/hhrr/planillas/agregar');
    };

    const editar = () => {
        const select = props.humanResourcesReducers.list.find((x: any) => x.select);
        if (select !== undefined)
            history.push('/papp/hhrr/planillas/editar/' + select.emplcode + '/' + select.payryear + '/' + select.payrweek + '/' + select.typesala.split('|')[0]);
        else
            props.payrollActionFailed('Error: 0x0', 'No se ha selecionado un elemento para modificar');
    }

    const eliminar = () => {
        const select = props.humanResourcesReducers.list.filter((x: any) => x.select);
        const selectEmplcodes = select.map((x: any) => { return x.emplcode });
        const selectPayryears = select.map((x: any) => { return x.payryear });
        const selectPayrweeks = select.map((x: any) => { return x.payrweek });
        const selectTypesalas = select.map((x: any) => { return x.typesala.split('|')[0] });
        if(select.length !== 0) {
            props.payrollDeleteRequest([selectEmplcodes[0]], [selectPayryears[0]], [selectPayrweeks[0]], [selectTypesalas[0]]);
            handleCloseModal();
        }
        else
            props.payrollActionFailed('Error: 0x0', 'No se ha selecionado un elemento para eliminar');
    }

    const handleShowModal = (typeModal: string) => {
        setShowModal(true);
        setTypeModal(typeModal);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setTypeModal('');
    };

    const onChangeSearchValue = (e) => {
        const text = e.target.value;
        setSearchValue(text);
        if(typing) clearTimeout(typing)
        setTyping(
            setTimeout(() => {
                onSearch(text);
            }, 500)
        )
    }

    const onSearch = (typing?: string) => {
        const body: any = {
            fields: { 
                nameeess: `${typing ?? searchValue}`,
            },
            skip: 0,
            take: amountJump
        } as any

        if(payryear !== '') body.payryear = payryear;
        if(payrmont !== '00') body.payrmont = payrmont;
        if(payrweek !== '00') body.payrweek = payrweek;
        if(typesala !== '000') body.typesala = typesala;
        if(payrtype !== '000') body.payrtype = payrtype;

        props.payrollGetAllRecordsRequest(body);
        setTake(amountJump);
    }

    const callMoreItems = () => {
        const body = {
            fields: { 
                nameeess: `${searchValue}`,
            },
            skip: 0,
            take: take + amountJump
        } as any

        if(payryear !== '') body.payryear = payryear;
        if(payrmont !== '00') body.payrmont = payrmont;
        if(payrweek !== '00') body.payrweek = payrweek;
        if(typesala !== '000') body.typesala = typesala;
        if(payrtype !== '000') body.payrtype = payrtype;

        props.payrollGetAllRecordsRequest(body);
        setTake(take + amountJump);
    }

    useEffect(() => {
        const { payrollGetAllRecordsRequest } = props;
        const body = {
            fields: { 
                nameeess: `${searchValue}`,
            },
            skip: 0,
            take
        } as any

        if(payryear !== '') body.payryear = payryear;
        if(payrmont !== '00') body.payrmont = payrmont;
        if(payrweek !== '00') body.payrweek = payrweek;
        if(typesala !== '000') body.typesala = typesala;
        if(payrtype !== '000') body.payrtype = payrtype;

        payrollGetAllRecordsRequest(body);
    }, []);

    return (
        <div className={classes.container}>
            <div className={classes.titleContainer}>
                <div className={classes.title}>Planillas</div>
                <div>
                    <span className={`${classes.tabs} ${classes.tabActive}`}>Lista</span>
                    <span className={classes.tabs}>Chart</span>
                    <span className={classes.tabs}>Historial</span>
                </div>
            </div>
            <div className={classes.listContainer}>
                <div>
                    <Search styleContainer={{flex: 1}} onChange={onChangeSearchValue} value={searchValue}/>
                    <Select label="Tipo" labelPosition="left" style={{marginLeft: '1rem'}} data={
                        [
                            {id: '000', name: '--Todos--'}, 
                            {id: '001', name: 'SUELDOS'}, 
                            {id: '002', name: 'VACACIONES'},
                            {id: '003', name: 'GRATIFICACIONES'},
                            {id: '004', name: 'SUBSIDIO'},
                            {id: '005', name: 'BONIFICACION VOLUNTARIO'},
                            {id: '006', name: 'GRATIFICACION EXTRAORDINARIA'},
                            {id: '007', name: 'PARTICIPACION UTILIDADES VOLUNT.'},
                            {id: '008', name: 'UTLIDADES'},
                            {id: '009', name: 'CTS'},
                            {id: '010', name: 'BONIFICACION ESPECIAL'}
                        ]
                    } value={typesala} onChange={(value) => setTypesala(value.id)}/>
                    <Select label="Planilla" labelPosition="left" style={{marginLeft: '1rem'}} data={
                        [
                            {id: '000', name: '--Todos--'}, 
                            {id: '101', name: 'EMPLEADOS'}, 
                            {id: '102', name: 'OBREROS'}
                        ]
                    } value={payrtype} onChange={(value) => setPayrtype(value.id)}/>
                    <Select label="Año" labelPosition="left" style={{marginLeft: '1rem'}} data={
                        [{id: '2024', name: '2024'}, {id: '2023', name: '2023'}, {id: '2022', name: '2022'}, {id: '2021', name: '2021'}, {id: '2020', name: '2020'}, {id: '2019', name: '2019'}]
                    } value={payryear} onChange={(value) => setPayryear(value.id)}/>
                    <Select label="Mes" labelPosition="left" style={{marginLeft: '1rem'}} data={months} value={payrmont} onChange={(value) => setPayrmont(value.id)}/>
                    <Select label="Semana" labelPosition="left" style={{marginLeft: '1rem'}} data={
                        [
                            ...generateWeeks()
                        ]
                    } value={payrweek} onChange={(value) => setPayrweek(value.id)}/>
                    &nbsp;&nbsp;
                    <Button type='primary' full={false} containerStyle={{height: 'auto'}} onClick={() => onSearch()}>Filtrar</Button>
                </div>
                <Separator marginBottom='1rem'/>
                <ListMenu
                    addItem={agregar}
                    editItem={editar}
                    deleteItem={() => handleShowModal('D')}
                />
                <Separator marginBottom='0.5rem'/>
                <ListView
                    header={header} body={props.humanResourcesReducers.list}
                    selectAction={props.payrollListSelectItem} 
                    selectAllAction={props.payrollListSelectAllItem}
                    total={props.humanResourcesReducers.total}
                    callMoreItems={callMoreItems}
                />
            </div>
            {
                showModal && typeModal === 'D' &&
                <Modal 
                    title="Penta ERP"
                    onClose={() => setShowModal(false)}
                    footer={
                        <React.Fragment>
                            <Button type='bad' full={false} onClick={handleCloseModal}>Cancelar</Button>
                            &nbsp;&nbsp;
                            <Button full={false} onClick={eliminar}>Aceptar</Button>
                        </React.Fragment>
                    }
                >
                    <React.Fragment>
                        <p>¿Desea eliminar los siguientes elemetos?</p>
                        {
                            props.humanResourcesReducers.list.filter((x: any) => x.select).map(item =>
                                <ul key={`${item.emplcode}-${item.nameeess}-${item.typesala}-${item.payrtype}-${item.payryear}-${item.payrmont}-${item.payrweek}`} style={{margin: '1rem 2rem'}}>
                                    <li>{`${item.emplcode}-${item.nameeess}-${item.typesala}-${item.payrtype}-${item.payryear}-${item.payrmont}-${item.payrweek}`}</li>
                                </ul>
                            )
                        }
                    </React.Fragment>
                </Modal>
            }
        </div>
    );
}

const humanResourcesReducers = 'humanResourcesReducers';
const dashboardReducers = 'dashboardReducers';

const mapStateToProps = (state: any) => ({
    humanResourcesReducers: state.get(humanResourcesReducers),
    dashboardReducers: state.get(dashboardReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        payrollGetAllRecordsRequest,
        payrollListSelectItem,
        payrollListSelectAllItem,
        payrollActionFailed,
        payrollDeleteRequest
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ListPayroll);