import React from 'react';
import { Backdrop, Button, ButtonGroup, Checkbox, CircularProgress, createStyles, FormControl, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Select, TextField, Theme, Tooltip, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { laborerPayrollRequest, payrollInitFormRequest } from 'penta-redux/actions/humanResourcesActions';
import history from '../../../../utils/history';
import './../reports.scss'
import FormFooter from 'penta-components/base/formFooter';
import { MessageBox } from 'penta-components/base/messageBox';
import { categoryEntity } from 'models/wharehouseProductsModels';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PdfView from 'penta-components/base/pdfView';
// import * as pdfMake from "pdfmake/build/pdfmake";
// import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
import * as XLSX from 'sheetjs-style';
import ScreenLoader from 'penta-components/base/screenLoader';

let pdfMake = require("pdfmake/build/pdfmake");
let saveAs = require('file-saver').saveAs;

const meses = [
    { key: '01', value: 'Enero' },
    { key: '02', value: 'Febrero' },
    { key: '03', value: 'Marzo' },
    { key: '04', value: 'Abril' },
    { key: '05', value: 'Mayo' },
    { key: '06', value: 'Junio' },
    { key: '07', value: 'Julio' },
    { key: '08', value: 'Agosto' },
    { key: '09', value: 'Septiembre' },
    { key: '10', value: 'Octubre' },
    { key: '11', value: 'Noviembre' },
    { key: '12', value: 'Diciembre' }
]

const _typesala = {
    '001': 'SUELDOS',
    '002': 'VACACIONES',
    '003': 'GRATIFICACIONES',
    '004': 'SUBSIDIO',
    '005': 'BONIFICACION VOLUNTARIO',
    '006': 'GRATIFICACION EXTRAORDINARIA',
    '007': 'PARTICIPACION UTILIDADES VOLUNT.',
    '008': 'UTLIDADES',
    '009': 'CTS',
    '010': 'BONIFICACION ESPECIAL'
}

const useStyles = (theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        formControl: {
            margin: 0,
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    });

interface FormcategorysState {
    title: string
    year: string
    week: string
    stardate: any
    endidate: any
    typesala: string
    base64Pdf: string
}

class ReportLaborerPayroll extends React.Component<any, FormcategorysState> {

    constructor(props: any) {
        super(props);
        this.state = {
            title: 'Reporte de Planilla de Obreros',
            year: moment().format('yyyy').toString(),
            week: '',
            stardate: moment().format('YYYY-MM-DD'),
            endidate: moment().format('YYYY-MM-DD'),
            typesala: '001',
            base64Pdf: ''
        }
    }

    onChangeRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            ...value
        }))
        const { tables } = this.props.humanResourcesReducers;
        const key = Object.keys(value)[0];
        if (key === 'week') {
            const weekcale = tables.prl_weekcale.find((x: any) => parseInt(x.weeknumb) == parseInt(value.week) && parseInt(x.yearnumb) == parseInt(this.state.year)) || { };
            this.setState((state) => ({
                ...state,
                stardate: moment(weekcale.stardate).format('YYYY-MM-DD'),
                endidate: moment(weekcale.endddate).format('YYYY-MM-DD')
            }));
        }
    }

    onGeneratePdf = () => {
        const data = this.props.humanResourcesReducers.onlyOne;
        const { week, year, stardate, endidate, typesala } = this.state;
        const { dashboardReducers } = this.props;
        // const mes = meses.find((x: any) => x.key === month).value;
        const tables = JSON.parse(localStorage.getItem('tables'));
        const company = tables.companys.find((x: any) => x.taxident === dashboardReducers.currentCompany);
        const division = company.lst_grl_division.find((x: any) => x.divicode === dashboardReducers.currentDivision);
        const oficina = division.lst_grl_bussofic.find((x: any) => x.officode === dashboardReducers.currentBussOfic);

        const pdfObject: any = {
            pageSize: {
                width: 3800,
                height: 700  
            },
            pageMargins: [ 20, 110, 20, 20 ],
            header: [
                {
                    text: ' ', lineHeight: 2
                },
                {
                    margin: [20, 0, 20, 0],
                    text: company.bussname.toUpperCase(),
                    style: {
                        bold: true,
                        fontSize: 12
                    }
                },
                {
                    margin: [20, 5, 20, 0],
                    text: `CORRESPONDIENTE A LA SEMANA ${week.padStart(2,'0')} DEL ${stardate} AL ${endidate}`,
                    style: {
                        fontSize: 12
                    }
                },
                {
                    margin: [20, 20, 20, 0],
                    text: `PLANILLA ${_typesala[typesala]} DE OBREROS` + division.diviname.toUpperCase() + '-' + oficina.offiname.toUpperCase(),
                    style: {
                        fontSize: 12
                    }
                },
                {
                    text: ' ', lineHeight: 0.5
                }
            ],
            content: [
                {
                    table: {
                        headerRows: 2,
                        widths: [],
                        body: []
                    }
                },
            ],
            styles: {
                headerTitle: {
                    bold: true,
                    fontSize: 8,
                    lineHeight: 1.2
                },
                table: {
                    layout: 'headerLineOnly',
                    fontSize: 8,
                    alignment: 'center'
                },
                subTable: {
                    layout: 'headerLineOnly',
                    fontSize: 8,
                    alignment: 'left'
                },
                tableDetailLeft: {
                    layout: 'headerLineOnly',
                    fontSize: 7,
                    alignment: 'left'
                },
                tableDetailRight: {
                    layout: 'headerLineOnly',
                    fontSize: 7,
                    alignment: 'right'
                }
            },
            defaultStyle: {
                columnGap: 20,
            },
        }

        let tableWidths = [ 
            50, 120, 100, 60, 50, 
            30, 30, 30, 30, 30, 30, 
            50, 30, 30, 30, 30, 30, 
            30, 50, 30, 30, 30, 30, 
            30, 30, 50, 30, 30, 50, 
            50, 50, 50, 50, 50, 50, 
            50, 50, 50, 50, 50, 50, 
            50, 50, 50, 50, 50, 50, 
            50, 50, 50, 50, 50, 50, 
            50, 50, 50, 50, 50, 50, 
            50, 70
        ];

        const colSpans = [4,1,7,7,7,1,1,16,11,1,4,1];

        const tableBody: any = [
            [],
            [ 
                { text: '\nCODIGO', style: 'subTable', border: [true, false, false, true] },
                { text: '\nAPELLIDOS Y NOMBRES', style: 'subTable', border: [false, false, false, true] },
                { text: '\nCARGO', style: 'subTable', border: [false, false, false, true] },
                { text: 'FECHA INGRESO', style: 'table', border: [false, false, false, true] },

                { text: 'JORNAL DIARIO', style: 'table', border: [true, false, false, true] },

                { text: '\nJ', style: 'table', border: [true, false, false, true] },
                { text: '\nV', style: 'table', border: [false, false, false, true] },
                { text: '\nS', style: 'table', border: [false, false, false, true] },
                { text: '\nL', style: 'table', border: [false, false, false, true] },
                { text: '\nM', style: 'table', border: [false, false, false, true] },
                { text: '\nM', style: 'table', border: [false, false, false, true] },
                { text: 'TOTAL ORDIN.', style: 'table', border: [false, false, false, true] },

                { text: '\nJ', style: 'table', border: [true, false, false, true] },
                { text: '\nV', style: 'table', border: [false, false, false, true] },
                { text: '\nS', style: 'table', border: [false, false, false, true] },
                { text: '\nL', style: 'table', border: [false, false, false, true] },
                { text: '\nM', style: 'table', border: [false, false, false, true] },
                { text: '\nM', style: 'table', border: [false, false, false, true] },
                { text: 'TOTAL HRS.EXT', style: 'table', border: [false, false, false, true] },

                { text: '\nJ', style: 'table', border: [true, false, false, true] },
                { text: '\nV', style: 'table', border: [false, false, false, true] },
                { text: '\nS', style: 'table', border: [false, false, false, true] },
                { text: '\nL', style: 'table', border: [false, false, false, true] },
                { text: '\nM', style: 'table', border: [false, false, false, true] },
                { text: '\nM', style: 'table', border: [false, false, false, true] },
                { text: 'TOTAL HRS.EXT', style: 'table', border: [false, false, false, true] },
                
                { text: 'Y\nFer', style: 'table', border: [true, false, false, true] },

                { text: 'Noct', style: 'table', border: [true, false, false, true] },

                { text: `\n${typesala === '004' ? 'SUBSIDIO' : 'JORNAL SEMANAL'}`, style: 'table', border: [true, false, false, true] },
                { text: '\nAS.FAMIL.', style: 'table', border: [false, false, false, true] },
                { text: '\nDOM.Y.FER.', style: 'table', border: [false, false, false, true] },
                { text: '\nFERIADO', style: 'table', border: [false, false, false, true] },
                { text: '\nDOMINICAL', style: 'table', border: [false, false, false, true] },
                { text: '\nGRATIFIC.', style: 'table', border: [false, false, false, true] },
                { text: '\nOTROS', style: 'table', border: [false, false, false, true] },
                { text: '\n1RO.MAYO', style: 'table', border: [false, false, false, true] },
                { text: '\nH.EXT.25%', style: 'table', border: [false, false, false, true] },
                { text: '\nH.EXT.35%', style: 'table', border: [false, false, false, true] },
                { text: '\nH.NOCT.35%', style: 'table', border: [false, false, false, true] },
                { text: '\nINCENTIVOS', style: 'table', border: [false, false, false, true] },
                { text: '\nR.AFECTO', style: 'table', border: [false, false, false, true] },
                { text: '\nR.INAFECTO', style: 'table', border: [false, false, false, true] },
                { text: '\nBONIF.', style: 'table', border: [false, false, false, true] },
                { text: '\nTOTAL', style: 'table', border: [false, false, true, true] },

                { text: '\nE.VIDA', style: 'table', border: [true, false, false, true] },
                { text: '\nPRESTAMO', style: 'table', border: [false, false, false, true] },
                { text: '\nRET.5TA.', style: 'table', border: [false, false, false, true] },
                { text: '\nDSC.JUD.', style: 'table', border: [false, false, false, true] },
                { text: '\nOTROS', style: 'table', border: [false, false, false, true] },
                { text: '\nONP', style: 'table', border: [false, false, false, true] },
                { text: '\nCOM.FLUJO', style: 'table', border: [false, false, false, true] },
                { text: '\nCOM.MIXTA', style: 'table', border: [false, false, false, true] },
                { text: '\nSEGURO', style: 'table', border: [false, false, false, true] },
                { text: '\nF.PENS.', style: 'table', border: [false, false, false, true] },
                { text: '\nTOTAL', style: 'table', border: [false, false, true, true] },
                
                { text: '\nNETO', style: 'table', border: [true, false, true, true] },
                
                { text: '\nESSALUD', style: 'table', border: [true, false, false, true] },
                { text: '\nS.N.P.', style: 'table', border: [false, false, false, true] },
                { text: '\nI.E.S.', style: 'table', border: [false, false, false, true] },
                { text: '\nTOTAL', style: 'table', border: [false, false, true, true] },

                { text: '', style: 'table', border: [false, false, true, true] },
            ]
        ]
        
        let gc101 = 0.00;
        let gc102 = 0.00;
        let gc103 = 0.00;
        let gc104 = 0.00;
        let gc105 = 0.00;
        let gc106 = 0.00;
        let gc107 = 0.00;
        let gc108 = 0.00;
        let gc109 = 0.00;
        let gc110 = 0.00;
        let gc111 = 0.00;
        let gc112 = 0.00;
        let gc113 = 0.00;
        let gc114 = 0.00;
        let gc115 = 0.00;
        let gc116 = 0.00;
        let gc117 = 0.00;
        let gc118 = 0.00;
        let gc119 = 0.00;
        let gc120 = 0.00;
        let gc121 = 0.00;
        let gc123 = 0.00;
        let gc124 = 0.00;
        let gc201 = 0.00;
        let gc202 = 0.00;
        let gc203 = 0.00;
        let gc204 = 0.00;
        let gc205 = 0.00;
        let gc206 = 0.00;
        let gc207 = 0.00;
        let gc208 = 0.00;
        let gc209 = 0.00;
        let gc210 = 0.00;
        let gc211 = 0.00;
        let gc212 = 0.00;
        let gc213 = 0.00;
        let gc214 = 0.00;
        let gc215 = 0.00;
        let gc501 = 0.00;
        let gc301 = 0.00;
        let gc302 = 0.00;
        let gc303 = 0.00;
        let gc304 = 0.00;
        let gc305 = 0.00;
        let gc306 = 0.00;
        let gc307 = 0.00;
        let gc308 = 0.00;
        let gc309 = 0.00;
        let gc310 = 0.00;
        let gc502 = 0.00;
        let gc503 = 0.00;
        let gc401 = 0.00;
        let gc402 = 0.00;
        let gc403 = 0.00;
        let gc404 = 0.00;

        for (const row of data) {

            const concepts = JSON.parse(row.concepts);

            const c101 = concepts.find((x: any) => x.conccode === 'C101')?.outcomee || '0.00';
            const c102 = concepts.find((x: any) => x.conccode === 'C102')?.outcomee || '0.00';
            const c103 = concepts.find((x: any) => x.conccode === 'C103')?.outcomee || '0.00';
            const c104 = concepts.find((x: any) => x.conccode === 'C104')?.outcomee || '0.00';
            const c105 = concepts.find((x: any) => x.conccode === 'C105')?.outcomee || '0.00';
            const c106 = concepts.find((x: any) => x.conccode === 'C106')?.outcomee || '0.00';
            const c107 = concepts.find((x: any) => x.conccode === 'C107')?.outcomee || '0.00';
            const c108 = concepts.find((x: any) => x.conccode === 'C108')?.outcomee || '0.00';
            const c109 = concepts.find((x: any) => x.conccode === 'C109')?.outcomee || '0.00';
            const c110 = concepts.find((x: any) => x.conccode === 'C110')?.outcomee || '0.00';
            const c111 = concepts.find((x: any) => x.conccode === 'C111')?.outcomee || '0.00';
            const c112 = concepts.find((x: any) => x.conccode === 'C112')?.outcomee || '0.00';
            const c113 = concepts.find((x: any) => x.conccode === 'C113')?.outcomee || '0.00';
            const c114 = concepts.find((x: any) => x.conccode === 'C114')?.outcomee || '0.00';
            const c115 = concepts.find((x: any) => x.conccode === 'C115')?.outcomee || '0.00';
            const c116 = concepts.find((x: any) => x.conccode === 'C116')?.outcomee || '0.00';
            const c117 = concepts.find((x: any) => x.conccode === 'C117')?.outcomee || '0.00';
            const c118 = concepts.find((x: any) => x.conccode === 'C118')?.outcomee || '0.00';
            const c119 = concepts.find((x: any) => x.conccode === 'C119')?.outcomee || '0.00';
            const c120 = concepts.find((x: any) => x.conccode === 'C120')?.outcomee || '0.00';
            const c121 = concepts.find((x: any) => x.conccode === 'C121')?.outcomee || '0.00';
            const c122 = concepts.find((x: any) => x.conccode === 'C122')?.outcomee || '0.00';
            const c123 = concepts.find((x: any) => x.conccode === 'C123')?.outcomee || '0.00';
            const c124 = concepts.find((x: any) => x.conccode === 'C124')?.outcomee || '0.00';

            const c201 = concepts.find((x: any) => x.conccode === 'C201')?.outcomee || '0.00';
            const c202 = concepts.find((x: any) => x.conccode === 'C202')?.outcomee || '0.00';
            const c203 = concepts.find((x: any) => x.conccode === 'C203')?.outcomee || '0.00';
            const c204 = concepts.find((x: any) => x.conccode === 'C204')?.outcomee || '0.00';
            const c205 = concepts.find((x: any) => x.conccode === 'C205')?.outcomee || '0.00';
            const c206 = concepts.find((x: any) => x.conccode === 'C206')?.outcomee || '0.00';
            const c207 = concepts.find((x: any) => x.conccode === 'C207')?.outcomee || '0.00';
            const c208 = concepts.find((x: any) => x.conccode === 'C208')?.outcomee || '0.00';
            const c209 = concepts.find((x: any) => x.conccode === 'C209')?.outcomee || '0.00';
            const c210 = concepts.find((x: any) => x.conccode === 'C210')?.outcomee || '0.00';
            const c211 = concepts.find((x: any) => x.conccode === 'C211')?.outcomee || '0.00';
            const c212 = concepts.find((x: any) => x.conccode === 'C212')?.outcomee || '0.00';
            const c213 = concepts.find((x: any) => x.conccode === 'C213')?.outcomee || '0.00';
            const c214 = concepts.find((x: any) => x.conccode === 'C214')?.outcomee || '0.00';
            const c215 = concepts.find((x: any) => x.conccode === 'C215')?.outcomee || '0.00';
            const c501 = concepts.find((x: any) => x.conccode === 'C501')?.outcomee || '0.00';

            const c301 = concepts.find((x: any) => x.conccode === 'C301')?.outcomee || '0.00';
            const c302 = concepts.find((x: any) => x.conccode === 'C302')?.outcomee || '0.00';
            const c303 = concepts.find((x: any) => x.conccode === 'C303')?.outcomee || '0.00';
            const c304 = concepts.find((x: any) => x.conccode === 'C304')?.outcomee || '0.00';
            const c305 = concepts.find((x: any) => x.conccode === 'C305')?.outcomee || '0.00';
            const c306 = concepts.find((x: any) => x.conccode === 'C306')?.outcomee || '0.00';
            const c307 = concepts.find((x: any) => x.conccode === 'C307')?.outcomee || '0.00';
            const c308 = concepts.find((x: any) => x.conccode === 'C308')?.outcomee || '0.00';
            const c309 = concepts.find((x: any) => x.conccode === 'C309')?.outcomee || '0.00';
            const c310 = concepts.find((x: any) => x.conccode === 'C310')?.outcomee || '0.00';
            const c502 = concepts.find((x: any) => x.conccode === 'C502')?.outcomee || '0.00';

            const c503 = concepts.find((x: any) => x.conccode === 'C503')?.outcomee || '0.00';

            const c401 = concepts.find((x: any) => x.conccode === 'C401')?.outcomee || '0.00';
            const c402 = concepts.find((x: any) => x.conccode === 'C402')?.outcomee || '0.00';
            const c403 = concepts.find((x: any) => x.conccode === 'C403')?.outcomee || '0.00';
            const c404 = concepts.find((x: any) => x.conccode === 'C404')?.outcomee || '0.00';

            tableBody.push(// pdfObject.content[0].table.body.push(
                [ 
                    { text: row.emplcode, style: 'tableDetailLeft', border: [true, false, false, true] },
                    { text: row.nameeess.substr(0, 30), style: 'tableDetailLeft', border: [false, false, false, true] },
                    { text: row.posscode.substr(0, 25), style: 'tableDetailLeft', border: [false, false, false, true] },
                    { text: moment(row.dateadmi).format('YYYY-MM-DD'), style: 'tableDetailLeft', border: [false, false, false, true] },

                    { text: String.formatNumber.new(row.basalary), style: 'tableDetailRight', border: [true, false, false, true] },
                    
                    { text: String.formatNumber.new(c101), style: 'tableDetailRight', border: [true, false, false, true] },
                    { text: String.formatNumber.new(c102), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c103), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c104), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c105), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c106), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c107), style: 'tableDetailRight', border: [false, false, false, true] },

                    { text: String.formatNumber.new(c108), style: 'tableDetailRight', border: [true, false, false, true] },
                    { text: String.formatNumber.new(c109), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c110), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c111), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c112), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c113), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c114), style: 'tableDetailRight', border: [false, false, false, true] },

                    { text: String.formatNumber.new(c115), style: 'tableDetailRight', border: [true, false, false, true] },
                    { text: String.formatNumber.new(c116), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c117), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c118), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c119), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c120), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c121), style: 'tableDetailRight', border: [false, false, false, true] },

                    { text: String.formatNumber.new((parseFloat(c122) + parseFloat(c123))), style: 'tableDetailRight', border: [true, false, false, true] },
                    { text: String.formatNumber.new(c124), style: 'tableDetailRight', border: [true, false, false, true] },

                    { text: String.formatNumber.new(c201), style: 'tableDetailRight', border: [true, false, false, true] },
                    { text: String.formatNumber.new(c202), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c203), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c204), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c205), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c206), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c207), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c208), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c209), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c210), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c211), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c212), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c213), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c214), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c215), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c501), style: 'tableDetailRight', border: [false, false, true, true] },
                    
                    { text: String.formatNumber.new(c301), style: 'tableDetailRight', border: [true, false, false, true] },
                    { text: String.formatNumber.new(c302), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c303), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c304), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c305), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c306), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c307), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c308), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c309), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c310), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c502), style: 'tableDetailRight', border: [false, false, true, true] },
                    
                    { text: String.formatNumber.new(c503), style: 'tableDetailRight', border: [true, false, true, true] },
                    
                    { text: String.formatNumber.new(c401), style: 'tableDetailRight', border: [true, false, false, true] },
                    { text: String.formatNumber.new(c402), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c403), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c404), style: 'tableDetailRight', border: [false, false, true, true] },

                    { text: '', style: 'tableDetailRight', border: [false, false, true, true] }
                ]
            )

            gc101 += +c101;
            gc102 += +c102;
            gc103 += +c103;
            gc104 += +c104;
            gc105 += +c105;
            gc106 += +c106;
            gc107 += +c107;
            gc108 += +c108;
            gc109 += +c109;
            gc110 += +c110;
            gc111 += +c111;
            gc112 += +c112;
            gc113 += +c113;
            gc114 += +c114;
            gc115 += +c115;
            gc116 += +c116;
            gc117 += +c117;
            gc118 += +c118;
            gc119 += +c119;
            gc120 += +c120;
            gc121 += +c121;
            gc123 += +c123;
            gc124 += +c124;
            gc201 += +c201;
            gc202 += +c202;
            gc203 += +c203;
            gc204 += +c204;
            gc205 += +c205;
            gc206 += +c206;
            gc207 += +c207;
            gc208 += +c208;
            gc209 += +c209;
            gc210 += +c210;
            gc211 += +c211;
            gc212 += +c212;
            gc213 += +c213;
            gc214 += +c214;
            gc215 += +c215;
            gc501 += +c501;
            gc301 += +c301;
            gc302 += +c302;
            gc303 += +c303;
            gc304 += +c304;
            gc305 += +c305;
            gc306 += +c306;
            gc307 += +c307;
            gc308 += +c308;
            gc309 += +c309;
            gc310 += +c310;
            gc502 += +c502;
            gc503 += +c503;
            gc401 += +c401;
            gc402 += +c402;
            gc403 += +c403;
            gc404 += +c404;
        }

        const totalplanilla = typesala === '004' ? 
        [
            { text: 'TOTAL PLANILLA', style: 'table', colSpan: 5, border: [true, false, false, true] },
            {},{},{},{},{},
            {},{},{},{},{},
            {},{},{},{},{},
            {},{},{},{},{},
            {},{},{},{},{},
            {},{}
        ]
        :
        [
            { text: 'TOTAL PLANILLA', style: 'table', colSpan: 28, border: [true, false, false, true] },
            {},{},{},{},{},
            {},{},{},{},{},
            {},{},{},{},{},
            {},{},{},{},{},
            {},{},{},{},{},
            {},{}
        ]

        tableBody.push(
            [ 
                ...totalplanilla,
                
                { text: String.formatNumber.new(gc201), style: 'tableDetailRight', border: [true, false, false, true] },
                { text: String.formatNumber.new(gc202), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(gc203), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(gc204), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(gc205), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(gc206), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(gc207), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(gc208), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(gc209), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(gc210), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(gc211), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(gc212), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(gc213), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(gc214), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(gc215), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(gc501), style: 'tableDetailRight', border: [false, false, true, true] },
                
                { text: String.formatNumber.new(gc301), style: 'tableDetailRight', border: [true, false, false, true] },
                { text: String.formatNumber.new(gc302), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(gc303), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(gc304), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(gc305), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(gc306), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(gc307), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(gc308), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(gc309), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(gc310), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(gc502), style: 'tableDetailRight', border: [false, false, true, true] },
                
                { text: String.formatNumber.new(gc503), style: 'tableDetailRight', border: [true, false, true, true] },
                
                { text: String.formatNumber.new(gc401), style: 'tableDetailRight', border: [true, false, false, true] },
                { text: String.formatNumber.new(gc402), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(gc403), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(gc404), style: 'tableDetailRight', border: [false, false, true, true] },

                { text: '', style: 'tableDetailRight', border: [false, false, true, true] }
            ]
        )

        for (let i = 1; i < tableBody.length; i++) {
            const iterator = tableBody[i];

            if(this.state.typesala === '004') {
                if (gc101 <= 0) delete iterator[5];
                if (gc102 <= 0) delete iterator[6];
                if (gc103 <= 0) delete iterator[7];
                if (gc104 <= 0) delete iterator[8];
                if (gc105 <= 0) delete iterator[9];
                if (gc106 <= 0) delete iterator[10];
                if (gc107 <= 0) delete iterator[11];
                if (gc108 <= 0) delete iterator[12];
                if (gc109 <= 0) delete iterator[13];
                if (gc110 <= 0) delete iterator[14];
                if (gc111 <= 0) delete iterator[15];
                if (gc112 <= 0) delete iterator[16];
                if (gc113 <= 0) delete iterator[17];
                if (gc114 <= 0) delete iterator[18];
                if (gc115 <= 0) delete iterator[19];
                if (gc116 <= 0) delete iterator[20];
                if (gc117 <= 0) delete iterator[21];
                if (gc118 <= 0) delete iterator[22];
                if (gc119 <= 0) delete iterator[23];
                if (gc120 <= 0) delete iterator[24];
                if (gc121 <= 0) delete iterator[25];
                if (gc123 <= 0) delete iterator[26];
                if (gc124 <= 0) delete iterator[27];
            }

            if (gc201 <= 0) { delete iterator[28]; }
            if (gc202 <= 0) { delete iterator[29]; }
            if (gc203 <= 0) { delete iterator[30]; }
            if (gc204 <= 0) { delete iterator[31]; }
            if (gc205 <= 0) { delete iterator[32]; }
            if (gc206 <= 0) { delete iterator[33]; }
            if (gc207 <= 0) { delete iterator[34]; }
            if (gc208 <= 0) { delete iterator[35]; }
            if (gc209 <= 0) { delete iterator[36]; }
            if (gc210 <= 0) { delete iterator[37]; }
            if (gc211 <= 0) { delete iterator[38]; }
            if (gc212 <= 0) { delete iterator[39]; }
            if (gc213 <= 0) { delete iterator[40]; }
            if (gc214 <= 0) { delete iterator[41]; }
            if (gc215 <= 0) { delete iterator[42]; }
            if (gc501 <= 0) { delete iterator[43]; }
            if (gc301 <= 0) { delete iterator[44]; }
            if (gc302 <= 0) { delete iterator[45]; }
            if (gc303 <= 0) { delete iterator[46]; }
            if (gc304 <= 0) { delete iterator[47]; }
            if (gc305 <= 0) { delete iterator[48]; }
            if (gc306 <= 0) { delete iterator[49]; }
            if (gc307 <= 0) { delete iterator[50]; }
            if (gc308 <= 0) { delete iterator[51]; }
            if (gc309 <= 0) { delete iterator[52]; }
            if (gc310 <= 0) { delete iterator[53]; }
            if (gc502 <= 0) { delete iterator[54]; }
            if (gc503 <= 0) { delete iterator[55]; }
            if (gc401 <= 0) { delete iterator[56]; }
            if (gc402 <= 0) { delete iterator[57]; }
            if (gc403 <= 0) { delete iterator[58]; }
            if (gc404 <= 0) { delete iterator[59]; }
        }

        if(this.state.typesala === '004') {
            if (gc101 <= 0) { delete tableWidths[5] ;  colSpans[2] = colSpans[2] - 1; }
            if (gc102 <= 0) { delete tableWidths[6] ;  colSpans[2] = colSpans[2] - 1; }
            if (gc103 <= 0) { delete tableWidths[7] ;  colSpans[2] = colSpans[2] - 1; }
            if (gc104 <= 0) { delete tableWidths[8] ;  colSpans[2] = colSpans[2] - 1; }
            if (gc105 <= 0) { delete tableWidths[9] ;  colSpans[2] = colSpans[2] - 1; }
            if (gc106 <= 0) { delete tableWidths[10];  colSpans[2] = colSpans[2] - 1; }
            if (gc107 <= 0) { delete tableWidths[11];  colSpans[2] = colSpans[2] - 1; }
            if (gc108 <= 0) { delete tableWidths[12];  colSpans[3] = colSpans[3] - 1; }
            if (gc109 <= 0) { delete tableWidths[13];  colSpans[3] = colSpans[3] - 1; }
            if (gc110 <= 0) { delete tableWidths[14];  colSpans[3] = colSpans[3] - 1; }
            if (gc111 <= 0) { delete tableWidths[15];  colSpans[3] = colSpans[3] - 1; }
            if (gc112 <= 0) { delete tableWidths[16];  colSpans[3] = colSpans[3] - 1; }
            if (gc113 <= 0) { delete tableWidths[17];  colSpans[3] = colSpans[3] - 1; }
            if (gc114 <= 0) { delete tableWidths[18];  colSpans[3] = colSpans[3] - 1; }
            if (gc115 <= 0) { delete tableWidths[19];  colSpans[4] = colSpans[4] - 1; }
            if (gc116 <= 0) { delete tableWidths[20];  colSpans[4] = colSpans[4] - 1; }
            if (gc117 <= 0) { delete tableWidths[21];  colSpans[4] = colSpans[4] - 1; }
            if (gc118 <= 0) { delete tableWidths[22];  colSpans[4] = colSpans[4] - 1; }
            if (gc119 <= 0) { delete tableWidths[23];  colSpans[4] = colSpans[4] - 1; }
            if (gc120 <= 0) { delete tableWidths[24];  colSpans[4] = colSpans[4] - 1; }
            if (gc121 <= 0) { delete tableWidths[25];  colSpans[4] = colSpans[4] - 1; }
            if (gc123 <= 0) { delete tableWidths[26];  colSpans[5] = colSpans[5] - 1; }
            if (gc124 <= 0) { delete tableWidths[27];  colSpans[6] = colSpans[6] - 1; }
        }

        if (gc201 <= 0) { delete tableWidths[28]; colSpans[7] = colSpans[7] - 1 }
        if (gc202 <= 0) { delete tableWidths[29]; colSpans[7] = colSpans[7] - 1 }
        if (gc203 <= 0) { delete tableWidths[30]; colSpans[7] = colSpans[7] - 1 }
        if (gc204 <= 0) { delete tableWidths[31]; colSpans[7] = colSpans[7] - 1 }
        if (gc205 <= 0) { delete tableWidths[32]; colSpans[7] = colSpans[7] - 1 }
        if (gc206 <= 0) { delete tableWidths[33]; colSpans[7] = colSpans[7] - 1 }
        if (gc207 <= 0) { delete tableWidths[34]; colSpans[7] = colSpans[7] - 1 }
        if (gc208 <= 0) { delete tableWidths[35]; colSpans[7] = colSpans[7] - 1 }
        if (gc209 <= 0) { delete tableWidths[36]; colSpans[7] = colSpans[7] - 1 }
        if (gc210 <= 0) { delete tableWidths[37]; colSpans[7] = colSpans[7] - 1 }
        if (gc211 <= 0) { delete tableWidths[38]; colSpans[7] = colSpans[7] - 1 }
        if (gc212 <= 0) { delete tableWidths[39]; colSpans[7] = colSpans[7] - 1 }
        if (gc213 <= 0) { delete tableWidths[40]; colSpans[7] = colSpans[7] - 1 }
        if (gc214 <= 0) { delete tableWidths[41]; colSpans[7] = colSpans[7] - 1 }
        if (gc215 <= 0) { delete tableWidths[42]; colSpans[7] = colSpans[7] - 1 }
        if (gc501 <= 0) { delete tableWidths[43]; colSpans[7] = colSpans[7] - 1 }
        if (gc301 <= 0) { delete tableWidths[44]; colSpans[8] = colSpans[8] - 1 }
        if (gc302 <= 0) { delete tableWidths[45]; colSpans[8] = colSpans[8] - 1 }
        if (gc303 <= 0) { delete tableWidths[46]; colSpans[8] = colSpans[8] - 1 }
        if (gc304 <= 0) { delete tableWidths[47]; colSpans[8] = colSpans[8] - 1 }
        if (gc305 <= 0) { delete tableWidths[48]; colSpans[8] = colSpans[8] - 1 }
        if (gc306 <= 0) { delete tableWidths[49]; colSpans[8] = colSpans[8] - 1 }
        if (gc307 <= 0) { delete tableWidths[50]; colSpans[8] = colSpans[8] - 1 }
        if (gc308 <= 0) { delete tableWidths[51]; colSpans[8] = colSpans[8] - 1 }
        if (gc309 <= 0) { delete tableWidths[52]; colSpans[8] = colSpans[8] - 1 }
        if (gc310 <= 0) { delete tableWidths[53]; colSpans[8] = colSpans[8] - 1 }
        if (gc502 <= 0) { delete tableWidths[54]; colSpans[8] = colSpans[8] - 1 }
        if (gc503 <= 0) { delete tableWidths[55]; colSpans[9] = colSpans[9] - 1 }
        if (gc401 <= 0) { delete tableWidths[56]; colSpans[10] = colSpans[10] - 1 }
        if (gc402 <= 0) { delete tableWidths[57]; colSpans[10] = colSpans[10] - 1 }
        if (gc403 <= 0) { delete tableWidths[58]; colSpans[10] = colSpans[10] - 1 }
        if (gc404 <= 0) { delete tableWidths[59]; colSpans[10] = colSpans[10] - 1 }

        const getArray = (col, colSpan) => {
            if(colSpan === 0) return []; 
            const arr = [col];
            for (let index = 0; index < colSpan - 1; index++) {
                arr.push({});
            }
            return arr;
        }

        tableBody[0] = [ 
            ...getArray({ text: '', style: 'table', colSpan: colSpans[0], border: [true, true, false, false] }, colSpans[0]),
            ...getArray({ text: '', style: 'table', colSpan: colSpans[1], border: [true, true, false, false] }, colSpans[1]),
            ...getArray({ text: 'DIAS TRABAJADOS', style: 'table', colSpan: colSpans[2], border: [true, true, false, false] }, colSpans[2]),
            ...getArray({ text: 'HORAS EXTRAS AL 25%', style: 'table', colSpan: colSpans[3], border: [true, true, false, false] }, colSpans[3]),
            ...getArray({ text: 'HORAS EXTRAS AL 35%', style: 'table', colSpan: colSpans[4], border: [true, true, false, false] }, colSpans[4]),
            ...getArray({ text: 'Dom', style: 'table', colSpan: colSpans[5], border: [true, true, false, false] }, colSpans[5]),
            ...getArray({ text: 'Hrs', style: 'table', colSpan: colSpans[6], border: [true, true, false, false] }, colSpans[6]),
            ...getArray({ text: 'INGRESOS', style: 'table', colSpan: colSpans[7], border: [true, true, false, false] }, colSpans[7]),
            ...getArray({ text: 'DESCUENTOS', style: 'table', colSpan: colSpans[8], border: [true, true, false, false] }, colSpans[8]),
            ...getArray({ text: '', style: 'table', colSpan: colSpans[9], border: [true, true, false, false] }, colSpans[9]),
            ...getArray({ text: 'APORTACIONES', style: 'table', colSpan: colSpans[10], border: [true, true, true, false] }, colSpans[10]),
            ...getArray({ text: 'OBSERVACIONES', style: 'table', colSpan: colSpans[11], border: [true, true, true, false] }, colSpans[11]),
        ];

        for (let index = 0; index < tableBody.length; index++) {
            tableBody[index] = tableBody[index].filter(x => x !== undefined);
        }

        pdfObject.content[0].table.widths = tableWidths.filter(x => x !== undefined);
        pdfObject.content[0].table.body = tableBody;

        pdfMake.fonts = {
            Roboto: {
                normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
                bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
                italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
                bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
              },
        }
        const pdfDocGenerator = pdfMake.createPdf(pdfObject);
        pdfDocGenerator.getDataUrl((data: any) => {
            // console.log(data);
            this.setState({
                base64Pdf: data
            })
        });

    }

    onGenerateExcel = () => {
        try {
            const data = this.props.humanResourcesReducers.onlyOne;
            const { week, year, stardate, endidate, typesala } = this.state;
            const { dashboardReducers } = this.props;
            const tables = JSON.parse(localStorage.getItem('tables'));
            const company = tables.companys.find((x: any) => x.taxident === dashboardReducers.currentCompany);
            const division = company.lst_grl_division.find((x: any) => x.divicode === dashboardReducers.currentDivision);
            const oficina = division.lst_grl_bussofic.find((x: any) => x.officode === dashboardReducers.currentBussOfic);

            const tableBody: any = [
                { 
                    A:  company.bussname.toUpperCase(),
                    B:  null,
                    C:  null,
                    D:  null,
                    E:  null,
                    F:  null,
                    G:  null,
                    H:  null,
                    I:  null,
                    J:  null,
                    K:  null,
                    L:  null,
                    M:  null,
                    N:  null,
                    O:  null,
                    P:  null,
                    Q:  null,
                    R:  null,
                    S:  null,
                    T:  null,
                    U:  null,
                    V:  null,
                    W:  null,
                    X:  null,
                    Y:  null,
                    Z:  null,
                    AA: null,
                    AB: null,
                    AC: null,
                    AD: null,
                    AE: null,
                    AF: null,
                    AG: null,
                    AH: null,
                    AI: null,
                    AJ: null,
                    AK: null,
                    AL: null,
                    AM: null,
                    AN: null,
                    AO: null,
                    AP: null,
                    AQ: null,
                    AR: null,
                    AS: null,
                    AT: null,
                    AU: null,
                    AV: null,
                    AW: null,
                    AX: null,
                    AY: null,
                    AZ: null,
                    BA: null,
                    BB: null,
                    BC: null,
                    BD: null,
                    BE: null,
                    BF: null,
                    BG: null,
                    BH: null,
                    BI: null
                },
                { 
                    A:  `CORRESPONDIENTE A LA SEMANA ${week.padStart(2,'0')} DEL ${stardate} AL ${endidate}`,
                    B:  null,
                    C:  null,
                    D:  null,
                    E:  null,
                    F:  null,
                    G:  null,
                    H:  null,
                    I:  null,
                    J:  null,
                    K:  null,
                    L:  null,
                    M:  null,
                    N:  null,
                    O:  null,
                    P:  null,
                    Q:  null,
                    R:  null,
                    S:  null,
                    T:  null,
                    U:  null,
                    V:  null,
                    W:  null,
                    X:  null,
                    Y:  null,
                    Z:  null,
                    AA: null,
                    AB: null,
                    AC: null,
                    AD: null,
                    AE: null,
                    AF: null,
                    AG: null,
                    AH: null,
                    AI: null,
                    AJ: null,
                    AK: null,
                    AL: null,
                    AM: null,
                    AN: null,
                    AO: null,
                    AP: null,
                    AQ: null,
                    AR: null,
                    AS: null,
                    AT: null,
                    AU: null,
                    AV: null,
                    AW: null,
                    AX: null,
                    AY: null,
                    AZ: null,
                    BA: null,
                    BB: null,
                    BC: null,
                    BD: null,
                    BE: null,
                    BF: null,
                    BG: null,
                    BH: null,
                    BI: null
                },
                { 
                    A:  '',
                    B:  '',
                    C:  '',
                    D:  '',
                    E:  '',
                    F:  '',
                    G:  '',
                    H:  '',
                    I:  '',
                    J:  '',
                    K:  '',
                    L:  '',
                    M:  '',
                    N:  '',
                    O:  '',
                    P:  '',
                    Q:  '',
                    R:  '',
                    S:  '',
                    T:  '',
                    U:  '',
                    V:  '',
                    W:  '',
                    X:  '',
                    Y:  '',
                    Z:  '',
                    AA: '',
                    AB: '',
                    AC: '',
                    AD: '',
                    AE: '',
                    AF: '',
                    AG: '',
                    AH: '',
                    AI: '',
                    AJ: '',
                    AK: '',
                    AL: '',
                    AM: '',
                    AN: '',
                    AO: '',
                    AP: '',
                    AQ: '',
                    AR: '',
                    AS: '',
                    AT: '',
                    AU: '',
                    AV: '',
                    AW: '',
                    AX: '',
                    AY: '',
                    AZ: '',
                    BA: '',
                    BB: '',
                    BC: '',
                    BD: '',
                    BE: '',
                    BF: '',
                    BG: '',
                    BH: '',
                    BI: ''
                },
                { 
                    A:  `PLANILLA ${_typesala[typesala]} DE OBREROS ` + division.diviname.toUpperCase() + '-' + oficina.offiname.toUpperCase(),
                    B:  null,
                    C:  null,
                    D:  null,
                    E:  null,
                    F:  null,
                    G:  null,
                    H:  null,
                    I:  null,
                    J:  null,
                    K:  null,
                    L:  null,
                    M:  null,
                    N:  null,
                    O:  null,
                    P:  null,
                    Q:  null,
                    R:  null,
                    S:  null,
                    T:  null,
                    U:  null,
                    V:  null,
                    W:  null,
                    X:  null,
                    Y:  null,
                    Z:  null,
                    AA: null,
                    AB: null,
                    AC: null,
                    AD: null,
                    AE: null,
                    AF: null,
                    AG: null,
                    AH: null,
                    AI: null,
                    AJ: null,
                    AK: null,
                    AL: null,
                    AM: null,
                    AN: null,
                    AO: null,
                    AP: null,
                    AQ: null,
                    AR: null,
                    AS: null,
                    AT: null,
                    AU: null,
                    AV: null,
                    AW: null,
                    AX: null,
                    AY: null,
                    AZ: null,
                    BA: null,
                    BB: null,
                    BC: null,
                    BD: null,
                    BE: null,
                    BF: null,
                    BG: null,
                    BH: null,
                    BI: null
                },
                { 
                    A:  '',
                    B:  '',
                    C:  '',
                    D:  '',
                    E:  '',
                    F:  '',
                    G:  '',
                    H:  '',
                    I:  '',
                    J:  '',
                    K:  '',
                    L:  '',
                    M:  '',
                    N:  '',
                    O:  '',
                    P:  '',
                    Q:  '',
                    R:  '',
                    S:  '',
                    T:  '',
                    U:  '',
                    V:  '',
                    W:  '',
                    X:  '',
                    Y:  '',
                    Z:  '',
                    AA: '',
                    AB: '',
                    AC: '',
                    AD: '',
                    AE: '',
                    AF: '',
                    AG: '',
                    AH: '',
                    AI: '',
                    AJ: '',
                    AK: '',
                    AL: '',
                    AM: '',
                    AN: '',
                    AO: '',
                    AP: '',
                    AQ: '',
                    AR: '',
                    AS: '',
                    AT: '',
                    AU: '',
                    AV: '',
                    AW: '',
                    AX: '',
                    AY: '',
                    AZ: '',
                    BA: '',
                    BB: '',
                    BC: '',
                    BD: '',
                    BE: '',
                    BF: '',
                    BG: '',
                    BH: '',
                    BI: ''
                },
                { 
                    A:  '',
                    B:  '',
                    C:  '',
                    D:  '',
                    E:  '',
                    F:  'DIAS TRABAJADOS',
                    G:  '',
                    H:  '',
                    I:  '',
                    J:  '',
                    K:  '',
                    L:  '',
                    M:  'HORAS EXTRAS AL 25%',
                    N:  '',
                    O:  '',
                    P:  '',
                    Q:  '',
                    R:  '',
                    S:  '',
                    T:  'HORAS EXTRAS AL 35%',
                    U:  '',
                    V:  '',
                    W:  '',
                    X:  '',
                    Y:  '',
                    Z:  '',
                    AA: 'Dom',
                    AB: 'Hrs',
                    AC: 'INGRESOS',
                    AD: '',
                    AE: '',
                    AF: '',
                    AG: '',
                    AH: '',
                    AI: '',
                    AJ: '',
                    AK: '',
                    AL: '',
                    AM: '',
                    AN: '',
                    AO: '',
                    AP: '',
                    AQ: '',
                    AR: '',
                    AS: 'DESCUENTOS',
                    AT: '',
                    AU: '',
                    AV: '',
                    AW: '',
                    AX: '',
                    AY: '',
                    AZ: '',
                    BA: '',
                    BB: '',
                    BC: '',
                    BD: '',
                    BE: 'APORTACIONES',
                    BF: '',
                    BG: '',
                    BH: '',
                    BI: ''
                },
                { 
                    A:  'CODIGO',
                    B:  'APELLIDOS Y NOMBRES',
                    C:  'CARGO',
                    D:  'FECHA INGRESO',
                    E:  'JORNAL DIARIO',
                    F:  'J',
                    G:  'V',
                    H:  'S',
                    I:  'L',
                    J:  'M',
                    K:  'M',
                    L:  'TOTAL ORDIN.',
                    M:  'J',
                    N:  'V',
                    O:  'S',
                    P:  'L',
                    Q:  'M',
                    R:  'M',
                    S:  'TOTAL HRS.EXT',
                    T:  'J',
                    U:  'V',
                    V:  'S',
                    W:  'L',
                    X:  'M',
                    Y:  'M',
                    Z:  'TOTAL HRS.EXT',
                    AA: 'Y Fer',
                    AB: 'Noct',
                    AC: typesala === '004' ? 'SUBSIDIO' : 'JORNAL SEMANAL',
                    AD: 'AS.FAMIL.',
                    AE: 'DOM.Y.FER.',
                    AF: 'FERIADO',
                    AG: 'DOMINICAL',
                    AH: 'GRATIFIC.',
                    AI: 'OTROS',
                    AJ: '1RO.MAYO',
                    AK: 'H.EXT.25%',
                    AL: 'H.EXT.35%',
                    AM: 'H.NOCT.35%',
                    AN: 'INCENTIVOS',
                    AO: 'R.AFECTO',
                    AP: 'R.INAFECTO',
                    AQ: 'BONIF.',
                    AR: 'TOTAL',
                    AS: 'E.VIDA',
                    AT: 'PRESTAMO',
                    AU: 'RET.5TA.',
                    AV: 'DSC.JUD.',
                    AW: 'OTROS',
                    AX: 'ONP',
                    AY: 'COM.FLUJO',
                    AZ: 'COM.MIXTA',
                    BA: 'SEGURO',
                    BB: 'F.PENS.',
                    BC: 'TOTAL',
                    BD: 'NETO',
                    BE: 'ESSALUD',
                    BF: 'S.N.P.',
                    BG: 'I.E.S.',
                    BH: 'TOTAL',
                    BI: 'OBSERVACIONES'
                }
            ];

            let gc101 = 0.00;
            let gc102 = 0.00;
            let gc103 = 0.00;
            let gc104 = 0.00;
            let gc105 = 0.00;
            let gc106 = 0.00;
            let gc107 = 0.00;
            let gc108 = 0.00;
            let gc109 = 0.00;
            let gc110 = 0.00;
            let gc111 = 0.00;
            let gc112 = 0.00;
            let gc113 = 0.00;
            let gc114 = 0.00;
            let gc115 = 0.00;
            let gc116 = 0.00;
            let gc117 = 0.00;
            let gc118 = 0.00;
            let gc119 = 0.00;
            let gc120 = 0.00;
            let gc121 = 0.00;
            let gc123 = 0.00;
            let gc124 = 0.00;
            let gc201 = 0.00;
            let gc202 = 0.00;
            let gc203 = 0.00;
            let gc204 = 0.00;
            let gc205 = 0.00;
            let gc206 = 0.00;
            let gc207 = 0.00;
            let gc208 = 0.00;
            let gc209 = 0.00;
            let gc210 = 0.00;
            let gc211 = 0.00;
            let gc212 = 0.00;
            let gc213 = 0.00;
            let gc214 = 0.00;
            let gc215 = 0.00;
            let gc501 = 0.00;
            let gc301 = 0.00;
            let gc302 = 0.00;
            let gc303 = 0.00;
            let gc304 = 0.00;
            let gc305 = 0.00;
            let gc306 = 0.00;
            let gc307 = 0.00;
            let gc308 = 0.00;
            let gc309 = 0.00;
            let gc310 = 0.00;
            let gc502 = 0.00;
            let gc503 = 0.00;
            let gc401 = 0.00;
            let gc402 = 0.00;
            let gc403 = 0.00;
            let gc404 = 0.00;

            for (const row of data) {

                const concepts = JSON.parse(row.concepts);
    
                const c101 = concepts.find((x: any) => x.conccode === 'C101')?.outcomee || '0.00';
                const c102 = concepts.find((x: any) => x.conccode === 'C102')?.outcomee || '0.00';
                const c103 = concepts.find((x: any) => x.conccode === 'C103')?.outcomee || '0.00';
                const c104 = concepts.find((x: any) => x.conccode === 'C104')?.outcomee || '0.00';
                const c105 = concepts.find((x: any) => x.conccode === 'C105')?.outcomee || '0.00';
                const c106 = concepts.find((x: any) => x.conccode === 'C106')?.outcomee || '0.00';
                const c107 = concepts.find((x: any) => x.conccode === 'C107')?.outcomee || '0.00';
                const c108 = concepts.find((x: any) => x.conccode === 'C108')?.outcomee || '0.00';
                const c109 = concepts.find((x: any) => x.conccode === 'C109')?.outcomee || '0.00';
                const c110 = concepts.find((x: any) => x.conccode === 'C110')?.outcomee || '0.00';
                const c111 = concepts.find((x: any) => x.conccode === 'C111')?.outcomee || '0.00';
                const c112 = concepts.find((x: any) => x.conccode === 'C112')?.outcomee || '0.00';
                const c113 = concepts.find((x: any) => x.conccode === 'C113')?.outcomee || '0.00';
                const c114 = concepts.find((x: any) => x.conccode === 'C114')?.outcomee || '0.00';
                const c115 = concepts.find((x: any) => x.conccode === 'C115')?.outcomee || '0.00';
                const c116 = concepts.find((x: any) => x.conccode === 'C116')?.outcomee || '0.00';
                const c117 = concepts.find((x: any) => x.conccode === 'C117')?.outcomee || '0.00';
                const c118 = concepts.find((x: any) => x.conccode === 'C118')?.outcomee || '0.00';
                const c119 = concepts.find((x: any) => x.conccode === 'C119')?.outcomee || '0.00';
                const c120 = concepts.find((x: any) => x.conccode === 'C120')?.outcomee || '0.00';
                const c121 = concepts.find((x: any) => x.conccode === 'C121')?.outcomee || '0.00';
                const c122 = concepts.find((x: any) => x.conccode === 'C122')?.outcomee || '0.00';
                const c123 = concepts.find((x: any) => x.conccode === 'C123')?.outcomee || '0.00';
                const c124 = concepts.find((x: any) => x.conccode === 'C124')?.outcomee || '0.00';
    
                const c201 = concepts.find((x: any) => x.conccode === 'C201')?.outcomee || '0.00';
                const c202 = concepts.find((x: any) => x.conccode === 'C202')?.outcomee || '0.00';
                const c203 = concepts.find((x: any) => x.conccode === 'C203')?.outcomee || '0.00';
                const c204 = concepts.find((x: any) => x.conccode === 'C204')?.outcomee || '0.00';
                const c205 = concepts.find((x: any) => x.conccode === 'C205')?.outcomee || '0.00';
                const c206 = concepts.find((x: any) => x.conccode === 'C206')?.outcomee || '0.00';
                const c207 = concepts.find((x: any) => x.conccode === 'C207')?.outcomee || '0.00';
                const c208 = concepts.find((x: any) => x.conccode === 'C208')?.outcomee || '0.00';
                const c209 = concepts.find((x: any) => x.conccode === 'C209')?.outcomee || '0.00';
                const c210 = concepts.find((x: any) => x.conccode === 'C210')?.outcomee || '0.00';
                const c211 = concepts.find((x: any) => x.conccode === 'C211')?.outcomee || '0.00';
                const c212 = concepts.find((x: any) => x.conccode === 'C212')?.outcomee || '0.00';
                const c213 = concepts.find((x: any) => x.conccode === 'C213')?.outcomee || '0.00';
                const c214 = concepts.find((x: any) => x.conccode === 'C214')?.outcomee || '0.00';
                const c215 = concepts.find((x: any) => x.conccode === 'C215')?.outcomee || '0.00';
                const c501 = concepts.find((x: any) => x.conccode === 'C501')?.outcomee || '0.00';
    
                const c301 = concepts.find((x: any) => x.conccode === 'C301')?.outcomee || '0.00';
                const c302 = concepts.find((x: any) => x.conccode === 'C302')?.outcomee || '0.00';
                const c303 = concepts.find((x: any) => x.conccode === 'C303')?.outcomee || '0.00';
                const c304 = concepts.find((x: any) => x.conccode === 'C304')?.outcomee || '0.00';
                const c305 = concepts.find((x: any) => x.conccode === 'C305')?.outcomee || '0.00';
                const c306 = concepts.find((x: any) => x.conccode === 'C306')?.outcomee || '0.00';
                const c307 = concepts.find((x: any) => x.conccode === 'C307')?.outcomee || '0.00';
                const c308 = concepts.find((x: any) => x.conccode === 'C308')?.outcomee || '0.00';
                const c309 = concepts.find((x: any) => x.conccode === 'C309')?.outcomee || '0.00';
                const c310 = concepts.find((x: any) => x.conccode === 'C310')?.outcomee || '0.00';
                const c502 = concepts.find((x: any) => x.conccode === 'C502')?.outcomee || '0.00';
    
                const c503 = concepts.find((x: any) => x.conccode === 'C503')?.outcomee || '0.00';
    
                const c401 = concepts.find((x: any) => x.conccode === 'C401')?.outcomee || '0.00';
                const c402 = concepts.find((x: any) => x.conccode === 'C402')?.outcomee || '0.00';
                const c403 = concepts.find((x: any) => x.conccode === 'C403')?.outcomee || '0.00';
                const c404 = concepts.find((x: any) => x.conccode === 'C404')?.outcomee || '0.00';
    
                tableBody.push(
                    { 
                        A:  row.emplcode,
                        B:  row.nameeess.substr(0, 30),
                        C:  row.posscode.substr(0, 25),
                        D:  moment(row.dateadmi).format('YYYY-MM-DD'),
                        E:  String.formatNumber.new(row.basalary),
                        F:  String.formatNumber.new(c101),
                        G:  String.formatNumber.new(c102),
                        H:  String.formatNumber.new(c103),
                        I:  String.formatNumber.new(c104),
                        J:  String.formatNumber.new(c105),
                        K:  String.formatNumber.new(c106),
                        L:  String.formatNumber.new(c107),
                        M:  String.formatNumber.new(c108),
                        N:  String.formatNumber.new(c109),
                        O:  String.formatNumber.new(c110),
                        P:  String.formatNumber.new(c111),
                        Q:  String.formatNumber.new(c112),
                        R:  String.formatNumber.new(c113),
                        S:  String.formatNumber.new(c114),
                        T:  String.formatNumber.new(c115),
                        U:  String.formatNumber.new(c116),
                        V:  String.formatNumber.new(c117),
                        W:  String.formatNumber.new(c118),
                        X:  String.formatNumber.new(c119),
                        Y:  String.formatNumber.new(c120),
                        Z:  String.formatNumber.new(c121),
                        AA: String.formatNumber.new((parseFloat(c122) + parseFloat(c123))),
                        AB: String.formatNumber.new(c124),
                        AC: String.formatNumber.new(c201),
                        AD: String.formatNumber.new(c202),
                        AE: String.formatNumber.new(c203),
                        AF: String.formatNumber.new(c204),
                        AG: String.formatNumber.new(c205),
                        AH: String.formatNumber.new(c206),
                        AI: String.formatNumber.new(c207),
                        AJ: String.formatNumber.new(c208),
                        AK: String.formatNumber.new(c209),
                        AL: String.formatNumber.new(c210),
                        AM: String.formatNumber.new(c211),
                        AN: String.formatNumber.new(c212),
                        AO: String.formatNumber.new(c213),
                        AP: String.formatNumber.new(c214),
                        AQ: String.formatNumber.new(c215),
                        AR: String.formatNumber.new(c501),
                        AS: String.formatNumber.new(c301),
                        AT: String.formatNumber.new(c302),
                        AU: String.formatNumber.new(c303),
                        AV: String.formatNumber.new(c304),
                        AW: String.formatNumber.new(c305),
                        AX: String.formatNumber.new(c306),
                        AY: String.formatNumber.new(c307),
                        AZ: String.formatNumber.new(c308),
                        BA: String.formatNumber.new(c309),
                        BB: String.formatNumber.new(c310),
                        BC: String.formatNumber.new(c502),
                        BD: String.formatNumber.new(c503),
                        BE: String.formatNumber.new(c401),
                        BF: String.formatNumber.new(c402),
                        BG: String.formatNumber.new(c403),
                        BH: String.formatNumber.new(c404),
                        BI: ''
                    }
                )
    
                gc101 += +c101;
                gc102 += +c102;
                gc103 += +c103;
                gc104 += +c104;
                gc105 += +c105;
                gc106 += +c106;
                gc107 += +c107;
                gc108 += +c108;
                gc109 += +c109;
                gc110 += +c110;
                gc111 += +c111;
                gc112 += +c112;
                gc113 += +c113;
                gc114 += +c114;
                gc115 += +c115;
                gc116 += +c116;
                gc117 += +c117;
                gc118 += +c118;
                gc119 += +c119;
                gc120 += +c120;
                gc121 += +c121;
                gc123 += +c123;
                gc124 += +c124;
                gc201 += +c201;
                gc202 += +c202;
                gc203 += +c203;
                gc204 += +c204;
                gc205 += +c205;
                gc206 += +c206;
                gc207 += +c207;
                gc208 += +c208;
                gc209 += +c209;
                gc210 += +c210;
                gc211 += +c211;
                gc212 += +c212;
                gc213 += +c213;
                gc214 += +c214;
                gc215 += +c215;
                gc501 += +c501;
                gc301 += +c301;
                gc302 += +c302;
                gc303 += +c303;
                gc304 += +c304;
                gc305 += +c305;
                gc306 += +c306;
                gc307 += +c307;
                gc308 += +c308;
                gc309 += +c309;
                gc310 += +c310;
                gc502 += +c502;
                gc503 += +c503;
                gc401 += +c401;
                gc402 += +c402;
                gc403 += +c403;
                gc404 += +c404;
            }
    
            tableBody.push(
                { 
                    A:  'TOTAL PLANILLA',
                    B:  '',
                    C:  '',
                    D:  '',
                    E:  '',
                    F:  '',
                    G:  '',
                    H:  '',
                    I:  '',
                    J:  '',
                    K:  '',
                    L:  '',
                    M:  '',
                    N:  '',
                    O:  '',
                    P:  '',
                    Q:  '',
                    R:  '',
                    S:  '',
                    T:  '',
                    U:  '',
                    V:  '',
                    W:  '',
                    X:  '',
                    Y:  '',
                    Z:  '',
                    AA: '',
                    AB: '',
                    AC: String.formatNumber.new(gc201),
                    AD: String.formatNumber.new(gc202),
                    AE: String.formatNumber.new(gc203),
                    AF: String.formatNumber.new(gc204),
                    AG: String.formatNumber.new(gc205),
                    AH: String.formatNumber.new(gc206),
                    AI: String.formatNumber.new(gc207),
                    AJ: String.formatNumber.new(gc208),
                    AK: String.formatNumber.new(gc209),
                    AL: String.formatNumber.new(gc210),
                    AM: String.formatNumber.new(gc211),
                    AN: String.formatNumber.new(gc212),
                    AO: String.formatNumber.new(gc213),
                    AP: String.formatNumber.new(gc214),
                    AQ: String.formatNumber.new(gc215),
                    AR: String.formatNumber.new(gc501),
                    AS: String.formatNumber.new(gc301),
                    AT: String.formatNumber.new(gc302),
                    AU: String.formatNumber.new(gc303),
                    AV: String.formatNumber.new(gc304),
                    AW: String.formatNumber.new(gc305),
                    AX: String.formatNumber.new(gc306),
                    AY: String.formatNumber.new(gc307),
                    AZ: String.formatNumber.new(gc308),
                    BA: String.formatNumber.new(gc309),
                    BB: String.formatNumber.new(gc310),
                    BC: String.formatNumber.new(gc502),
                    BD: String.formatNumber.new(gc503),
                    BE: String.formatNumber.new(gc401),
                    BF: String.formatNumber.new(gc402),
                    BG: String.formatNumber.new(gc403),
                    BH: String.formatNumber.new(gc404),
                    BI: ''
                }
            )

            const header = [
                'A',  
                'B',  
                'C',  
                'D',  
                'E',   
                'F',  
                'G',  
                'H',  
                'I',  
                'J',  
                'K',  
                'L',  
                'M',  
                'N',  
                'O',  
                'P',  
                'Q',  
                'R',  
                'S',  
                'T',  
                'U',  
                'V',  
                'W',  
                'X',  
                'Y',  
                'Z', 
                'AA',
                'AB',
                'AC',
                'AD',
                'AE',
                'AF',
                'AG', 
                'AH', 
                'AI', 
                'AJ', 
                'AK', 
                'AL', 
                'AM', 
                'AN', 
                'AO', 
                'AP', 
                'AQ', 
                'AR', 
                'AS', 
                'AT', 
                'AU', 
                'AV', 
                'AW', 
                'AX', 
                'AY', 
                'AZ', 
                'BA', 
                'BB', 
                'BC', 
                'BD', 
                'BE', 
                'BF', 
                'BG',
                'BH',
                'BI'
            ]
            
            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(tableBody, {header, skipHeader: true});
            ws["!cols"] = [
                {wpx:50},{wpx:150},{wpx:120},{wpx:70},{wpx:50},

                {wpx:25},{wpx:25},{wpx:25},{wpx:25},{wpx:25},{wpx:25},{wpx:30},
                {wpx:25},{wpx:25},{wpx:25},{wpx:25},{wpx:25},{wpx:25},{wpx:30},
                {wpx:25},{wpx:25},{wpx:25},{wpx:25},{wpx:25},{wpx:25},{wpx:30},
                {wpx:30},{wpx:30},
                
                {wpx:50},{wpx:50},
                {wpx:50},{wpx:50},
                {wpx:50},{wpx:50},{wpx:50},{wpx:50},{wpx:50},{wpx:50},{wpx:50},{wpx:50},
                {wpx:50},{wpx:50},{wpx:50},{wpx:50},{wpx:50},{wpx:50},{wpx:50},{wpx:50},{wpx:50},{wpx:50},
                {wpx:50},{wpx:50},{wpx:50},{wpx:50},{wpx:50},{wpx:50},{wpx:50},{wpx:50},{wpx:50},{wpx:50},
                {wpx:70}
            ];

            const keys = Object.keys(ws);
            const rowsCount = tableBody.length;

            for (const iterator of keys) {
                if(ws[iterator].v?.match(/^[0-9,.]+$/g) && !iterator.match(/^(A|B|C|D)[0-9]+$/g)) {
                    ws[iterator].v = ws[iterator].v.replace(/,/g, '');
                    ws[iterator].t = 'n';
                    ws[iterator].z = '#,##0.00';
                }
                if(iterator !== '!ref' && iterator !== '!cols') {
                    ws[iterator].s = {
                        font: {
                            name: 'Calibri',
                            sz: 10
                        }
                    }
                    const row = +(iterator.match(/\d+/)[0]);
                    if(iterator.match(/^(D|E|L|S|Z|AA|AB|AR|BC|BD|BH|BI)[0-9]+$/g) && + row >= 6) {
                        ws[iterator].s.border = {
                            right: { style: 'thin', color: 'black'}
                        }
                    }
                    if(row === rowsCount) {
                        ws[iterator].s.border = {
                            ...ws[iterator].s.border,
                            bottom: { style: 'thin', color: 'black'},
                            top: { style: 'thin', color: 'black'}
                        }
                    }
                }
            }

            ws['A1'].s = {font:{name: 'Arial', sz: 12, bold: true}}
            ws['A2'].s = {font:{name: 'Arial', sz: 10}}
            ws['A4'].s = {font:{name: 'Arial', sz: 10}}

            for (const iterator of header) {
                const key1 = iterator + "6";
                const key2 = iterator + "7";

                ws[key1].s = {
                    ...ws[key1].s, 
                    alignment: { wrapText: true, vertical: 'center', horizontal: 'center'},
                    border: { 
                        ...ws[key1].s.border, 
                        top: { style: 'thin', color: 'black'},
                        bottom: null,
                    } 
                }
                ws[key2].s = {
                    ...ws[key2].s, 
                    alignment: { wrapText: true, vertical: 'center', horizontal: 'center'},
                    border: { 
                        ...ws[key1].s.border, 
                        top: null,
                        bottom: { style: 'thin', color: 'black'},
                    } 
                }
            };

            if(this.state.typesala === '004') {
                if (gc101 <= 0) ws['!cols'][5].hidden = true;
                if (gc102 <= 0) ws['!cols'][6].hidden = true;
                if (gc103 <= 0) ws['!cols'][7].hidden = true;
                if (gc104 <= 0) ws['!cols'][8].hidden = true;
                if (gc105 <= 0) ws['!cols'][9].hidden = true;
                if (gc106 <= 0) ws['!cols'][10].hidden = true;
                if (gc107 <= 0) ws['!cols'][11].hidden = true;
                if (gc108 <= 0) ws['!cols'][12].hidden = true;
                if (gc109 <= 0) ws['!cols'][13].hidden = true;
                if (gc110 <= 0) ws['!cols'][14].hidden = true;
                if (gc111 <= 0) ws['!cols'][15].hidden = true;
                if (gc112 <= 0) ws['!cols'][16].hidden = true;
                if (gc113 <= 0) ws['!cols'][17].hidden = true;
                if (gc114 <= 0) ws['!cols'][18].hidden = true;
                if (gc115 <= 0) ws['!cols'][19].hidden = true;
                if (gc116 <= 0) ws['!cols'][20].hidden = true;
                if (gc117 <= 0) ws['!cols'][21].hidden = true;
                if (gc118 <= 0) ws['!cols'][22].hidden = true;
                if (gc119 <= 0) ws['!cols'][23].hidden = true;
                if (gc120 <= 0) ws['!cols'][24].hidden = true;
                if (gc121 <= 0) ws['!cols'][25].hidden = true;
                if (gc123 <= 0) ws['!cols'][26].hidden = true;
                if (gc124 <= 0) ws['!cols'][27].hidden = true;
            }
            
            if (gc201 <= 0)  ws['!cols'][28].hidden = true;
            if (gc202 <= 0)  ws['!cols'][29].hidden = true;
            if (gc203 <= 0)  ws['!cols'][30].hidden = true;
            if (gc204 <= 0)  ws['!cols'][31].hidden = true;
            if (gc205 <= 0)  ws['!cols'][32].hidden = true;
            if (gc206 <= 0)  ws['!cols'][33].hidden = true;
            if (gc207 <= 0)  ws['!cols'][34].hidden = true;
            if (gc208 <= 0)  ws['!cols'][35].hidden = true;
            if (gc209 <= 0)  ws['!cols'][36].hidden = true;
            if (gc210 <= 0)  ws['!cols'][37].hidden = true;
            if (gc211 <= 0)  ws['!cols'][38].hidden = true;
            if (gc212 <= 0)  ws['!cols'][39].hidden = true;
            if (gc213 <= 0)  ws['!cols'][40].hidden = true;
            if (gc214 <= 0)  ws['!cols'][41].hidden = true;
            if (gc215 <= 0)  ws['!cols'][42].hidden = true;
            if (gc501 <= 0)  ws['!cols'][43].hidden = true;
            if (gc301 <= 0)  ws['!cols'][44].hidden = true;
            if (gc302 <= 0)  ws['!cols'][45].hidden = true;
            if (gc303 <= 0)  ws['!cols'][46].hidden = true;
            if (gc304 <= 0)  ws['!cols'][47].hidden = true;
            if (gc305 <= 0)  ws['!cols'][48].hidden = true;
            if (gc306 <= 0)  ws['!cols'][49].hidden = true;
            if (gc307 <= 0)  ws['!cols'][50].hidden = true;
            if (gc308 <= 0)  ws['!cols'][51].hidden = true;
            if (gc309 <= 0)  ws['!cols'][52].hidden = true;
            if (gc310 <= 0)  ws['!cols'][53].hidden = true;
            if (gc502 <= 0)  ws['!cols'][54].hidden = true;
            if (gc503 <= 0)  ws['!cols'][55].hidden = true;
            if (gc401 <= 0)  ws['!cols'][56].hidden = true;
            if (gc402 <= 0)  ws['!cols'][57].hidden = true;
            if (gc403 <= 0)  ws['!cols'][58].hidden = true;
            if (gc404 <= 0)  ws['!cols'][59].hidden = true;

            if(this.state.typesala !== '004') ws['!cols'][60].hidden = true;

            ws["!merges"] = [
                { s: { r: 5, c: 0 }, e: { r: 5, c: 3 } },
                { s: { r: 5, c: 5 }, e: { r: 5, c: 11 } },
                { s: { r: 5, c: 12 }, e: { r: 5, c: 18 } },
                { s: { r: 5, c: 19 }, e: { r: 5, c: 25 } },
                { s: { r: 5, c: 28 }, e: { r: 5, c: 43 } },
                { s: { r: 5, c: 44 }, e: { r: 5, c: 54 } },
                { s: { r: 5, c: 56 }, e: { r: 5, c: 59 } },
                { s: { r: rowsCount - 1, c: 0 }, e: { r: rowsCount - 1, c: 27 } },
            ];

            XLSX.utils.book_append_sheet(wb, ws, "Planilla Obreros");

            var wbout = XLSX.write(wb, {bookType:'xlsx', type: 'binary', cellStyles: true});

            const s2ab = function(s) {
                var buf = new ArrayBuffer(s.length);
                var view = new Uint8Array(buf);
                for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
                return buf;
            }

            saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), `planilla-obreros-${year}-${week}.xlsx`);
        } catch(err) {
            console.log(err);
        }
    }

    componentDidMount() {
        const { payrollInitFormRequest } = this.props;
        payrollInitFormRequest(this.state.year, () => {});
    }

    render() {

        const { title, year, week, base64Pdf, stardate, endidate, typesala } = this.state;
        const { loading, list, tables, failed, failedTitle, failedContent } = this.props.humanResourcesReducers;
        const { classes } = this.props;

        return (
            <div style={{overflow: 'auto', height: '100%'}}>
                <Paper className='form__container'>
                    <Typography variant='h5'>{ title }</Typography>
                    <br/>
                    { failed && <MessageBox title={failedTitle} content={failedContent}/> }
                    <div style={{ display: 'flex' }}>
                        <form noValidate autoComplete="off" style={{ flex: 1, paddingRight: '15px', paddingTop: '2em' }}>
                            <Grid container spacing={2} >
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_año">Año</InputLabel>
                                            <Select
                                                labelId="lbl_año"
                                                id="year"
                                                className='select'
                                                label="Año"
                                                fullWidth
                                                value={ year }
                                                onChange={(e: any) => this.onChangeRoot({'year': e.target.value})}
                                            >
                                                <MenuItem value='2030'>2030</MenuItem>
                                                <MenuItem value='2029'>2029</MenuItem>
                                                <MenuItem value='2028'>2028</MenuItem>
                                                <MenuItem value='2027'>2027</MenuItem>
                                                <MenuItem value='2026'>2026</MenuItem>
                                                <MenuItem value='2025'>2025</MenuItem>
                                                <MenuItem value='2026'>2026</MenuItem>
                                                <MenuItem value='2025'>2025</MenuItem>
                                                <MenuItem value='2024'>2024</MenuItem>
                                                <MenuItem value='2023'>2023</MenuItem>
                                                <MenuItem value='2022'>2022</MenuItem>
                                                <MenuItem value='2021'>2021</MenuItem>
                                                <MenuItem value='2020'>2020</MenuItem>
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField fullWidth id="payrweek" label="Semana" variant="outlined" value={ week } onChange={(e: any) => this.onChangeRoot({'week': e.target.value})}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField fullWidth type="date" id="stardate" label="Inicio" variant="outlined" value={ stardate } disabled/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField fullWidth type="date" id="endidate" label="Final" variant="outlined" value={ endidate } disabled/>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_año">Tipo</InputLabel>
                                            <Select
                                                labelId="lbl_año"
                                                id="typesala"
                                                className='select'
                                                label="Año"
                                                fullWidth
                                                value={ typesala }
                                                onChange={(e: any) => this.onChangeRoot({'typesala': e.target.value})}
                                            >
                                                <MenuItem value='001'>SUELDOS</MenuItem>
                                                <MenuItem value='002'>VACACIONES</MenuItem>
                                                <MenuItem value='003'>GRATIFICACIONES</MenuItem>
                                                <MenuItem value='004'>SUBSIDIO</MenuItem>
                                                <MenuItem value='005'>BONIFICACION VOLUNTARIO</MenuItem>
                                                <MenuItem value='006'>GRATIFICACION EXTRAORDINARIA</MenuItem>
                                                <MenuItem value='007'>PARTICIPACION UTILIDADES VOLUNT.</MenuItem>
                                                <MenuItem value='008'>UTLIDADES</MenuItem>
                                                <MenuItem value='009'>CTS</MenuItem>
                                                <MenuItem value='010'>BONIFICACION ESPECIAL</MenuItem>
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} style={{ paddingTop: '50px' }}>
                                    <Button variant="contained" fullWidth color="primary" onClick={() => { this.props.laborerPayrollRequest(year, week, typesala, this.onGeneratePdf) }}>
                                        Generar Reporte PDF
                                    </Button>
                                </Grid>
                                <Grid item xs={12} style={{ paddingTop: '10px' }}>
                                    <Button variant="contained" fullWidth color="inherit" onClick={() => { this.props.laborerPayrollRequest(year, week, typesala, this.onGenerateExcel)  }}>
                                        Generar Reporte EXCEL
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                        <PdfView data={base64Pdf} style={{ flex: 2 }}/>
                    </div>
                    <br/>
                    {/* <FormFooter onCancel={ this.onCancel } onSubmit={ this.onSubmit }/> */}
                </Paper>
                <ScreenLoader enabled={loading}/>
            </div>
        );
    }
}

const humanResourcesReducers = 'humanResourcesReducers';
const dashboardReducers = 'dashboardReducers';

const mapStateToProps = (state: any) => ({
    humanResourcesReducers: state.get(humanResourcesReducers),
    dashboardReducers: state.get(dashboardReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        laborerPayrollRequest,
        payrollInitFormRequest
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(ReportLaborerPayroll));