import * as types  from 'redux/constants/generalConstants';

export const locationsGetDepartmentsRequest = () => ({
    type: types.LOCATIONS_GET_DEPARTMENTS_REQUEST
});

export const locationsGetDepartmentsSuccess = (tables: any) => ({
    type: types.LOCATIONS_GET_DEPARTMENTS_REQUEST_SUCCESS,
    tables
});

export const locationsGetDepartmentsFailed = (titleError: string, titleContent: string) => ({
    type: types.LOCATIONS_GET_DEPARTMENTS_REQUEST_FAILED,
    titleError,
    titleContent
});

export const locationsGetProvincesRequest = (locacode: string) => ({
    type: types.LOCATIONS_GET_PROVINCES_REQUEST,
    locacode
});

export const locationsGetProvincesSuccess = (tables: any) => ({
    type: types.LOCATIONS_GET_PROVINCES_REQUEST_SUCCESS,
    tables
});

export const locationsGetProvincesFailed = (titleError: string, titleContent: string) => ({
    type: types.LOCATIONS_GET_PROVINCES_REQUEST_FAILED,
    titleError,
    titleContent
});

export const locationsGetDistrictsRequest = (locacode: string) => ({
    type: types.LOCATIONS_GET_DISTRICT_REQUEST,
    locacode
});

export const locationsGetDistrictsSuccess = (tables: any) => ({
    type: types.LOCATIONS_GET_DISTRICT_REQUEST_SUCCESS,
    tables
});

export const locationsGetDistrictsFailed = (titleError: string, titleContent: string) => ({
    type: types.LOCATIONS_GET_DISTRICT_REQUEST_FAILED,
    titleError,
    titleContent
});

export const currencyExchangeRateRequest = (datetime: string, callback: any) => ({
    type: types.CURRENCY_EXCHANGE_RATE_REQUEST,
    datetime,
    callback
});

export const currencyExchangeRateRequestSuccess = (tables: any) => ({
    type: types.CURRENCY_EXCHANGE_RATE_REQUEST_SUCCESS,
    tables
});

export const currencyExchangeRateRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.CURRENCY_EXCHANGE_RATE_REQUEST_FAILED,
    titleError,
    titleContent
});

export const masterTablesRequest = (option: string, callback: void) => ({
    type: types.MASTER_TABLES_REQUEST,
    option,
    callback
});

export const masterTablesRequestSuccess = (onlyOne: any) => ({
    type: types.MASTER_TABLES_REQUEST_SUCCESS,
    onlyOne
});

export const masterTablesRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.MASTER_TABLES_REQUEST_FAILED,
    titleError,
    titleContent
});
