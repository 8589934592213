import { 
    USERS_ACTION_FAILED,
    USERS_DELETE_REQUEST,
    USERS_DELETE_REQUEST_FAILED,
    USERS_DELETE_REQUEST_SUCCESS,
    USERS_GET_ALL_RECORDS_REQUEST, 
    USERS_GET_ALL_RECORDS_REQUEST_FAILED, 
    USERS_GET_ALL_RECORDS_REQUEST_SUCCESS, 
    USERS_INIT_FORM_REQUEST, 
    USERS_INIT_FORM_REQUEST_FAILED, 
    USERS_INIT_FORM_REQUEST_SUCCESS, 
    USERS_INIT_UPDATE_FORM_REQUEST, 
    USERS_INIT_UPDATE_FORM_REQUEST_FAILED, 
    USERS_INIT_UPDATE_FORM_REQUEST_SUCCESS, 
    USERS_LIST_SELECT_ALL_ITEM, 
    USERS_LIST_SELECT_ITEM,  
    USERS_REGISTER_REQUEST,
    USERS_REGISTER_REQUEST_FAILED,
    USERS_REGISTER_REQUEST_SUCCESS,
    USERS_UPDATE_REQUEST,
    USERS_UPDATE_REQUEST_FAILED,
    USERS_UPDATE_REQUEST_SUCCESS} from '../constants/usersConstants';

const initialState: any = {
    tables: { roles: [], departamentos: [], provincias: [], distritos: [] },
    onlyOne: null,
    list: [],
    total: 0,
    loading: false,
    failed: false,
    failedTitle: null,
    failedContent: null
};

export default function usersReducer(state = initialState, action: any = {}) {
    switch(action.type) {
        case USERS_INIT_FORM_REQUEST:
            return {
                ...state,
                loading: true
            };
        case USERS_INIT_FORM_REQUEST_SUCCESS:
            return {
                ...state,
                tables: { ...state.tables, ...action.tables },
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case USERS_INIT_FORM_REQUEST_FAILED:
            return {
                ...initialState,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case USERS_INIT_UPDATE_FORM_REQUEST:
            return {
                ...state,
                loading: true
            };
        case USERS_INIT_UPDATE_FORM_REQUEST_SUCCESS:
            return {
                ...state,
                tables: { ...state.tables, ...action.tables },
                onlyOne: action.onlyOne,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case USERS_INIT_UPDATE_FORM_REQUEST_FAILED:
            return {
                ...initialState,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case USERS_GET_ALL_RECORDS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case USERS_GET_ALL_RECORDS_REQUEST_SUCCESS:
            return {
                ...state,
                list: action.list,
                total: action.total,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case USERS_GET_ALL_RECORDS_REQUEST_FAILED:
            return {
                ...initialState,
                list: [],
                total: 0,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case USERS_REGISTER_REQUEST:
            return {
                ...state,
                loading: true
            };
        case USERS_REGISTER_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case USERS_REGISTER_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case USERS_UPDATE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case USERS_UPDATE_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case USERS_UPDATE_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case USERS_DELETE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case USERS_DELETE_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case USERS_DELETE_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case USERS_LIST_SELECT_ITEM:
            const newList = state.list;
            newList[action.key].select = !newList[action.key].select;
            return {
                ...state,
                list: newList
            };
        case USERS_LIST_SELECT_ALL_ITEM:
            const newListAll = state.list;
            for (const item of newListAll) {
                item.select = action.checked
            }
            return {
                ...state,
                list: newListAll
            };
        case USERS_ACTION_FAILED:
            return {
                ...state,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        default:
            return state;
    }
}