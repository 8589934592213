import { baseModels } from 'penta-models';
import { 
    SALES_ANULATE,
    SALES_DELETE,
    SALES_GET_ALL_RECORDS,
    SALES_INIT_FORM,
    SALES_INIT_UPDATE_FORM,
    SALES_INSERT,
    SALES_UPDATE,
    CUSTOMERS_DELETE,
    CUSTOMERS_GET_ALL_RECORDS,
    CUSTOMERS_INIT_FORM, 
    CUSTOMERS_INIT_UPDATE_FORM, 
    CUSTOMERS_INSERT,
    CUSTOMERS_UPDATE,
    SALES_INSERT_BATCH,
    SALES_UPLOAD_CODES,
    SALES_DELETE_UPLOAD_CODE,
    SALES_DETAILED_DIARY_BOOK,
    KARDEX,
    INVOICES_CHARGE
} from 'services/constants/salesConstants';
import request from './basePipeline';

//#region  CUSTOMERS

export const customersInitForm = (fiscyear: string) => {
    return request.getWithToken(CUSTOMERS_INIT_FORM + fiscyear);
}

export const customersInitUpdateForm = (docnumbe: string) => {
    return request.getWithToken(CUSTOMERS_INIT_UPDATE_FORM + docnumbe)
}

export const customersGetAllRecords = (body: baseModels.pentaRequestQuery) => {
    return request.postWithToken(CUSTOMERS_GET_ALL_RECORDS, body);
}

export const customersInsert = (body: any) => {
    return request.postWithToken(CUSTOMERS_INSERT, body)
}

export const customersUpdate = (docnumbe: string, body: any) => {
    return request.putWithToken(CUSTOMERS_UPDATE + docnumbe, body)
}

export const customersDelete = (docnumbes: Array<string>) => {
    const urlSearchParams = new URLSearchParams();
    for(const docnumbe of docnumbes) {
        urlSearchParams.append("docnumbe", docnumbe);
    }
    return request.deleteWithToken(CUSTOMERS_DELETE + urlSearchParams.toString());
}

//#endregion

//#region  SALES

export const salesInitForm = () => {
    return request.getWithToken(SALES_INIT_FORM);
}

export const salesInitUpdateForm = (serialnu: string, documnum: string, docnumbe: string) => {
    return request.getWithToken(SALES_INIT_UPDATE_FORM + serialnu + '/' + documnum + '/' + docnumbe);
}

export const salesUploadCodes = (year: string) => {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append('year', year);
    return request.getWithToken(SALES_UPLOAD_CODES + urlSearchParams.toString());
}

export const salesGetAllRecords = (body: baseModels.pentaRequestQuery) => {
    return request.postWithToken(SALES_GET_ALL_RECORDS, body);
}

export const salesInsert = (body: any) => {
    return request.postWithToken(SALES_INSERT, body)
}

export const salesInsertBatch = (body: any) => {
    return request.postWithToken(SALES_INSERT_BATCH, body)
}

export const salesUpdate = (serialnu: string, documnum: string, docnumbe: string, body: any) => {
    return request.putWithToken(SALES_UPDATE + serialnu + '/' + documnum + '/' + docnumbe, body)
}

export const salesDelete = (serialnus: Array<string>, documnums: Array<string>, docnumbes: Array<string>) => {
    const urlSearchParams = new URLSearchParams();
    for(const serialnu of serialnus) {
        urlSearchParams.append("serialnu", serialnu);
    }
    for(const documnum of documnums) {
        urlSearchParams.append("documnum", documnum);
    }
    for(const docnumbe of docnumbes) {
        urlSearchParams.append("docnumbe", docnumbe);
    }
    return request.deleteWithToken(SALES_DELETE + urlSearchParams.toString());
}

export const salesDeleteByUploadCode = (uplocode: string) => {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append('uplocode', uplocode)
    return request.deleteWithToken(SALES_DELETE_UPLOAD_CODE + urlSearchParams.toString());
}

export const salesAnulate = (serialnus: Array<string>, documnums: Array<string>, docnumbes: Array<string>) => {
    const urlSearchParams = new URLSearchParams();
    for(const serialnu of serialnus) {
        urlSearchParams.append("serialnu", serialnu);
    }
    for(const documnum of documnums) {
        urlSearchParams.append("documnum", documnum);
    }
    for(const docnumbe of docnumbes) {
        urlSearchParams.append("docnumbe", docnumbe);
    }
    return request.deleteWithToken(SALES_ANULATE + urlSearchParams.toString());
}

//#endregion

//#region REPORTS

export const salesDetailedDiaryBook = (year: string, month: string) => {
    return request.getWithToken(SALES_DETAILED_DIARY_BOOK + year + '/' + month);
}

export const kardex = (skucodee: string, year: string, month: string) => {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append("skucodee", skucodee);
    return request.getWithToken(KARDEX + year + '/' + month + '?' + urlSearchParams.toString());
}

export const invoicesCharge = (initdate: string, endidate: string) => {
    return request.getWithToken(INVOICES_CHARGE + initdate + '/' + endidate);
}

//#endregion