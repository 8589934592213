import React from 'react';
import {createUseStyles} from 'react-jss';

const left = {
    width: '7rem',
    top: '50%',
    left: '110%',
    transform: 'translateY(-50%)',
    fontSize: '0.75rem',
    '&:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        right: '100%',
        marginTop: '-0.313rem',
        borderWidth: '0.313rem',
        borderStyle: 'solid',
        borderColor: 'transparent #3B3B3B transparent transparent',
    }
}

const bottom = {
    width: '3rem',
    top: '150%',
    left: '50%',
    transform: 'translateX(-50%)',
    fontSize: '0.5rem',
    '&:after': {
        content: '""',
        position: 'absolute',
        bottom: '100%',
        left: '50%',
        marginLeft: '-0.313rem',
        borderWidth: '0.313rem',
        borderStyle: 'solid',
        borderColor: 'transparent transparent #3B3B3B transparent',
    }
}

const format = {
    left,
    bottom
}

const useStyles = createUseStyles({
    text: {},
    container: (props: any) => ({
        position: 'relative',
        // display: 'inline-block',
        '& $text': {
            visibility: 'hidden',
            width: props.width,
            backgroundColor: '#3B3B3B',
            color: '#fff',
            textAlign: 'center',
            borderRadius: '0.375rem',
            padding: '0.5rem 0.5rem',
            position: 'absolute',
            zIndex: 9999999,
            top: props.top,
            left: props.left,
            transform: props.transform,
            fontSize: props.fontSize,
            fontFamily: '"Work Sans", sans-serif',
            '&:after': props['&:after'],
            boxShadow: '0rem 0rem 0.3rem 0px #3B3B3B'
        },
        '&:hover': {
            '& $text': {
                visibility: 'visible'
            }
        }
    })
})

interface Tooltip {
    children: React.ReactNode
    text: string
    position?: string
}

const Tooltip = (props: Tooltip) => {
    const classes = useStyles(format[props.position]);
    return (
        <div className={classes.container}>
            <span className={classes.text}>{props.text}</span>
            {props.children}
        </div>
    )
}

Tooltip.defaultProps = {
    position: 'left'
}

export default Tooltip;