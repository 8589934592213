import React from 'react';
import { Backdrop, Button, ButtonGroup, Checkbox, CircularProgress, createStyles, FormControl, FormControlLabel, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Select, Snackbar, Switch, TextField, Theme, Tooltip, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { purchasesInitFormRequest, purchasesInitUpdateFormRequest, purchasesInsertRequest, purchasesUpdateRequest, purchasesDeleteRequest } from 'penta-redux/actions/shoppingsActions';
import { currencyExchangeRateRequest } from 'penta-redux/actions/generalActions';
import history from '../../../utils/history';
import './purchases.scss'
import FormFooter from 'penta-components/base/formFooter';
import { MessageBox } from 'penta-components/base/messageBox';
import { purchaseEntity } from 'models/shoppingModels';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import EditableCell from 'penta-components/base/editableCell';
import { Alert } from '@material-ui/lab';
import pipelines from 'penta-services';
import ScreenLoader from 'penta-components/base/screenLoader';

const header = ["", "Producto", "Descripcion", "Unidad", "Cuenta Contable", "Denominacion", "Impuesto", "Incluye", "Cantidad", "Precio Unitario", "Subtotal", "IGV", "Total", "Bonif."];
const currentFiscyear = localStorage.getItem('currentFiscyear');

const useStyles = (theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        formControl: {
            margin: 0,
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    });

const defaultPurchasesEntity: purchaseEntity = {
    serialnu: '',
    documnum: '',
    officode: '',
    docsuppl: '',
    emitdate: moment().format('YYYY-MM-DD'),
    expidate: moment().format('YYYY-MM-DD'),
    recedate: moment().format('YYYY-MM-DD'),
    paycondi: '',
    topgrava: '0.00',
    topinafe: '0.00',
    topexone: '0.00',
    topgrati: '0.00',
    totaltax: '0.00',
    partotal: '0.00',
    percepci: '0.00',
    fleteamo: '0.00',
    totalamo: '0.00',
    amoupaid: '0.00',
    balanpay: '0.00',
    recstatu: '',
    currency: '',
    curreval: 3.2,
    typdocum: '',
    acpemont: moment().format('MM'),
    acpeyear: currentFiscyear,
    accaccou: null,
    wharcode: '',
    purcorde: '',
    fiscyear: +currentFiscyear,
    amountss: [],
    balances: [],
    voucnumb: null,
    opertype: null,
    detrdate: null,
    detrnumb: null,
    pbl_purchdet: []
}

interface FormPurchasesState {
    purchasesEntity: purchaseEntity
    title: string
    department: string
    province: string
    itemnumb: number
    snackbar: boolean
    freeEdit: boolean
    edit: boolean,
    enabledDetract: boolean
    error: {[key: string]: string | boolean}
}

class FormPurchases extends React.Component<any, FormPurchasesState> {

    constructor(props: any) {
        super(props);
        defaultPurchasesEntity.pbl_purchdet = [];
        this.state = {
            purchasesEntity: defaultPurchasesEntity,
            department: '',
            province: '',
            itemnumb: 0,
            title: 'Agregar Compras',
            snackbar: false,
            freeEdit: false,
            edit: false,
            enabledDetract: false,
            error: {
                message: '',
                state: false
            }
        }
    }

    onChangeRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            ...value
        }))
    }

    onChangePurchasesRoot = async (value: any) => {
        // console.log(value);
        const { tables } = this.props.shoppingsReducers;
        const key = Object.keys(value)[0];
        if(key === 'percepci') {
            const { purchasesEntity } = this.state;
            const subtotal = purchasesEntity.partotal;
            const flete = purchasesEntity.fleteamo;
            const total = parseFloat(subtotal.replace(/,/g, '')) + parseFloat(flete.replace(/,/g, '')) + parseFloat(value.percepci.replace(/,/g, ''));
            this.setState((state) => ({
                ...state,
                purchasesEntity: {
                    ...state.purchasesEntity,
                    ...value,
                    totalamo: String.formatNumber.formatear(total)
                }
            }));
        }
        else if(key === 'fleteamo') {
            const { purchasesEntity } = this.state;
            const subtotal = purchasesEntity.partotal;
            const percepcion = purchasesEntity.percepci;
            const total = parseFloat(subtotal.replace(/,/g, '')) + parseFloat(percepcion.replace(/,/g, '')) + parseFloat(value.fleteamo.replace(/,/g, ''));
            this.setState((state: any) => ({
                ...state,
                purchasesEntity: {
                    ...state.purchasesEntity,
                    ...value,
                    totalamo: String.formatNumber.formatear(total)
                }
            }));
        }
        else if(key === 'paycondi') {
            const { purchasesEntity } = this.state;
            const { definition } = this.props.shoppingsReducers.tables;
            const emitdate = purchasesEntity.emitdate;
            let days = definition.find((x: any) => x.deficode === value.paycondi).defivalu;
            this.setState((state: any) => ({
                ...state,
                purchasesEntity: {
                    ...state.purchasesEntity,
                    ...value,
                    expidate: moment(emitdate).add(days, 'days').format('YYYY-MM-DD')
                }
            }))
        }
        else if(key === 'currency' || key === 'curreval') {
            const { pbl_purchdet } = this.state.purchasesEntity;
            this.setState((state) => ({
                ...state,
                purchasesEntity: {
                    ...state.purchasesEntity,
                    ...value
                }
            }))
            setTimeout(() => {
                for(let i = 0; i < pbl_purchdet.length; i++) {
                    this.onEditCell({index: i, field: 'select' }, (pbl_purchdet[i] as any).select)
                }
            }, 0);
            
        }
        else if(key === 'emitdate') {
            const { purchasesEntity } = this.state;
            const { definition } = this.props.shoppingsReducers.tables;
            const emitdate = value.emitdate;
            let days = definition.find((x: any) => x.deficode === purchasesEntity.paycondi).defivalu;
            this.setState((state) => ({
                ...state,
                purchasesEntity: {
                    ...state.purchasesEntity,
                    ...value,
                    expidate: moment(emitdate).add(days, 'days').format('YYYY-MM-DD'),
                    recedate: moment(emitdate).format('YYYY-MM-DD'),
                }
            }))
            this.props.currencyExchangeRateRequest(moment(value[key]).format('YYYY-MM-DD'), this.onExchangeRateCallback);
            
        }
        else
            this.setState((state) => ({
                ...state,
                purchasesEntity: {
                    ...state.purchasesEntity,
                    ...value
                }
            }))
    }

    onExchangeRateCallback = () => {
        const { tables } = this.props.generalReducers;
        const { pbl_purchdet } = this.state.purchasesEntity;
        // console.log(tables.exchangeRate);
        if(tables.exchangeRate !== null) {
            this.setState((state) => ({
                ...state,
                purchasesEntity: {
                    ...state.purchasesEntity,
                    curreval: tables.exchangeRate.venta
                }
            }))
        }
        setTimeout(() => {
            for(let i = 0; i < pbl_purchdet.length; i++) {
                this.onEditCell({index: i, field: 'select' }, (pbl_purchdet[i] as any).select)
            }
        }, 0);
    }

    onCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        history.push('/papp/shopping/compras');
    }

    onContinueCallback = () => {
        const newPurchasesEntity = defaultPurchasesEntity;
        newPurchasesEntity.pbl_purchdet = [];
        this.setState({
            purchasesEntity: newPurchasesEntity,
            department: '',
            province: '',
            itemnumb: 0,
            snackbar: true
        });
        this.props.purchasesInitFormRequest(this.callbackInit);
    }

    isNotValidForm = () => {
        const { acc_planacco } = this.props.shoppingsReducers.tables;
        const { purchasesEntity } = this.state;
        if((purchasesEntity.serialnu || '') === '') {
            this.setState({error: {state: true, message: `El numero de serie no puede estar vacio`}})
            console.log('asas-1')
            return true;
        }
        if(purchasesEntity.serialnu.length < 4) {
            this.setState({error: {state: true, message: `El numero de serie no puede ser menor a 4`}})
            console.log('asas-2')
            return true;
        }
        if((purchasesEntity.documnum || '') === '') {
            this.setState({error: {state: true, message: `El numero de documento no puede estar vacio`}})
            return true;
        }
        const date = moment(purchasesEntity.emitdate).format('YYYY') + '' + moment(purchasesEntity.emitdate).format('MM')
        const contableDate = purchasesEntity.acpeyear + '' + purchasesEntity.acpemont
        if(date > contableDate) {
            this.setState({error: {state: true, message: `El año de la fecha de emision no puede ser diferente al año contable`}})
            return true;
        }
        // if(moment(purchasesEntity.emitdate).format('MM') > purchasesEntity.acpemont) {
        //     this.setState({error: {state: true, message: `El mes de la fecha de emision debe ser menor o igual al mes contable`}})
        //     return true;
        // }
        if((purchasesEntity.docsuppl || '') === '') {
            this.setState({error: {state: true, message: `El proveedor no puede estar vacio`}})
            return true;
        }
        for (const iterator of purchasesEntity.pbl_purchdet) {
            if(!acc_planacco.find(x => x.accaccou === iterator.accaccou))  {
                this.setState({error: {state: true, message: `El numero de cuenta ${iterator.accaccou} en el item nro ${iterator.itemnumb} no es valido`}})
                return true;
            }
        }
        return false;
    }

    onSubmitContinue = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const { params } = this.props.match;
        if(this.isNotValidForm()) return;
        if (params.serialnu !== undefined) {
            const purchasesEntity = this.state.purchasesEntity;
            if(!this.state.enabledDetract) {
                purchasesEntity.detrdate = null;
                purchasesEntity.detrnumb = null;
            }
            this.props.purchasesUpdateRequest(purchasesEntity, null);
        } else {
            this.props.purchasesInsertRequest(this.state.purchasesEntity, this.onContinueCallback);
        }
        this.setState({error: {state: false, message: ``}})
    }

    onSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const { params } = this.props.match;
        if(this.isNotValidForm()) return;
        if (params.serialnu !== undefined) {
            const purchasesEntity = this.state.purchasesEntity;
            if(!this.state.enabledDetract) {
                purchasesEntity.detrdate = null;
                purchasesEntity.detrnumb = null;
            }
            this.props.purchasesUpdateRequest(purchasesEntity, null);
        } else {
            this.props.purchasesInsertRequest(this.state.purchasesEntity, null);
        }
        this.setState({error: {state: false, message: ``}})
    }

    onEditCell = (props: any, value: any) => {
        const { definition } = this.props.shoppingsReducers.tables;
        const pbl_purchdet: any = this.state.purchasesEntity.pbl_purchdet;
        pbl_purchdet[props.index][props.field] = value;
        const taxvalue = definition.find((x: any) => x.deficode === pbl_purchdet[props.index]['taxtypee']).defivalu;
        let quantity = pbl_purchdet[props.index]['quantity'].replace(/,/g, '');
        let price = pbl_purchdet[props.index]['unitpric'].replace(/,/g, '');
        let subtotal = 0;
        let total = 0;

        if(!this.state.freeEdit) {
            if(props.field === 'amototal')
            {
                subtotal = +value.replace(/,/g, '') / (1 + parseFloat(taxvalue));
                price = +value.replace(/,/g, '') / quantity;
                pbl_purchdet[props.index]['unitpric'] = String.formatNumber.formatear(Math.round(price * 1000000) / 1000000, '000000');
                pbl_purchdet[props.index]['subtotal'] = String.formatNumber.formatear(Math.round(subtotal * 100) / 100, '00');
                pbl_purchdet[props.index]['totaltax'] = String.formatNumber.formatear(Math.round((+value.replace(/,/g, '') - subtotal) * 100) / 100, '00');
            }
            else if(props.field === 'totaltax')
            {
                total = +pbl_purchdet[props.index]['amototal'].replace(/,/g, '');
                subtotal = total - +value.replace(/,/g, '');
                price = total / quantity;
                pbl_purchdet[props.index]['subtotal'] = String.formatNumber.formatear(Math.round(subtotal * 1000000) / 1000000, '000000');
                pbl_purchdet[props.index]['unitpric'] = String.formatNumber.formatear(Math.round(price * 1000000) / 1000000, '000000');
            }
            else if(props.field === 'subtotal')
            {
                total = +value.replace(/,/g, '') * (1 + parseFloat(taxvalue));
                price = total / quantity;
                pbl_purchdet[props.index]['unitpric'] = String.formatNumber.formatear(Math.round(price * 1000000) / 1000000, '000000');
                pbl_purchdet[props.index]['amototal'] = String.formatNumber.formatear(Math.round(total * 100) / 100, '00');
                pbl_purchdet[props.index]['totaltax'] = String.formatNumber.formatear(Math.round((total - +value.replace(/,/g, '')) * 100) / 100, '00');
            }
            else if(props.field === 'unitpric')
            {
                total = quantity * +value.replace(/,/g, '');
                subtotal = total / (1 + parseFloat(taxvalue));
                pbl_purchdet[props.index]['amototal'] = String.formatNumber.formatear(Math.round(total * 100) / 100, '00');
                pbl_purchdet[props.index]['subtotal'] = String.formatNumber.formatear(Math.round(subtotal * 100) / 100, '00');
                pbl_purchdet[props.index]['totaltax'] = String.formatNumber.formatear(Math.round((total - subtotal) * 100) / 100, '00');
            }
            else if(props.field === 'quantity') 
            {
                total = price * +value.replace(/,/g, '');
                subtotal = total / (1 + parseFloat(taxvalue));
                pbl_purchdet[props.index]['amototal'] = String.formatNumber.formatear(Math.round(total * 100) / 100, '00');
                pbl_purchdet[props.index]['subtotal'] = String.formatNumber.formatear(Math.round(subtotal * 100) / 100, '00');
                pbl_purchdet[props.index]['totaltax'] = String.formatNumber.formatear(Math.round((total - subtotal) * 100) / 100, '00');
            }
            else if(props.field === 'taxtypee')
            {
                total = price * quantity;
                subtotal = total / (1 + parseFloat(taxvalue));
                pbl_purchdet[props.index]['amototal'] = String.formatNumber.formatear(Math.round(total * 100) / 100, '00');
                pbl_purchdet[props.index]['subtotal'] = String.formatNumber.formatear(Math.round(subtotal * 100) / 100, '00');
                pbl_purchdet[props.index]['totaltax'] = String.formatNumber.formatear(Math.round((total - subtotal) * 100) / 100, '00');
            }
        } else {
            if(props.field === 'amototal')
            {
                price = +value.replace(/,/g, '') / quantity;
                pbl_purchdet[props.index]['unitpric'] = String.formatNumber.formatear(Math.round(price * 1000000) / 1000000, '000000');
            }
            else if(props.field === 'totaltax')
            {
                total = +pbl_purchdet[props.index]['amototal'].replace(/,/g, '');
                subtotal = total - +value.replace(/,/g, '');
                price = total / quantity;
                pbl_purchdet[props.index]['unitpric'] = String.formatNumber.formatear(Math.round(price * 1000000) / 1000000, '000000');
            }
            else if(props.field === 'subtotal')
            {
                total = +value.replace(/,/g, '') * (1 + parseFloat(taxvalue));
                price = total / quantity;
                pbl_purchdet[props.index]['unitpric'] = String.formatNumber.formatear(Math.round(price * 1000000) / 1000000, '000000');
            }
        }
        
        if(pbl_purchdet[props.index]['incluigv'] == 0) {
            pbl_purchdet[props.index]['subprice'] = pbl_purchdet[props.index]['unitpric']
        } 
        else if(pbl_purchdet[props.index]['incluigv'] == 1) {
            const subprice = (parseFloat(pbl_purchdet[props.index]['unitpric'].replace(/,/g, '')) / (1 + parseFloat(taxvalue)));
            pbl_purchdet[props.index]['subprice'] = String.formatNumber.formatear(Math.round(subprice * 1000000) / 1000000. , '000000');
            // pbl_purchdet[props.index]['totaltax'] = String.formatNumber.formatear(Math.round(taxvalue * 100) / 100, '00');
        }

        let _subtotalG = 0;
        let _igvG = 0;
        let _totalG = 0;
        let _tog = 0;
        let _toe = 0;
        let _toi = 0;
        let _tob = 0;
        let _flete = parseFloat(this.state.purchasesEntity.fleteamo.replace(/,/g, ''));
        let _percepcion = parseFloat(this.state.purchasesEntity.percepci.replace(/,/g, ''));

        pbl_purchdet.forEach((item: any) => {
            if(!item.isbonuss) {
                if(item['taxtypee'] === '00000016') {
                    _tog += parseFloat(item.subtotal.replace(/,/g, ''));
                    _igvG += (parseFloat(item.amototal.replace(/,/g, '')) - parseFloat(item.subtotal.replace(/,/g, '')));
                }
                else if(item['taxtypee'] === '00000017') {
                    _toe += parseFloat(item.subtotal.replace(/,/g, ''));
                }
                else if(item['taxtypee'] === '00000018') {
                    _toi += parseFloat(item.subtotal.replace(/,/g, ''));
                }
                _subtotalG += parseFloat(item.amototal.replace(/,/g, ''));
            }
            else {
                _tob += parseFloat(item.subtotal.replace(/,/g, ''));
            }
        });

        _tog = Math.round(_tog*100)/100;
        _toe = Math.round(_toe*100)/100;
        _toi = Math.round(_toi*100)/100;
        _tob = Math.round(_tob*100)/100;
        _subtotalG = Math.round(_subtotalG*100)/100;
        _igvG = Math.round(_igvG*100)/100;
        // _igvG = _tog * parseFloat(definition.find((x: any) => x.deficode === '00000016').defivalu)
        // _igvG = Math.round(_igvG*100)/100;
        _flete = Math.round(_flete*100)/100;
        _percepcion = Math.round(_percepcion*100)/100;
        _totalG = _subtotalG + _flete + _percepcion;
        _totalG = Math.round(_totalG*100)/100;

        const companyCurrency = '00000014';
        const documentCurrency = this.state.purchasesEntity.currency;
        const change = this.state.purchasesEntity.curreval;
        const _taxvalue = definition.find((x: any) => x.deficode === '00000016').defivalu;
        let amoupaid = +this.state.purchasesEntity.amoupaid.replace(/,/g, '');
        let balanpay = _subtotalG - amoupaid;
        amoupaid = Math.round(amoupaid*100)/100;
        balanpay = Math.round(balanpay*100)/100;
        let amountssG = {};
        let balancesG = {};
        if(companyCurrency !== documentCurrency) {
            pbl_purchdet[props.index].amountss = {
                [companyCurrency]: {
                    amototal: Math.round(+pbl_purchdet[props.index]['amototal'].replace(/,/g, '') * change*100)/100,
                    subtotal: Math.round(Math.round(pbl_purchdet[props.index]['amototal'].replace(/,/g, '')*change*100)/100 / (1+(+taxvalue)) *100)/100,
                    unitpric: Math.round(+pbl_purchdet[props.index]['unitpric'].replace(/,/g, '') * change*100)/100,
                    quantity: Math.round(+pbl_purchdet[props.index]['quantity'].replace(/,/g, '') * 100)/100,
                    subprice: Math.round(+pbl_purchdet[props.index]['subprice'].replace(/,/g, '') * change*100)/100,
                }
            }
            const topgrava = Math.round(Math.round((_subtotalG - _toe - _toi)* change * 100)/100 / (1+(+_taxvalue))*100)/100;
            const topexone = Math.round(_toe * change*100)/100;
            const topinafe = Math.round(_toi * change*100)/100;
            const topgrati = Math.round(_tob * change*100)/100;
            const totaltax = Math.round((_subtotalG - _toe - _toi) * change * 100) / 100 - topgrava;
            const partotal = topgrava + topexone + topinafe + totaltax;
            const fleteamo = Math.round(_flete * change*100)/100;
            const percepci = Math.round(_percepcion * change*100)/100;
            const totalamo = partotal + fleteamo + percepci;
            amountssG = {
                [companyCurrency]: {
                    topgrava: topgrava,
                    topexone: topexone,
                    topinafe: topinafe,
                    topgrati: topgrati,
                    totalamo: totalamo,
                    totaltax: totaltax,
                    partotal: partotal,
                    fleteamo: fleteamo,
                    percepci: percepci
                }
            },
            balancesG = {
                [companyCurrency]: {
                    balanpay: Math.round(balanpay * change*100)/100,
                    amoupaid: Math.round(amoupaid * change*100)/100
                }
            }
            console.log(Math.round((_subtotalG - _toe - _toi)* change * 100)/100 / (1+(+_taxvalue)));
            console.log(Math.round(Math.round((_subtotalG - _toe - _toi)* change * 100)/100 / (1+(+_taxvalue))*100)/100);
            console.log(Math.round(balanpay * change*100)/100);
        }
        else {
            pbl_purchdet[props.index].amountss = {
                [companyCurrency]: {
                    amototal: +pbl_purchdet[props.index]['amototal'].replace(/,/g, ''),
                    subtotal: +pbl_purchdet[props.index]['subtotal'].replace(/,/g, ''),
                    unitpric: +pbl_purchdet[props.index]['unitpric'].replace(/,/g, ''),
                    quantity: +pbl_purchdet[props.index]['quantity'].replace(/,/g, ''),
                    subprice: +pbl_purchdet[props.index]['subprice'].replace(/,/g, ''),
                }
            }
            amountssG = {
                [companyCurrency]: {
                    topgrava: _tog,
                    topexone: _toe,
                    topinafe: _toi,
                    topgrati: _tob,
                    totalamo: _totalG,
                    totaltax: _igvG,
                    partotal: _subtotalG,
                    fleteamo: _flete,
                    percepci: _percepcion
                }
            }
            balancesG = {
                [companyCurrency]: {
                    balanpay: balanpay,
                    amoupaid: amoupaid
                }
            }
        }

        this.setState((state: FormPurchasesState) => ({
            ...state,
            purchasesEntity: {
                ...state.purchasesEntity,
                pbl_purchdet,
                topgrava: String.formatNumber.formatear(_tog),
                topexone: String.formatNumber.formatear(_toe),
                topinafe: String.formatNumber.formatear(_toi),
                topgrati: String.formatNumber.formatear(_tob),
                totalamo: String.formatNumber.formatear(_totalG),
                totaltax: String.formatNumber.formatear(_igvG),
                partotal: String.formatNumber.formatear(_subtotalG),
                fleteamo: String.formatNumber.formatear(_flete),
                percepci: String.formatNumber.formatear(_percepcion),
                amoupaid: String.formatNumber.formatear(amoupaid),
                balanpay: String.formatNumber.formatear(balanpay),
                amountss: amountssG,
                balances: balancesG
            }
        }))
    }

    onAddDetail = () => {
        const { pbl_purchdet } = this.state.purchasesEntity;
        const { definition, whp_unitmeas } = this.props.shoppingsReducers.tables;
        const itemnumb = this.state.itemnumb + 1;
        const newItem: any = {
            skucodee: null,
            itemnumb: itemnumb,
            taxtypee: definition.filter((x: any) => x.defitype === 'TXTYP')[0].deficode,
            incluigv: 1,
            quantity: '1.00',
            unitpric: '0.000000',
            subprice: '0.000000',
            subtotal: '0.00',
            totaltax: '0.00',
            amototal: '0.00',
            accaccou: null,
            fiscyear: null,
            isbonuss: false,
            prodname: '',
            meascode: 'UNI',//whp_unitmeas[0].meascode,
            amountss: {},
            select: false
        };
        (pbl_purchdet as any).push(newItem);
        this.setState((state: any) => ({
            ...state,
            itemnumb,
            purchasesEntity: {
                ...state.purchasesEntity,
                pbl_purchdet,
            }
        }))
    }

    onDeleteDetail = () => {
        const { definition } = this.props.shoppingsReducers.tables;
        let { pbl_purchdet } = this.state.purchasesEntity;        
        pbl_purchdet = pbl_purchdet.filter((x: any) => x.select === false || x.select === undefined);
        const taxvalue = definition.find((x: any) => x.deficode === '00000016').defivalu;
        
        let _subtotalG = 0;
        let _igvG = 0;
        let _totalG = 0;
        let _tog = 0;
        let _toe = 0;
        let _toi = 0;
        let _tob = 0;
        let _flete = parseFloat(this.state.purchasesEntity.fleteamo.replace(/,/g, ''));
        let _percepcion = parseFloat(this.state.purchasesEntity.percepci.replace(/,/g, ''));
        pbl_purchdet.forEach((item: any) => {
            if(!item.isbonuss) {
                if(item['taxtypee'] === '00000016') {
                    _tog += parseFloat(item.subtotal.replace(/,/g, ''));
                    _igvG += (parseFloat(item.amototal.replace(/,/g, '')) - parseFloat(item.subtotal.replace(/,/g, '')));
                }
                else if(item['taxtypee'] === '00000017') {
                    _toe += parseFloat(item.subtotal.replace(/,/g, ''));
                }
                else if(item['taxtypee'] === '00000018') {
                    _toi += parseFloat(item.subtotal.replace(/,/g, ''));
                }
                _subtotalG += parseFloat(item.amototal.replace(/,/g, ''));
            }
            else {
                _tob += parseFloat(item.subtotal.replace(/,/g, ''));
            }
        });

        _tog = Math.round(_tog*100)/100;
        _toe = Math.round(_toe*100)/100;
        _toi = Math.round(_toi*100)/100;
        _tob = Math.round(_tob*100)/100;
        _subtotalG = Math.round(_subtotalG*100)/100;
        _igvG = Math.round(_igvG*100)/100;
        // _igvG = _tog * parseFloat(definition.find((x: any) => x.deficode === '00000016').defivalu)
        // _igvG = Math.round(_igvG*100)/100;
        _flete = Math.round(_flete*100)/100;
        _percepcion = Math.round(_percepcion*100)/100;
        _totalG = _subtotalG + _flete + _percepcion;
        _totalG = Math.round(_totalG*100)/100;

        const companyCurrency = '00000014';
        const documentCurrency = this.state.purchasesEntity.currency;
        const change = this.state.purchasesEntity.curreval;
        let amoupaid = +this.state.purchasesEntity.amoupaid.replace(/,/g, '');
        let balanpay = _subtotalG - amoupaid;
        amoupaid = Math.round(amoupaid*100)/100;
        balanpay = Math.round(balanpay*100)/100;
        let amountssG = {};
        let balancesG = {};
        if(companyCurrency !== documentCurrency) {
            const topgrava = Math.round(Math.round((_subtotalG - _toe - _toi)* change * 100)/100 / (1+(+taxvalue))*100)/100;
            const topexone = Math.round(_toe * change*100)/100;
            const topinafe = Math.round(_toi * change*100)/100;
            const topgrati = Math.round(_tob * change*100)/100;
            const totaltax = Math.round((_subtotalG - _toe - _toi) * change * 100) / 100 - topgrava;
            const partotal = topgrava + topexone + topinafe + totaltax;
            const fleteamo = Math.round(_flete * change*100)/100;
            const percepci = Math.round(_percepcion * change*100)/100;
            const totalamo = partotal + fleteamo + percepci;
            amountssG = {
                [companyCurrency]: {
                    topgrava: topgrava,
                    topexone: topexone,
                    topinafe: topinafe,
                    topgrati: topgrati,
                    totalamo: totalamo,
                    totaltax: totaltax,
                    partotal: partotal,
                    fleteamo: fleteamo,
                    percepci: percepci
                }
            }
            balancesG = {
                [companyCurrency]: {
                    balanpay: Math.round(balanpay * change * 100) / 100,
                    amoupaid: Math.round(amoupaid * change * 100) / 100
                }
            }
        }
        else {
            amountssG = {
                [companyCurrency]: {
                    topgrava: _tog,
                    topexone: _toe,
                    topinafe: _toi,
                    topgrati: _tob,
                    totalamo: _totalG,
                    totaltax: _igvG,
                    partotal: _subtotalG,
                    fleteamo: _flete,
                    percepci: _percepcion
                }
            },
            balancesG = {
                [companyCurrency]: {
                    balanpay: balanpay,
                    amoupaid: amoupaid
                }
            }
        }

        this.setState((state: any) => ({
            ...state,
            purchasesEntity: {
                ...state.purchasesEntity,
                pbl_purchdet,
                topgrava: String.formatNumber.formatear(_tog),
                topexone: String.formatNumber.formatear(_toe),
                topinafe: String.formatNumber.formatear(_toi),
                topgrati: String.formatNumber.formatear(_tob),
                totalamo: String.formatNumber.formatear(_totalG),
                totaltax: String.formatNumber.formatear(_igvG),
                partotal: String.formatNumber.formatear(_subtotalG),
                fleteamo: String.formatNumber.formatear(_flete),
                percepci: String.formatNumber.formatear(_percepcion),
                amoupaid: String.formatNumber.formatear(amoupaid),
                balanpay: String.formatNumber.formatear(balanpay),
                amountss: amountssG,
                balances: balancesG
            }
        }));
    }

    renderDetail = () => {
        const { products, definition, whp_unitmeas, acc_planacco } = this.props.shoppingsReducers.tables;
        const { pbl_purchdet } = this.state.purchasesEntity;
        
        return pbl_purchdet.map((item: any, i: any) => (
            <tr key={ i } >
                <td>
                    <input type="checkbox"
                    checked={ item.select } 
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'select' }, 
                            e.target.checked
                        )}
                    />
                </td>
                <td style={{width:'150px'}}>
                    <Autocomplete
                        id="skucodee"
                        freeSolo
                        options={products}
                        getOptionLabel={ (x: any) => x.skucodee }
                        value={ products.find((x: any) => x.skucodee === item.skucodee) || null }
                        onChange={(e: any, value) => {
                            this.onEditCell({index: i, field: 'skucodee'}, value.skucodee);
                            this.onEditCell({index: i, field: 'prodname'}, value.prodname);
                            this.onEditCell({index: i, field: 'meascode'}, value.meascode);
                            this.onEditCell({index: i, field: 'accaccou'}, value.purcacca);
                            this.onEditCell({index: i, field: 'fiscyear'}, +currentFiscyear);
                        }}
                        renderInput={(params) => (
                            <TextField {...params} 
                            style={ { marginTop: 0 } } margin="normal" variant="outlined" />
                        )}
                    />
                </td>
                <td className={(item.skucodee || "") !== "" ? "disabled" : ""} style={{width:'200px'}}>
                    <input type="text"
                    value={ item.prodname }
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'prodname' }, 
                            e.target.value
                        )}
                    disabled={(item.skucodee || "") !== "" ? true : false}
                    />
                </td>
                <td className={(item.skucodee || "") !== "" ? "disabled" : ""} style={{width:'100px'}}>
                    <select style={{width:'100%'}} value={ item.meascode } 
                        onChange={ (e: any) => { 
                            this.onEditCell({index: i, field: 'meascode'}, e.target.value)
                        }}
                        disabled={(item.skucodee || "") !== "" ? true : false}
                    >
                        { whp_unitmeas.map((item:any) => <option value={item.meascode} key={item.meascode}>{item.measname}</option>) }
                    </select>
                </td>
                <td className={ (item.skucodee || "") !== "" ? "disabled" : "" } style={{width:'150px'}}>
                    <Autocomplete
                        id="accaccou"
                        freeSolo
                        options={acc_planacco}
                        getOptionLabel={ (x: any) => x.accaccou }
                        value={ acc_planacco.find((x: any) => x.accaccou === item.accaccou) || null }
                        onChange={(e: any, value) => {
                            this.onEditCell({index: i, field: 'accaccou'}, value.accaccou);
                            this.onEditCell({index: i, field: 'fiscyear'}, +currentFiscyear);
                        }}
                        renderInput={(params) => (
                            <TextField {...params} 
                            onBlur={(e) => { 
                                const a = acc_planacco.find((x: any) => x.accaccou === e.target.value);
                                if(a) {
                                    this.onEditCell({index: i, field: 'accaccou'}, a.accaccou);
                                    this.onEditCell({index: i, field: 'fiscyear'}, +currentFiscyear);
                                }
                            }}
                            style={ { marginTop: 0 } } margin="normal" variant="outlined" />
                        )}
                        disabled={ item.skucodee || '' !== '' }
                    />
                </td>
                <td className="disabled" style={{width:'200px'}}>
                    <input type="text"
                    value={ acc_planacco.find((x: any) => x.accaccou === item.accaccou)?.denomina || '' }
                    disabled
                    />
                </td>
                <td>
                    <select style={{width:'100%'}} value={ item.taxtypee } 
                        onChange={ (e: any) => { 
                            this.onEditCell({index: i, field: 'taxtypee'}, e.target.value)
                        }}
                    >
                        { definition.filter((x: any) => x.defitype === 'TXTYP').map((item:any) => <option value={item.deficode} key={item.deficode}>{item.definame}</option>) }
                    </select>
                </td>
                <td>
                    <select style={{width:'100%'}} value={ item.incluigv } 
                        onChange={ (e: any) => { 
                            this.onEditCell({index: i, field: 'incluigv'}, +e.target.value)
                        }}
                    >
                        <option value={1}>Incluye IGV</option>
                        <option value={0}>No Incluye IGV</option>
                    </select>
                </td>
                <td>
                    <input type="text"
                    value={ item.quantity } 
                    onFocus={(e) => e.target.select() }
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'quantity' }, 
                            e.target.value
                        )}
                    style={{ textAlign: 'right' }}
                    />
                </td>
                <td className="disabled">
                    <input type="text" 
                    value={ item.unitpric } 
                    onFocus={(e) => e.target.select() }
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'unitpric' }, 
                            e.target.value
                        )}
                    style={{ textAlign: 'right' }}
                    disabled
                    />
                </td>
                <td className={parseInt(item.incluigv) == 0 ? "" : this.state.freeEdit ? "" : "disabled"}>
                    <input type="text"
                    value={ item.subtotal } 
                    onFocus={(e) => e.target.select() }
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'subtotal' }, 
                            e.target.value
                        )}
                    style={{ textAlign: 'right' }}
                    disabled={parseInt(item.incluigv) == 0 ? false : this.state.freeEdit ? false : true}
                    />
                </td>
                <td className={this.state.freeEdit ? "" : "disabled"}>
                    <input type="text"
                    value={ item.totaltax } 
                    onFocus={(e) => e.target.select() }
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'totaltax' }, 
                            e.target.value
                        )}
                    style={{ textAlign: 'right' }}
                    disabled={this.state.freeEdit ? false : true}
                    />
                </td>
                <td className={parseInt(item.incluigv) == 1 ? "" : this.state.freeEdit ? "" : "disabled"}>
                    <input type="text"
                    value={ item.amototal } 
                    onFocus={(e) => e.target.select() }
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'amototal' }, 
                            e.target.value
                        )}
                    style={{ textAlign: 'right' }}
                    disabled={parseInt(item.incluigv) == 1 ? false : this.state.freeEdit ? false : true}
                    />
                </td>
                <td>
                    <input type="checkbox"
                    checked={ item.isbonuss } 
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'isbonuss' }, 
                            e.target.checked
                        )}
                    />
                </td>
            </tr>
        ))
    }

    renderGrilla = () => {
        return (
            <div className="form">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className="form__field">
                            <div className="grid__actions">
                                <button className="add" onClick={ (e) => { e.preventDefault(); this.onAddDetail() } }><i className="fas fa-plus"/></button>
                                <button className="remove" onClick={ (e) => { e.preventDefault(); this.onDeleteDetail() } }><i className="fas fa-times"/></button>
                                <button className="free__edit" onClick={ (e) => { e.preventDefault(); this.setState({freeEdit: !this.state.freeEdit}) } }>Calc. Filas <b>{!this.state.freeEdit ? '(Activado)' : '(Desactivado)'}</b></button>
                                <span style={ { marginLeft: '15px' } }>[ENTER]: Agregar nuevo item</span>
                                <span style={ { marginLeft: '15px' } }>[TAB]: Navegar entre las celdas</span>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <EditableCell
                            header={ header }
                            data={ this.renderDetail() }
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }

    callbackInit = () => {
        const { tables } = this.props.shoppingsReducers;
        const paycondi = tables.definition.filter((x: any) => x.defitype === 'PAYCO')[0].deficode
        const typdocum = tables.definition.filter((x: any) => x.defitype === 'TDSUN')[0].deficode
        const currency = tables.definition.filter((x: any) => x.defitype === 'CURCY')[0].deficode
        this.setState((state: any) => ({
            ...state,
            itemnumb: 1,
            purchasesEntity: {
                ...state.purchasesEntity,
                paycondi: paycondi,
                typdocum: typdocum,
                currency: currency,
                curreval: tables.exchangeRate.venta,
                wharcode: tables.wharhous[0].wharcode,
                pbl_purchdet: [
                    {
                        skucodee: null,
                        itemnumb: 1,
                        taxtypee: tables.definition.filter((x: any) => x.defitype === 'TXTYP')[0].deficode,
                        incluigv: 1,
                        quantity: '1.00',
                        unitpric: '0.000000',
                        subprice: '0.000000',
                        subtotal: '0.00',
                        totaltax: '0.00',
                        amototal: '0.00',
                        accaccou: null,
                        fiscyear: null,
                        isbonuss: false,
                        prodname: '',
                        meascode: 'UNI',//tables.whp_unitmeas[0].meascode,
                        select: false
                    }
                ]
            }
        }));
    }

    callbackInitCopy = (purchasesEntity: purchaseEntity) => {
        const { state } = this.props.history.location;
        purchasesEntity.emitdate = moment(purchasesEntity.emitdate).format('YYYY-MM-DD');
        purchasesEntity.expidate = moment(purchasesEntity.expidate).format('YYYY-MM-DD');
        purchasesEntity.recedate = moment(purchasesEntity.recedate).format('YYYY-MM-DD');
        purchasesEntity.detrdate = purchasesEntity.detrdate ? moment(purchasesEntity.detrdate).format('YYYY-MM-DD') : null;
        purchasesEntity.amountss = JSON.parse(purchasesEntity.amountss);
        purchasesEntity.balances = JSON.parse(purchasesEntity.balances);
        for (const iterator of purchasesEntity.pbl_purchdet) {
            iterator.incluigv = iterator.incluigv ? 1 : 0;
            const totaltax = +iterator.amototal.replace(/,/g, '') - +iterator.subtotal.replace(/,/g, '');
            iterator.totaltax = String.formatNumber.formatear(Math.round(totaltax * 100) / 100, '000');
            iterator.amountss = JSON.parse(iterator.amountss);
        }
        let itemnumb = 0;
        if(purchasesEntity.pbl_purchdet.length > 0)
            itemnumb = purchasesEntity.pbl_purchdet[purchasesEntity.pbl_purchdet.length - 1].itemnumb;
        purchasesEntity.officode = state.officode,
        this.setState({
            purchasesEntity,
            itemnumb,
            enabledDetract: (purchasesEntity.detrnumb || '') !== '' && (purchasesEntity.detrdate || '') !== '' ? true : false
        });
        if(state.deleteOrigin) this.props.purchasesDeleteRequest([state.serialnu], [state.documnum], [state.docsuppl], state.filters);
    }

    callbackInitUpdate = (purchasesEntity: purchaseEntity) => {
        purchasesEntity.emitdate = moment(purchasesEntity.emitdate).format('YYYY-MM-DD');
        purchasesEntity.expidate = moment(purchasesEntity.expidate).format('YYYY-MM-DD');
        purchasesEntity.recedate = moment(purchasesEntity.recedate).format('YYYY-MM-DD');
        purchasesEntity.detrdate = purchasesEntity.detrdate ? moment(purchasesEntity.detrdate).format('YYYY-MM-DD') : null;
        purchasesEntity.amountss = JSON.parse(purchasesEntity.amountss);
        purchasesEntity.balances = JSON.parse(purchasesEntity.balances);
        for (const iterator of purchasesEntity.pbl_purchdet) {
            iterator.incluigv = iterator.incluigv ? 1 : 0;
            const totaltax = +iterator.amototal.replace(/,/g, '') - +iterator.subtotal.replace(/,/g, '');
            iterator.totaltax = String.formatNumber.formatear(Math.round(totaltax * 100) / 100, '000');
            iterator.amountss = JSON.parse(iterator.amountss);
        }
        let itemnumb = 0;
        if(purchasesEntity.pbl_purchdet.length > 0)
            itemnumb = purchasesEntity.pbl_purchdet[purchasesEntity.pbl_purchdet.length - 1].itemnumb;
        this.setState({
            purchasesEntity,
            itemnumb,
            enabledDetract: (purchasesEntity.detrnumb || '') !== '' && (purchasesEntity.detrdate || '') !== '' ? true : false
        });
    }

    componentDidMount() {
        const { state } = this.props.history.location;
        const { params } = this.props.match;
        if(state?.method === 'copy') {
            this.props.purchasesInitUpdateFormRequest(state.serialnu, state.documnum, state.docsuppl, this.callbackInitCopy);
        } else {
            if (params.serialnu !== undefined) {
                this.setState({title: 'Editar Compras', edit: true});
                this.props.purchasesInitUpdateFormRequest(params.serialnu, params.documnum, params.docsuppl, this.callbackInitUpdate);
            }
            else {
                this.props.purchasesInitFormRequest(this.callbackInit);
            }
        }
    }

    render() {

        const { purchasesEntity, department, province, title, snackbar, edit, enabledDetract, error } = this.state;
        const { loading, list, tables, failed, failedTitle, failedContent } = this.props.shoppingsReducers;
        const generalTables = this.props.generalReducers.tables;
        const { classes } = this.props;

        return (
            <div style={{overflow: 'auto', height: '100%'}}>
                <Paper className='form__container'>
                    <br/>
                    <Typography variant='h5'>{ title }</Typography>
                    <br/>
                    { failed && <MessageBox title={failedTitle} content={failedContent}/> }
                    { error.state && <MessageBox title='Error' content={error.message as string}/> }
                    <form noValidate autoComplete="off">
                        <Grid container spacing={2} >
                            <Grid item xs={2}>
                                <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                    <InputLabel id="lbl_condicion">Tipo de documento</InputLabel>
                                    <Select
                                        labelId="lbl_typdocum"
                                        id="typdocum"
                                        className='select'
                                        label="Tipo de documento"
                                        fullWidth
                                        value={purchasesEntity.typdocum}
                                        onChange={(e: any) => this.onChangePurchasesRoot({'typdocum': e.target.value})}
                                    >
                                        {
                                            tables.definition.filter((x: any) => x.defitype === 'TDSUN').map((item: any, key: any) => 
                                                <MenuItem value={item.deficode} key={`typdocum_${key}`}>{item.definame}</MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                    <InputLabel id="lbl_acpemont">Periodo contable - Mes</InputLabel>
                                    <Select
                                        labelId="lbl_acpemont"
                                        id="acpemont"
                                        className='select'
                                        label="Periodo contable - Mes"
                                        fullWidth
                                        value={purchasesEntity.acpemont}
                                        onChange={(e: any) => this.onChangePurchasesRoot({'acpemont': e.target.value})}
                                    >
                                        <MenuItem value="01">01</MenuItem>
                                        <MenuItem value="02">02</MenuItem>
                                        <MenuItem value="03">03</MenuItem>
                                        <MenuItem value="04">04</MenuItem>
                                        <MenuItem value="05">05</MenuItem>
                                        <MenuItem value="06">06</MenuItem>
                                        <MenuItem value="07">07</MenuItem>
                                        <MenuItem value="08">08</MenuItem>
                                        <MenuItem value="09">09</MenuItem>
                                        <MenuItem value="10">10</MenuItem>
                                        <MenuItem value="11">11</MenuItem>
                                        <MenuItem value="12">12</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                    <InputLabel id="lbl_acpeyear">Periodo contable - Año</InputLabel>
                                    <Select
                                        labelId="lbl_acpeyear"
                                        id="acpeyear"
                                        className='select'
                                        label="Periodo contable - Año"
                                        fullWidth
                                        value={purchasesEntity.acpeyear}
                                        onChange={(e: any) => this.onChangePurchasesRoot({'acpeyear': e.target.value})}
                                    >
                                        <MenuItem value="2019">2019</MenuItem>
                                        <MenuItem value="2020">2020</MenuItem>
                                        <MenuItem value="2021">2021</MenuItem>
                                        <MenuItem value="2022">2022</MenuItem>
                                        <MenuItem value="2023">2023</MenuItem>
                                        <MenuItem value="2024">2024</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth id="serialnu" label="Serie" variant="outlined" value={ purchasesEntity.serialnu } onChange={(e: any) => this.onChangePurchasesRoot({'serialnu': e.target.value})}/>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth id="documnum" label="Nro. de documento" variant="outlined" value={ purchasesEntity.documnum } onChange={(e: any) => this.onChangePurchasesRoot({'documnum': e.target.value})}/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} >
                            <Grid item xs={3}>
                                <TextField fullWidth type="date" id="emitdate" label="Fecha de emision" variant="outlined" value={ purchasesEntity.emitdate } onChange={(e: any) => this.onChangePurchasesRoot({'emitdate': e.target.value})}/>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                    <InputLabel id="lbl_condicion">Condicion de pago</InputLabel>
                                    <Select
                                        labelId="lbl_condicion"
                                        id="condicion"
                                        className='select'
                                        label="Condicion de pago"
                                        fullWidth
                                        value={purchasesEntity.paycondi}
                                        onChange={(e: any) => this.onChangePurchasesRoot({'paycondi': e.target.value})}
                                    >
                                        {
                                            tables.definition.filter((x: any) => x.defitype === 'PAYCO').map((item: any, key: any) => 
                                                <MenuItem value={item.deficode} key={`condicion_${key}`}>{item.definame}</MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth type="date" id="expidate"  label="Fecha de Vencimiento" variant="outlined" value={ purchasesEntity.expidate } onChange={(e: any) => this.onChangePurchasesRoot({'expidate': e.target.value})}/>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth type="date" id="recedate"  label="Fecha de Recepcion" variant="outlined" value={ purchasesEntity.recedate } onChange={(e: any) => this.onChangePurchasesRoot({'recedate': e.target.value})}/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} >
                            <Grid item xs={6}>
                                <Autocomplete
                                    id="docsuppl"
                                    freeSolo
                                    options={tables.supplier}
                                    getOptionLabel={ (x: any) => x.suppname + " " + x.lastname + " " + x.mlstname + x.businame }
                                    value={ tables.supplier.find((x: any) => x.docnumbe === purchasesEntity.docsuppl) || null }
                                    onChange={(e: any, value) => this.onChangePurchasesRoot({'docsuppl': value.docnumbe, 'accaccou': value.accaccou, 'fiscyear': +currentFiscyear, 'acpeyear': +currentFiscyear})}
                                    renderInput={(params) => (
                                        <TextField {...params} 
                                        style={ { marginTop: 0 } } label="Proveedor" margin="normal" variant="outlined" />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth id="@docsuppl" label="RUC / DNI" variant="outlined" value={ purchasesEntity.docsuppl } disabled/>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth id="accaccou" label="Cuenta Contable" variant="outlined" value={ purchasesEntity.accaccou || '' } disabled/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} >
                            <Grid item xs={2}>
                                <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                    <InputLabel id="lbl_moneda">Moneda</InputLabel>
                                    <Select
                                        labelId="lbl_moneda"
                                        id="currency"
                                        className='select'
                                        label="Tipo de moneda"
                                        fullWidth
                                        value={purchasesEntity.currency}
                                        onChange={(e: any) => this.onChangePurchasesRoot({'currency': e.target.value})}
                                    >
                                        {
                                            tables.definition.filter((x: any) => x.defitype === 'CURCY').map((item: any, key: any) => 
                                                <MenuItem value={item.deficode} key={`moneda_${key}`}>{item.definame}</MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={1}>
                                <TextField fullWidth id="curreval"  label="Tipo de cambio" variant="outlined" value={ purchasesEntity.curreval } onChange={(e: any) => this.onChangePurchasesRoot({'curreval': e.target.value})}/>
                            </Grid>
                            <Grid item xs={1}>
                                <TextField fullWidth id="voucnumb"  label="Nro. Compro." variant="outlined" value={ purchasesEntity.voucnumb || '' } onChange={(e: any) => this.onChangePurchasesRoot({'voucnumb': e.target.value})}/>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                    <InputLabel id="lbl_opertype">Tipo Operacion</InputLabel>
                                    <Select
                                        labelId="lbl_opertype"
                                        id="opertype"
                                        className='select'
                                        label="Tipo Operacion"
                                        fullWidth
                                        value={purchasesEntity.opertype || ''}
                                        onChange={(e: any) => this.onChangePurchasesRoot({'opertype': e.target.value})}
                                    >
                                        {
                                            tables.definition.filter((x: any) => x.defitype === 'TYOPE').map((item: any, key: any) => 
                                                <MenuItem value={item.deficode} key={`opertype_${key}`}>{item.definame}</MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>


                            <Grid item xs={3}>
                                <TextField fullWidth id="purcorde"  label="Orden de compra" variant="outlined" value={ purchasesEntity.purcorde } onChange={(e: any) => this.onChangePurchasesRoot({'purcorde': e.target.value})}/>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                    <InputLabel id="lbl_almacen">Almacen</InputLabel>
                                    <Select
                                        labelId="lbl_almacen"
                                        id="wharcode"
                                        className='select'
                                        label="Almacen"
                                        fullWidth
                                        value={purchasesEntity.wharcode}
                                        onChange={(e: any) => this.onChangePurchasesRoot({'wharcode': e.target.value})}
                                    >
                                        {
                                            tables.wharhous.map((item: any, key: any) => 
                                                <MenuItem value={item.wharcode} key={`almacen_${key}`}>{item.wharname}</MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div style={{ height: 50 }}/>
                        <Grid container spacing={2} >
                            { this.renderGrilla()  }
                        </Grid>
                        <div style={{ height: 50 }}/>
                        <Grid container spacing={2} >
                            <Grid item xs={3}>
                                {
                                    edit && 
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={enabledDetract}
                                                onChange={(e: any) => { this.onChangeRoot({'enabledDetract': e.target.checked}); }}
                                                name="todo"
                                                color="primary"
                                            />
                                        }
                                        label="Detracc."
                                    />
                                }
                            </Grid>
                            <Grid item xs={3}/>
                            <Grid container item xs={3} direction="row-reverse" alignItems="center">
                                <Typography variant="caption">Total Operaciones Gravadas</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth disabled id="topgrava" variant="outlined" className="txt_total" value={ purchasesEntity.topgrava } onChange={(e: any) => this.onChangePurchasesRoot({'topgrava': e.target.value})}/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} >
                            <Grid item xs={3}>
                                {
                                    edit && enabledDetract && <TextField fullWidth type="date" id="detrdate" label="Fecha de detracc." variant="outlined" value={ purchasesEntity.detrdate || '' } onChange={(e: any) => this.onChangePurchasesRoot({'detrdate': e.target.value})}/>
                                }
                            </Grid>
                            <Grid item xs={3}/>
                            <Grid container item xs={3} direction="row-reverse" alignItems="center">
                                <Typography variant="caption">Total Operaciones Inafectas</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth disabled id="topinafe" variant="outlined" className="txt_total" value={ purchasesEntity.topinafe } onChange={(e: any) => this.onChangePurchasesRoot({'topinafe': e.target.value})}/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} >
                            <Grid item xs={3}>
                                {
                                    edit && enabledDetract && <TextField fullWidth id="detrnumb"  label="Nro. de detracc." variant="outlined" value={ purchasesEntity.detrnumb || '' } onChange={(e: any) => this.onChangePurchasesRoot({'detrnumb': e.target.value})}/>
                                }
                            </Grid>
                            <Grid item xs={3}/>
                            <Grid container item xs={3} direction="row-reverse" alignItems="center">
                                <Typography variant="caption">Total Operaciones Exoneradas</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth disabled id="topexone" variant="outlined" className="txt_total" value={ purchasesEntity.topexone } onChange={(e: any) => this.onChangePurchasesRoot({'topexone': e.target.value})}/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} >
                            <Grid item xs={3}/>
                            <Grid item xs={3}/>
                            <Grid container item xs={3} direction="row-reverse" alignItems="center">
                                <Typography variant="caption">Total Operaciones Gratuitas</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth disabled id="topgrati" variant="outlined" className="txt_total" value={ purchasesEntity.topgrati } onChange={(e: any) => this.onChangePurchasesRoot({'topgrati': e.target.value})}/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} >
                            <Grid item xs={3}/>
                            <Grid item xs={3}/>
                            <Grid container item xs={3} direction="row-reverse" alignItems="center">
                                <Typography variant="caption">Total Impuesto</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth disabled id="totaltax" variant="outlined" className="txt_total" value={ purchasesEntity.totaltax } onChange={(e: any) => this.onChangePurchasesRoot({'totaltax': e.target.value})}/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} >
                            <Grid item xs={3}/>
                            <Grid item xs={3}/>
                            <Grid container item xs={3} direction="row-reverse" alignItems="center">
                                <Typography variant="caption">Total</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth disabled id="partotal" variant="outlined" className="txt_total" value={ purchasesEntity.partotal } onChange={(e: any) => this.onChangePurchasesRoot({'partotal': e.target.value})}/>
                            </Grid>
                        </Grid>
                        {/* <Grid container spacing={2} >
                            <Grid item xs={3}/>
                            <Grid item xs={3}/>
                            <Grid container item xs={3} direction="row-reverse" alignItems="center">
                                <Typography variant="caption">Percepcion</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth id="percepci" variant="outlined" className="txt_total" value={ purchasesEntity.percepci } onChange={(e: any) => this.onChangePurchasesRoot({'percepci': e.target.value})}/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} >
                            <Grid item xs={3}/>
                            <Grid item xs={3}/>
                            <Grid container item xs={3} direction="row-reverse" alignItems="center">
                                <Typography variant="caption">Flete</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth id="fleteamo" variant="outlined" className="txt_total" value={ purchasesEntity.fleteamo } onChange={(e: any) => this.onChangePurchasesRoot({'fleteamo': e.target.value})}/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} >
                            <Grid item xs={3}/>
                            <Grid item xs={3}/>
                            <Grid container item xs={3} direction="row-reverse" alignItems="center">
                                <Typography variant="caption">Importe Total</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth disabled id="totalamo" variant="outlined" className="txt_total" value={ purchasesEntity.totalamo } onChange={(e: any) => this.onChangePurchasesRoot({'totalamo': e.target.value})}/>
                            </Grid>
                        </Grid> */}
                    </form>
                    <br/>
                    <FormFooter 
                        onCancel={ this.onCancel } 
                        onSubmitContinue={ this.onSubmitContinue } 
                        onSubmit={ this.onSubmit }/>
                </Paper>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={snackbar}
                    onClose={() => this.setState({snackbar: false})}
                    autoHideDuration={6000}
                >
                    <Alert onClose={() => this.setState({snackbar: false})} severity="success">
                        Datos Guardados Correctamente
                    </Alert>
                </Snackbar>
                <ScreenLoader enabled={loading}/>
            </div>
        );
    }
}

const shoppingsReducers = 'shoppingsReducers';
const generalReducers = 'generalReducers';

const mapStateToProps = (state: any) => ({
    shoppingsReducers: state.get(shoppingsReducers),
    generalReducers: state.get(generalReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        purchasesInitFormRequest,
        purchasesInitUpdateFormRequest,
        purchasesInsertRequest,
        purchasesUpdateRequest,
        purchasesDeleteRequest,
        currencyExchangeRateRequest
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(FormPurchases));