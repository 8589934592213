import React from 'react';
import { Backdrop, Button, ButtonGroup, Checkbox, CircularProgress, createStyles, FormControl, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Select, Snackbar, TextField, Theme, Tooltip, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { suppliersInitFormRequest, suppliersInitUpdateFormRequest, suppliersInsertRequest, suppliersUpdateRequest } from 'penta-redux/actions/shoppingsActions';
import { locationsGetProvincesRequest, locationsGetDistrictsRequest } from 'penta-redux/actions/generalActions';
import history from '../../../utils/history';
import './suppliers.scss'
import FormFooter from 'penta-components/base/formFooter';
import { MessageBox } from 'penta-components/base/messageBox';
import { supplierEntity } from 'models/shoppingModels';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Alert } from '@material-ui/lab';
import ScreenLoader from 'penta-components/base/screenLoader';

const useStyles = (theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        formControl: {
            margin: 0,
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    });

const defaultSuppliersEntity: supplierEntity = {
    docnumbe: '',
    officode: '',
    suppname: '',
    lastname: '',
    mlstname: '',
    businame: '',
    addrline: '',
    phonumbr: '',
    emailadr: '',
    locacode: '',
    typedocu: '00000008',
    accaccou: '',
    fiscyear: +localStorage.getItem('currentFiscyear')
}

interface FormSuppliersState {
    suppliersEntity: supplierEntity
    title: string
    department: string
    province: string
    snackbar: boolean
    error: {[key: string]: string | boolean}
}

class FormSuppliers extends React.Component<any, FormSuppliersState> {

    constructor(props: any) {
        super(props);
        this.state = {
            suppliersEntity: defaultSuppliersEntity,
            department: '',
            province: '',
            title: 'Agregar Proveedores',
            snackbar: false,
            error: {
                message: '',
                state: false
            }
        }
    }

    onChangeRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            ...value
        }))
    }

    onChangeDepartment = (value: any) => {
        this.onChangeRoot(value);
        this.props.locationsGetProvincesRequest(value.department);
    }

    onChangeProvince = (value: any) => {
        this.onChangeRoot(value);
        this.props.locationsGetDistrictsRequest(value.province);
    }

    onChangeSuppliersRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            suppliersEntity: {
                ...state.suppliersEntity,
                ...value
            }
        }))
    }

    onCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        console.log('cancel')
        history.push('/papp/shopping/proveedores');
    }

    onContinueCallback = () => {
        const newSuppliersEntity = defaultSuppliersEntity;
        this.setState({
            suppliersEntity: newSuppliersEntity,
            department: '',
            province: '',
            snackbar: true
        });
    }

    isNotValidForm = () => {
        const { planAccount } = this.props.shoppingsReducers.tables;
        const { suppliersEntity } = this.state;
        if((suppliersEntity.docnumbe || '') === '') {
            this.setState({error: {state: true, message: `El numero de documento no puede estar vacio`}});
            return true;
        }
        if(!planAccount.find(x => x.accaccou === suppliersEntity.accaccou))  {
            this.setState({error: {state: true, message: `El numero de cuenta ${suppliersEntity.accaccou} no es valido`}})
            return true;
        }
        return false;
    }


    onSubmitContinue = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const { params } = this.props.match;
        if(this.isNotValidForm()) return;
        if (params.docnumbe !== undefined) {
            this.props.suppliersUpdateRequest(this.state.suppliersEntity);
        } else {
            this.props.suppliersInsertRequest(this.state.suppliersEntity, this.onContinueCallback);
        }
        this.setState({error: {state: false, message: ``}})
    }

    onSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const { params } = this.props.match;
        if(this.isNotValidForm()) return;
        if (params.docnumbe !== undefined) {
            this.props.suppliersUpdateRequest(this.state.suppliersEntity);
        } else {
            this.props.suppliersInsertRequest(this.state.suppliersEntity, null);
        }
        this.setState({error: {state: false, message: ``}})
    }

    callbackInitUpdate = (suppliersEntity: supplierEntity, department: string, province: string) => {
        this.setState({
            suppliersEntity,
            department,
            province,
        });
    }

    componentDidMount() {
        const { params } = this.props.match;
        if (params.docnumbe !== undefined) {
            this.setState({title: 'Editar Proveedores'});
            this.props.suppliersInitUpdateFormRequest(params.docnumbe, this.callbackInitUpdate);
        }
        else {
            this.props.suppliersInitFormRequest(this.props.dashboardReducers.currentFiscyear);
        }
    }

    render() {

        const { suppliersEntity, department, province, title, snackbar, error } = this.state;
        const { loading, list, tables, failed, failedTitle, failedContent } = this.props.shoppingsReducers;
        const generalTables = this.props.generalReducers.tables;
        const { classes } = this.props;

        return (
            <div style={{overflow: 'auto', height: '100%'}}>
                <Paper className='form__container'>
                    <br/>
                    <Typography variant='h5'>{ title }</Typography>
                    <br/>
                    { failed && <MessageBox title={failedTitle} content={failedContent}/> }
                    { error.state && <MessageBox title='Error' content={error.message as string}/> }
                    <form noValidate autoComplete="off">
                        <Grid container spacing={2} >
                            <Grid item xs={4}>
                                <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                    <InputLabel id="lbl_tipodocumento">Tipo de Documento</InputLabel>
                                    <Select
                                        labelId="lbl_tipodocumento"
                                        id="typedocument"
                                        className='select'
                                        label="Tipo de Documento"
                                        fullWidth
                                        value={suppliersEntity.typedocu}
                                        onChange={(e: any) => this.onChangeSuppliersRoot({'typedocu': e.target.value})}
                                    >
                                        {
                                            tables.definition.filter((x: any) => x.defitype === 'TIDOC').map((item: any, key: any) => 
                                                <MenuItem value={item.deficode} key={`typedocument_${key}`}>{item.definame}</MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth id="docnumbe" label="Nro. de Documento" variant="outlined" value={ suppliersEntity.docnumbe } onChange={(e: any) => this.onChangeSuppliersRoot({'docnumbe': e.target.value})}/>
                            </Grid>
                        </Grid>
                        {
                            suppliersEntity.typedocu === '00000007' &&
                            <Grid container spacing={2} >
                                <Grid item xs={4}>
                                    <TextField fullWidth id="suppname" label="Nombres" variant="outlined" value={ suppliersEntity.suppname } onChange={(e: any) => this.onChangeSuppliersRoot({'suppname': e.target.value})}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField fullWidth id="lastname" label="Apellido Paterno" variant="outlined" value={ suppliersEntity.lastname } onChange={(e: any) => this.onChangeSuppliersRoot({'lastname': e.target.value})}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField fullWidth id="mlstname" label="Apellido Materno" variant="outlined" value={ suppliersEntity.mlstname } onChange={(e: any) => this.onChangeSuppliersRoot({'mlstname': e.target.value})}/>
                                </Grid>
                            </Grid>
                        }
                        {
                            suppliersEntity.typedocu === '00000008' &&
                            <Grid container spacing={2} >
                                <Grid item xs={12}>
                                    <TextField fullWidth id="businame" label="Razon Social" variant="outlined" value={ suppliersEntity.businame } onChange={(e: any) => this.onChangeSuppliersRoot({'businame': e.target.value})}/>
                                </Grid>
                            </Grid>
                        }
                        <Grid container spacing={2} >
                            <Grid item xs={4}>
                                <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                    <InputLabel id="lbl_department">Departamento</InputLabel>
                                    <Select
                                        labelId="lbl_department"
                                        id="department"
                                        className='select'
                                        label="Departamento"
                                        fullWidth
                                        value={ department }
                                        onChange={ (e) => this.onChangeDepartment({department: e.target.value}) }
                                    >
                                        {
                                            tables.departamentos.map((item: any, key: any) => 
                                                <MenuItem value={item.locacode} key={`department_${key}`}>{item.locaname}</MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                    <InputLabel id="lbl_province">Provincia</InputLabel>
                                    <Select
                                        labelId="lbl_province"
                                        id="province"
                                        className='select'
                                        label="Provincia"
                                        fullWidth
                                        value={ province }
                                        onChange={ (e) => this.onChangeProvince({province: e.target.value}) }
                                    >
                                        {
                                            generalTables.provincias.map((item: any, key: any) => 
                                                <MenuItem value={item.locacode} key={`province_${key}`}>{item.locaname}</MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                    <InputLabel id="lbl_district">Distrito</InputLabel>
                                    <Select
                                        labelId="lbl_district"
                                        id="district"
                                        className='select'
                                        label="Distrito"
                                        fullWidth
                                        value={suppliersEntity.locacode}
                                        onChange={(e: any) => this.onChangeSuppliersRoot({'locacode': e.target.value})}
                                    >
                                        {
                                            generalTables.distritos.map((item: any, key: any) => 
                                                <MenuItem value={item.locacode} key={`district_${key}`}>{item.locaname}</MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} >
                            <Grid item xs={12}>
                                <TextField fullWidth id="addrline" label="Direccion" variant="outlined" value={ suppliersEntity.addrline } onChange={(e: any) => this.onChangeSuppliersRoot({'addrline': e.target.value})}/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} >
                            <Grid item xs={4}>
                                <TextField fullWidth id="phonumbr" label="Nro. de telefono" variant="outlined" value={ suppliersEntity.phonumbr } onChange={(e: any) => this.onChangeSuppliersRoot({'phonumbr': e.target.value})}/>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth id="emailadr" label="Email" variant="outlined" value={ suppliersEntity.emailadr } onChange={(e: any) => this.onChangeSuppliersRoot({'emailadr': e.target.value})}/>
                            </Grid>
                            <Grid item xs={4}>
                                <Autocomplete
                                    id="accaccou"
                                    freeSolo
                                    options={tables.planAccount}
                                    getOptionLabel={ (x: any) => x.accaccou }
                                    value={ tables.planAccount.find((x: any) => x.accaccou === suppliersEntity.accaccou) || null }
                                    onChange={(e: any, value) => this.onChangeSuppliersRoot({'accaccou': value.accaccou, 'fiscyear': value.fiscyear})}
                                    renderInput={(params) => (
                                        <TextField {...params} 
                                        onBlur={(e) => { 
                                            const a = tables.planAccount.find((x: any) => x.accaccou === e.target.value);
                                            if(a) {
                                                this.onChangeSuppliersRoot({'accaccou': a.accaccou, 'fiscyear': a.fiscyear})
                                            }
                                        }}
                                        style={ { marginTop: 0 } } label="Cuenta Contable" margin="normal" variant="outlined" />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </form>
                    <br/>
                    <FormFooter 
                        onCancel={ this.onCancel } 
                        onSubmitContinue={ this.onSubmitContinue } 
                        onSubmit={ this.onSubmit }/>
                </Paper>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={snackbar}
                    onClose={() => this.setState({snackbar: false})}
                    autoHideDuration={6000}
                >
                    <Alert onClose={() => this.setState({snackbar: false})} severity="success">
                            Datos Guardados Correctamente
                    </Alert>
                </Snackbar>
                <ScreenLoader enabled={loading}/>
            </div>
        );
    }
}

const shoppingsReducers = 'shoppingsReducers';
const generalReducers = 'generalReducers';
const dashboardReducers = 'dashboardReducers';

const mapStateToProps = (state: any) => ({
    shoppingsReducers: state.get(shoppingsReducers),
    generalReducers: state.get(generalReducers),
    dashboardReducers: state.get(dashboardReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        suppliersInitFormRequest,
        suppliersInitUpdateFormRequest,
        suppliersInsertRequest,
        suppliersUpdateRequest,
        locationsGetProvincesRequest,
        locationsGetDistrictsRequest
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(FormSuppliers));