import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import Dashboard from '../templates/dashboard';
import {
  DashboardPage, 
  FormCashBanks, 
  FormCashBanksMovements, 
  FormCategorys, 
  FormChecks, 
  FormCommissions, 
  FormConcepts, 
  FormCreditNote, 
  FormCustomers, 
  FormDebitNote, 
  FormEmployes, 
  FormFamilies, 
  FormMajorization, 
  FormMasterAccountPage, 
  FormPayroll, 
  FormPlanAccount,
  FormProducts, 
  FormPurchases, 
  FormSuppliers, 
  FormUnits, 
  FormUsersPage, 
  FormVouchers, 
  FormWeekends, 
  FormWharehouse, 
  ListCashBanks, 
  ListCashBanksMovements, 
  ListCategorys, 
  ListChecks, 
  ListCommissions, 
  ListConcepts, 
  listCreditNote, 
  ListCustomers, 
  listDebitNote, 
  ListEmployes, 
  ListFamilies, 
  ListMasterTables, 
  ListPayroll,
  ListPlanAccount, 
  ListProducts, 
  ListPurchases, 
  ListSales, 
  ListSuppliers, 
  ListUnits, 
  ListUsersPage, 
  ListVouchers, 
  ListWeekends, 
  ListWharehouse,
  ReportAfpList,
  ReportBalanceFinancialPosition,
  ReportCashBookBanks,
  ReportChecksBalance,
  ReportChecksIssuedSummary,
  ReportCtsDeposits,
  ReportDaot,
  ReportDiaryBook,
  ReportEmployePayroll,
  ReportEmployePayrollAccumulated,
  ReportEmployeVoucher,
  ReportExpenseAnalysisNine,
  ReportExpenseAnalysisSix,
  ReportFifthCategoryCalculation,
  ReportGeneralBalances,
  ReportGeneralBalancesAccount,
  ReportInventoryBookBalance,
  ReportInvoicesToCharge,
  ReportInvoicesToPay,
  ReportKardex,
  ReportLaborerPayroll,
  ReportLaborerPayrollAccumulated,
  ReportMajorGeneral,
  ReportPdtStaff,
  ReportPlePurchase,
  ReportPleSale,
  ReportProfitLossStatement,
  ReportPurchaseDetailSupplier,
  ReportPurchasesRegister,
  ReportSalesDetailedDiaryBook,
  ReportSalesRegister,
  ReportWorksheet
} from '../pages/pageListAsync';

import packageJson from './../../../package.json';
import changelog from './../../changelog.json';
import Modal from 'penta-components/base/modal';
import Button from 'penta-components/controls/button';
import { createUseStyles } from 'react-jss';

const PentaLogo = require('penta-assets/images/PentaErpLogo2.png').default;
const LightIcon = require('penta-assets/images/light.svg').ReactComponent;

const useStyles = createUseStyles({
  modalBodyContainer: {
    position: 'relative'
  },
  modalBodyBackground: {
    position: 'absolute',
    backgroundImage: `url(${PentaLogo})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover', //30rem
    backgroundPositionX: '10rem',
    backgroundPositionY: '0rem',
    opacity: 0.05,
    width: '100%',
    height: '100%'
  },
  modalBodyTitle: {
    display: 'flex', 
    alignItems: 'center', 
    gap: '1rem',
    '& > div': {
      fontSize: '1.2rem', 
      fontWeight: 500
    }
  },
  modalBody: {
    padding: '1rem', 
    borderTop: '1px solid #dfdfdf', 
    margin: '1.5rem 0',
    maxHeight: '600px',
    overflowX: 'auto',
    '& > p': {
      margin:' 1rem 0rem', 
      fontSize: '0.85rem', 
      fontWeight: 600, 
      color: '#0060a6'
    },
    '& > ul': {
      padding: '0rem 1rem',
      '& > li': {
        margin: '0.5rem 0rem'
      }
    }
  }
});

const Application = () => {
  const [isNewVersion, setIsNewVersion] = React.useState(false);
  const [appVersion, setAppVersion] = React.useState('0.0.0');
  const classes = useStyles();

  useEffect(() => {
    const localAppVersion = localStorage.getItem('appVersion');
    const appVersion = packageJson.version;
    if(!localAppVersion || localAppVersion !== appVersion) {
      localStorage.setItem('appVersion', appVersion);
      setIsNewVersion(true);
    }
    setAppVersion(appVersion);
  }, [])

  return (
    <Dashboard>
      <Switch>
        <Route exact path="/papp" component={DashboardPage} />
        <Route path="/papp/users/maestro-usuarios/editar/:username" component={FormUsersPage} />
        <Route path="/papp/users/maestro-usuarios/agregar" component={FormUsersPage} />
        <Route path="/papp/users/maestro-usuarios" component={ListUsersPage} />
        <Route path="/papp/accounting/maestro-contable" component={FormMasterAccountPage} />
        <Route path="/papp/accounting/mayorizacion" component={FormMajorization} />
        <Route path="/papp/accounting/plan-cuentas/editar/:accaccou/:fiscyear" component={FormPlanAccount} />
        <Route path="/papp/accounting/plan-cuentas/agregar" component={FormPlanAccount} />
        <Route path="/papp/accounting/plan-cuentas" component={ListPlanAccount} />
        <Route path="/papp/accounting/comprobantes/editar/:correlat" component={FormVouchers} />
        <Route path="/papp/accounting/comprobantes/agregar" component={FormVouchers} />
        <Route path="/papp/accounting/comprobantes" component={ListVouchers} />
        <Route path="/papp/accounting/reportes/balance-general-cuentas" component={ReportGeneralBalancesAccount} />
        <Route path="/papp/accounting/reportes/libro-diario" component={ReportDiaryBook} />
        <Route path="/papp/accounting/reportes/registro-compras" component={ReportPurchasesRegister} />
        <Route path="/papp/accounting/reportes/hoja-trabajo" component={ReportWorksheet} />
        <Route path="/papp/accounting/reportes/mayor-general" component={ReportMajorGeneral} />
        <Route path="/papp/accounting/reportes/libro-caja-bancos" component={ReportCashBookBanks} />
        <Route path="/papp/accounting/reportes/analisis-gastos-c9" component={ReportExpenseAnalysisNine} />
        <Route path="/papp/accounting/reportes/analisis-gastos-c6" component={ReportExpenseAnalysisSix} />
        <Route path="/papp/accounting/reportes/libro-inv-balances" component={ReportInventoryBookBalance} />
        <Route path="/papp/accounting/reportes/balance-general" component={ReportGeneralBalances} />
        <Route path="/papp/accounting/reportes/balance-stn-financiera" component={ReportBalanceFinancialPosition} />
        <Route path="/papp/accounting/reportes/est-ganancias-perdidas" component={ReportProfitLossStatement} />
        <Route path="/papp/accounting/reportes/ple-compras" component={ReportPlePurchase} />
        <Route path="/papp/accounting/reportes/ple-ventas" component={ReportPleSale} />
        <Route path="/papp/accounting/reportes/registro-ventas" component={ReportSalesRegister} />
        <Route path="/papp/accounting/reportes/daot" component={ReportDaot} />
        <Route path="/papp/accounting/reportes/det-compras-proveedor" component={ReportPurchaseDetailSupplier} />
        <Route path="/papp/products/categorias/editar/:ctgrcode/:fiscyear" component={FormCategorys} />
        <Route path="/papp/products/categorias/agregar" component={FormCategorys} />
        <Route path="/papp/products/categorias" component={ListCategorys} />
        <Route path="/papp/products/familias/editar/:famicode" component={FormFamilies} />
        <Route path="/papp/products/familias/agregar" component={FormFamilies} />
        <Route path="/papp/products/familias" component={ListFamilies} />
        <Route path="/papp/products/unidades/editar/:meascode" component={FormUnits} />
        <Route path="/papp/products/unidades/agregar" component={FormUnits} />
        <Route path="/papp/products/unidades" component={ListUnits} />
        <Route path="/papp/products/almacenes/editar/:wharcode" component={FormWharehouse} />
        <Route path="/papp/products/almacenes/agregar" component={FormWharehouse} />
        <Route path="/papp/products/almacenes" component={ListWharehouse} />
        <Route path="/papp/products/productos/editar/:skucodee" component={FormProducts} />
        <Route path="/papp/products/productos/agregar" component={FormProducts} />
        <Route path="/papp/products/productos" component={ListProducts} />
        <Route path="/papp/sales/clientes/editar/:docnumbe" component={FormCustomers} />
        <Route path="/papp/sales/clientes/agregar" component={FormCustomers} />
        <Route path="/papp/sales/clientes" component={ListCustomers} />
        <Route path="/papp/sales/ventas-oficina" component={ListSales} />
        <Route path="/papp/sales/reportes/libro-ventas-detallado" component={ReportSalesDetailedDiaryBook} />
        <Route path="/papp/sales/reportes/kardex" component={ReportKardex} />
        <Route path="/papp/sales/reportes/facturas-cobrar" component={ReportInvoicesToCharge} />
        <Route path="/papp/shopping/proveedores/editar/:docnumbe" component={FormSuppliers} />
        <Route path="/papp/shopping/proveedores/agregar" component={FormSuppliers} />
        <Route path="/papp/shopping/proveedores" component={ListSuppliers} />
        <Route path="/papp/shopping/compras/editar/:serialnu/:documnum/:docsuppl" component={FormPurchases} />
        <Route path="/papp/shopping/compras/copiar" component={FormPurchases} />
        <Route path="/papp/shopping/compras/agregar" component={FormPurchases} />
        <Route path="/papp/shopping/compras" component={ListPurchases} />
        <Route path="/papp/shopping/notas-credito/editar/:serialnu/:documnum/:docsuppl" component={FormCreditNote} />
        <Route path="/papp/shopping/notas-credito/copiar" component={FormCreditNote} />
        <Route path="/papp/shopping/notas-credito/agregar" component={FormCreditNote} />
        <Route path="/papp/shopping/notas-credito" component={listCreditNote} />
        <Route path="/papp/shopping/notas-debito/editar/:serialnu/:documnum/:docsuppl" component={FormDebitNote} />
        <Route path="/papp/shopping/notas-debito/agregar" component={FormDebitNote} />
        <Route path="/papp/shopping/notas-debito" component={listDebitNote} />
        <Route path="/papp/shopping/reportes/facturas-pagar" component={ReportInvoicesToPay} />
        <Route path="/papp/hhrr/comisiones/editar/:commyear/:commmont" component={FormCommissions} />
        <Route path="/papp/hhrr/comisiones/agregar" component={FormCommissions} />
        <Route path="/papp/hhrr/comisiones" component={ListCommissions} />
        <Route path="/papp/hhrr/semanas/editar/:yearnumb/:weeknumb" component={FormWeekends} />
        <Route path="/papp/hhrr/semanas/agregar" component={FormWeekends} />
        <Route path="/papp/hhrr/semanas" component={ListWeekends} />
        <Route path="/papp/hhrr/empleados/editar/:emplcode" component={FormEmployes} />
        <Route path="/papp/hhrr/empleados/agregar" component={FormEmployes} />
        <Route path="/papp/hhrr/empleados" component={ListEmployes} />
        <Route path="/papp/hhrr/conceptos/editar/:conccode/:typesala/:payrtype" component={FormConcepts} />
        <Route path="/papp/hhrr/conceptos/agregar" component={FormConcepts} />
        <Route path="/papp/hhrr/conceptos" component={ListConcepts} />
        <Route path="/papp/hhrr/planillas/editar/:emplcode/:payryear/:payrweek/:typesala" component={FormPayroll} />
        <Route path="/papp/hhrr/planillas/agregar" component={FormPayroll} />
        <Route path="/papp/hhrr/planillas" component={ListPayroll} />
        <Route path="/papp/hhrr/reportes/planilla-empleados" component={ReportEmployePayroll} />
        <Route path="/papp/hhrr/reportes/planilla-obreros" component={ReportLaborerPayroll} />
        <Route path="/papp/hhrr/reportes/planilla-obreros-acumulado" component={ReportLaborerPayrollAccumulated} />
        <Route path="/papp/hhrr/reportes/planilla-empleados-acumulado" component={ReportEmployePayrollAccumulated} />
        <Route path="/papp/hhrr/reportes/voucher" component={ReportEmployeVoucher} />
        <Route path="/papp/hhrr/reportes/listado-afp" component={ReportAfpList} />
        <Route path="/papp/hhrr/reportes/pdt-empleados-obreros" component={ReportPdtStaff} />
        <Route path="/papp/hhrr/reportes/calculo-quinta-categoria" component={ReportFifthCategoryCalculation} />
        <Route path="/papp/hhrr/reportes/depositos-cts" component={ReportCtsDeposits} />
        <Route path="/papp/finance/caja-bancos/editar/:idcsbank" component={FormCashBanks} />
        <Route path="/papp/finance/caja-bancos/agregar" component={FormCashBanks} />
        <Route path="/papp/finance/caja-bancos" component={ListCashBanks} />
        <Route path="/papp/finance/movimientos-caja-bancos/editar/:yearexer/:montexer/:weekexer/:pagenumb/:idcsbank" component={FormCashBanksMovements} />
        <Route path="/papp/finance/movimientos-caja-bancos/agregar" component={FormCashBanksMovements} />
        <Route path="/papp/finance/movimientos-caja-bancos" component={ListCashBanksMovements} />
        <Route path="/papp/finance/cheques/editar/:correlat" component={FormChecks} />
        <Route path="/papp/finance/cheques/agregar" component={FormChecks} />
        <Route path="/papp/finance/cheques" component={ListChecks} />
        <Route path="/papp/finance/reportes/resumen-cheques-emitidos" component={ReportChecksIssuedSummary} />
        <Route path="/papp/finance/reportes/balance-cheques" component={ReportChecksBalance} />
        <Route path="/papp/config/tablas-maestras" component={ListMasterTables} />
      </Switch>
      {
        isNewVersion &&
        <Modal 
          title="Nueva versión Penta ERP"
          onClose={() => setIsNewVersion(false)}
          footer={
              <React.Fragment>
                  <Button full={false} onClick={() => setIsNewVersion(false)}>Aceptar</Button>
              </React.Fragment>
          }
        >
            <div className={classes.modalBodyContainer}>
              <div className={classes.modalBodyBackground}/>
              <div className={classes.modalBodyTitle}>
                <LightIcon width='3rem'/>
                <div>
                  Actualización {appVersion}
                </div>
              </div>
              <div className={classes.modalBody}>
                { changelog.feat.length > 0 && <p>Nuevas caracteristicas</p> }
                <ul>
                  {
                    changelog.feat.map((item, i) =>
                      <li key={i}>{item}</li>
                    )
                  }
                </ul>
                { changelog.fix.length > 0 && <p>Correcciones</p> }
                <ul>
                  {
                    changelog.fix.map((item, i) =>
                      <li key={i}>{item}</li>
                    )
                  }
                </ul>
              </div>
            </div>
        </Modal>
      }
    </Dashboard>
  );
}

export default Application;