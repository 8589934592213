import React from 'react';
import { Backdrop, Button, ButtonGroup, Checkbox, CircularProgress, createStyles, FormControl, FormControlLabel, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Select, Switch, TextField, Theme, Tooltip, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { expenseAnalysisSixRequest } from 'penta-redux/actions/accountantActions';
import history from '../../../../utils/history';
import './../reports.scss'
import FormFooter from 'penta-components/base/formFooter';
import { MessageBox } from 'penta-components/base/messageBox';
import { categoryEntity } from 'models/wharehouseProductsModels';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PdfView from 'penta-components/base/pdfView';
// import * as pdfMake from "pdfmake/build/pdfmake";
// import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
import * as XLSX from 'sheetjs-style';
import ScreenLoader from 'penta-components/base/screenLoader';

let pdfMake = require("pdfmake/build/pdfmake");
let saveAs = require('file-saver').saveAs;

const meses = [
    { key: '01', value: 'Enero' },
    { key: '02', value: 'Febrero' },
    { key: '03', value: 'Marzo' },
    { key: '04', value: 'Abril' },
    { key: '05', value: 'Mayo' },
    { key: '06', value: 'Junio' },
    { key: '07', value: 'Julio' },
    { key: '08', value: 'Agosto' },
    { key: '09', value: 'Septiembre' },
    { key: '10', value: 'Octubre' },
    { key: '11', value: 'Noviembre' },
    { key: '12', value: 'Diciembre' }
]

const useStyles = (theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        formControl: {
            margin: 0,
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    });

interface FormcategorysState {
    title: string
    year: string
    month: string
    accumulated: boolean
    base64Pdf: string
}

class ReportExpenseAnalysisSix extends React.Component<any, FormcategorysState> {

    constructor(props: any) {
        super(props);
        this.state = {
            title: 'Reporte de Analisis de Gastos Clase 6',
            year: moment().format('yyyy').toString(),
            month: moment().format('MM').toString(),
            accumulated: false,
            base64Pdf: ''
        }
    }

    onChangeRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            ...value
        }))
    }

    onGeneratePdf = () => {
        const data = this.props.accountantReducers.onlyOne;
        const { month, year } = this.state;
        const { dashboardReducers } = this.props;
        const mes = meses.find((x: any) => x.key === month).value;
        const tables = JSON.parse(localStorage.getItem('tables'));
        const company = tables.companys.find((x: any) => x.taxident === dashboardReducers.currentCompany);
        const division = company.lst_grl_division.find((x: any) => x.divicode === dashboardReducers.currentDivision);

        const pdfObject: any = {
            pageSize: 'A4',
            pageOrientation: 'landscape',
            pageMargins: [ 20, 80, 20, 20 ],
            header: [
                {
                    text: ' ', lineHeight: 2
                },
                {
                    margin: [20, 0, 20, 0],
                    text: 'SERVICENTRO LAS AMARICAS',
                    style: {
                        bold: true,
                        fontSize: 9
                    }
                },
                {
                    margin: [20, 5, 20, 0],
                    text: `CUADRO DE GASTOS DEL MES DE ${mes.toUpperCase()} ${year}`,
                    style: {
                        bold: true,
                        fontSize: 9,
                        alignment: 'center'
                    }
                }
            ],
            content: [
                {
                    table: {
                        headerRows: 1,
                        widths: [],
                        body: []
                    }
                },
            ],
            styles: {
                headerTitle: {
                    bold: true,
                    fontSize: 7,
                    lineHeight: 1.2
                },
                table: {
                    layout: 'headerLineOnly',
                    fontSize: 6,
                    alignment: 'center'
                },
                subTable: {
                    layout: 'headerLineOnly',
                    fontSize: 6,
                    alignment: 'left'
                },
                tableDetailLeft: {
                    layout: 'headerLineOnly',
                    fontSize: 5.5,
                    alignment: 'left'
                },
                tableDetailRight: {
                    layout: 'headerLineOnly',
                    fontSize: 5.5,
                    alignment: 'right'
                },
                tableDetailRightBold: {
                    layout: 'headerLineOnly',
                    fontSize: 5.5,
                    alignment: 'right',
                    bold: true
                }
            },
            defaultStyle: {
                columnGap: 20,
            },
        }

        const unicodopRead: any = [];
        const amototal = {'0': 0, '91': 0, '92': 0, '93': 0, '94': 0, '95': 0, '96': 0, '97': 0, '98': 0, '99': 0};
        // const credtaccs = [];
        
        // for (const accaccou of data) {
        //     if(!credtaccs.includes(accaccou.credtacc)) credtaccs.push(accaccou.credtacc);
        // }

        pdfObject.content[0].table.widths = [ 30, 250, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40 ];

        const tableBody: any = [
            [ 
                { text: '', style: 'table', colSpan: 1, border: [false, false, false, true] },
                { text: '', style: 'table', colSpan: 1, border: [false, false, false, true] },
                { text: '90\nGRIFO\nCHINCHA', style: 'table', colSpan: 1, border: [false, false, false, true] },
                { text: '91\nCOSTOS\nGRIFO', style: 'table', colSpan: 1, border: [false, false, false, true] },
                { text: '92\nCOSTOS\nSHOP', style: 'table', colSpan: 1, border: [false, false, false, true] },
                { text: '93\nCOSTOS\nSHOP GRIFO', style: 'table', colSpan: 1, border: [false, false, false, true] },
                { text: '95\nGASTOS\nADM', style: 'table', colSpan: 1, border: [false, false, false, true] },
                { text: '96\nGASTOS\nADM CHINCHA', style: 'table', colSpan: 1, border: [false, false, false, true] },
                { text: '97\nGASTOS\nDE VENTA', style: 'table', colSpan: 1, border: [false, false, false, true] },
                { text: '98\nCARGAS\nFINANCIERAS', style: 'table', colSpan: 1, border: [false, false, false, true] },
                { text: '99\nCARGAS\nADM LIMA', style: 'table', colSpan: 1, border: [false, false, false, true] },
                { text: 'TOTAL', style: 'table', colSpan: 1, border: [false, false, false, true] },
            ]
        ];

        for (const iterator of data) {
            if (!unicodopRead.includes(iterator.parenacc)) {

                const rows = data.filter((x: any) => x.parenacc === iterator.parenacc);

                const accRead = [];
                const acctotal = {'0': 0, '90': 0, '91': 0, '92': 0, '93': 0, '95': 0, '96': 0, '97': 0, '98': 0, '99': 0};

                for (const row of rows) {
                    if(!accRead.includes(row.accaccou)) {
                        const accaccous = rows.filter((x: any) => x.accaccou === row.accaccou);
                        const _push = [
                            { text: row.accaccou, style: 'tableDetailLeft', border: [false, false, false, false] },
                            { text: row.denomina, style: 'tableDetailLeft', border: [false, false, false, false] }
                        ];
    
                        let gentotal = 0;

                        for(let i = 90; i <= 99; i++) {
                            if (i === 94) continue;
                            const _row = accaccous.find((x: any) => x.credtacc === `${i.toString()}`) || { totalbal: '0' };
                            gentotal += +_row.totalbal;
                            acctotal[i] += +_row.totalbal;
                            _push.push({ text: +_row.totalbal > 0 ? String.formatNumber.new(_row.totalbal) : '', style: 'tableDetailRight', border: [false, false, false, false] });
                        }

                        acctotal['0'] += +gentotal;
                        _push.push({ text: +gentotal > 0 ? String.formatNumber.new(gentotal) : '', style: 'tableDetailRight', border: [false, false, false, false] });
    
                        tableBody.push(_push);
                        accRead.push(row.accaccou);
                    }
                }

                const _totpush = [
                    { text: '', style: 'tableDetailLeft', border: [false, false, false, false] },
                    { text: 'SUBTOTALES', style: 'tableDetailLeft', border: [false, false, false, false] }
                ];

                for(let i = 90; i <= 99; i++) {
                    if (i === 94) continue;
                    amototal[i] += +acctotal[i]
                    _totpush.push({ text: +acctotal[i] > 0 ? String.formatNumber.new(acctotal[i]) : '0.00', style: 'tableDetailRight', border: [false, true, false, true] });
                }

                amototal['0'] += +acctotal['0']
                _totpush.push({ text: +acctotal['0'] > 0 ? String.formatNumber.new(acctotal['0']) : '0.00', style: 'tableDetailRight', border: [false, true, false, true] });

                tableBody.push(_totpush);
                tableBody.push([{ text: '\n', style: 'tableDetailLeft', colSpan: 12, border: [false, false, false, false] }])

                unicodopRead.push(iterator.parenacc);
            }
        }

        const _totpush = [
            { text: '', style: 'tableDetailLeft', border: [false, false, false, false] },
            { text: 'TOTALES FINALES', style: 'tableDetailLeft', border: [false, false, false, false] }
        ];

        for(let i = 90; i <= 99; i++) {
            if (i === 94) continue;
            _totpush.push({ text: +amototal[i] > 0 ? String.formatNumber.new(amototal[i]) : '0.00', style: 'tableDetailRight', border: [false, true, false, true] });
        }

        _totpush.push({ text: +amototal['0'] > 0 ? String.formatNumber.new(amototal['0']) : '0.00', style: 'tableDetailRight', border: [false, true, false, true] });

        tableBody.push(_totpush);

        pdfObject.content[0].table.body = tableBody;

        pdfMake.fonts = {
            Roboto: {
                normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
                bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
                italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
                bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
              },
        }
        const pdfDocGenerator = pdfMake.createPdf(pdfObject);
        pdfDocGenerator.getDataUrl((data: any) => {
            // console.log(data);
            this.setState({
                base64Pdf: data
            })
        });

    }

    onGenerateExcel = () => {
        try {
            const data = this.props.accountantReducers.onlyOne;
            const { month, year } = this.state;
            const { dashboardReducers } = this.props;
            const mes = meses.find((x: any) => x.key === month).value;
            const tables = JSON.parse(localStorage.getItem('tables'));
            const company = tables.companys.find((x: any) => x.taxident === dashboardReducers.currentCompany);
            const division = company.lst_grl_division.find((x: any) => x.divicode === dashboardReducers.currentDivision);

            const tableBody: any = [
                { 
                    A: 'SERVICENTRO LAS AMARICAS',
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null,
                    G: null,
                    H: null,
                    I: null,
                    J: null,
                    K: null,
                    L: null
                },
                { 
                    A: `CUADRO DE GASTOS DEL MES DE ${mes.toUpperCase()} ${year}`,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null,
                    G: null,
                    H: null,
                    I: null,
                    J: null,
                    K: null,
                    L: null
                },
                { 
                    A: null,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null,
                    G: null,
                    H: null,
                    I: null,
                    J: null,
                    K: null,
                    L: null
                },
                { 
                    A: '',
                    B: '',
                    C: '90\nGRIFO\nCHINCHA',
                    D: '91\nCOSTOS\nGRIFO',
                    E: '92\nCOSTOS\nSHOP',
                    F: '93\nCOSTOS\nSHOP GRIFO',
                    G: '95\nGASTOS\nADM',
                    H: '96\nGASTOS\nADM CHINCHA',
                    I: '97\nGASTOS\nDE VENTA',
                    J: '98\nCARGAS\nFINANCIERAS',
                    K: '99\nCARGAS\nADM LIMA',
                    L: 'TOTAL'
                }
            ];

            const unicodopRead: any = [];
            const cutRows: Array<number> = [];
            const amototal = {'0': 0, '90': 0, '91': 0, '92': 0, '93': 0, '95': 0, '96': 0, '97': 0, '98': 0, '99': 0};
            let keys: any = {'90': 'C', '91': 'D', '92': 'E', '93': 'F', '95': 'G', '96': 'H', '97': 'I', '98': 'J', '99': 'K'};

            for (const iterator of data) {
                if (!unicodopRead.includes(iterator.parenacc)) {
    
                    const rows = data.filter((x: any) => x.parenacc === iterator.parenacc);
    
                    const accRead = [];
                    const acctotal = {'0': 0, '90': 0, '91': 0, '92': 0, '93': 0, '95': 0, '96': 0, '97': 0, '98': 0, '99': 0};
    
                    for (const row of rows) {
                        if(!accRead.includes(row.accaccou)) {
                            const accaccous = rows.filter((x: any) => x.accaccou === row.accaccou);
                            const _push = {
                                A: row.accaccou,
                                B: row.denomina,
                            };
        
                            let gentotal = 0;
    
                            for(let i = 90; i <= 99; i++) {
                                if (i === 94) continue;
                                const _row = accaccous.find((x: any) => x.credtacc === `${i.toString()}`) || { totalbal: '0' };
                                gentotal += +_row.totalbal;
                                acctotal[i] += +_row.totalbal;
                                _push[keys[i]] = +_row.totalbal > 0 ? String.formatNumber.new(_row.totalbal) : '';
                            }
    
                            acctotal['0'] += +gentotal;
                            _push['L'] = +gentotal > 0 ? String.formatNumber.new(gentotal) : '',
        
                            tableBody.push(_push);
                            accRead.push(row.accaccou);
                        }
                    }
    
                    const _totpush = {
                        A: '',
                        B: 'SUBTOTALES',
                        C: '', 
                        D: '',
                        E: '',
                        F: '',
                        G: '',
                        H: '',
                        I: '',
                        J: '',
                        K: '',
                        L: ''
                    };
    
                    for(let i = 90; i <= 99; i++) {
                        if (i === 94) continue;
                        amototal[i] += +acctotal[i]
                        _totpush[keys[i]] = +acctotal[i] > 0 ? String.formatNumber.new(acctotal[i]) : '0.00';
                    }
    
                    amototal['0'] += +acctotal['0']
                    _totpush['L'] = +acctotal['0'] > 0 ? String.formatNumber.new(acctotal['0']) : '0.00';
    
                    tableBody.push(_totpush);
                    tableBody.push(
                        { 
                            A: '',
                            B: '',
                            C: '',
                            D: '',
                            E: '',
                            F: '',
                            G: '',
                            H: '',
                            I: '',
                            J: '',
                            K: '',
                            L: ''
                        }
                    )
    
                    unicodopRead.push(iterator.parenacc);
                    cutRows.push(tableBody.length - 1);
                }
            }

            const _totpush = {
                A: '',
                B: 'TOTALES FINALES'
            };
    
            for(let i = 90; i <= 99; i++) {
                if (i === 94) continue;
                _totpush[keys[i]] = +amototal[i] > 0 ? String.formatNumber.new(amototal[i]) : '0.00';
            }
    
            _totpush['L'] = +amototal['0'] > 0 ? String.formatNumber.new(amototal['0']) : '0.00';
    
            tableBody.push(_totpush);
            cutRows.push(tableBody.length);

            const header = [
                'A',
                'B',
                'C',
                'D',
                'E',
                'F',
                'G',
                'H',
                'I',
                'J',
                'K',
                'L'
            ];

            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(tableBody, {header, skipHeader: true});
            ws["!cols"] = [
                {wpx:50},{wpx:200},{wpx:60},{wpx:60},{wpx:60},{wpx:60},{wpx:60},{wpx:60},{wpx:60},{wpx:60},{wpx:60},{wpx:60}
            ];
            ws['!rows'] = [
                {hpx: 16},{hpx: 18},{hpx: 16},{hpx: 34}
            ];

            keys = Object.keys(ws);
            const rowsCount = tableBody.length;

            for (const iterator of keys) {
                if(!isNaN(parseInt(ws[iterator].v)) && !iterator.match(/^(A|B)[0-9]+$/g) && +(iterator.match(/\d+/)[0]) > 4) {
                    ws[iterator].v = ws[iterator].v.replace(/,/g, '');
                    ws[iterator].t = 'n';
                    ws[iterator].z = '#,##0.00';
                }
                if(iterator !== '!ref' && iterator !== '!cols' && iterator !== '!rows') {
                    ws[iterator].s = {
                        font: {
                            name: 'Tahoma',
                            sz: 8
                        }
                    };
                    const row = +(iterator.match(/\d+/)[0]);
                    if(cutRows.includes(row) && !iterator.match(/^(A|B)[0-9]+$/g)) {
                        ws[iterator].s.border = {
                            ...ws[iterator].s.border,
                            bottom: { style: 'thin', color: 'black'},
                            top: { style: 'thin', color: 'black'}
                        }
                    }
                }
            }

            for (let index = 5; index <= rowsCount; index++) {
                ws['!rows'].push({hpx: 12})
            }

            ws['A1'].s = {font:{name: 'Tahoma', sz: 8, bold: true}}
            ws['A2'].s = {font:{name: 'Tahoma', sz: 15, bold: true}, alignment: { wrapText: true, vertical: 'center', horizontal: 'center'}}
            ws['A3'].s = {font:{name: 'Tahoma', sz: 8, bold: true}}
            ws['A4'].s = {font:{name: 'Tahoma', sz: 8, bold: true}}

            for (const iterator of header) {
                const key1 = iterator + "4";
                ws[key1].s = {
                    ...ws[key1].s,
                    alignment: { wrapText: true, vertical: 'center', horizontal: 'center'},
                    border: {
                        bottom: { style: 'thin', color: 'black'}
                    }
                }
            };

            ws["!merges"] = [
                { s: { r: 1, c: 0 }, e: { r: 1, c: 11 } },
            ];


            XLSX.utils.book_append_sheet(wb, ws, "Analisis Gatos 6");

            var wbout = XLSX.write(wb, {bookType:'xlsx', type: 'binary', cellStyles: true});

            const s2ab = function(s) {
                var buf = new ArrayBuffer(s.length);
                var view = new Uint8Array(buf);
                for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
                return buf;
            }

            saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), `analisis-gastos-6-${year}-${month}.xlsx`);

        } catch(err) {
            console.log(err);
        }
    }

    render() {

        const { title, year, month, base64Pdf, accumulated } = this.state;
        const { loading, list, tables, failed, failedTitle, failedContent } = this.props.accountantReducers;
        const { classes } = this.props;

        return (
            <div style={{overflow: 'auto', height: '100%'}}>
                <Paper className='form__container'>
                    <Typography variant='h5'>{ title }</Typography>
                    <br/>
                    { failed && <MessageBox title={failedTitle} content={failedContent}/> }
                    <div style={{ display: 'flex' }}>
                        <form noValidate autoComplete="off" style={{ flex: 1, paddingRight: '15px', paddingTop: '2em' }}>
                            <Grid container spacing={2} >
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_año">Año</InputLabel>
                                            <Select
                                                labelId="lbl_año"
                                                id="year"
                                                className='select'
                                                label="Año"
                                                fullWidth
                                                value={ year }
                                                onChange={(e: any) => this.onChangeRoot({'year': e.target.value})}
                                            >
                                                <MenuItem value='2030'>2030</MenuItem>
                                                <MenuItem value='2029'>2029</MenuItem>
                                                <MenuItem value='2028'>2028</MenuItem>
                                                <MenuItem value='2027'>2027</MenuItem>
                                                <MenuItem value='2026'>2026</MenuItem>
                                                <MenuItem value='2025'>2025</MenuItem>
                                                <MenuItem value='2026'>2026</MenuItem>
                                                <MenuItem value='2025'>2025</MenuItem>
                                                <MenuItem value='2024'>2024</MenuItem>
                                                <MenuItem value='2023'>2023</MenuItem>
                                                <MenuItem value='2022'>2022</MenuItem>
                                                <MenuItem value='2021'>2021</MenuItem>
                                                <MenuItem value='2020'>2020</MenuItem>
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_mes">Mes</InputLabel>
                                            <Select
                                                labelId="lbl_mes"
                                                id="month"
                                                className='select'
                                                label="Mes"
                                                fullWidth
                                                value={ month }
                                                onChange={(e: any) => this.onChangeRoot({'month': e.target.value})}
                                            >
                                                {
                                                    meses.map((x: any) => 
                                                        <MenuItem value={x.key} key={x.key}>{x.value}</MenuItem>
                                                    )
                                                }
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={accumulated}
                                                onChange={(e: any) => this.onChangeRoot({'accumulated': e.target.checked})}
                                                name="accumulated"
                                                color="primary"
                                            />
                                        }
                                        label="Acumulado"
                                    />
                                </Grid>
                                <Grid item xs={12} style={{ paddingTop: '50px' }}>
                                    <Button variant="contained" fullWidth color="primary" onClick={() => { 
                                        const balacode = [];
                                        if(accumulated)
                                            for(let i = 0; i <= +month; i++) {
                                                balacode.push(`${year}${i.toString().padStart(2, '0')}`)
                                            }
                                        else {
                                            balacode.push(`${year}${month.toString().padStart(2, '0')}`)
                                        }
                                        this.props.expenseAnalysisSixRequest(year, balacode, this.onGeneratePdf) 
                                    }}>
                                        Generar Reporte PDF
                                    </Button>
                                </Grid>
                                <Grid item xs={12} style={{ paddingTop: '10px' }}>
                                    <Button variant="contained" fullWidth color="inherit" onClick={() => { 
                                        const balacode = [];
                                        if(accumulated)
                                            for(let i = 0; i <= +month; i++) {
                                                balacode.push(`${year}${i.toString().padStart(2, '0')}`)
                                            }
                                        else {
                                            balacode.push(`${year}${month.toString().padStart(2, '0')}`)
                                        }
                                        this.props.expenseAnalysisSixRequest(year, balacode, this.onGenerateExcel) 
                                    }}>
                                        Generar Reporte EXCEL
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                        <PdfView data={base64Pdf} style={{ flex: 2 }}/>
                    </div>
                    <br/>
                    {/* <FormFooter onCancel={ this.onCancel } onSubmit={ this.onSubmit }/> */}
                </Paper>
                <ScreenLoader enabled={loading}/>
            </div>
        );
    }
}

const accountantReducers = 'accountantReducers';
const dashboardReducers = 'dashboardReducers';

const mapStateToProps = (state: any) => ({
    accountantReducers: state.get(accountantReducers),
    dashboardReducers: state.get(dashboardReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        expenseAnalysisSixRequest
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(ReportExpenseAnalysisSix));