import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { PrivateRoute } from 'penta-components/base/privateRoute';
import { PublicRoute } from 'penta-components/base/publicRoute';
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Application from './application';
import Auth from './auth';
(window as any).__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

const theme = createMuiTheme({
  palette: {
    primary: { 
      main: '#0060A6'
    },
  },
});

class App extends React.Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Switch>
          <Route path="/" exact>
            <Redirect to="/papp" />
          </Route>
          {/* <Route path="/" exact component={LandingCorporate} /> */}
          <PrivateRoute path="/papp" component={Application}/>
          <PublicRoute component={Auth} />
        </Switch>
      </ThemeProvider>
    );
  }
}

export default App;