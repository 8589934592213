import React from 'react';
import { Backdrop, ButtonGroup, Checkbox, CircularProgress, createStyles, Paper, TextField, Theme, Tooltip, withStyles } from '@material-ui/core';
import ListView from 'penta-components/base/listView';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { usersGetAllRecordsRequest, usersListSelectItem, usersListSelectAllItem, usersActionFailed, usersDeleteRequest } from 'penta-redux/actions/usersActions';
import history from '../../../utils/history';
import './users.scss'
import filePNG from './../../../../icons/fluent/file.png';
import fileEditPNG from './../../../../icons/fluent/file-edit.png';
import fileDeletePNG from './../../../../icons/fluent/delete.png';
import fileCopyPNG from './../../../../icons/fluent/copy.png';
import fileDownloadPNG from './../../../../icons/fluent/download.png';
import fileUploadPNG from './../../../../icons/fluent/upload.png';
import { MessageBox } from 'penta-components/base/messageBox';
import ActionDialog from 'penta-components/base/dialog';
import { Input } from 'penta-components/base/input';
import Button from 'penta-components/base/button';

const useStyles = (theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    });

const header = [
    { title: 'Username', key: 'username' },
    { title: 'Nombres', key: 'usr_userinfo.nameeess' },
    { title: 'Apellido Paterno', key: 'usr_userinfo.lastname' },
    { title: 'Apellido Materno', key: 'usr_userinfo.mlstname' },
    { title: 'Correo', key: 'usr_userinfo.emailadr' },
    { title: 'Telefono', key: 'usr_userinfo.phonumbr' },
    { title: 'Dirección', key: 'usr_userinfo.addrline' },
    { title: 'Estado', key: 'usrsttus' },
]

class ListUsers extends React.Component<any, any> {
// (173/20).toFixed(0) = 9
    constructor(props: any) {
        super(props);
        this.state = {
            openModal: false,
            searchValue: '',
            skip: 0, // (pagina - 1) *20
            take: 20,
            currentPage: 1
        }
    }

    componentDidMount() {
        const { skip, take } = this.state;
        const { usersGetAllRecordsRequest } = this.props;
        usersGetAllRecordsRequest({skip, take}); 
    }

    agregar = () => {
        history.push('/papp/users/maestro-usuarios/agregar');
    }

    editar = () => {
        const select = this.props.usersReducers.list.find((x: any) => x.select);
        if (select !== undefined)
            history.push('/papp/users/maestro-usuarios/editar/' + select.username);
        else
            this.props.usersActionFailed('Error: 0x0', 'No se ha selecionado un elemento para modificar');
    }

    eliminar = () => {
        const select = this.props.usersReducers.list.filter((x: any) => x.select).map((x: any) => {
            return x.username
        })
        this.props.usersDeleteRequest(select);
        this.handleCloseModal();
    }

    handleClickOpenModal = () => {
        // setAnchorEl(null);
        this.setState({
            openModal: true
        });
    };

    handleCloseModal = () => {
        this.setState({
            openModal: false
        });
    };

    onChangeRoot = (value) => {
        this.setState((state) => ({
            ...state,
            ...value
        }))
    }

    onSearch = (e) => {
        const { searchValue, skip, take } = this.state;
        const { usersGetAllRecordsRequest } = this.props;
        usersGetAllRecordsRequest(
            {
                fields: { 
                    'usr_sysusers.username': `like|'%${searchValue}%'`,
                    'usr_userinfo.nameeess': `like|'%${searchValue}%'`,
                    'usr_userinfo.lastname': `like|'%${searchValue}%'`,
                },
                skip,
                take
            } as any
        ); 
    }

    onNavigate = (page: number) => {
        const { searchValue, skip, take } = this.state;
        const { usersGetAllRecordsRequest } = this.props;
        this.setState({
            currentPage: page,
            skip: (page - 1) * 20
        });
        usersGetAllRecordsRequest(
            {
                fields: { 
                    'usr_sysusers.username': `like|'%${searchValue}%'`,
                    'usr_userinfo.nameeess': `like|'%${searchValue}%'`,
                    'usr_userinfo.lastname': `like|'%${searchValue}%'`,
                },
                skip: (page - 1) * 20,
                take
            } as any
        ); 
    }

    render() {
        const { searchValue, currentPage } = this.state;
        const { loading, list, total, failed, failedTitle, failedContent  } = this.props.usersReducers;
        const { classes } = this.props;

        return (
            <div style={{overflow: 'auto', height: '100%'}}>
                <Paper className='list__container'>
                    <div className='search__container'>
                        <div className='search__box'>
                            {/* <TextField id="outlined-search" fullWidth label="Buscar" type="search" variant="outlined" /> */}
                            <Input id="search" type="text" value={searchValue} onChange={ (e: any) => this.onChangeRoot({'searchValue': e.target.value}) }/>
                        </div>
                        <Button size="small" type="primary" onClick={this.onSearch}>buscar</Button>
                    </div>
                </Paper>
                <br/>
                { failed && <MessageBox title={failedTitle} content={failedContent}/> }
                <Paper className='list__container'>
                    <div className='tool__container'>
                        <Tooltip title="Agregar" aria-label="agregar">
                            <button><img width={25} height={25} src={ filePNG } onClick={() => this.agregar()}/></button>
                        </Tooltip>
                        <Tooltip title="Editar" aria-label="editar">
                            <button><img width={25} height={25} src={ fileEditPNG } onClick={() => this.editar() }/></button>
                        </Tooltip>
                        <Tooltip title="Eliminar" aria-label="eliminar">
                            <button><img width={25} height={25} src={ fileDeletePNG } onClick={() => this.handleClickOpenModal() }/></button>
                        </Tooltip>
                        <Tooltip title="Copiar" aria-label="copiar">
                            <button><img width={25} height={25} src={ fileCopyPNG }/></button>
                        </Tooltip>
                        <div style={{ margin: '7px 0px', marginRight: '5px', width: '2px', backgroundColor: '#cecdcd' }}/>
                        <Tooltip title="Importar" aria-label="importar">
                            <button><img width={25} height={25} src={ fileUploadPNG }/></button>
                        </Tooltip>
                        <Tooltip title="Exportar" aria-label="exportar">
                            <button><img width={25} height={25} src={ fileDownloadPNG }/></button>
                        </Tooltip>
                        {/* <ButtonGroup color="default" variant='text' aria-label="outlined primary button group">
                            <Button endIcon={ <i className="fas fa-plus" style= {{ color: '#005180'}}></i> }>Agregar</Button>
                            <Button endIcon={ <i className="fas fa-edit" style= {{ color: '#1fa591'}}></i> }>Editar</Button>
                            <Button endIcon={ <i className="fas fa-trash" style= {{ color: '#d40303'}}></i> }>Eliminar</Button>
                        </ButtonGroup> */}
                    </div>
                    <ListView
                        header={header} body={list} 
                        selectAction={this.props.usersListSelectItem} selectAllAction={this.props.usersListSelectAllItem} 
                        currentPage={currentPage} total={total}
                        onNavigate={this.onNavigate}/>
                    <br/>
                </Paper>
               <ActionDialog handleClose={this.handleCloseModal} open={this.state.openModal} onSubmit={this.eliminar}/>
            </div>
        );
    }
}

const reducer = 'usersReducers';

const mapStateToProps = (state: any) => ({
    usersReducers: state.get(reducer)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        usersGetAllRecordsRequest,
        usersListSelectItem,
        usersListSelectAllItem,
        usersActionFailed,
        usersDeleteRequest
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(ListUsers));