export const requestWithParams = <T>(type: string, body: T) => {
    return {
        type,
        body
    }
}

export const requestWithoutParams = (type: string) => {
    return { type }
}

export const requestSuccess = (type: string) => {
    return {
        type
    }
}

export const requestFailed = (type: string, titleError: string, titleContent: string) => {
    return {
        type,
        titleError,
        titleContent
    }
}