import React from 'react';
import { Backdrop, Button, ButtonGroup, Checkbox, CircularProgress, createStyles, Dialog, FormControl, Grid, IconButton, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Select, Snackbar, TextField, Theme, Tooltip, Typography, WithStyles, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { debitNoteInitFormRequest, debitNoteInitUpdateFormRequest, debitNoteInsertRequest, debitNoteUpdateRequest, purchasesInitUpdateFormRequest } from 'penta-redux/actions/shoppingsActions';
import { currencyExchangeRateRequest } from 'penta-redux/actions/generalActions';
import history from '../../../utils/history';
import './debitNote.scss'
import FormFooter from 'penta-components/base/formFooter';
import { MessageBox } from 'penta-components/base/messageBox';
import { pbl_debinote, purchaseEntity } from 'models/shoppingModels';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import EditableCell from 'penta-components/base/editableCell';
import { Alert } from '@material-ui/lab';
import ScreenLoader from 'penta-components/base/screenLoader';

const header = ["", "Producto", "Descripcion", "Unidad", "Cuenta Contable", "Cantidad", "Precio Unitario", "Subtotal", "Total"];

const useStyles = (theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        formControl: {
            margin: 0,
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    });

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        }
    });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const defaultDebitNoteEntity: pbl_debinote = {
    serialnu: '',
    documnum: '',
    docsuppl: '',
    officode: '',
    seriaref: '',
    documref: '',
    debitype: '',
    currency: '',
    curreval: 3.2,
    emitdate: moment().format('YYYY-MM-DD'),
    acpemont: moment().format('MM'),
    acpeyear: moment().format('YYYY'),
    intecode: 0,
    accaccou: '',
    fiscyear: +localStorage.getItem('currentFiscyear'),
    subtotal: '0.00',
    totaltax: '0.00',
    totalamo: '0.00',
    amoupaid: '0.00',
    balanpay: '0.00',
    amountss: [],
    balances: [],
    pbl_denotdet: []
}

interface FormDebitNoteState {
    debitNoteEntity: pbl_debinote
    title: string
    itemnumb: number
    snackbar: boolean
}

class FormPurchases extends React.Component<any, FormDebitNoteState> {

    constructor(props: any) {
        super(props);
        defaultDebitNoteEntity.pbl_denotdet = [];
        this.state = {
            debitNoteEntity: defaultDebitNoteEntity,
            itemnumb: 0,
            title: 'Agregar Nota de Debito',
            snackbar: false
        }
    }

    onChangeRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            ...value
        }))
    }

    onChangeDebitNoteRoot = async (value: any) => {
        const key = Object.keys(value)[0];
        if(key === 'docsuppl') {
            this.setState((state) => ({
                ...state,
                itemnumb: 0,
                debitNoteEntity: {
                    ...state.debitNoteEntity,
                    ...value,
                    seriaref: '',
                    documref: '',
                    accaccou: null,
                    fiscyear: 0,
                    subtotal: '0.00',
                    totaltax: '0.00',
                    totalamo: '0.00',
                    pbl_denotdet: []
                }
            }))
        }  else if(key === 'currency' || key === 'curreval') {
            const { pbl_denotdet } = this.state.debitNoteEntity;
            this.setState((state) => ({
                ...state,
                debitNoteEntity: {
                    ...state.debitNoteEntity,
                    ...value
                }
            }))
            setTimeout(() => {
                for(let i = 0; i < pbl_denotdet.length; i++) {
                    this.onEditCell({index: i, field: 'select' }, (pbl_denotdet[i] as any).select)
                }
            }, 0);
            
        } else if(key === 'emitdate') {
            this.setState((state) => ({
                ...state,
                debitNoteEntity: {
                    ...state.debitNoteEntity,
                    ...value
                }
            }))
            if(this.state.debitNoteEntity.currency !== '00000014')
                this.props.currencyExchangeRateRequest(moment(value[key]).format('YYYY-MM-DD'), this.onExchangeRateCallback);
        }
        else
            this.setState((state) => ({
                ...state,
                debitNoteEntity: {
                    ...state.debitNoteEntity,
                    ...value
                }
            }))
    }

    onExchangeRateCallback = () => {
        const { tables } = this.props.generalReducers;
        const { pbl_denotdet } = this.state.debitNoteEntity;
        // console.log(tables.exchangeRate);
        if(tables.exchangeRate !== null) {
            this.setState((state) => ({
                ...state,
                debitNoteEntity: {
                    ...state.debitNoteEntity,
                    curreval: tables.exchangeRate.venta
                }
            }))
        }
        setTimeout(() => {
            for(let i = 0; i < pbl_denotdet.length; i++) {
                this.onEditCell({index: i, field: 'select' }, (pbl_denotdet[i] as any).select)
            }
        }, 0);
    }

    onCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        console.log('cancel')
        history.push('/papp/shopping/notas-debito');
    }

    onContinueCallback = () => {
        const newDebitNoteEntity = defaultDebitNoteEntity;
        newDebitNoteEntity.pbl_denotdet = [];
        this.setState({
            debitNoteEntity: newDebitNoteEntity,
            itemnumb: 0,
            snackbar: true
        });
    }

    onSubmitContinue = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const { params } = this.props.match;
        if (params.serialnu !== undefined) {
            this.props.debitNoteUpdateRequest(this.state.debitNoteEntity, null);
        } else {
            this.props.debitNoteInsertRequest(this.state.debitNoteEntity, this.onContinueCallback);
        }
    }

    onSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const { params } = this.props.match;
        if (params.serialnu !== undefined) {
            this.props.debitNoteUpdateRequest(this.state.debitNoteEntity, null);
        } else {
            this.props.debitNoteInsertRequest(this.state.debitNoteEntity, null);
        }
    }

    onEditCell = (props: any, value: any) => {
        const { grl_definiti } = this.props.shoppingsReducers.tables;
        const pbl_denotdet: any = this.state.debitNoteEntity.pbl_denotdet;
        pbl_denotdet[props.index][props.field] = value;
        const taxvalue = grl_definiti.find((x: any) => x.deficode === '00000016').defivalu;
        let quantity = pbl_denotdet[props.index]['quantity'].replace(/,/g, '');
        let price = pbl_denotdet[props.index]['unitpric'].replace(/,/g, '');
        let subtotal = 0;
        let total = 0;
        if(props.field === 'amototal')
        {
            subtotal = +value.replace(/,/g, '') / (1 + parseFloat(taxvalue));
            price = value / quantity;
            pbl_denotdet[props.index]['unitpric'] = String.formatNumber.formatear(Math.round(price * 1000000) / 1000000, '000000');
            pbl_denotdet[props.index]['subtotal'] = String.formatNumber.formatear(Math.round(subtotal * 100) / 100, '00');
        }
        else if(props.field === 'unitpric')
        {
            total = quantity * +value.replace(/,/g, '');
            subtotal = total / (1 + parseFloat(taxvalue));
            pbl_denotdet[props.index]['amototal'] = String.formatNumber.formatear(Math.round(total * 100) / 100, '00');
            pbl_denotdet[props.index]['subtotal'] = String.formatNumber.formatear(Math.round(subtotal * 100) / 100, '00');
        }
        else if(props.field === 'quantity') 
        {
            total = price * +value.replace(/,/g, '');
            subtotal = total / (1 + parseFloat(taxvalue));
            pbl_denotdet[props.index]['amototal'] = String.formatNumber.formatear(Math.round(total * 100) / 100, '00');
            pbl_denotdet[props.index]['subtotal'] = String.formatNumber.formatear(Math.round(subtotal * 100) / 100, '00');
        }

        let _subtotalG = 0;
        let _igvG = 0;
        let _totalG = 0;

        pbl_denotdet.forEach((item: any) => {
            _subtotalG += parseFloat(item.subtotal.replace(/,/g, ''));
            // _igvG += (parseFloat(item.amototal.replace(/,/g, '')) - _subtotalG);
            _totalG += parseFloat(item.amototal.replace(/,/g, ''));
        });
        _igvG = (_totalG - _subtotalG);

        _subtotalG = Math.round(_subtotalG*100)/100;
        _igvG = Math.round(_igvG*100)/100;
        _totalG = Math.round(_totalG*100)/100;

        const companyCurrency = '00000014';
        const documentCurrency = this.state.debitNoteEntity.currency;
        const change = this.state.debitNoteEntity.curreval;
        let amoupaid = +this.state.debitNoteEntity.amoupaid.replace(/,/g, '');
        let balanpay = _totalG - amoupaid;
        amoupaid = Math.round(amoupaid*100)/100;
        balanpay = Math.round(balanpay*100)/100;
        let amountssG = {};
        let balancesG = {};
        if(companyCurrency !== documentCurrency) {
            pbl_denotdet[props.index].amountss = {
                [companyCurrency]: {
                    amototal: Math.round(+pbl_denotdet[props.index]['amototal'].replace(/,/g, '') * change*100)/100,
                    subtotal: Math.round(Math.round(pbl_denotdet[props.index]['amototal'].replace(/,/g, '')*change*100)/100 / (1+(+taxvalue)) *100)/100,
                    unitpric: Math.round(+pbl_denotdet[props.index]['unitpric'].replace(/,/g, '') * change*100)/100,
                    quantity: Math.round(+pbl_denotdet[props.index]['quantity'].replace(/,/g, '') * 100)/100
                }
            }
            amountssG = {
                [companyCurrency]: {
                    subtotal: Math.round(_totalG / (1+(+taxvalue))  * change*100)/100,
                    totaltax: Math.round(_totalG * change*100)/100 - Math.round(Math.round(_totalG * change * 100)/100 / (1+(+taxvalue)) *100)/100,
                    totalamo: Math.round(_totalG * change*100)/100
                },
                [documentCurrency]: {
                    totalamo: _totalG,
                    totaltax: _igvG,
                    subtotal: _subtotalG
                }
            },
            balancesG = {
                [companyCurrency]: {
                    balanpay: Math.round(balanpay * change*100)/100,
                    amoupaid: Math.round(amoupaid * change*100)/100
                }
            }
        }
        else {
            pbl_denotdet[props.index].amountss = {
                [companyCurrency]: {
                    amototal: +pbl_denotdet[props.index]['amototal'].replace(/,/g, ''),
                    subtotal: +pbl_denotdet[props.index]['subtotal'].replace(/,/g, ''),
                    unitpric: +pbl_denotdet[props.index]['unitpric'].replace(/,/g, ''),
                    quantity: +pbl_denotdet[props.index]['quantity'].replace(/,/g, ''),
                }
            }
            amountssG = {
                [companyCurrency]: {
                    totalamo: _totalG,
                    totaltax: _igvG,
                    subtotal: _subtotalG
                },
                '00000015': {
                    subtotal: Math.round(_totalG / (1+(+taxvalue))  / change*100)/100,
                    totaltax: Math.round(_totalG / change*100)/100 - Math.round(Math.round(_totalG / change * 100)/100 / (1+(+taxvalue)) *100)/100,
                    totalamo: Math.round(_totalG / change*100)/100
                },
            },
            balancesG = {
                [companyCurrency]: {
                    balanpay: balanpay,
                    amoupaid: amoupaid
                }
            }
        }

        this.setState((state: FormDebitNoteState) => ({
            ...state,
            debitNoteEntity: {
                ...state.debitNoteEntity,
                pbl_denotdet,
                totalamo: String.formatNumber.formatear(_totalG),
                totaltax: String.formatNumber.formatear(_igvG),
                subtotal: String.formatNumber.formatear(_subtotalG),
                amoupaid: String.formatNumber.formatear(amoupaid),
                balanpay: String.formatNumber.formatear(balanpay),
                amountss: amountssG,
                balances: balancesG
            }
        }))
    }

    onAddDetail = () => {
        const { whp_unitmeas } = this.props.shoppingsReducers.tables;
        const { pbl_denotdet } = this.state.debitNoteEntity;
        const itemnumb = this.state.itemnumb + 1;
        const newItem: any = {
            itemnumb: itemnumb,
            skucodee: null,
            prodname: '',
            meascode: whp_unitmeas[0].meascode,
            quantity: '1.00',
            unitpric: '0.000000',
            subtotal: '0.00',
            amototal: '0.00',
            accaccou: null,
            fiscyear: null,
            amountss: {},
            select: false
        };
        (pbl_denotdet as any).push(newItem);
        this.setState((state: any) => ({
            ...state,
            itemnumb,
            debitNoteEntity: {
                ...state.debitNoteEntity,
                pbl_denotdet,
            }
        }))
    }

    onDeleteDetail = () => {
        const { grl_definiti } = this.props.shoppingsReducers.tables;
        let { pbl_denotdet } = this.state.debitNoteEntity;        
        pbl_denotdet = pbl_denotdet.filter((x: any) => x.select === false || x.select === undefined);
        const taxvalue = grl_definiti.find((x: any) => x.deficode === '00000016').defivalu;
        
        let _subtotalG = 0;
        let _igvG = 0;
        let _totalG = 0;

        pbl_denotdet.forEach((item: any) => {
            _subtotalG += parseFloat(item.subtotal.replace(/,/g, ''));
            // _igvG += (parseFloat(item.amototal.replace(/,/g, '')) - _subtotalG);
            _totalG += parseFloat(item.amototal.replace(/,/g, ''));
        });
        _igvG = (_totalG - _subtotalG);

        _subtotalG = Math.round(_subtotalG*100)/100;
        _igvG = Math.round(_igvG*100)/100;
        _totalG = Math.round(_totalG*100)/100;

        const companyCurrency = '00000014';
        const documentCurrency = this.state.debitNoteEntity.currency;
        const change = this.state.debitNoteEntity.curreval;
        let amoupaid = +this.state.debitNoteEntity.amoupaid.replace(/,/g, '');
        let balanpay = _totalG - amoupaid;
        amoupaid = Math.round(amoupaid*100)/100;
        balanpay = Math.round(balanpay*100)/100;
        let balancesG = {};
        let amountssG = {};
        if(companyCurrency !== documentCurrency) {
            amountssG = {
                [companyCurrency]: {
                    subtotal: Math.round(_totalG / (1+(+taxvalue))  * change*100)/100,
                    totaltax: Math.round(_totalG * change*100)/100 - Math.round(Math.round(_totalG * change * 100)/100 / (1+(+taxvalue)) *100)/100,
                    totalamo: Math.round(_totalG * change*100)/100
                },
                [documentCurrency]: {
                    totalamo: _totalG,
                    totaltax: _igvG,
                    subtotal: _subtotalG
                }
            },
            balancesG = {
                [companyCurrency]: {
                    balanpay: Math.round(balanpay * change * 100) / 100,
                    amoupaid: Math.round(amoupaid * change * 100) / 100
                }
            }
        }
        else {
            amountssG = {
                [companyCurrency]: {
                    totalamo: _totalG,
                    totaltax: _igvG,
                    subtotal: _subtotalG
                },
                '00000015': {
                    subtotal: Math.round(_totalG / (1+(+taxvalue))  / change*100)/100,
                    totaltax: Math.round(_totalG / change*100)/100 - Math.round(Math.round(_totalG / change * 100)/100 / (1+(+taxvalue)) *100)/100,
                    totalamo: Math.round(_totalG / change*100)/100
                },
            },
            balancesG = {
                [companyCurrency]: {
                    balanpay: balanpay,
                    amoupaid: amoupaid
                }
            }
        }

        this.setState((state: FormDebitNoteState) => ({
            ...state,
            debitNoteEntity: {
                ...state.debitNoteEntity,
                pbl_denotdet,
                totalamo: String.formatNumber.formatear(_totalG),
                totaltax: String.formatNumber.formatear(_igvG),
                subtotal: String.formatNumber.formatear(_subtotalG),
                amoupaid: String.formatNumber.formatear(amoupaid),
                balanpay: String.formatNumber.formatear(balanpay),
                amountss: amountssG,
                balances: balancesG
            }
        }));
    }

    renderDetail = () => {
        const { whp_products, acc_planacco, whp_unitmeas } = this.props.shoppingsReducers.tables;
        const { pbl_denotdet, debitype } = this.state.debitNoteEntity;

        let dselect, dskucodee, dprodname, dmeascode, daccaccou, dquantity, dunitpric, dsubtotal, damototal;
        dselect = false, dskucodee = false, dprodname = false, dmeascode = false, daccaccou = false, dquantity = false, dunitpric = true, dsubtotal = true, damototal = false;
                       
        return pbl_denotdet.map((item: any, i: any) => (
            <tr key={ i } >
                <td className={ dselect ? "disabled" : "" }>
                    <input type="checkbox"
                    checked={ item.select } 
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'select' }, 
                            e.target.checked
                        )}
                    disabled={dselect}
                    />
                </td>
                <td className={ dskucodee ? "disabled" : "" } style={{width:'200px'}}>
                    <Autocomplete
                        id="skucodee"
                        freeSolo
                        options={whp_products}
                        getOptionLabel={ (x: any) => x.skucodee }
                        value={ whp_products.find((x: any) => x.skucodee === item.skucodee) || null }
                        onChange={(e: any, value) => {
                            this.onEditCell({index: i, field: 'skucodee'}, value.skucodee);
                            this.onEditCell({index: i, field: 'prodname'}, value.prodname);
                            this.onEditCell({index: i, field: 'meascode'}, value.meascode);
                            this.onEditCell({index: i, field: 'accaccou'}, value.accaccou);
                            this.onEditCell({index: i, field: 'fiscyear'}, value.fiscyear);
                        }}
                        renderInput={(params) => (
                            <TextField {...params} 
                            style={ { marginTop: 0 } } margin="normal" variant="outlined" />
                        )}
                        disabled={dskucodee}
                    />
                </td>
                <td className={ dprodname ? "disabled" : (item.skucodee || "") !== "" ? "disabled" : ""} style={{width:'250px'}}>
                    <input type="text"
                    value={ item.prodname }
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'prodname' }, 
                            e.target.value
                        )}
                    disabled={dprodname ? true : (item.skucodee || "") !== "" ? true : false}
                    />
                </td>
                <td className={ dmeascode ? "disabled" : (item.skucodee || "") !== "" ? "disabled" : "" } style={{width:'100px'}}>
                    <select style={{width:'100%'}} value={ item.meascode } 
                        onChange={ (e: any) => { 
                            this.onEditCell({index: i, field: 'meascode'}, e.target.value)
                        }}
                        disabled={dmeascode ? true : (item.skucodee || "") !== "" ? true : false}
                    >
                        { whp_unitmeas.map((item:any) => <option value={item.meascode} key={item.meascode}>{item.measname}</option>) }
                    </select>
                </td>
                <td className={ daccaccou ? "disabled" : (item.skucodee || "") !== "" ? "disabled" : "" } style={{width:'150px'}}>
                    <Autocomplete
                        id="accaccou"
                        freeSolo
                        options={acc_planacco}
                        getOptionLabel={ (x: any) => x.accaccou }
                        value={ acc_planacco.find((x: any) => x.accaccou === item.accaccou) || null }
                        onChange={(e: any, value) => {
                            this.onEditCell({index: i, field: 'accaccou'}, value.accaccou);
                            this.onEditCell({index: i, field: 'fiscyear'}, value.fiscyear);
                        }}
                        renderInput={(params) => (
                            <TextField {...params} 
                            onBlur={(e) => { 
                                const a = acc_planacco.find((x: any) => x.accaccou === e.target.value);
                                if(a) {
                                    this.onEditCell({index: i, field: 'accaccou'}, a.accaccou);
                                    this.onEditCell({index: i, field: 'fiscyear'}, a.fiscyear);
                                }
                            }}
                            style={ { marginTop: 0 } } margin="normal" variant="outlined" />
                        )}
                        disabled={ item.skucodee || '' !== '' }
                    />
                </td>
                <td className={ dquantity ? "disabled" : "" }>
                    <input type="text"
                    value={ item.quantity } 
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'quantity' }, 
                            e.target.value
                        )}
                    style={{ textAlign: 'right' }}
                    disabled={dquantity}
                    />
                </td>
                <td className={ dunitpric ? "disabled" : "" }>
                    <input type="text" 
                    value={ item.unitpric } 
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'unitpric' }, 
                            e.target.value
                        )}
                    style={{ textAlign: 'right' }}
                    disabled={dunitpric}
                    />
                </td>
                <td className={ dsubtotal ? "disabled" : "" }>
                    <input type="text"
                    value={ item.subtotal } 
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'subtotal' }, 
                            e.target.value
                        )}
                    style={{ textAlign: 'right' }}
                    disabled={dsubtotal}
                    />
                </td>
                <td className={ damototal ? "disabled" : "" }>
                    <input type="text"
                    value={ item.amototal } 
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'amototal' }, 
                            e.target.value
                        )}
                    style={{ textAlign: 'right' }}
                    disabled={damototal}
                    />
                </td>
            </tr>
        ))
    }

    renderGrilla = () => {
        return (
            <div className="form">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className="form__field">
                            <div className="grid__actions">
                                <button className="add" onClick={ (e) => { e.preventDefault(); this.onAddDetail() } }><i className="fas fa-plus"/></button>
                                <button className="remove" onClick={ (e) => { e.preventDefault(); this.onDeleteDetail() } }><i className="fas fa-times"/></button>
                                <span style={ { marginLeft: '15px' } }>[ENTER]: Agregar nuevo item</span>
                                <span style={ { marginLeft: '15px' } }>[TAB]: Navegar entre las celdas</span>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <EditableCell
                            header={ header }
                            data={ this.renderDetail() }
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }

    callbackInit = () => {
        const { tables } = this.props.shoppingsReducers;
        const debitype = tables.grl_definiti.filter((x: any) => x.defitype === 'NODYP')[0].deficode
        const currency = tables.grl_definiti.filter((x: any) => x.defitype === 'CURCY')[0].deficode
        this.setState((state: any) => ({
            ...state,
            itemnumb: 1,
            debitNoteEntity: {
                ...state.debitNoteEntity,
                debitype: debitype,
                currency: currency,
                curreval: tables.exchangeRate.venta
            },
            pbl_purchdet: []
        }));
    }

    callbackInitUpdate = (debitNoteEntity: pbl_debinote) => {
        const { tables } = this.props.shoppingsReducers;
        const supplier =  tables.pbl_supplier.find((x: any) => x.docnumbe === debitNoteEntity.docsuppl);
        debitNoteEntity.emitdate = moment(debitNoteEntity.emitdate).format('YYYY-MM-DD');
        debitNoteEntity.amountss = JSON.parse(debitNoteEntity.amountss);
        debitNoteEntity.balances = JSON.parse(debitNoteEntity.balances);
        (debitNoteEntity as any).addrline = supplier.addrline;
        for (const iterator of debitNoteEntity.pbl_denotdet) {
            iterator.amountss = JSON.parse(iterator.amountss);
        }
        this.setState({
            debitNoteEntity,
            itemnumb: debitNoteEntity.pbl_denotdet[debitNoteEntity.pbl_denotdet.length - 1].itemnumb
        });
    }

    componentDidMount() {
        const { params } = this.props.match;
        if (params.serialnu !== undefined) {
            this.setState({title: 'Editar Nota de Debito'});
            this.props.debitNoteInitUpdateFormRequest(params.serialnu, params.documnum, params.docsuppl, this.callbackInitUpdate);
        }
        else {
            this.props.debitNoteInitFormRequest(this.callbackInit);
        }
    }

    render() {

        const { debitNoteEntity, title, snackbar } = this.state;
        const { loading, list, tables, failed, failedTitle, failedContent } = this.props.shoppingsReducers;
        const generalTables = this.props.generalReducers.tables;
        const { classes } = this.props;

        return (
            <div style={{overflow: 'auto', height: '100%'}}>
                <Paper className='form__container'>
                    <br/>
                    <Typography variant='h5'>{ title }</Typography>
                    <br/>
                    { failed && <MessageBox title={failedTitle} content={failedContent}/> }
                    <form noValidate autoComplete="off">
                        <div className="seccion__title">PROVEEDOR</div>
                        <Grid container spacing={2} >
                            <Grid item xs={3}>
                                <Autocomplete
                                    id="docsuppl"
                                    freeSolo
                                    options={tables.pbl_supplier}
                                    getOptionLabel={ (x: any) => x.suppname + " " + x.lastname + " " + x.mlstname + x.businame }
                                    value={ tables.pbl_supplier.find((x: any) => x.docnumbe === debitNoteEntity.docsuppl) || null }
                                    onChange={(e: any, value) => this.onChangeDebitNoteRoot(
                                        {
                                            'docsuppl': value.docnumbe,
                                            'addrline': value.addrline,
                                        }
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} 
                                        style={ { marginTop: 0 } } label="Proveedor" margin="normal" variant="outlined" />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth id="docsuppl" label="RUC / DNI" variant="outlined" value={ debitNoteEntity.docsuppl }/>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth id="addrline" label="Direcion" variant="outlined" value={ (debitNoteEntity as any).addrline || '' }/>
                            </Grid>
                        </Grid>
                        <div className="seccion__title">DOCUMENTO</div>
                        <Grid container spacing={2} >
                            <Grid item xs={3}>
                                <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                    <InputLabel id="lbl_acpemont">Periodo contable - Mes</InputLabel>
                                    <Select
                                        labelId="lbl_acpemont"
                                        id="acpemont"
                                        className='select'
                                        label="Periodo contable - Mes"
                                        fullWidth
                                        value={debitNoteEntity.acpemont}
                                        onChange={(e: any) => this.onChangeDebitNoteRoot({'acpemont': e.target.value})}
                                    >
                                        <MenuItem value="01">01</MenuItem>
                                        <MenuItem value="02">02</MenuItem>
                                        <MenuItem value="03">03</MenuItem>
                                        <MenuItem value="04">04</MenuItem>
                                        <MenuItem value="05">05</MenuItem>
                                        <MenuItem value="06">06</MenuItem>
                                        <MenuItem value="07">07</MenuItem>
                                        <MenuItem value="08">08</MenuItem>
                                        <MenuItem value="09">09</MenuItem>
                                        <MenuItem value="10">10</MenuItem>
                                        <MenuItem value="11">11</MenuItem>
                                        <MenuItem value="12">12</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                    <InputLabel id="lbl_acpeyear">Periodo contable - Año</InputLabel>
                                    <Select
                                        labelId="lbl_acpeyear"
                                        id="acpeyear"
                                        className='select'
                                        label="Periodo contable - Año"
                                        fullWidth
                                        value={debitNoteEntity.acpeyear}
                                        onChange={(e: any) => this.onChangeDebitNoteRoot({'acpeyear': e.target.value})}
                                    >
                                        <MenuItem value="2019">2019</MenuItem>
                                        <MenuItem value="2020">2020</MenuItem>
                                        <MenuItem value="2021">2021</MenuItem>
                                        <MenuItem value="2022">2022</MenuItem>
                                        <MenuItem value="2023">2023</MenuItem>
                                        <MenuItem value="2024">2024</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} >
                            <Grid item xs={3}>
                                <TextField fullWidth id="serialnu" label="Serie" variant="outlined" value={ debitNoteEntity.serialnu } onChange={(e: any) => this.onChangeDebitNoteRoot({'serialnu': e.target.value})}/>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth id="documnum" label="Nro. de documento" variant="outlined" value={ debitNoteEntity.documnum } onChange={(e: any) => this.onChangeDebitNoteRoot({'documnum': e.target.value})}/>
                            </Grid>
                            <Grid item xs={3}>
                                <Autocomplete
                                    id="documnum"
                                    freeSolo
                                    options={tables.pbl_purchase.filter((x: any) => x.docsuppl === debitNoteEntity.docsuppl)}
                                    getOptionLabel={ (x: any) => (x.serialnu + '-' + x.documnum) }
                                    value={tables.pbl_purchase.find((x: any) => (x.serialnu + '-' + x.documnum) === (debitNoteEntity.seriaref + '-' + debitNoteEntity.documref)) || null }
                                    onChange={(e: any, value) => this.onChangeDebitNoteRoot(
                                        {
                                            'seriaref': value.serialnu,
                                            'documref': value.documnum,
                                            'currency': value.currency,
                                            'accaccou': value.accaccou, 
                                            'fiscyear': value.fiscyear
                                        }
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} 
                                        style={ { marginTop: 0 } } label="Documento de Ref." margin="normal" variant="outlined" />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                    <InputLabel id="lbl_tiponota">Tipo de nota</InputLabel>
                                    <Select
                                        labelId="lbl_tiponota"
                                        id="debitype"
                                        className='select'
                                        label="Tipo de nota"
                                        fullWidth
                                        value={debitNoteEntity.debitype}
                                        onChange={(e: any) => this.onChangeDebitNoteRoot({'debitype': e.target.value})}
                                    >
                                        {
                                            tables.grl_definiti.filter((x: any) => x.defitype === 'NODYP').map((item: any, key: any) => 
                                                <MenuItem value={item.deficode} key={`debitype_${key}`}>{item.definame}</MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid> 
                        <Grid container spacing={2} >
                            <Grid item xs={3}>
                                <TextField fullWidth type="date" id="emitdate" label="Fecha de emision" variant="outlined" value={ debitNoteEntity.emitdate } onChange={(e: any) => this.onChangeDebitNoteRoot({'emitdate': e.target.value})}/>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                    <InputLabel id="lbl_moneda">Moneda</InputLabel>
                                    <Select
                                        labelId="lbl_moneda"
                                        id="currency"
                                        className='select'
                                        label="Tipo de moneda"
                                        fullWidth
                                        value={debitNoteEntity.currency}
                                        onChange={(e: any) => this.onChangeDebitNoteRoot({'currency': e.target.value})}
                                    >
                                        {
                                            tables.grl_definiti.filter((x: any) => x.defitype === 'CURCY').map((item: any, key: any) => 
                                                <MenuItem value={item.deficode} key={`moneda_${key}`}>{item.definame}</MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth id="curreval"  label="Tipo de cambio" variant="outlined" value={ debitNoteEntity.curreval } onChange={(e: any) => this.onChangeDebitNoteRoot({'curreval': e.target.value})}/>
                            </Grid>
                        </Grid>
                        <div style={{ height: 50 }}/>
                        <Grid container spacing={2} >
                            { this.renderGrilla()  }
                        </Grid>
                        <div style={{ height: 50 }}/>
                        <Grid container spacing={2} >
                            <Grid item xs={3}/>
                            <Grid item xs={3}/>
                            <Grid container item xs={3} direction="row-reverse" alignItems="center">
                                <Typography variant="caption">Sub total</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth disabled id="subtotal" variant="outlined" className="txt_total" value={ debitNoteEntity.subtotal }/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} >
                            <Grid item xs={3}/>
                            <Grid item xs={3}/>
                            <Grid container item xs={3} direction="row-reverse" alignItems="center">
                                <Typography variant="caption">IGV</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth disabled id="totaltax" variant="outlined" className="txt_total" value={ debitNoteEntity.totaltax }/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} >
                            <Grid item xs={3}/>
                            <Grid item xs={3}/>
                            <Grid container item xs={3} direction="row-reverse" alignItems="center">
                                <Typography variant="caption">Importe Total</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth disabled id="totalamo" variant="outlined" className="txt_total" value={ debitNoteEntity.totalamo }/>
                            </Grid>
                        </Grid>
                    </form>
                    <br/>
                    <FormFooter 
                        onCancel={ this.onCancel } 
                        onSubmitContinue={ this.onSubmitContinue } 
                        onSubmit={ this.onSubmit }/>
                </Paper>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={snackbar}
                    onClose={() => this.setState({snackbar: false})}
                    autoHideDuration={6000}
                >
                    <Alert onClose={() => this.setState({snackbar: false})} severity="success">
                            Datos Guardados Correctamente
                    </Alert>
                </Snackbar>
                <ScreenLoader enabled={loading}/>
            </div>
        );
    }
}

const shoppingsReducers = 'shoppingsReducers';
const generalReducers = 'generalReducers';

const mapStateToProps = (state: any) => ({
    shoppingsReducers: state.get(shoppingsReducers),
    generalReducers: state.get(generalReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        debitNoteInitFormRequest,
        debitNoteInitUpdateFormRequest,
        debitNoteInsertRequest,
        debitNoteUpdateRequest,
        currencyExchangeRateRequest,
        purchasesInitUpdateFormRequest
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(FormPurchases));