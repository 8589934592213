import React from 'react';
import { Button, createStyles, Grid, Paper, TextField, Theme, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { invoicesPayRequest } from 'penta-redux/actions/shoppingsActions';
import './../reports.scss'
import { MessageBox } from 'penta-components/base/messageBox';
import moment from 'moment';
import * as XLSX from 'sheetjs-style';
import ScreenLoader from 'penta-components/base/screenLoader';

let saveAs = require('file-saver').saveAs;

const useStyles = (theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        formControl: {
            margin: 0,
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    });

interface FormState {
    title: string
    base64Pdf: string
    initdate: any
    endidate: any
}

class ReportInvoicesToPay extends React.Component<any, FormState> {

    constructor(props: any) {
        super(props);
        this.state = {
            title: 'Facturas por pagar',
            base64Pdf: '',
            initdate: moment().format('YYYY-MM-DD'),
            endidate: moment().format('YYYY-MM-DD')
        }
    }

    onChangeRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            ...value
        }))
    }

    onGenerateExcel = () => {
        try {
            const data = this.props.shoppingsReducers.onlyOne;
            const { dashboardReducers } = this.props;
            const { initdate, endidate } = this.state;
            const company = (JSON.parse(localStorage.getItem('tables')).companys.find((x: any) => x.taxident === dashboardReducers.currentCompany).bussname as string).toUpperCase()

            const tableBody: any = [
                {
                    A: company,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null,
                    G: null,
                    H: null
                },
                {
                    A: `FACTURAS POR PAGAR DEL ${moment(initdate).format('DD/MM/YYYY')} AL ${moment(endidate).format('DD/MM/YYYY')}`,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null,
                    G: null,
                    H: null
                },
                {
                    A: null,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null,
                    G: null,
                    H: null
                },
                {
                    A: 'Proveedor',
                    B: 'Nro Documento',
                    C: 'Fecha',
                    D: '0-7',
                    E: '8-15',
                    F: '16-30',
                    G: 'Mas de 30',
                    H: 'Total'
                },
                {
                    A: null,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null,
                    G: null,
                    H: null
                }
            ]

            const supplierRead: any = [];
            const cutRows: Array<number> = [];

            for (const row of data) {
                if (!supplierRead.includes(row.businame)) {
                    const purchases = data.filter(x => x.businame === row.businame);
                    let count = 1;
                    let total1 = 0;
                    let total2 = 0;
                    let total3 = 0;
                    let total4 = 0;
                    let total5 = 0;
                    for (const purchase of purchases) {
                        const emitdate = moment(purchase.emitdate);
                        const currentDate = moment(Date.now());
                        const expiredDays = currentDate.diff(emitdate, 'days');
                        tableBody.push(
                            { 
                                A: count == 1 || count == purchases.lenght ? row.businame : '',
                                B: `${purchase.serialnu}-${purchase.documnum}`,
                                C: moment(purchase.emitdate).format('DD/MM/YYYY'),
                                D: expiredDays <= 7 ? String.formatNumber.new(purchase.balanpay) : '',
                                E: expiredDays <= 15 && expiredDays >= 8 ? String.formatNumber.new(purchase.balanpay) : '',
                                F: expiredDays <= 30 && expiredDays >= 16  ? String.formatNumber.new(purchase.balanpay) : '',
                                G: expiredDays > 30  ? String.formatNumber.new(purchase.balanpay) : '',
                                H: String.formatNumber.new(purchase.balanpay)
                            }
                        );
                        total1 += (expiredDays <= 7 ? +purchase.balanpay : 0);
                        total2 += (expiredDays <= 15 && expiredDays >= 8 ? +purchase.balanpay : 0);
                        total3 += (expiredDays <= 30 && expiredDays >= 16 ? +purchase.balanpay : 0);
                        total4 += (expiredDays > 30 ? +purchase.balanpay : 0);
                        total5 += +purchase.balanpay;
                        count++;
                    }

                    tableBody.push(
                        { 
                            A: row.businame,
                            B: '',
                            C: '',
                            D: String.formatNumber.new(total1),
                            E: String.formatNumber.new(total2),
                            F: String.formatNumber.new(total3),
                            G: String.formatNumber.new(total4),
                            H: String.formatNumber.new(total5)
                        }
                    );
                    cutRows.push(tableBody.length);
                    supplierRead.push(row.businame);
                }
            
            }

            const header = [
                'A',
                'B',
                'C',
                'D',
                'E',
                'F',
                'G',
                'H'
            ];

            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(tableBody, {header, skipHeader: true});
            ws["!cols"] = [
                {wpx:200},{wpx:80},{wpx:70},{wpx:70},{wpx:70},{wpx:70},{wpx:70},{wpx:70}
            ];
            ws['!rows'] = [
                {hpx: 18},{hpx: 16},{hpx: 16},{hpx: 16},{hpx: 16}
            ];

            const keys = Object.keys(ws);
            const rowsCount = tableBody.length;

            for (const iterator of keys) {
                if(iterator !== '!ref' && iterator !== '!cols' && iterator !== '!rows') {
                    const row = +(iterator.match(/\d+/)[0]);
                    if(!isNaN(parseInt(ws[iterator].v)) && iterator.match(/^(D|E|F|G|H)[0-9]+$/g) && row > 4) {
                        ws[iterator].v = ws[iterator].v.replace(/,/g, '');
                        ws[iterator].t = 'n';
                        ws[iterator].z = '#,##0.00';
                    }
                    ws[iterator].s = {
                        font: {
                            name: 'Tahoma',
                            sz: 8
                        }
                    }
                    if(cutRows.includes(row) && !iterator.match(/^(A|B|C)[0-9]+$/g)) {
                        ws[iterator].s.border = {
                            ...ws[iterator].s.border,
                            bottom: { style: 'thin', color: 'black'},
                            top: { style: 'thin', color: 'black'}
                        }
                    }
                }
            }

            for (let index = 6; index <= rowsCount; index++) {
                ws['!rows'].push({hpx: 12})
            }

            ws['A1'].s = {font:{name: 'Tahoma', sz: 8, bold: true}}
            ws['A2'].s = {font:{name: 'Tahoma', sz: 15, bold: true}, alignment: { wrapText: true, vertical: 'center', horizontal: 'center'}}
            ws['A3'].s = {font:{name: 'Tahoma', sz: 8, bold: true}}

            for (const iterator of header) {
                const key1 = iterator + "4";
                ws[key1].s = {
                    ...ws[key1].s, 
                    alignment: { wrapText: true, vertical: 'center', horizontal: 'center'},
                    border: { 
                        top: { style: 'thin', color: 'black'},
                        bottom: { style: 'thin', color: 'black'},
                        left: { style: 'thin', color: 'black'},
                        right: { style: 'thin', color: 'black'}
                    } 
                }
            };

            ws["!merges"] = [
                { s: { r: 1, c: 0 }, e: { r: 1, c: 5 } },
            ];

            XLSX.utils.book_append_sheet(wb, ws, "Facturas por pagar");

            var wbout = XLSX.write(wb, {bookType:'xlsx', type: 'binary', cellStyles: true});

            const s2ab = function(s) {
                var buf = new ArrayBuffer(s.length);
                var view = new Uint8Array(buf);
                for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
                return buf;
            }

            saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), `facturas-pagar.xlsx`);

        } catch(err) {
            console.log(err);
        }
    }

    render() {

        const { title, initdate, endidate } = this.state;
        const { loading, failed, failedTitle, failedContent } = this.props.shoppingsReducers;

        return (
            <div style={{overflow: 'auto', height: '100%'}}>
                <Paper className='form__container'>
                    <Typography variant='h5'>{ title }</Typography>
                    <br/>
                    { failed && <MessageBox title={failedTitle} content={failedContent}/> }
                    <div style={{ display: 'flex' }}>
                        <form noValidate autoComplete="off" style={{ flex: 1, paddingRight: '15px', paddingTop: '2em' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <TextField fullWidth type="date" id="initdate" label="Fecha de inicio" variant="outlined" value={ initdate } onChange={(e: any) => this.onChangeRoot({'initdate': e.target.value})}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField fullWidth type="date" id="endidate" label="Fecha de fin" variant="outlined" value={ endidate } onChange={(e: any) => this.onChangeRoot({'endidate': e.target.value})}/>
                                </Grid>
                                <Grid item xs={4} style={{ paddingTop: '10px' }}>
                                    <Button variant="contained" fullWidth color="inherit" onClick={() => { this.props.invoicesPayRequest(initdate, endidate, this.onGenerateExcel) }}>
                                        Generar Reporte EXCEL
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                    <br/>
                    {/* <FormFooter onCancel={ this.onCancel } onSubmit={ this.onSubmit }/> */}
                </Paper>
                <ScreenLoader enabled={loading}/>
            </div>
        );
    }
}

const shoppingsReducers = 'shoppingsReducers';
const dashboardReducers = 'dashboardReducers';

const mapStateToProps = (state: any) => ({
    shoppingsReducers: state.get(shoppingsReducers),
    dashboardReducers: state.get(dashboardReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        invoicesPayRequest
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(ReportInvoicesToPay));