import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { authLogoutRequest } from 'redux/actions/authActions';
import {createUseStyles} from 'react-jss';

const PentaLogo = require('penta-assets/images/PentaErpLogo2.png').default;

const useStyles = createUseStyles({
    container: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        zIndex: 999999,
        left: 0,
        top: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#2424248f',
        '& > img': {
            width: 'auto',
            height: '100px',
            animation: '$loading 1.2s infinite linear'
        },
        // '& > div': {
        //     width: '100px',
        //     height: '10px',
        //     animation: '$shadown 1.2s infinite'
        // }
    },
    '@keyframes loading': {
        '0%': {
            transform: 'rotate(0deg)'
        },
        '100%': {
            transform: 'rotate(359deg)'
        }
    },
    // '@keyframes loading': {
    //     '0%': {
    //         transform: 'translateY(30px) scale(1, 1)'
    //     },
    //     '50%': {
    //         transform: 'translateY(-150px) scale(0.8, 1.2)'
    //     },
    //     '100%': {
    //         transform: 'translateY(37.5px) scale(1.2, 0.8)'
    //     }
    // },
    // '@keyframes shadown': {
    //     '0%': {
    //         width: '70px',
    //         boxShadow: '0px 20px 5px rgba(0,0,0,0.7)',
    //     },
    //     '50%': {
    //         width: '30px',
    //         boxShadow: '0px 20px 5px rgba(0,0,0,0.2)',
    //     },
    //     '100%': {
    //         width: '70px',
    //         boxShadow: '0px 20px 5px rgba(0,0,0,0.7)'
    //     }
    // }
});

interface ScreenLoader {
    enabled: boolean
}

const ScreenLoader = (props: ScreenLoader) => {
    const classes = useStyles();

    return (
        <React.Fragment>
            {
                props.enabled ?
                <div className={classes.container}>
                    <img src={PentaLogo}/>
                    <div />
                </div>
                :
                null
            }
        </React.Fragment>
    )
}

export default ScreenLoader;