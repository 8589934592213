import { authInitialState, dashboardInitialState } from 'models/statesModels';
import React, { Suspense, useEffect } from 'react';
import {createUseStyles} from 'react-jss';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import history from '../../utils/history';
import Tooltip from '../base/tooltip';
import { dashboardSelectMenu } from 'redux/actions/dashboardActions';

const ArrowBack = require('penta-assets/images/arrow-back.svg').ReactComponent;
// const HomeIcon = require('penta-assets/images/home.svg').ReactComponent;
const QuestionMark = require('penta-assets/images/question-mark.svg').ReactComponent;

const useStyles =  createUseStyles({
    container: {
        width: '5.625rem', 
        textAlign: 'center',
        padding: '2.5rem 0rem'
    },
    iconActive: {},
    iconSelect: {},
    iconDiv: {
        padding: '1.25rem 0rem',
        cursor: 'pointer',
        '& > svg': {
            fill: '#3B3B3B'
        },
        '&:hover': {
            backgroundColor: '#3B3B3B',
            '& > svg': {
                fill: 'white'
            }
        },
        '&$iconActive': {
            backgroundColor: '#3B3B3B',
            '& > svg': {
                fill: 'white'
            }
        },
        '&$iconSelect': {
            backgroundColor: '#525252',
            '& > svg': {
                fill: 'white'
            }
        }
    },
    submenuContainer: {
        position: 'absolute',
        width: '31.875rem',
        marginLeft: '5.625rem',
        height: '100%',
        top: 0,
        background: 'linear-gradient(140deg, #3b3b3bf2, #3b3b3b99)',
        '& > div': {
            padding: '4.375rem 2.5rem',
            textAlign: 'left',
            '& svg': {
                fill: 'white',
                width: '3.125rem',
                cursor: 'pointer',
                '&:hover': {
                    fill: '#d0d0d0',
                }
            }
        },
        zIndex: 999999
    },
    btnSubModuleContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '0.438476547rem',
        // justifyContent: 'center'
    },
    btnSubModule: {
        borderRadius: '1rem',
        width: '7.5rem',
        height: '7.5rem',
        backgroundColor: 'white',
        textAlign: 'center',
        margin: '1.375rem 2.75rem',
        fontSize: '0.75rem',
        fontWeight: 'bold',
        fontFamily: '"Open Sans", sans-serif',
        color: '#3B3B3B',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0rem 1rem',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#d0d0d0',
        }
    }
});

interface Menubar {
    authReducers: authInitialState
    dashboardReducers: dashboardInitialState
    dashboardSelectMenu: typeof dashboardSelectMenu
}

const Menubar = (props: Menubar) => {
    const classes = useStyles();
    const [icons, setIcons] = React.useState<{}>({});
    const [subIcons, setSubIcons] = React.useState<{}>({});
    const [submodule, setSubmodule] = React.useState([]);
    const [selectParents, setSelectParents] = React.useState([null]);
    const [selectParentsIndex, setSelectParentsIndex] = React.useState(0);
    const [showPanelMenu, setShowPanelMenu] = React.useState<Array<string|boolean>>([null, false]);
    const menu = props.authReducers.tables.menu || [];
    const { selectMenu } = props.dashboardReducers;

    const loadMenu = async () => {
        for (const iterator of menu) {
            const icon = await import(`penta-assets/images/${iterator.menuimag}`);
            icons[iterator.moducode] = icon.ReactComponent;
        }
        setIcons({...icons});
    }

    const loadSubMenu = async () => {
        for (const iterator of menu) {
            for (const subiterator of iterator.lst_grl_submodul) {
                try {
                    const subIcon = await import(`penta-assets/images/${subiterator.menuicon}`);
                    subIcons[subiterator.submcode] = subIcon.ReactComponent;
                } catch {

                }
            }
        }
        setSubIcons({...subIcons});
    }

    const renderIcon = (moducode: string) => {
        const IconComponent = icons[moducode]
        return IconComponent ? <IconComponent width='100%' height='1.875rem'/> : <QuestionMark height='1.875rem'/>
    }

    const renderSubIcon = (submcode: string) => {
        const IconComponent = subIcons[submcode]
        return IconComponent ? <IconComponent height='4.375rem'/> : <QuestionMark height='4.375rem'/>
    }

    const onNavigateMenu = (menuurls: string, submcode: string) => {
        if(menuurls !== '#') { 
            setShowPanelMenu([showPanelMenu[0], false]);
            const moducode = showPanelMenu[0] as string
            props.dashboardSelectMenu(moducode);
            localStorage.setItem('selectMenu', moducode);
            history.push(menuurls);
        }
        else {
            setSelectParents([...selectParents, submcode]);
            setSelectParentsIndex(selectParentsIndex + 1);
        }
    }

    const onShowPanelMenu = (moducode: string) => {
        const submodul = props.authReducers.tables.menu.find((x: any) => x.moducode === moducode);
        const tmp = showPanelMenu[0] !== moducode ? [moducode, true] : [moducode, !showPanelMenu[1]]
        setSubmodule(submodul.lst_grl_submodul);
        setShowPanelMenu(tmp);
        setSelectParents([null]);
        setSelectParentsIndex(0);
    }

    useEffect(() => {
        loadMenu();
        loadSubMenu();
    }, [])

    return (
        <div className={classes.container} onBlur={() => setShowPanelMenu([showPanelMenu[0], false])} tabIndex={0}>
            {
                menu.map((item: any) => (
                    <React.Fragment key={item.moducode}>
                        {
                            item.moducode === selectMenu ?
                                <div className={`${classes.iconDiv} ${classes.iconActive}`} onClick={() => onShowPanelMenu(item.moducode)}>
                                    { renderIcon(item.moducode) }
                                </div>
                            :
                                <Tooltip text={item.moduname}>
                                    <div 
                                        className={`${classes.iconDiv} ${showPanelMenu[1] && showPanelMenu[0] === item.moducode ? classes.iconSelect : ''}`} 
                                        onClick={() => onShowPanelMenu(item.moducode)}
                                    >
                                        { renderIcon(item.moducode) }
                                    </div> 
                                </Tooltip>
                        }
                    </React.Fragment>
                ))
            }
            {
                showPanelMenu[1] &&
                <div className={classes.submenuContainer}>
                    <div>
                        {
                            selectParentsIndex > 0 &&
                            <div onClick={() => { setSelectParentsIndex(selectParentsIndex - 1); setSelectParents(selectParents.slice(0, selectParents.length - 1)) }}>
                                <ArrowBack width='2.68rem'/>
                            </div>
                        }
                        <div className={classes.btnSubModuleContainer}>
                            {
                                submodule.filter((x) => x.subparen === selectParents[selectParentsIndex]).map((item: any) => 
                                    <div key={item.submcode} className={classes.btnSubModule} onClick={() => onNavigateMenu(item.menuurls, item.submcode)}>
                                        {renderSubIcon(item.submcode)}
                                        {item.submname}
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

const authReducers = 'authReducers';
const dashboardReducers = 'dashboardReducers';

const mapStateToProps = (state: any) => ({
    authReducers: state.get(authReducers),
    dashboardReducers: state.get(dashboardReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        dashboardSelectMenu
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(Menubar);
