import { 
    CATEGORYS_DELETE_REQUEST, 
    CATEGORYS_GET_ALL_RECORDS_REQUEST, 
    CATEGORYS_INIT_FORM_REQUEST, 
    CATEGORYS_INIT_UPDATE_FORM_REQUEST, 
    CATEGORYS_INSERT_REQUEST, 
    CATEGORYS_UPDATE_REQUEST, 
    FAMILIES_DELETE_REQUEST, 
    FAMILIES_GET_ALL_RECORDS_REQUEST, 
    FAMILIES_INIT_FORM_REQUEST,
    FAMILIES_INIT_UPDATE_FORM_REQUEST,
    FAMILIES_INSERT_REQUEST,
    FAMILIES_UPDATE_REQUEST,
    PRODUCTS_DELETE_REQUEST,
    PRODUCTS_GET_ALL_RECORDS_REQUEST,
    PRODUCTS_INIT_FORM_REQUEST,
    PRODUCTS_INIT_UPDATE_FORM_REQUEST,
    PRODUCTS_INSERT_REQUEST,
    PRODUCTS_UPDATE_REQUEST,
    UNITS_DELETE_REQUEST,
    UNITS_GET_ALL_RECORDS_REQUEST,
    UNITS_INIT_UPDATE_FORM_REQUEST,
    UNITS_INSERT_REQUEST,
    UNITS_UPDATE_REQUEST,
    WAREHOUSES_DELETE_REQUEST,
    WAREHOUSES_GET_ALL_RECORDS_REQUEST,
    WAREHOUSES_INIT_UPDATE_FORM_REQUEST,
    WAREHOUSES_INSERT_REQUEST,
    WAREHOUSES_UPDATE_REQUEST
} from "penta-redux/constants/wharehouseProductsConstants";

import {
    call, fork, put, take, takeEvery, all
} from 'redux-saga/effects';
import history from '../../utils/history';
import pipelines from 'services';
import {

} from "penta-redux/actions/accountantActions";
import { categoryEntity, familyEntity, productEntity, unitEntity, wharehouseEntity } from "models/wharehouseProductsModels";
import { 
    categorysDeleteRequestFailed,
    categorysGetAllRecordsRequestFailed,
    categorysGetAllRecordsRequestSuccess, 
    categorysInitFormRequestFailed, 
    categorysInitFormRequestSuccess, 
    categorysInitUpdateFormRequestFailed, 
    categorysInitUpdateFormRequestSuccess, 
    categorysInsertRequestFailed, 
    categorysInsertRequestSuccess, 
    categorysUpdateRequestFailed,
    familiesDeleteRequestFailed,
    familiesGetAllRecordsRequestFailed,
    familiesGetAllRecordsRequestSuccess,
    familiesInitFormRequestFailed,
    familiesInitFormRequestSuccess,
    familiesInitUpdateFormRequestFailed,
    familiesInitUpdateFormRequestSuccess,
    familiesInsertRequestFailed,
    familiesInsertRequestSuccess,
    familiesUpdateRequestFailed,
    productsDeleteRequestFailed,
    productsGetAllRecordsRequestFailed,
    productsGetAllRecordsRequestSuccess,
    productsInitFormRequestFailed,
    productsInitFormRequestSuccess,
    productsInitUpdateFormRequestFailed,
    productsInitUpdateFormRequestSuccess,
    productsInsertRequestFailed,
    productsInsertRequestSuccess,
    productsUpdateRequestFailed,
    unitsDeleteRequestFailed,
    unitsGetAllRecordsRequestFailed,
    unitsGetAllRecordsRequestSuccess,
    unitsInitUpdateFormRequestFailed,
    unitsInitUpdateFormRequestSuccess,
    unitsInsertRequestFailed,
    unitsInsertRequestSuccess,
    unitsUpdateRequestFailed,
    warehousesDeleteRequestFailed,
    warehousesGetAllRecordsRequestFailed,
    warehousesGetAllRecordsRequestSuccess,
    warehousesInitUpdateFormRequestFailed,
    warehousesInitUpdateFormRequestSuccess,
    warehousesInsertRequestFailed,
    warehousesInsertRequestSuccess,
    warehousesUpdateRequestFailed
} from "penta-redux/actions/wharehouseProductsActions";

//#region CATEGORY

function* categorysInitFormSaga(action: any) {
    try {
        const data = (yield call(pipelines.wharehouseProductsPipeline.categorysInitForm, action.fiscyear)).data;
        yield put(categorysInitFormRequestSuccess(data.data.tables));
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(categorysInitFormRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* categorysInitUpdateFormSaga(action: any) {
    try {
        const data = (yield call(pipelines.wharehouseProductsPipeline.categorysInitUpdateForm, action.ctgrcode, action.fiscyear)).data;
        yield put(categorysInitUpdateFormRequestSuccess(data.data.tables, data.data.onlyOne));
        yield call(action.callback, data.data.onlyOne);
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(categorysInitUpdateFormRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* categorysGetAllRecordsSaga(action: any) {
    try {
        const data = (yield call(pipelines.wharehouseProductsPipeline.categorysGetAllRecords, action.body)).data;
        yield put(categorysGetAllRecordsRequestSuccess(data.data.list, data.data.total));
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(categorysGetAllRecordsRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* categorysInsertSaga(action: any) {
    try {
        yield call(pipelines.wharehouseProductsPipeline.categorysInsert, action.body);
        if (action.callback !== null) {
            yield call(action.callback);
            yield put(categorysInsertRequestSuccess());
        }
        else
            yield history.push('/papp/products/categorias');
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(categorysInsertRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* categorysUpdateSaga(action: any) {
    try {
        const data = (yield call(pipelines.wharehouseProductsPipeline.categorysUpdate, (action.body as categoryEntity).ctgrcode, action.body)).data;
        yield history.push('/papp/products/categorias');
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(categorysUpdateRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* categorysDeleteSaga(action: any) {
    try {
        const data = (yield call(pipelines.wharehouseProductsPipeline.categorysDelete, action.ctgrcodes)).data;
        yield put({ type: CATEGORYS_GET_ALL_RECORDS_REQUEST, body: {} });
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(categorysDeleteRequestFailed(`Error: ${data.code}`, data.message));
    }
}

//#endregion

//#region FAMILIES

function* familiesInitFormSaga(action: any) {
    try {
        const data = (yield call(pipelines.wharehouseProductsPipeline.familiesInitForm)).data;
        yield put(familiesInitFormRequestSuccess(data.data.tables));
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(familiesInitFormRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* familiesInitUpdateFormSaga(action: any) {
    try {
        const data = (yield call(pipelines.wharehouseProductsPipeline.familiesInitUpdateForm, action.famicode)).data;
        yield put(familiesInitUpdateFormRequestSuccess(data.data.tables, data.data.onlyOne));
        yield call(action.callback, data.data.onlyOne);
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(familiesInitUpdateFormRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* familiesGetAllRecordsSaga(action: any) {
    try {
        const data = (yield call(pipelines.wharehouseProductsPipeline.familiesGetAllRecords, action.body)).data;
        yield put(familiesGetAllRecordsRequestSuccess(data.data.list, data.data.total));
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(familiesGetAllRecordsRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* familiesInsertSaga(action: any) {
    try {
        yield call(pipelines.wharehouseProductsPipeline.familiesInsert, action.body);
        if (action.callback !== null) {
            yield call(action.callback);
            yield put(familiesInsertRequestSuccess());
        }
        else
            yield history.push('/papp/products/familias');
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(familiesInsertRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* familiesUpdateSaga(action: any) {
    try {
        const data = (yield call(pipelines.wharehouseProductsPipeline.familiesUpdate, (action.body as familyEntity).famicode, action.body)).data;
        yield history.push('/papp/products/familias');
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(familiesUpdateRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* familiesDeleteSaga(action: any) {
    try {
        const data = (yield call(pipelines.wharehouseProductsPipeline.familiesDelete, action.famicodes)).data;
        yield put({ type: FAMILIES_GET_ALL_RECORDS_REQUEST, body: {} });
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(familiesDeleteRequestFailed(`Error: ${data.code}`, data.message));
    }
}

//#endregion

//#region UNITS

function* unitsInitUpdateFormSaga(action: any) {
    try {
        const data = (yield call(pipelines.wharehouseProductsPipeline.unitsInitUpdateForm, action.meascode)).data;
        yield put(unitsInitUpdateFormRequestSuccess(data.data.tables, data.data.onlyOne));
        yield call(action.callback, data.data.onlyOne);
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(unitsInitUpdateFormRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* unitsGetAllRecordsSaga(action: any) {
    try {
        const data = (yield call(pipelines.wharehouseProductsPipeline.unitsGetAllRecords, action.body)).data;
        yield put(unitsGetAllRecordsRequestSuccess(data.data.list, data.data.total));
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(unitsGetAllRecordsRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* unitsInsertSaga(action: any) {
    try {
        yield call(pipelines.wharehouseProductsPipeline.unitsInsert, action.body);
        if (action.callback !== null) {
            yield call(action.callback);
            yield put(unitsInsertRequestSuccess());
        }
        else
            yield history.push('/papp/products/unidades');
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(unitsInsertRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* unitsUpdateSaga(action: any) {
    try {
        const data = (yield call(pipelines.wharehouseProductsPipeline.unitsUpdate, (action.body as unitEntity).meascode, action.body)).data;
        yield history.push('/papp/products/unidades');
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(unitsUpdateRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* unitsDeleteSaga(action: any) {
    try {
        const data = (yield call(pipelines.wharehouseProductsPipeline.unitsDelete, action.meascodes)).data;
        yield put({ type: UNITS_GET_ALL_RECORDS_REQUEST, body: {} });
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(unitsDeleteRequestFailed(`Error: ${data.code}`, data.message));
    }
}

//#endregion

//#region WAREHOUSES

function* warehousesInitUpdateFormSaga(action: any) {
    try {
        const data = (yield call(pipelines.wharehouseProductsPipeline.warehousesInitUpdateForm, action.wharcode)).data;
        yield put(warehousesInitUpdateFormRequestSuccess(data.data.tables, data.data.onlyOne));
        yield call(action.callback, data.data.onlyOne);
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(warehousesInitUpdateFormRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* warehousesGetAllRecordsSaga(action: any) {
    try {
        const data = (yield call(pipelines.wharehouseProductsPipeline.warehousesGetAllRecords, action.body)).data;
        yield put(warehousesGetAllRecordsRequestSuccess(data.data.list, data.data.total));
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(warehousesGetAllRecordsRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* warehousesInsertSaga(action: any) {
    try {
        yield call(pipelines.wharehouseProductsPipeline.warehousesInsert, action.body);
        if (action.callback !== null) {
            yield call(action.callback);
            yield put(warehousesInsertRequestSuccess());
        }
        else
            yield history.push('/papp/products/almacenes');
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(warehousesInsertRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* warehousesUpdateSaga(action: any) {
    try {
        const data = (yield call(pipelines.wharehouseProductsPipeline.warehousesUpdate, (action.body as wharehouseEntity).wharcode, action.body)).data;
        yield history.push('/papp/products/almacenes');
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(warehousesUpdateRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* warehousesDeleteSaga(action: any) {
    try {
        const data = (yield call(pipelines.wharehouseProductsPipeline.warehousesDelete, action.wharcodes)).data;
        yield put({ type: WAREHOUSES_GET_ALL_RECORDS_REQUEST, body: {} });
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(warehousesDeleteRequestFailed(`Error: ${data.code}`, data.message));
    }
}

//#endregion

//#region PRODUCTS

function* productsInitFormSaga(action: any) {
    try {
        const data = (yield call(pipelines.wharehouseProductsPipeline.productsInitForm, action.fiscyear)).data;
        yield put(productsInitFormRequestSuccess(data.data.tables));
        yield call(action.callback, data.data.tables);
    } catch (error) {
        console.log(error);
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(productsInitFormRequestFailed(`Error: ${err.code || '0x0'}`, err.message || err));
    }
}

function* productsInitUpdateFormSaga(action: any) {
    try {
        const data = (yield call(pipelines.wharehouseProductsPipeline.productsInitUpdateForm, action.skucodee)).data;
        yield put(productsInitUpdateFormRequestSuccess(data.data.tables, data.data.onlyOne));
        yield call(action.callback, data.data.onlyOne);
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(productsInitUpdateFormRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* productsGetAllRecordsSaga(action: any) {
    try {
        const data = (yield call(pipelines.wharehouseProductsPipeline.productsGetAllRecords, action.body)).data;
        yield put(productsGetAllRecordsRequestSuccess(data.data.list, data.data.total));
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(productsGetAllRecordsRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* productsInsertSaga(action: any) {
    try {
        yield call(pipelines.wharehouseProductsPipeline.productsInsert, action.body);
        if (action.callback !== null) {
            yield call(action.callback);
            yield put(productsInsertRequestSuccess());
        }
        else
            yield history.push('/papp/products/productos');
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(productsInsertRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* productsUpdateSaga(action: any) {
    try {
        const data = (yield call(pipelines.wharehouseProductsPipeline.productsUpdate, (action.body as productEntity).skucodee, action.body)).data;
        yield history.push('/papp/products/productos');
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(productsUpdateRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* productsDeleteSaga(action: any) {
    try {
        const data = (yield call(pipelines.wharehouseProductsPipeline.productsDelete, action.skucodees)).data;
        yield put({ type: PRODUCTS_GET_ALL_RECORDS_REQUEST, body: {} });
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(productsDeleteRequestFailed(`Error: ${data.code}`, data.message));
    }
}

//#endregion

function* wharehouseProductsRootSaga() {
    yield all([
        takeEvery(CATEGORYS_INIT_FORM_REQUEST, categorysInitFormSaga),
        takeEvery(CATEGORYS_INIT_UPDATE_FORM_REQUEST, categorysInitUpdateFormSaga),
        takeEvery(CATEGORYS_GET_ALL_RECORDS_REQUEST, categorysGetAllRecordsSaga),
        takeEvery(CATEGORYS_INSERT_REQUEST, categorysInsertSaga),
        takeEvery(CATEGORYS_UPDATE_REQUEST, categorysUpdateSaga),
        takeEvery(CATEGORYS_DELETE_REQUEST, categorysDeleteSaga),
        takeEvery(FAMILIES_INIT_FORM_REQUEST, familiesInitFormSaga),
        takeEvery(FAMILIES_INIT_UPDATE_FORM_REQUEST, familiesInitUpdateFormSaga),
        takeEvery(FAMILIES_GET_ALL_RECORDS_REQUEST, familiesGetAllRecordsSaga),
        takeEvery(FAMILIES_INSERT_REQUEST, familiesInsertSaga),
        takeEvery(FAMILIES_UPDATE_REQUEST, familiesUpdateSaga),
        takeEvery(FAMILIES_DELETE_REQUEST, familiesDeleteSaga),
        takeEvery(UNITS_INIT_UPDATE_FORM_REQUEST, unitsInitUpdateFormSaga),
        takeEvery(UNITS_GET_ALL_RECORDS_REQUEST, unitsGetAllRecordsSaga),
        takeEvery(UNITS_INSERT_REQUEST, unitsInsertSaga),
        takeEvery(UNITS_UPDATE_REQUEST, unitsUpdateSaga),
        takeEvery(UNITS_DELETE_REQUEST, unitsDeleteSaga),
        takeEvery(WAREHOUSES_INIT_UPDATE_FORM_REQUEST, warehousesInitUpdateFormSaga),
        takeEvery(WAREHOUSES_GET_ALL_RECORDS_REQUEST, warehousesGetAllRecordsSaga),
        takeEvery(WAREHOUSES_INSERT_REQUEST, warehousesInsertSaga),
        takeEvery(WAREHOUSES_UPDATE_REQUEST, warehousesUpdateSaga),
        takeEvery(WAREHOUSES_DELETE_REQUEST, warehousesDeleteSaga),
        takeEvery(PRODUCTS_INIT_FORM_REQUEST, productsInitFormSaga),
        takeEvery(PRODUCTS_INIT_UPDATE_FORM_REQUEST, productsInitUpdateFormSaga),
        takeEvery(PRODUCTS_GET_ALL_RECORDS_REQUEST, productsGetAllRecordsSaga),
        takeEvery(PRODUCTS_INSERT_REQUEST, productsInsertSaga),
        takeEvery(PRODUCTS_UPDATE_REQUEST, productsUpdateSaga),
        takeEvery(PRODUCTS_DELETE_REQUEST, productsDeleteSaga)
    ]);
}
  
const wharehouseProductsSagas = [
    fork(wharehouseProductsRootSaga),
];
  
export default wharehouseProductsSagas;