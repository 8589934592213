import React from 'react';
import { Backdrop, Button, ButtonGroup, Checkbox, CircularProgress, createStyles, FormControl, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Select, Snackbar, TextField, Theme, Tooltip, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { unitsInitUpdateFormRequest, unitsInsertRequest, unitsUpdateRequest } from 'penta-redux/actions/wharehouseProductsActions';
import history from '../../../utils/history';
import './units.scss'
import FormFooter from 'penta-components/base/formFooter';
import { MessageBox } from 'penta-components/base/messageBox';
import { unitEntity } from 'models/wharehouseProductsModels';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Alert } from '@material-ui/lab';
import ScreenLoader from 'penta-components/base/screenLoader';

const useStyles = (theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        formControl: {
            margin: 0,
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    });

const defaultunitsEntity: unitEntity = {
    meascode: '',
    measname: ''
}

interface FormFamiliesState {
    unitsEntity: unitEntity
    title: string
    snackbar: boolean
}

class FormFamilies extends React.Component<any, FormFamiliesState> {

    constructor(props: any) {
        super(props);
        this.state = {
            unitsEntity: defaultunitsEntity,
            title: 'Agregar Unidades',
            snackbar: false
        }
    }

    onChangeRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            ...value
        }))
    }

    onChangeunitsRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            unitsEntity: {
                ...state.unitsEntity,
                ...value
            }
        }))
    }

    onCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        console.log('cancel')
        history.push('/papp/products/unidades');
    }

    onContinueCallback = () => {
        const newUnitsEntity = defaultunitsEntity;
        this.setState({
            unitsEntity: newUnitsEntity,
            snackbar: true
        });
    }

    onSubmitContinue = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const { params } = this.props.match;
        if (params.meascode !== undefined) {
            this.props.unitsUpdateRequest(this.state.unitsEntity);
        } else {
            this.props.unitsInsertRequest(this.state.unitsEntity, this.onContinueCallback);
        }
    }

    onSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const { params } = this.props.match;
        if (params.meascode !== undefined) {
            this.props.unitsUpdateRequest(this.state.unitsEntity);
        } else {
            this.props.unitsInsertRequest(this.state.unitsEntity, null);
        }
    }

    callbackInitUpdate = (unitsEntity: unitEntity) => {
        this.setState({
            unitsEntity
        });
    }

    componentDidMount() {
        const { params } = this.props.match;
        if (params.meascode !== undefined) {
            this.setState({title: 'Editar unidades'});
            this.props.unitsInitUpdateFormRequest(params.meascode, this.callbackInitUpdate);
        }
    }

    render() {

        const { unitsEntity, title, snackbar } = this.state;
        const { loading, list, tables, failed, failedTitle, failedContent } = this.props.wharehouseProductsReducers;
        const { classes } = this.props;

        return (
            <div style={{overflow: 'auto', height: '100%'}}>
                <Paper className='form__container'>
                    <br/>
                    <Typography variant='h5'>{ title }</Typography>
                    <br/>
                    { failed && <MessageBox title={failedTitle} content={failedContent}/> }
                    <form noValidate autoComplete="off">
                        <Grid container spacing={2} >
                            <Grid item xs={4}>
                                <TextField fullWidth id="meascode" label="Codigo" variant="outlined" value={ unitsEntity.meascode } onChange={(e: any) => this.onChangeunitsRoot({'meascode': e.target.value})}/>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth id="measname" label="Nombre" variant="outlined" value={ unitsEntity.measname } onChange={(e: any) => this.onChangeunitsRoot({'measname': e.target.value})}/>
                            </Grid>
                        </Grid>
                    </form>
                    <br/>
                    <FormFooter 
                        onCancel={ this.onCancel } 
                        onSubmitContinue={ this.onSubmitContinue } 
                        onSubmit={ this.onSubmit }/>
                </Paper>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={snackbar}
                    onClose={() => this.setState({snackbar: false})}
                    autoHideDuration={6000}
                >
                    <Alert onClose={() => this.setState({snackbar: false})} severity="success">
                            Datos Guardados Correctamente
                    </Alert>
                </Snackbar>
                <ScreenLoader enabled={loading}/>
            </div>
        );
    }
}

const wharehouseProductsReducers = 'wharehouseProductsReducers';

const mapStateToProps = (state: any) => ({
    wharehouseProductsReducers: state.get(wharehouseProductsReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        unitsInitUpdateFormRequest,
        unitsInsertRequest,
        unitsUpdateRequest
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(FormFamilies));