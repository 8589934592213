import * as types from 'penta-redux/constants/financeConstants';
import { baseModels } from 'penta-models';

//#region CASH BANKS

export const cashBanksInitFormRequest = (fiscyear: number, callback: void) => ({
    type: types.CASH_BANKS_INIT_FORM_REQUEST,
    fiscyear,
    callback
});

export const cashBanksInitFormRequestSuccess = (tables: any) => ({
    type: types.CASH_BANKS_INIT_FORM_REQUEST_SUCCESS,
    tables
});

export const cashBanksInitFormRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.CASH_BANKS_INIT_FORM_REQUEST_FAILED,
    titleError,
    titleContent
});

export const cashBanksInitUpdateFormRequest = (idcsbank: number, callback: void) => ({
    type: types.CASH_BANKS_INIT_UPDATE_FORM_REQUEST,
    idcsbank,
    callback
});

export const cashBanksInitUpdateFormRequestSuccess = (tables: any, onlyOne: any) => ({
    type: types.CASH_BANKS_INIT_UPDATE_FORM_REQUEST_SUCCESS,
    tables,
    onlyOne
});

export const cashBanksInitUpdateFormRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.CASH_BANKS_INIT_UPDATE_FORM_REQUEST_FAILED,
    titleError,
    titleContent
});

export const cashBanksGetAllRecordsRequest = (body: baseModels.pentaRequestQuery) => ({
    type: types.CASH_BANKS_GET_ALL_RECORDS_REQUEST,
    body
});

export const cashBanksGetAllRecordsRequestSuccess = (list: Array<any>, total: number) => ({
    type: types.CASH_BANKS_GET_ALL_RECORDS_REQUEST_SUCCESS,
    list,
    total
});

export const cashBanksGetAllRecordsRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.CASH_BANKS_GET_ALL_RECORDS_REQUEST_FAILED,
    titleError,
    titleContent
});

export const cashBanksInsertRequest = (body: any, callback?: void) => ({
    type: types.CASH_BANKS_INSERT_REQUEST,
    body,
    callback
});

export const cashBanksInsertRequestSuccess = () => ({
    type: types.CASH_BANKS_INSERT_REQUEST_SUCCESS
});

export const cashBanksInsertRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.CASH_BANKS_INSERT_REQUEST_FAILED,
    titleError,
    titleContent
});

export const cashBanksUpdateRequest = (body: any, callback?: void) => ({
    type: types.CASH_BANKS_UPDATE_REQUEST,
    body,
    callback
});

export const cashBanksUpdateRequestSuccess = () => ({
    type: types.CASH_BANKS_UPDATE_REQUEST_SUCCESS
});

export const cashBanksUpdateRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.CASH_BANKS_UPDATE_REQUEST_FAILED,
    titleError,
    titleContent
});

export const cashBanksDeleteRequest = (idcsbanks: Array<number>) => ({
    type: types.CASH_BANKS_DELETE_REQUEST,
    idcsbanks
});

export const cashBanksDeleteRequestSuccess = () => ({
    type: types.CASH_BANKS_DELETE_REQUEST_SUCCESS
});

export const cashBanksDeleteRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.CASH_BANKS_DELETE_REQUEST_FAILED,
    titleError,
    titleContent
});

/*Components */
export const cashBanksListSelectItem = (key: number) => ({
    type: types.CASH_BANKS_LIST_SELECT_ITEM,
    key
});

export const cashBanksListSelectAllItem = (checked: boolean) => ({
    type: types.CASH_BANKS_LIST_SELECT_ALL_ITEM,
    checked
});
export const cashBanksActionFailed = (titleError: string, titleContent: string) => ({
    type: types.CASH_BANKS_ACTION_FAILED,
    titleError,
    titleContent
});

//#endregion

//#region CASH BANKS MOVEMENTS

export const cashBanksMovementsInitFormRequest = (fiscyear: number, callback: void) => ({
    type: types.CASH_BANKS_MOVEMENTS_INIT_FORM_REQUEST,
    fiscyear,
    callback
});

export const cashBanksMovementsInitFormRequestSuccess = (tables: any) => ({
    type: types.CASH_BANKS_MOVEMENTS_INIT_FORM_REQUEST_SUCCESS,
    tables
});

export const cashBanksMovementsInitFormRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.CASH_BANKS_MOVEMENTS_INIT_FORM_REQUEST_FAILED,
    titleError,
    titleContent
});

export const cashBanksMovementsInitUpdateFormRequest = (yearexer: string, montexer: string, weekexer: string, pagenumb: number, idcsbank: number, callback: void) => ({
    type: types.CASH_BANKS_MOVEMENTS_INIT_UPDATE_FORM_REQUEST,
    yearexer,
    montexer,
    weekexer,
    pagenumb,
    idcsbank,
    callback
});

export const cashBanksMovementsInitUpdateFormRequestSuccess = (tables: any, onlyOne: any) => ({
    type: types.CASH_BANKS_MOVEMENTS_INIT_UPDATE_FORM_REQUEST_SUCCESS,
    tables,
    onlyOne
});

export const cashBanksMovementsInitUpdateFormRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.CASH_BANKS_MOVEMENTS_INIT_UPDATE_FORM_REQUEST_FAILED,
    titleError,
    titleContent
});

export const cashBanksMovementsGetAllRecordsRequest = (body: baseModels.pentaRequestQuery) => ({
    type: types.CASH_BANKS_MOVEMENTS_GET_ALL_RECORDS_REQUEST,
    body
});

export const cashBanksMovementsGetAllRecordsRequestSuccess = (list: Array<any>, total: number) => ({
    type: types.CASH_BANKS_MOVEMENTS_GET_ALL_RECORDS_REQUEST_SUCCESS,
    list,
    total
});

export const cashBanksMovementsGetAllRecordsRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.CASH_BANKS_MOVEMENTS_GET_ALL_RECORDS_REQUEST_FAILED,
    titleError,
    titleContent
});

export const cashBanksMovementsInsertRequest = (body: any, callback?: void) => ({
    type: types.CASH_BANKS_MOVEMENTS_INSERT_REQUEST,
    body,
    callback
});

export const cashBanksMovementsInsertRequestSuccess = () => ({
    type: types.CASH_BANKS_MOVEMENTS_INSERT_REQUEST_SUCCESS
});

export const cashBanksMovementsInsertRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.CASH_BANKS_MOVEMENTS_INSERT_REQUEST_FAILED,
    titleError,
    titleContent
});

export const cashBanksMovementsUpdateRequest = (body: any, callback?: void) => ({
    type: types.CASH_BANKS_MOVEMENTS_UPDATE_REQUEST,
    body,
    callback
});

export const cashBanksMovementsUpdateRequestSuccess = () => ({
    type: types.CASH_BANKS_MOVEMENTS_UPDATE_REQUEST_SUCCESS
});

export const cashBanksMovementsUpdateRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.CASH_BANKS_MOVEMENTS_UPDATE_REQUEST_FAILED,
    titleError,
    titleContent
});

export const cashBanksMovementsDeleteRequest = (yearexers: Array<string>, montexers: Array<string>, weekexers: Array<string>, pagenumbs: Array<number>, idcsbanks: Array<number>) => ({
    type: types.CASH_BANKS_MOVEMENTS_DELETE_REQUEST,
    yearexers,
    montexers,
    weekexers,
    pagenumbs,
    idcsbanks
});

export const cashBanksMovementsDeleteRequestSuccess = () => ({
    type: types.CASH_BANKS_MOVEMENTS_DELETE_REQUEST_SUCCESS
});

export const cashBanksMovementsDeleteRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.CASH_BANKS_MOVEMENTS_DELETE_REQUEST_FAILED,
    titleError,
    titleContent
});

export const cashBanksMovementsMassiveUpdateRequest = (body: any) => ({
    type: types.CASH_BANKS_MOVEMENTS_MASSIVE_UPDATE_REQUEST,
    body
});

export const cashBanksMovementsMassiveUpdateRequestSuccess = () => ({
    type: types.CASH_BANKS_MOVEMENTS_MASSIVE_UPDATE_REQUEST_SUCCESS
});

export const cashBanksMovementsMassiveUpdateRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.CASH_BANKS_MOVEMENTS_MASSIVE_UPDATE_REQUEST_FAILED,
    titleError,
    titleContent
});

/*Components */
export const cashBanksMovementsListSelectItem = (key: number) => ({
    type: types.CASH_BANKS_MOVEMENTS_LIST_SELECT_ITEM,
    key
});

export const cashBanksMovementsListSelectAllItem = (checked: boolean) => ({
    type: types.CASH_BANKS_MOVEMENTS_LIST_SELECT_ALL_ITEM,
    checked
});
export const cashBanksMovementsActionFailed = (titleError: string, titleContent: string) => ({
    type: types.CASH_BANKS_MOVEMENTS_ACTION_FAILED,
    titleError,
    titleContent
});

//#endregion

//#region CHECKS

export const checksInitFormRequest = (fiscyear: number, callback: void) => ({
    type: types.CHECKS_INIT_FORM_REQUEST,
    fiscyear,
    callback
});

export const checksInitFormRequestSuccess = (tables: any) => ({
    type: types.CHECKS_INIT_FORM_REQUEST_SUCCESS,
    tables
});

export const checksInitFormRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.CHECKS_INIT_FORM_REQUEST_FAILED,
    titleError,
    titleContent
});

export const checksInitUpdateFormRequest = (correlat: number, callback: void) => ({
    type: types.CHECKS_INIT_UPDATE_FORM_REQUEST,
    correlat,
    callback
});

export const checksInitUpdateFormRequestSuccess = (tables: any, onlyOne: any) => ({
    type: types.CHECKS_INIT_UPDATE_FORM_REQUEST_SUCCESS,
    tables,
    onlyOne
});

export const checksInitUpdateFormRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.CHECKS_INIT_UPDATE_FORM_REQUEST_FAILED,
    titleError,
    titleContent
});

export const checksGetAllRecordsRequest = (body: baseModels.pentaRequestQuery) => ({
    type: types.CHECKS_GET_ALL_RECORDS_REQUEST,
    body
});

export const checksGetAllRecordsRequestSuccess = (list: Array<any>, total: number) => ({
    type: types.CHECKS_GET_ALL_RECORDS_REQUEST_SUCCESS,
    list,
    total
});

export const checksGetAllRecordsRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.CHECKS_GET_ALL_RECORDS_REQUEST_FAILED,
    titleError,
    titleContent
});

export const checksInsertRequest = (body: any, callback?: void) => ({
    type: types.CHECKS_INSERT_REQUEST,
    body,
    callback
});

export const checksInsertRequestSuccess = () => ({
    type: types.CHECKS_INSERT_REQUEST_SUCCESS
});

export const checksInsertRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.CHECKS_INSERT_REQUEST_FAILED,
    titleError,
    titleContent
});

export const checksUpdateRequest = (body: any) => ({
    type: types.CHECKS_UPDATE_REQUEST,
    body
});

export const checksUpdateRequestSuccess = () => ({
    type: types.CHECKS_UPDATE_REQUEST_SUCCESS
});

export const checksUpdateRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.CHECKS_UPDATE_REQUEST_FAILED,
    titleError,
    titleContent
});

export const checksDeleteRequest = (correlats: Array<string>) => ({
    type: types.CHECKS_DELETE_REQUEST,
    correlats
});

export const checksDeleteRequestSuccess = () => ({
    type: types.CHECKS_DELETE_REQUEST_SUCCESS
});

export const checksDeleteRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.CHECKS_DELETE_REQUEST_FAILED,
    titleError,
    titleContent
});

/*Components */
export const checksListSelectItem = (key: number) => ({
    type: types.CHECKS_LIST_SELECT_ITEM,
    key
});

export const checksListSelectAllItem = (checked: boolean) => ({
    type: types.CHECKS_LIST_SELECT_ALL_ITEM,
    checked
});
export const checksActionFailed = (titleError: string, titleContent: string) => ({
    type: types.CHECKS_ACTION_FAILED,
    titleError,
    titleContent
});

//#endregion

//#region REPORTS

export const checksBalanceRequest = (fiscyear: string, month: string, currency: string, callback: void) => ({
    type: types.CHECKS_BALANCE_REQUEST,
    fiscyear,
    month,
    currency,
    callback
});

export const checksBalanceRequestSuccess = (onlyOne: any) => ({
    type: types.CHECKS_BALANCE_REQUEST_SUCCESS,
    onlyOne
});

export const checksBalanceRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.CHECKS_BALANCE_REQUEST_FAILED,
    titleError,
    titleContent
});

export const checksIssuedSummaryRequest = (fiscyear: string, month: string, initdate: string, endidate: string, callback: void) => ({
    type: types.CHECKS_ISSUED_SUMMARY_REQUEST,
    fiscyear,
    month,
    initdate,
    endidate,
    callback
});

export const checksIssuedSummaryRequestSuccess = (onlyOne: any) => ({
    type: types.CHECKS_ISSUED_SUMMARY_REQUEST_SUCCESS,
    onlyOne
});

export const checksIssuedSummaryRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.CHECKS_ISSUED_SUMMARY_REQUEST_FAILED,
    titleError,
    titleContent
});

//#endregion