const serverUrl = process.env.PENTA_MASTER_SERVER_URL || `https://api.${window.location.hostname}/api/`;

export const CATEGORYS_INIT_FORM = serverUrl + 'wharehouseproducts/categorys/init-form/';
export const CATEGORYS_INIT_UPDATE_FORM = serverUrl + 'wharehouseproducts/categorys/init-update-form/';
export const CATEGORYS_GET_ALL_RECORDS = serverUrl + 'wharehouseproducts/categorys/get-all-records';
export const CATEGORYS_INSERT = serverUrl + 'wharehouseproducts/categorys/insert';
export const CATEGORYS_UPDATE = serverUrl + 'wharehouseproducts/categorys/update/';
export const CATEGORYS_DELETE = serverUrl + 'wharehouseproducts/categorys/delete?';

export const FAMILIES_INIT_FORM = serverUrl + 'wharehouseproducts/families/init-form';
export const FAMILIES_INIT_UPDATE_FORM = serverUrl + 'wharehouseproducts/families/init-update-form/';
export const FAMILIES_GET_ALL_RECORDS = serverUrl + 'wharehouseproducts/families/get-all-records';
export const FAMILIES_INSERT = serverUrl + 'wharehouseproducts/families/insert';
export const FAMILIES_UPDATE = serverUrl + 'wharehouseproducts/families/update/';
export const FAMILIES_DELETE = serverUrl + 'wharehouseproducts/families/delete?';

export const UNITS_INIT_UPDATE_FORM = serverUrl + 'wharehouseproducts/units/init-update-form/';
export const UNITS_GET_ALL_RECORDS = serverUrl + 'wharehouseproducts/units/get-all-records';
export const UNITS_INSERT = serverUrl + 'wharehouseproducts/units/insert';
export const UNITS_UPDATE = serverUrl + 'wharehouseproducts/units/update/';
export const UNITS_DELETE = serverUrl + 'wharehouseproducts/units/delete?';

export const WAREHOUSES_INIT_UPDATE_FORM = serverUrl + 'wharehouseproducts/warehouses/init-update-form/';
export const WAREHOUSES_GET_ALL_RECORDS = serverUrl + 'wharehouseproducts/warehouses/get-all-records';
export const WAREHOUSES_INSERT = serverUrl + 'wharehouseproducts/warehouses/insert';
export const WAREHOUSES_UPDATE = serverUrl + 'wharehouseproducts/warehouses/update/';
export const WAREHOUSES_DELETE = serverUrl + 'wharehouseproducts/warehouses/delete?';

export const PRODUCTS_INIT_FORM = serverUrl + 'wharehouseproducts/products/init-form/';
export const PRODUCTS_INIT_UPDATE_FORM = serverUrl + 'wharehouseproducts/products/init-update-form/';
export const PRODUCTS_GET_ALL_RECORDS = serverUrl + 'wharehouseproducts/products/get-all-records';
export const PRODUCTS_INSERT = serverUrl + 'wharehouseproducts/products/insert';
export const PRODUCTS_UPDATE = serverUrl + 'wharehouseproducts/products/update/';
export const PRODUCTS_DELETE = serverUrl + 'wharehouseproducts/products/delete?';