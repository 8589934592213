import React from 'react';
import { Backdrop, Button, ButtonGroup, Checkbox, CircularProgress, createStyles, FormControl, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Select, TextField, Theme, Tooltip, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import history from '../../../../utils/history';
import './../reports.scss'
import { MessageBox } from 'penta-components/base/messageBox';
import PdfView from 'penta-components/base/pdfView';
import moment from 'moment';
import * as XLSX from 'sheetjs-style';
import ScreenLoader from 'penta-components/base/screenLoader';
import { checksIssuedSummaryRequest } from 'penta-redux/actions/financeActions';

let pdfMake = require("pdfmake/build/pdfmake");
let saveAs = require('file-saver').saveAs;

const meses = [
    { key: '01', value: 'Enero' },
    { key: '02', value: 'Febrero' },
    { key: '03', value: 'Marzo' },
    { key: '04', value: 'Abril' },
    { key: '05', value: 'Mayo' },
    { key: '06', value: 'Junio' },
    { key: '07', value: 'Julio' },
    { key: '08', value: 'Agosto' },
    { key: '09', value: 'Septiembre' },
    { key: '10', value: 'Octubre' },
    { key: '11', value: 'Noviembre' },
    { key: '12', value: 'Diciembre' }
]
const currentFiscyear = localStorage.getItem('currentFiscyear');

const useStyles = (theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        formControl: {
            margin: 0,
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    });

interface FormcategorysState {
    title: string,
    year: string,
    month: string,
    initdate: string
    endidate: string
    base64Pdf: string
}

class ReportChecksIssuedSummary extends React.Component<any, FormcategorysState> {

    constructor(props: any) {
        super(props);
        this.state = {
            title: 'Reporte de Resumen de cheques emitidos',
            year: currentFiscyear,
            month: moment().format('MM').toString(),
            initdate: moment().set({'year': +currentFiscyear}).format('YYYY-MM-DD'),
            endidate: moment().set({'year': +currentFiscyear}).format('YYYY-MM-DD'),
            base64Pdf: ''
        }
    }

    onChangeRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            ...value
        }))
    }

    onGeneratePdf = () => {
        const data = this.props.financeReducers.onlyOne;
        const { month, year, initdate, endidate } = this.state;
        const { dashboardReducers } = this.props;
        const mes = meses.find((x: any) => x.key === month).value;
        const company = (JSON.parse(localStorage.getItem('tables')).companys.find((x: any) => x.taxident === dashboardReducers.currentCompany).bussname as string).toUpperCase()

        const pdfObject: any = {
            pageOrientation: 'portrait',
            pageMargins: [ 20, 90, 20, 20 ],
            pageSize: 'A4',
            header: [
                {
                    text: ' ', lineHeight: 2
                },
                {
                    margin: [20, 0, 20, 0],
                    text: company,
                    style: {
                        bold: true,
                        fontSize: 9
                    }
                },
                {
                    text: 'RESUMEN CHEQUES EMITIDOS',
                    style: {
                        alignment: 'center',
                        bold: true,
                        fontSize: 9
                    }
                },
                {
                    margin: [20, 10, 0, 0],
                    text: `EJERCICIO: ${mes}-${year} SEMANA DEL DIA ${initdate} al ${endidate}`,
                    style: {
                        bold: true,
                        fontSize: 9
                    }
                }
            ],
            content: [
                {
                    table: {
                        headerRows: 1,
                        widths: [ 50, '*', 50, 50, 50, 50 ],
                        body: []
                    }
                },
            ],
            styles: {
                headerTitle: {
                    bold: true,
                    fontSize: 6,
                    lineHeight: 1.2
                },
                table: {
                    layout: 'headerLineOnly',
                    fontSize: 6,
                    alignment: 'center'
                },
                tableDetailLeft: {
                    layout: 'headerLineOnly',
                    fontSize: 5.5,
                    alignment: 'left'
                },
                tableDetailRight: {
                    layout: 'headerLineOnly',
                    fontSize: 5.5,
                    alignment: 'right'
                },
                tableDetailRightBold: {
                    layout: 'headerLineOnly',
                    fontSize: 5.5,
                    alignment: 'right',
                    bold: true
                }
            },
            defaultStyle: {
                columnGap: 20,
            },
        }

        const tableBody: any = [
            [ 
                { text: 'FECHA', style: 'table', border: [true, true, false, true] },
                { text: 'GLOSA', style: 'table', border: [true, true, false, true] },
                { text: 'NUMERO\nCHEQUE', style: 'table', border: [true, true, false, true] },
                { text: 'IMPORTE DOLARES', style: 'table', border: [true, true, true, true] },
                { text: 'DEBE', style: 'table', border: [true, true, true, true] },
                { text: 'HABER', style: 'table', border: [true, true, true, true] },
            ]
        ]

        let debitamo = 0.00;
        let assetamo = 0.00;

        for (const row of data) {

            const balances = JSON.parse(row.balances);
            const dolares = row.currency === '00000014' ? '0.00' : +balances[row.currency].assetamo.replace(/,/g, '') + +balances[row.currency].debitamo.replace(/,/g, '');

            debitamo += +balances['00000014'].debitamo.replace(/,/g, '');
            assetamo += +balances['00000014'].assetamo.replace(/,/g, '');

            tableBody.push(
                [ 
                    { text: moment(row.dateoper).format('YYYY-MM-DD'), border: [false, false, false, false], style: 'tableDetailLeft' },
                    { text: row.glosarys.substr(0, 150), border: [false, false, false, false], style: 'tableDetailLeft' },
                    { text: row.checknum, border: [false, false, false, false], style: 'tableDetailRight' },
                    { text: dolares !== '0.00' ? String.formatNumber.new(dolares) : '', border: [false, false, false, false], style: 'tableDetailRight' },
                    { text: String.formatNumber.new(balances['00000014'].debitamo.replace(/,/g, '')), border: [false, false, false, false], style: 'tableDetailRight' },
                    { text: String.formatNumber.new(balances['00000014'].assetamo.replace(/,/g, '')), border: [false, false, false, false], style: 'tableDetailRight' }
                ]
            );
        }

        tableBody.push(
            [ 
                { text: '', border: [false, false, false, false], style: 'tableDetailLeft' },
                { text: '', border: [false, false, false, false], style: 'tableDetailLeft' },
                { text: '', border: [false, false, false, false], style: 'tableDetailRight' },
                { text: '', border: [false, false, false, false], style: 'tableDetailRight' },
                { text: ' ', border: [false, false, false, false], style: 'tableDetailRight' },
                { text: ' ', border: [false, false, false, false], style: 'tableDetailRight' }
            ],
            [ 
                { text: '', border: [false, false, false, false], style: 'tableDetailLeft' },
                { text: '', border: [false, false, false, false], style: 'tableDetailLeft' },
                { text: '', border: [false, false, false, false], style: 'tableDetailRight' },
                { text: '', border: [false, false, false, false], style: 'tableDetailRight' },
                { text: String.formatNumber.new(debitamo), border: [false, false, false, false], style: 'tableDetailRightBold' },
                { text: String.formatNumber.new(assetamo), border: [false, false, false, false], style: 'tableDetailRightBold' }
            ]
        )

        pdfObject.content[0].table.body = tableBody;

        pdfMake.fonts = {
            Roboto: {
                normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
                bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
                italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
                bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
              },
        }
        const pdfDocGenerator = pdfMake.createPdf(pdfObject);
        pdfDocGenerator.getDataUrl((data: any) => {
            // console.log(data);
            this.setState({
                base64Pdf: data
            })
        });

    }

    onGenerateExcel = () => {
        try {
            const data = this.props.financeReducers.onlyOne;
            const { month, year, initdate, endidate } = this.state;
            const { dashboardReducers } = this.props;
            const mes = meses.find((x: any) => x.key === month).value;
            const company = (JSON.parse(localStorage.getItem('tables')).companys.find((x: any) => x.taxident === dashboardReducers.currentCompany).bussname as string).toUpperCase()

            const tableBody: any = [
                {
                    A: company,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null
                },
                {
                    A: 'RESUMEN CHEQUES EMITIDOS',
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null
                },
                {
                    A: `EJERCICIO: ${mes}-${year} SEMANA DEL DIA ${initdate} al ${endidate}`,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null
                },
                {
                    A: null,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null
                },
                { 
                    A: 'FECHA',
                    B: 'GLOSA',
                    C: 'NUMERO\nCHEQUE',
                    D: 'IMPORTE DOLARES',
                    E: 'DEBE',
                    F: 'HABER'
                }
            ]
 
            let debitamo = 0.00;
            let assetamo = 0.00;

            for (const row of data) {

                const balances = JSON.parse(row.balances);
                const dolares = row.currency === '00000014' ? '0.00' : +balances[row.currency].assetamo.replace(/,/g, '') + +balances[row.currency].debitamo.replace(/,/g, '');
    
                debitamo += +balances['00000014'].debitamo.replace(/,/g, '');
                assetamo += +balances['00000014'].assetamo.replace(/,/g, '');

                tableBody.push(
                    { 
                        A: moment(row.dateoper).format('YYYY-MM-DD'),
                        B: row.glosarys.substr(0, 150),
                        C: row.checknum,
                        D: dolares !== '0.00' ? String.formatNumber.new(dolares) : '',
                        E: String.formatNumber.new(balances['00000014'].debitamo.replace(/,/g, '')),
                        F: String.formatNumber.new(balances['00000014'].assetamo.replace(/,/g, ''))
                    }
                );
            }

            tableBody.push(
                { 
                    A: '',
                    B: '',
                    C: '',
                    D: '',
                    E: '',
                    F: ''
                },
                { 
                    A: '',
                    B: '',
                    C: '',
                    D: '',
                    E: String.formatNumber.new(debitamo),
                    F: String.formatNumber.new(assetamo)
                }
            );

            const header = [
                'A',
                'B',
                'C',
                'D',
                'E',
                'F'
            ];

            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(tableBody, {header, skipHeader: true});
            ws["!cols"] = [
                {wpx:80},{wpx:200},{wpx:80},{wpx:80},{wpx:80},{wpx:80}
            ];
            ws['!rows'] = [
                {hpx: 16},{hpx: 18},{hpx: 16},{hpx: 18}
            ];

            const keys = Object.keys(ws);
            const rowsCount = tableBody.length;

            for (const iterator of keys) {
                if(!isNaN(parseInt(ws[iterator].v)) && !iterator.match(/^(A|B|C)[0-9]+$/g)) {
                    ws[iterator].v = ws[iterator].v.toString().replace(/,/g, '');
                    ws[iterator].t = 'n';
                    ws[iterator].z = '#,##0.00';
                }
                if(iterator !== '!ref' && iterator !== '!cols' && iterator !== '!rows') {
                    ws[iterator].s = {
                        font: {
                            name: 'Tahoma',
                            sz: 8
                        }
                    }
                }
            }

            for (let index = 5; index <= rowsCount; index++) {
                ws['!rows'].push({hpx: 12})
            }

            ws['A1'].s = {font:{name: 'Tahoma', sz: 8, bold: true}}
            ws['A2'].s = {font:{name: 'Tahoma', sz: 15, bold: true}, alignment: { wrapText: true, vertical: 'center', horizontal: 'center'}}
            ws['A3'].s = {font:{name: 'Tahoma', sz: 8, bold: true}}
            ws['A4'].s = {font:{name: 'Tahoma', sz: 8, bold: true}}

            for (const iterator of header) {
                const key1 = iterator + "5";
                ws[key1].s = {
                    ...ws[key1].s, 
                    alignment: { wrapText: true, vertical: 'center', horizontal: 'center'},
                    border: { 
                        top: { style: 'thin', color: 'black'},
                        bottom: { style: 'thin', color: 'black'},
                        left: { style: 'thin', color: 'black'},
                        right: { style: 'thin', color: 'black'}
                    } 
                }
            };

            ws["!merges"] = [
                { s: { r: 1, c: 0 }, e: { r: 1, c: 5 } },
            ];

            XLSX.utils.book_append_sheet(wb, ws, "Resumen de cheques emitidos");

            var wbout = XLSX.write(wb, {bookType:'xlsx', type: 'binary', cellStyles: true});

            const s2ab = function(s) {
                var buf = new ArrayBuffer(s.length);
                var view = new Uint8Array(buf);
                for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
                return buf;
            }

            saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), `resumen-cheques-emitidos-${year}-${month}.xlsx`);

        } catch(err) {
            console.log(err);
        }
    }

    render() {

        const { title, year, month, initdate, endidate, base64Pdf } = this.state;
        const { loading, failed, failedTitle, failedContent } = this.props.financeReducers;
        const { classes } = this.props;

        return (
            <div style={{overflow: 'auto', height: '100%'}}>
                <Paper className='form__container'>
                    <Typography variant='h5'>{ title }</Typography>
                    <br/>
                    { failed && <MessageBox title={failedTitle} content={failedContent}/> }
                    <div style={{ display: 'flex' }}>
                        <form noValidate autoComplete="off" style={{ flex: 1, paddingRight: '15px', paddingTop: '2em' }}>
                            <Grid container spacing={2} >
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_año">Año</InputLabel>
                                            <Select
                                                labelId="lbl_año"
                                                id="year"
                                                className='select'
                                                label="Año"
                                                fullWidth
                                                value={ year }
                                                onChange={(e: any) => this.onChangeRoot({'year': e.target.value})}
                                            >
                                                <MenuItem value='2030'>2030</MenuItem>
                                                <MenuItem value='2029'>2029</MenuItem>
                                                <MenuItem value='2028'>2028</MenuItem>
                                                <MenuItem value='2027'>2027</MenuItem>
                                                <MenuItem value='2026'>2026</MenuItem>
                                                <MenuItem value='2025'>2025</MenuItem>
                                                <MenuItem value='2026'>2026</MenuItem>
                                                <MenuItem value='2025'>2025</MenuItem>
                                                <MenuItem value='2024'>2024</MenuItem>
                                                <MenuItem value='2023'>2023</MenuItem>
                                                <MenuItem value='2022'>2022</MenuItem>
                                                <MenuItem value='2021'>2021</MenuItem>
                                                <MenuItem value='2020'>2020</MenuItem>
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_mes">Mes</InputLabel>
                                            <Select
                                                labelId="lbl_mes"
                                                id="month"
                                                className='select'
                                                label="Mes"
                                                fullWidth
                                                value={ month }
                                                onChange={(e: any) => this.onChangeRoot({'month': e.target.value})}
                                            >
                                                {
                                                    meses.map((x: any) => 
                                                        <MenuItem value={x.key} key={x.key}>{x.value}</MenuItem>
                                                    )
                                                }
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField fullWidth type="date" id="initdate"  label="Fecha de Inicio" variant="outlined" value={ initdate } onChange={(e: any) => this.onChangeRoot({'initdate': e.target.value})}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField fullWidth type="date" id="endidate"  label="Fecha de Fin" variant="outlined" value={ endidate } onChange={(e: any) => this.onChangeRoot({'endidate': e.target.value})}/>
                                </Grid>
                                <Grid item xs={12} style={{ paddingTop: '50px' }}>
                                    <Button variant="contained" fullWidth color="primary" onClick={() => { this.props.checksIssuedSummaryRequest(year, month, initdate, endidate, this.onGeneratePdf) }}>
                                        Generar Reporte PDF
                                    </Button>
                                </Grid>
                                <Grid item xs={12} style={{ paddingTop: '10px' }}>
                                    <Button variant="contained" fullWidth color="inherit" onClick={() => { this.props.checksIssuedSummaryRequest(year, month, initdate, endidate, this.onGenerateExcel) }}>
                                        Generar Reporte EXCEL
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                        <PdfView data={base64Pdf} style={{ flex: 2 }}/>
                    </div>
                    <br/>
                    {/* <FormFooter onCancel={ this.onCancel } onSubmit={ this.onSubmit }/> */}
                </Paper>
                <ScreenLoader enabled={loading}/>
            </div>
        );
    }
}

const financeReducers = 'financeReducers';
const dashboardReducers = 'dashboardReducers';

const mapStateToProps = (state: any) => ({
    financeReducers: state.get(financeReducers),
    dashboardReducers: state.get(dashboardReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        checksIssuedSummaryRequest
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(ReportChecksIssuedSummary));