import { Backdrop, CircularProgress, createStyles, Theme, withStyles } from '@material-ui/core';
import ScreenLoader from 'penta-components/base/screenLoader';
import React from 'react';
import { connect } from 'react-redux';
import './dashboard.scss';

const useStyles = (theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  });


class Dashboard extends React.Component<any> {

    render() {

        const { loading } = this.props.dashboardReducers;
        const { classes } = this.props;

        return (
            <div className="dashboard__container">
              <ScreenLoader enabled={loading}/>
            </div>
        );
    }
}

const reducer = 'dashboardReducers';

const mapStateToProps = (state: any) => ({
    dashboardReducers: state.get(reducer)
});

// const mapDispatchToProps = (dispatch: Dispatch) => {
//     return bindActionCreators({
//         authLoginRequest,
//     }, dispatch);
// };  

export default connect(mapStateToProps, null)(withStyles(useStyles)(Dashboard));