import React from 'react';
import { Backdrop, Button, ButtonGroup, Checkbox, CircularProgress, createStyles, FormControl, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Select, Snackbar, TextField, Theme, Tooltip, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { commissisInitUpdateFormRequest, commissisInsertRequest, commissisUpdateRequest } from 'penta-redux/actions/humanResourcesActions';
import { locationsGetProvincesRequest, locationsGetDistrictsRequest } from 'penta-redux/actions/generalActions';
import history from '../../../utils/history';
import './commissis.scss'
import FormFooter from 'penta-components/base/formFooter';
import { MessageBox } from 'penta-components/base/messageBox';
import { commissiEntity } from 'models/humanResourcesModels';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import EditableCell from 'penta-components/base/editableCell';
import { Alert } from '@material-ui/lab';
import ScreenLoader from 'penta-components/base/screenLoader';

const header = ["", "", "AFP", "Comision Fija", "Comision sobre flujo", "Comision Mixta / Comision sobre flujo", "Comision Mixta / Comision anual sobre saldo", "Prima de seguros", "Porcentaje de aporte obligatorio al Fondo de Pensiones", "Renumeracion maxima Asegurable"];

const useStyles = (theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        formControl: {
            margin: 0,
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    });

const defaultCommissisEntity: commissiEntity = {
    commyear: moment().format('YYYY'),
    commmont: moment().format('MM'),
    commissi: [
        {
            codigoafp: '01', nombreafp: 'HABITAT', comisionfija: 0.00, comisionflujo: 0.00,
            comisionmixtaflujo: 0.00, comisionmixtaanual: 0.00, prima: 0.00, aporte: 0.00,
            renumeracion: 0.00, select: false
        },
        {
            codigoafp: '02', nombreafp: 'INTEGRA', comisionfija: 0.00, comisionflujo: 0.00,
            comisionmixtaflujo: 0.00, comisionmixtaanual: 0.00, prima: 0.00, aporte: 0.00,
            renumeracion: 0.00, select: false
        },
        {
            codigoafp: '03', nombreafp: 'PRIMA', comisionfija: 0.00, comisionflujo: 0.00,
            comisionmixtaflujo: 0.00, comisionmixtaanual: 0.00, prima: 0.00, aporte: 0.00,
            renumeracion: 0.00, select: false
        },
        {
            codigoafp: '04', nombreafp: 'PROFUTURO', comisionfija: 0.00, comisionflujo: 0.00,
            comisionmixtaflujo: 0.00, comisionmixtaanual: 0.00, prima: 0.00, aporte: 0.00,
            renumeracion: 0.00, select: false
        }
    ]
}

interface FormCommissionsState {
    commissisEntity: commissiEntity
    title: string
    snackbar: boolean
}

class FormCommissions extends React.Component<any, FormCommissionsState> {

    constructor(props: any) {
        super(props);
        this.state = {
            commissisEntity: defaultCommissisEntity,
            title: 'Agregar Comisiones',
            snackbar: false
        }
    }

    onChangeRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            ...value
        }))
    }

    onChangeCommissisRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            commissisEntity: {
                ...state.commissisEntity,
                ...value
            }
        }));
    }

    onCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        console.log('cancel')
        history.push('/papp/hhrr/comisiones');
    }

    onContinueCallback = () => {
        const newCommissisEntity = defaultCommissisEntity;
        newCommissisEntity.commissi = [
            {
                codigoafp: '01', nombreafp: 'HABITAT', comisionfija: 0.00, comisionflujo: 0.00,
                comisionmixtaflujo: 0.00, comisionmixtaanual: 0.00, prima: 0.00, aporte: 0.00,
                renumeracion: 0.00, select: false
            },
            {
                codigoafp: '02', nombreafp: 'INTEGRA', comisionfija: 0.00, comisionflujo: 0.00,
                comisionmixtaflujo: 0.00, comisionmixtaanual: 0.00, prima: 0.00, aporte: 0.00,
                renumeracion: 0.00, select: false
            },
            {
                codigoafp: '03', nombreafp: 'PRIMA', comisionfija: 0.00, comisionflujo: 0.00,
                comisionmixtaflujo: 0.00, comisionmixtaanual: 0.00, prima: 0.00, aporte: 0.00,
                renumeracion: 0.00, select: false
            },
            {
                codigoafp: '04', nombreafp: 'PROFUTURO', comisionfija: 0.00, comisionflujo: 0.00,
                comisionmixtaflujo: 0.00, comisionmixtaanual: 0.00, prima: 0.00, aporte: 0.00,
                renumeracion: 0.00, select: false
            }
        ];
        this.setState({
            commissisEntity: newCommissisEntity,
            snackbar: true
        });
    }

    onSubmitContinue = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const { params } = this.props.match;
        if (params.commyear !== undefined) {
            this.props.commissisUpdateRequest(this.state.commissisEntity, null);
        } else {
            this.props.commissisInsertRequest(this.state.commissisEntity, this.onContinueCallback);
        }
    }

    onSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const { params } = this.props.match;
        if (params.commyear !== undefined) {
            this.props.commissisUpdateRequest(this.state.commissisEntity, null);
        } else {
            this.props.commissisInsertRequest(this.state.commissisEntity, null);
        }
    }

    onEditCell = (props: any, value: any) => {
        const commissi: any = this.state.commissisEntity.commissi;
        commissi[props.index][props.field] = value;

        this.setState((state: FormCommissionsState) => ({
            ...state,
            commissisEntity: {
                ...state.commissisEntity,
                commissi,
            }
        }))
    }

    onAddDetail = () => {
        const { commissi } = this.state.commissisEntity;
        const newItem: any = {
            codigoafp: '', nombreafp: '', comisionfija: 0.00, comisionflujo: 0.00,
            comisionmixtaflujo: 0.00, comisionmixtaanual: 0.00, prima: 0.00, aporte: 0.00,
            renumeracion: 0.00, select: false
        };
        commissi.push(newItem);
        this.setState((state: any) => ({
            ...state,
            commissisEntity: {
                ...state.commissisEntity,
                commissi,
            }
        }))
    }

    onDeleteDetail = () => {
        let { commissi } = this.state.commissisEntity;   
        commissi = commissi.filter((x: any) => x.select === false || x.select === undefined);

        this.setState((state: any) => ({
            ...state,
            commissisEntity: {
                ...state.commissisEntity,
                commissi,
            }
        }));
    }

    renderDetail = () => {
        const { commissi } = this.state.commissisEntity;
        
        return commissi.map((item: any, i: any) => (
            <tr key={ i } >
                <td>
                    <input type="checkbox"
                    checked={ item.select } 
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'select' }, 
                            e.target.checked
                        )}
                    />
                </td>
                <td>
                    <input type="text"
                    value={ item.codigoafp } 
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'codigoafp' }, 
                            e.target.value
                        )}
                    style={{ textAlign: 'right' }}
                    />
                </td>
                <td>
                    <input type="text"
                    value={ item.nombreafp } 
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'nombreafp' }, 
                            e.target.value
                        )}
                    style={{ textAlign: 'right' }}
                    />
                </td>
                <td>
                    <input type="text"
                    value={ item.comisionfija } 
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'comisionfija' }, 
                            e.target.value
                        )}
                    style={{ textAlign: 'right' }}
                    />
                </td>
                <td>
                    <input type="text"
                    value={ item.comisionflujo } 
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'comisionflujo' }, 
                            e.target.value
                        )}
                    style={{ textAlign: 'right' }}
                    />
                </td>
                <td>
                    <input type="text"
                    value={ item.comisionmixtaflujo } 
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'comisionmixtaflujo' }, 
                            e.target.value
                        )}
                    style={{ textAlign: 'right' }}
                    />
                </td>
                <td>
                    <input type="text"
                    value={ item.comisionmixtaanual } 
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'comisionmixtaanual' }, 
                            e.target.value
                        )}
                    style={{ textAlign: 'right' }}
                    />
                </td>
                <td>
                    <input type="text"
                    value={ item.prima } 
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'prima' }, 
                            e.target.value
                        )}
                    style={{ textAlign: 'right' }}
                    />
                </td>
                <td>
                    <input type="text"
                    value={ item.aporte } 
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'aporte' }, 
                            e.target.value
                        )}
                    style={{ textAlign: 'right' }}
                    />
                </td>
                <td>
                    <input type="text"
                    value={ item.renumeracion } 
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'renumeracion' }, 
                            e.target.value
                        )}
                    style={{ textAlign: 'right' }}
                    />
                </td>
            </tr>
        ))
    }

    renderGrilla = () => {
        return (
            <div className="form">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className="form__field">
                            <div className="grid__actions">
                                <button className="add" onClick={ (e) => { e.preventDefault(); this.onAddDetail() } }><i className="fas fa-plus"/></button>
                                <button className="remove" onClick={ (e) => { e.preventDefault(); this.onDeleteDetail() } }><i className="fas fa-times"/></button>
                                <span style={ { marginLeft: '15px' } }>[ENTER]: Agregar nuevo item</span>
                                <span style={ { marginLeft: '15px' } }>[TAB]: Navegar entre las celdas</span>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <EditableCell
                            header={ header }
                            data={ this.renderDetail() }
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }

    callbackInit = () => {
        const { tables } = this.props.humanResourcesReducers;
        this.setState((state: any) => ({
            ...state,
            commissisEntity: {
                ...state.commissisEntity,
                pbl_purchdet: [
                    {
                        skucodee: '',
                        itemnumb: 1,
                        taxtypee: tables.definition.filter((x: any) => x.defitype === 'TXTYP')[0].deficode,
                        incluigv: 1,
                    }
                ]
            }
        }));
    }

    callbackInitUpdate = (commissisEntity: commissiEntity) => {
        commissisEntity.commissi = JSON.parse(commissisEntity.commissi);
        this.setState({
            commissisEntity
        });
    }

    componentDidMount() {
        const { params } = this.props.match;
        if (params.commyear !== undefined) {
            this.setState({title: 'Editar Comisiones'});
            this.props.commissisInitUpdateFormRequest(params.commyear, params.commmont, this.callbackInitUpdate);
        }
    }

    render() {

        const { commissisEntity, title, snackbar } = this.state;
        const { loading, list, tables, failed, failedTitle, failedContent } = this.props.humanResourcesReducers;
        const generalTables = this.props.generalReducers.tables;
        const { classes } = this.props;

        return (
            <div style={{overflow: 'auto', height: '100%'}}>
                <Paper className='form__container'>
                    <br/>
                    <Typography variant='h5'>{ title }</Typography>
                    <br/>
                    { failed && <MessageBox title={failedTitle} content={failedContent}/> }
                    <form noValidate autoComplete="off">
                        <Grid container spacing={2} >
                            <Grid item xs={3}>
                                <TextField fullWidth id="commyear" label="Año de desvengue" variant="outlined" value={ commissisEntity.commyear } onChange={(e: any) => this.onChangeCommissisRoot({'commyear': e.target.value})}/>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth id="commmont" label="Mes de desvengue" variant="outlined" value={ commissisEntity.commmont } onChange={(e: any) => this.onChangeCommissisRoot({'commmont': e.target.value})}/>
                            </Grid>
                        </Grid>
                        <div style={{ height: 50 }}/>
                        <Grid container spacing={2} >
                            { this.renderGrilla()  }
                        </Grid>
                        <div style={{ height: 50 }}/>
                    </form>
                    <br/>
                    <FormFooter 
                        onCancel={ this.onCancel } 
                        onSubmitContinue={ this.onSubmitContinue } 
                        onSubmit={ this.onSubmit }/>
                </Paper>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={snackbar}
                    onClose={() => this.setState({snackbar: false})}
                    autoHideDuration={6000}
                >
                    <Alert onClose={() => this.setState({snackbar: false})} severity="success">
                            Datos Guardados Correctamente
                    </Alert>
                </Snackbar>
                <ScreenLoader enabled={loading}/>
            </div>
        );
    }
}

const humanResourcesReducers = 'humanResourcesReducers';
const generalReducers = 'generalReducers';

const mapStateToProps = (state: any) => ({
    humanResourcesReducers: state.get(humanResourcesReducers),
    generalReducers: state.get(generalReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        // commissisInitFormRequest,
        commissisInitUpdateFormRequest,
        commissisInsertRequest,
        commissisUpdateRequest
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(FormCommissions));