import React from 'react';
import { Button, createStyles, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Snackbar, Theme, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { employePayrollRequest } from 'penta-redux/actions/humanResourcesActions';
import './../reports/reports.scss'
import { MessageBox } from 'penta-components/base/messageBox';
import PdfView from 'penta-components/base/pdfView';
// import * as pdfMake from "pdfmake/build/pdfmake";
// import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
import * as XLSX from 'sheetjs-style';
import ScreenLoader from 'penta-components/base/screenLoader';
import { Alert } from '@material-ui/lab';
import { masterTablesRequest } from 'penta-redux/actions/generalActions';

let pdfMake = require("pdfmake/build/pdfmake");
let saveAs = require('file-saver').saveAs;

const useStyles = (theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        formControl: {
            margin: 0,
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    });

interface FormcategorysState {
    title: string
    option: string
    snackbar: boolean
}

class ListMasterTables extends React.Component<any, FormcategorysState> {

    constructor(props: any) {
        super(props);
        this.state = {
            title: 'Mayorización',
            option: '001',
            snackbar: false
        }
    }

    onChangeRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            ...value
        }))
    }

    onGenerateExcel = () => {
        const data = this.props.generalReducers.onlyOne;

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(data);

        XLSX.utils.book_append_sheet(wb, ws, "Tabla");
        var wbout = XLSX.write(wb, {bookType:'xlsx', type: 'binary', cellStyles: true});

        const s2ab = function(s) {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
        }

        let option = 'Proveedores';
        switch (this.state.option) {
            case '001': option = 'Proveedores'; break;
            case '002': option = 'Clientes'; break;
            case '003': option = 'Trabajadores'; break;
            case '004': option = 'Plan cuentas'; break;
        }

        saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), `${option}.xlsx`);
}

    render() {

        const { title, option, snackbar } = this.state;
        const { loading, failed, failedTitle, failedContent } = this.props.generalReducers;
        const { classes } = this.props;

        return (
            <div style={{overflow: 'auto', height: '100%'}}>
                <Paper className='form__container'>
                    <Typography variant='h5'>{ title }</Typography>
                    <br/>
                    { failed && <MessageBox title={failedTitle} content={failedContent}/> }
                    <div style={{ display: 'flex' }}>
                        <form noValidate autoComplete="off" style={{ flex: 1, paddingRight: '15px', paddingTop: '2em' }}>
                            <Grid container spacing={2} >
                                <Grid item xs={4}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_option">Option</InputLabel>
                                            <Select
                                                labelId="lbl_option"
                                                id="option"
                                                className='select'
                                                label="Option"
                                                fullWidth
                                                value={ option }
                                                onChange={(e: any) => this.onChangeRoot({'option': e.target.value})}
                                            >
                                                <MenuItem value='001'>Proveedores</MenuItem>
                                                <MenuItem value='002'>Clientes</MenuItem>
                                                <MenuItem value='003'>Trabajadores</MenuItem>
                                                <MenuItem value='004'>Plan cuentas</MenuItem>
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <Button variant="contained" fullWidth color="primary" onClick={() => { this.props.masterTablesRequest(option, this.onGenerateExcel)}}>
                                        Descargar
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                    <br/>
                    {/* <FormFooter onCancel={ this.onCancel } onSubmit={ this.onSubmit }/> */}
                </Paper>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={snackbar}
                    onClose={() => this.setState({snackbar: false})}
                    autoHideDuration={6000}
                >
                    <Alert onClose={() => this.setState({snackbar: false})} severity="success">
                        Mayorización realizada correctamente
                    </Alert>
                </Snackbar>
                <ScreenLoader enabled={loading}/>
            </div>
        );
    }
}

const generalReducers = 'generalReducers';
const dashboardReducers = 'dashboardReducers';

const mapStateToProps = (state: any) => ({
    generalReducers: state.get(generalReducers),
    dashboardReducers: state.get(dashboardReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        masterTablesRequest
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(ListMasterTables));