import React from 'react';
import { Backdrop, Button, ButtonGroup, Checkbox, CircularProgress, createStyles, FormControl, FormControlLabel, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Select, Switch, TextField, Theme, Tooltip, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { payrollGetAllRecordsRequest, employeVoucherRequest, employeVoucherAllRequest } from 'penta-redux/actions/humanResourcesActions';
import history from '../../../../utils/history';
import './../reports.scss'
import FormFooter from 'penta-components/base/formFooter';
import { MessageBox } from 'penta-components/base/messageBox';
import { categoryEntity } from 'models/wharehouseProductsModels';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PdfView from 'penta-components/base/pdfView';
// import * as pdfMake from "pdfmake/build/pdfmake";
// import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
import ScreenLoader from 'penta-components/base/screenLoader';

let pdfMake = require("pdfmake/build/pdfmake");

const meses = [
    { key: '01', value: 'Enero' },
    { key: '02', value: 'Febrero' },
    { key: '03', value: 'Marzo' },
    { key: '04', value: 'Abril' },
    { key: '05', value: 'Mayo' },
    { key: '06', value: 'Junio' },
    { key: '07', value: 'Julio' },
    { key: '08', value: 'Agosto' },
    { key: '09', value: 'Septiembre' },
    { key: '10', value: 'Octubre' },
    { key: '11', value: 'Noviembre' },
    { key: '12', value: 'Diciembre' }
]

const useStyles = (theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        formControl: {
            margin: 0,
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    });

interface FormcategorysState {
    title: string
    emplcode: string
    nameeess: string
    year: string
    month: string
    week: string
    payrtype: string
    typesala: string
    base64Pdf: string
    allFlag: boolean
}

class ReportEmployeVoucher extends React.Component<any, FormcategorysState> {

    constructor(props: any) {
        super(props);
        this.state = {
            title: 'Boleta de Pago',
            emplcode: '',
            nameeess: '',
            year: moment().format('yyyy').toString(),
            month: moment().format('MM').toString(),
            week: moment().week().toString(),
            payrtype: '101',
            typesala: '001',
            base64Pdf: '',
            allFlag: true
        }
    }

    onChangeRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            ...value
        }))
    }

    onGeneratePdf = () => {
        const data = this.props.humanResourcesReducers.onlyOne; //[0];
        const { emplcode, nameeess, year, month, week, payrtype } = this.state;
        const { dashboardReducers } = this.props;
        const mes = meses.find((x: any) => x.key === month).value;
        const tables = JSON.parse(localStorage.getItem('tables'));
        const company = tables.companys.find((x: any) => x.taxident === dashboardReducers.currentCompany);
        const division = company.lst_grl_division.find((x: any) => x.divicode === dashboardReducers.currentDivision);
        const oficina = division.lst_grl_bussofic.find((x: any) => x.officode === dashboardReducers.currentBussOfic);
        const pdfObject: any = {
            pageSize: 'A4',
            pageOrientation: 'landscape',
            pageMargins: [ 10, 20, 10, 20 ],
            content: [],
            styles: {
                headerTitle: {
                    bold: true,
                    fontSize: 9,
                    lineHeight: 1.2
                },
                table: {
                    layout: 'headerLineOnly',
                    fontSize: 10,
                    alignment: 'center'
                },
                tableDetailLeft: {
                    layout: 'headerLineOnly',
                    fontSize: 7,
                    alignment: 'left'
                },
                tableDetailRight: {
                    layout: 'headerLineOnly',
                    fontSize: 7,
                    alignment: 'right'
                }
            }
        }

        const rowLength = data.length;
        let i = 0;

        for (const iterator of data) {
            const json = this.generateOnlyJsonVoucher(iterator, emplcode, nameeess, year, mes, week, payrtype, company, oficina);
            pdfObject.content.push(json);
            i++;
            if(i % 2 !== 0)
            pdfObject.content.push({lineHeight: 3, text: ' '});
        }

        pdfMake.fonts = {
            Roboto: {
                normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
                bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
                italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
                bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
              },
        }

        const pdfDocGenerator = pdfMake.createPdf(pdfObject);
        pdfDocGenerator.getDataUrl((data: any) => {
            // console.log(data);
            this.setState({
                base64Pdf: data
            })
        });

    }

    generateOnlyJsonVoucher = (data: any, emplcode: string, nameeess: string, year: string, mes: string, week: string, payrtype: string, company: any, oficina: any) => {
        const { dashboardReducers } = this.props;
        const concepts = JSON.parse(data.concepts);

        const content: any = {
            unbreakable: true,
            stack: [
                {
                    table: {
                        headerRows: 1,
                        widths: [810],
                        body: [
                            [
                                {
                                    text: 'BOLETA DE PAGO',
                                    style: {
                                        alignment: 'center',
                                        lineHeight: .7,
                                        bold: true,
                                        fontSize: 12
                                    },
                                    border: [true, true, true, false]
                                }
                            ],
                            [
                                {
                                    lineHeight: 1.2,
                                    columns: [
                                        {
                                            
                                            text: company.bussname.toUpperCase(),
                                            style: {
                                                alignment: 'left',
                                                fontSize: 11,
                                                bold: true
                                            }
                                        },
                                        {
                                            
                                            text: 'D.S. 105 - 72 TR',
                                            style: {
                                                alignment: 'center',
                                                fontSize: 9,
                                                bold: true
                                            }
                                        },
                                        {
                                            
                                            text: 'RUC:' + dashboardReducers.currentCompany,
                                            style: {
                                                alignment: 'right',
                                                fontSize: 11,
                                                bold: true
                                            }
                                        }
                                    ],
                                    border: [true, false, true, false]
                                }
                            ],
                            [
                                {
                                    lineHeight: 1,
                                    columns: [
                                        {
                                            width: 340,
                                            text: `${data.typesala} ${payrtype === '102' ? 'SEMANAL' : '' } - ${data.payrtype} ${oficina.offiname}`,
                                            style: {
                                                alignment: 'left',
                                                fontSize: 11,
                                                bold: true
                                            }
                                        },
                                        {
                                            
                                            text: data.typesala !== 'SUBSIDIO' ?
                                            (payrtype === '102' ? 
                                            `CORRESPONDIENTE A LA SEMANA ${data.payrweek} DEL ${moment(data.stardate).format('DD-MM-YYYY')} AL ${moment(data.endidate).format('DD-MM-YYYY')}` 
                                            : `CORRESPONDIENTE AL MES DE ${mes.toUpperCase()} DEL ${year}`)
                                            : `CORRESPONDIENTE DEL ${parseInt(concepts.find((x: any) => x.conccode === 'C136')?.outcomee || '0')} AL ${parseInt(concepts.find((x: any) => x.conccode === 'C137')?.outcomee || '0')} DE ${mes.toUpperCase()} DEL ${year}` ,
                                            style: {
                                                alignment: 'left',
                                                fontSize: 11,
                                                bold: true
                                            }
                                        }
                                    ],
                                    border: [true, false, true, true]
                                }
                            ],
                            [
                                {
                                    lineHeight: .7,
                                    columns: [
                                        {
                                            width: 40,
                                            text: 'C.C',
                                            style: {
                                                alignment: 'left',
                                                fontSize: 9
                                            }
                                        },
                                        {
                                            width: 60,
                                            text: 'Código',
                                            style: {
                                                alignment: 'left',
                                                fontSize: 9
                                            }
                                        },
                                        {
                                            width: 200,
                                            text: 'Apellidos y Nombres',
                                            style: {
                                                alignment: 'left',
                                                fontSize: 9
                                            }
                                        },
                                        {
                                            width: 150,
                                            text: 'Ocupación',
                                            style: {
                                                alignment: 'left',
                                                fontSize: 9
                                            }
                                        },
                                        {
                                            width: 100,
                                            text: 'C.ESSALUD/CENSO',
                                            style: {
                                                alignment: 'left',
                                                fontSize: 9
                                            }
                                        },
                                        {
                                            text: 'Fecha de Ingreso',
                                            style: {
                                                alignment: 'left',
                                                fontSize: 9
                                            }
                                        },
                                        {
                                            width: 60,
                                            text: 'Básico',
                                            style: {
                                                alignment: 'center',
                                                fontSize: 9
                                            }
                                        },
                                        {
                                            width: 60,
                                            text: 'Horas Trab.',
                                            style: {
                                                alignment: 'center',
                                                fontSize: 9
                                            }
                                        },
                                        {
                                            width: 60,
                                            text: 'Horas Ext.',
                                            style: {
                                                alignment: 'center',
                                                fontSize: 9
                                            }
                                        }
                                    ],
                                    border: [true, false, true, false]
                                }
                            ],
                            [
                                {
                                    lineHeight: 1,
                                    columns: [
                                        {
                                            width: 40,
                                            text: '-',
                                            style: {
                                                alignment: 'left',
                                                fontSize: 9,
                                                bold: true
                                            }
                                        },
                                        {
                                            width: 60,
                                            text: data.emplcode,
                                            style: {
                                                alignment: 'left',
                                                fontSize: 9,
                                                bold: true
                                            }
                                        },
                                        {
                                            width: 200,
                                            text: data.nameeess.toUpperCase(),
                                            style: {
                                                alignment: 'left',
                                                fontSize: 9,
                                                bold: true
                                            }
                                        },
                                        {
                                            width: 150,
                                            text: data.posscode,
                                            style: {
                                                alignment: 'left',
                                                fontSize: 9,
                                                bold: true
                                            }
                                        },
                                        {
                                            width: 100,
                                            text: data.sesocode,
                                            style: {
                                                alignment: 'left',
                                                fontSize: 9,
                                                bold: true
                                            }
                                        },
                                        {
                                            
                                            text: moment(data.dateadmi).format('DD-MM-YYYY'),
                                            style: {
                                                alignment: 'left',
                                                fontSize: 9,
                                                bold: true
                                            }
                                        },
                                        {
                                            width: 60,
                                            text: data.basalary,
                                            style: {
                                                alignment: 'right',
                                                fontSize: 9,
                                                bold: true
                                            }
                                        },
                                        {
                                            width: 60,
                                            text: payrtype === '102' ? parseFloat(concepts.find((x: any) => x.conccode === 'C107')?.outcomee || '0') : parseFloat(concepts.find((x: any) => x.conccode === 'C103')?.outcomee || '0') * 8,
                                            style: {
                                                alignment: 'right',
                                                fontSize: 9,
                                                bold: true
                                            }
                                        },
                                        {
                                            width: 60,
                                            text: payrtype === '102' ? parseFloat(concepts.find((x: any) => x.conccode === 'C114')?.outcomee || '0') + parseFloat(concepts.find((x: any) => x.conccode === 'C121')?.outcomee || '0') + parseFloat(concepts.find((x: any) => x.conccode === 'C122')?.outcomee || '0') + parseFloat(concepts.find((x: any) => x.conccode === 'C123')?.outcomee || '0') + parseFloat(concepts.find((x: any) => x.conccode === 'C124')?.outcomee || '0') : parseFloat(concepts.find((x: any) => x.conccode === 'C104')?.outcomee || '0') + parseFloat(concepts.find((x: any) => x.conccode === 'C105')?.outcomee || '0') + parseFloat(concepts.find((x: any) => x.conccode === 'C106')?.outcomee || '0'),
                                            style: {
                                                alignment: 'right',
                                                fontSize: 9,
                                                bold: true
                                            }
                                        }
                                    ],
                                    border: [true, false, true, false]
                                }
                            ]
                        ]
                    }
                },
                {
                    table: {
                        headerRows: 1,
                        widths: [ 195.75, 195.75, 195.75, 195.75 ],
                        body: [
                            [ 
                                { text: 'RENUMERACION', characterSpacing: 3, border: [true, true, true, true], style: 'table' },
                                { text: 'DESCUENTOS', characterSpacing: 3, border: [true, true, true, true], style: 'table' },
                                { text: 'APORTACIONES', characterSpacing: 3, border: [true, true, true, true], style: 'table' },
                                { text: '', border: [true, true, true, false], style: 'table' }
                            ],
                            [ 
                                { 
                                    stack: [ ], 
                                    border: [true, true, true, false]
                                },
                                { 
                                    stack: [ ], 
                                    border: [true, true, true, false]
                                },
                                { 
                                    stack: [ ], 
                                    border: [true, true, true, false]
                                },
                                { 
                                    stack: [ ], 
                                    border: [true, false, true, false]
                                }
                            ],
                            [ 
                                { 
                                    text: '',
                                    border: [true, false, true, true]
                                },
                                { 
                                    text: '',
                                    border: [true, false, true, true]
                                },
                                { 
                                    text: '',
                                    border: [true, false, true, true]
                                },
                                { 
                                    stack: [
                                        {
                                            lineHeight: 1.5,
                                            columns: [
                                                 { text: '____________________', style: { fontSize: 8, alignment: 'center' } },
                                                 { text: '____________________', style: { fontSize: 8, alignment: 'center' } }
                                            ],
                                        },
                                        {
                                            lineHeight: 2,
                                            columns: [
                                                 { text: 'EMPLEADOR', style: { fontSize: 8, alignment: 'center' } },
                                                 { text: 'TRABAJADOR', style: { fontSize: 8, alignment: 'center' } }
                                            ],
                                        },
                                    ], 
                                    border: [true, false, true, true]
                                }
                            ]
                        ]
                    }
                }
            ]
        }

        const concept051 = concepts.filter((x: any) => x.conctype === '051' && parseFloat(x?.outcomee || '0') !== 0);
        const concept052 = concepts.filter((x: any) => x.conctype === '052' && parseFloat(x?.outcomee || '0') !== 0);
        const concept053 = concepts.filter((x: any) => x.conctype === '053' && parseFloat(x?.outcomee || '0') !== 0);
        const totalAportes = concepts.find((x: any) => x.conccode === 'C404');
        const totalIngresos = concepts.find((x: any) => x.conccode === 'C501');
        const totalDescuentos = concepts.find((x: any) => x.conccode === 'C502');
        const NetoPagar = concepts.find((x: any) => x.conccode === 'C503');

        for (const iterator of concept051) {
            content.stack[1].table.body[1][0].stack.push(
                {
                    lineHeight: 1.1,
                    columns: [
                         { text: iterator.descript.toUpperCase(), style: { fontSize: 9, bold: true } },
                         { text: String.formatNumber.new(iterator?.outcomee || '0'), style: { fontSize: 9, bold: true, alignment: 'right' }, width: 50 }
                    ],
                }
            )
        }

        for (const iterator of concept052) {
            const descript = !(this.state.typesala === '003' && this.state.payrtype === '102' && iterator.conccode === 'C206') ? iterator.descript.toUpperCase() : 'BONIFICACIÓN';
            content.stack[1].table.body[1][1].stack.push(
                {
                    lineHeight: 1.1,
                    columns: [
                         { text: descript, style: { fontSize: 9, bold: true } },
                         { text: String.formatNumber.new(iterator?.outcomee || '0'), style: { fontSize: 9, bold: true, alignment: 'right' }, width: 50 }
                    ],
                }
            )
        }

        for (const iterator of concept053) {
            if(iterator.conccode === 'C404') continue;
            content.stack[1].table.body[1][2].stack.push(
                {
                    lineHeight: 1.1,
                    columns: [
                         { text: iterator.descript.toUpperCase(), style: { fontSize: 9, bold: true } },
                         { text: String.formatNumber.new(iterator?.outcomee || '0'), style: { fontSize: 9, bold: true, alignment: 'right' }, width: 50 }
                    ],
                }
            )
        }

        content.stack[1].table.body[1][3].stack.push(
            {
                lineHeight: 1.1,
                columns: [
                     { text: totalIngresos?.descript.toUpperCase(), style: { fontSize: 9, bold: true } },
                     { text: String.formatNumber.new(totalIngresos?.outcomee || '0'), style: { fontSize: 9, bold: true, alignment: 'right' }, width: 50 }
                ],
            }
        )

        content.stack[1].table.body[1][3].stack.push(
            {
                lineHeight: .5,
                columns: [
                     { text: totalDescuentos?.descript.toUpperCase(), style: { fontSize: 9, bold: true } },
                     { text: String.formatNumber.new(totalDescuentos?.outcomee || '0'), style: { fontSize: 9, bold: true, alignment: 'right' }, width: 50 }
                ],
            }
        )

        content.stack[1].table.body[1][3].stack.push(
            {
                lineHeight: 1.1,
                columns: [
                     { text: '', style: { fontSize: 9 } },
                     { text: '____________', style: { fontSize: 9, bold: true, alignment: 'right' }, width: 50 }
                ],
            }
        )

        content.stack[1].table.body[1][3].stack.push(
            {
                lineHeight: .5,
                columns: [
                     { text: NetoPagar.descript.toUpperCase(), style: { fontSize: 9, bold: true } },
                     { text: String.formatNumber.new(NetoPagar?.outcomee || '0'), style: { fontSize: 9, bold: true, alignment: 'right' }, width: 50 }
                ],
            }
        )

        content.stack[1].table.body[1][3].stack.push(
            {
                lineHeight: 1.1,
                columns: [
                     { text: '', style: { fontSize: 9 } },
                     { text: '____________', style: { fontSize: 9, alignment: 'right' }, width: 50 }
                ],
            }
        )

        content.stack[1].table.body[1][3].stack.push(
            {
                lineHeight: 6,
                columns: [
                     { text: totalAportes?.descript.toUpperCase(), style: { fontSize: 9, bold: true } },
                     { text: String.formatNumber.new(totalAportes?.outcomee || '0'), style: { fontSize: 9, bold: true, alignment: 'right' }, width: 50 }
                ],
            }
        )

        return content;
    }

    onGenerateVoucher = () => {
        const { emplcode, year, month, week, typesala, payrtype, allFlag } = this.state;
        if (allFlag) {
            this.props.employeVoucherAllRequest(year, month, week, typesala, payrtype, this.onGeneratePdf)
        } else {
            this.props.employeVoucherRequest(emplcode, year, month, week, typesala, payrtype, this.onGeneratePdf)
        }
    }

    componentDidMount() {
        const { payrollGetAllRecordsRequest } = this.props;
        payrollGetAllRecordsRequest({}); 
    }

    render() {

        const { title, emplcode, nameeess, year, month, week, payrtype, typesala, base64Pdf, allFlag } = this.state;
        const { loading, list, failed, failedTitle, failedContent } = this.props.humanResourcesReducers;
        const { classes } = this.props;

        return (
            <div style={{overflow: 'auto', height: '100%'}}>
                <Paper className='form__container'>
                    <Typography variant='h5'>{ title }</Typography>
                    <br/>
                    { failed && <MessageBox title={failedTitle} content={failedContent}/> }
                    <div style={{ display: 'flex' }}>
                        <form noValidate autoComplete="off" style={{ flex: 1, paddingRight: '15px', paddingTop: '2em' }}>
                            <Grid container spacing={2} >
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_planilla">Planilla</InputLabel>
                                            <Select
                                                labelId="lbl_planilla"
                                                id="payrtype"
                                                className='select'
                                                label="Planilla"
                                                fullWidth
                                                value={ payrtype }
                                                onChange={(e: any) => this.onChangeRoot({'payrtype': e.target.value})}
                                            >
                                                <MenuItem value='101'>Empleados</MenuItem>
                                                <MenuItem value='102'>Obreros</MenuItem>
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_tipo">Tipo</InputLabel>
                                            <Select
                                                labelId="lbl_tipo"
                                                id="typesala"
                                                className='select'
                                                label="Tipo"
                                                fullWidth
                                                value={ typesala }
                                                onChange={(e: any) => this.onChangeRoot({'typesala': e.target.value})}
                                            >
                                                <MenuItem value='001'>Sueldos</MenuItem>
                                                <MenuItem value='002'>Vacaciones</MenuItem>
                                                <MenuItem value='003'>Gratificacion</MenuItem>
                                                <MenuItem value='004'>Subsidio</MenuItem>
                                                <MenuItem value='008'>Utilidades</MenuItem>
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                        <Switch
                                            checked={allFlag}
                                            onChange={(e: any) => this.onChangeRoot({'allFlag': e.target.checked})}
                                            name="checkedB"
                                            color="primary"
                                        />
                                        }
                                        label="Todo el personal"
                                    />
                                </Grid>
                            </Grid>
                                {
                                    !allFlag && 
                                    <>
                                        <Grid container spacing={2}>   
                                                <Grid item xs={12}>
                                                    <Autocomplete
                                                        id="emplcode"
                                                        freeSolo
                                                        options={list.filter((x: any) => x.payrtype === payrtype) || []}
                                                        getOptionLabel={ (x: any) => x.emplcode }
                                                        value={ list.find((x: any) => x.emplcode === emplcode) || null }
                                                        onChange={(e: any, value) => this.onChangeRoot({'emplcode': value.emplcode, 'nameeess': value.nameeess})}
                                                        renderInput={(params) => (
                                                            <TextField {...params} 
                                                            style={ { marginTop: 0 } } label="Empleado" margin="normal" variant="outlined" />
                                                        )}
                                                    />
                                                </Grid>
                                        </Grid>
                                        <Grid container spacing={2}> 
                                            <Grid item xs={12}>
                                                <TextField fullWidth id="nameeess" label="Nombres" variant="outlined" value={ nameeess || '' } disabled/>
                                            </Grid>
                                        </Grid>
                                    </>
                            } 
                            <Grid container spacing={2}>   
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_año">Año</InputLabel>
                                            <Select
                                                labelId="lbl_año"
                                                id="year"
                                                className='select'
                                                label="Año"
                                                fullWidth
                                                value={ year }
                                                onChange={(e: any) => this.onChangeRoot({'year': e.target.value})}
                                            >
                                                <MenuItem value='2030'>2030</MenuItem>
                                                <MenuItem value='2029'>2029</MenuItem>
                                                <MenuItem value='2028'>2028</MenuItem>
                                                <MenuItem value='2027'>2027</MenuItem>
                                                <MenuItem value='2026'>2026</MenuItem>
                                                <MenuItem value='2025'>2025</MenuItem>
                                                <MenuItem value='2026'>2026</MenuItem>
                                                <MenuItem value='2025'>2025</MenuItem>
                                                <MenuItem value='2024'>2024</MenuItem>
                                                <MenuItem value='2023'>2023</MenuItem>
                                                <MenuItem value='2022'>2022</MenuItem>
                                                <MenuItem value='2021'>2021</MenuItem>
                                                <MenuItem value='2020'>2020</MenuItem>
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField fullWidth id="payrweek" label="Semana" variant="outlined" value={ week } onChange={(e: any) => this.onChangeRoot({'week': e.target.value})} disabled={ payrtype === '102' ? false : true }/>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_mes">Mes</InputLabel>
                                            <Select
                                                labelId="lbl_mes"
                                                id="month"
                                                className='select'
                                                label="Mes"
                                                fullWidth
                                                value={ month }
                                                onChange={(e: any) => this.onChangeRoot({'month': e.target.value})}
                                                disabled={ payrtype === '101' ? false : true }
                                            >
                                                {
                                                    meses.map((x: any) => 
                                                        <MenuItem value={x.key} key={x.key}>{x.value}</MenuItem>
                                                    )
                                                }
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} style={{ paddingTop: '50px' }}>
                                    <Button variant="contained" fullWidth color="primary" onClick={this.onGenerateVoucher}>
                                        Generar Boleta
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                        <PdfView data={base64Pdf} style={{ flex: 2 }}/>
                    </div>
                    <br/>
                    {/* <FormFooter onCancel={ this.onCancel } onSubmit={ this.onSubmit }/> */}
                </Paper>
                <ScreenLoader enabled={loading}/>
            </div>
        );
    }
}

const humanResourcesReducers = 'humanResourcesReducers';
const dashboardReducers = 'dashboardReducers';

const mapStateToProps = (state: any) => ({
    humanResourcesReducers: state.get(humanResourcesReducers),
    dashboardReducers: state.get(dashboardReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        payrollGetAllRecordsRequest,
        employeVoucherRequest,
        employeVoucherAllRequest
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(ReportEmployeVoucher));