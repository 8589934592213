import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Outer from '../templates/outer';
import {
    LoginPage
} from '../pages/pageListAsync';

class Auth extends React.Component {
  render() {
    return (
      <Outer>
        <Switch>
          <Route path="/login" exact component={LoginPage} />
          {/* <Route path="/register" component={Register} />
          <Route path="/reset-password" component={ResetPassword} />
          <Route path="/lock-screen" component={LockScreen} />
          <Route path="/maintenance" component={Maintenance} />
          <Route path="/coming-soon" component={ComingSoon} />
          <Route path="/terms-conditions" component={TermsConditions} />
          <Route component={NotFound} /> */}
        </Switch>
      </Outer>
    );
  }
}

export default Auth;