import {
    CASH_BOOK_BANKS_REQUEST,
    CASH_BOOK_BANKS_REQUEST_FAILED,
    CASH_BOOK_BANKS_REQUEST_SUCCESS,
    DAOT_REQUEST,
    DAOT_REQUEST_FAILED,
    DAOT_REQUEST_SUCCESS,
    DIARY_BOOK_REQUEST,
    DIARY_BOOK_REQUEST_FAILED,
    DIARY_BOOK_REQUEST_SUCCESS,
    EXPENSE_ANALYSIS_NINE_REQUEST,
    EXPENSE_ANALYSIS_NINE_REQUEST_FAILED,
    EXPENSE_ANALYSIS_NINE_REQUEST_SUCCESS,
    EXPENSE_ANALYSIS_SIX_REQUEST,
    EXPENSE_ANALYSIS_SIX_REQUEST_FAILED,
    EXPENSE_ANALYSIS_SIX_REQUEST_SUCCESS,
    GENERAL_BALANCES_ACCOUNT_REQUEST,
    GENERAL_BALANCES_ACCOUNT_REQUEST_FAILED,
    GENERAL_BALANCES_ACCOUNT_REQUEST_SUCCESS,
    GENERAL_BALANCES_REQUEST,
    GENERAL_BALANCES_REQUEST_FAILED,
    GENERAL_BALANCES_REQUEST_SUCCESS,
    INVENTORY_BOOK_BALANCE_REQUEST,
    INVENTORY_BOOK_BALANCE_REQUEST_FAILED,
    INVENTORY_BOOK_BALANCE_REQUEST_SUCCESS,
    MAJORIZATION_REQUEST,
    MAJORIZATION_REQUEST_FAILED,
    MAJORIZATION_REQUEST_SUCCESS,
    MAJOR_GENERAL_REQUEST,
    MAJOR_GENERAL_REQUEST_FAILED,
    MAJOR_GENERAL_REQUEST_SUCCESS,
    MASTER_ACCOUNT_GET_ALL_RECORDS_REQUEST, 
    MASTER_ACCOUNT_GET_ALL_RECORDS_REQUEST_FAILED, 
    MASTER_ACCOUNT_GET_ALL_RECORDS_REQUEST_SUCCESS,
    MASTER_ACCOUNT_UPDATE_REQUEST,
    MASTER_ACCOUNT_UPDATE_REQUEST_FAILED,
    MASTER_ACCOUNT_UPDATE_REQUEST_SUCCESS,
    PLAN_ACCOUNT_ACTION_FAILED,
    PLAN_ACCOUNT_DELETE_REQUEST,
    PLAN_ACCOUNT_DELETE_REQUEST_FAILED,
    PLAN_ACCOUNT_DELETE_REQUEST_SUCCESS,
    PLAN_ACCOUNT_GET_ALL_RECORDS_REQUEST,
    PLAN_ACCOUNT_GET_ALL_RECORDS_REQUEST_FAILED,
    PLAN_ACCOUNT_GET_ALL_RECORDS_REQUEST_SUCCESS,
    PLAN_ACCOUNT_INIT_FORM_REQUEST,
    PLAN_ACCOUNT_INIT_FORM_REQUEST_FAILED,
    PLAN_ACCOUNT_INIT_FORM_REQUEST_SUCCESS,
    PLAN_ACCOUNT_INIT_UPDATE_FORM_REQUEST,
    PLAN_ACCOUNT_INIT_UPDATE_FORM_REQUEST_FAILED,
    PLAN_ACCOUNT_INIT_UPDATE_FORM_REQUEST_SUCCESS,
    PLAN_ACCOUNT_INSERT_REQUEST,
    PLAN_ACCOUNT_INSERT_REQUEST_FAILED,
    PLAN_ACCOUNT_INSERT_REQUEST_SUCCESS,
    PLAN_ACCOUNT_LIST_SELECT_ALL_ITEM,
    PLAN_ACCOUNT_LIST_SELECT_ITEM,
    PLAN_ACCOUNT_UPDATE_REQUEST,
    PLAN_ACCOUNT_UPDATE_REQUEST_FAILED,
    PLAN_ACCOUNT_UPDATE_REQUEST_SUCCESS,
    PROFIT_LOSS_STATEMENT_REQUEST,
    PROFIT_LOSS_STATEMENT_REQUEST_FAILED,
    PROFIT_LOSS_STATEMENT_REQUEST_SUCCESS,
    PURCHASES_REGISTER_REQUEST,
    PURCHASES_REGISTER_REQUEST_FAILED,
    PURCHASES_REGISTER_REQUEST_SUCCESS,
    PURCHASE_DETAIL_SUPPLIER_REQUEST,
    PURCHASE_DETAIL_SUPPLIER_REQUEST_FAILED,
    PURCHASE_DETAIL_SUPPLIER_REQUEST_SUCCESS,
    SALES_REGISTER_REQUEST,
    SALES_REGISTER_REQUEST_FAILED,
    SALES_REGISTER_REQUEST_SUCCESS,
    VOUCHERS_ACTION_FAILED,
    VOUCHERS_DELETE_REQUEST,
    VOUCHERS_DELETE_REQUEST_FAILED,
    VOUCHERS_DELETE_REQUEST_SUCCESS,
    VOUCHERS_GET_ALL_RECORDS_REQUEST,
    VOUCHERS_GET_ALL_RECORDS_REQUEST_FAILED,
    VOUCHERS_GET_ALL_RECORDS_REQUEST_SUCCESS,
    VOUCHERS_INIT_FORM_REQUEST,
    VOUCHERS_INIT_FORM_REQUEST_FAILED,
    VOUCHERS_INIT_FORM_REQUEST_SUCCESS,
    VOUCHERS_INIT_UPDATE_FORM_REQUEST,
    VOUCHERS_INIT_UPDATE_FORM_REQUEST_FAILED,
    VOUCHERS_INIT_UPDATE_FORM_REQUEST_SUCCESS,
    VOUCHERS_INSERT_REQUEST,
    VOUCHERS_INSERT_REQUEST_FAILED,
    VOUCHERS_INSERT_REQUEST_SUCCESS,
    VOUCHERS_LIST_SELECT_ALL_ITEM,
    VOUCHERS_LIST_SELECT_ITEM,
    VOUCHERS_UPDATE_REQUEST,
    VOUCHERS_UPDATE_REQUEST_FAILED,
    VOUCHERS_UPDATE_REQUEST_SUCCESS,
    WORKSHEET_REQUEST,
    WORKSHEET_REQUEST_FAILED,
    WORKSHEET_REQUEST_SUCCESS
} from '../constants/accountantConstants';

const initialState: any = {
    tables: { accountNature: [], planAccount: [], accotype: [], masteaccs: [], grl_definiti: [] },
    onlyOne: null,
    list: [],
    total: 0,
    loading: false,
    failed: false,
    failedTitle: null,
    failedContent: null
};

export default function accountantReducer(state = initialState, action: any = {}) {
    switch(action.type) {
        case MASTER_ACCOUNT_GET_ALL_RECORDS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case MASTER_ACCOUNT_GET_ALL_RECORDS_REQUEST_SUCCESS:
            return {
                ...state,
                list: action.list,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case MASTER_ACCOUNT_GET_ALL_RECORDS_REQUEST_FAILED:
            return {
                ...initialState,
                list: [],
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case MASTER_ACCOUNT_UPDATE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case MASTER_ACCOUNT_UPDATE_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case MASTER_ACCOUNT_UPDATE_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case PLAN_ACCOUNT_INIT_FORM_REQUEST:
        case VOUCHERS_INIT_FORM_REQUEST:
            return {
                ...state,
                loading: true
            };
        case PLAN_ACCOUNT_INIT_FORM_REQUEST_SUCCESS:
        case VOUCHERS_INIT_FORM_REQUEST_SUCCESS:
            return {
                ...state,
                tables: { ...state.tables, ...action.tables },
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case PLAN_ACCOUNT_INIT_FORM_REQUEST_FAILED:
        case VOUCHERS_INIT_FORM_REQUEST_FAILED:
            return {
                ...initialState,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case PLAN_ACCOUNT_INIT_UPDATE_FORM_REQUEST:
        case VOUCHERS_INIT_UPDATE_FORM_REQUEST:
            return {
                ...state,
                loading: true
            };
        case PLAN_ACCOUNT_INIT_UPDATE_FORM_REQUEST_SUCCESS:
        case VOUCHERS_INIT_UPDATE_FORM_REQUEST_SUCCESS:
            return {
                ...state,
                tables: { ...state.tables, ...action.tables },
                onlyOne: action.onlyOne,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case PLAN_ACCOUNT_INIT_UPDATE_FORM_REQUEST_FAILED:
        case VOUCHERS_INIT_UPDATE_FORM_REQUEST_FAILED:
            return {
                ...initialState,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case PLAN_ACCOUNT_GET_ALL_RECORDS_REQUEST:
        case VOUCHERS_GET_ALL_RECORDS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case PLAN_ACCOUNT_GET_ALL_RECORDS_REQUEST_SUCCESS:
        case VOUCHERS_GET_ALL_RECORDS_REQUEST_SUCCESS:
            return {
                ...state,
                list: action.list,
                total: action.total,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case PLAN_ACCOUNT_GET_ALL_RECORDS_REQUEST_FAILED:
        case VOUCHERS_GET_ALL_RECORDS_REQUEST_FAILED:
            return {
                ...initialState,
                list: [],
                total: 0,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case PLAN_ACCOUNT_INSERT_REQUEST:
        case VOUCHERS_INSERT_REQUEST:
            return {
                ...state,
                loading: true
            };
        case PLAN_ACCOUNT_INSERT_REQUEST_SUCCESS:
        case VOUCHERS_INSERT_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case PLAN_ACCOUNT_INSERT_REQUEST_FAILED:
        case VOUCHERS_INSERT_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case PLAN_ACCOUNT_UPDATE_REQUEST:
        case VOUCHERS_UPDATE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case PLAN_ACCOUNT_UPDATE_REQUEST_SUCCESS:
        case VOUCHERS_UPDATE_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case PLAN_ACCOUNT_UPDATE_REQUEST_FAILED:
        case VOUCHERS_UPDATE_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case PLAN_ACCOUNT_DELETE_REQUEST:
        case VOUCHERS_DELETE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case PLAN_ACCOUNT_DELETE_REQUEST_SUCCESS:
        case VOUCHERS_DELETE_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case PLAN_ACCOUNT_DELETE_REQUEST_FAILED:
        case VOUCHERS_DELETE_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case PLAN_ACCOUNT_LIST_SELECT_ITEM:
        case VOUCHERS_LIST_SELECT_ITEM:
            const newList = state.list;
            newList[action.key].select = !newList[action.key].select;
            return {
                ...state,
                list: newList
            };
        case PLAN_ACCOUNT_LIST_SELECT_ALL_ITEM:
        case VOUCHERS_LIST_SELECT_ALL_ITEM:
            const newListAll = state.list;
            for (const item of newListAll) {
                item.select = action.checked
            }
            return {
                ...state,
                list: newListAll
            };
        case PLAN_ACCOUNT_ACTION_FAILED:
        case VOUCHERS_ACTION_FAILED:
            return {
                ...state,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case GENERAL_BALANCES_ACCOUNT_REQUEST:
        case DIARY_BOOK_REQUEST:
        case PURCHASES_REGISTER_REQUEST:
        case WORKSHEET_REQUEST:
        case MAJOR_GENERAL_REQUEST:
        case CASH_BOOK_BANKS_REQUEST:
        case EXPENSE_ANALYSIS_NINE_REQUEST:
        case EXPENSE_ANALYSIS_SIX_REQUEST:
        case INVENTORY_BOOK_BALANCE_REQUEST:
        case GENERAL_BALANCES_REQUEST:
        case PROFIT_LOSS_STATEMENT_REQUEST:
        case MAJORIZATION_REQUEST:
        case SALES_REGISTER_REQUEST:
        case DAOT_REQUEST:
        case PURCHASE_DETAIL_SUPPLIER_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GENERAL_BALANCES_ACCOUNT_REQUEST_SUCCESS:
        case DIARY_BOOK_REQUEST_SUCCESS:
        case PURCHASES_REGISTER_REQUEST_SUCCESS:
        case WORKSHEET_REQUEST_SUCCESS:
        case MAJOR_GENERAL_REQUEST_SUCCESS:
        case CASH_BOOK_BANKS_REQUEST_SUCCESS:
        case EXPENSE_ANALYSIS_NINE_REQUEST_SUCCESS:
        case EXPENSE_ANALYSIS_SIX_REQUEST_SUCCESS:
        case INVENTORY_BOOK_BALANCE_REQUEST_SUCCESS:
        case GENERAL_BALANCES_REQUEST_SUCCESS:
        case PROFIT_LOSS_STATEMENT_REQUEST_SUCCESS:
        case MAJORIZATION_REQUEST_SUCCESS:
        case SALES_REGISTER_REQUEST_SUCCESS:
        case DAOT_REQUEST_SUCCESS:
        case PURCHASE_DETAIL_SUPPLIER_REQUEST_SUCCESS:
            return {
                ...state,
                onlyOne: action.onlyOne,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case GENERAL_BALANCES_ACCOUNT_REQUEST_FAILED:
        case DIARY_BOOK_REQUEST_FAILED:
        case PURCHASES_REGISTER_REQUEST_SUCCESS:
        case WORKSHEET_REQUEST_FAILED:
        case MAJOR_GENERAL_REQUEST_FAILED:
        case CASH_BOOK_BANKS_REQUEST_FAILED:
        case EXPENSE_ANALYSIS_NINE_REQUEST_FAILED:
        case EXPENSE_ANALYSIS_SIX_REQUEST_FAILED:
        case INVENTORY_BOOK_BALANCE_REQUEST_FAILED:
        case GENERAL_BALANCES_REQUEST_FAILED:
        case PROFIT_LOSS_STATEMENT_REQUEST_FAILED:
        case MAJORIZATION_REQUEST_FAILED:
        case SALES_REGISTER_REQUEST_FAILED:
        case DAOT_REQUEST_FAILED:
        case PURCHASE_DETAIL_SUPPLIER_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        default:
            return state;
    }
}