import React, { CSSProperties } from 'react';
import {createUseStyles} from 'react-jss'

const useStyles = createUseStyles({
    container: {
        'display': 'flex',
        // 'margin': '1rem 0rem'
    },
    label: {
        'color': '#3B3B3B',
        'fontSize': '0.75rem',
        'fontFamily': '"Work Sans", sans-serif',
        'fontWeight': 400,
        'marginLeft': '0.5rem',
    },
    input: {
        'margin': 0,
        'border': '0.1rem solid #dfdfdf',
        'borderRadius': '0.3rem',
        'color': '#3B3B3B',
        'fontSize': '0.75rem',
        'fontFamily': '"Work Sans", sans-serif',
    }
})

interface Checkbox {
    label?: string
    containerStyle?: CSSProperties
    checked?: boolean
    onClick?: (e: any) => void
    onChange: (e: any) => void
}

const Checkbox = (props: Checkbox) => {
    const classes = useStyles();
    return (
        <div className={classes.container} style={props.containerStyle}>
            <input 
                className={classes.input} 
                type="checkbox" 
                checked={props.checked} 
                onClick={props.onClick}
                onChange={props.onChange}
            />
            {
                props.label && <label className={classes.label}>{props.label}</label>
            }
        </div>
    )
}

Checkbox.defaultProps = {
    checked: false,
    onClick: () => {},
    onChange: () => {}
}

export default Checkbox;