import React from 'react';
import { Backdrop, Button, ButtonGroup, Checkbox, CircularProgress, createStyles, FormControl, FormControlLabel, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Select, Switch, TextField, Theme, Tooltip, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { majorGeneralRequest } from 'penta-redux/actions/accountantActions';
import history from '../../../../utils/history';
import './../reports.scss'
import FormFooter from 'penta-components/base/formFooter';
import { MessageBox } from 'penta-components/base/messageBox';
import { categoryEntity } from 'models/wharehouseProductsModels';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PdfView from 'penta-components/base/pdfView';
// import * as pdfMake from "pdfmake/build/pdfmake";
// import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
import * as XLSX from 'sheetjs-style';
import ScreenLoader from 'penta-components/base/screenLoader';

let pdfMake = require("pdfmake/build/pdfmake");
let saveAs = require('file-saver').saveAs;

const meses = [
    { key: '01', value: 'Enero' },
    { key: '02', value: 'Febrero' },
    { key: '03', value: 'Marzo' },
    { key: '04', value: 'Abril' },
    { key: '05', value: 'Mayo' },
    { key: '06', value: 'Junio' },
    { key: '07', value: 'Julio' },
    { key: '08', value: 'Agosto' },
    { key: '09', value: 'Septiembre' },
    { key: '10', value: 'Octubre' },
    { key: '11', value: 'Noviembre' },
    { key: '12', value: 'Diciembre' }
]

const useStyles = (theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        formControl: {
            margin: 0,
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    });

interface FormReportState {
    title: string,
    year: string,
    month: string,
    accumulated: boolean
    base64Pdf: string
}

class ReportMajorGeneral extends React.Component<any, FormReportState> {

    constructor(props: any) {
        super(props);
        this.state = {
            title: 'Reporte de Major General',
            year: moment().format('yyyy').toString(),
            month: moment().format('MM').toString(),
            accumulated: false,
            base64Pdf: ''
        }
    }

    onChangeRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            ...value
        }))
    }

    onGeneratePdf = () => {
        const data = this.props.accountantReducers.onlyOne;
        const { month, year, accumulated } = this.state;
        const { dashboardReducers } = this.props;
        const mes = meses.find((x: any) => x.key === month).value;
        const tables = JSON.parse(localStorage.getItem('tables'));
        const company = tables.companys.find((x: any) => x.taxident === dashboardReducers.currentCompany);
        const division = company.lst_grl_division.find((x: any) => x.divicode === dashboardReducers.currentDivision);
        const oficina = division.lst_grl_bussofic.find((x: any) => x.officode === dashboardReducers.currentBussOfic);
        const date = new Date(parseInt(year), parseInt(month) - 1, 1);
        const stardate = moment(date).format('DD-MM-YYYY');
        const endidate = moment(date).daysInMonth() + moment(date).format('-MM-') + moment(date).format('YYYY');

        const pdfObject: any = {
            pageSize: 'A4',
            pageMargins: [ 20, 90, 20, 20 ],
            header: [
                {
                    margin: [20, 20, 20, 0],
                    text: 'MAYOR GENERAL',
                    style: {
                        bold: true,
                        fontSize: 9,
                        alignment: 'center', 
                        lineHeight: 3
                    }
                },
                {
                    margin: [20, 0, 20, 0],
                    columns: [
                        {
                            stack: [
                                {
                                    columns: [
                                        {
                                            width: 50,
                                            text: 'PERIODO:',
                                            style: 'headerTitle'
                                        },
                                        {
                                            width: 500,
                                            text: `DEL ${stardate} AL ${endidate}`,
                                            style: 'headerTitle'
                                        }
                                    ]
                                },
                                {
                                    columns: [
                                        {
                                            width: 50,
                                            text: 'RUC:',
                                            style: 'headerTitle'
                                        },
                                        {
                                            width: 500,
                                            text: dashboardReducers.currentCompany,
                                            style: 'headerTitle'
                                        }
                                    ]
                                },
                                {
                                    columns: [
                                        {
                                            width: 50,
                                            text: 'EMPRESA:',
                                            style: 'headerTitle'
                                        },
                                        {
                                            width: 500,
                                            text: company.bussname.toUpperCase(),
                                            style: 'headerTitle'
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ],
            content: [
                {
                    table: {
                        headerRows: 2,
                        widths: [ 40, 35, 20, 40, 40, 40, 60, 60, 40, 40, 40 ],
                        body: []
                    }
                },
            ],
            styles: {
                headerTitle: {
                    bold: true,
                    fontSize: 7,
                    lineHeight: 1.2
                },
                table: {
                    layout: 'headerLineOnly',
                    fontSize: 6,
                    alignment: 'center'
                },
                subTable: {
                    layout: 'headerLineOnly',
                    fontSize: 6,
                    alignment: 'left'
                },
                tableDetailLeft: {
                    layout: 'headerLineOnly',
                    fontSize: 5.5,
                    alignment: 'left'
                },
                tableDetailRight: {
                    layout: 'headerLineOnly',
                    fontSize: 5.5,
                    alignment: 'right'
                },
                tableDetailRightBold: {
                    layout: 'headerLineOnly',
                    fontSize: 5.5,
                    alignment: 'right',
                    bold: true
                }
            },
            defaultStyle: {
                columnGap: 20,
            },
        }

        const tableBody: any = [
            [ 
                { text: 'CUENTA CONTABLE NRO. INTERNO', rowSpan: 2, style: 'table', border: [true, true, false, true] },
                { text: 'FECHA DE LA OPERACION', rowSpan: 2, style: 'table', border: [true, true, false, true] },
                { text: 'NUMERO CORRELATIVO', style: 'table', colSpan: 4, border: [true, true, false, true] },{},{},{},
                { text: '\nDESCRIPCION O GLOSA DE LA OPERACION', style: 'table', colSpan: 2, rowSpan: 2, border: [true, true, true, true] },{},
                { text: 'MOVIMIENTO', style: 'table', colSpan: 2, border: [true, true, true, true] },{},
                { text: '\nSALDO ACUMULADO', rowSpan: 2, style: 'table', border: [true, true, true, true] }
            ],
            [ 
                { text: '', style: 'table', border: [true, false, true, true] },
                { text: '', style: 'table', border: [false, false, true, true] },
                { text: 'GRUP.', style: 'table', border: [true, false, true, true] },
                { text: 'COMPR.', style: 'table', border: [false, false, true, true] },
                { text: 'CHEQ.', style: 'table', border: [false, false, true, true] },
                { text: 'DOC.SUST.', style: 'table', border: [false, false, true, true] },
                { text: '', style: 'table', border: [false, false, false, true] },
                { text: '', style: 'table', border: [false, false, true, true] },
                { text: 'DEBE.\n\n', style: 'table', border: [false, false, true, true] },
                { text: 'HABER\n\n', style: 'table', border: [false, false, true, true] },
                { text: '', style: 'table', border: [false, false, true, true] }
            ],
        ]

        const { DiaryBook, GeneralBalancesAccount } = data;
        const accaccou = [];
        const accBanks = [
            '104102',
            '104101',
            '104103',
            '104104',
            '104106',
            '104107',
            '104108',
            '104200',
            '101001',
            '101002',
            '101003',
            '101004',
            '101005',
            '101000',
            '101006',
            '101007'
        ];
        
        for (const row of GeneralBalancesAccount) {
            if(!accaccou.includes(row.accaccou)) {
                const diary = DiaryBook.filter((x: any) => x.accaccou === row.accaccou);
                if(diary.length > 0) {
                    const _ = GeneralBalancesAccount.filter(x => x.accaccou === row.accaccou);
                    const first = _.length > 1 ? _[0] : { totalacc: 0 };
                    const last = _[_.length - 1];
                    let debe = 0.00, haber = 0.00;
                    tableBody.push(
                        [
                            { text: row.accaccou, style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                            { text: row.denomina, style: 'tableDetailLeft', colSpan: 2, border: [false, false, false, false] },{},
                            { text: '', style: 'tableDetailRight', colSpan: 7, border: [false, false, false, false] },{},{},{},{},{},{},
                            { text: String.formatNumber.new(first.totalacc), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] }
                        ]
                    )
                    for (const iterator of diary) {
                        if(row.accaccou.startsWith('10') && iterator.unicodop.startsWith('MV') && iterator.position.startsWith('C'))
                        {
                            if(accBanks.includes(row.accaccou)) continue;
                        } 
                        tableBody.push(
                            [ 
                                { text: iterator.unicodop, style: 'tableDetailLeft', border: [false, false, false, false] },
                                { text: moment(iterator.dateoper).format('DD/MM/YYYY'), style: 'tableDetailLeft', border: [false, false, false, false] },
                                { text: iterator.grounumb, style: 'tableDetailLeft', border: [false, false, false, false] },
                                { text: iterator.voucnumb, style: 'tableDetailLeft', border: [false, false, false, false] },
                                { text: iterator.checnumb, style: 'tableDetailLeft', border: [false, false, false, false] },
                                { text: iterator.sustnumb, style: 'tableDetailLeft', border: [false, false, false, false] },
                                { text: iterator.operglos.split('|')[0].substr(0, 15), style: 'tableDetailLeft', border: [false, false, false, false] },
                                { text: iterator.operglos.split('|')[1]?.substr(0, 15) || '', style: 'tableDetailLeft', border: [false, false, false, false] },
                                
                                { text: +iterator.chargamo !== 0 ? String.formatNumber.new(iterator.chargamo) : '', style: 'tableDetailRight', border: [false, false, false, false] },
                                { text: +iterator.crediamo !== 0 ? String.formatNumber.new(iterator.crediamo) : '', style: 'tableDetailRight', border: [false, false, false, false] },
                                { text: '', style: 'tableDetailRight', border: [false, false, false, false] },
                            ]
                        )
                        debe += +iterator.chargamo;
                        haber += +iterator.crediamo;
                    }
                    tableBody.push(
                        [
                            { text: row.accaccou, style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                            { text: 'TOTAL CTA.', style: 'tableDetailLeft', colSpan: 2, border: [false, false, false, false] },{},
                            { text: '', style: 'tableDetailRight', colSpan: 5, border: [false, false, false, false] },{},{},{},{},
                            { text: String.formatNumber.new(debe), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                            { text: String.formatNumber.new(haber), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                            { text: String.formatNumber.new(last.totalacc), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] }
                        ]
                    )
                } else {
                    if(row.acclevel == 1) {
                        const _ = GeneralBalancesAccount.filter(x => x.accaccou === row.accaccou);
                        const first = _.length > 1 ? _[0] : { totalacc: 0 };
                        const last = _[_.length - 1];
                        tableBody.push(
                            [
                                { text: row.accaccou, style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: row.denomina, style: 'tableDetailLeft', colSpan: 5, border: [false, false, false, false] },{},{},{},{},
                                { text: 'Saldo Inicial', style: 'tableDetailRight', colSpan: 4, border: [false, false, false, false] },{},{},{},
                                { text: String.formatNumber.new(first.totalacc), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] }
                            ],
                            [
                                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: '', style: 'tableDetailLeft', colSpan: 5, border: [false, false, false, false] },{},{},{},{},
                                { text: 'Saldo Final', style: 'tableDetailRight', colSpan: 4, border: [false, false, false, false] },{},{},{},
                                { text: String.formatNumber.new(last.totalacc), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] }
                            ]
                        )
                    }
                    else {
                        const _ = GeneralBalancesAccount.filter(x => x.accaccou === row.accaccou);
                        const last = _[_.length - 1];
                        tableBody.push(
                            [
                                { text: row.accaccou, style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: row.denomina, style: 'tableDetailLeft', colSpan: 5, border: [false, false, false, false] },{},{},{},{},
                                { text: '', style: 'tableDetailLeft', colSpan: 4, border: [false, false, false, false] },{},{},{},
                                { text: String.formatNumber.new(last.totalacc), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] }
                            ]
                        )
                        tableBody.push(
                            [
                                { text: row.accaccou, style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: 'TOTAL CTA.', style: 'tableDetailLeft', colSpan: 2, border: [false, false, false, false] },{},
                                { text: '', style: 'tableDetailRight', colSpan: 5, border: [false, false, false, false] },{},{},{},{},
                                { text: String.formatNumber.new('0'), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber.new('0'), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber.new(last.totalacc), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] }
                            ]
                        )
                    }
                }
                accaccou.push(row.accaccou)
            }
        }

        pdfObject.content[0].table.body = tableBody;

        pdfMake.fonts = {
            Roboto: {
                normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
                bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
                italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
                bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
              },
        }
        const pdfDocGenerator = pdfMake.createPdf(pdfObject);
        pdfDocGenerator.getBlob((data: any) => {
            var url = URL.createObjectURL(data);
            this.setState({
                base64Pdf: url
            })
        });

        // pdfMake.createPdf(pdfObject).print();
    }

    onGenerateExcel = () => {
        try {
            const data = this.props.accountantReducers.onlyOne;
            const { month, year, accumulated } = this.state;
            const { dashboardReducers } = this.props;
            const mes = meses.find((x: any) => x.key === month).value;
            const tables = JSON.parse(localStorage.getItem('tables'));
            const company = tables.companys.find((x: any) => x.taxident === dashboardReducers.currentCompany);
            const division = company.lst_grl_division.find((x: any) => x.divicode === dashboardReducers.currentDivision);
            const oficina = division.lst_grl_bussofic.find((x: any) => x.officode === dashboardReducers.currentBussOfic);
            const date = new Date(parseInt(year), parseInt(month) - 1, 1);
            const stardate = moment(date).format('DD-MM-YYYY');
            const endidate = moment(date).daysInMonth() + moment(date).format('-MM-') + moment(date).format('YYYY');

            const tableBody: any = [
                { 
                    A: null,
                    B: null,
                    C: null,
                    D: null,
                    E: 'MAYOR GENERAL',
                    F: null,
                    G: null,
                    H: null,
                    I: null,
                    J: null,
                    K: null
                },
                { 
                    A: `PERIODO: DEL ${stardate} AL ${endidate}`,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null,
                    G: null,
                    H: null,
                    I: null,
                    J: null,
                    K: null
                },
                { 
                    A: `RUC: ${dashboardReducers.currentCompany}`,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null,
                    G: null,
                    H: null,
                    I: null,
                    J: null,
                    K: null
                },
                { 
                    A: `EMPRESA: ${company.bussname.toUpperCase()}`,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null,
                    G: null,
                    H: null,
                    I: null,
                    J: null,
                    K: null
                },
                { 
                    A: null,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null,
                    G: null,
                    H: null,
                    I: null,
                    J: null,
                    K: null
                },
                { 
                    A: 'CUENTA CONTABLE NRO. INTERNO',
                    B: 'FECHA DE LA OPERACION',
                    C: 'NUMERO CORRELATIVO',
                    D: '',
                    E: '',
                    F: '',
                    G: 'DESCRIPCION O GLOSA DE LA OPERACION',
                    H: '',
                    I: 'MOVIMIENTO',
                    J: '',
                    K: 'SALDO ACUMULADO'
                },
                { 
                    A: '',
                    B: '',
                    C: 'GRUP.',
                    D: 'COMPR.',
                    E: 'CHEQ.',
                    F: 'DOC.SUST.',
                    G: '',
                    H: '',
                    I: 'DEBE',
                    J: 'HABER',
                    K: ''
                }
            ]

            const { DiaryBook, GeneralBalancesAccount } = data;
            const accaccou = [];
            const accBanks = [
                '104102',
                '104101',
                '104103',
                '104104',
                '104106',
                '104107',
                '104108',
                '104200',
                '101001',
                '101002',
                '101003',
                '101004',
                '101005',
                '101000',
                '101006',
                '101007'
            ];

            for (const row of GeneralBalancesAccount) {
                if(!accaccou.includes(row.accaccou)) {
                    const diary = DiaryBook.filter((x: any) => x.accaccou === row.accaccou);
                    if(diary.length > 0) {
                        const _ = GeneralBalancesAccount.filter(x => x.accaccou === row.accaccou);
                        const first = _.length > 1 ? _[0] : { totalacc: 0 };
                        const last = _[_.length - 1];
                        let debe = 0.00, haber = 0.00;
                        tableBody.push(
                            {
                                A: row.accaccou,
                                B: row.denomina,
                                C: null,
                                D: null,
                                E: null,
                                F: null,
                                G: null,
                                H: null,
                                I: null,
                                J: null,
                                K: String.formatNumber.new(first.totalacc)
                            }
                        )
                        for (const iterator of diary) {
                            if(row.accaccou.startsWith('10') && iterator.unicodop.startsWith('MV') && iterator.position.startsWith('C'))
                            {
                                if(accBanks.includes(row.accaccou)) continue;
                            }
                            tableBody.push(
                                { 
                                    A: iterator.unicodop,
                                    B: moment(iterator.dateoper).format('DD/MM/YYYY'),
                                    C: iterator.grounumb,
                                    D: iterator.voucnumb,
                                    E: iterator.checnumb,
                                    F: iterator.sustnumb,
                                    G: iterator.operglos.split('|')[0],//.substr(0, 30),
                                    H: iterator.operglos.split('|')[1] || '',//?.substr(0, 30) || '',
                                    I: +iterator.chargamo !== 0 ? String.formatNumber.new(iterator.chargamo) : '',
                                    J: +iterator.crediamo !== 0 ? String.formatNumber.new(iterator.crediamo) : '',
                                    K: ''
                                }
                            )
                            debe += +iterator.chargamo;
                            haber += +iterator.crediamo;
                        }
                        tableBody.push(
                            {
                                A: row.accaccou,
                                B: 'TOTAL CTA.',
                                C: '',
                                D: '',
                                E: '',
                                F: '',
                                G: '',
                                H: '',
                                I: String.formatNumber.new(debe),
                                J: String.formatNumber.new(haber),
                                K: String.formatNumber.new(last.totalacc)
                            }
                        )
                    } else {
                        if(row.acclevel == 1) {
                            const _ = GeneralBalancesAccount.filter(x => x.accaccou === row.accaccou);
                            const first = _.length > 1 ? _[0] : { totalacc: 0 };
                            const last = _[_.length - 1];
                            tableBody.push(
                                {
                                    A: row.accaccou,
                                    B: row.denomina,
                                    C: null,
                                    D: null,
                                    E: null,
                                    F: null,
                                    G: 'Saldo Inicial',
                                    H: null,
                                    I: null,
                                    J: null,
                                    K: String.formatNumber.new(first.totalacc)
                                },
                                {
                                    A: null,
                                    B: null,
                                    C: null,
                                    D: null,
                                    E: null,
                                    F: null,
                                    G: 'Saldo Final',
                                    H: null,
                                    I: null,
                                    J: null,
                                    K: String.formatNumber.new(last.totalacc)
                                }
                            )
                        }
                        else {
                            const _ = GeneralBalancesAccount.filter(x => x.accaccou === row.accaccou);
                            const last = _[_.length - 1];
                            tableBody.push(
                                {
                                    A: row.accaccou,
                                    B: row.denomina,
                                    C: null,
                                    D: null,
                                    E: null,
                                    F: null,
                                    G: null,
                                    H: null,
                                    I: null,
                                    J: null,
                                    K: String.formatNumber.new(last.totalacc)
                                }
                            );
                            tableBody.push(
                                {
                                    A: row.accaccou,
                                    B: 'TOTAL CTA.',
                                    C: '',
                                    D: '',
                                    E: '',
                                    F: '',
                                    G: '',
                                    H: '',
                                    I: String.formatNumber.new('0'),
                                    J: String.formatNumber.new('0'),
                                    K: String.formatNumber.new(last.totalacc)
                                }
                            )
                        }
                    }
                    accaccou.push(row.accaccou)
                }
            }

            const header = [
                'A',
                'B',
                'C',
                'D',
                'E',
                'F',
                'G',
                'H',
                'I',
                'J',
                'K'
            ];

            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(tableBody, {header, skipHeader: true});
            ws["!cols"] = [
                {wpx:70},{wpx:65},{wpx:30},{wpx:40},{wpx:40},{wpx:60},{wpx:150},{wpx:150},{wpx:70},{wpx:70},{wpx:70}
            ];
            ws['!rows'] = [
                {hpx: 18},{hpx: 16},{hpx: 16},{hpx: 16},{hpx: 16},{hpx: 16},{hpx: 30}
            ];

            const keys = Object.keys(ws);
            const rowsCount = tableBody.length;

            for (const iterator of keys) {
                if(!isNaN(parseInt(ws[iterator].v)) && iterator.match(/^(I|J|K)[0-9]+$/g)) {
                    ws[iterator].v = ws[iterator].v.replace(/,/g, '');
                    ws[iterator].t = 'n';
                    ws[iterator].z = '#,##0.00';
                }
                if(iterator !== '!ref' && iterator !== '!cols' && iterator !== '!rows') {
                    ws[iterator].s = {
                        font: {
                            name: 'Tahoma',
                            sz: 8
                        }
                    }
                }
            }

            for (let index = 8; index <= rowsCount; index++) {
                ws['!rows'].push({hpx: 12})
            }

            ws['E1'].s = {font:{name: 'Tahoma', sz: 15, bold: true}}
            ws['A2'].s = {font:{name: 'Tahoma', sz: 8, bold: true}}
            ws['A3'].s = {font:{name: 'Tahoma', sz: 8, bold: true}}
            ws['A4'].s = {font:{name: 'Tahoma', sz: 8, bold: true}}

            for (const iterator of header) {
                const key1 = iterator + "6";
                const key2 = iterator + "7";

                ws[key1].s = {
                    ...ws[key1].s, 
                    alignment: { wrapText: true, vertical: 'center', horizontal: 'center'},
                    border: { 
                        top: { style: 'thin', color: 'black'},
                        bottom: { style: 'thin', color: 'black'},
                        left: { style: 'thin', color: 'black'},
                        right: { style: 'thin', color: 'black'}
                    } 
                }
                ws[key2].s = {
                    ...ws[key2].s, 
                    alignment: { wrapText: true, vertical: 'center', horizontal: 'center'},
                    border: { 
                        top: { style: 'thin', color: 'black'},
                        bottom: { style: 'thin', color: 'black'},
                        left: { style: 'thin', color: 'black'},
                        right: { style: 'thin', color: 'black'}
                    } 
                }
            };

            ws["!merges"] = [
                { s: { r: 5, c: 0 }, e: { r: 6, c: 0 } },
                { s: { r: 5, c: 1 }, e: { r: 6, c: 1 } },
                { s: { r: 5, c: 2 }, e: { r: 5, c: 5 } },
                { s: { r: 5, c: 6 }, e: { r: 6, c: 7 } },
                { s: { r: 5, c: 8 }, e: { r: 5, c: 9 } },
                { s: { r: 5, c: 10 }, e: { r: 6, c: 10 } },
            ];

            XLSX.utils.book_append_sheet(wb, ws, "Mayor");

            var wbout = XLSX.write(wb, {bookType:'xlsx', type: 'binary', cellStyles: true});

            const s2ab = function(s) {
                var buf = new ArrayBuffer(s.length);
                var view = new Uint8Array(buf);
                for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
                return buf;
            }

            saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), `mayor-general-${year}-${month}.xlsx`);

        } catch(err) {
            console.log(err);
        }
    }

    render() {

        const { title, year, month, accumulated, base64Pdf } = this.state;
        const { loading, list, tables, failed, failedTitle, failedContent } = this.props.accountantReducers;
        const { classes } = this.props;

        return (
            <div style={{overflow: 'auto', height: '100%'}}>
                <Paper className='form__container'>
                    <Typography variant='h5'>{ title }</Typography>
                    <br/>
                    { failed && <MessageBox title={failedTitle} content={failedContent}/> }
                    <div style={{ display: 'flex' }}>
                        <form noValidate autoComplete="off" style={{ flex: 1, paddingRight: '15px', paddingTop: '2em' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_año">Año</InputLabel>
                                            <Select
                                                labelId="lbl_año"
                                                id="year"
                                                className='select'
                                                label="Año"
                                                fullWidth
                                                value={ year }
                                                onChange={(e: any) => this.onChangeRoot({'year': e.target.value})}
                                            >
                                                <MenuItem value='2030'>2030</MenuItem>
                                                <MenuItem value='2029'>2029</MenuItem>
                                                <MenuItem value='2028'>2028</MenuItem>
                                                <MenuItem value='2027'>2027</MenuItem>
                                                <MenuItem value='2026'>2026</MenuItem>
                                                <MenuItem value='2025'>2025</MenuItem>
                                                <MenuItem value='2026'>2026</MenuItem>
                                                <MenuItem value='2025'>2025</MenuItem>
                                                <MenuItem value='2024'>2024</MenuItem>
                                                <MenuItem value='2023'>2023</MenuItem>
                                                <MenuItem value='2022'>2022</MenuItem>
                                                <MenuItem value='2021'>2021</MenuItem>
                                                <MenuItem value='2020'>2020</MenuItem>
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_mes">Mes</InputLabel>
                                            <Select
                                                labelId="lbl_mes"
                                                id="month"
                                                className='select'
                                                label="Mes"
                                                fullWidth
                                                value={ month }
                                                onChange={(e: any) => this.onChangeRoot({'month': e.target.value})}
                                            >
                                                {
                                                    meses.map((x: any) => 
                                                        <MenuItem value={x.key} key={x.key}>{x.value}</MenuItem>
                                                    )
                                                }
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={accumulated}
                                                onChange={(e: any) => this.onChangeRoot({'accumulated': e.target.checked})}
                                                name="accumulated"
                                                color="primary"
                                            />
                                        }
                                        label="Acumulado"
                                    />
                                </Grid>
                                <Grid item xs={12} style={{ paddingTop: '50px' }}>
                                    <Button variant="contained" fullWidth color="primary" onClick={() => { 
                                        const balacode = [];
                                        if(accumulated)
                                            for(let i = 1; i <= +month; i++) {
                                                balacode.push(`${year}${i.toString().padStart(2, '0')}`)
                                            }
                                        else {
                                            balacode.push(`${year}${month.toString().padStart(2, '0')}`)
                                        }
                                        this.props.majorGeneralRequest(year, balacode, this.onGeneratePdf) 
                                    }}>
                                        Generar Reporte PDF
                                    </Button>
                                </Grid>
                                <Grid item xs={12} style={{ paddingTop: '10px' }}>
                                    <Button variant="contained" fullWidth color="inherit" onClick={() => { 
                                        const balacode = [];
                                        if(accumulated)
                                            for(let i = 1; i <= +month; i++) {
                                                balacode.push(`${year}${i.toString().padStart(2, '0')}`)
                                            }
                                        else {
                                            balacode.push(`${year}${month.toString().padStart(2, '0')}`)
                                        }
                                        this.props.majorGeneralRequest(year, balacode, this.onGenerateExcel) 
                                    }}>
                                        Generar Reporte EXCEL
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                        <PdfView data={base64Pdf} style={{ flex: 2 }}/>
                    </div>
                    <br/>
                    {/* <FormFooter onCancel={ this.onCancel } onSubmit={ this.onSubmit }/> */}
                </Paper>
                <ScreenLoader enabled={loading}/>
            </div>
        );
    }
}

const accountantReducers = 'accountantReducers';
const dashboardReducers = 'dashboardReducers';

const mapStateToProps = (state: any) => ({
    accountantReducers: state.get(accountantReducers),
    dashboardReducers: state.get(dashboardReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        majorGeneralRequest
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(ReportMajorGeneral));