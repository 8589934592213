import React, { useState } from 'react';
import { Backdrop, CircularProgress, createStyles, Theme, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { authLoginRequest } from 'redux/actions/authActions';
import { genericValidation } from 'utils/validation';
import { bindActionCreators, Dispatch } from 'redux';
import TextInput from 'penta-components/controls/input';
import Button from 'penta-components/controls/button';
import Checkbox from 'penta-components/controls/checkbox';
import MessageBox from 'penta-components/popups/messageBox';
import logo from './../../../assets/images/PentaErpLogo1.png';
import { authInitialState } from 'models/statesModels';
import './auth.scss';
import ScreenLoader from 'penta-components/base/screenLoader';

const useStyles = (theme: Theme) => createStyles({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
});

interface Login {
    authReducers: authInitialState
    authLoginRequest: typeof authLoginRequest,
    classes: any
}

const Login = (props: Login) => {
    const { classes, authReducers } = props;
    const { loading, failed, failedTitle, failedContent } = authReducers;
    const [company, setCompany] = useState<string>(process.env.PENTA_CLIENT_COMPANY);
    const [credentials, setCredentials] = useState<{username: string, password: string}>({username: '', password: ''});
    const [credentialsErrors, setCredentialsErrors] = useState<{usernameError: boolean, passwordError: boolean}>({usernameError: false, passwordError: false});

    const onChange = (value: Object) => {
        const key = Object.keys(value)[0];
        setCredentials({
            ...credentials,
            ...value,
        });
        setCredentialsErrors({
            ...credentialsErrors,
            [`${key}Error`]: false
        });
    }

    const onSubmit = () => {
        const { username, password } = credentials;
        const states: any = {};
        const fields = genericValidation({username, password});
        if (fields.length > 0) {
            for (const item of fields) {
                states[`${item}Error`] = true;
            }
            setCredentialsErrors({
                ...credentialsErrors,
                ...states
            });
            return;
        }
        props.authLoginRequest({username, password});
    }


    return (
        <div className='login__container'>
            <div className='login__subcontainer'>
                <div className='form__container'>
                    <div className='logo'>
                        <img src={logo} />
                    </div>
                    <div className='form'>
                        <TextInput type='text' label='Usuario' labelPosition='top' error={credentialsErrors.usernameError} value={credentials.username} onChange={(e) => onChange({username: e.target.value})}/>
                        <TextInput type='password' label='Password' labelPosition='top' error={credentialsErrors.passwordError} value={credentials.password} onChange={(e) => onChange({password: e.target.value})}/>
                        <Button onClick={onSubmit}>INICIAR SESION</Button>
                        <Checkbox label="Recordar" containerStyle={{margin: '1rem 0rem'}}/>
                    </div>
                </div>
                <div className='welcome__container'>
                    <div className='info'>
                        <a href='https://www.pentaerp.com' target='_blank'>www.pentaerp.com</a>
                    </div>
                </div>
            </div>
            { failed && <MessageBox title={failedTitle} content={failedContent}/> }
            <ScreenLoader enabled={loading}/>
        </div>
    )
};

const reducer = 'authReducers';

const mapStateToProps = (state: any) => ({
    authReducers: state.get(reducer)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        authLoginRequest,
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(Login));