import React from 'react';
import { Backdrop, Button, ButtonGroup, Checkbox, CircularProgress, createStyles, FormControl, FormControlLabel, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Select, Switch, TextField, Theme, Tooltip, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { worksheetRequest } from 'penta-redux/actions/accountantActions';
import history from '../../../../utils/history';
import './../reports.scss'
import FormFooter from 'penta-components/base/formFooter';
import { MessageBox } from 'penta-components/base/messageBox';
import { categoryEntity } from 'models/wharehouseProductsModels';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PdfView from 'penta-components/base/pdfView';
// import * as pdfMake from "pdfmake/build/pdfmake";
// import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
import * as XLSX from 'sheetjs-style';
import ScreenLoader from 'penta-components/base/screenLoader';

let pdfMake = require("pdfmake/build/pdfmake");
let saveAs = require('file-saver').saveAs;

const meses = [
    { key: '01', value: 'Enero' },
    { key: '02', value: 'Febrero' },
    { key: '03', value: 'Marzo' },
    { key: '04', value: 'Abril' },
    { key: '05', value: 'Mayo' },
    { key: '06', value: 'Junio' },
    { key: '07', value: 'Julio' },
    { key: '08', value: 'Agosto' },
    { key: '09', value: 'Septiembre' },
    { key: '10', value: 'Octubre' },
    { key: '11', value: 'Noviembre' },
    { key: '12', value: 'Diciembre' }
]

const useStyles = (theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        formControl: {
            margin: 0,
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    });

interface FormcategorysState {
    title: string
    year: string
    month: string
    level: number
    accumulated: boolean
    base64Pdf: string
}

class ReportGeneralBalancesAccount extends React.Component<any, FormcategorysState> {

    constructor(props: any) {
        super(props);
        this.state = {
            title: 'Reporte de Hoja de Trabajo',
            year: moment().format('yyyy').toString(),
            month: moment().format('MM').toString(),
            accumulated: false,
            level: 1,
            base64Pdf: ''
        }
    }

    onChangeRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            ...value
        }))
    }

    onGeneratePdf = () => {
        const data = this.props.accountantReducers.onlyOne;
        const { month, year, level } = this.state;
        const { dashboardReducers } = this.props;
        const mes = meses.find((x: any) => x.key === month).value;
        const company = (JSON.parse(localStorage.getItem('tables')).companys.find((x: any) => x.taxident === dashboardReducers.currentCompany).bussname as string).toUpperCase();

        const pdfObject: any = {
            pageSize: 'A4',
            pageOrientation: 'landscape',
            pageMargins: [ 20, 90, 20, 20 ],
            header: [
                {
                    text: ' ', lineHeight: 2
                },
                {
                    margin: [20, 0, 20, 0],
                    text: company,
                    style: {
                        bold: true,
                        fontSize: 9
                    }
                },
                {
                    margin: [20, 5, 20, 0],
                    text: `HOJA DE TRABAJO ${mes.toUpperCase()}-${year} MENSUAL`,
                    style: {
                        bold: true,
                        fontSize: 9,
                        alignment: 'center'
                    }
                },
                {
                    margin: [20, 10, 0, 0],
                    text: `NIVEL MAXIMO: ${level}`,
                    style: {
                        bold: true,
                        fontSize: 9
                    }
                }
            ],
            content: [
                {
                    table: {
                        headerRows: 2,
                        widths: [ 30, 150, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50 ],
                        body: []
                    }
                },
            ],
            styles: {
                headerTitle: {
                    bold: true,
                    fontSize: 7,
                    lineHeight: 1.2
                },
                table: {
                    layout: 'headerLineOnly',
                    fontSize: 6,
                    alignment: 'center'
                },
                subTable: {
                    layout: 'headerLineOnly',
                    fontSize: 6,
                    alignment: 'left'
                },
                tableDetailLeft: {
                    layout: 'headerLineOnly',
                    fontSize: 6,
                    alignment: 'left'
                },
                tableDetailRight: {
                    layout: 'headerLineOnly',
                    fontSize: 6,
                    alignment: 'right'
                },
                tableDetailRightBold: {
                    layout: 'headerLineOnly',
                    fontSize: 6,
                    alignment: 'right',
                    bold: true
                }
            },
            defaultStyle: {
                columnGap: 20,
            },
        }

        const tableBody: any = [
            [ 
                { text: 'CUENTA', style: 'table', colSpan: 1, border: [true, true, false, false] },
                { text: 'DESCRIPCION', style: 'table', colSpan: 1, border: [true, true, false, false] },
                { text: 'SUMAS', style: 'table', colSpan: 2, border: [true, true, false, false] },{},
                { text: 'SALDOS', style: 'table', colSpan: 2, border: [true, true, false, false] },{},
                { text: 'INVENTARIOS', style: 'table', colSpan: 2, border: [true, true, false, false] },{},
                { text: 'RESULTADOS POR NATURALEZA', style: 'table', colSpan: 2, border: [true, true, false, false] },{},
                { text: 'RESULTADOS POR FUNCION', style: 'table', colSpan: 2, border: [true, true, true, false] },{},
            ],
            [ 
                { text: '', style: 'subTable', border: [true, false, false, true] },
                
                { text: '', style: 'table', border: [true, false, false, true] },
                
                { text: 'DEBE', style: 'table', border: [true, false, false, true] },
                { text: 'HABER', style: 'table', border: [false, false, false, true] },
                
                { text: 'DEUDOR', style: 'table', border: [true, false, false, true] },
                { text: 'ACREEDOR', style: 'table', border: [false, false, false, true] },
                
                { text: 'ACTIVO', style: 'table', border: [true, false, false, true] },
                { text: 'PASIVO', style: 'table', border: [false, false, false, true] },
                
                { text: 'PERDIDA', style: 'table', border: [true, false, false, true] },
                { text: 'GANANCIA', style: 'table', border: [false, false, false, true] },
                
                { text: 'PERDIDA', style: 'table', border: [true, false, false, true] },
                { text: 'GANANCIA', style: 'table', border: [false, false, true, true] },
            ],
        ];

        let col1 = 0, col2 = 0, col3 = 0, col4 = 0, col5 = 0, col6 = 0, col7 = 0, col8 = 0, col9 = 0, col10 = 0;
        
        for (const row of data) {

            const diff = row.totalbal;
            col1 += row.debitamo;
            col2 += row.assetamo;
            col3 += diff > 0 ? Math.abs(diff) : 0;
            col4 += diff < 0 ? Math.abs(diff) : 0;
            col5 += row.codactyp == 1 ? (diff > 0 ? Math.abs(diff) : 0) : 0;
            col6 += row.codactyp == 1 ? (diff < 0 ? Math.abs(diff) : 0) : 0;
            col7 += row.codactyp == 2 ? (diff > 0 ? Math.abs(diff) : 0) : 0;
            col8 += row.codactyp == 2 ? (diff < 0 ? Math.abs(diff) : 0) : 0;
            col9 += row.codactyp == 3 ? (diff > 0 ? Math.abs(diff) : 0) : 0;
            col10 += row.codactyp == 3 ? (diff < 0 ? Math.abs(diff) : 0) : 0;

            tableBody.push(
                [ 
                    { text: row.accaccou, style: 'tableDetailLeft', border: [false, false, false, false] },
                    
                    { text: row.denomina.substr(0, 30), style: 'tableDetailLeft', border: [false, false, false, false] },
                    
                    { text: String.formatNumber.new(row.debitamo), style: 'tableDetailRight', border: [false, false, false, false] },
                    { text: String.formatNumber.new(row.assetamo), style: 'tableDetailRight', border: [false, false, false, false] },
                    
                    { text: diff > 0 ? String.formatNumber.new(Math.abs(diff)) : '', style: 'tableDetailRight', border: [false, false, false, false] },
                    { text: diff < 0 ? String.formatNumber.new(Math.abs(diff)) : '', style: 'tableDetailRight', border: [false, false, false, false] },
                    
                    { text: row.codactyp == 1 ? (diff > 0 ? String.formatNumber.new(Math.abs(diff)) : '') : '', style: 'tableDetailRight', border: [false, false, false, false] },
                    { text: row.codactyp == 1 ? (diff < 0 ? String.formatNumber.new(Math.abs(diff)) : '') : '', style: 'tableDetailRight', border: [false, false, false, false] },
                    
                    { text: row.codactyp == 2 ? (diff > 0 ? String.formatNumber.new(Math.abs(diff)) : '') : '', style: 'tableDetailRight', border: [false, false, false, false] },
                    { text: row.codactyp == 2 ? (diff < 0 ? String.formatNumber.new(Math.abs(diff)) : '') : '', style: 'tableDetailRight', border: [false, false, false, false] },
                    
                    { text: row.codactyp == 3 ? (diff > 0 ? String.formatNumber.new(Math.abs(diff)) : '') : '', style: 'tableDetailRight', border: [false, false, false, false] },
                    { text: row.codactyp == 3 ? (diff < 0 ? String.formatNumber.new(Math.abs(diff)) : '') : '', style: 'tableDetailRight', border: [false, false, false, false] },
                ]
            )
        }

        tableBody.push(
            [ 
                { text: 'TOTALES', style: 'table', characterSpacing: 3, colSpan: 2, border: [true, true, false, true] },{},
                
                { text: String.formatNumber.new(col1), style: 'tableDetailRight', border: [true, true, false, true] },
                { text: String.formatNumber.new(col2), style: 'tableDetailRight', border: [false, true, false, true] },
                
                { text: String.formatNumber.new(col3), style: 'tableDetailRight', border: [false, true, false, true] },
                { text: String.formatNumber.new(col4), style: 'tableDetailRight', border: [false, true, false, true] },
                
                { text: String.formatNumber.new(col5), style: 'tableDetailRight', border: [false, true, false, true] },
                { text: String.formatNumber.new(col6), style: 'tableDetailRight', border: [false, true, false, true] },
                
                { text: String.formatNumber.new(col7), style: 'tableDetailRight', border: [false, true, false, true] },
                { text: String.formatNumber.new(col8), style: 'tableDetailRight', border: [false, true, false, true] },
                
                { text: String.formatNumber.new(col9), style: 'tableDetailRight', border: [false, true, false, true] },
                { text: String.formatNumber.new(col10), style: 'tableDetailRight', border: [false, true, true, true] },
            ],
        )

        const util1 = col5 - col6;
        const util2 = col7 - col8;
        const util3 = col9 - col10;
        col5 += util1 < 0 ? Math.abs(util1) : 0;
        col6 += util1 > 0 ? Math.abs(util1) : 0;
        col7 += util2 < 0 ? Math.abs(util2) : 0;
        col8 += util2 > 0 ? Math.abs(util2) : 0;
        col9 += util3 < 0 ? Math.abs(util3) : 0;
        col10 += util3 > 0 ? Math.abs(util3) : 0;

        tableBody.push(
            [ 
                { text: '', style: 'table', characterSpacing: 3, colSpan: 2, border: [false, false, false, false] },{},
                
                { text: '', style: 'tableDetailRight', border: [false, false, false, false] },
                { text: '', style: 'tableDetailRight', border: [false, false, false, false] },
                
                { text: '', style: 'tableDetailRight', border: [false, false, false, false] },
                { text: 'UTILIDAD', style: 'tableDetailLeft', border: [true, false, false, true] },
                
                { text: util1 < 0 ? String.formatNumber.new(Math.abs(util1)) : '', style: 'tableDetailRight', border: [false, false, false, true] },
                { text: util1 > 0 ? String.formatNumber.new(Math.abs(util1)) : '', style: 'tableDetailRight', border: [false, false, false, true] },
                
                { text: util2 < 0 ? String.formatNumber.new(Math.abs(util2)) : '', style: 'tableDetailRight', border: [false, false, false, true] },
                { text: util2 > 0 ? String.formatNumber.new(Math.abs(util2)) : '', style: 'tableDetailRight', border: [false, false, false, true] },
                
                { text: util3 < 0 ? String.formatNumber.new(Math.abs(util3)) : '', style: 'tableDetailRight', border: [false, false, false, true] },
                { text: util3 > 0 ? String.formatNumber.new(Math.abs(util3)) : '', style: 'tableDetailRight', border: [false, false, true, true] },
            ],
            [ 
                { text: '', style: 'table', characterSpacing: 3, colSpan: 2, border: [false, false, false, false] },{},
                
                { text: '', style: 'tableDetailRight', border: [false, false, false, false] },
                { text: '', style: 'tableDetailRight', border: [false, false, false, false] },
                
                { text: '', style: 'tableDetailRight', border: [false, false, false, false] },
                { text: 'TOTALES', style: 'tableDetailLeft', border: [true, false, false, true] },
                
                { text: String.formatNumber.new(col5), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(col6), style: 'tableDetailRight', border: [false, false, false, true] },
                
                { text: String.formatNumber.new(col7), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(col8), style: 'tableDetailRight', border: [false, false, false, true] },
                
                { text: String.formatNumber.new(col9), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(col10), style: 'tableDetailRight', border: [false, false, true, true] },
            ]
        )

        pdfObject.content[0].table.body = tableBody;

        pdfMake.fonts = {
            Roboto: {
                normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
                bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
                italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
                bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
              },
        }
        const pdfDocGenerator = pdfMake.createPdf(pdfObject);
        pdfDocGenerator.getDataUrl((data: any) => {
            // console.log(data);
            this.setState({
                base64Pdf: data
            })
        });

    }

    onGenerateExcel = () => {
        try {
            const data = this.props.accountantReducers.onlyOne;
            const { month, year, level } = this.state;
            const { dashboardReducers } = this.props;
            const mes = meses.find((x: any) => x.key === month).value;
            const company = (JSON.parse(localStorage.getItem('tables')).companys.find((x: any) => x.taxident === dashboardReducers.currentCompany).bussname as string).toUpperCase();

            const tableBody: any = [
                { 
                    A: company,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null,
                    G: null,
                    H: null,
                    I: null,
                    J: null,
                    K: null,
                    L: null
                },
                { 
                    A:`HOJA DE TRABAJO ${mes.toUpperCase()}-${year} MENSUAL`,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null,
                    G: null,
                    H: null,
                    I: null,
                    J: null,
                    K: null,
                    L: null
                },
                { 
                    A: `NIVEL MAXIMO: ${level}`,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null,
                    G: null,
                    H: null,
                    I: null,
                    J: null,
                    K: null,
                    L: null
                },
                { 
                    A: null,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null,
                    G: null,
                    H: null,
                    I: null,
                    J: null,
                    K: null,
                    L: null
                },
                { 
                    A: 'CUENTA',
                    B: 'DESCRIPCION',
                    C: 'SUMAS',
                    D: '',
                    E: 'SALDOS',
                    F: '',
                    G: 'INVENTARIOS',
                    H: '',
                    I: 'RESULTADOS POR NATURALEZA',
                    J: '',
                    K: 'RESULTADOS POR FUNCION',
                    L: ''
                },
                { 
                    A: '',
                    B: '',
                    C: 'DEBE',
                    D: 'HABER',
                    E: 'DEUDOR',
                    F: 'ACREEDOR',
                    G: 'ACTIVO',
                    H: 'PASIVO',
                    I: 'PERDIDA',
                    J: 'GANANCIA',
                    K: 'PERDIDA',
                    L: 'GANANCIA',
                },
            ];

            let col1 = 0, col2 = 0, col3 = 0, col4 = 0, col5 = 0, col6 = 0, col7 = 0, col8 = 0, col9 = 0, col10 = 0;
        
            for (const row of data) {

                const diff = row.totalbal;
                col1 += row.debitamo;
                col2 += row.assetamo;
                col3 += diff > 0 ? Math.abs(diff) : 0;
                col4 += diff < 0 ? Math.abs(diff) : 0;
                col5 += row.codactyp == 1 ? (diff > 0 ? Math.abs(diff) : 0) : 0;
                col6 += row.codactyp == 1 ? (diff < 0 ? Math.abs(diff) : 0) : 0;
                col7 += row.codactyp == 2 ? (diff > 0 ? Math.abs(diff) : 0) : 0;
                col8 += row.codactyp == 2 ? (diff < 0 ? Math.abs(diff) : 0) : 0;
                col9 += row.codactyp == 3 ? (diff > 0 ? Math.abs(diff) : 0) : 0;
                col10 += row.codactyp == 3 ? (diff < 0 ? Math.abs(diff) : 0) : 0;

                tableBody.push(
                    { 
                        A: row.accaccou,
                        B: row.denomina.substr(0, 30),
                        C: String.formatNumber.new(row.debitamo),
                        D: String.formatNumber.new(row.assetamo),
                        E: diff > 0 ? String.formatNumber.new(Math.abs(diff)) : '',
                        F: diff < 0 ? String.formatNumber.new(Math.abs(diff)) : '',
                        G: row.codactyp == 1 ? (diff > 0 ? String.formatNumber.new(Math.abs(diff)) : '') : '',
                        H: row.codactyp == 1 ? (diff < 0 ? String.formatNumber.new(Math.abs(diff)) : '') : '',
                        I: row.codactyp == 2 ? (diff > 0 ? String.formatNumber.new(Math.abs(diff)) : '') : '',
                        J: row.codactyp == 2 ? (diff < 0 ? String.formatNumber.new(Math.abs(diff)) : '') : '',
                        K: row.codactyp == 3 ? (diff > 0 ? String.formatNumber.new(Math.abs(diff)) : '') : '',
                        L: row.codactyp == 3 ? (diff < 0 ? String.formatNumber.new(Math.abs(diff)) : '') : '',
                    }
                )
            }

            tableBody.push(
                { 
                    A: 'TOTALES',
                    B: '',
                    C: String.formatNumber.new(col1),
                    D: String.formatNumber.new(col2),
                    E: String.formatNumber.new(col3),
                    F: String.formatNumber.new(col4),
                    G: String.formatNumber.new(col5),
                    H: String.formatNumber.new(col6),
                    I: String.formatNumber.new(col7),
                    J: String.formatNumber.new(col8),
                    K: String.formatNumber.new(col9),
                    L: String.formatNumber.new(col10)
                }
            )

            const util1 = col5 - col6;
            const util2 = col7 - col8;
            const util3 = col9 - col10;
            col5 += util1 < 0 ? Math.abs(util1) : 0;
            col6 += util1 > 0 ? Math.abs(util1) : 0;
            col7 += util2 < 0 ? Math.abs(util2) : 0;
            col8 += util2 > 0 ? Math.abs(util2) : 0;
            col9 += util3 < 0 ? Math.abs(util3) : 0;
            col10 += util3 > 0 ? Math.abs(util3) : 0;

            tableBody.push(
                { 
                    A: '',
                    B: '',
                    C: '',
                    D: '',
                    E: '',
                    F: 'UTILIDAD',
                    G: util1 < 0 ? String.formatNumber.new(Math.abs(util1)) : '',
                    H: util1 > 0 ? String.formatNumber.new(Math.abs(util1)) : '',
                    I: util2 < 0 ? String.formatNumber.new(Math.abs(util2)) : '',
                    J: util2 > 0 ? String.formatNumber.new(Math.abs(util2)) : '',
                    K: util3 < 0 ? String.formatNumber.new(Math.abs(util3)) : '',
                    L: util3 > 0 ? String.formatNumber.new(Math.abs(util3)) : '',
                },
                {
                    A: '',
                    B: '',
                    C: '',
                    D: '',
                    E: '',
                    F: 'TOTALES',
                    G: String.formatNumber.new(col5),
                    H: String.formatNumber.new(col6),
                    I: String.formatNumber.new(col7),
                    J: String.formatNumber.new(col8),
                    K: String.formatNumber.new(col9),
                    L: String.formatNumber.new(col10)
                }
            )
            
            const header = [
                'A',
                'B',
                'C',
                'D',
                'E',
                'F',
                'G',
                'H',
                'I',
                'J',
                'K',
                'L'
            ];

            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(tableBody, {header, skipHeader: true});
            ws["!cols"] = [
                {wpx:50},{wpx:200},{wpx:70},{wpx:70},{wpx:70},{wpx:70},{wpx:70},{wpx:70},{wpx:70},{wpx:70},{wpx:70},{wpx:70}
            ];
            ws['!rows'] = [
                {hpx: 16},{hpx: 18},{hpx: 16},{hpx: 16},{hpx: 16}
            ];

            const keys = Object.keys(ws);
            const rowsCount = tableBody.length;

            for (const iterator of keys) {
                if(!isNaN(parseInt(ws[iterator].v)) && !iterator.match(/^(A|B)[0-9]+$/g)) {
                    ws[iterator].v = ws[iterator].v.replace(/,/g, '');
                    ws[iterator].t = 'n';
                    ws[iterator].z = '#,##0.00';
                }
                if(iterator !== '!ref' && iterator !== '!cols' && iterator !== '!rows') {
                    ws[iterator].s = {
                        font: {
                            name: 'Tahoma',
                            sz: 8
                        }
                    }
                    const row = +(iterator.match(/\d+/)[0]);
                    if(row === (rowsCount - 2)) {
                        ws[iterator].s.border = {};
                        if(iterator.match(/^(A)[0-9]+$/g)) {
                            ws[iterator].s.alignment = { wrapText: true, vertical: 'center', horizontal: 'center'};
                            ws[iterator].s.border.left = { style: 'thin', color: 'black'};
                        }
                        if(iterator.match(/^(C)[0-9]+$/g))
                            ws[iterator].s.border.left = { style: 'thin', color: 'black'};
                        if(iterator.match(/^(L)[0-9]+$/g))
                            ws[iterator].s.border.right = { style: 'thin', color: 'black'};
                        ws[iterator].s.border = {
                            ...ws[iterator].s.border,
                            bottom: { style: 'thin', color: 'black'},
                            top: { style: 'thin', color: 'black'}
                        }
                    }
                    if(row > (rowsCount - 2) && !iterator.match(/^(A|B|C|D|E)[0-9]+$/g)) {
                        ws[iterator].s.border = {};
                        if(iterator.match(/^(F)[0-9]+$/g))
                            ws[iterator].s.border.left = { style: 'thin', color: 'black'};
                        if(iterator.match(/^(L)[0-9]+$/g))
                            ws[iterator].s.border.right = { style: 'thin', color: 'black'};
                        ws[iterator].s.border.bottom = { style: 'thin', color: 'black'};
                        ws[iterator].s.border.top = { style: 'thin', color: 'black'};
                    }
                }
            }

            for (let index = 7; index <= rowsCount; index++) {
                ws['!rows'].push({hpx: 12})
            }

            ws['A1'].s = {font:{name: 'Tahoma', sz: 8, bold: true}}
            ws['A2'].s = {font:{name: 'Tahoma', sz: 15, bold: true}, alignment: { wrapText: true, vertical: 'center', horizontal: 'center'}}
            ws['A3'].s = {font:{name: 'Tahoma', sz: 8, bold: true}}
            
            for (const iterator of header) {
                const key1 = iterator + "5";
                const key2 = iterator + "6";
                ws[key1].s = {
                    ...ws[key1].s, 
                    alignment: { wrapText: true, vertical: 'center', horizontal: 'center'},
                    border: { 
                        top: { style: 'thin', color: 'black'},
                        bottom: { style: 'thin', color: 'black'},
                        left: { style: 'thin', color: 'black'},
                        right: { style: 'thin', color: 'black'}
                    } 
                }
                ws[key2].s = {
                    ...ws[key2].s, 
                    alignment: { wrapText: true, vertical: 'center', horizontal: 'center'},
                    border: { 
                        top: { style: 'thin', color: 'black'},
                        bottom: { style: 'thin', color: 'black'},
                        left: { style: 'thin', color: 'black'},
                        right: { style: 'thin', color: 'black'}
                    } 
                }
            };

            ws["!merges"] = [
                { s: { r: 1, c: 0 }, e: { r: 1, c: 11 } },
                { s: { r: 4, c: 0 }, e: { r: 5, c: 0 } },
                { s: { r: 4, c: 1 }, e: { r: 5, c: 1 } },
                { s: { r: 4, c: 2 }, e: { r: 4, c: 3 } },
                { s: { r: 4, c: 4 }, e: { r: 4, c: 5 } },
                { s: { r: 4, c: 6 }, e: { r: 4, c: 7 } },
                { s: { r: 4, c: 8 }, e: { r: 4, c: 9 } },
                { s: { r: 4, c: 10 }, e: { r: 4, c: 11 } },
                { s: { r: rowsCount - 3, c: 0 }, e: { r: rowsCount - 3, c: 1 } }
            ];

            XLSX.utils.book_append_sheet(wb, ws, "Hoja de Trabajo");

            var wbout = XLSX.write(wb, {bookType:'xlsx', type: 'binary', cellStyles: true});

            const s2ab = function(s) {
                var buf = new ArrayBuffer(s.length);
                var view = new Uint8Array(buf);
                for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
                return buf;
            }

            saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), `hoja-trabajo-${year}-${month}.xlsx`);

        } catch(err) {
            console.log(err);
        }
    }

    render() {

        const { title, year, month, level, accumulated, base64Pdf } = this.state;
        const { loading, list, tables, failed, failedTitle, failedContent } = this.props.accountantReducers;
        const { classes } = this.props;

        return (
            <div style={{overflow: 'auto', height: '100%'}}>
                <Paper className='form__container'>
                    <Typography variant='h5'>{ title }</Typography>
                    <br/>
                    { failed && <MessageBox title={failedTitle} content={failedContent}/> }
                    <div style={{ display: 'flex' }}>
                        <form noValidate autoComplete="off" style={{ flex: 1, paddingRight: '15px', paddingTop: '2em' }}>
                            <Grid container spacing={2} >
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_año">Año</InputLabel>
                                            <Select
                                                labelId="lbl_año"
                                                id="year"
                                                className='select'
                                                label="Año"
                                                fullWidth
                                                value={ year }
                                                onChange={(e: any) => this.onChangeRoot({'year': e.target.value})}
                                            >
                                                <MenuItem value='2030'>2030</MenuItem>
                                                <MenuItem value='2029'>2029</MenuItem>
                                                <MenuItem value='2028'>2028</MenuItem>
                                                <MenuItem value='2027'>2027</MenuItem>
                                                <MenuItem value='2026'>2026</MenuItem>
                                                <MenuItem value='2025'>2025</MenuItem>
                                                <MenuItem value='2026'>2026</MenuItem>
                                                <MenuItem value='2025'>2025</MenuItem>
                                                <MenuItem value='2024'>2024</MenuItem>
                                                <MenuItem value='2023'>2023</MenuItem>
                                                <MenuItem value='2022'>2022</MenuItem>
                                                <MenuItem value='2021'>2021</MenuItem>
                                                <MenuItem value='2020'>2020</MenuItem>
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_mesi">Mes</InputLabel>
                                            <Select
                                                labelId="lbl_mes"
                                                id="month"
                                                className='select'
                                                label="Mes"
                                                fullWidth
                                                value={ month }
                                                onChange={(e: any) => this.onChangeRoot({'month': e.target.value})}
                                            >
                                                {
                                                    meses.map((x: any) => 
                                                        <MenuItem value={x.key} key={x.key}>{x.value}</MenuItem>
                                                    )
                                                }
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_nivel">Nivel</InputLabel>
                                            <Select
                                                labelId="lbl_nivel"
                                                id="level"
                                                className='select'
                                                label="Nivel"
                                                fullWidth
                                                value={ level }
                                                onChange={(e: any) => this.onChangeRoot({'level': e.target.value})}
                                            >
                                                <MenuItem value='1'>1</MenuItem>
                                                <MenuItem value='2'>2</MenuItem>
                                                <MenuItem value='3'>3</MenuItem>
                                                <MenuItem value='4'>4</MenuItem>
                                                <MenuItem value='5'>5</MenuItem>
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={accumulated}
                                                onChange={(e: any) => this.onChangeRoot({'accumulated': e.target.checked})}
                                                name="accumulated"
                                                color="primary"
                                            />
                                        }
                                        label="Acumulado"
                                    />
                                </Grid>
                                <Grid item xs={12} style={{ paddingTop: '50px' }}>
                                    <Button variant="contained" fullWidth color="primary" onClick={() => { 
                                        const balacode = [];
                                        if(accumulated)
                                            for(let i = 0; i <= +month; i++) {
                                                balacode.push(`${year}${i.toString().padStart(2, '0')}`)
                                            }
                                        else {
                                            balacode.push(`${year}${month.toString().padStart(2, '0')}`)
                                        }
                                        this.props.worksheetRequest(year, balacode, level, this.onGeneratePdf) 
                                    }}>
                                        Generar Reporte PDF
                                    </Button>
                                </Grid>
                                <Grid item xs={12} style={{ paddingTop: '10px' }}>
                                    <Button variant="contained" fullWidth color="inherit" onClick={() => { 
                                        const balacode = [];
                                        if(accumulated)
                                            for(let i = 0; i <= +month; i++) {
                                                balacode.push(`${year}${i.toString().padStart(2, '0')}`)
                                            }
                                        else {
                                            balacode.push(`${year}${month.toString().padStart(2, '0')}`)
                                        }
                                        this.props.worksheetRequest(year, balacode, level, this.onGenerateExcel) 
                                    }}>
                                        Generar Reporte EXCEL
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                        <PdfView data={base64Pdf} style={{ flex: 2 }}/>
                    </div>
                    <br/>
                    {/* <FormFooter onCancel={ this.onCancel } onSubmit={ this.onSubmit }/> */}
                </Paper>
                <ScreenLoader enabled={loading}/>
            </div>
        );
    }
}

const accountantReducers = 'accountantReducers';
const dashboardReducers = 'dashboardReducers';

const mapStateToProps = (state: any) => ({
    accountantReducers: state.get(accountantReducers),
    dashboardReducers: state.get(dashboardReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        worksheetRequest
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(ReportGeneralBalancesAccount));