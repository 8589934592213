import React from 'react';
import { Button, createStyles, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Paper, Select, Switch, TextField, Theme, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { payrollInitFormRequest, employeVoucherRequest, employeVoucherAllRequest } from 'penta-redux/actions/humanResourcesActions';
import { MessageBox } from 'penta-components/base/messageBox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import * as XLSX from 'sheetjs-style';
import ScreenLoader from 'penta-components/base/screenLoader';

let pdfMake = require("pdfmake/build/pdfmake");
let saveAs = require('file-saver').saveAs;

const meses = [
    { key: '01', value: 'Enero' },
    { key: '02', value: 'Febrero' },
    { key: '03', value: 'Marzo' },
    { key: '04', value: 'Abril' },
    { key: '05', value: 'Mayo' },
    { key: '06', value: 'Junio' },
    { key: '07', value: 'Julio' },
    { key: '08', value: 'Agosto' },
    { key: '09', value: 'Septiembre' },
    { key: '10', value: 'Octubre' },
    { key: '11', value: 'Noviembre' },
    { key: '12', value: 'Diciembre' }
]

const useStyles = (theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        formControl: {
            margin: 0,
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    });

interface FormState {
    title: string
    emplcode: string
    nameeess: string
    year: string
    month: string
    week: string
    payrtype: string
    typesala: string
    base64Pdf: string
    allFlag: boolean
}

class ReportFifthCategoryCalculation extends React.Component<any, FormState> {

    constructor(props: any) {
        super(props);
        this.state = {
            title: 'Calculo 5ta categoria',
            emplcode: '',
            nameeess: '',
            year: moment().format('yyyy').toString(),
            month: moment().format('MM').toString(),
            week: moment().week().toString(),
            payrtype: '101',
            typesala: '001',
            base64Pdf: '',
            allFlag: true
        }
    }

    onChangeRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            ...value
        }))
    }

    onGenerateExcel = () => {
        try {
            const { prl_employee, prl_concepts, PreviousRenumbering, prl_definiti, PreviousGratification } = this.props.humanResourcesReducers.tables;
            const { month, year, typesala, payrtype } = this.state;
            const { dashboardReducers } = this.props;
            const mes = meses.find((x: any) => x.key === month).value;
            const tables = JSON.parse(localStorage.getItem('tables'));
            const company = tables.companys.find((x: any) => x.taxident === dashboardReducers.currentCompany);
            const division = company.lst_grl_division.find((x: any) => x.divicode === dashboardReducers.currentDivision);

            const tableBody: any = [
                { 
                    A: 'SERVICENTRO LAS AMARICAS',
                    B: null,
                    C: null,
                    D: null,
                },
                { 
                    A: `CALCULO DE QUINTA DEL MES DE ${mes.toUpperCase()} ${year}`,
                    B: null,
                    C: null,
                    D: null,
                },
                { 
                    A: null,
                    B: null,
                    C: null,
                    D: null,
                }
            ];

            const uit = 4600;
            const cutRows: Array<number> = [];
            // const concepts = prl_concepts.filter((x: any) => x.typesala === typesala && x.payrtype === payrtype);
            for (const iterator of prl_employee) {
                // let base = +JSON.parse(iterator.prl_emplrenu.renumber).renumber1;
                let base = +(PreviousRenumbering.find(x => x.emplcode === iterator.emplcode && x.payrmont == month)?.basalary || '0');
                if (base < 100) base = base*30;
                const asignmen = JSON.parse(iterator.prl_emplrela.relative).find((x: any) => moment().year() - moment(x.nacimiento).year() <= 18 ) !== undefined ? '520' : '521';

                const sueldoMinimoVital = +month <= 4 ? 930 : 1025; //parseFloat(prl_definiti.find((x) => x.deficode === '500').defivalu);
                const asigFamiliarBase = asignmen !== '520' ? 0 : sueldoMinimoVital * 0.1;
                // const asigFamiliarBase = asignmen !== '520' ? 0 : +(PreviousRenumbering.find(x => x.emplcode === iterator.emplcode && x.conccode === 'C202' && x.payrmont == month)?.outcomee || sueldoMinimoVital * 0.1);
                // const diasTrabajados = +concepts.find((x) => x.conccode === 'C103').outcomee;
                const totalIngresosPeriodo = +(PreviousRenumbering.find(x => x.emplcode === iterator.emplcode && x.conccode === 'C501' && x.payrmont == month)?.outcomee || '0');

                const currentMonth = +month;
                const currentYear = +year;

                const bonificacionExtraordinaria = +(PreviousGratification.find(x => x.emplcode === iterator.emplcode && x.conccode === 'C203' && x.payryear == currentYear && x.payrmont == (currentMonth >= 7 ? '07' : '12'))?.outcomee * 0.09 || '0');

                const sueldoMensual1 = base + asigFamiliarBase;

                const mesesRestantes = { 1: 11, 2: 10, 3: 9, 4: 8, 5: 7, 6: 6, 7: 5, 8: 4, 9: 3, 10: 2, 11: 1, 12: 0 };
                const totalRenumProyect = sueldoMensual1 * mesesRestantes[`${currentMonth}`];

                const employee = prl_employee.find((x) => x.emplcode === iterator.emplcode);
                const fechaIngreso = moment(employee.prl_emplojob.dateadmi);
                const fechaJulio = moment(moment(`${currentYear}0701`).format('YYYY-MM-') + moment(`${currentYear}0701`).daysInMonth());
                let diasComputadosJulio = fechaJulio.diff(fechaIngreso, 'days');
                diasComputadosJulio = diasComputadosJulio > 180 ? 180 : diasComputadosJulio;
                const gratificacionJulio = sueldoMensual1 / 180 * diasComputadosJulio;

                const fechaDiciembre = moment(moment(`${currentYear}1201`).format('YYYY-MM-') + moment(`${currentYear}1201`).daysInMonth());
                let diasComputadosDiciembre = fechaDiciembre.diff(fechaIngreso, 'days');
                diasComputadosDiciembre = diasComputadosDiciembre > 180 ? 180 : diasComputadosDiciembre;
                const gratificacionDiciembre = sueldoMensual1 / 180 * diasComputadosDiciembre;

                const utiBonOtros = 0;

                const totalRenumeAnteriores = PreviousRenumbering.filter(x => x.emplcode === iterator.emplcode && (+x.payrmont < currentMonth) && (['C501'].some(y => y === x.conccode))).map(x => +x.outcomee).reduce((x, y) => x + y, 0);

                // const bonificacionExtraordinaria = (currentMonth >= 7 ? gratificacionJulio : gratificacionDiciembre) * 0.09;

                const renumeracionBrutaAnual = totalIngresosPeriodo + totalRenumProyect + gratificacionJulio + gratificacionDiciembre + utiBonOtros + totalRenumeAnteriores + bonificacionExtraordinaria;

                const deduccion7UIT = uit * 7;

                const renumeracionNetaAnual = renumeracionBrutaAnual - deduccion7UIT;

                // debugger;

                // if(renumeracionNetaAnual < 0) continue;

                const tazas = {'5': 0.08, '20': 0.14, '35': 0.17, '45': 0.2, '00': 0.3};
                const maximo = {'5': uit*5, '20': uit*20, '35': uit*35, '45': uit*45, '00': uit*45};
                const montoAfecto = {'5': 0, '20': 0, '35': 0, '45': 0, '00': 0};
                montoAfecto['5'] = renumeracionBrutaAnual <= deduccion7UIT ? 0 : (renumeracionNetaAnual <= maximo['5'] ? renumeracionNetaAnual :  maximo['5']);
                montoAfecto['20'] = (renumeracionNetaAnual - montoAfecto['5']) <= 0 ? 0 : (renumeracionNetaAnual <= maximo['20'] ? renumeracionNetaAnual - montoAfecto['5'] : maximo['20'] - montoAfecto['5']);
                montoAfecto['35'] = (renumeracionNetaAnual - (montoAfecto['5'] + montoAfecto['20'])) <= 0 ? 0 : (renumeracionNetaAnual <= maximo['35'] ? renumeracionNetaAnual - (montoAfecto['5'] + montoAfecto['20']) : maximo['35'] - montoAfecto['20'] - montoAfecto['5']);
                montoAfecto['45'] = (renumeracionNetaAnual - (montoAfecto['5'] + montoAfecto['20'] + montoAfecto['35'])) <= 0 ? 0 : (renumeracionNetaAnual <= maximo['45'] ? renumeracionNetaAnual - (montoAfecto['5'] + montoAfecto['20'] + montoAfecto['35']) : maximo['45'] - montoAfecto['35'] - montoAfecto['20'] - montoAfecto['5']);
                montoAfecto['00'] = renumeracionNetaAnual > maximo['45'] ? renumeracionNetaAnual - maximo['45'] : 0;

                const impuesto = {'5': 0, '20': 0, '35': 0, '45': 0, '00': 0};
                impuesto['5'] = tazas['5'] * montoAfecto['5'];
                impuesto['20'] = tazas['20'] * montoAfecto['20'];
                impuesto['35'] = tazas['35'] * montoAfecto['35'];
                impuesto['45'] = tazas['45'] * montoAfecto['45'];
                impuesto['00'] = tazas['00'] * montoAfecto['00'];

                const totalImpuesto = impuesto['5'] + impuesto['20'] + impuesto['35'] + impuesto['45'] + impuesto['00'];

                let totalQuintaAnteriores = 0;
                const quintaTemp = PreviousRenumbering.filter(x => x.emplcode === iterator.emplcode && x.conccode === 'C305');
                if (currentMonth >= 11) totalQuintaAnteriores = quintaTemp.filter(x => ['01','02','03','04','05','06','07','08','09','10','11'].some(y => y === x.payrmont)).map(x => +x.outcomee).reduce((x, y) => x + y, 0);
                else if (currentMonth >= 9) totalQuintaAnteriores = quintaTemp.filter(x => ['01','02','03','04','05','06','07','08'].some(y => y === x.payrmont)).map(x => +x.outcomee).reduce((x, y) => x + y, 0);
                else if (currentMonth >= 8) totalQuintaAnteriores = quintaTemp.filter(x => ['01','02','03','04','05','06','07'].some(y => y === x.payrmont)).map(x => +x.outcomee).reduce((x, y) => x + y, 0);
                else if (currentMonth >= 5) totalQuintaAnteriores = quintaTemp.filter(x => ['01','02','03','04'].some(y => y === x.payrmont)).map(x => +x.outcomee).reduce((x, y) => x + y, 0);
                else if (currentMonth >= 4) totalQuintaAnteriores = quintaTemp.filter(x => ['01','02','03'].some(y => y === x.payrmont)).map(x => +x.outcomee).reduce((x, y) => x + y, 0);

                const totalImpuestoProyectado = totalImpuesto - totalQuintaAnteriores;

                const retencionMensual = { 1: 12, 2: 12, 3: 12, 4: 9, 5: 8, 6: 8, 7: 8, 8: 5, 9: 4, 10: 4, 11: 4, 12: 1 };
                const totalRetencionMensual = totalImpuestoProyectado / retencionMensual[`${currentMonth}`];

                let totalRenumeracionNetaAnual = 0;
                const montoAfectoSegTramo = {'5': 0, '20': 0, '35': 0, '45': 0, '00': 0};
                const impuestoSegTramo = {'5': 0, '20': 0, '35': 0, '45': 0, '00': 0};
                let totalImpuestoSegtramo = 0;
                let retencionConceptosExtraordinarios = 0;
                if (currentMonth === 7 || currentMonth === 12) {
                    totalRenumeracionNetaAnual = renumeracionNetaAnual + bonificacionExtraordinaria;
                    montoAfectoSegTramo['5'] = renumeracionBrutaAnual <= deduccion7UIT ? 0 : (totalRenumeracionNetaAnual <= maximo['5'] ? totalRenumeracionNetaAnual :  maximo['5']);
                    montoAfectoSegTramo['20'] = (totalRenumeracionNetaAnual - montoAfectoSegTramo['5']) <= 0 ? 0 : (totalRenumeracionNetaAnual <= maximo['20'] ? totalRenumeracionNetaAnual - montoAfectoSegTramo['5'] : maximo['20'] - montoAfectoSegTramo['5']);
                    montoAfectoSegTramo['35'] = (totalRenumeracionNetaAnual - (montoAfectoSegTramo['5'] + montoAfectoSegTramo['20'])) <= 0 ? 0 : (totalRenumeracionNetaAnual <= maximo['35'] ? totalRenumeracionNetaAnual - (montoAfectoSegTramo['5'] + montoAfectoSegTramo['20']) : maximo['35'] - montoAfectoSegTramo['20'] - montoAfectoSegTramo['5']);
                    montoAfectoSegTramo['45'] = (totalRenumeracionNetaAnual - (montoAfectoSegTramo['5'] + montoAfectoSegTramo['20'] + montoAfectoSegTramo['35'])) <= 0 ? 0 : (totalRenumeracionNetaAnual <= maximo['45'] ? totalRenumeracionNetaAnual - (montoAfectoSegTramo['5'] + montoAfectoSegTramo['20'] + montoAfectoSegTramo['35']) : maximo['45'] - montoAfectoSegTramo['35'] - montoAfectoSegTramo['20'] - montoAfectoSegTramo['5']);
                    montoAfectoSegTramo['00'] = totalRenumeracionNetaAnual > maximo['45'] ? totalRenumeracionNetaAnual - maximo['45'] : 0;
                    impuestoSegTramo['5'] = tazas['5'] * montoAfectoSegTramo['5'];
                    impuestoSegTramo['20'] = tazas['20'] * montoAfectoSegTramo['20'];
                    impuestoSegTramo['35'] = tazas['35'] * montoAfectoSegTramo['35'];
                    impuestoSegTramo['45'] = tazas['45'] * montoAfectoSegTramo['45'];
                    impuestoSegTramo['00'] = tazas['00'] * montoAfectoSegTramo['00'];
                    totalImpuestoSegtramo = impuestoSegTramo['5'] + impuestoSegTramo['20'] + impuestoSegTramo['35'] + impuestoSegTramo['45'] + impuestoSegTramo['00'];
                    retencionConceptosExtraordinarios = totalImpuestoSegtramo - totalImpuesto;
                }

                tableBody.push(
                    { 
                        A: `Empleado: ${iterator.emplcode} - ${iterator.lastname} ${iterator.mlstname}, ${iterator.nameeess}`,
                        B: null,
                        C: null,
                        D: null,
                        E: null,
                        F: null,
                        G: null
                    }
                );
                tableBody.push(
                    { 
                        A: null,
                        B: 'Renumeracion Basica',
                        C: null,
                        D: null,
                        E: null,
                        F: null,
                        G: String.formatNumber.new(base)
                    }
                );
                tableBody.push(
                    { 
                        A: null,
                        B: 'Asignacion Familiar',
                        C: null,
                        D: null,
                        E: null,
                        F: null,
                        G: String.formatNumber.new(asigFamiliarBase)
                    }
                );
                tableBody.push(
                    { 
                        A: null,
                        B: 'Renumeracion Computable Mensual',
                        C: null,
                        D: null,
                        E: null,
                        F: null,
                        G: String.formatNumber.new(sueldoMensual1)
                    }
                );
                cutRows.push(tableBody.length);
                tableBody.push(
                    { 
                        A: null,
                        B: 'Total renumeraciones proyectadas del periodo',
                        C: null,
                        D: null,
                        E: null,
                        F: null,
                        G: String.formatNumber.new(totalRenumProyect)
                    }
                );
                tableBody.push(
                    { 
                        A: null,
                        B: 'Gratificacion Julio',
                        C: null,
                        D: null,
                        E: null,
                        F: null,
                        G: String.formatNumber.new(gratificacionJulio)
                    }
                );
                tableBody.push(
                    { 
                        A: null,
                        B: 'Gratificacion Diciembre',
                        C: null,
                        D: null,
                        E: null,
                        F: null,
                        G: String.formatNumber.new(gratificacionDiciembre)
                    }
                );
                tableBody.push(
                    { 
                        A: null,
                        B: 'Total ingreso del periodo',
                        C: null,
                        D: null,
                        E: null,
                        F: null,
                        G: String.formatNumber.new(totalIngresosPeriodo)
                    }
                );
                tableBody.push(
                    { 
                        A: null,
                        B: 'Conceptos recibidos en meses anteriores',
                        C: null,
                        D: null,
                        E: null,
                        F: null,
                        G: null
                    }
                );
                cutRows.push(tableBody.length);
                tableBody.push(
                    { 
                        A: null,
                        B: 'Rem. Meses anteriores',
                        C: null,
                        D: null,
                        E: null,
                        F: null,
                        G: String.formatNumber.new(totalRenumeAnteriores)
                    }
                );
                tableBody.push(
                    { 
                        A: null,
                        B: 'Utilidades',
                        C: null,
                        D: null,
                        E: null,
                        F: null,
                        G: '0.00'
                    }
                );
                tableBody.push(
                    { 
                        A: null,
                        B: 'Bonificacion extraordinaria Julio',
                        C: null,
                        D: null,
                        E: null,
                        F: null,
                        G: currentMonth >= 7 ? String.formatNumber.new(bonificacionExtraordinaria) : '0.00'
                    }
                );
                tableBody.push(
                    { 
                        A: null,
                        B: 'Bonificacion extraordinaria Diciembre',
                        C: null,
                        D: null,
                        E: null,
                        F: null,
                        G: currentMonth >= 7 ? '0.00' : String.formatNumber.new(bonificacionExtraordinaria)
                    }
                );
                tableBody.push(
                    { 
                        A: null,
                        B: 'Total ingreso anual proyectado',
                        C: null,
                        D: null,
                        E: null,
                        F: null,
                        G: String.formatNumber.new(renumeracionBrutaAnual)
                    }
                );
                tableBody.push(
                    { 
                        A: null,
                        B: `Deduccion de 7 UIT (${uit})`,
                        C: null,
                        D: null,
                        E: null,
                        F: null,
                        G: String.formatNumber.new(deduccion7UIT)
                    }
                );
                tableBody.push(
                    { 
                        A: null,
                        B: 'Renumeracion Neta anual proyectado',
                        C: null,
                        D: null,
                        E: null,
                        F: null,
                        G: String.formatNumber.new(renumeracionNetaAnual)
                    }
                );
                cutRows.push(tableBody.length);
                tableBody.push(
                    { 
                        A: null,
                        B: 'Determinacion IR',
                        C: null,
                        D: null,
                        E: null,
                        F: null,
                        G: null
                    }
                );
                cutRows.push(tableBody.length);
                tableBody.push(
                    { 
                        A: null,
                        B: 'Hasta 5 UIT',
                        C: '8%',
                        D: String.formatNumber.new(maximo['5']),
                        E: String.formatNumber.new(montoAfecto['5']),
                        F: null,
                        G: String.formatNumber.new(impuesto['5'])
                    }
                );
                tableBody.push(
                    { 
                        A: null,
                        B: 'Mas de 5 UIT Hasta 20 UIT',
                        C: '14%',
                        D: String.formatNumber.new(maximo['20']),
                        E: String.formatNumber.new(montoAfecto['20']),
                        F: null,
                        G: String.formatNumber.new(impuesto['20'])
                    }
                );
                tableBody.push(
                    { 
                        A: null,
                        B: 'Mas de 20 UIT Hasta 35 UIT',
                        C: '17%',
                        D: String.formatNumber.new(maximo['35']),
                        E: String.formatNumber.new(montoAfecto['35']),
                        F: null,
                        G: String.formatNumber.new(impuesto['35'])
                    }
                );
                tableBody.push(
                    { 
                        A: null,
                        B: 'Mas de 35 UIT Hasta 45 UIT',
                        C: '20%',
                        D: String.formatNumber.new(maximo['45']),
                        E: String.formatNumber.new(montoAfecto['45']),
                        F: null,
                        G: String.formatNumber.new(impuesto['45'])
                    }
                );
                tableBody.push(
                    { 
                        A: null,
                        B: 'Mas de 45 UIT',
                        C: '30%',
                        D: String.formatNumber.new(maximo['00']),
                        E: String.formatNumber.new(montoAfecto['00']),
                        F: null,
                        G: String.formatNumber.new(impuesto['00'])
                    }
                );
                tableBody.push(
                    { 
                        A: null,
                        B: 'Calculo del IR anual proyectado',
                        C: null,
                        D: null,
                        E: null,
                        F: null,
                        G: String.formatNumber.new(totalImpuesto)
                    }
                );
                tableBody.push(
                    { 
                        A: null,
                        B: 'Retenciones de meses anteriores',
                        C: null,
                        D: null,
                        E: null,
                        F: null,
                        G: String.formatNumber.new(totalQuintaAnteriores)
                    }
                );
                tableBody.push(
                    { 
                        A: null,
                        B: 'Total IR anual proyectado',
                        C: null,
                        D: null,
                        E: null,
                        F: null,
                        G: String.formatNumber.new(totalImpuestoProyectado)
                    }
                );
                tableBody.push(
                    { 
                        A: null,
                        B: 'Retencion mensual',
                        C: null,
                        D: null,
                        E: null,
                        F: null,
                        G: String.formatNumber.new(totalRetencionMensual)
                    }
                );
                cutRows.push(tableBody.length);
                // Segundo tramo
                if (currentMonth === 7 || currentMonth === 12) {
                    tableBody.push(
                        { 
                            A: null,
                            B: `Bonificacion extraordinaria - 9% gratificacion de ${currentMonth === 7 ? 'julio' : 'diciembre'}`,
                            C: null,
                            D: null,
                            E: null,
                            F: null,
                            G: String.formatNumber.new(bonificacionExtraordinaria)
                        }
                    );
                    tableBody.push(
                        { 
                            A: null,
                            B: 'Total Remuneracion Neta Anual',
                            C: null,
                            D: null,
                            E: null,
                            F: null,
                            G: String.formatNumber.new(totalRenumeracionNetaAnual)
                        }
                    );
                    tableBody.push(
                        { 
                            A: null,
                            B: 'Determinacion IR',
                            C: null,
                            D: null,
                            E: null,
                            F: null,
                            G: null
                        }
                    );
                    cutRows.push(tableBody.length);
                    tableBody.push(
                        { 
                            A: null,
                            B: 'Hasta 5 UIT',
                            C: '8%',
                            D: String.formatNumber.new(maximo['5']),
                            E: String.formatNumber.new(montoAfectoSegTramo['5']),
                            F: null,
                            G: String.formatNumber.new(impuestoSegTramo['5'])
                        }
                    );
                    tableBody.push(
                        { 
                            A: null,
                            B: 'Mas de 5 UIT Hasta 20 UIT',
                            C: '14%',
                            D: String.formatNumber.new(maximo['20']),
                            E: String.formatNumber.new(montoAfectoSegTramo['20']),
                            F: null,
                            G: String.formatNumber.new(impuestoSegTramo['20'])
                        }
                    );
                    tableBody.push(
                        { 
                            A: null,
                            B: 'Mas de 20 UIT Hasta 35 UIT',
                            C: '17%',
                            D: String.formatNumber.new(maximo['35']),
                            E: String.formatNumber.new(montoAfectoSegTramo['35']),
                            F: null,
                            G: String.formatNumber.new(impuestoSegTramo['35'])
                        }
                    );
                    tableBody.push(
                        { 
                            A: null,
                            B: 'Mas de 35 UIT Hasta 45 UIT',
                            C: '20%',
                            D: String.formatNumber.new(maximo['45']),
                            E: String.formatNumber.new(montoAfectoSegTramo['45']),
                            F: null,
                            G: String.formatNumber.new(impuestoSegTramo['45'])
                        }
                    );
                    tableBody.push(
                        { 
                            A: null,
                            B: 'Mas de 45 UIT',
                            C: '30%',
                            D: String.formatNumber.new(maximo['00']),
                            E: String.formatNumber.new(montoAfectoSegTramo['00']),
                            F: null,
                            G: String.formatNumber.new(impuestoSegTramo['00'])
                        }
                    );
                    tableBody.push(
                        { 
                            A: null,
                            B: 'Calculo del IR anual proyectado',
                            C: null,
                            D: null,
                            E: null,
                            F: null,
                            G: String.formatNumber.new(totalImpuestoSegtramo)
                        }
                    );
                    tableBody.push(
                        { 
                            A: null,
                            B: 'Retencion por Conceptos Exraordinarios',
                            C: null,
                            D: null,
                            E: null,
                            F: null,
                            G: String.formatNumber.new(retencionConceptosExtraordinarios)
                        }
                    );
                    tableBody.push(
                        { 
                            A: null,
                            B: 'Retencion Total',
                            C: null,
                            D: null,
                            E: null,
                            F: null,
                            G: String.formatNumber.new(totalRetencionMensual + retencionConceptosExtraordinarios)
                        }
                    );
                    cutRows.push(tableBody.length);
                }
                //
                tableBody.push(
                    { 
                        A: null,
                        B: null,
                        C: null,
                        D: null,
                        E: null,
                        F: null,
                        G: null
                    }
                );
            }

            const header = [
                'A',
                'B',
                'C',
                'D',
                'E',
                'F',
                'G'
            ];

            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(tableBody, {header, skipHeader: true});
            ws["!cols"] = [
                {wpx:50},{wpx:200},{wpx:60},{wpx:60},{wpx:60},{wpx:100},{wpx:60}
            ];
            ws['!rows'] = [
                {hpx: 16},{hpx: 18},{hpx: 16},{hpx: 16}
            ];

            // for (let index = 5; index <= rowsCount; index++) {
            //     ws['!rows'].push({hpx: 12})
            // }
            const keys = Object.keys(ws);

            for (const iterator of keys) {
                if(!isNaN(parseInt(ws[iterator].v)) && iterator.match(/^(D|E|G)[0-9]+$/g)) {
                    ws[iterator].v = ws[iterator].v.replace(/,/g, '');
                    ws[iterator].t = 'n';
                    ws[iterator].z = '#,##0.00';
                }
                if(iterator !== '!ref' && iterator !== '!cols' && iterator !== '!rows') {
                    ws[iterator].s = {
                        font: {
                            name: 'Tahoma',
                            sz: 8
                        }
                    };
                    const row = +(iterator.match(/\d+/)[0]);
                    if(cutRows.includes(row)) {
                        ws[iterator].s.font.bold = true;
                    }
                }
            }

            ws['A1'].s = {font:{name: 'Tahoma', sz: 8, bold: true}}
            ws['A2'].s = {font:{name: 'Tahoma', sz: 15, bold: true}, alignment: { wrapText: true, vertical: 'center', horizontal: 'center'}}
            ws['A3'].s = {font:{name: 'Tahoma', sz: 8, bold: true}}

            ws["!merges"] = [
                { s: { r: 1, c: 0 }, e: { r: 1, c: 6 } },
            ];

            XLSX.utils.book_append_sheet(wb, ws, "Calculo de 5ta categoria");

            var wbout = XLSX.write(wb, {bookType:'xlsx', type: 'binary', cellStyles: true});

            const s2ab = function(s) {
                var buf = new ArrayBuffer(s.length);
                var view = new Uint8Array(buf);
                for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
                return buf;
            }

            saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), `calculo-5ta-categoria-${year}-${month}.xlsx`);

        } catch(err) {
            console.log(err);
        }
    }

    // onGenerateVoucher = () => {
    //     const { emplcode, year, month, week, typesala, payrtype, allFlag } = this.state;
    //     if (allFlag) {
    //         // this.props.employeVoucherAllRequest(year, month, week, typesala, payrtype, this.onGeneratePdf)
    //     } else {
    //         // this.props.employeVoucherRequest(emplcode, year, month, week, typesala, payrtype, this.onGeneratePdf)
    //     }
    // }

    componentDidMount() {
        const { payrollInitFormRequest } = this.props;
        payrollInitFormRequest(this.state.year, () => {});
    }

    render() {

        const { title, emplcode, nameeess, year, month, week, payrtype, typesala, base64Pdf, allFlag } = this.state;
        const { loading, list, failed, failedTitle, failedContent } = this.props.humanResourcesReducers;
        const { classes } = this.props;

        return (
            <div style={{overflow: 'auto', height: '100%'}}>
                <Paper className='form__container'>
                    <Typography variant='h5'>{ title }</Typography>
                    <br/>
                    { failed && <MessageBox title={failedTitle} content={failedContent}/> }
                    <div style={{ display: 'flex' }}>
                        <form noValidate autoComplete="off" style={{ flex: 1, paddingRight: '15px', paddingTop: '2em' }}>
                            <Grid container spacing={2} >
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                        <Switch
                                            checked={allFlag}
                                            onChange={(e: any) => this.onChangeRoot({'allFlag': e.target.checked})}
                                            name="checkedB"
                                            color="primary"
                                        />
                                        }
                                        label="Todo el personal"
                                    />
                                </Grid>
                            </Grid>
                                {
                                    !allFlag && 
                                    <>
                                        <Grid container spacing={2}>   
                                                <Grid item xs={12}>
                                                    <Autocomplete
                                                        id="emplcode"
                                                        freeSolo
                                                        options={list || []}
                                                        getOptionLabel={ (x: any) => x.emplcode }
                                                        value={ list.find((x: any) => x.emplcode === emplcode) || null }
                                                        onChange={(e: any, value) => this.onChangeRoot({'emplcode': value.emplcode, 'nameeess': value.nameeess})}
                                                        renderInput={(params) => (
                                                            <TextField {...params} 
                                                            style={ { marginTop: 0 } } label="Empleado" margin="normal" variant="outlined" />
                                                        )}
                                                    />
                                                </Grid>
                                        </Grid>
                                        <Grid container spacing={2}> 
                                            <Grid item xs={12}>
                                                <TextField fullWidth id="nameeess" label="Nombres" variant="outlined" value={ nameeess || '' } disabled/>
                                            </Grid>
                                        </Grid>
                                    </>
                            } 
                            <Grid container spacing={2}>   
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_año">Año</InputLabel>
                                            <Select
                                                labelId="lbl_año"
                                                id="year"
                                                className='select'
                                                label="Año"
                                                fullWidth
                                                value={ year }
                                                onChange={(e: any) => this.onChangeRoot({'year': e.target.value})}
                                            >
                                                <MenuItem value='2030'>2030</MenuItem>
                                                <MenuItem value='2029'>2029</MenuItem>
                                                <MenuItem value='2028'>2028</MenuItem>
                                                <MenuItem value='2027'>2027</MenuItem>
                                                <MenuItem value='2026'>2026</MenuItem>
                                                <MenuItem value='2025'>2025</MenuItem>
                                                <MenuItem value='2026'>2026</MenuItem>
                                                <MenuItem value='2025'>2025</MenuItem>
                                                <MenuItem value='2024'>2024</MenuItem>
                                                <MenuItem value='2023'>2023</MenuItem>
                                                <MenuItem value='2022'>2022</MenuItem>
                                                <MenuItem value='2021'>2021</MenuItem>
                                                <MenuItem value='2020'>2020</MenuItem>
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField fullWidth id="payrweek" label="Semana" variant="outlined" value={ week } onChange={(e: any) => this.onChangeRoot({'week': e.target.value})} disabled={ payrtype === '102' ? false : true }/>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_mes">Mes</InputLabel>
                                            <Select
                                                labelId="lbl_mes"
                                                id="month"
                                                className='select'
                                                label="Mes"
                                                fullWidth
                                                value={ month }
                                                onChange={(e: any) => this.onChangeRoot({'month': e.target.value})}
                                                disabled={ payrtype === '101' ? false : true }
                                            >
                                                {
                                                    meses.map((x: any) => 
                                                        <MenuItem value={x.key} key={x.key}>{x.value}</MenuItem>
                                                    )
                                                }
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} style={{ paddingTop: '50px' }}>
                                    <Button variant="contained" fullWidth color="primary" onClick={this.onGenerateExcel}>
                                        Generar Reporte
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                    <br/>
                    {/* <FormFooter onCancel={ this.onCancel } onSubmit={ this.onSubmit }/> */}
                </Paper>
                <ScreenLoader enabled={loading}/>
            </div>
        );
    }
}

const humanResourcesReducers = 'humanResourcesReducers';
const dashboardReducers = 'dashboardReducers';

const mapStateToProps = (state: any) => ({
    humanResourcesReducers: state.get(humanResourcesReducers),
    dashboardReducers: state.get(dashboardReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        payrollInitFormRequest,
        employeVoucherRequest,
        employeVoucherAllRequest
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(ReportFifthCategoryCalculation));