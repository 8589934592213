import React from 'react';
import { Backdrop, Button, ButtonGroup, Checkbox, CircularProgress, createStyles, FormControl, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Select, TextField, Theme, Tooltip, Typography, FormControlLabel, withStyles, Snackbar } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { weekcalesInitUpdateFormRequest, weekcalesInsertRequest, weekcalesUpdateRequest } from 'penta-redux/actions/humanResourcesActions';
import { locationsGetProvincesRequest, locationsGetDistrictsRequest } from 'penta-redux/actions/generalActions';
import history from '../../../utils/history';
import './weekends.scss'
import FormFooter from 'penta-components/base/formFooter';
import { MessageBox } from 'penta-components/base/messageBox';
import { weekcaleEntity } from 'models/humanResourcesModels';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import EditableCell from 'penta-components/base/editableCell';
import { Alert } from '@material-ui/lab';
import ScreenLoader from 'penta-components/base/screenLoader';

const useStyles = (theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        formControl: {
            margin: 0,
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    });

const defaultWeekcalesEntity: weekcaleEntity = {
    yearnumb: moment().format('YYYY'),
    weeknumb: '1',
    stardate: moment().format('YYYY-MM-DD'),
    endddate: moment().format('YYYY-MM-DD'),
    montnumb: moment().format('MM'),
    holinumb: '0',
    essaludv: false,
    quintact: false
}

interface FormWeekendsState {
    weekcalesEntity: weekcaleEntity
    title: string
    snackbar: boolean
}

class FormWeekends extends React.Component<any, FormWeekendsState> {

    constructor(props: any) {
        super(props);
        this.state = {
            weekcalesEntity: defaultWeekcalesEntity,
            title: 'Agregar Semanas',
            snackbar: false
        }
    }

    onChangeRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            ...value
        }))
    }

    onChangeCommissisRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            weekcalesEntity: {
                ...state.weekcalesEntity,
                ...value
            }
        }));
    }

    onCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        history.push('/papp/hhrr/semanas');
    }

    onContinueCallback = () => {
        const newWeekcalesEntity = defaultWeekcalesEntity;
        this.setState({
            weekcalesEntity: newWeekcalesEntity,
            snackbar: true
        });
    }

    onSubmitContinue = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const { params } = this.props.match;
        if (params.yearnumb !== undefined) {
            this.props.weekcalesUpdateRequest(this.state.weekcalesEntity);
        } else {
            this.props.weekcalesInsertRequest(this.state.weekcalesEntity, this.onContinueCallback);
        }
    }

    onSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const { params } = this.props.match;
        if (params.yearnumb !== undefined) {
            this.props.weekcalesUpdateRequest(this.state.weekcalesEntity);
        } else {
            this.props.weekcalesInsertRequest(this.state.weekcalesEntity, null);
        }
    }

    callbackInit = () => {
        const { tables } = this.props.humanResourcesReducers;
        this.setState((state: any) => ({
            ...state,
            weekcalesEntity: {
                ...state.weekcalesEntity,
                pbl_purchdet: [
                    {
                        skucodee: '',
                        itemnumb: 1,
                        taxtypee: tables.definition.filter((x: any) => x.defitype === 'TXTYP')[0].deficode,
                        incluigv: 1,
                    }
                ]
            }
        }));
    }

    callbackInitUpdate = (weekcalesEntity: weekcaleEntity) => {
        // weekcalesEntity.weekcale = JSON.parse(weekcalesEntity.weekcale);
        weekcalesEntity.stardate = moment(weekcalesEntity.stardate).format('YYYY-MM-DD');
        weekcalesEntity.endddate = moment(weekcalesEntity.endddate).format('YYYY-MM-DD');
        this.setState({
            weekcalesEntity
        });
    }

    componentDidMount() {
        const { params } = this.props.match;
        if (params.yearnumb !== undefined) {
            this.setState({title: 'Editar Comisiones'});
            this.props.weekcalesInitUpdateFormRequest(params.yearnumb, params.weeknumb, this.callbackInitUpdate);
        }
    }

    render() {

        const { weekcalesEntity, title, snackbar } = this.state;
        const { loading, list, tables, failed, failedTitle, failedContent } = this.props.humanResourcesReducers;
        const generalTables = this.props.generalReducers.tables;
        const { classes } = this.props;

        return (
            <div style={{overflow: 'auto', height: '100%'}}>
                <Paper className='form__container'>
                    <br/>
                    <Typography variant='h5'>{ title }</Typography>
                    <br/>
                    { failed && <MessageBox title={failedTitle} content={failedContent}/> }
                    <form noValidate autoComplete="off">
                        <Grid container spacing={2} >
                            <Grid item xs={3}>
                                <TextField fullWidth id="yearnumb" label="Año" variant="outlined" value={ weekcalesEntity.yearnumb } onChange={(e: any) => this.onChangeCommissisRoot({'yearnumb': e.target.value})}/>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth id="weeknumb" label="Numero de Semana" variant="outlined" value={ weekcalesEntity.weeknumb } onChange={(e: any) => this.onChangeCommissisRoot({'weeknumb': e.target.value})}/>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth type="date" id="stardate"  label="Fecha de Inicio" variant="outlined" value={ weekcalesEntity.stardate } onChange={(e: any) => this.onChangeCommissisRoot({'stardate': e.target.value})}/>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth type="date" id="endddate"  label="Fecha de Fin" variant="outlined" value={ weekcalesEntity.endddate } onChange={(e: any) => this.onChangeCommissisRoot({'endddate': e.target.value})}/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} >
                            <Grid item xs={3}>
                                <TextField fullWidth id="montnumb" label="Mes" variant="outlined" value={ weekcalesEntity.montnumb } onChange={(e: any) => this.onChangeCommissisRoot({'montnumb': e.target.value})}/>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth id="holinumb" label="Nro. de feriados" variant="outlined" value={ weekcalesEntity.holinumb } onChange={(e: any) => this.onChangeCommissisRoot({'holinumb': e.target.value})}/>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControlLabel
                                    control={<Checkbox checked={weekcalesEntity.essaludv} onChange={(e: any) => this.onChangeCommissisRoot({'essaludv': e.target.checked})} name="essaludv" />}
                                    label="Essalud"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormControlLabel
                                    control={<Checkbox checked={weekcalesEntity.quintact} onChange={(e: any) => this.onChangeCommissisRoot({'quintact': e.target.checked})} name="quintact" />}
                                    label="Quinta Categoria"
                                />
                            </Grid>
                        </Grid>
                        <div style={{ height: 50 }}/>
                    </form>
                    <br/>
                    <FormFooter 
                        onCancel={ this.onCancel } 
                        onSubmitContinue={ this.onSubmitContinue } 
                        onSubmit={ this.onSubmit }/>
                </Paper>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={snackbar}
                    onClose={() => this.setState({snackbar: false})}
                    autoHideDuration={6000}
                >
                    <Alert onClose={() => this.setState({snackbar: false})} severity="success">
                            Datos Guardados Correctamente
                    </Alert>
                </Snackbar>
                <ScreenLoader enabled={loading}/>
            </div>
        );
    }
}

const humanResourcesReducers = 'humanResourcesReducers';
const generalReducers = 'generalReducers';

const mapStateToProps = (state: any) => ({
    humanResourcesReducers: state.get(humanResourcesReducers),
    generalReducers: state.get(generalReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        // weekcalesInitFormRequest,
        weekcalesInitUpdateFormRequest,
        weekcalesInsertRequest,
        weekcalesUpdateRequest
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(FormWeekends));