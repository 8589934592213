import { all } from 'redux-saga/effects';
import authSagas from './authSagas';
import dashboardSagas from './dashboardSagas';
import usersSagas from './usersSagas';
import generalSagas from './generalSagas';
import accountantSagas from './accountantSagas';
import wharehouseProductsSagas from './wharehouseProductsSagas';
import salesSagas from './salesSagas';
import shoppingsSagas from './shoppingsSagas';
import humanResourcesSagas from './humanResourcesSagas';
import financeSagas from './financeSagas';

export default function* sagas() {
  yield all([
    ...authSagas,
    ...dashboardSagas,
    ...usersSagas,
    ...generalSagas,
    ...accountantSagas,
    ...wharehouseProductsSagas,
    ...salesSagas,
    ...shoppingsSagas,
    ...humanResourcesSagas,
    ...financeSagas
  ]);
}