import React from 'react';
import {createUseStyles} from 'react-jss';
import HelpIcon from 'penta-assets/images/information.svg';

const useStyles =  createUseStyles({
    container: {
        'position': 'relative'
    },
    helpDiv: {
        'display': 'flex',
        'alignItems': 'center',
        'cursor': 'pointer'
    },
    helpIcon: {
        'width': 'auto',
        'height': '1.25rem'
    }
});

interface Help {
    // onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    // value: string | number | readonly string[]
}

const Help = (props: Help) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <div className={classes.helpDiv}>
                <img src={HelpIcon} className={classes.helpIcon} />
            </div>
        </div>
    )
}

export default Help;