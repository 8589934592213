import React, { useEffect } from 'react';
import { Backdrop, CircularProgress, createStyles, Paper, TextField, Theme, Tooltip, withStyles } from '@material-ui/core';
import ListView from 'penta-components/base/listView';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { purchasesGetAllRecordsRequest, purchasesListSelectItem, purchasesListSelectAllItem, purchasesActionFailed, purchasesDeleteRequest, purchasesMassiveUpdateRequest } from 'penta-redux/actions/shoppingsActions';
import history from '../../../utils/history';
import './purchases.scss'
import filePNG from './../../../../icons/fluent/file.png';
import fileEditPNG from './../../../../icons/fluent/file-edit.png';
import fileDeletePNG from './../../../../icons/fluent/delete.png';
import fileCopyPNG from './../../../../icons/fluent/copy.png';
import fileDownloadPNG from './../../../../icons/fluent/download.png';
import fileUploadPNG from './../../../../icons/fluent/upload.png';
import fileAnulatePNG from './../../../../icons/fluent/anulate.png';
import { MessageBox } from 'penta-components/base/messageBox';
import ActionDialog from 'penta-components/base/dialog';
import { Input } from 'penta-components/base/input';
import Button from 'penta-components/controls/button';
import * as XLSX from 'xlsx';
import { purchaseEntity } from 'models/shoppingModels';
import ListMenu from 'penta-components/base/listMenu';
import Separator from 'penta-components/base/separator';
import Search from 'penta-components/controls/search';
import Select from 'penta-components/controls/select';
import { createUseStyles } from 'react-jss';
import Modal from 'penta-components/base/modal';
import Checkbox from 'penta-components/controls/checkbox';

const moment = require('moment');
const months = [
    { id: '01', name: 'Enero' },
    { id: '02', name: 'Febrero' },
    { id: '03', name: 'Marzo' },
    { id: '04', name: 'Abril' },
    { id: '05', name: 'Mayo' },
    { id: '06', name: 'Junio' },
    { id: '07', name: 'Julio' },
    { id: '08', name: 'Agosto' },
    { id: '09', name: 'Septiembre' },
    { id: '10', name: 'Octubre' },
    { id: '11', name: 'Noviembre' },
    { id: '12', name: 'Diciembre' }
];
const header = [
    { title: 'Registro', key: 'intecode' },
    { title: 'Nro de serie', key: 'serialnu' },
    { title: 'Nro de documento', key: 'documnum' },
    { title: 'Proveedor', key: 'supplnam', style: () => { return {width: '50%'} } },
    { title: 'Moneda', key: 'currency' },
    { title: 'Monto total', key: 'totalamo', style: () => { return {textAlign: 'right'} } },
    { title: 'Estado', key: 'estado',
        value: (call, item) => { 
            try {
                const val = +call(item, 'balanpay').replace(/,/g, '');
                if(val > 0) 
                    return <div style={{width: '1.25rem', height: '1.25rem', backgroundColor: '#E26464', borderRadius: '0.625rem', margin: 'auto'}}/>; 
                else 
                    return <div style={{width: '1.25rem', height: '1.25rem', backgroundColor: '#64E29F', borderRadius: '0.625rem', margin: 'auto'}}/>;
            } 
            catch { } 
        },
        style: (call, item) => { return {paddingBottom: '0', paddingTop: '0'}; }
    }
]; 

const useStyles = createUseStyles({
    container: {
        overflow: 'auto', 
        height: '100%', 
        display: 'flex', 
        flexDirection: 'column'
    },
    titleContainer: {
        flex: 1, 
        display: 'flex', 
        alignItems: 'baseline'
    },
    title: {
        fontFamily: '"Open Sans", sans-serif', 
        fontSize: '1.563rem', 
        fontWeight: 'bold', 
        color: '#0060A6', 
        marginRight: '3rem'
    },
    tabActive: {},
    tabs: {
        margin: '0 0.4rem', 
        padding: '0.4rem 0.5rem', 
        color: '#b9b9b9', 
        fontFamily: '"Open Sans", sans-serif', 
        fontWeight: 'bold', 
        fontSize: '0.875rem',
        cursor: 'pointer',
        '&$tabActive': {
            color: '#3B3B3B',
            borderBottom: '0.2rem solid #3B3B3B', 
        }
    },
    listContainer: {
        flex: 1,
        '& > div:first-child': {
            display: 'flex'
        }
    }
});

const ListPurchases = (props: any) => {
    const classes = useStyles();
    const amountJump = 20;
    const [showModal, setShowModal] = React.useState(false);
    const [typeModal, setTypeModal] = React.useState('');
    //===============================================================
    const [searchValue, setSearchValue] = React.useState('');
    const [acpeyear, setAcpeyear] = React.useState(moment().format('YYYY'));
    const [acpemont, setAcpemont] = React.useState(moment().format('MM'));
    const [state, setState] = React.useState('00');
    const [take, setTake] = React.useState(amountJump);
    //===============================================================
    const [file, setFile] = React.useState('');
    const [typing, setTyping] = React.useState(null);
    //===============================================================
    const [origin, setOrigin] = React.useState(null);
    const [deleteOrigin, setDeleteOrigin] = React.useState(false);
    const [listCompany, setListCompany] = React.useState([]);
    const [listDivision, setListDivision] = React.useState([]);
    const [listBussofic, setListBussofic] = React.useState([]);
    const [company, setCompany] = React.useState('');
    const [division, setDivision] = React.useState('');
    const [bussofic, setBussofic] = React.useState('');

    const agregar = () => {
        history.push('/papp/shopping/compras/agregar');
    };

    const editar = () => {
        const select = props.shoppingsReducers.list.find((x: any) => x.select);
        if (select !== undefined)
            history.push('/papp/shopping/compras/editar/' + select.serialnu + '/' + select.documnum + '/' + select.docsuppl);
        else
            props.purchasesActionFailed('Error: 0x0', 'No se ha selecionado un elemento para modificar');
    }

    const eliminar = () => {
        const select = props.shoppingsReducers.list.filter((x: any) => x.select);
        const selectSerial = select.map((x: any) => { return x.serialnu });
        const selectNumero = select.map((x: any) => { return x.documnum });
        const selectProveedor = select.map((x: any) => { return x.docsuppl });
        if(select.length !== 0) {
            const filters = {
                fields: {
                    serialnu: searchValue,
                    documnum: searchValue,
                    docsuppl: searchValue,
                    businame: searchValue,
                    lastname: searchValue,
                    mlstname: searchValue,
                    suppname: searchValue
                },
                skip: 0,
                take: amountJump
            } as any
    
            if(acpeyear !== '') filters.year = acpeyear;
            if(acpemont !== '') filters.month = acpemont;

            props.purchasesDeleteRequest(selectSerial, selectNumero, selectProveedor, filters);
            handleCloseModal();
        }
        else
            props.purchasesActionFailed('Error: 0x0', 'No se ha selecionado un elemento para eliminar');
    }

    const copy = () => {
        const elementCopy = origin;
        if(elementCopy) {
            const filters = {
                fields: { },
                skip: 0,
                take: amountJump
            } as any

            if(acpeyear !== '') filters.year = acpeyear;
            if(acpemont !== '') filters.month = acpemont;

            history.push('/papp/shopping/compras/copiar', {
                method: 'copy', 
                serialnu: elementCopy.serialnu, 
                documnum: elementCopy.documnum, 
                officode: bussofic, 
                docsuppl: elementCopy.docsuppl,
                deleteOrigin: deleteOrigin, 
                filters: filters
            })
        }
        setDeleteOrigin(false);
    }

    const importExcel = (e: any) => {
        const { purchasesMassiveUpdateRequest } = props;
        // if(confirm("Upload File: " + e.target.files[0].name))
        // companyPostUploadRequest(e.target.files[0]);
        const reader = new FileReader();
        reader.onload = (evt) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, {type:'binary'});
            const cbname = wb.SheetNames[0];
            const cb = wb.Sheets[cbname];
            const dename = wb.SheetNames[1];
            const de = wb.Sheets[dename];
            const general: Array<any> = XLSX.utils.sheet_to_json(cb, {header:0} as XLSX.Sheet2JSONOpts);
            const detail: Array<any> = XLSX.utils.sheet_to_json(de, {header:0} as XLSX.Sheet2JSONOpts);

            const definition = [
                { deficode: '00000016', definame: 'IGV 18%'	   , defivalu: 0.18, defitype: 'TXTYP' },
                { deficode: '00000017', definame: 'EXON. 0.00%', defivalu: 0.00, defitype: 'TXTYP' },
                { deficode: '00000018', definame: 'INAF. 0.00%', defivalu: 0.00, defitype: 'TXTYP' }
            ]

            for (const iterator of general) {

                switch(iterator.opertype) {
                    case '30': iterator.opertype = '00000019'; break;
                    case '31': iterator.opertype = '00000020'; break;
                    case '32': iterator.opertype = '00000021'; break;
                    case '33': iterator.opertype = '00000022'; break;
                    case '34': iterator.opertype = '00000023'; break;
                    default: break;
                }

                iterator.officode = iterator.officode.padStart(5, '0');
                iterator.emitdate = moment(iterator.emitdate).format('YYYY-MM-DD');
                iterator.expidate = moment(iterator.expidate).format('YYYY-MM-DD');
                iterator.recedate = moment(iterator.recedate).format('YYYY-MM-DD');
                iterator.intecode = 0,
                iterator.amountss = [],
                iterator.balances = [],
                iterator.pbl_purchdet = detail.filter(x => x.serialnu === iterator.serialnu && x.documnum === iterator.documnum && x.officode.padStart(5, '0') === iterator.officode && x.docsuppl === iterator.docsuppl);
                iterator.pbl_purchdet = iterator.pbl_purchdet.map(x => {
                    return { ...x,
                        incluigv: x.incluigv.toUpperCase() === 'TRUE' ? true : false,
                        isbonuss: x.isbonuss.toUpperCase() === 'TRUE' ? true : false,
                        skucodee: x.skucodee.toUpperCase() === 'NULL' ? null : x.skucodee,
                        prodname: x.prodname.trim(), accaccou: x.accaccou.trim(),
                        meascode: x.meascode.trim()
                    }
                });
                const companyCurrency = '00000014';
                const documentCurrency = iterator.currency;
                const change = iterator.curreval;
                const _taxvalue = definition.find((x: any) => x.deficode === '00000016').defivalu;
                let amoupaid = iterator.amoupaid;
                let balanpay = iterator.partotal - amoupaid;
                amoupaid = Math.round(amoupaid*100)/100;
                balanpay = Math.round(balanpay*100)/100;
                if(companyCurrency !== documentCurrency) {
                    for(let i = 0; i < iterator.pbl_purchdet.length; i++) {
                        const taxvalue = definition.find((x: any) => x.deficode === iterator.pbl_purchdet[i]['taxtypee']).defivalu;
                        iterator.pbl_purchdet[i].amountss = {
                            [companyCurrency]: {
                                amototal: Math.round(iterator.pbl_purchdet[i]['amototal'] * change*100)/100,
                                subtotal: Math.round(Math.round(iterator.pbl_purchdet[i]['amototal']*change*100)/100 / (1+(+taxvalue)) *100)/100,
                                unitpric: Math.round(iterator.pbl_purchdet[i]['unitpric'] * change*100)/100,
                                quantity: Math.round(iterator.pbl_purchdet[i]['quantity'] * 100)/100,
                                subprice: Math.round(iterator.pbl_purchdet[i]['subprice'] * change*100)/100,
                            }
                        }
                    }
                    const topgrava = Math.round(Math.round((iterator.partotal - iterator.topexone - iterator.topinafe)*change*100)/100 / (1+(+_taxvalue))*100)/100;
                    const topexone = Math.round(iterator.topexone * change*100)/100;
                    const topinafe = Math.round(iterator.topinafe * change*100)/100;
                    const topgrati = Math.round(iterator.topgrati * change*100)/100;
                    const totaltax = Math.round((iterator.partotal - iterator.topexone - iterator.topinafe)*change*100)/100 - topgrava;
                    const partotal = topgrava + topexone + topinafe + totaltax;
                    const fleteamo = Math.round(iterator.fleteamo * change*100)/100;
                    const percepci = Math.round(iterator.percepci * change*100)/100;
                    const totalamo = partotal + fleteamo + percepci;
                    iterator.amountss = {
                        [companyCurrency]: {
                            topgrava: topgrava,
                            topexone: topexone,
                            topinafe: topinafe,
                            topgrati: topgrati,
                            totalamo: totalamo,
                            totaltax: totaltax,
                            partotal: partotal,
                            fleteamo: fleteamo,
                            percepci: percepci
                        }
                    },
                    iterator.balances = {
                        [companyCurrency]: {
                            balanpay: Math.round(balanpay * change*100)/100,
                            amoupaid: Math.round(amoupaid * change*100)/100
                        }
                    }
                }
                else {
                    for(let i = 0; i < iterator.pbl_purchdet.length; i++) {
                        iterator.pbl_purchdet[i].amountss = {
                            [companyCurrency]: {
                                amototal: iterator.pbl_purchdet[i]['amototal'],
                                subtotal: iterator.pbl_purchdet[i]['subtotal'],
                                unitpric: iterator.pbl_purchdet[i]['unitpric'],
                                quantity: iterator.pbl_purchdet[i]['quantity'],
                                subprice: iterator.pbl_purchdet[i]['subprice'],
                            }
                        }
                    }
                    iterator.amountss = {
                        [companyCurrency]: {
                            topgrava: iterator.topgrava,
                            topexone: iterator.topexone,
                            topinafe: iterator.topinafe,
                            topgrati: iterator.topgrati,
                            totalamo: iterator.totalamo,
                            totaltax: iterator.totaltax,
                            partotal: iterator.partotal,
                            fleteamo: iterator.fleteamo,
                            percepci: iterator.percepci
                        }
                    },
                    iterator.balances = {
                        [companyCurrency]: {
                            balanpay: balanpay,
                            amoupaid: amoupaid
                        }
                    }
                }
            }

            purchasesMassiveUpdateRequest(general);
        };
        reader.readAsBinaryString(e.target.files[0]);
    }

    const handleShowModal = (typeModal: string) => {
        setShowModal(true);
        setTypeModal(typeModal);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setTypeModal('');
    };

    const openModalCopy = () => {
        const selects = props.shoppingsReducers.list.filter((x: any) => x.select);
        if(selects.length > 0) {
            handleShowModal('C')
            setOrigin(selects[0]);
        }
    }

    const onChangeSearchValue = (e) => {
        const text = e.target.value;
        setSearchValue(text);
        if(typing) clearTimeout(typing)
        setTyping(
            setTimeout(() => {
                onSearch(text);
            }, 500)
        )
    }

    const onSearch = (typing?: string) => {
        const body = {
            fields: {
                serialnu: `${typing ?? searchValue}`,
                documnum: `${typing ?? searchValue}`,
                docsuppl: `${typing ?? searchValue}`,
                businame: `${typing ?? searchValue}`,
                lastname: `${typing ?? searchValue}`,
                mlstname: `${typing ?? searchValue}`,
                suppname: `${typing ?? searchValue}`
            },
            skip: 0,
            take: amountJump
        } as any

        if(acpeyear !== '') body.year = acpeyear;
        if(acpemont !== '') body.month = acpemont;
        if(state !== '') body.state = state;

        props.purchasesGetAllRecordsRequest(body);
        setTake(amountJump);
    }

    const callMoreItems = () => {
        const body = {
            fields: {
                serialnu: searchValue,
                documnum: searchValue,
                docsuppl: searchValue,
                businame: searchValue,
                lastname: searchValue,
                mlstname: searchValue,
                suppname: searchValue
            },
            skip: 0,
            take: take + amountJump
        } as any

        if(acpeyear !== '') body.year = acpeyear;
        if(acpemont !== '') body.month = acpemont;
        if(state !== '') body.state = state;

        props.purchasesGetAllRecordsRequest(body);
        setTake(take + amountJump);
    }

    useEffect(() => {
        const { purchasesGetAllRecordsRequest } = props;
        const body = {
            fields: {
                serialnu: searchValue,
                documnum: searchValue,
                docsuppl: searchValue,
                businame: searchValue,
                lastname: searchValue,
                mlstname: searchValue,
                suppname: searchValue
            },
            skip: 0,
            take
        } as any

        if(acpeyear !== '') body.year = acpeyear;
        if(acpemont !== '') body.month = acpemont;
        if(state !== '') body.state = state;

        purchasesGetAllRecordsRequest(body);
    }, []);

    useEffect(() => {
        const { authReducers } = props;
        const lst_division = authReducers.tables.companys.find((x: any) => x.taxident === company)?.lst_grl_division.map((x: any) => { return { id: x.divicode, name: x.diviname } }) || [];
        setListDivision(lst_division);
        setListBussofic([]);
    }, [company]);

    useEffect(() => {
        const { authReducers } = props;
        const lst_bussofic = authReducers.tables.companys.find((x: any) => x.taxident === company)?.lst_grl_division.find((x: any) => x.divicode === division)?.lst_grl_bussofic.map((x: any) => { return { id: x.officode, name: x.offiname } }) || [];
        setListBussofic(lst_bussofic);
    }, [division]);

    useEffect(() => {
        const { dashboardReducers } = props;
        setCompany(dashboardReducers.currentCompany);
        setDivision(dashboardReducers.currentDivision);
        setBussofic(dashboardReducers.currentBussOfic);
    }, [showModal]);

    useEffect(() => {
        const { authReducers } = props;
        const lst_companys = authReducers.tables.companys.map((x: any) => {
            return { name: x.bussname, id: x.taxident }
        });
        setListCompany(lst_companys);
        setListDivision([]);
        setListBussofic([]);
    }, []);

    return (
        <div className={classes.container}>
            <div className={classes.titleContainer}>
                <div className={classes.title}>Compras</div>
                <div>
                    <span className={`${classes.tabs} ${classes.tabActive}`}>Lista</span>
                    <span className={classes.tabs}>Chart</span>
                    <span className={classes.tabs}>Historial</span>
                </div>
            </div>
            <div className={classes.listContainer}>
                <div>
                    <Search styleContainer={{flex: 1}} onChange={onChangeSearchValue} value={searchValue}/>
                    <Select label="Año" labelPosition="left" style={{marginLeft: '1rem'}} data={
                        [{id: '2024', name: '2024'}, {id: '2023', name: '2023'}, {id: '2022', name: '2022'}, {id: '2021', name: '2021'}, {id: '2020', name: '2020'}, {id: '2019', name: '2019'}]
                    } value={acpeyear} onChange={(value) => setAcpeyear(value.id)}/>
                    <Select label="Mes" labelPosition="left" style={{marginLeft: '1rem'}} data={months} value={acpemont} onChange={(value) => setAcpemont(value.id)}/>
                    <Select label="Estado" labelPosition="left" style={{marginLeft: '1rem'}} data={
                        [{id: '00', name: '--Todos--'}, {id: '01', name: 'Deuda'}, {id: '02', name: 'Pagado'}]
                    } value={state} onChange={(value) => setState(value.id)}/>
                    &nbsp;&nbsp;
                    <Button type='primary' full={false} containerStyle={{height: 'auto'}} onClick={() => onSearch()}>Filtrar</Button>
                </div>
                <Separator marginBottom='1rem'/>
                <ListMenu
                    addItem={agregar}
                    editItem={editar}
                    deleteItem={() => handleShowModal('D')}
                    copyItem={openModalCopy}
                />
                <Separator marginBottom='0.5rem'/>
                <ListView
                    header={header} body={props.shoppingsReducers.list}
                    selectAction={props.purchasesListSelectItem} 
                    selectAllAction={props.purchasesListSelectAllItem}
                    total={props.shoppingsReducers.total}
                    callMoreItems={callMoreItems}
                />
            </div>
            {
                showModal && typeModal === 'D' &&
                <Modal 
                    title="Penta ERP"
                    onClose={() => setShowModal(false)}
                    footer={
                        <React.Fragment>
                            <Button type='bad' full={false} onClick={handleCloseModal}>Cancelar</Button>
                            &nbsp;&nbsp;
                            <Button full={false} onClick={eliminar}>Aceptar</Button>
                        </React.Fragment>
                    }
                >
                    <React.Fragment>
                        <p>¿Desea eliminar los siguientes elemetos?</p>
                        {
                            props.shoppingsReducers.list.filter((x: any) => x.select).map(item =>
                                <ul key={`${item.serialnu}-${item.documnum}`} style={{margin: '1rem 2rem'}}>
                                    <li>{item.serialnu}-{item.documnum}</li>
                                </ul>
                            )
                        }
                    </React.Fragment>
                </Modal>
            }
            {
                showModal && typeModal === 'C' &&
                <Modal 
                    title="Penta ERP"
                    onClose={() => setShowModal(false)}
                    footer={
                        <React.Fragment>
                            <Button type='bad' full={false} onClick={handleCloseModal}>Cancelar</Button>
                            &nbsp;&nbsp;
                            <Button full={false} onClick={copy}>Aceptar</Button>
                        </React.Fragment>
                    }
                >
                    <React.Fragment>
                        <p>¿Desea copiar los datos del elemento {`${origin.serialnu}-${origin.documnum}`}?</p>
                        <br/>
                        <p>Oficina de destino:</p>
                        <Select label="Compañia" labelPosition="left" style={{marginTop: '1rem'}} data={listCompany} value={company} onChange={(value) => { setCompany(value.id); setDivision(''); setBussofic(''); } }/>
                        <Select label="Division" labelPosition="left" style={{marginTop: '1rem'}} data={listDivision} value={division} onChange={(value) => { setDivision(value.id); setBussofic(''); }}/>
                        <Select label="Oficina" labelPosition="left" style={{marginTop: '1rem'}} data={listBussofic} value={bussofic} onChange={(value) => setBussofic(value.id)}/>
                        <Checkbox label="Borrar elemento original" checked={deleteOrigin} onClick={(e: any) => setDeleteOrigin(e.target.checked)} containerStyle={{margin: '1rem 0rem'}}/>
                    </React.Fragment>
                </Modal>
            }
        </div>
    );
}

const authReducers = 'authReducers';
const dashboardReducers = 'dashboardReducers';
const reducer = 'shoppingsReducers';

const mapStateToProps = (state: any) => ({
    authReducers: state.get(authReducers),
    dashboardReducers: state.get(dashboardReducers),
    shoppingsReducers: state.get(reducer)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        purchasesGetAllRecordsRequest,
        purchasesListSelectItem,
        purchasesListSelectAllItem,
        purchasesActionFailed,
        purchasesDeleteRequest,
        purchasesMassiveUpdateRequest
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ListPurchases);