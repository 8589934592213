import React from 'react';
import { Backdrop, Button, ButtonGroup, Checkbox, CircularProgress, createStyles, FormControl, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Select, TextField, Theme, Tooltip, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { profitLossStatementRequest } from 'penta-redux/actions/accountantActions';
import history from '../../../../utils/history';
import './../reports.scss'
import FormFooter from 'penta-components/base/formFooter';
import { MessageBox } from 'penta-components/base/messageBox';
import { categoryEntity } from 'models/wharehouseProductsModels';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PdfView from 'penta-components/base/pdfView';
// import * as pdfMake from "pdfmake/build/pdfmake";
// import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
import * as XLSX from 'sheetjs-style';
import ScreenLoader from 'penta-components/base/screenLoader';

let pdfMake = require("pdfmake/build/pdfmake");
let saveAs = require('file-saver').saveAs;

const meses = [
    { key: '01', value: 'Enero' },
    { key: '02', value: 'Febrero' },
    { key: '03', value: 'Marzo' },
    { key: '04', value: 'Abril' },
    { key: '05', value: 'Mayo' },
    { key: '06', value: 'Junio' },
    { key: '07', value: 'Julio' },
    { key: '08', value: 'Agosto' },
    { key: '09', value: 'Septiembre' },
    { key: '10', value: 'Octubre' },
    { key: '11', value: 'Noviembre' },
    { key: '12', value: 'Diciembre' }
]

const useStyles = (theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        formControl: {
            margin: 0,
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    });

interface FormState {
    title: string,
    year: string,
    month: string,
    base64Pdf: string
}

class ReportProfitLossStatement extends React.Component<any, FormState> {

    constructor(props: any) {
        super(props);
        this.state = {
            title: 'Reporte de Estado de ganacias y perdidas',
            year: moment().format('yyyy').toString(),
            month: moment().format('MM').toString(),
            base64Pdf: ''
        }
    }

    onChangeRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            ...value
        }))
    }

    onGeneratePdf = () => {
        const data = this.props.accountantReducers.onlyOne;
        const { month, year } = this.state;
        const { dashboardReducers } = this.props;
        const mes = meses.find((x: any) => x.key === month).value;
        const company = (JSON.parse(localStorage.getItem('tables')).companys.find((x: any) => x.taxident === dashboardReducers.currentCompany).bussname as string).toUpperCase();

        const maxMonth = +month;

        const pdfObject: any = {
            pageSize: {
                width: 595.28,
                height: 1560
            },
            pageOrientation: 'landscape',
            pageMargins: [ 20, 70, 20, 20 ],
            header: [
                {
                    text: ' ', lineHeight: 2
                },
                {
                    margin: [20, 0, 0, 0],
                    text: company.toUpperCase(),
                    style: {
                        bold: true,
                        fontSize: 9
                    }
                },
                {
                    margin: [20, 0, 20, 0],
                    text: `ESTADO DE GANANCIAS Y PERDIDAS ${year}-${mes.toUpperCase()}`,
                    style: {
                        bold: true,
                        fontSize: 9
                    }
                },
            ],
            content: [
                {
                    table: {
                        headerRows: 2,
                        widths: [],
                        body: []
                    }
                }
            ],
            styles: {
                headerTitle: {
                    bold: true,
                    fontSize: 7,
                    lineHeight: 1.2
                },
                table: {
                    layout: 'headerLineOnly',
                    fontSize: 6,
                    alignment: 'center'
                },
                subTable: {
                    layout: 'headerLineOnly',
                    fontSize: 6,
                    alignment: 'left'
                },
                tableDetailLeft: {
                    layout: 'headerLineOnly',
                    fontSize: 6,
                    alignment: 'left'
                },
                tableDetailRight: {
                    layout: 'headerLineOnly',
                    fontSize: 6,
                    alignment: 'right'
                },
                tableDetailLeftBold: {
                    layout: 'headerLineOnly',
                    fontSize: 6,
                    alignment: 'left',
                    bold: true
                },
                tableDetailRightBold: {
                    layout: 'headerLineOnly',
                    fontSize: 6,
                    alignment: 'right',
                    bold: true
                }
            },
            defaultStyle: {
                columnGap: 20,
            },
        };

        let tableWidths = [ 150 ];
        for(let i = 1; i <= maxMonth; i++) {
            tableWidths.push(50);
            if(i > 1) tableWidths.push(50);
        }

        const monthRows = [
            { text: 'ENERO', style: 'table', colSpan: 1, border: [true, true, true, false] },
            { text: 'FEBRERO', style: 'table', colSpan: 2, border: [true, true, true, false] },
            { text: 'MARZO', style: 'table', colSpan: 2, border: [true, true, true, false] },
            { text: 'ABRIL', style: 'table', colSpan: 2, border: [true, true, true, false] },
            { text: 'MAYO', style: 'table', colSpan: 2, border: [true, true, true, false] },
            { text: 'JUNIO', style: 'table', colSpan: 2, border: [true, true, true, false] },
            { text: 'JULIO', style: 'table', colSpan: 2, border: [true, true, true, false] },
            { text: 'AGOSTO', style: 'table', colSpan: 2, border: [true, true, true, false] },
            { text: 'SEPTIEMBRE', style: 'table', colSpan: 2, border: [true, true, true, false] },
            { text: 'OCTUBRE', style: 'table', colSpan: 2, border: [true, true, true, false] },
            { text: 'NOVIEMBRE', style: 'table', colSpan: 2, border: [true, true, true, false] },
            { text: 'DICIEMBRE', style: 'table', colSpan: 2, border: [true, true, true, false] }
        ]

        const tableBody: any = [
            [ 
                { text: 'DETALLE', style: 'table', colSpan: 1, border: [true, true, false, false] },
            ],
            [ 
                { text: '', style: 'table', border: [true, false, false, true] }
            ]
        ];

        for(let i = 0; i < maxMonth; i++) {
            tableBody[0].push(monthRows[i]);
            tableBody[1].push({ text: 'MENSUAL', style: 'table', border: [true, false, false, true] });
            if(i > 0) {
                tableBody[0].push({});
                tableBody[1].push({ text: 'ACUMULADO', style: 'table', border: [false, false, true, true] });
            }
        }

        let subtotal = [];

        //#region 70100, 69100

        const array70100 = data.filter(x => x.accaccou === '70100' || x.accaccou === '70107');
        const array69100 = data.filter(x => x.accaccou === '69100');
        let totalbal: any = {};
        let totalacc: any = {};

        const body70100 = [{ text: array70100[0]?.denomina, style: 'tableDetailLeft', border: [true, false, true, false] }];
        for(let i = 1; i <= maxMonth; i++) {
            const balacode = `${year}${i.toString().padStart(2, '0')}`;
            const row = array70100.find((x: any) => x.balacode === balacode) || { totalbal: '0', totalacc: '0' };
            body70100.push({ text: String.formatNumber.new(row.totalbal), style: 'tableDetailRight', border: [true, false, true, false] });
            if(i > 1) body70100.push({ text: String.formatNumber.new(row.totalacc), style: 'tableDetailRight', border: [true, false, true, false] });
            totalbal[balacode] = (totalbal[balacode] || 0) + row.totalbal;
            totalacc[balacode] = (totalacc[balacode] || 0) + row.totalacc;
        }
        if(body70100.slice(1).reduce((x, y) => x + +y.text.replace(/,/g, ''), 0) > 0) tableBody.push(body70100);

        const body69100 = [{ text: array69100[0]?.denomina, style: 'tableDetailLeft', border: [true, false, true, false] }];
        for(let i = 1; i <= maxMonth; i++) {
            const balacode = `${year}${i.toString().padStart(2, '0')}`;
            const row = array69100.find((x: any) => x.balacode === balacode) || { totalbal: '0', totalacc: '0' };
            body69100.push({ text: String.formatNumber.new(row.totalbal), style: 'tableDetailRight', border: [true, false, true, false] });
            if(i > 1) body69100.push({ text: String.formatNumber.new(row.totalacc), style: 'tableDetailRight', border: [true, false, true, false] });
            totalbal[balacode] = (totalbal[balacode] || 0) + row.totalbal;
            totalacc[balacode] = (totalacc[balacode] || 0) + row.totalacc;
        }
        if(body69100.slice(1).reduce((x, y) => x + +y.text.replace(/,/g, ''), 0) > 0) tableBody.push(body69100);

        //
        subtotal = [{ text: '', style: 'tableDetailLeft', border: [true, false, true, false] }];
        for(let i = 1; i <= maxMonth; i++) {
            const balacode = `${year}${i.toString().padStart(2, '0')}`;
            subtotal.push({ text: String.formatNumber.new(totalbal[balacode]), style: 'tableDetailRight', border: [true, true, true, false] });
            if(i > 1) subtotal.push({ text: String.formatNumber.new(totalacc[balacode]), style: 'tableDetailRight', border: [true, true, true, false] });
        }
        if(subtotal.slice(1).reduce((x, y) => x + +y.text.replace(/,/g, ''), 0) > 0) tableBody.push(subtotal);
        subtotal = [{ text: ' ', lineHeight: 0.5, border: [true, false, true, false] }];
        for(let i = 1; i <= maxMonth; i++) {
            subtotal.push({ text: ' ', lineHeight: 0.5, border: [true, false, true, false] });
            if(i > 1) subtotal.push({ text: ' ', lineHeight: 0.5, border: [true, false, true, false] });
        }
        if(subtotal.slice(1).reduce((x, y) => x + +y.text.replace(/,/g, ''), 0) > 0) tableBody.push(subtotal);
        //

        //#endregion

        //#region 70101, 69101

        const array70101 = data.filter(x => x.accaccou === '70101');
        const array69101 = data.filter(x => x.accaccou === '69101');
        totalbal = {};
        totalacc = {};

        const body70101 = [{ text: array70101[0]?.denomina, style: 'tableDetailLeft', border: [true, false, true, false] }];
        for(let i = 1; i <= maxMonth; i++) {
            const balacode = `${year}${i.toString().padStart(2, '0')}`;
            const row = array70101.find((x: any) => x.balacode === balacode) || { totalbal: '0', totalacc: '0' };
            body70101.push({ text: String.formatNumber.new(row.totalbal), style: 'tableDetailRight', border: [true, false, true, false] });
            if(i > 1) body70101.push({ text: String.formatNumber.new(row.totalacc), style: 'tableDetailRight', border: [true, false, true, false] });
            totalbal[balacode] = (totalbal[balacode] || 0) + row.totalbal;
            totalacc[balacode] = (totalacc[balacode] || 0) + row.totalacc;
        }
        if(body70101.slice(1).reduce((x, y) => x + +y.text.replace(/,/g, ''), 0) > 0)  tableBody.push(body70101);

        const body69101 = [{ text: array69101[0]?.denomina, style: 'tableDetailLeft', border: [true, false, true, false] }];
        for(let i = 1; i <= maxMonth; i++) {
            const balacode = `${year}${i.toString().padStart(2, '0')}`;
            const row = array69101.find((x: any) => x.balacode === balacode) || { totalbal: '0', totalacc: '0' };
            body69101.push({ text: String.formatNumber.new(row.totalbal), style: 'tableDetailRight', border: [true, false, true, false] });
            if(i > 1) body69101.push({ text: String.formatNumber.new(row.totalacc), style: 'tableDetailRight', border: [true, false, true, false] });
            totalbal[balacode] = (totalbal[balacode] || 0) + row.totalbal;
            totalacc[balacode] = (totalacc[balacode] || 0) + row.totalacc;
        }
        if(body69101.slice(1).reduce((x, y) => x + +y.text.replace(/,/g, ''), 0) > 0) tableBody.push(body69101);

        //
        subtotal = [{ text: '', style: 'tableDetailLeft', border: [true, false, true, false] }];
        for(let i = 1; i <= maxMonth; i++) {
            const balacode = `${year}${i.toString().padStart(2, '0')}`;
            subtotal.push({ text: String.formatNumber.new(totalbal[balacode]), style: 'tableDetailRight', border: [true, true, true, false] });
            if(i > 1) subtotal.push({ text: String.formatNumber.new(totalacc[balacode]), style: 'tableDetailRight', border: [true, true, true, false] });
        }
        if(subtotal.slice(1).reduce((x, y) => x + +y.text.replace(/,/g, ''), 0) > 0) tableBody.push(subtotal);
        subtotal = [{ text: ' ', lineHeight: 0.5, border: [true, false, true, false] }];
        for(let i = 1; i <= maxMonth; i++) {
            subtotal.push({ text: ' ', lineHeight: 0.5, border: [true, false, true, false] });
            if(i > 1) subtotal.push({ text: ' ', lineHeight: 0.5, border: [true, false, true, false] });
        }
        if(subtotal.slice(1).reduce((x, y) => x + +y.text.replace(/,/g, ''), 0) > 0) tableBody.push(subtotal);
        //

        //#endregion

        //#region 70102, 69102

        const array70102 = data.filter(x => x.accaccou === '70102');
        const array69102 = data.filter(x => x.accaccou === '69102');
        totalbal = {};
        totalacc = {};

        const body70102 = [{ text: array70102[0]?.denomina, style: 'tableDetailLeft', border: [true, false, true, false] }];
        for(let i = 1; i <= maxMonth; i++) {
            const balacode = `${year}${i.toString().padStart(2, '0')}`;
            const row = array70102.find((x: any) => x.balacode === balacode) || { totalbal: '0', totalacc: '0' };
            body70102.push({ text: String.formatNumber.new(row.totalbal), style: 'tableDetailRight', border: [true, false, true, false] });
            if(i > 1) body70102.push({ text: String.formatNumber.new(row.totalacc), style: 'tableDetailRight', border: [true, false, true, false] });
            totalbal[balacode] = (totalbal[balacode] || 0) + row.totalbal;
            totalacc[balacode] = (totalacc[balacode] || 0) + row.totalacc;
        }
        if(body70102.slice(1).reduce((x, y) => x + +y.text.replace(/,/g, ''), 0) > 0) tableBody.push(body70102);

        const body69102 = [{ text: array69102[0]?.denomina, style: 'tableDetailLeft', border: [true, false, true, false] }];
        for(let i = 1; i <= maxMonth; i++) {
            const balacode = `${year}${i.toString().padStart(2, '0')}`;
            const row = array69102.find((x: any) => x.balacode === balacode) || { totalbal: '0', totalacc: '0' };
            body69102.push({ text: String.formatNumber.new(row.totalbal), style: 'tableDetailRight', border: [true, false, true, false] });
            if(i > 1) body69102.push({ text: String.formatNumber.new(row.totalacc), style: 'tableDetailRight', border: [true, false, true, false] });
            totalbal[balacode] = (totalbal[balacode] || 0) + row.totalbal;
            totalacc[balacode] = (totalacc[balacode] || 0) + row.totalacc;
        }
        if(body69102.slice(1).reduce((x, y) => x + +y.text.replace(/,/g, ''), 0) > 0) tableBody.push(body69102);

        //
        subtotal = [{ text: '', style: 'tableDetailLeft', border: [true, false, true, false] }];
        for(let i = 1; i <= maxMonth; i++) {
            const balacode = `${year}${i.toString().padStart(2, '0')}`;
            subtotal.push({ text: String.formatNumber.new(totalbal[balacode]), style: 'tableDetailRight', border: [true, true, true, false] });
            if(i > 1) subtotal.push({ text: String.formatNumber.new(totalacc[balacode]), style: 'tableDetailRight', border: [true, true, true, false] });
        }
        if(subtotal.slice(1).reduce((x, y) => x + +y.text.replace(/,/g, ''), 0) > 0) tableBody.push(subtotal);
        subtotal = [{ text: ' ', lineHeight: 0.5, border: [true, false, true, false] }];
        for(let i = 1; i <= maxMonth; i++) {
            subtotal.push({ text: ' ', lineHeight: 0.5, border: [true, false, true, false] });
            if(i > 1) subtotal.push({ text: ' ', lineHeight: 0.5, border: [true, false, true, false] });
        }
        if(subtotal.slice(1).reduce((x, y) => x + +y.text.replace(/,/g, ''), 0) > 0) tableBody.push(subtotal);
        //

        //#endregion

        //#region 70103, 69103

        const array70103 = data.filter(x => x.accaccou === '70103');
        const array69103 = data.filter(x => x.accaccou === '69103');
        totalbal = {};
        totalacc = {};

        const body70103 = [{ text: array70103[0]?.denomina, style: 'tableDetailLeft', border: [true, false, true, false] }];
        for(let i = 1; i <= maxMonth; i++) {
            const balacode = `${year}${i.toString().padStart(2, '0')}`;
            const row = array70103.find((x: any) => x.balacode === balacode) || { totalbal: '0', totalacc: '0' };
            body70103.push({ text: String.formatNumber.new(row.totalbal), style: 'tableDetailRight', border: [true, false, true, false] });
            if(i > 1) body70103.push({ text: String.formatNumber.new(row.totalacc), style: 'tableDetailRight', border: [true, false, true, false] });
            totalbal[balacode] = (totalbal[balacode] || 0) + row.totalbal;
            totalacc[balacode] = (totalacc[balacode] || 0) + row.totalacc;
        }
        if(body70103.slice(1).reduce((x, y) => x + +y.text.replace(/,/g, ''), 0) > 0) tableBody.push(body70103);

        const body69103 = [{ text: array69103[0]?.denomina, style: 'tableDetailLeft', border: [true, false, true, false] }];
        for(let i = 1; i <= maxMonth; i++) {
            const balacode = `${year}${i.toString().padStart(2, '0')}`;
            const row = array69103.find((x: any) => x.balacode === balacode) || { totalbal: '0', totalacc: '0' };
            body69103.push({ text: String.formatNumber.new(row.totalbal), style: 'tableDetailRight', border: [true, false, true, false] });
            if(i > 1) body69103.push({ text: String.formatNumber.new(row.totalacc), style: 'tableDetailRight', border: [true, false, true, false] });
            totalbal[balacode] = (totalbal[balacode] || 0) + row.totalbal;
            totalacc[balacode] = (totalacc[balacode] || 0) + row.totalacc;
        }
        if(body69103.slice(1).reduce((x, y) => x + +y.text.replace(/,/g, ''), 0) > 0) tableBody.push(body69103);

        //
        subtotal = [{ text: '', style: 'tableDetailLeft', border: [true, false, true, false] }];
        for(let i = 1; i <= maxMonth; i++) {
            const balacode = `${year}${i.toString().padStart(2, '0')}`;
            subtotal.push({ text: String.formatNumber.new(totalbal[balacode]), style: 'tableDetailRight', border: [true, true, true, false] });
            if(i > 1) subtotal.push({ text: String.formatNumber.new(totalacc[balacode]), style: 'tableDetailRight', border: [true, true, true, false] });
        }
        if(subtotal.slice(1).reduce((x, y) => x + +y.text.replace(/,/g, ''), 0) > 0) tableBody.push(subtotal);
        subtotal = [{ text: ' ', lineHeight: 0.5, border: [true, false, true, false] }];
        for(let i = 1; i <= maxMonth; i++) {
            subtotal.push({ text: ' ', lineHeight: 0.5, border: [true, false, true, false] });
            if(i > 1) subtotal.push({ text: ' ', lineHeight: 0.5, border: [true, false, true, false] });
        }
        if(subtotal.slice(1).reduce((x, y) => x + +y.text.replace(/,/g, ''), 0) > 0) tableBody.push(subtotal);
        //

        //#endregion

        //#region 70104, 69104

        const array70104 = data.filter(x => x.accaccou === '70104');
        const array69104 = data.filter(x => x.accaccou === '69104');
        totalbal = {};
        totalacc = {};

        const body70104 = [{ text: array70104[0]?.denomina, style: 'tableDetailLeft', border: [true, false, true, false] }];
        for(let i = 1; i <= maxMonth; i++) {
            const balacode = `${year}${i.toString().padStart(2, '0')}`;
            const row = array70104.find((x: any) => x.balacode === balacode) || { totalbal: '0', totalacc: '0' };
            body70104.push({ text: String.formatNumber.new(row.totalbal), style: 'tableDetailRight', border: [true, false, true, false] });
            if(i > 1) body70104.push({ text: String.formatNumber.new(row.totalacc), style: 'tableDetailRight', border: [true, false, true, false] });
            totalbal[balacode] = (totalbal[balacode] || 0) + row.totalbal;
            totalacc[balacode] = (totalacc[balacode] || 0) + row.totalacc;
        }
        if(body70104.slice(1).reduce((x, y) => x + +y.text.replace(/,/g, ''), 0) > 0) tableBody.push(body70104);

        const body69104 = [{ text: array69104[0]?.denomina, style: 'tableDetailLeft', border: [true, false, true, false] }];
        for(let i = 1; i <= maxMonth; i++) {
            const balacode = `${year}${i.toString().padStart(2, '0')}`;
            const row = array69104.find((x: any) => x.balacode === balacode) || { totalbal: '0', totalacc: '0' };
            body69104.push({ text: String.formatNumber.new(row.totalbal), style: 'tableDetailRight', border: [true, false, true, false] });
            if(i > 1) body69104.push({ text: String.formatNumber.new(row.totalacc), style: 'tableDetailRight', border: [true, false, true, false] });
            totalbal[balacode] = (totalbal[balacode] || 0) + row.totalbal;
            totalacc[balacode] = (totalacc[balacode] || 0) + row.totalacc;
        }
        if(body69104.slice(1).reduce((x, y) => x + +y.text.replace(/,/g, ''), 0) > 0) tableBody.push(body69104);

        //
        subtotal = [{ text: '', style: 'tableDetailLeft', border: [true, false, true, false] }];
        for(let i = 1; i <= maxMonth; i++) {
            const balacode = `${year}${i.toString().padStart(2, '0')}`;
            subtotal.push({ text: String.formatNumber.new(totalbal[balacode]), style: 'tableDetailRight', border: [true, true, true, false] });
            if(i > 1) subtotal.push({ text: String.formatNumber.new(totalacc[balacode]), style: 'tableDetailRight', border: [true, true, true, false] });
        }
        if(subtotal.slice(1).reduce((x, y) => x + +y.text.replace(/,/g, ''), 0) > 0) tableBody.push(subtotal);
        subtotal = [{ text: ' ', lineHeight: 0.5, border: [true, false, true, false] }];
        for(let i = 1; i <= maxMonth; i++) {
            subtotal.push({ text: ' ', lineHeight: 0.5, border: [true, false, true, false] });
            if(i > 1) subtotal.push({ text: ' ', lineHeight: 0.5, border: [true, false, true, false] });
        }
        if(subtotal.slice(1).reduce((x, y) => x + +y.text.replace(/,/g, ''), 0) > 0) tableBody.push(subtotal);
        //

        //#endregion

        //#region 704

        const array704 = data.filter(x => x.accaccou === '704');
        totalbal = {};
        totalacc = {};

        const body704 = [{ text: array704[0]?.denomina, style: 'tableDetailLeft', border: [true, false, true, false] }];
        for(let i = 1; i <= maxMonth; i++) {
            const balacode = `${year}${i.toString().padStart(2, '0')}`;
            const row = array704.find((x: any) => x.balacode === balacode) || { totalbal: '0', totalacc: '0' };
            body704.push({ text: String.formatNumber.new(row.totalbal), style: 'tableDetailRight', border: [true, false, true, false] });
            if(i > 1) body704.push({ text: String.formatNumber.new(row.totalacc), style: 'tableDetailRight', border: [true, false, true, false] });
            totalbal[balacode] = (totalbal[balacode] || 0) + row.totalbal;
            totalacc[balacode] = (totalacc[balacode] || 0) + row.totalacc;
        }
        if(body704.slice(1).reduce((x, y) => x + +y.text.replace(/,/g, ''), 0) > 0) tableBody.push(body704);

        //
        subtotal = [{ text: '', style: 'tableDetailLeft', border: [true, false, true, false] }];
        for(let i = 1; i <= maxMonth; i++) {
            const balacode = `${year}${i.toString().padStart(2, '0')}`;
            subtotal.push({ text: String.formatNumber.new(totalbal[balacode]), style: 'tableDetailRight', border: [true, true, true, false] });
            if(i > 1) subtotal.push({ text: String.formatNumber.new(totalacc[balacode]), style: 'tableDetailRight', border: [true, true, true, false] });
        }
        if(subtotal.slice(1).reduce((x, y) => x + +y.text.replace(/,/g, ''), 0) > 0) tableBody.push(subtotal);
        subtotal = [{ text: ' ', lineHeight: 0.5, border: [true, false, true, false] }];
        for(let i = 1; i <= maxMonth; i++) {
            subtotal.push({ text: ' ', lineHeight: 0.5, border: [true, false, true, false] });
            if(i > 1) subtotal.push({ text: ' ', lineHeight: 0.5, border: [true, false, true, false] });
        }
        if(subtotal.slice(1).reduce((x, y) => x + +y.text.replace(/,/g, ''), 0) > 0) tableBody.push(subtotal);
        //

        //#endregion
        
        //#region UTILIDAD BRUTA

        const array = data.filter(x => ['70100','69100','70101','69101','70102','69102','70103','69103','70104','69104','704'].includes(x.accaccou));
        totalbal = {};
        totalacc = {};

        const body = [{ text: 'UTILIDAD BRUTA', style: 'tableDetailLeft', border: [true, false, true, false] }];
        for(let i = 1; i <= maxMonth; i++) {
            const balacode = `${year}${i.toString().padStart(2, '0')}`;
            const _totalbal = array.filter((x: any) => x.balacode === balacode).reduce((x, y) => x + y.totalbal, 0);
            const _totalacc = array.filter((x: any) => x.balacode === balacode).reduce((x, y) => x + y.totalacc, 0);
            body.push({ text: String.formatNumber.new(_totalbal), style: 'tableDetailRight', border: [true, true, true, false] });
            if(i > 1) body.push({ text: String.formatNumber.new(_totalacc), style: 'tableDetailRight', border: [true, true, true, false] });
            totalbal[balacode] = (totalbal[balacode] || 0) + _totalbal;
            totalacc[balacode] = (totalacc[balacode] || 0) + _totalacc;
        }
        tableBody.push(body);

        //
        subtotal = [{ text: ' ', lineHeight: 0.5, border: [true, false, true, false] }];
        for(let i = 1; i <= maxMonth; i++) {
            subtotal.push({ text: ' ', lineHeight: 0.5, border: [true, false, true, false] });
            if(i > 1) subtotal.push({ text: ' ', lineHeight: 0.5, border: [true, false, true, false] });
        }
        tableBody.push(subtotal);
        //

        //#endregion
        
        //#region 91, 92, 93, 94, 95, 96, 97 + 99

        const array91 = data.filter(x => x.accaccou === '91');
        const array92 = data.filter(x => x.accaccou === '92');
        const array93 = data.filter(x => x.accaccou === '93');
        const array94 = data.filter(x => x.accaccou === '94');
        const array95 = data.filter(x => x.accaccou === '95');
        const array96 = data.filter(x => x.accaccou === '96');
        const array9799 = data.filter(x => ['97','99'].includes(x.accaccou));
        // totalbal = {};
        // totalacc = {};

        const body91 = [{ text: array91[0]?.denomina, style: 'tableDetailLeft', border: [true, false, true, false] }];
        for(let i = 1; i <= maxMonth; i++) {
            const balacode = `${year}${i.toString().padStart(2, '0')}`;
            const row = array91.find((x: any) => x.balacode === balacode) || { totalbal: '0', totalacc: '0' };
            body91.push({ text: String.formatNumber.new(row.totalbal), style: 'tableDetailRight', border: [true, false, true, false] });
            if(i > 1) body91.push({ text: String.formatNumber.new(row.totalacc), style: 'tableDetailRight', border: [true, false, true, false] });
            totalbal[balacode] = (totalbal[balacode] || 0) + row.totalbal;
            totalacc[balacode] = (totalacc[balacode] || 0) + row.totalacc;
        }
        if(body91.slice(1).reduce((x, y) => x + +y.text.replace(/,/g, ''), 0) > 0) tableBody.push(body91);

        const body92 = [{ text: array92[0]?.denomina, style: 'tableDetailLeft', border: [true, false, true, false] }];
        for(let i = 1; i <= maxMonth; i++) {
            const balacode = `${year}${i.toString().padStart(2, '0')}`;
            const row = array92.find((x: any) => x.balacode === balacode) || { totalbal: '0', totalacc: '0' };
            body92.push({ text: String.formatNumber.new(row.totalbal), style: 'tableDetailRight', border: [true, false, true, false] });
            if(i > 1) body92.push({ text: String.formatNumber.new(row.totalacc), style: 'tableDetailRight', border: [true, false, true, false] });
            totalbal[balacode] = (totalbal[balacode] || 0) + row.totalbal;
            totalacc[balacode] = (totalacc[balacode] || 0) + row.totalacc;
        }
        if(body92.slice(1).reduce((x, y) => x + +y.text.replace(/,/g, ''), 0) > 0) tableBody.push(body92);

        const body93 = [{ text: array93[0]?.denomina, style: 'tableDetailLeft', border: [true, false, true, false] }];
        for(let i = 1; i <= maxMonth; i++) {
            const balacode = `${year}${i.toString().padStart(2, '0')}`;
            const row = array93.find((x: any) => x.balacode === balacode) || { totalbal: '0', totalacc: '0' };
            body93.push({ text: String.formatNumber.new(row.totalbal), style: 'tableDetailRight', border: [true, false, true, false] });
            if(i > 1) body93.push({ text: String.formatNumber.new(row.totalacc), style: 'tableDetailRight', border: [true, false, true, false] });
            totalbal[balacode] = (totalbal[balacode] || 0) + row.totalbal;
            totalacc[balacode] = (totalacc[balacode] || 0) + row.totalacc;
        }
        if(body93.slice(1).reduce((x, y) => x + +y.text.replace(/,/g, ''), 0) > 0) tableBody.push(body93);

        const body94 = [{ text: array94[0]?.denomina, style: 'tableDetailLeft', border: [true, false, true, false] }];
        for(let i = 1; i <= maxMonth; i++) {
            const balacode = `${year}${i.toString().padStart(2, '0')}`;
            const row = array94.find((x: any) => x.balacode === balacode) || { totalbal: '0', totalacc: '0' };
            body94.push({ text: String.formatNumber.new(row.totalbal), style: 'tableDetailRight', border: [true, false, true, false] });
            if(i > 1) body94.push({ text: String.formatNumber.new(row.totalacc), style: 'tableDetailRight', border: [true, false, true, false] });
            totalbal[balacode] = (totalbal[balacode] || 0) + row.totalbal;
            totalacc[balacode] = (totalacc[balacode] || 0) + row.totalacc;
        }
        if(body94.slice(1).reduce((x, y) => x + +y.text.replace(/,/g, ''), 0) > 0) tableBody.push(body94);

        const body95 = [{ text: array95[0]?.denomina, style: 'tableDetailLeft', border: [true, false, true, false] }];
        for(let i = 1; i <= maxMonth; i++) {
            const balacode = `${year}${i.toString().padStart(2, '0')}`;
            const row = array95.find((x: any) => x.balacode === balacode) || { totalbal: '0', totalacc: '0' };
            body95.push({ text: String.formatNumber.new(row.totalbal), style: 'tableDetailRight', border: [true, false, true, false] });
            if(i > 1) body95.push({ text: String.formatNumber.new(row.totalacc), style: 'tableDetailRight', border: [true, false, true, false] });
            totalbal[balacode] = (totalbal[balacode] || 0) + row.totalbal;
            totalacc[balacode] = (totalacc[balacode] || 0) + row.totalacc;
        }
        if(body95.slice(1).reduce((x, y) => x + +y.text.replace(/,/g, ''), 0) > 0) tableBody.push(body95);

        const body96 = [{ text: array96[0]?.denomina, style: 'tableDetailLeft', border: [true, false, true, false] }];
        for(let i = 1; i <= maxMonth; i++) {
            const balacode = `${year}${i.toString().padStart(2, '0')}`;
            const row = array96.find((x: any) => x.balacode === balacode) || { totalbal: '0', totalacc: '0' };
            body96.push({ text: String.formatNumber.new(row.totalbal), style: 'tableDetailRight', border: [true, false, true, false] });
            if(i > 1) body96.push({ text: String.formatNumber.new(row.totalacc), style: 'tableDetailRight', border: [true, false, true, false] });
            totalbal[balacode] = (totalbal[balacode] || 0) + row.totalbal;
            totalacc[balacode] = (totalacc[balacode] || 0) + row.totalacc;
        }
        if(body96.slice(1).reduce((x, y) => x + +y.text.replace(/,/g, ''), 0) > 0) tableBody.push(body96);

        const body9799 = [{ text: 'GASTOS ADMINISTRATIVOS LIMA', style: 'tableDetailLeft', border: [true, false, true, false] }];
        for(let i = 1; i <= maxMonth; i++) {
            const balacode = `${year}${i.toString().padStart(2, '0')}`;
            const _totalbal = array9799.filter((x: any) => x.balacode === balacode).reduce((x, y) => x + y.totalbal, 0);
            const _totalacc = array9799.filter((x: any) => x.balacode === balacode).reduce((x, y) => x + y.totalacc, 0);
            body9799.push({ text: String.formatNumber.new(_totalbal), style: 'tableDetailRight', border: [true, false, true, false] });
            if(i > 1) body9799.push({ text: String.formatNumber.new(_totalacc), style: 'tableDetailRight', border: [true, false, true, false] });
            totalbal[balacode] = (totalbal[balacode] || 0) + _totalbal;
            totalacc[balacode] = (totalacc[balacode] || 0) + _totalacc;
        }
        if(body9799.slice(1).reduce((x, y) => x + +y.text.replace(/,/g, ''), 0) > 0) tableBody.push(body9799);

        //
        subtotal = [{ text: '', style: 'tableDetailLeft', border: [true, false, true, false] }];
        for(let i = 1; i <= maxMonth; i++) {
            const balacode = `${year}${i.toString().padStart(2, '0')}`;
            subtotal.push({ text: String.formatNumber.new(totalbal[balacode]), style: 'tableDetailRight', border: [true, true, true, false] });
            if(i > 1) subtotal.push({ text: String.formatNumber.new(totalacc[balacode]), style: 'tableDetailRight', border: [true, true, true, false] });
        }
        if(subtotal.slice(1).reduce((x, y) => x + +y.text.replace(/,/g, ''), 0) > 0) tableBody.push(subtotal);
        subtotal = [{ text: ' ', lineHeight: 0.5, border: [true, false, true, false] }];
        for(let i = 1; i <= maxMonth; i++) {
            subtotal.push({ text: ' ', lineHeight: 0.5, border: [true, false, true, false] });
            if(i > 1) subtotal.push({ text: ' ', lineHeight: 0.5, border: [true, false, true, false] });
        }
        if(subtotal.slice(1).reduce((x, y) => x + +y.text.replace(/,/g, ''), 0) > 0) tableBody.push(subtotal);
        //

        //#endregion

        //#region 67, 75, 77

        const array65 = data.filter(x => x.accaccou === '65');
        const array75 = data.filter(x => x.accaccou === '75');
        const array77 = data.filter(x => x.accaccou === '77');
        // totalbal = {};
        // totalacc = {};

        const body65 = [{ text: array65[0]?.denomina, style: 'tableDetailLeft', border: [true, false, true, false] }];
        for(let i = 1; i <= maxMonth; i++) {
            const balacode = `${year}${i.toString().padStart(2, '0')}`;
            const row = array65.find((x: any) => x.balacode === balacode) || { totalbal: '0', totalacc: '0' };
            body65.push({ text: String.formatNumber.new(row.totalbal), style: 'tableDetailRight', border: [true, false, true, false] });
            if(i > 1) body65.push({ text: String.formatNumber.new(row.totalacc), style: 'tableDetailRight', border: [true, false, true, false] });
            totalbal[balacode] = (totalbal[balacode] || 0) + row.totalbal;
            totalacc[balacode] = (totalacc[balacode] || 0) + row.totalacc;
        }
        if(body65.slice(1).reduce((x, y) => x + +y.text.replace(/,/g, ''), 0) > 0) tableBody.push(body65);

        const body75 = [{ text: array75[0]?.denomina, style: 'tableDetailLeft', border: [true, false, true, false] }];
        for(let i = 1; i <= maxMonth; i++) {
            const balacode = `${year}${i.toString().padStart(2, '0')}`;
            const row = array75.find((x: any) => x.balacode === balacode) || { totalbal: '0', totalacc: '0' };
            body75.push({ text: String.formatNumber.new(row.totalbal), style: 'tableDetailRight', border: [true, false, true, false] });
            if(i > 1) body75.push({ text: String.formatNumber.new(row.totalacc), style: 'tableDetailRight', border: [true, false, true, false] });
            totalbal[balacode] = (totalbal[balacode] || 0) + row.totalbal;
            totalacc[balacode] = (totalacc[balacode] || 0) + row.totalacc;
        }
        if(body75.slice(1).reduce((x, y) => x + +y.text.replace(/,/g, ''), 0) > 0) tableBody.push(body75);

        const body77 = [{ text: array77[0]?.denomina, style: 'tableDetailLeft', border: [true, false, true, false] }];
        for(let i = 1; i <= maxMonth; i++) {
            const balacode = `${year}${i.toString().padStart(2, '0')}`;
            const row = array77.find((x: any) => x.balacode === balacode) || { totalbal: '0', totalacc: '0' };
            body77.push({ text: String.formatNumber.new(row.totalbal), style: 'tableDetailRight', border: [true, false, true, false] });
            if(i > 1) body77.push({ text: String.formatNumber.new(row.totalacc), style: 'tableDetailRight', border: [true, false, true, false] });
            totalbal[balacode] = (totalbal[balacode] || 0) + row.totalbal;
            totalacc[balacode] = (totalacc[balacode] || 0) + row.totalacc;
        }
        if(body77.slice(1).reduce((x, y) => x + +y.text.replace(/,/g, ''), 0) > 0) tableBody.push(body77);

        //
        subtotal = [{ text: 'UTILIDAD ANTES DEL IMPUESTO', style: 'tableDetailLeft', border: [true, false, true, false] }];
        for(let i = 1; i <= maxMonth; i++) {
            const balacode = `${year}${i.toString().padStart(2, '0')}`;
            subtotal.push({ text: String.formatNumber.new(totalbal[balacode]), style: 'tableDetailRight', border: [true, true, true, false] });
            if(i > 1) subtotal.push({ text: String.formatNumber.new(totalacc[balacode]), style: 'tableDetailRight', border: [true, true, true, false] });
        }
        tableBody.push(subtotal);
        subtotal = [{ text: 'ADICIONES', style: 'tableDetailLeft', border: [true, false, true, false] }];
        for(let i = 1; i <= maxMonth; i++) {
            subtotal.push({ text: ' ', border: [true, false, true, false] });
            if(i > 1) subtotal.push({ text: ' ', border: [true, false, true, false] });
        }
        tableBody.push(subtotal);
        subtotal = [{ text: 'UTILIDAD TRIBUTARIA ANTES DEL IMPUESTO', style: 'tableDetailLeft', border: [true, false, true, false] }];
        for(let i = 1; i <= maxMonth; i++) {
            subtotal.push({ text: ' ', border: [true, false, true, false] });
            if(i > 1) subtotal.push({ text: ' ', border: [true, false, true, false] });
        }
        tableBody.push(subtotal);
        //

        //#endregion
        
        //#region IMPUESTO A LA RENTA

        //
        subtotal = [{ text: 'IMPUESTO A LA RENTA', style: 'tableDetailLeft', border: [true, false, true, false] }];
        for(let i = 1; i <= maxMonth; i++) {
            const balacode = `${year}${i.toString().padStart(2, '0')}`;
            subtotal.push({ text: String.formatNumber.new(0 - totalbal[balacode]*0.28), style: 'tableDetailRight', border: [true, true, true, false] });
            if(i > 1) subtotal.push({ text: String.formatNumber.new(0 - totalacc[balacode]*0.28), style: 'tableDetailRight', border: [true, true, true, false] });
        }
        tableBody.push(subtotal);
        subtotal = [{ text: ' ', lineHeight: 0.5, border: [true, false, true, false] }];
        for(let i = 1; i <= maxMonth; i++) {
            subtotal.push({ text: ' ', lineHeight: 0.5, border: [true, false, true, false] });
            if(i > 1) subtotal.push({ text: ' ', lineHeight: 0.5, border: [true, false, true, false] });
        }
        //

        //#endregion

        subtotal = [{ text: 'RESULTADO DEL EJERCICIO - TRIBUTARIO', style: 'tableDetailLeft', border: [true, true, true, true] }];
        for(let i = 1; i <= maxMonth; i++) {
            subtotal.push({ text: '', style: 'tableDetailRight', border: [true, true, true, true] });
            if(i > 1) subtotal.push({ text: '', style: 'tableDetailRight', border: [true, true, true, true] });
        }
        tableBody.push(subtotal);
        subtotal = [{ text: 'RESULTADO DEL EJERCICIO - CONTABLE', style: 'tableDetailLeft', border: [true, true, true, true] }];
        for(let i = 1; i <= maxMonth; i++) {
            const balacode = `${year}${i.toString().padStart(2, '0')}`;
            subtotal.push({ text: String.formatNumber.new(totalbal[balacode] + (0 - totalbal[balacode]*0.28)), style: 'tableDetailRight', border: [true, true, true, true] });
            if(i > 1) subtotal.push({ text: String.formatNumber.new(totalacc[balacode] + (0 - totalacc[balacode]*0.28)), style: 'tableDetailRight', border: [true, true, true, true] });
        }
        tableBody.push(subtotal);

        pdfObject.content[0].table.widths = tableWidths;
        pdfObject.content[0].table.body = tableBody;

        pdfMake.fonts = {
            Roboto: {
                normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
                bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
                italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
                bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
              },
        }
        const pdfDocGenerator = pdfMake.createPdf(pdfObject);
        pdfDocGenerator.getDataUrl((data: any) => {
            // console.log(data);
            this.setState({
                base64Pdf: data
            })
        });

    }

    getGrifoPisco = (data, tableBody, hiddenRow, year) => {

        //#region 70100, 69100

        const array70100 = data.filter(x => x.accaccou === '70100');
        const array70107 = data.filter(x => x.accaccou === '70107');
        const array691000 = data.filter(x => x.accaccou === '69100');
        const array69108 = data.filter(x => x.accaccou === '69108');
        let totalbal: any = {};
        let totalacc: any = {};

        tableBody.push(
            {
                A:  null,
                B:  'VENTAS NETAS GRIFO',
                C:  (array70100.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0) + (array70107.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0),
                D:  (array70100.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0) + (array70107.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0),
                E:  (array70100.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0) + (array70107.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0),
                F:  (array70100.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0) + (array70107.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0),
                G:  (array70100.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0) + (array70107.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0),
                H:  (array70100.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0) + (array70107.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0),
                I:  (array70100.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0) + (array70107.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0),
                J:  (array70100.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0) + (array70107.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0),
                K:  (array70100.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0) + (array70107.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0),
                L:  (array70100.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0) + (array70107.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0),
                M:  (array70100.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0) + (array70107.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0),
                N:  (array70100.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0) + (array70107.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0),
                O:  (array70100.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0) + (array70107.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0),
                P:  (array70100.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0) + (array70107.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0),
                Q:  (array70100.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0) + (array70107.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0),
                R:  (array70100.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0) + (array70107.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0),
                S:  (array70100.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0) + (array70107.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0),
                T:  (array70100.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0) + (array70107.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0),
                U:  (array70100.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0) + (array70107.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0),
                V:  (array70100.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0) + (array70107.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0),
                W:  (array70100.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0) + (array70107.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0),
                X:  (array70100.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0) + (array70107.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0),
                Y:  (array70100.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0) + (array70107.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0)
            }
        );

        tableBody.push(
            {
                A:  null,
                B:  '(-) COSTO DE VENTAS GRIFO',
                C:  (array691000.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0) + (array69108.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0),
                D:  (array691000.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0) + (array69108.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0),
                E:  (array691000.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0) + (array69108.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0),
                F:  (array691000.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0) + (array69108.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0),
                G:  (array691000.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0) + (array69108.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0),
                H:  (array691000.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0) + (array69108.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0),
                I:  (array691000.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0) + (array69108.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0),
                J:  (array691000.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0) + (array69108.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0),
                K:  (array691000.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0) + (array69108.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0),
                L:  (array691000.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0) + (array69108.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0),
                M:  (array691000.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0) + (array69108.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0),
                N:  (array691000.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0) + (array69108.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0),
                O:  (array691000.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0) + (array69108.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0),
                P:  (array691000.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0) + (array69108.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0),
                Q:  (array691000.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0) + (array69108.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0),
                R:  (array691000.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0) + (array69108.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0),
                S:  (array691000.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0) + (array69108.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0),
                T:  (array691000.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0) + (array69108.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0),
                U:  (array691000.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0) + (array69108.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0),
                V:  (array691000.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0) + (array69108.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0),
                W:  (array691000.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0) + (array69108.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0),
                X:  (array691000.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0) + (array69108.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0),
                Y:  (array691000.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0) + (array69108.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0)
            }
        );

        tableBody.push(
            {
                A:  null,
                B:  '',
                C:  (array70100.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0) + (array70107.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0) + (array691000.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0) + (array69108.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0),
                D:  (array70100.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0) + (array70107.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0) + (array691000.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0) + (array69108.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0),
                E:  (array70100.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0) + (array70107.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0) + (array691000.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0) + (array69108.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0),
                F:  (array70100.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0) + (array70107.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0) + (array691000.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0) + (array69108.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0),
                G:  (array70100.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0) + (array70107.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0) + (array691000.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0) + (array69108.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0),
                H:  (array70100.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0) + (array70107.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0) + (array691000.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0) + (array69108.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0),
                I:  (array70100.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0) + (array70107.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0) + (array691000.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0) + (array69108.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0),
                J:  (array70100.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0) + (array70107.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0) + (array691000.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0) + (array69108.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0),
                K:  (array70100.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0) + (array70107.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0) + (array691000.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0) + (array69108.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0),
                L:  (array70100.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0) + (array70107.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0) + (array691000.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0) + (array69108.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0),
                M:  (array70100.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0) + (array70107.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0) + (array691000.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0) + (array69108.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0),
                N:  (array70100.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0) + (array70107.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0) + (array691000.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0) + (array69108.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0),
                O:  (array70100.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0) + (array70107.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0) + (array691000.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0) + (array69108.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0),
                P:  (array70100.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0) + (array70107.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0) + (array691000.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0) + (array69108.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0),
                Q:  (array70100.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0) + (array70107.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0) + (array691000.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0) + (array69108.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0),
                R:  (array70100.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0) + (array70107.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0) + (array691000.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0) + (array69108.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0),
                S:  (array70100.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0) + (array70107.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0) + (array691000.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0) + (array69108.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0),
                T:  (array70100.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0) + (array70107.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0) + (array691000.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0) + (array69108.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0),
                U:  (array70100.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0) + (array70107.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0) + (array691000.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0) + (array69108.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0),
                V:  (array70100.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0) + (array70107.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0) + (array691000.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0) + (array69108.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0),
                W:  (array70100.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0) + (array70107.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0) + (array691000.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0) + (array69108.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0),
                X:  (array70100.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0) + (array70107.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0) + (array691000.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0) + (array69108.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0),
                Y:  (array70100.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0) + (array70107.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0) + (array691000.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0) + (array69108.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0)
            }
        );

        tableBody.push(
            {
                A:  null,
                B:  '',
                C:  '',
                D:  '',
                E:  '',
                F:  '',
                G:  '',
                H:  '',
                I:  '',
                J:  '',
                K:  '',
                L:  '',
                M:  '',
                N:  '',
                O:  '',
                P:  '',
                Q:  '',
                R:  '',
                S:  '',
                T:  '',
                U:  '',
                V:  '',
                W:  '',
                X:  '',
                Y:  ''
            }
        );

        if(array70100.reduce((x, y) => x + (y.totalbal + y.totalacc), 0) + array70107.reduce((x, y) => x + (y.totalbal + y.totalacc), 0) === 0) hiddenRow.push(6);
        if(array691000.reduce((x, y) => x + (y.totalbal + y.totalacc), 0) + array69108.reduce((x, y) => x + (y.totalbal + y.totalacc), 0) === 0) hiddenRow.push(7);
        if([...array70100, ...array70107, ...array691000, ...array69108].reduce((x, y) => x + (y.totalbal + y.totalacc), 0) === 0) 
        {
            hiddenRow.push(8);
            hiddenRow.push(9);
        }

        //#endregion

        //#region 70101, 69101

        const array70101 = data.filter(x => x.accaccou === '70101');
        const array69101 = data.filter(x => x.accaccou === '69101');
        totalbal = {};
        totalacc = {};

        tableBody.push(
            {
                A:  null,
                B:  'VENTAS NETAS SHOP',
                C:  array70101.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0,
                D:  array70101.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0,
                E:  array70101.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0,
                F:  array70101.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0,
                G:  array70101.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0,
                H:  array70101.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0,
                I:  array70101.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0,
                J:  array70101.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0,
                K:  array70101.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0,
                L:  array70101.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0,
                M:  array70101.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0,
                N:  array70101.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0,
                O:  array70101.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0,
                P:  array70101.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0,
                Q:  array70101.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0,
                R:  array70101.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0,
                S:  array70101.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0,
                T:  array70101.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0,
                U:  array70101.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0,
                V:  array70101.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0,
                W:  array70101.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0,
                X:  array70101.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0,
                Y:  array70101.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0
            }
        );

        tableBody.push(
            {
                A:  null,
                B:  '(-) COSTO DE VENTAS SHOP',
                C:  array69101.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0,
                D:  array69101.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0,
                E:  array69101.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0,
                F:  array69101.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0,
                G:  array69101.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0,
                H:  array69101.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0,
                I:  array69101.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0,
                J:  array69101.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0,
                K:  array69101.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0,
                L:  array69101.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0,
                M:  array69101.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0,
                N:  array69101.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0,
                O:  array69101.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0,
                P:  array69101.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0,
                Q:  array69101.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0,
                R:  array69101.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0,
                S:  array69101.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0,
                T:  array69101.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0,
                U:  array69101.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0,
                V:  array69101.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0,
                W:  array69101.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0,
                X:  array69101.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0,
                Y:  array69101.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0
            }
        );

        tableBody.push(
            {
                A:  null,
                B:  '',
                C:  (array70101.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0) + (array69101.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0),
                D:  (array70101.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0) + (array69101.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0),
                E:  (array70101.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0) + (array69101.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0),
                F:  (array70101.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0) + (array69101.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0),
                G:  (array70101.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0) + (array69101.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0),
                H:  (array70101.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0) + (array69101.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0),
                I:  (array70101.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0) + (array69101.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0),
                J:  (array70101.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0) + (array69101.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0),
                K:  (array70101.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0) + (array69101.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0),
                L:  (array70101.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0) + (array69101.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0),
                M:  (array70101.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0) + (array69101.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0),
                N:  (array70101.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0) + (array69101.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0),
                O:  (array70101.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0) + (array69101.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0),
                P:  (array70101.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0) + (array69101.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0),
                Q:  (array70101.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0) + (array69101.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0),
                R:  (array70101.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0) + (array69101.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0),
                S:  (array70101.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0) + (array69101.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0),
                T:  (array70101.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0) + (array69101.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0),
                U:  (array70101.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0) + (array69101.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0),
                V:  (array70101.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0) + (array69101.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0),
                W:  (array70101.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0) + (array69101.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0),
                X:  (array70101.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0) + (array69101.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0),
                Y:  (array70101.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0) + (array69101.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0)
            }
        );

        tableBody.push(
            {
                A:  null,
                B:  '',
                C:  '',
                D:  '',
                E:  '',
                F:  '',
                G:  '',
                H:  '',
                I:  '',
                J:  '',
                K:  '',
                L:  '',
                M:  '',
                N:  '',
                O:  '',
                P:  '',
                Q:  '',
                R:  '',
                S:  '',
                T:  '',
                U:  '',
                V:  '',
                W:  '',
                X:  '',
                Y:  ''
            }
        );

        if(array70101.reduce((x, y) => x + (y.totalbal + y.totalacc), 0) === 0) hiddenRow.push(10);
        if(array69101.reduce((x, y) => x + (y.totalbal + y.totalacc), 0) === 0) hiddenRow.push(11);
        if([...array70101, ...array69101].reduce((x, y) => x + (y.totalbal + y.totalacc), 0) === 0) 
        {
            hiddenRow.push(12);
            hiddenRow.push(13);
        }

        //#endregion

        //#region 70103, 69103

        const array70103 = data.filter(x => x.accaccou === '70103');
        const array69103 = data.filter(x => x.accaccou === '69103');
        totalbal = {};
        totalacc = {};

        tableBody.push(
            {
                A:  null,
                B:  'VENTAS NETAS GIMNASIO',
                C:  array70103.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0,
                D:  array70103.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0,
                E:  array70103.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0,
                F:  array70103.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0,
                G:  array70103.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0,
                H:  array70103.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0,
                I:  array70103.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0,
                J:  array70103.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0,
                K:  array70103.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0,
                L:  array70103.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0,
                M:  array70103.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0,
                N:  array70103.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0,
                O:  array70103.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0,
                P:  array70103.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0,
                Q:  array70103.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0,
                R:  array70103.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0,
                S:  array70103.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0,
                T:  array70103.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0,
                U:  array70103.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0,
                V:  array70103.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0,
                W:  array70103.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0,
                X:  array70103.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0,
                Y:  array70103.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0
            }
        );

        tableBody.push(
            {
                A: null,
                B: '(-) COSTO DE VENTAS GIMNASIO',
                C: array69103.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0,
                D: array69103.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0,
                E: array69103.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0,
                F: array69103.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0,
                G: array69103.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0,
                H: array69103.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0,
                I: array69103.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0,
                J: array69103.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0,
                K: array69103.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0,
                L: array69103.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0,
                M: array69103.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0,
                N: array69103.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0,
                O: array69103.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0,
                P: array69103.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0,
                Q: array69103.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0,
                R: array69103.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0,
                S: array69103.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0,
                T: array69103.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0,
                U: array69103.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0,
                V: array69103.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0,
                W: array69103.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0,
                X: array69103.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0,
                Y: array69103.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0
            }
        );

        tableBody.push(
            {
                A: null,
                B: '',
                C: (array70103.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0) + (array69103.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0),
                D: (array70103.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0) + (array69103.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0),
                E: (array70103.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0) + (array69103.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0),
                F: (array70103.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0) + (array69103.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0),
                G: (array70103.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0) + (array69103.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0),
                H: (array70103.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0) + (array69103.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0),
                I: (array70103.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0) + (array69103.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0),
                J: (array70103.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0) + (array69103.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0),
                K: (array70103.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0) + (array69103.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0),
                L: (array70103.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0) + (array69103.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0),
                M: (array70103.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0) + (array69103.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0),
                N: (array70103.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0) + (array69103.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0),
                O: (array70103.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0) + (array69103.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0),
                P: (array70103.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0) + (array69103.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0),
                Q: (array70103.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0) + (array69103.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0),
                R: (array70103.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0) + (array69103.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0),
                S: (array70103.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0) + (array69103.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0),
                T: (array70103.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0) + (array69103.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0),
                U: (array70103.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0) + (array69103.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0),
                V: (array70103.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0) + (array69103.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0),
                W: (array70103.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0) + (array69103.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0),
                X: (array70103.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0) + (array69103.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0),
                Y: (array70103.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0) + (array69103.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0)
            }
        );

        tableBody.push(
            {
                A:  null,
                B:  '',
                C:  '',
                D:  '',
                E:  '',
                F:  '',
                G:  '',
                H:  '',
                I:  '',
                J:  '',
                K:  '',
                L:  '',
                M:  '',
                N:  '',
                O:  '',
                P:  '',
                Q:  '',
                R:  '',
                S:  '',
                T:  '',
                U:  '',
                V:  '',
                W:  '',
                X:  '',
                Y:  ''
            }
        );

        if(array70103.reduce((x, y) => x + (y.totalbal + y.totalacc), 0) === 0) hiddenRow.push(14);
        if(array69103.reduce((x, y) => x + (y.totalbal + y.totalacc), 0) === 0) hiddenRow.push(15);
        if([...array70103, ...array69103].reduce((x, y) => x + (y.totalbal + y.totalacc), 0) === 0) 
        {
            hiddenRow.push(16);
            hiddenRow.push(17);
        }

        //#endregion

        //#region 70102, 69102

        const array70102 = data.filter(x => x.accaccou === '70102');
        const array69102 = data.filter(x => x.accaccou === '69102');
        totalbal = {};
        totalacc = {};

        tableBody.push(
            {
                A:  null,
                B:  'VENTAS NETAS VIP',
                C:  array70102.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0,
                D:  array70102.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0,
                E:  array70102.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0,
                F:  array70102.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0,
                G:  array70102.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0,
                H:  array70102.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0,
                I:  array70102.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0,
                J:  array70102.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0,
                K:  array70102.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0,
                L:  array70102.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0,
                M:  array70102.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0,
                N:  array70102.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0,
                O:  array70102.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0,
                P:  array70102.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0,
                Q:  array70102.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0,
                R:  array70102.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0,
                S:  array70102.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0,
                T:  array70102.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0,
                U:  array70102.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0,
                V:  array70102.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0,
                W:  array70102.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0,
                X:  array70102.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0,
                Y:  array70102.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0
            }
        );

        tableBody.push(
            {
                A: null,
                B: '(-) COSTO DE VENTAS VIP',
                C: array69102.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0,
                D: array69102.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0,
                E: array69102.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0,
                F: array69102.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0,
                G: array69102.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0,
                H: array69102.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0,
                I: array69102.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0,
                J: array69102.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0,
                K: array69102.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0,
                L: array69102.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0,
                M: array69102.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0,
                N: array69102.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0,
                O: array69102.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0,
                P: array69102.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0,
                Q: array69102.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0,
                R: array69102.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0,
                S: array69102.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0,
                T: array69102.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0,
                U: array69102.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0,
                V: array69102.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0,
                W: array69102.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0,
                X: array69102.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0,
                Y: array69102.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0
            }
        );

        tableBody.push(
            {
                A: null,
                B: '',
                C: (array70102.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0) + (array69102.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0),
                D: (array70102.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0) + (array69102.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0),
                E: (array70102.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0) + (array69102.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0),
                F: (array70102.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0) + (array69102.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0),
                G: (array70102.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0) + (array69102.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0),
                H: (array70102.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0) + (array69102.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0),
                I: (array70102.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0) + (array69102.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0),
                J: (array70102.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0) + (array69102.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0),
                K: (array70102.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0) + (array69102.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0),
                L: (array70102.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0) + (array69102.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0),
                M: (array70102.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0) + (array69102.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0),
                N: (array70102.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0) + (array69102.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0),
                O: (array70102.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0) + (array69102.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0),
                P: (array70102.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0) + (array69102.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0),
                Q: (array70102.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0) + (array69102.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0),
                R: (array70102.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0) + (array69102.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0),
                S: (array70102.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0) + (array69102.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0),
                T: (array70102.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0) + (array69102.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0),
                U: (array70102.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0) + (array69102.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0),
                V: (array70102.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0) + (array69102.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0),
                W: (array70102.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0) + (array69102.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0),
                X: (array70102.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0) + (array69102.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0),
                Y: (array70102.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0) + (array69102.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0)
            }
        );

        tableBody.push(
            {
                A:  null,
                B:  '',
                C:  '',
                D:  '',
                E:  '',
                F:  '',
                G:  '',
                H:  '',
                I:  '',
                J:  '',
                K:  '',
                L:  '',
                M:  '',
                N:  '',
                O:  '',
                P:  '',
                Q:  '',
                R:  '',
                S:  '',
                T:  '',
                U:  '',
                V:  '',
                W:  '',
                X:  '',
                Y:  ''
            }
        );

        if(array70103.reduce((x, y) => x + (y.totalbal + y.totalacc), 0) === 0) hiddenRow.push(18);
        if(array69103.reduce((x, y) => x + (y.totalbal + y.totalacc), 0) === 0) hiddenRow.push(19);
        if([...array70103, ...array69103].reduce((x, y) => x + (y.totalbal + y.totalacc), 0) === 0) 
        {
            hiddenRow.push(20);
            hiddenRow.push(21);
        }

        //#endregion

        //#region 70104, 69104

        const array70104 = data.filter(x => x.accaccou === '70104');
        const array69104 = data.filter(x => x.accaccou === '69104');
        totalbal = {};
        totalacc = {};

        tableBody.push(
            {
                A:  null,
                B:  'VENTAS NETAS PUB',
                C:  array70104.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0,
                D:  array70104.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0,
                E:  array70104.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0,
                F:  array70104.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0,
                G:  array70104.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0,
                H:  array70104.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0,
                I:  array70104.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0,
                J:  array70104.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0,
                K:  array70104.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0,
                L:  array70104.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0,
                M:  array70104.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0,
                N:  array70104.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0,
                O:  array70104.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0,
                P:  array70104.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0,
                Q:  array70104.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0,
                R:  array70104.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0,
                S:  array70104.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0,
                T:  array70104.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0,
                U:  array70104.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0,
                V:  array70104.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0,
                W:  array70104.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0,
                X:  array70104.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0,
                Y:  array70104.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0
            }
        );

        tableBody.push(
            {
                A: null,
                B: '(-) COSTO DE VENTAS PUB',
                C: array69104.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0,
                D: array69104.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0,
                E: array69104.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0,
                F: array69104.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0,
                G: array69104.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0,
                H: array69104.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0,
                I: array69104.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0,
                J: array69104.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0,
                K: array69104.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0,
                L: array69104.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0,
                M: array69104.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0,
                N: array69104.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0,
                O: array69104.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0,
                P: array69104.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0,
                Q: array69104.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0,
                R: array69104.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0,
                S: array69104.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0,
                T: array69104.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0,
                U: array69104.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0,
                V: array69104.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0,
                W: array69104.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0,
                X: array69104.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0,
                Y: array69104.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0
            }
        );

        tableBody.push(
            {
                A: null,
                B: '',
                C: (array70104.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0) + (array69104.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0),
                D: (array70104.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0) + (array69104.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0),
                E: (array70104.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0) + (array69104.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0),
                F: (array70104.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0) + (array69104.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0),
                G: (array70104.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0) + (array69104.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0),
                H: (array70104.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0) + (array69104.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0),
                I: (array70104.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0) + (array69104.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0),
                J: (array70104.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0) + (array69104.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0),
                K: (array70104.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0) + (array69104.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0),
                L: (array70104.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0) + (array69104.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0),
                M: (array70104.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0) + (array69104.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0),
                N: (array70104.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0) + (array69104.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0),
                O: (array70104.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0) + (array69104.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0),
                P: (array70104.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0) + (array69104.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0),
                Q: (array70104.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0) + (array69104.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0),
                R: (array70104.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0) + (array69104.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0),
                S: (array70104.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0) + (array69104.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0),
                T: (array70104.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0) + (array69104.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0),
                U: (array70104.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0) + (array69104.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0),
                V: (array70104.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0) + (array69104.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0),
                W: (array70104.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0) + (array69104.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0),
                X: (array70104.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0) + (array69104.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0),
                Y: (array70104.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0) + (array69104.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0)
            }
        );

        tableBody.push(
            {
                A:  null,
                B:  '',
                C:  '',
                D:  '',
                E:  '',
                F:  '',
                G:  '',
                H:  '',
                I:  '',
                J:  '',
                K:  '',
                L:  '',
                M:  '',
                N:  '',
                O:  '',
                P:  '',
                Q:  '',
                R:  '',
                S:  '',
                T:  '',
                U:  '',
                V:  '',
                W:  '',
                X:  '',
                Y:  ''
            }
        );

        if(array70103.reduce((x, y) => x + (y.totalbal + y.totalacc), 0) === 0) hiddenRow.push(22);
        if(array69103.reduce((x, y) => x + (y.totalbal + y.totalacc), 0) === 0) hiddenRow.push(23);
        if([...array70103, ...array69103].reduce((x, y) => x + (y.totalbal + y.totalacc), 0) === 0) 
        {
            hiddenRow.push(24);
            hiddenRow.push(25);
        }

        //#endregion

        //#region 7041

        const array7041 = data.filter(x => x.accaccou === '704100');
        totalbal = {};
        totalacc = {};

        tableBody.push(
            {
                A:  null,
                B:  'VENTAS SERVICIOS',
                C:  array7041.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0,
                D:  array7041.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0,
                E:  array7041.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0,
                F:  array7041.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0,
                G:  array7041.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0,
                H:  array7041.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0,
                I:  array7041.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0,
                J:  array7041.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0,
                K:  array7041.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0,
                L:  array7041.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0,
                M:  array7041.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0,
                N:  array7041.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0,
                O:  array7041.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0,
                P:  array7041.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0,
                Q:  array7041.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0,
                R:  array7041.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0,
                S:  array7041.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0,
                T:  array7041.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0,
                U:  array7041.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0,
                V:  array7041.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0,
                W:  array7041.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0,
                X:  array7041.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0,
                Y:  array7041.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0
            }
        );

        tableBody.push(
            {
                A:  null,
                B:  '',
                C:  array7041.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0,
                D:  array7041.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0,
                E:  array7041.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0,
                F:  array7041.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0,
                G:  array7041.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0,
                H:  array7041.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0,
                I:  array7041.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0,
                J:  array7041.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0,
                K:  array7041.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0,
                L:  array7041.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0,
                M:  array7041.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0,
                N:  array7041.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0,
                O:  array7041.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0,
                P:  array7041.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0,
                Q:  array7041.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0,
                R:  array7041.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0,
                S:  array7041.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0,
                T:  array7041.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0,
                U:  array7041.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0,
                V:  array7041.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0,
                W:  array7041.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0,
                X:  array7041.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0,
                Y:  array7041.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0
            }
        );

        tableBody.push(
            {
                A:  null,
                B:  '',
                C:  '',
                D:  '',
                E:  '',
                F:  '',
                G:  '',
                H:  '',
                I:  '',
                J:  '',
                K:  '',
                L:  '',
                M:  '',
                N:  '',
                O:  '',
                P:  '',
                Q:  '',
                R:  '',
                S:  '',
                T:  '',
                U:  '',
                V:  '',
                W:  '',
                X:  '',
                Y:  ''
            }
        );

        //#endregion

        //#region vacios

        tableBody.push(
            {
                A: null,
                B: 'VENTAS (TRANSFERENCIA GRATUITA)',
                C: 0,
                D: 0,
                E: 0,
                F: 0,
                G: 0,
                H: 0,
                I: 0,
                J: 0,
                K: 0,
                L: 0,
                M: 0,
                N: 0,
                O: 0,
                P: 0,
                Q: 0,
                R: 0,
                S: 0,
                T: 0,
                U: 0,
                V: 0,
                W: 0,
                X: 0,
                Y: 0
            }
        );

        tableBody.push(
            {
                A:  null,
                B:  '',
                C:  '',
                D:  '',
                E:  '',
                F:  '',
                G:  '',
                H:  '',
                I:  '',
                J:  '',
                K:  '',
                L:  '',
                M:  '',
                N:  '',
                O:  '',
                P:  '',
                Q:  '',
                R:  '',
                S:  '',
                T:  '',
                U:  '',
                V:  '',
                W:  '',
                X:  '',
                Y:  ''
            }
        );

        tableBody.push(
            {
                A:  null,
                B:  '',
                C:  '',
                D:  '',
                E:  '',
                F:  '',
                G:  '',
                H:  '',
                I:  '',
                J:  '',
                K:  '',
                L:  '',
                M:  '',
                N:  '',
                O:  '',
                P:  '',
                Q:  '',
                R:  '',
                S:  '',
                T:  '',
                U:  '',
                V:  '',
                W:  '',
                X:  '',
                Y:  ''
            }
        );

        //#endregion

        //#region UTILIDAD BRUTA GRIFO PISCO

        const arrayubgp = data.filter(x => ['70100', '70107', '69100', '69108', '70101','69101', '70103', '69103', '70102', '69102', '70104', '69104', '704100'].includes(x.accaccou));
        totalbal = {};
        totalacc = {};

        tableBody.push(
            {
                A:  null,
                B:  'UTILIDAD BRUTA GRIFO PISCO',
                C:  arrayubgp.filter((x: any) => x.balacode === `${year}01`).reduce((x: any, y: any) => x + y.totalbal, 0),
                D:  arrayubgp.filter((x: any) => x.balacode === `${year}02`).reduce((x: any, y: any) => x + y.totalbal, 0),
                E:  arrayubgp.filter((x: any) => x.balacode === `${year}02`).reduce((x: any, y: any) => x + y.totalacc, 0),
                F:  arrayubgp.filter((x: any) => x.balacode === `${year}03`).reduce((x: any, y: any) => x + y.totalbal, 0),
                G:  arrayubgp.filter((x: any) => x.balacode === `${year}03`).reduce((x: any, y: any) => x + y.totalacc, 0),
                H:  arrayubgp.filter((x: any) => x.balacode === `${year}04`).reduce((x: any, y: any) => x + y.totalbal, 0),
                I:  arrayubgp.filter((x: any) => x.balacode === `${year}04`).reduce((x: any, y: any) => x + y.totalacc, 0),
                J:  arrayubgp.filter((x: any) => x.balacode === `${year}05`).reduce((x: any, y: any) => x + y.totalbal, 0),
                K:  arrayubgp.filter((x: any) => x.balacode === `${year}05`).reduce((x: any, y: any) => x + y.totalacc, 0),
                L:  arrayubgp.filter((x: any) => x.balacode === `${year}06`).reduce((x: any, y: any) => x + y.totalbal, 0),
                M:  arrayubgp.filter((x: any) => x.balacode === `${year}06`).reduce((x: any, y: any) => x + y.totalacc, 0),
                N:  arrayubgp.filter((x: any) => x.balacode === `${year}07`).reduce((x: any, y: any) => x + y.totalbal, 0),
                O:  arrayubgp.filter((x: any) => x.balacode === `${year}07`).reduce((x: any, y: any) => x + y.totalacc, 0),
                P:  arrayubgp.filter((x: any) => x.balacode === `${year}08`).reduce((x: any, y: any) => x + y.totalbal, 0),
                Q:  arrayubgp.filter((x: any) => x.balacode === `${year}08`).reduce((x: any, y: any) => x + y.totalacc, 0),
                R:  arrayubgp.filter((x: any) => x.balacode === `${year}09`).reduce((x: any, y: any) => x + y.totalbal, 0),
                S:  arrayubgp.filter((x: any) => x.balacode === `${year}09`).reduce((x: any, y: any) => x + y.totalacc, 0),
                T:  arrayubgp.filter((x: any) => x.balacode === `${year}10`).reduce((x: any, y: any) => x + y.totalbal, 0),
                U:  arrayubgp.filter((x: any) => x.balacode === `${year}10`).reduce((x: any, y: any) => x + y.totalacc, 0),
                V:  arrayubgp.filter((x: any) => x.balacode === `${year}11`).reduce((x: any, y: any) => x + y.totalbal, 0),
                W:  arrayubgp.filter((x: any) => x.balacode === `${year}11`).reduce((x: any, y: any) => x + y.totalacc, 0),
                X:  arrayubgp.filter((x: any) => x.balacode === `${year}12`).reduce((x: any, y: any) => x + y.totalbal, 0),
                Y:  arrayubgp.filter((x: any) => x.balacode === `${year}12`).reduce((x: any, y: any) => x + y.totalacc, 0)
            }
        );

        tableBody.push(
            {
                A:  null,
                B:  '',
                C:  '',
                D:  '',
                E:  '',
                F:  '',
                G:  '',
                H:  '',
                I:  '',
                J:  '',
                K:  '',
                L:  '',
                M:  '',
                N:  '',
                O:  '',
                P:  '',
                Q:  '',
                R:  '',
                S:  '',
                T:  '',
                U:  '',
                V:  '',
                W:  '',
                X:  '',
                Y:  ''
            }
        );

        //#endregion
    
    }

    getGrifoChincha = (data, tableBody, hiddenRow, year) => {
        
        //#region 70105, 69105

        const array701005 = data.filter(x => x.accaccou === '70105');
        const array70108 = data.filter(x => x.accaccou === '70108');
        const array69105 = data.filter(x => x.accaccou === '69105');
        const array69107 = data.filter(x => x.accaccou === '69107');
        let totalbal: any = {};
        let totalacc: any = {};

        tableBody.push(
            {
                A:  null,
                B:  'VENTAS NETAS GRIFO',
                C:  (array701005.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0) + array70108.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0,
                D:  (array701005.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0) + array70108.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0,
                E:  (array701005.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0) + array70108.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0,
                F:  (array701005.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0) + array70108.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0,
                G:  (array701005.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0) + array70108.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0,
                H:  (array701005.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0) + array70108.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0,
                I:  (array701005.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0) + array70108.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0,
                J:  (array701005.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0) + array70108.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0,
                K:  (array701005.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0) + array70108.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0,
                L:  (array701005.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0) + array70108.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0,
                M:  (array701005.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0) + array70108.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0,
                N:  (array701005.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0) + array70108.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0,
                O:  (array701005.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0) + array70108.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0,
                P:  (array701005.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0) + array70108.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0,
                Q:  (array701005.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0) + array70108.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0,
                R:  (array701005.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0) + array70108.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0,
                S:  (array701005.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0) + array70108.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0,
                T:  (array701005.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0) + array70108.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0,
                U:  (array701005.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0) + array70108.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0,
                V:  (array701005.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0) + array70108.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0,
                W:  (array701005.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0) + array70108.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0,
                X:  (array701005.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0) + array70108.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0,
                Y:  (array701005.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0) + array70108.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0
            }
        );

        tableBody.push(
            {
                A:  null,
                B:  '(-) COSTO DE VENTAS GRIFO',
                C:  (array69105.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0) + array69107.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0,
                D:  (array69105.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0) + array69107.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0,
                E:  (array69105.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0) + array69107.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0,
                F:  (array69105.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0) + array69107.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0,
                G:  (array69105.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0) + array69107.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0,
                H:  (array69105.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0) + array69107.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0,
                I:  (array69105.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0) + array69107.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0,
                J:  (array69105.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0) + array69107.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0,
                K:  (array69105.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0) + array69107.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0,
                L:  (array69105.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0) + array69107.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0,
                M:  (array69105.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0) + array69107.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0,
                N:  (array69105.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0) + array69107.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0,
                O:  (array69105.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0) + array69107.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0,
                P:  (array69105.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0) + array69107.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0,
                Q:  (array69105.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0) + array69107.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0,
                R:  (array69105.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0) + array69107.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0,
                S:  (array69105.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0) + array69107.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0,
                T:  (array69105.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0) + array69107.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0,
                U:  (array69105.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0) + array69107.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0,
                V:  (array69105.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0) + array69107.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0,
                W:  (array69105.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0) + array69107.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0,
                X:  (array69105.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0) + array69107.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0,
                Y:  (array69105.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0) + array69107.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0
            }
        );

        tableBody.push(
            {
                A:  null,
                B:  '',
                C:  (array701005.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0) + (array70108.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0) + (array69105.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0) + (array69107.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0),
                D:  (array701005.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0) + (array70108.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0) + (array69105.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0) + (array69107.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0),
                E:  (array701005.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0) + (array70108.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0) + (array69105.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0) + (array69107.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0),
                F:  (array701005.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0) + (array70108.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0) + (array69105.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0) + (array69107.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0),
                G:  (array701005.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0) + (array70108.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0) + (array69105.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0) + (array69107.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0),
                H:  (array701005.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0) + (array70108.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0) + (array69105.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0) + (array69107.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0),
                I:  (array701005.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0) + (array70108.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0) + (array69105.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0) + (array69107.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0),
                J:  (array701005.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0) + (array70108.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0) + (array69105.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0) + (array69107.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0),
                K:  (array701005.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0) + (array70108.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0) + (array69105.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0) + (array69107.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0),
                L:  (array701005.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0) + (array70108.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0) + (array69105.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0) + (array69107.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0),
                M:  (array701005.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0) + (array70108.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0) + (array69105.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0) + (array69107.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0),
                N:  (array701005.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0) + (array70108.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0) + (array69105.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0) + (array69107.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0),
                O:  (array701005.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0) + (array70108.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0) + (array69105.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0) + (array69107.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0),
                P:  (array701005.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0) + (array70108.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0) + (array69105.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0) + (array69107.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0),
                Q:  (array701005.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0) + (array70108.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0) + (array69105.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0) + (array69107.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0),
                R:  (array701005.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0) + (array70108.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0) + (array69105.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0) + (array69107.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0),
                S:  (array701005.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0) + (array70108.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0) + (array69105.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0) + (array69107.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0),
                T:  (array701005.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0) + (array70108.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0) + (array69105.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0) + (array69107.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0),
                U:  (array701005.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0) + (array70108.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0) + (array69105.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0) + (array69107.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0),
                V:  (array701005.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0) + (array70108.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0) + (array69105.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0) + (array69107.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0),
                W:  (array701005.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0) + (array70108.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0) + (array69105.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0) + (array69107.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0),
                X:  (array701005.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0) + (array70108.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0) + (array69105.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0) + (array69107.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0),
                Y:  (array701005.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0) + (array70108.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0) + (array69105.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0) + (array69107.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0)
            }
        );

        tableBody.push(
            {
                A:  null,
                B:  '',
                C:  '',
                D:  '',
                E:  '',
                F:  '',
                G:  '',
                H:  '',
                I:  '',
                J:  '',
                K:  '',
                L:  '',
                M:  '',
                N:  '',
                O:  '',
                P:  '',
                Q:  '',
                R:  '',
                S:  '',
                T:  '',
                U:  '',
                V:  '',
                W:  '',
                X:  '',
                Y:  ''
            }
        );

        //#endregion

        //#region 70106, 69106

        const array70106 = data.filter(x => x.accaccou === '70106');
        const array69106 = data.filter(x => x.accaccou === '69106');
        totalbal = {};
        totalacc = {};

        tableBody.push(
            {
                A:  null,
                B:  'VENTAS NETAS SHOP',
                C:  array70106.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0,
                D:  array70106.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0,
                E:  array70106.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0,
                F:  array70106.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0,
                G:  array70106.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0,
                H:  array70106.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0,
                I:  array70106.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0,
                J:  array70106.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0,
                K:  array70106.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0,
                L:  array70106.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0,
                M:  array70106.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0,
                N:  array70106.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0,
                O:  array70106.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0,
                P:  array70106.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0,
                Q:  array70106.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0,
                R:  array70106.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0,
                S:  array70106.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0,
                T:  array70106.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0,
                U:  array70106.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0,
                V:  array70106.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0,
                W:  array70106.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0,
                X:  array70106.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0,
                Y:  array70106.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0
            }
        );

        tableBody.push(
            {
                A:  null,
                B:  '(-) COSTO DE VENTAS SHOP',
                C:  array69106.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0,
                D:  array69106.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0,
                E:  array69106.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0,
                F:  array69106.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0,
                G:  array69106.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0,
                H:  array69106.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0,
                I:  array69106.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0,
                J:  array69106.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0,
                K:  array69106.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0,
                L:  array69106.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0,
                M:  array69106.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0,
                N:  array69106.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0,
                O:  array69106.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0,
                P:  array69106.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0,
                Q:  array69106.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0,
                R:  array69106.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0,
                S:  array69106.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0,
                T:  array69106.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0,
                U:  array69106.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0,
                V:  array69106.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0,
                W:  array69106.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0,
                X:  array69106.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0,
                Y:  array69106.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0
            }
        );

        tableBody.push(
            {
                A:  null,
                B:  '',
                C:  (array70106.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0) + (array69106.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0),
                D:  (array70106.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0) + (array69106.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0),
                E:  (array70106.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0) + (array69106.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0),
                F:  (array70106.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0) + (array69106.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0),
                G:  (array70106.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0) + (array69106.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0),
                H:  (array70106.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0) + (array69106.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0),
                I:  (array70106.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0) + (array69106.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0),
                J:  (array70106.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0) + (array69106.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0),
                K:  (array70106.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0) + (array69106.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0),
                L:  (array70106.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0) + (array69106.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0),
                M:  (array70106.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0) + (array69106.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0),
                N:  (array70106.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0) + (array69106.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0),
                O:  (array70106.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0) + (array69106.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0),
                P:  (array70106.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0) + (array69106.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0),
                Q:  (array70106.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0) + (array69106.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0),
                R:  (array70106.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0) + (array69106.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0),
                S:  (array70106.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0) + (array69106.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0),
                T:  (array70106.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0) + (array69106.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0),
                U:  (array70106.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0) + (array69106.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0),
                V:  (array70106.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0) + (array69106.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0),
                W:  (array70106.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0) + (array69106.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0),
                X:  (array70106.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0) + (array69106.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0),
                Y:  (array70106.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0) + (array69106.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0)
            }
        );

        tableBody.push(
            {
                A:  null,
                B:  '',
                C:  '',
                D:  '',
                E:  '',
                F:  '',
                G:  '',
                H:  '',
                I:  '',
                J:  '',
                K:  '',
                L:  '',
                M:  '',
                N:  '',
                O:  '',
                P:  '',
                Q:  '',
                R:  '',
                S:  '',
                T:  '',
                U:  '',
                V:  '',
                W:  '',
                X:  '',
                Y:  ''
            }
        );

        //#endregion

        //#region 7042

        const array7042 = data.filter(x => x.accaccou === '704200');
        totalbal = {};
        totalacc = {};

        tableBody.push(
            {
                A:  null,
                B:  'VENTAS SERVICIOS',
                C:  array7042.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0,
                D:  array7042.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0,
                E:  array7042.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0,
                F:  array7042.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0,
                G:  array7042.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0,
                H:  array7042.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0,
                I:  array7042.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0,
                J:  array7042.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0,
                K:  array7042.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0,
                L:  array7042.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0,
                M:  array7042.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0,
                N:  array7042.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0,
                O:  array7042.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0,
                P:  array7042.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0,
                Q:  array7042.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0,
                R:  array7042.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0,
                S:  array7042.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0,
                T:  array7042.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0,
                U:  array7042.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0,
                V:  array7042.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0,
                W:  array7042.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0,
                X:  array7042.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0,
                Y:  array7042.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0
            }
        );

        tableBody.push(
            {
                A:  null,
                B:  '',
                C:  array7042.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0,
                D:  array7042.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0,
                E:  array7042.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0,
                F:  array7042.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0,
                G:  array7042.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0,
                H:  array7042.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0,
                I:  array7042.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0,
                J:  array7042.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0,
                K:  array7042.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0,
                L:  array7042.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0,
                M:  array7042.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0,
                N:  array7042.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0,
                O:  array7042.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0,
                P:  array7042.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0,
                Q:  array7042.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0,
                R:  array7042.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0,
                S:  array7042.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0,
                T:  array7042.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0,
                U:  array7042.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0,
                V:  array7042.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0,
                W:  array7042.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0,
                X:  array7042.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0,
                Y:  array7042.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0
            }
        );

        tableBody.push(
            {
                A:  null,
                B:  '',
                C:  '',
                D:  '',
                E:  '',
                F:  '',
                G:  '',
                H:  '',
                I:  '',
                J:  '',
                K:  '',
                L:  '',
                M:  '',
                N:  '',
                O:  '',
                P:  '',
                Q:  '',
                R:  '',
                S:  '',
                T:  '',
                U:  '',
                V:  '',
                W:  '',
                X:  '',
                Y:  ''
            }
        );

        //#endregion

        //#region UTILIDAD BRUTA GRIFO CHINCHA

        const arrayubgc = data.filter(x => ['70105', '70108', '69105', '69107', '70106','69106','704200'].includes(x.accaccou));
        totalbal = {};
        totalacc = {};

        tableBody.push(
            {
                A:  null,
                B:  'UTILIDAD BRUTA GRIFO CHINCHA',
                C:  arrayubgc.filter((x: any) => x.balacode === `${year}01`).reduce((x: any, y: any) => x + y.totalbal, 0),
                D:  arrayubgc.filter((x: any) => x.balacode === `${year}02`).reduce((x: any, y: any) => x + y.totalbal, 0),
                E:  arrayubgc.filter((x: any) => x.balacode === `${year}02`).reduce((x: any, y: any) => x + y.totalacc, 0),
                F:  arrayubgc.filter((x: any) => x.balacode === `${year}03`).reduce((x: any, y: any) => x + y.totalbal, 0),
                G:  arrayubgc.filter((x: any) => x.balacode === `${year}03`).reduce((x: any, y: any) => x + y.totalacc, 0),
                H:  arrayubgc.filter((x: any) => x.balacode === `${year}04`).reduce((x: any, y: any) => x + y.totalbal, 0),
                I:  arrayubgc.filter((x: any) => x.balacode === `${year}04`).reduce((x: any, y: any) => x + y.totalacc, 0),
                J:  arrayubgc.filter((x: any) => x.balacode === `${year}05`).reduce((x: any, y: any) => x + y.totalbal, 0),
                K:  arrayubgc.filter((x: any) => x.balacode === `${year}05`).reduce((x: any, y: any) => x + y.totalacc, 0),
                L:  arrayubgc.filter((x: any) => x.balacode === `${year}06`).reduce((x: any, y: any) => x + y.totalbal, 0),
                M:  arrayubgc.filter((x: any) => x.balacode === `${year}06`).reduce((x: any, y: any) => x + y.totalacc, 0),
                N:  arrayubgc.filter((x: any) => x.balacode === `${year}07`).reduce((x: any, y: any) => x + y.totalbal, 0),
                O:  arrayubgc.filter((x: any) => x.balacode === `${year}07`).reduce((x: any, y: any) => x + y.totalacc, 0),
                P:  arrayubgc.filter((x: any) => x.balacode === `${year}08`).reduce((x: any, y: any) => x + y.totalbal, 0),
                Q:  arrayubgc.filter((x: any) => x.balacode === `${year}08`).reduce((x: any, y: any) => x + y.totalacc, 0),
                R:  arrayubgc.filter((x: any) => x.balacode === `${year}09`).reduce((x: any, y: any) => x + y.totalbal, 0),
                S:  arrayubgc.filter((x: any) => x.balacode === `${year}09`).reduce((x: any, y: any) => x + y.totalacc, 0),
                T:  arrayubgc.filter((x: any) => x.balacode === `${year}10`).reduce((x: any, y: any) => x + y.totalbal, 0),
                U:  arrayubgc.filter((x: any) => x.balacode === `${year}10`).reduce((x: any, y: any) => x + y.totalacc, 0),
                V:  arrayubgc.filter((x: any) => x.balacode === `${year}11`).reduce((x: any, y: any) => x + y.totalbal, 0),
                W:  arrayubgc.filter((x: any) => x.balacode === `${year}11`).reduce((x: any, y: any) => x + y.totalacc, 0),
                X:  arrayubgc.filter((x: any) => x.balacode === `${year}12`).reduce((x: any, y: any) => x + y.totalbal, 0),
                Y:  arrayubgc.filter((x: any) => x.balacode === `${year}12`).reduce((x: any, y: any) => x + y.totalacc, 0)
            }
        );

        tableBody.push(
            {
                A:  null,
                B:  '',
                C:  '',
                D:  '',
                E:  '',
                F:  '',
                G:  '',
                H:  '',
                I:  '',
                J:  '',
                K:  '',
                L:  '',
                M:  '',
                N:  '',
                O:  '',
                P:  '',
                Q:  '',
                R:  '',
                S:  '',
                T:  '',
                U:  '',
                V:  '',
                W:  '',
                X:  '',
                Y:  ''
            }
        );

        //#endregion

    }

    onGenerateExcel = () => {
        try {
            const data = this.props.accountantReducers.onlyOne;
            const { month, year } = this.state;
            const { dashboardReducers } = this.props;
            const mes = meses.find((x: any) => x.key === month).value;
            const company = (JSON.parse(localStorage.getItem('tables')).companys.find((x: any) => x.taxident === dashboardReducers.currentCompany).bussname as string).toUpperCase();
    
            const maxMonth = +month;

            const tableBody: any = [
                {
                    A:  null,
                    B:  company.toUpperCase(),
                    C:  null,
                    D:  null,
                    E:  null,
                    F:  null,
                    G:  null,
                    H:  null,
                    I:  null,
                    J:  null,
                    K:  null,
                    L:  null,
                    M:  null,
                    N:  null,
                    O:  null,
                    P:  null,
                    Q:  null,
                    R:  null,
                    S:  null,
                    T:  null,
                    U:  null,
                    V:  null,
                    W:  null,
                    X:  null,
                    Y:  null
                },
                {
                    A:  null,
                    B:  `ESTADO DE GANANCIAS Y PERDIDAS ${year}-${mes.toUpperCase()}`,
                    C:  null,
                    D:  null,
                    E:  null,
                    F:  null,
                    G:  null,
                    H:  null,
                    I:  null,
                    J:  null,
                    K:  null,
                    L:  null,
                    M:  null,
                    N:  null,
                    O:  null,
                    P:  null,
                    Q:  null,
                    R:  null,
                    S:  null,
                    T:  null,
                    U:  null,
                    V:  null,
                    W:  null,
                    X:  null,
                    Y:  null
                },
                {
                    A:  null,
                    B:  '(EXPRESADO EN NUEVOS SOLES)',
                    C:  null,
                    D:  null,
                    E:  null,
                    F:  null,
                    G:  null,
                    H:  null,
                    I:  null,
                    J:  null,
                    K:  null,
                    L:  null,
                    M:  null,
                    N:  null,
                    O:  null,
                    P:  null,
                    Q:  null,
                    R:  null,
                    S:  null,
                    T:  null,
                    U:  null,
                    V:  null,
                    W:  null,
                    X:  null,
                    Y:  null
                },
                {
                    A:  null,
                    B:  null,
                    C:  null,
                    D:  null,
                    E:  null,
                    F:  null,
                    G:  null,
                    H:  null,
                    I:  null,
                    J:  null,
                    K:  null,
                    L:  null,
                    M:  null,
                    N:  null,
                    O:  null,
                    P:  null,
                    Q:  null,
                    R:  null,
                    S:  null,
                    T:  null,
                    U:  null,
                    V:  null,
                    W:  null,
                    X:  null,
                    Y:  null
                },
                {
                    A: null,
                    B: 'D E T A L L E',
                    C: 'E N E R O',
                    D: 'F E B R E R O',
                    E: '',
                    F: 'M A R Z O',
                    G: '',
                    H: 'A B R I L',
                    I: '',
                    J: 'M A Y O',
                    K: '',
                    L: 'J U N I O',
                    M: '',
                    N: 'J U L I O',
                    O: '',
                    P: 'A G O S T O',
                    Q: '',
                    R: 'S E P T I E M B R E',
                    S: '',
                    T: 'O C T U B R E',
                    U: '',
                    V: 'N O V I E M B R E',
                    W: '',
                    X: 'D I C I E M B R E',
                    Y: ''
                },
                {
                    A: null,
                    B: '',
                    C: 'MENSUAL',
                    D: 'MENSUAL',
                    E: 'ACUMULADO',
                    F: 'MENSUAL',
                    G: 'ACUMULADO',
                    H: 'MENSUAL',
                    I: 'ACUMULADO',
                    J: 'MENSUAL',
                    K: 'ACUMULADO',
                    L: 'MENSUAL',
                    M: 'ACUMULADO',
                    N: 'MENSUAL',
                    O: 'ACUMULADO',
                    P: 'MENSUAL',
                    Q: 'ACUMULADO',
                    R: 'MENSUAL',
                    S: 'ACUMULADO',
                    T: 'MENSUAL',
                    U: 'ACUMULADO',
                    V: 'MENSUAL',
                    W: 'ACUMULADO',
                    X: 'MENSUAL',
                    Y: 'ACUMULADO'
                }
            ];

            let hiddenRow = [];

            tableBody.push(
                {
                    A:  null,
                    B:  '',
                    C:  '',
                    D:  '',
                    E:  '',
                    F:  '',
                    G:  '',
                    H:  '',
                    I:  '',
                    J:  '',
                    K:  '',
                    L:  '',
                    M:  '',
                    N:  '',
                    O:  '',
                    P:  '',
                    Q:  '',
                    R:  '',
                    S:  '',
                    T:  '',
                    U:  '',
                    V:  '',
                    W:  '',
                    X:  '',
                    Y:  ''
                }
            );

            tableBody.push(
                {
                    A:  null,
                    B:  'GRIFO PISCO',
                    C:  'S/',
                    D:  'S/',
                    E:  'S/',
                    F:  'S/',
                    G:  'S/',
                    H:  'S/',
                    I:  'S/',
                    J:  'S/',
                    K:  'S/',
                    L:  'S/',
                    M:  'S/',
                    N:  'S/',
                    O:  'S/',
                    P:  'S/',
                    Q:  'S/',
                    R:  'S/',
                    S:  'S/',
                    T:  'S/',
                    U:  'S/',
                    V:  'S/',
                    W:  'S/',
                    X:  'S/',
                    Y:  'S/'
                }
            );

            tableBody.push(
                {
                    A:  null,
                    B:  '',
                    C:  '',
                    D:  '',
                    E:  '',
                    F:  '',
                    G:  '',
                    H:  '',
                    I:  '',
                    J:  '',
                    K:  '',
                    L:  '',
                    M:  '',
                    N:  '',
                    O:  '',
                    P:  '',
                    Q:  '',
                    R:  '',
                    S:  '',
                    T:  '',
                    U:  '',
                    V:  '',
                    W:  '',
                    X:  '',
                    Y:  ''
                }
            );

            this.getGrifoPisco(data, tableBody, hiddenRow, year);

            tableBody.push(
                {
                    A:  null,
                    B:  'GRIFO CHINCHA',
                    C:  '',
                    D:  '',
                    E:  '',
                    F:  '',
                    G:  '',
                    H:  '',
                    I:  '',
                    J:  '',
                    K:  '',
                    L:  '',
                    M:  '',
                    N:  '',
                    O:  '',
                    P:  '',
                    Q:  '',
                    R:  '',
                    S:  '',
                    T:  '',
                    U:  '',
                    V:  '',
                    W:  '',
                    X:  '',
                    Y:  ''
                }
            );

            tableBody.push(
                {
                    A:  null,
                    B:  '',
                    C:  '',
                    D:  '',
                    E:  '',
                    F:  '',
                    G:  '',
                    H:  '',
                    I:  '',
                    J:  '',
                    K:  '',
                    L:  '',
                    M:  '',
                    N:  '',
                    O:  '',
                    P:  '',
                    Q:  '',
                    R:  '',
                    S:  '',
                    T:  '',
                    U:  '',
                    V:  '',
                    W:  '',
                    X:  '',
                    Y:  ''
                }
            );

            this.getGrifoChincha(data, tableBody, hiddenRow, year);

            //#region UTILIDAD BRUTA

            const array = data.filter(x => ['70100', '70107', '69100', '69108', '70101','69101', '70103', '69103', '70102', '69102', '70104', '69104', '704100', '70105', '70108', '69105', '69107', '70106','69106','704200'].includes(x.accaccou));
            let totalbal: any = {};
            let totalacc: any = {};

            tableBody.push(
                {
                    A:  null,
                    B:  'UTILIDAD BRUTA',
                    C:  array.filter((x: any) => x.balacode === `${year}01`).reduce((x: any, y: any) => x + y.totalbal, 0),
                    D:  array.filter((x: any) => x.balacode === `${year}02`).reduce((x: any, y: any) => x + y.totalbal, 0),
                    E:  array.filter((x: any) => x.balacode === `${year}02`).reduce((x: any, y: any) => x + y.totalacc, 0),
                    F:  array.filter((x: any) => x.balacode === `${year}03`).reduce((x: any, y: any) => x + y.totalbal, 0),
                    G:  array.filter((x: any) => x.balacode === `${year}03`).reduce((x: any, y: any) => x + y.totalacc, 0),
                    H:  array.filter((x: any) => x.balacode === `${year}04`).reduce((x: any, y: any) => x + y.totalbal, 0),
                    I:  array.filter((x: any) => x.balacode === `${year}04`).reduce((x: any, y: any) => x + y.totalacc, 0),
                    J:  array.filter((x: any) => x.balacode === `${year}05`).reduce((x: any, y: any) => x + y.totalbal, 0),
                    K:  array.filter((x: any) => x.balacode === `${year}05`).reduce((x: any, y: any) => x + y.totalacc, 0),
                    L:  array.filter((x: any) => x.balacode === `${year}06`).reduce((x: any, y: any) => x + y.totalbal, 0),
                    M:  array.filter((x: any) => x.balacode === `${year}06`).reduce((x: any, y: any) => x + y.totalacc, 0),
                    N:  array.filter((x: any) => x.balacode === `${year}07`).reduce((x: any, y: any) => x + y.totalbal, 0),
                    O:  array.filter((x: any) => x.balacode === `${year}07`).reduce((x: any, y: any) => x + y.totalacc, 0),
                    P:  array.filter((x: any) => x.balacode === `${year}08`).reduce((x: any, y: any) => x + y.totalbal, 0),
                    Q:  array.filter((x: any) => x.balacode === `${year}08`).reduce((x: any, y: any) => x + y.totalacc, 0),
                    R:  array.filter((x: any) => x.balacode === `${year}09`).reduce((x: any, y: any) => x + y.totalbal, 0),
                    S:  array.filter((x: any) => x.balacode === `${year}09`).reduce((x: any, y: any) => x + y.totalacc, 0),
                    T:  array.filter((x: any) => x.balacode === `${year}10`).reduce((x: any, y: any) => x + y.totalbal, 0),
                    U:  array.filter((x: any) => x.balacode === `${year}10`).reduce((x: any, y: any) => x + y.totalacc, 0),
                    V:  array.filter((x: any) => x.balacode === `${year}11`).reduce((x: any, y: any) => x + y.totalbal, 0),
                    W:  array.filter((x: any) => x.balacode === `${year}11`).reduce((x: any, y: any) => x + y.totalacc, 0),
                    X:  array.filter((x: any) => x.balacode === `${year}12`).reduce((x: any, y: any) => x + y.totalbal, 0),
                    Y:  array.filter((x: any) => x.balacode === `${year}12`).reduce((x: any, y: any) => x + y.totalacc, 0)
                }
            );

            tableBody.push(
                {
                    A:  null,
                    B:  '',
                    C:  '',
                    D:  '',
                    E:  '',
                    F:  '',
                    G:  '',
                    H:  '',
                    I:  '',
                    J:  '',
                    K:  '',
                    L:  '',
                    M:  '',
                    N:  '',
                    O:  '',
                    P:  '',
                    Q:  '',
                    R:  '',
                    S:  '',
                    T:  '',
                    U:  '',
                    V:  '',
                    W:  '',
                    X:  '',
                    Y:  ''
                }
            );

            //#endregion

            tableBody.push(
                {
                    A:  null,
                    B:  'GRIFO PISCO',
                    C:  '',
                    D:  '',
                    E:  '',
                    F:  '',
                    G:  '',
                    H:  '',
                    I:  '',
                    J:  '',
                    K:  '',
                    L:  '',
                    M:  '',
                    N:  '',
                    O:  '',
                    P:  '',
                    Q:  '',
                    R:  '',
                    S:  '',
                    T:  '',
                    U:  '',
                    V:  '',
                    W:  '',
                    X:  '',
                    Y:  ''
                }
            );

            //#region 91, 92, 95

            const array91 = data.filter(x => x.accaccou === '91');
            const array92 = data.filter(x => x.accaccou === '92');
            const array94 = data.filter(x => x.accaccou === '94');
            const array95 = data.filter(x => x.accaccou === '95');

            tableBody.push(
                {
                    A:  null,
                    B:  '(-) GASTOS ADMINISTRATIVOS GRIFO',
                    C:  array91.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0,
                    D:  array91.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0,
                    E:  array91.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0,
                    F:  array91.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0,
                    G:  array91.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0,
                    H:  array91.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0,
                    I:  array91.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0,
                    J:  array91.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0,
                    K:  array91.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0,
                    L:  array91.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0,
                    M:  array91.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0,
                    N:  array91.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0,
                    O:  array91.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0,
                    P:  array91.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0,
                    Q:  array91.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0,
                    R:  array91.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0,
                    S:  array91.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0,
                    T:  array91.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0,
                    U:  array91.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0,
                    V:  array91.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0,
                    W:  array91.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0,
                    X:  array91.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0,
                    Y:  array91.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0
                }
            );

            tableBody.push(
                {
                    A:  null,
                    B:  '(-) GASTOS ADMINISTRATIVOS SHOP',
                    C:  array92.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0,
                    D:  array92.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0,
                    E:  array92.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0,
                    F:  array92.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0,
                    G:  array92.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0,
                    H:  array92.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0,
                    I:  array92.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0,
                    J:  array92.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0,
                    K:  array92.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0,
                    L:  array92.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0,
                    M:  array92.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0,
                    N:  array92.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0,
                    O:  array92.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0,
                    P:  array92.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0,
                    Q:  array92.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0,
                    R:  array92.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0,
                    S:  array92.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0,
                    T:  array92.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0,
                    U:  array92.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0,
                    V:  array92.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0,
                    W:  array92.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0,
                    X:  array92.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0,
                    Y:  array92.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0
                }
            );

            tableBody.push(
                {
                    A:  null,
                    B:  '(-) GASTOS ADMINISTRATIVOS GIMNASIO',
                    C:  array94.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0,
                    D:  array94.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0,
                    E:  array94.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0,
                    F:  array94.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0,
                    G:  array94.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0,
                    H:  array94.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0,
                    I:  array94.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0,
                    J:  array94.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0,
                    K:  array94.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0,
                    L:  array94.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0,
                    M:  array94.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0,
                    N:  array94.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0,
                    O:  array94.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0,
                    P:  array94.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0,
                    Q:  array94.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0,
                    R:  array94.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0,
                    S:  array94.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0,
                    T:  array94.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0,
                    U:  array94.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0,
                    V:  array94.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0,
                    W:  array94.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0,
                    X:  array94.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0,
                    Y:  array94.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0
                }
            );

            tableBody.push(
                {
                    A:  null,
                    B:  '(-) GASTOS ADMINISTRATIVOS',
                    C:  array95.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0,
                    D:  array95.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0,
                    E:  array95.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0,
                    F:  array95.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0,
                    G:  array95.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0,
                    H:  array95.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0,
                    I:  array95.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0,
                    J:  array95.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0,
                    K:  array95.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0,
                    L:  array95.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0,
                    M:  array95.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0,
                    N:  array95.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0,
                    O:  array95.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0,
                    P:  array95.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0,
                    Q:  array95.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0,
                    R:  array95.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0,
                    S:  array95.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0,
                    T:  array95.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0,
                    U:  array95.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0,
                    V:  array95.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0,
                    W:  array95.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0,
                    X:  array95.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0,
                    Y:  array95.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0
                }
            );

            tableBody.push(
                {
                    A:  null,
                    B:  '',
                    C:  '',
                    D:  '',
                    E:  '',
                    F:  '',
                    G:  '',
                    H:  '',
                    I:  '',
                    J:  '',
                    K:  '',
                    L:  '',
                    M:  '',
                    N:  '',
                    O:  '',
                    P:  '',
                    Q:  '',
                    R:  '',
                    S:  '',
                    T:  '',
                    U:  '',
                    V:  '',
                    W:  '',
                    X:  '',
                    Y:  ''
                }
            );
            
            //#endregion

            tableBody.push(
                {
                    A:  null,
                    B:  'GRIFO CHINCHA',
                    C:  '',
                    D:  '',
                    E:  '',
                    F:  '',
                    G:  '',
                    H:  '',
                    I:  '',
                    J:  '',
                    K:  '',
                    L:  '',
                    M:  '',
                    N:  '',
                    O:  '',
                    P:  '',
                    Q:  '',
                    R:  '',
                    S:  '',
                    T:  '',
                    U:  '',
                    V:  '',
                    W:  '',
                    X:  '',
                    Y:  ''
                }
            );

            //#region 91, 92, 95

            const array90 = data.filter(x => x.accaccou === '90');
            const array93 = data.filter(x => x.accaccou === '93');
            const array96 = data.filter(x => x.accaccou === '96');

            tableBody.push(
                {
                    A:  null,
                    B:  '(-) GASTOS ADMINISTRATIVOS GRIFO',
                    C:  array90.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0,
                    D:  array90.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0,
                    E:  array90.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0,
                    F:  array90.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0,
                    G:  array90.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0,
                    H:  array90.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0,
                    I:  array90.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0,
                    J:  array90.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0,
                    K:  array90.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0,
                    L:  array90.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0,
                    M:  array90.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0,
                    N:  array90.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0,
                    O:  array90.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0,
                    P:  array90.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0,
                    Q:  array90.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0,
                    R:  array90.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0,
                    S:  array90.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0,
                    T:  array90.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0,
                    U:  array90.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0,
                    V:  array90.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0,
                    W:  array90.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0,
                    X:  array90.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0,
                    Y:  array90.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0
                }
            );

            tableBody.push(
                {
                    A:  null,
                    B:  '(-) GASTOS ADMINISTRATIVOS SHOP',
                    C:  array93.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0,
                    D:  array93.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0,
                    E:  array93.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0,
                    F:  array93.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0,
                    G:  array93.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0,
                    H:  array93.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0,
                    I:  array93.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0,
                    J:  array93.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0,
                    K:  array93.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0,
                    L:  array93.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0,
                    M:  array93.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0,
                    N:  array93.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0,
                    O:  array93.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0,
                    P:  array93.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0,
                    Q:  array93.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0,
                    R:  array93.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0,
                    S:  array93.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0,
                    T:  array93.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0,
                    U:  array93.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0,
                    V:  array93.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0,
                    W:  array93.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0,
                    X:  array93.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0,
                    Y:  array93.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0
                }
            );

            tableBody.push(
                {
                    A:  null,
                    B:  '(-) GASTOS ADMINISTRATIVOS',
                    C:  array96.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0,
                    D:  array96.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0,
                    E:  array96.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0,
                    F:  array96.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0,
                    G:  array96.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0,
                    H:  array96.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0,
                    I:  array96.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0,
                    J:  array96.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0,
                    K:  array96.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0,
                    L:  array96.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0,
                    M:  array96.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0,
                    N:  array96.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0,
                    O:  array96.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0,
                    P:  array96.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0,
                    Q:  array96.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0,
                    R:  array96.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0,
                    S:  array96.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0,
                    T:  array96.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0,
                    U:  array96.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0,
                    V:  array96.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0,
                    W:  array96.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0,
                    X:  array96.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0,
                    Y:  array96.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0
                }
            );

            tableBody.push(
                {
                    A:  null,
                    B:  '',
                    C:  '',
                    D:  '',
                    E:  '',
                    F:  '',
                    G:  '',
                    H:  '',
                    I:  '',
                    J:  '',
                    K:  '',
                    L:  '',
                    M:  '',
                    N:  '',
                    O:  '',
                    P:  '',
                    Q:  '',
                    R:  '',
                    S:  '',
                    T:  '',
                    U:  '',
                    V:  '',
                    W:  '',
                    X:  '',
                    Y:  ''
                }
            );
            
            //#endregion

            //#region 97 + 99

            const array9799 = data.filter(x => ['97','99'].includes(x.accaccou));
            let utilidadOperacional = data.filter(x => ['70100', '70107', '69100', '69108', '70101','69101', '70103', '69103', '70102', '69102', '70104', '69104', '704100', '70105', '70108', '69105', '69107', '70106','69106','704200', '91','92', '94', '95','90','93','96','97','99'].includes(x.accaccou));

            tableBody.push(
                {
                    A:  null,
                    B:  '(-) GASTOS ADMINISTRATIVOS LIMA',
                    C:  array9799.filter((x: any) => x.balacode === `${year}01`).reduce((x: any, y: any) => x + y.totalbal, 0),
                    D:  array9799.filter((x: any) => x.balacode === `${year}02`).reduce((x: any, y: any) => x + y.totalbal, 0),
                    E:  array9799.filter((x: any) => x.balacode === `${year}02`).reduce((x: any, y: any) => x + y.totalacc, 0),
                    F:  array9799.filter((x: any) => x.balacode === `${year}03`).reduce((x: any, y: any) => x + y.totalbal, 0),
                    G:  array9799.filter((x: any) => x.balacode === `${year}03`).reduce((x: any, y: any) => x + y.totalacc, 0),
                    H:  array9799.filter((x: any) => x.balacode === `${year}04`).reduce((x: any, y: any) => x + y.totalbal, 0),
                    I:  array9799.filter((x: any) => x.balacode === `${year}04`).reduce((x: any, y: any) => x + y.totalacc, 0),
                    J:  array9799.filter((x: any) => x.balacode === `${year}05`).reduce((x: any, y: any) => x + y.totalbal, 0),
                    K:  array9799.filter((x: any) => x.balacode === `${year}05`).reduce((x: any, y: any) => x + y.totalacc, 0),
                    L:  array9799.filter((x: any) => x.balacode === `${year}06`).reduce((x: any, y: any) => x + y.totalbal, 0),
                    M:  array9799.filter((x: any) => x.balacode === `${year}06`).reduce((x: any, y: any) => x + y.totalacc, 0),
                    N:  array9799.filter((x: any) => x.balacode === `${year}07`).reduce((x: any, y: any) => x + y.totalbal, 0),
                    O:  array9799.filter((x: any) => x.balacode === `${year}07`).reduce((x: any, y: any) => x + y.totalacc, 0),
                    P:  array9799.filter((x: any) => x.balacode === `${year}08`).reduce((x: any, y: any) => x + y.totalbal, 0),
                    Q:  array9799.filter((x: any) => x.balacode === `${year}08`).reduce((x: any, y: any) => x + y.totalacc, 0),
                    R:  array9799.filter((x: any) => x.balacode === `${year}09`).reduce((x: any, y: any) => x + y.totalbal, 0),
                    S:  array9799.filter((x: any) => x.balacode === `${year}09`).reduce((x: any, y: any) => x + y.totalacc, 0),
                    T:  array9799.filter((x: any) => x.balacode === `${year}10`).reduce((x: any, y: any) => x + y.totalbal, 0),
                    U:  array9799.filter((x: any) => x.balacode === `${year}10`).reduce((x: any, y: any) => x + y.totalacc, 0),
                    V:  array9799.filter((x: any) => x.balacode === `${year}11`).reduce((x: any, y: any) => x + y.totalbal, 0),
                    W:  array9799.filter((x: any) => x.balacode === `${year}11`).reduce((x: any, y: any) => x + y.totalacc, 0),
                    X:  array9799.filter((x: any) => x.balacode === `${year}12`).reduce((x: any, y: any) => x + y.totalbal, 0),
                    Y:  array9799.filter((x: any) => x.balacode === `${year}12`).reduce((x: any, y: any) => x + y.totalacc, 0)
                }
            );

            tableBody.push(
                {
                    A:  null,
                    B:  '(Incluye gtos. ventas)',
                    C:  '',
                    D:  '',
                    E:  '',
                    F:  '',
                    G:  '',
                    H:  '',
                    I:  '',
                    J:  '',
                    K:  '',
                    L:  '',
                    M:  '',
                    N:  '',
                    O:  '',
                    P:  '',
                    Q:  '',
                    R:  '',
                    S:  '',
                    T:  '',
                    U:  '',
                    V:  '',
                    W:  '',
                    X:  '',
                    Y:  ''
                }
            );

            tableBody.push(
                {
                    A:  null,
                    B:  'UTILIDAD OPERACIONAL',
                    C:  utilidadOperacional.filter((x: any) => x.balacode === `${year}01`).reduce((x: any, y: any) => x + y.totalbal, 0),
                    D:  utilidadOperacional.filter((x: any) => x.balacode === `${year}02`).reduce((x: any, y: any) => x + y.totalbal, 0),
                    E:  utilidadOperacional.filter((x: any) => x.balacode === `${year}02`).reduce((x: any, y: any) => x + y.totalacc, 0),
                    F:  utilidadOperacional.filter((x: any) => x.balacode === `${year}03`).reduce((x: any, y: any) => x + y.totalbal, 0),
                    G:  utilidadOperacional.filter((x: any) => x.balacode === `${year}03`).reduce((x: any, y: any) => x + y.totalacc, 0),
                    H:  utilidadOperacional.filter((x: any) => x.balacode === `${year}04`).reduce((x: any, y: any) => x + y.totalbal, 0),
                    I:  utilidadOperacional.filter((x: any) => x.balacode === `${year}04`).reduce((x: any, y: any) => x + y.totalacc, 0),
                    J:  utilidadOperacional.filter((x: any) => x.balacode === `${year}05`).reduce((x: any, y: any) => x + y.totalbal, 0),
                    K:  utilidadOperacional.filter((x: any) => x.balacode === `${year}05`).reduce((x: any, y: any) => x + y.totalacc, 0),
                    L:  utilidadOperacional.filter((x: any) => x.balacode === `${year}06`).reduce((x: any, y: any) => x + y.totalbal, 0),
                    M:  utilidadOperacional.filter((x: any) => x.balacode === `${year}06`).reduce((x: any, y: any) => x + y.totalacc, 0),
                    N:  utilidadOperacional.filter((x: any) => x.balacode === `${year}07`).reduce((x: any, y: any) => x + y.totalbal, 0),
                    O:  utilidadOperacional.filter((x: any) => x.balacode === `${year}07`).reduce((x: any, y: any) => x + y.totalacc, 0),
                    P:  utilidadOperacional.filter((x: any) => x.balacode === `${year}08`).reduce((x: any, y: any) => x + y.totalbal, 0),
                    Q:  utilidadOperacional.filter((x: any) => x.balacode === `${year}08`).reduce((x: any, y: any) => x + y.totalacc, 0),
                    R:  utilidadOperacional.filter((x: any) => x.balacode === `${year}09`).reduce((x: any, y: any) => x + y.totalbal, 0),
                    S:  utilidadOperacional.filter((x: any) => x.balacode === `${year}09`).reduce((x: any, y: any) => x + y.totalacc, 0),
                    T:  utilidadOperacional.filter((x: any) => x.balacode === `${year}10`).reduce((x: any, y: any) => x + y.totalbal, 0),
                    U:  utilidadOperacional.filter((x: any) => x.balacode === `${year}10`).reduce((x: any, y: any) => x + y.totalacc, 0),
                    V:  utilidadOperacional.filter((x: any) => x.balacode === `${year}11`).reduce((x: any, y: any) => x + y.totalbal, 0),
                    W:  utilidadOperacional.filter((x: any) => x.balacode === `${year}11`).reduce((x: any, y: any) => x + y.totalacc, 0),
                    X:  utilidadOperacional.filter((x: any) => x.balacode === `${year}12`).reduce((x: any, y: any) => x + y.totalbal, 0),
                    Y:  utilidadOperacional.filter((x: any) => x.balacode === `${year}12`).reduce((x: any, y: any) => x + y.totalacc, 0)
                }
            );

            tableBody.push(
                {
                    A:  null,
                    B:  '',
                    C:  '',
                    D:  '',
                    E:  '',
                    F:  '',
                    G:  '',
                    H:  '',
                    I:  '',
                    J:  '',
                    K:  '',
                    L:  '',
                    M:  '',
                    N:  '',
                    O:  '',
                    P:  '',
                    Q:  '',
                    R:  '',
                    S:  '',
                    T:  '',
                    U:  '',
                    V:  '',
                    W:  '',
                    X:  '',
                    Y:  ''
                }
            );
            
            //#endregion

            tableBody.push(
                {
                    A:  null,
                    B:  'OTROS INGRESOS Y EGRESOS',
                    C:  '',
                    D:  '',
                    E:  '',
                    F:  '',
                    G:  '',
                    H:  '',
                    I:  '',
                    J:  '',
                    K:  '',
                    L:  '',
                    M:  '',
                    N:  '',
                    O:  '',
                    P:  '',
                    Q:  '',
                    R:  '',
                    S:  '',
                    T:  '',
                    U:  '',
                    V:  '',
                    W:  '',
                    X:  '',
                    Y:  ''
                }
            );

            //#region 73, 66, 67 - (673100 + 676110), 673100, 676110, 75

            const array73 = data.filter(x => x.accaccou === '73');
            const array74 = data.filter(x => x.accaccou === '74');
            const array66 = data.filter(x => x.accaccou === '66');
            const array67 = data.filter(x => x.accaccou === '67');
            const array671 = data.filter(x => x.accaccou === '671');
            const array676110 = data.filter(x => x.accaccou === '676110');
            const array75 = data.filter(x => x.accaccou === '75');

            tableBody.push(
                {
                    A:  null,
                    B:  'DSCTOS. REBAJAS OBTENIDOS',
                    C:  array73.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0,
                    D:  array73.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0,
                    E:  array73.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0,
                    F:  array73.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0,
                    G:  array73.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0,
                    H:  array73.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0,
                    I:  array73.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0,
                    J:  array73.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0,
                    K:  array73.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0,
                    L:  array73.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0,
                    M:  array73.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0,
                    N:  array73.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0,
                    O:  array73.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0,
                    P:  array73.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0,
                    Q:  array73.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0,
                    R:  array73.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0,
                    S:  array73.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0,
                    T:  array73.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0,
                    U:  array73.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0,
                    V:  array73.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0,
                    W:  array73.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0,
                    X:  array73.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0,
                    Y:  array73.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0
                }
            );

            tableBody.push(
                {
                    A:  null,
                    B:  'DSCTOS. REBAJAS CONCEDIDOS',
                    C:  array74.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0,
                    D:  array74.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0,
                    E:  array74.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0,
                    F:  array74.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0,
                    G:  array74.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0,
                    H:  array74.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0,
                    I:  array74.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0,
                    J:  array74.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0,
                    K:  array74.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0,
                    L:  array74.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0,
                    M:  array74.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0,
                    N:  array74.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0,
                    O:  array74.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0,
                    P:  array74.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0,
                    Q:  array74.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0,
                    R:  array74.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0,
                    S:  array74.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0,
                    T:  array74.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0,
                    U:  array74.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0,
                    V:  array74.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0,
                    W:  array74.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0,
                    X:  array74.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0,
                    Y:  array74.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0
                }
            );

            tableBody.push(
                {
                    A:  null,
                    B:  'CARGAS EXCEPCIONALES',
                    C:  array66.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0,
                    D:  array66.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0,
                    E:  array66.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0,
                    F:  array66.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0,
                    G:  array66.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0,
                    H:  array66.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0,
                    I:  array66.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0,
                    J:  array66.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0,
                    K:  array66.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0,
                    L:  array66.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0,
                    M:  array66.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0,
                    N:  array66.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0,
                    O:  array66.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0,
                    P:  array66.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0,
                    Q:  array66.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0,
                    R:  array66.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0,
                    S:  array66.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0,
                    T:  array66.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0,
                    U:  array66.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0,
                    V:  array66.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0,
                    W:  array66.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0,
                    X:  array66.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0,
                    Y:  array66.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0
                }
            );

            tableBody.push(
                {
                    A:  null,
                    B:  'CARGAS FINANCIERAS',
                    C:  (array67.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0) - ((array671.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0) + (array676110.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0)),
                    D:  (array67.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0) - ((array671.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0) + (array676110.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0)),
                    E:  (array67.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0) - ((array671.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0) + (array676110.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0)),
                    F:  (array67.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0) - ((array671.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0) + (array676110.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0)),
                    G:  (array67.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0) - ((array671.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0) + (array676110.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0)),
                    H:  (array67.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0) - ((array671.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0) + (array676110.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0)),
                    I:  (array67.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0) - ((array671.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0) + (array676110.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0)),
                    J:  (array67.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0) - ((array671.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0) + (array676110.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0)),
                    K:  (array67.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0) - ((array671.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0) + (array676110.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0)),
                    L:  (array67.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0) - ((array671.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0) + (array676110.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0)),
                    M:  (array67.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0) - ((array671.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0) + (array676110.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0)),
                    N:  (array67.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0) - ((array671.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0) + (array676110.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0)),
                    O:  (array67.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0) - ((array671.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0) + (array676110.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0)),
                    P:  (array67.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0) - ((array671.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0) + (array676110.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0)),
                    Q:  (array67.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0) - ((array671.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0) + (array676110.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0)),
                    R:  (array67.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0) - ((array671.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0) + (array676110.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0)),
                    S:  (array67.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0) - ((array671.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0) + (array676110.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0)),
                    T:  (array67.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0) - ((array671.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0) + (array676110.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0)),
                    U:  (array67.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0) - ((array671.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0) + (array676110.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0)),
                    V:  (array67.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0) - ((array671.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0) + (array676110.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0)),
                    W:  (array67.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0) - ((array671.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0) + (array676110.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0)),
                    X:  (array67.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0) - ((array671.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0) + (array676110.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0)),
                    Y:  (array67.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0) - ((array671.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0) + (array676110.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0))
                }
            );

            tableBody.push(
                {
                    A:  null,
                    B:  'INTERESES PRESTAMOS',
                    C:  array671.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0,
                    D:  array671.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0,
                    E:  array671.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0,
                    F:  array671.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0,
                    G:  array671.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0,
                    H:  array671.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0,
                    I:  array671.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0,
                    J:  array671.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0,
                    K:  array671.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0,
                    L:  array671.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0,
                    M:  array671.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0,
                    N:  array671.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0,
                    O:  array671.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0,
                    P:  array671.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0,
                    Q:  array671.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0,
                    R:  array671.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0,
                    S:  array671.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0,
                    T:  array671.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0,
                    U:  array671.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0,
                    V:  array671.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0,
                    W:  array671.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0,
                    X:  array671.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0,
                    Y:  array671.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0
                }
            );

            tableBody.push(
                {
                    A:  null,
                    B:  'DIFERENCIA DE CAMBIO',
                    C:  array676110.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0,
                    D:  array676110.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0,
                    E:  array676110.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0,
                    F:  array676110.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0,
                    G:  array676110.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0,
                    H:  array676110.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0,
                    I:  array676110.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0,
                    J:  array676110.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0,
                    K:  array676110.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0,
                    L:  array676110.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0,
                    M:  array676110.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0,
                    N:  array676110.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0,
                    O:  array676110.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0,
                    P:  array676110.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0,
                    Q:  array676110.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0,
                    R:  array676110.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0,
                    S:  array676110.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0,
                    T:  array676110.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0,
                    U:  array676110.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0,
                    V:  array676110.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0,
                    W:  array676110.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0,
                    X:  array676110.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0,
                    Y:  array676110.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0
                }
            );

            tableBody.push(
                {
                    A:  null,
                    B:  'OTROS INGRESOS(Otros)',
                    C:  array75.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0,
                    D:  array75.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0,
                    E:  array75.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0,
                    F:  array75.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0,
                    G:  array75.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0,
                    H:  array75.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0,
                    I:  array75.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0,
                    J:  array75.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0,
                    K:  array75.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0,
                    L:  array75.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0,
                    M:  array75.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0,
                    N:  array75.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0,
                    O:  array75.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0,
                    P:  array75.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0,
                    Q:  array75.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0,
                    R:  array75.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0,
                    S:  array75.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0,
                    T:  array75.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0,
                    U:  array75.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0,
                    V:  array75.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0,
                    W:  array75.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0,
                    X:  array75.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0,
                    Y:  array75.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0
                }
            );

            tableBody.push(
                {
                    A:  null,
                    B:  '',
                    C:  '',
                    D:  '',
                    E:  '',
                    F:  '',
                    G:  '',
                    H:  '',
                    I:  '',
                    J:  '',
                    K:  '',
                    L:  '',
                    M:  '',
                    N:  '',
                    O:  '',
                    P:  '',
                    Q:  '',
                    R:  '',
                    S:  '',
                    T:  '',
                    U:  '',
                    V:  '',
                    W:  '',
                    X:  '',
                    Y:  ''
                }
            );

            //#endregion 

            //#region 77

            const array77 = data.filter(x => x.accaccou === '77');
            const array776010 = data.filter(x => x.accaccou === '776010');
            const array776000 = data.filter(x => x.accaccou === '776000');

            tableBody.push(
                {
                    A:  null,
                    B:  'INGRESOS FINANCIEROS',
                    C:  (array77.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0) - array776010.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0,
                    D:  (array77.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0) - array776010.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0,
                    E:  (array77.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0) - array776010.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0,
                    F:  (array77.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0) - array776010.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0,
                    G:  (array77.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0) - array776010.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0,
                    H:  (array77.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0) - array776010.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0,
                    I:  (array77.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0) - array776010.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0,
                    J:  (array77.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0) - array776010.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0,
                    K:  (array77.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0) - array776010.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0,
                    L:  (array77.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0) - array776010.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0,
                    M:  (array77.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0) - array776010.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0,
                    N:  (array77.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0) - array776010.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0,
                    O:  (array77.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0) - array776010.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0,
                    P:  (array77.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0) - array776010.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0,
                    Q:  (array77.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0) - array776010.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0,
                    R:  (array77.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0) - array776010.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0,
                    S:  (array77.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0) - array776010.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0,
                    T:  (array77.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0) - array776010.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0,
                    U:  (array77.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0) - array776010.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0,
                    V:  (array77.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0) - array776010.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0,
                    W:  (array77.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0) - array776010.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0,
                    X:  (array77.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0) - array776010.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0,
                    Y:  (array77.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0) - array776010.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0
                }
            );

            tableBody.push(
                {
                    A:  null,
                    B:  'INGRESOS FINANCIEROS(DIF/CAMBIO)',
                    C:  array776000.find((x: any) => x.balacode === `${year}01`)?.totalbal || 0,
                    D:  array776000.find((x: any) => x.balacode === `${year}02`)?.totalbal || 0,
                    E:  array776000.find((x: any) => x.balacode === `${year}02`)?.totalacc || 0,
                    F:  array776000.find((x: any) => x.balacode === `${year}03`)?.totalbal || 0,
                    G:  array776000.find((x: any) => x.balacode === `${year}03`)?.totalacc || 0,
                    H:  array776000.find((x: any) => x.balacode === `${year}04`)?.totalbal || 0,
                    I:  array776000.find((x: any) => x.balacode === `${year}04`)?.totalacc || 0,
                    J:  array776000.find((x: any) => x.balacode === `${year}05`)?.totalbal || 0,
                    K:  array776000.find((x: any) => x.balacode === `${year}05`)?.totalacc || 0,
                    L:  array776000.find((x: any) => x.balacode === `${year}06`)?.totalbal || 0,
                    M:  array776000.find((x: any) => x.balacode === `${year}06`)?.totalacc || 0,
                    N:  array776000.find((x: any) => x.balacode === `${year}07`)?.totalbal || 0,
                    O:  array776000.find((x: any) => x.balacode === `${year}07`)?.totalacc || 0,
                    P:  array776000.find((x: any) => x.balacode === `${year}08`)?.totalbal || 0,
                    Q:  array776000.find((x: any) => x.balacode === `${year}08`)?.totalacc || 0,
                    R:  array776000.find((x: any) => x.balacode === `${year}09`)?.totalbal || 0,
                    S:  array776000.find((x: any) => x.balacode === `${year}09`)?.totalacc || 0,
                    T:  array776000.find((x: any) => x.balacode === `${year}10`)?.totalbal || 0,
                    U:  array776000.find((x: any) => x.balacode === `${year}10`)?.totalacc || 0,
                    V:  array776000.find((x: any) => x.balacode === `${year}11`)?.totalbal || 0,
                    W:  array776000.find((x: any) => x.balacode === `${year}11`)?.totalacc || 0,
                    X:  array776000.find((x: any) => x.balacode === `${year}12`)?.totalbal || 0,
                    Y:  array776000.find((x: any) => x.balacode === `${year}12`)?.totalacc || 0
                }
            );

            tableBody.push(
                {
                    A:  null,
                    B:  '',
                    C:  '',
                    D:  '',
                    E:  '',
                    F:  '',
                    G:  '',
                    H:  '',
                    I:  '',
                    J:  '',
                    K:  '',
                    L:  '',
                    M:  '',
                    N:  '',
                    O:  '',
                    P:  '',
                    Q:  '',
                    R:  '',
                    S:  '',
                    T:  '',
                    U:  '',
                    V:  '',
                    W:  '',
                    X:  '',
                    Y:  ''
                }
            );

            //#endregion

            let utilidadAntesImpuesto = data.filter(x => ['70100', '70107', '69100', '69108', '70101','69101', '70103', '69103', '70102', '69102', '70104', '69104', '704100', '70105', '70108', '69105', '69107', '70106','69106','704200','91','92','94','95','90','93','96','97','99','73','74','67','75','77'].includes(x.accaccou));
            
            const totC = utilidadAntesImpuesto.filter((x: any) => x.balacode === `${year}01`).reduce((x: any, y: any) => x + y.totalbal, 0);
            const totD = utilidadAntesImpuesto.filter((x: any) => x.balacode === `${year}02`).reduce((x: any, y: any) => x + y.totalbal, 0);
            const totE = utilidadAntesImpuesto.filter((x: any) => x.balacode === `${year}02`).reduce((x: any, y: any) => x + y.totalacc, 0);
            const totF = utilidadAntesImpuesto.filter((x: any) => x.balacode === `${year}03`).reduce((x: any, y: any) => x + y.totalbal, 0);
            const totG = utilidadAntesImpuesto.filter((x: any) => x.balacode === `${year}03`).reduce((x: any, y: any) => x + y.totalacc, 0);
            const totH = utilidadAntesImpuesto.filter((x: any) => x.balacode === `${year}04`).reduce((x: any, y: any) => x + y.totalbal, 0);
            const totI = utilidadAntesImpuesto.filter((x: any) => x.balacode === `${year}04`).reduce((x: any, y: any) => x + y.totalacc, 0);
            const totJ = utilidadAntesImpuesto.filter((x: any) => x.balacode === `${year}05`).reduce((x: any, y: any) => x + y.totalbal, 0);
            const totK = utilidadAntesImpuesto.filter((x: any) => x.balacode === `${year}05`).reduce((x: any, y: any) => x + y.totalacc, 0);
            const totL = utilidadAntesImpuesto.filter((x: any) => x.balacode === `${year}06`).reduce((x: any, y: any) => x + y.totalbal, 0);
            const totM = utilidadAntesImpuesto.filter((x: any) => x.balacode === `${year}06`).reduce((x: any, y: any) => x + y.totalacc, 0);
            const totN = utilidadAntesImpuesto.filter((x: any) => x.balacode === `${year}07`).reduce((x: any, y: any) => x + y.totalbal, 0);
            const totO = utilidadAntesImpuesto.filter((x: any) => x.balacode === `${year}07`).reduce((x: any, y: any) => x + y.totalacc, 0);
            const totP = utilidadAntesImpuesto.filter((x: any) => x.balacode === `${year}08`).reduce((x: any, y: any) => x + y.totalbal, 0);
            const totQ = utilidadAntesImpuesto.filter((x: any) => x.balacode === `${year}08`).reduce((x: any, y: any) => x + y.totalacc, 0);
            const totR = utilidadAntesImpuesto.filter((x: any) => x.balacode === `${year}09`).reduce((x: any, y: any) => x + y.totalbal, 0);
            const totS = utilidadAntesImpuesto.filter((x: any) => x.balacode === `${year}09`).reduce((x: any, y: any) => x + y.totalacc, 0);
            const totT = utilidadAntesImpuesto.filter((x: any) => x.balacode === `${year}10`).reduce((x: any, y: any) => x + y.totalbal, 0);
            const totU = utilidadAntesImpuesto.filter((x: any) => x.balacode === `${year}10`).reduce((x: any, y: any) => x + y.totalacc, 0);
            const totV = utilidadAntesImpuesto.filter((x: any) => x.balacode === `${year}11`).reduce((x: any, y: any) => x + y.totalbal, 0);
            const totW = utilidadAntesImpuesto.filter((x: any) => x.balacode === `${year}11`).reduce((x: any, y: any) => x + y.totalacc, 0);
            const totX = utilidadAntesImpuesto.filter((x: any) => x.balacode === `${year}12`).reduce((x: any, y: any) => x + y.totalbal, 0);
            const totY = utilidadAntesImpuesto.filter((x: any) => x.balacode === `${year}12`).reduce((x: any, y: any) => x + y.totalacc, 0);

            tableBody.push(
                {
                    A:  null,
                    B:  'UTILIDAD ANTES DEL IMPUESTO',
                    C:  totC,
                    D:  totD,
                    E:  totE,
                    F:  totF,
                    G:  totG,
                    H:  totH,
                    I:  totI,
                    J:  totJ,
                    K:  totK,
                    L:  totL,
                    M:  totM,
                    N:  totN,
                    O:  totO,
                    P:  totP,
                    Q:  totQ,
                    R:  totR,
                    S:  totS,
                    T:  totT,
                    U:  totU,
                    V:  totV,
                    W:  totW,
                    X:  totX,
                    Y:  totY
                }
            );

            tableBody.push(
                {
                    A:  null,
                    B:  'ADICIONALES',
                    C:  0,
                    D:  0,
                    E:  0,
                    F:  0,
                    G:  0,
                    H:  0,
                    I:  0,
                    J:  0,
                    K:  0,
                    L:  0,
                    M:  0,
                    N:  0,
                    O:  0,
                    P:  0,
                    Q:  0,
                    R:  0,
                    S:  0,
                    T:  0,
                    U:  0,
                    V:  0,
                    W:  0,
                    X:  0,
                    Y:  0
                }
            );

            tableBody.push(
                {
                    A:  null,
                    B:  'UTILIDAD TRIBUTARIA ANTES DEL IMPUESTO',
                    C:  0,
                    D:  0,
                    E:  0,
                    F:  0,
                    G:  0,
                    H:  0,
                    I:  0,
                    J:  0,
                    K:  0,
                    L:  0,
                    M:  0,
                    N:  0,
                    O:  0,
                    P:  0,
                    Q:  0,
                    R:  0,
                    S:  0,
                    T:  0,
                    U:  0,
                    V:  0,
                    W:  0,
                    X:  0,
                    Y:  0
                }
            );

            tableBody.push(
                {
                    A:  null,
                    B:  'PARTICIPACION DE LOS TRABAJADORES',
                    C:  0,
                    D:  0,
                    E:  0,
                    F:  0,
                    G:  0,
                    H:  0,
                    I:  0,
                    J:  0,
                    K:  0,
                    L:  0,
                    M:  0,
                    N:  0,
                    O:  0,
                    P:  0,
                    Q:  0,
                    R:  0,
                    S:  0,
                    T:  0,
                    U:  0,
                    V:  0,
                    W:  0,
                    X:  0,
                    Y:  0
                }
            );

            tableBody.push(
                {
                    A:  null,
                    B:  'UTILIDAD TRIBUTARIA ANTES DEL IMPUESTO',
                    C:  0,
                    D:  0,
                    E:  0,
                    F:  0,
                    G:  0,
                    H:  0,
                    I:  0,
                    J:  0,
                    K:  0,
                    L:  0,
                    M:  0,
                    N:  0,
                    O:  0,
                    P:  0,
                    Q:  0,
                    R:  0,
                    S:  0,
                    T:  0,
                    U:  0,
                    V:  0,
                    W:  0,
                    X:  0,
                    Y:  0
                }
            );

            tableBody.push(
                {
                    A:  null,
                    B:  'IMPUESTO A LA RENTA',
                    C:  totC * -1 > 0 ? 0 - totC * 29.5 / 100 : 0,
                    D:  totD * -1 > 0 ? 0 - totD * 29.5 / 100 : 0,
                    E:  totE * -1 > 0 ? 0 - totE * 29.5 / 100 : 0,
                    F:  totF * -1 > 0 ? 0 - totF * 29.5 / 100 : 0,
                    G:  totG * -1 > 0 ? 0 - totG * 29.5 / 100 : 0,
                    H:  totH * -1 > 0 ? 0 - totH * 29.5 / 100 : 0,
                    I:  totI * -1 > 0 ? 0 - totI * 29.5 / 100 : 0,
                    J:  totJ * -1 > 0 ? 0 - totJ * 29.5 / 100 : 0,
                    K:  totK * -1 > 0 ? 0 - totK * 29.5 / 100 : 0,
                    L:  totL * -1 > 0 ? 0 - totL * 29.5 / 100 : 0,
                    M:  totM * -1 > 0 ? 0 - totM * 29.5 / 100 : 0,
                    N:  totN * -1 > 0 ? 0 - totN * 29.5 / 100 : 0,
                    O:  totO * -1 > 0 ? 0 - totO * 29.5 / 100 : 0,
                    P:  totP * -1 > 0 ? 0 - totP * 29.5 / 100 : 0,
                    Q:  totQ * -1 > 0 ? 0 - totQ * 29.5 / 100 : 0,
                    R:  totR * -1 > 0 ? 0 - totR * 29.5 / 100 : 0,
                    S:  totS * -1 > 0 ? 0 - totS * 29.5 / 100 : 0,
                    T:  totT * -1 > 0 ? 0 - totT * 29.5 / 100 : 0,
                    U:  totU * -1 > 0 ? 0 - totU * 29.5 / 100 : 0,
                    V:  totV * -1 > 0 ? 0 - totV * 29.5 / 100 : 0,
                    W:  totW * -1 > 0 ? 0 - totW * 29.5 / 100 : 0,
                    X:  totX * -1 > 0 ? 0 - totX * 29.5 / 100 : 0,
                    Y:  totY * -1 > 0 ? 0 - totY * 29.5 / 100 : 0
                }
            );

            tableBody.push(
                {
                    A:  null,
                    B:  'RESULTADO DEL EJERCICIO - TRIBUTARIO',
                    C:  0,
                    D:  0,
                    E:  0,
                    F:  0,
                    G:  0,
                    H:  0,
                    I:  0,
                    J:  0,
                    K:  0,
                    L:  0,
                    M:  0,
                    N:  0,
                    O:  0,
                    P:  0,
                    Q:  0,
                    R:  0,
                    S:  0,
                    T:  0,
                    U:  0,
                    V:  0,
                    W:  0,
                    X:  0,
                    Y:  0
                }
            );

            tableBody.push(
                {
                    A:  null,
                    B:  'RESULTADO DEL EJERCICIO - CONTABLE',
                    C:  totC + (totC * -1 > 0 ? 0 - totC * 29.5 / 100 : 0),
                    D:  totD + (totD * -1 > 0 ? 0 - totD * 29.5 / 100 : 0),
                    E:  totE + (totE * -1 > 0 ? 0 - totE * 29.5 / 100 : 0),
                    F:  totF + (totF * -1 > 0 ? 0 - totF * 29.5 / 100 : 0),
                    G:  totG + (totG * -1 > 0 ? 0 - totG * 29.5 / 100 : 0),
                    H:  totH + (totH * -1 > 0 ? 0 - totH * 29.5 / 100 : 0),
                    I:  totI + (totI * -1 > 0 ? 0 - totI * 29.5 / 100 : 0),
                    J:  totJ + (totJ * -1 > 0 ? 0 - totJ * 29.5 / 100 : 0),
                    K:  totK + (totK * -1 > 0 ? 0 - totK * 29.5 / 100 : 0),
                    L:  totL + (totL * -1 > 0 ? 0 - totL * 29.5 / 100 : 0),
                    M:  totM + (totM * -1 > 0 ? 0 - totM * 29.5 / 100 : 0),
                    N:  totN + (totN * -1 > 0 ? 0 - totN * 29.5 / 100 : 0),
                    O:  totO + (totO * -1 > 0 ? 0 - totO * 29.5 / 100 : 0),
                    P:  totP + (totP * -1 > 0 ? 0 - totP * 29.5 / 100 : 0),
                    Q:  totQ + (totQ * -1 > 0 ? 0 - totQ * 29.5 / 100 : 0),
                    R:  totR + (totR * -1 > 0 ? 0 - totR * 29.5 / 100 : 0),
                    S:  totS + (totS * -1 > 0 ? 0 - totS * 29.5 / 100 : 0),
                    T:  totT + (totT * -1 > 0 ? 0 - totT * 29.5 / 100 : 0),
                    U:  totU + (totU * -1 > 0 ? 0 - totU * 29.5 / 100 : 0),
                    V:  totV + (totV * -1 > 0 ? 0 - totV * 29.5 / 100 : 0),
                    W:  totW + (totW * -1 > 0 ? 0 - totW * 29.5 / 100 : 0),
                    X:  totX + (totX * -1 > 0 ? 0 - totX * 29.5 / 100 : 0),
                    Y:  totY + (totY * -1 > 0 ? 0 - totY * 29.5 / 100 : 0)
                }
            );

            const header = [];

            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(tableBody, {header, skipHeader: true});
            ws["!cols"] = [
                {wpx:10},{wpx:200},{wpx:80},{wpx:80},{wpx:80},{wpx:80},{wpx:80},{wpx:80},{wpx:80},{wpx:80},
                {wpx:80},{wpx:80},{wpx:80},{wpx:80},{wpx:80},{wpx:80},{wpx:80},{wpx:80},{wpx:80},{wpx:80},
                {wpx:80},{wpx:80},{wpx:80},{wpx:80},{wpx:80},{wpx:80},
            ];
            ws['!rows'] = [
                {hpx: 16},{hpx: 18},{hpx: 16}
            ];

            const keys = Object.keys(ws);
            const rowsCount = tableBody.length;

            for (const iterator of keys) {
                if(ws[iterator].v?.toString().match(/^[0-9,.-]+$/g) && !iterator.match(/^(A|B)[0-9]+$/g)) {
                    ws[iterator].v = ws[iterator].v * -1;
                    ws[iterator].t = 'n';
                    ws[iterator].z = '#,##0.00;[Black](#,##0.00);;';
                }
                if(iterator !== '!ref' && iterator !== '!cols' && iterator !== '!rows') {
                    ws[iterator].s = {
                        font: {
                            name: 'Tahoma',
                            sz: 9
                        }
                    }
                    const row = +(iterator.match(/\d+/)[0]);
                    if(!iterator.match(/^(A)[0-9]+$/g)) {
                        if(row === 5) {
                            ws[iterator].s = {...ws[iterator].s, alignment: { horizontal: 'center', vertical: 'top' }}
                            ws[iterator].s.border = {
                                right: { style: 'thin', color: 'black'},
                                left: { style: 'thin', color: 'black'},
                                top: { style: 'thin', color: 'black'}
                            }
                            if(iterator === 'B4') {
                                ws[iterator].s = {...ws[iterator].s, alignment: { horizontal: 'left', vertical: 'top' }}
                            }
                        } else if(row === 6) {
                            if(/^(B|C|D|F|H|J|L|N|P|R|T|V|X)[0-9]+$/g.test(iterator)) {
                                ws[iterator].s.alignment = { horizontal: 'center' };
                                ws[iterator].s.border = {
                                    left: { style: 'thin', color: 'black'},
                                    bottom: { style: 'thin', color: 'black'}
                                }
                            } else if(/^(B|E|G|I|K|M|O|Q|S|U|W|Y)[0-9]+$/g.test(iterator)) {
                                ws[iterator].s.alignment = { horizontal: 'center' };
                                ws[iterator].s.border = {
                                    right: { style: 'thin', color: 'black'},
                                    bottom: { style: 'thin', color: 'black'}
                                }
                            }
                        } 
                        else if(!(/^(B)[0-9]+$/g.test(iterator)) && [11,15,19,27,30,33,35,36,41,45,48,50,51,81,85].includes(row)) {
                            ws[iterator].s.border = {
                                bottom: { style: 'thin', color: 'black'}
                            }
                        }
                        if((/^(B)[0-9]+$/g.test(iterator)) && [8,38,55,61].includes(row)) {
                            ws[iterator].s = {
                                ...ws[iterator].s,
                                font: {
                                    ...ws[iterator].s.font,
                                    sz: 11,
                                    bold: true
                                }
                            }
                        }
                        if((/^(B)[0-9]+$/g.test(iterator)) && [36,51,53,70].includes(row)) {
                            ws[iterator].s = {
                                ...ws[iterator].s,
                                font: {
                                    ...ws[iterator].s.font,
                                    bold: true
                                }
                            }
                        }
                    }
                    if(!iterator.match(/^(A)[0-9]+$/g) && row >= 7) {
                        ws[iterator].s.border = {
                            ...ws[iterator].s.border,
                            right: { style: 'thin', color: 'black'},
                            left: { style: 'thin', color: 'black'},
                        }
                    }
                    if(/^(B)[0-9]+$/g.test(iterator) && row >= (rowsCount - 5)) {
                        ws[iterator].s = {
                            ...ws[iterator].s,
                            alignment: { horizontal: 'center' }
                        }
                        if(row === (rowsCount - 2)) {
                            ws[iterator].s = {
                                ...ws[iterator].s,
                                font: {
                                    ...ws[iterator].s.font,
                                    sz: 11,
                                    bold: true
                                }
                            }
                        }
                    }
                    if(row === (rowsCount - 6)) {
                        ws[iterator].s.border = {
                            ...ws[iterator].s.border,
                            bottom: { style: 'thin', color: 'black'}
                        }
                    }
                    if(row === rowsCount || row === (rowsCount - 1)) {
                        ws[iterator].s.border = {
                            ...ws[iterator].s.border,
                            bottom: { style: 'thin', color: 'black'},
                            top: { style: 'thin', color: 'black'}
                        }
                    }
                }
            }

            for (let index = 3; index <= rowsCount; index++) {
                ws['!rows'].push({hpx: 16})
            }

            ws['B1'].s = {font:{name: 'Tahoma', sz: 9, bold: true}}
            ws['B2'].s = {font:{name: 'Tahoma', sz: 10, bold: true}}
            ws['B3'].s = {font:{name: 'Tahoma', sz: 9, bold: true}}
            // ws['B5'].s = {font:{name: 'Tahoma', sz: 10, bold: true}, alignment: { horizontal: 'center' }}
            
            if (totC === 0) ws['!cols'][2] .hidden = true;
            if (totD === 0) ws['!cols'][3] .hidden = true;
            if (totE === 0) ws['!cols'][4] .hidden = true;
            if (totF === 0) ws['!cols'][5] .hidden = true;
            if (totG === 0) ws['!cols'][6] .hidden = true;
            if (totH === 0) ws['!cols'][7] .hidden = true;
            if (totI === 0) ws['!cols'][8] .hidden = true;
            if (totJ === 0) ws['!cols'][9] .hidden = true;
            if (totK === 0) ws['!cols'][10].hidden = true;
            if (totL === 0) ws['!cols'][11].hidden = true;
            if (totM === 0) ws['!cols'][12].hidden = true;
            if (totN === 0) ws['!cols'][13].hidden = true;
            if (totO === 0) ws['!cols'][14].hidden = true;
            if (totP === 0) ws['!cols'][15].hidden = true;
            if (totQ === 0) ws['!cols'][16].hidden = true;
            if (totR === 0) ws['!cols'][17].hidden = true;
            if (totS === 0) ws['!cols'][18].hidden = true;
            if (totT === 0) ws['!cols'][19].hidden = true;
            if (totU === 0) ws['!cols'][20].hidden = true;
            if (totV === 0) ws['!cols'][21].hidden = true;
            if (totW === 0) ws['!cols'][22].hidden = true;
            if (totX === 0) ws['!cols'][23].hidden = true;
            if (totY === 0) ws['!cols'][24].hidden = true;

            // for (const row of hiddenRow) {
            //     ws['!rows'][row-1].hidden = true
            // }

            ws["!merges"] = [
                { s: { r: 4, c: 1 }, e: { r: 5, c: 1 } },
                { s: { r: 4, c: 3 }, e: { r: 4, c: 4 } },
                { s: { r: 4, c: 5 }, e: { r: 4, c: 6 } },
                { s: { r: 4, c: 7 }, e: { r: 4, c: 8 } },
                { s: { r: 4, c: 9 }, e: { r: 4, c: 10 } },
                { s: { r: 4, c: 11 }, e: { r: 4, c: 12 } },
                { s: { r: 4, c: 13 }, e: { r: 4, c: 14 } },
                { s: { r: 4, c: 15 }, e: { r: 4, c: 16 } },
                { s: { r: 4, c: 17 }, e: { r: 4, c: 18 } },
                { s: { r: 4, c: 19 }, e: { r: 4, c: 20 } },
                { s: { r: 4, c: 21 }, e: { r: 4, c: 22 } },
                { s: { r: 4, c: 23 }, e: { r: 4, c: 24 } },
                { s: { r: 4, c: 25 }, e: { r: 4, c: 26 } },
            ];

            XLSX.utils.book_append_sheet(wb, ws, "EGP Anual");

            var wbout = XLSX.write(wb, {bookType:'xlsx', type: 'binary', cellStyles: true});

            const s2ab = function(s) {
                var buf = new ArrayBuffer(s.length);
                var view = new Uint8Array(buf);
                for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
                return buf;
            }

            saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), `egp-anual-${year}-${month}.xlsx`);
        } catch(err) {
            console.log(err);
        }
    }

    render() {

        const { title, year, month, base64Pdf } = this.state;
        const { loading, list, tables, failed, failedTitle, failedContent } = this.props.accountantReducers;
        const { classes } = this.props;

        return (
            <div style={{overflow: 'auto', height: '100%'}}>
                <Paper className='form__container'>
                    <Typography variant='h5'>{ title }</Typography>
                    <br/>
                    { failed && <MessageBox title={failedTitle} content={failedContent}/> }
                    <div style={{ display: 'flex' }}>
                        <form noValidate autoComplete="off" style={{ flex: 1, paddingRight: '15px', paddingTop: '2em' }}>
                            <Grid container spacing={2} >
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_año">Año</InputLabel>
                                            <Select
                                                labelId="lbl_año"
                                                id="year"
                                                className='select'
                                                label="Año"
                                                fullWidth
                                                value={ year }
                                                onChange={(e: any) => this.onChangeRoot({'year': e.target.value})}
                                            >
                                                <MenuItem value='2030'>2030</MenuItem>
                                                <MenuItem value='2029'>2029</MenuItem>
                                                <MenuItem value='2028'>2028</MenuItem>
                                                <MenuItem value='2027'>2027</MenuItem>
                                                <MenuItem value='2026'>2026</MenuItem>
                                                <MenuItem value='2025'>2025</MenuItem>
                                                <MenuItem value='2026'>2026</MenuItem>
                                                <MenuItem value='2025'>2025</MenuItem>
                                                <MenuItem value='2024'>2024</MenuItem>
                                                <MenuItem value='2023'>2023</MenuItem>
                                                <MenuItem value='2022'>2022</MenuItem>
                                                <MenuItem value='2021'>2021</MenuItem>
                                                <MenuItem value='2020'>2020</MenuItem>
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_mes">Mes</InputLabel>
                                            <Select
                                                labelId="lbl_mes"
                                                id="month"
                                                className='select'
                                                label="Mes"
                                                fullWidth
                                                value={ month }
                                                onChange={(e: any) => this.onChangeRoot({'month': e.target.value})}
                                            >
                                                {
                                                    meses.map((x: any) => 
                                                        <MenuItem value={x.key} key={x.key}>{x.value}</MenuItem>
                                                    )
                                                }
                                            </Select>
                                    </FormControl>
                                </Grid>
                                {/* <Grid item xs={12} style={{ paddingTop: '50px' }}>
                                    <Button variant="contained" fullWidth color="primary" onClick={() => { 
                                            const balacode = [];
                                            for(let i = 0; i <= +month; i++) {
                                                balacode.push(`${year}${i.toString().padStart(2, '0')}`)
                                            }
                                            this.props.profitLossStatementRequest(year, balacode, this.onGeneratePdf) 
                                        }}>
                                        Generar Reporte
                                    </Button>
                                </Grid> */}
                                <Grid item xs={12} style={{ paddingTop: '10px' }}>
                                    <Button variant="contained" fullWidth color="inherit" onClick={() => { 
                                            const balacode = [];
                                            for(let i = 0; i <= +month; i++) {
                                                balacode.push(`${year}${i.toString().padStart(2, '0')}`)
                                            }
                                            this.props.profitLossStatementRequest(year, balacode, this.onGenerateExcel) 
                                        }}>
                                        Generar Reporte EXCEL
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                        <PdfView data={base64Pdf} style={{ flex: 2 }}/>
                    </div>
                    <br/>
                    {/* <FormFooter onCancel={ this.onCancel } onSubmit={ this.onSubmit }/> */}
                </Paper>
                <ScreenLoader enabled={loading}/>
            </div>
        );
    }
}

const accountantReducers = 'accountantReducers';
const dashboardReducers = 'dashboardReducers';

const mapStateToProps = (state: any) => ({
    accountantReducers: state.get(accountantReducers),
    dashboardReducers: state.get(dashboardReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        profitLossStatementRequest
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(ReportProfitLossStatement));