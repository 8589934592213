import * as authPipeline from './pipelines/authPipeline'; 
import * as usersPipeline from './pipelines/usersPipeline'; 
import * as generalPipeline from './pipelines/generalPipeline'; 
import * as accountantPipeline from './pipelines/accountantPipeline';
import * as wharehouseProductsPipeline from './pipelines/wharehouseProductsPipeline';
import * as salesPipeline from './pipelines/salesPipeline';
import * as shoppingsPipeline from './pipelines/shoppingsPipeline';
import * as humanResourcesPipeline from './pipelines/humanResourcesPipeline';
import * as financePipeline from './pipelines/financePipeline';

const pipelines = {
    authPipeline,
    usersPipeline,
    generalPipeline,
    accountantPipeline,
    wharehouseProductsPipeline,
    salesPipeline,
    shoppingsPipeline,
    humanResourcesPipeline,
    financePipeline
};

export default pipelines;