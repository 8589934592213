const serverUrl = process.env.PENTA_MASTER_SERVER_URL || `https://api.${window.location.hostname}/api/`;

export const COMMISSIS_INIT_UPDATE_FORM = serverUrl + 'humanresources/commissis/init-update-form/';
export const COMMISSIS_GET_ALL_RECORDS = serverUrl + 'humanresources/commissis/get-all-records';
export const COMMISSIS_INSERT = serverUrl + 'humanresources/commissis/insert';
export const COMMISSIS_UPDATE = serverUrl + 'humanresources/commissis/update/';
export const COMMISSIS_DELETE = serverUrl + 'humanresources/commissis/delete?';

export const WEEKCALES_INIT_UPDATE_FORM = serverUrl + 'humanresources/weekcales/init-update-form/';
export const WEEKCALES_GET_ALL_RECORDS = serverUrl + 'humanresources/weekcales/get-all-records';
export const WEEKCALES_INSERT = serverUrl + 'humanresources/weekcales/insert';
export const WEEKCALES_UPDATE = serverUrl + 'humanresources/weekcales/update/';
export const WEEKCALES_DELETE = serverUrl + 'humanresources/weekcales/delete?';

export const EMPLOYES_INIT_FORM = serverUrl + 'humanresources/employes/init-form';
export const EMPLOYES_INIT_UPDATE_FORM = serverUrl + 'humanresources/employes/init-update-form/';
export const EMPLOYES_GET_ALL_RECORDS = serverUrl + 'humanresources/employes/get-all-records';
export const EMPLOYES_INSERT = serverUrl + 'humanresources/employes/insert';
export const EMPLOYES_UPDATE = serverUrl + 'humanresources/employes/update/';
export const EMPLOYES_DELETE = serverUrl + 'humanresources/employes/delete?';

export const CONCEPTS_INIT_FORM = serverUrl + 'humanresources/concepts/init-form/';
export const CONCEPTS_INIT_UPDATE_FORM = serverUrl + 'humanresources/concepts/init-update-form/';
export const CONCEPTS_GET_ALL_RECORDS = serverUrl + 'humanresources/concepts/get-all-records';
export const CONCEPTS_INSERT = serverUrl + 'humanresources/concepts/insert';
export const CONCEPTS_UPDATE = serverUrl + 'humanresources/concepts/update/';
export const CONCEPTS_DELETE = serverUrl + 'humanresources/concepts/delete?';

export const PAYROLL_INIT_FORM = serverUrl + 'humanresources/payroll/init-form/';
export const PAYROLL_INIT_UPDATE_FORM = serverUrl + 'humanresources/payroll/init-update-form/';
export const PAYROLL_GET_ALL_RECORDS = serverUrl + 'humanresources/payroll/get-all-records';
export const PAYROLL_INSERT = serverUrl + 'humanresources/payroll/insert';
export const PAYROLL_UPDATE = serverUrl + 'humanresources/payroll/update/';
export const PAYROLL_DELETE = serverUrl + 'humanresources/payroll/delete?';

export const EMPLOYE_PAYROLL = serverUrl + 'humanresources/reports/employe-payroll/';
export const LABORER_PAYROLL = serverUrl + 'humanresources/reports/laborer-payroll/';
export const LABORER_PAYROLL_ACCUMULATED = serverUrl + 'humanresources/reports/laborer-payroll-accumulated/';
export const EMPLOYE_PAYROLL_ACCUMULATED = serverUrl + 'humanresources/reports/employe-payroll-accumulated/';
export const EMPLOYE_VOUCHER = serverUrl + 'humanresources/reports/voucher/';
export const EMPLOYE_VOUCHER_ALL = serverUrl + 'humanresources/reports/voucher-all/';
export const AFP_LIST = serverUrl + 'humanresources/reports/afp-list/';
export const PDT_STAFF = serverUrl + 'humanresources/reports/pdt-staff/';
export const CTS_DEPOSITS = serverUrl + 'humanresources/reports/cts-deposits/';