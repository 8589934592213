import React from 'react';
import {createUseStyles} from 'react-jss'

const useStyles = createUseStyles({
    container: {
        // 'visibility': 'hidden',
        'backgroundColor': '#E26464',
        'position': 'absolute',
        'right': '0',
        'margin': '2rem',
        'padding': '1.5rem',
        'max-width': '22rem',
        'width': '22rem',
        'color': 'white',
        'fontSize': '0.75rem',
        'visibility': (props: any) => props.visibility,
        'animation': '$fadein 1s',
        //   }
          
        //   @-webkit-keyframes fadeout {
        //     from {bottom: 30px; opacity: 1;} 
        //     to {bottom: 0; opacity: 0;}
        //   }
          
        //   @keyframes fadeout {
        //     from {bottom: 30px; opacity: 1;}
        //     to {bottom: 0; opacity: 0;}
        //   }
    },
          
    '@keyframes fadein': {
        from: {top: -20, opacity: 0},
        to: {top: 0, opacity: 1}
    },
    
    title: {
        'display': 'flex',
        'padding-bottom': '0.7rem',
        '& div': {
            'flex': 1,
            'fontWeight': 700
        },
        '& div:last-child': {
            'textAlign': 'end',
            'cursor': 'pointer'
        }
    }
})

interface MessageBox {
    title: string
    content: string
}

const MessageBox = (props: MessageBox) => {
    const classes = useStyles({visibility: true});
    return (
        <div className={classes.container}>
            <div className={classes.title}>
                <div>{props.title}</div>
                <div>X</div>
            </div>
            <div>{props.content}</div>
        </div>
    )
}

export default MessageBox;