import * as types  from 'redux/constants/dashboardConstants';

/*components */
export const dashboardSetWorkerContext = (company: string, division: string, bussofic: string, fiscyear: string, callback: any) => ({
    type: types.DASHBOARD_SET_WORKER_CONTEXT,
    company,
    division,
    bussofic,
    fiscyear,
    callback
});

export const dashboardSetWorkerContextOnly = (company: string, division: string, bussofic: string, fiscyear: string) => ({
    type: types.DASHBOARD_SET_WORKER_CONTEXT_ONLY,
    company,
    division,
    bussofic,
    fiscyear
});


export const dashboardSetWorkerContextSuccess = () => ({
    type: types.DASHBOARD_SET_WORKER_CONTEXT_SUCCESS
});

export const dashboardSelectMenu = (moducode: string) => ({
    type: types.DASHBOARD_SELECT_MENU,
    moducode
});

export const dashboardReset = () => ({
    type: types.DASHBOARD_RESET
});