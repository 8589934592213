import { baseModels } from 'penta-models';
import { 
    AFP_LIST,
    COMMISSIS_DELETE, 
    COMMISSIS_GET_ALL_RECORDS, 
    COMMISSIS_INIT_UPDATE_FORM, 
    COMMISSIS_INSERT, 
    COMMISSIS_UPDATE, 
    CONCEPTS_DELETE, 
    CONCEPTS_GET_ALL_RECORDS, 
    CONCEPTS_INIT_FORM, 
    CONCEPTS_INIT_UPDATE_FORM, 
    CONCEPTS_INSERT, 
    CONCEPTS_UPDATE, 
    CTS_DEPOSITS, 
    EMPLOYES_DELETE, 
    EMPLOYES_GET_ALL_RECORDS, 
    EMPLOYES_INIT_FORM, 
    EMPLOYES_INIT_UPDATE_FORM, 
    EMPLOYES_INSERT, 
    EMPLOYES_UPDATE, 
    EMPLOYE_PAYROLL, 
    EMPLOYE_PAYROLL_ACCUMULATED, 
    EMPLOYE_VOUCHER, 
    EMPLOYE_VOUCHER_ALL, 
    LABORER_PAYROLL, 
    LABORER_PAYROLL_ACCUMULATED, 
    PAYROLL_DELETE, 
    PAYROLL_GET_ALL_RECORDS, 
    PAYROLL_INIT_FORM, 
    PAYROLL_INIT_UPDATE_FORM, 
    PAYROLL_INSERT, 
    PAYROLL_UPDATE, 
    PDT_STAFF, 
    WEEKCALES_DELETE, 
    WEEKCALES_GET_ALL_RECORDS, 
    WEEKCALES_INIT_UPDATE_FORM, 
    WEEKCALES_INSERT, 
    WEEKCALES_UPDATE 
} from 'services/constants/humanResourcesConstants';
import request from './basePipeline';

//#region COMMISSIS

export const commissisInitUpdateForm = (commyear: string, commmont: string) => {
    return request.getWithToken(COMMISSIS_INIT_UPDATE_FORM + commyear + "/" + commmont)
}

export const commissisGetAllRecords = (body: baseModels.pentaRequestQuery) => {
    return request.postWithToken(COMMISSIS_GET_ALL_RECORDS, body);
}

export const commissisInsert = (body: any) => {
    return request.postWithToken(COMMISSIS_INSERT, body)
}

export const commissisUpdate = (commyear: string, commmont: string, body: any) => {
    return request.putWithToken(COMMISSIS_UPDATE + commyear + "/" + commmont, body)
}

export const commissisDelete = (commyears: Array<string>, commmonts: Array<string>) => {
    const urlSearchParams = new URLSearchParams();
    for(const commyear of commyears) {
        urlSearchParams.append("commyear", commyear);
    }
    for(const commmont of commmonts) {
        urlSearchParams.append("commmont", commmont);
    }
    return request.deleteWithToken(COMMISSIS_DELETE + urlSearchParams.toString());
}

//#endregion

//#region WEEKCALES

export const weekcalesInitUpdateForm = (yearnumb: string, weeknumb: string) => {
    return request.getWithToken(WEEKCALES_INIT_UPDATE_FORM + yearnumb + "/" + weeknumb)
}

export const weekcalesGetAllRecords = (body: baseModels.pentaRequestQuery) => {
    return request.postWithToken(WEEKCALES_GET_ALL_RECORDS, body);
}

export const weekcalesInsert = (body: any) => {
    return request.postWithToken(WEEKCALES_INSERT, body)
}

export const weekcalesUpdate = (yearnumb: string, weeknumb: string, body: any) => {
    return request.putWithToken(WEEKCALES_UPDATE + yearnumb + "/" + weeknumb, body)
}

export const weekcalesDelete = (yearnumbs: Array<string>, weeknumbs: Array<string>) => {
    const urlSearchParams = new URLSearchParams();
    for(const yearnumb of yearnumbs) {
        urlSearchParams.append("yearnumb", yearnumb);
    }
    for(const weeknumb of weeknumbs) {
        urlSearchParams.append("weeknumb", weeknumb);
    }
    return request.deleteWithToken(WEEKCALES_DELETE + urlSearchParams.toString());
}

//#endregion

//#region EMPLOYES

export const employesInitForm = () => {
    return request.getWithToken(EMPLOYES_INIT_FORM);
}

export const employesInitUpdateForm = (emplcode: string) => {
    return request.getWithToken(EMPLOYES_INIT_UPDATE_FORM + emplcode)
}

export const employesGetAllRecords = (body: baseModels.pentaRequestQuery) => {
    return request.postWithToken(EMPLOYES_GET_ALL_RECORDS, body);
}

export const employesInsert = (body: any) => {
    return request.postWithToken(EMPLOYES_INSERT, body)
}

export const employesUpdate = (emplcode: string, body: any) => {
    return request.putWithToken(EMPLOYES_UPDATE + emplcode, body)
}

export const employesDelete = (emplcodes: Array<string>) => {
    const urlSearchParams = new URLSearchParams();
    for(const emplcode of emplcodes) {
        urlSearchParams.append("emplcode", emplcode);
    }
    return request.deleteWithToken(EMPLOYES_DELETE + urlSearchParams.toString());
}

//#endregion 

//#region CONCEPTS

export const conceptsInitForm = (fiscyear: number) => {
    return request.getWithToken(CONCEPTS_INIT_FORM + fiscyear);
}

export const conceptsInitUpdateForm = (conccode: string, typesala: string, payrtype: string) => {
    return request.getWithToken(CONCEPTS_INIT_UPDATE_FORM + conccode + '/' + typesala + '/' + payrtype)
}

export const conceptsGetAllRecords = (body: baseModels.pentaRequestQuery) => {
    return request.postWithToken(CONCEPTS_GET_ALL_RECORDS, body);
}

export const conceptsInsert = (body: any) => {
    return request.postWithToken(CONCEPTS_INSERT, body)
}

export const conceptsUpdate = (conccode: string, body: any) => {
    return request.putWithToken(CONCEPTS_UPDATE + conccode, body)
}

export const conceptsDelete = (conccodes: Array<string>, typesalas: Array<string>, payrtypes: Array<string>) => {
    const urlSearchParams = new URLSearchParams();
    for(const conccode of conccodes) {
        urlSearchParams.append("conccode", conccode);
    }
    for(const typesala of typesalas) {
        urlSearchParams.append("typesala", typesala);
    }
    for(const payrtype of payrtypes) {
        urlSearchParams.append("payrtype", payrtype);
    }
    return request.deleteWithToken(CONCEPTS_DELETE + urlSearchParams.toString());
}

//#endregion 

//#region PAYROLL

export const payrollInitForm = (payryear: string) => {
    return request.getWithToken(PAYROLL_INIT_FORM + payryear);
}

export const payrollInitUpdateForm = (emplcode: string, payryear: string, payrweek: string, typesala: string) => {
    return request.getWithToken(PAYROLL_INIT_UPDATE_FORM + emplcode + "/" + payryear + "/" + payrweek + "/" + typesala)
}

export const payrollGetAllRecords = (body: baseModels.pentaRequestQuery) => {
    return request.postWithToken(PAYROLL_GET_ALL_RECORDS, body);
}

export const payrollInsert = (body: any) => {
    return request.postWithToken(PAYROLL_INSERT, body)
}

export const payrollUpdate = (emplcode: string, payryear: string, payrweek: string, body: any) => {
    return request.putWithToken(PAYROLL_UPDATE + emplcode + "/" + payryear + "/" + payrweek, body)
}

export const payrollDelete = (emplcodes: Array<string>, payryears: Array<string>, payrweeks: Array<string>, typesalas: Array<string>) => {
    const urlSearchParams = new URLSearchParams();
    for(const emplcode of emplcodes) {
        urlSearchParams.append("emplcode", emplcode);
    }
    for(const payryear of payryears) {
        urlSearchParams.append("payryear", payryear);
    }
    for(const payrmont of payrweeks) {
        urlSearchParams.append("payrweek", payrmont);
    }
    for(const typesala of typesalas) {
        urlSearchParams.append("typesala", typesala);
    }
    return request.deleteWithToken(PAYROLL_DELETE + urlSearchParams.toString());
}

//#endregion

//#region REPORTS

export const employePayroll = (year: string, month: string, typesala: string) => {
    return request.getWithToken(EMPLOYE_PAYROLL + year + '/' + month + '/' + typesala);
}

export const laborerPayroll = (year: string, month: string, typesala: string) => {
    return request.getWithToken(LABORER_PAYROLL + year + '/' + month + '/' + typesala);
}

export const laborerPayrollAccumulated = (year: string, month: string, endMonth: string, all: boolean) => {
    return request.getWithToken(LABORER_PAYROLL_ACCUMULATED + year + '/' + month + '/' + endMonth + '/' + all);
}

export const employePayrollAccumulated = (year: string, month: string, endMonth: string, all: boolean) => {
    return request.getWithToken(EMPLOYE_PAYROLL_ACCUMULATED + year + '/' + month + '/' + endMonth + '/' + all);
}

export const employeVoucher = (emplcode: string, year: string, month: string, week: string, typesala: string, payrtype: string) => {
    return request.getWithToken(EMPLOYE_VOUCHER + emplcode + '/' + year + '/' + month + '/' + week + '/' + typesala + '/' + payrtype);
}

export const employeVoucherAll = (year: string, month: string, week: string, typesala: string, payrtype: string) => {
    return request.getWithToken(EMPLOYE_VOUCHER_ALL + year + '/' + month + '/' + week + '/' + typesala + '/' + payrtype);
}

export const afpList = (officode: string, payryear: string, payrmont: string, payrtype: string) => {
    return request.getWithToken(AFP_LIST + officode + '/' + payryear + '/' + payrmont + '/001/' + payrtype);
}

export const pdtStaff = (year: string, month: string) => {
    return request.getWithToken(PDT_STAFF + year + '/' + month);
}

export const ctsDeposits = (officode: string) => {
    return request.getWithToken(CTS_DEPOSITS + officode);
}

//#endregion