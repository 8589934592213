import { 
    CATEGORYS_GET_ALL_RECORDS_REQUEST, 
    CATEGORYS_GET_ALL_RECORDS_REQUEST_FAILED, 
    CATEGORYS_GET_ALL_RECORDS_REQUEST_SUCCESS,
    CATEGORYS_INSERT_REQUEST,
    CATEGORYS_INSERT_REQUEST_FAILED,
    CATEGORYS_INSERT_REQUEST_SUCCESS,
    CATEGORYS_UPDATE_REQUEST,
    CATEGORYS_UPDATE_REQUEST_FAILED,
    CATEGORYS_UPDATE_REQUEST_SUCCESS,
    CATEGORYS_DELETE_REQUEST_FAILED,
    CATEGORYS_DELETE_REQUEST_SUCCESS,
    CATEGORYS_DELETE_REQUEST,
    CATEGORYS_LIST_SELECT_ITEM,
    CATEGORYS_LIST_SELECT_ALL_ITEM,
    CATEGORYS_ACTION_FAILED,
    CATEGORYS_INIT_FORM_REQUEST,
    CATEGORYS_INIT_FORM_REQUEST_SUCCESS,
    CATEGORYS_INIT_FORM_REQUEST_FAILED,
    CATEGORYS_INIT_UPDATE_FORM_REQUEST,
    CATEGORYS_INIT_UPDATE_FORM_REQUEST_SUCCESS,
    CATEGORYS_INIT_UPDATE_FORM_REQUEST_FAILED,
    FAMILIES_INIT_FORM_REQUEST,
    FAMILIES_INIT_FORM_REQUEST_SUCCESS,
    FAMILIES_INIT_FORM_REQUEST_FAILED,
    FAMILIES_INIT_UPDATE_FORM_REQUEST,
    FAMILIES_INIT_UPDATE_FORM_REQUEST_SUCCESS,
    FAMILIES_INIT_UPDATE_FORM_REQUEST_FAILED,
    FAMILIES_GET_ALL_RECORDS_REQUEST,
    FAMILIES_GET_ALL_RECORDS_REQUEST_SUCCESS,
    FAMILIES_GET_ALL_RECORDS_REQUEST_FAILED,
    FAMILIES_INSERT_REQUEST,
    FAMILIES_INSERT_REQUEST_SUCCESS,
    FAMILIES_INSERT_REQUEST_FAILED,
    FAMILIES_UPDATE_REQUEST,
    FAMILIES_UPDATE_REQUEST_SUCCESS,
    FAMILIES_UPDATE_REQUEST_FAILED,
    FAMILIES_DELETE_REQUEST,
    FAMILIES_DELETE_REQUEST_SUCCESS,
    FAMILIES_DELETE_REQUEST_FAILED,
    FAMILIES_LIST_SELECT_ITEM,
    FAMILIES_LIST_SELECT_ALL_ITEM,
    FAMILIES_ACTION_FAILED,
    UNITS_INIT_UPDATE_FORM_REQUEST,
    UNITS_INIT_UPDATE_FORM_REQUEST_SUCCESS,
    UNITS_INIT_UPDATE_FORM_REQUEST_FAILED,
    UNITS_GET_ALL_RECORDS_REQUEST,
    UNITS_GET_ALL_RECORDS_REQUEST_SUCCESS,
    UNITS_GET_ALL_RECORDS_REQUEST_FAILED,
    UNITS_INSERT_REQUEST,
    UNITS_INSERT_REQUEST_SUCCESS,
    UNITS_INSERT_REQUEST_FAILED,
    UNITS_UPDATE_REQUEST,
    UNITS_UPDATE_REQUEST_SUCCESS,
    UNITS_UPDATE_REQUEST_FAILED,
    UNITS_DELETE_REQUEST,
    UNITS_DELETE_REQUEST_SUCCESS,
    UNITS_DELETE_REQUEST_FAILED,
    UNITS_LIST_SELECT_ITEM,
    UNITS_LIST_SELECT_ALL_ITEM,
    UNITS_ACTION_FAILED,
    WAREHOUSES_INIT_UPDATE_FORM_REQUEST_SUCCESS,
    WAREHOUSES_INIT_UPDATE_FORM_REQUEST,
    WAREHOUSES_INIT_UPDATE_FORM_REQUEST_FAILED,
    WAREHOUSES_GET_ALL_RECORDS_REQUEST,
    WAREHOUSES_GET_ALL_RECORDS_REQUEST_SUCCESS,
    WAREHOUSES_GET_ALL_RECORDS_REQUEST_FAILED,
    WAREHOUSES_INSERT_REQUEST,
    WAREHOUSES_INSERT_REQUEST_SUCCESS,
    WAREHOUSES_INSERT_REQUEST_FAILED,
    WAREHOUSES_UPDATE_REQUEST,
    WAREHOUSES_UPDATE_REQUEST_SUCCESS,
    WAREHOUSES_UPDATE_REQUEST_FAILED,
    WAREHOUSES_DELETE_REQUEST,
    WAREHOUSES_DELETE_REQUEST_SUCCESS,
    WAREHOUSES_DELETE_REQUEST_FAILED,
    WAREHOUSES_LIST_SELECT_ITEM,
    WAREHOUSES_LIST_SELECT_ALL_ITEM,
    WAREHOUSES_ACTION_FAILED,
    PRODUCTS_INIT_FORM_REQUEST,
    PRODUCTS_INIT_FORM_REQUEST_SUCCESS,
    PRODUCTS_INIT_FORM_REQUEST_FAILED,
    PRODUCTS_INIT_UPDATE_FORM_REQUEST,
    PRODUCTS_INIT_UPDATE_FORM_REQUEST_SUCCESS,
    PRODUCTS_INIT_UPDATE_FORM_REQUEST_FAILED,
    PRODUCTS_GET_ALL_RECORDS_REQUEST,
    PRODUCTS_GET_ALL_RECORDS_REQUEST_SUCCESS,
    PRODUCTS_GET_ALL_RECORDS_REQUEST_FAILED,
    PRODUCTS_INSERT_REQUEST,
    PRODUCTS_INSERT_REQUEST_SUCCESS,
    PRODUCTS_INSERT_REQUEST_FAILED,
    PRODUCTS_UPDATE_REQUEST,
    PRODUCTS_UPDATE_REQUEST_SUCCESS,
    PRODUCTS_UPDATE_REQUEST_FAILED,
    PRODUCTS_DELETE_REQUEST,
    PRODUCTS_DELETE_REQUEST_SUCCESS,
    PRODUCTS_DELETE_REQUEST_FAILED,
    PRODUCTS_LIST_SELECT_ITEM,
    PRODUCTS_LIST_SELECT_ALL_ITEM,
    PRODUCTS_ACTION_FAILED
} from '../constants/wharehouseProductsConstants';

const initialState: any = {
    tables: { planAccount: [], categorys: [], definition: [], families: [], units: [] },
    onlyOne: null,
    list: [],
    total: 0,
    loading: false,
    failed: false,
    failedTitle: null,
    failedContent: null
};

export default function wharehouseProductsReducer(state = initialState, action: any = {}) {
    switch(action.type) {
        case CATEGORYS_INIT_FORM_REQUEST:
        case FAMILIES_INIT_FORM_REQUEST:
        case PRODUCTS_INIT_FORM_REQUEST:
            return {
                ...state,
                loading: true
            };
        case CATEGORYS_INIT_FORM_REQUEST_SUCCESS:
        case FAMILIES_INIT_FORM_REQUEST_SUCCESS:
        case PRODUCTS_INIT_FORM_REQUEST_SUCCESS:
            return {
                ...state,
                tables: { ...state.tables, ...action.tables },
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case CATEGORYS_INIT_FORM_REQUEST_FAILED:
        case FAMILIES_INIT_FORM_REQUEST_FAILED:
        case PRODUCTS_INIT_FORM_REQUEST_FAILED:
            return {
                ...initialState,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case CATEGORYS_INIT_UPDATE_FORM_REQUEST:
        case FAMILIES_INIT_UPDATE_FORM_REQUEST:
        case UNITS_INIT_UPDATE_FORM_REQUEST:
        case WAREHOUSES_INIT_UPDATE_FORM_REQUEST:
        case PRODUCTS_INIT_UPDATE_FORM_REQUEST:
            return {
                ...state,
                loading: true
            };
        case CATEGORYS_INIT_UPDATE_FORM_REQUEST_SUCCESS:
        case FAMILIES_INIT_UPDATE_FORM_REQUEST_SUCCESS:
        case UNITS_INIT_UPDATE_FORM_REQUEST_SUCCESS:
        case WAREHOUSES_INIT_UPDATE_FORM_REQUEST_SUCCESS:
        case PRODUCTS_INIT_UPDATE_FORM_REQUEST_SUCCESS:
            return {
                ...state,
                tables: { ...state.tables, ...action.tables },
                onlyOne: action.onlyOne,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case CATEGORYS_INIT_UPDATE_FORM_REQUEST_FAILED:
        case FAMILIES_INIT_UPDATE_FORM_REQUEST_FAILED:
        case UNITS_INIT_UPDATE_FORM_REQUEST_FAILED:
        case WAREHOUSES_INIT_UPDATE_FORM_REQUEST_FAILED:
        case PRODUCTS_INIT_UPDATE_FORM_REQUEST_FAILED:
            return {
                ...initialState,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case CATEGORYS_GET_ALL_RECORDS_REQUEST:
        case FAMILIES_GET_ALL_RECORDS_REQUEST:
        case UNITS_GET_ALL_RECORDS_REQUEST:
        case WAREHOUSES_GET_ALL_RECORDS_REQUEST:
        case PRODUCTS_GET_ALL_RECORDS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case CATEGORYS_GET_ALL_RECORDS_REQUEST_SUCCESS:
        case FAMILIES_GET_ALL_RECORDS_REQUEST_SUCCESS:
        case UNITS_GET_ALL_RECORDS_REQUEST_SUCCESS:
        case WAREHOUSES_GET_ALL_RECORDS_REQUEST_SUCCESS:
        case PRODUCTS_GET_ALL_RECORDS_REQUEST_SUCCESS:
            return {
                ...state,
                list: action.list,
                total: action.total,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case CATEGORYS_GET_ALL_RECORDS_REQUEST_FAILED:
        case FAMILIES_GET_ALL_RECORDS_REQUEST_FAILED:
        case UNITS_GET_ALL_RECORDS_REQUEST_FAILED:
        case WAREHOUSES_GET_ALL_RECORDS_REQUEST_FAILED:
        case PRODUCTS_GET_ALL_RECORDS_REQUEST_FAILED:
            return {
                ...initialState,
                list: [],
                total: 0,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case CATEGORYS_INSERT_REQUEST:
        case FAMILIES_INSERT_REQUEST:
        case UNITS_INSERT_REQUEST:
        case WAREHOUSES_INSERT_REQUEST:
        case PRODUCTS_INSERT_REQUEST:
            return {
                ...state,
                loading: true
            };
        case CATEGORYS_INSERT_REQUEST_SUCCESS:
        case FAMILIES_INSERT_REQUEST_SUCCESS:
        case UNITS_INSERT_REQUEST_SUCCESS:
        case WAREHOUSES_INSERT_REQUEST_SUCCESS:
        case PRODUCTS_INSERT_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case CATEGORYS_INSERT_REQUEST_FAILED:
        case FAMILIES_INSERT_REQUEST_FAILED:
        case UNITS_INSERT_REQUEST_FAILED:
        case WAREHOUSES_INSERT_REQUEST_FAILED:
        case PRODUCTS_INSERT_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case CATEGORYS_UPDATE_REQUEST:
        case FAMILIES_UPDATE_REQUEST:
        case UNITS_UPDATE_REQUEST:
        case WAREHOUSES_UPDATE_REQUEST:
        case PRODUCTS_UPDATE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case CATEGORYS_UPDATE_REQUEST_SUCCESS:
        case FAMILIES_UPDATE_REQUEST_SUCCESS:
        case UNITS_UPDATE_REQUEST_SUCCESS:
        case WAREHOUSES_UPDATE_REQUEST_SUCCESS:
        case PRODUCTS_UPDATE_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case CATEGORYS_UPDATE_REQUEST_FAILED:
        case FAMILIES_UPDATE_REQUEST_FAILED:
        case UNITS_UPDATE_REQUEST_FAILED:
        case WAREHOUSES_UPDATE_REQUEST_FAILED:
        case PRODUCTS_UPDATE_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case CATEGORYS_DELETE_REQUEST:
        case FAMILIES_DELETE_REQUEST:
        case UNITS_DELETE_REQUEST:
        case WAREHOUSES_DELETE_REQUEST:
        case PRODUCTS_DELETE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case CATEGORYS_DELETE_REQUEST_SUCCESS:
        case FAMILIES_DELETE_REQUEST_SUCCESS:
        case UNITS_DELETE_REQUEST_SUCCESS:
        case WAREHOUSES_DELETE_REQUEST_SUCCESS:
        case PRODUCTS_DELETE_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case CATEGORYS_DELETE_REQUEST_FAILED:
        case FAMILIES_DELETE_REQUEST_FAILED:
        case UNITS_DELETE_REQUEST_FAILED:
        case WAREHOUSES_DELETE_REQUEST_FAILED:
        case PRODUCTS_DELETE_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case CATEGORYS_LIST_SELECT_ITEM:
        case FAMILIES_LIST_SELECT_ITEM:
        case UNITS_LIST_SELECT_ITEM:
        case WAREHOUSES_LIST_SELECT_ITEM:
        case PRODUCTS_LIST_SELECT_ITEM:
            const newList = state.list;
            newList[action.key].select = !newList[action.key].select;
            return {
                ...state,
                list: newList
            };
        case CATEGORYS_LIST_SELECT_ALL_ITEM:
        case FAMILIES_LIST_SELECT_ALL_ITEM:
        case UNITS_LIST_SELECT_ALL_ITEM:
        case WAREHOUSES_LIST_SELECT_ALL_ITEM:
        case PRODUCTS_LIST_SELECT_ALL_ITEM:
            const newListAll = state.list;
            for (const item of newListAll) {
                item.select = action.checked
            }
            return {
                ...state,
                list: newListAll
            };
        case CATEGORYS_ACTION_FAILED:
        case FAMILIES_ACTION_FAILED:
        case UNITS_ACTION_FAILED:
        case WAREHOUSES_ACTION_FAILED:
        case PRODUCTS_ACTION_FAILED:
            return {
                ...state,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };    
        default:
            return state;
    }
}