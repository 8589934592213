import { 
    CREDIT_NOTE_ACTION_FAILED,
    CREDIT_NOTE_DELETE_REQUEST,
    CREDIT_NOTE_DELETE_REQUEST_FAILED,
    CREDIT_NOTE_DELETE_REQUEST_SUCCESS,
    CREDIT_NOTE_GET_ALL_RECORDS_REQUEST,
    CREDIT_NOTE_GET_ALL_RECORDS_REQUEST_FAILED,
    CREDIT_NOTE_GET_ALL_RECORDS_REQUEST_SUCCESS,
    CREDIT_NOTE_INIT_FORM_REQUEST,
    CREDIT_NOTE_INIT_FORM_REQUEST_FAILED,
    CREDIT_NOTE_INIT_FORM_REQUEST_SUCCESS,
    CREDIT_NOTE_INIT_UPDATE_FORM_REQUEST,
    CREDIT_NOTE_INIT_UPDATE_FORM_REQUEST_FAILED,
    CREDIT_NOTE_INIT_UPDATE_FORM_REQUEST_SUCCESS,
    CREDIT_NOTE_INSERT_REQUEST,
    CREDIT_NOTE_INSERT_REQUEST_FAILED,
    CREDIT_NOTE_INSERT_REQUEST_SUCCESS,
    CREDIT_NOTE_LIST_SELECT_ALL_ITEM,
    CREDIT_NOTE_LIST_SELECT_ITEM,
    CREDIT_NOTE_UPDATE_REQUEST,
    CREDIT_NOTE_UPDATE_REQUEST_FAILED,
    CREDIT_NOTE_UPDATE_REQUEST_SUCCESS,
    DEBIT_NOTE_ACTION_FAILED,
    DEBIT_NOTE_DELETE_REQUEST,
    DEBIT_NOTE_DELETE_REQUEST_FAILED,
    DEBIT_NOTE_DELETE_REQUEST_SUCCESS,
    DEBIT_NOTE_GET_ALL_RECORDS_REQUEST,
    DEBIT_NOTE_GET_ALL_RECORDS_REQUEST_FAILED,
    DEBIT_NOTE_GET_ALL_RECORDS_REQUEST_SUCCESS,
    DEBIT_NOTE_INIT_FORM_REQUEST,
    DEBIT_NOTE_INIT_FORM_REQUEST_FAILED,
    DEBIT_NOTE_INIT_FORM_REQUEST_SUCCESS,
    DEBIT_NOTE_INIT_UPDATE_FORM_REQUEST,
    DEBIT_NOTE_INIT_UPDATE_FORM_REQUEST_FAILED,
    DEBIT_NOTE_INIT_UPDATE_FORM_REQUEST_SUCCESS,
    DEBIT_NOTE_INSERT_REQUEST,
    DEBIT_NOTE_INSERT_REQUEST_FAILED,
    DEBIT_NOTE_INSERT_REQUEST_SUCCESS,
    DEBIT_NOTE_LIST_SELECT_ALL_ITEM,
    DEBIT_NOTE_LIST_SELECT_ITEM,
    DEBIT_NOTE_UPDATE_REQUEST,
    DEBIT_NOTE_UPDATE_REQUEST_FAILED,
    DEBIT_NOTE_UPDATE_REQUEST_SUCCESS,
    INVOICES_PAY_REQUEST,
    INVOICES_PAY_REQUEST_FAILED,
    INVOICES_PAY_REQUEST_SUCCESS,
    PURCHASES_ACTION_FAILED,
    PURCHASES_ANULATE_REQUEST,
    PURCHASES_ANULATE_REQUEST_FAILED,
    PURCHASES_ANULATE_REQUEST_SUCCESS,
    PURCHASES_DELETE_REQUEST,
    PURCHASES_DELETE_REQUEST_FAILED,
    PURCHASES_DELETE_REQUEST_SUCCESS,
    PURCHASES_GET_ALL_RECORDS_REQUEST,
    PURCHASES_GET_ALL_RECORDS_REQUEST_FAILED,
    PURCHASES_GET_ALL_RECORDS_REQUEST_SUCCESS,
    PURCHASES_INIT_FORM_REQUEST,
    PURCHASES_INIT_FORM_REQUEST_FAILED,
    PURCHASES_INIT_FORM_REQUEST_SUCCESS,
    PURCHASES_INIT_UPDATE_FORM_REQUEST,
    PURCHASES_INIT_UPDATE_FORM_REQUEST_FAILED,
    PURCHASES_INIT_UPDATE_FORM_REQUEST_SUCCESS,
    PURCHASES_INSERT_REQUEST,
    PURCHASES_INSERT_REQUEST_FAILED,
    PURCHASES_INSERT_REQUEST_SUCCESS,
    PURCHASES_LIST_SELECT_ALL_ITEM,
    PURCHASES_LIST_SELECT_ITEM,
    PURCHASES_MASSIVE_UPDATE_REQUEST,
    PURCHASES_MASSIVE_UPDATE_REQUEST_FAILED,
    PURCHASES_MASSIVE_UPDATE_REQUEST_SUCCESS,
    PURCHASES_UPDATE_REQUEST,
    PURCHASES_UPDATE_REQUEST_FAILED,
    PURCHASES_UPDATE_REQUEST_SUCCESS,
    SUPPLIERS_ACTION_FAILED,
    SUPPLIERS_DELETE_REQUEST,
    SUPPLIERS_DELETE_REQUEST_FAILED,
    SUPPLIERS_DELETE_REQUEST_SUCCESS,
    SUPPLIERS_GET_ALL_RECORDS_REQUEST,
    SUPPLIERS_GET_ALL_RECORDS_REQUEST_FAILED,
    SUPPLIERS_GET_ALL_RECORDS_REQUEST_SUCCESS,
    SUPPLIERS_INIT_FORM_REQUEST, 
    SUPPLIERS_INIT_FORM_REQUEST_FAILED, 
    SUPPLIERS_INIT_FORM_REQUEST_SUCCESS,
    SUPPLIERS_INIT_UPDATE_FORM_REQUEST,
    SUPPLIERS_INIT_UPDATE_FORM_REQUEST_FAILED,
    SUPPLIERS_INIT_UPDATE_FORM_REQUEST_SUCCESS,
    SUPPLIERS_INSERT_REQUEST,
    SUPPLIERS_INSERT_REQUEST_FAILED,
    SUPPLIERS_INSERT_REQUEST_SUCCESS,
    SUPPLIERS_LIST_SELECT_ALL_ITEM,
    SUPPLIERS_LIST_SELECT_ITEM,
    SUPPLIERS_UPDATE_REQUEST,
    SUPPLIERS_UPDATE_REQUEST_FAILED,
    SUPPLIERS_UPDATE_REQUEST_SUCCESS
} from "penta-redux/constants/shoppingsConstants";

const initialState: any = {
    tables: { planAccount: [], categorys: [], definition: [], families: [], units: [], departamentos: [], provincias: [], distritos: [], 
        supplier: [], products: [], wharhous: [], masteaccs: [], pbl_purchase: [], pbl_supplier: [], grl_definiti: [], acc_planacco: [], whp_unitmeas: [] },
    onlyOne: null,
    list: [],
    total: 0,
    loading: false,
    failed: false,
    failedTitle: null,
    failedContent: null
};

export default function shoppingsReducer(state = initialState, action: any = {}) {
    switch(action.type) {
        case SUPPLIERS_INIT_FORM_REQUEST:
        case PURCHASES_INIT_FORM_REQUEST:
        case CREDIT_NOTE_INIT_FORM_REQUEST:
        case DEBIT_NOTE_INIT_FORM_REQUEST:
            return {
                ...state,
                loading: true
            };
        case SUPPLIERS_INIT_FORM_REQUEST_SUCCESS:
        case PURCHASES_INIT_FORM_REQUEST_SUCCESS:
        case CREDIT_NOTE_INIT_FORM_REQUEST_SUCCESS:
        case DEBIT_NOTE_INIT_FORM_REQUEST_SUCCESS:
            return {
                ...state,
                tables: { ...state.tables, ...action.tables },
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case SUPPLIERS_INIT_FORM_REQUEST_FAILED:
        case PURCHASES_INIT_FORM_REQUEST_FAILED:
        case CREDIT_NOTE_INIT_FORM_REQUEST_FAILED:
        case DEBIT_NOTE_INIT_FORM_REQUEST_FAILED:
            return {
                ...initialState,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case SUPPLIERS_INIT_UPDATE_FORM_REQUEST:
        case PURCHASES_INIT_UPDATE_FORM_REQUEST:
        case CREDIT_NOTE_INIT_UPDATE_FORM_REQUEST:
        case DEBIT_NOTE_INIT_UPDATE_FORM_REQUEST:
            return {
                ...state,
                loading: true
            };
        case SUPPLIERS_INIT_UPDATE_FORM_REQUEST_SUCCESS:
        case PURCHASES_INIT_UPDATE_FORM_REQUEST_SUCCESS:
        case CREDIT_NOTE_INIT_UPDATE_FORM_REQUEST_SUCCESS:
        case DEBIT_NOTE_INIT_UPDATE_FORM_REQUEST_SUCCESS:
            return {
                ...state,
                tables: { ...state.tables, ...action.tables },
                onlyOne: action.onlyOne,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case SUPPLIERS_INIT_UPDATE_FORM_REQUEST_FAILED:
        case PURCHASES_INIT_UPDATE_FORM_REQUEST_FAILED:
        case CREDIT_NOTE_INIT_UPDATE_FORM_REQUEST_FAILED:
        case DEBIT_NOTE_INIT_UPDATE_FORM_REQUEST_FAILED:
            return {
                ...initialState,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case SUPPLIERS_GET_ALL_RECORDS_REQUEST:
        case PURCHASES_GET_ALL_RECORDS_REQUEST:
        case CREDIT_NOTE_GET_ALL_RECORDS_REQUEST:
        case DEBIT_NOTE_GET_ALL_RECORDS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case SUPPLIERS_GET_ALL_RECORDS_REQUEST_SUCCESS:
        case PURCHASES_GET_ALL_RECORDS_REQUEST_SUCCESS:
        case CREDIT_NOTE_GET_ALL_RECORDS_REQUEST_SUCCESS:
        case DEBIT_NOTE_GET_ALL_RECORDS_REQUEST_SUCCESS:
            return {
                ...state,
                list: action.list,
                total: action.total,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case SUPPLIERS_GET_ALL_RECORDS_REQUEST_FAILED:
        case PURCHASES_GET_ALL_RECORDS_REQUEST_FAILED:
        case CREDIT_NOTE_GET_ALL_RECORDS_REQUEST_FAILED:
        case DEBIT_NOTE_GET_ALL_RECORDS_REQUEST_FAILED:
            return {
                ...initialState,
                list: [],
                total: 0,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case SUPPLIERS_INSERT_REQUEST:
        case PURCHASES_INSERT_REQUEST:
        case CREDIT_NOTE_INSERT_REQUEST:
        case DEBIT_NOTE_INSERT_REQUEST:
            return {
                ...state,
                loading: true
            };
        case SUPPLIERS_INSERT_REQUEST_SUCCESS:
        case PURCHASES_INSERT_REQUEST_SUCCESS:
        case CREDIT_NOTE_INSERT_REQUEST_SUCCESS:
        case DEBIT_NOTE_INSERT_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case SUPPLIERS_INSERT_REQUEST_FAILED:
        case PURCHASES_INSERT_REQUEST_FAILED:
        case CREDIT_NOTE_INSERT_REQUEST_FAILED:
        case DEBIT_NOTE_INSERT_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case SUPPLIERS_UPDATE_REQUEST:
        case PURCHASES_UPDATE_REQUEST:
        case CREDIT_NOTE_UPDATE_REQUEST:
        case DEBIT_NOTE_UPDATE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case SUPPLIERS_UPDATE_REQUEST_SUCCESS:
        case PURCHASES_UPDATE_REQUEST_SUCCESS:
        case CREDIT_NOTE_UPDATE_REQUEST_SUCCESS:
        case DEBIT_NOTE_UPDATE_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case SUPPLIERS_UPDATE_REQUEST_FAILED:
        case PURCHASES_UPDATE_REQUEST_FAILED:
        case CREDIT_NOTE_UPDATE_REQUEST_FAILED:
        case DEBIT_NOTE_UPDATE_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case SUPPLIERS_DELETE_REQUEST:
        case PURCHASES_DELETE_REQUEST:
        case CREDIT_NOTE_DELETE_REQUEST:
        case DEBIT_NOTE_DELETE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case SUPPLIERS_DELETE_REQUEST_SUCCESS:
        case PURCHASES_DELETE_REQUEST_SUCCESS:
        case CREDIT_NOTE_DELETE_REQUEST_SUCCESS:
        case DEBIT_NOTE_DELETE_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case SUPPLIERS_DELETE_REQUEST_FAILED:
        case PURCHASES_DELETE_REQUEST_FAILED:
        case CREDIT_NOTE_DELETE_REQUEST_FAILED:
        case DEBIT_NOTE_DELETE_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case PURCHASES_ANULATE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case PURCHASES_ANULATE_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case PURCHASES_ANULATE_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case PURCHASES_MASSIVE_UPDATE_REQUEST:
                return {
                    ...state,
                    loading: true
                };
        case PURCHASES_MASSIVE_UPDATE_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case PURCHASES_MASSIVE_UPDATE_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case SUPPLIERS_LIST_SELECT_ITEM:
        case PURCHASES_LIST_SELECT_ITEM:
        case CREDIT_NOTE_LIST_SELECT_ITEM:
        case DEBIT_NOTE_LIST_SELECT_ITEM:
            const newList = state.list;
            newList[action.key].select = !newList[action.key].select;
            return {
                ...state,
                list: newList
            };
        case SUPPLIERS_LIST_SELECT_ALL_ITEM:
        case PURCHASES_LIST_SELECT_ALL_ITEM:
        case CREDIT_NOTE_LIST_SELECT_ALL_ITEM:
        case DEBIT_NOTE_LIST_SELECT_ALL_ITEM:
            const newListAll = state.list;
            for (const item of newListAll) {
                item.select = action.checked
            }
            return {
                ...state,
                list: newListAll
            };
        case SUPPLIERS_ACTION_FAILED:
        case PURCHASES_ACTION_FAILED:
        case CREDIT_NOTE_ACTION_FAILED:
        case DEBIT_NOTE_ACTION_FAILED:
            return {
                ...state,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case INVOICES_PAY_REQUEST:
            return {
                ...state,
                loading: true
            };
        case INVOICES_PAY_REQUEST_SUCCESS:
            return {
                ...state,
                onlyOne: action.onlyOne,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case INVOICES_PAY_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent
            };
        default:
            return state;
    }
}