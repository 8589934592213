import React from 'react';
import { Backdrop, Button, ButtonGroup, Checkbox, CircularProgress, createStyles, FormControl, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Select, Snackbar, TextField, Theme, Tooltip, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { planAccountInitFormRequest, planAccountInsertRequest, planAccountInitUpdateFormRequest, planAccountUpdateRequest } from 'penta-redux/actions/accountantActions';
import { locationsGetProvincesRequest, locationsGetDistrictsRequest } from 'penta-redux/actions/generalActions';
import { userEntity } from 'models/userModels';
import history from '../../../utils/history';
import './planAccount.scss'
import FormFooter from 'penta-components/base/formFooter';
import { MessageBox } from 'penta-components/base/messageBox';
import { planAccountEntity } from 'models/accountantModels';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Alert } from '@material-ui/lab';
import ScreenLoader from 'penta-components/base/screenLoader';

const useStyles = (theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        formControl: {
            margin: 0,
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    });

const defaultPlanAccountEntity: planAccountEntity = {
    accaccou: '',
    fiscyear: +localStorage.getItem('currentFiscyear'),
    denomina: '',
    codaccnt: '',
    chargacc: '',
    credtacc: '',
    majoracc: '',
    acclevel: 0,
    descnumb: 0,
    chargdes: '',
    credtdes: '',
    majordes: '',
    codactyp: '',
    chargyea: null,
    credtyea: null,
    majoryea: null
}

interface FormPlanAccountState {
    planAccountEntity: planAccountEntity
    title: string,
    accountError: boolean
    snackbar: boolean
}

class FormPlanAccount extends React.Component<any, FormPlanAccountState> {

    constructor(props: any) {
        super(props);
        this.state = {
            planAccountEntity: defaultPlanAccountEntity,
            title: 'Agregar Plan de Cuentas',
            accountError: false,
            snackbar: false
        }
    }

    onChangeRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            ...value
        }))
    }

    onChangePlanAccountRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            planAccountEntity: {
                ...state.planAccountEntity,
                ...value
            }
        }))
    }

    validateAccount = () => {
        const { masteaccs, planAccount } = this.props.accountantReducers.tables;
        const { accaccou } = this.state.planAccountEntity;
        const rules = masteaccs[0];
        let mayor = '';
        let denomicacion = '';
        let año = 0;
        let nivel = 1;
        let error = false;
        if(rules.accumone === accaccou.length) {
            mayor = '';
            denomicacion = '';
            año = 0;
            nivel = 1;
            error = false;
        }
        else if(rules.accumtwo === accaccou.length) {
            mayor =  accaccou.substring(0, accaccou.length - rules.leveltwo);
            const account = planAccount.find((x: any) => x.accaccou === mayor);
            if (account !== undefined) {
                mayor = account.accaccou;
                denomicacion = account.denomina;
                año = account.fiscyear;
                nivel = 2;
            } else error = true;
        }
        else if(rules.accumtre === accaccou.length) {
            mayor =  accaccou.substring(0, accaccou.length - rules.leveltre);
            const account = planAccount.find((x: any) => x.accaccou === mayor);
            if (account !== undefined) {
                mayor = account.accaccou;
                denomicacion = account.denomina;
                año = account.fiscyear;
                nivel = 3;
            } else error = true;
        }
        else if(rules.accumfou === accaccou.length) {
            mayor =  accaccou.substring(0, accaccou.length - rules.levelfou);
            const account = planAccount.find((x: any) => x.accaccou === mayor);
            if (account !== undefined) {
                mayor = account.accaccou;
                denomicacion = account.denomina;
                año = account.fiscyear;
                nivel = 4;
            } else error = true;
        }
        else if(rules.accunfig === accaccou.length) {
            mayor =  accaccou.substring(0, accaccou.length - rules.levelfig);
            const account = planAccount.find((x: any) => x.accaccou === mayor);
            if (account !== undefined) {
                mayor = account.accaccou;
                denomicacion = account.denomina;
                año = account.fiscyear;
                nivel = 5;
            } else error = true;
        }
        else {
            error = true;
        }

        this.setState((state) => ({
            ...state,
            planAccountEntity: {
                ...state.planAccountEntity,
                majoracc: mayor,
                majordes: denomicacion,
                majoryea: año,
                acclevel: nivel
            },
            accountError: error
        }))
    }

    onCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        console.log('cancel')
        history.push('/papp/accounting/plan-cuentas');
    }

    onContinueCallback = () => {
        const newPlanAccountEntity = defaultPlanAccountEntity;
        this.setState({
            planAccountEntity: newPlanAccountEntity,
            snackbar: true
        });
    }

    onSubmitContinue = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const { params } = this.props.match;
        if (params.accaccou !== undefined) {
            this.props.planAccountUpdateRequest(this.state.planAccountEntity);
        } else {
            this.props.planAccountInsertRequest(this.state.planAccountEntity, this.onContinueCallback);
        }
    }


    onSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (this.state.accountError) return;
        const { params } = this.props.match;
        if (params.accaccou !== undefined) {
            this.props.planAccountUpdateRequest(this.state.planAccountEntity);
        } else {
            this.props.planAccountInsertRequest(this.state.planAccountEntity, null);
        }
    }

    callbackInit = () => {
        const { accotype, accountNature } = this.props.accountantReducers.tables;
        const { planAccountEntity } = this.state;
        planAccountEntity.codaccnt = accountNature[0].codaccnt;
        planAccountEntity.codactyp = accotype[0].codactyp;
        planAccountEntity.fiscyear = this.props.dashboardReducers.currentFiscyear;
        this.setState({
            planAccountEntity
        });
    }

    callbackInitUpdate = (planAccountEntity: planAccountEntity) => {
        this.setState({
            planAccountEntity
        });
    }

    componentDidMount() {
        const { params } = this.props.match;
        if (params.accaccou !== undefined) {
            this.setState({title: 'Editar Plan de Cuentas'});
            this.props.planAccountInitUpdateFormRequest(params.accaccou, params.fiscyear, this.callbackInitUpdate);
        }
        else {
            this.props.planAccountInitFormRequest(this.props.dashboardReducers.currentFiscyear, this.callbackInit);
        }
    }

    render() {

        const { planAccountEntity, title, snackbar, accountError } = this.state;
        const { loading, list, tables, failed, failedTitle, failedContent } = this.props.accountantReducers;
        const { classes } = this.props;

        return (
            <div style={{overflow: 'auto', height: '100%'}}>
                <Paper className='form__container'>
                    <br/>
                    <Typography variant='h5'>{ title }</Typography>
                    <br/>
                    { failed && <MessageBox title={failedTitle} content={failedContent}/> }
                    <form noValidate autoComplete="off">
                        <Grid container spacing={2} >
                            <Grid item xs={4}>
                                <TextField error={ accountError } fullWidth id="accaccou" label="Cuenta Contable" helperText={ accountError ? "No existe cuenta mayor o la cuenta es invalida" : "" } variant="outlined" value={ planAccountEntity.accaccou } onBlur={this.validateAccount} onChange={(e: any) => this.onChangePlanAccountRoot({'accaccou': e.target.value})} inputProps={ { maxLength: tables.masteaccs.length > 0 ? tables.masteaccs[0].accunfig : 0 } }/>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField fullWidth id="denomina" label="Denominacion" variant="outlined" value={ planAccountEntity.denomina } onChange={(e: any) => this.onChangePlanAccountRoot({'denomina': e.target.value})}/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} >
                            <Grid item xs={4}>
                                <Autocomplete
                                    id="chargacc"
                                    freeSolo
                                    options={tables.planAccount}
                                    getOptionLabel={ (x: any) => x.accaccou }
                                    value={ tables.planAccount.find((x: any) => x.accaccou === planAccountEntity.chargacc ) || {accaccou: '', denomina: '', fiscyear: ''}  }
                                    onChange={(e: any, value) => this.onChangePlanAccountRoot({'chargacc': value?.accaccou, 'chargdes': value?.denomina, 'chargyea': value?.fiscyear})}
                                    renderInput={(params) => (
                                        <TextField {...params}
                                        style={ { marginTop: 0 } } label="Cuenta de Cargo" margin="normal" variant="outlined" />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <TextField fullWidth id="chargdes" label="Denominacion Cargo" variant="outlined" value={ planAccountEntity.chargdes || '' } disabled/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} >
                            <Grid item xs={4}>
                                <Autocomplete
                                    id="credtacc"
                                    freeSolo
                                    options={tables.planAccount}
                                    getOptionLabel={ (x: any) => x.accaccou }
                                    value={ tables.planAccount.find((x: any) => x.accaccou === planAccountEntity.credtacc ) || {accaccou: '', denomina: '', fiscyear: ''}  }
                                    onChange={(e: any, value) => this.onChangePlanAccountRoot({'credtacc': value?.accaccou, 'credtdes': value?.denomina, 'credtyea': value?.fiscyear})}
                                    renderInput={(params) => (
                                        <TextField {...params} 
                                        style={ { marginTop: 0 } } label="Cuenta de Abono" margin="normal" variant="outlined" />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <TextField fullWidth id="credtdes" label="Denominacion Abono" variant="outlined" value={ planAccountEntity.credtdes || '' } disabled/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} >
                            <Grid item xs={4}>
                                <TextField fullWidth id="majoracc" label="Cuenta Mayor" variant="outlined" value={ planAccountEntity.majoracc } disabled/>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField fullWidth id="majordes" label="Denominacion Mayor" variant="outlined" value={ planAccountEntity.majordes } disabled/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} >
                            <Grid item xs={4}>
                                <TextField fullWidth id="acclevel" label="Nivel de Cuenta" variant="outlined" value={ planAccountEntity.acclevel } disabled/>
                            </Grid>
                            <Grid item xs={4}> 
                                <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                    <InputLabel id="lbl_accotype">Tipo de Cuenta</InputLabel>
                                    <Select
                                        labelId="lbl_accotype"
                                        id="accotype"
                                        className='select'
                                        label="Tipo de Cuenta"
                                        fullWidth
                                        value={ planAccountEntity.codactyp }
                                        onChange={ (e) => this.onChangePlanAccountRoot({codactyp: e.target.value}) }
                                    >
                                        {
                                            tables.accotype.map((item: any, key: any) => 
                                                <MenuItem value={item.codactyp} key={`accotype_${key}`}>{item.codaname}</MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}> 
                                <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                    <InputLabel id="lbl_nature">Naturaleza</InputLabel>
                                    <Select
                                        labelId="lbl_nature"
                                        id="nature"
                                        className='select'
                                        label="Naturaleza"
                                        fullWidth
                                        value={ planAccountEntity.codaccnt }
                                        onChange={ (e) => this.onChangePlanAccountRoot({codaccnt: e.target.value}) }
                                    >
                                        {
                                            tables.accountNature.map((item: any, key: any) => 
                                                <MenuItem value={item.codaccnt} key={`nature_${key}`}>{item.natuname}</MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        {/* <Grid container spacing={2} >
                            <Grid item xs={4}>
                                <TextField fullWidth id="accaccou" label="Plan de cuenta" variant="outlined" value={ planAccountEntity.accaccou } onChange={(e: any) => this.onChangePlanAccountRoot({'accaccou': e.target.value})}/>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField fullWidth id="denomina" label="Denominacion" variant="outlined" value={ planAccountEntity.denomina } onChange={(e: any) => this.onChangePlanAccountRoot({'denomina': e.target.value})}/>
                            </Grid>
                        </Grid> */}
                    </form>
                    <br/>
                    <FormFooter 
                        onCancel={ this.onCancel } 
                        onSubmitContinue={ this.onSubmitContinue } 
                        onSubmit={ this.onSubmit }/>
                </Paper>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={snackbar}
                    onClose={() => this.setState({snackbar: false})}
                    autoHideDuration={6000}
                >
                    <Alert onClose={() => this.setState({snackbar: false})} severity="success">
                            Datos Guardados Correctamente
                    </Alert>
                </Snackbar>
                <ScreenLoader enabled={loading}/>
            </div>
        );
    }
}

const accountantReducers = 'accountantReducers';
const dashboardReducers = 'dashboardReducers';

const mapStateToProps = (state: any) => ({
    accountantReducers: state.get(accountantReducers),
    dashboardReducers: state.get(dashboardReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        planAccountInitFormRequest,
        planAccountInitUpdateFormRequest,
        planAccountInsertRequest,
        planAccountUpdateRequest
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(FormPlanAccount));