import React from 'react';
import {createUseStyles} from 'react-jss';
import BellIcon from 'penta-assets/images/bell.svg';

const useStyles =  createUseStyles({
    container: {
        'position': 'relative'
    },
    bellDiv: {
        'display': 'flex',
        'alignItems': 'center',
        'cursor': 'pointer'
    },
    bellIcon: {
        'width': 'auto',
        'height': '1.25rem'
    }
});

interface NotificationBell {
    // onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    // value: string | number | readonly string[]
}

const NotificationBell = (props: NotificationBell) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <div className={classes.bellDiv}>
                <img src={BellIcon} className={classes.bellIcon} />
            </div>
        </div>
    )
}

export default NotificationBell;