import React from 'react';
import { Backdrop, Button, ButtonGroup, Checkbox, CircularProgress, createStyles, FormControl, FormControlLabel, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Select, Switch, TextField, Theme, Tooltip, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { afpListRequest } from 'penta-redux/actions/humanResourcesActions';
import history from '../../../../utils/history';
import './../reports.scss'
import FormFooter from 'penta-components/base/formFooter';
import { MessageBox } from 'penta-components/base/messageBox';
import { categoryEntity } from 'models/wharehouseProductsModels';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PdfView from 'penta-components/base/pdfView';
// import * as pdfMake from "pdfmake/build/pdfmake";
// import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
import * as XLSX from 'sheetjs-style';
import ScreenLoader from 'penta-components/base/screenLoader';

let pdfMake = require("pdfmake/build/pdfmake");
let saveAs = require('file-saver').saveAs;

const meses = [
    { key: '01', value: 'Enero' },
    { key: '02', value: 'Febrero' },
    { key: '03', value: 'Marzo' },
    { key: '04', value: 'Abril' },
    { key: '05', value: 'Mayo' },
    { key: '06', value: 'Junio' },
    { key: '07', value: 'Julio' },
    { key: '08', value: 'Agosto' },
    { key: '09', value: 'Septiembre' },
    { key: '10', value: 'Octubre' },
    { key: '11', value: 'Noviembre' },
    { key: '12', value: 'Diciembre' }
]

const useStyles = (theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        formControl: {
            margin: 0,
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    });

interface FormState {
    title: string
    year: string
    month: string
    payrtype: string
    officode: string
    divicode: string
    base64Pdf: string
    allFlag: boolean
}

class ReportAfpList extends React.Component<any, FormState> {

    constructor(props: any) {
        super(props);
        this.state = {
            title: 'Reporte de Listado de AFP',
            year: moment().format('yyyy').toString(),
            month: moment().format('MM').toString(),
            payrtype: '101',
            officode: '',
            divicode: '',
            base64Pdf: '',
            allFlag: false
        }
    }

    onChangeRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            ...value
        }))
    }

    onGeneratePdf = () => {
        const data = this.props.humanResourcesReducers.onlyOne;
        const { month, year, payrtype, divicode, officode } = this.state;
        const { dashboardReducers } = this.props;
        const mes = meses.find((x: any) => x.key === month).value;
        const company = (JSON.parse(localStorage.getItem('tables')).companys.find((x: any) => x.taxident === dashboardReducers.currentCompany));
        const division = company.lst_grl_division.find((x: any) => x.divicode === divicode) || {};
        const oficina = division.lst_grl_bussofic?.find((x: any) => x.officode === officode) || {offiname: 'TODAS'};
        const stardate = new Date(parseInt(year), parseInt(month) - 1, 1);
        const endidate = new Date(parseInt(year), parseInt(month) - 1, moment(stardate).daysInMonth());

        const pdfObject: any = {
            pageSize: 'A4',
            pageOrientation: 'portrait',
            pageMargins: [ 20, 100, 20, 20 ],
            header: [
                {
                    text: ' ', lineHeight: 2
                },
                {
                    margin: [20, 0, 0, 0],
                    text: company.bussname.toUpperCase(),
                    style: {
                        bold: true,
                        fontSize: 9
                    }
                },
                {
                    margin: [20, 5, 20, 0],
                    text: `${mes} ${year}`,
                    style: {
                        fontSize: 9,
                        alignment: 'center'
                    }
                },
                {
                    margin: [20, 10, 20, 0],
                    text: payrtype === '102' ? `SEMANAS : ${moment(stardate).week()}-${moment(endidate).week()}` : 'MES : ' + month,
                    style: {
                        fontSize: 7
                    }
                },
                {
                    margin: [20, 0, 20, 0],
                    text: 'PLANILLA : ' + (payrtype === '102' ? 'OBREROS ' : 'EMPLEADOS ') + oficina.offiname.toUpperCase(),
                    style: {
                        fontSize: 7
                    }
                }
            ],
            content: [
                {
                    table: {
                        headerRows: 0,
                        widths: [ 30, 100, 50, 50, 45, 50, 50, 50, 50 ],
                        body: []
                    }
                },
            ],
            styles: {
                headerTitle: {
                    bold: true,
                    fontSize: 7,
                    lineHeight: 1.2
                },
                table: {
                    layout: 'headerLineOnly',
                    fontSize: 6,
                    alignment: 'center'
                },
                subTable: {
                    layout: 'headerLineOnly',
                    fontSize: 6,
                    alignment: 'left'
                },
                tableDetailLeft: {
                    layout: 'headerLineOnly',
                    fontSize: 6,
                    alignment: 'left'
                },
                tableDetailRight: {
                    layout: 'headerLineOnly',
                    fontSize: 6,
                    alignment: 'right'
                },
                tableDetailRightBold: {
                    layout: 'headerLineOnly',
                    fontSize: 6,
                    alignment: 'right',
                    bold: true
                }
            },
            defaultStyle: {
                columnGap: 20,
            },
        }

        const tableBody: any = [

        ];

        const readCommissi = [];
        let ggc1 = 0, ggc2 = 0, ggc3 = 0, ggc4 = 0, ggc5 = 0, ggc6 = 0, ggc7 = 0;
        const afp = { '00': 'DECRETO LEY 19990 - SNP', '01': 'HABITAT', '02': 'INTEGRA', '03': 'PRIMA', '04': 'PROFUTURO' }
        
        for (const row of data) {
            if(!readCommissi.includes((row.commcode || '00'))) {
                const rows = data.filter((x) => x.commcode === row.commcode);
                const commcode = (row.commcode || '00')

                tableBody.push([ 
                    { text: afp[commcode], style: 'tableDetailLeft', colSpan: 9, border: [false, false, false, true] },{},{},{},{},{},{},{},{}
                ]);
                tableBody.push([ 
                    { text: 'RENUMERACION', style: 'table', colSpan: 9, border: [false, false, false, false] },{},{},{},{},{},{},{},{}
                ]);
                tableBody.push([ 
                    { text: 'CODIGO', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, true] },
                    { text: 'APELLIDOS Y NOMBRES', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, true] },
                    { text: 'ASEGURABLE', style: 'table', colSpan: 1, border: [false, false, false, true] },
                    { text: 'F.PENS.', style: 'table', colSpan: 1, border: [false, false, false, true] },
                    { text: 'SEGUROS', style: 'table', colSpan: 1, border: [false, false, false, true] },
                    { text: 'COM.MIXTA', style: 'table', colSpan: 1, border: [false, false, false, true] },
                    { text: 'COM.FLUJO', style: 'table', colSpan: 1, border: [false, false, false, true] },
                    { text: 'SUB-TOTAL', style: 'table', colSpan: 1, border: [false, false, false, true] },
                    { text: 'TOTAL', style: 'table', colSpan: 1, border: [false, false, false, true] },
                ]);

                const laborers = [];
                let gc1 = 0, gc2 = 0, gc3 = 0, gc4 = 0, gc5 = 0, gc6 = 0, gc7 = 0;

                for (const iterator of rows) {
                    if(!laborers.includes(iterator.emplcode)) {
                        const laborer = rows.filter((x) => x.emplcode === iterator.emplcode);
                        const c1 = laborer.find((x) => x.conccode === 'C501').outcomee;
                        const c2 = laborer.find((x) => x.conccode === (payrtype === '102' ? 'C310' : 'C313'))?.outcomee || 0;
                        const c3 = laborer.find((x) => x.conccode === (payrtype === '102' ? 'C309' : 'C312'))?.outcomee || 0;
                        const c4 = laborer.find((x) => x.conccode === (payrtype === '102' ? 'C308' : 'C311'))?.outcomee || 0;
                        const c5 = laborer.find((x) => x.conccode === (payrtype === '102' ? 'C307' : 'C310'))?.outcomee || 0;
                        gc1 += c1; gc2 += c2; gc3 += c3; gc4 += c4; gc5 += c5; gc6 += (c3 + c4 + c5); gc7 += (c2 + c3 + c4 + c5);
                        tableBody.push(
                            [ 
                                { text: iterator.emplcode.toUpperCase(), style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: iterator.nameeess.substr(0, 25).toUpperCase(), style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber.new(c1), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber.new(c2), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber.new(c3), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber.new(c4), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber.new(c5), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber.new(c3 + c4 + c5), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber.new(c2 + c3 + c4 + c5), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                            ]
                        )
                        laborers.push(iterator.emplcode);
                    }
                }

                ggc1 += gc1; ggc2 += gc2; ggc3 += gc3; ggc4 += gc4; ggc5 += gc5; ggc6 += gc6; ggc7 += gc7;

                tableBody.push(
                    [ 
                        { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                        { text: 'TOTALES POR AFP', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                        { text: String.formatNumber.new(gc1), style: 'tableDetailRight', colSpan: 1, border: [false, true, false, true] },
                        { text: String.formatNumber.new(gc2), style: 'tableDetailRight', colSpan: 1, border: [false, true, false, true] },
                        { text: String.formatNumber.new(gc3), style: 'tableDetailRight', colSpan: 1, border: [false, true, false, true] },
                        { text: String.formatNumber.new(gc4), style: 'tableDetailRight', colSpan: 1, border: [false, true, false, true] },
                        { text: String.formatNumber.new(gc5), style: 'tableDetailRight', colSpan: 1, border: [false, true, false, true] },
                        { text: String.formatNumber.new(gc6), style: 'tableDetailRight', colSpan: 1, border: [false, true, false, true] },
                        { text: String.formatNumber.new(gc7), style: 'tableDetailRight', colSpan: 1, border: [false, true, false, true] },
                    ],
                    [ 
                        { text: ' ', style: { lineHeight: 1 }, colSpan: 9, border: [false, false, false, false] },
                    ],
                )

                readCommissi.push(commcode);
            }
        }

        tableBody.push(
            [ 
                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                { text: 'TOTALES GENERALES POR AFP', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                { text: String.formatNumber.new(ggc1), style: 'tableDetailRight', colSpan: 1, border: [false, true, false, true] },
                { text: String.formatNumber.new(ggc2), style: 'tableDetailRight', colSpan: 1, border: [false, true, false, true] },
                { text: String.formatNumber.new(ggc3), style: 'tableDetailRight', colSpan: 1, border: [false, true, false, true] },
                { text: String.formatNumber.new(ggc4), style: 'tableDetailRight', colSpan: 1, border: [false, true, false, true] },
                { text: String.formatNumber.new(ggc5), style: 'tableDetailRight', colSpan: 1, border: [false, true, false, true] },
                { text: String.formatNumber.new(ggc6), style: 'tableDetailRight', colSpan: 1, border: [false, true, false, true] },
                { text: String.formatNumber.new(ggc7), style: 'tableDetailRight', colSpan: 1, border: [false, true, false, true] },
            ],
        )

        pdfObject.content[0].table.body = tableBody;

        pdfMake.fonts = {
            Roboto: {
                normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
                bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
                italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
                bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
              },
        }

        const pdfDocGenerator = pdfMake.createPdf(pdfObject);
        pdfDocGenerator.getDataUrl((data: any) => {
            // console.log(data);
            this.setState({
                base64Pdf: data
            })
        });

    }

    onGenerateExcel = () => {
        try {
            const data = this.props.humanResourcesReducers.onlyOne;
            const { month, year, payrtype, divicode, officode } = this.state;
            const { dashboardReducers } = this.props;
            const mes = meses.find((x: any) => x.key === month).value;
            const company = (JSON.parse(localStorage.getItem('tables')).companys.find((x: any) => x.taxident === dashboardReducers.currentCompany));
            const division = company.lst_grl_division.find((x: any) => x.divicode === divicode) || {};
            const oficina = division?.lst_grl_bussofic.find((x: any) => x.officode === officode) || {offiname: 'TODAS'};
            const stardate = new Date(parseInt(year), parseInt(month) - 1, 1);
            const endidate = new Date(parseInt(year), parseInt(month) - 1, moment(stardate).daysInMonth());

            const tableBody: any = [
                { 
                    A: company.bussname.toUpperCase(),
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null,
                    G: null,
                    H: null,
                    I: null
                },
                { 
                    A: `${mes} ${year}`,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null,
                    G: null,
                    H: null,
                    I: null,
                },
                { 
                    A: payrtype === '102' ? `SEMANAS : ${moment(stardate).week()}-${moment(endidate).week()}` : 'MES : ' + month,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null,
                    G: null,
                    H: null,
                    I: null
                },
                { 
                    A: 'PLANILLA : ' + (payrtype === '102' ? 'OBREROS ' : 'EMPLEADOS ') + oficina.offiname.toUpperCase(),
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null,
                    G: null,
                    H: null,
                    I: null
                },
                { 
                    A: null,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null,
                    G: null,
                    H: null,
                    I: null
                },
            ];

            const readCommissi = [];
            let ggc1 = 0, ggc2 = 0, ggc3 = 0, ggc4 = 0, ggc5 = 0, ggc6 = 0, ggc7 = 0;
            const afp = { '00': 'DECRETO LEY 19990 - SNP', '01': 'HABITAT', '02': 'INTEGRA', '03': 'PRIMA', '04': 'PROFUTURO' }
            const cutRows: Array<number> = [];
            const cutHeaderRows: Array<number> = [];
            const cutTextRows: Array<number> = [];

            for (const row of data) {
                if(!readCommissi.includes((row.commcode || '00'))) {
                    cutRows.push(tableBody.length);
                    cutTextRows.push(tableBody.length + 1);
                    cutHeaderRows.push(tableBody.length + 2);
                    const rows = data.filter((x) => x.commcode === row.commcode);
                    const commcode = (row.commcode || '00')
        
                    tableBody.push(
                        { 
                            A: afp[commcode],
                            B: '',
                            C: '',
                            D: '',
                            E: '',
                            F: '',
                            G: '',
                            H: '',
                            I: ''
                        },
                        { 
                            A: 'RENUMERACION',
                            B: '',
                            C: '',
                            D: '',
                            E: '',
                            F: '',
                            G: '',
                            H: '',
                            I: ''
                        },
                        {
                            A: 'CODIGO',
                            B: 'APELLIDOS Y NOMBRES',
                            C: 'ASEGURABLE',
                            D: 'F.PENS.',
                            E: 'SEGUROS',
                            F: 'COM.MIXTA',
                            G: 'COM.FLUJO',
                            H: 'SUB-TOTAL',
                            I: 'TOTAL'
                        }
                    );
        
                    const laborers = [];
                    let gc1 = 0, gc2 = 0, gc3 = 0, gc4 = 0, gc5 = 0, gc6 = 0, gc7 = 0;
        
                    for (const iterator of rows) {
                        if(!laborers.includes(iterator.emplcode)) {
                            const laborer = rows.filter((x) => x.emplcode === iterator.emplcode);
                            const c1 = laborer.find((x) => x.conccode === 'C501').outcomee;
                            const c2 = laborer.find((x) => x.conccode === (payrtype === '102' ? 'C310' : 'C313'))?.outcomee || 0;
                            const c3 = laborer.find((x) => x.conccode === (payrtype === '102' ? 'C309' : 'C312'))?.outcomee || 0;
                            const c4 = laborer.find((x) => x.conccode === (payrtype === '102' ? 'C308' : 'C311'))?.outcomee || 0;
                            const c5 = laborer.find((x) => x.conccode === (payrtype === '102' ? 'C307' : 'C310'))?.outcomee || 0;
                            gc1 += c1; gc2 += c2; gc3 += c3; gc4 += c4; gc5 += c5; gc6 += (c3 + c4 + c5); gc7 += (c2 + c3 + c4 + c5);
                            tableBody.push(
                                {
                                    A: iterator.emplcode.toUpperCase(),
                                    B: iterator.nameeess.substr(0, 25).toUpperCase(),
                                    C: String.formatNumber.new(c1),
                                    D: String.formatNumber.new(c2),
                                    E: String.formatNumber.new(c3),
                                    F: String.formatNumber.new(c4),
                                    G: String.formatNumber.new(c5),
                                    H: String.formatNumber.new(c3 + c4 + c5),
                                    I: String.formatNumber.new(c2 + c3 + c4 + c5),
                                }
                            )
                            laborers.push(iterator.emplcode);
                        }
                    }
        
                    ggc1 += gc1; ggc2 += gc2; ggc3 += gc3; ggc4 += gc4; ggc5 += gc5; ggc6 += gc6; ggc7 += gc7;
        
                    tableBody.push(
                        { 
                            A: '',
                            B: 'TOTALES POR AFP',
                            C: String.formatNumber.new(gc1),
                            D: String.formatNumber.new(gc2),
                            E: String.formatNumber.new(gc3),
                            F: String.formatNumber.new(gc4),
                            G: String.formatNumber.new(gc5),
                            H: String.formatNumber.new(gc6),
                            I: String.formatNumber.new(gc7),
                        },
                        {
                            A: null, 
                            B: null,
                            C: null,
                            D: null,
                            E: null,
                            F: null,
                            G: null,
                            H: null,
                            I: null
                        }
                    )
        
                    readCommissi.push(commcode);
                }
            }

            tableBody.push(
                { 
                    A: '',
                    B: 'TOTALES GENERALES POR AFP',
                    C: String.formatNumber.new(ggc1),
                    D: String.formatNumber.new(ggc2),
                    E: String.formatNumber.new(ggc3),
                    F: String.formatNumber.new(ggc4),
                    G: String.formatNumber.new(ggc5),
                    H: String.formatNumber.new(ggc6),
                    I: String.formatNumber.new(ggc7)
                },
            )

            const header = [
                'A',
                'B',
                'C',
                'D',
                'E',
                'F',
                'G',
                'H',
                'I'
            ];

            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(tableBody, {header, skipHeader: true});
            ws["!cols"] = [
                {wpx:70},{wpx:150},{wpx:70},{wpx:70},{wpx:70},{wpx:70},{wpx:70},{wpx:70},{wpx:70}
            ];
            ws['!rows'] = [
                {hpx: 18},{hpx: 16},{hpx: 16},{hpx: 16}
            ];

            const keys = Object.keys(ws);
            const rowsCount = tableBody.length;

            for (const iterator of keys) {
                if(!isNaN(parseInt(ws[iterator].v)) && !iterator.match(/^(A|B)[0-9]+$/g)) {
                    ws[iterator].v = ws[iterator].v.replace(/,/g, '');
                    ws[iterator].t = 'n';
                    ws[iterator].z = '#,##0.00';
                }
                if(iterator !== '!ref' && iterator !== '!cols' && iterator !== '!rows') {
                    ws[iterator].s = {
                        font: {
                            name: 'Tahoma',
                            sz: 8
                        }
                    }
                    const row = +(iterator.match(/\d+/)[0]);
                    if(!cutRows.includes(row - 1) && !cutTextRows.includes(row - 1) && !cutHeaderRows.includes(row - 1) && !iterator.match(/^(A|B)[0-9]+$/g)) {
                        ws[iterator].s.border = {
                            ...ws[iterator].s.border,
                            bottom: { style: 'thin', color: 'black'}
                        }
                    }
                    else if(cutRows.includes(row - 1)) {
                        ws[iterator].s.border = {
                            ...ws[iterator].s.border,
                            bottom: { style: 'thin', color: 'black'}
                        }
                    }
                    else if(cutTextRows.includes(row - 1)) {
                        ws[iterator].s = {
                            ...ws[iterator].s,
                            alignment: { wrapText: true, vertical: 'center', horizontal: 'center'}
                        }
                    }
                    else if(cutHeaderRows.includes(row - 1)) {
                        ws[iterator].s.border = {
                            ...ws[iterator].s.border,
                            bottom: { style: 'thin', color: 'black'}
                        }
                    }
                }
            }

            for (let index = 5; index <= rowsCount; index++) {
                ws['!rows'].push({hpx: 12})
            }

            ws['A1'].s = {font:{name: 'Tahoma', sz: 12, bold: true}}
            ws['A2'].s = {font:{name: 'Tahoma', sz: 8, bold: true}, alignment: { wrapText: true, vertical: 'center', horizontal: 'center'}}
            ws['A3'].s = {font:{name: 'Tahoma', sz: 8, bold: true}}
            ws['A4'].s = {font:{name: 'Tahoma', sz: 8, bold: true}}

            // for (const iterator of header) {
            //     const key1 = iterator + "6";
            //     const key2 = iterator + "7";

            //     ws[key1].s = {
            //         ...ws[key1].s, 
            //         alignment: { wrapText: true, vertical: 'center', horizontal: 'center'},
            //         border: { 
            //             top: { style: 'thin', color: 'black'},
            //             bottom: { style: 'thin', color: 'black'},
            //             left: { style: 'thin', color: 'black'},
            //             right: { style: 'thin', color: 'black'}
            //         } 
            //     }
            //     ws[key2].s = {
            //         ...ws[key2].s, 
            //         alignment: { wrapText: true, vertical: 'center', horizontal: 'center'},
            //         border: { 
            //             top: { style: 'thin', color: 'black'},
            //             bottom: { style: 'thin', color: 'black'},
            //             left: { style: 'thin', color: 'black'},
            //             right: { style: 'thin', color: 'black'}
            //         } 
            //     }
            // };

            ws["!merges"] = [
                { s: { r: 1, c: 0 }, e: { r: 1, c: 8 } },
                ...cutRows.map((item) => { return  { s: { r: item, c: 0 }, e: { r: item, c: 8 } } }),
                ...cutTextRows.map((item) => { return  { s: { r: item, c: 0 }, e: { r: item, c: 8 } } })
            //     { s: { r: 5, c: 0 }, e: { r: 6, c: 0 } },
            //     { s: { r: 5, c: 1 }, e: { r: 6, c: 1 } },
            //     { s: { r: 5, c: 2 }, e: { r: 6, c: 2 } },
            //     { s: { r: 5, c: 3 }, e: { r: 5, c: 5 } },
            //     { s: { r: 5, c: 6 }, e: { r: 5, c: 7 } },
            //     { s: { r: 5, c: 8 }, e: { r: 5, c: 9 } },
            ];

            XLSX.utils.book_append_sheet(wb, ws, "Listado de AFP");

            var wbout = XLSX.write(wb, {bookType:'xlsx', type: 'binary', cellStyles: true});

            const s2ab = function(s) {
                var buf = new ArrayBuffer(s.length);
                var view = new Uint8Array(buf);
                for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
                return buf;
            }

            saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), `listado-afp-${year}-${month}.xlsx`);

        } catch(err) {
            console.log(err);
        }
    }

    render() {

        const { title, year, month, payrtype, divicode, officode, base64Pdf, allFlag } = this.state;
        const { loading, list, tables, failed, failedTitle, failedContent } = this.props.humanResourcesReducers;
        const { classes, dashboardReducers } = this.props;

        const company = JSON.parse(localStorage.getItem('tables')).companys.find((x: any) => x.taxident === dashboardReducers.currentCompany);

        return (
            <div style={{overflow: 'auto', height: '100%'}}>
                <Paper className='form__container'>
                    <Typography variant='h5'>{ title }</Typography>
                    <br/>
                    { failed && <MessageBox title={failedTitle} content={failedContent}/> }
                    <div style={{ display: 'flex' }}>
                        <form noValidate autoComplete="off" style={{ flex: 1, paddingRight: '15px', paddingTop: '2em' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_planilla">Planilla</InputLabel>
                                            <Select
                                                labelId="lbl_planilla"
                                                id="payrtype"
                                                className='select'
                                                label="Planilla"
                                                fullWidth
                                                value={ payrtype }
                                                onChange={(e: any) => this.onChangeRoot({'payrtype': e.target.value})}
                                            >
                                                <MenuItem value='101'>Empleados</MenuItem>
                                                <MenuItem value='102'>Obreros</MenuItem>
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_año">Año</InputLabel>
                                            <Select
                                                labelId="lbl_año"
                                                id="year"
                                                className='select'
                                                label="Año"
                                                fullWidth
                                                value={ year }
                                                onChange={(e: any) => this.onChangeRoot({'year': e.target.value})}
                                            >
                                                <MenuItem value='2030'>2030</MenuItem>
                                                <MenuItem value='2029'>2029</MenuItem>
                                                <MenuItem value='2028'>2028</MenuItem>
                                                <MenuItem value='2027'>2027</MenuItem>
                                                <MenuItem value='2026'>2026</MenuItem>
                                                <MenuItem value='2025'>2025</MenuItem>
                                                <MenuItem value='2026'>2026</MenuItem>
                                                <MenuItem value='2025'>2025</MenuItem>
                                                <MenuItem value='2024'>2024</MenuItem>
                                                <MenuItem value='2023'>2023</MenuItem>
                                                <MenuItem value='2022'>2022</MenuItem>
                                                <MenuItem value='2021'>2021</MenuItem>
                                                <MenuItem value='2020'>2020</MenuItem>
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_mesi">Mes</InputLabel>
                                            <Select
                                                labelId="lbl_mes"
                                                id="month"
                                                className='select'
                                                label="Mes Inicio"
                                                fullWidth
                                                value={ month }
                                                onChange={(e: any) => this.onChangeRoot({'month': e.target.value})}
                                            >
                                                {
                                                    meses.map((x: any) => 
                                                        <MenuItem value={x.key} key={x.key}>{x.value}</MenuItem>
                                                    )
                                                }
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                        <Switch
                                            checked={allFlag}
                                            onChange={(e: any) => this.onChangeRoot({'allFlag': e.target.checked})}
                                            name="allFlag"
                                            color="primary"
                                        />
                                        }
                                        label="Todo el personal"
                                    />
                                </Grid>
                                {
                                    !allFlag &&
                                    <>
                                        <Grid item xs={12}>
                                            <FormControl variant="outlined" className={classes.formControl} fullWidth disabled={company === '' ? true : false}>
                                                <InputLabel id="lbl_division">Division</InputLabel>
                                                <Select
                                                    labelId="lbl_division"
                                                    id="division"
                                                    value={divicode}
                                                    onChange={(e: React.ChangeEvent<{ value: unknown }>) => { this.onChangeRoot({'divicode': e.target.value}) }}
                                                    label="Division"
                                                    fullWidth
                                                >
                                                    {
                                                        company.lst_grl_division.map((x: any) => 
                                                            <MenuItem value={x.divicode} key={x.divicode}>{ x.diviname }</MenuItem>
                                                        )
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl variant="outlined" className={classes.formControl} fullWidth disabled={divicode === '' ? true : false}>
                                                <InputLabel id="lbl_oficina">Oficina</InputLabel>
                                                <Select
                                                    labelId="lbl_oficina"
                                                    id="oficina"
                                                    value={officode}
                                                    onChange={(e: React.ChangeEvent<{ value: unknown }>) => { this.onChangeRoot({'officode': e.target.value}) }}
                                                    label="Oficina"
                                                    fullWidth
                                                >
                                                    {
                                                        company.lst_grl_division.find((x) => x.divicode === divicode)?.lst_grl_bussofic.map((x: any) => 
                                                            <MenuItem value={x.officode} key={x.officode}>{ x.offiname }</MenuItem>
                                                        )
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </>
                                }
                                <Grid item xs={12} style={{ paddingTop: '50px' }}>
                                    <Button variant="contained" fullWidth color="primary" onClick={() => { 
                                        this.props.afpListRequest(allFlag ? 'ALL' : officode, year, month, payrtype, this.onGeneratePdf) 
                                    }}>
                                        Generar Reporte PDF
                                    </Button>
                                </Grid>
                                <Grid item xs={12} style={{ paddingTop: '10px' }}>
                                    <Button variant="contained" fullWidth color="inherit" onClick={() => { 
                                        this.props.afpListRequest(allFlag ? 'ALL' : officode, year, month, payrtype, this.onGenerateExcel) 
                                    }}>
                                        Generar Reporte EXCEL
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                        <PdfView data={base64Pdf} style={{ flex: 2 }}/>
                    </div>
                    <br/>
                    {/* <FormFooter onCancel={ this.onCancel } onSubmit={ this.onSubmit }/> */}
                </Paper>
                <ScreenLoader enabled={loading}/>
            </div>
        );
    }
}

const humanResourcesReducers = 'humanResourcesReducers';
const dashboardReducers = 'dashboardReducers';

const mapStateToProps = (state: any) => ({
    humanResourcesReducers: state.get(humanResourcesReducers),
    dashboardReducers: state.get(dashboardReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        afpListRequest
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(ReportAfpList));