const serverUrl = process.env.PENTA_MASTER_SERVER_URL || `https://api.${window.location.hostname}/api/`;

export const MASTER_ACCOUNT_GET_ALL_RECORDS = serverUrl + 'accountant/master-account/get-all-records';
export const MASTER_ACCOUNT_UPDATE = serverUrl + 'accountant/master-account/update/';

export const PLAN_ACCOUNT_INIT_FORM = serverUrl + 'accountant/plan-account/init-form/';
export const PLAN_ACCOUNT_INIT_UPDATE_FORM = serverUrl + 'accountant/plan-account/init-update-form/';
export const PLAN_ACCOUNT_GET_ALL_RECORDS = serverUrl + 'accountant/plan-account/get-all-records';
export const PLAN_ACCOUNT_INSERT = serverUrl + 'accountant/plan-account/insert';
export const PLAN_ACCOUNT_UPDATE = serverUrl + 'accountant/plan-account/update/';
export const PLAN_ACCOUNT_DELETE = serverUrl + 'accountant/plan-account/delete?';

export const VOUCHERS_INIT_FORM = serverUrl + 'accountant/vouchers/init-form/';
export const VOUCHERS_INIT_UPDATE_FORM = serverUrl + 'accountant/vouchers/init-update-form/';
export const VOUCHERS_GET_ALL_RECORDS = serverUrl + 'accountant/vouchers/get-all-records';
export const VOUCHERS_INSERT = serverUrl + 'accountant/vouchers/insert';
export const VOUCHERS_UPDATE = serverUrl + 'accountant/vouchers/update/';
export const VOUCHERS_DELETE = serverUrl + 'accountant/vouchers/delete?';
export const VOUCHERS_ANULATE = serverUrl + 'accountant/vouchers/anulate?';

export const GENERAL_BALANCES_ACCOUNT = serverUrl + 'accountant/reports/general-balances-account/';
export const GENERATE_EMPTY_BALANCES = serverUrl + 'accountant/utilities/generate-empty-balances/';
export const DIARY_BOOK = serverUrl + 'accountant/reports/diary-book/';
export const PURCHASES_REGISTER = serverUrl + 'accountant/reports/purchases-register/';
export const WORKSHEET = serverUrl + 'accountant/reports/worksheet/';
export const MAJOR_GENERAL = serverUrl + 'accountant/reports/major-general/';
export const CASH_BOOK_BANKS = serverUrl + 'accountant/reports/cash-book-banks/';
export const EXPENSE_ANALYSIS_NINE = serverUrl + 'accountant/reports/expense-analysis-nine/';
export const EXPENSE_ANALYSIS_SIX = serverUrl + 'accountant/reports/expense-analysis-six/';
export const INVENTORY_BOOK_BALANCE = serverUrl + 'accountant/reports/inventory-book-balance/';
export const GENERAL_BALANCES = serverUrl + 'accountant/reports/general-balances/';
export const PROFIT_LOSS_STATEMENT = serverUrl + 'accountant/reports/profit-loss-statement/';
export const SALES_REGISTER = serverUrl + 'accountant/reports/sales-register/';
export const DAOT = serverUrl + 'accountant/reports/daot/';
export const PURCHASE_DETAIL_SUPPLIER = serverUrl + 'accountant/reports/purchase-detail-supplier/';

export const MAJORIZATION = serverUrl + 'accountant/utilities/majorization/';