import React from 'react';
import { AppBar, Backdrop, Box, Button, ButtonGroup, Checkbox, CircularProgress, createStyles, FormControl, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Select, Tab, Tabs, TextField, Theme, Tooltip, Typography, FormControlLabel, withStyles, TextareaAutosize, Snackbar } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { vouchersInitFormRequest, vouchersInitUpdateFormRequest, vouchersInsertRequest, vouchersUpdateRequest } from 'penta-redux/actions/accountantActions';
import { currencyExchangeRateRequest } from 'penta-redux/actions/generalActions';
import history from '../../../utils/history';
import './vouchers.scss'
import FormFooter from 'penta-components/base/formFooter';
import { MessageBox } from 'penta-components/base/messageBox';
import { acc_vouchers } from 'models/accountantModels';
import moment from 'moment';
import { Alert, Autocomplete } from '@material-ui/lab';
import EditableCell from 'penta-components/base/editableCell';
import ScreenLoader from 'penta-components/base/screenLoader';

const meses = [
    { key: '00', value: 'Apertura' },
    { key: '01', value: 'Enero' },
    { key: '02', value: 'Febrero' },
    { key: '03', value: 'Marzo' },
    { key: '04', value: 'Abril' },
    { key: '05', value: 'Mayo' },
    { key: '06', value: 'Junio' },
    { key: '07', value: 'Julio' },
    { key: '08', value: 'Agosto' },
    { key: '09', value: 'Septiembre' },
    { key: '10', value: 'Octubre' },
    { key: '11', value: 'Noviembre' },
    { key: '12', value: 'Diciembre' },
    { key: '13', value: 'Cierre' },
]

const header = [
    "", 
    "Tipo Comprobante",
    "Cuenta Contable",
    "Denominación",
    "Moneda",
    "Fecha Operación",
    "Tipo de Cambio", 
    "Debe S/.",
    "Haber S/.",
    "Debe $",
    "Haber $",
    "Glosa", 
    "Nro. Documento", 
    "Cto.Costo",
    "Cta.Destino",
    "Analitico",
    "Destino(+)",
    "Destino(-)",
    "Comp."
];

const currentFiscyear = localStorage.getItem('currentFiscyear');

const useStyles = (theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        formControl: {
            margin: 0,
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    });

const defaultVouchersEntity: acc_vouchers = {
    correlat: 0,
    dateoper: currentFiscyear.toString() + moment().format('-MM-DD'),
    yearexer: currentFiscyear,
    montexer: moment().format('MM'),
    currency: '00000014',
    subdiary: 'BOKCO005',
    optotals: {
        '00000014': { debitamo: 0.00, assetamo: 0.00 },
        '00000015': { debitamo: 0.00, assetamo: 0.00 },
    },
    acc_vouchdet: []
}

interface FormVouchersState {
    vouchersEntity: acc_vouchers
    title: string
    dateoper: any
    vouchers: any
    curreval: any
    denomina: string
    glosarys: string
    itemnumb: number
    snackbar: boolean
    error: {[key: string]: string | boolean}
}

class FormVouchers extends React.Component<any, FormVouchersState> {

    constructor(props: any) {
        super(props);
        defaultVouchersEntity.acc_vouchdet = [];
        this.state = {
            vouchersEntity: defaultVouchersEntity,
            title: 'Agregar Movimientos',
            dateoper: defaultVouchersEntity.dateoper,
            vouchers: '',
            curreval: 3.22,
            denomina: '',
            glosarys: '',
            itemnumb: 0,
            snackbar: false,
            error: {
                message: '',
                state: false
            }
        }
    }

    onChangeRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            ...value
        }))
    }

    onChangeVouchersRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            vouchersEntity: {
                ...state.vouchersEntity,
                ...value
            }
        }));
    }

    onCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        console.log('cancel')
        history.push('/papp/accounting/comprobantes');
    }

    onContinueCallback = () => {
        const newVoucherEntity = defaultVouchersEntity;
        newVoucherEntity.acc_vouchdet = [];
        this.setState({
            vouchersEntity: newVoucherEntity,
            itemnumb: 0,
            snackbar: true
        });
    }

    isNotValidForm = () => {
        const { acc_planacco } = this.props.accountantReducers.tables;
        const { vouchersEntity } = this.state;
        for (const iterator of vouchersEntity.acc_vouchdet) {
            if(!acc_planacco.find(x => x.accaccou === iterator.accaccou))  {
                this.setState({error: {state: true, message: `El numero de cuenta ${iterator.accaccou} en el item nro ${iterator.itemnumb} no es valido`}})
                return true;
            }
        }
        return false;
    }

    onSubmitContinue = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const { params } = this.props.match;
        if(this.isNotValidForm()) return;
        if (params.correlat !== undefined) {
            this.props.vouchersUpdateRequest(this.state.vouchersEntity, null);
        } else {
            this.props.vouchersInsertRequest(this.state.vouchersEntity, this.onContinueCallback);
        }
        this.setState({error: {state: false, message: ``}})
    }

    onSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const { params } = this.props.match;
        if(this.isNotValidForm()) return;
        if (params.correlat !== undefined) {
            this.props.vouchersUpdateRequest(this.state.vouchersEntity, null);
        } else {
            this.props.vouchersInsertRequest(this.state.vouchersEntity, null);
        }
        this.setState({error: {state: false, message: ``}})
    }

    callbackInitUpdate = (vouchersEntity: acc_vouchers) => {
        console.log(vouchersEntity);
        vouchersEntity.dateoper = moment(vouchersEntity.dateoper).format('YYYY-MM-DD');
        vouchersEntity.optotals = JSON.parse(vouchersEntity.optotals);
        for (let iterator of vouchersEntity.acc_vouchdet) {
            iterator.balances = JSON.parse(iterator.balances);
            iterator.dateoper = moment(iterator.dateoper).format('YYYY-MM-DD');
        }

        if (vouchersEntity.acc_vouchdet.length >  0) {
            this.setState({
                vouchersEntity,
                dateoper: vouchersEntity.dateoper,
                vouchers: vouchersEntity.acc_vouchdet[vouchersEntity.acc_vouchdet.length - 1].vouchers,
                curreval: vouchersEntity.acc_vouchdet[vouchersEntity.acc_vouchdet.length - 1].curreval,
                itemnumb: vouchersEntity.acc_vouchdet[vouchersEntity.acc_vouchdet.length - 1].itemnumb
            });
        } else {
            this.setState({
                vouchersEntity
            });
        }
    }

    callbackInit = () => {
        this.props.currencyExchangeRateRequest(moment(this.state.vouchersEntity.dateoper).format('YYYY-MM-DD'), () => {
            const { tables } = this.props.generalReducers;
            if(tables.exchangeRate !== null) {
                this.setState((state: any) => ({
                    ...state,
                    curreval: tables.exchangeRate.venta
                }))
            }
        });
    }

    calculateGrid = (acc_vouchdet, props, value, curreval, currency, totalamo, _precalc = true, _origin = '') => {
        const { csb_cashbank } = this.props.accountantReducers.tables;
        let balances;
        const companyCurrency = '00000014';
        const _typevouc = props.field === 'typevouc' ? value : acc_vouchdet[props.index]['typevouc'];
        let _curreval = props.field === 'curreval' ? +value : +acc_vouchdet[props.index]['curreval'];
        // console.log(_curreval);
        const _currency = this.state.vouchersEntity.currency;
        let _exchrate = 0.00;
        if(companyCurrency === _currency) {
            if(currency === _currency) {
                const _totalamo = totalamo;
                const __altttamo = totalamo / _curreval;
                const str_totalamo = _precalc ? String.formatNumber.new(_totalamo) : _origin;
                const str_altttamo = String.formatNumber.new(__altttamo);
                balances = {
                    '00000014': { debitamo: _typevouc === 'TCSVO002' ? '0.00' : str_totalamo, assetamo: _typevouc === 'TCSVO002' ? str_totalamo : '0.00' },
                    '00000015': { debitamo: _typevouc === 'TCSVO002' ? '0.00' : str_altttamo, assetamo: _typevouc === 'TCSVO002' ? str_altttamo : '0.00' },
                }
            } else {
                const __altttamo = _precalc ? (Math.round((totalamo * curreval)*100)/100) : totalamo;

                // const totalFactura = _precalc ? totalamo : +acc_vouchdet[props.index]['pbl_purchase'].balanpay.replace(/,/g, '')
                // const newCurrency = Math.round(((Math.round((totalFactura * _curreval) / totalFactura*100)/100))*1000000)/1000000;

                let _totalamo = __altttamo / _curreval;
                const str_totalamo = String.formatNumber.new(_totalamo);
                const str_altttamo = _precalc ? String.formatNumber.new(__altttamo) : _origin;
                balances = {
                    '00000014': { debitamo: _typevouc === 'TCSVO002' ? '0.00' : str_altttamo, assetamo: _typevouc === 'TCSVO002' ? str_altttamo : '0.00' },
                    '00000015': { debitamo: _typevouc === 'TCSVO002' ? '0.00' : str_totalamo, assetamo: _typevouc === 'TCSVO002' ? str_totalamo : '0.00' },
                }
                _totalamo = Math.round((_totalamo)*100)/100
                const a = Math.round(__altttamo*100)/100;
                const b =  Math.round((_precalc ? totalamo*_curreval : Math.round(Math.round(_totalamo*_curreval*100)/100 / curreval *100)/100 * _curreval) *100)/100;
                _exchrate = Math.round((a - b)*100)/100;
                // _exchrate = Math.round(((Math.round(_totalamo*100)/100) - (Math.round((_precalc ? totalamo : (totalamo / curreval))*100)/100))*100)/100;
            }
        } else {
            if(currency === _currency) {
                const _totalamo = totalamo;
                const __altttamo = totalamo * _curreval;
                const str_totalamo = _precalc ? String.formatNumber.new(_totalamo) : _origin;
                const str_altttamo = String.formatNumber.new(__altttamo);
                balances = {
                    '00000014': { debitamo: _typevouc === 'TCSVO002' ? '0.00' : str_altttamo, assetamo: _typevouc === 'TCSVO002' ? str_altttamo : '0.00' },
                    '00000015': { debitamo: _typevouc === 'TCSVO002' ? '0.00' : str_totalamo, assetamo: _typevouc === 'TCSVO002' ? str_totalamo : '0.00' },
                }
                const a = Math.round(_totalamo*curreval*100)/100;
                const b =  Math.round(__altttamo*100)/100;
                _exchrate = Math.round((a - b)*100)/100;
            } else {
                const __altttamo = _precalc ? (Math.round((totalamo / _curreval)*100)/100) : totalamo;

                const totalFactura = _precalc ? totalamo : +acc_vouchdet[props.index]['pbl_purchase'].balanpay.replace(/,/g, '')
                const newCurrency = Math.round((totalFactura / (Math.round((totalFactura / _curreval)*100)/100))*1000000)/1000000;

                const _totalamo = __altttamo * newCurrency;
                const str_totalamo = String.formatNumber.new(_totalamo);
                const str_altttamo = _precalc ? String.formatNumber.new(__altttamo) : _origin;
                balances = {
                    '00000014': { debitamo: _typevouc === 'TCSVO002' ? '0.00' : str_totalamo, assetamo: _typevouc === 'TCSVO002' ? str_totalamo : '0.00' },
                    '00000015': { debitamo: _typevouc === 'TCSVO002' ? '0.00' : str_altttamo, assetamo: _typevouc === 'TCSVO002' ? str_altttamo : '0.00' },
                }
                totalamo = Math.round((totalamo)*100)/100
                const a = Math.round(_totalamo*100)/100;
                const b =  Math.round((_precalc ? Math.round(totalamo/curreval*100)/100 * _curreval : (Math.round(_totalamo/curreval*100)/100 * _curreval))*100)/100;
                _exchrate = Math.round((a - b)*100)/100;
                // _exchrate = Math.round(((Math.round((_precalc ? totalamo : (totalamo * curreval) - (Math.round(_totalamo*100)/100))*100)/100))*100)/100;
            }
        }

        if(_exchrate !== 0.00) {
            // const { idcsbank } = this.state.vouchersEntity;
            // acc_vouchdet[props.index]['exchrate'] = Math.abs(_exchrate);
            // acc_vouchdet[props.index]['diffsign'] = _exchrate > 0 ? '+' : '-';
            // acc_vouchdet[props.index]['defiacco'] = _exchrate > 0 ?  
            // csb_cashbank.find((x: any) => x.idcsbank === idcsbank).profiacc
            // :
            // csb_cashbank.find((x: any) => x.idcsbank === idcsbank).lostacco;
            // debugger;
        }
        acc_vouchdet[props.index]['balances'] = balances;
    }

    onEditCell = (props: any, value: any) => {
        const acc_vouchdet: any = this.state.vouchersEntity.acc_vouchdet;

        let curreval, currency, totalamo;
        if(props.field === 'balances') {
            curreval = 1;
            currency = acc_vouchdet[props.index]['currency'];
            this.calculateGrid(acc_vouchdet, props, value, curreval, currency, +value.replace(/,/g, ''), false, value);
        } else if(props.field === 'curreval') {
            curreval = 1;
            currency = acc_vouchdet[props.index]['currency'];
            const balances = acc_vouchdet[props.index]['balances'];
            let _value = +balances[currency].debitamo.replace(/,/g, '') + +balances[currency].assetamo.replace(/,/g, '');
            this.calculateGrid(acc_vouchdet, props, value, curreval, currency, _value, false, _value.toString());
        }

        if(props.field !== 'balances')
            acc_vouchdet[props.index][props.field] = value;

        const optotals = {
            '00000014': { debitamo: 0.00, assetamo: 0.00 },
            '00000015': { debitamo: 0.00, assetamo: 0.00 },
        };
        for (const iterator of acc_vouchdet) {
            const { balances } = iterator;
            optotals['00000014'].debitamo += +balances['00000014'].debitamo.replace(/,/g, '');
            optotals['00000014'].assetamo += +balances['00000014'].assetamo.replace(/,/g, '');
            optotals['00000015'].debitamo += +balances['00000015'].debitamo.replace(/,/g, '');
            optotals['00000015'].assetamo += +balances['00000015'].assetamo.replace(/,/g, '');
        }

        this.setState((state: FormVouchersState) => ({
            ...state,
            dateoper: props.field === 'dateoper' ? value : state.dateoper,
            vouchers: props.field === 'vouchers' ? value : state.vouchers,
            curreval: props.field === 'curreval' ? value : state.curreval,
            denomina: props.field === 'denomina' ? value : state.denomina,
            glosarys: props.field === 'glosarys' ? value : state.glosarys,
            vouchersEntity: {
                ...state.vouchersEntity,
                optotals,
                acc_vouchdet,
            }
        }))

        if(props.field === 'dateoper') {
            const callback = (i) => {
                const { tables } = this.props.generalReducers;
                if(tables.exchangeRate !== null) {
                    this.onEditCell(
                        {index: i, field: 'curreval' }, 
                        tables.exchangeRate.venta
                    )
                }
            };
            this.props.currencyExchangeRateRequest(moment(acc_vouchdet[props.index]['dateoper']).format('YYYY-MM-DD'), () => callback(props.index));
        }
    }

    onAddDetail = () => {
        const { acc_vouchdet, currency } = this.state.vouchersEntity;
        const { grl_definiti } = this.props.accountantReducers.tables;
        const itemnumb = this.state.itemnumb + 1;

        const newItem: any = {
            itemnumb: itemnumb,
            typevouc: grl_definiti.filter((x: any) => x.defitype === 'TCSVO')[0].deficode,
            accaccou: null,
            fiscyear: 0,
            denomina: this.state.denomina,
            currency: currency,
            dateoper: this.state.dateoper,
            curreval: this.state.curreval,
            balances: {
                '00000014': { debitamo: '0.00', assetamo: '0.00' },
                '00000015': { debitamo: '0.00', assetamo: '0.00' },
            },
            glosarys: this.state.glosarys,
            documnum: '',
            costcent: '',
            destacco: '',
            analitic: '',
            chargacc: null,
            credtacc: null,
            vouchers: this.state.vouchers
        };
        (acc_vouchdet as any).push(newItem);
        this.setState((state: any) => ({
            ...state,
            itemnumb,
            vouchersEntity: {
                ...state.vouchersEntity,
                acc_vouchdet,
            }
        }))
    }

    onDeleteDetail = () => {
        let { acc_vouchdet } = this.state.vouchersEntity;        
        acc_vouchdet = acc_vouchdet.filter((x: any) => x.select === false || x.select === undefined);

        const optotals = {
            '00000014': { debitamo: 0.00, assetamo: 0.00 },
            '00000015': { debitamo: 0.00, assetamo: 0.00 },
        };
        for (const iterator of acc_vouchdet) {
            const { balances } = iterator;
            optotals['00000014'].debitamo += +balances['00000014'].debitamo.replace(/,/g, '');
            optotals['00000014'].assetamo += +balances['00000014'].assetamo.replace(/,/g, '');
            optotals['00000015'].debitamo += +balances['00000015'].debitamo.replace(/,/g, '');
            optotals['00000015'].assetamo += +balances['00000015'].assetamo.replace(/,/g, '');
        }

        this.setState((state: FormVouchersState) => ({
            ...state,
            vouchersEntity: {
                ...state.vouchersEntity,
                optotals,
                acc_vouchdet,
            }
        }))
    }

    renderDetail = () => {
        const { grl_definiti, pbl_supplier, acc_planacco, pbl_purchase } = this.props.accountantReducers.tables;
        const { acc_vouchdet } = this.state.vouchersEntity;

        return acc_vouchdet.map((item: any, i: any) => (
            <tr key={ i } >
                <td style={{minWidth:'50px'}}>
                    <input type="checkbox"
                    checked={ item.select } 
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'select' }, 
                            e.target.checked
                        )}
                    />
                </td>
                <td style={{minWidth:'80px'}}>
                    <select style={{width:'100%'}} value={ item.typevouc } 
                        onChange={ (e: any) => { 
                            this.onEditCell({index: i, field: 'typevouc'}, e.target.value)
                        }}
                    >
                        { grl_definiti.filter((x: any) => x.defitype === 'TCSVO').map((item:any) => <option value={item.deficode} key={item.deficode}>{item.definame}</option>) }
                    </select>
                </td>
                <td style={{minWidth:'130px'}}>
                    <Autocomplete
                        id="accaccou"
                        freeSolo
                        options={acc_planacco}
                        getOptionLabel={ (x: any) => x.accaccou }
                        value={ acc_planacco.find((x: any) => x.accaccou === item.accaccou) || null }
                        onChange={(e: any, value) => {
                            console.log(value);
                            this.onEditCell({index: i, field: 'accaccou'}, value.accaccou);
                            this.onEditCell({index: i, field: 'fiscyear'}, value.fiscyear);
                            // this.onEditCell({index: i, field: 'denomina'}, value.denomina);
                            this.onEditCell({index: i, field: 'chargacc'}, value.chargacc);
                            this.onEditCell({index: i, field: 'credtacc'}, value.credtacc);
                        }}
                        renderInput={(params) => (
                            <TextField {...params} 
                            onBlur={(e) => { 
                                const a = acc_planacco.find((x: any) => x.accaccou === e.target.value);
                                if(a) {
                                    this.onEditCell({index: i, field: 'accaccou'}, a.accaccou);
                                    this.onEditCell({index: i, field: 'fiscyear'}, a.fiscyear);
                                    // this.onEditCell({index: i, field: 'denomina'}, value.denomina);
                                    this.onEditCell({index: i, field: 'chargacc'}, a.chargacc);
                                    this.onEditCell({index: i, field: 'credtacc'}, a.credtacc);
                                }
                            }}
                            style={ { marginTop: 0 } } margin="normal" variant="outlined" />
                        )}
                    />
                </td>
                <td style={{minWidth:'150px'}}>
                    <input type="text"
                    value={ item.denomina } 
                    style={{ textAlign: 'left' }}
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'denomina' }, 
                            e.target.value
                        )}
                    />
                </td>
                <td style={{minWidth:'70px'}}>
                    <select style={{width:'100%'}} value={ item.currency } 
                        onChange={ (e: any) => { 
                            this.onEditCell({index: i, field: 'currency'}, e.target.value)
                        }}
                    >
                        { grl_definiti.filter((x: any) => x.defitype === 'CURCY').map((item:any) => <option value={item.deficode} key={item.deficode}>{item.definame}</option>) }
                    </select>
                </td>
                <td style={{minWidth:'120px'}}>
                    <input type="date"
                    value={ item.dateoper } 
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'dateoper' }, 
                            e.target.value
                        )}
                    />
                </td>
                <td style={{width:'50px'}}>
                    <input type="text"
                    value={ item.curreval } 
                    onFocus={(e) => e.target.select() }
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'curreval' }, 
                            e.target.value
                        )}
                    style={{ textAlign: 'right' }}
                    />
                </td>
                <td className={ item.typevouc !== 'TCSVO001' ? "disabled" :  item.currency !== '00000014' ? 'disabled' : '' } style={{minWidth:'70px'}}>
                    <input type="text"
                    value={ item.balances['00000014'].debitamo } 
                    onFocus={(e) => e.target.select() }
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'balances' },
                            e.target.value 
                        )}
                    style={{ textAlign: 'right' }}
                    disabled={ item.typevouc !== 'TCSVO001' ? true : item.currency !== '00000014' ? true : false }
                    />
                </td>
                <td className={ item.typevouc !== 'TCSVO002' ? "disabled" : item.currency !== '00000014' ? 'disabled' : '' } style={{minWidth:'70px'}}>
                    <input type="text"
                    value={ item.balances['00000014'].assetamo } 
                    onFocus={(e) => e.target.select() }
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'balances' }, 
                            e.target.value 
                        )}
                    style={{ textAlign: 'right' }}
                    disabled={ item.typevouc !== 'TCSVO002' ? true : item.currency !== '00000014' ? true : false  }
                    />
                </td>
                <td className={ item.typevouc !== 'TCSVO001' ? "disabled" : item.currency !== '00000015' ? 'disabled' : '' } style={{minWidth:'70px'}}>
                    <input type="text"
                    value={ item.balances['00000015'].debitamo } 
                    onFocus={(e) => e.target.select() }
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'balances' }, 
                            e.target.value 
                        )}
                    style={{ textAlign: 'right' }}
                    disabled={ item.typevouc !== 'TCSVO001' ? true : item.currency !== '00000015' ? true : false  }
                    />
                </td>
                <td className={ item.typevouc !== 'TCSVO002' ? "disabled" : item.currency !== '00000015' ? 'disabled' : '' } style={{minWidth:'70px'}}>
                    <input type="text"
                    value={ item.balances['00000015'].assetamo } 
                    onFocus={(e) => e.target.select() }
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'balances' }, 
                            e.target.value
                        )}
                    style={{ textAlign: 'right' }}
                    disabled={ item.typevouc !== 'TCSVO002' ? true : item.currency !== '00000015' ? true : false }
                    />
                </td>
                <td style={{minWidth:'150px'}}>
                    <input type="text"
                    value={ item.glosarys } 
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'glosarys' }, 
                            e.target.value
                        )}
                    style={{ textAlign: 'left' }}
                    />
                </td>
                <td style={{width:'50px'}}>
                    <input type="text"
                    value={ item.documnum } 
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'documnum' }, 
                            e.target.value
                        )}
                    style={{ textAlign: 'left' }}
                    />
                </td>
                <td style={{width:'50px'}}>
                    <input type="text"
                    value={ item.costcent } 
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'costcent' }, 
                            e.target.value
                        )}
                    style={{ textAlign: 'left' }}
                    />
                </td>
                <td style={{width:'50px'}}>
                    <input type="text"
                    value={ item.destacco } 
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'destacco' }, 
                            e.target.value
                        )}
                    style={{ textAlign: 'left' }}
                    />
                </td>
                <td style={{width:'50px'}}>
                    <input type="text"
                    value={ item.analitic } 
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'analitic' }, 
                            e.target.value
                        )}
                    style={{ textAlign: 'left' }}
                    />
                </td>
                <td className="disabled" style={{width:'50px'}}>
                    <input type="text"
                    value={ item.chargacc  || '' } 
                    style={{ textAlign: 'left' }}
                    disabled
                    />
                </td>
                <td className="disabled" style={{width:'50px'}}>
                    <input type="text"
                    value={ item.credtacc || '' } 
                    style={{ textAlign: 'left' }}
                    disabled
                    />
                </td>
                <td style={{width:'50px'}}>
                    <input type="text"
                    value={ item.vouchers } 
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'vouchers' }, 
                            e.target.value
                        )}
                    style={{ textAlign: 'left' }}
                    />
                </td>
            </tr>
        ))
    }

    renderGrilla = () => {
        return (
            <div className="form">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className="form__field">
                            <div className="grid__actions">
                                <button className="add" onClick={ (e) => { e.preventDefault(); this.onAddDetail() } }><i className="fas fa-plus"/></button>
                                <button className="remove" onClick={ (e) => { e.preventDefault(); this.onDeleteDetail() } }><i className="fas fa-times"/></button>
                                <span style={ { marginLeft: '15px' } }>[ENTER]: Agregar nuevo item</span>
                                <span style={ { marginLeft: '15px' } }>[TAB]: Navegar entre las celdas</span>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <EditableCell
                            header={ header }
                            data={ this.renderDetail() }
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }

    componentDidMount() {
        const { params } = this.props.match;
        if (params.correlat !== undefined) {
            this.setState({title: 'Editar Movimientos'});
            this.props.vouchersInitUpdateFormRequest(params.correlat, this.callbackInitUpdate);
        } else {
            this.props.vouchersInitFormRequest(this.props.dashboardReducers.currentFiscyear, this.callbackInit);
        }
    }

    render() {

        const { vouchersEntity, title, snackbar, error } = this.state;
        const { loading, list, tables, failed, failedTitle, failedContent } = this.props.accountantReducers;
        const { classes } = this.props;

        return (
            <div style={{overflow: 'auto', height: '100%'}}>
                <Paper className='form__container'>
                    <br/>
                    <Typography variant='h5'>{ title }</Typography>
                    <br/>
                    { failed && <MessageBox title={failedTitle} content={failedContent}/> }
                    { error.state && <MessageBox title='Error' content={error.message as string}/> }
                    <form noValidate autoComplete="off">
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                        <InputLabel id="yearexer">Ejercicio - Año</InputLabel>
                                        <Select
                                            labelId="lbl_año"
                                            id="year"
                                            className='select'
                                            label="Año"
                                            fullWidth
                                            value={ vouchersEntity.yearexer || '' }
                                            onChange={(e: any) => this.onChangeVouchersRoot({'yearexer': e.target.value})}
                                        >
                                            <MenuItem value="2019">2019</MenuItem>
                                            <MenuItem value="2020">2020</MenuItem>
                                            <MenuItem value="2021">2021</MenuItem>
                                            <MenuItem value="2022">2022</MenuItem>
                                            <MenuItem value="2023">2023</MenuItem>
                                            <MenuItem value="2024">2024</MenuItem>
                                        </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                        <InputLabel id="montexer">Ejercicio - Mes</InputLabel>
                                        <Select
                                            labelId="lbl_mes"
                                            id="month"
                                            className='select'
                                            label="Mes"
                                            fullWidth
                                            value={ vouchersEntity.montexer || '' }
                                            onChange={(e: any) => this.onChangeVouchersRoot({'montexer': e.target.value})}
                                        >
                                            {
                                                meses.map((x: any) => 
                                                    <MenuItem value={x.key} key={x.key}>{x.value}</MenuItem>
                                                )
                                            }
                                        </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                    <InputLabel id="lbl_moneda">Moneda</InputLabel>
                                    <Select
                                        labelId="lbl_moneda"
                                        id="currency"
                                        className='select'
                                        label="Tipo de moneda"
                                        fullWidth
                                        value={vouchersEntity.currency}
                                        onChange={(e: any) => this.onChangeVouchersRoot({'currency': e.target.value, 'acc_vouchdet': []})}
                                    >
                                        {
                                            tables.grl_definiti.filter((x: any) => x.defitype === 'CURCY').map((item: any, key: any) => 
                                                <MenuItem value={item.deficode} key={`moneda_${key}`}>{item.definame}</MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                    <InputLabel id="lbl_subdiario">Subdiario</InputLabel>
                                    <Select
                                        labelId="lbl_subdiario"
                                        id="subdiary"
                                        className='select'
                                        label="Subdiario"
                                        fullWidth
                                        value={vouchersEntity.subdiary}
                                        onChange={(e: any) => this.onChangeVouchersRoot({'subdiary': e.target.value})}
                                        disabled
                                    >
                                        {
                                            tables.grl_definiti.filter((x: any) => x.defitype === 'BOKCO').map((item: any, key: any) => 
                                                <MenuItem value={item.deficode} key={`subdiario_${key}`}>{item.definame}</MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            {/* <Grid item xs={3}>
                                <TextField fullWidth type="date" id="dateoper" label="Fecha de operacion" variant="outlined" value={ vouchersEntity.dateoper } onChange={(e: any) => this.onChangeVouchersRoot({'dateoper': e.target.value})}/>
                            </Grid> */}
                        </Grid>
                        <div style={{ height: 50 }}/>
                        <Grid container spacing={2} >
                            { this.renderGrilla()  }
                        </Grid>
                        <div style={{ height: 50 }}/>
                        <Grid container spacing={2} justify="center">
                            <Grid item xs={1}>
                                <TextField fullWidth id="00000014debitamo" label="T. Debe Soles" className="txt_total" variant="outlined" value={ String.formatNumber.new(vouchersEntity.optotals['00000014'].debitamo) }/>
                            </Grid>
                            <Grid item xs={1}>
                                <TextField fullWidth id="00000014assetamo" label="T. Haber Soles" className="txt_total" variant="outlined" value={ String.formatNumber.new(vouchersEntity.optotals['00000014'].assetamo) }/>
                            </Grid>
                            <Grid item xs={1}>
                                <TextField fullWidth id="00000015debitamo" label="T. Debe Dolar" className="txt_total" variant="outlined" value={ String.formatNumber.new(vouchersEntity.optotals['00000015'].debitamo) }/>
                            </Grid>
                            <Grid item xs={1}>
                                <TextField fullWidth id="00000015assetamo" label="T. Haber Dolar" className="txt_total" variant="outlined" value={ String.formatNumber.new(vouchersEntity.optotals['00000015'].assetamo) }/>
                            </Grid>
                        </Grid>
                    </form>
                    <br/>
                    <FormFooter 
                        onCancel={ this.onCancel } 
                        onSubmitContinue={ this.onSubmitContinue } 
                        onSubmit={ this.onSubmit }/>
                </Paper>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={snackbar}
                    onClose={() => this.setState({snackbar: false})}
                    autoHideDuration={6000}
                >
                    <Alert onClose={() => this.setState({snackbar: false})} severity="success">
                            Datos Guardados Correctamente
                    </Alert>
                </Snackbar>
                <ScreenLoader enabled={loading}/>
            </div>
        );
    }
}

const accountantReducers = 'accountantReducers';
const dashboardReducers = 'dashboardReducers';
const generalReducers = 'generalReducers';

const mapStateToProps = (state: any) => ({
    accountantReducers: state.get(accountantReducers),
    dashboardReducers: state.get(dashboardReducers),
    generalReducers: state.get(generalReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        vouchersInitFormRequest,
        vouchersInitUpdateFormRequest,
        vouchersInsertRequest,
        vouchersUpdateRequest,
        currencyExchangeRateRequest
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(FormVouchers));