import React, { useEffect } from 'react';
import { AppBar, createStyles, CssBaseline, IconButton, makeStyles, Menu, MenuItem, Theme, Toolbar, Typography } from '@material-ui/core';
// import Context from './context';
import DrawerDashboard from './drawer';
import { connect } from 'react-redux';
// import MenuBar from 'penta-components/application/menuBar';
import history from '../../utils/history';
import { bindActionCreators, Dispatch } from 'redux';
import { authLogoutRequest } from 'redux/actions/authActions';
import { createUseStyles } from 'react-jss';
import iconLogo from 'penta-assets/images/PentaErpLogo2.png';
import Breadcrumb from './breadcrumb';
import Search from 'penta-components/controls/search';
import CountryPicker from 'penta-components/controls/countryPicker';
import Separator from 'penta-components/base/separator';
import NotificationBell from 'penta-components/base/notificationBell';
import Help from 'penta-components/base/help';
import Profile from 'penta-components/application/profile';
import Menubar from './menubar';
import Main from './main';

const useStyles = createUseStyles({
    container: {
        'backgroundColor': '#E8E8E8',
        'height': '100%',
        'width': '100%',
        'display': 'flex',
        'flexDirection': 'column'
    },
    header: {
        'display': 'flex',
    },
    headerMiddle: {
        'display': 'flex',
        'padding': '0.625rem 2.5rem',
        'alignItems': 'center',
        'flex': 1
    },
    headerEnd: {
        'display': 'flex',
        'padding': '0.625rem 3.75rem',
        'alignItems': 'center',
    },
    iconDiv: {
        'padding': '0.625rem 1.25rem'
    },
    iconLogo: {
        width: '3.125rem',
        height: '3.031rem'
    },
    body: {
        'display': 'flex',
        'flex': '1 1 auto',
        height: '1rem'
    },
    footer: {
        'display': 'flex',
        flexDirection: 'row-reverse',
        padding: '1.25rem 3.75rem',
        '& p': {
            fontSize: '0.75rem',
            fontWeight: 400,
            textTransform: 'uppercase',
            fontFamily: '"Work Sans", sans-serif'
        }
    }
});

interface Container {
    children: React.ReactNode
    dashboardReducers: any
    authLogoutRequest: typeof authLogoutRequest
}

const Container = (props: Container) => {
    const classes = useStyles();
    const [mount, setMount] = React.useState(false);
    const [open, setOpen] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [openModal, setOpenModal] = React.useState(false);
    const [company, setCompany] = React.useState(null);
    const [division, setDivision] = React.useState(null);
    const [office, setOffice] = React.useState(null);

    // const isMenuOpen = Boolean(anchorEl);

    // const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    //     setAnchorEl(event.currentTarget);
    // };

    // const handleMenuClose = () => {
    //     setAnchorEl(null);
    // };

    // const handleDrawer = () => {
    //     setOpen(!open);
    // };

    useEffect(() => {
        try {
            const { dashboardReducers } = props;
            const tables = JSON.parse(localStorage.getItem('tables'));
            const company = tables.companys.find((x: any) => x.taxident === dashboardReducers.currentCompany);
            const division = company.lst_grl_division.find((x: any) => x.divicode === dashboardReducers.currentDivision);
            const office = division.lst_grl_bussofic.find((x: any) => x.officode === dashboardReducers.currentBussOfic);
            setCompany(company);
            setDivision(division);
            setOffice(office);
        } catch { }
    }, [props.dashboardReducers]);

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <div className={classes.iconDiv}><img className={classes.iconLogo} src={iconLogo}/></div>
                <div className={classes.headerMiddle}>
                    <Breadcrumb/>
                </div>
                <div className={classes.headerEnd}>
                    <Search styleInput={{borderRadius: '1.563rem', border: 'none'}}/><Separator marginRight='1.563rem'/>
                    <CountryPicker/><Separator marginRight='1.563rem'/>
                    <NotificationBell/><Separator marginRight='1.563rem'/>
                    <Help/><Separator marginRight='1.563rem'/>
                    <Profile/>
                </div>
            </div>
            <div className={classes.body}>
                <Menubar/>
                <Main>
                    {
                        props.children
                    }
                </Main>
            </div>
            <div className={classes.footer}>
                {
                    <p><b>contexto: </b> {(company?.bussname || 'ninguno') + ' - ' + (division?.diviname  || 'ninguno') + ' - ' + (office?.offiname || 'ninguno' )}</p>
                }
            </div>
        </div>
    );
}

const dashboardReducers = 'dashboardReducers';

const mapStateToProps = (state: any) => ({
    dashboardReducers: state.get(dashboardReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        authLogoutRequest,
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(Container);