const serverUrl = process.env.PENTA_MASTER_SERVER_URL || `https://api.${window.location.hostname}/api/`;

export const CASH_BANKS_INIT_FORM = serverUrl + 'finance/cash-banks/init-form/';
export const CASH_BANKS_INIT_UPDATE_FORM = serverUrl + 'finance/cash-banks/init-update-form/';
export const CASH_BANKS_GET_ALL_RECORDS = serverUrl + 'finance/cash-banks/get-all-records';
export const CASH_BANKS_INSERT = serverUrl + 'finance/cash-banks/insert';
export const CASH_BANKS_UPDATE = serverUrl + 'finance/cash-banks/update/';
export const CASH_BANKS_DELETE = serverUrl + 'finance/cash-banks/delete?';

export const CASH_BANKS_MOVEMENTS_INIT_FORM = serverUrl + 'finance/cash-banks-movements/init-form/';
export const CASH_BANKS_MOVEMENTS_INIT_UPDATE_FORM = serverUrl + 'finance/cash-banks-movements/init-update-form/';
export const CASH_BANKS_MOVEMENTS_GET_ALL_RECORDS = serverUrl + 'finance/cash-banks-movements/get-all-records';
export const CASH_BANKS_MOVEMENTS_INSERT = serverUrl + 'finance/cash-banks-movements/insert';
export const CASH_BANKS_MOVEMENTS_UPDATE = serverUrl + 'finance/cash-banks-movements/update/';
export const CASH_BANKS_MOVEMENTS_DELETE = serverUrl + 'finance/cash-banks-movements/delete?';

export const CHECKS_INIT_FORM = serverUrl + 'finance/checks/init-form/';
export const CHECKS_INIT_UPDATE_FORM = serverUrl + 'finance/checks/init-update-form/';
export const CHECKS_GET_ALL_RECORDS = serverUrl + 'finance/checks/get-all-records';
export const CHECKS_INSERT = serverUrl + 'finance/checks/insert';
export const CHECKS_UPDATE = serverUrl + 'finance/checks/update/';
export const CHECKS_DELETE = serverUrl + 'finance/checks/delete?';

export const CHECKS_BALANCE = serverUrl + 'finance/reports/checks-balance/';
export const CHECKS_ISSUED_SUMMARY = serverUrl + 'finance/reports/checks-issued-summary/';