import {
    call, fork, put, take, takeEvery, all
} from 'redux-saga/effects';
import history from '../../utils/history';
import pipelines from 'services';
import { 
    salesDeleteRequestFailed,
    salesGetAllRecordsRequestFailed,
    salesGetAllRecordsRequestSuccess,
    salesInitFormRequestFailed,
    salesInitFormRequestSuccess,
    salesInitUpdateFormRequestFailed,
    salesInitUpdateFormRequestSuccess,
    salesInsertRequestFailed,
    salesInsertRequestSuccess,
    salesMassiveUpdateRequestFailed,
    salesUpdateRequestFailed,
    customersDeleteRequestFailed,
    customersGetAllRecordsRequestFailed,
    customersGetAllRecordsRequestSuccess,
    customersInitFormRequestFailed,
    customersInitFormRequestSuccess, 
    customersInitUpdateFormRequestFailed, 
    customersInitUpdateFormRequestSuccess,
    customersInsertRequestFailed,
    customersInsertRequestSuccess,
    customersUpdateRequestFailed,
    salesDetailedDiaryBookRequestSuccess,
    salesDetailedDiaryBookRequestFailed,
    kardexRequestFailed,
    kardexRequestSuccess,
    invoicesChargeRequestSuccess,
    invoicesChargeRequestFailed
} from 'penta-redux/actions/salesActions';
import { saleEntity, customerEntity } from 'models/salesModels';
import { 
    SALES_ANULATE_REQUEST,
    SALES_DELETE_REQUEST,
    SALES_GET_ALL_RECORDS_REQUEST,
    SALES_INIT_FORM_REQUEST,
    SALES_INIT_UPDATE_FORM_REQUEST,
    SALES_INSERT_REQUEST,
    SALES_MASSIVE_UPDATE_REQUEST,
    SALES_UPDATE_REQUEST,
    CUSTOMERS_DELETE_REQUEST,
    CUSTOMERS_GET_ALL_RECORDS_REQUEST, 
    CUSTOMERS_INIT_FORM_REQUEST,
    CUSTOMERS_INIT_UPDATE_FORM_REQUEST,
    CUSTOMERS_INSERT_REQUEST,
    CUSTOMERS_UPDATE_REQUEST,
    SALES_DETAILED_DIARY_BOOK_REQUEST,
    KARDEX_REQUEST,
    INVOICES_CHARGE_REQUEST
} from 'penta-redux/constants/salesConstants';
import { locationsGetProvincesSuccess } from 'penta-redux/actions/generalActions';

//#region CUSTOMERS

function* customersInitFormSaga(action: any) {
    try {
        const data = (yield call(pipelines.salesPipeline.customersInitForm, action.fiscyear)).data;
        yield put(customersInitFormRequestSuccess(data.data.tables));
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(customersInitFormRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* customersInitUpdateFormSaga(action: any) {
    try {
        const data = (yield call(pipelines.salesPipeline.customersInitUpdateForm, action.docnumbe)).data;
        yield put(customersInitUpdateFormRequestSuccess(data.data.tables, data.data.onlyOne));
        yield put(locationsGetProvincesSuccess(data.data.tables));
        yield put(locationsGetProvincesSuccess(data.data.tables));
        yield call(action.callback, data.data.onlyOne, data.data.tables.provincias[0].ploccode, data.data.tables.distritos[0].ploccode);
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(customersInitUpdateFormRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* customersGetAllRecordsSaga(action: any) {
    try {
        const data = (yield call(pipelines.salesPipeline.customersGetAllRecords, action.body)).data;
        yield put(customersGetAllRecordsRequestSuccess(data.data.list, data.data.total));
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(customersGetAllRecordsRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* customersInsertSaga(action: any) {
    try {
        yield call(pipelines.salesPipeline.customersInsert, action.body);
        if (action.callback !== null) {
            yield call(action.callback);
            yield put(customersInsertRequestSuccess());
        }
        else
            yield history.push('/papp/sales/clientes');
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(customersInsertRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* customersUpdateSaga(action: any) {
    try {
        const data = (yield call(pipelines.salesPipeline.customersUpdate, (action.body as customerEntity).docnumbe, action.body)).data;
        yield history.push('/papp/sales/clientes');
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(customersUpdateRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* customersDeleteSaga(action: any) {
    try {
        const data = (yield call(pipelines.salesPipeline.customersDelete, action.docnumbes)).data;
        yield put({ type: CUSTOMERS_GET_ALL_RECORDS_REQUEST, body: {} });
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(customersDeleteRequestFailed(`Error: ${data.code}`, data.message));
    }
}

//#endregion

//#region SALES

function* salesInitFormSaga(action: any) {
    try {
        const data = (yield call(pipelines.salesPipeline.salesInitForm)).data;
        yield put(salesInitFormRequestSuccess(data.data.tables));
        yield call(action.callback, data.data.tables);
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(salesInitFormRequestFailed(`Error: ${err.code || '0x0'}`, err.message || err));
    }
}

function* salesInitUpdateFormSaga(action: any) {
    try {
        const data = (yield call(pipelines.salesPipeline.salesInitUpdateForm, action.serialnu, action.documnum, action.docsuppl)).data;
        yield put(salesInitUpdateFormRequestSuccess(data.data.tables, data.data.onlyOne));
        yield call(action.callback, data.data.onlyOne);
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(salesInitUpdateFormRequestFailed(`Error: ${err.code || '0x0'}`, err.message || err));
    }
}

function* salesGetAllRecordsSaga(action: any) {
    try {
        const data = (yield call(pipelines.salesPipeline.salesGetAllRecords, action.body)).data;
        yield put(salesGetAllRecordsRequestSuccess(data.data.list, data.data.total));
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(salesGetAllRecordsRequestFailed(`Error: ${err.code || '0x0'}`, err.message || err));
    }
}

function* salesInsertSaga(action: any) {
    try {
        yield call(pipelines.salesPipeline.salesInsert, action.body);
        if (action.callback !== null) {
            yield call(action.callback);
            yield put(salesInsertRequestSuccess());
        }
        else
            yield history.push('/papp/sales/ventas-oficina');
    } catch (error) {
        console.log(error.response);
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(salesInsertRequestFailed(`Error: ${err.code || '0x0'}`, err.message || err));
    }
}

function* salesUpdateSaga(action: any) {
    try {
        const data = (yield call(pipelines.salesPipeline.salesUpdate, (action.body as saleEntity).serialnu, (action.body as saleEntity).documnum, (action.body as saleEntity).docnumbe, action.body)).data;
        yield history.push('/papp/sales/ventas-oficina');
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(salesUpdateRequestFailed(`Error: ${err.code || '0x0'}`, err.message || err));
    }
}

function* salesDeleteSaga(action: any) {
    try {
        const data = (yield call(pipelines.salesPipeline.salesDelete, action.serialnus, action.documnums, action.docnumbes)).data;
        yield put({ type: SALES_GET_ALL_RECORDS_REQUEST, body: action.filters });
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(salesDeleteRequestFailed(`Error: ${err.code || '0x0'}`, err.message || err));
    }
}

function* salesAnulateSaga(action: any) {
    try {
        const data = (yield call(pipelines.salesPipeline.salesAnulate, action.serialnus, action.documnums, action.docnumbes)).data;
        yield put({ type: SALES_GET_ALL_RECORDS_REQUEST, body: {} });
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(salesDeleteRequestFailed(`Error: ${err.code || '0x0'}`, err.message || err));
    }
}

function* salesMassiveUpdateSaga(action: any) {
    try {
        for (const iterator of action.body) {
            try {
                yield call(pipelines.salesPipeline.salesInsert, iterator);
            } catch { }
            yield call((async (ms) => { return new Promise(resolve => setTimeout(resolve, ms)) }), 1000)
        }
        // yield history.push('/papp/sales/compras');
        yield put({ type: SALES_GET_ALL_RECORDS_REQUEST, body: {} });
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(salesMassiveUpdateRequestFailed(`Error: ${err.code || '0x0'}`, err.message || err));
    }
}

//#endregion

//#region REPORTS

function* saledDetailedDiaryBookSaga(action: any) {
    try {
        const data = (yield call(pipelines.salesPipeline.salesDetailedDiaryBook, action.year, action.month)).data;
        yield put(salesDetailedDiaryBookRequestSuccess(data.data.onlyOne));
        yield call(action.callback);
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(salesDetailedDiaryBookRequestFailed(`Error: ${err.code}`, err.message));
    }
}

function* kardexSaga(action: any) {
    try {
        const data = (yield call(pipelines.salesPipeline.kardex, action.skucodee, action.year, action.month)).data;
        yield put(kardexRequestSuccess(data.data.onlyOne));
        yield call(action.callback);
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(kardexRequestFailed(`Error: ${err.code}`, err.message));
    }
}

function* invoicesChargeSaga(action: any) {
    try {
        const data = (yield call(pipelines.salesPipeline.invoicesCharge, action.initdate, action.endidate)).data;
        yield put(invoicesChargeRequestSuccess(data.data.onlyOne));
        yield call(action.callback);
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(invoicesChargeRequestFailed(`Error: ${err.code}`, err.message));
    }
}

//#endregion

function* salesRootSaga() {
    yield all([
        takeEvery(CUSTOMERS_INIT_FORM_REQUEST, customersInitFormSaga),
        takeEvery(CUSTOMERS_INIT_UPDATE_FORM_REQUEST, customersInitUpdateFormSaga),
        takeEvery(CUSTOMERS_GET_ALL_RECORDS_REQUEST, customersGetAllRecordsSaga),
        takeEvery(CUSTOMERS_INSERT_REQUEST, customersInsertSaga),
        takeEvery(CUSTOMERS_UPDATE_REQUEST, customersUpdateSaga),
        takeEvery(CUSTOMERS_DELETE_REQUEST, customersDeleteSaga),
        takeEvery(SALES_INIT_FORM_REQUEST, salesInitFormSaga),
        takeEvery(SALES_INIT_UPDATE_FORM_REQUEST, salesInitUpdateFormSaga),
        takeEvery(SALES_GET_ALL_RECORDS_REQUEST, salesGetAllRecordsSaga),
        takeEvery(SALES_INSERT_REQUEST, salesInsertSaga),
        takeEvery(SALES_UPDATE_REQUEST, salesUpdateSaga),
        takeEvery(SALES_DELETE_REQUEST, salesDeleteSaga),
        takeEvery(SALES_ANULATE_REQUEST, salesAnulateSaga),
        takeEvery(SALES_MASSIVE_UPDATE_REQUEST, salesMassiveUpdateSaga),
        takeEvery(SALES_DETAILED_DIARY_BOOK_REQUEST, saledDetailedDiaryBookSaga),
        takeEvery(KARDEX_REQUEST, kardexSaga),
        takeEvery(INVOICES_CHARGE_REQUEST, invoicesChargeSaga)
    ]);
}
  
const salesRootSagas = [
    fork(salesRootSaga),
];
  
export default salesRootSagas;