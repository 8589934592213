import React from 'react';
import { createStyles, Grid, Paper, Snackbar, TextField, Theme, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { categorysInitFormRequest, categorysInitUpdateFormRequest, categorysInsertRequest, categorysUpdateRequest } from 'penta-redux/actions/wharehouseProductsActions';
import history from '../../../utils/history';
import './categorys.scss'
import FormFooter from 'penta-components/base/formFooter';
import { MessageBox } from 'penta-components/base/messageBox';
import { categoryEntity } from 'models/wharehouseProductsModels';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Alert } from '@material-ui/lab';
import ScreenLoader from 'penta-components/base/screenLoader';

const useStyles = (theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        formControl: {
            margin: 0,
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    });

const defaultcategorysEntity: categoryEntity = {
    ctgrcode: '',
    ctgrname: '',
    purcacca: '',
    saleacca: '',
    fiscyear: +localStorage.getItem('currentFiscyear')
}

interface FormcategorysState {
    categorysEntity: categoryEntity
    title: string
    snackbar: boolean
}

class FormCategorys extends React.Component<any, FormcategorysState> {

    constructor(props: any) {
        super(props);
        this.state = {
            categorysEntity: defaultcategorysEntity,
            title: 'Agregar Categorias',
            snackbar: false
        }
    }

    onChangeRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            ...value
        }))
    }

    onChangecategorysRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            categorysEntity: {
                ...state.categorysEntity,
                ...value
            }
        }))
    }

    onCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        console.log('cancel')
        history.push('/papp/products/categorias');
    }

    onContinueCallback = () => {
        const newCategorysEntity = defaultcategorysEntity;
        this.setState({
            categorysEntity: newCategorysEntity,
            snackbar: true
        });
    }

    onSubmitContinue = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const { params } = this.props.match;
        if (params.ctgrcode !== undefined) {
            this.props.categorysUpdateRequest(this.state.categorysEntity);
        } else {
            this.props.categorysInsertRequest(this.state.categorysEntity, this.onContinueCallback);
        }
    }

    onSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const { params } = this.props.match;
        if (params.ctgrcode !== undefined) {
            this.props.categorysUpdateRequest(this.state.categorysEntity);
        } else {
            this.props.categorysInsertRequest(this.state.categorysEntity, null);
        }
    }

    callbackInitUpdate = (categorysEntity: categoryEntity) => {
        this.setState({
            categorysEntity
        });
    }

    componentDidMount() {
        const { params } = this.props.match;
        if (params.ctgrcode !== undefined) {
            this.setState({title: 'Editar Categorias'});
            this.props.categorysInitUpdateFormRequest(params.ctgrcode, params.fiscyear, this.callbackInitUpdate);
        }
        else {
            this.props.categorysInitFormRequest(this.props.dashboardReducers.currentFiscyear);
        }
    }

    render() {

        const { categorysEntity, title, snackbar } = this.state;
        const { loading, list, tables, failed, failedTitle, failedContent } = this.props.wharehouseProductsReducers;
        const { classes } = this.props;

        return (
            <div style={{overflow: 'auto', height: '100%'}}>
                <Paper className='form__container'>
                    <br/>
                    <Typography variant='h5'>{ title }</Typography>
                    <br/>
                    { failed && <MessageBox title={failedTitle} content={failedContent}/> }
                    <form noValidate autoComplete="off">
                        <Grid container spacing={2} >
                            <Grid item xs={4}>
                                <TextField fullWidth id="ctgrcode" label="Codigo" variant="outlined" value={ categorysEntity.ctgrcode } onChange={(e: any) => this.onChangecategorysRoot({'ctgrcode': e.target.value})}/>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth id="ctgrname" label="Nombre" variant="outlined" value={ categorysEntity.ctgrname } onChange={(e: any) => this.onChangecategorysRoot({'ctgrname': e.target.value})}/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} >
                            <Grid item xs={4}>
                                <Autocomplete
                                    id="purcacca"
                                    freeSolo
                                    options={tables.planAccount}
                                    getOptionLabel={ (x: any) => x.accaccou }
                                    value={ tables.planAccount.find((x: any) => x.accaccou === categorysEntity.purcacca) || {accaccou: '', fiscyear: ''}  }
                                    onChange={(e: any, value) => {
                                        this.onChangecategorysRoot({'purcacca': value?.accaccou})
                                        this.onChangecategorysRoot({'fiscyear': value?.fiscyear})
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} 
                                        style={ { marginTop: 0 } } label="Cta Contable compras" margin="normal" variant="outlined" />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Autocomplete
                                    id="saleacca"
                                    freeSolo
                                    options={tables.planAccount}
                                    getOptionLabel={ (x: any) => x.accaccou }
                                    value={ tables.planAccount.find((x: any) => x.accaccou === categorysEntity.saleacca) || {accaccou: '', fiscyear: ''}  }
                                    onChange={(e: any, value) => {
                                        this.onChangecategorysRoot({'saleacca': value?.accaccou})
                                        this.onChangecategorysRoot({'fiscyear': value?.fiscyear})
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} 
                                        style={ { marginTop: 0 } } label="Cta Contable ventas" margin="normal" variant="outlined" />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </form>
                    <br/>
                    <FormFooter 
                        onCancel={ this.onCancel } 
                        onSubmitContinue={ this.onSubmitContinue } 
                        onSubmit={ this.onSubmit }/>
                </Paper>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={snackbar}
                    onClose={() => this.setState({snackbar: false})}
                    autoHideDuration={6000}
                >
                    <Alert onClose={() => this.setState({snackbar: false})} severity="success">
                            Datos Guardados Correctamente
                    </Alert>
                </Snackbar>
                <ScreenLoader enabled={loading}/>
            </div>
        );
    }
}

const wharehouseProductsReducers = 'wharehouseProductsReducers';
const dashboardReducers = 'dashboardReducers';

const mapStateToProps = (state: any) => ({
    wharehouseProductsReducers: state.get(wharehouseProductsReducers),
    dashboardReducers: state.get(dashboardReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        categorysInitFormRequest,
        categorysInitUpdateFormRequest,
        categorysInsertRequest,
        categorysUpdateRequest
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(FormCategorys));