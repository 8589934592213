import {
    CUSTOMERS_ACTION_FAILED,
    CUSTOMERS_DELETE_REQUEST,
    CUSTOMERS_DELETE_REQUEST_FAILED,
    CUSTOMERS_DELETE_REQUEST_SUCCESS,
    CUSTOMERS_GET_ALL_RECORDS_REQUEST,
    CUSTOMERS_GET_ALL_RECORDS_REQUEST_FAILED,
    CUSTOMERS_GET_ALL_RECORDS_REQUEST_SUCCESS,
    CUSTOMERS_INIT_FORM_REQUEST, 
    CUSTOMERS_INIT_FORM_REQUEST_FAILED, 
    CUSTOMERS_INIT_FORM_REQUEST_SUCCESS,
    CUSTOMERS_INIT_UPDATE_FORM_REQUEST,
    CUSTOMERS_INIT_UPDATE_FORM_REQUEST_FAILED,
    CUSTOMERS_INIT_UPDATE_FORM_REQUEST_SUCCESS,
    CUSTOMERS_INSERT_REQUEST,
    CUSTOMERS_INSERT_REQUEST_FAILED,
    CUSTOMERS_INSERT_REQUEST_SUCCESS,
    CUSTOMERS_LIST_SELECT_ALL_ITEM,
    CUSTOMERS_LIST_SELECT_ITEM,
    CUSTOMERS_UPDATE_REQUEST,
    CUSTOMERS_UPDATE_REQUEST_FAILED,
    CUSTOMERS_UPDATE_REQUEST_SUCCESS,
    INVOICES_CHARGE_REQUEST,
    INVOICES_CHARGE_REQUEST_FAILED,
    INVOICES_CHARGE_REQUEST_SUCCESS,
    KARDEX_REQUEST,
    KARDEX_REQUEST_FAILED,
    KARDEX_REQUEST_SUCCESS,
    SALES_ACTION_FAILED,
    SALES_ANULATE_REQUEST,
    SALES_ANULATE_REQUEST_FAILED,
    SALES_ANULATE_REQUEST_SUCCESS,
    SALES_DELETE_REQUEST,
    SALES_DELETE_REQUEST_FAILED,
    SALES_DELETE_REQUEST_SUCCESS,
    SALES_DETAILED_DIARY_BOOK_REQUEST,
    SALES_DETAILED_DIARY_BOOK_REQUEST_FAILED,
    SALES_DETAILED_DIARY_BOOK_REQUEST_SUCCESS,
    SALES_GET_ALL_RECORDS_REQUEST,
    SALES_GET_ALL_RECORDS_REQUEST_FAILED,
    SALES_GET_ALL_RECORDS_REQUEST_SUCCESS,
    SALES_INIT_FORM_REQUEST,
    SALES_INIT_FORM_REQUEST_FAILED,
    SALES_INIT_FORM_REQUEST_SUCCESS,
    SALES_INIT_UPDATE_FORM_REQUEST,
    SALES_INIT_UPDATE_FORM_REQUEST_FAILED,
    SALES_INIT_UPDATE_FORM_REQUEST_SUCCESS,
    SALES_INSERT_REQUEST,
    SALES_INSERT_REQUEST_FAILED,
    SALES_INSERT_REQUEST_SUCCESS,
    SALES_LIST_SELECT_ALL_ITEM,
    SALES_LIST_SELECT_ITEM,
    SALES_MASSIVE_UPDATE_REQUEST,
    SALES_MASSIVE_UPDATE_REQUEST_FAILED,
    SALES_MASSIVE_UPDATE_REQUEST_SUCCESS,
    SALES_UPDATE_REQUEST,
    SALES_UPDATE_REQUEST_FAILED,
    SALES_UPDATE_REQUEST_SUCCESS
} from "penta-redux/constants/salesConstants";

const initialState: any = {
    tables: { planAccount: [], categorys: [], definition: [], families: [], units: [], departamentos: [], provincias: [], distritos: [], 
        supplier: [], products: [], wharhous: [], masteaccs: [], pbl_purchase: [], pbl_supplier: [], grl_definiti: [], acc_planacco: [], whp_unitmeas: [] },
    onlyOne: null,
    list: [],
    total: 0,
    loading: false,
    failed: false,
    failedTitle: null,
    failedContent: null
};

export default function shoppingsReducer(state = initialState, action: any = {}) {
    switch(action.type) {
        case CUSTOMERS_INIT_FORM_REQUEST:
        case SALES_INIT_FORM_REQUEST:
            return {
                ...state,
                loading: true
            };
        case CUSTOMERS_INIT_FORM_REQUEST_SUCCESS:
        case SALES_INIT_FORM_REQUEST_SUCCESS:
            return {
                ...state,
                tables: { ...state.tables, ...action.tables },
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case CUSTOMERS_INIT_FORM_REQUEST_FAILED:
        case SALES_INIT_FORM_REQUEST_FAILED:
            return {
                ...initialState,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case CUSTOMERS_INIT_UPDATE_FORM_REQUEST:
        case SALES_INIT_UPDATE_FORM_REQUEST:
            return {
                ...state,
                loading: true
            };
        case CUSTOMERS_INIT_UPDATE_FORM_REQUEST_SUCCESS:
        case SALES_INIT_UPDATE_FORM_REQUEST_SUCCESS:
            return {
                ...state,
                tables: { ...state.tables, ...action.tables },
                onlyOne: action.onlyOne,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case CUSTOMERS_INIT_UPDATE_FORM_REQUEST_FAILED:
        case SALES_INIT_UPDATE_FORM_REQUEST_FAILED:
            return {
                ...initialState,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case CUSTOMERS_GET_ALL_RECORDS_REQUEST:
        case SALES_GET_ALL_RECORDS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case CUSTOMERS_GET_ALL_RECORDS_REQUEST_SUCCESS:
        case SALES_GET_ALL_RECORDS_REQUEST_SUCCESS:
            return {
                ...state,
                list: action.list,
                total: action.total,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case CUSTOMERS_GET_ALL_RECORDS_REQUEST_FAILED:
        case SALES_GET_ALL_RECORDS_REQUEST_FAILED:
            return {
                ...initialState,
                list: [],
                total: 0,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case CUSTOMERS_INSERT_REQUEST:
        case SALES_INSERT_REQUEST:
            return {
                ...state,
                loading: true
            };
        case CUSTOMERS_INSERT_REQUEST_SUCCESS:
        case SALES_INSERT_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case CUSTOMERS_INSERT_REQUEST_FAILED:
        case SALES_INSERT_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case CUSTOMERS_UPDATE_REQUEST:
        case SALES_UPDATE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case CUSTOMERS_UPDATE_REQUEST_SUCCESS:
        case SALES_UPDATE_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case CUSTOMERS_UPDATE_REQUEST_FAILED:
        case SALES_UPDATE_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case CUSTOMERS_DELETE_REQUEST:
        case SALES_DELETE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case CUSTOMERS_DELETE_REQUEST_SUCCESS:
        case SALES_DELETE_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case CUSTOMERS_DELETE_REQUEST_FAILED:
        case SALES_DELETE_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case SALES_ANULATE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case SALES_ANULATE_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case SALES_ANULATE_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case SALES_MASSIVE_UPDATE_REQUEST:
                return {
                    ...state,
                    loading: true
                };
        case SALES_MASSIVE_UPDATE_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case SALES_MASSIVE_UPDATE_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case CUSTOMERS_LIST_SELECT_ITEM:
        case SALES_LIST_SELECT_ITEM:
            const newList = state.list;
            newList[action.key].select = !newList[action.key].select;
            return {
                ...state,
                list: newList
            };
        case CUSTOMERS_LIST_SELECT_ALL_ITEM:
        case SALES_LIST_SELECT_ALL_ITEM:
            const newListAll = state.list;
            for (const item of newListAll) {
                item.select = action.checked
            }
            return {
                ...state,
                list: newListAll
            };
        case CUSTOMERS_ACTION_FAILED:
        case SALES_ACTION_FAILED:
            return {
                ...state,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        case SALES_DETAILED_DIARY_BOOK_REQUEST:
        case KARDEX_REQUEST:
        case INVOICES_CHARGE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case SALES_DETAILED_DIARY_BOOK_REQUEST_SUCCESS:
        case KARDEX_REQUEST_SUCCESS:
        case INVOICES_CHARGE_REQUEST_SUCCESS:
            return {
                ...state,
                onlyOne: action.onlyOne,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case SALES_DETAILED_DIARY_BOOK_REQUEST_FAILED:
        case KARDEX_REQUEST_FAILED:
        case INVOICES_CHARGE_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent
            };
        default:
            return state;
    }
}