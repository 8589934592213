import React from 'react';
import { AppBar, Backdrop, Box, Button, ButtonGroup, Checkbox, CircularProgress, createStyles, FormControl, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Select, Tab, Tabs, TextField, Theme, Tooltip, Typography, FormControlLabel, withStyles, Snackbar } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { employesInitFormRequest, employesInitUpdateFormRequest, employesInsertRequest, employesUpdateRequest } from 'penta-redux/actions/humanResourcesActions';
import { locationsGetProvincesRequest, locationsGetDistrictsRequest } from 'penta-redux/actions/generalActions';
import history from '../../../utils/history';
import './employes.scss'
import FormFooter from 'penta-components/base/formFooter';
import { MessageBox } from 'penta-components/base/messageBox';
import { employeeEntity, emplrelaEntity } from 'models/humanResourcesModels';
import Autocomplete from '@material-ui/lab/Autocomplete';
import EditableCell from 'penta-components/base/editableCell';
import moment from 'moment';
import { Alert } from '@material-ui/lab';
import ScreenLoader from 'penta-components/base/screenLoader';

const header = ["","Apellido Paterno", "Apellido materno", "Nombres", "Nro. Documento", "Fecha Nacimiento", "Relacion"];

interface TabPanelProps {
    children: any
    index: any
    value: any
};

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}
  
function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = (theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        formControl: {
            margin: 0,
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    });

const defaultEmployesEntity: employeeEntity = {
    emplcode: '',
    officode: '',
    nameeess: '',
    lastname: '',
    mlstname: '',
    prl_emplojob: {
        emplcode: '',
        officode: '',
        dateadmi: moment().format('YYYY-MM-DD'),
        termdate: moment().format('YYYY-MM-DD'),
        commcode: '',
        typecomm: '',
        afficode: '',
        sesocode: '',
        payrtype: null,
        posscode: '',
        banksala: '',
        accosala: '',
        bankcsts: '',
        accocsts: '',
        regitype: '511'
    },
    prl_emplinfo: {
        emplcode: '',
        officode: '',
        naticode: '',
        placbirt: '',
        datebirt: moment().format('YYYY-MM-DD'),
        sexemplo: '',
        civistat: '',
        docutype: '',
        docunumb: '',
        degrinst: '',
        especial: '',
        addrline: '',
        locacode: ''
    },
    prl_emplrenu: {
        emplcode: '',
        officode: '',
        renumber: {
            renumber1: 0.0,
            renumber2: 0.0,
            renumber3: 0.0,
            renumber4: 0.0,
            renumber5: 0.0
        }
    },
    prl_emplrela: {
        emplcode: '',
        officode: '',
        relative: []
    }
}

interface FormEmployesState {
    employesEntity: employeeEntity
    title: string
    department: string
    province: string
    tabValue: number
    snackbar: boolean
}

class FormEmployes extends React.Component<any, FormEmployesState> {

    constructor(props: any) {
        super(props);
        defaultEmployesEntity.prl_emplrela.relative = [];
        this.state = {
            employesEntity: defaultEmployesEntity,
            department: '',
            province: '',
            title: 'Agregar Empleados',
            tabValue: 0,
            snackbar: false
        }
    }

    onChangeTab = (event: any, newValue: any) => {
        this.setState({
            tabValue: newValue
        })
    }

    onChangeRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            ...value
        }))
    }

    onChangeDepartment = (value: any) => {
        this.onChangeRoot(value);
        this.props.locationsGetProvincesRequest(value.department);
    }

    onChangeProvince = (value: any) => {
        this.onChangeRoot(value);
        this.props.locationsGetDistrictsRequest(value.province);
    }

    onChangeEmployesRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            employesEntity: {
                ...state.employesEntity,
                ...value
            }
        }))
    }

    onChangeEmployesInfoRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            employesEntity: {
                ...state.employesEntity,
                prl_emplinfo: {
                    ...state.employesEntity.prl_emplinfo,
                    ...value
                }
            }
        }))
    }

    onChangeEmployesJobRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            employesEntity: {
                ...state.employesEntity,
                prl_emplojob: {
                    ...state.employesEntity.prl_emplojob,
                    ...value
                }
            }
        }))
        const key = Object.keys(value)[0];
        if(key === 'regitype') {
            if(value.regitype !== '511')
                this.setState((state) => ({
                    ...state,
                    employesEntity: {
                        ...state.employesEntity,
                        prl_emplojob: {
                            ...state.employesEntity.prl_emplojob,
                            commcode: null,
                            typecomm: null
                        }
                    }
                }))
        }
    }

    onChangeEmployesRenumberRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            employesEntity: {
                ...state.employesEntity,
                prl_emplrenu: {
                    ...state.employesEntity.prl_emplrenu,
                    renumber: {
                        ...state.employesEntity.prl_emplrenu.renumber,
                        ...value
                    }
                }
            }
        }))
    }

    onCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        console.log('cancel')
        history.push('/papp/hhrr/empleados');
    }

    onContinueCallback = () => {
        const newEmployesEntity = defaultEmployesEntity;
        newEmployesEntity.prl_emplrela.relative = [];
        this.setState({
            employesEntity: newEmployesEntity,
            department: '',
            province: '',
            snackbar: true
        });
    }

    onSubmitContinue = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const { params } = this.props.match;
        if (params.emplcode !== undefined) {
            this.props.employesUpdateRequest(this.state.employesEntity);
        } else {
            this.props.employesInsertRequest(this.state.employesEntity, this.onContinueCallback);
        }
    }

    onSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const { params } = this.props.match;
        if (params.emplcode !== undefined) {
            this.props.employesUpdateRequest(this.state.employesEntity);
        } else {
            this.props.employesInsertRequest(this.state.employesEntity, null);
        }
    }

    onEditCell = (props: any, value: any, type:number) => {
        const { relative } = this.state.employesEntity.prl_emplrela;
        switch(type){
            case 1: {
                (relative as any)[props.index][props.field] = value;
                this.setState((state: FormEmployesState) => ({
                    ...state,
                    employesEntity: {
                        ...state.employesEntity,
                        prl_emplrela: {
                            ...state.employesEntity.prl_emplrela,
                            relative
                        }
                    }
                }))
            };
            break;
        }
    }

    onAddDetail = (type:number) => {
        const { relative } = this.state.employesEntity.prl_emplrela;
        const { prl_definiti } = this.props.humanResourcesReducers.tables;
        switch(type) {
            case 1 :{
                const newItem: any = {
                    apellidopaterno: '',
                    apellidomaterno: '',
                    relacion: prl_definiti.filter((x: any) => x.defitype === 'REL')[0].deficode,
                    documento: '',
                    nacimiento: '',
                    select: false
                };
                (relative as any).push(newItem);
                this.setState((state: FormEmployesState) => ({
                    ...state,
                    employesEntity: {
                        ...state.employesEntity,
                        prl_emplrela: {
                            ...state.employesEntity.prl_emplrela,
                            relative
                        }
                    }
                }))
            };
            break;
        }
    }

    onDeleteDetail = (type:number) => {
        let { relative } = this.state.employesEntity.prl_emplrela;
        switch(type){
            case 1:{            
                relative = relative.filter((x: any) => x.select === false || x.select === undefined);
                this.setState((state: any) => ({
                    ...state,
                    employesEntity: {
                        ...state.employesEntity,
                        prl_emplrela: {
                            ...state.employesEntity.prl_emplrela,
                            relative
                        }
                    }
                }));
            };
            break;
        }
    }

    refsGrid: any = {};

    renderDetail = (type: number) => {
        const { prl_definiti } = this.props.humanResourcesReducers.tables;
        const { relative } = this.state.employesEntity.prl_emplrela;
        switch(type){
            case 1 : return (
                relative.map((item: any, i: any) => (
                    <tr key={ i } >
                        <td>
                            <input type="checkbox"
                            checked={ item.select } 
                            onChange={ (e: any) => 
                                this.onEditCell(
                                    {index: i, field: 'select' }, 
                                    e.target.checked,
                                    1
                                )}
                            />
                        </td>
                        <td>
                            <input type="text"
                            value={ item.apellidopaterno } 
                            onChange={ (e: any) => 
                                this.onEditCell(
                                    {index: i, field: 'apellidopaterno' }, 
                                    e.target.value,
                                    1
                                )}
                            />
                        </td>
                        <td>
                            <input type="text"
                            value={ item.apellidomaterno } 
                            onChange={ (e: any) => 
                                this.onEditCell(
                                    {index: i, field: 'apellidomaterno' }, 
                                    e.target.value,
                                    1
                                )}
                            />
                        </td>
                        <td>
                            <input type="text"
                            value={ item.nombres } 
                            onChange={ (e: any) => 
                                this.onEditCell(
                                    {index: i, field: 'nombres' }, 
                                    e.target.value,
                                    1
                                )}
                            />
                        </td>
                        <td>
                            <input type="text"
                            value={ item.documento } 
                            onChange={ (e: any) => 
                                this.onEditCell(
                                    {index: i, field: 'documento' }, 
                                    e.target.value,
                                    1
                                )}
                            />
                        </td>
                        <td>
                            <input type="date"
                            value={ item.nacimiento } 
                            onChange={ (e: any) => 
                                this.onEditCell(
                                    {index: i, field: 'nacimiento' }, 
                                    e.target.value,
                                    1
                                )}
                            />
                        </td>
                        <td>
                            <select style={{width:'100%'}} value={ item.relacion } onChange={ (e: any) => this.onEditCell({index: i, field: 'relacion'}, e.target.value, 1 )}>
                                { prl_definiti.filter((x: any) => x.defitype === 'REL').map((subitem:any) => <option value={subitem.deficode} key={subitem.deficode}>{subitem.definame}</option>) }
                            </select>
                        </td>
                    </tr>
                ))
            )
        }
    }

    renderGrilla = (header: Object, type: number) => {
        return (
            <div className="form">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className="form__field">
                            <div className="grid__actions">
                                <button className="add" onClick={ (e) => { e.preventDefault(); this.onAddDetail(type) } }><i className="fas fa-plus"/></button>
                                <button className="remove" onClick={ (e) => { e.preventDefault(); this.onDeleteDetail(type) } }><i className="fas fa-times"/></button>
                                <span style={ { marginLeft: '15px' } }>[ENTER]: Agregar nuevo item</span>
                                <span style={ { marginLeft: '15px' } }>[TAB]: Navegar entre las celdas</span>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <EditableCell
                            header={ header }
                            data={ this.renderDetail(type) }
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }

    callbackInitUpdate = (employesEntity: employeeEntity, department: string, province: string) => {
        employesEntity.prl_emplinfo.datebirt = moment(employesEntity.prl_emplinfo.datebirt).format('YYYY-MM-DD')
        employesEntity.prl_emplojob.dateadmi = moment(employesEntity.prl_emplojob.dateadmi).format('YYYY-MM-DD')
        employesEntity.prl_emplojob.termdate = moment(employesEntity.prl_emplojob.termdate).format('YYYY-MM-DD')
        employesEntity.prl_emplrenu.renumber = JSON.parse(employesEntity.prl_emplrenu.renumber);
        employesEntity.prl_emplrela.relative = JSON.parse(employesEntity.prl_emplrela.relative);
        this.setState({
            employesEntity,
            department,
            province,
        });
    }

    componentDidMount() {
        const { params } = this.props.match;
        if (params.emplcode !== undefined) {
            this.setState({title: 'Editar Empleados'});
            this.props.employesInitUpdateFormRequest(params.emplcode, this.callbackInitUpdate);
        } else {
            this.props.employesInitFormRequest();
        }
    }

    render() {

        const { employesEntity, department, province, title, snackbar, tabValue } = this.state;
        const { loading, list, tables, failed, failedTitle, failedContent } = this.props.humanResourcesReducers;
        const generalTables = this.props.generalReducers.tables;
        const { classes } = this.props;

        const commissis = tables.prl_commissis !== null ? JSON.parse(tables.prl_commissis.commissi) : [ 
            { codigoafp: '01', nombreafp: 'HABITAT' },
            { codigoafp: '02', nombreafp: 'INTEGRA' },
            { codigoafp: '03', nombreafp: 'PRIMA' },
            { codigoafp: '04', nombreafp: 'PROFUTURO' }
        ];

        return (
            <div style={{overflow: 'auto', height: '100%'}}>
                <Paper className='form__container'>
                    <br/>
                    <Typography variant='h5'>{ title }</Typography>
                    <br/>
                    { failed && <MessageBox title={failedTitle} content={failedContent}/> }
                    <form noValidate autoComplete="off">
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <TextField fullWidth id="emplcode" label="Codigo" variant="outlined" value={ employesEntity.emplcode } onChange={(e: any) => this.onChangeEmployesRoot({'emplcode': e.target.value})}/>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth id="nameeess" label="Nombres" variant="outlined" value={ employesEntity.nameeess } onChange={(e: any) => this.onChangeEmployesRoot({'nameeess': e.target.value})}/>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth id="lastname" label="Apellidos Paternos" variant="outlined" value={ employesEntity.lastname } onChange={(e: any) => this.onChangeEmployesRoot({'lastname': e.target.value})}/>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth id="mlstname" label="Apellidos Maternos" variant="outlined" value={ employesEntity.mlstname } onChange={(e: any) => this.onChangeEmployesRoot({'mlstname': e.target.value})}/>
                            </Grid>
                        </Grid>
                        <div style={{ height: 20 }}/>
                        <AppBar position="static">
                            <Tabs value={tabValue} onChange={this.onChangeTab} aria-label="simple tabs example">
                                <Tab label="Datos Personales" {...a11yProps(0)} />
                                <Tab label="Datos del trabajo" {...a11yProps(1)} />
                                <Tab label="Renumeraciones Fijas" {...a11yProps(2)} />
                                <Tab label="Derechohabientes" {...a11yProps(3)} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={tabValue} index={0}>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                        <InputLabel id="lbl_nacionalidad">Nacionalidad</InputLabel>
                                        <Select
                                            labelId="lbl_nacionalidad"
                                            id="naticode"
                                            className='select'
                                            label="Nacionalidad"
                                            fullWidth
                                            value={employesEntity.prl_emplinfo.naticode}
                                            onChange={(e: any) => this.onChangeEmployesInfoRoot({'naticode': e.target.value})}
                                        >
                                            {
                                                tables.prl_definiti.filter((x: any) => x.defitype === 'NAT').map((item: any, key: any) => 
                                                    <MenuItem value={item.deficode} key={`nacionalidad_${key}`}>{item.definame}</MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={9}>
                                    <TextField fullWidth id="placbirt" label="Lugar de nacimiento" variant="outlined" value={ employesEntity.prl_emplinfo.placbirt } onChange={(e: any) => this.onChangeEmployesInfoRoot({'placbirt': e.target.value})}/>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <TextField fullWidth type="date" id="datebirt" label="Fecha de nacimiento" variant="outlined" value={ employesEntity.prl_emplinfo.datebirt } onChange={(e: any) => this.onChangeEmployesInfoRoot({'datebirt': e.target.value})}/>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                        <InputLabel id="lbl_sexo">Sexo</InputLabel>
                                        <Select
                                            labelId="lbl_sexo"
                                            id="sexemplo"
                                            className='select'
                                            label="Sexo"
                                            fullWidth
                                            value={employesEntity.prl_emplinfo.sexemplo}
                                            onChange={(e: any) => this.onChangeEmployesInfoRoot({'sexemplo': e.target.value})}
                                        >
                                            <MenuItem value='M'>Masculino (M)</MenuItem>
                                            <MenuItem value='F'>Femenino (F)</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                        <InputLabel id="lbl_estadocivil">Estado civil</InputLabel>
                                        <Select
                                            labelId="lbl_estadocivil"
                                            id="civistat"
                                            className='select'
                                            label="Estado civil"
                                            fullWidth
                                            value={employesEntity.prl_emplinfo.civistat}
                                            onChange={(e: any) => this.onChangeEmployesInfoRoot({'civistat': e.target.value})}
                                        >
                                            {
                                                tables.prl_definiti.filter((x: any) => x.defitype === 'CIS').map((item: any, key: any) => 
                                                    <MenuItem value={item.deficode} key={`estadocivil_${key}`}>{item.definame}</MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                        <InputLabel id="lbl_tipodocumento">Tipo Documento</InputLabel>
                                        <Select
                                            labelId="lbl_tipodocumento"
                                            id="docutype"
                                            className='select'
                                            label="Tipo Documento"
                                            fullWidth
                                            value={employesEntity.prl_emplinfo.docutype}
                                            onChange={(e: any) => this.onChangeEmployesInfoRoot({'docutype': e.target.value})}
                                        >
                                            {
                                                tables.grl_definiti.filter((x: any) => x.defitype === 'TIDOC').map((item: any, key: any) => 
                                                    <MenuItem value={item.deficode} key={`tipodocumento_${key}`}>{item.definame}</MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <TextField fullWidth id="docunumb" label="Nro de documento" variant="outlined" value={ employesEntity.prl_emplinfo.docunumb } onChange={(e: any) => this.onChangeEmployesInfoRoot({'docunumb': e.target.value})}/>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                        <InputLabel id="lbl_grado">Grado de Instruccion</InputLabel>
                                        <Select
                                            labelId="lbl_grado"
                                            id="degrinst"
                                            className='select'
                                            label="Grado de Instruccion"
                                            fullWidth
                                            value={employesEntity.prl_emplinfo.degrinst}
                                            onChange={(e: any) => this.onChangeEmployesInfoRoot({'degrinst': e.target.value})}
                                        >
                                            {
                                                tables.prl_definiti.filter((x: any) => x.defitype === 'GRI').map((item: any, key: any) => 
                                                    <MenuItem value={item.deficode} key={`grado_${key}`}>{item.definame}</MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField fullWidth id="especial" label="Especialidad" variant="outlined" value={ employesEntity.prl_emplinfo.especial } onChange={(e: any) => this.onChangeEmployesInfoRoot({'especial': e.target.value})}/>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField fullWidth id="addrline" label="Domicilio" variant="outlined" value={ employesEntity.prl_emplinfo.addrline } onChange={(e: any) => this.onChangeEmployesInfoRoot({'addrline': e.target.value})}/>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                        <InputLabel id="lbl_department">Departamento</InputLabel>
                                        <Select
                                            labelId="lbl_department"
                                            id="department"
                                            className='select'
                                            label="Departamento"
                                            fullWidth
                                            value={ department }
                                            onChange={ (e) => this.onChangeDepartment({department: e.target.value}) }
                                        >
                                            {
                                                tables.departamentos.map((item: any, key: any) => 
                                                    <MenuItem value={item.locacode} key={`department_${key}`}>{item.locaname}</MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                        <InputLabel id="lbl_province">Provincia</InputLabel>
                                        <Select
                                            labelId="lbl_province"
                                            id="province"
                                            className='select'
                                            label="Provincia"
                                            fullWidth
                                            value={ province }
                                            onChange={ (e) => this.onChangeProvince({province: e.target.value}) }
                                        >
                                            {
                                                generalTables.provincias.map((item: any, key: any) => 
                                                    <MenuItem value={item.locacode} key={`province_${key}`}>{item.locaname}</MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                        <InputLabel id="lbl_district">Distrito</InputLabel>
                                        <Select
                                            labelId="lbl_district"
                                            id="district"
                                            className='select'
                                            label="Distrito"
                                            fullWidth
                                            value={employesEntity.prl_emplinfo.locacode}
                                            onChange={(e: any) => this.onChangeEmployesInfoRoot({'locacode': e.target.value})}
                                        >
                                            {
                                                generalTables.distritos.map((item: any, key: any) => 
                                                    <MenuItem value={item.locacode} key={`district_${key}`}>{item.locaname}</MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <TextField fullWidth type="date" id="dateadmi" label="Fecha de Ingreso" variant="outlined" value={ employesEntity.prl_emplojob.dateadmi } onChange={(e: any) => this.onChangeEmployesJobRoot({'dateadmi': e.target.value})}/>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth type="date" id="termdate" label="Fecha de Cese" variant="outlined" value={ employesEntity.prl_emplojob.termdate } onChange={(e: any) => this.onChangeEmployesJobRoot({'termdate': e.target.value})}/>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                        <InputLabel id="lbl_regitype">Tipo de Regimen</InputLabel>
                                        <Select
                                            labelId="lbl_afp"
                                            id="regitype"
                                            className='select'
                                            label="Tipo de Regimen"
                                            fullWidth
                                            value={employesEntity.prl_emplojob.regitype}
                                            onChange={(e: any) => this.onChangeEmployesJobRoot({'regitype': e.target.value})}
                                        >
                                            {
                                                tables.prl_definiti.filter((x: any) => x.defitype === 'RET').map((item: any, key: any) => 
                                                    <MenuItem value={item.deficode} key={`regitype_${key}`}>{item.definame}</MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                        <InputLabel id="lbl_afp">AFP</InputLabel>
                                        <Select
                                            labelId="lbl_afp"
                                            id="commcode"
                                            className='select'
                                            label="AFP"
                                            fullWidth
                                            value={employesEntity.prl_emplojob.commcode}
                                            onChange={(e: any) => this.onChangeEmployesJobRoot({'commcode': e.target.value})}
                                            disabled={ employesEntity.prl_emplojob.regitype === '511' ? false : true }
                                        >
                                            {
                                                commissis.map((item: any, key: any) => 
                                                    <MenuItem value={item.codigoafp} key={`afp_${key}`}>{item.nombreafp}</MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                        <InputLabel id="lbl_tipomf">Tipo (Mixta/Flujo)</InputLabel>
                                        <Select
                                            labelId="lbl_tipomf"
                                            id="typecomm"
                                            className='select'
                                            label="Tipo (Mixta/Flujo)"
                                            fullWidth
                                            value={employesEntity.prl_emplojob.typecomm}
                                            onChange={(e: any) => this.onChangeEmployesJobRoot({'typecomm': e.target.value})}
                                            disabled={ employesEntity.prl_emplojob.regitype === '511' ? false : true }
                                        >
                                            {
                                                tables.prl_definiti.filter((x: any) => x.defitype === 'TIC').map((item: any, key: any) => 
                                                    <MenuItem value={item.deficode} key={`tipomf_${key}`}>{item.definame}</MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth id="afficode" label="CUSPP" variant="outlined" value={ employesEntity.prl_emplojob.afficode } onChange={(e: any) => this.onChangeEmployesJobRoot({'afficode': e.target.value})}/>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth id="sesocode" label="Essalud" variant="outlined" value={ employesEntity.prl_emplojob.sesocode } onChange={(e: any) => this.onChangeEmployesJobRoot({'sesocode': e.target.value})}/>
                                </Grid>
                                {/* <Grid item xs={3}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                        <InputLabel id="lbl_payrtype">Frec. Pago</InputLabel>
                                        <Select
                                            labelId="lbl_payrtype"
                                            id="payrtype"
                                            className='select'
                                            label="Tipo de planilla"
                                            fullWidth
                                            value={employesEntity.prl_emplojob.payrtype}
                                            onChange={(e: any) => this.onChangeEmployesJobRoot({'payrtype': e.target.value})}
                                        >
                                            {
                                                tables.prl_definiti.filter((x: any) => x.defitype === 'PRT').map((item: any, key: any) => 
                                                    <MenuItem value={item.deficode} key={`tipomf_${key}`}>{item.definame}</MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid> */}
                                <Grid item xs={3}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                        <InputLabel id="lbl_cargo">Cargo</InputLabel>
                                        <Select
                                            labelId="lbl_cargo"
                                            id="posscode"
                                            className='select'
                                            label="Cargo"
                                            fullWidth
                                            value={employesEntity.prl_emplojob.posscode}
                                            onChange={(e: any) => this.onChangeEmployesJobRoot({'posscode': e.target.value})}
                                        >
                                            {
                                                tables.prl_definiti.filter((x: any) => x.defitype === 'POS').map((item: any, key: any) => 
                                                    <MenuItem value={item.deficode} key={`tipomf_${key}`}>{item.definame}</MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                        <InputLabel id="lbl_bancopago">Banco Pago</InputLabel>
                                        <Select
                                            labelId="lbl_bancopago"
                                            id="banksala"
                                            className='select'
                                            label="Banco Pago"
                                            fullWidth
                                            value={employesEntity.prl_emplojob.banksala}
                                            onChange={(e: any) => this.onChangeEmployesJobRoot({'banksala': e.target.value})}
                                        >
                                            {
                                                tables.prl_definiti.filter((x: any) => x.defitype === 'BAK').map((item: any, key: any) => 
                                                    <MenuItem value={item.deficode} key={`bancopago_${key}`}>{item.definame}</MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth id="accosala" label="Nro. de cuenta" variant="outlined" value={ employesEntity.prl_emplojob.accosala } onChange={(e: any) => this.onChangeEmployesJobRoot({'accosala': e.target.value})}/>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                        <InputLabel id="lbl_bancocts">Banco CTS</InputLabel>
                                        <Select
                                            labelId="lbl_bancocts"
                                            id="bankcsts"
                                            className='select'
                                            label="Banco CTS"
                                            fullWidth
                                            value={employesEntity.prl_emplojob.bankcsts}
                                            onChange={(e: any) => this.onChangeEmployesJobRoot({'bankcsts': e.target.value})}
                                        >
                                            {
                                                tables.prl_definiti.filter((x: any) => x.defitype === 'BAK').map((item: any, key: any) => 
                                                    <MenuItem value={item.deficode} key={`bancocts_${key}`}>{item.definame}</MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth id="accocsts" label="Nro. de cuenta" variant="outlined" value={ employesEntity.prl_emplojob.accocsts } onChange={(e: any) => this.onChangeEmployesJobRoot({'accocsts': e.target.value})}/>
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value={tabValue} index={2}>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <TextField fullWidth id="renumber1" label="Renumeracion 1" variant="outlined" value={ employesEntity.prl_emplrenu.renumber.renumber1 } onChange={(e: any) => this.onChangeEmployesRenumberRoot({'renumber1': e.target.value})}/>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <TextField fullWidth id="renumber2" label="Renumeracion 2" variant="outlined" value={ employesEntity.prl_emplrenu.renumber.renumber2 } onChange={(e: any) => this.onChangeEmployesRenumberRoot({'renumber2': e.target.value})}/>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <TextField fullWidth id="renumber3" label="Renumeracion 3" variant="outlined" value={ employesEntity.prl_emplrenu.renumber.renumber3 } onChange={(e: any) => this.onChangeEmployesRenumberRoot({'renumber3': e.target.value})}/>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <TextField fullWidth id="renumber4" label="Renumeracion 4" variant="outlined" value={ employesEntity.prl_emplrenu.renumber.renumber4 } onChange={(e: any) => this.onChangeEmployesRenumberRoot({'renumber4': e.target.value})}/>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <TextField fullWidth id="renumber5" label="Renumeracion 5" variant="outlined" value={ employesEntity.prl_emplrenu.renumber.renumber5 } onChange={(e: any) => this.onChangeEmployesRenumberRoot({'renumber5': e.target.value})}/>
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value={tabValue} index={3}>
                            {this.renderGrilla(header, 1)}
                        </TabPanel>
                    </form>
                    <br/>
                    <FormFooter 
                        onCancel={ this.onCancel } 
                        onSubmitContinue={ this.onSubmitContinue } 
                        onSubmit={ this.onSubmit }/>
                </Paper>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={snackbar}
                    onClose={() => this.setState({snackbar: false})}
                    autoHideDuration={6000}
                >
                    <Alert onClose={() => this.setState({snackbar: false})} severity="success">
                            Datos Guardados Correctamente
                    </Alert>
                </Snackbar>
                <ScreenLoader enabled={loading}/>
            </div>
        );
    }
}

const humanResourcesReducers = 'humanResourcesReducers';
const generalReducers = 'generalReducers';
const dashboardReducers = 'dashboardReducers';

const mapStateToProps = (state: any) => ({
    humanResourcesReducers: state.get(humanResourcesReducers),
    generalReducers: state.get(generalReducers),
    dashboardReducers: state.get(dashboardReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        employesInitFormRequest,
        employesInitUpdateFormRequest,
        employesInsertRequest,
        employesUpdateRequest,
        locationsGetProvincesRequest,
        locationsGetDistrictsRequest
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(FormEmployes));