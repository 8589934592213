import { CURRENCY_EXCHANGE_RATE, LOCATIONS_GET_DEPARTMENTS, LOCATIONS_GET_DISTRICTS, LOCATIONS_GET_PROVINCES, MASTER_TABLES } from 'services/constants/generalConstants';
import request from './basePipeline';

export const locationGetDepartments = () => {
    return request.getWithoutToken(LOCATIONS_GET_DEPARTMENTS);
}

export const locationGetProvinces = (uri: string) => {
    return request.getWithoutToken(LOCATIONS_GET_PROVINCES + uri);
}

export const locationGetDistricts = (uri: string) => {
    return request.getWithoutToken(LOCATIONS_GET_DISTRICTS + uri);
}

export const currencyExchangeRate = (datetime: string) => {
    return request.getWithoutToken(CURRENCY_EXCHANGE_RATE + datetime);
}

export const masterTables = (option: string) => {
    return request.getWithToken(MASTER_TABLES + option);
}