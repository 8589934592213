var formatNumber = {
    separador: ",", // separador para los miles
    sepDecimal: '.', // separador para los decimales
    formatear: function (num, zeros = '00') {
        num +='';
        var splitStr = num.split('.');
        var splitLeft = splitStr[0];
        var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1].padEnd(zeros.length, '0') : '.' + zeros;
        var regx = /(\d+)(\d{3})/;
        while (regx.test(splitLeft)) {
            splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
        }
        return splitLeft + splitRight;
    },
    new: function(num) {
        num = Math.round(parseFloat(num) * 100) / 100;
        return this.formatear(num);
    }
}

var formatNumber2 = {
    separador: ",", // separador para los miles
    sepDecimal: '.', // separador para los decimales
    formatear: function (num, zeros = '00') {
        num += '';
        var splitStr = num.replace('-', '').split('.');
        var splitLeft = splitStr[0];
        var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1].padEnd(zeros.length, '0') : '.' + zeros;
        var regx = /(\d+)(\d{3})/;
        while (regx.test(splitLeft)) {
            splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
        }
        if(+num < 0)
            return '(' + splitLeft + splitRight + ')';
        else
            return splitLeft + splitRight;
    },
    new: function(num) {
        num = Math.round(parseFloat(num) * 100) / 100;
        return this.formatear(num);
    }
}

interface StringConstructor {
    formatNumber: any
    formatNumber2: any
}

String.formatNumber = formatNumber;
String.formatNumber2 = formatNumber2;