import React from 'react';
import { AppBar, Backdrop, Box, Button, ButtonGroup, Checkbox, CircularProgress, createStyles, FormControl, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Select, Tab, Tabs, TextField, Theme, Tooltip, Typography, FormControlLabel, withStyles, TextareaAutosize, Snackbar } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { cashBanksInitFormRequest, cashBanksInitUpdateFormRequest, cashBanksInsertRequest, cashBanksUpdateRequest } from 'penta-redux/actions/financeActions';
import history from '../../../utils/history';
import './cashBanks.scss'
import FormFooter from 'penta-components/base/formFooter';
import { MessageBox } from 'penta-components/base/messageBox';
import { cashBankEntity } from 'models/financeModels';
import moment from 'moment';
import { Alert, Autocomplete } from '@material-ui/lab';
import ScreenLoader from 'penta-components/base/screenLoader';

const useStyles = (theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        formControl: {
            margin: 0,
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    });

const defaultCashBankEntity: cashBankEntity = {
    idcsbank: 0,
    typecsbk: '',
    acconumb: null,
    denomina: '',
    currency: null,
    accaccou: null,
    fiscyear: null,
    accdenom: null,
    profiacc: null,
    prodenom: null,
    lostacco: null,
    losdenom: null,
    csb_balances: []
}

interface FormCashBanksState {
    cashBankEntity: cashBankEntity
    title: string
    snackbar: boolean
}

class FormCashBanks extends React.Component<any, FormCashBanksState> {

    constructor(props: any) {
        super(props);
        this.state = {
            cashBankEntity: defaultCashBankEntity,
            title: 'Agregar Caja y Bancos',
            snackbar: false
        }
    }

    onChangeRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            ...value
        }))
    }

    onChangeCashBankRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            cashBankEntity: {
                ...state.cashBankEntity,
                ...value
            }
        }));
        const key = Object.keys(value)[0];
        if (key === 'typecsbk') {
            this.setState((state) => ({
                ...state,
                cashBankEntity: {
                    ...state.cashBankEntity,
                    acconumb: null,
                    profiacc: null,
                    prodenom: null,
                    lostacco: null,
                    losdenom: null
                }
            }));
        }
        else if (key === 'currency') {
            this.setState((state) => ({
                ...state,
                cashBankEntity: {
                    ...state.cashBankEntity,
                    profiacc: null,
                    prodenom: null,
                    lostacco: null,
                    losdenom: null
                }
            }));
        }
    }

    onCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        console.log('cancel')
        history.push('/papp/finance/caja-bancos');
    }

    onContinueCallback = () => {
        const { tables } = this.props.financeReducers;
        const newCashBankEntity = defaultCashBankEntity;
        newCashBankEntity.typecsbk = tables.grl_definiti.filter((x: any) => x.defitype === 'TCSBK')[0].deficode;
        newCashBankEntity.currency = tables.grl_definiti.filter((x: any) => x.defitype === 'CURCY')[0].deficode;
        this.setState({
            cashBankEntity: newCashBankEntity,
            snackbar: true
        });
    }

    onSubmitContinue = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const { params } = this.props.match;
        if (params.idcsbank !== undefined) {
            this.props.cashBanksUpdateRequest(this.state.cashBankEntity, null);
        } else {
            this.props.cashBanksInsertRequest(this.state.cashBankEntity, this.onContinueCallback);
        }
    }

    onSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const { params } = this.props.match;
        if (params.idcsbank !== undefined) {
            this.props.cashBanksUpdateRequest(this.state.cashBankEntity, null);
        } else {
            this.props.cashBanksInsertRequest(this.state.cashBankEntity, null);
        }
    }

    callbackInitUpdate = (cashBankEntity: cashBankEntity) => {
        this.setState({
            cashBankEntity
        });
    }

    callbackInit = () => {
        const { cashBankEntity } = this.state;
        const { tables } = this.props.financeReducers;
        cashBankEntity.typecsbk = tables.grl_definiti.filter((x: any) => x.defitype === 'TCSBK')[0].deficode;
        cashBankEntity.currency = tables.grl_definiti.filter((x: any) => x.defitype === 'CURCY')[0].deficode;
        this.setState((state) => ({
            ...state,
            cashBankEntity
        }))
    }

    componentDidMount() {
        const { params } = this.props.match;
        if (params.idcsbank !== undefined) {
            this.setState({title: 'Editar Caja y Bancos'});
            this.props.cashBanksInitUpdateFormRequest(params.idcsbank, this.callbackInitUpdate);
        } else {
            this.props.cashBanksInitFormRequest(this.props.dashboardReducers.currentFiscyear, this.callbackInit);
        }
    }

    render() {

        const { cashBankEntity, title, snackbar } = this.state;
        const { loading, list, tables, failed, failedTitle, failedContent } = this.props.financeReducers;
        const { classes } = this.props;

        return (
            <div style={{overflow: 'auto', height: '100%'}}>
                <Paper className='form__container'>
                    <br/>
                    <Typography variant='h5'>{ title }</Typography>
                    <br/>
                    { failed && <MessageBox title={failedTitle} content={failedContent}/> }
                    <form noValidate autoComplete="off">
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                    <InputLabel id="lbl_tipo">Tipo</InputLabel>
                                    <Select
                                        labelId="lbl_tipo"
                                        id="typecsbk"
                                        className='select'
                                        label="Tipo"
                                        fullWidth
                                        value={cashBankEntity.typecsbk || ''}
                                        onChange={(e: any) => this.onChangeCashBankRoot({'typecsbk': e.target.value})}
                                    >
                                        {
                                            tables.grl_definiti.filter((x: any) => x.defitype === 'TCSBK' ).map((item: any, key: any) => 
                                                <MenuItem value={item.deficode} key={`tipo_${key}`}>{item.definame}</MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <TextField fullWidth id="denomina" label="Denominacion" variant="outlined" value={ cashBankEntity.denomina } onChange={(e: any) => this.onChangeCashBankRoot({'denomina': e.target.value})}/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <TextField fullWidth id="acconumb" label="Numero de cuenta" variant="outlined" value={ cashBankEntity.acconumb || '' } onChange={(e: any) => this.onChangeCashBankRoot({'acconumb': e.target.value})} disabled={ cashBankEntity.typecsbk === 'TCSBK001' }/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                    <InputLabel id="lbl_moneda">Moneda</InputLabel>
                                    <Select
                                        labelId="lbl_moneda"
                                        id="currency"
                                        className='select'
                                        label="Moneda"
                                        fullWidth
                                        value={cashBankEntity.currency || ''}
                                        onChange={(e: any) => this.onChangeCashBankRoot({'currency': e.target.value})}
                                    >
                                        {
                                            tables.grl_definiti.filter((x: any) => x.defitype === 'CURCY' ).map((item: any, key: any) => 
                                                <MenuItem value={item.deficode} key={`moneda_${key}`}>{item.definame}</MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <Autocomplete
                                    id="accaccou"
                                    freeSolo
                                    options={tables.acc_planacco}
                                    getOptionLabel={ (x: any) => x.accaccou }
                                    value={ tables.acc_planacco.find((x: any) => x.accaccou === cashBankEntity.accaccou) || null }
                                    onChange={(e: any, value) => this.onChangeCashBankRoot({'accaccou': value.accaccou, 'fiscyear': value.fiscyear, 'accdenom': value.denomina })}
                                    renderInput={(params) => (
                                        <TextField {...params} 
                                        style={ { marginTop: 0 } } label="Cuenta Contable" margin="normal" variant="outlined" />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth id="accdenom" variant="outlined" value={ cashBankEntity.accdenom || '' }/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <Autocomplete
                                    id="accaccou"
                                    freeSolo
                                    options={tables.acc_planacco}
                                    getOptionLabel={ (x: any) => x.accaccou }
                                    value={ tables.acc_planacco.find((x: any) => x.accaccou === cashBankEntity.profiacc) || null }
                                    onChange={(e: any, value) => this.onChangeCashBankRoot({'profiacc': value.accaccou, 'prodenom': value.denomina })}
                                    // disabled={ cashBankEntity.typecsbk === 'TCSBK001' || cashBankEntity.currency === '00000014' }
                                    renderInput={(params) => (
                                        <TextField {...params} 
                                        style={ { marginTop: 0 } } label="Cuenta Ganancia" margin="normal" variant="outlined" />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth id="prodenom" variant="outlined" value={ cashBankEntity.prodenom || '' } disabled={ cashBankEntity.typecsbk === 'TCSBK001' || cashBankEntity.currency === '00000014' }/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <Autocomplete
                                    id="accaccou"
                                    freeSolo
                                    options={tables.acc_planacco}
                                    getOptionLabel={ (x: any) => x.accaccou }
                                    value={ tables.acc_planacco.find((x: any) => x.accaccou === cashBankEntity.lostacco) || null }
                                    onChange={(e: any, value) => this.onChangeCashBankRoot({'lostacco': value.accaccou, 'losdenom': value.denomina })}
                                    // disabled={ cashBankEntity.typecsbk === 'TCSBK001' || cashBankEntity.currency === '00000014' }
                                    renderInput={(params) => (
                                        <TextField {...params} 
                                        style={ { marginTop: 0 } } label="Cuenta Perdida" margin="normal" variant="outlined" />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth id="losdenom" variant="outlined" value={ cashBankEntity.losdenom || '' } disabled={ cashBankEntity.typecsbk === 'TCSBK001' || cashBankEntity.currency === '00000014' }/>
                            </Grid>
                        </Grid>
                    </form>
                    <br/>
                    <FormFooter 
                        onCancel={ this.onCancel } 
                        onSubmitContinue={ this.onSubmitContinue } 
                        onSubmit={ this.onSubmit }/>
                </Paper>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={snackbar}
                    onClose={() => this.setState({snackbar: false})}
                    autoHideDuration={6000}
                >
                    <Alert onClose={() => this.setState({snackbar: false})} severity="success">
                            Datos Guardados Correctamente
                    </Alert>
                </Snackbar>
                <ScreenLoader enabled={loading}/>
            </div>
        );
    }
}

const financeReducers = 'financeReducers';
const dashboardReducers = 'dashboardReducers';

const mapStateToProps = (state: any) => ({
    financeReducers: state.get(financeReducers),
    dashboardReducers: state.get(dashboardReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        cashBanksInitFormRequest,
        cashBanksInitUpdateFormRequest,
        cashBanksInsertRequest,
        cashBanksUpdateRequest
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(FormCashBanks));