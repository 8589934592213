import {
    call, fork, put, take, takeEvery, all
  } from 'redux-saga/effects';
import history from '../../utils/history';
import { USERS_DELETE_REQUEST, USERS_GET_ALL_RECORDS_REQUEST, USERS_INIT_FORM_REQUEST, USERS_INIT_UPDATE_FORM_REQUEST, USERS_REGISTER_REQUEST, USERS_UPDATE_REQUEST } from 'redux/constants/usersConstants';
import pipelines from 'services';
import { usersGetAllRecordsRequest, usersGetAllRecordsRequestFailed, usersGetAllRecordsRequestSuccess, usersInitFormRequestFailed, usersInitFormRequestSuccess, usersInitUpdateFormRequestSuccess, usersRegisterRequestFailed, usersRegisterRequestSuccess } from 'penta-redux/actions/usersActions';
import { locationsGetProvincesSuccess } from 'penta-redux/actions/generalActions';
import { userEntity } from 'models/userModels';

function* usersInitFormSaga(action: any) {
    try {
        const data = (yield call(pipelines.usersPipeline.usersInitForm)).data;
        yield put(usersInitFormRequestSuccess(data.data.tables));
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(usersInitFormRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* usersInitUpdateFormSaga(action: any) {
    try {
        const data = (yield call(pipelines.usersPipeline.usersInitUpdateForm, action.username)).data;
        yield put(usersInitUpdateFormRequestSuccess(data.data.tables, data.data.onlyOne));
        yield put(locationsGetProvincesSuccess(data.data.tables));
        yield put(locationsGetProvincesSuccess(data.data.tables));
        yield call(action.callback, data.data.onlyOne, data.data.tables.provincias[0].ploccode, data.data.tables.distritos[0].ploccode);
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(usersInitFormRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* usersGetAllRecordsSaga(action: any) {
    try {
        const data = (yield call(pipelines.usersPipeline.usersGetAllRecords, action.body)).data;
        yield put(usersGetAllRecordsRequestSuccess(data.data.list, data.data.total));
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(usersGetAllRecordsRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* usersRegisterSaga(action: any) {
    try {
        yield call(pipelines.usersPipeline.usersRegister, action.body);
        if (action.callback !== null) {
            yield call(action.callback);
            yield put(usersRegisterRequestSuccess());
        }
        else
            yield history.push('/papp/users/maestro-usuarios');
        // yield put({ type: USERS_GET_ALL_RECORDS_REQUEST, body: {} });
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(usersRegisterRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* usersUpdateSaga(action: any) {
    try {
        yield call(pipelines.usersPipeline.usersUpdate, (action.body as userEntity).username, action.body);
        yield history.push('/papp/users/maestro-usuarios');
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(usersRegisterRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* usersDeleteSaga(action: any) {
    try {
        const data = (yield call(pipelines.usersPipeline.usersDelete, action.username)).data;
        yield put({ type: USERS_GET_ALL_RECORDS_REQUEST, body: {} });
        console.log(data);
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(usersRegisterRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* authRootSaga() {
    yield all([
        takeEvery(USERS_INIT_FORM_REQUEST, usersInitFormSaga),
        takeEvery(USERS_INIT_UPDATE_FORM_REQUEST, usersInitUpdateFormSaga),
        takeEvery(USERS_GET_ALL_RECORDS_REQUEST, usersGetAllRecordsSaga),
        takeEvery(USERS_REGISTER_REQUEST, usersRegisterSaga),
        takeEvery(USERS_UPDATE_REQUEST, usersUpdateSaga),
        takeEvery(USERS_DELETE_REQUEST, usersDeleteSaga)
    ]);
}
  
const authSagas = [
    fork(authRootSaga),
];
  
export default authSagas;