import React, { useEffect } from 'react';
import { Backdrop, CircularProgress, createStyles, Paper, TextField, Theme, Tooltip, withStyles } from '@material-ui/core';
import ListView from 'penta-components/base/listView';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { cashBanksMovementsGetAllRecordsRequest, cashBanksMovementsListSelectItem, cashBanksMovementsListSelectAllItem, cashBanksMovementsActionFailed, cashBanksMovementsDeleteRequest, cashBanksMovementsMassiveUpdateRequest } from 'penta-redux/actions/financeActions';
import history from '../../../utils/history';
import './cashBanksMovements.scss'
import filePNG from './../../../../icons/fluent/file.png';
import fileEditPNG from './../../../../icons/fluent/file-edit.png';
import fileDeletePNG from './../../../../icons/fluent/delete.png';
import fileCopyPNG from './../../../../icons/fluent/copy.png';
import fileDownloadPNG from './../../../../icons/fluent/download.png';
import fileUploadPNG from './../../../../icons/fluent/upload.png';
import fileAnulatePNG from './../../../../icons/fluent/anulate.png';
import { MessageBox } from 'penta-components/base/messageBox';
import ActionDialog from 'penta-components/base/dialog';
import { Input } from 'penta-components/base/input';
import Button from 'penta-components/controls/button';
import * as XLSX from 'xlsx';
import { purchaseEntity } from 'models/shoppingModels';
import ListMenu from 'penta-components/base/listMenu';
import Separator from 'penta-components/base/separator';
import Search from 'penta-components/controls/search';
import Select from 'penta-components/controls/select';
import { createUseStyles } from 'react-jss';
import Modal from 'penta-components/base/modal';
import Checkbox from 'penta-components/controls/checkbox';

const moment = require('moment');
const months = [
    { id: '00', name: '--Todos--' },
    { id: '01', name: 'Enero' },
    { id: '02', name: 'Febrero' },
    { id: '03', name: 'Marzo' },
    { id: '04', name: 'Abril' },
    { id: '05', name: 'Mayo' },
    { id: '06', name: 'Junio' },
    { id: '07', name: 'Julio' },
    { id: '08', name: 'Agosto' },
    { id: '09', name: 'Septiembre' },
    { id: '10', name: 'Octubre' },
    { id: '11', name: 'Noviembre' },
    { id: '12', name: 'Diciembre' }
];
const header = [
    { title: 'Correlativo', key: 'intecode' },
    { title: 'Denominación', key: 'csb_cashbank.denomina' },
    { title: 'Número de cuenta', key: 'csb_cashbank.acconumb' },
    { title: 'Moneda', key: 'csb_cashbank.currency' },
    { title: 'Cuenta Contable', key: 'csb_cashbank.accaccou' },
    { title: 'Año de ejercicio', key: 'yearexer' },
    { title: 'Mes de ejercicio', key: 'montexer' },
    { title: 'Semana', key: 'weekexer' },
    { title: 'Pagina', key: 'pagenumb' },
]; 

const useStyles =  createUseStyles({
    container: {
        overflow: 'auto', 
        height: '100%', 
        display: 'flex', 
        flexDirection: 'column'
    },
    titleContainer: {
        flex: 1, 
        display: 'flex', 
        alignItems: 'baseline'
    },
    title: {
        fontFamily: '"Open Sans", sans-serif', 
        fontSize: '1.563rem', 
        fontWeight: 'bold', 
        color: '#0060A6', 
        marginRight: '3rem'
    },
    tabActive: {},
    tabs: {
        margin: '0 0.4rem', 
        padding: '0.4rem 0.5rem', 
        color: '#b9b9b9', 
        fontFamily: '"Open Sans", sans-serif', 
        fontWeight: 'bold', 
        fontSize: '0.875rem',
        cursor: 'pointer',
        '&$tabActive': {
            color: '#3B3B3B',
            borderBottom: '0.2rem solid #3B3B3B', 
        }
    },
    listContainer: {
        flex: 1,
        '& > div:first-child': {
            display: 'flex'
        }
    }
});

const ListCashBanksMovements = (props: any) => {
    const classes = useStyles();
    const amountJump = 20;
    const [showModal, setShowModal] = React.useState(false);
    const [typeModal, setTypeModal] = React.useState('');
    //===============================================================
    const [searchValue, setSearchValue] = React.useState('');
    const [acpeyear, setAcpeyear] = React.useState(moment().format('YYYY'));
    const [acpemont, setAcpemont] = React.useState('00');
    const [currency, setCurrency] = React.useState('00');
    const [state, setState] = React.useState('00');
    const [take, setTake] = React.useState(amountJump);
    //===============================================================
    const [file, setFile] = React.useState('');
    const [typing, setTyping] = React.useState(null);
    //===============================================================
    const [origin, setOrigin] = React.useState(null);
    const [deleteOrigin, setDeleteOrigin] = React.useState(false);
    const [listCompany, setListCompany] = React.useState([]);
    const [listDivision, setListDivision] = React.useState([]);
    const [listBussofic, setListBussofic] = React.useState([]);
    const [company, setCompany] = React.useState('');
    const [division, setDivision] = React.useState('');
    const [bussofic, setBussofic] = React.useState('');

    const agregar = () => {
        history.push('/papp/finance/movimientos-caja-bancos/agregar');
    };

    const editar = () => {
        const select = props.financeReducers.list.find((x: any) => x.select);
        if (select !== undefined)
            history.push('/papp/finance/movimientos-caja-bancos/editar/' +  `${select.yearexer}/${select.montexer}/${select.weekexer}/${select.pagenumb}/${select.idcsbank}` );
        else
            props.cashBanksMovementsActionFailed('Error: 0x0', 'No se ha selecionado un elemento para modificar');
    }

    const eliminar = () => {
        const select = props.financeReducers.list.filter((x: any) => x.select);
        const selectYearexer = select.map((x: any) => { return x.yearexer });
        const selectMontexer = select.map((x: any) => { return x.montexer });
        const selectWeekexer = select.map((x: any) => { return x.weekexer });
        const selectPagenumb = select.map((x: any) => { return x.pagenumb });
        const selectIdcsbank = select.map((x: any) => { return x.idcsbank });
        if(select.length !== 0) {
            // const filters = {
            //     fields: {
            //         correlat: searchValue,
            //     },
            //     skip: 0,
            //     take: amountJump
            // } as any
    
            // if(acpeyear !== '') filters.year = acpeyear;
            // if(acpemont !== '00') filters.month = acpemont;
            // if(currency !== '00') filters.currency = currency;

            props.cashBanksMovementsDeleteRequest(selectYearexer, selectMontexer, selectWeekexer, selectPagenumb, selectIdcsbank);
            // props.vouchersDeleteRequest(selectCorrelat, filters);
            handleCloseModal();
        }
        else
            props.cashBanksMovementsActionFailed('Error: 0x0', 'No se ha selecionado un elemento para eliminar');
    }

    // const copy = () => {
    //     const elementCopy = origin;
    //     if(elementCopy) {
    //         const filters = {
    //             fields: { },
    //             skip: 0,
    //             take: amountJump
    //         } as any

    //         if(acpeyear !== '') filters.year = acpeyear;
    //         if(acpemont !== '') filters.month = acpemont;

    //         history.push('/papp/shopping/compras/copiar', {
    //             method: 'copy', 
    //             serialnu: elementCopy.serialnu, 
    //             documnum: elementCopy.documnum, 
    //             officode: bussofic, 
    //             docsuppl: elementCopy.docsuppl,
    //             deleteOrigin: deleteOrigin, 
    //             filters: filters
    //         })
    //     }
    //     setDeleteOrigin(false);
    // }

    const handleShowModal = (typeModal: string) => {
        setShowModal(true);
        setTypeModal(typeModal);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setTypeModal('');
    };

    // const openModalCopy = () => {
    //     const selects = props.shoppingsReducers.list.filter((x: any) => x.select);
    //     if(selects.length > 0) {
    //         handleShowModal('C')
    //         setOrigin(selects[0]);
    //     }
    // }

    const onChangeSearchValue = (e) => {
        const text = e.target.value;
        setSearchValue(text);
        if(typing) clearTimeout(typing)
        setTyping(
            setTimeout(() => {
                onSearch(text);
            }, 500)
        )
    }

    const onSearch = (typing?: string) => {
        const body = {
            fields: {
                'csb_csbkmove.intecode': `like|'%${typing ?? searchValue}%'`,
                'csb_cashbank.idcsbank': `like|'%${typing ?? searchValue}%'`,
                'csb_cashbank.acconumb': `like|'%${typing ?? searchValue}%'`,
                'csb_cashbank.denomina': `like|'%${typing ?? searchValue}%'`,
                'csb_csbkmove.yearexer': `like|'%${typing ?? searchValue}%'`,
                'csb_csbkmove.montexer': `like|'%${typing ?? searchValue}%'`,
                'csb_csbkmove.weekexer': `like|'%${typing ?? searchValue}%'`,
                'csb_csbkmove.pagenumb': `like|'%${typing ?? searchValue}%'`
            },
            skip: 0,
            take: amountJump
        } as any

        // if(acpeyear !== '') body.year = acpeyear;
        // if(acpemont !== '00') body.month = acpemont;
        // if(currency !== '00') body.currency = currency;

        props.cashBanksMovementsGetAllRecordsRequest(body);
        setTake(amountJump);
    }

    const callMoreItems = () => {
        const body = {
            fields: {
                'csb_cashbank.idcsbank': `like|'%${searchValue}%'`,
                'csb_cashbank.acconumb': `like|'%${searchValue}%'`,
                'csb_cashbank.denomina': `like|'%${searchValue}%'`,
                'csb_csbkmove.yearexer': `like|'%${searchValue}%'`,
                'csb_csbkmove.montexer': `like|'%${searchValue}%'`,
                'csb_csbkmove.weekexer': `like|'%${searchValue}%'`,
                'csb_csbkmove.pagenumb': `like|'%${searchValue}%'`
            },
            skip: 0,
            take: take + amountJump
        } as any

        // if(acpeyear !== '') body.year = acpeyear;
        // if(acpemont !== '00') body.month = acpemont;
        // if(currency !== '00') body.currency = currency;

        props.cashBanksMovementsGetAllRecordsRequest(body);
        setTake(take + amountJump);
    }

    useEffect(() => {
        const { cashBanksMovementsGetAllRecordsRequest } = props;
        const body = {
            // fields: {
            //     correlat: searchValue
            // },
            skip: 0,
            take
        } as any

        // if(acpeyear !== '') body.year = acpeyear;
        // if(acpemont !== '00') body.month = acpemont;
        // if(currency !== '00') body.currency = currency;

        cashBanksMovementsGetAllRecordsRequest(body);
    }, []);

    useEffect(() => {
        const { authReducers } = props;
        const lst_division = authReducers.tables.companys.find((x: any) => x.taxident === company)?.lst_grl_division.map((x: any) => { return { id: x.divicode, name: x.diviname } }) || [];
        setListDivision(lst_division);
        setListBussofic([]);
    }, [company]);

    useEffect(() => {
        const { authReducers } = props;
        const lst_bussofic = authReducers.tables.companys.find((x: any) => x.taxident === company)?.lst_grl_division.find((x: any) => x.divicode === division)?.lst_grl_bussofic.map((x: any) => { return { id: x.officode, name: x.offiname } }) || [];
        setListBussofic(lst_bussofic);
    }, [division]);

    useEffect(() => {
        const { dashboardReducers } = props;
        setCompany(dashboardReducers.currentCompany);
        setDivision(dashboardReducers.currentDivision);
        setBussofic(dashboardReducers.currentBussOfic);
    }, [showModal]);

    useEffect(() => {
        const { authReducers } = props;
        const lst_companys = authReducers.tables.companys.map((x: any) => {
            return { name: x.bussname, id: x.taxident }
        });
        setListCompany(lst_companys);
        setListDivision([]);
        setListBussofic([]);
    }, []);

    return (
        <div className={classes.container}>
            <div className={classes.titleContainer}>
                <div className={classes.title}>Mov. de caja y banco</div>
                <div>
                    <span className={`${classes.tabs} ${classes.tabActive}`}>Lista</span>
                    <span className={classes.tabs}>Chart</span>
                    <span className={classes.tabs}>Historial</span>
                </div>
            </div>
            <div className={classes.listContainer}>
                <div>
                    <Search styleContainer={{flex: 1}} onChange={onChangeSearchValue} value={searchValue}/>
                    {/* <Select label="Año" labelPosition="left" style={{marginLeft: '1rem'}} data={
                        [{id: '2021', name: '2021'}, {id: '2020', name: '2020'}, {id: '2019', name: '2019'}]
                    } value={acpeyear} onChange={(value) => setAcpeyear(value.id)}/>
                    <Select label="Mes" labelPosition="left" style={{marginLeft: '1rem'}} data={months} value={acpemont} onChange={(value) => setAcpemont(value.id)}/>
                    <Select label="Moneda" labelPosition="left" style={{marginLeft: '1rem'}} data={
                        [{id: '00', name: '--Todos--'}, {id: '00000014', name: 'Soles'}, {id: '00000015', name: 'Dolares'}]
                    } value={state} onChange={(value) => setCurrency(value.id)}/> */}
                    &nbsp;&nbsp;
                    <Button type='primary' full={false} containerStyle={{height: 'auto'}} onClick={() => onSearch()}>Filtrar</Button>
                </div>
                <Separator marginBottom='1rem'/>
                <ListMenu
                    addItem={agregar}
                    editItem={editar}
                    deleteItem={() => handleShowModal('D')}
                    // copyItem={openModalCopy}
                />
                <Separator marginBottom='0.5rem'/>
                <ListView
                    header={header} body={props.financeReducers.list}
                    selectAction={props.cashBanksMovementsListSelectItem} 
                    selectAllAction={props.cashBanksMovementsListSelectAllItem}
                    total={props.financeReducers.total}
                    callMoreItems={callMoreItems}
                />
            </div>
            {
                showModal && typeModal === 'D' &&
                <Modal 
                    title="Penta ERP"
                    onClose={() => setShowModal(false)}
                    footer={
                        <React.Fragment>
                            <Button type='bad' full={false} onClick={handleCloseModal}>Cancelar</Button>
                            &nbsp;&nbsp;
                            <Button full={false} onClick={eliminar}>Aceptar</Button>
                        </React.Fragment>
                    }
                >
                    <React.Fragment>
                        <p>¿Desea eliminar los siguientes elemetos?</p>
                        {
                            props.financeReducers.list.filter((x: any) => x.select).map(item =>
                                <ul key={`${item.serialnu}-${item.documnum}`} style={{margin: '1rem 2rem'}}>
                                    <li>{item.serialnu}-{item.documnum}</li>
                                </ul>
                            )
                        }
                    </React.Fragment>
                </Modal>
            }
            {
                showModal && typeModal === 'C' &&
                <Modal 
                    title="Penta ERP"
                    onClose={() => setShowModal(false)}
                    footer={
                        <React.Fragment>
                            <Button type='bad' full={false} onClick={handleCloseModal}>Cancelar</Button>
                            &nbsp;&nbsp;
                            {/* <Button full={false} onClick={copy}>Aceptar</Button> */}
                        </React.Fragment>
                    }
                >
                    <React.Fragment>
                        <p>¿Desea copiar los datos del elemento {`${origin.serialnu}-${origin.documnum}`}?</p>
                        <br/>
                        <p>Oficina de destino:</p>
                        <Select label="Compañia" labelPosition="left" style={{marginTop: '1rem'}} data={listCompany} value={company} onChange={(value) => { setCompany(value.id); setDivision(''); setBussofic(''); } }/>
                        <Select label="Division" labelPosition="left" style={{marginTop: '1rem'}} data={listDivision} value={division} onChange={(value) => { setDivision(value.id); setBussofic(''); }}/>
                        <Select label="Oficina" labelPosition="left" style={{marginTop: '1rem'}} data={listBussofic} value={bussofic} onChange={(value) => setBussofic(value.id)}/>
                        <Checkbox label="Borrar elemento original" checked={deleteOrigin} onClick={(e: any) => setDeleteOrigin(e.target.checked)} containerStyle={{margin: '1rem 0rem'}}/>
                    </React.Fragment>
                </Modal>
            }
        </div>
    );
}

const authReducers = 'authReducers';
const dashboardReducers = 'dashboardReducers';
const reducer = 'financeReducers';

const mapStateToProps = (state: any) => ({
    authReducers: state.get(authReducers),
    dashboardReducers: state.get(dashboardReducers),
    financeReducers: state.get(reducer)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        cashBanksMovementsGetAllRecordsRequest,
        cashBanksMovementsListSelectItem,
        cashBanksMovementsListSelectAllItem,
        cashBanksMovementsActionFailed,
        cashBanksMovementsDeleteRequest,
        cashBanksMovementsMassiveUpdateRequest
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ListCashBanksMovements);