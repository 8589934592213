import React from 'react';
import { Backdrop, Button, ButtonGroup, Checkbox, CircularProgress, createStyles, FormControl, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Select, TextField, Theme, Tooltip, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { generalBalancesRequest } from 'penta-redux/actions/accountantActions';
import history from '../../../../utils/history';
import './../reports.scss'
import FormFooter from 'penta-components/base/formFooter';
import { MessageBox } from 'penta-components/base/messageBox';
import { categoryEntity } from 'models/wharehouseProductsModels';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PdfView from 'penta-components/base/pdfView';
// import * as pdfMake from "pdfmake/build/pdfmake";
// import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
import * as XLSX from 'sheetjs-style';
import ScreenLoader from 'penta-components/base/screenLoader';

let pdfMake = require("pdfmake/build/pdfmake");
let saveAs = require('file-saver').saveAs;

const meses = [
    { key: '01', value: 'Enero' },
    { key: '02', value: 'Febrero' },
    { key: '03', value: 'Marzo' },
    { key: '04', value: 'Abril' },
    { key: '05', value: 'Mayo' },
    { key: '06', value: 'Junio' },
    { key: '07', value: 'Julio' },
    { key: '08', value: 'Agosto' },
    { key: '09', value: 'Septiembre' },
    { key: '10', value: 'Octubre' },
    { key: '11', value: 'Noviembre' },
    { key: '12', value: 'Diciembre' }
]

const useStyles = (theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        formControl: {
            margin: 0,
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    });

interface FormState {
    title: string,
    year: string,
    month: string,
    base64Pdf: string
}

class ReportGeneralBalances extends React.Component<any, FormState> {

    constructor(props: any) {
        super(props);
        this.state = {
            title: 'Reporte de Balance General',
            year: moment().format('yyyy').toString(),
            month: moment().format('MM').toString(),
            base64Pdf: ''
        }
    }

    onChangeRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            ...value
        }))
    }

    onGeneratePdf = () => {
        const data = this.props.accountantReducers.onlyOne;
        const { month, year } = this.state;
        const { dashboardReducers } = this.props;
        const mes = meses.find((x: any) => x.key === month).value;
        const company = (JSON.parse(localStorage.getItem('tables')).companys.find((x: any) => x.taxident === dashboardReducers.currentCompany).bussname as string).toUpperCase()

        const totalActivoCorriente = data.filter(x => ['10','31','12','14','16','18','20','25'].includes(x.accaccou)).reduce((x, y) => x + y.totalacc, 0);
        const totalActivoNoCorriente = data.filter(x => ['33','39','34'].includes(x.accaccou)).reduce((x, y) => x + y.totalacc, 0);
        const totalPasivoCorriente = data.filter(x => ['40','41','42','44'].includes(x.accaccou)).reduce((x, y) => x + y.totalacc, 0) - (data.find(x => x.accaccou === '415')?.totalacc || 0);
        const totalPasivoNoCorriente = data.filter(x => ['46','415'].includes(x.accaccou)).reduce((x, y) => x + y.totalacc, 0);
        const resultadoEjercicio = totalActivoCorriente - (totalPasivoCorriente*-1) + totalActivoNoCorriente - (totalPasivoNoCorriente*-1) + data.filter(x => ['50','52','57','58','59'].includes(x.accaccou)).reduce((x, y) => x - (y.totalacc*-1), 0);
        const totalPatrimonio = data.filter(x => ['50','52','57','58','59'].includes(x.accaccou)).reduce((x, y) => x + (y.totalacc*-1), 0) + resultadoEjercicio;

        const totalActivo = totalActivoCorriente + totalActivoNoCorriente;
        const totalPasivoPatrimonio = (totalPasivoCorriente*-1) + (totalPasivoNoCorriente*-1) + totalPatrimonio;

        const pdfObject: any = {
            pageSize: 'A4',
            pageOrientation: 'portrait',
            pageMargins: [ 20, 110, 20, 20 ],
            header: [
                {
                    text: ' ', lineHeight: 2
                },
                {
                    margin: [20, 0, 20, 0],
                    text: 'LIBRO DE INVENTARIOS Y BALANCES\nBALANCE GENERAL',
                    style: {
                        bold: true,
                        fontSize: 9,
                        alignment: 'center'
                    }
                },
                {
                    margin: [20, 15, 0, 0],
                    text: `EJERCICIO : ${year}-${mes.toUpperCase()}`,
                    style: {
                        bold: true,
                        fontSize: 8
                    }
                },
                {
                    margin: [20, 0, 0, 0],
                    text: 'RUC : ' + dashboardReducers.currentCompany,
                    style: {
                        bold: true,
                        fontSize: 8
                    }
                },
                {
                    margin: [20, 0, 0, 0],
                    text: 'RAZON SOCIAL : ' + company.toUpperCase(),
                    style: {
                        bold: true,
                        fontSize: 8
                    }
                }
            ],
            content: [
                {
                    columns: [
                        {
                            stack: [
                                { text: 'ACTIVO', fontSize: 7.5, bold: true, lineHeight: 2 },
                                {
                                    table: {
                                        headerRows: 0,
                                        widths: [ 180, '*' ],
                                        body: [
                                            [ 
                                                { text: 'ACTIVO CORRIENTE', fontSize: 8, colSpan: 1, border: [false, false, false, true] },
                                                { text: 'S/', fontSize: 8, alignment: 'center', colSpan: 1, border: [false, false, false, true] }
                                            ],
                                            [ 
                                                { text: 'Caja y Bancos', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                                { text: String.formatNumber2.new(data.find(x => x.accaccou === '10')?.totalacc || 0), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] }
                                            ],
                                            [ 
                                                { text: 'Valores Negociables', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                                { text: String.formatNumber2.new(data.find(x => x.accaccou === '31')?.totalacc || 0), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] }
                                            ],
                                            [ 
                                                { text: 'Cuentas por Cobrar Negociables', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                                { text: String.formatNumber2.new(data.find(x => x.accaccou === '12')?.totalacc || 0), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] }
                                            ],
                                            [ 
                                                { text: 'Cuentas por Cobrar a Vinculadas', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                                { text: String.formatNumber2.new(data.find(x => x.accaccou === '14')?.totalacc || 0), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] }
                                            ],
                                            [ 
                                                { text: 'Otras Cuentas Por Cobrar', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                                { text: String.formatNumber2.new(data.find(x => x.accaccou === '16')?.totalacc || 0), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] }
                                            ],
                                            [ 
                                                { text: 'Gastos Pagados Por Anticipado', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                                { text: String.formatNumber2.new(data.find(x => x.accaccou === '18')?.totalacc || 0), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] }
                                            ],
                                            [ 
                                                { text: 'Existencias', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                                { text: String.formatNumber2.new(data.filter(x => ['20','25'].includes(x.accaccou)).reduce((x, y) => x + y.totalacc, 0)), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] }
                                            ],
                                            [ 
                                                { text: 'Total Activo Corriente', style: 'tableDetailLeftBold', colSpan: 1, border: [false, false, false, false] },
                                                { text: String.formatNumber2.new(totalActivoCorriente), style: 'tableDetailRightBold', colSpan: 1, border: [false, true, false, false] }
                                            ]
                                            
                                        ]
                                    }
                                },
                                { text: ' ', lineHeight: 2 },
                                {
                                    table: {
                                        headerRows: 0,
                                        widths: [ 180, '*' ],
                                        body: [
                                            [ 
                                                { text: 'ACTIVO NO CORRIENTE', fontSize: 8, colSpan: 1, border: [false, false, false, true] },
                                                { text: 'S/', fontSize: 8, alignment: 'center', colSpan: 1, border: [false, false, false, true] }
                                            ],
                                            [ 
                                                { text: 'Inmueb. Maq. y Equipos', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                                { text: String.formatNumber2.new(data.find(x => x.accaccou === '33')?.totalacc || 0), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] }
                                            ],
                                            [ 
                                                { text: 'Deprec. Acumulada', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                                { text: String.formatNumber2.new(data.find(x => x.accaccou === '39')?.totalacc || 0), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] }
                                            ],
                                            [ 
                                                { text: 'Intangibles', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                                { text: String.formatNumber2.new(data.find(x => x.accaccou === '34')?.totalacc || 0), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] }
                                            ],
                                            [ 
                                                { text: 'Otros Activos', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                                { text: '0.00', style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] }
                                            ],
                                            [ 
                                                { text: 'Total Activo No Corriente', style: 'tableDetailLeftBold', colSpan: 1, border: [false, false, false, false] },
                                                { text: String.formatNumber2.new(totalActivoNoCorriente), style: 'tableDetailRightBold', colSpan: 1, border: [false, true, false, false] }
                                            ]
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            stack: [
                                { text: 'PASIVO', fontSize: 7.5, bold: true, lineHeight: 2 },
                                {
                                    table: {
                                        headerRows: 0,
                                        widths: [ 180, '*' ],
                                        body: [
                                            [ 
                                                { text: 'PASIVO CORRIENTE', fontSize: 8, colSpan: 1, border: [false, false, false, true] },
                                                { text: 'S/', fontSize: 8, alignment: 'center', colSpan: 1, border: [false, false, false, true] }
                                            ],
                                            [ 
                                                { text: 'Sobregiros Bancarios', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                                { text: '0.00', style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] }
                                            ],
                                            [ 
                                                { text: 'Anticipos Clientes', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                                { text: '0.00', style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] }
                                            ],
                                            [ 
                                                { text: 'Tributos Por Pagar', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                                { text: String.formatNumber2.new((data.find(x => x.accaccou === '40')?.totalacc || 0)*-1), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] }
                                            ],
                                            [ 
                                                { text: 'Rem. y Part. a Pagar', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                                { text: String.formatNumber2.new(((data.find(x => x.accaccou === '41')?.totalacc || 0) - (data.find(x => x.accaccou === '415')?.totalacc || 0))*-1), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] }
                                            ],
                                            [ 
                                                { text: 'Cuentas Por Cobrar Comerciales', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                                { text: String.formatNumber2.new((data.find(x => x.accaccou === '42')?.totalacc || 0)*-1), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] }
                                            ],
                                            [ 
                                                { text: 'Cuentas Por Pagar a Vinculadas', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                                { text: String.formatNumber2.new((data.find(x => x.accaccou === '44')?.totalacc || 0)*-1), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] }
                                            ],
                                            [ 
                                                { text: 'Otras Cuentas Por Pagar', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                                { text: '0.00', style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] }
                                            ],
                                            [ 
                                                { text: 'Total Pasivo Corriente', style: 'tableDetailLeftBold', colSpan: 1, border: [false, false, false, false] },
                                                { text: String.formatNumber2.new(totalPasivoCorriente*-1), style: 'tableDetailRightBold', colSpan: 1, border: [false, true, false, false] }
                                            ]
                                        ]
                                    }
                                },
                                { text: ' ', lineHeight: 2 },
                                {
                                    table: {
                                        headerRows: 0,
                                        widths: [ 180, '*' ],
                                        body: [
                                            [ 
                                                { text: 'PASIVO NO CORRIENTE', fontSize: 8, colSpan: 1, border: [false, false, false, true] },
                                                { text: 'S/', fontSize: 8, alignment: 'center', colSpan: 1, border: [false, false, false, true] }
                                            ],
                                            [ 
                                                { text: 'Ctas. Por Pagar Diversas', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                                { text: String.formatNumber2.new((data.find(x => x.accaccou === '46')?.totalacc || 0)*-1), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] }
                                            ],
                                            [ 
                                                { text: 'Benef. Sociales Trab.', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                                { text: String.formatNumber2.new((data.find(x => x.accaccou === '415')?.totalacc || 0)*-1), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] }
                                            ],
                                            [ 
                                                { text: 'Provisiones Diversas', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                                { text: '0.00', style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] }
                                            ],
                                            [ 
                                                { text: 'Ganancias Diferidas', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                                { text: '0.00', style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] }
                                            ],
                                            [ 
                                                { text: 'Total Pasivo No Corriente', style: 'tableDetailLeftBold', colSpan: 1, border: [false, false, false, false] },
                                                { text: String.formatNumber2.new(totalPasivoNoCorriente*-1), style: 'tableDetailRightBold', colSpan: 1, border: [false, true, false, false] }
                                            ]
                                        ]
                                    }
                                },
                                { text: ' ', lineHeight: 2 },
                                {
                                    table: {
                                        headerRows: 0,
                                        widths: [ 180, '*' ],
                                        body: [
                                            [ 
                                                { text: 'PATRIMONIO', fontSize: 8, colSpan: 1, border: [false, false, false, true] },
                                                { text: 'S/', fontSize: 8, alignment: 'center', colSpan: 1, border: [false, false, false, true] }
                                            ],
                                            [ 
                                                { text: 'Capital Social', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                                { text: String.formatNumber2.new((data.find(x => x.accaccou === '50')?.totalacc || 0)*-1), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] }
                                            ],
                                            [ 
                                                { text: 'Acciones Laborales', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                                { text: '0.00', style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] }
                                            ],
                                            [ 
                                                { text: 'Capital Adicional', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                                { text: String.formatNumber2.new((data.find(x => x.accaccou === '52')?.totalacc || 0)*-1), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] }
                                            ],
                                            [ 
                                                { text: 'Excd De Ravaluacion', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                                { text: String.formatNumber2.new((data.find(x => x.accaccou === '57')?.totalacc || 0)*-1), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] }
                                            ],
                                            [ 
                                                { text: 'Reservas', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                                { text: String.formatNumber2.new((data.find(x => x.accaccou === '58')?.totalacc || 0)*-1), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] }
                                            ],
                                            [ 
                                                { text: 'Resultados Acumulados', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                                { text: String.formatNumber2.new((data.find(x => x.accaccou === '59')?.totalacc || 0)*-1), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] }
                                            ],
                                            [ 
                                                { text: 'Resultados Del Ejercicio', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                                { text: String.formatNumber2.new(resultadoEjercicio), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] }
                                            ],
                                            [ 
                                                { text: 'Total Patrimonio', style: 'tableDetailLeftBold', colSpan: 1, border: [false, false, false, false] },
                                                { text: String.formatNumber2.new(totalPatrimonio), style: 'tableDetailRightBold', colSpan: 1, border: [false, true, false, false] }
                                            ]
                                        ]
                                    }
                                }
                            ]
                        }
                    ]
                },
                {
                    columns: [
                        {
                            stack: [
                                { text: ' ', lineHeight: 2 },
                                {
                                    table: {
                                        headerRows: 0,
                                        widths: [ 180, '*' ],
                                        body: [
                                            [ 
                                                { text: 'TOTAL ACTIVO', style: 'tableDetailLeftBold', colSpan: 1, border: [false, false, false, false] },
                                                { text: String.formatNumber2.new(totalActivo), style: 'tableDetailRightBold', colSpan: 1, border: [false, true, false, false] }
                                            ]
                                        ]
                                    }
                                },
                            ]
                        },
                        {
                            stack: [
                                { text: ' ', lineHeight: 2 },
                                {
                                    table: {
                                        headerRows: 0,
                                        widths: [ 180, '*' ],
                                        body: [
                                            [ 
                                                { text: 'TOTAL PASIVO Y PATRIMONIO', style: 'tableDetailLeftBold', colSpan: 1, border: [false, false, false, false] },
                                                { text: String.formatNumber2.new(totalPasivoPatrimonio), style: 'tableDetailRightBold', colSpan: 1, border: [false, true, false, false] }
                                            ]
                                        ]
                                    }
                                },
                            ]
                        }
                    ]
                }
            ],
            styles: {
            headerTitle: {
                bold: true,
                fontSize: 7,
                lineHeight: 1.2
            },
            table: {
                layout: 'headerLineOnly',
                fontSize: 6,
                alignment: 'center'
            },
            subTable: {
                layout: 'headerLineOnly',
                fontSize: 6,
                alignment: 'left'
            },
            tableDetailLeft: {
                layout: 'headerLineOnly',
                fontSize: 6,
                alignment: 'left'
            },
            tableDetailRight: {
                layout: 'headerLineOnly',
                fontSize: 6,
                alignment: 'right'
            },
            tableDetailLeftBold: {
                layout: 'headerLineOnly',
                fontSize: 6,
                alignment: 'left',
                bold: true
            },
            tableDetailRightBold: {
                layout: 'headerLineOnly',
                fontSize: 6,
                alignment: 'right',
                bold: true
            }
            },
            defaultStyle: {
                columnGap: 20,
            },
        };

        pdfMake.fonts = {
            Roboto: {
                normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
                bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
                italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
                bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
              },
        }
        const pdfDocGenerator = pdfMake.createPdf(pdfObject);
        pdfDocGenerator.getDataUrl((data: any) => {
            // console.log(data);
            this.setState({
                base64Pdf: data
            })
        });

    }

    onGenerateExcel = () => {
        try {
            const data = this.props.accountantReducers.onlyOne;
            const { month, year } = this.state;
            const { dashboardReducers } = this.props;
            const mes = meses.find((x: any) => x.key === month).value;
            const company = (JSON.parse(localStorage.getItem('tables')).companys.find((x: any) => x.taxident === dashboardReducers.currentCompany).bussname as string).toUpperCase()

            const totalActivoCorriente = data.filter(x => ['10','31','12','14','16','18','20','25'].includes(x.accaccou)).reduce((x, y) => x + y.totalacc, 0);
            const totalActivoNoCorriente = data.filter(x => ['33','39','34'].includes(x.accaccou)).reduce((x, y) => x + y.totalacc, 0);
            const totalPasivoCorriente = data.filter(x => ['40','41','42','44'].includes(x.accaccou)).reduce((x, y) => x + y.totalacc, 0) - (data.find(x => x.accaccou === '415')?.totalacc || 0);
            const totalPasivoNoCorriente = data.filter(x => ['46','415'].includes(x.accaccou)).reduce((x, y) => x + y.totalacc, 0);
            const resultadoEjercicio = totalActivoCorriente - (totalPasivoCorriente*-1) + totalActivoNoCorriente - (totalPasivoNoCorriente*-1) + data.filter(x => ['50','52','57','58','59'].includes(x.accaccou)).reduce((x, y) => x - (y.totalacc*-1), 0);
            const totalPatrimonio = data.filter(x => ['50','52','57','58','59'].includes(x.accaccou)).reduce((x, y) => x + (y.totalacc*-1), 0) + resultadoEjercicio;

            const totalActivo = totalActivoCorriente + totalActivoNoCorriente;
            const totalPasivoPatrimonio = (totalPasivoCorriente*-1) + (totalPasivoNoCorriente*-1) + totalPatrimonio;

            const tableBody: any = [
                {
                    A: null,
                    B: 'LIBRO DE INVENTARIOS Y BALANCES',
                    C: null,
                    D: null,
                    E: null,
                    F: null
                },
                {
                    A: null,
                    B: 'BALANCE GENERAL',
                    C: null,
                    D: null,
                    E: null,
                    F: null
                },
                {
                    A: null,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null
                },
                {
                    A: null,
                    B: `EJERCICIO : ${year}-${mes.toUpperCase()}`,
                    C: null,
                    D: null,
                    E: null,
                    F: null
                },
                {
                    A: null,
                    B: 'RUC : ' + dashboardReducers.currentCompany,
                    C: null,
                    D: null,
                    E: null,
                    F: null
                },
                {
                    A: null,
                    B: 'RAZON SOCIAL : ' + company.toUpperCase(),
                    C: null,
                    D: null,
                    E: null,
                    F: null
                },
                {
                    A: null,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null
                },
                {
                    A: null,
                    B: 'ACTIVO',
                    C: null,
                    D: null,
                    E: 'PASIVO',
                    F: null
                },
                {
                    A: null,
                    B: 'ACTIVO CORRIENTE',
                    C: 'S/.',
                    D: null,
                    E: 'PASIVO CORRIENTE',
                    F: 'S/.'
                },
                {
                    A: null,
                    B: 'Caja y Bancos',
                    C: String.formatNumber.new(data.find(x => x.accaccou === '10')?.totalacc || 0),
                    D: null,
                    E: 'Sobregiros Bancarios',
                    F: '0.00'
                },
                {
                    A: null,
                    B: 'Valores Negociables',
                    C: String.formatNumber.new(data.find(x => x.accaccou === '31')?.totalacc || 0),
                    D: null,
                    E: 'Anticipos Clientes',
                    F: '0.00'
                },
                {
                    A: null,
                    B: 'Cuentas por Cobrar Negociables',
                    C: String.formatNumber.new(data.find(x => x.accaccou === '12')?.totalacc || 0),
                    D: null,
                    E: 'Tributos Por Pagar',
                    F: String.formatNumber.new((data.find(x => x.accaccou === '40')?.totalacc || 0)*-1)
                },
                {
                    A: null,
                    B: 'Cuentas por Cobrar a Vinculadas',
                    C: String.formatNumber.new(data.find(x => x.accaccou === '14')?.totalacc || 0),
                    D: null,
                    E: 'Rem. y Part. a Pagar',
                    F: String.formatNumber.new(((data.find(x => x.accaccou === '41')?.totalacc || 0) - (data.find(x => x.accaccou === '415')?.totalacc || 0))*-1)
                },
                {
                    A: null,
                    B: 'Otras Cuentas Por Cobrar',
                    C: String.formatNumber.new(data.find(x => x.accaccou === '16')?.totalacc || 0),
                    D: null,
                    E: 'Cuentas Por Cobrar Comerciales',
                    F: String.formatNumber.new((data.find(x => x.accaccou === '42')?.totalacc || 0)*-1)
                },
                {
                    A: null,
                    B: 'Gastos Pagados Por Anticipado',
                    C: String.formatNumber.new(data.find(x => x.accaccou === '18')?.totalacc || 0),
                    D: null,
                    E: 'Cuentas Por Pagar a Vinculadas',
                    F: String.formatNumber.new((data.find(x => x.accaccou === '44')?.totalacc || 0)*-1)
                },
                {
                    A: null,
                    B: 'Existencias',
                    C: String.formatNumber.new(data.filter(x => ['20','25'].includes(x.accaccou)).reduce((x, y) => x + y.totalacc, 0)),
                    D: null,
                    E: 'Otras Cuentas Por Pagar',
                    F: '0.00'
                },
                {
                    A: null,
                    B: 'Total Activo Corriente',
                    C: String.formatNumber.new(totalActivoCorriente),
                    D: null,
                    E: 'Total Pasivo Corriente',
                    F: String.formatNumber.new(totalPasivoCorriente*-1)
                },
                {
                    A: null,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null
                },
                {
                    A: null,
                    B: 'ACTIVO NO CORRIENTE',
                    C: 'S/.',
                    D: null,
                    E: 'PASIVO NO CORRIENTE',
                    F: 'S/.'
                },
                {
                    A: null,
                    B: 'Inmueb. Maq. y Equipos',
                    C: String.formatNumber.new(data.find(x => x.accaccou === '33')?.totalacc || 0),
                    D: null,
                    E: 'Ctas. Por Pagar Diversas',
                    F: String.formatNumber.new(((data.find(x => x.accaccou === '46')?.totalacc || 0) + (data.find(x => x.accaccou === '47')?.totalacc || 0))*-1)
                },
                {
                    A: null,
                    B: 'Deprec. Acumulada',
                    C: String.formatNumber.new(data.find(x => x.accaccou === '39')?.totalacc || 0),
                    D: null,
                    E: 'Benef. Sociales Trab.',
                    F: String.formatNumber.new((data.find(x => x.accaccou === '415')?.totalacc || 0)*-1)
                },
                {
                    A: null,
                    B: 'Intangibles',
                    C: String.formatNumber.new(data.find(x => x.accaccou === '34')?.totalacc || 0),
                    D: null,
                    E: 'Provisiones Diversas',
                    F: '0.00'
                },
                {
                    A: null,
                    B: 'Otros Activos',
                    C: '0.00',
                    D: null,
                    E: 'Ganancias Diferidas',
                    F: '0.00'
                },
                {
                    A: null,
                    B: 'Total Activo No Corriente',
                    C: String.formatNumber.new(totalActivoNoCorriente),
                    D: null,
                    E: 'Total Pasivo No Corriente',
                    F: String.formatNumber.new(totalPasivoNoCorriente*-1)
                },
                {
                    A: null,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null
                },
                {
                    A: null,
                    B: null,
                    C: null,
                    D: null,
                    E: 'PATRIMONIO',
                    F: 'S/.'
                },
                {
                    A: null,
                    B: null,
                    C: null,
                    D: null,
                    E: 'Capital Social',
                    F: String.formatNumber.new((data.find(x => x.accaccou === '50')?.totalacc || 0)*-1)
                },
                {
                    A: null,
                    B: null,
                    C: null,
                    D: null,
                    E: 'Acciones Laborales',
                    F: '0.00'
                },
                {
                    A: null,
                    B: null,
                    C: null,
                    D: null,
                    E: 'Capital Adicional',
                    F: String.formatNumber.new((data.find(x => x.accaccou === '52')?.totalacc || 0)*-1)
                },
                {
                    A: null,
                    B: null,
                    C: null,
                    D: null,
                    E: 'Excd De Ravaluacion',
                    F: String.formatNumber.new((data.find(x => x.accaccou === '57')?.totalacc || 0)*-1)
                },
                {
                    A: null,
                    B: null,
                    C: null,
                    D: null,
                    E: 'Reservas',
                    F: String.formatNumber.new((data.find(x => x.accaccou === '58')?.totalacc || 0)*-1)
                },
                {
                    A: null,
                    B: null,
                    C: null,
                    D: null,
                    E: 'Resultados Acumulados',
                    F: String.formatNumber.new((data.find(x => x.accaccou === '59')?.totalacc || 0)*-1)
                },
                {
                    A: null,
                    B: null,
                    C: null,
                    D: null,
                    E: 'Resultados Del Ejercicio',
                    F: String.formatNumber.new(resultadoEjercicio)
                },
                {
                    A: null,
                    B: null,
                    C: null,
                    D: null,
                    E: 'Total Patrimonio',
                    F: String.formatNumber.new(totalPatrimonio)
                },
                {
                    A: null,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null
                },
                {
                    A: null,
                    B: 'TOTAL ACTIVO',
                    C: String.formatNumber.new(totalActivo),
                    D: null,
                    E: 'TOTAL PASIVO Y PATRIMONIO',
                    F: String.formatNumber.new(totalPasivoPatrimonio)
                }
            ];

            const header = [
                'A',
                'B',
                'C',
                'D',
                'E',
                'F'
            ];


            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(tableBody, {header, skipHeader: true});

            ws["!cols"] = [
                {wpx:10},{wpx:200},{wpx:80},{wpx:20},{wpx:200},{wpx:80}
            ];
            ws['!rows'] = [
                {hpx: 18},{hpx: 18},{hpx: 16}
            ];

            const keys = Object.keys(ws);
            const rowsCount = tableBody.length;

            for (const iterator of keys) {
                if(ws[iterator].v?.match(/^[0-9,.-]+$/g) && iterator.match(/^(C|F)[0-9]+$/g)) {
                    ws[iterator].v = ws[iterator].v.replace(/,/g, '');
                    ws[iterator].t = 'n';
                    ws[iterator].z = '#,##0.00;[Black](#,##0.00);0.00;';
                }
                if(iterator !== '!ref' && iterator !== '!cols' && iterator !== '!rows') {
                    ws[iterator].s = {
                        font: {
                            name: 'Tahoma',
                            sz: 10
                        }
                    }
                }
            }

            for (let index = 3; index <= rowsCount; index++) {
                ws['!rows'].push({hpx: 16})
            }

            ws['B1'].s = {font:{name: 'Tahoma', sz: 11, bold: true}, alignment: { horizontal: 'center' }}
            ws['B2'].s = {font:{name: 'Tahoma', sz: 11, bold: true}, alignment: { horizontal: 'center' }}

            ws['B4'].s = {font:{name: 'Tahoma', sz: 10, bold: true}}
            ws['B5'].s = {font:{name: 'Tahoma', sz: 10, bold: true}}
            ws['B6'].s = {font:{name: 'Tahoma', sz: 10, bold: true}}

            ws['B8'].s = {font:{name: 'Tahoma', sz: 10, bold: true}}
            ws['E8'].s = {font:{name: 'Tahoma', sz: 10, bold: true}}

            ws['B9'].s = {font:{name: 'Tahoma', sz: 10}, border: {bottom: { style: 'thin', color: 'black'}}}
            ws['C9'].s = {font:{name: 'Tahoma', sz: 10}, alignment: { horizontal: 'center' }, border: {bottom: { style: 'thin', color: 'black'}}}
            ws['E9'].s = {font:{name: 'Tahoma', sz: 10}, border: {bottom: { style: 'thin', color: 'black'}}}
            ws['F9'].s = {font:{name: 'Tahoma', sz: 10}, alignment: { horizontal: 'center' }, border: {bottom: { style: 'thin', color: 'black'}}}

            ws['B17'].s = {font:{name: 'Tahoma', sz: 10, bold: true}}
            ws['C17'].s = {font:{name: 'Tahoma', sz: 10, bold: true}, border: {top: { style: 'thin', color: 'black'}}}
            ws['E17'].s = {font:{name: 'Tahoma', sz: 10, bold: true}}
            ws['F17'].s = {font:{name: 'Tahoma', sz: 10, bold: true}, border: {top: { style: 'thin', color: 'black'}}}

            ws['B19'].s = {font:{name: 'Tahoma', sz: 10}, border: {bottom: { style: 'thin', color: 'black'}}}
            ws['C19'].s = {font:{name: 'Tahoma', sz: 10}, alignment: { horizontal: 'center' }, border: {bottom: { style: 'thin', color: 'black'}}}
            ws['E19'].s = {font:{name: 'Tahoma', sz: 10}, border: {bottom: { style: 'thin', color: 'black'}}}
            ws['F19'].s = {font:{name: 'Tahoma', sz: 10}, alignment: { horizontal: 'center' }, border: {bottom: { style: 'thin', color: 'black'}}}

            ws['B24'].s = {font:{name: 'Tahoma', sz: 10, bold: true}}
            ws['C24'].s = {font:{name: 'Tahoma', sz: 10, bold: true}, border: {top: { style: 'thin', color: 'black'}}}
            ws['E24'].s = {font:{name: 'Tahoma', sz: 10, bold: true}}
            ws['F24'].s = {font:{name: 'Tahoma', sz: 10, bold: true}, border: {top: { style: 'thin', color: 'black'}}}

            ws['E26'].s = {font:{name: 'Tahoma', sz: 10}, border: {bottom: { style: 'thin', color: 'black'}}}
            ws['F26'].s = {font:{name: 'Tahoma', sz: 10}, border: {bottom: { style: 'thin', color: 'black'}}}

            ws['E34'].s = {font:{name: 'Tahoma', sz: 10, bold: true}}
            ws['F34'].s = {font:{name: 'Tahoma', sz: 10, bold: true}, border: {top: { style: 'thin', color: 'black'}}}

            ws['B36'].s = {font:{name: 'Tahoma', sz: 10, bold: true}}
            ws['C36'].s = {font:{name: 'Tahoma', sz: 10, bold: true}, border: {top: { style: 'thin', color: 'black'}}}
            ws['E36'].s = {font:{name: 'Tahoma', sz: 10, bold: true}}
            ws['F36'].s = {font:{name: 'Tahoma', sz: 10, bold: true}, border: {top: { style: 'thin', color: 'black'}}}

            ws["!merges"] = [
                { s: { r: 0, c: 1 }, e: { r: 0, c: 5 } },
                { s: { r: 1, c: 1 }, e: { r: 1, c: 5 } }
            ];

            XLSX.utils.book_append_sheet(wb, ws, "Balance general");

            var wbout = XLSX.write(wb, {bookType:'xlsx', type: 'binary', cellStyles: true});

            const s2ab = function(s) {
                var buf = new ArrayBuffer(s.length);
                var view = new Uint8Array(buf);
                for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
                return buf;
            }

            saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), `balance-general-${year}-${month}.xlsx`);

        } catch(err) {
            console.log(err);
        }
    }

    render() {

        const { title, year, month, base64Pdf } = this.state;
        const { loading, list, tables, failed, failedTitle, failedContent } = this.props.accountantReducers;
        const { classes } = this.props;

        return (
            <div style={{overflow: 'auto', height: '100%'}}>
                <Paper className='form__container'>
                    <Typography variant='h5'>{ title }</Typography>
                    <br/>
                    { failed && <MessageBox title={failedTitle} content={failedContent}/> }
                    <div style={{ display: 'flex' }}>
                        <form noValidate autoComplete="off" style={{ flex: 1, paddingRight: '15px', paddingTop: '2em' }}>
                            <Grid container spacing={2} >
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_año">Año</InputLabel>
                                            <Select
                                                labelId="lbl_año"
                                                id="year"
                                                className='select'
                                                label="Año"
                                                fullWidth
                                                value={ year }
                                                onChange={(e: any) => this.onChangeRoot({'year': e.target.value})}
                                            >
                                                <MenuItem value='2030'>2030</MenuItem>
                                                <MenuItem value='2029'>2029</MenuItem>
                                                <MenuItem value='2028'>2028</MenuItem>
                                                <MenuItem value='2027'>2027</MenuItem>
                                                <MenuItem value='2026'>2026</MenuItem>
                                                <MenuItem value='2025'>2025</MenuItem>
                                                <MenuItem value='2026'>2026</MenuItem>
                                                <MenuItem value='2025'>2025</MenuItem>
                                                <MenuItem value='2024'>2024</MenuItem>
                                                <MenuItem value='2023'>2023</MenuItem>
                                                <MenuItem value='2022'>2022</MenuItem>
                                                <MenuItem value='2021'>2021</MenuItem>
                                                <MenuItem value='2020'>2020</MenuItem>
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_mes">Mes</InputLabel>
                                            <Select
                                                labelId="lbl_mes"
                                                id="month"
                                                className='select'
                                                label="Mes"
                                                fullWidth
                                                value={ month }
                                                onChange={(e: any) => this.onChangeRoot({'month': e.target.value})}
                                            >
                                                {
                                                    meses.map((x: any) => 
                                                        <MenuItem value={x.key} key={x.key}>{x.value}</MenuItem>
                                                    )
                                                }
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} style={{ paddingTop: '50px' }}>
                                    <Button variant="contained" fullWidth color="primary" onClick={() => { 
                                            const balacode = [];
                                            for(let i = 0; i <= +month; i++) {
                                                balacode.push(`${year}${i.toString().padStart(2, '0')}`)
                                            }
                                            this.props.generalBalancesRequest(year, balacode, this.onGeneratePdf) 
                                        }}>
                                        Generar Reporte
                                    </Button>
                                </Grid>
                                <Grid item xs={12} style={{ paddingTop: '10px' }}>
                                    <Button variant="contained" fullWidth color="inherit" onClick={() => { 
                                            const balacode = [];
                                            for(let i = 0; i <= +month; i++) {
                                                balacode.push(`${year}${i.toString().padStart(2, '0')}`)
                                            }
                                            this.props.generalBalancesRequest(year, balacode, this.onGenerateExcel) 
                                        }}>
                                        Generar Reporte EXCEL
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                        <PdfView data={base64Pdf} style={{ flex: 2 }}/>
                    </div>
                    <br/>
                    {/* <FormFooter onCancel={ this.onCancel } onSubmit={ this.onSubmit }/> */}
                </Paper>
                <ScreenLoader enabled={loading}/>
            </div>
        );
    }
}

const accountantReducers = 'accountantReducers';
const dashboardReducers = 'dashboardReducers';

const mapStateToProps = (state: any) => ({
    accountantReducers: state.get(accountantReducers),
    dashboardReducers: state.get(dashboardReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        generalBalancesRequest
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(ReportGeneralBalances));