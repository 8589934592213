import Checkbox from 'penta-components/controls/checkbox';
import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import Skeleton from './skeleton';
// import './base.scss';

const useStyles = createUseStyles({
    container: {
        width: '100%',
        border: '0.063rem solid #DFDFDF',
        borderRadius: '1.25rem',
        boxShadow: '0 0.188rem 0.375rem #00000029'
    },
    tableContainer: {
        height: '30.85rem', 
        overflow: 'auto', 
        borderRadius: '1.25rem 1.25rem 0rem 0rem'
    },
    thHead: {},
    thBody: {},
    table: {
        width: '100%',
        borderCollapse: 'collapse',
        borderSpacing: 0,
        fontSize: '0.75rem',
        fontFamily: '"Work Sans", sans-serif',
        color: '#3B3B3B',
        '& thead': {
            boxShadow: '0 0.188rem 0.375rem #00000029'
        },
        '& $thHead': {
            padding: '0.938rem 1.875rem 0.938rem 1.875rem',
            fontWeight: 600,
            whiteSpace: 'nowrap',
            position: 'sticky',
            top: 0,
            zIndex: 5,
            backgroundColor: '#ffffff'
        },
        '& $thBody': {
            padding: '0.938rem 1.875rem 0.938rem 1.875rem',
            fontWeight: 400,
            whiteSpace: 'nowrap'
        },
        '& tbody': {
            '& tr': {
                cursor: 'pointer',
                borderBottom: '0.063rem solid #DFDFDF',
                '&:hover': {
                    backgroundColor: '#ECF2FF'
                }
            }
        }
    },
    footer: {
        padding: '1.563rem 1.25rem',
        textAlign: 'right',
        fontSize: '0.75rem',
        fontFamily: '"Work Sans", sans-serif'
    }
})

interface ListView {
    header: Array<any>
    body: Array<any>
    selectAction: any
    selectAllAction: any
    total?: number
    currentPage?: number
    callMoreItems?: () => void
    onNavigate?: (page: number) => void
}

const ListView = (props: ListView) => {
    const classes = useStyles();
    const [bodyLength, setBodyLength] = React.useState(0);
    const [checkAll, setCheckAll] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const onSelect = (key: number) => {
        props.selectAction(key);
    }

    const onSelectAll = (checked: boolean) => {
        setCheckAll(checked);
        props.selectAllAction(checked);
    }

    const onScroll = (e) => {
        const element = e.target;

        if(element.scrollHeight - element.scrollTop === element.clientHeight && props.total !== bodyLength && !loading) {
            setLoading(true);
            props.callMoreItems();
        }
    }

    const getValueFromNestedKeys = (item: any, text: string) => {
        try {
            const keys = text.split('.');
            let newItem = Object.assign({}, item);
            for (const iterator of keys) {
                newItem = newItem[iterator];
            }
            return newItem;
        }
        catch { }
    }

    const renderRows = () => {
        return props.body.map((item, key) => 
            <tr key={`body_listview_${key}`}>
                <td className={classes.thBody}>
                    <Checkbox
                        onClick={() => onSelect(key)}
                        checked={item.select === undefined ? false : item.select}
                    />
                </td>
                {
                    props.header.map((hitem, key) => 
                        <td className={classes.thBody} style={typeof(hitem.style) == "function" ? hitem.style(getValueFromNestedKeys, item) : {}} key={`${hitem.key}_${key}`}>
                            {
                                typeof(hitem.value) === "function" &&
                                hitem.value(getValueFromNestedKeys, item)
                                ||
                                getValueFromNestedKeys(item, hitem.key)
                            }
                        </td>
                    )
                }
            </tr>
        )
    }

    useEffect(() => {
        setBodyLength(props.body.length);
        setLoading(false);
    }, [props.body])

    return (
        <div className={classes.container}>
            <div className={classes.tableContainer} onScroll={onScroll}>
                <table className={classes.table}>
                    <thead>
                        <tr>
                            <th className={classes.thHead} style={{paddingRight: '1.875rem'}}>
                                <Checkbox checked={checkAll} onClick={(e: any) => onSelectAll(e.target.checked)}/>
                            </th>
                            {
                                props.header.map((item, key) => 
                                    <th key={`header_listview_${key}`} className={classes.thHead}>{item.title}</th>
                                )
                            }
                        </tr>
                    </thead>
                    <tbody>
                        { renderRows() }
                        {
                            loading &&
                            <tr>
                                <Skeleton.Row/>
                                {
                                    props.header.map((hitem, key) => 
                                        <Skeleton.Row key={key}/>
                                    )
                                }
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
            <div className={classes.footer}>
                Total de items: {(props.body.length + ' / ' + props.total) || props.body.length}
            </div>
        </div>
    )
};

ListView.defaultProps = {
    total: null,
    callMoreItems: () => {}
}

export default ListView;