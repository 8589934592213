export const USERS_INIT_FORM_REQUEST = 'USERS_INIT_FORM_REQUEST';
export const USERS_INIT_FORM_REQUEST_SUCCESS = 'USERS_INIT_FORM_REQUEST_SUCCESS';
export const USERS_INIT_FORM_REQUEST_FAILED = 'USERS_INIT_FORM_REQUEST_FAILED';
export const USERS_INIT_UPDATE_FORM_REQUEST = 'USERS_INIT_UPDATE_FORM_REQUEST';
export const USERS_INIT_UPDATE_FORM_REQUEST_SUCCESS = 'USERS_INIT_UPDATE_FORM_REQUEST_SUCCESS';
export const USERS_INIT_UPDATE_FORM_REQUEST_FAILED = 'USERS_INIT_UPDATE_FORM_REQUEST_FAILED';
export const USERS_GET_ALL_RECORDS_REQUEST = 'USERS_GET_ALL_RECORDS_REQUEST';
export const USERS_GET_ALL_RECORDS_REQUEST_SUCCESS = 'USERS_GET_ALL_RECORDS_SUCCESS';
export const USERS_GET_ALL_RECORDS_REQUEST_FAILED = 'USERS_GET_ALL_RECORDS_FAILED';
export const USERS_REGISTER_REQUEST = 'USERS_REGISTER_REQUEST';
export const USERS_REGISTER_REQUEST_SUCCESS = 'USERS_REGISTER_REQUEST_SUCCESS';
export const USERS_REGISTER_REQUEST_FAILED = 'USERS_REGISTER_REQUEST_FAILED';
export const USERS_UPDATE_REQUEST = 'USERS_UPDATE_REQUEST';
export const USERS_UPDATE_REQUEST_SUCCESS = 'USERS_UPDATE_REQUEST_SUCCESS';
export const USERS_UPDATE_REQUEST_FAILED = 'USERS_UPDATE_REQUEST_FAILED';
export const USERS_DELETE_REQUEST = 'USERS_DELETE_REQUEST';
export const USERS_DELETE_REQUEST_SUCCESS = 'USERS_DELETE_REQUEST_SUCCESS';
export const USERS_DELETE_REQUEST_FAILED = 'USERS_DELETE_REQUEST_FAILED';

/*Components */
export const USERS_LIST_SELECT_ITEM = 'USERS_LIST_SELECT_ITEM';
export const USERS_LIST_SELECT_ALL_ITEM = 'USERS_LIST_SELECT_ALL_ITEM';
export const USERS_ACTION_FAILED = 'USERS_ACTION_FAILED';