import { Grid } from "@material-ui/core";
import React, { ChangeEvent } from "react";
import './base.scss';

interface InputProps {
    type: string
    value: string
    className?: string
    label?: string
    id: string,
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void
}

export const Input = (props: InputProps) => {

    const className = 'penta form ' + (props.className || '');

    return (
        <Grid container spacing={2} alignItems="center">
            {
                props.label &&
                <Grid item xs={12}>
                    <div style={{ display: 'flex' }}>
                        <label className="penta form">{props.label}</label>
                        <input id={props.id} className={className} style={{ flex: '1' }} type={props.type} value={props.value} onChange={props.onChange}/>
                    </div>
                    {/* <Grid item xs={2}>
                        <label>{props.label}</label>
                    </Grid>
                    <Grid item xs={10}>
                        <input id={props.id} className={className} type={props.type} value={props.value} onChange={props.onChange}/>
                    </Grid> */}
                </Grid>
                ||
                <Grid item xs={12}>
                    <input id={props.id} className={className} type={props.type} value={props.value} onChange={props.onChange}/>
                </Grid>
            }
        </Grid>
    )
}