import React from 'react';
import {createUseStyles} from 'react-jss'

const error = {
    'border': '0.1rem solid #dc1010',
    'marginBottom': '0rem',
    'hover': { 
        'borderColor': '#dc1010'
    },
    'focus': {
        'borderColor': '#dc1010'
    }
}

const normal = {
    'border': '0.1rem solid #dfdfdf',
    'marginBottom': '2rem',
    'hover': {
        'borderColor': '#3B3B3B'
    },
    'focus': {
        'borderColor': '#0060A6'
    }
}

const useStyles =  createUseStyles({
    container: {
        'display': 'flex',
        'flexDirection': 'column'
    },
    label: {
        'color': '#3B3B3B',
        'fontSize': '0.75rem',
        'fontFamily': '"Work Sans", sans-serif',
        'fontWeight': 600,
    },
    input: (props: any) => ({
        'margin': '1rem 0rem',
        'marginBottom': props.marginBottom,
        'border': props.border,
        'padding': '0.6rem',
        'borderRadius': '0.3rem',
        'color': '#3B3B3B',
        'fontSize': '0.75rem',
        'fontFamily': '"Work Sans", sans-serif',
        '&:hover': props.hover,
        '&:focus': props.focus
    }),
    labelError: {
        'paddingTop': '0.5rem',
        'paddingBottom': '2rem',
        'fontSize': '0.65rem',
        'color': '#dc1010',
        'fontFamily': '"Work Sans", sans-serif',
    }
});

interface TextInput {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    value: string | number | readonly string[]
    type?: 'text' | 'password' | 'date'
    label: string
    error?: boolean
    labelPosition?: 'top' | 'left'
    labelLeftWidth?: string
}

const TextInput = (props: TextInput) => {
    let propsStyle = {};
    propsStyle = {...propsStyle, ...(props.error ? error : normal)}
    const classes = useStyles(propsStyle);
    return (
        <div className={classes.container}>
            {
                props.labelPosition === 'top' &&
                <React.Fragment>
                    <label className={classes.label}>{props.label}</label>
                    <input className={classes.input} type={props.type} value={props.value} onChange={props.onChange}/>
                </React.Fragment>
            }
            {
                props.labelPosition === 'left' &&
                <div style={{display: 'grid', gridTemplateColumns: `${props.labelLeftWidth} calc(100% - ${props.labelLeftWidth})`, alignItems: 'center', width: '100%'}}>
                    <label className={classes.label}>{props.label}</label>
                    <input className={classes.input} style={{margin: '0', borderRadius: 0}} type={props.type} value={props.value} onChange={props.onChange}/>
                </div>
            }
            {
                props.error && <span className={classes.labelError}>{props.label} no puede estar vacio</span>
            }
        </div>
    )
}

TextInput.defaultProps = {
    labelPosition: 'left',
    labelLeftWidth: '5rem',
    type: 'text',
    error: false
}

export default TextInput;