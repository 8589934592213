import * as types  from 'redux/constants/usersConstants';
import { baseModels } from 'penta-models';

export const usersInitFormRequest = () => ({
    type: types.USERS_INIT_FORM_REQUEST
});

export const usersInitFormRequestSuccess = (tables: any) => ({
    type: types.USERS_INIT_FORM_REQUEST_SUCCESS,
    tables
});

export const usersInitFormRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.USERS_INIT_FORM_REQUEST_FAILED,
    titleError,
    titleContent
});

export const usersInitUpdateFormRequest = (username: string, callback: void) => ({
    type: types.USERS_INIT_UPDATE_FORM_REQUEST,
    username,
    callback
});

export const usersInitUpdateFormRequestSuccess = (tables: any, onlyOne: any) => ({
    type: types.USERS_INIT_UPDATE_FORM_REQUEST_SUCCESS,
    tables,
    onlyOne
});

export const usersInitUpdateFormRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.USERS_INIT_UPDATE_FORM_REQUEST_FAILED,
    titleError,
    titleContent
});

export const usersGetAllRecordsRequest = (body: baseModels.pentaRequestQuery) => ({
    type: types.USERS_GET_ALL_RECORDS_REQUEST,
    body,
});

export const usersGetAllRecordsRequestSuccess = (list: Array<any>, total: number) => ({
    type: types.USERS_GET_ALL_RECORDS_REQUEST_SUCCESS,
    list,
    total
});

export const usersGetAllRecordsRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.USERS_GET_ALL_RECORDS_REQUEST_FAILED,
    titleError,
    titleContent
});

export const usersRegisterRequest = (body: any, callback?: void) => ({
    type: types.USERS_REGISTER_REQUEST,
    body,
    callback
});

export const usersRegisterRequestSuccess = () => ({
    type: types.USERS_REGISTER_REQUEST_SUCCESS,
});

export const usersRegisterRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.USERS_REGISTER_REQUEST_FAILED,
    titleError,
    titleContent
});

export const usersUpdateRequest = (body: any) => ({
    type: types.USERS_UPDATE_REQUEST,
    body
});

export const usersUpdateRequestSuccess = () => ({
    type: types.USERS_UPDATE_REQUEST_SUCCESS,
});

export const usersUpdateRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.USERS_UPDATE_REQUEST_FAILED,
    titleError,
    titleContent
});

export const usersDeleteRequest = (username: Array<string>) => ({
    type: types.USERS_DELETE_REQUEST,
    username,
});

export const usersDeleteRequestSuccess = () => ({
    type: types.USERS_DELETE_REQUEST_SUCCESS,
});

export const usersDeleteRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.USERS_DELETE_REQUEST_FAILED,
    titleError,
    titleContent
});

/*Components */
export const usersListSelectItem = (key: number) => ({
    type: types.USERS_LIST_SELECT_ITEM,
    key
});

export const usersListSelectAllItem = (checked: boolean) => ({
    type: types.USERS_LIST_SELECT_ALL_ITEM,
    checked
});
export const usersActionFailed = (titleError: string, titleContent: string) => ({
    type: types.USERS_ACTION_FAILED,
    titleError,
    titleContent
});