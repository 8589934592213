import React from 'react';
import { Backdrop, Button, ButtonGroup, Checkbox, CircularProgress, createStyles, Dialog, FormControl, Grid, IconButton, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Select, Snackbar, TextField, Theme, Tooltip, Typography, WithStyles, withStyles } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { creditNoteInitFormRequest, creditNoteInitUpdateFormRequest, creditNoteInsertRequest, creditNoteUpdateRequest, creditNoteDeleteRequest, purchasesInitUpdateFormRequest } from 'penta-redux/actions/shoppingsActions';
import { currencyExchangeRateRequest } from 'penta-redux/actions/generalActions';
import history from '../../../utils/history';
import './creditNote.scss'
import FormFooter from 'penta-components/base/formFooter';
import { MessageBox } from 'penta-components/base/messageBox';
import { pbl_crednote, purchaseEntity } from 'models/shoppingModels';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import EditableCell from 'penta-components/base/editableCell';
import { Alert } from '@material-ui/lab';
import ScreenLoader from 'penta-components/base/screenLoader';

const header = ["", "Producto", "Descripcion", "Unidad", "Cuenta Contable", "Cantidad", "Precio Unitario", "Subtotal", "Total"];

const useStyles = (theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        formControl: {
            margin: 0,
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    });

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        }
    });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <i className="fas fa-times"></i>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});
    
const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);
    
const DialogActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const defaultCreditNoteEntity: pbl_crednote = {
    serialnu: '',
    documnum: '',
    docsuppl: '',
    officode: '',
    seriaref: '',
    documref: '',
    notetype: '',
    currency: '',
    curreval: 3.2,
    emitdate: moment().format('YYYY-MM-DD'),
    acpemont: moment().format('MM'),
    acpeyear: moment().format('YYYY'),
    intecode: 0,
    accaccou: '',
    fiscyear: +localStorage.getItem('currentFiscyear'),
    subtotal: '0.00',
    totaltax: '0.00',
    totalamo: '0.00',
    amountss: [],
    pbl_crnotdet: []
}

interface FormCreditNoteState {
    creditNoteEntity: pbl_crednote
    title: string
    itemnumb: number
    openModal: boolean
    pbl_purchdet: any
    snackbar: boolean
    freeEdit: boolean
}

class FormPurchases extends React.Component<any, FormCreditNoteState> {

    constructor(props: any) {
        super(props);
        defaultCreditNoteEntity.pbl_crnotdet = [];
        this.state = {
            creditNoteEntity: defaultCreditNoteEntity,
            itemnumb: 0,
            title: 'Agregar Nota de Credito',
            openModal: false,
            pbl_purchdet: [],
            snackbar: false,
            freeEdit: false
        }
    }

    onChangeRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            ...value
        }))
    }

    onChangeCreditNoteRoot = async (value: any) => {
        // console.log(value);
        const { tables } = this.props.shoppingsReducers;
        const key = Object.keys(value)[0];
        if(key === 'docsuppl') {
            this.setState((state) => ({
                ...state,
                itemnumb: 0,
                creditNoteEntity: {
                    ...state.creditNoteEntity,
                    ...value,
                    seriaref: '',
                    documref: '',
                    accaccou: null,
                    fiscyear: 0,
                    subtotal: '0.00',
                    totaltax: '0.00',
                    totalamo: '0.00',
                    pbl_crnotdet: []
                }
            }))
        } else if(key === 'notetype' || key === 'seriaref') {
            const { creditNoteEntity } = this.state;
            let itemnumb = 0;
            let pbl_crnotdet = [];
            const seriaref = key === 'seriaref' ? value['seriaref'] : creditNoteEntity.seriaref;
            const documref = key === 'seriaref' ? value['documref'] : creditNoteEntity.documref;
            const docsuppl = creditNoteEntity.docsuppl;
            const notetype = key === 'notetype' ? value['notetype'] : creditNoteEntity.notetype;
            if(notetype === 'NOTYP001' || notetype === 'NOTYP002') {
                this.props.purchasesInitUpdateFormRequest(seriaref, documref, docsuppl, (purchasesEntity: purchaseEntity) => {
                    pbl_crnotdet = purchasesEntity.pbl_purchdet.map((x: any) => {
                        itemnumb = itemnumb + 1;
                        return {
                            itemnumb: itemnumb,
                            skucodee: x.skucodee,
                            prodname: x.prodname,
                            meascode: x.meascode,
                            quantity: x.quantity,
                            unitpric: x.unitpric,
                            subtotal: x.subtotal,
                            amototal: x.amototal,
                            accaccou: x.accaccou,
                            fiscyear: x.fiscyear,
                            amountss: JSON.parse(x.amountss),
                            select: false
                        }
                    })
                    this.setState((state) => ({
                        ...state,
                        itemnumb,
                        creditNoteEntity: {
                            ...state.creditNoteEntity,
                            pbl_crnotdet
                        }
                    }))
                    setTimeout(() => {
                        for(let i = 0; i < pbl_crnotdet.length; i++) {
                            this.onEditCell({index: i, field: 'select' }, (pbl_crnotdet[i] as any).select)
                        }
                    }, 0);
                });
            }
            else {
                this.setState((state) => ({
                    ...state,
                    creditNoteEntity: {
                        ...state.creditNoteEntity,
                        subtotal: '0.00',
                        totaltax: '0.00',
                        totalamo: '0.00'
                    }
                }))
            }

            pbl_crnotdet = pbl_crnotdet || [];

            this.setState((state) => ({
                ...state,
                itemnumb,
                creditNoteEntity: {
                    ...state.creditNoteEntity,
                    ...value,
                    pbl_crnotdet
                }
            }))
        } else if(key === 'currency' || key === 'curreval') {
            const { pbl_crnotdet } = this.state.creditNoteEntity;
            this.setState((state) => ({
                ...state,
                creditNoteEntity: {
                    ...state.creditNoteEntity,
                    ...value
                }
            }))
            setTimeout(() => {
                for(let i = 0; i < pbl_crnotdet.length; i++) {
                    this.onEditCell({index: i, field: 'select' }, (pbl_crnotdet[i] as any).select)
                }
            }, 0);
            
        } else if(key === 'emitdate') {
            this.setState((state) => ({
                ...state,
                creditNoteEntity: {
                    ...state.creditNoteEntity,
                    ...value
                }
            }))
            if(this.state.creditNoteEntity.currency !== '00000014')
                this.props.currencyExchangeRateRequest(moment(value[key]).format('YYYY-MM-DD'), this.onExchangeRateCallback);
        }
        else
            this.setState((state) => ({
                ...state,
                creditNoteEntity: {
                    ...state.creditNoteEntity,
                    ...value
                }
            }))
    }

    onExchangeRateCallback = () => {
        const { tables } = this.props.generalReducers;
        const { pbl_crnotdet } = this.state.creditNoteEntity;
        // console.log(tables.exchangeRate);
        if(tables.exchangeRate !== null) {
            this.setState((state) => ({
                ...state,
                creditNoteEntity: {
                    ...state.creditNoteEntity,
                    curreval: tables.exchangeRate.venta
                }
            }))
        }
        setTimeout(() => {
            for(let i = 0; i < pbl_crnotdet.length; i++) {
                this.onEditCell({index: i, field: 'select' }, (pbl_crnotdet[i] as any).select)
            }
        }, 0);
    }

    onCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        console.log('cancel')
        history.push('/papp/shopping/notas-credito');
    }

    onContinueCallback = () => {
        const newCreditNoteEntity = defaultCreditNoteEntity;
        newCreditNoteEntity.pbl_crnotdet = [];
        this.setState({
            creditNoteEntity: newCreditNoteEntity,
            itemnumb: 0,
            pbl_purchdet: [],
            snackbar: true
        });
    }

    onSubmitContinue = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const { params } = this.props.match;
        if (params.serialnu !== undefined) {
            this.props.creditNoteUpdateRequest(this.state.creditNoteEntity, null);
        } else {
            this.props.creditNoteInsertRequest(this.state.creditNoteEntity, this.onContinueCallback);
        }
    }

    onSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const { params } = this.props.match;
        if (params.serialnu !== undefined) {
            this.props.creditNoteUpdateRequest(this.state.creditNoteEntity, null);
        } else {
            this.props.creditNoteInsertRequest(this.state.creditNoteEntity, null);
        }
    }

    onEditCell = (props: any, value: any) => {
        const { grl_definiti } = this.props.shoppingsReducers.tables;
        const pbl_crnotdet: any = this.state.creditNoteEntity.pbl_crnotdet;
        pbl_crnotdet[props.index][props.field] = value;
        const taxvalue = grl_definiti.find((x: any) => x.deficode === '00000016').defivalu;
        let quantity = pbl_crnotdet[props.index]['quantity'].replace(/,/g, '');
        let price = pbl_crnotdet[props.index]['unitpric'].replace(/,/g, '');
        let subtotal = 0;
        let total = 0;

        if(!this.state.freeEdit) {
            if(props.field === 'amototal')
            {
                subtotal = +value.replace(/,/g, '') / (1 + parseFloat(taxvalue));
                price = value / quantity;
                pbl_crnotdet[props.index]['unitpric'] = String.formatNumber.formatear(Math.round(price * 1000000) / 1000000, '000000');
                pbl_crnotdet[props.index]['subtotal'] = String.formatNumber.formatear(Math.round(subtotal * 100) / 100, '00');
            }
            else if(props.field === 'unitpric')
            {
                total = quantity * +value.replace(/,/g, '');
                subtotal = total / (1 + parseFloat(taxvalue));
                pbl_crnotdet[props.index]['amototal'] = String.formatNumber.formatear(Math.round(total * 100) / 100, '00');
                pbl_crnotdet[props.index]['subtotal'] = String.formatNumber.formatear(Math.round(subtotal * 100) / 100, '00');
            }
            else if(props.field === 'quantity') 
            {
                total = price * +value.replace(/,/g, '');
                subtotal = total / (1 + parseFloat(taxvalue));
                pbl_crnotdet[props.index]['amototal'] = String.formatNumber.formatear(Math.round(total * 100) / 100, '00');
                pbl_crnotdet[props.index]['subtotal'] = String.formatNumber.formatear(Math.round(subtotal * 100) / 100, '00');
            }
        }

        let _subtotalG = 0;
        let _igvG = 0;
        let _totalG = 0;

        pbl_crnotdet.forEach((item: any) => {
            _subtotalG += parseFloat(item.subtotal.replace(/,/g, ''));
            _totalG += parseFloat(item.amototal.replace(/,/g, ''));
        });
        _igvG = (_totalG - _subtotalG);

        _subtotalG = Math.round(_subtotalG*100)/100;
        _igvG = Math.round(_igvG*100)/100;
        _totalG = Math.round(_totalG*100)/100;

        const companyCurrency = '00000014';
        const documentCurrency = this.state.creditNoteEntity.currency;
        const change = this.state.creditNoteEntity.curreval;
        let amountssG = {};
        if(companyCurrency !== documentCurrency) {
            pbl_crnotdet[props.index].amountss = {
                [companyCurrency]: {
                    amototal: Math.round(+pbl_crnotdet[props.index]['amototal'].replace(/,/g, '') * change*100)/100,
                    subtotal: Math.round(Math.round(pbl_crnotdet[props.index]['amototal'].replace(/,/g, '')*change*100)/100 / (1+(+taxvalue)) *100)/100,
                    unitpric: Math.round(+pbl_crnotdet[props.index]['unitpric'].replace(/,/g, '') * change*100)/100,
                    quantity: Math.round(+pbl_crnotdet[props.index]['quantity'].replace(/,/g, '') * 100)/100
                }
            }
            amountssG = {
                [companyCurrency]: {
                    subtotal: Math.round(_totalG / (1+(+taxvalue))  * change*100)/100,
                    totaltax: Math.round(_totalG * change*100)/100 - Math.round(Math.round(_totalG * change * 100)/100 / (1+(+taxvalue)) *100)/100,
                    totalamo: Math.round(_totalG * change*100)/100
                },
                [documentCurrency]: {
                    totalamo: _totalG,
                    totaltax: _igvG,
                    subtotal: _subtotalG
                }
            }
        }
        else {
            pbl_crnotdet[props.index].amountss = {
                [companyCurrency]: {
                    amototal: +pbl_crnotdet[props.index]['amototal'].replace(/,/g, ''),
                    subtotal: +pbl_crnotdet[props.index]['subtotal'].replace(/,/g, ''),
                    unitpric: +pbl_crnotdet[props.index]['unitpric'].replace(/,/g, ''),
                    quantity: +pbl_crnotdet[props.index]['quantity'].replace(/,/g, ''),
                }
            }
            amountssG = {
                [companyCurrency]: {
                    totalamo: _totalG,
                    totaltax: _igvG,
                    subtotal: _subtotalG
                },
                '00000015': {
                    subtotal: Math.round(_totalG / (1+(+taxvalue))  / change*100)/100,
                    totaltax: Math.round(_totalG / change*100)/100 - Math.round(Math.round(_totalG / change * 100)/100 / (1+(+taxvalue)) *100)/100,
                    totalamo: Math.round(_totalG / change*100)/100
                },
            }
        }

        this.setState((state: FormCreditNoteState) => ({
            ...state,
            creditNoteEntity: {
                ...state.creditNoteEntity,
                pbl_crnotdet,
                totalamo: String.formatNumber.formatear(_totalG),
                totaltax: String.formatNumber.formatear(_igvG),
                subtotal: String.formatNumber.formatear(_subtotalG),
                amountss: amountssG
            }
        }))
    }

    onImportDetail = () => {
        const { creditNoteEntity, pbl_purchdet } = this.state;
        let itemnumb = 0;
        const seriaref = creditNoteEntity.seriaref;
        const documref = creditNoteEntity.documref;
        const docsuppl = creditNoteEntity.docsuppl;
        if(pbl_purchdet.length === 0)
            this.props.purchasesInitUpdateFormRequest(seriaref, documref, docsuppl, (purchasesEntity: purchaseEntity) => {
                const pbl_purchdet = purchasesEntity.pbl_purchdet.map((x: any) => {
                    itemnumb = itemnumb + 1;
                    return {
                        itemnumb: itemnumb,
                        skucodee: x.skucodee,
                        prodname: x.prodname,
                        meascode: x.meascode,
                        quantity: x.quantity,
                        unitpric: x.unitpric,
                        subtotal: x.subtotal,
                        amototal: x.amototal,
                        accaccou: x.accaccou,
                        fiscyear: x.fiscyear,
                        amountss: JSON.parse(x.amountss),
                        select: false
                    }
                })
                this.setState((state) => ({
                    ...state,
                    itemnumb,
                    openModal: true,
                    pbl_purchdet
                }))
            });
        else
            this.setState((state) => ({
                ...state,
                openModal: true,
            }))
    }

    onAddDetail = () => {
        const { whp_unitmeas } = this.props.shoppingsReducers.tables;
        const { pbl_crnotdet } = this.state.creditNoteEntity;
        const itemnumb = this.state.itemnumb + 1;
        const newItem: any = {
            itemnumb: itemnumb,
            skucodee: null,
            prodname: '',
            meascode: whp_unitmeas[0].meascode,
            quantity: '1.00',
            unitpric: '0.000000',
            subtotal: '0.00',
            amototal: '0.00',
            accaccou: null,
            fiscyear: null,
            amountss: {},
            select: false
        };
        (pbl_crnotdet as any).push(newItem);
        this.setState((state: any) => ({
            ...state,
            itemnumb,
            creditNoteEntity: {
                ...state.creditNoteEntity,
                pbl_crnotdet,
            }
        }))
    }

    onDeleteDetail = () => {
        const { grl_definiti } = this.props.shoppingsReducers.tables;
        let { pbl_crnotdet } = this.state.creditNoteEntity;        
        pbl_crnotdet = pbl_crnotdet.filter((x: any) => x.select === false || x.select === undefined);
        const taxvalue = grl_definiti.find((x: any) => x.deficode === '00000016').defivalu;
        
        let _subtotalG = 0;
        let _igvG = 0;
        let _totalG = 0;

        pbl_crnotdet.forEach((item: any) => {
            _subtotalG += parseFloat(item.subtotal.replace(/,/g, ''));
            // _igvG += (parseFloat(item.amototal.replace(/,/g, '')) - _subtotalG);
            _totalG += parseFloat(item.amototal.replace(/,/g, ''));
        });
        _igvG = (_totalG - _subtotalG);

        _subtotalG = Math.round(_subtotalG*100)/100;
        _igvG = Math.round(_igvG*100)/100;
        _totalG = Math.round(_totalG*100)/100;

        const companyCurrency = '00000014';
        const documentCurrency = this.state.creditNoteEntity.currency;
        const change = this.state.creditNoteEntity.curreval;
        let amountssG = {};
        if(companyCurrency !== documentCurrency) {
            amountssG = {
                [companyCurrency]: {
                    subtotal: Math.round(_totalG / (1+(+taxvalue))  * change*100)/100,
                    totaltax: Math.round(_totalG * change*100)/100 - Math.round(Math.round(_totalG * change * 100)/100 / (1+(+taxvalue)) *100)/100,
                    totalamo: Math.round(_totalG * change*100)/100
                },
                [documentCurrency]: {
                    totalamo: _totalG,
                    totaltax: _igvG,
                    subtotal: _subtotalG
                }
            }
        }
        else {
            amountssG = {
                [companyCurrency]: {
                    totalamo: _totalG,
                    totaltax: _igvG,
                    subtotal: _subtotalG
                },
                '00000015': {
                    subtotal: Math.round(_totalG / (1+(+taxvalue))  / change*100)/100,
                    totaltax: Math.round(_totalG / change*100)/100 - Math.round(Math.round(_totalG / change * 100)/100 / (1+(+taxvalue)) *100)/100,
                    totalamo: Math.round(_totalG / change*100)/100
                },
            }
        }

        this.setState((state: FormCreditNoteState) => ({
            ...state,
            creditNoteEntity: {
                ...state.creditNoteEntity,
                pbl_crnotdet,
                totalamo: String.formatNumber.formatear(_totalG),
                totaltax: String.formatNumber.formatear(_igvG),
                subtotal: String.formatNumber.formatear(_subtotalG),
                amountss: amountssG
            }
        }));
    }

    renderDetail = () => {
        const { whp_products, acc_planacco, whp_unitmeas } = this.props.shoppingsReducers.tables;
        const { pbl_crnotdet, notetype } = this.state.creditNoteEntity;

        let dselect, dskucodee, dprodname, dmeascode, daccaccou, dquantity, dunitpric, dsubtotal, damototal;

        switch(notetype) {
            case 'NOTYP001':
            case 'NOTYP002':
                dselect = true, dskucodee = true, dprodname = true, dmeascode = true, daccaccou = true, dquantity = true, dunitpric = true, dsubtotal = true, damototal = true;
                break;
            case 'NOTYP003': 
            case 'NOTYP004': 
            case 'NOTYP006': 
            case 'NOTYP008': 
            case 'NOTYP009': 
            case 'NOTYP010': 
                dselect = false, dskucodee = false, dprodname = false, dmeascode = false, daccaccou = false, dquantity = false, dunitpric = true, dsubtotal = true, damototal = false;
                break;
            case 'NOTYP005': 
            case 'NOTYP007': 
                dselect = true, dskucodee = true, dprodname = false, dmeascode = false, daccaccou = false, dquantity = false, dunitpric = true, dsubtotal = true, damototal = false;
                break;
        }

        if (this.state.freeEdit) {
            dquantity = false, dunitpric = false, dsubtotal = false, damototal = false;
        }

        
        return pbl_crnotdet.map((item: any, i: any) => (
            <tr key={ i } >
                <td className={ dselect ? "disabled" : "" }>
                    <input type="checkbox"
                    checked={ item.select } 
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'select' }, 
                            e.target.checked
                        )}
                    disabled={dselect}
                    />
                </td>
                <td className={ dskucodee ? "disabled" : "" } style={{width:'200px'}}>
                    <Autocomplete
                        id="skucodee"
                        freeSolo
                        options={whp_products}
                        getOptionLabel={ (x: any) => x.skucodee }
                        value={ whp_products.find((x: any) => x.skucodee === item.skucodee) || null }
                        onChange={(e: any, value) => {
                            this.onEditCell({index: i, field: 'skucodee'}, value.skucodee);
                            this.onEditCell({index: i, field: 'prodname'}, value.prodname);
                            this.onEditCell({index: i, field: 'meascode'}, value.meascode);
                            this.onEditCell({index: i, field: 'accaccou'}, value.accaccou);
                            this.onEditCell({index: i, field: 'fiscyear'}, value.fiscyear);
                        }}
                        renderInput={(params) => (
                            <TextField {...params} 
                            style={ { marginTop: 0 } } margin="normal" variant="outlined" />
                        )}
                        disabled={dskucodee}
                    />
                </td>
                <td className={ dprodname ? "disabled" : (item.skucodee || "") !== "" ? "disabled" : ""} style={{width:'250px'}}>
                    <input type="text"
                    value={ item.prodname }
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'prodname' }, 
                            e.target.value
                        )}
                    disabled={dprodname ? true : (item.skucodee || "") !== "" ? true : false}
                    />
                </td>
                <td className={ dmeascode ? "disabled" : (item.skucodee || "") !== "" ? "disabled" : "" } style={{width:'100px'}}>
                    <select style={{width:'100%'}} value={ item.meascode } 
                        onChange={ (e: any) => { 
                            this.onEditCell({index: i, field: 'meascode'}, e.target.value)
                        }}
                        disabled={dmeascode ? true : (item.skucodee || "") !== "" ? true : false}
                    >
                        { whp_unitmeas.map((item:any) => <option value={item.meascode} key={item.meascode}>{item.measname}</option>) }
                    </select>
                </td>
                <td className={ daccaccou ? "disabled" : (item.skucodee || "") !== "" ? "disabled" : "" } style={{width:'150px'}}>
                    <Autocomplete
                        id="accaccou"
                        freeSolo
                        options={acc_planacco}
                        getOptionLabel={ (x: any) => x.accaccou }
                        value={ acc_planacco.find((x: any) => x.accaccou === item.accaccou) || null }
                        onChange={(e: any, value) => {
                            this.onEditCell({index: i, field: 'accaccou'}, value.accaccou);
                            this.onEditCell({index: i, field: 'fiscyear'}, value.fiscyear);
                        }}
                        renderInput={(params) => (
                            <TextField {...params} 
                            onBlur={(e) => { 
                                const a = acc_planacco.find((x: any) => x.accaccou === e.target.value);
                                if(a) {
                                    this.onEditCell({index: i, field: 'accaccou'}, a.accaccou);
                                    this.onEditCell({index: i, field: 'fiscyear'}, a.fiscyear);
                                }
                            }}
                            style={ { marginTop: 0 } } margin="normal" variant="outlined" />
                        )}
                        disabled={ item.skucodee || '' !== '' }
                    />
                </td>
                <td className={ dquantity ? "disabled" : "" }>
                    <input type="text"
                    value={ item.quantity } 
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'quantity' }, 
                            e.target.value
                        )}
                    style={{ textAlign: 'right' }}
                    disabled={dquantity}
                    />
                </td>
                <td className={ dunitpric ? "disabled" : "" }>
                    <input type="text" 
                    value={ item.unitpric } 
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'unitpric' }, 
                            e.target.value
                        )}
                    style={{ textAlign: 'right' }}
                    disabled={dunitpric}
                    />
                </td>
                <td className={ dsubtotal ? "disabled" : "" }>
                    <input type="text"
                    value={ item.subtotal } 
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'subtotal' }, 
                            e.target.value
                        )}
                    style={{ textAlign: 'right' }}
                    disabled={dsubtotal}
                    />
                </td>
                <td className={ damototal ? "disabled" : "" }>
                    <input type="text"
                    value={ item.amototal } 
                    onChange={ (e: any) => 
                        this.onEditCell(
                            {index: i, field: 'amototal' }, 
                            e.target.value
                        )}
                    style={{ textAlign: 'right' }}
                    disabled={damototal}
                    />
                </td>
            </tr>
        ))
    }

    renderGrilla = () => {
        const { notetype } = this.state.creditNoteEntity
        return (
            <div className="form">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className="form__field">
                            <div className="grid__actions">
                                <button className="import" onClick={ (e) => { e.preventDefault(); this.onImportDetail() } } disabled={!(notetype === 'NOTYP005' || notetype === 'NOTYP007')}><i className="fas fa-download"/></button>
                                <button className="add" onClick={ (e) => { e.preventDefault(); this.onAddDetail() } } disabled={!(notetype === 'NOTYP003' || notetype === 'NOTYP004' || notetype === 'NOTYP006' || notetype === 'NOTYP008' || notetype === 'NOTYP009' || notetype === 'NOTYP010')}><i className="fas fa-plus"/></button>
                                <button className="remove" onClick={ (e) => { e.preventDefault(); this.onDeleteDetail() } } disabled={!(notetype === 'NOTYP003' || notetype === 'NOTYP004' || notetype === 'NOTYP006' || notetype === 'NOTYP008' || notetype === 'NOTYP009' || notetype === 'NOTYP010')}><i className="fas fa-times"/></button>
                                <button className="free__edit" onClick={ (e) => { e.preventDefault(); this.setState({freeEdit: !this.state.freeEdit}) } }>Calc. Filas <b>{!this.state.freeEdit ? '(Activado)' : '(Desactivado)'}</b></button>
                                <span style={ { marginLeft: '15px' } }>[ENTER]: Agregar nuevo item</span>
                                <span style={ { marginLeft: '15px' } }>[TAB]: Navegar entre las celdas</span>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <EditableCell
                            header={ header }
                            data={ this.renderDetail() }
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }

    handleCloseModal = () => {
        this.setState({
            openModal: false
        })
    }

    handleSubmitModal = () => {
        const { pbl_purchdet } = this.state;
        const pbl_crnotdet = [...pbl_purchdet.filter((x: any) => x.select).map((x: any) => { return { ...x, select: false }})];
        this.setState((state) => ({
            ...state,
            openModal: false,
            creditNoteEntity: {
                ...state.creditNoteEntity,
                pbl_crnotdet
            }
        }))
        setTimeout(() => {
            for(let i = 0; i < pbl_crnotdet.length; i++) {
                this.onEditCell({index: i, field: 'select' }, (pbl_crnotdet[i] as any).select)
            }
        }, 0);
    }

    renderDetailModal = () => {
        const { pbl_purchdet } = this.state;
        
        return pbl_purchdet.map((item: any, i: any) => (
            <tr key={ i } >
                <td>
                    <input type="checkbox"
                    checked={ item.select } 
                    onChange={ (e: any) => {
                        pbl_purchdet[i]['select'] = e.target.checked;
                        this.setState((state: FormCreditNoteState) => ({
                            ...state,
                            creditNoteEntity: {
                                ...state.creditNoteEntity,
                                pbl_purchdet
                            }
                        }))
                    }}
                    />
                </td>
                <td className="disabled" style={{width:'200px'}}>
                    <input type="text" value={ item.skucodee } disabled/>
                </td>
                <td className="disabled" style={{width:'250px'}}>
                    <input type="text" value={ item.prodname } disabled/>
                </td>
                <td className="disabled" style={{width:'100px'}}>
                    <input type="text" value={ item.meascode } disabled/>
                </td>
                <td className="disabled" style={{width:'100px'}}>
                    <input type="text" value={ item.accaccou } disabled/>
                </td>
                <td className="disabled">
                    <input type="text" value={ item.quantity } style={{ textAlign: 'right' }} disabled/>
                </td>
                <td className="disabled">
                    <input type="text" value={ item.unitpric } style={{ textAlign: 'right' }} disabled/>
                </td>
                <td className="disabled">
                    <input type="text" value={ item.subtotal } style={{ textAlign: 'right' }} disabled/>
                </td>
                <td className="disabled">
                    <input type="text" value={ item.amototal } style={{ textAlign: 'right' }} disabled/>
                </td>
            </tr>
        ))
    }

    renderPopUp = () => {
        return (
            <Dialog onClose={this.handleCloseModal} aria-labelledby="customized-dialog-title" open={this.state.openModal} fullWidth maxWidth="lg">
                <DialogTitle id="customized-dialog-title" onClose={() => this.handleCloseModal()}>
                    Seleccionar Items
                </DialogTitle>
                <DialogContent dividers>
                    <EditableCell
                        header={ header }
                        data={ this.renderDetailModal() }
                    />
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={this.handleCloseModal} color="secondary">
                        Cancelar
                    </Button>
                    <Button autoFocus onClick={this.handleSubmitModal} color="primary">
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    callbackInit = () => {
        const { tables } = this.props.shoppingsReducers;
        const notetype = tables.grl_definiti.filter((x: any) => x.defitype === 'NOTYP')[0].deficode
        const currency = tables.grl_definiti.filter((x: any) => x.defitype === 'CURCY')[0].deficode
        this.setState((state: any) => ({
            ...state,
            itemnumb: 1,
            creditNoteEntity: {
                ...state.creditNoteEntity,
                notetype: notetype,
                currency: currency,
                curreval: tables.exchangeRate.venta
            },
            pbl_purchdet: []
        }));
    }

    callbackInitCopy = (creditNoteEntity: pbl_crednote) => {
        const { state } = this.props.history.location;
        creditNoteEntity.emitdate = moment(creditNoteEntity.emitdate).format('YYYY-MM-DD');
        creditNoteEntity.amountss = JSON.parse(creditNoteEntity.amountss);
        for (const iterator of creditNoteEntity.pbl_crnotdet) {
            iterator.amountss = JSON.parse(iterator.amountss);
        }
        let itemnumb = 0;
        if(creditNoteEntity.pbl_crnotdet.length > 0)
            itemnumb = creditNoteEntity.pbl_crnotdet[creditNoteEntity.pbl_crnotdet.length - 1].itemnumb;
        creditNoteEntity.officode = state.officode,
        this.setState({
            creditNoteEntity,
            itemnumb
        });
        if(state.deleteOrigin) this.props.creditNoteDeleteRequest([state.serialnu], [state.documnum], [state.docsuppl]);
    }

    callbackInitUpdate = (creditNoteEntity: pbl_crednote) => {
        const { tables } = this.props.shoppingsReducers;
        const supplier =  tables.pbl_supplier.find((x: any) => x.docnumbe === creditNoteEntity.docsuppl);
        creditNoteEntity.emitdate = moment(creditNoteEntity.emitdate).format('YYYY-MM-DD');
        creditNoteEntity.amountss = JSON.parse(creditNoteEntity.amountss);
        (creditNoteEntity as any).addrline = supplier.addrline;
        for (const iterator of creditNoteEntity.pbl_crnotdet) {
            iterator.amountss = JSON.parse(iterator.amountss);
        }
        this.setState({
            creditNoteEntity,
            itemnumb: creditNoteEntity.pbl_crnotdet[creditNoteEntity.pbl_crnotdet.length - 1].itemnumb
        });
    }

    componentDidMount() {
        const { state } = this.props.history.location;
        const { params } = this.props.match;
        if(state?.method === 'copy') {
            this.props.creditNoteInitUpdateFormRequest(state.serialnu, state.documnum, state.docsuppl, this.callbackInitCopy);
        } else {
            if (params.serialnu !== undefined) {
                this.setState({title: 'Editar Nota de Credito'});
                this.props.creditNoteInitUpdateFormRequest(params.serialnu, params.documnum, params.docsuppl, this.callbackInitUpdate);
            }
            else {
                this.props.creditNoteInitFormRequest(this.callbackInit);
            }
        }
    }

    render() {

        const { creditNoteEntity, title, snackbar } = this.state;
        const { loading, list, tables, failed, failedTitle, failedContent } = this.props.shoppingsReducers;
        const generalTables = this.props.generalReducers.tables;
        const { classes } = this.props;

        return (
            <div style={{overflow: 'auto', height: '100%'}}>
                <Paper className='form__container'>
                    <br/>
                    <Typography variant='h5'>{ title }</Typography>
                    <br/>
                    { failed && <MessageBox title={failedTitle} content={failedContent}/> }
                    <form noValidate autoComplete="off">
                        <div className="seccion__title">PROVEEDOR</div>
                        <Grid container spacing={2} >
                            <Grid item xs={3}>
                                <Autocomplete
                                    id="docsuppl"
                                    freeSolo
                                    options={tables.pbl_supplier}
                                    getOptionLabel={ (x: any) => x.suppname + " " + x.lastname + " " + x.mlstname + x.businame }
                                    value={ tables.pbl_supplier.find((x: any) => x.docnumbe === creditNoteEntity.docsuppl) || null }
                                    onChange={(e: any, value) => this.onChangeCreditNoteRoot(
                                        {
                                            'docsuppl': value.docnumbe,
                                            'addrline': value.addrline,
                                        }
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} 
                                        style={ { marginTop: 0 } } label="Proveedor" margin="normal" variant="outlined" />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth id="docsuppl" label="RUC / DNI" variant="outlined" value={ creditNoteEntity.docsuppl }/>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth id="addrline" label="Direcion" variant="outlined" value={ (creditNoteEntity as any).addrline || '' }/>
                            </Grid>
                        </Grid>
                        <div className="seccion__title">DOCUMENTO</div>
                        <Grid container spacing={2} >
                            <Grid item xs={3}>
                                <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                    <InputLabel id="lbl_acpemont">Periodo contable - Mes</InputLabel>
                                    <Select
                                        labelId="lbl_acpemont"
                                        id="acpemont"
                                        className='select'
                                        label="Periodo contable - Mes"
                                        fullWidth
                                        value={creditNoteEntity.acpemont}
                                        onChange={(e: any) => this.onChangeCreditNoteRoot({'acpemont': e.target.value})}
                                    >
                                        <MenuItem value="01">01</MenuItem>
                                        <MenuItem value="02">02</MenuItem>
                                        <MenuItem value="03">03</MenuItem>
                                        <MenuItem value="04">04</MenuItem>
                                        <MenuItem value="05">05</MenuItem>
                                        <MenuItem value="06">06</MenuItem>
                                        <MenuItem value="07">07</MenuItem>
                                        <MenuItem value="08">08</MenuItem>
                                        <MenuItem value="09">09</MenuItem>
                                        <MenuItem value="10">10</MenuItem>
                                        <MenuItem value="11">11</MenuItem>
                                        <MenuItem value="12">12</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                    <InputLabel id="lbl_acpeyear">Periodo contable - Año</InputLabel>
                                    <Select
                                        labelId="lbl_acpeyear"
                                        id="acpeyear"
                                        className='select'
                                        label="Periodo contable - Año"
                                        fullWidth
                                        value={creditNoteEntity.acpeyear}
                                        onChange={(e: any) => this.onChangeCreditNoteRoot({'acpeyear': e.target.value})}
                                    >
                                        <MenuItem value="2019">2019</MenuItem>
                                        <MenuItem value="2020">2020</MenuItem>
                                        <MenuItem value="2021">2021</MenuItem>
                                        <MenuItem value="2022">2022</MenuItem>
                                        <MenuItem value="2023">2023</MenuItem>
                                        <MenuItem value="2024">2024</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} >
                            <Grid item xs={3}>
                                <TextField fullWidth id="serialnu" label="Serie" variant="outlined" value={ creditNoteEntity.serialnu } onChange={(e: any) => this.onChangeCreditNoteRoot({'serialnu': e.target.value})}/>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth id="documnum" label="Nro. de documento" variant="outlined" value={ creditNoteEntity.documnum } onChange={(e: any) => this.onChangeCreditNoteRoot({'documnum': e.target.value})}/>
                            </Grid>
                            <Grid item xs={3}>
                                <Autocomplete
                                    id="documnum"
                                    freeSolo
                                    options={tables.pbl_purchase.filter((x: any) => x.docsuppl === creditNoteEntity.docsuppl)}
                                    getOptionLabel={ (x: any) => (x.serialnu + '-' + x.documnum) }
                                    value={tables.pbl_purchase.find((x: any) => (x.serialnu + '-' + x.documnum) === (creditNoteEntity.seriaref + '-' + creditNoteEntity.documref)) || null }
                                    onChange={(e: any, value) => this.onChangeCreditNoteRoot(
                                        {
                                            'seriaref': value.serialnu,
                                            'documref': value.documnum,
                                            'currency': value.currency,
                                            'accaccou': value.accaccou, 
                                            'fiscyear': value.fiscyear
                                        }
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} 
                                        style={ { marginTop: 0 } } label="Documento de Ref." margin="normal" variant="outlined" />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                    <InputLabel id="lbl_tiponota">Tipo de nota</InputLabel>
                                    <Select
                                        labelId="lbl_tiponota"
                                        id="notetype"
                                        className='select'
                                        label="Tipo de nota"
                                        fullWidth
                                        value={creditNoteEntity.notetype}
                                        onChange={(e: any) => this.onChangeCreditNoteRoot({'notetype': e.target.value})}
                                    >
                                        {
                                            tables.grl_definiti.filter((x: any) => x.defitype === 'NOTYP').map((item: any, key: any) => 
                                                <MenuItem value={item.deficode} key={`notetype_${key}`}>{item.definame}</MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid> 
                        <Grid container spacing={2} >
                            <Grid item xs={3}>
                                <TextField fullWidth type="date" id="emitdate" label="Fecha de emision" variant="outlined" value={ creditNoteEntity.emitdate } onChange={(e: any) => this.onChangeCreditNoteRoot({'emitdate': e.target.value})}/>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                    <InputLabel id="lbl_moneda">Moneda</InputLabel>
                                    <Select
                                        labelId="lbl_moneda"
                                        id="currency"
                                        className='select'
                                        label="Tipo de moneda"
                                        fullWidth
                                        value={creditNoteEntity.currency}
                                        onChange={(e: any) => this.onChangeCreditNoteRoot({'currency': e.target.value})}
                                    >
                                        {
                                            tables.grl_definiti.filter((x: any) => x.defitype === 'CURCY').map((item: any, key: any) => 
                                                <MenuItem value={item.deficode} key={`moneda_${key}`}>{item.definame}</MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth id="curreval"  label="Tipo de cambio" variant="outlined" value={ creditNoteEntity.curreval } onChange={(e: any) => this.onChangeCreditNoteRoot({'curreval': e.target.value})}/>
                            </Grid>
                        </Grid>
                        <div style={{ height: 50 }}/>
                        <Grid container spacing={2} >
                            { this.renderGrilla()  }
                        </Grid>
                        <div style={{ height: 50 }}/>
                        <Grid container spacing={2} >
                            <Grid item xs={3}/>
                            <Grid item xs={3}/>
                            <Grid container item xs={3} direction="row-reverse" alignItems="center">
                                <Typography variant="caption">Sub total</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth disabled id="subtotal" variant="outlined" className="txt_total" value={ creditNoteEntity.subtotal }/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} >
                            <Grid item xs={3}/>
                            <Grid item xs={3}/>
                            <Grid container item xs={3} direction="row-reverse" alignItems="center">
                                <Typography variant="caption">IGV</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth disabled id="totaltax" variant="outlined" className="txt_total" value={ creditNoteEntity.totaltax }/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} >
                            <Grid item xs={3}/>
                            <Grid item xs={3}/>
                            <Grid container item xs={3} direction="row-reverse" alignItems="center">
                                <Typography variant="caption">Importe Total</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth disabled id="totalamo" variant="outlined" className="txt_total" value={ creditNoteEntity.totalamo }/>
                            </Grid>
                        </Grid>
                    </form>
                    <br/>
                    <FormFooter 
                        onCancel={ this.onCancel } 
                        onSubmitContinue={ this.onSubmitContinue } 
                        onSubmit={ this.onSubmit }/>
                </Paper>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={snackbar}
                    onClose={() => this.setState({snackbar: false})}
                    autoHideDuration={6000}
                >
                    <Alert onClose={() => this.setState({snackbar: false})} severity="success">
                            Datos Guardados Correctamente
                    </Alert>
                </Snackbar>
                {this.renderPopUp()}
                <ScreenLoader enabled={loading}/>
            </div>
        );
    }
}

const shoppingsReducers = 'shoppingsReducers';
const generalReducers = 'generalReducers';

const mapStateToProps = (state: any) => ({
    shoppingsReducers: state.get(shoppingsReducers),
    generalReducers: state.get(generalReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        creditNoteInitFormRequest,
        creditNoteInitUpdateFormRequest,
        creditNoteInsertRequest,
        creditNoteUpdateRequest,
        creditNoteDeleteRequest,
        currencyExchangeRateRequest,
        purchasesInitUpdateFormRequest
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(FormPurchases));