import React from 'react';
import { Backdrop, Button, ButtonGroup, Checkbox, CircularProgress, createStyles, FormControl, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Select, TextField, Theme, Tooltip, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { daotRequest } from 'penta-redux/actions/accountantActions';
import history from '../../../../utils/history';
import './../reports.scss'
import FormFooter from 'penta-components/base/formFooter';
import { MessageBox } from 'penta-components/base/messageBox';
import { categoryEntity } from 'models/wharehouseProductsModels';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PdfView from 'penta-components/base/pdfView';
// import * as pdfMake from "pdfmake/build/pdfmake";
// import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
import * as XLSX from 'sheetjs-style';
import ScreenLoader from 'penta-components/base/screenLoader';

let pdfMake = require("pdfmake/build/pdfmake");
let saveAs = require('file-saver').saveAs;

const meses = [
    { key: '01', value: 'Enero' },
    { key: '02', value: 'Febrero' },
    { key: '03', value: 'Marzo' },
    { key: '04', value: 'Abril' },
    { key: '05', value: 'Mayo' },
    { key: '06', value: 'Junio' },
    { key: '07', value: 'Julio' },
    { key: '08', value: 'Agosto' },
    { key: '09', value: 'Septiembre' },
    { key: '10', value: 'Octubre' },
    { key: '11', value: 'Noviembre' },
    { key: '12', value: 'Diciembre' }
]

const useStyles = (theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        formControl: {
            margin: 0,
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    });

interface FormState {
    title: string
    year: string
    initmonth: string
    endmonth: string
    base64Pdf: string
}

const officode = localStorage.getItem('currentBussOfic');

class ReportDaot extends React.Component<any, FormState> {

    constructor(props: any) {
        super(props);
        this.state = {
            title: 'Reporte de DAOT acumulado proveedores',
            year: moment().format('yyyy').toString(),
            initmonth: moment().format('MM').toString(),
            endmonth: moment().format('MM').toString(),
            base64Pdf: ''
        }
    }

    onChangeRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            ...value
        }))
    }

    onGeneratePdf = () => {
        const data = this.props.accountantReducers.onlyOne;
        const { initmonth, endmonth, year } = this.state;
        const { dashboardReducers } = this.props;
        const mesInicio = meses.find((x: any) => x.key === initmonth).value;
        const mesFin = meses.find((x: any) => x.key === endmonth).value;
        const company = (JSON.parse(localStorage.getItem('tables')).companys.find((x: any) => x.taxident === dashboardReducers.currentCompany).bussname as string).toUpperCase()

        const pdfObject: any = {
            pageOrientation: 'portrait',
            pageMargins: [ 20, 70, 20, 20 ],
            pageSize: 'A4',
            header: [
                {
                    text: ' ', lineHeight: 2
                },
                {
                    margin: [20, 0, 20, 0],
                    text: company,
                    style: {
                        bold: true,
                        fontSize: 9
                    }
                },
                {
                    text: `ACUMULADO DE PROVEEDORES DE ${mesInicio.toUpperCase()} A ${mesFin.toUpperCase()}`,
                    style: {
                        alignment: 'center',
                        bold: true,
                        fontSize: 9
                    }
                }
            ],
            content: [
                {
                    table: {
                        headerRows: 1,
                        widths: [ 50, '*', 50, 50, 50, 50 ],
                        body: []
                    }
                },
            ],
            styles: {
                headerTitle: {
                    bold: true,
                    fontSize: 6,
                    lineHeight: 1.2
                },
                table: {
                    layout: 'headerLineOnly',
                    fontSize: 6,
                    alignment: 'center'
                },
                tableDetailLeft: {
                    layout: 'headerLineOnly',
                    fontSize: 5.5,
                    alignment: 'left'
                },
                tableDetailRight: {
                    layout: 'headerLineOnly',
                    fontSize: 5.5,
                    alignment: 'right'
                },
                tableDetailRightBold: {
                    layout: 'headerLineOnly',
                    fontSize: 6,
                    alignment: 'right',
                    bold: true
                }
            },
            defaultStyle: {
                columnGap: 20,
            },
        }

        const tableBody: any = [
            [ 
                { text: 'R.U.C', style: 'table', colSpan: 1, border: [true, true, true, true] },
                { text: 'RAZON SOCIAL', style: 'table', colSpan: 1, border: [true, true, true, true] },
                { text: 'BASE IMPONIBLE', style: 'table', colSpan: 1, border: [true, true, true, true] },
                { text: 'I.G.V', style: 'table', colSpan: 1, border: [true, true, true, true] },
                { text: 'OTROS', style: 'table', colSpan: 1, border: [true, true, true, true] },
                { text: 'TOTAL', style: 'table', colSpan: 1, border: [true, true, true, true] }
            ],
        ];

        let topgrava = 0;
        let totaltax = 0;
        let topotros = 0;
        let totalamo = 0;

        const purchases = data.filter(x => x.typedocm === 'P');
        const cnotes = data.filter(x => x.typedocm === 'C');
        const dnotes = data.filter(x => x.typedocm === 'D');

        for (const row of purchases) {

            const creditNote = cnotes.find(x => row.docsuppl === x.docsuppl) || { topgrava: 0, totaltax: 0, totalamo: 0 };
            const debitNote = dnotes.find(x => row.docsuppl === x.docsuppl) || { topgrava: 0, totaltax: 0, totalamo: 0 };

            const _topgrava = row.topgrava - creditNote.topgrava + debitNote.topgrava;
            const _totaltax = row.totaltax - creditNote.totaltax + debitNote.totaltax;
            const _totalamo = row.totalamo - creditNote.totalamo + debitNote.totalamo;

            topgrava += _topgrava;
            totaltax += _totaltax;
            topotros += row.topinafe + row.topexone;
            totalamo += _totalamo;
            tableBody.push(
                [ 
                    { text: row.docsuppl, border: [false, false, false, false], style: 'tableDetailLeft' },
                    { text: row.businame.substr(0, 150), border: [false, false, false, false], style: 'tableDetailLeft' },
                    { text: String.formatNumber.new(_topgrava), border: [false, false, false, false], style: 'tableDetailRight' },
                    { text: String.formatNumber.new(_totaltax), border: [false, false, false, false], style: 'tableDetailRight' },
                    { text: String.formatNumber.new(row.topinafe + row.topexone), border: [false, false, false, false], style: 'tableDetailRight' },
                    { text: String.formatNumber.new(_totalamo), border: [false, false, false, false], style: 'tableDetailRight' }
                ]
            )
        }

        tableBody.push(
            [ 
                { text: '', border: [false, false, false, false], style: 'tableDetailLeft' },
                { text: 'TOTAL GENERAL', border: [false, false, false, false], style: 'tableDetailLeft' },
                { text: String.formatNumber.new(topgrava), border: [false, false, false, false], style: 'tableDetailRightBold' },
                { text: String.formatNumber.new(totaltax), border: [false, false, false, false], style: 'tableDetailRightBold' },
                { text: String.formatNumber.new(topotros), border: [false, false, false, false], style: 'tableDetailRightBold' },
                { text: String.formatNumber.new(totalamo), border: [false, false, false, false], style: 'tableDetailRightBold' }
            ]
        )

        pdfObject.content[0].table.body = tableBody;

        pdfMake.fonts = {
            Roboto: {
                normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
                bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
                italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
                bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
              },
        }
        const pdfDocGenerator = pdfMake.createPdf(pdfObject);
        pdfDocGenerator.getDataUrl((data: any) => {
            // console.log(data);
            this.setState({
                base64Pdf: data
            })
        });

    }

    onGenerateExcel = () => {
        try {
            const data = this.props.accountantReducers.onlyOne;
            const { initmonth, endmonth, year } = this.state;
            const { dashboardReducers } = this.props;
            const mesInicio = meses.find((x: any) => x.key === initmonth).value;
            const mesFin = meses.find((x: any) => x.key === endmonth).value;
            const company = (JSON.parse(localStorage.getItem('tables')).companys.find((x: any) => x.taxident === dashboardReducers.currentCompany).bussname as string).toUpperCase()

            const tableBody: any = [
                {
                    A: company,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null
                },
                {
                    A: `ACUMULADO DE PROVEEDORES DE ${mesInicio.toUpperCase()} A ${mesFin.toUpperCase()}`,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null
                },
                {
                    A: null,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null
                },
                { 
                    A: 'R.U.C',
                    B: 'RAZON SOCIAL',
                    C: 'BASE IMPONIBLE',
                    D: 'I.G.V',
                    E: 'OTROS',
                    F: 'TOTAL'
                }
            ]

            let topgrava = 0;
            let totaltax = 0;
            let topotros = 0;
            let totalamo = 0;

            const purchases = data.filter(x => x.typedocm === 'P');
            const cnotes = data.filter(x => x.typedocm === 'C');
            const dnotes = data.filter(x => x.typedocm === 'D');

            for (const row of purchases) {

                const creditNote = cnotes.find(x => row.docsuppl === x.docsuppl) || { topgrava: 0, totaltax: 0, totalamo: 0 };
                const debitNote = dnotes.find(x => row.docsuppl === x.docsuppl) || { topgrava: 0, totaltax: 0, totalamo: 0 };

                const _topgrava = row.topgrava - creditNote.topgrava + debitNote.topgrava;
                const _totaltax = row.totaltax - creditNote.totaltax + debitNote.totaltax;
                const _totalamo = row.totalamo - creditNote.totalamo + debitNote.totalamo;

                topgrava += _topgrava;
                totaltax += _totaltax;
                topotros += row.topinafe + row.topexone;
                totalamo += _totalamo;
                tableBody.push(
                    { 
                        A: row.docsuppl,
                        B: row.businame,
                        C: String.formatNumber.new(_topgrava),
                        D: String.formatNumber.new(_totaltax),
                        E: String.formatNumber.new(row.topinafe + row.topexone),
                        F: String.formatNumber.new(_totalamo)
                    }
                )
            }

            tableBody.push(
                { 
                    A: null,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null
                },
                { 
                    A: null,
                    B: 'TOTAL GENERAL',
                    C: String.formatNumber.new(topgrava),
                    D: String.formatNumber.new(totaltax),
                    E: String.formatNumber.new(topotros),
                    F: String.formatNumber.new(totalamo)
                }
            )

            const header = [
                'A',
                'B',
                'C',
                'D',
                'E',
                'F'
            ];

            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(tableBody, {header, skipHeader: true});
            ws["!cols"] = [
                {wpx:80},{wpx:200},{wpx:80},{wpx:80},{wpx:80},{wpx:80}
            ];
            ws['!rows'] = [
                {hpx: 18},{hpx: 16},{hpx: 16}
            ];

            const keys = Object.keys(ws);
            const rowsCount = tableBody.length;

            for (const iterator of keys) {
                if(!isNaN(parseInt(ws[iterator].v)) && !iterator.match(/^(A|B)[0-9]+$/g)) {
                    ws[iterator].v = ws[iterator].v.replace(/,/g, '');
                    ws[iterator].t = 'n';
                    ws[iterator].z = '#,##0.00';
                }
                if(iterator !== '!ref' && iterator !== '!cols' && iterator !== '!rows') {
                    ws[iterator].s = {
                        font: {
                            name: 'Tahoma',
                            sz: 8
                        }
                    }
                }
            }

            for (let index = 3; index <= rowsCount; index++) {
                ws['!rows'].push({hpx: 12})
            }

            ws['A1'].s = {font:{name: 'Tahoma', sz: 8, bold: true}}
            ws['A2'].s = {font:{name: 'Tahoma', sz: 15, bold: true}, alignment: { wrapText: true, vertical: 'center', horizontal: 'center'}}
            ws['A3'].s = {font:{name: 'Tahoma', sz: 8, bold: true}}

            for (const iterator of header) {
                const key1 = iterator + "4";
                ws[key1].s = {
                    ...ws[key1].s, 
                    alignment: { wrapText: true, vertical: 'center', horizontal: 'center'},
                    border: { 
                        top: { style: 'thin', color: 'black'},
                        bottom: { style: 'thin', color: 'black'},
                        left: { style: 'thin', color: 'black'},
                        right: { style: 'thin', color: 'black'}
                    } 
                }
            };

            ws["!merges"] = [
                { s: { r: 1, c: 0 }, e: { r: 1, c: 5 } },
            ];

            XLSX.utils.book_append_sheet(wb, ws, "DAOT");

            var wbout = XLSX.write(wb, {bookType:'xlsx', type: 'binary', cellStyles: true});

            const s2ab = function(s) {
                var buf = new ArrayBuffer(s.length);
                var view = new Uint8Array(buf);
                for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
                return buf;
            }

            saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), `daot-${year}-${initmonth}-${endmonth}.xlsx`);

        } catch(err) {
            console.log(err);
        }
    }

    render() {

        const { title, year, initmonth, endmonth, base64Pdf } = this.state;
        const { loading, failed, failedTitle, failedContent } = this.props.accountantReducers;
        const { classes } = this.props;

        return (
            <div style={{overflow: 'auto', height: '100%'}}>
                <Paper className='form__container'>
                    <Typography variant='h5'>{ title }</Typography>
                    <br/>
                    { failed && <MessageBox title={failedTitle} content={failedContent}/> }
                    <div style={{ display: 'flex' }}>
                        <form noValidate autoComplete="off" style={{ flex: 1, paddingRight: '15px', paddingTop: '2em' }}>
                            <Grid container spacing={2} >
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_año">Año</InputLabel>
                                            <Select
                                                labelId="lbl_año"
                                                id="year"
                                                className='select'
                                                label="Año"
                                                fullWidth
                                                value={ year }
                                                onChange={(e: any) => this.onChangeRoot({'year': e.target.value})}
                                            >
                                                <MenuItem value='2030'>2030</MenuItem>
                                                <MenuItem value='2029'>2029</MenuItem>
                                                <MenuItem value='2028'>2028</MenuItem>
                                                <MenuItem value='2027'>2027</MenuItem>
                                                <MenuItem value='2026'>2026</MenuItem>
                                                <MenuItem value='2025'>2025</MenuItem>
                                                <MenuItem value='2026'>2026</MenuItem>
                                                <MenuItem value='2025'>2025</MenuItem>
                                                <MenuItem value='2024'>2024</MenuItem>
                                                <MenuItem value='2023'>2023</MenuItem>
                                                <MenuItem value='2022'>2022</MenuItem>
                                                <MenuItem value='2021'>2021</MenuItem>
                                                <MenuItem value='2020'>2020</MenuItem>
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_mesinicio">Mes inicio</InputLabel>
                                            <Select
                                                labelId="lbl_mesinicio"
                                                id="initmonth"
                                                className='select'
                                                label="Mes inicio"
                                                fullWidth
                                                value={ initmonth }
                                                onChange={(e: any) => this.onChangeRoot({'initmonth': e.target.value})}
                                            >
                                                {
                                                    meses.map((x: any) => 
                                                        <MenuItem value={x.key} key={x.key}>{x.value}</MenuItem>
                                                    )
                                                }
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_mesfin">Mes fin</InputLabel>
                                            <Select
                                                labelId="lbl_mesfin"
                                                id="endmonth"
                                                className='select'
                                                label="Mes fin"
                                                fullWidth
                                                value={ endmonth }
                                                onChange={(e: any) => this.onChangeRoot({'endmonth': e.target.value})}
                                            >
                                                {
                                                    meses.map((x: any) => 
                                                        <MenuItem value={x.key} key={x.key}>{x.value}</MenuItem>
                                                    )
                                                }
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} style={{ paddingTop: '50px' }}>
                                    <Button variant="contained" fullWidth color="primary" onClick={() => { this.props.daotRequest(officode, year, initmonth, endmonth, this.onGeneratePdf) }}>
                                        Generar Reporte PDF
                                    </Button>
                                </Grid>
                                <Grid item xs={12} style={{ paddingTop: '10px' }}>
                                    <Button variant="contained" fullWidth color="inherit" onClick={() => { this.props.daotRequest(officode, year, initmonth, endmonth, this.onGenerateExcel) }}>
                                        Generar Reporte EXCEL
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                        <PdfView data={base64Pdf} style={{ flex: 2 }}/>
                    </div>
                    <br/>
                    {/* <FormFooter onCancel={ this.onCancel } onSubmit={ this.onSubmit }/> */}
                </Paper>
                <ScreenLoader enabled={loading}/>
            </div>
        );
    }
}

const accountantReducers = 'accountantReducers';
const dashboardReducers = 'dashboardReducers';

const mapStateToProps = (state: any) => ({
    accountantReducers: state.get(accountantReducers),
    dashboardReducers: state.get(dashboardReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        daotRequest
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(ReportDaot));