export const COMMISSIS_INIT_UPDATE_FORM_REQUEST = 'COMMISSIS_INIT_UPDATE_FORM_REQUEST';
export const COMMISSIS_INIT_UPDATE_FORM_REQUEST_SUCCESS = 'COMMISSIS_INIT_UPDATE_FORM_REQUEST_SUCCESS';
export const COMMISSIS_INIT_UPDATE_FORM_REQUEST_FAILED = 'COMMISSIS_INIT_UPDATE_FORM_REQUEST_FAILED';
export const COMMISSIS_GET_ALL_RECORDS_REQUEST = 'COMMISSIS_GET_ALL_RECORDS_REQUEST'; 
export const COMMISSIS_GET_ALL_RECORDS_REQUEST_SUCCESS = 'COMMISSIS_GET_ALL_RECORDS_REQUEST_SUCCESS'; 
export const COMMISSIS_GET_ALL_RECORDS_REQUEST_FAILED = 'COMMISSIS_GET_ALL_RECORDS_REQUEST_FAILED';
export const COMMISSIS_INSERT_REQUEST = 'COMMISSIS_INSERT_REQUEST';
export const COMMISSIS_INSERT_REQUEST_SUCCESS = 'COMMISSIS_INSERT_REQUEST_SUCCESS';
export const COMMISSIS_INSERT_REQUEST_FAILED = 'COMMISSIS_INSERT_REQUEST_FAILED';
export const COMMISSIS_UPDATE_REQUEST = 'COMMISSIS_UPDATE_REQUEST';
export const COMMISSIS_UPDATE_REQUEST_SUCCESS = 'COMMISSIS_UPDATE_REQUEST_SUCCESS';
export const COMMISSIS_UPDATE_REQUEST_FAILED = 'COMMISSIS_UPDATE_REQUEST_FAILED';
export const COMMISSIS_DELETE_REQUEST = 'COMMISSIS_DELETE_REQUEST';
export const COMMISSIS_DELETE_REQUEST_SUCCESS = 'COMMISSIS_DELETE_REQUEST_SUCCESS';
export const COMMISSIS_DELETE_REQUEST_FAILED = 'COMMISSIS_DELETE_REQUEST_FAILED';
/*Components */
export const COMMISSIS_LIST_SELECT_ITEM = 'COMMISSIS_LIST_SELECT_ITEM';
export const COMMISSIS_LIST_SELECT_ALL_ITEM = 'COMMISSIS_LIST_SELECT_ALL_ITEM';
export const COMMISSIS_ACTION_FAILED = 'COMMISSIS_ACTION_FAILED';

export const WEEKCALES_INIT_UPDATE_FORM_REQUEST = 'WEEKCALES_INIT_UPDATE_FORM_REQUEST';
export const WEEKCALES_INIT_UPDATE_FORM_REQUEST_SUCCESS = 'WEEKCALES_INIT_UPDATE_FORM_REQUEST_SUCCESS';
export const WEEKCALES_INIT_UPDATE_FORM_REQUEST_FAILED = 'WEEKCALES_INIT_UPDATE_FORM_REQUEST_FAILED';
export const WEEKCALES_GET_ALL_RECORDS_REQUEST = 'WEEKCALES_GET_ALL_RECORDS_REQUEST'; 
export const WEEKCALES_GET_ALL_RECORDS_REQUEST_SUCCESS = 'WEEKCALES_GET_ALL_RECORDS_REQUEST_SUCCESS'; 
export const WEEKCALES_GET_ALL_RECORDS_REQUEST_FAILED = 'WEEKCALES_GET_ALL_RECORDS_REQUEST_FAILED';
export const WEEKCALES_INSERT_REQUEST = 'WEEKCALES_INSERT_REQUEST';
export const WEEKCALES_INSERT_REQUEST_SUCCESS = 'WEEKCALES_INSERT_REQUEST_SUCCESS';
export const WEEKCALES_INSERT_REQUEST_FAILED = 'WEEKCALES_INSERT_REQUEST_FAILED';
export const WEEKCALES_UPDATE_REQUEST = 'WEEKCALES_UPDATE_REQUEST';
export const WEEKCALES_UPDATE_REQUEST_SUCCESS = 'WEEKCALES_UPDATE_REQUEST_SUCCESS';
export const WEEKCALES_UPDATE_REQUEST_FAILED = 'WEEKCALES_UPDATE_REQUEST_FAILED';
export const WEEKCALES_DELETE_REQUEST = 'WEEKCALES_DELETE_REQUEST';
export const WEEKCALES_DELETE_REQUEST_SUCCESS = 'WEEKCALES_DELETE_REQUEST_SUCCESS';
export const WEEKCALES_DELETE_REQUEST_FAILED = 'WEEKCALES_DELETE_REQUEST_FAILED';
/*Components */
export const WEEKCALES_LIST_SELECT_ITEM = 'WEEKCALES_LIST_SELECT_ITEM';
export const WEEKCALES_LIST_SELECT_ALL_ITEM = 'WEEKCALES_LIST_SELECT_ALL_ITEM';
export const WEEKCALES_ACTION_FAILED = 'WEEKCALES_ACTION_FAILED';

export const EMPLOYES_INIT_FORM_REQUEST = 'EMPLOYES_INIT_FORM_REQUEST';
export const EMPLOYES_INIT_FORM_REQUEST_SUCCESS = 'EMPLOYES_INIT_FORM_REQUEST_SUCCESS';
export const EMPLOYES_INIT_FORM_REQUEST_FAILED = 'EMPLOYES_INIT_FORM_REQUEST_FAILED';
export const EMPLOYES_INIT_UPDATE_FORM_REQUEST = 'EMPLOYES_INIT_UPDATE_FORM_REQUEST';
export const EMPLOYES_INIT_UPDATE_FORM_REQUEST_SUCCESS = 'EMPLOYES_INIT_UPDATE_FORM_REQUEST_SUCCESS';
export const EMPLOYES_INIT_UPDATE_FORM_REQUEST_FAILED = 'EMPLOYES_INIT_UPDATE_FORM_REQUEST_FAILED';
export const EMPLOYES_GET_ALL_RECORDS_REQUEST = 'EMPLOYES_GET_ALL_RECORDS_REQUEST'; 
export const EMPLOYES_GET_ALL_RECORDS_REQUEST_SUCCESS = 'EMPLOYES_GET_ALL_RECORDS_REQUEST_SUCCESS'; 
export const EMPLOYES_GET_ALL_RECORDS_REQUEST_FAILED = 'EMPLOYES_GET_ALL_RECORDS_REQUEST_FAILED';
export const EMPLOYES_INSERT_REQUEST = 'EMPLOYES_INSERT_REQUEST';
export const EMPLOYES_INSERT_REQUEST_SUCCESS = 'EMPLOYES_INSERT_REQUEST_SUCCESS';
export const EMPLOYES_INSERT_REQUEST_FAILED = 'EMPLOYES_INSERT_REQUEST_FAILED';
export const EMPLOYES_UPDATE_REQUEST = 'EMPLOYES_UPDATE_REQUEST';
export const EMPLOYES_UPDATE_REQUEST_SUCCESS = 'EMPLOYES_UPDATE_REQUEST_SUCCESS';
export const EMPLOYES_UPDATE_REQUEST_FAILED = 'EMPLOYES_UPDATE_REQUEST_FAILED';
export const EMPLOYES_DELETE_REQUEST = 'EMPLOYES_DELETE_REQUEST';
export const EMPLOYES_DELETE_REQUEST_SUCCESS = 'EMPLOYES_DELETE_REQUEST_SUCCESS';
export const EMPLOYES_DELETE_REQUEST_FAILED = 'EMPLOYES_DELETE_REQUEST_FAILED';
/*Components */
export const EMPLOYES_LIST_SELECT_ITEM = 'EMPLOYES_LIST_SELECT_ITEM';
export const EMPLOYES_LIST_SELECT_ALL_ITEM = 'EMPLOYES_LIST_SELECT_ALL_ITEM';
export const EMPLOYES_ACTION_FAILED = 'EMPLOYES_ACTION_FAILED';

export const CONCEPTS_INIT_FORM_REQUEST = 'CONCEPTS_INIT_FORM_REQUEST';
export const CONCEPTS_INIT_FORM_REQUEST_SUCCESS = 'CONCEPTS_INIT_FORM_REQUEST_SUCCESS';
export const CONCEPTS_INIT_FORM_REQUEST_FAILED = 'CONCEPTS_INIT_FORM_REQUEST_FAILED';
export const CONCEPTS_INIT_UPDATE_FORM_REQUEST = 'CONCEPTS_INIT_UPDATE_FORM_REQUEST';
export const CONCEPTS_INIT_UPDATE_FORM_REQUEST_SUCCESS = 'CONCEPTS_INIT_UPDATE_FORM_REQUEST_SUCCESS';
export const CONCEPTS_INIT_UPDATE_FORM_REQUEST_FAILED = 'CONCEPTS_INIT_UPDATE_FORM_REQUEST_FAILED';
export const CONCEPTS_GET_ALL_RECORDS_REQUEST = 'CONCEPTS_GET_ALL_RECORDS_REQUEST'; 
export const CONCEPTS_GET_ALL_RECORDS_REQUEST_SUCCESS = 'CONCEPTS_GET_ALL_RECORDS_REQUEST_SUCCESS'; 
export const CONCEPTS_GET_ALL_RECORDS_REQUEST_FAILED = 'CONCEPTS_GET_ALL_RECORDS_REQUEST_FAILED';
export const CONCEPTS_INSERT_REQUEST = 'CONCEPTS_INSERT_REQUEST';
export const CONCEPTS_INSERT_REQUEST_SUCCESS = 'CONCEPTS_INSERT_REQUEST_SUCCESS';
export const CONCEPTS_INSERT_REQUEST_FAILED = 'CONCEPTS_INSERT_REQUEST_FAILED';
export const CONCEPTS_UPDATE_REQUEST = 'CONCEPTS_UPDATE_REQUEST';
export const CONCEPTS_UPDATE_REQUEST_SUCCESS = 'CONCEPTS_UPDATE_REQUEST_SUCCESS';
export const CONCEPTS_UPDATE_REQUEST_FAILED = 'CONCEPTS_UPDATE_REQUEST_FAILED';
export const CONCEPTS_DELETE_REQUEST = 'CONCEPTS_DELETE_REQUEST';
export const CONCEPTS_DELETE_REQUEST_SUCCESS = 'CONCEPTS_DELETE_REQUEST_SUCCESS';
export const CONCEPTS_DELETE_REQUEST_FAILED = 'CONCEPTS_DELETE_REQUEST_FAILED';
/*Components */
export const CONCEPTS_LIST_SELECT_ITEM = 'CONCEPTS_LIST_SELECT_ITEM';
export const CONCEPTS_LIST_SELECT_ALL_ITEM = 'CONCEPTS_LIST_SELECT_ALL_ITEM';
export const CONCEPTS_ACTION_FAILED = 'CONCEPTS_ACTION_FAILED';

export const PAYROLL_INIT_FORM_REQUEST = 'PAYROLL_INIT_FORM_REQUEST';
export const PAYROLL_INIT_FORM_REQUEST_SUCCESS = 'PAYROLL_INIT_FORM_REQUEST_SUCCESS';
export const PAYROLL_INIT_FORM_REQUEST_FAILED = 'PAYROLL_INIT_FORM_REQUEST_FAILED';
export const PAYROLL_INIT_UPDATE_FORM_REQUEST = 'PAYROLL_INIT_UPDATE_FORM_REQUEST';
export const PAYROLL_INIT_UPDATE_FORM_REQUEST_SUCCESS = 'PAYROLL_INIT_UPDATE_FORM_REQUEST_SUCCESS';
export const PAYROLL_INIT_UPDATE_FORM_REQUEST_FAILED = 'PAYROLL_INIT_UPDATE_FORM_REQUEST_FAILED';
export const PAYROLL_GET_ALL_RECORDS_REQUEST = 'PAYROLL_GET_ALL_RECORDS_REQUEST'; 
export const PAYROLL_GET_ALL_RECORDS_REQUEST_SUCCESS = 'PAYROLL_GET_ALL_RECORDS_REQUEST_SUCCESS'; 
export const PAYROLL_GET_ALL_RECORDS_REQUEST_FAILED = 'PAYROLL_GET_ALL_RECORDS_REQUEST_FAILED';
export const PAYROLL_INSERT_REQUEST = 'PAYROLL_INSERT_REQUEST';
export const PAYROLL_INSERT_REQUEST_SUCCESS = 'PAYROLL_INSERT_REQUEST_SUCCESS';
export const PAYROLL_INSERT_REQUEST_FAILED = 'PAYROLL_INSERT_REQUEST_FAILED';
export const PAYROLL_UPDATE_REQUEST = 'PAYROLL_UPDATE_REQUEST';
export const PAYROLL_UPDATE_REQUEST_SUCCESS = 'PAYROLL_UPDATE_REQUEST_SUCCESS';
export const PAYROLL_UPDATE_REQUEST_FAILED = 'PAYROLL_UPDATE_REQUEST_FAILED';
export const PAYROLL_DELETE_REQUEST = 'PAYROLL_DELETE_REQUEST';
export const PAYROLL_DELETE_REQUEST_SUCCESS = 'PAYROLL_DELETE_REQUEST_SUCCESS';
export const PAYROLL_DELETE_REQUEST_FAILED = 'PAYROLL_DELETE_REQUEST_FAILED';
/*Components */
export const PAYROLL_LIST_SELECT_ITEM = 'PAYROLL_LIST_SELECT_ITEM';
export const PAYROLL_LIST_SELECT_ALL_ITEM = 'PAYROLL_LIST_SELECT_ALL_ITEM';
export const PAYROLL_ACTION_FAILED = 'PAYROLL_ACTION_FAILED';

//#region REPORTS

export const EMPLOYE_PAYROLL_REQUEST = 'EMPLOYE_PAYROLL_REQUEST';
export const EMPLOYE_PAYROLL_REQUEST_SUCCESS = 'EMPLOYE_PAYROLL_REQUEST_SUCCESS';
export const EMPLOYE_PAYROLL_REQUEST_FAILED = 'EMPLOYE_PAYROLL_REQUEST_FAILED';

export const LABORER_PAYROLL_REQUEST = 'LABORER_PAYROLL_REQUEST';
export const LABORER_PAYROLL_REQUEST_SUCCESS = 'LABORER_PAYROLL_REQUEST_SUCCESS';
export const LABORER_PAYROLL_REQUEST_FAILED = 'LABORER_PAYROLL_REQUEST_FAILED';

export const LABORER_PAYROLL_ACCUMULATED_REQUEST = 'LABORER_PAYROLL_ACCUMULATED_REQUEST';
export const LABORER_PAYROLL_ACCUMULATED_REQUEST_SUCCESS = 'LABORER_PAYROLL_ACCUMULATED_REQUEST_SUCCESS';
export const LABORER_PAYROLL_ACCUMULATED_REQUEST_FAILED = 'LABORER_PAYROLL_ACCUMULATED_REQUEST_FAILED';

export const EMPLOYE_PAYROLL_ACCUMULATED_REQUEST = 'EMPLOYE_PAYROLL_ACCUMULATED_REQUEST';
export const EMPLOYE_PAYROLL_ACCUMULATED_REQUEST_SUCCESS = 'EMPLOYE_PAYROLL_ACCUMULATED_REQUEST_SUCCESS';
export const EMPLOYE_PAYROLL_ACCUMULATED_REQUEST_FAILED = 'EMPLOYE_PAYROLL_ACCUMULATED_REQUEST_FAILED';

export const EMPLOYE_VOUCHER_REQUEST = 'EMPLOYE_VOUCHER_REQUEST';
export const EMPLOYE_VOUCHER_REQUEST_SUCCESS = 'EMPLOYE_VOUCHER_REQUEST_SUCCESS';
export const EMPLOYE_VOUCHER_REQUEST_FAILED = 'EMPLOYE_VOUCHER_REQUEST_FAILED';

export const EMPLOYE_VOUCHER_ALL_REQUEST = 'EMPLOYE_VOUCHER_ALL_REQUEST';
export const EMPLOYE_VOUCHER_ALL_REQUEST_SUCCESS = 'EMPLOYE_VOUCHER_ALL_REQUEST_SUCCESS';
export const EMPLOYE_VOUCHER_ALL_REQUEST_FAILED = 'EMPLOYE_VOUCHER_ALL_REQUEST_FAILED';

export const AFP_LIST_REQUEST = 'AFP_LIST_REQUEST';
export const AFP_LIST_REQUEST_SUCCESS = 'AFP_LIST_REQUEST_SUCCESS';
export const AFP_LIST_REQUEST_FAILED = 'AFP_LIST_REQUEST_FAILED';

export const PDT_STAFF_REQUEST = 'PDT_STAFF_REQUEST';
export const PDT_STAFF_REQUEST_SUCCESS = 'PDT_STAFF_REQUEST_SUCCESS';
export const PDT_STAFF_REQUEST_FAILED = 'PDT_STAFF_REQUEST_FAILED';

export const CTS_DEPOSITS_REQUEST = 'CTS_DEPOSITS_REQUEST';
export const CTS_DEPOSITS_REQUEST_SUCCESS = 'CTS_DEPOSITS_REQUEST_SUCCESS';
export const CTS_DEPOSITS_REQUEST_FAILED = 'CTS_DEPOSITS_REQUEST_FAILED';

//#endregion