import { authModels } from 'penta-models';
import * as types from '../constants/authConstants';
import { requestFailed, requestSuccess, requestWithoutParams, requestWithParams } from './commonActions';

export const authLoginRequest = (body: authModels.loginCredentials) => requestWithParams<authModels.loginCredentials>(types.AUTH_LOGIN_REQUEST, body);

export const authLoginRequestSuccess = () => requestSuccess(types.AUTH_LOGIN_REQUEST_SUCCESS);

export const authLoginRequestFailed = (titleError: string, titleContent: string) => requestFailed(types.AUTH_LOGIN_REQUEST_FAILED, titleError, titleContent);

export const authLogoutRequest = () => requestWithoutParams(types.AUTH_LOGOUT_REQUEST);
