import { baseModels } from 'penta-models';
import { 
    CREDIT_NOTE_DELETE,
    CREDIT_NOTE_GET_ALL_RECORDS,
    CREDIT_NOTE_INIT_FORM,
    CREDIT_NOTE_INIT_UPDATE_FORM,
    CREDIT_NOTE_INSERT,
    CREDIT_NOTE_UPDATE,
    DEBIT_NOTE_DELETE,
    DEBIT_NOTE_GET_ALL_RECORDS,
    DEBIT_NOTE_INIT_FORM,
    DEBIT_NOTE_INIT_UPDATE_FORM,
    DEBIT_NOTE_INSERT,
    DEBIT_NOTE_UPDATE,
    INVOICES_PAY,
    PURCHASES_ANULATE,
    PURCHASES_DELETE,
    PURCHASES_GET_ALL_RECORDS,
    PURCHASES_INIT_FORM,
    PURCHASES_INIT_UPDATE_FORM,
    PURCHASES_INSERT,
    PURCHASES_UPDATE,
    SUPPLIERS_DELETE,
    SUPPLIERS_GET_ALL_RECORDS,
    SUPPLIERS_INIT_FORM, 
    SUPPLIERS_INIT_UPDATE_FORM, 
    SUPPLIERS_INSERT,
    SUPPLIERS_UPDATE
} from 'services/constants/shoppingsConstants';
import request from './basePipeline';

//#region  SUPPLIERS

export const suppliersInitForm = (fiscyear: string) => {
    return request.getWithToken(SUPPLIERS_INIT_FORM + fiscyear);
}

export const suppliersInitUpdateForm = (docnumbe: string) => {
    return request.getWithToken(SUPPLIERS_INIT_UPDATE_FORM + docnumbe)
}

export const suppliersGetAllRecords = (body: baseModels.pentaRequestQuery) => {
    return request.postWithToken(SUPPLIERS_GET_ALL_RECORDS, body);
}

export const suppliersInsert = (body: any) => {
    return request.postWithToken(SUPPLIERS_INSERT, body)
}

export const suppliersUpdate = (docnumbe: string, body: any) => {
    return request.putWithToken(SUPPLIERS_UPDATE + docnumbe, body)
}

export const suppliersDelete = (docnumbes: Array<string>) => {
    const urlSearchParams = new URLSearchParams();
    for(const docnumbe of docnumbes) {
        urlSearchParams.append("docnumbe", docnumbe);
    }
    return request.deleteWithToken(SUPPLIERS_DELETE + urlSearchParams.toString());
}

//#endregion

//#region  PURCHASES

export const purchasesInitForm = () => {
    return request.getWithToken(PURCHASES_INIT_FORM);
}

export const purchasesInitUpdateForm = (serialnu: string, documnum: string, docsuppl: string) => {
    return request.getWithToken(PURCHASES_INIT_UPDATE_FORM + serialnu + '/' + documnum + '/' + docsuppl);
}

export const purchasesGetAllRecords = (body: baseModels.pentaRequestQuery) => {
    return request.postWithToken(PURCHASES_GET_ALL_RECORDS, body);
}

export const purchasesInsert = (body: any) => {
    return request.postWithToken(PURCHASES_INSERT, body)
}

export const purchasesUpdate = (serialnu: string, documnum: string, docsuppl: string, body: any) => {
    return request.putWithToken(PURCHASES_UPDATE + serialnu + '/' + documnum + '/' + docsuppl, body)
}

export const purchasesDelete = (serialnus: Array<string>, documnums: Array<string>, docsuppls: Array<string>) => {
    const urlSearchParams = new URLSearchParams();
    for(const serialnu of serialnus) {
        urlSearchParams.append("serialnu", serialnu);
    }
    for(const documnum of documnums) {
        urlSearchParams.append("documnum", documnum);
    }
    for(const docsuppl of docsuppls) {
        urlSearchParams.append("docsuppl", docsuppl);
    }
    return request.deleteWithToken(PURCHASES_DELETE + urlSearchParams.toString());
}

export const purchasesAnulate = (serialnus: Array<string>, documnums: Array<string>, docsuppls: Array<string>) => {
    const urlSearchParams = new URLSearchParams();
    for(const serialnu of serialnus) {
        urlSearchParams.append("serialnu", serialnu);
    }
    for(const documnum of documnums) {
        urlSearchParams.append("documnum", documnum);
    }
    for(const docsuppl of docsuppls) {
        urlSearchParams.append("docsuppl", docsuppl);
    }
    return request.deleteWithToken(PURCHASES_ANULATE + urlSearchParams.toString());
}

//#endregion

//#region CREDIT NOTE

export const creditNoteInitForm = () => {
    return request.getWithToken(CREDIT_NOTE_INIT_FORM);
}

export const creditNoteInitUpdateForm = (serialnu: string, documnum: string, docsuppl: string) => {
    return request.getWithToken(CREDIT_NOTE_INIT_UPDATE_FORM + serialnu + '/' + documnum + '/' + docsuppl);
}

export const creditNoteGetAllRecords = (body: baseModels.pentaRequestQuery) => {
    return request.postWithToken(CREDIT_NOTE_GET_ALL_RECORDS, body);
}

export const creditNoteInsert = (body: any) => {
    return request.postWithToken(CREDIT_NOTE_INSERT, body)
}

export const creditNoteUpdate = (serialnu: string, documnum: string, docsuppl: string, body: any) => {
    return request.putWithToken(CREDIT_NOTE_UPDATE + serialnu + '/' + documnum + '/' + docsuppl, body)
}

export const creditNoteDelete = (serialnus: Array<string>, documnums: Array<string>, docsuppls: Array<string>) => {
    const urlSearchParams = new URLSearchParams();
    for(const serialnu of serialnus) {
        urlSearchParams.append("serialnu", serialnu);
    }
    for(const documnum of documnums) {
        urlSearchParams.append("documnum", documnum);
    }
    for(const docsuppl of docsuppls) {
        urlSearchParams.append("docsuppl", docsuppl);
    }
    return request.deleteWithToken(CREDIT_NOTE_DELETE + urlSearchParams.toString());
}

//#endregion

//#region DEBIT NOTE

export const debitNoteInitForm = () => {
    return request.getWithToken(DEBIT_NOTE_INIT_FORM);
}

export const debitNoteInitUpdateForm = (serialnu: string, documnum: string, docsuppl: string) => {
    return request.getWithToken(DEBIT_NOTE_INIT_UPDATE_FORM + serialnu + '/' + documnum + '/' + docsuppl);
}

export const debitNoteGetAllRecords = (body: baseModels.pentaRequestQuery) => {
    return request.postWithToken(DEBIT_NOTE_GET_ALL_RECORDS, body);
}

export const debitNoteInsert = (body: any) => {
    return request.postWithToken(DEBIT_NOTE_INSERT, body)
}

export const debitNoteUpdate = (serialnu: string, documnum: string, docsuppl: string, body: any) => {
    return request.putWithToken(DEBIT_NOTE_UPDATE + serialnu + '/' + documnum + '/' + docsuppl, body)
}

export const debitNoteDelete = (serialnus: Array<string>, documnums: Array<string>, docsuppls: Array<string>) => {
    const urlSearchParams = new URLSearchParams();
    for(const serialnu of serialnus) {
        urlSearchParams.append("serialnu", serialnu);
    }
    for(const documnum of documnums) {
        urlSearchParams.append("documnum", documnum);
    }
    for(const docsuppl of docsuppls) {
        urlSearchParams.append("docsuppl", docsuppl);
    }
    return request.deleteWithToken(DEBIT_NOTE_DELETE + urlSearchParams.toString());
}

//#endregion

//#region REPORTS

export const invoicesPay = (initdate: string, endidate: string) => {
    return request.getWithToken(INVOICES_PAY + initdate + '/' + endidate);
}

//#endregion