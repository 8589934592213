import React from 'react';
import { Backdrop, Button, ButtonGroup, Checkbox, CircularProgress, createStyles, FormControl, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Select, TextField, Theme, Tooltip, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { salesDetailedDiaryBookRequest } from 'penta-redux/actions/salesActions';
import './../reports.scss'
import { MessageBox } from 'penta-components/base/messageBox';
import PdfView from 'penta-components/base/pdfView';
import moment from 'moment';
import * as XLSX from 'sheetjs-style';
import ScreenLoader from 'penta-components/base/screenLoader';

let pdfMake = require("pdfmake/build/pdfmake");
let saveAs = require('file-saver').saveAs;

const meses = [
    { key: '00', value: 'Apertura' },
    { key: '01', value: 'Enero' },
    { key: '02', value: 'Febrero' },
    { key: '03', value: 'Marzo' },
    { key: '04', value: 'Abril' },
    { key: '05', value: 'Mayo' },
    { key: '06', value: 'Junio' },
    { key: '07', value: 'Julio' },
    { key: '08', value: 'Agosto' },
    { key: '09', value: 'Septiembre' },
    { key: '10', value: 'Octubre' },
    { key: '11', value: 'Noviembre' },
    { key: '12', value: 'Diciembre' }
]

const useStyles = (theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        formControl: {
            margin: 0,
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    });

interface FormcategorysState {
    title: string,
    year: string,
    month: string,
    base64Pdf: string
}

class ReportSalesDetailedDiaryBook extends React.Component<any, FormcategorysState> {

    constructor(props: any) {
        super(props);
        this.state = {
            title: 'Reporte de Libro Diario de Ventas detallado',
            year: moment().format('yyyy').toString(),
            month: moment().format('MM').toString(),
            base64Pdf: ''
        }
    }

    onChangeRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            ...value
        }))
    }

    onGeneratePdf = () => {
        const data = this.props.salesReducers.onlyOne;
        const { month, year } = this.state;
        const { dashboardReducers } = this.props;
        const mes = meses.find((x: any) => x.key === month).value;
        const tables = JSON.parse(localStorage.getItem('tables'));
        const company = tables.companys.find((x: any) => x.taxident === dashboardReducers.currentCompany);
        const division = company.lst_grl_division.find((x: any) => x.divicode === dashboardReducers.currentDivision);
        const oficina = division.lst_grl_bussofic.find((x: any) => x.officode === dashboardReducers.currentBussOfic);
        const date = new Date(parseInt(year), parseInt(month === '00' ? '01' : month) - 1, 1);
        const stardate = moment(date).format('DD-MM-YYYY');
        const endidate = moment(date).daysInMonth() + moment(date).format('-MM-') + moment(date).format('YYYY');

        const pdfObject: any = {
            pageSize: 'A4',
            pageMargins: [ 20, 90, 20, 20 ],
            header: [
                {
                    margin: [20, 20, 20, 0],
                    text: 'LIBRO DIARIO',
                    style: {
                        bold: true,
                        fontSize: 9,
                        alignment: 'center', 
                        lineHeight: 3
                    }
                },
                {
                    margin: [20, 0, 20, 0],
                    columns: [
                        {
                            stack: [
                                {
                                    columns: [
                                        {
                                            width: 50,
                                            text: 'PERIODO:',
                                            style: 'headerTitle'
                                        },
                                        {
                                            width: 500,
                                            text: `DEL ${stardate} AL ${endidate}`,
                                            style: 'headerTitle'
                                        }
                                    ]
                                },
                                {
                                    columns: [
                                        {
                                            width: 50,
                                            text: 'RUC:',
                                            style: 'headerTitle'
                                        },
                                        {
                                            width: 500,
                                            text: dashboardReducers.currentCompany,
                                            style: 'headerTitle'
                                        }
                                    ]
                                },
                                {
                                    columns: [
                                        {
                                            width: 50,
                                            text: 'EMPRESA:',
                                            style: 'headerTitle'
                                        },
                                        {
                                            width: 500,
                                            text: company.bussname.toUpperCase(),
                                            style: 'headerTitle'
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ],
            content: [
                {
                    table: {
                        headerRows: 2,
                        widths: [ 40, 35, 90, 20, 35, 45, 30, 90, 40, 40 ],
                        body: []
                    }
                },
            ],
            styles: {
                headerTitle: {
                    bold: true,
                    fontSize: 7,
                    lineHeight: 1.2
                },
                table: {
                    layout: 'headerLineOnly',
                    fontSize: 6,
                    alignment: 'center'
                },
                subTable: {
                    layout: 'headerLineOnly',
                    fontSize: 6,
                    alignment: 'left'
                },
                tableDetailLeft: {
                    layout: 'headerLineOnly',
                    fontSize: 5.5,
                    alignment: 'left'
                },
                tableDetailRight: {
                    layout: 'headerLineOnly',
                    fontSize: 5.5,
                    alignment: 'right'
                },
                tableDetailRightBold: {
                    layout: 'headerLineOnly',
                    fontSize: 5.5,
                    alignment: 'right',
                    bold: true
                }
            },
            defaultStyle: {
                columnGap: 20,
            },
        }

        const tableBody: any = [
            [ 
                { text: 'NUM CORREL.\nDEL ASIENTO', style: 'table', border: [true, true, false, false] },
                { text: '\nFECHA', style: 'table', border: [true, true, false, false] },
                { text: 'GLOSA O\nDESCRIPCION', style: 'table', border: [true, true, false, false] },
                { text: 'REFERENCIA DE LA OPERACION', style: 'table', colSpan: 3, border: [true, true, true, true] },{},{},
                { text: 'CUENTA CONTABLE ASOCIADA A LA OPERACION', style: 'table', colSpan: 2, border: [true, true, true, true] },{},
                { text: 'MOVIMIENTO', style: 'table', colSpan: 2, border: [true, true, true, true] },{},
            ],
            [ 
                { text: 'O COD UNICO\nDE LA OPERAC.', style: 'table', border: [true, false, true, true] },
                { text: 'DE LA OPERAC.', style: 'table', border: [false, false, true, true] },
                { text: 'DE LA\nOPERAC.', style: 'table', border: [false, false, true, true] },
                
                { text: 'COD\nLIBRO', style: 'table', border: [false, false, true, true] },
                { text: 'NUMERO CORRELAT.', style: 'table', border: [false, false, true, true] },
                { text: 'NUM DOC SUSTENTA', style: 'table', border: [false, false, true, true] },
                
                { text: '\nCODIGO', style: 'table', border: [true, false, true, true] },
                { text: '\nDENOMINACION', style: 'table', border: [false, false, true, true] },
                
                { text: '\nDEBE.', style: 'table', border: [false, false, true, true] },
                { text: '\nHABER', style: 'table', border: [false, false, true, true] },
            ],
        ]

        const ventas = data.filter(x => x.unicodop.includes('FV'));
        const unicodopRead: any = [];
        let chargamoSubdiario = 0.00;
        let crediamoSubdiario = 0.00;
        let chargamoGeneral = 0.00;
        let crediamoGeneral = 0.00;

        if (ventas.length > 0)
            tableBody.push(
                [ 
                    { text: 'SUBDIARIO: 14 REGISTRO DE VENTAS', style: 'tableDetailLeft', colSpan: 10, border: [false, false, false, false] }
                ]
            )
        for (const iterator of ventas) {
            if (!unicodopRead.includes(iterator.unicodop)) {
                const rows = data.filter((x: any) => x.unicodop === iterator.unicodop);
                let chargamo = 0.00;
                let crediamo = 0.00;
                for (const row of rows) {
                    tableBody.push(
                        [ 
                            { text: row.unicodop, style: 'tableDetailLeft', border: [false, false, false, false] },
                            { text: moment(row.dateoper).format('DD/MM/YYYY'), style: 'tableDetailLeft', border: [false, false, false, false] },
                            { text: row.operglos.substr(0, 25), style: 'tableDetailLeft', border: [false, false, false, false] },
                            { text: row.codebook, style: 'tableDetailLeft', border: [false, false, false, false] },
                            { text: row.corrnumb, style: 'tableDetailLeft', border: [false, false, false, false] },
                            { text: row.sustnumb, style: 'tableDetailRight', border: [false, false, false, false] },
                            
                            { text: row.accaccou, style: 'tableDetailLeft', border: [false, false, false, false] },
                            { text: row.denomina.substr(0, 25), style: 'tableDetailLeft', border: [false, false, false, false] },
                            { text: +row.chargamo !== 0 ? String.formatNumber2.new(row.chargamo) : '', style: 'tableDetailRight', border: [false, false, false, false] },
                            { text: +row.crediamo !== 0 ? String.formatNumber2.new(row.crediamo) : '', style: 'tableDetailRight', border: [false, false, false, false] },
                        ],
                    )
                    chargamo += +row.chargamo;
                    crediamo += +row.crediamo;
                    chargamoSubdiario += +row.chargamo;
                    crediamoSubdiario += +row.crediamo;
                }
                tableBody.push(
                    [ 
                        { text: '', border: [false, false, false, false] },
                        { text: '', border: [false, false, false, false] },
                        { text: '', border: [false, false, false, false] },
                        { text: '', border: [false, false, false, false] },
                        { text: '', border: [false, false, false, false] },
                        { text: '', border: [false, false, false, false] },
                        
                        { text: '', border: [false, false, false, false] },
                        { text: 'TOTAL COMPROBANTE', style: 'tableDetailLeft', border: [false, false, false, false] },
                        { text: String.formatNumber2.new(chargamo), style: 'tableDetailRightBold', border: [false, true, false, false] },
                        { text: String.formatNumber2.new(crediamo), style: 'tableDetailRightBold', border: [false, true, false, false] },
                    ]
                )
                unicodopRead.push(iterator.unicodop);
            }
        }
        if (ventas.length > 0)
            tableBody.push(
                [ 
                    { text: '', border: [false, false, false, false] },
                    { text: '', border: [false, false, false, false] },
                    { text: '', border: [false, false, false, false] },
                    { text: '', border: [false, false, false, false] },
                    { text: '', border: [false, false, false, false] },
                    { text: '', border: [false, false, false, false] },
                    
                    { text: '', border: [false, false, false, false] },
                    { text: 'TOTAL SUBDIARIO', style: 'tableDetailLeft', border: [false, false, false, false] },
                    { text: String.formatNumber2.new(chargamoSubdiario), style: 'tableDetailRightBold', border: [false, true, false, false] },
                    { text: String.formatNumber2.new(crediamoSubdiario), style: 'tableDetailRightBold', border: [false, true, false, false] },
                ]
            )
        chargamoGeneral += chargamoSubdiario;
        crediamoGeneral += crediamoSubdiario;
        chargamoSubdiario = 0.00;
        crediamoSubdiario = 0.00;

        tableBody.push(
            [ 
                { text: '', border: [false, false, false, false] },
                { text: '', border: [false, false, false, false] },
                { text: '', border: [false, false, false, false] },
                { text: '', border: [false, false, false, false] },
                { text: '', border: [false, false, false, false] },
                { text: '', border: [false, false, false, false] },
                
                { text: '', border: [false, false, false, false] },
                { text: 'TOTAL GENERAL', style: 'tableDetailLeft', border: [false, false, false, false] },
                { text: String.formatNumber2.new(chargamoGeneral), style: 'tableDetailRightBold', border: [false, true, false, false] },
                { text: String.formatNumber2.new(crediamoGeneral), style: 'tableDetailRightBold', border: [false, true, false, false] },
            ]
        )

        pdfObject.content[0].table.body = tableBody;

        pdfMake.fonts = {
            Roboto: {
                normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
                bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
                italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
                bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
              },
        }
        const pdfDocGenerator = pdfMake.createPdf(pdfObject);
        pdfDocGenerator.getBlob((data: any) => {
            var url = URL.createObjectURL(data);
            this.setState({
                base64Pdf: url
            })
        });

    }

    onGenerateExcel = () => {
        try {
            const data = this.props.salesReducers.onlyOne;
            const { month, year } = this.state;
            const { dashboardReducers } = this.props;
            const mes = meses.find((x: any) => x.key === month).value;
            const tables = JSON.parse(localStorage.getItem('tables'));
            const company = tables.companys.find((x: any) => x.taxident === dashboardReducers.currentCompany);
            const division = company.lst_grl_division.find((x: any) => x.divicode === dashboardReducers.currentDivision);
            const oficina = division.lst_grl_bussofic.find((x: any) => x.officode === dashboardReducers.currentBussOfic);
            const date = new Date(parseInt(year), parseInt(month === '00' ? '01' : month) - 1, 1);
            const stardate = moment(date).format('DD-MM-YYYY');
            const endidate = moment(date).daysInMonth() + moment(date).format('-MM-') + moment(date).format('YYYY');

            const tableBody: any = [
                { 
                    A: null,
                    B: null,
                    C: null,
                    D: null,
                    E: 'LIBRO DIARIO',
                    F: null,
                    G: null,
                    H: null,
                    I: null,
                    J: null
                },
                { 
                    A: `PERIODO: DEL ${stardate} AL ${endidate}`,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null,
                    G: null,
                    H: null,
                    I: null,
                    J: null
                },
                { 
                    A: `RUC: ${dashboardReducers.currentCompany}`,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null,
                    G: null,
                    H: null,
                    I: null,
                    J: null
                },
                { 
                    A: `EMPRESA: ${company.bussname.toUpperCase()}`,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null,
                    G: null,
                    H: null,
                    I: null,
                    J: null
                },
                { 
                    A: null,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null,
                    G: null,
                    H: null,
                    I: null,
                    J: null
                },
                { 
                    A: 'NUM CORREL. DEL ASIENTO',
                    B: 'FECHA',
                    C: 'GLOSA O DESCRIPCION',
                    D: 'REFERENCIA DE LA OPERACION',
                    E: '',
                    F: '',
                    G: 'CUENTA CONTABLE ASOCIADA A LA OPERACION',
                    H: '',
                    I: 'MOVIMIENTO',
                    J: ''
                },
                { 
                    A: 'O COD UNICO DE LA OPERAC.',
                    B: 'DE LA OPERAC.',
                    C: 'DE LA OPERAC.',
                    D: 'COD LIBRO',
                    E: 'NUMERO CORRELAT.',
                    F: 'NUM DOC SUSTENTA',
                    G: 'CODIGO',
                    H: 'DENOMINACION',
                    I: 'DEBE.',
                    J: 'HABER',
                },
            ];

            const ventas = data.filter(x => x.unicodop.includes('FV'));
            const unicodopRead: any = [];
            const cutRows: Array<number> = [];
            let chargamoSubdiario = 0.00;
            let crediamoSubdiario = 0.00;
            let chargamoGeneral = 0.00;
            let crediamoGeneral = 0.00;

            if (ventas.length > 0)
                tableBody.push(
                    { 
                        A: 'SUBDIARIO: 14 REGISTRO DE VENTAS',
                        B: null,
                        C: null,
                        D: null,
                        E: null,
                        F: null,
                        G: null,
                        H: null,
                        I: null,
                        J: null
                    }
                )
            for (const iterator of ventas) {
                if (!unicodopRead.includes(iterator.unicodop)) {
                    const rows = data.filter((x: any) => x.unicodop === iterator.unicodop);
                    let chargamo = 0.00;
                    let crediamo = 0.00;
                    for (const row of rows) {
                        tableBody.push(
                            { 
                                A: row.unicodop,
                                B: moment(row.dateoper).format('DD/MM/YYYY'),
                                C: row.operglos.substr(0, 25),
                                D: row.codebook,
                                E: row.corrnumb,
                                F: row.sustnumb,
                                G: row.accaccou,
                                H: row.denomina.substr(0, 25),
                                I: +row.chargamo !== 0 ? String.formatNumber2.new(row.chargamo) : '',
                                J: +row.crediamo !== 0 ? String.formatNumber2.new(row.crediamo) : ''
                            },
                        )
                        chargamo += +row.chargamo;
                        crediamo += +row.crediamo;
                        chargamoSubdiario += +row.chargamo;
                        crediamoSubdiario += +row.crediamo;
                    }
                    tableBody.push(
                        { 
                            A: '',
                            B: '',
                            C: '',
                            D: '',
                            E: '',
                            F: '',
                            G: '',
                            H: 'TOTAL COMPROBANTE',
                            I: String.formatNumber2.new(chargamo),
                            J: String.formatNumber2.new(crediamo)
                        }
                    )
                    unicodopRead.push(iterator.unicodop);
                    cutRows.push(tableBody.length);
                }
            }
            if (ventas.length > 0) {
                tableBody.push(
                    { 
                        A: '',
                        B: '',
                        C: '',
                        D: '',
                        E: '',
                        F: '',
                        G: '',
                        H: 'TOTAL SUBDIARIO',
                        I: String.formatNumber2.new(chargamoSubdiario),
                        J: String.formatNumber2.new(crediamoSubdiario)
                    }
                )
                cutRows.push(tableBody.length);
            }
            chargamoGeneral += chargamoSubdiario;
            crediamoGeneral += crediamoSubdiario;
            chargamoSubdiario = 0.00;
            crediamoSubdiario = 0.00;

            tableBody.push(
                { 
                    A: '',
                    B: '',
                    C: '',
                    D: '',
                    E: '',
                    F: '',
                    G: '',
                    H: 'TOTAL GENERAL',
                    I: String.formatNumber2.new(chargamoGeneral),
                    J: String.formatNumber2.new(crediamoGeneral)
                }
            )
            cutRows.push(tableBody.length);

            const header = [
                'A',
                'B',
                'C',
                'D',
                'E',
                'F',
                'G',
                'H',
                'I',
                'J'
            ];

            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(tableBody, {header, skipHeader: true});
            ws["!cols"] = [
                {wpx:70},{wpx:65},{wpx:150},{wpx:50},{wpx:65},{wpx:75},{wpx:60},{wpx:150},{wpx:70},{wpx:70}
            ];
            ws['!rows'] = [
                {hpx: 18},{hpx: 16},{hpx: 16},{hpx: 16},{hpx: 16},{hpx: 16},{hpx: 30}
            ];

            const keys = Object.keys(ws);
            const rowsCount = tableBody.length;

            for (const iterator of keys) {
                if(!isNaN(parseInt(ws[iterator].v)) && iterator.match(/^(I|J)[0-9]+$/g)) {
                    ws[iterator].v = ws[iterator].v.replace(/,/g, '');
                    ws[iterator].t = 'n';
                    ws[iterator].z = '#,##0.00';
                }
                if(iterator !== '!ref' && iterator !== '!cols' && iterator !== '!rows') {
                    ws[iterator].s = {
                        font: {
                            name: 'Tahoma',
                            sz: 8
                        }
                    }
                    const row = +(iterator.match(/\d+/)[0]);
                    if(cutRows.includes(row) && iterator.match(/^(I|J)[0-9]+$/g)) {
                        ws[iterator].s.border = {
                            ...ws[iterator].s.border,
                            top: { style: 'thin', color: 'black'}
                        }
                        ws[iterator].s.font = {
                            ...ws[iterator].s.font,
                            bold: true
                        };
                    }
                }
            }

            for (let index = 8; index <= rowsCount; index++) {
                ws['!rows'].push({hpx: 12})
            }

            ws['E1'].s = {font:{name: 'Tahoma', sz: 15, bold: true}}
            ws['A2'].s = {font:{name: 'Tahoma', sz: 8, bold: true}}
            ws['A3'].s = {font:{name: 'Tahoma', sz: 8, bold: true}}
            ws['A4'].s = {font:{name: 'Tahoma', sz: 8, bold: true}}

            for (const iterator of header) {
                const key1 = iterator + "6";
                const key2 = iterator + "7";

                ws[key1].s = {
                    ...ws[key1].s, 
                    alignment: { wrapText: true, vertical: 'center', horizontal: 'center'},
                    border: { 
                        top: { style: 'thin', color: 'black'},
                        bottom: { style: 'thin', color: 'black'},
                        left: { style: 'thin', color: 'black'},
                        right: { style: 'thin', color: 'black'}
                    } 
                }
                ws[key2].s = {
                    ...ws[key2].s, 
                    alignment: { wrapText: true, vertical: 'center', horizontal: 'center'},
                    border: { 
                        top: { style: 'thin', color: 'black'},
                        bottom: { style: 'thin', color: 'black'},
                        left: { style: 'thin', color: 'black'},
                        right: { style: 'thin', color: 'black'}
                    } 
                }
            };

            ws["!merges"] = [
                { s: { r: 5, c: 0 }, e: { r: 6, c: 0 } },
                { s: { r: 5, c: 1 }, e: { r: 6, c: 1 } },
                { s: { r: 5, c: 2 }, e: { r: 6, c: 2 } },
                { s: { r: 5, c: 3 }, e: { r: 5, c: 5 } },
                { s: { r: 5, c: 6 }, e: { r: 5, c: 7 } },
                { s: { r: 5, c: 8 }, e: { r: 5, c: 9 } },
            ];

            XLSX.utils.book_append_sheet(wb, ws, "Libro diario");

            var wbout = XLSX.write(wb, {bookType:'xlsx', type: 'binary', cellStyles: true});

            const s2ab = function(s) {
                var buf = new ArrayBuffer(s.length);
                var view = new Uint8Array(buf);
                for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
                return buf;
            }

            saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), `libro-diario-${year}-${month}.xlsx`);

        } catch(err) {
            console.log(err);
        }
    }

    render() {

        const { title, year, month, base64Pdf } = this.state;
        const { loading, list, tables, failed, failedTitle, failedContent } = this.props.salesReducers;
        const { classes } = this.props;

        return (
            <div style={{overflow: 'auto', height: '100%'}}>
                <Paper className='form__container'>
                    <Typography variant='h5'>{ title }</Typography>
                    <br/>
                    { failed && <MessageBox title={failedTitle} content={failedContent}/> }
                    <div style={{ display: 'flex' }}>
                        <form noValidate autoComplete="off" style={{ flex: 1, paddingRight: '15px', paddingTop: '2em' }}>
                            <Grid container spacing={2} >
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_año">Año</InputLabel>
                                            <Select
                                                labelId="lbl_año"
                                                id="year"
                                                className='select'
                                                label="Año"
                                                fullWidth
                                                value={ year }
                                                onChange={(e: any) => this.onChangeRoot({'year': e.target.value})}
                                            >
                                                <MenuItem value='2030'>2030</MenuItem>
                                                <MenuItem value='2029'>2029</MenuItem>
                                                <MenuItem value='2028'>2028</MenuItem>
                                                <MenuItem value='2027'>2027</MenuItem>
                                                <MenuItem value='2026'>2026</MenuItem>
                                                <MenuItem value='2025'>2025</MenuItem>
                                                <MenuItem value='2026'>2026</MenuItem>
                                                <MenuItem value='2025'>2025</MenuItem>
                                                <MenuItem value='2024'>2024</MenuItem>
                                                <MenuItem value='2023'>2023</MenuItem>
                                                <MenuItem value='2022'>2022</MenuItem>
                                                <MenuItem value='2021'>2021</MenuItem>
                                                <MenuItem value='2020'>2020</MenuItem>
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_mes">Mes</InputLabel>
                                            <Select
                                                labelId="lbl_mes"
                                                id="month"
                                                className='select'
                                                label="Mes"
                                                fullWidth
                                                value={ month }
                                                onChange={(e: any) => this.onChangeRoot({'month': e.target.value})}
                                            >
                                                {
                                                    meses.map((x: any) => 
                                                        <MenuItem value={x.key} key={x.key}>{x.value}</MenuItem>
                                                    )
                                                }
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} style={{ paddingTop: '50px' }}>
                                    <Button variant="contained" fullWidth color="primary" onClick={() => { this.props.salesDetailedDiaryBookRequest(year, month, this.onGeneratePdf) }}>
                                        Generar Reporte PDF
                                    </Button>
                                </Grid>
                                <Grid item xs={12} style={{ paddingTop: '10px' }}>
                                    <Button variant="contained" fullWidth color="inherit" onClick={() => { this.props.salesDetailedDiaryBookRequest(year, month, this.onGenerateExcel) }}>
                                        Generar Reporte EXCEL
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                        <PdfView data={base64Pdf} style={{ flex: 2 }}/>
                    </div>
                    <br/>
                    {/* <FormFooter onCancel={ this.onCancel } onSubmit={ this.onSubmit }/> */}
                </Paper>
                <ScreenLoader enabled={loading}/>
            </div>
        );
    }
}

const salesReducers = 'salesReducers';
const dashboardReducers = 'dashboardReducers';

const mapStateToProps = (state: any) => ({
    salesReducers: state.get(salesReducers),
    dashboardReducers: state.get(dashboardReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        salesDetailedDiaryBookRequest
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(ReportSalesDetailedDiaryBook));