import React, { useEffect } from 'react';
import { Backdrop, CircularProgress, createStyles, Paper, TextField, Theme, Tooltip, withStyles } from '@material-ui/core';
import ListView from 'penta-components/base/listView';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { creditNoteGetAllRecordsRequest, creditNoteListSelectItem, creditNoteListSelectAllItem, creditNoteActionFailed, creditNoteDeleteRequest } from 'penta-redux/actions/shoppingsActions';
import history from '../../../utils/history';
import './creditNote.scss'
import filePNG from './../../../../icons/fluent/file.png';
import fileEditPNG from './../../../../icons/fluent/file-edit.png';
import fileDeletePNG from './../../../../icons/fluent/delete.png';
import fileCopyPNG from './../../../../icons/fluent/copy.png';
import fileDownloadPNG from './../../../../icons/fluent/download.png';
import fileUploadPNG from './../../../../icons/fluent/upload.png';
import fileAnulatePNG from './../../../../icons/fluent/anulate.png';
import { MessageBox } from 'penta-components/base/messageBox';
import ActionDialog from 'penta-components/base/dialog';
import { Input } from 'penta-components/base/input';
import Button from 'penta-components/controls/button';
import * as XLSX from 'xlsx';
import { purchaseEntity } from 'models/shoppingModels';
import ListMenu from 'penta-components/base/listMenu';
import Separator from 'penta-components/base/separator';
import Search from 'penta-components/controls/search';
import Select from 'penta-components/controls/select';
import { createUseStyles } from 'react-jss';
import Modal from 'penta-components/base/modal';
import Checkbox from 'penta-components/controls/checkbox';

const moment = require('moment');

const header = [
    { title: 'Nro de serie', key: 'serialnu' },
    { title: 'Nro de documento', key: 'documnum' },
    { title: 'Fecha de emision', key: 'emitdate' },
    { title: 'Proveedor', key: 'docsuppl' },
    { title: 'Monto total', key: 'totalamo' }
]

const useStyles =  createUseStyles({
    container: {
        overflow: 'auto', 
        height: '100%', 
        display: 'flex', 
        flexDirection: 'column'
    },
    titleContainer: {
        flex: 1, 
        display: 'flex', 
        alignItems: 'baseline'
    },
    title: {
        fontFamily: '"Open Sans", sans-serif', 
        fontSize: '1.563rem', 
        fontWeight: 'bold', 
        color: '#0060A6', 
        marginRight: '3rem'
    },
    tabActive: {},
    tabs: {
        margin: '0 0.4rem', 
        padding: '0.4rem 0.5rem', 
        color: '#b9b9b9', 
        fontFamily: '"Open Sans", sans-serif', 
        fontWeight: 'bold', 
        fontSize: '0.875rem',
        cursor: 'pointer',
        '&$tabActive': {
            color: '#3B3B3B',
            borderBottom: '0.2rem solid #3B3B3B', 
        }
    },
    listContainer: {
        flex: 1,
        '& > div:first-child': {
            display: 'flex'
        }
    }
});

const ListCreditNote = (props: any) => {
    const classes = useStyles();
    const amountJump = 20;
    const [showModal, setShowModal] = React.useState(false);
    const [typeModal, setTypeModal] = React.useState('');
    //===============================================================
    const [searchValue, setSearchValue] = React.useState('');
    const [fiscyear, setFiscyear] = React.useState(moment().format('YYYY'));
    const [take, setTake] = React.useState(amountJump);
    //===============================================================
    const [typing, setTyping] = React.useState(null);
    //===============================================================
    const [origin, setOrigin] = React.useState(null);
    const [deleteOrigin, setDeleteOrigin] = React.useState(false);
    const [listCompany, setListCompany] = React.useState([]);
    const [listDivision, setListDivision] = React.useState([]);
    const [listBussofic, setListBussofic] = React.useState([]);
    const [company, setCompany] = React.useState('');
    const [division, setDivision] = React.useState('');
    const [bussofic, setBussofic] = React.useState('');

    const agregar = () => {
        history.push('/papp/shopping/notas-credito/agregar');
    };

    const editar = () => {
        const select = props.shoppingsReducers.list.find((x: any) => x.select);
        if (select !== undefined)
        history.push('/papp/shopping/notas-credito/editar/' + select.serialnu + '/' + select.documnum + '/' + select.docsuppl);
        else
            props.creditNoteActionFailed('Error: 0x0', 'No se ha selecionado un elemento para modificar');
    }

    const eliminar = () => {
        const select = props.shoppingsReducers.list.filter((x: any) => x.select);
        const selectSerial = select.map((x: any) => { return x.serialnu });
        const selectNumero = select.map((x: any) => { return x.documnum });
        const selectProveedor = select.map((x: any) => { return x.docsuppl });
        if(select.length !== 0) {
            props.creditNoteDeleteRequest(selectSerial, selectNumero, selectProveedor);
            handleCloseModal();
        }
        else
            props.creditNoteActionFailed('Error: 0x0', 'No se ha selecionado un elemento para eliminar');
    }

    const copy = () => {
        const elementCopy = origin;
        if(elementCopy) {
            const filters = {
                fields: { },
                skip: 0,
                take: amountJump
            } as any

            history.push('/papp/shopping/notas-credito/copiar', {
                method: 'copy', 
                serialnu: elementCopy.serialnu, 
                documnum: elementCopy.documnum, 
                officode: bussofic, 
                docsuppl: elementCopy.docsuppl,
                deleteOrigin: deleteOrigin, 
                filters: filters
            })
        }
        setDeleteOrigin(false);
    }

    const handleShowModal = (typeModal: string) => {
        setShowModal(true);
        setTypeModal(typeModal);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setTypeModal('');
    };

    const openModalCopy = () => {
        const selects = props.shoppingsReducers.list.filter((x: any) => x.select);
        if(selects.length > 0) {
            handleShowModal('C')
            setOrigin(selects[0]);
        }
    }

    const onChangeSearchValue = (e) => {
        const text = e.target.value;
        setSearchValue(text);
        if(typing) clearTimeout(typing)
        setTyping(
            setTimeout(() => {
                onSearch(text);
            }, 500)
        )
    }

    const onSearch = (typing?: string) => {
        const body = {
            fields: { 
                serialnu: `${typing ?? searchValue}`,
                documnum: `${typing ?? searchValue}`,
                docsuppl: `${typing ?? searchValue}`
            },
            skip: 0,
            take: amountJump
        } as any

        props.creditNoteGetAllRecordsRequest(body);
        setTake(amountJump);
    }

    const callMoreItems = () => {
        const body = {
            fields: { 
                serialnu: `${searchValue}`,
                documnum: `${searchValue}`,
                docsuppl: `${searchValue}`
            },
            skip: 0,
            take: take + amountJump
        } as any

        props.creditNoteGetAllRecordsRequest(body);
        setTake(take + amountJump);
    }

    useEffect(() => {
        const { creditNoteGetAllRecordsRequest } = props;
        const body = {
            fields: { 
                serialnu: `${searchValue}`,
                documnum: `${searchValue}`,
                docsuppl: `${searchValue}`
            },
            skip: 0,
            take
        } as any

        creditNoteGetAllRecordsRequest(body);
    }, []);

    useEffect(() => {
        const { authReducers } = props;
        const lst_division = authReducers.tables.companys.find((x: any) => x.taxident === company)?.lst_grl_division.map((x: any) => { return { id: x.divicode, name: x.diviname } }) || [];
        setListDivision(lst_division);
        setListBussofic([]);
    }, [company]);

    useEffect(() => {
        const { authReducers } = props;
        const lst_bussofic = authReducers.tables.companys.find((x: any) => x.taxident === company)?.lst_grl_division.find((x: any) => x.divicode === division)?.lst_grl_bussofic.map((x: any) => { return { id: x.officode, name: x.offiname } }) || [];
        setListBussofic(lst_bussofic);
    }, [division]);

    useEffect(() => {
        const { dashboardReducers } = props;
        setCompany(dashboardReducers.currentCompany);
        setDivision(dashboardReducers.currentDivision);
        setBussofic(dashboardReducers.currentBussOfic);
    }, [showModal]);

    useEffect(() => {
        const { authReducers } = props;
        const lst_companys = authReducers.tables.companys.map((x: any) => {
            return { name: x.bussname, id: x.taxident }
        });
        setListCompany(lst_companys);
        setListDivision([]);
        setListBussofic([]);
    }, []);

    return (
        <div className={classes.container}>
            <div className={classes.titleContainer}>
                <div className={classes.title}>Notas de credito</div>
                <div>
                    <span className={`${classes.tabs} ${classes.tabActive}`}>Lista</span>
                    <span className={classes.tabs}>Chart</span>
                    <span className={classes.tabs}>Historial</span>
                </div>
            </div>
            <div className={classes.listContainer}>
                <div>
                    <Search styleContainer={{flex: 1}} onChange={onChangeSearchValue} value={searchValue}/>
                    {/* <Select label="Año" labelPosition="left" style={{marginLeft: '1rem'}} data={
                        [{id: '2021', name: '2021'}, {id: '2020', name: '2020'}, {id: '2019', name: '2019'}]
                    } value={fiscyear} onChange={(value) => setFiscyear(value.id)}/> */}
                    {/* <Select label="Mes" labelPosition="left" style={{marginLeft: '1rem'}} data={months} value={acpemont} onChange={(value) => setAcpemont(value.id)}/>
                    <Select label="Moneda" labelPosition="left" style={{marginLeft: '1rem'}} data={
                        [{id: '00', name: '--Todos--'}, {id: '00000014', name: 'Soles'}, {id: '00000015', name: 'Dolares'}]
                    } value={state} onChange={(value) => setCurrency(value.id)}/> */}
                    &nbsp;&nbsp;
                    <Button type='primary' full={false} containerStyle={{height: 'auto'}} onClick={() => onSearch()}>Filtrar</Button>
                </div>
                <Separator marginBottom='1rem'/>
                <ListMenu
                    addItem={agregar}
                    editItem={editar}
                    deleteItem={() => handleShowModal('D')}
                    copyItem={openModalCopy}
                />
                <Separator marginBottom='0.5rem'/>
                <ListView
                    header={header} body={props.shoppingsReducers.list}
                    selectAction={props.creditNoteListSelectItem} 
                    selectAllAction={props.creditNoteListSelectAllItem}
                    total={props.shoppingsReducers.total}
                    callMoreItems={callMoreItems}
                />
            </div>
            {
                showModal && typeModal === 'D' &&
                <Modal 
                    title="Penta ERP"
                    onClose={() => setShowModal(false)}
                    footer={
                        <React.Fragment>
                            <Button type='bad' full={false} onClick={handleCloseModal}>Cancelar</Button>
                            &nbsp;&nbsp;
                            <Button full={false} onClick={eliminar}>Aceptar</Button>
                        </React.Fragment>
                    }
                >
                    <React.Fragment>
                        <p>¿Desea eliminar los siguientes elemetos?</p>
                        {
                            props.shoppingsReducers.list.filter((x: any) => x.select).map(item =>
                                <ul key={`${item.serialnu}-${item.documnum}-${item.docsuppl}`} style={{margin: '1rem 2rem'}}>
                                    <li>{item.serialnu}-{item.documnum}-{item.docsuppl}</li>
                                </ul>
                            )
                        }
                    </React.Fragment>
                </Modal>
            }
            {
                showModal && typeModal === 'C' &&
                <Modal 
                    title="Penta ERP"
                    onClose={() => setShowModal(false)}
                    footer={
                        <React.Fragment>
                            <Button type='bad' full={false} onClick={handleCloseModal}>Cancelar</Button>
                            &nbsp;&nbsp;
                            <Button full={false} onClick={copy}>Aceptar</Button>
                        </React.Fragment>
                    }
                >
                    <React.Fragment>
                        <p>¿Desea copiar los datos del elemento {`${origin.serialnu}-${origin.documnum}`}?</p>
                        <br/>
                        <p>Oficina de destino:</p>
                        <Select label="Compañia" labelPosition="left" style={{marginTop: '1rem'}} data={listCompany} value={company} onChange={(value) => { setCompany(value.id); setDivision(''); setBussofic(''); } }/>
                        <Select label="Division" labelPosition="left" style={{marginTop: '1rem'}} data={listDivision} value={division} onChange={(value) => { setDivision(value.id); setBussofic(''); }}/>
                        <Select label="Oficina" labelPosition="left" style={{marginTop: '1rem'}} data={listBussofic} value={bussofic} onChange={(value) => setBussofic(value.id)}/>
                        <Checkbox label="Borrar elemento original" checked={deleteOrigin} onClick={(e: any) => setDeleteOrigin(e.target.checked)} containerStyle={{margin: '1rem 0rem'}}/>
                    </React.Fragment>
                </Modal>
            }
        </div>
    );
}

const authReducers = 'authReducers';
const shoppingsReducers = 'shoppingsReducers';
const dashboardReducers = 'dashboardReducers';

const mapStateToProps = (state: any) => ({
    authReducers: state.get(authReducers),
    shoppingsReducers: state.get(shoppingsReducers),
    dashboardReducers: state.get(dashboardReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        creditNoteGetAllRecordsRequest,
        creditNoteListSelectItem,
        creditNoteListSelectAllItem,
        creditNoteActionFailed,
        creditNoteDeleteRequest
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ListCreditNote);