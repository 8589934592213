import React from 'react';
import { Backdrop, Button, ButtonGroup, Checkbox, CircularProgress, createStyles, FormControl, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Select, TextField, Theme, Tooltip, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { masterAccountGetAllRecordsRequest, masterAccountUpdateRequest } from 'penta-redux/actions/accountantActions';
// import { locationsGetProvincesRequest, locationsGetDistrictsRequest } from 'penta-redux/actions/generalActions';
import history from '../../../utils/history';
import './masterAccount.scss'
import FormFooter from 'penta-components/base/formFooter';
import { MessageBox } from 'penta-components/base/messageBox';
import { masterAccountEntity } from 'models/accountantModels';
import ScreenLoader from 'penta-components/base/screenLoader';

const useStyles = (theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        formControl: {
            margin: 0,
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    });

const defaultMasterAccountEntity: masterAccountEntity = {
    fiscyear: (new Date()).getFullYear(),
    levelone: 0,
    accumone: 0,
    leveltwo: 0,
    accumtwo: 0,
    leveltre: 0,
    accumtre: 0,
    levelfou: 0,
    accumfou: 0,
    levelfig: 0,
    accunfig: 0
}

interface FormMasterAccountState {
    masterAccountEntity: masterAccountEntity
    title: string
}

class FormMasterAccount extends React.Component<any, FormMasterAccountState> {

    constructor(props: any) {
        super(props);
        this.state = {
            masterAccountEntity: defaultMasterAccountEntity,
            title: 'Maestro de plan de cuenta'
        }
    }

    onChangeRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            ...value
        }))
    }

    onChangeMasterAccountRoot = (value: any) => {
        this.calculator(value);
        this.setState((state) => ({
            ...state,
            masterAccountEntity: {
                ...state.masterAccountEntity,
                ...value
            }
        }))
    }

    calculator = (value: any) => {
        const { masterAccountEntity } = this.state;
        const key = Object.keys(value)[0];
        switch(key) {
            case 'levelone': 
                masterAccountEntity.accumone = parseInt(value.levelone);
                masterAccountEntity.accumtwo = parseInt(masterAccountEntity.accumone + '') + parseInt(masterAccountEntity.leveltwo + '');
                masterAccountEntity.accumtre = parseInt(masterAccountEntity.accumtwo + '') + parseInt(masterAccountEntity.leveltre + '');
                masterAccountEntity.accumfou = parseInt(masterAccountEntity.accumtre + '') + parseInt(masterAccountEntity.levelfou + '');
                masterAccountEntity.accunfig = parseInt(masterAccountEntity.accumfou + '') + parseInt(masterAccountEntity.levelfig + '');
                break;
            case 'leveltwo': 
                masterAccountEntity.accumtwo = parseInt(masterAccountEntity.accumone + '') + parseInt(value.leveltwo);
                masterAccountEntity.accumtre = parseInt(masterAccountEntity.accumtwo + '') + parseInt(masterAccountEntity.leveltre + '');
                masterAccountEntity.accumfou = parseInt(masterAccountEntity.accumtre + '') + parseInt(masterAccountEntity.levelfou + '');
                masterAccountEntity.accunfig = parseInt(masterAccountEntity.accumfou + '') + parseInt(masterAccountEntity.levelfig + '');
                break;
            case 'leveltre': 
                masterAccountEntity.accumtre = parseInt(masterAccountEntity.accumtwo + '') + parseInt(value.leveltre);
                masterAccountEntity.accumfou = parseInt(masterAccountEntity.accumtre + '') + parseInt(masterAccountEntity.levelfou + '');
                masterAccountEntity.accunfig = parseInt(masterAccountEntity.accumfou + '') + parseInt(masterAccountEntity.levelfig + '');
                break;
            case 'levelfou': 
                masterAccountEntity.accumfou = parseInt(masterAccountEntity.accumtre + '') + parseInt(value.levelfou);
                masterAccountEntity.accunfig = parseInt(masterAccountEntity.accumfou + '') + parseInt(masterAccountEntity.levelfig + '');
                break;
            case 'levelfig': 
                masterAccountEntity.accunfig = parseInt(masterAccountEntity.accumfou + '') + parseInt(value.levelfig);
                break;
        }
        this.setState({masterAccountEntity});
    }

    onCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        history.push('/papp/accounting/maestro-contable');
    }

    onSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // const { params } = this.props.match;
        // if (params.username !== undefined) {
        //     this.props.usersUpdateRequest(this.state.userEntity);
        // } else {
        //     this.props.usersRegisterRequest(this.state.userEntity);
        // }
        this.props.masterAccountUpdateRequest(this.state.masterAccountEntity);
    }

    callback = (masterAccountEntity: masterAccountEntity) => {
        this.setState({
            masterAccountEntity,
        });
    }

    componentDidMount() {
        this.props.masterAccountGetAllRecordsRequest({}, this.callback);
    }

    render() {

        const { masterAccountEntity, title } = this.state;
        const { loading, list, tables, failed, failedTitle, failedContent } = this.props.accountantReducers;
        const generalTables = this.props.generalReducers.tables;
        const { classes } = this.props;

        return (
            <div style={{overflow: 'auto', height: '100%'}}>
                <Paper className='form__container'>
                    <br/>
                    <Typography variant='h5'>{ title }</Typography>
                    <br/>
                    {/* { failed && <MessageBox title={failedTitle} content={failedContent}/> } */}
                    <form noValidate autoComplete="off">
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <TextField fullWidth id="fiscyear" label="Año fiscal" variant="outlined" value={ masterAccountEntity.fiscyear } onChange={(e: any) => this.onChangeMasterAccountRoot({'fiscyear': (e.target.value)})}/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <TextField fullWidth id="levelone" label="Nivel 1" variant="outlined" value={ masterAccountEntity.levelone } onChange={(e: any) => this.onChangeMasterAccountRoot({'levelone': (e.target.value)})}/>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField fullWidth id="accumone" label="Acumulado 1" variant="outlined" value={ masterAccountEntity.accumone } onChange={(e: any) => this.onChangeMasterAccountRoot({'accumone': (e.target.value)})} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <TextField fullWidth id="leveltwo" label="Nivel 2" variant="outlined" value={ masterAccountEntity.leveltwo } onChange={(e: any) => this.onChangeMasterAccountRoot({'leveltwo': (e.target.value)})} />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField fullWidth id="accumtwo" label="Acumulado 2" variant="outlined" value={ masterAccountEntity.accumtwo } onChange={(e: any) => this.onChangeMasterAccountRoot({'accumtwo': (e.target.value)})} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <TextField fullWidth id="leveltre" label="Nivel 3" variant="outlined" value={ masterAccountEntity.leveltre } onChange={(e: any) => this.onChangeMasterAccountRoot({'leveltre': (e.target.value)})} />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField fullWidth id="accumtre" label="Acumulado 3" variant="outlined" value={ masterAccountEntity.accumtre } onChange={(e: any) => this.onChangeMasterAccountRoot({'accumtre': (e.target.value)})} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <TextField fullWidth id="levelfou" label="Nivel 4" variant="outlined" value={ masterAccountEntity.levelfou } onChange={(e: any) => this.onChangeMasterAccountRoot({'levelfou': (e.target.value)})} />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField fullWidth id="accumfou" label="Acumulado 4" variant="outlined" value={ masterAccountEntity.accumfou } onChange={(e: any) => this.onChangeMasterAccountRoot({'accumfou': (e.target.value)})} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <TextField fullWidth id="levelfig" label="Nivel 5" variant="outlined" value={ masterAccountEntity.levelfig } onChange={(e: any) => this.onChangeMasterAccountRoot({'levelfig': (e.target.value)})} />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField fullWidth id="accumfig" label="Acumulado 5" variant="outlined" value={ masterAccountEntity.accunfig } onChange={(e: any) => this.onChangeMasterAccountRoot({'accunfig': (e.target.value)})} />
                            </Grid>
                        </Grid>
                    </form>
                    <br/>
                    <FormFooter onCancel={ this.onCancel } onSubmit={ this.onSubmit }/>
                </Paper>
                <ScreenLoader enabled={loading}/>
            </div>
        );
    }
}

const accountantReducers = 'accountantReducers';
const generalReducers = 'generalReducers';

const mapStateToProps = (state: any) => ({
    accountantReducers: state.get(accountantReducers),
    generalReducers: state.get(generalReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        masterAccountGetAllRecordsRequest,
        masterAccountUpdateRequest
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(FormMasterAccount));