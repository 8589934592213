import React from 'react';
import { Backdrop, Button, ButtonGroup, Checkbox, CircularProgress, createStyles, FormControl, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Select, Snackbar, TextField, Theme, Tooltip, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { usersInitFormRequest, usersRegisterRequest, usersUpdateRequest, usersInitUpdateFormRequest } from 'penta-redux/actions/usersActions';
import { locationsGetProvincesRequest, locationsGetDistrictsRequest } from 'penta-redux/actions/generalActions';
import { userEntity } from 'models/userModels';
import history from '../../../utils/history';
import './users.scss'
import FormFooter from 'penta-components/base/formFooter';
import { MessageBox } from 'penta-components/base/messageBox';
import { Alert } from '@material-ui/lab';
import ScreenLoader from 'penta-components/base/screenLoader';

const useStyles = (theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        formControl: {
            margin: 0,
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    });

const defaultUserEntity: userEntity = {
    username: '',
    password: '',
    usrsttus: '',
    usr_userinfo: {
        nameeess: '',
        lastname: '',
        mlstname: '',
        emailadr: '',
        phonumbr: '',
        addrline: '',
        locacode: '',
        photpath: ''
    },
    lst_usr_syssrols: []
}

interface FormUsersState {
    userEntity: userEntity
    department: string
    province: string
    title: string
    snackbar: boolean
}

class FormUsers extends React.Component<any, FormUsersState> {

    constructor(props: any) {
        super(props);
        this.state = {
            userEntity: defaultUserEntity,
            department: '',
            province: '',
            title: 'Agregar Usuario',
            snackbar: false
        }
    }

    onChangeRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            ...value
        }))
    }

    onChangeDepartment = (value: any) => {
        this.onChangeRoot(value);
        this.props.locationsGetProvincesRequest(value.department);
    }

    onChangeProvince = (value: any) => {
        this.onChangeRoot(value);
        this.props.locationsGetDistrictsRequest(value.province);
    }

    onChangeUserRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            userEntity: {
                ...state.userEntity,
                ...value
            }
        }))
    }

    onChangeUserInfo = (value: any) => {
        this.setState((state) => ({
            ...state,
            userEntity: {
                ...state.userEntity,
                usr_userinfo: {
                    ...state.userEntity.usr_userinfo,
                    ...value
                }
            }
        }))
    }

    onChangeRoles = (value: any) => {
        let { lst_usr_syssrols } = this.state.userEntity;
        if(lst_usr_syssrols.find((x) => x.rolecode === value.rolecode) !== undefined) {
            lst_usr_syssrols = lst_usr_syssrols.filter((x: any) => x.rolecode !== value.rolecode);
        }
        else {
            lst_usr_syssrols.push(value);
        }
        this.setState((state) => ({
            ...state,
            userEntity: {
                ...state.userEntity,
                lst_usr_syssrols
            }
        }))
    }

    onCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        console.log('cancel')
        history.push('/papp/users/maestro-usuarios');
    }

    onContinueCallback = () => {
        const newUserEntity = defaultUserEntity;
        newUserEntity.lst_usr_syssrols = [];
        this.setState({
            userEntity: newUserEntity,
            department: '',
            province: '',
            snackbar: true
        });
    }

    onSubmitContinue = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const { params } = this.props.match;
        if (params.username !== undefined) {
            this.props.usersUpdateRequest(this.state.userEntity);
        } else {
            this.props.usersRegisterRequest(this.state.userEntity, this.onContinueCallback);
        }
    }

    onSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const { params } = this.props.match;
        if (params.username !== undefined) {
            this.props.usersUpdateRequest(this.state.userEntity);
        } else {
            this.props.usersRegisterRequest(this.state.userEntity, null);
        }
    }

    callbackInitUpdate = (userEntity: userEntity, department: string, province: string) => {
        userEntity.lst_usr_syssrols = userEntity.lst_usr_syssrols.map((x) => {
            return {
                ...x,
                lst_usr_syspemsn: []
            }
        })
        this.setState({
            userEntity,
            department,
            province,
        });
    }

    componentDidMount() {
        const { params } = this.props.match;
        if (params.username !== undefined) {
            this.setState({title: 'Editar Usuario'});
            this.props.usersInitUpdateFormRequest(params.username, this.callbackInitUpdate);
        }
        else {
            this.props.usersInitFormRequest();
        }
    }

    render() {

        const { userEntity, department, province, title, snackbar } = this.state;
        const { loading, tables, failed, failedTitle, failedContent } = this.props.usersReducers;
        const generalTables = this.props.generalReducers.tables;
        const { classes } = this.props;

        return (
            <div style={{overflow: 'auto', height: '100%'}}>
                <Paper className='form__container'>
                    <br/>
                    <Typography variant='h5'>{ title }</Typography>
                    <br/>
                    { failed && <MessageBox title={failedTitle} content={failedContent}/> }
                    <form noValidate autoComplete="off">
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <TextField fullWidth id="username" label="Username" variant="outlined" value={ userEntity.username } onChange={(e: any) => this.onChangeUserRoot({'username': e.target.value})}/>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth id="password" label="Password" variant="outlined" type='password' value={ userEntity.password } onChange={(e: any) => this.onChangeUserRoot({'password': e.target.value})}/>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth id="emailadr" label="Correo" variant="outlined" value={ userEntity.usr_userinfo.emailadr } onChange={(e: any) => this.onChangeUserInfo({'emailadr': e.target.value})}/>
                            </Grid>
                            <Grid item xs={3}>
                                {/* <TextField fullWidth id="usrsttus" label="Estado" variant="outlined" /> */}
                                <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                    <InputLabel id="lbl_usrsttus">Estado</InputLabel>
                                    <Select
                                        labelId="lbl_usrsttus"
                                        id="usrsttus"
                                        // value={company}
                                        // onChange={(e: React.ChangeEvent<{ value: unknown }>) => { setCompany(e.target.value as string) }}
                                        className='select'
                                        label="Estado"
                                        fullWidth
                                        value={userEntity.usrsttus}
                                        onChange={(e: any) => this.onChangeUserRoot({'usrsttus': e.target.value})}
                                    >
                                        <MenuItem value='A'>Activo</MenuItem>
                                        <MenuItem value='I'>Inactivo</MenuItem>
                                        {/* {
                                            lst_companys.map((x: any) => 
                                                <MenuItem value={x.taxident} key={x.taxident}>{ x.bussname }</MenuItem>
                                            )
                                        } */}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <TextField fullWidth id="nameeess" label="Nombres" variant="outlined" value={ userEntity.usr_userinfo.nameeess } onChange={(e: any) => this.onChangeUserInfo({'nameeess': e.target.value})}/>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth id="lastname" label="Apellido Paterno" variant="outlined" value={ userEntity.usr_userinfo.lastname } onChange={(e: any) => this.onChangeUserInfo({'lastname': e.target.value})}/>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth id="mlstname" label="Apellido Materno" variant="outlined" value={ userEntity.usr_userinfo.mlstname } onChange={(e: any) => this.onChangeUserInfo({'mlstname': e.target.value})}/>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth id="phonumbr" label="Numero de telefono" variant="outlined" value={ userEntity.usr_userinfo.phonumbr } onChange={(e: any) => this.onChangeUserInfo({'phonumbr': e.target.value})}/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                    <InputLabel id="lbl_department">Departamento</InputLabel>
                                    <Select
                                        labelId="lbl_department"
                                        id="department"
                                        className='select'
                                        label="Departamento"
                                        fullWidth
                                        value={ department }
                                        onChange={ (e) => this.onChangeDepartment({department: e.target.value}) }
                                    >
                                        {
                                            tables.departamentos.map((item: any, key: any) => 
                                                <MenuItem value={item.locacode} key={`department_${key}`}>{item.locaname}</MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                    <InputLabel id="lbl_province">Provincia</InputLabel>
                                    <Select
                                        labelId="lbl_province"
                                        id="province"
                                        className='select'
                                        label="Provincia"
                                        fullWidth
                                        value={ province }
                                        onChange={ (e) => this.onChangeProvince({province: e.target.value}) }
                                    >
                                        {
                                            generalTables.provincias.map((item: any, key: any) => 
                                                <MenuItem value={item.locacode} key={`province_${key}`}>{item.locaname}</MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                    <InputLabel id="lbl_district">Distrito</InputLabel>
                                    <Select
                                        labelId="lbl_district"
                                        id="district"
                                        className='select'
                                        label="Distrito"
                                        fullWidth
                                        value={userEntity.usr_userinfo.locacode}
                                        onChange={(e: any) => this.onChangeUserInfo({'locacode': e.target.value})}
                                    >
                                        {
                                            generalTables.distritos.map((item: any, key: any) => 
                                                <MenuItem value={item.locacode} key={`district_${key}`}>{item.locaname}</MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={9}>
                                <TextField fullWidth id="addrline" label="Direccion" variant="outlined" value={ userEntity.usr_userinfo.addrline } onChange={(e: any) => this.onChangeUserInfo({'addrline': e.target.value})}/>
                            </Grid>
                        </Grid>
                    </form>
                    <br/>
                    <Typography variant='subtitle1'>Roles</Typography>
                    <List className={classes.root}>
                        {tables.roles.map((item: any, key: any) => (
                            <ListItem key={`roles_list_${key}`} role={undefined} dense button onChange={ () => this.onChangeRoles(item)}>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={ userEntity.lst_usr_syssrols.find((x) => x.rolecode === item.rolecode) !== undefined }
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': `roles_list_${key}` }}
                                    />
                                    </ListItemIcon>
                                <ListItemText id={`roles_list_${key}`} primary={`${item.rolecode} | ${item.rolename}`} />
                            </ListItem>
                        ))}
                    </List>
                    <FormFooter 
                        onCancel={ this.onCancel } 
                        onSubmitContinue={ this.onSubmitContinue } 
                        onSubmit={ this.onSubmit }/>
                </Paper>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={snackbar}
                    onClose={() => this.setState({snackbar: false})}
                    autoHideDuration={6000}
                >
                    <Alert onClose={() => this.setState({snackbar: false})} severity="success">
                            Datos Guardados Correctamente
                    </Alert>
                </Snackbar>
                <ScreenLoader enabled={loading}/>
            </div>
        );
    }
}

const usersReducers = 'usersReducers';
const generalReducers = 'generalReducers';

const mapStateToProps = (state: any) => ({
    usersReducers: state.get(usersReducers),
    generalReducers: state.get(generalReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        usersInitFormRequest,
        usersInitUpdateFormRequest,
        locationsGetProvincesRequest,
        locationsGetDistrictsRequest,
        usersRegisterRequest,
        usersUpdateRequest
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(FormUsers));