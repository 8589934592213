import React from 'react';
import { Backdrop, Button, ButtonGroup, Checkbox, CircularProgress, createStyles, FormControl, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Select, TextField, Theme, Tooltip, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { employePayrollRequest } from 'penta-redux/actions/humanResourcesActions';
import history from '../../../../utils/history';
import './../reports.scss'
import FormFooter from 'penta-components/base/formFooter';
import { MessageBox } from 'penta-components/base/messageBox';
import { categoryEntity } from 'models/wharehouseProductsModels';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PdfView from 'penta-components/base/pdfView';
// import * as pdfMake from "pdfmake/build/pdfmake";
// import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
import * as XLSX from 'sheetjs-style';
import ScreenLoader from 'penta-components/base/screenLoader';

let pdfMake = require("pdfmake/build/pdfmake");
let saveAs = require('file-saver').saveAs;

const meses = [
    { key: '01', value: 'Enero' },
    { key: '02', value: 'Febrero' },
    { key: '03', value: 'Marzo' },
    { key: '04', value: 'Abril' },
    { key: '05', value: 'Mayo' },
    { key: '06', value: 'Junio' },
    { key: '07', value: 'Julio' },
    { key: '08', value: 'Agosto' },
    { key: '09', value: 'Septiembre' },
    { key: '10', value: 'Octubre' },
    { key: '11', value: 'Noviembre' },
    { key: '12', value: 'Diciembre' }
]

const typesala = {
    '001': 'SUELDOS',
    '002': 'VACACIONES',
    '003': 'GRATIFICACIONES',
    '004': 'SUBSIDIO',
    '005': 'BONIFICACION VOLUNTARIO',
    '006': 'GRATIFICACION EXTRAORDINARIA',
    '007': 'PARTICIPACION UTILIDADES VOLUNT.',
    '008': 'UTLIDADES',
    '009': 'CTS',
    '010': 'BONIFICACION ESPECIAL'
}

const useStyles = (theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        formControl: {
            margin: 0,
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    });

interface FormcategorysState {
    title: string,
    year: string,
    month: string,
    typesala: string
    base64Pdf: string
}

class ReportEmployePayroll extends React.Component<any, FormcategorysState> {

    constructor(props: any) {
        super(props);
        this.state = {
            title: 'Reporte de Planilla de Empleados',
            year: moment().format('yyyy').toString(),
            month: moment().format('MM').toString(),
            typesala: '001',
            base64Pdf: ''
        }
    }

    onChangeRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            ...value
        }))
    }

    onGeneratePdf = () => {
        const data = this.props.humanResourcesReducers.onlyOne;
        const { month, year } = this.state;
        const { dashboardReducers } = this.props;
        const mes = meses.find((x: any) => x.key === month).value;
        const tables = JSON.parse(localStorage.getItem('tables'));
        const company = tables.companys.find((x: any) => x.taxident === dashboardReducers.currentCompany);
        const division = company.lst_grl_division.find((x: any) => x.divicode === dashboardReducers.currentDivision);
        const oficina = division.lst_grl_bussofic.find((x: any) => x.officode === dashboardReducers.currentBussOfic);

        const pdfObject: any = {
            pageSize: {
                width: 2700,
                height: 700  
            },
            pageMargins: [ 20, 110, 20, 20 ],
            header: [
                {
                    text: ' ', lineHeight: 2
                },
                {
                    margin: [20, 0, 20, 0],
                    text: company.bussname.toUpperCase(),
                    style: {
                        bold: true,
                        fontSize: 12
                    }
                },
                {
                    margin: [20, 5, 20, 0],
                    text: `MES DE ${mes.toUpperCase()} DEL ${year}`,
                    style: {
                        fontSize: 12
                    }
                },
                {
                    margin: [20, 20, 20, 0],
                    text: 'PLANILLA DE ' + typesala[this.state.typesala] + ' EMPLEADOS ' + division.diviname.toUpperCase() + '-' + oficina.offiname.toUpperCase(),
                    style: {
                        fontSize: 12
                    }
                },
                {
                    text: ' ', lineHeight: 0.5
                }
            ],
            content: [
                {
                    table: {
                        headerRows: 2,
                        widths: [],
                        body: []
                    }
                },
            ],
            styles: {
                headerTitle: {
                    bold: true,
                    fontSize: 8,
                    lineHeight: 1.2
                },
                table: {
                    layout: 'headerLineOnly',
                    fontSize: 8,
                    alignment: 'center'
                },
                subTable: {
                    layout: 'headerLineOnly',
                    fontSize: 8,
                    alignment: 'left'
                },
                tableDetailLeft: {
                    layout: 'headerLineOnly',
                    fontSize: 7,
                    alignment: 'left'
                },
                tableDetailRight: {
                    layout: 'headerLineOnly',
                    fontSize: 7,
                    alignment: 'right'
                }
            },
            defaultStyle: {
                columnGap: 20,
            },
        }

        let tableWidths = [ 50, 120, 100, 60, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50 ];
        let colSpans = [5,14,14,1,4];

        const tableBody: any = [
            [],
            [ 
                { text: '\nCODIGO', style: 'subTable', border: [true, false, false, true] },
                { text: '\nAPELLIDOS Y NOMBRES', style: 'subTable', border: [false, false, false, true] },
                { text: '\nCARGO', style: 'subTable', border: [false, false, false, true] },
                { text: 'FECHA INGRESO', style: 'table', border: [false, false, false, true] },
                { text: 'SUELDO BASICO', style: 'table', border: [false, false, false, true] },
                
                { text: '\nS.BASICO', style: 'table', border: [true, false, false, true] },
                { text: '\nAS.FAMIL.', style: 'table', border: [false, false, false, true] },
                { text: '\nGRATIF.', style: 'table', border: [false, false, false, true] },
                { text: '\nH.E.25%', style: 'table', border: [false, false, false, true] },
                { text: '\nH.E.35%', style: 'table', border: [false, false, false, true] },
                { text: '\nH.E.100%', style: 'table', border: [false, false, false, true] },
                { text: '\nINC.PROD.', style: 'table', border: [false, false, false, true] },
                { text: '\nOTROS', style: 'table', border: [false, false, false, true] },
                { text: '\n1RO.MAYO', style: 'table', border: [false, false, false, true] },
                { text: '\nREINTEGRO', style: 'table', border: [false, false, false, true] },
                { text: '\nOTR.ING.', style: 'table', border: [false, false, false, true] },
                { text: '\nOTROS', style: 'table', border: [false, false, false, true] },
                { text: '\nBONIF.', style: 'table', border: [false, false, false, true] },
                { text: '\nTOTAL', style: 'table', border: [false, false, false, true] },
                
                { text: '\nAD.QUINC.', style: 'table', border: [true, false, false, true] },
                { text: '\nADELANTO.', style: 'table', border: [false, false, false, true] },
                { text: '\nDSC.JUD.', style: 'table', border: [false, false, false, true] },
                { text: '\nDCS.JUD.', style: 'table', border: [false, false, false, true] },
                { text: '\nRET.5TA.', style: 'table', border: [false, false, false, true] },
                { text: '\nPRESTAMO', style: 'table', border: [false, false, false, true] },
                { text: '\nS.N.P', style: 'table', border: [false, false, false, true] },
                { text: '\nE.VIDA', style: 'table', border: [false, false, false, true] },
                { text: '\nS.I.C.', style: 'table', border: [false, false, false, true] },
                { text: '\nCOM.FLUJO', style: 'table', border: [false, false, false, true] },
                { text: '\nCOM.MIXTA', style: 'table', border: [false, false, false, true] },
                { text: '\nSEGURO', style: 'table', border: [false, false, false, true] },
                { text: '\nF.PENS.', style: 'table', border: [false, false, false, true] },
                { text: '\nTOTAL', style: 'table', border: [false, false, false, true] },
                
                { text: '\nNETO', style: 'table', border: [true, false, false, true] },
                
                { text: '\nESSALUD', style: 'table', border: [true, false, false, true] },
                { text: '\nS.N.P.', style: 'table', border: [false, false, false, true] },
                { text: '\nI.E.S.', style: 'table', border: [false, false, false, true] },
                { text: '\nTOTAL', style: 'table', border: [false, false, true, true] },
            ]
        ]
        
        let gc201 = 0.00;
        let gc202 = 0.00;
        let gc203 = 0.00;
        let gc204 = 0.00;
        let gc205 = 0.00;
        let gc206 = 0.00;
        let gc207 = 0.00;
        let gc208 = 0.00;
        let gc209 = 0.00;
        let gc210 = 0.00;
        let gc211 = 0.00;
        let gc212 = 0.00;
        let gc213 = 0.00;
        let gc501 = 0.00;
        let gc301 = 0.00;
        let gc302 = 0.00;
        let gc303 = 0.00;
        let gc304 = 0.00;
        let gc305 = 0.00;
        let gc306 = 0.00;
        let gc307 = 0.00;
        let gc308 = 0.00;
        let gc309 = 0.00;
        let gc310 = 0.00;
        let gc311 = 0.00;
        let gc312 = 0.00;
        let gc313 = 0.00;
        let gc502 = 0.00;
        let gc503 = 0.00;
        let gc401 = 0.00;
        let gc402 = 0.00;
        let gc403 = 0.00;
        let gc404 = 0.00;

        for (const row of data) {

            const concepts = JSON.parse(row.concepts);

            const c201 = concepts.find((x: any) => x.conccode === 'C201')?.outcomee || '0.00';
            const c202 = concepts.find((x: any) => x.conccode === 'C202')?.outcomee || '0.00';
            const c203 = concepts.find((x: any) => x.conccode === 'C203')?.outcomee || '0.00';
            const c204 = concepts.find((x: any) => x.conccode === 'C204')?.outcomee || '0.00';
            const c205 = concepts.find((x: any) => x.conccode === 'C205')?.outcomee || '0.00';
            const c206 = concepts.find((x: any) => x.conccode === 'C206')?.outcomee || '0.00';
            const c207 = concepts.find((x: any) => x.conccode === 'C207')?.outcomee || '0.00';
            const c208 = concepts.find((x: any) => x.conccode === 'C208')?.outcomee || '0.00';
            const c209 = concepts.find((x: any) => x.conccode === 'C209')?.outcomee || '0.00';
            const c210 = concepts.find((x: any) => x.conccode === 'C210')?.outcomee || '0.00';
            const c211 = concepts.find((x: any) => x.conccode === 'C211')?.outcomee || '0.00';
            const c212 = concepts.find((x: any) => x.conccode === 'C212')?.outcomee || '0.00';
            const c213 = concepts.find((x: any) => x.conccode === 'C213')?.outcomee || '0.00';
            const c501 = concepts.find((x: any) => x.conccode === 'C501')?.outcomee || '0.00';

            const c301 = concepts.find((x: any) => x.conccode === 'C301')?.outcomee || '0.00';
            const c302 = concepts.find((x: any) => x.conccode === 'C302')?.outcomee || '0.00';
            const c303 = concepts.find((x: any) => x.conccode === 'C303')?.outcomee || '0.00';
            const c304 = concepts.find((x: any) => x.conccode === 'C304')?.outcomee || '0.00';
            const c305 = concepts.find((x: any) => x.conccode === 'C305')?.outcomee || '0.00';
            const c306 = concepts.find((x: any) => x.conccode === 'C306')?.outcomee || '0.00';
            const c307 = concepts.find((x: any) => x.conccode === 'C307')?.outcomee || '0.00';
            const c308 = concepts.find((x: any) => x.conccode === 'C308')?.outcomee || '0.00';
            const c309 = concepts.find((x: any) => x.conccode === 'C309')?.outcomee || '0.00';
            const c310 = concepts.find((x: any) => x.conccode === 'C310')?.outcomee || '0.00';
            const c311 = concepts.find((x: any) => x.conccode === 'C311')?.outcomee || '0.00';
            const c312 = concepts.find((x: any) => x.conccode === 'C312')?.outcomee || '0.00';
            const c313 = concepts.find((x: any) => x.conccode === 'C313')?.outcomee || '0.00';
            const c502 = concepts.find((x: any) => x.conccode === 'C502')?.outcomee || '0.00';

            const c503 = concepts.find((x: any) => x.conccode === 'C503')?.outcomee || '0.00';

            const c401 = concepts.find((x: any) => x.conccode === 'C401')?.outcomee || '0.00';
            const c402 = concepts.find((x: any) => x.conccode === 'C402')?.outcomee || '0.00';
            const c403 = concepts.find((x: any) => x.conccode === 'C403')?.outcomee || '0.00';
            const c404 = concepts.find((x: any) => x.conccode === 'C404')?.outcomee || '0.00';

            tableBody.push(// pdfObject.content[0].table.body.push(
                [ 
                    { text: row.emplcode, style: 'tableDetailLeft', border: [true, false, false, true] },
                    { text: row.nameeess.substr(0, 30), style: 'tableDetailLeft', border: [false, false, false, true] },
                    { text: row.posscode.substr(0, 25), style: 'tableDetailLeft', border: [false, false, false, true] },
                    { text: moment(row.dateadmi).format('YYYY-MM-DD'), style: 'tableDetailLeft', border: [false, false, false, true] },
                    { text: String.formatNumber.new(row.basalary), style: 'tableDetailRight', border: [false, false, false, true] },
                    
                    { text: String.formatNumber.new(c201), style: 'tableDetailRight', border: [true, false, false, true] },
                    { text: String.formatNumber.new(c202), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c203), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c204), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c205), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c206), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c207), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c208), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c209), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c210), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c211), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c212), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c213), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c501), style: 'tableDetailRight', border: [false, false, false, true] },
                    
                    { text: String.formatNumber.new(c301), style: 'tableDetailRight', border: [true, false, false, true] },
                    { text: String.formatNumber.new(c302), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c303), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c304), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c305), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c306), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c307), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c308), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c309), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c310), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c311), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c312), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c313), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c502), style: 'tableDetailRight', border: [false, false, false, true] },
                    
                    { text: String.formatNumber.new(c503), style: 'tableDetailRight', border: [true, false, false, true] },
                    
                    { text: String.formatNumber.new(c401), style: 'tableDetailRight', border: [true, false, false, true] },
                    { text: String.formatNumber.new(c402), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c403), style: 'tableDetailRight', border: [false, false, false, true] },
                    { text: String.formatNumber.new(c404), style: 'tableDetailRight', border: [false, false, true, true] }
                ]
            )

            gc201 += parseFloat(c201);
            gc202 += parseFloat(c202);
            gc203 += parseFloat(c203);
            gc204 += parseFloat(c204);
            gc205 += parseFloat(c205);
            gc206 += parseFloat(c206);
            gc207 += parseFloat(c207);
            gc208 += parseFloat(c208);
            gc209 += parseFloat(c209);
            gc210 += parseFloat(c210);
            gc211 += parseFloat(c211);
            gc212 += parseFloat(c212);
            gc213 += parseFloat(c213);
            gc501 += parseFloat(c501);
            gc301 += parseFloat(c301);
            gc302 += parseFloat(c302);
            gc303 += parseFloat(c303);
            gc304 += parseFloat(c304);
            gc305 += parseFloat(c305);
            gc306 += parseFloat(c306);
            gc307 += parseFloat(c307);
            gc308 += parseFloat(c308);
            gc309 += parseFloat(c309);
            gc310 += parseFloat(c310);
            gc311 += parseFloat(c311);
            gc312 += parseFloat(c312);
            gc313 += parseFloat(c313);
            gc502 += parseFloat(c502);
            gc503 += parseFloat(c503);
            gc401 += parseFloat(c401);
            gc402 += parseFloat(c402);
            gc403 += parseFloat(c403);
            gc404 += parseFloat(c404);
        }

        tableBody.push(
            [ 
                { text: 'TOTAL PLANILLA', style: 'table', colSpan: 5, border: [true, false, false, true] },
                {},
                {},
                {},
                {},
                
                { text: String.formatNumber.new(gc201), style: 'tableDetailRight', border: [true, false, false, true] },
                { text: String.formatNumber.new(gc202), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(gc203), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(gc204), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(gc205), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(gc206), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(gc207), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(gc208), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(gc209), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(gc210), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(gc211), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(gc212), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(gc213), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(gc501), style: 'tableDetailRight', border: [false, false, false, true] },
                
                { text: String.formatNumber.new(gc301), style: 'tableDetailRight', border: [true, false, false, true] },
                { text: String.formatNumber.new(gc302), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(gc303), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(gc304), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(gc305), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(gc306), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(gc307), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(gc308), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(gc309), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(gc310), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(gc311), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(gc312), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(gc313), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(gc502), style: 'tableDetailRight', border: [false, false, false, true] },
                
                { text: String.formatNumber.new(gc503), style: 'tableDetailRight', border: [true, false, false, true] },
                
                { text: String.formatNumber.new(gc401), style: 'tableDetailRight', border: [true, false, false, true] },
                { text: String.formatNumber.new(gc402), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(gc403), style: 'tableDetailRight', border: [false, false, false, true] },
                { text: String.formatNumber.new(gc404), style: 'tableDetailRight', border: [false, false, true, true] }
            ]
        )

        for (let i = 1; i < tableBody.length; i++) {
            const iterator = tableBody[i];
            if (gc201 <= 0) { delete iterator[5] ; }
            if (gc202 <= 0) { delete iterator[6] ; }
            if (gc203 <= 0) { delete iterator[7] ; }
            if (gc204 <= 0) { delete iterator[8] ; }
            if (gc205 <= 0) { delete iterator[9] ; }
            if (gc206 <= 0) { delete iterator[10]; }
            if (gc207 <= 0) { delete iterator[11]; }
            if (gc208 <= 0) { delete iterator[12]; }
            if (gc209 <= 0) { delete iterator[13]; }
            if (gc210 <= 0) { delete iterator[14]; }
            if (gc211 <= 0) { delete iterator[15]; }
            if (gc212 <= 0) { delete iterator[16]; }
            if (gc213 <= 0) { delete iterator[17]; }
            if (gc501 <= 0) { delete iterator[18]; }
            if (gc301 <= 0) { delete iterator[19]; }
            if (gc302 <= 0) { delete iterator[20]; }
            if (gc303 <= 0) { delete iterator[21]; }
            if (gc304 <= 0) { delete iterator[22]; }
            if (gc305 <= 0) { delete iterator[23]; }
            if (gc306 <= 0) { delete iterator[24]; }
            if (gc307 <= 0) { delete iterator[25]; }
            if (gc308 <= 0) { delete iterator[26]; }
            if (gc309 <= 0) { delete iterator[27]; }
            if (gc310 <= 0) { delete iterator[28]; }
            if (gc311 <= 0) { delete iterator[29]; }
            if (gc312 <= 0) { delete iterator[30]; }
            if (gc313 <= 0) { delete iterator[31]; }
            if (gc502 <= 0) { delete iterator[32]; }
            if (gc503 <= 0) { delete iterator[33]; }
            if (gc401 <= 0) { delete iterator[34]; }
            if (gc402 <= 0) { delete iterator[35]; }
            if (gc403 <= 0) { delete iterator[36]; }
            if (gc404 <= 0) { delete iterator[37]; }
        }

        if (gc201 <= 0) { delete tableWidths[5] ; colSpans[1] = colSpans[1] - 1 }
        if (gc202 <= 0) { delete tableWidths[6] ; colSpans[1] = colSpans[1] - 1 }
        if (gc203 <= 0) { delete tableWidths[7] ; colSpans[1] = colSpans[1] - 1 }
        if (gc204 <= 0) { delete tableWidths[8] ; colSpans[1] = colSpans[1] - 1 }
        if (gc205 <= 0) { delete tableWidths[9] ; colSpans[1] = colSpans[1] - 1 }
        if (gc206 <= 0) { delete tableWidths[10]; colSpans[1] = colSpans[1] - 1 }
        if (gc207 <= 0) { delete tableWidths[11]; colSpans[1] = colSpans[1] - 1 }
        if (gc208 <= 0) { delete tableWidths[12]; colSpans[1] = colSpans[1] - 1 }
        if (gc209 <= 0) { delete tableWidths[13]; colSpans[1] = colSpans[1] - 1 }
        if (gc210 <= 0) { delete tableWidths[14]; colSpans[1] = colSpans[1] - 1 }
        if (gc211 <= 0) { delete tableWidths[15]; colSpans[1] = colSpans[1] - 1 }
        if (gc212 <= 0) { delete tableWidths[16]; colSpans[1] = colSpans[1] - 1 }
        if (gc213 <= 0) { delete tableWidths[17]; colSpans[1] = colSpans[1] - 1 }
        if (gc501 <= 0) { delete tableWidths[18]; colSpans[1] = colSpans[1] - 1 }
        if (gc301 <= 0) { delete tableWidths[19]; colSpans[2] = colSpans[2] - 1 }
        if (gc302 <= 0) { delete tableWidths[20]; colSpans[2] = colSpans[2] - 1 }
        if (gc303 <= 0) { delete tableWidths[21]; colSpans[2] = colSpans[2] - 1 }
        if (gc304 <= 0) { delete tableWidths[22]; colSpans[2] = colSpans[2] - 1 }
        if (gc305 <= 0) { delete tableWidths[23]; colSpans[2] = colSpans[2] - 1 }
        if (gc306 <= 0) { delete tableWidths[24]; colSpans[2] = colSpans[2] - 1 }
        if (gc307 <= 0) { delete tableWidths[25]; colSpans[2] = colSpans[2] - 1 }
        if (gc308 <= 0) { delete tableWidths[26]; colSpans[2] = colSpans[2] - 1 }
        if (gc309 <= 0) { delete tableWidths[27]; colSpans[2] = colSpans[2] - 1 }
        if (gc310 <= 0) { delete tableWidths[28]; colSpans[2] = colSpans[2] - 1 }
        if (gc311 <= 0) { delete tableWidths[29]; colSpans[2] = colSpans[2] - 1 }
        if (gc312 <= 0) { delete tableWidths[30]; colSpans[2] = colSpans[2] - 1 }
        if (gc313 <= 0) { delete tableWidths[31]; colSpans[2] = colSpans[2] - 1 }
        if (gc502 <= 0) { delete tableWidths[32]; colSpans[2] = colSpans[2] - 1 }
        if (gc503 <= 0) { delete tableWidths[33]; colSpans[3] = colSpans[3] - 1 }
        if (gc401 <= 0) { delete tableWidths[34]; colSpans[4] = colSpans[4] - 1 }
        if (gc402 <= 0) { delete tableWidths[35]; colSpans[4] = colSpans[4] - 1 }
        if (gc403 <= 0) { delete tableWidths[36]; colSpans[4] = colSpans[4] - 1 }
        if (gc404 <= 0) { delete tableWidths[37]; colSpans[4] = colSpans[4] - 1 }

        const getArray = (col, colSpan) => {
            if(colSpan === 0) return []; 
            const arr = [col];
            for (let index = 0; index < colSpan - 1; index++) {
                arr.push({});
            }
            return arr;
        }
        
        tableBody[0] = [ 
            ...getArray({ text: '', style: 'table', colSpan: colSpans[0], border: [true, true, false, false] }, colSpans[0]),
            ...getArray({ text: 'INGRESOS', style: 'table', colSpan: colSpans[1], border: [true, true, false, false] }, colSpans[1]),
            ...getArray({ text: 'DESCUENTOS', style: 'table', colSpan: colSpans[2], border: [true, true, false, false] }, colSpans[2]),
            ...getArray({ text: '', style: 'table', colSpan: colSpans[3], border: [true, true, false, false] }, colSpans[3]),
            ...getArray({ text: 'APORTACIONES', style: 'table', colSpan: colSpans[4], border: [true, true, true, false] }, colSpans[4])
        ]

        for (let index = 0; index < tableBody.length; index++) {
            tableBody[index] = tableBody[index].filter(x => x !== undefined);
        }

        pdfObject.content[0].table.widths = tableWidths.filter(x => x !== undefined);
        pdfObject.content[0].table.body = tableBody;

        pdfMake.fonts = {
            Roboto: {
                normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
                bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
                italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
                bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
              },
        }
        const pdfDocGenerator = pdfMake.createPdf(pdfObject);
        pdfDocGenerator.getDataUrl((data: any) => {
            // console.log(data);
            this.setState({
                base64Pdf: data
            })
        });

    }

    onGenerateExcel = () => {
        try {
            const data = this.props.humanResourcesReducers.onlyOne;
            const { month, year } = this.state;
            const { dashboardReducers } = this.props;
            const mes = meses.find((x: any) => x.key === month).value;
            const tables = JSON.parse(localStorage.getItem('tables'));
            const company = tables.companys.find((x: any) => x.taxident === dashboardReducers.currentCompany);
            const division = company.lst_grl_division.find((x: any) => x.divicode === dashboardReducers.currentDivision);
            const oficina = division.lst_grl_bussofic.find((x: any) => x.officode === dashboardReducers.currentBussOfic);

            const tableBody: any = [
                {
                    A:  company.bussname.toUpperCase(),
                    B:  null,
                    C:  null,
                    D:  null,
                    E:  null,
                    F:  null,
                    G:  null,
                    H:  null,
                    I:  null,
                    J:  null,
                    K:  null,
                    L:  null,
                    M:  null,
                    N:  null,
                    O:  null,
                    P:  null,
                    Q:  null,
                    R:  null,
                    S:  null,
                    T:  null,
                    U:  null,
                    V:  null,
                    W:  null,
                    X:  null,
                    Y:  null,
                    Z:  null,
                    AA: null,
                    AB: null,
                    AC: null,
                    AD: null,
                    AE: null,
                    AF: null,
                    AG: null,
                    AH: null,
                    AI: null,
                    AJ: null,
                    AK: null,
                    AL: null,
                },
                {
                    A:  `MES DE ${mes.toUpperCase()} DEL ${year}`,
                    B:  null,
                    C:  null,
                    D:  null,
                    E:  null,
                    F:  null,
                    G:  null,
                    H:  null,
                    I:  null,
                    J:  null,
                    K:  null,
                    L:  null,
                    M:  null,
                    N:  null,
                    O:  null,
                    P:  null,
                    Q:  null,
                    R:  null,
                    S:  null,
                    T:  null,
                    U:  null,
                    V:  null,
                    W:  null,
                    X:  null,
                    Y:  null,
                    Z:  null,
                    AA: null,
                    AB: null,
                    AC: null,
                    AD: null,
                    AE: null,
                    AF: null,
                    AG: null,
                    AH: null,
                    AI: null,
                    AJ: null,
                    AK: null,
                    AL: null,
                },
                {
                    A:  '',
                    B:  '',
                    C:  '',
                    D:  '',
                    E:  '',
                    F:  '',
                    G:  '',
                    H:  '',
                    I:  '',
                    J:  '',
                    K:  '',
                    L:  '',
                    M:  '',
                    N:  '',
                    O:  '',
                    P:  '',
                    Q:  '',
                    R:  '',
                    S:  '',
                    T:  '',
                    U:  '',
                    V:  '',
                    W:  '',
                    X:  '',
                    Y:  '',
                    Z:  '',
                    AA: '',
                    AB: '',
                    AC: '',
                    AD: '',
                    AE: '',
                    AF: '',
                    AG: '',
                    AH: '',
                    AI: '',
                    AJ: '',
                    AK: '',
                    AL: '',
                },
                {
                    A:  'PLANILLA DE ' + typesala[this.state.typesala] + ' EMPLEADOS ' + division.diviname.toUpperCase() + '-' + oficina.offiname.toUpperCase(),
                    B:  null,
                    C:  null,
                    D:  null,
                    E:  null,
                    F:  null,
                    G:  null,
                    H:  null,
                    I:  null,
                    J:  null,
                    K:  null,
                    L:  null,
                    M:  null,
                    N:  null,
                    O:  null,
                    P:  null,
                    Q:  null,
                    R:  null,
                    S:  null,
                    T:  null,
                    U:  null,
                    V:  null,
                    W:  null,
                    X:  null,
                    Y:  null,
                    Z:  null,
                    AA: null,
                    AB: null,
                    AC: null,
                    AD: null,
                    AE: null,
                    AF: null,
                    AG: null,
                    AH: null,
                    AI: null,
                    AJ: null,
                    AK: null,
                    AL: null,
                },
                {
                    A:  '',
                    B:  '',
                    C:  '',
                    D:  '',
                    E:  '',
                    F:  '',
                    G:  '',
                    H:  '',
                    I:  '',
                    J:  '',
                    K:  '',
                    L:  '',
                    M:  '',
                    N:  '',
                    O:  '',
                    P:  '',
                    Q:  '',
                    R:  '',
                    S:  '',
                    T:  '',
                    U:  '',
                    V:  '',
                    W:  '',
                    X:  '',
                    Y:  '',
                    Z:  '',
                    AA: '',
                    AB: '',
                    AC: '',
                    AD: '',
                    AE: '',
                    AF: '',
                    AG: '',
                    AH: '',
                    AI: '',
                    AJ: '',
                    AK: '',
                    AL: '',
                },
            ]

            tableBody.push(
                {
                    A:  '',
                    B:  '',
                    C:  '',
                    D:  '',
                    E:  '',
                    F:  'INGRESOS',
                    G:  '',
                    H:  '',
                    I:  '',
                    J:  '',
                    K:  '',
                    L:  '',
                    M:  '',
                    N:  '',
                    O:  '',
                    P:  '',
                    Q:  '',
                    R:  '',
                    S:  '',
                    T:  'DESCUENTOS',
                    U:  '',
                    V:  '',
                    W:  '',
                    X:  '',
                    Y:  '',
                    Z:  '',
                    AA: '',
                    AB: '',
                    AC: '',
                    AD: '',
                    AE: '',
                    AF: '',
                    AG: '',
                    AH: '',
                    AI: 'APORTACIONES',
                    AJ: '',
                    AK: '',
                    AL: '',
                },
            );
            tableBody.push(    
                { 
                    A:  'CODIGO',
                    B:  'APELLIDOS Y NOMBRES',
                    C:  'CARGO',
                    D:  'FECHA INGRESO',
                    E:  'SUELDO BASICO',
                    F:  'S.BASICO',
                    G:  'AS.FAMIL.',
                    H:  'GRATIF.',
                    I:  'H.E.25%',
                    J:  'H.E.35%',
                    K:  'H.E.100%',
                    L:  'INC.PROD.',
                    M:  'OTROS',
                    N:  '1RO.MAYO',
                    O:  'REINTEGRO',
                    P:  'OTR.ING.',
                    Q:  'OTROS',
                    R:  'BONIF.',
                    S:  'TOTAL',
                    T:  'AD.QUINC.',
                    U:  'ADELANTO.',
                    V:  'DSC.JUD.',
                    W:  'DCS.JUD.',
                    X:  'RET.5TA.',
                    Y:  'PRESTAMO',
                    Z:  'S.N.P',
                    AA: 'E.VIDA',
                    AB: 'S.I.C.',
                    AC: 'COM.FLUJO',
                    AD: 'COM.MIXTA',
                    AE: 'SEGURO',
                    AF: 'F.PENS.',
                    AG: 'TOTAL',
                    AH: 'NETO',
                    AI: 'ESSALUD',
                    AJ: 'S.N.P.',
                    AK: 'I.E.S.',
                    AL: 'TOTAL'
                }
            );

            let gc201 = 0.00;
            let gc202 = 0.00;
            let gc203 = 0.00;
            let gc204 = 0.00;
            let gc205 = 0.00;
            let gc206 = 0.00;
            let gc207 = 0.00;
            let gc208 = 0.00;
            let gc209 = 0.00;
            let gc210 = 0.00;
            let gc211 = 0.00;
            let gc212 = 0.00;
            let gc213 = 0.00;
            let gc501 = 0.00;
            let gc301 = 0.00;
            let gc302 = 0.00;
            let gc303 = 0.00;
            let gc304 = 0.00;
            let gc305 = 0.00;
            let gc306 = 0.00;
            let gc307 = 0.00;
            let gc308 = 0.00;
            let gc309 = 0.00;
            let gc310 = 0.00;
            let gc311 = 0.00;
            let gc312 = 0.00;
            let gc313 = 0.00;
            let gc502 = 0.00;
            let gc503 = 0.00;
            let gc401 = 0.00;
            let gc402 = 0.00;
            let gc403 = 0.00;
            let gc404 = 0.00;

            for (const row of data) {

                const concepts = JSON.parse(row.concepts);

                const c201 = concepts.find((x: any) => x.conccode === 'C201')?.outcomee || '0.00';
                const c202 = concepts.find((x: any) => x.conccode === 'C202')?.outcomee || '0.00';
                const c203 = concepts.find((x: any) => x.conccode === 'C203')?.outcomee || '0.00';
                const c204 = concepts.find((x: any) => x.conccode === 'C204')?.outcomee || '0.00';
                const c205 = concepts.find((x: any) => x.conccode === 'C205')?.outcomee || '0.00';
                const c206 = concepts.find((x: any) => x.conccode === 'C206')?.outcomee || '0.00';
                const c207 = concepts.find((x: any) => x.conccode === 'C207')?.outcomee || '0.00';
                const c208 = concepts.find((x: any) => x.conccode === 'C208')?.outcomee || '0.00';
                const c209 = concepts.find((x: any) => x.conccode === 'C209')?.outcomee || '0.00';
                const c210 = concepts.find((x: any) => x.conccode === 'C210')?.outcomee || '0.00';
                const c211 = concepts.find((x: any) => x.conccode === 'C211')?.outcomee || '0.00';
                const c212 = concepts.find((x: any) => x.conccode === 'C212')?.outcomee || '0.00';
                const c213 = concepts.find((x: any) => x.conccode === 'C213')?.outcomee || '0.00';
                const c501 = concepts.find((x: any) => x.conccode === 'C501')?.outcomee || '0.00';

                const c301 = concepts.find((x: any) => x.conccode === 'C301')?.outcomee || '0.00';
                const c302 = concepts.find((x: any) => x.conccode === 'C302')?.outcomee || '0.00';
                const c303 = concepts.find((x: any) => x.conccode === 'C303')?.outcomee || '0.00';
                const c304 = concepts.find((x: any) => x.conccode === 'C304')?.outcomee || '0.00';
                const c305 = concepts.find((x: any) => x.conccode === 'C305')?.outcomee || '0.00';
                const c306 = concepts.find((x: any) => x.conccode === 'C306')?.outcomee || '0.00';
                const c307 = concepts.find((x: any) => x.conccode === 'C307')?.outcomee || '0.00';
                const c308 = concepts.find((x: any) => x.conccode === 'C308')?.outcomee || '0.00';
                const c309 = concepts.find((x: any) => x.conccode === 'C309')?.outcomee || '0.00';
                const c310 = concepts.find((x: any) => x.conccode === 'C310')?.outcomee || '0.00';
                const c311 = concepts.find((x: any) => x.conccode === 'C311')?.outcomee || '0.00';
                const c312 = concepts.find((x: any) => x.conccode === 'C312')?.outcomee || '0.00';
                const c313 = concepts.find((x: any) => x.conccode === 'C313')?.outcomee || '0.00';
                const c502 = concepts.find((x: any) => x.conccode === 'C502')?.outcomee || '0.00';

                const c503 = concepts.find((x: any) => x.conccode === 'C503')?.outcomee || '0.00';

                const c401 = concepts.find((x: any) => x.conccode === 'C401')?.outcomee || '0.00';
                const c402 = concepts.find((x: any) => x.conccode === 'C402')?.outcomee || '0.00';
                const c403 = concepts.find((x: any) => x.conccode === 'C403')?.outcomee || '0.00';
                const c404 = concepts.find((x: any) => x.conccode === 'C404')?.outcomee || '0.00';

                tableBody.push(
                    { 
                        A:  row.emplcode,
                        B:  row.nameeess.substr(0, 30),
                        C:  row.posscode.substr(0, 25),
                        D:  moment(row.dateadmi).format('YYYY-MM-DD'),
                        E:  String.formatNumber.new(row.basalary),
                        F:  String.formatNumber.new(c201),
                        G:  String.formatNumber.new(c202),
                        H:  String.formatNumber.new(c203),
                        I:  String.formatNumber.new(c204),
                        J:  String.formatNumber.new(c205),
                        K:  String.formatNumber.new(c206),
                        L:  String.formatNumber.new(c207),
                        M:  String.formatNumber.new(c208),
                        N:  String.formatNumber.new(c209),
                        O:  String.formatNumber.new(c210),
                        P:  String.formatNumber.new(c211),
                        Q:  String.formatNumber.new(c212),
                        R:  String.formatNumber.new(c213),
                        S:  String.formatNumber.new(c501),
                        T:  String.formatNumber.new(c301),
                        U:  String.formatNumber.new(c302),
                        V:  String.formatNumber.new(c303),
                        W:  String.formatNumber.new(c304),
                        X:  String.formatNumber.new(c305),
                        Y:  String.formatNumber.new(c306),
                        Z:  String.formatNumber.new(c307),
                        AA: String.formatNumber.new(c308),
                        AB: String.formatNumber.new(c309),
                        AC: String.formatNumber.new(c310),
                        AD: String.formatNumber.new(c311),
                        AE: String.formatNumber.new(c312),
                        AF: String.formatNumber.new(c313),
                        AG: String.formatNumber.new(c502),
                        AH: String.formatNumber.new(c503),
                        AI: String.formatNumber.new(c401),
                        AJ: String.formatNumber.new(c402),
                        AK: String.formatNumber.new(c403),
                        AL: String.formatNumber.new(c404)
                    }
                )

                gc201 += parseFloat(c201);
                gc202 += parseFloat(c202);
                gc203 += parseFloat(c203);
                gc204 += parseFloat(c204);
                gc205 += parseFloat(c205);
                gc206 += parseFloat(c206);
                gc207 += parseFloat(c207);
                gc208 += parseFloat(c208);
                gc209 += parseFloat(c209);
                gc210 += parseFloat(c210);
                gc211 += parseFloat(c211);
                gc212 += parseFloat(c212);
                gc213 += parseFloat(c213);
                gc501 += parseFloat(c501);
                gc301 += parseFloat(c301);
                gc302 += parseFloat(c302);
                gc303 += parseFloat(c303);
                gc304 += parseFloat(c304);
                gc305 += parseFloat(c305);
                gc306 += parseFloat(c306);
                gc307 += parseFloat(c307);
                gc308 += parseFloat(c308);
                gc309 += parseFloat(c309);
                gc310 += parseFloat(c310);
                gc311 += parseFloat(c311);
                gc312 += parseFloat(c312);
                gc313 += parseFloat(c313);
                gc502 += parseFloat(c502);
                gc503 += parseFloat(c503);
                gc401 += parseFloat(c401);
                gc402 += parseFloat(c402);
                gc403 += parseFloat(c403);
                gc404 += parseFloat(c404);
            }

            tableBody.push(
                { 
                    A:  'TOTAL PLANILLA',
                    B:  '',
                    C:  '',
                    D:  '',
                    E:  '',
                    F:  String.formatNumber.new(gc201),
                    G:  String.formatNumber.new(gc202),
                    H:  String.formatNumber.new(gc203),
                    I:  String.formatNumber.new(gc204),
                    J:  String.formatNumber.new(gc205),
                    K:  String.formatNumber.new(gc206),
                    L:  String.formatNumber.new(gc207),
                    M:  String.formatNumber.new(gc208),
                    N:  String.formatNumber.new(gc209),
                    O:  String.formatNumber.new(gc210),
                    P:  String.formatNumber.new(gc211),
                    Q:  String.formatNumber.new(gc212),
                    R:  String.formatNumber.new(gc213),
                    S:  String.formatNumber.new(gc501),
                    T:  String.formatNumber.new(gc301),
                    U:  String.formatNumber.new(gc302),
                    V:  String.formatNumber.new(gc303),
                    W:  String.formatNumber.new(gc304),
                    X:  String.formatNumber.new(gc305),
                    Y:  String.formatNumber.new(gc306),
                    Z:  String.formatNumber.new(gc307),
                    AA: String.formatNumber.new(gc308),
                    AB: String.formatNumber.new(gc309),
                    AC: String.formatNumber.new(gc310),
                    AD: String.formatNumber.new(gc311),
                    AE: String.formatNumber.new(gc312),
                    AF: String.formatNumber.new(gc313),
                    AG: String.formatNumber.new(gc502),
                    AH: String.formatNumber.new(gc503),
                    AI: String.formatNumber.new(gc401),
                    AJ: String.formatNumber.new(gc402),
                    AK: String.formatNumber.new(gc403),
                    AL: String.formatNumber.new(gc404)
                }
            )

            const header = [
                'A',
                'B',
                'C',
                'D',
                'E',
                'F',
                'G',
                'H',
                'I',
                'J',
                'K',
                'L',
                'M',
                'N',
                'O',
                'P',
                'Q',
                'R',
                'S',
                'T',
                'U',
                'V',
                'W',
                'X',
                'Y',
                'Z',
                'AA',
                'AB',
                'AC',
                'AD',
                'AE',
                'AF',
                'AG',
                'AH',
                'AI',
                'AJ',
                'AK',
                'AL',
            ]

            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(tableBody, {header, skipHeader: true});
            ws["!cols"] = [
                {wpx:50},{wpx:150},{wpx:120},{wpx:70},{wpx:50},{wpx:50},{wpx:50},{wpx:50},{wpx:50},{wpx:50},
                {wpx:50},{wpx:50},{wpx:50},{wpx:50},{wpx:50},{wpx:50},{wpx:50},{wpx:50},{wpx:50},{wpx:50},
                {wpx:50},{wpx:50},{wpx:50},{wpx:50},{wpx:50},{wpx:50},{wpx:50},{wpx:50},{wpx:50},{wpx:50},
                {wpx:50},{wpx:50},{wpx:50},{wpx:50},{wpx:50},{wpx:50},{wpx:50},{wpx:50}
            ];

            const keys = Object.keys(ws);
            const rowsCount = tableBody.length;

            for (const iterator of keys) {
                if(ws[iterator].v?.match(/^[0-9,.]+$/g) && !iterator.match(/^(A|B|C|D)[0-9]+$/g)) {
                    ws[iterator].v = ws[iterator].v.replace(/,/g, '');
                    ws[iterator].t = 'n';
                    ws[iterator].z = '#,##0.00';
                }
                if(iterator !== '!ref' && iterator !== '!cols') {
                    ws[iterator].s = {
                        font: {
                            name: 'Calibri',
                            sz: 10
                        }
                    }
                    const row = +(iterator.match(/\d+/)[0]);
                    if(iterator.match(/^(E|S|AG|AH|AL)[0-9]+$/g) && + row >= 6) {
                        ws[iterator].s.border = {
                            right: { style: 'thin', color: 'black'}
                        }
                    }
                    if(row === rowsCount) {
                        ws[iterator].s.border = {
                            ...ws[iterator].s.border,
                            bottom: { style: 'thin', color: 'black'},
                            top: { style: 'thin', color: 'black'}
                        }
                    }
                }
            }

            ws['A1'].s = {font:{name: 'Arial', sz: 12, bold: true}}
            ws['A2'].s = {font:{name: 'Arial', sz: 10}}
            ws['A4'].s = {font:{name: 'Arial', sz: 10}}
            
            for (const iterator of header) {
                const key1 = iterator + "6";
                const key2 = iterator + "7";

                ws[key1].s = {
                    ...ws[key1].s, 
                    alignment: { wrapText: true, vertical: 'center', horizontal: 'center'},
                    border: { 
                        ...ws[key1].s.border, 
                        top: { style: 'thin', color: 'black'},
                        bottom: null,
                    } 
                }
                ws[key2].s = {
                    ...ws[key2].s, 
                    alignment: { wrapText: true, vertical: 'center', horizontal: 'center'},
                    border: { 
                        ...ws[key1].s.border, 
                        top: null,
                        bottom: { style: 'thin', color: 'black'},
                    } 
                }
            };

            if (gc201 <= 0) ws['!cols'][5].hidden = true;
            if (gc202 <= 0) ws['!cols'][6].hidden = true;
            if (gc203 <= 0) ws['!cols'][7].hidden = true;
            if (gc204 <= 0) ws['!cols'][8].hidden = true;
            if (gc205 <= 0) ws['!cols'][9].hidden = true;
            if (gc206 <= 0) ws['!cols'][10].hidden = true;
            if (gc207 <= 0) ws['!cols'][11].hidden = true;
            if (gc208 <= 0) ws['!cols'][12].hidden = true;
            if (gc209 <= 0) ws['!cols'][13].hidden = true;
            if (gc210 <= 0) ws['!cols'][14].hidden = true;
            if (gc211 <= 0) ws['!cols'][15].hidden = true;
            if (gc212 <= 0) ws['!cols'][16].hidden = true;
            if (gc213 <= 0) ws['!cols'][17].hidden = true;
            if (gc501 <= 0) ws['!cols'][18].hidden = true;
            if (gc301 <= 0) ws['!cols'][19].hidden = true;
            if (gc302 <= 0) ws['!cols'][20].hidden = true;
            if (gc303 <= 0) ws['!cols'][21].hidden = true;
            if (gc304 <= 0) ws['!cols'][22].hidden = true;
            if (gc305 <= 0) ws['!cols'][23].hidden = true;
            if (gc306 <= 0) ws['!cols'][24].hidden = true;
            if (gc307 <= 0) ws['!cols'][25].hidden = true;
            if (gc308 <= 0) ws['!cols'][26].hidden = true;
            if (gc309 <= 0) ws['!cols'][27].hidden = true;
            if (gc310 <= 0) ws['!cols'][28].hidden = true;
            if (gc311 <= 0) ws['!cols'][29].hidden = true;
            if (gc312 <= 0) ws['!cols'][30].hidden = true;
            if (gc313 <= 0) ws['!cols'][31].hidden = true;
            if (gc502 <= 0) ws['!cols'][32].hidden = true;
            if (gc503 <= 0) ws['!cols'][33].hidden = true;
            if (gc401 <= 0) ws['!cols'][34].hidden = true;
            if (gc402 <= 0) ws['!cols'][35].hidden = true;
            if (gc403 <= 0) ws['!cols'][36].hidden = true;
            if (gc404 <= 0) ws['!cols'][37].hidden = true;

            ws["!merges"] = [
                { s: { r: 5, c: 0 }, e: { r: 5, c: 4 } },
                { s: { r: 5, c: 5 }, e: { r: 5, c: 18 } },
                { s: { r: 5, c: 19 }, e: { r: 5, c: 32 } },
                { s: { r: 5, c: 34 }, e: { r: 5, c: 37 } }
            ];

            XLSX.utils.book_append_sheet(wb, ws, "Planilla Empleados");

            var wbout = XLSX.write(wb, {bookType:'xlsx', type: 'binary', cellStyles: true});

            const s2ab = function(s) {
                var buf = new ArrayBuffer(s.length);
                var view = new Uint8Array(buf);
                for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
                return buf;
            }

            saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), `planilla-empleados-${year}-${month}.xlsx`);
        } catch(err) {
            console.log(err);
        }
    }

    render() {

        const { title, year, month, base64Pdf, typesala } = this.state;
        const { loading, list, tables, failed, failedTitle, failedContent } = this.props.humanResourcesReducers;
        const { classes } = this.props;

        return (
            <div style={{overflow: 'auto', height: '100%'}}>
                <Paper className='form__container'>
                    <Typography variant='h5'>{ title }</Typography>
                    <br/>
                    { failed && <MessageBox title={failedTitle} content={failedContent}/> }
                    <div style={{ display: 'flex' }}>
                        <form noValidate autoComplete="off" style={{ flex: 1, paddingRight: '15px', paddingTop: '2em' }}>
                            <Grid container spacing={2} >
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_año">Año</InputLabel>
                                            <Select
                                                labelId="lbl_año"
                                                id="year"
                                                className='select'
                                                label="Año"
                                                fullWidth
                                                value={ year }
                                                onChange={(e: any) => this.onChangeRoot({'year': e.target.value})}
                                            >
                                                <MenuItem value='2030'>2030</MenuItem>
                                                <MenuItem value='2029'>2029</MenuItem>
                                                <MenuItem value='2028'>2028</MenuItem>
                                                <MenuItem value='2027'>2027</MenuItem>
                                                <MenuItem value='2026'>2026</MenuItem>
                                                <MenuItem value='2025'>2025</MenuItem>
                                                <MenuItem value='2026'>2026</MenuItem>
                                                <MenuItem value='2025'>2025</MenuItem>
                                                <MenuItem value='2024'>2024</MenuItem>
                                                <MenuItem value='2023'>2023</MenuItem>
                                                <MenuItem value='2022'>2022</MenuItem>
                                                <MenuItem value='2021'>2021</MenuItem>
                                                <MenuItem value='2020'>2020</MenuItem>
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_mes">Mes</InputLabel>
                                            <Select
                                                labelId="lbl_mes"
                                                id="month"
                                                className='select'
                                                label="Mes"
                                                fullWidth
                                                value={ month }
                                                onChange={(e: any) => this.onChangeRoot({'month': e.target.value})}
                                            >
                                                {
                                                    meses.map((x: any) => 
                                                        <MenuItem value={x.key} key={x.key}>{x.value}</MenuItem>
                                                    )
                                                }
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_año">Tipo</InputLabel>
                                            <Select
                                                labelId="lbl_año"
                                                id="typesala"
                                                className='select'
                                                label="Año"
                                                fullWidth
                                                value={ typesala }
                                                onChange={(e: any) => this.onChangeRoot({'typesala': e.target.value})}
                                            >
                                                <MenuItem value='001'>SUELDOS</MenuItem>
                                                <MenuItem value='002'>VACACIONES</MenuItem>
                                                <MenuItem value='003'>GRATIFICACIONES</MenuItem>
                                                <MenuItem value='004'>SUBSIDIO</MenuItem>
                                                <MenuItem value='005'>BONIFICACION VOLUNTARIO</MenuItem>
                                                <MenuItem value='006'>GRATIFICACION EXTRAORDINARIA</MenuItem>
                                                <MenuItem value='007'>PARTICIPACION UTILIDADES VOLUNT.</MenuItem>
                                                <MenuItem value='008'>UTLIDADES</MenuItem>
                                                <MenuItem value='009'>CTS</MenuItem>
                                                <MenuItem value='010'>BONIFICACION ESPECIAL</MenuItem>
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} style={{ paddingTop: '50px' }}>
                                    <Button variant="contained" fullWidth color="primary" onClick={() => { this.props.employePayrollRequest(year, month, typesala, this.onGeneratePdf) }}>
                                        Generar Reporte PDF
                                    </Button>
                                </Grid>
                                <Grid item xs={12} style={{ paddingTop: '10px' }}>
                                    <Button variant="contained" fullWidth color="inherit" onClick={() => { this.props.employePayrollRequest(year, month, typesala, this.onGenerateExcel) }}>
                                        Generar Reporte EXCEL
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                        <PdfView data={base64Pdf} style={{ flex: 2 }}/>
                    </div>
                    <br/>
                    {/* <FormFooter onCancel={ this.onCancel } onSubmit={ this.onSubmit }/> */}
                </Paper>
                <ScreenLoader enabled={loading}/>
            </div>
        );
    }
}

const humanResourcesReducers = 'humanResourcesReducers';
const dashboardReducers = 'dashboardReducers';

const mapStateToProps = (state: any) => ({
    humanResourcesReducers: state.get(humanResourcesReducers),
    dashboardReducers: state.get(dashboardReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        employePayrollRequest
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(ReportEmployePayroll));