import React, { MouseEvent } from "react";
import ReactDOM from "react-dom";
import { TweenMax } from "gsap";
import './base.scss';

interface ButtonProps {
    type: 'primary'
    size: 'medium' | 'small' | 'giant'
    className?: string
    children?: React.ReactNode,
    onClick?: (event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => void
}

class Button extends React.Component<ButtonProps, any> {

    constructor(props) {
        super(props);
        this.state = {
            width: 0, 
            height: 0
        };
    }

    handleClick = (event) => {
        this.props.onClick(event);
        const elem       = ReactDOM.findDOMNode(this.refs.ripple),
            x            = event.nativeEvent.offsetX,
            y            = event.nativeEvent.offsetY,
            w            = event.target.offsetWidth,
            h            = event.target.offsetHeight,
            offsetX      = Math.abs( (w / 2) - x ),
            offsetY      = Math.abs( (h / 2) - y ),
            deltaX       = (w / 2) + offsetX,
            deltaY       = (h / 2) + offsetY,
            scaleRatio   = Math.sqrt(Math.pow(deltaX, 2) + Math.pow(deltaY, 2))

        this.setState({...this.state,
            width: w,
            height: h,
        })

        TweenMax.fromTo(elem, 0.5, {
            x: x,
            y: y,
            transformOrigin: '50% 50%',
            scale: 0,
            opacity: 1
        }, {
            scale: scaleRatio,
            opacity: 0
        })
    }

    render() {
        const className = 'form ' + this.props.size + ' ' + this.props.type + ' ' + (this.props.className || '');
		return (
			<div className="penta button riple__container" onClick={this.handleClick}>
                <button className={className}>{this.props.children || ''}</button>
                <svg viewBox={`0 0 ${this.state.width} ${this.state.height}`} className="button ripple__obj">
                    <circle ref="ripple" cx="1" cy="1" r="1" />
                </svg>
            </div>
		);
	}
}

export default Button;