export const SUPPLIERS_INIT_FORM_REQUEST = 'SUPPLIERS_INIT_FORM_REQUEST';
export const SUPPLIERS_INIT_FORM_REQUEST_SUCCESS = 'SUPPLIERS_INIT_FORM_REQUEST_SUCCESS';
export const SUPPLIERS_INIT_FORM_REQUEST_FAILED = 'SUPPLIERS_INIT_FORM_REQUEST_FAILED';
export const SUPPLIERS_INIT_UPDATE_FORM_REQUEST = 'SUPPLIERS_INIT_UPDATE_FORM_REQUEST';
export const SUPPLIERS_INIT_UPDATE_FORM_REQUEST_SUCCESS = 'SUPPLIERS_INIT_UPDATE_FORM_REQUEST_SUCCESS';
export const SUPPLIERS_INIT_UPDATE_FORM_REQUEST_FAILED = 'SUPPLIERS_INIT_UPDATE_FORM_REQUEST_FAILED';
export const SUPPLIERS_GET_ALL_RECORDS_REQUEST = 'SUPPLIERS_GET_ALL_RECORDS_REQUEST'; 
export const SUPPLIERS_GET_ALL_RECORDS_REQUEST_SUCCESS = 'SUPPLIERS_GET_ALL_RECORDS_REQUEST_SUCCESS'; 
export const SUPPLIERS_GET_ALL_RECORDS_REQUEST_FAILED = 'SUPPLIERS_GET_ALL_RECORDS_REQUEST_FAILED';
export const SUPPLIERS_INSERT_REQUEST = 'SUPPLIERS_INSERT_REQUEST';
export const SUPPLIERS_INSERT_REQUEST_SUCCESS = 'SUPPLIERS_INSERT_REQUEST_SUCCESS';
export const SUPPLIERS_INSERT_REQUEST_FAILED = 'SUPPLIERS_INSERT_REQUEST_FAILED';
export const SUPPLIERS_UPDATE_REQUEST = 'SUPPLIERS_UPDATE_REQUEST';
export const SUPPLIERS_UPDATE_REQUEST_SUCCESS = 'SUPPLIERS_UPDATE_REQUEST_SUCCESS';
export const SUPPLIERS_UPDATE_REQUEST_FAILED = 'SUPPLIERS_UPDATE_REQUEST_FAILED';
export const SUPPLIERS_DELETE_REQUEST = 'SUPPLIERS_DELETE_REQUEST';
export const SUPPLIERS_DELETE_REQUEST_SUCCESS = 'SUPPLIERS_DELETE_REQUEST_SUCCESS';
export const SUPPLIERS_DELETE_REQUEST_FAILED = 'SUPPLIERS_DELETE_REQUEST_FAILED';
/*Components */
export const SUPPLIERS_LIST_SELECT_ITEM = 'SUPPLIERS_LIST_SELECT_ITEM';
export const SUPPLIERS_LIST_SELECT_ALL_ITEM = 'SUPPLIERS_LIST_SELECT_ALL_ITEM';
export const SUPPLIERS_ACTION_FAILED = 'SUPPLIERS_ACTION_FAILED';

export const PURCHASES_INIT_FORM_REQUEST = 'PURCHASES_INIT_FORM_REQUEST';
export const PURCHASES_INIT_FORM_REQUEST_SUCCESS = 'PURCHASES_INIT_FORM_REQUEST_SUCCESS';
export const PURCHASES_INIT_FORM_REQUEST_FAILED = 'PURCHASES_INIT_FORM_REQUEST_FAILED';
export const PURCHASES_INIT_UPDATE_FORM_REQUEST = 'PURCHASES_INIT_UPDATE_FORM_REQUEST';
export const PURCHASES_INIT_UPDATE_FORM_REQUEST_SUCCESS = 'PURCHASES_INIT_UPDATE_FORM_REQUEST_SUCCESS';
export const PURCHASES_INIT_UPDATE_FORM_REQUEST_FAILED = 'PURCHASES_INIT_UPDATE_FORM_REQUEST_FAILED';
export const PURCHASES_GET_ALL_RECORDS_REQUEST = 'PURCHASES_GET_ALL_RECORDS_REQUEST'; 
export const PURCHASES_GET_ALL_RECORDS_REQUEST_SUCCESS = 'PURCHASES_GET_ALL_RECORDS_REQUEST_SUCCESS'; 
export const PURCHASES_GET_ALL_RECORDS_REQUEST_FAILED = 'PURCHASES_GET_ALL_RECORDS_REQUEST_FAILED';
export const PURCHASES_INSERT_REQUEST = 'PURCHASES_INSERT_REQUEST';
export const PURCHASES_INSERT_REQUEST_SUCCESS = 'PURCHASES_INSERT_REQUEST_SUCCESS';
export const PURCHASES_INSERT_REQUEST_FAILED = 'PURCHASES_INSERT_REQUEST_FAILED';
export const PURCHASES_UPDATE_REQUEST = 'PURCHASES_UPDATE_REQUEST';
export const PURCHASES_UPDATE_REQUEST_SUCCESS = 'PURCHASES_UPDATE_REQUEST_SUCCESS';
export const PURCHASES_UPDATE_REQUEST_FAILED = 'PURCHASES_UPDATE_REQUEST_FAILED';
export const PURCHASES_DELETE_REQUEST = 'PURCHASES_DELETE_REQUEST';
export const PURCHASES_DELETE_REQUEST_SUCCESS = 'PURCHASES_DELETE_REQUEST_SUCCESS';
export const PURCHASES_DELETE_REQUEST_FAILED = 'PURCHASES_DELETE_REQUEST_FAILED';
export const PURCHASES_ANULATE_REQUEST = 'PURCHASES_ANULATE_REQUEST';
export const PURCHASES_ANULATE_REQUEST_SUCCESS = 'PURCHASES_ANULATE_REQUEST_SUCCESS';
export const PURCHASES_ANULATE_REQUEST_FAILED = 'PURCHASES_ANULATE_REQUEST_FAILED';
export const PURCHASES_MASSIVE_UPDATE_REQUEST = 'PURCHASES_MASSIVE_UPDATE_REQUEST';
export const PURCHASES_MASSIVE_UPDATE_REQUEST_SUCCESS = 'PURCHASES_MASSIVE_UPDATE_REQUEST_SUCCESS';
export const PURCHASES_MASSIVE_UPDATE_REQUEST_FAILED = 'PURCHASES_MASSIVE_UPDATE_REQUEST_FAILED';
/*Components */
export const PURCHASES_LIST_SELECT_ITEM = 'PURCHASES_LIST_SELECT_ITEM';
export const PURCHASES_LIST_SELECT_ALL_ITEM = 'PURCHASES_LIST_SELECT_ALL_ITEM';
export const PURCHASES_ACTION_FAILED = 'PURCHASES_ACTION_FAILED';

//#region CREDIT NOTE

export const CREDIT_NOTE_INIT_FORM_REQUEST = 'CREDIT_NOTE_INIT_FORM_REQUEST';
export const CREDIT_NOTE_INIT_FORM_REQUEST_SUCCESS = 'CREDIT_NOTE_INIT_FORM_REQUEST_SUCCESS';
export const CREDIT_NOTE_INIT_FORM_REQUEST_FAILED = 'CREDIT_NOTE_INIT_FORM_REQUEST_FAILED';
export const CREDIT_NOTE_INIT_UPDATE_FORM_REQUEST = 'CREDIT_NOTE_INIT_UPDATE_FORM_REQUEST';
export const CREDIT_NOTE_INIT_UPDATE_FORM_REQUEST_SUCCESS = 'CREDIT_NOTE_INIT_UPDATE_FORM_REQUEST_SUCCESS';
export const CREDIT_NOTE_INIT_UPDATE_FORM_REQUEST_FAILED = 'CREDIT_NOTE_INIT_UPDATE_FORM_REQUEST_FAILED';
export const CREDIT_NOTE_GET_ALL_RECORDS_REQUEST = 'CREDIT_NOTE_GET_ALL_RECORDS_REQUEST'; 
export const CREDIT_NOTE_GET_ALL_RECORDS_REQUEST_SUCCESS = 'CREDIT_NOTE_GET_ALL_RECORDS_REQUEST_SUCCESS'; 
export const CREDIT_NOTE_GET_ALL_RECORDS_REQUEST_FAILED = 'CREDIT_NOTE_GET_ALL_RECORDS_REQUEST_FAILED';
export const CREDIT_NOTE_INSERT_REQUEST = 'CREDIT_NOTE_INSERT_REQUEST';
export const CREDIT_NOTE_INSERT_REQUEST_SUCCESS = 'CREDIT_NOTE_INSERT_REQUEST_SUCCESS';
export const CREDIT_NOTE_INSERT_REQUEST_FAILED = 'CREDIT_NOTE_INSERT_REQUEST_FAILED';
export const CREDIT_NOTE_UPDATE_REQUEST = 'CREDIT_NOTE_UPDATE_REQUEST';
export const CREDIT_NOTE_UPDATE_REQUEST_SUCCESS = 'CREDIT_NOTE_UPDATE_REQUEST_SUCCESS';
export const CREDIT_NOTE_UPDATE_REQUEST_FAILED = 'CREDIT_NOTE_UPDATE_REQUEST_FAILED';
export const CREDIT_NOTE_DELETE_REQUEST = 'CREDIT_NOTE_DELETE_REQUEST';
export const CREDIT_NOTE_DELETE_REQUEST_SUCCESS = 'CREDIT_NOTE_DELETE_REQUEST_SUCCESS';
export const CREDIT_NOTE_DELETE_REQUEST_FAILED = 'CREDIT_NOTE_DELETE_REQUEST_FAILED';
export const CREDIT_NOTE_ANULATE_REQUEST = 'CREDIT_NOTE_ANULATE_REQUEST';
export const CREDIT_NOTE_ANULATE_REQUEST_SUCCESS = 'CREDIT_NOTE_ANULATE_REQUEST_SUCCESS';
export const CREDIT_NOTE_ANULATE_REQUEST_FAILED = 'CREDIT_NOTE_ANULATE_REQUEST_FAILED';
/*Components */
export const CREDIT_NOTE_LIST_SELECT_ITEM = 'CREDIT_NOTE_LIST_SELECT_ITEM';
export const CREDIT_NOTE_LIST_SELECT_ALL_ITEM = 'CREDIT_NOTE_LIST_SELECT_ALL_ITEM';
export const CREDIT_NOTE_ACTION_FAILED = 'CREDIT_NOTE_ACTION_FAILED';

//#endregion

//#endregion

export const DEBIT_NOTE_INIT_FORM_REQUEST = 'DEBIT_NOTE_INIT_FORM_REQUEST';
export const DEBIT_NOTE_INIT_FORM_REQUEST_SUCCESS = 'DEBIT_NOTE_INIT_FORM_REQUEST_SUCCESS';
export const DEBIT_NOTE_INIT_FORM_REQUEST_FAILED = 'DEBIT_NOTE_INIT_FORM_REQUEST_FAILED';
export const DEBIT_NOTE_INIT_UPDATE_FORM_REQUEST = 'DEBIT_NOTE_INIT_UPDATE_FORM_REQUEST';
export const DEBIT_NOTE_INIT_UPDATE_FORM_REQUEST_SUCCESS = 'DEBIT_NOTE_INIT_UPDATE_FORM_REQUEST_SUCCESS';
export const DEBIT_NOTE_INIT_UPDATE_FORM_REQUEST_FAILED = 'DEBIT_NOTE_INIT_UPDATE_FORM_REQUEST_FAILED';
export const DEBIT_NOTE_GET_ALL_RECORDS_REQUEST = 'DEBIT_NOTE_GET_ALL_RECORDS_REQUEST'; 
export const DEBIT_NOTE_GET_ALL_RECORDS_REQUEST_SUCCESS = 'DEBIT_NOTE_GET_ALL_RECORDS_REQUEST_SUCCESS'; 
export const DEBIT_NOTE_GET_ALL_RECORDS_REQUEST_FAILED = 'DEBIT_NOTE_GET_ALL_RECORDS_REQUEST_FAILED';
export const DEBIT_NOTE_INSERT_REQUEST = 'DEBIT_NOTE_INSERT_REQUEST';
export const DEBIT_NOTE_INSERT_REQUEST_SUCCESS = 'DEBIT_NOTE_INSERT_REQUEST_SUCCESS';
export const DEBIT_NOTE_INSERT_REQUEST_FAILED = 'DEBIT_NOTE_INSERT_REQUEST_FAILED';
export const DEBIT_NOTE_UPDATE_REQUEST = 'DEBIT_NOTE_UPDATE_REQUEST';
export const DEBIT_NOTE_UPDATE_REQUEST_SUCCESS = 'DEBIT_NOTE_UPDATE_REQUEST_SUCCESS';
export const DEBIT_NOTE_UPDATE_REQUEST_FAILED = 'DEBIT_NOTE_UPDATE_REQUEST_FAILED';
export const DEBIT_NOTE_DELETE_REQUEST = 'DEBIT_NOTE_DELETE_REQUEST';
export const DEBIT_NOTE_DELETE_REQUEST_SUCCESS = 'DEBIT_NOTE_DELETE_REQUEST_SUCCESS';
export const DEBIT_NOTE_DELETE_REQUEST_FAILED = 'DEBIT_NOTE_DELETE_REQUEST_FAILED';
export const DEBIT_NOTE_ANULATE_REQUEST = 'DEBIT_NOTE_ANULATE_REQUEST';
export const DEBIT_NOTE_ANULATE_REQUEST_SUCCESS = 'DEBIT_NOTE_ANULATE_REQUEST_SUCCESS';
export const DEBIT_NOTE_ANULATE_REQUEST_FAILED = 'DEBIT_NOTE_ANULATE_REQUEST_FAILED';
/*Components */
export const DEBIT_NOTE_LIST_SELECT_ITEM = 'DEBIT_NOTE_LIST_SELECT_ITEM';
export const DEBIT_NOTE_LIST_SELECT_ALL_ITEM = 'DEBIT_NOTE_LIST_SELECT_ALL_ITEM';
export const DEBIT_NOTE_ACTION_FAILED = 'DEBIT_NOTE_ACTION_FAILED';

//#region 

//#region REPORTS

export const INVOICES_PAY_REQUEST = 'INVOICES_PAY_REQUEST';
export const INVOICES_PAY_REQUEST_SUCCESS = 'INVOICES_PAY_REQUEST_SUCCESS';
export const INVOICES_PAY_REQUEST_FAILED = 'INVOICES_PAY_REQUEST_FAILED';

//#endregion