import * as types from 'penta-redux/constants/accountantConstants';
import { baseModels } from 'penta-models';


/** MASTER ACCOUNT */
export const masterAccountGetAllRecordsRequest = (body: baseModels.pentaRequestQuery, callback: void) => ({
    type: types.MASTER_ACCOUNT_GET_ALL_RECORDS_REQUEST,
    body,
    callback
});

export const masterAccountGetAllRecordsRequestSuccess = (list: Array<any>) => ({
    type: types.MASTER_ACCOUNT_GET_ALL_RECORDS_REQUEST_SUCCESS,
    list
});

export const masterAccountGetAllRecordsRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.MASTER_ACCOUNT_GET_ALL_RECORDS_REQUEST_FAILED,
    titleError,
    titleContent
});

export const masterAccountUpdateRequest = (body: any) => ({
    type: types.MASTER_ACCOUNT_UPDATE_REQUEST,
    body
});

export const masterAccountUpdateRequestSuccess = () => ({
    type: types.MASTER_ACCOUNT_UPDATE_REQUEST_SUCCESS
});

export const masterAccountUpdateRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.MASTER_ACCOUNT_UPDATE_REQUEST_FAILED,
    titleError,
    titleContent
});


/** PLAN ACCOUNT */
export const planAccountInitFormRequest = (fiscyear: string, callback: void) => ({
    type: types.PLAN_ACCOUNT_INIT_FORM_REQUEST,
    fiscyear,
    callback
});

export const planAccountInitFormRequestSuccess = (tables: any) => ({
    type: types.PLAN_ACCOUNT_INIT_FORM_REQUEST_SUCCESS,
    tables
});

export const planAccountInitFormRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.PLAN_ACCOUNT_INIT_FORM_REQUEST_FAILED,
    titleError,
    titleContent
});

export const planAccountInitUpdateFormRequest = (accaccou: string, fiscyear: string, callback: void) => ({
    type: types.PLAN_ACCOUNT_INIT_UPDATE_FORM_REQUEST,
    accaccou,
    fiscyear,
    callback
});

export const planAccountInitUpdateFormRequestSuccess = (tables: any, onlyOne: any) => ({
    type: types.PLAN_ACCOUNT_INIT_UPDATE_FORM_REQUEST_SUCCESS,
    tables,
    onlyOne
});

export const planAccountInitUpdateFormRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.PLAN_ACCOUNT_INIT_UPDATE_FORM_REQUEST_FAILED,
    titleError,
    titleContent
});

export const planAccountGetAllRecordsRequest = (body: baseModels.pentaRequestQuery) => ({
    type: types.PLAN_ACCOUNT_GET_ALL_RECORDS_REQUEST,
    body
});

export const planAccountGetAllRecordsRequestSuccess = (list: Array<any>, total: number) => ({
    type: types.PLAN_ACCOUNT_GET_ALL_RECORDS_REQUEST_SUCCESS,
    list,
    total
});

export const planAccountGetAllRecordsRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.PLAN_ACCOUNT_GET_ALL_RECORDS_REQUEST_FAILED,
    titleError,
    titleContent
});

export const planAccountInsertRequest = (body: any, callback?: void) => ({
    type: types.PLAN_ACCOUNT_INSERT_REQUEST,
    body,
    callback
});

export const planAccountInsertRequestSuccess = () => ({
    type: types.PLAN_ACCOUNT_INSERT_REQUEST_SUCCESS
});

export const planAccountInsertRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.PLAN_ACCOUNT_INSERT_REQUEST_FAILED,
    titleError,
    titleContent
});

export const planAccountUpdateRequest = (body: any) => ({
    type: types.PLAN_ACCOUNT_UPDATE_REQUEST,
    body
});

export const planAccountUpdateRequestSuccess = () => ({
    type: types.PLAN_ACCOUNT_UPDATE_REQUEST_SUCCESS
});

export const planAccountUpdateRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.PLAN_ACCOUNT_UPDATE_REQUEST_FAILED,
    titleError,
    titleContent
});

export const planAccountDeleteRequest = (accaccous: Array<string>, fiscyears: Array<string>) => ({
    type: types.PLAN_ACCOUNT_DELETE_REQUEST,
    accaccous,
    fiscyears
});

export const planAccountDeleteRequestSuccess = () => ({
    type: types.PLAN_ACCOUNT_DELETE_REQUEST_SUCCESS
});

export const planAccountDeleteRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.PLAN_ACCOUNT_DELETE_REQUEST_FAILED,
    titleError,
    titleContent
});

/*Components */
export const planAccountListSelectItem = (key: number) => ({
    type: types.PLAN_ACCOUNT_LIST_SELECT_ITEM,
    key
});

export const planAccountListSelectAllItem = (checked: boolean) => ({
    type: types.PLAN_ACCOUNT_LIST_SELECT_ALL_ITEM,
    checked
});

export const planAccountActionFailed = (titleError: string, titleContent: string) => ({
    type: types.PLAN_ACCOUNT_ACTION_FAILED,
    titleError,
    titleContent
});

//#region REPORTS

export const generalBalancesAccountRequest = (year: string, month: string, callback: void) => ({
    type: types.GENERAL_BALANCES_ACCOUNT_REQUEST,
    year,
    month,
    callback
});

export const generalBalancesAccountRequestSuccess = (onlyOne: any) => ({
    type: types.GENERAL_BALANCES_ACCOUNT_REQUEST_SUCCESS,
    onlyOne
});

export const generalBalancesAccountRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.GENERAL_BALANCES_ACCOUNT_REQUEST_FAILED,
    titleError,
    titleContent
});

export const diaryBookRequest = (year: string, month: string, callback: void) => ({
    type: types.DIARY_BOOK_REQUEST,
    year,
    month,
    callback
});

export const diaryBookRequestSuccess = (onlyOne: any) => ({
    type: types.DIARY_BOOK_REQUEST_SUCCESS,
    onlyOne
});

export const diaryBookRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.DIARY_BOOK_REQUEST_FAILED,
    titleError,
    titleContent
});

export const purchasesRegisterRequest = (officode: string, year: string, month: string, all: boolean, callback: void) => ({
    type: types.PURCHASES_REGISTER_REQUEST,
    officode,
    year,
    month,
    all,
    callback
});

export const purchasesRegisterRequestSuccess = (onlyOne: any) => ({
    type: types.PURCHASES_REGISTER_REQUEST_SUCCESS,
    onlyOne
});

export const purchasesRegisterRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.PURCHASES_REGISTER_REQUEST_FAILED,
    titleError,
    titleContent
});

export const worksheetRequest = (fiscyear: string, balacode: Array<string>, level: number, callback: void) => ({
    type: types.WORKSHEET_REQUEST,
    fiscyear,
    balacode,
    level,
    callback
});

export const worksheetRequestSuccess = (onlyOne: any) => ({
    type: types.WORKSHEET_REQUEST_SUCCESS,
    onlyOne
});

export const worksheetRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.WORKSHEET_REQUEST_FAILED,
    titleError,
    titleContent
});

export const majorGeneralRequest = (fiscyear: string, balacode: Array<string>, callback: void) => ({
    type: types.MAJOR_GENERAL_REQUEST,
    fiscyear,
    balacode,
    callback
});

export const majorGeneralRequestSuccess = (onlyOne: any) => ({
    type: types.MAJOR_GENERAL_REQUEST_SUCCESS,
    onlyOne
});

export const majorGeneralRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.MAJOR_GENERAL_REQUEST_FAILED,
    titleError,
    titleContent
});

export const cashBookBanksRequest = (year: string, month: string, typecsbk: string, callback: void) => ({
    type: types.CASH_BOOK_BANKS_REQUEST,
    year,
    month,
    typecsbk,
    callback
});

export const cashBookBanksRequestSuccess = (onlyOne: any) => ({
    type: types.CASH_BOOK_BANKS_REQUEST_SUCCESS,
    onlyOne
});

export const cashBookBanksRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.CASH_BOOK_BANKS_REQUEST_FAILED,
    titleError,
    titleContent
});

export const expenseAnalysisNineRequest = (year: string, balacode: Array<string>, callback: void) => ({
    type: types.EXPENSE_ANALYSIS_NINE_REQUEST,
    year,
    balacode,
    callback
});

export const expenseAnalysisNineRequestSuccess = (onlyOne: any) => ({
    type: types.EXPENSE_ANALYSIS_NINE_REQUEST_SUCCESS,
    onlyOne
});

export const expenseAnalysisNineRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.EXPENSE_ANALYSIS_NINE_REQUEST_FAILED,
    titleError,
    titleContent
});

export const expenseAnalysisSixRequest = (year: string, balacode: Array<string>, callback: void) => ({
    type: types.EXPENSE_ANALYSIS_SIX_REQUEST,
    year,
    balacode,
    callback
});

export const expenseAnalysisSixRequestSuccess = (onlyOne: any) => ({
    type: types.EXPENSE_ANALYSIS_SIX_REQUEST_SUCCESS,
    onlyOne
});

export const expenseAnalysisSixRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.EXPENSE_ANALYSIS_SIX_REQUEST_FAILED,
    titleError,
    titleContent
});

export const inventoryBookBalanceRequest = (year: string, month: string, callback: void) => ({
    type: types.INVENTORY_BOOK_BALANCE_REQUEST,
    year,
    month,
    callback
});

export const inventoryBookBalanceRequestSuccess = (onlyOne: any) => ({
    type: types.INVENTORY_BOOK_BALANCE_REQUEST_SUCCESS,
    onlyOne
});

export const inventoryBookBalanceRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.INVENTORY_BOOK_BALANCE_REQUEST_FAILED,
    titleError,
    titleContent
});

export const generalBalancesRequest = (fiscyear: string, balacode: Array<string>, callback: void) => ({
    type: types.GENERAL_BALANCES_REQUEST,
    fiscyear,
    balacode,
    callback
});

export const generalBalancesRequestSuccess = (onlyOne: any) => ({
    type: types.GENERAL_BALANCES_REQUEST_SUCCESS,
    onlyOne
});

export const generalBalancesRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.GENERAL_BALANCES_REQUEST_FAILED,
    titleError,
    titleContent
});

export const profitLossStatementRequest = (fiscyear: string, balacode: Array<string>, callback: void) => ({
    type: types.PROFIT_LOSS_STATEMENT_REQUEST,
    fiscyear,
    balacode,
    callback
});

export const profitLossStatementRequestSuccess = (onlyOne: any) => ({
    type: types.PROFIT_LOSS_STATEMENT_REQUEST_SUCCESS,
    onlyOne
});

export const profitLossStatementRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.PROFIT_LOSS_STATEMENT_REQUEST_FAILED,
    titleError,
    titleContent
});

export const salesRegisterRequest = (officode: string, year: string, month: string, all: boolean, callback: void) => ({
    type: types.SALES_REGISTER_REQUEST,
    officode,
    year,
    month,
    all,
    callback
});

export const salesRegisterRequestSuccess = (onlyOne: any) => ({
    type: types.SALES_REGISTER_REQUEST_SUCCESS,
    onlyOne
});

export const salesRegisterRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.SALES_REGISTER_REQUEST_FAILED,
    titleError,
    titleContent
});

export const daotRequest = (officode: string, year: string, initmonth: string, endmonth: string, callback: void) => ({
    type: types.DAOT_REQUEST,
    officode,
    year,
    initmonth,
    endmonth,
    callback
});

export const daotRequestSuccess = (onlyOne: any) => ({
    type: types.DAOT_REQUEST_SUCCESS,
    onlyOne
});

export const daotRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.DAOT_REQUEST_FAILED,
    titleError,
    titleContent
});

export const purchaseDetailSupplierRequest = (officode: string, year: string, initmonth: string, endmonth: string, ruc: string, product: string, callback: void) => ({
    type: types.PURCHASE_DETAIL_SUPPLIER_REQUEST,
    officode,
    year,
    initmonth,
    endmonth,
    ruc,
    product,
    callback
});

export const purchaseDetailSupplierRequestSuccess = (onlyOne: any) => ({
    type: types.PURCHASE_DETAIL_SUPPLIER_REQUEST_SUCCESS,
    onlyOne
});

export const purchaseDetailSupplierRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.PURCHASE_DETAIL_SUPPLIER_REQUEST_FAILED,
    titleError,
    titleContent
});

//#endregion

//#region VOUCHERS

export const vouchersInitFormRequest = (fiscyear: number, callback: void) => ({
    type: types.VOUCHERS_INIT_FORM_REQUEST,
    fiscyear,
    callback
});

export const vouchersInitFormRequestSuccess = (tables: any) => ({
    type: types.VOUCHERS_INIT_FORM_REQUEST_SUCCESS,
    tables
});

export const vouchersInitFormRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.VOUCHERS_INIT_FORM_REQUEST_FAILED,
    titleError,
    titleContent
});

export const vouchersInitUpdateFormRequest = (correlat: number, callback: void) => ({
    type: types.VOUCHERS_INIT_UPDATE_FORM_REQUEST,
    correlat,
    callback
});

export const vouchersInitUpdateFormRequestSuccess = (tables: any, onlyOne: any) => ({
    type: types.VOUCHERS_INIT_UPDATE_FORM_REQUEST_SUCCESS,
    tables,
    onlyOne
});

export const vouchersInitUpdateFormRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.VOUCHERS_INIT_UPDATE_FORM_REQUEST_FAILED,
    titleError,
    titleContent
});

export const vouchersGetAllRecordsRequest = (body: baseModels.pentaRequestQuery) => ({
    type: types.VOUCHERS_GET_ALL_RECORDS_REQUEST,
    body
});

export const vouchersGetAllRecordsRequestSuccess = (list: Array<any>, total: number) => ({
    type: types.VOUCHERS_GET_ALL_RECORDS_REQUEST_SUCCESS,
    list,
    total
});

export const vouchersGetAllRecordsRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.VOUCHERS_GET_ALL_RECORDS_REQUEST_FAILED,
    titleError,
    titleContent
});

export const vouchersInsertRequest = (body: any, callback?: void) => ({
    type: types.VOUCHERS_INSERT_REQUEST,
    body,
    callback
});

export const vouchersInsertRequestSuccess = () => ({
    type: types.VOUCHERS_INSERT_REQUEST_SUCCESS
});

export const vouchersInsertRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.VOUCHERS_INSERT_REQUEST_FAILED,
    titleError,
    titleContent
});

export const vouchersUpdateRequest = (body: any) => ({
    type: types.VOUCHERS_UPDATE_REQUEST,
    body
});

export const vouchersUpdateRequestSuccess = () => ({
    type: types.VOUCHERS_UPDATE_REQUEST_SUCCESS
});

export const vouchersUpdateRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.VOUCHERS_UPDATE_REQUEST_FAILED,
    titleError,
    titleContent
});

export const vouchersDeleteRequest = (correlats: Array<string>) => ({
    type: types.VOUCHERS_DELETE_REQUEST,
    correlats
});

export const vouchersDeleteRequestSuccess = () => ({
    type: types.VOUCHERS_DELETE_REQUEST_SUCCESS
});

export const vouchersDeleteRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.VOUCHERS_DELETE_REQUEST_FAILED,
    titleError,
    titleContent
});

/*Components */
export const vouchersListSelectItem = (key: number) => ({
    type: types.VOUCHERS_LIST_SELECT_ITEM,
    key
});

export const vouchersListSelectAllItem = (checked: boolean) => ({
    type: types.VOUCHERS_LIST_SELECT_ALL_ITEM,
    checked
});
export const vouchersActionFailed = (titleError: string, titleContent: string) => ({
    type: types.VOUCHERS_ACTION_FAILED,
    titleError,
    titleContent
});

//#endregion

export const majorizationRequest = (year: string, month: string, callback: void) => ({
    type: types.MAJORIZATION_REQUEST,
    year,
    month,
    callback
});

export const majorizationRequestSuccess = (onlyOne: any) => ({
    type: types.MAJORIZATION_REQUEST_SUCCESS,
    onlyOne
});

export const majorizationRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.MAJORIZATION_REQUEST_FAILED,
    titleError,
    titleContent
});
