import React, { useEffect } from 'react';
import ListView from 'penta-components/base/listView';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { salesGetAllRecordsRequest, salesListSelectItem, salesListSelectAllItem, salesActionFailed, salesDeleteRequest, salesMassiveUpdateRequest } from 'penta-redux/actions/salesActions';
import './sales.scss'
import Button from 'penta-components/controls/button';
import * as XLSX from 'xlsx';
import ListMenu from 'penta-components/base/listMenu';
import Separator from 'penta-components/base/separator';
import Search from 'penta-components/controls/search';
import Select from 'penta-components/controls/select';
import { createUseStyles } from 'react-jss';
import Modal from 'penta-components/base/modal';
import pipelines from 'penta-services';
import { salesInsertBatch } from 'services/pipelines/salesPipeline';
import ScreenLoader from 'penta-components/base/screenLoader';

const moment = require('moment');
const months = [
    { id: '01', name: 'Enero' },
    { id: '02', name: 'Febrero' },
    { id: '03', name: 'Marzo' },
    { id: '04', name: 'Abril' },
    { id: '05', name: 'Mayo' },
    { id: '06', name: 'Junio' },
    { id: '07', name: 'Julio' },
    { id: '08', name: 'Agosto' },
    { id: '09', name: 'Septiembre' },
    { id: '10', name: 'Octubre' },
    { id: '11', name: 'Noviembre' },
    { id: '12', name: 'Diciembre' }
];
const header = [
    { title: 'Registro', key: 'intecode' },
    { title: 'Nro de serie', key: 'serialnu' },
    { title: 'Nro de documento', key: 'documnum' },
    { title: 'Cliente', key: 'custname', style: () => { return {width: '50%'} } },
    { title: 'Moneda', key: 'currency' },
    { title: 'Valor de venta', key: '_topgrava', 
        value: (call, item) => { 
            const val = +call(item, 'topgrava').replace(/,/g, ''); 
            return String.formatNumber.new(val);
        },
        style: () => { return {textAlign: 'right'} } 
    },
    { title: 'IGV', key: '_totaltax', 
        value: (call, item) => { 
            const val = +call(item, 'totaltax').replace(/,/g, ''); 
            return String.formatNumber.new(val);
        },
        style: () => { return {textAlign: 'right'} } 
    },
    { title: 'Monto total', key: '_totalamo', 
        value: (call, item) => { 
            const val = +call(item, 'totalamo').replace(/,/g, ''); 
            return String.formatNumber.new(val);
        },
        style: () => { return {textAlign: 'right'} } 
    },
    { title: 'Estado', key: 'estado',
        value: (call, item) => { 
            try {
                const val = +call(item, 'balanpay').replace(/,/g, ''); 
                if(val > 0) 
                    return <div style={{width: '1.25rem', height: '1.25rem', backgroundColor: '#E26464', borderRadius: '0.625rem', margin: 'auto'}}/>; 
                else 
                    return <div style={{width: '1.25rem', height: '1.25rem', backgroundColor: '#64E29F', borderRadius: '0.625rem', margin: 'auto'}}/>;
            } 
            catch { } 
        },
        style: (call, item) => { return {paddingBottom: '0', paddingTop: '0'}; }
    }
]; 

const useStyles =  createUseStyles({
    container: {
        overflow: 'auto', 
        height: '100%', 
        display: 'flex', 
        flexDirection: 'column'
    },
    titleContainer: {
        flex: 1, 
        display: 'flex', 
        alignItems: 'baseline'
    },
    title: {
        fontFamily: '"Open Sans", sans-serif', 
        fontSize: '1.563rem', 
        fontWeight: 'bold', 
        color: '#0060A6', 
        marginRight: '3rem'
    },
    tabActive: {},
    tabs: {
        margin: '0 0.4rem', 
        padding: '0.4rem 0.5rem', 
        color: '#b9b9b9', 
        fontFamily: '"Open Sans", sans-serif', 
        fontWeight: 'bold', 
        fontSize: '0.875rem',
        cursor: 'pointer',
        '&$tabActive': {
            color: '#3B3B3B',
            borderBottom: '0.2rem solid #3B3B3B', 
        }
    },
    listContainer: {
        flex: 1,
        '& > div:first-child': {
            display: 'flex'
        }
    }
});

const ListSales = (props: any) => {
    const classes = useStyles();
    const amountJump = 20;
    const [showModal, setShowModal] = React.useState(false);
    const [typeModal, setTypeModal] = React.useState('');
    const [modalMessage, setModaMessage] = React.useState<any>({});
    const [loading, setLoading] = React.useState(false);
    //===============================================================
    const [searchValue, setSearchValue] = React.useState('');
    const [acpeyear, setAcpeyear] = React.useState(moment().format('YYYY'));
    const [acpemont, setAcpemont] = React.useState(moment().format('MM'));
    const [state, setState] = React.useState('00');
    const [take, setTake] = React.useState(amountJump);
    //===============================================================
    const [typing, setTyping] = React.useState(null);
    //===============================================================
    const [tables, setTables] = React.useState<any>({});
    //===============================================================
    const [uploadCodes, setUploadCodes] = React.useState<any>([]);
    const [uploadDelete, setUploadDelete] = React.useState<string>(null);

    const eliminar = () => {
        // const select = props.salesReducers.list.filter((x: any) => x.select);
        // const selectSerial = select.map((x: any) => { return x.serialnu });
        // const selectNumero = select.map((x: any) => { return x.documnum });
        // const selectCliente = select.map((x: any) => { return x.docnumbe });
        if(uploadDelete) {
            const filters = {
                fields: {
                    serialnu: searchValue,
                    documnum: searchValue,
                    docnumbe: searchValue,
                    custname: searchValue
                },
                skip: 0,
                take: amountJump
            } as any
    
            if(acpeyear !== '') filters.year = acpeyear;
            if(acpemont !== '') filters.month = acpemont;

            // props.salesDeleteRequest(selectSerial, selectNumero, selectCliente, filters);
            
            setLoading(true);
            pipelines.salesPipeline.salesDeleteByUploadCode(uploadDelete).then((res) => {
                setUploadDelete(null);
                setLoading(false);
                props.salesGetAllRecordsRequest(filters);
                handleCloseModal();
            });
        }
        else
            props.salesActionFailed('Error: 0x0', 'No se ha selecionado un elemento para eliminar');
    }

    const ExcelDateToJSDate = (date) => {
        return moment(new Date(Math.round((date - 25568)*86400*1000))).format('YYYY-MM-DD');
    }

    const importExcel = (e: any, fx: any) => {
        const file = e.target.files[0];
        if(!confirm("Upload File: " + file.name)) return;
        const reader = new FileReader();
        reader.onload = (evt) => {
            setLoading(true);
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, {type:'binary'});
            const slsname = wb.SheetNames[0];
            const sls = wb.Sheets[slsname];
            const headerExcelRaw: Array<any> = XLSX.utils.sheet_to_json(sls, {header:"A", dateNF: 'dd/mm/YYYY'} as XLSX.Sheet2JSONOpts);

            const officode = localStorage.getItem('currentBussOfic');
            const accountsPerOffice = {
                '00003': '121201',
                '00004': '121203',
                // '00005':
                '00006': '121200',
                '00007': '121206',
                '00008': '121203',
                // '00009':
                '00010': '121205'
                // '00011':
            }

            let total = 0.00;

            const header = headerExcelRaw.map((x, i) => {

                if(i > 4) {

                    const serialnu = x.E?.toString();
                    const documnum = x.F?.toString();

                    if (serialnu && documnum) {
                        const emitdate = moment(ExcelDateToJSDate(x['B']));
                        const expidate = moment(x['C'] ? ExcelDateToJSDate(x['C']) : ExcelDateToJSDate(x['B']));
                        const expiredDays = expidate.diff(emitdate);
                        let paycondi = '00000009';
                        if(expiredDays >= 60) paycondi = '00000013';
                        else if(expiredDays >= 45) paycondi = '00000012';
                        else if(expiredDays >= 30) paycondi = '00000011';
                        else if(expiredDays >= 15) paycondi = '00000010';

                        // const totalamo = preHeader.reduce((a: any, b: any) => b.amototal+ a, 0);
                        const recstatu = x['I']?.toLocaleLowerCase() === 'anulada' ? 'A' : 'P';
                        let typdocum = 'TDOC0001';
                        switch (x['D']) {
                            case 1: typdocum = 'TDOC0001'; break;
                            case 3: typdocum = 'TDOC0002'; break;
                            case 7: typdocum = 'TDOC0006'; break;
                        }
                        let typedref = null;
                        switch (x['T']) {
                            case 1: typedref = 'TDOC0001'; break;
                            case 3: typedref = 'TDOC0002'; break;
                            case 7: typedref = 'TDOC0006'; break;
                        }

                        let tipdoccu = null;
                        switch(x['G']) {
                            case 0: 
                                tipdoccu = 'TIDOC003';
                                break;
                            case 1: 
                                tipdoccu = '00000007';
                                break;
                            case 6: 
                                tipdoccu = '00000008';
                                break;
                        }

                        const accodate = ExcelDateToJSDate(x['W'])

                        return {
                            serialnu: serialnu,
                            documnum: documnum,
                            officode: officode,
                            docnumbe: x.H?.toString().trim() || '0',
                            tipdoccu: tipdoccu,
                            custname: x.H?.toString().trim() === '0' ? 'VARIOS' : x['I'].toString().trim(),
                            emitdate: emitdate,
                            expidate: expidate,
                            recedate: emitdate,
                            paycondi,

                            topgrava: x['K'],
                            topinafe: x['M'],
                            topexone: x['L'],
                            topgrati: +'0.00',
                            totaltax: x['O'],
                            partotal: x['Q'],
                            percepci: +'0.00',
                            fleteamo: +'0.00',
                            totalamo: x['Q'],

                            amoupaid: paycondi === '00000009' ? x['Q'] : '0.00',
                            balanpay: paycondi === '00000009' ? '0.00' : x['Q'],
                            recstatu,
                            currency: x['Y'] === 1 ? '00000014' : '00000015',
                            curreval: x['R'],
                            typdocum,
                            acpemont: moment(accodate).format('MM'),
                            acpeyear: moment(accodate).format('YYYY'),
                            accaccou: accountsPerOffice[officode] || '10',
                            fiscyear: moment(accodate).format('YYYY'),
                            wharcode: 'A0001',
                            intecode: 0,
                            amountss: [] as any,
                            balances: [] as any,
                            uplodate: moment().format('YYYY-MM-DDTHH:mm:ss'),
                            seriaref: x['U'],
                            documref: x['V'],
                            typedref,
                            emitdref: x['S'] ? ExcelDateToJSDate(x['S']) : null,
                            sls_ofsaldet: []
                        }
                    }
                }
            }).filter(x => x && x.docnumbe !== '');
            
            for (const iterator of header) {
                const companyCurrency: string = '00000014';
                const documentCurrency = iterator.currency;
                const change = iterator.curreval;
                const _taxvalue = 0.18;
                let amoupaid = iterator.amoupaid;
                let balanpay = iterator.partotal - amoupaid;
                amoupaid = Math.round(amoupaid*100)/100;
                balanpay = Math.round(balanpay*100)/100;
                if(companyCurrency !== documentCurrency) {
                    const topgrava = Math.round(Math.round((iterator.partotal - iterator.topexone - iterator.topinafe)*change*100)/100 / (1+(+_taxvalue))*100)/100;
                    const topexone = Math.round(iterator.topexone * change*100)/100;
                    const topinafe = Math.round(iterator.topinafe * change*100)/100;
                    const topgrati = Math.round(iterator.topgrati * change*100)/100;
                    const totaltax = Math.round((iterator.partotal - iterator.topexone - iterator.topinafe)*change*100)/100 - topgrava;
                    const partotal = topgrava + topexone + topinafe + totaltax;
                    const fleteamo = Math.round(iterator.fleteamo * change*100)/100;
                    const percepci = Math.round(iterator.percepci * change*100)/100;
                    const totalamo = partotal + fleteamo + percepci;
                    iterator.amountss = {
                        [companyCurrency]: {
                            topgrava: topgrava,
                            topexone: topexone,
                            topinafe: topinafe,
                            topgrati: topgrati,
                            totalamo: totalamo,
                            totaltax: totaltax,
                            partotal: partotal,
                            fleteamo: fleteamo,
                            percepci: percepci
                        }
                    },
                    iterator.balances = {
                        [companyCurrency]: {
                            balanpay: Math.round(balanpay * change*100)/100,
                            amoupaid: Math.round(amoupaid * change*100)/100
                        }
                    }
                    total += totalamo;
                }
                else {
                    iterator.amountss = {
                        [companyCurrency]: {
                            topgrava: iterator.topgrava,
                            topexone: iterator.topexone,
                            topinafe: iterator.topinafe,
                            topgrati: iterator.topgrati,
                            totalamo: iterator.totalamo,
                            totaltax: iterator.totaltax,
                            partotal: iterator.partotal,
                            fleteamo: iterator.fleteamo,
                            percepci: iterator.percepci
                        }
                    },
                    iterator.balances = {
                        [companyCurrency]: {
                            balanpay: balanpay,
                            amoupaid: amoupaid
                        }
                    }
                    total += iterator.totalamo;
                }
            }

            // const customers = headerExcelRaw.map((x, i) => {

            //     if(i > 8) {

            //         const serialnu = x.E?.toString();
            //         const documnum = x.F?.toString();

            //         if (!(serialnu && documnum)) return undefined;

            //         if (x['I']?.toLocaleLowerCase() === 'anulada') return undefined;
            //         let typedocu = null, custname = '', businame = '';
            //         switch(x['G']) {
            //             case 0: 
            //                 typedocu = 'TIDOC003';
            //                 custname = 'VARIOS';
            //                 break;
            //             case 1: 
            //                 typedocu = '00000007';
            //                 custname = x['I'].trim();
            //                 break;
            //             case 6: 
            //                 typedocu = '00000008';
            //                 businame = x['I'].trim();
            //                 break;
            //         }
            //         return { 
            //             docnumbe: x['H'] || '99999999999', 
            //             custname: custname,
            //             accaccou: accountsPerOffice[officode] || '10',
            //             fiscyear: localStorage.getItem('currentFiscyear'),
            //             lastname: '',
            //             mlstname: '',
            //             businame: businame,
            //             addrline: '',
            //             phonumbr: '',
            //             emailadr: '',
            //             locacode: '150101',
            //             typedocu: typedocu
            //         }
            //     }
            // }).filter((x) => x && x.docnumbe !== '');

            salesInsertBatch({
                sls_offisale: header
            }).then(res => {
                const { salesGetAllRecordsRequest } = props;
                const body = {
                    fields: {
                        serialnu: searchValue,
                        documnum: searchValue,
                        docnumbe: searchValue,
                        custname: searchValue
                    },
                    skip: 0,
                    take
                } as any
        
                if(acpeyear !== '') body.year = acpeyear;
                if(acpemont !== '') body.month = acpemont;
                if(state !== '') body.state = state;
        
                salesGetAllRecordsRequest(body);
                setModaMessage({type: 'success', message: 'Archivos cargados correctamente. Importe total: ' + String.formatNumber.new(total)});
            }).catch(err => {
                setModaMessage({type: 'error', message: `Error al realizar la importación: ${err.message}`});
            }).finally( () => { fx(); setLoading(false); handleShowModal('I'); });
        };
        reader.readAsBinaryString(file);
    }

    const handleShowModal = (typeModal: string) => {
        setShowModal(true);
        setTypeModal(typeModal);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setTypeModal('');
    };

    const onChangeSearchValue = (e) => {
        const text = e.target.value;
        setSearchValue(text);
        if(typing) clearTimeout(typing)
        setTyping(
            setTimeout(() => {
                onSearch(text);
            }, 500)
        )
    }

    const onSearch = (typing?: string) => {
        const body = {
            fields: {
                serialnu: `${typing ?? searchValue}`,
                documnum: `${typing ?? searchValue}`,
                docnumbe: `${typing ?? searchValue}`,
                custname: `${typing ?? searchValue}`
            },
            skip: 0,
            take: amountJump
        } as any

        if(acpeyear !== '') body.year = acpeyear;
        if(acpemont !== '') body.month = acpemont;
        if(state !== '') body.state = state;

        props.salesGetAllRecordsRequest(body);
        setTake(amountJump);
        pipelines.salesPipeline.salesUploadCodes(acpeyear).then((res) => {
            setUploadCodes(res.data.data.sls_uploads.map(x => { return {id: x.uplocode, name: moment(x.uplodate).format('DD-MM-YYYY') + '   -   ' + x.uplocode} }));
        });
    }

    const callMoreItems = () => {
        const body = {
            fields: {
                serialnu: searchValue,
                documnum: searchValue,
                docnumbe: searchValue,
                custname: searchValue
            },
            skip: 0,
            take: take + amountJump
        } as any

        if(acpeyear !== '') body.year = acpeyear;
        if(acpemont !== '') body.month = acpemont;
        if(state !== '') body.state = state;

        props.salesGetAllRecordsRequest(body);
        setTake(take + amountJump);
    }

    useEffect(() => {
        const { salesGetAllRecordsRequest } = props;
        const body = {
            fields: {
                serialnu: searchValue,
                documnum: searchValue,
                docnumbe: searchValue,
                custname: searchValue
            },
            skip: 0,
            take
        } as any

        if(acpeyear !== '') body.year = acpeyear;
        if(acpemont !== '') body.month = acpemont;
        if(state !== '') body.state = state;

        salesGetAllRecordsRequest(body);
        pipelines.salesPipeline.salesInitForm().then((res) => {
            setTables(res.data.data.tables);
        });
        pipelines.salesPipeline.salesUploadCodes(acpeyear).then((res) => {
            setUploadCodes(res.data.data.sls_uploads.map(x => { return {id: x.uplocode, name: moment(x.uplodate).format('DD-MM-YYYY') + '   -   ' + x.uplocode} }));
        });
    }, []);

    return (
        <div className={classes.container}>
            <div className={classes.titleContainer}>
                <div className={classes.title}>Ventas</div>
                <div>
                    <span className={`${classes.tabs} ${classes.tabActive}`}>Lista</span>
                    <span className={classes.tabs}>Chart</span>
                    <span className={classes.tabs}>Historial</span>
                </div>
            </div>
            <div className={classes.listContainer}>
                <div>
                    <Search styleContainer={{flex: 1}} onChange={onChangeSearchValue} value={searchValue}/>
                    <Select label="Año" labelPosition="left" style={{marginLeft: '1rem'}} data={
                        [{id: '2024', name: '2024'}, {id: '2023', name: '2023'}, {id: '2022', name: '2022'}, {id: '2021', name: '2021'}, {id: '2020', name: '2020'}, {id: '2019', name: '2019'}]
                    } value={acpeyear} onChange={(value) => setAcpeyear(value.id)}/>
                    <Select label="Mes" labelPosition="left" style={{marginLeft: '1rem'}} data={months} value={acpemont} onChange={(value) => setAcpemont(value.id)}/>
                    <Select label="Estado" labelPosition="left" style={{marginLeft: '1rem'}} data={
                        [{id: '00', name: '--Todos--'}, {id: '01', name: 'Deuda'}, {id: '02', name: 'Pagado'}]
                    } value={state} onChange={(value) => setState(value.id)}/>
                    &nbsp;&nbsp;
                    <Button type='primary' full={false} containerStyle={{height: 'auto'}} onClick={() => onSearch()}>Filtrar</Button>
                </div>
                <Separator marginBottom='1rem'/>
                <ListMenu
                    deleteItem={() => handleShowModal('D')}
                    uploadItems={importExcel}
                />
                <Separator marginBottom='0.5rem'/>
                <ListView
                    header={header} body={props.salesReducers.list}
                    selectAction={props.salesListSelectItem} 
                    selectAllAction={props.salesListSelectAllItem}
                    total={props.salesReducers.total}
                    callMoreItems={callMoreItems}
                />
            </div>
            {
                showModal && typeModal === 'D' &&
                <Modal 
                    title="Penta ERP"
                    onClose={() => setShowModal(false)}
                    footer={
                        <React.Fragment>
                            <Button type='bad' full={false} onClick={handleCloseModal}>Cancelar</Button>
                            &nbsp;&nbsp;
                            <Button full={false} onClick={eliminar}>Aceptar</Button>
                        </React.Fragment>
                    }
                >
                    <React.Fragment>
                        <p>Seleccione los elementos a eliminar por lote de subida<br/></p>
                        <Select label="Lotes" labelPosition="top" style={{marginBottom: '1rem'}} data={uploadCodes} value={uploadDelete} onChange={(value) => setUploadDelete(value.id)}/>
                    </React.Fragment>
                </Modal>
            }
            {
                showModal && typeModal === 'I' &&
                <Modal 
                    title="Penta ERP"
                    onClose={handleCloseModal}
                    footer={
                        <React.Fragment>
                            <Button full={false} onClick={handleCloseModal}>Aceptar</Button>
                        </React.Fragment>
                    }
                >
                    <React.Fragment>
                        <p>{modalMessage.message}</p>
                    </React.Fragment>
                </Modal>
            }
            <ScreenLoader enabled={loading}/>
        </div>
    );
}

const authReducers = 'authReducers';
const dashboardReducers = 'dashboardReducers';
const reducer = 'salesReducers';

const mapStateToProps = (state: any) => ({
    authReducers: state.get(authReducers),
    dashboardReducers: state.get(dashboardReducers),
    salesReducers: state.get(reducer)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        salesGetAllRecordsRequest,
        salesListSelectItem,
        salesListSelectAllItem,
        salesActionFailed,
        salesDeleteRequest,
        salesMassiveUpdateRequest
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ListSales);