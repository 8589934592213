import React from 'react';
import { Button, createStyles, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Theme, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { kardexRequest } from 'penta-redux/actions/salesActions';
import './../reports.scss'
import { MessageBox } from 'penta-components/base/messageBox';
import moment from 'moment';
import * as XLSX from 'sheetjs-style';
import ScreenLoader from 'penta-components/base/screenLoader';

let saveAs = require('file-saver').saveAs;

const meses = [
    { key: '00', value: 'Apertura' },
    { key: '01', value: 'Enero' },
    { key: '02', value: 'Febrero' },
    { key: '03', value: 'Marzo' },
    { key: '04', value: 'Abril' },
    { key: '05', value: 'Mayo' },
    { key: '06', value: 'Junio' },
    { key: '07', value: 'Julio' },
    { key: '08', value: 'Agosto' },
    { key: '09', value: 'Septiembre' },
    { key: '10', value: 'Octubre' },
    { key: '11', value: 'Noviembre' },
    { key: '12', value: 'Diciembre' }
]

const useStyles = (theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        formControl: {
            margin: 0,
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    });

interface FormState {
    title: string,
    skucodee: string
    year: string,
    month: string,
    base64Pdf: string
}

class ReportKardex extends React.Component<any, FormState> {

    constructor(props: any) {
        super(props);
        this.state = {
            title: 'Reporte de Kardex',
            skucodee: '',
            year: moment().format('yyyy').toString(),
            month: moment().format('MM').toString(),
            base64Pdf: ''
        }
    }

    onChangeRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            ...value
        }))
    }

    onGenerateExcel = () => {
        try {
            const data = this.props.salesReducers.onlyOne;
            const { month, year } = this.state;
            const { dashboardReducers } = this.props;
            const mes = meses.find((x: any) => x.key === month).value;
            const tables = JSON.parse(localStorage.getItem('tables'));
            const company = tables.companys.find((x: any) => x.taxident === dashboardReducers.currentCompany);
            const division = company.lst_grl_division.find((x: any) => x.divicode === dashboardReducers.currentDivision);
            const oficina = division.lst_grl_bussofic.find((x: any) => x.officode === dashboardReducers.currentBussOfic);

            const tableBody: any = [
                { 
                    A: null,
                    B: 'KARDEX ' + oficina.offiname.toUpperCase(),
                    C: null,
                    D: null,
                    E: null,
                    F: null,
                    G: null,
                    H: null,
                    I: null,
                    J: null,
                    K: null,
                    L: null,
                    M: null
                },
                { 
                    A: null,
                    B: `PERIODO: ${mes} ${year}`,
                    C: null,
                    D: null,
                    E: null,
                    F: null,
                    G: null,
                    H: null,
                    I: null,
                    J: null,
                    K: null,
                    L: null,
                    M: null
                },
                { 
                    A: null,
                    B: `RUC: ${dashboardReducers.currentCompany}`,
                    C: null,
                    D: null,
                    E: null,
                    F: null,
                    G: null,
                    H: null,
                    I: null,
                    J: null,
                    K: null,
                    L: null,
                    M: null
                },
                { 
                    A: null,
                    B: `EMPRESA: ${company.bussname.toUpperCase()}`,
                    C: null,
                    D: null,
                    E: null,
                    F: null,
                    G: null,
                    H: null,
                    I: null,
                    J: null,
                    K: null,
                    L: null,
                    M: null
                },
                { 
                    A: null,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null,
                    G: null,
                    H: null,
                    I: null,
                    J: null,
                    K: null,
                    L: null,
                    M: null
                }
            ];

            // const compras = data.filter(x => x.unicodop.includes('FC') || x.unicodop.includes('NC') || x.unicodop.includes('ND'));
            // const ventas = data.filter(x => x.unicodop.includes('FV'));
            const productsRead: any = [];
            const cutRows: Array<number> = [];

            for (const iterator of data) {
                if (!productsRead.includes(iterator.skucodee)) {
                    let totalQuantity = 0;
                    let totalCost = 0;
                    tableBody.push(
                        {
                            A: null,
                            B: `Codigo de existencia: ${iterator.skucodee}`,
                            C: null,
                            D: null,
                            E: null,
                            F: null,
                            G: null,
                            H: null,
                            I: null,
                            J: null,
                            K: null,
                            L: null,
                            M: null
                        }
                    );
                    tableBody.push(
                        {
                            A: null,
                            B: `Descripcion ${iterator.prodname}`,
                            C: null,
                            D: null,
                            E: null,
                            F: null,
                            G: null,
                            H: null,
                            I: null,
                            J: null,
                            K: null,
                            L: null,
                            M: null
                        }
                    );
                    tableBody.push(
                        {
                            A: null,
                            B: null,
                            C: null,
                            D: null,
                            E: null,
                            F: null,
                            G: null,
                            H: null,
                            I: null,
                            J: null,
                            K: null,
                            L: null,
                            M: null
                        }
                    );
                    cutRows.push(tableBody.length);
                    tableBody.push(
                        {
                            A: null,
                            B: 'FECHA',
                            C: 'TIPO DE OPERACIÓN',
                            D: 'DOCUMENTO',
                            E: 'ENTRADAS',
                            F: '',
                            G: '',
                            H: 'SALIDAS',
                            I: '',
                            J: '',
                            K: 'SALDOS',
                            L: '',
                            M: ''
                        }
                    );
                    tableBody.push(
                        {
                            A: null,
                            B: '',
                            C: '',
                            D: '',
                            E: 'CANT.',
                            F: 'C.U.',
                            G: 'C.T.',
                            H: 'CANT.',
                            I: 'C.U.',
                            J: 'C.T.',
                            K: 'CANT.',
                            L: 'C.U.',
                            M: 'C.T.'
                        }
                    );
                    tableBody.push(
                        { 
                            A: null,
                            B: moment(new Date(`${year}-${month}-01`)).format('DD/MM/YYYY'),
                            C: 'SALDO INICIAL',
                            D: null,
                            E: null,
                            F: null,
                            G: null,
                            H: null,
                            I: null,
                            J: null,
                            K: String.formatNumber.new(0),
                            L: String.formatNumber.new(0),
                            M: String.formatNumber.new(0)
                        },
                    );
                    const rows = data.filter((x: any) => x.skucodee === iterator.skucodee);
                    const rowsCompras = rows.filter(x => x.typetran === 'PURCH');
                    const rowsVentas = rows.filter(x => x.typetran === 'SALES');
                    const rowsNotaCreditoCompras = rows.filter(x => x.typetran === 'NOCRC');
                    const rowsNotaCreditoVentas = rows.filter(x => x.typetran === 'NOCRV');
                    for (const row of rowsCompras) {
                        totalQuantity += row.quantity;
                        totalCost += row.amototal;
                        const totalUnitaryCost = totalCost / totalQuantity;
                        tableBody.push(
                            { 
                                A: null,
                                B: moment(row.dateoper).format('DD/MM/YYYY'),
                                C: 'COMPRA',
                                D: `${row.serialnu}-${row.documnum}`,
                                E: String.formatNumber.new(row.quantity),
                                F: String.formatNumber.new(row.unitpric),
                                G: String.formatNumber.new(row.amototal),
                                H: null,
                                I: null,
                                J: null,
                                K: String.formatNumber.new(totalQuantity),
                                L: String.formatNumber.new(totalUnitaryCost),
                                M: String.formatNumber.new(totalCost)
                            },
                        )
                    }
                    for (const row of rowsVentas) {
                        totalQuantity -= row.quantity;
                        totalCost -= row.amototal;
                        const totalUnitaryCost = totalCost / totalQuantity;
                        tableBody.push(
                            { 
                                A: null,
                                B: moment(row.dateoper).format('DD/MM/YYYY'),
                                C: 'VENTA',
                                D: `${row.serialnu}-${row.documnum}`,
                                E: null,
                                F: null,
                                G: null,
                                H: String.formatNumber.new(row.quantity),
                                I: String.formatNumber.new(row.unitpric),
                                J: String.formatNumber.new(row.amototal),
                                K: String.formatNumber.new(totalQuantity),
                                L: String.formatNumber.new(totalUnitaryCost),
                                M: String.formatNumber.new(totalCost)
                            },
                        )
                    }
                    for (const row of rowsNotaCreditoCompras) {
                        totalQuantity += row.quantity;
                        totalCost += row.amototal;
                        const totalUnitaryCost = totalCost / totalQuantity;
                        tableBody.push(
                            { 
                                A: null,
                                B: moment(row.dateoper).format('DD/MM/YYYY'),
                                C: 'NOTA DE CREDITO-COMPRAS',
                                D: `${row.serialnu}-${row.documnum}`,
                                E: String.formatNumber.new(row.quantity),
                                F: String.formatNumber.new(row.unitpric),
                                G: String.formatNumber.new(row.amototal),
                                H: null,
                                I: null,
                                J: null,
                                K: String.formatNumber.new(totalQuantity),
                                L: String.formatNumber.new(totalUnitaryCost),
                                M: String.formatNumber.new(totalCost)
                            },
                        )
                    }
                    for (const row of rowsNotaCreditoVentas) {
                        totalQuantity -= row.quantity;
                        totalCost -= row.amototal;
                        const totalUnitaryCost = totalCost / totalQuantity;
                        tableBody.push(
                            { 
                                A: null,
                                B: moment(row.dateoper).format('DD/MM/YYYY'),
                                C: 'NOTA DE CREDITO-VENTA',
                                D: `${row.serialnu}-${row.documnum}`,
                                E: null,
                                F: null,
                                G: null,
                                H: String.formatNumber.new(row.quantity),
                                I: String.formatNumber.new(row.unitpric),
                                J: String.formatNumber.new(row.amototal),
                                K: String.formatNumber.new(totalQuantity),
                                L: String.formatNumber.new(totalUnitaryCost),
                                M: String.formatNumber.new(totalCost)
                            },
                        )
                    }
                    tableBody.push(
                        { 
                            A: null,
                            B: null,
                            C: 'SALDO FINAL',
                            D: null,
                            E: null,
                            F: null,
                            G: null,
                            H: null,
                            I: null,
                            J: null,
                            K: String.formatNumber.new(totalQuantity),
                            L: String.formatNumber.new(totalCost / totalQuantity),
                            M: String.formatNumber.new(totalCost)
                        },
                    )
                    tableBody.push(
                        {
                            A: null,
                            B: null,
                            C: null,
                            D: null,
                            E: null,
                            F: null,
                            G: null,
                            H: null,
                            I: null,
                            J: null,
                            K: null,
                            L: null,
                            M: null
                        }
                    );
                    productsRead.push(iterator.skucodee);
                }
            }

            const header = [
                'A',
                'B',
                'C',
                'D',
                'E',
                'F',
                'G',
                'H',
                'I',
                'J',
                'K',
                'L',
                'M'
            ];

            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(tableBody, {header, skipHeader: true});
            ws["!cols"] = [
                {wpx:10},{wpx:70},{wpx:70},{wpx:70},{wpx:50},{wpx:50},{wpx:50},{wpx:50},{wpx:50},{wpx:50},{wpx:50},{wpx:50},{wpx:50}
            ];
            ws['!rows'] = [
                {hpx: 18},{hpx: 16},{hpx: 16},{hpx: 16},
            ];

            const keys = Object.keys(ws);
            const rowsCount = tableBody.length;

            for (const iterator of keys) {
                if(!isNaN(parseInt(ws[iterator].v)) && !iterator.match(/^(A|B|C|D)[0-9]+$/g)) {
                    ws[iterator].v = ws[iterator].v.replace(/,/g, '');
                    ws[iterator].t = 'n';
                    ws[iterator].z = '#,##0.00';
                }
                if(iterator !== '!ref' && iterator !== '!cols' && iterator !== '!rows') {
                    ws[iterator].s = {
                        font: {
                            name: 'Tahoma',
                            sz: 8
                        }
                    }
                    const row = +(iterator.match(/\d+/)[0]);
                    if(cutRows.includes(row + 1) || cutRows.includes(row + 2)) {
                        ws[iterator].s.font.bold = true;
                    }
                    if(cutRows.includes(row - 1) || cutRows.includes(row - 2)) {
                        ws[iterator].s.alignment = { wrapText: true, vertical: 'center', horizontal: 'center'}
                        ws[iterator].s.border = {
                            ...ws[iterator].s.border,
                            top: { style: 'thin', color: 'black'},
                            bottom: { style: 'thin', color: 'black'},
                            left: { style: 'thin', color: 'black'},
                            right: { style: 'thin', color: 'black'}
                        }
                    }
                }
            }

            for (let index = 4; index <= rowsCount; index++) {
                ws['!rows'].push({hpx: 12})
            }

            ws['B1'].s = {font:{name: 'Tahoma', sz: 15, bold: true}, alignment: { wrapText: true, vertical: 'center', horizontal: 'center'}}
            ws['B2'].s = {font:{name: 'Tahoma', sz: 8, bold: true}}
            ws['B3'].s = {font:{name: 'Tahoma', sz: 8, bold: true}}
            ws['B4'].s = {font:{name: 'Tahoma', sz: 8, bold: true}}

            ws["!merges"] = [
                { s: { r: 0, c: 1 }, e: { r: 0, c: 12 } },
                ...cutRows.map((item) => { return { s: { r: item, c: 1 }, e: { r: item + 1, c: 1 } } }),
                ...cutRows.map((item) => { return { s: { r: item, c: 2 }, e: { r: item + 1, c: 2 } } }),
                ...cutRows.map((item) => { return { s: { r: item, c: 3 }, e: { r: item + 1, c: 3 } } }),
                ...cutRows.map((item) => { return { s: { r: item, c: 4 }, e: { r: item, c: 6 } } }),
                ...cutRows.map((item) => { return { s: { r: item, c: 7 }, e: { r: item, c: 9 } } }),
                ...cutRows.map((item) => { return { s: { r: item, c: 10 }, e: { r: item, c: 12 } } }),
            ];

            XLSX.utils.book_append_sheet(wb, ws, "Kardex");

            var wbout = XLSX.write(wb, {bookType:'xlsx', type: 'binary', cellStyles: true});

            const s2ab = function(s) {
                var buf = new ArrayBuffer(s.length);
                var view = new Uint8Array(buf);
                for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
                return buf;
            }

            saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), `kardex-${year}-${month}.xlsx`);

        } catch(err) {
            console.log(err);
        }
    }

    render() {

        const { title, year, month, skucodee } = this.state;
        const { loading, failed, failedTitle, failedContent } = this.props.salesReducers;
        const { classes } = this.props;

        return (
            <div style={{overflow: 'auto', height: '100%'}}>
                <Paper className='form__container'>
                    <Typography variant='h5'>{ title }</Typography>
                    <br/>
                    { failed && <MessageBox title={failedTitle} content={failedContent}/> }
                    <div style={{ display: 'flex' }}>
                        <form noValidate autoComplete="off" style={{ flex: 1, paddingRight: '15px', paddingTop: '2em' }}>
                            <Grid container spacing={2} >
                                <Grid item xs={4}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_año">Año</InputLabel>
                                            <Select
                                                labelId="lbl_año"
                                                id="year"
                                                className='select'
                                                label="Año"
                                                fullWidth
                                                value={ year }
                                                onChange={(e: any) => this.onChangeRoot({'year': e.target.value})}
                                            >
                                                <MenuItem value='2030'>2030</MenuItem>
                                                <MenuItem value='2029'>2029</MenuItem>
                                                <MenuItem value='2028'>2028</MenuItem>
                                                <MenuItem value='2027'>2027</MenuItem>
                                                <MenuItem value='2026'>2026</MenuItem>
                                                <MenuItem value='2025'>2025</MenuItem>
                                                <MenuItem value='2026'>2026</MenuItem>
                                                <MenuItem value='2025'>2025</MenuItem>
                                                <MenuItem value='2024'>2024</MenuItem>
                                                <MenuItem value='2023'>2023</MenuItem>
                                                <MenuItem value='2022'>2022</MenuItem>
                                                <MenuItem value='2021'>2021</MenuItem>
                                                <MenuItem value='2020'>2020</MenuItem>
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_mes">Mes</InputLabel>
                                            <Select
                                                labelId="lbl_mes"
                                                id="month"
                                                className='select'
                                                label="Mes"
                                                fullWidth
                                                value={ month }
                                                onChange={(e: any) => this.onChangeRoot({'month': e.target.value})}
                                            >
                                                {
                                                    meses.map((x: any) => 
                                                        <MenuItem value={x.key} key={x.key}>{x.value}</MenuItem>
                                                    )
                                                }
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                        <TextField fullWidth id="skucodee" label="Codigo de producto" variant="outlined" value={ skucodee } onChange={(e: any) => this.onChangeRoot({'skucodee': e.target.value})} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4} style={{ paddingTop: '10px' }}>
                                    <Button variant="contained" fullWidth color="inherit" onClick={() => { this.props.kardexRequest(skucodee, year, month, this.onGenerateExcel) }}>
                                        Generar Reporte EXCEL
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                    <br/>
                    {/* <FormFooter onCancel={ this.onCancel } onSubmit={ this.onSubmit }/> */}
                </Paper>
                <ScreenLoader enabled={loading}/>
            </div>
        );
    }
}

const salesReducers = 'salesReducers';
const dashboardReducers = 'dashboardReducers';

const mapStateToProps = (state: any) => ({
    salesReducers: state.get(salesReducers),
    dashboardReducers: state.get(dashboardReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        kardexRequest
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(ReportKardex));