import React from 'react';
import { Button, createStyles, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Paper, Select, Switch, Theme, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { salesRegisterRequest } from 'penta-redux/actions/accountantActions';
import { MessageBox } from 'penta-components/base/messageBox';
import moment from 'moment';
import ScreenLoader from 'penta-components/base/screenLoader';

let saveAs = require('file-saver').saveAs;

const meses = [
    { key: '01', value: 'Enero' },
    { key: '02', value: 'Febrero' },
    { key: '03', value: 'Marzo' },
    { key: '04', value: 'Abril' },
    { key: '05', value: 'Mayo' },
    { key: '06', value: 'Junio' },
    { key: '07', value: 'Julio' },
    { key: '08', value: 'Agosto' },
    { key: '09', value: 'Septiembre' },
    { key: '10', value: 'Octubre' },
    { key: '11', value: 'Noviembre' },
    { key: '12', value: 'Diciembre' }
]

const useStyles = (theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        formControl: {
            margin: 0,
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    });

interface FormcategorysState {
    title: string,
    year: string,
    month: string,
    officode: string,
    divicode: string
    todo: boolean
    base64Pdf: string
}

class ReportPleSale extends React.Component<any, FormcategorysState> {

    constructor(props: any) {
        super(props);
        this.state = {
            title: 'PLE de ventas',
            year: moment().format('yyyy').toString(),
            month: moment().format('MM').toString(),
            officode: '',
            divicode: '',
            base64Pdf: '',
            todo: false
        }
    }

    onChangeRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            ...value
        }))
    }

    onGeneratePdf = () => {
        const { dashboardReducers } = this.props;
        const { month, year } = this.state;
        const data = this.props.accountantReducers.onlyOne;
        const tables = JSON.parse(localStorage.getItem('tables'));
        const company = tables.companys.find((x: any) => x.taxident === dashboardReducers.currentCompany);
        const division = company.lst_grl_division.find((x: any) => x.divicode === dashboardReducers.currentDivision);
        const oficina = division.lst_grl_bussofic.find((x: any) => x.officode === dashboardReducers.currentBussOfic);
        let txtContent = '';
        for (const row of data) {
            txtContent += `20104625500|SERVICENTRO LAS AMERICAS S.A.C.|${row.acpeyear}${row.acpemont}||${moment(row.emitdate).format('DD/MM/YYYY')}|${row.paycondi !== '00000009' ? moment(row.expidate).format('DD/MM/YYYY') : ''}|${row.tppurcha}|${row.serialnu}|${row.documnum}||${row.typedocu}|${row.docnumbe}|${row.businame}|0|${Math.round(row.topgrava * 100) / 100}|0|${Math.round(row.totaltax * 100) / 100}|0|${Math.round(row.topexone * 100) / 100}|${Math.round(row.topinafe * 100) / 100}|0|0|0|0|0|${Math.round(row.totalamo * 100) / 100}|${row.currency === '00000014' ? 'PEN' : 'USD'}|1.000|||||||`;

            // txtContent += `${row.acpeyear}${row.acpemont}00|${row.unicodop}|M${row.unicodop.slice(2)}|${moment(row.emitdate).format('DD/MM/YYYY')}|${row.tppurcha === '14' ? moment(row.expidate).format('DD/MM/YYYY') : ''}|${row.tppurcha}|${row.serialnu}|${row.documnum}||${+row.typedocu}|${row.docnumbe}|${row.businame}|0.00|${Math.round(row.topgrava * 100) / 100}|0.00|${Math.round(row.totaltax * 100) / 100}|0.00|0|0.00|0.00|0.00|0.00|0.00|0.00|${Math.round(row.totalamo * 100) / 100}|||${row.emitdref ? moment(row.emitdref).format('DD/MM/YYYY') : '01/01/0001'}|${row.tppurref ? row.tppurref : ''}|${row.seriaref ? row.seriaref.trim() : ''}|${row.documref ? row.documref.trim() : ''}|||1|1|`;
            txtContent += '\n';
        }
        saveAs(new Blob([txtContent],{type:"text/plain"}), `LE${dashboardReducers.currentCompany}${year}${month}00140100001111.txt`);
        // saveAs(new Blob([''],{type:"text/plain"}), `LE${dashboardReducers.currentCompany}${year}${month}00080200001011.txt`);
    }

    componentDidMount() {
        const { dashboardReducers } = this.props;
        this.setState({
            officode: dashboardReducers.currentDivision,
            divicode: dashboardReducers.currentBussOfic,
        })
    }

    render() {

        const { title, year, month, divicode, officode, base64Pdf, todo } = this.state;
        const { loading, list, tables, failed, failedTitle, failedContent } = this.props.accountantReducers;
        const { classes, dashboardReducers } = this.props;
        const company = JSON.parse(localStorage.getItem('tables')).companys.find((x: any) => x.taxident === dashboardReducers.currentCompany);

        return (
            <div style={{overflow: 'auto', height: '100%'}}>
                <Paper className='form__container'>
                    <Typography variant='h5'>{ title }</Typography>
                    <br/>
                    { failed && <MessageBox title={failedTitle} content={failedContent}/> }
                    <div style={{ display: 'flex' }}>
                        <form noValidate autoComplete="off" style={{ flex: 1, paddingRight: '15px', paddingTop: '2em' }}>
                            <Grid container spacing={2} >
                                <Grid item xs={6}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_año">Año</InputLabel>
                                            <Select
                                                labelId="lbl_año"
                                                id="year"
                                                className='select'
                                                label="Año"
                                                fullWidth
                                                value={ year }
                                                onChange={(e: any) => this.onChangeRoot({'year': e.target.value})}
                                            >
                                                <MenuItem value='2030'>2030</MenuItem>
                                                <MenuItem value='2029'>2029</MenuItem>
                                                <MenuItem value='2028'>2028</MenuItem>
                                                <MenuItem value='2027'>2027</MenuItem>
                                                <MenuItem value='2026'>2026</MenuItem>
                                                <MenuItem value='2025'>2025</MenuItem>
                                                <MenuItem value='2026'>2026</MenuItem>
                                                <MenuItem value='2025'>2025</MenuItem>
                                                <MenuItem value='2024'>2024</MenuItem>
                                                <MenuItem value='2023'>2023</MenuItem>
                                                <MenuItem value='2022'>2022</MenuItem>
                                                <MenuItem value='2021'>2021</MenuItem>
                                                <MenuItem value='2020'>2020</MenuItem>
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_mes">Mes</InputLabel>
                                            <Select
                                                labelId="lbl_mes"
                                                id="month"
                                                className='select'
                                                label="Mes"
                                                fullWidth
                                                value={ month }
                                                onChange={(e: any) => this.onChangeRoot({'month': e.target.value})}
                                            >
                                                {
                                                    meses.map((x: any) => 
                                                        <MenuItem value={x.key} key={x.key}>{x.value}</MenuItem>
                                                    )
                                                }
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth disabled={company === '' ? true : false}>
                                        <InputLabel id="lbl_division">Division</InputLabel>
                                        <Select
                                            labelId="lbl_division"
                                            id="division"
                                            value={divicode}
                                            onChange={(e: React.ChangeEvent<{ value: unknown }>) => { this.onChangeRoot({'divicode': e.target.value}) }}
                                            label="Division"
                                            fullWidth
                                        >
                                            {
                                                company.lst_grl_division.map((x: any) => 
                                                    <MenuItem value={x.divicode} key={x.divicode}>{ x.diviname }</MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth disabled={divicode === '' ? true : false}>
                                        <InputLabel id="lbl_oficina">Oficina</InputLabel>
                                        <Select
                                            labelId="lbl_oficina"
                                            id="oficina"
                                            value={officode}
                                            onChange={(e: React.ChangeEvent<{ value: unknown }>) => { this.onChangeRoot({'officode': e.target.value}) }}
                                            label="Oficina"
                                            fullWidth
                                        >
                                            {
                                                company.lst_grl_division.find((x) => x.divicode === divicode)?.lst_grl_bussofic.map((x: any) => 
                                                    <MenuItem value={x.officode} key={x.officode}>{ x.offiname }</MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={todo}
                                                onChange={(e: any) => this.onChangeRoot({'todo': e.target.checked})}
                                                name="todo"
                                                color="primary"
                                            />
                                        }
                                        label="Acumulado"
                                    />
                                </Grid>
                                <Grid item xs={6} style={{ paddingTop: '50px' }}>
                                    <Button variant="contained" fullWidth color="primary" onClick={() => {
                                        this.props.salesRegisterRequest(officode, year, month, todo, this.onGeneratePdf) 
                                    }}>
                                        Generar Reporte TXT
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                    <br/>
                    {/* <FormFooter onCancel={ this.onCancel } onSubmit={ this.onSubmit }/> */}
                </Paper>
                <ScreenLoader enabled={loading}/>
            </div>
        );
    }
}

const accountantReducers = 'accountantReducers';
const dashboardReducers = 'dashboardReducers';

const mapStateToProps = (state: any) => ({
    accountantReducers: state.get(accountantReducers),
    dashboardReducers: state.get(dashboardReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        salesRegisterRequest
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(ReportPleSale));