import React from 'react';
import { Button, createStyles, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Theme, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { purchaseDetailSupplierRequest } from 'penta-redux/actions/accountantActions';
import './../reports.scss'
import { MessageBox } from 'penta-components/base/messageBox';
import PdfView from 'penta-components/base/pdfView';
import moment from 'moment';
import * as XLSX from 'sheetjs-style';
import ScreenLoader from 'penta-components/base/screenLoader';

let pdfMake = require("pdfmake/build/pdfmake");
let saveAs = require('file-saver').saveAs;

const meses = [
    { key: '01', value: 'Enero' },
    { key: '02', value: 'Febrero' },
    { key: '03', value: 'Marzo' },
    { key: '04', value: 'Abril' },
    { key: '05', value: 'Mayo' },
    { key: '06', value: 'Junio' },
    { key: '07', value: 'Julio' },
    { key: '08', value: 'Agosto' },
    { key: '09', value: 'Septiembre' },
    { key: '10', value: 'Octubre' },
    { key: '11', value: 'Noviembre' },
    { key: '12', value: 'Diciembre' }
]

const useStyles = (theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        formControl: {
            margin: 0,
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    });

interface FormState {
    title: string
    year: string
    initmonth: string
    endmonth: string
    ruc: string
    product: string
    base64Pdf: string
}

const officode = localStorage.getItem('currentBussOfic');

class ReportPurchaseDetailSupplier extends React.Component<any, FormState> {

    constructor(props: any) {
        super(props);
        this.state = {
            title: 'Reporte de Detalle de compras por proveedor',
            year: moment().format('yyyy').toString(),
            initmonth: moment().format('MM').toString(),
            endmonth: moment().format('MM').toString(),
            ruc: '',
            product: '',
            base64Pdf: ''
        }
    }

    onChangeRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            ...value
        }))
    }

    onGeneratePdf = () => {
        const data = this.props.accountantReducers.onlyOne;
        const { initmonth, endmonth, year } = this.state;
        const { dashboardReducers } = this.props;
        const mesInicio = meses.find((x: any) => x.key === initmonth).value;
        const mesFin = meses.find((x: any) => x.key === endmonth).value;
        const company = (JSON.parse(localStorage.getItem('tables')).companys.find((x: any) => x.taxident === dashboardReducers.currentCompany).bussname as string).toUpperCase()

        const pdfObject: any = {
            pageOrientation: 'portrait',
            pageMargins: [ 20, 70, 20, 20 ],
            pageSize: 'A4',
            header: [
                {
                    text: ' ', lineHeight: 2
                },
                {
                    margin: [20, 0, 20, 0],
                    text: company,
                    style: {
                        bold: true,
                        fontSize: 9
                    }
                },
                {
                    text: `FACTURAS DE COMPRAS DE ${mesInicio.toUpperCase()} A ${mesFin.toUpperCase()}`,
                    style: {
                        alignment: 'center',
                        bold: true,
                        fontSize: 9
                    }
                }
            ],
            content: [
                {
                    table: {
                        headerRows: 0,
                        widths: [ 20, 50, '*', 50, 50, 50 ],
                        body: []
                    }
                },
            ],
            styles: {
                headerTitle: {
                    bold: true,
                    fontSize: 6,
                    lineHeight: 1.2
                },
                table: {
                    layout: 'headerLineOnly',
                    fontSize: 6,
                    alignment: 'center'
                },
                tableDetailLeft: {
                    layout: 'headerLineOnly',
                    fontSize: 5.5,
                    alignment: 'left'
                },
                tableDetailLeftBold: {
                    layout: 'headerLineOnly',
                    fontSize: 5.5,
                    alignment: 'left',
                    bold: true
                },
                tableDetailRight: {
                    layout: 'headerLineOnly',
                    fontSize: 5.5,
                    alignment: 'right'
                },
                tableDetailRightBold: {
                    layout: 'headerLineOnly',
                    fontSize: 6,
                    alignment: 'right',
                    bold: true
                }
            },
            defaultStyle: {
                columnGap: 20,
            },
        }

        const tableBody: any = [];
        const supplierRead: any = [];

        for (const row of data) {
            if (!supplierRead.includes(row.docsuppl)) {
                tableBody.push(
                    [ 
                        { text: row.businame, colSpan: 6, border: [false, false, false, false], style: {fontSize: 7, alignment: 'left', bold: true} },
                        { text: '', border: [false, false, false, false], style: 'tableDetailLeft' },
                        { text: '', border: [false, false, false, false], style: 'tableDetailRight' },
                        { text: '', border: [false, false, false, false], style: 'tableDetailRight' },
                        { text: '', border: [false, false, false, false], style: 'tableDetailRight' },
                        { text: '', border: [false, false, false, false], style: 'tableDetailRight' }
                    ]
                );
                tableBody.push(
                    [ 
                        { text: ' ', colSpan: 6, border: [false, false, false, false], style: 'tableDetailLeft' },
                        { text: '', border: [false, false, false, false], style: 'tableDetailLeft' },
                        { text: '', border: [false, false, false, false], style: 'tableDetailRight' },
                        { text: '', border: [false, false, false, false], style: 'tableDetailRight' },
                        { text: '', border: [false, false, false, false], style: 'tableDetailRight' },
                        { text: '', border: [false, false, false, false], style: 'tableDetailRight' }
                    ]
                );
                const purchases = data.filter(x => x.docsuppl === row.docsuppl);
                const purchRead = [];
                for (const purchase of purchases) {
                    if(purchRead.includes(`${purchase.serialnu}-${purchase.documnum}`)) continue;
                    tableBody.push(
                        [ 
                            { text: `FAC:${purchase.serialnu}-${purchase.documnum} EMISION:${moment(purchase.emitdate).format('DDMMYYYY')}`, colSpan: 6, border: [false, false, false, false], style: 'tableDetailLeft' },
                            { text: '', border: [false, false, false, false], style: 'tableDetailLeft' },
                            { text: '', border: [false, false, false, false], style: 'tableDetailRight' },
                            { text: '', border: [false, false, false, false], style: 'tableDetailRight' },
                            { text: '', border: [false, false, false, false], style: 'tableDetailRight' },
                            { text: '', border: [false, false, false, false], style: 'tableDetailRight' }
                        ]
                    );
                    const details = purchases.filter(x => x.serialnu === purchase.serialnu && x.documnum === purchase.documnum);
                    let nroItem = 1;
                    for (const detail of details) {
                        tableBody.push(
                            [ 
                                { text: nroItem, border: [true, true, true, true], style: 'tableDetailLeft' },
                                { text: '', border: [true, true, true, true], style: 'tableDetailLeft' },
                                { text: detail.prodname, border: [true, true, true, true], style: 'tableDetailLeft' },
                                { text: String.formatNumber.new(detail.quantity), border: [true, true, true, true], style: 'tableDetailRight' },
                                { text: String.formatNumber.new(detail.unitpric), border: [true, true, true, true], style: 'tableDetailRight' },
                                { text: String.formatNumber.new(detail.amototal), border: [true, true, true, true], style: 'tableDetailRight' }
                            ]
                        );
                        nroItem++;
                    }
                    const currencySymbol = purchase.currency === '00000014' ? 'S/.' : '$';
                    tableBody.push(
                        [ 
                            { text: '', colSpan: 4, border: [false, false, false, false], style: 'tableDetailLeft' },
                            { text: '', border: [false, false, false, false], style: 'tableDetailLeft' },
                            { text: '', border: [false, false, false, false], style: 'tableDetailRight' },
                            { text: '', border: [false, false, false, false], style: 'tableDetailRight' },
                            { text: `SUBTOTAL ${currencySymbol}`, border: [true, true, true, true], style: 'tableDetailLeft' },
                            { text: String.formatNumber.new(purchase.topgrava), border: [true, true, true, true], style: 'tableDetailRight' }
                        ]
                    );
                    tableBody.push(
                        [ 
                            { text: '', colSpan: 4, border: [false, false, false, false], style: 'tableDetailLeft' },
                            { text: '', border: [false, false, false, false], style: 'tableDetailLeft' },
                            { text: '', border: [false, false, false, false], style: 'tableDetailRight' },
                            { text: '', border: [false, false, false, false], style: 'tableDetailRight' },
                            { text: `I.G.V. ${currencySymbol}`, border: [true, true, true, true], style: 'tableDetailLeft' },
                            { text: String.formatNumber.new(purchase.totaltax), border: [true, true, true, true], style: 'tableDetailRight' }
                        ]
                    );
                    tableBody.push(
                        [ 
                            { text: '', colSpan: 4, border: [false, false, false, false], style: 'tableDetailLeft' },
                            { text: '', border: [false, false, false, false], style: 'tableDetailLeft' },
                            { text: '', border: [false, false, false, false], style: 'tableDetailRight' },
                            { text: '', border: [false, false, false, false], style: 'tableDetailRight' },
                            { text: `TOTAL ${currencySymbol}`, border: [true, true, true, true], style: 'tableDetailLeft' },
                            { text: String.formatNumber.new(purchase.totalamo), border: [true, true, true, true], style: 'tableDetailRight' }
                        ]
                    );
                    purchRead.push(`${purchase.serialnu}-${purchase.documnum}`);
                }
                
                supplierRead.push(row.docsuppl);
            }
           
        }

        pdfObject.content[0].table.body = tableBody;
        if(data.length === 0) pdfObject.content[0] = {};

        pdfMake.fonts = {
            Roboto: {
                normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
                bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
                italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
                bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
              },
        }
        const pdfDocGenerator = pdfMake.createPdf(pdfObject);
        pdfDocGenerator.getDataUrl((data: any) => {
            this.setState({
                base64Pdf: data
            })
        });

    }

    onGenerateExcel = () => {
        try {
            const data = this.props.accountantReducers.onlyOne;
            const { initmonth, endmonth, year } = this.state;
            const { dashboardReducers } = this.props;
            const mesInicio = meses.find((x: any) => x.key === initmonth).value;
            const mesFin = meses.find((x: any) => x.key === endmonth).value;
            const company = (JSON.parse(localStorage.getItem('tables')).companys.find((x: any) => x.taxident === dashboardReducers.currentCompany).bussname as string).toUpperCase()

            const tableBody: any = [
                {
                    A: company,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null
                },
                {
                    A: `FACTURAS DE COMPRAS DE ${mesInicio.toUpperCase()} A ${mesFin.toUpperCase()}`,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null
                },
                {
                    A: null,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null
                }
            ]

            const supplierRead: any = [];
            const cutRows: Array<number> = [];
            const cutTotalRows: Array<number> = [];

            for (const row of data) {
                if (!supplierRead.includes(row.docsuppl)) {
                    tableBody.push(
                        { 
                            A: row.businame,
                            B: null,
                            C: null,
                            D: null,
                            E: null,
                            F: null,
                        }
                    );
                    tableBody.push(
                        { 
                            A: ' ',
                            B: '',
                            C: '',
                            D: '',
                            E: '',
                            F: ''
                        }
                    );
                    const purchases = data.filter(x => x.docsuppl === row.docsuppl);
                    const purchRead = [];
                    for (const purchase of purchases) {
                        if(purchRead.includes(`${purchase.serialnu}-${purchase.documnum}`)) continue;
                        tableBody.push(
                            { 
                                A: `FAC:${purchase.serialnu}-${purchase.documnum} EMISION:${moment(purchase.emitdate).format('DDMMYYYY')}`,
                                B: null,
                                C: null,
                                D: null,
                                E: null,
                                F: null
                            }
                        );
                        const details = purchases.filter(x => x.serialnu === purchase.serialnu && x.documnum === purchase.documnum);
                        let nroItem = 1;
                        for (const detail of details) {
                            tableBody.push(
                                { 
                                    A: nroItem,
                                    B: '',
                                    C: detail.prodname,
                                    D: String.formatNumber.new(detail.quantity),
                                    E: String.formatNumber.new(detail.unitpric),
                                    F: String.formatNumber.new(detail.amototal)
                                }
                            );
                            cutRows.push(tableBody.length);
                            nroItem++;
                        }
                        const currencySymbol = purchase.currency === '00000014' ? 'S/.' : '$';
                        tableBody.push(
                            { 
                                A: '',
                                B: '',
                                C: '',
                                D: '',
                                E: `SUBTOTAL ${currencySymbol}`,
                                F: String.formatNumber.new(purchase.topgrava)
                            }
                        );
                        cutTotalRows.push(tableBody.length);
                        tableBody.push(
                            { 
                                A: '',
                                B: '',
                                C: '',
                                D: '',
                                E: `I.G.V. ${currencySymbol}`,
                                F: String.formatNumber.new(purchase.totaltax)
                            }
                        );
                        cutTotalRows.push(tableBody.length);
                        tableBody.push(
                            { 
                                A: '',
                                B: '',
                                C: '',
                                D: '',
                                E: `TOTAL ${currencySymbol}`,
                                F: String.formatNumber.new(purchase.totalamo)
                            }
                        );
                        cutTotalRows.push(tableBody.length);
                        purchRead.push(`${purchase.serialnu}-${purchase.documnum}`);
                    }
                    
                    supplierRead.push(row.docsuppl);
                }
            
            }

            const header = [
                'A',
                'B',
                'C',
                'D',
                'E',
                'F'
            ];

            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(tableBody, {header, skipHeader: true});
            ws["!cols"] = [
                {wpx:40},{wpx:80},{wpx:200},{wpx:80},{wpx:80},{wpx:80}
            ];
            ws['!rows'] = [
                {hpx: 18},{hpx: 16},{hpx: 16}
            ];

            const keys = Object.keys(ws);
            const rowsCount = tableBody.length;

            for (const iterator of keys) {
                if(!isNaN(parseInt(ws[iterator].v)) && !iterator.match(/^(A|B|C)[0-9]+$/g)) {
                    ws[iterator].v = ws[iterator].v.replace(/,/g, '');
                    ws[iterator].t = 'n';
                    ws[iterator].z = '#,##0.00';
                }
                if(iterator !== '!ref' && iterator !== '!cols' && iterator !== '!rows') {
                    ws[iterator].s = {
                        font: {
                            name: 'Tahoma',
                            sz: 8
                        }
                    }
                    const row = +(iterator.match(/\d+/)[0]);
                    if(cutRows.includes(row)) {
                        ws[iterator].s.border = {
                            ...ws[iterator].s.border,
                            bottom: { style: 'thin', color: 'black'},
                            top: { style: 'thin', color: 'black'},
                            left: { style: 'thin', color: 'black'},
                            right: { style: 'thin', color: 'black'}
                        }
                    }
                    else if(cutTotalRows.includes(row) && !iterator.match(/^(A|B|C|D)[0-9]+$/g)) {
                        ws[iterator].s.border = {
                            ...ws[iterator].s.border,
                            bottom: { style: 'thin', color: 'black'},
                            top: { style: 'thin', color: 'black'},
                            left: { style: 'thin', color: 'black'},
                            right: { style: 'thin', color: 'black'}
                        }
                    }
                }
            }

            for (let index = 3; index <= rowsCount; index++) {
                ws['!rows'].push({hpx: 12})
            }

            ws['A1'].s = {font:{name: 'Tahoma', sz: 8, bold: true}}
            ws['A2'].s = {font:{name: 'Tahoma', sz: 15, bold: true}, alignment: { wrapText: true, vertical: 'center', horizontal: 'center'}}
            ws['A3'].s = {font:{name: 'Tahoma', sz: 8, bold: true}}

            // for (const iterator of header) {
            //     const key1 = iterator + "4";
            //     ws[key1].s = {
            //         ...ws[key1].s, 
            //         alignment: { wrapText: true, vertical: 'center', horizontal: 'center'},
            //         border: { 
            //             top: { style: 'thin', color: 'black'},
            //             bottom: { style: 'thin', color: 'black'},
            //             left: { style: 'thin', color: 'black'},
            //             right: { style: 'thin', color: 'black'}
            //         } 
            //     }
            // };

            ws["!merges"] = [
                { s: { r: 1, c: 0 }, e: { r: 1, c: 5 } },
            ];

            XLSX.utils.book_append_sheet(wb, ws, "Detalle Compras");

            var wbout = XLSX.write(wb, {bookType:'xlsx', type: 'binary', cellStyles: true});

            const s2ab = function(s) {
                var buf = new ArrayBuffer(s.length);
                var view = new Uint8Array(buf);
                for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
                return buf;
            }

            saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), `detalle-compras-proveedor-${year}-${initmonth}-${endmonth}.xlsx`);

        } catch(err) {
            console.log(err);
        }
    }

    render() {

        const { title, year, initmonth, endmonth, ruc, product, base64Pdf } = this.state;
        const { loading, failed, failedTitle, failedContent } = this.props.accountantReducers;
        const { classes } = this.props;

        return (
            <div style={{overflow: 'auto', height: '100%'}}>
                <Paper className='form__container'>
                    <Typography variant='h5'>{ title }</Typography>
                    <br/>
                    { failed && <MessageBox title={failedTitle} content={failedContent}/> }
                    <div style={{ display: 'flex' }}>
                        <form noValidate autoComplete="off" style={{ flex: 1, paddingRight: '15px', paddingTop: '2em' }}>
                            <Grid container spacing={2} >
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_año">Año</InputLabel>
                                            <Select
                                                labelId="lbl_año"
                                                id="year"
                                                className='select'
                                                label="Año"
                                                fullWidth
                                                value={ year }
                                                onChange={(e: any) => this.onChangeRoot({'year': e.target.value})}
                                            >
                                                <MenuItem value='2030'>2030</MenuItem>
                                                <MenuItem value='2029'>2029</MenuItem>
                                                <MenuItem value='2028'>2028</MenuItem>
                                                <MenuItem value='2027'>2027</MenuItem>
                                                <MenuItem value='2026'>2026</MenuItem>
                                                <MenuItem value='2025'>2025</MenuItem>
                                                <MenuItem value='2026'>2026</MenuItem>
                                                <MenuItem value='2025'>2025</MenuItem>
                                                <MenuItem value='2024'>2024</MenuItem>
                                                <MenuItem value='2023'>2023</MenuItem>
                                                <MenuItem value='2022'>2022</MenuItem>
                                                <MenuItem value='2021'>2021</MenuItem>
                                                <MenuItem value='2020'>2020</MenuItem>
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_mesinicio">Mes inicio</InputLabel>
                                            <Select
                                                labelId="lbl_mesinicio"
                                                id="initmonth"
                                                className='select'
                                                label="Mes inicio"
                                                fullWidth
                                                value={ initmonth }
                                                onChange={(e: any) => this.onChangeRoot({'initmonth': e.target.value})}
                                            >
                                                {
                                                    meses.map((x: any) => 
                                                        <MenuItem value={x.key} key={x.key}>{x.value}</MenuItem>
                                                    )
                                                }
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_mesfin">Mes fin</InputLabel>
                                            <Select
                                                labelId="lbl_mesfin"
                                                id="endmonth"
                                                className='select'
                                                label="Mes fin"
                                                fullWidth
                                                value={ endmonth }
                                                onChange={(e: any) => this.onChangeRoot({'endmonth': e.target.value})}
                                            >
                                                {
                                                    meses.map((x: any) => 
                                                        <MenuItem value={x.key} key={x.key}>{x.value}</MenuItem>
                                                    )
                                                }
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField fullWidth id="ruc" label="R.U.C. Proveedor" variant="outlined" value={ ruc } onChange={(e: any) => this.onChangeRoot({'ruc': e.target.value})}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField fullWidth id="product" label="Descripción del producto" variant="outlined" value={ product } onChange={(e: any) => this.onChangeRoot({'product': e.target.value})}/>
                                </Grid>
                                <Grid item xs={12} style={{ paddingTop: '50px' }}>
                                    <Button variant="contained" fullWidth color="primary" onClick={() => { this.props.purchaseDetailSupplierRequest(officode, year, initmonth, endmonth, ruc, product, this.onGeneratePdf) }}>
                                        Generar Reporte PDF
                                    </Button>
                                </Grid>
                                <Grid item xs={12} style={{ paddingTop: '10px' }}>
                                    <Button variant="contained" fullWidth color="inherit" onClick={() => { this.props.purchaseDetailSupplierRequest(officode, year, initmonth, endmonth, ruc, product, this.onGenerateExcel) }}>
                                        Generar Reporte EXCEL
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                        <PdfView data={base64Pdf} style={{ flex: 2 }}/>
                    </div>
                    <br/>
                    {/* <FormFooter onCancel={ this.onCancel } onSubmit={ this.onSubmit }/> */}
                </Paper>
                <ScreenLoader enabled={loading}/>
            </div>
        );
    }
}

const accountantReducers = 'accountantReducers';
const dashboardReducers = 'dashboardReducers';

const mapStateToProps = (state: any) => ({
    accountantReducers: state.get(accountantReducers),
    dashboardReducers: state.get(dashboardReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        purchaseDetailSupplierRequest
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(ReportPurchaseDetailSupplier));