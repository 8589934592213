import { 
    AFP_LIST_REQUEST,
    COMMISSIS_DELETE_REQUEST, 
    COMMISSIS_GET_ALL_RECORDS_REQUEST, 
    COMMISSIS_INIT_UPDATE_FORM_REQUEST, 
    COMMISSIS_INSERT_REQUEST, 
    COMMISSIS_UPDATE_REQUEST, 
    CONCEPTS_DELETE_REQUEST, 
    CONCEPTS_GET_ALL_RECORDS_REQUEST, 
    CONCEPTS_INIT_FORM_REQUEST, 
    CONCEPTS_INIT_UPDATE_FORM_REQUEST, 
    CONCEPTS_INSERT_REQUEST, 
    CONCEPTS_UPDATE_REQUEST, 
    CTS_DEPOSITS_REQUEST, 
    EMPLOYES_DELETE_REQUEST, 
    EMPLOYES_GET_ALL_RECORDS_REQUEST, 
    EMPLOYES_INIT_FORM_REQUEST, 
    EMPLOYES_INIT_UPDATE_FORM_REQUEST, 
    EMPLOYES_INSERT_REQUEST, 
    EMPLOYES_UPDATE_REQUEST, 
    EMPLOYE_PAYROLL_ACCUMULATED_REQUEST, 
    EMPLOYE_PAYROLL_REQUEST, 
    EMPLOYE_VOUCHER_ALL_REQUEST, 
    EMPLOYE_VOUCHER_REQUEST, 
    LABORER_PAYROLL_ACCUMULATED_REQUEST, 
    LABORER_PAYROLL_REQUEST, 
    PAYROLL_DELETE_REQUEST, 
    PAYROLL_GET_ALL_RECORDS_REQUEST, 
    PAYROLL_INIT_FORM_REQUEST, 
    PAYROLL_INIT_UPDATE_FORM_REQUEST, 
    PAYROLL_INSERT_REQUEST, 
    PAYROLL_UPDATE_REQUEST, 
    PDT_STAFF_REQUEST, 
    WEEKCALES_DELETE_REQUEST, 
    WEEKCALES_GET_ALL_RECORDS_REQUEST,
    WEEKCALES_INIT_UPDATE_FORM_REQUEST,
    WEEKCALES_INSERT_REQUEST,
    WEEKCALES_UPDATE_REQUEST
} from "penta-redux/constants/humanResourcesConstants";

import {
    call, fork, put, take, takeEvery, all
} from 'redux-saga/effects';
import history from '../../utils/history';
import pipelines from 'services';
import { commissiEntity, conceptsEntity, employeeEntity, emplpayrEntity, weekcaleEntity } from "models/humanResourcesModels";
import { 
    afpListRequestFailed,
    afpListRequestSuccess,
    commissisDeleteRequestFailed, 
    commissisGetAllRecordsRequestFailed, 
    commissisGetAllRecordsRequestSuccess, 
    commissisInitUpdateFormRequestFailed, 
    commissisInitUpdateFormRequestSuccess, 
    commissisInsertRequestFailed, 
    commissisInsertRequestSuccess, 
    commissisUpdateRequestFailed, 
    conceptsDeleteRequestFailed, 
    conceptsGetAllRecordsRequestFailed, 
    conceptsGetAllRecordsRequestSuccess, 
    conceptsInitFormRequestFailed, 
    conceptsInitFormRequestSuccess, 
    conceptsInitUpdateFormRequestFailed, 
    conceptsInitUpdateFormRequestSuccess, 
    conceptsInsertRequestFailed, 
    conceptsInsertRequestSuccess, 
    conceptsUpdateRequestFailed, 
    ctsDepositsRequestFailed, 
    ctsDepositsRequestSuccess, 
    employePayrollAccumulatedRequestFailed, 
    employePayrollAccumulatedRequestSuccess, 
    employePayrollRequestFailed, 
    employePayrollRequestSuccess, 
    employesDeleteRequestFailed, 
    employesGetAllRecordsRequestFailed, 
    employesGetAllRecordsRequestSuccess, 
    employesInitFormRequestFailed, 
    employesInitFormRequestSuccess, 
    employesInitUpdateFormRequestFailed, 
    employesInitUpdateFormRequestSuccess, 
    employesInsertRequestFailed, 
    employesInsertRequestSuccess, 
    employesUpdateRequestFailed, 
    employeVoucherAllRequestFailed, 
    employeVoucherAllRequestSuccess, 
    employeVoucherRequestFailed, 
    employeVoucherRequestSuccess, 
    laborerPayrollAccumulatedRequestFailed, 
    laborerPayrollAccumulatedRequestSuccess, 
    laborerPayrollRequestFailed, 
    laborerPayrollRequestSuccess, 
    payrollDeleteRequestFailed, 
    payrollGetAllRecordsRequestFailed, 
    payrollGetAllRecordsRequestSuccess, 
    payrollInitFormRequestFailed, 
    payrollInitFormRequestSuccess, 
    payrollInitUpdateFormRequestFailed, 
    payrollInitUpdateFormRequestSuccess, 
    payrollInsertRequestFailed, 
    payrollInsertRequestSuccess, 
    payrollUpdateRequestFailed, 
    payrollUpdateRequestSuccess, 
    pdtStaffRequestFailed, 
    pdtStaffRequestSuccess, 
    weekcalesDeleteRequestFailed, 
    weekcalesGetAllRecordsRequestFailed, 
    weekcalesGetAllRecordsRequestSuccess, 
    weekcalesInitUpdateFormRequestFailed, 
    weekcalesInitUpdateFormRequestSuccess, 
    weekcalesInsertRequestFailed,
    weekcalesInsertRequestSuccess,
    weekcalesUpdateRequestFailed
} from "penta-redux/actions/humanResourcesActions";
import { locationsGetProvincesSuccess } from 'penta-redux/actions/generalActions';
import { afpList } from "services/pipelines/humanResourcesPipeline";

//#region COMMISSIS

function* commissisInitUpdateFormSaga(action: any) {
    try {
        const data = (yield call(pipelines.humanResourcesPipeline.commissisInitUpdateForm, action.commyear, action.commmont)).data;
        yield put(commissisInitUpdateFormRequestSuccess(data.data.tables, data.data.onlyOne));
        yield call(action.callback, data.data.onlyOne);
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(commissisInitUpdateFormRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* commissisGetAllRecordsSaga(action: any) {
    try {
        const data = (yield call(pipelines.humanResourcesPipeline.commissisGetAllRecords, action.body)).data;
        yield put(commissisGetAllRecordsRequestSuccess(data.data.list, data.data.total));
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(commissisGetAllRecordsRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* commissisInsertSaga(action: any) {
    try {
        yield call(pipelines.humanResourcesPipeline.commissisInsert, action.body);
        if (action.callback !== null) {
            yield call(action.callback);
            yield put(commissisInsertRequestSuccess());
        }
        else
            yield history.push('/papp/hhrr/comisiones');
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(commissisInsertRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* commissisUpdateSaga(action: any) {
    try {
        const data = (yield call(pipelines.humanResourcesPipeline.commissisUpdate, (action.body as commissiEntity).commyear, (action.body as commissiEntity).commmont, action.body)).data;
        yield history.push('/papp/hhrr/comisiones');
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(commissisUpdateRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* commissisDeleteSaga(action: any) {
    try {
        const data = (yield call(pipelines.humanResourcesPipeline.commissisDelete, action.commyears, action.commmonts)).data;
        yield put({ type: COMMISSIS_GET_ALL_RECORDS_REQUEST, body: {} });
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(commissisDeleteRequestFailed(`Error: ${data.code}`, data.message));
    }
}

//#endregion

//#region WEEKCALES

function* weekcalesInitUpdateFormSaga(action: any) {
    try {
        const data = (yield call(pipelines.humanResourcesPipeline.weekcalesInitUpdateForm, action.yearnumb, action.weeknumb)).data;
        yield put(weekcalesInitUpdateFormRequestSuccess(data.data.tables, data.data.onlyOne));
        yield call(action.callback, data.data.onlyOne);
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(weekcalesInitUpdateFormRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* weekcalesGetAllRecordsSaga(action: any) {
    try {
        const data = (yield call(pipelines.humanResourcesPipeline.weekcalesGetAllRecords, action.body)).data;
        const list = data.data.list.map((x: any) => {
            return { ...x, essaludv: x.essaludv ? 'Si' : 'No', quintact: x.quintact ? 'Si' : 'No' }
        });
        yield put(weekcalesGetAllRecordsRequestSuccess(list, data.data.total));
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(weekcalesGetAllRecordsRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* weekcalesInsertSaga(action: any) {
    try {
        yield call(pipelines.humanResourcesPipeline.weekcalesInsert, action.body);
        if (action.callback !== null) {
            yield call(action.callback);
            yield put(weekcalesInsertRequestSuccess());
        }
        else
            yield history.push('/papp/hhrr/semanas');
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(weekcalesInsertRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* weekcalesUpdateSaga(action: any) {
    try {
        const data = (yield call(pipelines.humanResourcesPipeline.weekcalesUpdate, (action.body as weekcaleEntity).yearnumb, (action.body as weekcaleEntity).weeknumb, action.body)).data;
        yield history.push('/papp/hhrr/semanas');
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(weekcalesUpdateRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* weekcalesDeleteSaga(action: any) {
    try {
        const data = (yield call(pipelines.humanResourcesPipeline.weekcalesDelete, action.yearnumbs, action.weeknumbs)).data;
        yield put({ type: WEEKCALES_GET_ALL_RECORDS_REQUEST, body: {} });
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(weekcalesDeleteRequestFailed(`Error: ${data.code}`, data.message));
    }
}

//#endregion

//#region EMPLOYES

function* employesInitFormSaga(action: any) {
    try {
        const data = (yield call(pipelines.humanResourcesPipeline.employesInitForm)).data;
        yield put(employesInitFormRequestSuccess(data.data.tables));
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(employesInitFormRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* employesInitUpdateFormSaga(action: any) {
    try {
        const data = (yield call(pipelines.humanResourcesPipeline.employesInitUpdateForm, action.emplcode)).data;
        yield put(employesInitUpdateFormRequestSuccess(data.data.tables, data.data.onlyOne));
        yield put(locationsGetProvincesSuccess(data.data.tables));
        yield put(locationsGetProvincesSuccess(data.data.tables));
        yield call(action.callback, data.data.onlyOne, data.data.tables.provincias[0].ploccode, data.data.tables.distritos[0].ploccode);
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(employesInitUpdateFormRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* employesGetAllRecordsSaga(action: any) {
    try {
        const data = (yield call(pipelines.humanResourcesPipeline.employesGetAllRecords, action.body)).data;
        yield put(employesGetAllRecordsRequestSuccess(data.data.list, data.data.total));
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(employesGetAllRecordsRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* employesInsertSaga(action: any) {
    try {
        yield call(pipelines.humanResourcesPipeline.employesInsert, action.body);
        if (action.callback !== null) {
            yield call(action.callback);
            yield put(employesInsertRequestSuccess());
        }
        else
            yield history.push('/papp/hhrr/empleados');
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(employesInsertRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* employesUpdateSaga(action: any) {
    try {
        const data = (yield call(pipelines.humanResourcesPipeline.employesUpdate, (action.body as employeeEntity).emplcode, action.body)).data;
        yield history.push('/papp/hhrr/empleados');
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(employesUpdateRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* employesDeleteSaga(action: any) {
    try {
        const data = (yield call(pipelines.humanResourcesPipeline.employesDelete, action.emplcodes)).data;
        yield put({ type: EMPLOYES_GET_ALL_RECORDS_REQUEST, body: {} });
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(employesDeleteRequestFailed(`Error: ${data.code}`, data.message));
    }
}

//#endregion

//#region CONCEPTS

function* conceptsInitFormSaga(action: any) {
    try {
        const data = (yield call(pipelines.humanResourcesPipeline.conceptsInitForm, action.fiscyear)).data;
        yield put(conceptsInitFormRequestSuccess(data.data.tables));
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(conceptsInitFormRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* conceptsInitUpdateFormSaga(action: any) {
    try {
        const data = (yield call(pipelines.humanResourcesPipeline.conceptsInitUpdateForm, action.conccode, action.typesala, action.payrtype)).data;
        yield put(conceptsInitUpdateFormRequestSuccess(data.data.tables, data.data.onlyOne));
        yield call(action.callback, data.data.onlyOne);
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(conceptsInitUpdateFormRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* conceptsGetAllRecordsSaga(action: any) {
    try {
        const data = (yield call(pipelines.humanResourcesPipeline.conceptsGetAllRecords, action.body)).data;
        yield put(conceptsGetAllRecordsRequestSuccess(data.data.list, data.data.total));
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(conceptsGetAllRecordsRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* conceptsInsertSaga(action: any) {
    try {
        yield call(pipelines.humanResourcesPipeline.conceptsInsert, action.body);
        if (action.callback !== null) {
            yield call(action.callback);
            yield put(conceptsInsertRequestSuccess());
        }
        else
            yield history.push('/papp/hhrr/conceptos');
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(conceptsInsertRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* conceptsUpdateSaga(action: any) {
    try {
        const data = (yield call(pipelines.humanResourcesPipeline.conceptsUpdate, (action.body as conceptsEntity).conccode, action.body)).data;
        yield history.push('/papp/hhrr/conceptos');
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(conceptsUpdateRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* conceptsDeleteSaga(action: any) {
    try {
        const data = (yield call(pipelines.humanResourcesPipeline.conceptsDelete, action.conccodes, action.typesalas, action.payrtypes)).data;
        yield put({ type: CONCEPTS_GET_ALL_RECORDS_REQUEST, body: {} });
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(conceptsDeleteRequestFailed(`Error: ${data.code}`, data.message));
    }
}

//#endregion

//#region PAYROLL

function* payrollInitFormSaga(action: any) {
    try {
        const data = (yield call(pipelines.humanResourcesPipeline.payrollInitForm, action.payryear)).data;
        yield put(payrollInitFormRequestSuccess(data.data.tables));
        yield call(action.callback);
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(payrollInitFormRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* payrollInitUpdateFormSaga(action: any) {
    try {
        const data = (yield call(pipelines.humanResourcesPipeline.payrollInitUpdateForm, action.emplcode, action.payryear, action.payrweek, action.typesala)).data;
        yield put(payrollInitUpdateFormRequestSuccess(data.data.tables, data.data.onlyOne));
        yield call(action.callback, data.data.onlyOne);
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(payrollInitUpdateFormRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* payrollGetAllRecordsSaga(action: any) {
    try {
        const data = (yield call(pipelines.humanResourcesPipeline.payrollGetAllRecords, action.body)).data;
        yield put(payrollGetAllRecordsRequestSuccess(data.data.list, data.data.total));
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(payrollGetAllRecordsRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* payrollInsertSaga(action: any) {
    try {
        yield call(pipelines.humanResourcesPipeline.payrollInsert, action.body);
        if (action.callback !== null) {
            yield call(action.callback);
            yield put(payrollInsertRequestSuccess());
        }
        else
            yield history.push('/papp/hhrr/planillas');
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(payrollInsertRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* payrollUpdateSaga(action: any) {
    try {
        const data = (yield call(pipelines.humanResourcesPipeline.payrollUpdate, action.body.emplcode, action.body.payryear, action.body.payrweek, action.body)).data;
        if (action.callback !== null) {
            yield history.push('/papp/hhrr/planillas/agregar');
        }
        else
            yield history.push('/papp/hhrr/planillas');
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(payrollUpdateRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* payrollDeleteSaga(action: any) {
    try {
        const data = (yield call(pipelines.humanResourcesPipeline.payrollDelete, action.emplcodes, action.payryears, action.payrweeks, action.typesalas)).data;
        yield put({ type: PAYROLL_GET_ALL_RECORDS_REQUEST, body: {} });
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(payrollDeleteRequestFailed(`Error: ${data.code}`, data.message));
    }
}

//#endregion

//#region REPORTS

function* employePayrollSaga(action: any) {
    try {
        const data = (yield call(pipelines.humanResourcesPipeline.employePayroll, action.year, action.month, action.typesala)).data;
        yield put(employePayrollRequestSuccess(data.data.onlyOne));
        yield call(action.callback);
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(employePayrollRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* laborerPayrollSaga(action: any) {
    try {
        const data = (yield call(pipelines.humanResourcesPipeline.laborerPayroll, action.year, action.month, action.typesala)).data;
        yield put(laborerPayrollRequestSuccess(data.data.onlyOne));
        yield call(action.callback);
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(laborerPayrollRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* laborerPayrollAccumulatedSaga(action: any) {
    try {
        const data = (yield call(pipelines.humanResourcesPipeline.laborerPayrollAccumulated, action.year, action.month, action.endMonth, action.all)).data;
        yield put(laborerPayrollAccumulatedRequestSuccess(data.data.onlyOne));
        yield call(action.callback);
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(laborerPayrollAccumulatedRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* employePayrollAccumulatedSaga(action: any) {
    try {
        const data = (yield call(pipelines.humanResourcesPipeline.employePayrollAccumulated, action.year, action.month, action.endMonth, action.all)).data;
        yield put(employePayrollAccumulatedRequestSuccess(data.data.onlyOne));
        yield call(action.callback);
    } catch (error) {
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(employePayrollAccumulatedRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* employeVoucherSaga(action: any) {
    try {
        const data = (yield call(pipelines.humanResourcesPipeline.employeVoucher, action.emplcode, action.year, action.month, action.week, action.typesala, action.payrtype)).data;
        yield put(employeVoucherRequestSuccess(data.data.onlyOne));
        yield call(action.callback);
    } catch (error) {
        console.log(error);
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(employeVoucherRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* employeVoucherAllSaga(action: any) {
    try {
        const data = (yield call(pipelines.humanResourcesPipeline.employeVoucherAll, action.year, action.month, action.week, action.typesala, action.payrtype)).data;
        yield put(employeVoucherAllRequestSuccess(data.data.onlyOne));
        yield call(action.callback);
    } catch (error) {
        console.log(error);
        const err = error.response;
        let data;
        if(err !== undefined) data = err.data;
        else data = { code: '0x0', message: "Error al establecer una conexion con el servidor" };
        yield put(employeVoucherAllRequestFailed(`Error: ${data.code}`, data.message));
    }
}

function* afpListSaga(action: any) {
    try {
        const data = (yield call(pipelines.humanResourcesPipeline.afpList, action.officode, action.payryear, action.payrmont, action.payrtype)).data;
        yield put(afpListRequestSuccess(data.data.onlyOne));
        yield call(action.callback);
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(afpListRequestFailed(`Error: ${err.code}`, err.message));
    }
}

function* pdtStaffSaga(action: any) {
    try {
        const data = (yield call(pipelines.humanResourcesPipeline.pdtStaff, action.year, action.month)).data;
        yield put(pdtStaffRequestSuccess(data.data.onlyOne));
        yield call(action.callback);
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(pdtStaffRequestFailed(`Error: ${err.code}`, err.message));
    }
}

function* ctsDepositsSaga(action: any) {
    try {
        const data = (yield call(pipelines.humanResourcesPipeline.ctsDeposits, action.officode)).data;
        yield put(ctsDepositsRequestSuccess(data.data.onlyOne));
        yield call(action.callback);
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(ctsDepositsRequestFailed(`Error: ${err.code}`, err.message));
    }
}

//#endregion

function* humanResourcesRootSaga() {
    yield all([
        takeEvery(COMMISSIS_INIT_UPDATE_FORM_REQUEST, commissisInitUpdateFormSaga),
        takeEvery(COMMISSIS_GET_ALL_RECORDS_REQUEST, commissisGetAllRecordsSaga),
        takeEvery(COMMISSIS_INSERT_REQUEST, commissisInsertSaga),
        takeEvery(COMMISSIS_UPDATE_REQUEST, commissisUpdateSaga),
        takeEvery(COMMISSIS_DELETE_REQUEST, commissisDeleteSaga),
        takeEvery(WEEKCALES_INIT_UPDATE_FORM_REQUEST, weekcalesInitUpdateFormSaga),
        takeEvery(WEEKCALES_GET_ALL_RECORDS_REQUEST, weekcalesGetAllRecordsSaga),
        takeEvery(WEEKCALES_INSERT_REQUEST, weekcalesInsertSaga),
        takeEvery(WEEKCALES_UPDATE_REQUEST, weekcalesUpdateSaga),
        takeEvery(WEEKCALES_DELETE_REQUEST, weekcalesDeleteSaga),
        takeEvery(EMPLOYES_INIT_FORM_REQUEST, employesInitFormSaga),
        takeEvery(EMPLOYES_INIT_UPDATE_FORM_REQUEST, employesInitUpdateFormSaga),
        takeEvery(EMPLOYES_GET_ALL_RECORDS_REQUEST, employesGetAllRecordsSaga),
        takeEvery(EMPLOYES_INSERT_REQUEST, employesInsertSaga),
        takeEvery(EMPLOYES_UPDATE_REQUEST, employesUpdateSaga),
        takeEvery(EMPLOYES_DELETE_REQUEST, employesDeleteSaga),
        takeEvery(CONCEPTS_INIT_FORM_REQUEST, conceptsInitFormSaga),
        takeEvery(CONCEPTS_INIT_UPDATE_FORM_REQUEST, conceptsInitUpdateFormSaga),
        takeEvery(CONCEPTS_GET_ALL_RECORDS_REQUEST, conceptsGetAllRecordsSaga),
        takeEvery(CONCEPTS_INSERT_REQUEST, conceptsInsertSaga),
        takeEvery(CONCEPTS_UPDATE_REQUEST, conceptsUpdateSaga),
        takeEvery(CONCEPTS_DELETE_REQUEST, conceptsDeleteSaga),
        takeEvery(PAYROLL_INIT_FORM_REQUEST, payrollInitFormSaga),
        takeEvery(PAYROLL_INIT_UPDATE_FORM_REQUEST, payrollInitUpdateFormSaga),
        takeEvery(PAYROLL_GET_ALL_RECORDS_REQUEST, payrollGetAllRecordsSaga),
        takeEvery(PAYROLL_INSERT_REQUEST, payrollInsertSaga),
        takeEvery(PAYROLL_UPDATE_REQUEST, payrollUpdateSaga),
        takeEvery(PAYROLL_DELETE_REQUEST, payrollDeleteSaga),
        takeEvery(EMPLOYE_PAYROLL_REQUEST, employePayrollSaga),
        takeEvery(LABORER_PAYROLL_ACCUMULATED_REQUEST, laborerPayrollAccumulatedSaga),
        takeEvery(EMPLOYE_PAYROLL_ACCUMULATED_REQUEST, employePayrollAccumulatedSaga),
        takeEvery(LABORER_PAYROLL_REQUEST, laborerPayrollSaga),
        takeEvery(EMPLOYE_VOUCHER_REQUEST, employeVoucherSaga),
        takeEvery(EMPLOYE_VOUCHER_ALL_REQUEST, employeVoucherAllSaga),
        takeEvery(AFP_LIST_REQUEST, afpListSaga),
        takeEvery(PDT_STAFF_REQUEST, pdtStaffSaga),
        takeEvery(CTS_DEPOSITS_REQUEST, ctsDepositsSaga)
    ]);
}
  
const humanResourcesSagas = [
    fork(humanResourcesRootSaga),
];
  
export default humanResourcesSagas;