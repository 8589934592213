import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { authLogoutRequest } from 'redux/actions/authActions';
import { dashboardSetWorkerContext } from 'redux/actions/dashboardActions';
import {createUseStyles} from 'react-jss';
import ProfileImage from 'penta-assets/images/a-profile-default.png';
import Modal from 'penta-components/base/modal';
import Button from 'penta-components/controls/button';
import TextInput from 'penta-components/controls/input';
import Select from 'penta-components/controls/select';

const useStyles =  createUseStyles({
    container: {
        'position': 'relative'
    },
    profileDiv: {
        'display': 'flex',
        'alignItems': 'center',
        'cursor': 'pointer'
    },
    profileIcon: {
        'width': '2.188rem',
        'height': '2.188rem',
        'borderRadius': '50%'
    },
    menuChild: {},
    menuContainer: {
        'position': 'absolute',
        'backgroundColor': 'white',
        right: 0,
        marginTop: '0.5rem',
        minWidth: '10rem',
        zIndex: 1000,
        boxShadow: '0.3rem 0.5rem 1rem 0px #b7b7b7',
        '& $menuChild': {
            borderBottom: '0.1rem solid #e8e8e8',
            padding: '0.875rem 1rem',
            cursor: 'pointer',
            fontSize: '0.75rem',
            fontFamily: '"Work Sans", sans-serif',
            fontWeight: '400',
            '&:hover': {
                backgroundColor: '#e8e8e8'
            }
        }
    }
});

interface Profile {
    authLogoutRequest: typeof authLogoutRequest
    dashboardSetWorkerContext: typeof dashboardSetWorkerContext
    authReducers: any
    dashboardReducers: any
}

const Profile = (props: Profile) => {
    const classes = useStyles();
    const [showMenu, setShowMenu] = React.useState(false);
    const [showModal, setShowModal] = React.useState(false);
    const [listCompany, setListCompany] = React.useState([]);
    const [listDivision, setListDivision] = React.useState([]);
    const [listBussofic, setListBussofic] = React.useState([]);
    const [company, setCompany] = React.useState('');
    const [division, setDivision] = React.useState('');
    const [bussofic, setBussofic] = React.useState('');
    const [fiscyear, setFiscyear] = React.useState('2022');

    const onLogon = () => {
        props.authLogoutRequest();
    }

    const initChangeContext = () => {
        setShowMenu(false)
        setShowModal(true)
    }

    const onSubmit = () => {
        props.dashboardSetWorkerContext(company, division, bussofic, fiscyear, () => { setShowModal(false); window.location.reload(); });
    }

    useEffect(() => {
        const { authReducers } = props;
        const lst_division = authReducers.tables.companys.find((x: any) => x.taxident === company)?.lst_grl_division.map((x: any) => { return { id: x.divicode, name: x.diviname } }) || [];
        setListDivision(lst_division);
        setListBussofic([]);
    }, [company]);

    useEffect(() => {
        const { authReducers } = props;
        const lst_bussofic = authReducers.tables.companys.find((x: any) => x.taxident === company)?.lst_grl_division.find((x: any) => x.divicode === division)?.lst_grl_bussofic.map((x: any) => { return { id: x.officode, name: x.offiname } }) || [];
        setListBussofic(lst_bussofic);
    }, [division]);

    useEffect(() => {
        const { dashboardReducers } = props;
        setCompany(dashboardReducers.currentCompany);
        setDivision(dashboardReducers.currentDivision);
        setBussofic(dashboardReducers.currentBussOfic);
        setFiscyear(dashboardReducers.currentFiscyear);
    }, [showModal]);

    useEffect(() => {
        const { authReducers } = props;
        const lst_companys = authReducers.tables.companys.map((x: any) => {
            return { name: x.bussname, id: x.taxident }
        });
        setListCompany(lst_companys);
        setListDivision([]);
        setListBussofic([]);
    }, []);

    return (
        <>
            <div className={classes.container} tabIndex={0} onBlur={() => setShowMenu(false)}>
                <div className={classes.profileDiv} onClick={() => setShowMenu(!showMenu)}>
                    <img src={ProfileImage} className={classes.profileIcon} />
                </div>
                {
                    showMenu &&
                    <div className={classes.menuContainer}>
                        <div className={classes.menuChild}>Perfil</div>
                        <div className={classes.menuChild} onClick={initChangeContext}>Cambiar contexto</div>
                        <div className={classes.menuChild} onClick={onLogon}>Salir</div>
                    </div>
                }
            </div>
            {
                showModal &&
                <Modal 
                    title="Cambiar contexto"
                    onClose={() => setShowModal(false)}
                    footer={
                        <React.Fragment>
                            <Button type='bad' full={false} onClick={() => setShowModal(false)}>Cancelar</Button>
                            &nbsp;&nbsp;
                            <Button full={false} onClick={onSubmit}>Aceptar</Button>
                        </React.Fragment>
                    }
                >
                    <React.Fragment>
                        <Select label="Compañia" labelPosition="top" style={{marginBottom: '1rem'}} data={listCompany} value={company} onChange={(value) => { setCompany(value.id); setDivision(''); setBussofic(''); } }/>
                        <Select label="Division" labelPosition="top" style={{marginBottom: '1rem'}} data={listDivision} value={division} onChange={(value) => { setDivision(value.id); setBussofic(''); }}/>
                        <Select label="Oficina" labelPosition="top" style={{marginBottom: '1rem'}} data={listBussofic} value={bussofic} onChange={(value) => setBussofic(value.id)}/>
                        <Select label="Año" labelPosition="top" style={{marginBottom: '1rem'}} data={
                            [{id: '2024', name: '2024'}, {id: '2023', name: '2023'}, {id: '2022', name: '2022'}, {id: '2021', name: '2021'}, {id: '2020', name: '2020'}, {id: '2019', name: '2019'}]
                        } value={fiscyear} onChange={(value) => setFiscyear(value.id)}/>
                    </React.Fragment>
                </Modal>
            }
        </>
    )
}

const authReducers = 'authReducers';
const dashboardReducers = 'dashboardReducers';

const mapStateToProps = (state: any) => ({
    authReducers: state.get(authReducers),
    dashboardReducers: state.get(dashboardReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        authLogoutRequest,
        dashboardSetWorkerContext
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
