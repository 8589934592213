export const CATEGORYS_INIT_FORM_REQUEST = 'CATEGORYS_INIT_FORM_REQUEST';
export const CATEGORYS_INIT_FORM_REQUEST_SUCCESS = 'CATEGORYS_INIT_FORM_REQUEST_SUCCESS';
export const CATEGORYS_INIT_FORM_REQUEST_FAILED = 'CATEGORYS_INIT_FORM_REQUEST_FAILED';
export const CATEGORYS_INIT_UPDATE_FORM_REQUEST = 'CATEGORYS_INIT_UPDATE_FORM_REQUEST';
export const CATEGORYS_INIT_UPDATE_FORM_REQUEST_SUCCESS = 'CATEGORYS_INIT_UPDATE_FORM_REQUEST_SUCCESS';
export const CATEGORYS_INIT_UPDATE_FORM_REQUEST_FAILED = 'CATEGORYS_INIT_UPDATE_FORM_REQUEST_FAILED';
export const CATEGORYS_GET_ALL_RECORDS_REQUEST = 'CATEGORYS_GET_ALL_RECORDS_REQUEST'; 
export const CATEGORYS_GET_ALL_RECORDS_REQUEST_SUCCESS = 'CATEGORYS_GET_ALL_RECORDS_REQUEST_SUCCESS'; 
export const CATEGORYS_GET_ALL_RECORDS_REQUEST_FAILED = 'CATEGORYS_GET_ALL_RECORDS_REQUEST_FAILED';
export const CATEGORYS_INSERT_REQUEST = 'CATEGORYS_INSERT_REQUEST';
export const CATEGORYS_INSERT_REQUEST_SUCCESS = 'CATEGORYS_INSERT_REQUEST_SUCCESS';
export const CATEGORYS_INSERT_REQUEST_FAILED = 'CATEGORYS_INSERT_REQUEST_FAILED';
export const CATEGORYS_UPDATE_REQUEST = 'CATEGORYS_UPDATE_REQUEST';
export const CATEGORYS_UPDATE_REQUEST_SUCCESS = 'CATEGORYS_UPDATE_REQUEST_SUCCESS';
export const CATEGORYS_UPDATE_REQUEST_FAILED = 'CATEGORYS_UPDATE_REQUEST_FAILED';
export const CATEGORYS_DELETE_REQUEST = 'CATEGORYS_DELETE_REQUEST';
export const CATEGORYS_DELETE_REQUEST_SUCCESS = 'CATEGORYS_DELETE_REQUEST_SUCCESS';
export const CATEGORYS_DELETE_REQUEST_FAILED = 'CATEGORYS_DELETE_REQUEST_FAILED';
/*Components */
export const CATEGORYS_LIST_SELECT_ITEM = 'CATEGORYS_LIST_SELECT_ITEM';
export const CATEGORYS_LIST_SELECT_ALL_ITEM = 'CATEGORYS_LIST_SELECT_ALL_ITEM';
export const CATEGORYS_ACTION_FAILED = 'CATEGORYS_ACTION_FAILED';

export const FAMILIES_INIT_FORM_REQUEST = 'FAMILIES_INIT_FORM_REQUEST';
export const FAMILIES_INIT_FORM_REQUEST_SUCCESS = 'FAMILIES_INIT_FORM_REQUEST_SUCCESS';
export const FAMILIES_INIT_FORM_REQUEST_FAILED = 'FAMILIES_INIT_FORM_REQUEST_FAILED';
export const FAMILIES_INIT_UPDATE_FORM_REQUEST = 'FAMILIES_INIT_UPDATE_FORM_REQUEST';
export const FAMILIES_INIT_UPDATE_FORM_REQUEST_SUCCESS = 'FAMILIES_INIT_UPDATE_FORM_REQUEST_SUCCESS';
export const FAMILIES_INIT_UPDATE_FORM_REQUEST_FAILED = 'FAMILIES_INIT_UPDATE_FORM_REQUEST_FAILED';
export const FAMILIES_GET_ALL_RECORDS_REQUEST = 'FAMILIES_GET_ALL_RECORDS_REQUEST';
export const FAMILIES_GET_ALL_RECORDS_REQUEST_SUCCESS = 'FAMILIES_GET_ALL_RECORDS_REQUEST_SUCCESS';
export const FAMILIES_GET_ALL_RECORDS_REQUEST_FAILED = 'FAMILIES_GET_ALL_RECORDS_REQUEST_FAILED';
export const FAMILIES_INSERT_REQUEST = 'FAMILIES_INSERT_REQUEST';
export const FAMILIES_INSERT_REQUEST_SUCCESS = 'FAMILIES_INSERT_REQUEST_SUCCESS';
export const FAMILIES_INSERT_REQUEST_FAILED = 'FAMILIES_INSERT_REQUEST_FAILED';
export const FAMILIES_UPDATE_REQUEST = 'FAMILIES_UPDATE_REQUEST';
export const FAMILIES_UPDATE_REQUEST_SUCCESS = 'FAMILIES_UPDATE_REQUEST_SUCCESS';
export const FAMILIES_UPDATE_REQUEST_FAILED = 'FAMILIES_UPDATE_REQUEST_FAILED';
export const FAMILIES_DELETE_REQUEST = 'FAMILIES_DELETE_REQUEST';
export const FAMILIES_DELETE_REQUEST_SUCCESS = 'FAMILIES_DELETE_REQUEST_SUCCESS';
export const FAMILIES_DELETE_REQUEST_FAILED = 'FAMILIES_DELETE_REQUEST_FAILED';
/*Components */
export const FAMILIES_LIST_SELECT_ITEM = 'FAMILIES_LIST_SELECT_ITEM';
export const FAMILIES_LIST_SELECT_ALL_ITEM = 'FAMILIES_LIST_SELECT_ALL_ITEM';
export const FAMILIES_ACTION_FAILED = 'FAMILIES_ACTION_FAILED';

export const UNITS_INIT_UPDATE_FORM_REQUEST = 'UNITS_INIT_UPDATE_FORM_REQUEST';
export const UNITS_INIT_UPDATE_FORM_REQUEST_SUCCESS = 'UNITS_INIT_UPDATE_FORM_REQUEST_SUCCESS';
export const UNITS_INIT_UPDATE_FORM_REQUEST_FAILED = 'UNITS_INIT_UPDATE_FORM_REQUEST_FAILED';
export const UNITS_GET_ALL_RECORDS_REQUEST = 'UNITS_GET_ALL_RECORDS_REQUEST';
export const UNITS_GET_ALL_RECORDS_REQUEST_SUCCESS = 'UNITS_GET_ALL_RECORDS_REQUEST_SUCCESS';
export const UNITS_GET_ALL_RECORDS_REQUEST_FAILED = 'UNITS_GET_ALL_RECORDS_REQUEST_FAILED';
export const UNITS_INSERT_REQUEST = 'UNITS_GET_ALL_RECORDS_REQUEST_FAILED';
export const UNITS_INSERT_REQUEST_SUCCESS = 'UNITS_INSERT_REQUEST_SUCCESS';
export const UNITS_INSERT_REQUEST_FAILED = 'UNITS_INSERT_REQUEST_FAILED';
export const UNITS_UPDATE_REQUEST = 'UNITS_UPDATE_REQUEST';
export const UNITS_UPDATE_REQUEST_SUCCESS = 'UNITS_UPDATE_REQUEST_SUCCESS';
export const UNITS_UPDATE_REQUEST_FAILED = 'UNITS_UPDATE_REQUEST_FAILED';
export const UNITS_DELETE_REQUEST = 'UNITS_DELETE_REQUEST';
export const UNITS_DELETE_REQUEST_SUCCESS = 'UNITS_DELETE_REQUEST_SUCCESS';
export const UNITS_DELETE_REQUEST_FAILED = 'UNITS_DELETE_REQUEST_FAILED';
/*Components */
export const UNITS_LIST_SELECT_ITEM = 'UNITS_LIST_SELECT_ITEM';
export const UNITS_LIST_SELECT_ALL_ITEM = 'UNITS_LIST_SELECT_ALL_ITEM';
export const UNITS_ACTION_FAILED = 'UNITS_ACTION_FAILED';

export const WAREHOUSES_INIT_UPDATE_FORM_REQUEST = 'WAREHOUSES_INIT_UPDATE_FORM_REQUEST';
export const WAREHOUSES_INIT_UPDATE_FORM_REQUEST_SUCCESS = 'WAREHOUSES_INIT_UPDATE_FORM_REQUEST_SUCCESS';
export const WAREHOUSES_INIT_UPDATE_FORM_REQUEST_FAILED = 'WAREHOUSES_INIT_UPDATE_FORM_REQUEST_FAILED';
export const WAREHOUSES_GET_ALL_RECORDS_REQUEST = 'WAREHOUSES_GET_ALL_RECORDS_REQUEST';
export const WAREHOUSES_GET_ALL_RECORDS_REQUEST_SUCCESS = 'WAREHOUSES_GET_ALL_RECORDS_REQUEST_SUCCESS';
export const WAREHOUSES_GET_ALL_RECORDS_REQUEST_FAILED = 'WAREHOUSES_GET_ALL_RECORDS_REQUEST_FAILED';
export const WAREHOUSES_INSERT_REQUEST = 'WAREHOUSES_INSERT_REQUEST';
export const WAREHOUSES_INSERT_REQUEST_SUCCESS = 'WAREHOUSES_INSERT_REQUEST_SUCCESS';
export const WAREHOUSES_INSERT_REQUEST_FAILED = 'WAREHOUSES_INSERT_REQUEST_FAILED';
export const WAREHOUSES_UPDATE_REQUEST = 'WAREHOUSES_UPDATE_REQUEST';
export const WAREHOUSES_UPDATE_REQUEST_SUCCESS = 'WAREHOUSES_UPDATE_REQUEST_SUCCESS';
export const WAREHOUSES_UPDATE_REQUEST_FAILED = 'WAREHOUSES_UPDATE_REQUEST_FAILED';
export const WAREHOUSES_DELETE_REQUEST = 'WAREHOUSES_DELETE_REQUEST';
export const WAREHOUSES_DELETE_REQUEST_SUCCESS = 'WAREHOUSES_DELETE_REQUEST_SUCCESS';
export const WAREHOUSES_DELETE_REQUEST_FAILED = 'WAREHOUSES_DELETE_REQUEST_FAILED';
/*Components */
export const WAREHOUSES_LIST_SELECT_ITEM = 'WAREHOUSES_LIST_SELECT_ITEM';
export const WAREHOUSES_LIST_SELECT_ALL_ITEM = 'WAREHOUSES_LIST_SELECT_ALL_ITEM';
export const WAREHOUSES_ACTION_FAILED = 'WAREHOUSES_ACTION_FAILED';

export const PRODUCTS_INIT_FORM_REQUEST = 'PRODUCTS_INIT_FORM_REQUEST';
export const PRODUCTS_INIT_FORM_REQUEST_SUCCESS = 'PRODUCTS_INIT_FORM_REQUEST_SUCCESS';
export const PRODUCTS_INIT_FORM_REQUEST_FAILED = 'PRODUCTS_INIT_FORM_REQUEST_FAILED';
export const PRODUCTS_INIT_UPDATE_FORM_REQUEST = 'PRODUCTS_INIT_UPDATE_FORM_REQUEST';
export const PRODUCTS_INIT_UPDATE_FORM_REQUEST_SUCCESS = 'PRODUCTS_INIT_UPDATE_FORM_REQUEST_SUCCESS';
export const PRODUCTS_INIT_UPDATE_FORM_REQUEST_FAILED = 'PRODUCTS_INIT_UPDATE_FORM_REQUEST_FAILED';
export const PRODUCTS_GET_ALL_RECORDS_REQUEST = 'PRODUCTS_GET_ALL_RECORDS_REQUEST';
export const PRODUCTS_GET_ALL_RECORDS_REQUEST_SUCCESS = 'PRODUCTS_GET_ALL_RECORDS_REQUEST_SUCCESS';
export const PRODUCTS_GET_ALL_RECORDS_REQUEST_FAILED = 'PRODUCTS_GET_ALL_RECORDS_REQUEST_FAILED';
export const PRODUCTS_INSERT_REQUEST = 'PRODUCTS_INSERT_REQUEST';
export const PRODUCTS_INSERT_REQUEST_SUCCESS = 'PRODUCTS_INSERT_REQUEST_SUCCESS';
export const PRODUCTS_INSERT_REQUEST_FAILED = 'PRODUCTS_INSERT_REQUEST_FAILED';
export const PRODUCTS_UPDATE_REQUEST = 'PRODUCTS_UPDATE_REQUEST';
export const PRODUCTS_UPDATE_REQUEST_SUCCESS = 'PRODUCTS_UPDATE_REQUEST_SUCCESS';
export const PRODUCTS_UPDATE_REQUEST_FAILED = 'PRODUCTS_UPDATE_REQUEST_FAILED';
export const PRODUCTS_DELETE_REQUEST = 'PRODUCTS_DELETE_REQUEST';
export const PRODUCTS_DELETE_REQUEST_SUCCESS = 'PRODUCTS_DELETE_REQUEST_SUCCESS';
export const PRODUCTS_DELETE_REQUEST_FAILED = 'PRODUCTS_DELETE_REQUEST_FAILED';
/*Components */
export const PRODUCTS_LIST_SELECT_ITEM = 'PRODUCTS_LIST_SELECT_ITEM';
export const PRODUCTS_LIST_SELECT_ALL_ITEM = 'PRODUCTS_LIST_SELECT_ALL_ITEM';
export const PRODUCTS_ACTION_FAILED = 'PRODUCTS_ACTION_FAILED';