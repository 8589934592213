export const LOCATIONS_GET_DEPARTMENTS_REQUEST = 'LOCATIONS_GET_DEPARTMENTS_REQUEST';
export const LOCATIONS_GET_DEPARTMENTS_REQUEST_SUCCESS = 'LOCATIONS_GET_DEPARTMENTS_REQUEST_SUCCESS';
export const LOCATIONS_GET_DEPARTMENTS_REQUEST_FAILED = 'LOCATIONS_GET_DEPARTMENTS_REQUEST_FAILED';
export const LOCATIONS_GET_PROVINCES_REQUEST = 'LOCATIONS_GET_PROVINCES_REQUEST';
export const LOCATIONS_GET_PROVINCES_REQUEST_SUCCESS = 'LOCATIONS_GET_PROVINCES_REQUEST_SUCCESS';
export const LOCATIONS_GET_PROVINCES_REQUEST_FAILED = 'LOCATIONS_GET_PROVINCES_REQUEST_FAILED';
export const LOCATIONS_GET_DISTRICT_REQUEST = 'LOCATIONS_GET_DISTRICT_REQUEST';
export const LOCATIONS_GET_DISTRICT_REQUEST_SUCCESS = 'LOCATIONS_GET_DISTRICT_REQUEST_SUCCESS';
export const LOCATIONS_GET_DISTRICT_REQUEST_FAILED = 'LOCATIONS_GET_DISTRICT_REQUEST_FAILED';

export const CURRENCY_EXCHANGE_RATE_REQUEST = 'CURRENCY_EXCHANGE_RATE_REQUEST';
export const CURRENCY_EXCHANGE_RATE_REQUEST_SUCCESS = 'CURRENCY_EXCHANGE_RATE_REQUEST_SUCCESS';
export const CURRENCY_EXCHANGE_RATE_REQUEST_FAILED = 'CURRENCY_EXCHANGE_RATE_REQUEST_FAILED';

export const MASTER_TABLES_REQUEST = 'MASTER_TABLES_REQUEST';
export const MASTER_TABLES_REQUEST_SUCCESS = 'MASTER_TABLES_REQUEST_SUCCESS';
export const MASTER_TABLES_REQUEST_FAILED = 'MASTER_TABLES_REQUEST_FAILED';