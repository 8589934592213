import React from 'react';
import { Backdrop, Button, ButtonGroup, Checkbox, CircularProgress, createStyles, FormControl, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Select, TextField, Theme, Tooltip, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { generalBalancesRequest } from 'penta-redux/actions/accountantActions';
import history from '../../../../utils/history';
import './../reports.scss'
import FormFooter from 'penta-components/base/formFooter';
import { MessageBox } from 'penta-components/base/messageBox';
import { categoryEntity } from 'models/wharehouseProductsModels';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PdfView from 'penta-components/base/pdfView';
// import * as pdfMake from "pdfmake/build/pdfmake";
// import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
import * as XLSX from 'sheetjs-style';
import ScreenLoader from 'penta-components/base/screenLoader';

let pdfMake = require("pdfmake/build/pdfmake");
let saveAs = require('file-saver').saveAs;

const meses = [
    { key: '01', value: 'Enero' },
    { key: '02', value: 'Febrero' },
    { key: '03', value: 'Marzo' },
    { key: '04', value: 'Abril' },
    { key: '05', value: 'Mayo' },
    { key: '06', value: 'Junio' },
    { key: '07', value: 'Julio' },
    { key: '08', value: 'Agosto' },
    { key: '09', value: 'Septiembre' },
    { key: '10', value: 'Octubre' },
    { key: '11', value: 'Noviembre' },
    { key: '12', value: 'Diciembre' }
]

const useStyles = (theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        formControl: {
            margin: 0,
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    });

interface FormState {
    title: string,
    year: string,
    month: string,
    base64Pdf: string
}

class ReportBalanceFinancialPosition extends React.Component<any, FormState> {

    constructor(props: any) {
        super(props);
        this.state = {
            title: 'Reporte de Balance de Situacion Financiera',
            year: moment().format('yyyy').toString(),
            month: moment().format('MM').toString(),
            base64Pdf: ''
        }
    }

    onChangeRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            ...value
        }))
    }

    onGeneratePdf = () => {
        const data = this.props.accountantReducers.onlyOne;
        const { month, year } = this.state;
        const { dashboardReducers } = this.props;
        const mes = meses.find((x: any) => x.key === month).value;
        const company = (JSON.parse(localStorage.getItem('tables')).companys.find((x: any) => x.taxident === dashboardReducers.currentCompany).bussname as string).toUpperCase()

        const totalActivoCorriente = data.filter(x => ['10','12','14','20','25'].includes(x.accaccou)).reduce((x, y) => x + y.totalacc, 0);
        const totalActivoNoCorriente = data.filter(x => ['33','39','18','34'].includes(x.accaccou)).reduce((x, y) => x + y.totalacc, 0);
        const totalActivo = totalActivoCorriente + totalActivoNoCorriente;

        const totalPasivoCorriente = data.filter(x => ['40','41','42','44'].includes(x.accaccou)).reduce((x, y) => x + y.totalacc, 0) - (data.find(x => x.accaccou === '415')?.totalacc || 0);
        const totalPasivoNoCorriente = data.filter(x => ['415','46'].includes(x.accaccou)).reduce((x, y) => x + y.totalacc, 0);
        const resultadoEjercicio = totalActivoCorriente - totalPasivoCorriente + totalActivoNoCorriente - totalPasivoNoCorriente + data.filter(x => ['50','52','59'].includes(x.accaccou)).reduce((x, y) => x - y.totalacc, 0);
        const resultadoEjercicioAjustado = totalActivoCorriente - (totalPasivoCorriente*-1) + totalActivoNoCorriente - (totalPasivoNoCorriente*-1) + data.filter(x => ['50','52','59'].includes(x.accaccou)).reduce((x, y) => x - (y.totalacc*-1), 0);
        const totalPatrimonio = data.filter(x => ['50','52','59'].includes(x.accaccou)).reduce((x, y) => x + y.totalacc, 0) + resultadoEjercicio;
        const totalPatrimonioAjustado = data.filter(x => ['50','52','59'].includes(x.accaccou)).reduce((x, y) => x + (y.totalacc*-1), 0) + resultadoEjercicioAjustado;
        const totalPasivo = totalPasivoCorriente + totalPasivoNoCorriente + totalPatrimonio;
        const totalPasivoAjustado = (totalPasivoCorriente*-1) + (totalPasivoNoCorriente*-1) + totalPatrimonioAjustado;

        const pdfObject: any = {
            pageSize: 'A4',
            pageOrientation: 'portrait',
            pageMargins: [ 20, 100, 20, 20 ],
            header: [
                {
                    text: ' ', lineHeight: 2
                },
                {
                    margin: [20, 0, 0, 0],
                    text: company.toUpperCase(),
                    style: {
                        bold: true,
                        fontSize: 9
                    }
                },
                {
                    margin: [20, 15, 20, 0],
                    text: `BALANCE DE SITUACION FINANCIERA AL ${moment(new Date(parseInt(year), parseInt(month) - 1, 1)).daysInMonth()} DE ${mes.toUpperCase()} DEL ${year}`,
                    style: {
                        bold: true,
                        fontSize: 9,
                        alignment: 'center'
                    }
                },
            ],
            content: [
                {
                    table: {
                        headerRows: 0,
                        widths: [ '*', 20, 70, 20, 70 ],
                        body: [
                            [ 
                                { text: 'ACTIVO', fontSize: 8, bold: true, alignment: 'center', colSpan: 1, border: [false, false, false, false] },
                                { text: '', colSpan: 4, border: [false, false, false, false] },{},{},{}
                            ],
                            [
                                { text: ' ', lineHeight: 0.5, colSpan: 5, border: [false, false, false, false] },
                            ],
                            [ 
                                { text: 'ACTIVO CORRIENTE', fontSize: 8, bold: true, alignment: 'left', colSpan: 1, border: [false, false, false, false] },
                                { text: '', fontSize: 8, bold: true, alignment: 'center', colSpan: 1, border: [false, false, false, false] },
                                { text: 'HISTORICO', fontSize: 8, bold: true, alignment: 'center', colSpan: 1, border: [false, false, false, false] },
                                { text: '', fontSize: 8, bold: true, alignment: 'center', colSpan: 1, border: [false, false, false, false] },
                                { text: 'AJUSTADO', fontSize: 8, bold: true, alignment: 'center', colSpan: 1, border: [false, false, false, false] },
                            ],
                            [ 
                                { text: '', fontSize: 8, bold: true, alignment: 'center', colSpan: 1, border: [false, false, false, false] },
                                { text: 'S/', fontSize: 8, bold: true, alignment: 'center', colSpan: 1, border: [false, false, false, false] },
                                { text: '', fontSize: 8, bold: true, alignment: 'center', colSpan: 1, border: [false, false, false, false] },
                                { text: 'S/', fontSize: 8, bold: true, alignment: 'center', colSpan: 1, border: [false, false, false, false] },
                                { text: '', fontSize: 8, bold: true, alignment: 'center', colSpan: 1, border: [false, false, false, false] },
                            ],
                            [ 
                                { text: 'CAJA Y BANCOS', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber2.new(data.find(x => x.accaccou === '10')?.totalacc || 0), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber2.new(data.find(x => x.accaccou === '10')?.totalacc || 0), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                            ],
                            [ 
                                { text: 'CLIENTES', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber2.new(data.find(x => x.accaccou === '12')?.totalacc || 0), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber2.new(data.find(x => x.accaccou === '12')?.totalacc || 0), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                            ],
                            [ 
                                { text: 'CUENTAS POR COBRAR ACC.Y PERSONAL', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber2.new(data.find(x => x.accaccou === '14')?.totalacc || 0), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber2.new(data.find(x => x.accaccou === '14')?.totalacc || 0), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                            ],
                            [ 
                                { text: 'EXISTENCIAS', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber2.new((data.find(x => x.accaccou === '20')?.totalacc || 0) + (data.find(x => x.accaccou === '25')?.totalacc || 0)), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber2.new((data.find(x => x.accaccou === '20')?.totalacc || 0) + (data.find(x => x.accaccou === '25')?.totalacc || 0)), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                            ],
                            [ 
                                { text: 'TOT.ACT.CORRIENTE', style: 'tableDetailLeftBold', colSpan: 1, border: [false, false, false, false] },
                                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber2.new(totalActivoCorriente), style: 'tableDetailRightBold', colSpan: 1, border: [false, true, false, false] },
                                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber2.new(totalActivoCorriente), style: 'tableDetailRightBold', colSpan: 1, border: [false, true, false, false] },
                            ],
                            [
                                { text: ' ', lineHeight: 0.5, colSpan: 5, border: [false, false, false, false] },
                            ],
                            [ 
                                { text: 'ACTIVO NO CORRIENTE', fontSize: 8, bold: true, alignment: 'left', colSpan: 1, border: [false, false, false, false] },
                                { text: '', fontSize: 8, bold: true, alignment: 'center', colSpan: 1, border: [false, false, false, false] },
                                { text: '', fontSize: 8, bold: true, alignment: 'center', colSpan: 1, border: [false, false, false, false] },
                                { text: '', fontSize: 8, bold: true, alignment: 'center', colSpan: 1, border: [false, false, false, false] },
                                { text: '', fontSize: 8, bold: true, alignment: 'center', colSpan: 1, border: [false, false, false, false] },
                            ],
                            [
                                { text: ' ', lineHeight: 0.5, colSpan: 5, border: [false, false, false, false] },
                            ],
                            [ 
                                { text: 'INMUEB.MAQ.Y EQUIPO', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber2.new(data.find(x => x.accaccou === '33')?.totalacc || 0), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber2.new(data.find(x => x.accaccou === '33')?.totalacc || 0), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                            ],
                            [ 
                                { text: 'INTANGIBLES', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber2.new(data.find(x => x.accaccou === '34')?.totalacc || 0), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber2.new(data.find(x => x.accaccou === '34')?.totalacc || 0), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                            ],
                            [ 
                                { text: 'DEPRE.Y AMORTIZ.ACUMULADA', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber2.new(data.find(x => x.accaccou === '39')?.totalacc || 0), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber2.new(data.find(x => x.accaccou === '39')?.totalacc || 0), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                            ],
                            [ 
                                { text: 'SERVICIOS Y OTROS CONTRATADOS X ANTICIPADO', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber2.new(data.find(x => x.accaccou === '18')?.totalacc || 0), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber2.new(data.find(x => x.accaccou === '18')?.totalacc || 0), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                            ],
                            [ 
                                { text: 'TOT.ACT.NO CORRIENTE', style: 'tableDetailLeftBold', colSpan: 1, border: [false, false, false, false] },
                                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber2.new(totalActivoNoCorriente), style: 'tableDetailRightBold', colSpan: 1, border: [false, true, false, false] },
                                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber2.new(totalActivoNoCorriente), style: 'tableDetailRightBold', colSpan: 1, border: [false, true, false, false] },
                            ],
                            [
                                { text: ' ', lineHeight: 0.5, colSpan: 5, border: [false, false, false, false] },
                            ],
                            [ 
                                { text: 'TOTAL ACTIVO', style: 'tableDetailLeftBold', colSpan: 1, border: [false, false, false, false] },
                                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber2.new(totalActivo), style: 'tableDetailRightBold', colSpan: 1, border: [false, true, false, false] },
                                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber2.new(totalActivo), style: 'tableDetailRightBold', colSpan: 1, border: [false, true, false, false] },
                            ],
                        ]
                    }
                },
                {
                    table: {
                        headerRows: 0,
                        widths: [ '*', 20, 60, 20, 60 ],
                        body: [
                            [ 
                                { text: 'PASIVO', fontSize: 8, bold: true, alignment: 'center', colSpan: 1, border: [false, false, false, false] },
                                { text: '', colSpan: 4, border: [false, false, false, false] },{},{},{}
                            ],
                            [
                                { text: ' ', lineHeight: 0.5, colSpan: 5, border: [false, false, false, false] },
                            ],
                            [ 
                                { text: 'CORRIENTE', fontSize: 8, bold: true, alignment: 'left', colSpan: 1, border: [false, false, false, false] },
                                { text: '', fontSize: 8, bold: true, alignment: 'center', colSpan: 1, border: [false, false, false, false] },
                                { text: '', fontSize: 8, bold: true, alignment: 'center', colSpan: 1, border: [false, false, false, false] },
                                { text: '', fontSize: 8, bold: true, alignment: 'center', colSpan: 1, border: [false, false, false, false] },
                                { text: '', fontSize: 8, bold: true, alignment: 'center', colSpan: 1, border: [false, false, false, false] },
                            ],
                            [
                                { text: ' ', lineHeight: 0.5, colSpan: 5, border: [false, false, false, false] },
                            ],
                            [ 
                                { text: 'TRIBUTOS POR PAGAR', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber2.new(data.find(x => x.accaccou === '40')?.totalacc || 0), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber2.new((data.find(x => x.accaccou === '40')?.totalacc || 0) * -1), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                            ],
                            [ 
                                { text: 'REMUNERACIONES POR PAGAR', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber2.new((data.find(x => x.accaccou === '41')?.totalacc || 0) - (data.find(x => x.accaccou === '415')?.totalacc || 0)), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber2.new(((data.find(x => x.accaccou === '41')?.totalacc || 0) - (data.find(x => x.accaccou === '415')?.totalacc || 0)) * -1), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                            ],
                            [ 
                                { text: 'CUENTAS POR PAGAR COMERCIALES', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber2.new(data.find(x => x.accaccou === '42')?.totalacc || 0), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber2.new((data.find(x => x.accaccou === '42')?.totalacc || 0) * -1), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                            ],
                            [ 
                                { text: 'CUENTAS POR PAGAR A VINVULADOS', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber2.new(data.find(x => x.accaccou === '44')?.totalacc || 0), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber2.new((data.find(x => x.accaccou === '44')?.totalacc || 0) * -1), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                            ],
                            [ 
                                { text: 'TOT.PASIVO CORRIENTE', style: 'tableDetailLeftBold', colSpan: 1, border: [false, false, false, false] },
                                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber2.new(totalPasivoCorriente), style: 'tableDetailRightBold', colSpan: 1, border: [false, true, false, false] },
                                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber2.new(totalPasivoCorriente*-1), style: 'tableDetailRightBold', colSpan: 1, border: [false, true, false, false] },
                            ],
                            [
                                { text: ' ', lineHeight: 0.5, colSpan: 5, border: [false, false, false, false] },
                            ],
                            [ 
                                { text: 'PASIVO NO CORRIENTE', fontSize: 8, bold: true, alignment: 'left', colSpan: 1, border: [false, false, false, false] },
                                { text: '', fontSize: 8, bold: true, alignment: 'center', colSpan: 1, border: [false, false, false, false] },
                                { text: '', fontSize: 8, bold: true, alignment: 'center', colSpan: 1, border: [false, false, false, false] },
                                { text: '', fontSize: 8, bold: true, alignment: 'center', colSpan: 1, border: [false, false, false, false] },
                                { text: '', fontSize: 8, bold: true, alignment: 'center', colSpan: 1, border: [false, false, false, false] },
                            ],
                            [
                                { text: ' ', lineHeight: 0.5, colSpan: 5, border: [false, false, false, false] },
                            ],
                            [ 
                                { text: 'BEN.SOCIALES TRABAJADORES', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber2.new(data.find(x => x.accaccou === '415')?.totalacc || 0), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber2.new((data.find(x => x.accaccou === '415')?.totalacc || 0) * -1), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                            ],
                            [ 
                                { text: 'CTA.POR PAGAR DIVERSAS', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber2.new(data.find(x => x.accaccou === '46')?.totalacc || 0), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber2.new((data.find(x => x.accaccou === '46')?.totalacc || 0) * -1), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                            ],
                            [ 
                                { text: 'TOT.PASIVO NO CORRIENTE', style: 'tableDetailLeftBold', colSpan: 1, border: [false, false, false, false] },
                                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber2.new(totalPasivoNoCorriente), style: 'tableDetailRightBold', colSpan: 1, border: [false, true, false, false] },
                                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber2.new(totalPasivoNoCorriente*-1), style: 'tableDetailRightBold', colSpan: 1, border: [false, true, false, false] },
                            ],
                            [
                                { text: ' ', lineHeight: 0.5, colSpan: 5, border: [false, false, false, false] },
                            ],
                            [ 
                                { text: 'PATRIMONIO', fontSize: 8, bold: true, alignment: 'left', colSpan: 1, border: [false, false, false, false] },
                                { text: '', fontSize: 8, bold: true, alignment: 'center', colSpan: 1, border: [false, false, false, false] },
                                { text: '', fontSize: 8, bold: true, alignment: 'center', colSpan: 1, border: [false, false, false, false] },
                                { text: '', fontSize: 8, bold: true, alignment: 'center', colSpan: 1, border: [false, false, false, false] },
                                { text: '', fontSize: 8, bold: true, alignment: 'center', colSpan: 1, border: [false, false, false, false] },
                            ],
                            [
                                { text: ' ', lineHeight: 0.5, colSpan: 5, border: [false, false, false, false] },
                            ],
                            [ 
                                { text: 'CAPITAL SOCIAL', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber2.new(data.find(x => x.accaccou === '50')?.totalacc || 0), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber2.new((data.find(x => x.accaccou === '50')?.totalacc || 0) * -1), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                            ],
                            [ 
                                { text: 'CAPITAL ADICIONAL', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber2.new(data.find(x => x.accaccou === '52')?.totalacc || 0), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber2.new((data.find(x => x.accaccou === '52')?.totalacc || 0) * -1), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                            ],
                            [ 
                                { text: 'RESULTADOS ACUMULADOS', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber2.new(data.find(x => x.accaccou === '59')?.totalacc || 0), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber2.new((data.find(x => x.accaccou === '59')?.totalacc || 0) * -1), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                            ],
                            [ 
                                { text: 'RESULTADOS DEL EJERCICIO', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber2.new(resultadoEjercicio), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber2.new(resultadoEjercicioAjustado), style: 'tableDetailRight', colSpan: 1, border: [false, false, false, false] },
                            ],
                            [ 
                                { text: ' TOTAL PATRIMONIO', style: 'tableDetailLeftBold', colSpan: 1, border: [false, false, false, false] },
                                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber2.new(totalPatrimonio), style: 'tableDetailRightBold', colSpan: 1, border: [false, true, false, false] },
                                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber2.new(totalPatrimonioAjustado), style: 'tableDetailRightBold', colSpan: 1, border: [false, true, false, false] },
                            ],
                            [
                                { text: ' ', lineHeight: 0.5, colSpan: 5, border: [false, false, false, false] },
                            ],
                            [ 
                                { text: 'TOTAL PASIVO Y PATRIMONIO', style: 'tableDetailLeftBold', colSpan: 1, border: [false, false, false, false] },
                                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber2.new(totalPasivo), style: 'tableDetailRightBold', colSpan: 1, border: [false, true, false, false] },
                                { text: '', style: 'tableDetailLeft', colSpan: 1, border: [false, false, false, false] },
                                { text: String.formatNumber2.new(totalPasivoAjustado), style: 'tableDetailRightBold', colSpan: 1, border: [false, true, false, false] },
                            ],
                        ]
                    }
                }
            ],
            styles: {
                headerTitle: {
                    bold: true,
                    fontSize: 7,
                    lineHeight: 1.2
                },
                table: {
                    layout: 'headerLineOnly',
                    fontSize: 6,
                    alignment: 'center'
                },
                subTable: {
                    layout: 'headerLineOnly',
                    fontSize: 6,
                    alignment: 'left'
                },
                tableDetailLeft: {
                    layout: 'headerLineOnly',
                    fontSize: 7,
                    alignment: 'left'
                },
                tableDetailRight: {
                    layout: 'headerLineOnly',
                    fontSize: 7,
                    alignment: 'right'
                },
                tableDetailLeftBold: {
                    layout: 'headerLineOnly',
                    fontSize: 7,
                    alignment: 'left',
                    bold: true
                },
                tableDetailRightBold: {
                    layout: 'headerLineOnly',
                    fontSize: 7,
                    alignment: 'right',
                    bold: true
                }
            },
            defaultStyle: {
                columnGap: 20,
            },
        };

        pdfMake.fonts = {
            Roboto: {
                normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
                bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
                italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
                bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
              },
        }
        const pdfDocGenerator = pdfMake.createPdf(pdfObject);
        pdfDocGenerator.getDataUrl((data: any) => {
            // console.log(data);
            this.setState({
                base64Pdf: data
            })
        });

    }

    onGenerateExcel = () => {
        try {
            const data = this.props.accountantReducers.onlyOne;
            const { month, year } = this.state;
            const { dashboardReducers } = this.props;
            const mes = meses.find((x: any) => x.key === month).value;
            const company = (JSON.parse(localStorage.getItem('tables')).companys.find((x: any) => x.taxident === dashboardReducers.currentCompany).bussname as string).toUpperCase()
    
            const totalActivoCorriente = data.filter(x => ['10','12','14','20','25'].includes(x.accaccou)).reduce((x, y) => x + y.totalacc, 0);
            const totalActivoNoCorriente = data.filter(x => ['33','39','18','34'].includes(x.accaccou)).reduce((x, y) => x + y.totalacc, 0);
            const totalActivo = totalActivoCorriente + totalActivoNoCorriente;

            const totalPasivoCorriente = data.filter(x => ['40','41','42','44'].includes(x.accaccou)).reduce((x, y) => x + y.totalacc, 0) - (data.find(x => x.accaccou === '415')?.totalacc || 0);
            const totalPasivoNoCorriente = data.filter(x => ['415','46'].includes(x.accaccou)).reduce((x, y) => x + y.totalacc, 0);
            const resultadoEjercicio = totalActivoCorriente - totalPasivoCorriente + totalActivoNoCorriente - totalPasivoNoCorriente + data.filter(x => ['50','52','59'].includes(x.accaccou)).reduce((x, y) => x - y.totalacc, 0);
            const resultadoEjercicioAjustado = totalActivoCorriente - (totalPasivoCorriente*-1) + totalActivoNoCorriente - (totalPasivoNoCorriente*-1) + data.filter(x => ['50','52','59'].includes(x.accaccou)).reduce((x, y) => x - (y.totalacc*-1), 0);
            const totalPatrimonio = data.filter(x => ['50','52','59'].includes(x.accaccou)).reduce((x, y) => x + y.totalacc, 0) + resultadoEjercicio;
            const totalPatrimonioAjustado = data.filter(x => ['50','52','59'].includes(x.accaccou)).reduce((x, y) => x + (y.totalacc*-1), 0) + resultadoEjercicioAjustado;
            const totalPasivo = totalPasivoCorriente + totalPasivoNoCorriente + totalPatrimonio;
            const totalPasivoAjustado = (totalPasivoCorriente*-1) + (totalPasivoNoCorriente*-1) + totalPatrimonioAjustado;

            const tableBody: any = [
                {
                    A: null,
                    B: company.toUpperCase(),
                    C: null,
                    D: null,
                    E: null,
                    F: null
                },
                {
                    A: null,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null
                },
                {
                    A: null,
                    B: `BALANCE DE SITUACION FINANCIERA AL ${moment(new Date(parseInt(year), parseInt(month) - 1, 1)).daysInMonth()} DE ${mes.toUpperCase()} DEL ${year}`,
                    C: null,
                    D: null,
                    E: null,
                    F: null
                },
                {
                    A: null,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null
                },
                {
                    A: null,
                    B: 'ACTIVO',
                    C: null,
                    D: null,
                    E: null,
                    F: null
                },
                {
                    A: null,
                    B: 'ACTIVO CORRIENTE',
                    C: null,
                    D: 'HISTORICO',
                    E: null,
                    F: 'AJUSTADO'
                },
                {
                    A: null,
                    B: null,
                    C: 'S/',
                    D: null,
                    E: 'S/',
                    F: null
                },
                {
                    A: null,
                    B: 'CAJA Y BANCOS',
                    C: null,
                    D: String.formatNumber.new(data.find(x => x.accaccou === '10')?.totalacc || 0),
                    E: null,
                    F: String.formatNumber.new(data.find(x => x.accaccou === '10')?.totalacc || 0)
                },
                {
                    A: null,
                    B: 'CLIENTES',
                    C: null,
                    D: String.formatNumber.new(data.find(x => x.accaccou === '12')?.totalacc || 0),
                    E: null,
                    F: String.formatNumber.new(data.find(x => x.accaccou === '12')?.totalacc || 0)
                },
                {
                    A: null,
                    B: 'CUENTAS POR COBRAR ACC.Y PERSONAL',
                    C: null,
                    D: String.formatNumber.new(data.find(x => x.accaccou === '14')?.totalacc || 0),
                    E: null,
                    F: String.formatNumber.new(data.find(x => x.accaccou === '14')?.totalacc || 0)
                },
                {
                    A: null,
                    B: 'EXISTENCIAS',
                    C: null,
                    D: String.formatNumber.new((data.find(x => x.accaccou === '20')?.totalacc || 0) + (data.find(x => x.accaccou === '25')?.totalacc || 0)),
                    E: null,
                    F: String.formatNumber.new((data.find(x => x.accaccou === '20')?.totalacc || 0) + (data.find(x => x.accaccou === '25')?.totalacc || 0))
                },
                {
                    A: null,
                    B: 'TOT.ACT.CORRIENTE',
                    C: null,
                    D: String.formatNumber.new(totalActivoCorriente),
                    E: null,
                    F: String.formatNumber.new(totalActivoCorriente)
                },
                {
                    A: null,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null
                },
                {
                    A: null,
                    B: 'ACTIVO NO CORRIENTE',
                    C: null,
                    D: null,
                    E: null,
                    F: null
                },
                {
                    A: null,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null
                },
                {
                    A: null,
                    B: 'INMUEB.MAQ.Y EQUIPO',
                    C: null,
                    D: String.formatNumber.new(data.find(x => x.accaccou === '33')?.totalacc || 0),
                    E: null,
                    F: String.formatNumber.new(data.find(x => x.accaccou === '33')?.totalacc || 0)
                },
                {
                    A: null,
                    B: 'INTANGIBLES',
                    C: null,
                    D: String.formatNumber.new(data.find(x => x.accaccou === '34')?.totalacc || 0),
                    E: null,
                    F: String.formatNumber.new(data.find(x => x.accaccou === '34')?.totalacc || 0)
                },
                {
                    A: null,
                    B: 'DEPRE.Y AMORTIZ.ACUMULADA',
                    C: null,
                    D: String.formatNumber.new(data.find(x => x.accaccou === '39')?.totalacc || 0),
                    E: null,
                    F: String.formatNumber.new(data.find(x => x.accaccou === '39')?.totalacc || 0)
                },
                {
                    A: null,
                    B: 'SERVICIOS Y OTROS CONTRATADOS X ANTICIPADO',
                    C: null,
                    D: String.formatNumber.new(data.find(x => x.accaccou === '18')?.totalacc || 0),
                    E: null,
                    F: String.formatNumber.new(data.find(x => x.accaccou === '18')?.totalacc || 0)
                },
                {
                    A: null,
                    B: 'TOT.ACT.NO CORRIENTE',
                    C: null,
                    D: String.formatNumber.new(totalActivoNoCorriente),
                    E: null,
                    F: String.formatNumber.new(totalActivoNoCorriente)
                },
                {
                    A: null,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null
                },
                {
                    A: null,
                    B: 'TOTAL ACTIVO',
                    C: null,
                    D: String.formatNumber.new(totalActivo),
                    E: null,
                    F: String.formatNumber.new(totalActivo)
                },
                {
                    A: null,
                    B: 'PASIVO',
                    C: null,
                    D: null,
                    E: null,
                    F: null
                },
                {
                    A: null,
                    B: 'CORRIENTE',
                    C: null,
                    D: null,
                    E: null,
                    F: null
                },
                {
                    A: null,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null
                },
                {
                    A: null,
                    B: 'TRIBUTOS POR PAGAR',
                    C: null,
                    D: String.formatNumber.new(data.find(x => x.accaccou === '40')?.totalacc || 0),
                    E: null,
                    F: String.formatNumber.new((data.find(x => x.accaccou === '40')?.totalacc || 0)*-1)
                },
                {
                    A: null,
                    B: 'REMUNERACIONES POR PAGAR',
                    C: null,
                    D: String.formatNumber.new((data.find(x => x.accaccou === '41')?.totalacc || 0) - (data.find(x => x.accaccou === '415')?.totalacc || 0)),
                    E: null,
                    F: String.formatNumber.new(((data.find(x => x.accaccou === '41')?.totalacc || 0) - (data.find(x => x.accaccou === '415')?.totalacc || 0))*-1)
                },
                {
                    A: null,
                    B: 'CUENTAS POR PAGAR COMERCIALES',
                    C: null,
                    D: String.formatNumber.new(data.find(x => x.accaccou === '42')?.totalacc || 0),
                    E: null,
                    F: String.formatNumber.new((data.find(x => x.accaccou === '42')?.totalacc || 0)*-1)
                },
                {
                    A: null,
                    B: 'CUENTAS POR PAGAR A VINVULADOS',
                    C: null,
                    D: String.formatNumber.new(data.find(x => x.accaccou === '44')?.totalacc || 0),
                    E: null,
                    F: String.formatNumber.new((data.find(x => x.accaccou === '44')?.totalacc || 0)*-1)
                },
                {
                    A: null,
                    B: 'TOT.PASIVO CORRIENTE',
                    C: null,
                    D: String.formatNumber.new(totalPasivoCorriente),
                    E: null,
                    F: String.formatNumber.new(totalPasivoCorriente*-1)
                },
                {
                    A: null,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null
                },
                {
                    A: null,
                    B: 'PASIVO NO CORRIENTE',
                    C: null,
                    D: null,
                    E: null,
                    F: null
                },
                {
                    A: null,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null
                },
                {
                    A: null,
                    B: 'BEN.SOCIALES TRABAJADORES',
                    C: null,
                    D: String.formatNumber.new(data.find(x => x.accaccou === '415')?.totalacc || 0),
                    E: null,
                    F: String.formatNumber.new((data.find(x => x.accaccou === '415')?.totalacc || 0)*-1)
                },
                {
                    A: null,
                    B: 'CTA.POR PAGAR DIVERSAS',
                    C: null,
                    D: String.formatNumber.new(data.find(x => x.accaccou === '46')?.totalacc || 0),
                    E: null,
                    F: String.formatNumber.new((data.find(x => x.accaccou === '46')?.totalacc || 0)*-1)
                },
                {
                    A: null,
                    B: 'TOT.PASIVO NO CORRIENTE',
                    C: null,
                    D: String.formatNumber.new(totalPasivoNoCorriente),
                    E: null,
                    F: String.formatNumber.new(totalPasivoNoCorriente*-1)
                },
                {
                    A: null,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null
                },
                {
                    A: null,
                    B: 'PATRIMONIO',
                    C: null,
                    D: null,
                    E: null,
                    F: null
                },
                {
                    A: null,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null
                },
                {
                    A: null,
                    B: 'CAPITAL SOCIAL',
                    C: null,
                    D: String.formatNumber.new(data.find(x => x.accaccou === '50')?.totalacc || 0),
                    E: null,
                    F: String.formatNumber.new((data.find(x => x.accaccou === '50')?.totalacc || 0)*-1)
                },
                {
                    A: null,
                    B: 'CAPITAL ADICIONAL',
                    C: null,
                    D: String.formatNumber.new(data.find(x => x.accaccou === '52')?.totalacc || 0),
                    E: null,
                    F: String.formatNumber.new((data.find(x => x.accaccou === '52')?.totalacc || 0)*-1)
                },
                {
                    A: null,
                    B: 'RESULTADOS ACUMULADOS',
                    C: null,
                    D: String.formatNumber.new(data.find(x => x.accaccou === '59')?.totalacc || 0),
                    E: null,
                    F: String.formatNumber.new((data.find(x => x.accaccou === '59')?.totalacc || 0)*-1)
                },
                {
                    A: null,
                    B: 'RESULTADOS DEL EJERCICIO',
                    C: null,
                    D: String.formatNumber.new(resultadoEjercicio),
                    E: null,
                    F: String.formatNumber.new(resultadoEjercicioAjustado)
                },
                {
                    A: null,
                    B: 'TOTAL PATRIMONIO',
                    C: null,
                    D: String.formatNumber.new(totalPatrimonio),
                    E: null,
                    F: String.formatNumber.new(totalPatrimonioAjustado)
                },
                {
                    A: null,
                    B: null,
                    C: null,
                    D: null,
                    E: null,
                    F: null
                },
                {
                    A: null,
                    B: 'TOTAL PASIVO Y PATRIMONIO',
                    C: null,
                    D: String.formatNumber.new(totalPasivo),
                    E: null,
                    F: String.formatNumber.new(totalPasivoAjustado)
                },
            ]

            const header = [
                'A',
                'B',
                'C',
                'D',
                'E',
                'F'
            ];

            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(tableBody, {header, skipHeader: true});
            ws["!cols"] = [
                {wpx:10},{wpx:300},{wpx:20},{wpx:80},{wpx:20},{wpx:80}
            ];
            ws['!rows'] = [
                {hpx: 16},{hpx: 18},{hpx: 16}
            ];

            const keys = Object.keys(ws);
            const rowsCount = tableBody.length;

            for (const iterator of keys) {
                if(ws[iterator].v?.match(/^[0-9,.-]+$/g) && iterator.match(/^(D|F)[0-9]+$/g)) {
                    ws[iterator].v = ws[iterator].v.replace(/,/g, '');
                    ws[iterator].t = 'n';
                    ws[iterator].z = '#,##0.00;[Black](#,##0.00);0.00;';
                }
                if(iterator !== '!ref' && iterator !== '!cols' && iterator !== '!rows') {
                    ws[iterator].s = {
                        font: {
                            name: 'Tahoma',
                            sz: 10
                        }
                    }
                }
            }

            for (let index = 3; index <= rowsCount; index++) {
                ws['!rows'].push({hpx: 16})
            }

            ws['B1'].s = {font:{name: 'Tahoma', sz: 10, bold: true}}
            ws['B3'].s = {font:{name: 'Tahoma', sz: 11, bold: true}, alignment: { horizontal: 'center' }}
            ws['B5'].s = {font:{name: 'Tahoma', sz: 10, bold: true}, alignment: { horizontal: 'center' }}

            ws['B6'].s = {font:{name: 'Tahoma', sz: 10, bold: true}}
            ws['D6'].s = {font:{name: 'Tahoma', sz: 10, bold: true}}
            ws['F6'].s = {font:{name: 'Tahoma', sz: 10, bold: true}}

            ws['B12'].s = {font:{name: 'Tahoma', sz: 10, bold: true}}
            ws['D12'].s = {font:{name: 'Tahoma', sz: 10, bold: true}, border: {top: { style: 'thin', color: 'black'}}}
            ws['F12'].s = {font:{name: 'Tahoma', sz: 10, bold: true}, border: {top: { style: 'thin', color: 'black'}}}

            ws['B14'].s = {font:{name: 'Tahoma', sz: 10, bold: true}}

            ws['B20'].s = {font:{name: 'Tahoma', sz: 10, bold: true}}
            ws['D20'].s = {font:{name: 'Tahoma', sz: 10, bold: true}, border: {top: { style: 'thin', color: 'black'}}}
            ws['F20'].s = {font:{name: 'Tahoma', sz: 10, bold: true}, border: {top: { style: 'thin', color: 'black'}}}

            ws['B22'].s = {font:{name: 'Tahoma', sz: 10, bold: true}}
            ws['D22'].s = {font:{name: 'Tahoma', sz: 10, bold: true}, border: {top: { style: 'thin', color: 'black'}}}
            ws['F22'].s = {font:{name: 'Tahoma', sz: 10, bold: true}, border: {top: { style: 'thin', color: 'black'}}}

            ws['B23'].s = {font:{name: 'Tahoma', sz: 10, bold: true}, alignment: { horizontal: 'center' }}

            ws['B24'].s = {font:{name: 'Tahoma', sz: 10, bold: true}}

            ws['B30'].s = {font:{name: 'Tahoma', sz: 10, bold: true}}
            ws['D30'].s = {font:{name: 'Tahoma', sz: 10, bold: true}, border: {top: { style: 'thin', color: 'black'}}}
            ws['F30'].s = {font:{name: 'Tahoma', sz: 10, bold: true}, border: {top: { style: 'thin', color: 'black'}}}

            ws['B32'].s = {font:{name: 'Tahoma', sz: 10, bold: true}}

            ws['B36'].s = {font:{name: 'Tahoma', sz: 10, bold: true}}
            ws['D36'].s = {font:{name: 'Tahoma', sz: 10, bold: true}, border: {top: { style: 'thin', color: 'black'}}}
            ws['F36'].s = {font:{name: 'Tahoma', sz: 10, bold: true}, border: {top: { style: 'thin', color: 'black'}}}

            ws['B38'].s = {font:{name: 'Tahoma', sz: 10, bold: true}}

            ws['B44'].s = {font:{name: 'Tahoma', sz: 10, bold: true}}
            ws['D44'].s = {font:{name: 'Tahoma', sz: 10, bold: true}, border: {top: { style: 'thin', color: 'black'}}}
            ws['F44'].s = {font:{name: 'Tahoma', sz: 10, bold: true}, border: {top: { style: 'thin', color: 'black'}}}

            ws['B46'].s = {font:{name: 'Tahoma', sz: 10, bold: true}}
            ws['D46'].s = {font:{name: 'Tahoma', sz: 10, bold: true}, border: {top: { style: 'thin', color: 'black'}}}
            ws['F46'].s = {font:{name: 'Tahoma', sz: 10, bold: true}, border: {top: { style: 'thin', color: 'black'}}}
            
            ws["!merges"] = [
                { s: { r: 2, c: 1 }, e: { r: 2, c: 5 } }
            ];

            XLSX.utils.book_append_sheet(wb, ws, "Balance situacion financiera");

            var wbout = XLSX.write(wb, {bookType:'xlsx', type: 'binary', cellStyles: true});

            const s2ab = function(s) {
                var buf = new ArrayBuffer(s.length);
                var view = new Uint8Array(buf);
                for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
                return buf;
            }

            saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), `balance-situacion-financiera-${year}-${month}.xlsx`);

        } catch(err) {
            console.log(err);
        }
    }

    render() {

        const { title, year, month, base64Pdf } = this.state;
        const { loading, list, tables, failed, failedTitle, failedContent } = this.props.accountantReducers;
        const { classes } = this.props;

        return (
            <div style={{overflow: 'auto', height: '100%'}}>
                <Paper className='form__container'>
                    <Typography variant='h5'>{ title }</Typography>
                    <br/>
                    { failed && <MessageBox title={failedTitle} content={failedContent}/> }
                    <div style={{ display: 'flex' }}>
                        <form noValidate autoComplete="off" style={{ flex: 1, paddingRight: '15px', paddingTop: '2em' }}>
                            <Grid container spacing={2} >
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_año">Año</InputLabel>
                                            <Select
                                                labelId="lbl_año"
                                                id="year"
                                                className='select'
                                                label="Año"
                                                fullWidth
                                                value={ year }
                                                onChange={(e: any) => this.onChangeRoot({'year': e.target.value})}
                                            >
                                                <MenuItem value='2030'>2030</MenuItem>
                                                <MenuItem value='2029'>2029</MenuItem>
                                                <MenuItem value='2028'>2028</MenuItem>
                                                <MenuItem value='2027'>2027</MenuItem>
                                                <MenuItem value='2026'>2026</MenuItem>
                                                <MenuItem value='2025'>2025</MenuItem>
                                                <MenuItem value='2026'>2026</MenuItem>
                                                <MenuItem value='2025'>2025</MenuItem>
                                                <MenuItem value='2024'>2024</MenuItem>
                                                <MenuItem value='2023'>2023</MenuItem>
                                                <MenuItem value='2022'>2022</MenuItem>
                                                <MenuItem value='2021'>2021</MenuItem>
                                                <MenuItem value='2020'>2020</MenuItem>
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="lbl_mes">Mes</InputLabel>
                                            <Select
                                                labelId="lbl_mes"
                                                id="month"
                                                className='select'
                                                label="Mes"
                                                fullWidth
                                                value={ month }
                                                onChange={(e: any) => this.onChangeRoot({'month': e.target.value})}
                                            >
                                                {
                                                    meses.map((x: any) => 
                                                        <MenuItem value={x.key} key={x.key}>{x.value}</MenuItem>
                                                    )
                                                }
                                            </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} style={{ paddingTop: '50px' }}>
                                    <Button variant="contained" fullWidth color="primary" onClick={() => { 
                                            const balacode = [];
                                            for(let i = 0; i <= +month; i++) {
                                                balacode.push(`${year}${i.toString().padStart(2, '0')}`)
                                            }
                                            this.props.generalBalancesRequest(year, balacode, this.onGeneratePdf) 
                                        }}>
                                        Generar Reporte
                                    </Button>
                                </Grid>
                                <Grid item xs={12} style={{ paddingTop: '10px' }}>
                                    <Button variant="contained" fullWidth color="inherit" onClick={() => { 
                                            const balacode = [];
                                            for(let i = 0; i <= +month; i++) {
                                                balacode.push(`${year}${i.toString().padStart(2, '0')}`)
                                            }
                                            this.props.generalBalancesRequest(year, balacode, this.onGenerateExcel) 
                                        }}>
                                        Generar Reporte EXCEL
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                        <PdfView data={base64Pdf} style={{ flex: 2 }}/>
                    </div>
                    <br/>
                    {/* <FormFooter onCancel={ this.onCancel } onSubmit={ this.onSubmit }/> */}
                </Paper>
                <ScreenLoader enabled={loading}/>
            </div>
        );
    }
}

const accountantReducers = 'accountantReducers';
const dashboardReducers = 'dashboardReducers';

const mapStateToProps = (state: any) => ({
    accountantReducers: state.get(accountantReducers),
    dashboardReducers: state.get(dashboardReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        generalBalancesRequest
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(ReportBalanceFinancialPosition));