/**
 * Combine all reducers in this file and export the combined reducers.
 */
import { reducer as form } from 'redux-form/immutable';
import { combineReducers } from 'redux-immutable';
import { connectRouter } from 'connected-react-router/immutable';
import history from '../utils/history';
import authReducers from 'redux/reducers/authReducers';
import dashboardReducers from 'redux/reducers/dashboardReducers';
import usersReducers from 'redux/reducers/usersReducers';
import generalReducers from 'redux/reducers/generalReducers';
import accountantReducers from 'redux/reducers/accountantReducers';
import wharehouseProductsReducers from 'redux/reducers/wharehouseProductsReducers';
import salesReducers from 'redux/reducers/salesReducers';
import shoppingsReducers from 'redux/reducers/shoppingsReducers';
import humanResourcesReducers from 'redux/reducers/humanResourcesReducers';
import financeReducers from 'redux/reducers/financeReducers';

// function branchReducer(reducerFunction, reducerName) {
//   return (state, action) => {
//     const { branch } = action;
//     const isInitializationCall = state === undefined;
//     if (branch !== reducerName && !isInitializationCall) {
//       return state;
//     }
//     return reducerFunction(state, action);
//   };
// }

export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    form,
    router: connectRouter(history),
    authReducers,
    dashboardReducers,
    usersReducers,
    generalReducers,
    accountantReducers,
    wharehouseProductsReducers,
    shoppingsReducers,
    salesReducers,
    humanResourcesReducers,
    financeReducers,
    ...injectedReducers,
  });

  // Wrap the root reducer and return a new root reducer with router state
  const mergeWithRouterState = connectRouter(history);
  return mergeWithRouterState(rootReducer);
}