import React from 'react';
import { AppBar, Backdrop, Box, Button, ButtonGroup, Checkbox, CircularProgress, createStyles, FormControl, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Select, Tab, Tabs, TextField, Theme, Tooltip, Typography, FormControlLabel, withStyles, Snackbar } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { productsInitFormRequest, productsInitUpdateFormRequest, productsInsertRequest, productsUpdateRequest } from 'penta-redux/actions/wharehouseProductsActions';
import history from '../../../utils/history';
import './products.scss'
import FormFooter from 'penta-components/base/formFooter';
import { MessageBox } from 'penta-components/base/messageBox';
import { productEntity, unitEntity } from 'models/wharehouseProductsModels';
import Autocomplete from '@material-ui/lab/Autocomplete';
import EditableCell from 'penta-components/base/editableCell';
import moment from 'moment';
import { Alert } from '@material-ui/lab';
import ScreenLoader from 'penta-components/base/screenLoader';

const headerLocation = ["","Almacen", "Ubicacion", "Stock", "Stock Reservado", "Costo Total"];
const headerBarcode = ["","Codigo de Barras"];
const headerPrices = ["", "Codigo", "Precio", "Tipo de precio", "Es oferta", "Precio Oferta", "Por Defecto"];

interface TabPanelProps {
    children: any
    index: any
    value: any
};

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}
  
function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = (theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        formControl: {
            margin: 0,
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    });

const defaultProductsEntity: productEntity = {
    skucodee: '',
    officode: '',
    uprcodee: '',
    prodname: '',
    proddesc: '',
    clascode: '',
    typecode: '',
    ctgrcode: '',
    famicode: '',
    ctrlstoc: false,
    stockmin: 0,
    stockmax: 0,
    meascode: '',
    photpath: '',
    purcacca: '',
    saleacca: '',
    fiscyear: +localStorage.getItem('currentFiscyear'),
    whp_atribute: [],
    whp_prodloca: [],
    whp_prodbarc: [],
    whp_assembly: [],
    whp_prprices: []
}

interface FormProductsState {
    productsEntity: productEntity
    title: string
    tabValue: number
    snackbar: boolean
    error: {[key: string]: string | boolean}
}

class FormProducts extends React.Component<any, FormProductsState> {

    constructor(props: any) {
        super(props);
        defaultProductsEntity.whp_atribute = [];
        defaultProductsEntity.whp_prodloca = [];
        defaultProductsEntity.whp_prodbarc = [];
        defaultProductsEntity.whp_assembly = [];
        defaultProductsEntity.whp_prprices = [];
        this.state = {
            productsEntity: defaultProductsEntity,
            title: 'Agregar Productos',
            tabValue: 0,
            snackbar: false,
            error: {
                message: '',
                state: false
            }
        }
    }

    onChangeTab = (event: any, newValue: any) => {
        this.setState({
            tabValue: newValue
        })
    }

    onChangeRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            ...value
        }))
    }

    onChangeproductsRoot = (value: any) => {
        this.setState((state) => ({
            ...state,
            productsEntity: {
                ...state.productsEntity,
                ...value
            }
        }))
    }

    onCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        console.log('cancel')
        history.push('/papp/products/productos');
    }

    onContinueCallback = () => {
        const newProductsEntity = defaultProductsEntity;
        newProductsEntity.whp_atribute = [];
        newProductsEntity.whp_prodloca = [];
        newProductsEntity.whp_prodbarc = [];
        newProductsEntity.whp_assembly = [];
        newProductsEntity.whp_prprices = [];
        this.setState({
            productsEntity: newProductsEntity,
            snackbar: true
        });
    }

    isNotValidForm = () => {
        const { planAccount } = this.props.wharehouseProductsReducers.tables;
        const { productsEntity } = this.state;
        if((productsEntity.skucodee || '') === '') {
            this.setState({error: {state: true, message: `El código de producto no puede estar vacio`}})
            console.log('asas-1')
            return true;
        }
        if(!planAccount.find(x => x.accaccou === productsEntity.purcacca))  {
            this.setState({error: {state: true, message: `El numero de cuenta ${productsEntity.purcacca} no es valido`}})
            return true;
        }
        if(!planAccount.find(x => x.accaccou === productsEntity.saleacca))  {
            this.setState({error: {state: true, message: `El numero de cuenta ${productsEntity.saleacca} no es valido`}})
            return true;
        }
        return false;
    }

    onSubmitContinue = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const { params } = this.props.match;
        if(this.isNotValidForm()) return;
        if (params.skucodee !== undefined) {
            this.props.productsUpdateRequest(this.state.productsEntity);
        } else {
            this.props.productsInsertRequest(this.state.productsEntity, this.onContinueCallback);
        }
    }

    onSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const { params } = this.props.match;
        if(this.isNotValidForm()) return;
        if (params.skucodee !== undefined) {
            this.props.productsUpdateRequest(this.state.productsEntity);
        } else {
            this.props.productsInsertRequest(this.state.productsEntity, null);
        }
    }

    onEditCell = (props: any, value: any, type:number) => {
        const { whp_assembly, whp_prodbarc, whp_atribute, whp_prodloca, whp_prprices } = this.state.productsEntity;
        switch(type){
            case 1: {
                (whp_prodloca as any)[props.index][props.field] = value;
                this.setState((state: FormProductsState) => ({
                    ...state,
                    productsEntity: {
                        ...state.productsEntity,
                        whp_prodloca
                    }
                }))
            };
            break;
            case 2: {
                (whp_prodbarc as any)[props.index][props.field] = value;
                this.setState((state: any) => ({
                    ...state,
                    productsEntity: {
                        ...state.productsEntity,
                        whp_prodbarc
                    }
                }));
            };
            break;
            case 3: {
                if(props.field == "isdfault"){
                    for(var i = 0; i < (whp_prprices as any).length; i++){
                        (whp_prprices as any)[i].isdfault = false;
                    }
                }
                (whp_prprices as any)[props.index][props.field] = value;
                this.setState((state: any) => ({
                    ...state,
                    productsEntity: {
                        ...state.productsEntity,
                        whp_prprices
                    }
                }));
            };
            break;
        }
    }

    onAddDetail = (type:number) => {
        const { whp_assembly, whp_prodbarc, whp_atribute, whp_prodloca, whp_prprices } = this.state.productsEntity;
        const { wharehouse, definition } = this.props.wharehouseProductsReducers.tables;
        switch(type) {
            case 1 :{
                const newItem: any = {
                    wharcode: wharehouse[0].wharcode,
                    locadesc: '',
                    totstock: 0,
                    avastock: 0,
                    resstock: 0,
                    totacost: 0,
                    avarcost: 0,
                    balances: "{}",
                    select: false
                };
                (whp_prodloca as any).push(newItem);
                this.setState((state: any) => ({
                    ...state,
                    productsEntity: {
                        ...state.productsEntity,
                        whp_prodloca,
                    }
                }))
            };
            break;
            case 2: {
                const newItem: any = {
                    barcodee: '',
                    select: false
                };
                (whp_prodbarc as any).push(newItem);
                this.setState((state:any) => ({
                    ...state,
                    productsEntity: {
                        ...state.productsEntity,
                        whp_prodbarc
                    }
                }))
            };
            break;
            case 3: {
                const newItem : any= {
                    priccode: '',
                    pricprod: 0,
                    isdfault: false,
                    deficode: definition[0].deficode,
                    isofertt: false,
                    inioffed: moment(Date.now()),
                    endoffed: moment(Date.now()),
                    select: false
                };
                (whp_prprices as any).push(newItem);
                this.setState((state:any) => ({
                    ...state,
                    productsEntity: {
                        ...state.productsEntity,
                        whp_prprices
                    }
                }))
            };
            break;
        }
    }

    onDeleteDetail = (type:number) => {
        let { whp_assembly, whp_prodbarc, whp_atribute, whp_prodloca, whp_prprices } = this.state.productsEntity;
        switch(type){
            case 1:{            
                whp_prodloca = whp_prodloca.filter((x: any) => x.select === false || x.select === undefined);
                this.setState((state: any) => ({
                    ...state,
                    productsEntity: {
                        ...state.productsEntity,
                        whp_prodloca
                    }
                }));
            };
            break;
            case 2:{
                whp_prodbarc = whp_prodbarc.filter((x: any) => x.select === false || x.select === undefined);
                this.setState((state: any) => ({
                    ...state,
                    productsEntity: {
                        ...state.productsEntity,
                        whp_prodbarc
                    }
                }))
            };
            break;
            case 3:{
                whp_prprices = whp_prprices.filter((x: any) => x.select === false || x.select === undefined);
                this.setState((state: any) => ({
                    ...state,
                    productsEntity: {
                        ...state.productsEntity,
                        whp_prprices
                    }
                }))
            };
            break;
        }
    }

    refsGrid: any = {};

    renderDetail = (type: number) => {
        const { wharehouse, definition } = this.props.wharehouseProductsReducers.tables;
        const { whp_assembly, whp_prodbarc, whp_atribute, whp_prodloca, whp_prprices } = this.state.productsEntity;
        switch(type){
            case 1 : return (
                whp_prodloca.map((item: any, i: any) => (
                    <tr key={ i } >
                        <td>
                            <input type="checkbox"
                            checked={ item.select } 
                            onChange={ (e: any) => 
                                this.onEditCell(
                                    {index: i, field: 'select' }, 
                                    e.target.checked,
                                    1
                                )}
                            />
                        </td>
                        <td>
                            <select style={{width:'100%'}} value={ item.wharcode } onChange={ (e: any) => this.onEditCell({index: i, field: 'wharcode'}, e.target.value, 1 )}>
                                { wharehouse.map((item:any) => <option value={item.wharcode} key={item.wharcode}>{item.wharname}</option>) }
                            </select>
                        </td>
                        <td>
                            <input type="text"
                            value={ item.locadesc } 
                            onChange={ (e: any) => 
                                this.onEditCell(
                                    {index: i, field: 'locadesc' }, 
                                    e.target.value,
                                    1
                                )}
                            />
                        </td>
                        <td className="disabled">
                            <input type="text"
                            value={ item.totstock }
                            disabled
                            />
                        </td>
                        <td className="disabled">
                            <input type="text"
                            value={ item.resstock }
                            disabled
                            />
                        </td>
                        <td className="disabled">
                            <input type="text"
                            value={ item.totacost }
                            disabled
                            />
                        </td>
                    </tr>
                ))
            )
            case 2: return(
                whp_prodbarc.map((item: any, i: any) => (
                    <tr key={ i }>
                        <td>
                            <input type="checkbox"
                            checked={ item.select } 
                            onChange={ (e: any) => 
                                this.onEditCell(
                                    {index: i, field: 'select' }, 
                                    e.target.checked,
                                    2
                                )}
                            />
                        </td>
                        <td>
                            <input type="text"
                            value={ item.barcodee } 
                            onChange={ (e: any) => 
                                this.onEditCell(
                                    {index: i, field: 'barcodee' }, 
                                    e.target.value
                                    ,2
                                )}
                            />
                        </td>
                    </tr>
                ))
            )
            case 3: return(
                whp_prprices.map((item:any, i:any) => (
                    <tr key={ i } onKeyPress={ (e:any) => { if(e.key === 'Enter') this.onAddDetail(type); } }>
                        <td>
                            <input type="checkbox"
                            checked={ item.select } 
                            onChange={ (e: any) => 
                                this.onEditCell(
                                    {index: i, field: 'select' }, 
                                    e.target.checked,
                                    3
                                )}
                            />
                        </td>
                        <td>
                            <input type="text"
                            value={ item.priccode } 
                            onChange={ (e: any) => 
                                this.onEditCell(
                                    {index: i, field: 'priccode' }, 
                                    e.target.value,
                                    3
                                )}
                            />
                        </td>
                        <td>
                            <input type="text"
                            value={ item.pricprod } 
                            onChange={ (e: any) => 
                                this.onEditCell(
                                    {index: i, field: 'pricprod' }, 
                                    e.target.value,
                                    3
                                )}
                            />
                        </td>
                        <td>
                            <select style={{width:'100%'}} value={ item.deficode } onChange={ (e: any) => this.onEditCell({index: i, field: 'deficode'}, e.target.value, 1 )}>
                                { definition.filter((x: any) => x.defitype === 'PROPR').map((item:any) => <option value={item.deficode} key={item.deficode}>{item.definame}</option>) }
                            </select>
                        </td>
                        <td>
                            <input type="checkbox"
                            checked={ item.isoffert } 
                            onChange={ (e: any) => 
                                this.onEditCell(
                                    {index: i, field: 'isoffert' }, 
                                    e.target.checked,
                                    3
                                )}
                            />
                        </td>
                        <td>
                            <input type="text"
                            value={ item.offepric } 
                            onChange={ (e: any) => 
                                this.onEditCell(
                                    {index: i, field: 'offepric' }, 
                                    e.target.value,
                                    3
                                )}
                            />
                        </td>
                        <td>
                            <input type="checkbox"
                            checked={ item.isdfault } 
                            onChange={ (e: any) => 
                                this.onEditCell(
                                    {index: i, field: 'isdfault' }, 
                                    e.target.checked,
                                    3
                                )}
                            />
                        </td>
                    </tr>
                ))
            )
        }
    }

    renderGrilla = (header: Object, type: number) => {
        return (
            <div className="form">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className="form__field">
                            <div className="grid__actions">
                                <button className="add" onClick={ (e) => { e.preventDefault(); this.onAddDetail(type) } }><i className="fas fa-plus"/></button>
                                <button className="remove" onClick={ (e) => { e.preventDefault(); this.onDeleteDetail(type) } }><i className="fas fa-times"/></button>
                                <span style={ { marginLeft: '15px' } }>[ENTER]: Agregar nuevo item</span>
                                <span style={ { marginLeft: '15px' } }>[TAB]: Navegar entre las celdas</span>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <EditableCell
                            header={ header }
                            data={ this.renderDetail(type) }
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }

    callbackInit = () => {
        const { tables } = this.props.wharehouseProductsReducers;
        const clascode = tables.definition.filter((x: any) => x.defitype === 'PROCL')[0].deficode
        const typecode = tables.definition.filter((x: any) => x.defitype === 'PROTP')[0].deficode
        this.setState((state: any) => ({
            ...state,
            productsEntity: {
                ...state.productsEntity,
                clascode,
                typecode,
                ctgrcode: tables.categorys[0].ctgrcode,
                famicode: tables.families[0].famicode,
                ctrlstoc: false,
                stockmin: 0,
                stockmax: 0,
                meascode: tables.units[0].meascode,
                whp_prodloca: [
                    {
                        skucodee: null,
                        wharcode: tables.wharehouse[0].wharcode,
                        locadesc: '',
                        totstock: 0,
                        avastock: 0,
                        resstock: 0,
                        totacost: 0,
                        avarcost: 0,
                        balances: {},
                        select: false
                    }
                ]
            }
        }));
        console.log(tables);
    }

    callbackInitUpdate = (productsEntity: productEntity) => {
        this.setState({
            productsEntity
        });
    }

    componentDidMount() {
        const { params } = this.props.match;
        if (params.skucodee !== undefined) {
            this.setState({title: 'Editar Productos'});
            this.props.productsInitUpdateFormRequest(params.skucodee, this.callbackInitUpdate);
        } else {
            this.props.productsInitFormRequest(this.props.dashboardReducers.currentFiscyear, this.callbackInit);
        }
    }

    render() {

        const { productsEntity, title, snackbar, tabValue, error } = this.state;
        const { loading, list, tables, failed, failedTitle, failedContent } = this.props.wharehouseProductsReducers;
        const { classes } = this.props;

        return (
            <div style={{overflow: 'auto', height: '100%'}}>
                <Paper className='form__container'>
                    <br/>
                    <Typography variant='h5'>{ title }</Typography>
                    <br/>
                    { failed && <MessageBox title={failedTitle} content={failedContent}/> }
                    { error.state && <MessageBox title='Error' content={error.message as string}/> }
                    <form noValidate autoComplete="off">
                        <AppBar position="static">
                            <Tabs value={tabValue} onChange={this.onChangeTab} aria-label="simple tabs example">
                                <Tab label="General" {...a11yProps(0)} />
                                <Tab label="Ubicaciones" {...a11yProps(1)} />
                                <Tab label="Codigo de barras" {...a11yProps(2)} />
                                <Tab label="Precios" {...a11yProps(3)} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={tabValue} index={0}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <TextField fullWidth id="skucodee" label="Codigo" variant="outlined" value={ productsEntity.skucodee } onChange={(e: any) => this.onChangeproductsRoot({'skucodee': e.target.value})}/>
                                </Grid>
                                {/* <Grid item xs={4}>
                                    <TextField fullWidth id="uprcodee" label="UPC" variant="outlined" value={ productsEntity.uprcodee } onChange={(e: any) => this.onChangeproductsRoot({'uprcodee': e.target.value})}/>
                                </Grid> */}
                                <Grid item xs={4}>
                                    <TextField fullWidth id="prodname" label="Nombre" variant="outlined" value={ productsEntity.prodname } onChange={(e: any) => this.onChangeproductsRoot({'prodname': e.target.value})}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField fullWidth id="proddesc" label="Descripcion" variant="outlined" value={ productsEntity.proddesc } onChange={(e: any) => this.onChangeproductsRoot({'proddesc': e.target.value})}/>
                                </Grid>
                            </Grid>
                            {/* <Grid container spacing={2} >
                                <Grid item xs={4}>
                                    <TextField fullWidth id="prodname" label="Nombre" variant="outlined" value={ productsEntity.prodname } onChange={(e: any) => this.onChangeproductsRoot({'prodname': e.target.value})}/>
                                </Grid>
                            </Grid> */}
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                        <InputLabel id="lbl_clasification">Clasificacion</InputLabel>
                                        <Select
                                            labelId="lbl_clasification"
                                            id="clasification"
                                            className='select'
                                            label="Clasificacion"
                                            fullWidth
                                            value={productsEntity.clascode}
                                            onChange={(e: any) => this.onChangeproductsRoot({'clascode': e.target.value})}
                                        >
                                            {
                                                tables.definition.filter((x: any) => x.defitype === 'PROCL').map((item: any, key: any) => 
                                                    <MenuItem value={item.deficode} key={`clasification_${key}`}>{item.definame}</MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                        <InputLabel id="lbl_type">Tipo</InputLabel>
                                        <Select
                                            labelId="lbl_type"
                                            id="type"
                                            className='select'
                                            label="tipo"
                                            fullWidth
                                            value={productsEntity.typecode}
                                            onChange={(e: any) => this.onChangeproductsRoot({'typecode': e.target.value})}
                                        >
                                            {
                                                tables.definition.filter((x: any) => x.defitype === 'PROTP').map((item: any, key: any) => 
                                                    <MenuItem value={item.deficode} key={`type_${key}`}>{item.definame}</MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                        <InputLabel id="lbl_unit">Unidad de medida</InputLabel>
                                        <Select
                                            labelId="lbl_unit"
                                            id="unit"
                                            className='select'
                                            label="Unidad"
                                            fullWidth
                                            value={productsEntity.meascode}
                                            onChange={(e: any) => this.onChangeproductsRoot({'meascode': e.target.value})}
                                        >
                                            {
                                                tables.units.map((item: any, key: any) => 
                                                    <MenuItem value={item.meascode} key={`unit_${key}`}>{item.measname}</MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Autocomplete
                                        id="ctgrcode"
                                        freeSolo
                                        options={tables.categorys}
                                        getOptionLabel={ (x: any) => x.ctgrcode }
                                        value={ tables.categorys.find((x: any) => x.ctgrcode === productsEntity.ctgrcode) || null }
                                        onChange={(e: any, value) => this.onChangeproductsRoot({'ctgrcode': value.ctgrcode, 'accaccou': value.accaccou, 'fiscyear': value.fiscyear})}
                                        renderInput={(params) => (
                                            <TextField {...params} 
                                            style={ { marginTop: 0 } } label="Categoria" margin="normal" variant="outlined" />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Autocomplete
                                        id="famicode"
                                        freeSolo
                                        options={tables.families}
                                        getOptionLabel={ (x: any) => x.famicode }
                                        value={ tables.families.find((x: any) => x.famicode === productsEntity.famicode) || null }
                                        onChange={(e: any, value) => this.onChangeproductsRoot({'famicode': value.famicode})}
                                        renderInput={(params) => (
                                            <TextField {...params} 
                                            style={ { marginTop: 0 } } label="Familias" margin="normal" variant="outlined" />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Autocomplete
                                        id="purcacca"
                                        freeSolo
                                        options={tables.planAccount}
                                        getOptionLabel={ (x: any) => x.accaccou }
                                        value={ tables.planAccount.find((x: any) => x.accaccou === productsEntity.purcacca) || {accaccou: '', fiscyear: ''}  }
                                        onChange={(e: any, value) => {
                                            this.onChangeproductsRoot({'purcacca': value?.accaccou})
                                            this.onChangeproductsRoot({'fiscyear': value?.fiscyear})
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} 
                                            onBlur={(e) => { 
                                                const a = tables.planAccount.find((x: any) => x.accaccou === e.target.value);
                                                if(a) {
                                                    this.onChangeproductsRoot({'purcacca': a?.accaccou})
                                                    this.onChangeproductsRoot({'fiscyear': a?.fiscyear})
                                                }
                                            }}
                                            style={ { marginTop: 0 } } label="Cta contable compras" margin="normal" variant="outlined" />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Autocomplete
                                        id="saleacca"
                                        freeSolo
                                        options={tables.planAccount}
                                        getOptionLabel={ (x: any) => x.accaccou }
                                        value={ tables.planAccount.find((x: any) => x.accaccou === productsEntity.saleacca) || {accaccou: '', fiscyear: ''}  }
                                        onChange={(e: any, value) => {
                                            this.onChangeproductsRoot({'saleacca': value?.accaccou})
                                            this.onChangeproductsRoot({'fiscyear': value?.fiscyear})
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} 
                                            onBlur={(e) => { 
                                                const a = tables.planAccount.find((x: any) => x.accaccou === e.target.value);
                                                if(a) {
                                                    this.onChangeproductsRoot({'saleacca': a?.accaccou})
                                                    this.onChangeproductsRoot({'fiscyear': a?.fiscyear})
                                                }
                                            }}
                                            style={ { marginTop: 0 } } label="Cta contable ventas" margin="normal" variant="outlined" />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <TextField fullWidth id="stockmin" label="Stock Minimo" variant="outlined" value={ productsEntity.stockmin } onChange={(e: any) => this.onChangeproductsRoot({'stockmin': e.target.value})}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField fullWidth id="stockmax" label="Stock Maximo" variant="outlined" value={ productsEntity.stockmax } onChange={(e: any) => this.onChangeproductsRoot({'stockmax': e.target.value})}/>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={<Checkbox checked={productsEntity.ctrlstoc} onChange={(e: any) => this.onChangeproductsRoot({'ctrlstoc': e.target.checked})} name="ctrlstoc" />}
                                        label="Controla Stock"
                                    />
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            {this.renderGrilla(headerLocation, 1)}
                        </TabPanel>
                        <TabPanel value={tabValue} index={2}>
                            {this.renderGrilla(headerBarcode, 2)}
                        </TabPanel>
                        <TabPanel value={tabValue} index={3}>
                            {this.renderGrilla(headerPrices, 3)}
                        </TabPanel>
                    </form>
                    <br/>
                    <FormFooter 
                        onCancel={ this.onCancel } 
                        onSubmitContinue={ this.onSubmitContinue } 
                        onSubmit={ this.onSubmit }/>
                </Paper>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={snackbar}
                    onClose={() => this.setState({snackbar: false})}
                    autoHideDuration={6000}
                >
                    <Alert onClose={() => this.setState({snackbar: false})} severity="success">
                            Datos Guardados Correctamente
                    </Alert>
                </Snackbar>
                <ScreenLoader enabled={loading}/>
            </div>
        );
    }
}

const wharehouseProductsReducers = 'wharehouseProductsReducers';
const dashboardReducers = 'dashboardReducers';

const mapStateToProps = (state: any) => ({
    wharehouseProductsReducers: state.get(wharehouseProductsReducers),
    dashboardReducers: state.get(dashboardReducers)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        productsInitFormRequest,
        productsInitUpdateFormRequest,
        productsInsertRequest,
        productsUpdateRequest
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(FormProducts));