import React from 'react';
import DashboardContainer from 'penta-components/application/container';

interface Dashboard {
    children: React.ReactNode
}

const Dashboard = (props: Dashboard) => {
    return (
        <DashboardContainer>
            { props.children }
        </DashboardContainer>
    )
};

export default Dashboard;